import React from 'react';
import { checkArrayLength, checkObject } from '../../utils/utils';

// Components

const TableCell = (props) => {
    const { children, numeric, sortDirection, style, width, dataTypePrefix, className, nowrap, centerV, isTableCellClass, ...rem } = props;
    let styles = checkObject(style) ? style : {};
    styles = {
        ...styles,
        display: 'flex',
        alignItems: 'center',
        width,
    };
    return (
        <div 
            data-type={`${dataTypePrefix}TableCell`} 
            {...rem} 
            style={styles}
            className={!isTableCellClass ? className : `${className} TableCell ${nowrap ? 'nowrap' : ''} ${centerV ? 'centerV' : ''}`}
        >
            {isTableCellClass && nowrap && (!checkArrayLength(children) || (!!children[1] && !children[1].props?.id?.match(/r[0-9]+/))) ? (
                <div className="nowrap">
                    <span>{children}</span>
                </div>
            ) : (
                children
            )}
        </div>
    );
};

TableCell.defaultProps = {
    dataTypePrefix: '',
};

export default TableCell;
