import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { checkObject } from '../../utils/utils';
import { TABLES_CSS } from '../../common/cssConstants';

const styles = (theme) => ({
    extraPadding: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: 15,
        },
    },
    tableHead: {
        background: '#fff',
        [theme.breakpoints.up('sm')]: {
            paddingRight: 15,
        },
        '& .TableRow': {
            height: 40,
            backgroundColor: '#fff',
            '& .TableCell': {
                paddingLeft: '5px !important',
                paddingRight: '5px !important',
                height: 40,
                display: 'flex',
                justifyContent: 'space-between',
                position: 'relative',
                minWidth: '60px',
                [theme.breakpoints.only('xs')]: {
                    minWidth: '200px',
                },
                '& #element1.THCellWrapper': {
                    overflow: 'initial',
                },
                '& .THCellWrapper': {
                    display: 'flex',
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    overflow: 'hidden',
                    '& .THSortText': {
                        fontWeight: 'bold',
                        fontSize: TABLES_CSS.headingFontSize,
                        color: TABLES_CSS.headingFontColor,
                    },
                    '& .THText': {
                        fontWeight: 'bold',
                        fontSize: TABLES_CSS.headingFontSize,
                        color: TABLES_CSS.headingFontColor,
                        cursor: 'default',
                        display: 'inline-flex',
                        alignItems: 'center',
                        flexDirection: 'inherit',
                        justifyContent: 'flex-start',
                    },
                    '& .sortLable': {
                        position: 'unset',
                    },
                },
            },
            '& .TableCell.nowrap': {
                '& .THCellWrapper': {
                    whiteSpace: 'nowrap',
                },
            },
            '& .TableCell.centerV': {
                '& .THCellWrapper': {
                    textAlign: 'center',
                    justifyContent: 'center',
                },
            },
        },
    },
});

const TableHead = (props) => {
    const { children, style, className, classes, dataTypePrefix, display, isTableHeadClass, calledFrom, ...rem } = props;
    const classNames = className || '';
    let _style = checkObject(style) ? style : {};
    _style = {
        ..._style,
        display: 'inline-block',
        minWidth: '100%',
    };

    return (
        <div data-type={`${dataTypePrefix}TableHead`} {...rem} style={_style} className={`${classNames} ${!isTableHeadClass ? calledFrom === "postAction" ? '' : classes.extraPadding : `${classes.tableHead} TableHead`}`}>
            {children}
        </div>
    );
};

TableHead.defaultProps = {
    dataTypePrefix: '',
};

export default withStyles(styles)(TableHead);
