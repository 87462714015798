import React, { useEffect } from 'react'
// Importing components
import TableRow from '../../../../components/Table/TableRow'
import TableCell from '../../../../components/Table/TableCell';
import { MenuOptions } from '../../../../components/Button/MenuOptions';
import Tooltip from '../../../../components/Tooltip';
// Importing material ui components
import { Typography, TextField, } from '@material-ui/core';
// Importing Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
// Importing utils
import { resizerX } from '../../../../utils/utils'
import { TestStepUtils } from '../../../../utils/TestStepUtils';

const DatasheetRow = (props) => {
    const {
        tableRowStyle,
        tableCellStyles,
        elementPrefix,
        isColumnEmpty,
        rowData,
        rowIndex,
        colsWidth,
        width,
        classes,
        updateSheet,
        updateDataSheet,
        renderPopperItems,
        availWidth,
        rowNo,
        columnNo,
        hoverColumn,
        handleHoverOnTable,
    } = props

    useEffect(() => {
        rowData.forEach((data, index) => {
            if (index !== rowData.length - 1) {
                resizerX(`r${index}`, `element${index}`, `element${index + 1}`, colsWidth, elementPrefix);
            }
        });
    }, [colsWidth]);

    return (
        <TableRow
            className={classes.tableRowHover}
            isTableRowStyle={true}
            showBorder={'solid 0.5px rgb(151 151 151 / 69%)'}
            style={{
                ...tableRowStyle,
                height: rowIndex === 0 ? '40px' : '50px',
                backgroundColor: rowIndex !== 0 && rowNo === rowIndex && columnNo === 0 ? 'rgba(0, 0, 0, 0.07)' : rowIndex === 0 ? "#fff" : "#f4f6f7",
                paddingLeft: rowIndex !== 0 ? '5px' : "0"
            }}
            dataTypePrefix={`${elementPrefix}`}
            width={`${availWidth}px`}
        >
            {rowData.map((label, index) => (
                <TableCell
                    dataTypePrefix={`${elementPrefix}`}
                    isTableCellClass={true} 
                    key={`${rowIndex}-${rowIndex === 0 ? 'column' : 'row'}-${index}`}
                    className={[classes.cell, classes.noWrap].join(' ')}
                    style={{ ...tableCellStyles, backgroundColor: hoverColumn && columnNo === index ? 'rgba(0, 0, 0, 0.07)' : 'inherit'}}
                    width={width}
                    data-resize={`${elementPrefix}element${index}_c`}
                >
                    <div
                        id={`${elementPrefix}element${index}`}
                        className={`THCellWrapper ${classes.width100}`}
                        aria-hidden
                    >

                        <span className={classes.cellContainer}>
                            {updateSheet && <MenuOptions
                                handleHoverOnTable={(...args) => handleHoverOnTable(...args)}
                                row={rowIndex}
                                column={index}
                                items={renderPopperItems(rowIndex === 0 ? 'columns' : 'rows', rowIndex, index, label.value)}
                                keepMounted
                                customClasses={
                                    ((rowIndex === 0 && rowNo === rowIndex && columnNo === index) || (index === 0 && rowNo === rowIndex && columnNo === index))
                                        ?
                                        classes.persistMoreIconOnClick
                                        :
                                        ((rowIndex === 0) || (index === 0))
                                            ? classes.managerHeaderCell
                                            :
                                            classes.hideOption}
                            />}
                            {!updateSheet ?
                                <Typography
                                    variant="body2"
                                    className={`${classes.noWrap} ${classes.textStyle} THText`}
                                    style={{
                                        fontWeight: rowIndex === 0 ? 'bold' : '400',
                                        display: 'block'
                                    }}
                                >
                                    <Tooltip data={` ${rowIndex === 0 ? label.value.replace(/"/g, '') : label.isPasswordColumn ? TestStepUtils.isShowAsterik("password", label.value) : label.value}`}>
                                        {`${rowIndex === 0 ? label.value.replace(/"/g, '') : label.isPasswordColumn ? TestStepUtils.isShowAsterik("password", label.value) : label.value}`}
                                    </Tooltip>
                                </Typography>
                                :
                                <Tooltip  toolTipAreaStyle={{width:'100%'}}>
                                    <TextField
                                    id={`outlined-basic-${label.value}`}
                                    variant="outlined"
                                    type={label.isPasswordColumn && rowIndex !== 0 ? 'password' : 'text'}
                                    InputProps={{
                                        classes: {
                                            focused: `${(isColumnEmpty && rowIndex === 0 && label.value?.trim() === '') && classes.focused}`,
                                            notchedOutline: classes.notchedOutline                                           
                                          }                                      
                                      }}
                                    fullWidth
                                    inputProps={{
                                        style: {fontWeight: rowIndex === 0 ? 'bold' : 'normal'}
                                    }}
                                    style={{padding: rowIndex === 0 ? '5px 5px 5px 2px' : '5px 13px 5px 5px'}}
                                    value={label.value}
                                    className={`${classes.textfieldClass} ${(isColumnEmpty && rowIndex === 0 && label.value?.trim() === '') && classes.errorFieldClass}`}
                                    onChange={(e) => updateDataSheet(e, index, rowIndex)}
                                    />
                                </Tooltip>
                            }
                        </span>
                    </div>
                    {rowIndex === 0 && index !== rowData.length - 1 &&
                        <span
                            className={classes.barStyle}
                            id={`${elementPrefix}r${index}`}
                        >
                            <FontAwesomeIcon
                                className={classes.barIconStyle}
                                icon={faSortDown}
                                aria-label="faSortDown"
                                id="faSortDown"
                            />
                        </span>
                    }
                </TableCell>
            ))}
        </TableRow>
    )
}

export default DatasheetRow
