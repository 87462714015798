/* eslint-disable no-template-curly-in-string */
// import materials
import { withStyles } from '@material-ui/core/styles';
// import packages
import React from 'react';
import { connect } from 'react-redux';
import { Button, DialogTitle, Dialog, DialogContent, DialogActions, TextField } from '@material-ui/core';
import CloseOnEscape from 'react-close-on-escape';
import js_beautify from 'js-beautify';
import Close from '@material-ui/icons/Close';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/snippets/javascript';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/snippets/python';
import 'ace-builds/src-noconflict/mode-sh';
import 'ace-builds/src-noconflict/snippets/sh';
import 'ace-builds/src-noconflict/theme-kuroir';
import {
    DIALOG_ROOT,
    MODAL_HEADER_TITLE,
    MODAL_HEADER_CLOSE_ICON,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_SUBMIT_BUTTON,
    MODAL_CANCEL_BUTTON,
    MODAL_FOOTER,
    BLACK_FONT,
} from '../../../../common/cssConstants';
import { TestStepUtils } from '../../../../utils/TestStepUtils';
import { ModalActions } from '../../../../store/actions';
import { checkArrayLength, checkKeyInObject, checkObject } from '../../../../utils/utils';

// global variables
const options = {
    indent_size: 4,
    space_in_empty_paren: true,
    break_chained_methods: true,
    wrap_line_length: 50,
};
// let isMount = false
// const regex = /exec_.+[\s{]*([\s]*.*)[}\s]*with/i; // it will capture inner most pattern
const regex = /exec_.+[\s]*{([^`]+)}[\s]*with/i;
// Style
const styles = () => ({
    dialogTitleStyle: MODAL_HEADER_TITLE,
    dialog: {
        textAlign: 'center',
        backgroundColor: '#e9effd',
        borderBottom: '1px solid #dcdcdc',
    },
    buttonStyle: {
        padding: '2px 8px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#1067ce',
        borderRadius: '6px',
        textTransform: 'capitalize',
        marginRight: '10px',
    },
    cancelButton: {
        padding: '2px 8px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#1067ce',
        borderRadius: '6px',
        textTransform: 'capitalize',
    },
    dialogContent: {
        padding: '7px 0',
        minWidth: '700px',
        margin: '20px 30px',
        backgroundColor: '#F3F3F3',
    },
    button: {
        backgroundColor: '#ececec',
        color: BLACK_FONT,
        borderRadius: '10px',
        marginRight: 5,
        fontWeight: 700,
        width: '80px',
        minHeight: '30px',
        maxHeight: '30px',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '35px',
    },
    selected: {
        color: '#0068d4 !important',
        backgroundColor: '#aad4f7',
        '&:hover': {
            backgroundColor: '#86c8f0',
        },
    },
    dialogModal: {
        '&>div': {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    editor: {
        width: '100% !important',
        minHeight: '100px !important',
        maxHeight: 'calc(100vh - 420px) !important',
        backgroundColor: '#F3F3F3',
        '& textarea': {
            fontFamily: "'Inconsolata' !important",
            fontSize: '14px !important',
        },
        '& div': {
            fontFamily: "'Inconsolata' !important",
            fontSize: '14px !important',
        },
        '&>div:first-of-type>div:first-of-type': {
            backgroundColor: '#F3F3F3',
        },
        '& span': {
            fontFamily: "'Inconsolata' !important",
            fontSize: '14px !important',
        },
    },
    dataField: {
        '&>div>input': {
            fontFamily: "'Inconsolata' !important",
            fontSize: '12px !important',
        },
    },
    dataTitle: {
        fontFamily: "'Inconsolata' !important",
        fontSize: '14px !important',
    },
});

const codeArray = ['exec _js', 'exec _py', 'exec _sh', 'exec _bash'];

class CodeEditorModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: 'Exec _js{} with ${input} returning ${}',
            selectedExtension: 'javascript',
            dataValue: '',
        };
    }

    componentDidMount() {
        const {
            data: { codeEditorCallingAction, currentTestStep, tempTestStepToAdd },
        } = this.props;
        let _code = this.state.code;
        let _data = '';
        let _selectedExtension = this.state.selectedExtension;
        if (codeEditorCallingAction === 'edit') {
            if (checkKeyInObject(currentTestStep, 'instr')) {
                _code = currentTestStep.instr;
            }
            if (checkKeyInObject(currentTestStep, 'data')) {
                _data = currentTestStep.data;
            }
        } else if (codeEditorCallingAction === 'add' && checkObject(tempTestStepToAdd)) {
            _code = tempTestStepToAdd.testInstruction || _code;
            _data = tempTestStepToAdd.testData || '';
        }

        // To update extension button
        if (_code && typeof _code === 'string') {
            switch (codeArray.findIndex((c) => _code.toLowerCase().includes(c))) {
                case 0:
                    _selectedExtension = 'javascript';
                    break;
                case 1:
                    _selectedExtension = 'python';
                    break;
                case 2:
                    _selectedExtension = 'sh';
                    break;
                case 3:
                    _selectedExtension = 'bash';
                    break;
                default:
                    _selectedExtension = '';
                    break;
            }
        }

        this.updateState({ selectedExtension: _selectedExtension, code: js_beautify(_code, options), dataValue: _data });
        // isMount = true;
    }

    // componentWillUnmount() { isMount = false; }

    setCodeByExtension = (buttonName) => {
        const { code } = this.state;
        const capturedCode = code.match(regex);
        const extractedCode = checkArrayLength(capturedCode) ? capturedCode[1] : '';
        let _code = extractedCode;

        switch (buttonName) {
            case 'javascript': {
                if (code && code.toLowerCase().includes('save _js')) {
                    _code = `Save _js {${_code}} as variableName`;
                } else {
                    _code = `Exec _js {${_code}} with \${input_var} returning \${output_var}`;
                }
                break;
            }
            case 'python':
                _code = `Exec _py {${_code}} with \${input_var} returning \${output_var}`;
                break;
            case 'sh':
                _code = `Exec _sh {${_code}} with \${input_var} returning \${output_var}`;
                break;
            case 'bash':
                _code = `Exec _bash {${_code}} with \${input_var} returning \${output_var}`;
                break;
            default:
                _code = code;
        }
        return js_beautify(_code, options);
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleSubmit = (event) => {
        event.preventDefault();
    };

    handleButtonClick = () => {
        const { toggleModal } = this.props;
        toggleModal();
    };

    handleEscape = () => {
        this.handleButtonClick();
    };

    changeExtension = (buttonName) => {
        const code = this.setCodeByExtension(buttonName);
        this.setState({ selectedExtension: buttonName, code });
    };

    handleDataChange = (event) => {
        this.setState({ dataValue: event.target.value });
    };

    updateCode = () => {
        const {
            actions: { toggleCodeEditorModal, updateCodeData },
            data: { codeEditorCallingAction, currentTestStep },
            editedStepIndex,
            newStepIndex,
        } = this.props;
        const stepIndex = codeEditorCallingAction === 'add' ? newStepIndex : editedStepIndex;
        let { code } = this.state;
        const { dataValue } = this.state;
        // uglifying code
        code = code.replace(/[\n\r]+/g, '');
        code = code.replace(/\s+\s+/g, ' ');
        code = code.replace(/}+/g, '} ');
        code = code.replace(/\s+{+/g, '{');
        code = code.replace(/{+\s+/g, '{');
        code = code.replace(/\s+[.]+/g, '.');
        if (codeEditorCallingAction === 'add') {
            updateCodeData(code, dataValue);
        } else if (codeEditorCallingAction === 'edit') {
            TestStepUtils.handleEditSteps(code, dataValue, stepIndex, currentTestStep);
        }
        toggleCodeEditorModal(false);
    };

    render() {
        const {
            classes,
            actions: { toggleCodeEditorModal, onCloseModal },
            data: { codeEditorCallingAction },
        } = this.props;

        const { code, selectedExtension, dataValue } = this.state;
        return (
            <Dialog disableRestoreFocus open aria-labelledby="form-dialog-title" maxWidth="md" className={classes.dialogModal}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                        Code Editor
                    </DialogTitle>
                    <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                        <Close
                            style={MODAL_HEADER_CLOSE_ICON}
                            onClick={() => {
                                if (codeEditorCallingAction === 'edit') {
                                    TestStepUtils.onCancelEditStep();
                                }
                                toggleCodeEditorModal(false);
                                onCloseModal();
                            }}
                            aria-label="closeIcon"
                            id="closeIcon"
                        />
                    </div>
                </div>
                <DialogTitle style={{ padding: '0 30px' }}>
                    <div className={classes.buttonContainer}>
                        <Button
                            size="small"
                            className={selectedExtension === 'javascript' ? [classes.button, classes.selected].join(' ') : classes.button}
                            onClick={() => {
                                if (selectedExtension !== 'javascript') {
                                    this.changeExtension('javascript');
                                }
                            }}
                            disabled={selectedExtension === 'javascript'}
                        >
                            JS
                        </Button>
                        <Button
                            size="small"
                            className={selectedExtension === 'python' ? [classes.button, classes.selected].join(' ') : classes.button}
                            onClick={() => {
                                if (selectedExtension !== 'python') {
                                    this.changeExtension('python');
                                }
                            }}
                            disabled={selectedExtension === 'python'}
                        >
                            PY
                        </Button>
                        <Button
                            size="small"
                            className={selectedExtension === 'sh' ? [classes.button, classes.selected].join(' ') : classes.button}
                            onClick={() => {
                                if (selectedExtension !== 'sh') {
                                    this.changeExtension('sh');
                                }
                            }}
                            disabled={selectedExtension === 'sh'}
                        >
                            Sh
                        </Button>
                        {/* <Button
                            size="small"
                            className={selectedExtension === 'bash' ? [classes.button, classes.selected].join(' ') : classes.button}
                            onClick={() => {
                                if (selectedExtension !== 'bash') {
                                    this.changeExtension('bash');
                                }
                            }}
                            disabled={selectedExtension === 'bash'}
                        >
                            Bash
                        </Button>
                        <Button
                            size="small"
                            className={selectedExtension === 'api' ? [classes.button, classes.selected].join(' ') : classes.button}
                            onClick={() => {
                                if (selectedExtension !== 'api') {
                                    this.changeExtension('api');
                                }
                            }}
                            disabled={selectedExtension === 'api'}
                        >
                            API
                        </Button> */}
                    </div>
                </DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    <div className={classes.dialogContent}>
                        <AceEditor
                            placeholder="Code"
                            mode={selectedExtension}
                            theme="kuroir"
                            name="editor"
                            // onLoad={this.onLoad}
                            onChange={(_code) => {
                                this.setState({ code: _code });
                            }}
                            fontSize={14}
                            className={classes.editor}
                            showPrintMargin={false}
                            showGutter
                            highlightActiveLine
                            value={code}
                            setOptions={{
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                                useWorker: false,
                                showLineNumbers: true,
                                tabSize: 4,
                            }}
                        />
                    </div>
                    <div style={{ height: 60, padding: '0 30px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className={classes.dataTitle}> Data:</span>
                            <TextField
                                style={{ margin: '0px' }}
                                autoFocus
                                value={dataValue}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes.dataField}
                                id="data"
                                aria-label="Data Value"
                                type="text"
                                onChange={(event) => this.handleDataChange(event)}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={MODAL_FOOTER}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            this.updateCode();
                        }}
                        style={MODAL_SUBMIT_BUTTON}
                        color="secondary"
                        disabled={this.state.isButtonDisabled}
                    >
                        Update
                    </Button>
                    <CloseOnEscape onEscape={this.handleEscape}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (codeEditorCallingAction === 'edit') {
                                    TestStepUtils.onCancelEditStep();
                                }
                                toggleCodeEditorModal(false);
                                onCloseModal();
                            }}
                            style={MODAL_CANCEL_BUTTON}
                            color="secondary"
                            disabled={this.state.isButtonDisabled}
                        >
                            Close
                        </Button>
                    </CloseOnEscape>
                </DialogActions>
            </Dialog>
        );
    }
}

CodeEditorModal.defaultProps = {
    updateCodeData: () => {},
};

const mapStateToProps = (state) => {
    return {
        editedStepIndex: state.selectedTestCaseReducer.editedStepIndex,
        newStepIndex: state.selectedTestCaseReducer.newStepIndex,
    };
};

const mapDispatchToProps = (dispatch) => ({
    toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CodeEditorModal));
/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
