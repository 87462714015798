import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { faUser, faLock, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Hidden } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import { AuthActions } from '../../store/actions';
import { LOGIN_PAGE_CSS, BLACK_FONT } from '../../common/cssConstants';
import config from '../../config';
import './mycss.css';
import { checkArrayLength } from '../../utils/utils';
import Loader from '../Loader';

const { customerDeployment, companyName, logoUrl, logoLarge, website } = config;
const styles = () => ({
    linkForText: {
        textDecoration: 'none',
        color: '#1168CD',
    },
    listItems: {
        display: 'flex',
        flexDirection: 'row',
        padding: '17px 100px 0px 0px',
        color: '#67686a',
        fontSize: '18px',
        lineHeight: '22px',
    },
    contactBtn: {
        borderRadius: '19.5px',
        backgroundColor: '#0092e6',
        color: LOGIN_PAGE_CSS.darkFontColor,
    },
    contact: {
        color: 'inherit',
        fontWeight: '500',
    },
    navigations: {
        color: '#67686a',
        fontWeight: '500',
        fontSize: 16,
    },
    arrowIcon: {
        color: 'inherit',
        marginLeft: '-17px',
        marginRight: '0px',
    },
    raisedBtn: {
        width: '100%',
        height: LOGIN_PAGE_CSS.submitButtonHeight,
        border: 'none',
        borderRadius: '20px',
        marginTop: '1rem',
        backgroundColor: LOGIN_PAGE_CSS.submitButtonBackgroundColor,
        color: LOGIN_PAGE_CSS.submitButtonColor,
        lineHeight: '22px',
        '&:hover': {
            backgroundColor: '#3B91DF',
        },
    },
    forgotPasswordTxt: {
        color: '#979797',
        cursor: 'pointer',
        fontSize: LOGIN_PAGE_CSS.normalFontSize,
        paddingTop: LOGIN_PAGE_CSS.normalFontSize,
    },
    checkBoxRoot: {
        '&>span>svg': {
            fontSize: '22px',
        },
    },
    rememberMeLabel: {
        color: '#979797',
        fontSize: LOGIN_PAGE_CSS.normalFontSize,
        marginLeft: '-7px',
    },
    loginDisabled: {
        color: LOGIN_PAGE_CSS.submitButtonDisableColor,
        backgroundColor: LOGIN_PAGE_CSS.submitButtonDisableBackgroundColor,
    },
    colorError: {
        color: LOGIN_PAGE_CSS.errorFontColor,
        display: 'flex',
        justifyContent: 'center',
        margin: '5px 0px 5px 0px',
        fontWeight: 600,
        fontSize: '12px',
    },
    formWrapperWithError: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ff4c54',
        borderRadius: '2px',
        height: '26px',
        width: '100%',
    },
    formInput: {
        width: '100%',
        border: 'none',
        maxWidth: '100%',
        paddingTop: '4px',
        paddingBottom: '4px',
        '&>div>input': {
            '-webkit-box-shadow': '0 0 0px 1000px #ffffff inset !important',
        },
    },
    fullList: {
        width: 'auto',
        padding: 20,
    },
    bars: {
        color: '#0092e6',
        fontSize: '25px',
        marginRight: '25px',
        marginTop: '15px',
    },
    root: {
        maxHeight: '100vh',
        height: '100vh',
        overflowY: 'auto',
    },
    navigation: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    whiteCard: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    whiteCardLeft: {
        display: 'flex',
        width: '65%',
        maxWidth: '900px',
        height: '500px',
        backgroundColor: '#fff',
        borderRadius: 50,
        margin: 50,
        boxShadow: '0px 2px 13px -4px',
    },
    imageContainer: {
        width: '60%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        borderBottomLeftRadius: '50px',
        borderTopLeftRadius: '50px',
    },
    autonomiqLogo: {
        paddingTop: '25px',
        width: '45%',
        height: 'auto',
        position: 'absolute',
        left: '50px',
        top: '215px',
    },
    aiQ: {
        width: 'auto',
        height: '700px',
        position: 'absolute',
        top: '-46px',
        left: '-165px',
    },
    otherLogo: {
        width: '72%',
        height: 'auto',
    },
    whiteCardRight: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    formArea: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textField: {
        backgroundColor: 'white',
        width: '100%',
        height: LOGIN_PAGE_CSS.textFieldHeight,
    },
    formGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    loginWith: {
        margin: '25px 0px 5px 0px',
        fontSize: 14,
        color: '#979797',
    },
    buttonsArea: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '70%',
    },
    loginButtons: {
        boxShadow: '0px 1px 3.5px -1px grey',
        width: '30%',
        height: '40px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        cursor: 'pointer',
    },
    platform: {
        fontSize: 12,
        marginRight: 10,
        color: BLACK_FONT,
    },
    opacity: {
        opacity: '0.2',
    },
    inputDetail: {
        marginLeft: '10px',
        marginRight: '11px',
        color: '#BAC4CE',
    },
});

class Download extends React.Component {
    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
            // rememberMe: false,
            isFocus: false,
            // isValidate: false,
            openNav: false,
            // isPassword: false,
            isLoading: false,
            startDownloading: false,
            isError: '',
        };
    }

    componentDidMount() {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : false;
        if (token) {
            this.updateState({
                isLoading: true,
            });
            this.getDownloadAiqReport();
        }
    }

    componentWillUnmount() {
        this.props.clearAuthError();
        this.props.clearAuthSuccess();
    }

    onKeyHandler = (event) => {
        if (event.charCode === 13) {
            this.LogIn();
        }
    };

    getDownloadFile = async (minioLink, isArrayBuffer = false) => {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : false;
        let options;
        if (token) {
            options = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
        } else {
            options = {
                auth: {
                    username: this.state.username,
                    password: this.state.password,
                },
            };
        }

        if (isArrayBuffer) {
            options.responseType = 'arraybuffer';
        }
        this.setState({ startDownloading: true });
        const response = await axios.get(minioLink, options);
        return response;
    };

    getDownloadAiqReport = async (reportName = 'Download_Report.html', showErrorIfFail = false) => {
        try {
            
            let urlParams = new URLSearchParams(this.props.location.search);
            let executionId = urlParams.get('testexecution_id');
            let testcaseId = urlParams.get('testcase_id');

            return await this.getDownloadFile(`${config.api.endpoint}/v1/testacases/${testcaseId}/testexecutions/${executionId}/downloadreport`).then(
                async (response) => {
                    const resFileName = `${response.headers['content-disposition']}`.match(/filename=[\w]+[.](\w+)*/);
                    const repFileName = `${reportName}`.match(/[\w]+[.](\w+)*/);
                    let ext = '.html';
                    if (checkArrayLength(repFileName) && repFileName[1]) {
                        ext = '';
                    } else if (checkArrayLength(resFileName) && resFileName[1]) {
                        ext = `.${resFileName[1]}`;
                    }
                    const newBlob = new Blob([response.data], { type: 'text/html' });
                    if (response.data.includes('<Error>') && showErrorIfFail) {
                        // store.dispatch(ModalActions.toggleSnackBar('Report not available'))
                        return;
                    }
                    // IE doesn't allow using a blob object directly as link href
                    // instead it is necessary to use msSaveOrOpenBlob
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob);
                        return;
                    }

                    // For other browsers:
                    // Create a link pointing to the ObjectURL containing the blob.
                    const data = window.URL.createObjectURL(newBlob);
                    const link = document.createElement('a');
                    link.href = data;
                    link.download = reportName + ext;
                    link.target = '_top';
                    link.click();
                    window.location.href = data;
                    setTimeout(() => {
                        window.URL.revokeObjectURL(data);
                    }, 100);
                    this.setState({ isLoading: false, startDownloading: false });
                },
            );
        } catch (error) {
            if (error.response) {
                this.setState({ isLoading: false, startDownloading: false, isError: error.response.data });
            }
            console.error('DownloadAiqReport:', error);
            return null;
        }
    };

    toggleDrawer = (open) => () => {
        this.setState({
            openNav: open,
        });
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleEmail = (event) => {
        const { value } = event.currentTarget;
        this.setState({ username: value.trim(), isFocus: true }, () => {
            if (this.props.error) {
                this.props.clearAuthError();
            }
        });
    };

    handlePassword = (event) => {
        const { value } = event.currentTarget;
        this.setState({ password: value.trim(), isFocus: true });
    };

    checkForValid = () => {
        // In functional components we can`t get element values by ref so
        // using getElementById here
        // check user focused on input then check from state
        const { username, password, isFocus } = this.state;
        if (isFocus) {
            return !(username && password);
        }
        return !(document.getElementById('username') && document.getElementById('password'));
    };

    render() {
        const { classes } = this.props;
        const { username, password, isLoading, startDownloading, isError } = this.state;
        const navList = [
            <ListItem key={1} button component="a" href={website} target="_blank">
                <ListItemText primary={<Typography className={classes.navigations}>Home</Typography>} />
            </ListItem>,
            <ListItem key={2} component="a" href={website} target="_blank" button>
                <ListItemText primary={<Typography className={classes.navigations}>Products</Typography>} />
            </ListItem>,
            <ListItem key={3} component="a" href={website} target="_blank" button>
                <ListItemText primary={<Typography className={classes.navigations}>Features</Typography>} />
            </ListItem>,
            <ListItem key={4} component="a" href={website} target="_blank" button>
                <ListItemText primary={<Typography className={classes.navigations}>Resources</Typography>} />
            </ListItem>,
            <ListItem key={5} button component="a" href={`${website}/team/`} target="_blank">
                <ListItemText primary={<Typography className={classes.navigations}>About</Typography>} />
            </ListItem>,
            <ListItem
                key={6}
                button
                className={`${classes.contactBtn} cntctButton`}
                component="a"
                href={`${website}#autonomiq-signup-form`}
                target="_blank"
            >
                <ListItemText
                    disableTypography
                    primary={
                        <Typography type="body2" className={classes.contact}>
                            Contact&nbsp;Us
                        </Typography>
                    }
                />
                <ListItemIcon className={classes.arrowIcon}>
                    <ArrowRight aria-label="arrowRightIcon" id="arrowRightIcon" />
                </ListItemIcon>
                <ListItemIcon className={classes.arrowIcon}>
                    <ArrowRight aria-label="arrowRightIcon" id="arrowRightIcon" />
                </ListItemIcon>
            </ListItem>,
        ];

        const sideList = (
            <div className={classes.fullList}>
                {companyName === 'AutonomIQ' && (
                    <List>
                        {navList.map((item, index) => {
                            if (index < navList.length - 1) {
                                return item;
                            }
                            return null;
                        })}
                    </List>
                )}
                <Divider />
                <List>{navList[navList.length - 1]}</List>
            </div>
        );

        const usernamePasswordErrorStyle = isError ? { padding: 5, textAlign: 'center', height: 'auto' } : null;

        return isLoading || startDownloading ? (
            <Loader loadingText="Getting downloadFile" />
        ) : (
            <div className={classes.root}>
                {customerDeployment === 'false' && (
                    <div className={classes.navigation}>
                        <Hidden only={['sm', 'xs']}>
                            {companyName === 'AutonomIQ' ? <List className={classes.listItems}>{navList.map((item) => item)}</List> : null}
                        </Hidden>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <div className={classes.navigation}>
                                <FontAwesomeIcon
                                    icon={faBars}
                                    aria-label="faBars"
                                    id="faBars"
                                    className={classes.bars}
                                    onClick={this.toggleDrawer(true)}
                                />
                                <SwipeableDrawer
                                    anchor="right"
                                    open={this.state.openNav}
                                    onClose={this.toggleDrawer(false)}
                                    onOpen={this.toggleDrawer(true)}
                                    disableSwipeToOpen
                                >
                                    <div tabIndex={0} role="button" onClick={this.toggleDrawer(false)} onKeyDown={this.toggleDrawer(false)}>
                                        {companyName === 'AutonomIQ' ? sideList : null}
                                    </div>
                                </SwipeableDrawer>
                            </div>
                        </Hidden>
                    </div>
                )}
                <div className={classes.whiteCard}>
                    <div className={classes.whiteCardLeft}>
                        <div className={classes.imageContainer}>
                            {companyName.toLocaleLowerCase() === 'autonomiq' ? (
                                <div>
                                    <img alt="" src={logoLarge} className={classes.aiQ} />
                                    <img alt="" src={logoUrl} className={classes.autonomiqLogo} />
                                </div>
                            ) : (
                                <img alt="" src={logoUrl} className={classes.otherLogo} />
                            )}
                        </div>
                        <div className={classes.whiteCardRight}>
                            <form
                                noValidate
                                className={classes.form}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if (!this.checkForValid()) {
                                        this.getDownloadAiqReport();
                                    }
                                }}
                            >
                                <div className={classes.formArea}>
                                    <div className={classes.textField}>
                                        <TextField
                                            autoFocus
                                            name="username"
                                            id="username"
                                            aria-label="User Name"
                                            placeholder="Username"
                                            onChange={this.handleEmail}
                                            onKeyPress={this.onKeyHandler}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" className={classes.inputDetail}>
                                                        <FontAwesomeIcon icon={faUser} aria-label="faUser" id="faUser" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            className={classes.formInput}
                                            value={username}
                                        />
                                    </div>
                                    <div className={classes.textField} style={{ display: 'unset' }}>
                                        <TextField
                                            id="pwd"
                                            aria-label="Password"
                                            name="password"
                                            placeholder="Password"
                                            onChange={this.handlePassword}
                                            onKeyPress={this.onKeyHandler}
                                            type="password"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" className={classes.inputDetail}>
                                                        <FontAwesomeIcon icon={faLock} aria-label="gaLock" id="faLock" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            className={classes.formInput}
                                            error={Boolean(isError)}
                                            value={password}
                                        />
                                    </div>
                                    {isError && (
                                        <div className={classes.formWrapperWithError} style={usernamePasswordErrorStyle}>
                                            {isError && <p className={classes.colorError}>{isError}</p>}
                                        </div>
                                    )}
                                    <Button
                                        type="submit"
                                        className={classes.raisedBtn}
                                        color="primary"
                                        disabled={this.checkForValid()}
                                        classes={{
                                            disabled: classes.loginDisabled,
                                        }}
                                    >
                                        Download File
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Download.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
    return {
        state,
        error: state.authReducer.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearAuthError: () => dispatch(AuthActions.clearAuthError()),
        clearAuthSuccess: () => dispatch(AuthActions.clearAuthSuccess()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Download));
