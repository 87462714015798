import React from 'react';

import { Typography } from '@material-ui/core';

import { IconButton } from '@material-ui/core';
import { getFormatedDate, getRelativeTime, checkKeyInObject } from '../../../utils/utils';
import { TABLES_CSS } from '../../../common/cssConstants';

import TableRow from '../../../components/Table/TableRow';
import TableCell from '../../../components/Table/TableCell';
import Delete from '@material-ui/icons/Delete';
import Download from 'mdi-material-ui/Download';
import Clone from '@material-ui/icons/FileCopySharp';
import Tooltip from '../../../components/Tooltip';

import { CustomDownloadModal } from '../../../components/CustomModal/CustomDownloadModal';

const FlowRow = (props) => {

    const {
        classes,
        headers,
        user,
        selectedBlocks,
        index,
        block,
        cloneFlow,
        viewFlow,
        deleteFlow,
        disableDownloadFlow,
        handleDownloadPopupToggle,
        handleDownloadBlock,
        projectName,
        isHovered,
        setHovered
    } = props;
    const dataSet = handleDownloadPopupToggle();
    const selectionClass = Boolean(selectedBlocks.includes(block.name)) ? classes.backgroundWhite : null;

    return (
        <TableRow
            onMouseEnter={() => setHovered(index)}
            onMouseLeave={() => setHovered(null)}
            isTableRowStyle={true}
            focusCustom={classes.focused}
            className={[classes.tableRow, selectionClass].join(' ')}
            hover
            key={index}
            tabIndex={-1}
        >
            {/****<TableCell
                width={headers[0].width}
                nowrap
                data-resize={`element${0}_c`}
                className={[classes.borders, classes.cell, classes.noWrap, classes.checkBoxContainer, selectionClass].join(' ')}
            >
                <Checkbox
                    onClick={(e) => {
                        handleCheckUncheck(e.target.checked, block.name);
                    }}
                    checked={Boolean(selectedBlocks.includes(block.name))}
                    style={{
                        color: Boolean(selectedBlocks.includes(block.name)) ? '#3b91df' : '#bac4ce',
                    }}
                />
            </TableCell>
            <TableCell width={headers[1].width} nowrap data-resize={`element${1}_c`} />***/}
            <TableCell
                className={[classes.borders, classes.noPadding, classes.cell, classes.noWrap, classes.minWidth, selectionClass].join(' ')}
                width={headers[0].width}
                isTableCellClass={true} 
                data-resize={`element${0}_c`}
            >
                <div style={{ paddingLeft: '15px', width: '100%' }}>
                    <Typography onClick={() => viewFlow(block)} noWrap  className={classes.flowName}>
                        <Tooltip data={` ${block.name}`}>
                            {block.name}
                        </Tooltip>
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                className={[classes.borders, classes.noPadding, classes.cell, classes.noWrap, classes.minWidth, selectionClass].join(' ')}
                width={headers[1].width}
                isTableCellClass={true} 
                data-resize={`element${1}_c`}
            >
                <div style={{ width: '100%' , paddingLeft: '20px' }}>
                    <Typography noWrap className={classes.timCreated} >
                        {checkKeyInObject(user, 'isRelativeDateFormat')
                            ? getRelativeTime(block.creationTime)
                            : getFormatedDate(block.creationTime, 'MMM D, YYYY', '')}
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                className={[classes.borders, classes.noPadding, classes.cell, classes.noWrap, classes.minWidth, selectionClass].join(' ')}
                width={headers[2].width}
                isTableCellClass={true} 
                data-resize={`element${2}_c`}
            >
                <div style={{ paddingLeft: '15px', width: '100%' }}>
                    <Typography noWrap className={classes.oval} style={{ fontSize: 12, color: TABLES_CSS.normalFontColor }}>
                        {block.steps?.length || 0}
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                className={[classes.borders, classes.noPaddingBoth, classes.IconCell, classes.noWrap, classes.minWidth, selectionClass].join(' ')}
                width={headers[3].width}
                isTableCellClass={true} 
                data-resize={`element${3}_c`}
            >
                <div className={classes.iconContainer}>
                    {isHovered === index || Boolean(selectedBlocks.includes(block.name)) ? (
                        <Tooltip data= "Copy flow">
                            <IconButton className={classes.IconButton} onClick={() => cloneFlow(block)}>
                                <Clone className={classes.moreIcons} aria-label="cloneIcon" id="cloneIcon" />
                            </IconButton>
                        </Tooltip>
                    )
                :
                null
                }
                </div>
            </TableCell>
            <TableCell
                className={[classes.borders, classes.noPadding, classes.IconCell, classes.noWrap, classes.minWidth, selectionClass].join(' ')}
                width={headers[4].width}
                isTableCellClass={true} 
                data-resize={`element${4}_c`}
            >
                <div className={classes.iconContainer}>
                    {isHovered === index || Boolean(selectedBlocks.includes(block.name)) ? (
                        <Tooltip data= "Delete flow">
                            <IconButton className={classes.IconButton} onClick={() => deleteFlow(block)}>
                                <Delete className={classes.moreIcons} aria-label="Delete" id="Delete" />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </div>
            </TableCell>
            <TableCell
                className={[classes.borders, classes.noPadding, classes.IconCell, classes.noWrap, classes.minWidth, selectionClass].join(' ')}
                width={headers[5].width}
                isTableCellClass={true} 
                data-resize={`element${5}_c`}
            >
                <div className={classes.iconContainer}>
                    {isHovered === index || Boolean(selectedBlocks.includes(block.name)) ? (
                        <CustomDownloadModal
                            key="DownloadFlow"
                            handleSubmit={(downloadType, projectFile) => {
                                handleDownloadBlock(downloadType, projectFile, [block]);
                            }}
                            selectedFlow={[block]}
                            dataSet={dataSet}
                            keepMounted
                            calledFrom="flow"
                            projectName={projectName}
                            containerStyle={{ height: 'unset' }}
                            btn={
                                <Tooltip data="Download Flow">
                                    <IconButton className={classes.childIconButton} disabled={disableDownloadFlow} id="downloadFlow" aria-label="Download">
                                        <Download className={classes.moreIcons} aria-label="Download" id="Download" />
                                    </IconButton>
                                </Tooltip>
                            }
                        />
                    )
                :
                null
                }
                </div>
            </TableCell>
        </TableRow>
    );
};

export default FlowRow;
