import { ActionTypes } from '../constants';
import AuthMiddleWare from '../middleware/authMiddleware';
import CookieStorage from '../../common/cookieStorage';
import WSService from '../../services/WSService';
import { googleAnalytics, checkKeyInObject } from '../../utils/utils';
import config from '../../config';
import { identify , track } from '../../services/Segment';
import { SEGMENT_EVENT } from '../../common/constants';
import { ModalActions } from '.';
import store from '../../store'
export default class AuthActions {
    static onRegister(obj) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.SIGNUP });
            AuthMiddleWare.register(obj)
                .then((data) =>
                    dispatch({
                        type: ActionTypes.SIGNUP_SUCCESS,
                        payload: {
                            data,
                            message: 'A confirmation link has been sent to your email.',
                        },
                    }),
                )
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.SIGNUP_FAILURE,
                        payload: {
                            error,
                            message: 'Something went wrong! Please Try Again.',
                        },
                    }),
                );
        };
    }

    static sendconfirmation(query, callBack) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.CONFIRM });
            AuthMiddleWare.sendconfirmation(query)
                .then(() => {
                    callBack(true);
                    return dispatch({
                        type: ActionTypes.CONFIRM_SUCCESS,
                    });
                })
                .catch((error) => {
                    let errorMessage = 'Something went wrong';
                    if (error && error.response && error.response.data && error.response.data.error) {
                        errorMessage = error.response.data.error;
                    }
                    dispatch({
                        type: ActionTypes.CONFIRM_FAILURE,
                        payload: {
                            error: errorMessage,
                        },
                    });
                });
        };
    }

    /* eslint-disable no-param-reassign */
    static authenticate({ username, password, check }) {
        return async (dispatch) => {
            dispatch({
                type: ActionTypes.SIGNIN,
            });
            if (!(username && password)) {
                dispatch({
                    type: ActionTypes.SET_AUTH_ERROR,
                    payload: 'no arguments',
                });
            } else if (!AuthMiddleWare) {
                // self.state = 'error';
            } else {
                try {
                    const result = await AuthMiddleWare.authenticate({
                        username,
                        password,
                    });
                    if (result.status === 204) {
                        googleAnalytics('send', 'event', 'Login', 'Login', 'Fail');
                        throw new Error('AuthenticationUser not found!');
                    } else if (result.status === 500) {
                        googleAnalytics('send', 'event', 'Login', 'Login', 'Fail');
                        throw new Error('Failed to login, Internal server error!');
                    } else if (result.status === 200) {
                        const userData = result.data;
                        const existingId = localStorage.getItem('pluginId');
                        localStorage.setItem('token', userData.token);

                        // Clear local installation of recorder if there is no any existing plugin id in localStorage
                        // and set the default one after authentication
                        if (!existingId) localStorage.setItem('pluginId', config.pluginId);

                        CookieStorage.setSimple('X-Authorization', userData.token);
                        CookieStorage.setSimple('accountId', userData.userAccount);
                        // CookieStorage.setSimple('user-name',userData.name);
                        CookieStorage.set('aut_remember', {
                            accountId: userData.userAccount,
                            email: userData.email,
                            userId: `${userData.userId}`,
                            name: userData.name,
                            role: userData.role,
                            id: `${userData.userId}`,
                            token: userData.token,
                            familyName: userData?.family_name || '',
                            givenName: userData?.given_name || '',
                            userAccount: userData.userAccount,
                            remember: check,
                            isRelativeDateFormat: userData.isRelativeDateFormat,
                        });
                        WSService.initializeWebSocket(userData.userAccount);
                        const user = {
                            accountId: userData.userAccount,
                            email: userData.email,
                            userId: `${userData.userId}`,
                            name: userData.name,
                            role: userData.role,
                            id: `${userData.userId}`,
                            token: userData.token,
                            familyName: userData?.family_name || '',
                            givenName: userData?.given_name || '',
                            userAccount: userData.userAccount,
                            isRelativeDateFormat: userData.isRelativeDateFormat,
                        };
                        const localStorageData = {
                            tickerIndicatorValue: localStorage.getItem('StickyHeader'),
                        };

                        dispatch({
                            type: ActionTypes.SIGNIN_SUCCESS,
                            payload: { data: user },
                        });
                        dispatch({
                            type: ActionTypes.DASHBOARD_TICKER_TIMEINDICATOR,
                            payload: {
                                tickerIndicatorValue: checkKeyInObject(localStorageData, 'tickerIndicatorValue')
                                    ? localStorageData.tickerIndicatorValue
                                    : 'Week',
                            },
                        });
                        googleAnalytics('send', 'event', 'Login', 'Login', 'Success');
                        // self.isLoggedin = true;

                        //Segment Identify
                        const _userId =  `${userData.userId}`;
                        const hostName = window?.location?.hostname;

                        identify(_userId,{

                            hostName:hostName || '',
                            accountId: userData.userAccount,
                            email: userData.email,
                            name: userData.name,
                            role: userData.role,

                        });

                        const eventName  = SEGMENT_EVENT.LOGIN;

                        track(eventName,{
                            status:"Success",
                            account_id: userData.userAccount,
                            email: userData.email,
                            user_id: `${userData.userId}`,
                            user_name: userData.name,
                            role: userData.role
                        })
                    }
                } catch (error) {
                    let message = '';

                    if (checkKeyInObject(error, 'message', 'bool')) {
                        message = error.message;
                    }
                    if (checkKeyInObject(error, 'response.status') === 401) {
                        message = error?.response?.data 
                    }
                    if(checkKeyInObject(error, 'response.status') === 403){
                        message = error?.response?.data
                    }

                    dispatch({
                        type: ActionTypes.SET_AUTH_ERROR,
                        payload: message || 'Error while login, try later, please report if issue persist.',
                    });
                    // ... including try/catch error handling
                    // self.state = 'error';
                    // self.error = 'Unable to login!';

                    googleAnalytics('send', 'event', 'Login', 'Login', 'Fail');

                    


                    // for testing
                    console.error(error.toString()); // tslint:disable-line no-console
                }
            }

            // return self.state === 'authenticated';
        };
    }

    static getAuthDetails(user) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_AUTH_DETAILS });
            return AuthMiddleWare.getAuthDetails(user)
                .then((result) => {
                    dispatch({
                        type: ActionTypes.GET_AUTH_DETAILS_SUCCESS,
                        payload: result.data,
                    });
                    if (result.data && result.data.authType === 'saml') {
                        window.location.assign(
                            `${config.api.endpoint}/v1/accounts/${result.data.accountId}/${result.data.accountId}/auth/saml?location=`,
                        );
                    }
                    return result
                })
                .catch(() => {
                    dispatch({
                        type: ActionTypes.GET_AUTH_DETAILS_FAILURE,
                        payload: {
                            message: 'User not found.',
                        },
                    });
                });
        };
    }

    /* eslint-disable no-param-reassign */
    static authenticateWithGmail(result) {
        return async (dispatch) => {
            const userData = result.data;
            localStorage.setItem('token', userData.token);
            CookieStorage.setSimple('X-Authorization', userData.token);
            CookieStorage.set('aut_remember', {
                accountId: userData.userAccount,
                email: userData.email,
                userId: `${userData.userId}`,
                name: userData.name,
                role: userData.role,
                id: `${userData.userId}`,
                token: userData.token,
                userAccount: userData.userAccount,
                isRelativeDateFormat: userData.isRelativeDateFormat,
            });
            WSService.initializeWebSocket(userData.userAccount);
            const user = {
                accountId: userData.userAccount,
                email: userData.email,
                userId: `${userData.userId}`,
                name: userData.name,
                role: userData.role,
                id: `${userData.userId}`,
                token: userData.token,
                userAccount: userData.userAccount,
                isRelativeDateFormat: userData.isRelativeDateFormat,
            };
            dispatch({
                type: ActionTypes.SIGNIN_SUCCESS,
                payload: { data: user },
            });
        };
    }

    static authenticateEmail(email) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.FORGOT_PASSWORD });
            AuthMiddleWare.authenticateEmail(email)
                .then(() =>
                    dispatch({
                        type: ActionTypes.FORGOT_PASSWORD_SUCCESS,
                        payload: 'Email has been send successfully , Please check your email.',
                    }),
                )
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.FORGOT_PASSWORD_FAILURE,
                        payload: {
                            error,
                            message: 'user not found',
                        },
                    }),
                );
        };
    }

    static resetPasswordMail(email) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.ADMIN_RESET_PASSWORD_EMAIL });
            return AuthMiddleWare.resetPasswordMail(email)
                .then(() => {
                    dispatch({
                        type: ActionTypes.ADMIN_RESET_PASSWORD_EMAIL_SUCCESS,
                        payload: 'Email has been send successfully , Please check your email.',
                    });
                    return { status: true, message: 'Email has been send successfully , Please check your email.' };
                })
                .catch((error) => {
                    dispatch({
                        type: ActionTypes.ADMIN_RESET_PASSWORD_EMAIL_FAILURE,
                        payload: {
                            error,
                            message: 'unable to send email',
                        },
                    });
                    return { status: false, message: 'Unable to send email, Please try again.' };
                });
        };
    }

    static resetPassword(password, token, id) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.RESET_PASSWORD });
            return AuthMiddleWare.resetPassword(password, token, id)
                .then((data) => {
                    dispatch({
                        type: ActionTypes.RESET_PASSWORD_SUCCESS,
                        payload: data,
                    })
                    return data

                }

                )
                .catch((error) => {

                    dispatch({
                        type: ActionTypes.RESET_PASSWORD_FAILURE,
                        payload: {
                            error,
                            message: 'Failed to reset your password. Contact your administrator',
                        },
                    })
                    return error

                }
                    
                );
        };
    }

    static changePassword(data, cb) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.CHANGE_PASSWORD });
            AuthMiddleWare.changePassword(data)
                .then(() => {
                    cb({ msg: 'Password changed successfully', success: true });
                })
                .catch(() => {
                    cb({ msg: 'Failed to change your password. Contact your administrator', success: false });
                });
        };
    }

    static signupSuccess(data) {
        return {
            type: ActionTypes.SIGNUP_SUCCESS,
            data,
        };
    }

    static signupFailure(error) {
        return {
            type: ActionTypes.SIGNUP_FAILURE,
            error,
        };
    }

    static signin(user) {
        return {
            type: ActionTypes.SIGNIN,
            payload: user,
        };
    }

    static signinSuccess(error, data) {
        return {
            type: ActionTypes.SIGNIN_SUCCESS,
            payload: data,
        };
    }

    static signinFailure(error) {
        return {
            type: ActionTypes.SIGNIN_FAILURE,
            error,
        };
    }

    static logout() {
        CookieStorage.remove('aut_remember');
        localStorage.removeItem('token');
        CookieStorage.remove('X-Authorization');
        CookieStorage.remove('token');
        CookieStorage.remove('accountId');
        CookieStorage.remove('auth-type');
        localStorage.removeItem('executionEngine')
        localStorage.removeItem('windowsBrowser');
        localStorage.removeItem('accountPermission');
        WSService.closeWebSocketInstance(1000);
        store.dispatch(ModalActions.clearSnackbar())
        // window.location.assign('/dashboard'); // this will reset all the reducers because it refresh the browser page
        return {
            type: ActionTypes.LOGOUT_SUCCESS,
        };
    }

    static logoutFailure(error) {
        return {
            type: ActionTypes.LOGOUT_FAILURE,
            error,
        };
    }

    static clearAuthError() {
        return {
            type: ActionTypes.CLEAR_ERROR,
        };
    }

    static clearAuthSuccess() {
        return {
            type: ActionTypes.CLEAR_SUCCESS,
        };
    }

    static updateIsRelativeDate(isRelativeDateFormat) {
        return {
            type: ActionTypes.UPDATE_IS_RELATIVE_DATE,
            payload: { isRelativeDateFormat },
        };
    }
}
