// material-ui component
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Grid, Typography, TextField, Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import jwt_decode from 'jwt-decode';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalActions, AuthActions, UserActions } from '../../store/actions';
import config from '../../config';
import { MODAL_DELETE_BUTTON, MODAL_CANCEL_BUTTON, MODAL_SUBMIT_BUTTON, BLACK_FONT } from '../../common/cssConstants';
import { USER_NAME } from '../../common/constants';
import { PASSWORD_REGEX } from '../../common/regexConstants';

const { companyName } = config;
const styles = (theme) => ({
    input: {
        border: '1px solid #8898A8',
        borderRadius: '2px',
        backgroundColor: '#FFFFFF',
        height: '25px',
        '&>div>input': {
            padding: '2px 0px 2px 5px',
        },
    },
    subHeading: {
        minWidth: '79px',
        textAlign: 'right',
        marginRight: '10px',
        color: BLACK_FONT,
        fontSize: 12,
        fontWeight: '500',
    },
    noBoxShadow: {
        backgroundColor: 'transparent',
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
    },
    button: {
        borderRadius: '2px',
        // minHeight: 0,
        minWidth: '75px',
        padding: '2px 8px',
        textTransform: 'capitalize',
    },
    buttonContainer: {
        margin: '0px 9px 11px 0px',
        padding: '10px 0px 0px 0px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonReset: {
        borderRadius: '3px',
        minWidth: '144px',
        padding: '2px 8px',
        textTransform: 'capitalize',
        marginLeft: '128px',
        [theme.breakpoints.only('xs')]: {
            marginLeft: '71px',
            marginTop: '15px',
        },
    },
    buttonSave: {
        background: '#0092e6',
        color: '#fff',
        width: '100px',
        height: '50px',
    },
    buttonCancel: {
        background: 'white',
        color: '#000',
        width: '100px',
        height: '50px',
        marginRight: '10px',
    },
    expensionContent: {
        margin: '0 !important',
        cursor: 'default',
        paddingBottom: '10px',
    },
    noBottomPadding: {
        paddingBottom: '0px',
    },
    expensionsummary: {
        height: 'auto !important',
        minHeight: 'auto !important',
        paddingLeft: '0px',
        // borderBottom: '1px solid #BAC4CE'
    },
    expensionDetail: {
        padding: '0 20px',
        display: 'block',
    },
    heading: {
        paddingLeft: 5,
        fontWeight: 'bold',
        color: '#494949',
        fontSize: 12,
    },
    fonts: {
        fontSize: 12,
    },
    mainWrapper: {
        paddingTop: 20,
        flexDirection: 'row',
        display: 'flex',
        [theme.breakpoints.only('xs')]: {
            padding: ' 20px 10px 20px 10px',
        },
    },
    message: {
        float: 'left',
        fontSize: 12,
        color: BLACK_FONT,
    },
    deleteButtonDanger: MODAL_DELETE_BUTTON,
});

class AdminSecurity extends React.Component {
    constructor() {
        super();
        this.state = {
            expanded: null,
            // autonomiq: 'AutonomIQ',
            deleting: 'Disable your profile',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            tokenDetail: {},
            isDisabled: false,
        };
    }

    componentDidMount() {
        const token = localStorage.getItem('token');
        const tokenDetail = jwt_decode(token);
        this.updateState({ tokenDetail });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { authenticateEmail } = this.props;
        authenticateEmail(this.props.user.email);
        this.props.showSnackBar('Confirmation email sent.', '', true);
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    updatePassword = () => {
        const { currentPassword, newPassword, confirmPassword, tokenDetail } = this.state;

        const toggleDisable = () => {
            this.setState({ isDisabled: false });
        };

        if (!currentPassword || !newPassword) {
            this.props.showSnackBar('Please enter passwords.', '', false, 8000);
            toggleDisable();
        } else if (!PASSWORD_REGEX.test(newPassword)){
            this.props.showSnackBar("Password should contain atleast 8 characters, 1 special, 1 small and upper case character, 1 numeric character", '', false, 8000);
            toggleDisable();
        } else if (currentPassword === newPassword) {
            this.props.showSnackBar('Current and new password are same.', '', false, 8000);
            toggleDisable();
        } else if (newPassword !== confirmPassword) {
            this.props.showSnackBar("Password and confirm password doesn't match.", '', false, 8000);
            toggleDisable();
        } 
        else {
            this.props.changeUserPassword(
                {
                    old_password: currentPassword,
                    new_password: newPassword,
                    id: `${tokenDetail.aiqUserId}`,
                },
                (result) => {
                    if (result.success) {
                        this.logout()
                        this.props.showSnackBar(result.msg, '', true,4000,false,true);
                    } else {
                        this.props.showSnackBar(result.msg, '', false, 8000);
                    }
                    // toggleDisable();
                },
            );
        }
    };

    checkPassword = (e) => {
        this.handleChange(e);
    };

    // handleExpand = (panel) => {
    //     this.setState((prevState) => ({
    //         expanded: prevState.expanded === panel ? false : panel,
    //     }));
    // };

    handleDelete = () => {
        const { toggleModal, user, classes } = this.props;
        toggleModal('GeneralModal', null, null, {
            title: 'Disable Profile',
            closeIconAction: () => {
                this.changeUserStatus(false, user);
            },
            component: [
                {
                    content: (
                        <div
                            style={{
                                width: '100%',
                                padding: '0 30px',
                                minWidth: '300px',
                            }}
                        >
                            <Typography className={classes.message}>Are you sure you want to disable your profile?</Typography>
                        </div>
                    ),
                    buttons: [
                        {
                            name: 'Disable',
                            variant: 'contained',
                            buttonClass: classes.deleteButtonDanger,
                            style: { ...MODAL_SUBMIT_BUTTON },
                            action: () => {
                                this.changeUserStatus(true, user);
                            },
                            isDisabled: false,
                        },
                        {
                            name: 'Cancel',
                            variant: 'outlined',
                            style: { ...MODAL_CANCEL_BUTTON },
                            action: () => {
                                this.changeUserStatus(false, user);
                            },
                            isDisabled: false,
                        },
                    ],
                },
            ],
        });
    };

    logout = () => {
        this.props.logout();
    };

    changeUserStatus = async (flag, user) => {
        if (flag) {
            let changeStatus = false;
            if (user && user.id) {
                changeStatus = await this.props.changeUserStatus(user.id, 1);
            }
            if (changeStatus !== false) {
                this.logout();
                return;
            }
            this.props.showSnackBar('Failed to change user status.');
        }
        this.props.toggleModal();
    };

    render() {
        const { classes, user } = this.props;
        const { expanded, currentPassword, newPassword, confirmPassword, isDisabled } = this.state;
        const pass = [];
        for (let i = 0; i < 6; i++) {
            pass.push(
                <FontAwesomeIcon
                    icon={faCircle}
                    aria-label="faCircle"
                    id="faCircle"
                    key={i}
                    style={{
                        color: '#BAC4CE',
                        fontSize: '10px',
                        marginRight: '3px',
                    }}
                />,
            );
        }
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={false} sm={1} md={2} lg={2} />
                <Grid item xs={12} sm={10} md={7} lg={7}>
                    <div
                        className={classes.mainWrapper}
                        style={{
                            borderBottom: expanded === 'Password' ? 'none' : '1px solid #BAC4CE',
                            backgroundColor: expanded === 'Password' ? 'white' : '',
                        }}
                    >
                        <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <Typography
                                variant="subtitle1"
                                gutterBottom
                                className={classes.heading}
                                style={{
                                    margin: 0,
                                    paddingBottom: '0.45em',
                                    borderBottom: expanded === 'Password' ? '1px solid #BAC4CE' : 'none',
                                    textIndent: 19,
                                    width: '100%',
                                }}
                            >
                                Password Reset
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={9} lg={9}>
                            <Accordion
                                // expanded={expanded === 'Password'}
                                /* onChange={this.handleExpand('Company Name')} */ className={classes.noBoxShadow}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    className={classes.expensionsummary}
                                    classes={{ content: [classes.expensionContent, classes.noBottomPadding].join(' ') }}
                                >
                                    <Typography
                                        style={{
                                            color: '#494949',
                                            fontWeight: 'bold',
                                            margin: 0,
                                            borderBottom: 'none',
                                            paddingBottom: '0.45em',
                                            fontSize: 12,
                                            marginTop: '1px',
                                            paddingLeft: 10,
                                        }}
                                    >
                                        {pass}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.expensionDetail} style={{ borderTop: '1px solid #BAC4CE', paddingRight: 0 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                        <Typography className={classes.subHeading}>Current</Typography>
                                        <TextField
                                            InputProps={{ disableUnderline: true, autoComplete: 'off' }}
                                            type="password"
                                            name="currentPassword"
                                            value={currentPassword || ''}
                                            onChange={this.handleChange}
                                            className={classes.input}
                                            aria-label="Current Password"
                                            id="currentPassword"
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                        <Typography className={classes.subHeading}>New</Typography>
                                        <TextField
                                            InputProps={{ disableUnderline: true, autoComplete: 'off' }}
                                            type="password"
                                            name="newPassword"
                                            value={newPassword || ''}
                                            onChange={this.checkPassword}
                                            className={classes.input}
                                            aria-label="New Password"
                                            id="newPassword"
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                        <Typography className={classes.subHeading}>Re-type New</Typography>
                                        <TextField
                                            InputProps={{ disableUnderline: true, autoComplete: 'off' }}
                                            type="password"
                                            name="confirmPassword"
                                            value={confirmPassword || ''}
                                            onChange={this.handleChange}
                                            className={classes.input}
                                            aria-label="Confirm Password"
                                            id="confirmPassword"
                                        />
                                    </div>
                                    <div style={{ display: 'block', padding: '10px 0 15px 0' }}>
                                        <Typography
                                            onClick={this.handleSubmit}
                                            style={{
                                                color: '#0092e6',
                                                fontSize: 12,
                                                fontWeight: '500',
                                                cursor: 'pointer',
                                                width:'6.1rem',
                                            }}
                                        >
                                            Forget password?
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={classes.buttonReset}
                                            onClick={(e) => {
                                                this.setState({ isDisabled: true });
                                                this.updatePassword(e);
                                            }}
                                            disabled={isDisabled}
                                        >
                                            Reset Password
                                        </Button>
                                    </div>
                                    {/* <span
                                        onClick={() => this.handleExpand('Password')}
                                        style={{ margin: '-3px 15px 0px 0px', padding: 0, position: 'absolute', right: 0, cursor: 'pointer', top: 0 }}
                                        aria-hidden
                                    >
                                        {expanded !== 'Password' ? (
                                            <ExpandMoreIcon aria-label="ExpandMoreIcon" id="ExpandMoreIcon" />
                                        ) : (
                                            <ExpandLessIcon aria-label="ExpandLessIcon" id="ExpandLessIcon" />
                                        )}
                                    </span> */}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </div>
                    {/* <div style={{ marginLeft: '39px', paddingTop: 20, flexDirection: "row", display: 'flex', borderBottom: expanded === 'Access' ? 'none' : '1px solid #BAC4CE', backgroundColor: expanded === 'Access' ? 'white' : '', }}>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                Access to Multiple Accounts
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                            <Accordion expanded={expanded === 'Access'} className={classes.noBoxShadow}>
                                <AccordionSummary expandIcon={null} className={classes.expensionsummary} classes={{ content: classes.expensionContent }}>
                                    <Typography className={classes.heading}>
                                        {this.state.autonomiq}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.expensionDetail} style={{ borderTop: '1px solid #BAC4CE' }}>
                                    <span style={{ display: 'flex', paddingTop: '25px' }}>
                                        <Typography style={{ marginBottom: '10px' }}>
                                            Content yet to come
                                        </Typography>
                                    </span>
                                    <span onClick={() => this.handleExpand('Access')} style={{ margin: '-3px 15px 0px 0px', padding: 0, position: 'absolute', right: 0, cursor: 'pointer', top: 0, }}>
                                        {expanded !== 'Access' ? <ExpandMoreIcon aria-label="ExpandMoreIcon" id="ExpandMoreIcon" aria-label="ExpandLessIcon" id="ExpandLessIcon" /> : <ExpandLessIcon />}
                                    </span>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </div> */}
                    {user.name && (
                        <div
                            className={classes.mainWrapper}
                            style={{
                                borderBottom: expanded === 'Deletion' ? 'none' : '1px solid #BAC4CE',
                                backgroundColor: expanded === 'Deletion' ? 'white' : '',
                            }}
                        >
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading} style={{ textIndent: 19, width: '100%' }}>
                                    Disable Profile
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={9} lg={9}>
                                <Accordion className={classes.noBoxShadow}>
                                    {expanded !== 'Deletion' ? (
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            className={classes.expensionsummary}
                                            classes={{ content: classes.expensionContent }}
                                        >
                                            <Typography className={classes.heading} style={{ paddingLeft: 10 }}>
                                                {this.state.deleting} 
                                            </Typography>
                                        </AccordionSummary>
                                    ) : (
                                        ' '
                                    )}
                                    <AccordionDetails className={classes.expensionDetail} style={{ padding: '0 10px 10px' }}>
                                        <div style={{ display: 'flex' }}>
                                            <Typography className={classes.fonts} style={{ marginRight: 20 }}>
                                                {`This will disable your account on ${companyName}.`}
                                            </Typography>
                                        </div>
                                        {user.name !== USER_NAME ? 
                                        <Typography
                                            onClick={this.handleDelete}
                                            className={classes.fonts}
                                            style={{ marginRight: 20, color: '#0092e6', cursor: 'pointer' }}
                                        >
                                            Disable your profile
                                        </Typography>
                                        : 
                                        <Typography
                                            className={classes.fonts}
                                            style={{ marginRight: 20, color: '#BAC4CE', pointerEvents: 'none' }}
                                        >
                                            Disable your profile
                                        </Typography> 
                                        }
                                        {/* <span
                                            onClick={() => this.handleExpand('Deletion')}
                                            style={{
                                                margin: '-3px 15px 0px 0px',
                                                padding: 0,
                                                position: 'absolute',
                                                right: 0,
                                                cursor: 'pointer',
                                                top: 0,
                                            }}
                                            aria-hidden
                                        >
                                            {expanded !== 'Deletion' ? (
                                                <ExpandMoreIcon aria-label="ExpandMoreIcon" id="ExpandMoreIcon" />
                                            ) : (
                                                <ExpandLessIcon aria-label="ExpandLessIcon" id="ExpandLessIcon" />
                                            )}
                                        </span> */}
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </div>
                    )}
                </Grid>
                <Grid item xs={false} sm={1} md={3} lg={3} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
        isLoading: state.authReducer.isLoading,
        error: state.authReducer.error,
        emailSendSuccess: state.authReducer.emailSendSuccess,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        changeUserPassword: (data, callBack = () => {}) => dispatch(AuthActions.changePassword(data, callBack)),
        authenticateEmail: (email) => dispatch(AuthActions.authenticateEmail(email)),
        clearAuthError: () => dispatch(AuthActions.clearAuthError()),
        clearAuthSuccess: () => dispatch(AuthActions.clearAuthSuccess()),
        changeUserStatus: (user, status) => dispatch(UserActions.changesUserStatus(user, status)),
        logout: () => dispatch(AuthActions.logout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(AdminSecurity)));
