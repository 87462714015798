import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Video from '../Video/Video';
import Status from '../TasksStatus/Status';
import { getFormatedDate, getParamValues } from '../../utils/utils';
import Tooltip from '../Tooltip';
import Loader from '../Loader';
import { ProjectActions, ExecutionActions } from '../../store/actions';

import CompleteIcon from '../../assets/images/successIcon.png';
import LoadingIcon from '../../assets/images/loadingIcon.png';
import ErrorIcon from '../../assets/images/errorLogoIcon.png';
import singleFileCreationService from '../../services/singleFileCreationService';
import StopIcon from '@material-ui/icons/Stop'

//Devices/Browsers Icons
import firefoxIcon from '../../assets/icons/browsers/firefox.svg';
import chrome_Icon from '../../assets/icons/browsers/chrome.svg';
import ieIcon from '../../assets/icons/browsers/ie.svg';
import safari_Icon from '../../assets/icons/browsers/safari.svg';
import Microsoftedge_Icon from '../../assets/icons/browsers/microsoftedge.svg';

// Platform icons
import windows_Icon from '../../assets/icons/platforms/windows.svg'
import linux_Icon from '../../assets/icons/platforms/linux.svg';
import macos_Icon from '../../assets/icons/platforms/macos.svg';
import android_Icon from '../../assets/icons/platforms/android.svg';
import real_device_Icon from '../../assets/icons/devices/real.svg';
import emulator_device_Icon from '../../assets/icons/devices/emulator.svg';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {THREE_DOT_MENU_CSS} from '../../common/cssConstants'

const styles = () => ({
    container: {
        marginBottom: 3,
        width: 'inherit',
        position: 'relative',
    },
    statusActive: {
        color: '#43cb83',
        marginLeft: 2,
    },
    statusInActive: {
        color: '#da4e35',
        marginLeft: 2,
    },
    statusInProgress: {
        color: '#eac129',
    },
    statusInQue: {
        color: '#eac129',
    },
    iconStyle: {
        fontSize: 15,
        color: '#4A4A4A',
    },
    progressIconStyle: {
        fontSize: 24,
    },
    progressIconSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',

    },
    typographyStyle: {
        fontSize: 12,
        lineHeight: '11px',
        color: '#615e5e',
        marginBottom: 8,
    },
    subHeadngStyle: {
        fontSize: 12,
        color: '#222020',
        lineHeight: '11px',
    },
    trayIcon: {
        fontSize: 14,
        margin: 4,
    },
    enabledIcon: {
        color: '#4A4A4A',
        cursor: 'pointer',
    },
    disabledIcon: {
        color: '#d9d9d9',
        cursor: 'not-allowed',
    },
    checKStatus: {
        width: '18px',
        height: '18px',
        color: '#4fe3c2',
    },
    circularProgressSvg: {
        display: 'inline-block',
    },
    faEyeicon: {
        color: '#4A4A4A',
        fontSize: '16px',
        cursor: 'pointer',
    },
    wrapperCircularProgress: {
        display: 'inline-block',
        overflow: 'hidden',
        height: 20,
        width: 20,
    },
    mainHeadingStyle: {
        color: '#206eb6',
        fontSize: 12,
        lineHeight: '11px',
        marginTop: '10px',
        marginBottom: 8,
        cursor:"pointer",
        textOverflow: "ellipsis",
        maxWidth: "145px",
        display: "inline-block",
        overflow: "hidden",
    },
    browserIcon: {
        marginRight: '10px',
        color: 'black',
        // fontSize: 20,
        marginTop: '-35px',
        height: '18px',
        width: '18px',
    },
    downloadLinkStyle: {
        color: '#206eb6',
        fontSize: '9.5px',
        fontWeight: 'normal',
        fontFamily: 'HelveticaNeue',
        cursor: 'pointer',
        marginRight: 'auto',
        position: 'absolute',
        right: '0%',
        bottom: '17%',
    },
    statusIcon: {
        width: '18px',
        marginLeft:'6px'
    },
    loadingIcon: {
        width: '42.5px',
        height: '13px',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        marginRight: '4px',
    },
    faIcons: {
        fontSize: 17,
    },
    headingLinkStyle: {
        color: '#206eb6',
        cursor: 'pointer',
        textDecoration: 'none'
    }
});

class TaskTile extends React.Component {
    state = {
        showLoader: false,
    };

    getProgressIcon = (status) => {
        const { classes } = this.props;
        if (status === 'SUCCESS') {
             return <img src={CompleteIcon} alt="Success" className={classes.statusIcon} />;
        }
        if (status === 'INPROGRESS') {
             return <img src={LoadingIcon} alt="Loading" className={classes.loadingIcon} style={{width:"28px", height:"11px"}}/>;
        }
        if (status === 'STOPPED') {
            return <StopIcon className={[classes.progressIconStyle, classes.statusInActive].join(' ')} ></StopIcon>
       }
       if(status === 'QUEUED') {
           return <AccessTimeIcon className={[classes.progressIconStyle, classes.statusInQue].join(' ')} ></AccessTimeIcon>
       }
        return <img src={ErrorIcon} alt="Error" className={classes.statusIcon} />;
    };

    isTaskCompleted = (aiqExecution) => {
        const { classes, task, showExecutionVideo } = this.props;
        const caseName = task.executionName ? task.executionName.replace(new RegExp('_exec$'), '') : '';
        const icons = [];
        if (task?.executionVideoUrl && (task?.executionStatus === 'ERROR' || task?.executionStatus === 'SUCCESS' || task?.executionStatus === 'STOPPED')) {
            icons.push(
                <Video key="btn3" videoUrl={task.executionVideoUrl} caseName={caseName} showExecutionVideo={showExecutionVideo} disabled={false} />,
            );
        }
        if (task?.deviceType?.toLowerCase() ==='real') {
            const realIcon = () => (
                <Tooltip data={'Real Device'} key="btn2">
                    <img src={real_device_Icon} alt="Real" className={classes.statusIcon} />
                </Tooltip>
            );
            icons.push(realIcon());
        }
        if (task?.deviceType?.toLowerCase() ==='emulator') {
            const emulatorIcon = () => (
                <Tooltip data={'Emulator'} key="btn2">
                    <img src={emulator_device_Icon} alt="Emulator" className={classes.statusIcon} />
                </Tooltip>
            );
            icons.push(emulatorIcon());
        }
        if (task?.platform?.toLowerCase()?.includes('linux')) {
            const linuxIcon = () => (
                <Tooltip data="platform" key="btn2">
                    <img src={linux_Icon} alt="Linux" className={classes.statusIcon} />
                </Tooltip>
            );
            icons.push(linuxIcon());
        } else if (task?.platform?.toLowerCase()?.includes('windows')) {
            const windowsIcon = () => (
                <Tooltip data="platform" key="btn2">
                    <img src={windows_Icon} alt="Windows" className={classes.statusIcon} />
                </Tooltip>
            );
            icons.push(windowsIcon());
        } else if (task?.platform?.toLowerCase()?.includes('macos') || task?.platform?.toLowerCase()?.includes('ios')) {
            const macIcon = () => (
                <Tooltip data="platform" key="btn2">
                    <img src={macos_Icon} alt="macOS" className={classes.statusIcon} />
                </Tooltip>
            );
            icons.push(macIcon());
        } else if (task?.platform?.toLowerCase()?.includes('android')) {
            const androidIcon = () => (
                <Tooltip data="platform" key="btn2">
                    <img src={android_Icon} alt="Android" className={classes.statusIcon} />
                </Tooltip>
            );
            icons.push(androidIcon());
        } else {
            // No need to show default platform, its leading to display wrong information on ui
            // const defaultIcon = () => (
            //     <Tooltip data="platform" key="btn2">
            //         <Link to={{ pathname: `/details/${task.projectId}/${task.testcaseId}/executions/${task.executionId || task.value}` }}>
            //         <FontAwesomeIcon className={`${classes.moreIconsBtn} ${classes.faIcons}`} icon={faLinux} aria-label="linux" id="linux" />
            //         </Link>
            //     </Tooltip>
            // );
            // icons.push(defaultIcon());
        }
        if (task?.browser?.toLowerCase() === "firefox") {
            const mozillaIcon = () => (
                <Tooltip data="browser" key="btn1">
                    <img src={firefoxIcon} alt="Firefox" className={classes.statusIcon} />
                </Tooltip>
            );
            icons.push(mozillaIcon());
        } else if (task?.browser?.toLowerCase() === "chrome") {
            const chromeIcon = () => (
                <Tooltip data="browser" key="btn1">
                    <img src={chrome_Icon} alt="Chrome" className={classes.statusIcon} />
                </Tooltip>
            );
            icons.push(chromeIcon());
        } else if (task?.browser?.toLowerCase() === "internet explorer") {
            const interExpIcon = () => (
                <Tooltip data="browser" key="btn1">
                    <img src={ieIcon} alt="Internet Explorer" className={classes.statusIcon} />
                </Tooltip>
            );
            icons.push(interExpIcon());
        } else if (task?.browser?.toLowerCase() === "safari") {
            const safariIcon = () => (
                <Tooltip data="browser" key="btn1">
                    <img src={safari_Icon} alt="Safari" className={classes.statusIcon} />
                </Tooltip>
            );
            icons.push(safariIcon());
        } else if (task?.browser?.toLowerCase() === "microsoftedge") {
            const edgeIcon = () => (
                <Tooltip data="browser" key="btn1">
                    <img src={Microsoftedge_Icon} alt="Microsoft Edge" className={classes.statusIcon} />
                </Tooltip>
            );
            icons.push(edgeIcon());
        }else {

            // No default browser icon need to be displayed
            // const chromeIcon = () => (
            //     <Tooltip data="browser" key="btn1">
            //         <Link to={{ pathname: `/details/${task.projectId}/${task.testcaseId}/executions/${task.executionId || task.value}` }}>
            //         <FontAwesomeIcon className={`${classes.moreIconsBtn} ${classes.faIcons}`} icon={faChrome} aria-label="chrome" id="chrome" />
            //         </Link>
            //     </Tooltip>
            // );
            // icons.push(chromeIcon());
        }

        return icons;
    };

    isTaskStatus = (progressIcon, Executionid, taskStatus) => {
        const { showExecutionStatus, ExecutionDetails } = this.props;
        return taskStatus === 'SUCCESS' || taskStatus === 'STOPPED' || taskStatus === 'QUEUED' ? (
            <Status taskStatus = {taskStatus} progressIcon={progressIcon} disabled />
        ) : taskStatus === 'INPROGRESS' ? (
            <Status taskStatus={taskStatus} progressIcon={progressIcon} inprocess={true} />
        ) : (
            <Status
                taskStatus={taskStatus}
                showExecutionStatus={showExecutionStatus}
                progressIcon={progressIcon}
                Executionid={Executionid}
                ExecutionDetails={ExecutionDetails}
            />
        );
    };

    routeToExecution = () => {
        const { task } = this.props;
        if (getParamValues(3) === 'executions' && `${task.executionId || task.value}` !== getParamValues(4)) {
            this.props.clearReducer();
        }
    };
    goToTestSuite = async (task) => {
        const { history,toggleSwipeable } = this.props;
        await history.push({
            pathname: `/projects/${task.projectId}/${task.testcaseId}/executions/${task.executionId || task.value}`,
            state: { selectedTab: 1 },
        });
        toggleSwipeable(true)
    }

    downloadReport = async (reportUrl, reportName, testCaseId, executionId) => {
        if (reportUrl) {
            await singleFileCreationService.getDownloadAiqReport(reportUrl, `${reportName}.html`, true, false, testCaseId, executionId);
        }
    };

    render() {
        const { classes, task } = this.props;
        const { showLoader } = this.state;
        const { aiqExecution } = task;
        const progressIcon = this.getProgressIcon(task.executionStatus);
        return (
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={2} sm={2} md={2} lg={2} className={classes.progressIconSection}>
                    <div>{this.isTaskStatus(progressIcon, task.executionId || task.value, task.executionStatus)}</div>
                </Grid>
                <Grid item xs={7} sm={7} md={7} lg={6}>
                    <Typography noWrap className={classes.mainHeadingStyle} onClick={() => (this.goToTestSuite(task))}>
                        <Tooltip data={task.executionName || task.label}>
                            <Link to={`/projects/${task.projectId}/${task.testcaseId}/executions/${task.executionId || task.value}`} className={classes.headingLinkStyle}>
                                {task.executionName || task.label} {/* date*/}
                            </Link>
                        </Tooltip>
                    </Typography>
                    <Typography noWrap className={classes.typographyStyle}>
                        <Tooltip data={getFormatedDate(task.initiatedOn, 'HH:mm:ss', null)}>
                            {getFormatedDate(task.initiatedOn, 'MMM, DD, YYYY', 'N/A')}  {task.initiatedBy ? ` - ${task.initiatedBy}` :''} {/* date*/}
                        </Tooltip>
                    </Typography>
                    <Typography noWrap className={classes.subHeadngStyle}>
                        <Tooltip data={task.projectName}>
                            {task.projectName} {/* sub heading*/}
                        </Tooltip>
                    </Typography>
                    {task.executionStatus === "INPROGRESS" || !task.reportUrl ? null : (
                    <Typography noWrap className={classes.downloadLinkStyle} onClick={()=>this.downloadReport(task.reportUrl, `${task.executionName ? task.executionName : task.label}_Report`, task.testcaseId, task.executionId)}>
                        Download Report {/* sub heading*/}
                    </Typography>
                    )
                   }
                </Grid>
                {
                    <Grid item xs={3} sm={3} md={3} lg={4} className={classes.progressIconSection}>
                        {this.isTaskCompleted(aiqExecution)}
                    </Grid>
                    
                }
                
                {showLoader ? <Loader /> : null}
            </Grid>
        );
    }
}

TaskTile.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    showExecutionVideo: PropTypes.func.isRequired,
    showExecutionStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSingleTestCase: (...args) => dispatch(ProjectActions.getSingleTestCase(...args)),
        clearReducer: () => dispatch(ExecutionActions.clearReducer()),
    };
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(TaskTile));
