import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import CloseOnEscape from 'react-close-on-escape';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import {
    DIALOG_ROOT,
    MODAL_CANCEL_BUTTON,
    MODAL_SUBMIT_BUTTON,
    MODAL_FOOTER,
    MODAL_HEADER_TITLE,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_HEADER_CLOSE_ICON,
    BLACK_FONT,
} from '../../../common/cssConstants';

const styles = () => ({
    InnerTS: {
        minWidth: 240,
        maxWidth: 240,
        marginTop: 15,
        marginBottom: 15,
        borderRadius: '5px',
        overflow: 'hidden',
        '&>div': {
            maxHeight: 'calc(100vh - 259px)',
            overflowY: 'auto',
        },
    },
    inputLabel: {
        left: 'inherit',
    },
    root: {
        ...DIALOG_ROOT,
        overflowX: 'auto',
        heigt: '680px',
    },
    dialogTitleStyle: MODAL_HEADER_TITLE,
    checked: {
        justifyContent: ' center',
        width: '-webkit-fill-available',
        height: 'auto',
        paddingTop: '10px',
    },
    InnerHeading: {
        justifyContent: ' center',
        textAlign: ' center',
        fontSize: 12,
        fontWeight: 500,
        color: BLACK_FONT,
        paddingBottom: '5px',
        width: 'calc(100% - 40px)',
        borderBottom: '1px solid #979797',
        margin: '10px 20px',
    },
    InnerData: {
        width: '100%',
        padding: '0px 20px 10px 20px',
    },
    InnerText: {
        paddingTop: 5,
        color: BLACK_FONT,
        fontSize: 12,
    },
    DGcont: {
        flexDirection: 'row',
        minWidth: 540,
        display: 'flex',
    },
    select: {
        width: 190,
        fontSize: 20,
    },
});
class StepAndDataModal extends React.Component {
    state = {
        selectedValueForSteps: -1,
        selectedValueForData: -1,
        selectedValueForResult: -1,
        // getStepAndData: { Ts: '1', Td: '1', ab: '1', abs: '1', abss: '1', abssd: '1', instr: 'Click On this' },
        selectOp: 'Test Steps',
    };

    componentDidUpdate(prevProps) {
        const { Steps, ColumnData, ExpectedResult } = this.props;
        if (prevProps.Steps !== Steps || prevProps.ColumnData !== ColumnData || prevProps.ExpectedResult !== ExpectedResult) {
            this.updateState({
                selectedValueForSteps: Steps !== 0 ? Steps - 1 : -1,
                selectedValueForData: ColumnData !== 0 ? ColumnData - 1 : -1,
                selectedValueForResult: ExpectedResult !== 0 ? ExpectedResult - 1 : -1,
            });
        }
    }

    handleChange = (event) => {
        if (this.props.modalType === 'testStep') {
            this.setState({ selectedValueForSteps: event.target.value });
        } else if (this.props.modalType === 'testData') {
            this.setState({ selectedValueForData: event.target.value });
        } else if (this.props.modalType === 'testResult') {
            this.setState({ selectedValueForResult: event.target.value });
        }
    };

    handleChangeDrop = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    submitVal = () => {
        const { selectedValueForSteps, selectedValueForData, selectedValueForResult } = this.state;
        const dataObj = { Step: selectedValueForSteps, Data: selectedValueForData, Result: selectedValueForResult };
        this.props.getStep_data(dataObj);
        this.props.handleClose();
    };

    detectStyle = (ind) => {
        if (this.state.selectOp === 'Test Data' && `${this.state.selectedValueForData}` === `${ind}`) {
            return { color: ' #f0b637' };
        }
        if (`${this.state.selectedValueForData}` === `${ind}` && this.state.selectOp === 'Test Steps') {
            return { color: '#f0b637' };
        }
        if (`${this.state.selectedValueForResult}` === `${ind}` && this.state.selectOp === 'Expected Results') {
            return { color: '#f0b637' };
        }
        if (
            (this.state.selectOp === 'Test Steps' && `${this.state.selectedValueForSteps}` === `${ind}`) ||
            (this.state.selectOp === 'Test Data' && `${this.state.selectedValueForData}` === `${ind}`) ||
            (this.state.selectOp === 'Expected Results' && `${this.state.selectedValueForResult}` === `${ind}`)
        ) {
            return { color: '#1168cd' };
        }
        return {};
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    render() {
        const { classes, open, handleClose, data, modalType } = this.props;

        const infoHeader = data && data.length ? data[0] : [];
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.root}
                    maxWidth="md"
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DialogTitle classes={{ root: classes.dialogTitleStyle }} id="alert-dialog-title">
                            Select the column for{' '}
                            {modalType === 'testStep'
                                ? 'steps'
                                : '' || modalType === 'testData'
                                ? 'data'
                                : '' || modalType === 'testResult'
                                ? 'Expected Result'
                                : ''}
                        </DialogTitle>
                        <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                            <Close style={MODAL_HEADER_CLOSE_ICON} onClick={handleClose} aria-label="closeIcon" id="closeIcon" />
                        </div>
                    </div>
                    <DialogContent
                        style={{
                            padding: '0px 30px',
                            maxWidth: '581px',
                            display: 'block',
                            overflowY: 'auto',
                        }}
                    >
                        <div className={classes.DGcont}>
                            {infoHeader.map((val, ind) => {
                                return (
                                    <div
                                        key={ind}
                                        style={{
                                            marginLeft: ind !== 0 ? 15 : 0,
                                            marginRight: ind !== infoHeader.length - 1 ? 15 : 0,
                                            boxShadow:
                                                (modalType === 'testStep' && `${this.state.selectedValueForSteps}` === `${ind}`) ||
                                                (modalType === 'testData' && `${this.state.selectedValueForData}` === `${ind}`) ||
                                                (modalType === 'testResult' && `${this.state.selectedValueForData}` === `${ind}`)
                                                    ? '0 2px 7px 0 rgba(0,0,0,0.8)'
                                                    : '0 2px 7px 0 rgba(0,0,0,0.2)',
                                        }}
                                        className={classes.InnerTS}
                                    >
                                        <div>
                                            <Radio
                                                className={classes.checked}
                                                checked={
                                                    `${this.state.selectedValueForSteps}` === `${ind}` ||
                                                    `${this.state.selectedValueForData}` === `${ind}` ||
                                                    (`${this.state.selectedValueForResult}` === `${ind}` && this.state.selectOp === 'Test Data') ||
                                                    `${this.state.selectedValueForData}` === `${ind}` ||
                                                    `${this.state.selectedValueForSteps}` === `${ind}` ||
                                                    (`${this.state.selectedValueForResult}` === `${ind}` && this.state.selectOp === 'Test Steps') ||
                                                    `${this.state.selectedValueForData}` === `${ind}` ||
                                                    `${this.state.selectedValueForSteps}` === `${ind}` ||
                                                    (`${this.state.selectedValueForResult}` === `${ind}` && this.state.selectOp === 'Test Result')
                                                }
                                                onChange={this.handleChange}
                                                value={`${ind}`}
                                                style={this.detectStyle(ind)}
                                                name="radio-button-demo"
                                                aria-label={ind}
                                                disabled={
                                                    (`${this.state.selectedValueForSteps}` === `${ind}` &&
                                                        `${this.state.selectedValueForResult}` === `${ind}` &&
                                                        this.state.selectOp === 'Test Data') ||
                                                    (`${this.state.selectedValueForResult}` === `${ind}` &&
                                                        `${this.state.selectedValueForData}` === `${ind}` &&
                                                        this.state.selectOp === 'Test Steps') ||
                                                    (`${this.state.selectedValueForData}` === `${ind}` &&
                                                        `${this.state.selectedValueForSteps}` === `${ind}` &&
                                                        this.state.selectOp === 'Test Result')
                                                }
                                            />
                                            <div className={classes.InnerHeading}>{val}</div>
                                            <div className={classes.InnerData}>
                                                {data.map((info, index) => {
                                                    return (
                                                        index >= 1 && (
                                                            <Typography
                                                                key={index}
                                                                style={{ paddingTop: index === 1 ? '0' : '' }}
                                                                noWrap
                                                                className={classes.InnerText}
                                                            >
                                                                {info[ind]}
                                                            </Typography>
                                                        )
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </DialogContent>
                    <DialogActions style={MODAL_FOOTER}>
                        <Button variant="contained" type="submit" color="secondary" onClick={this.submitVal} style={MODAL_SUBMIT_BUTTON}>
                            OK
                        </Button>
                        <CloseOnEscape onEscape={handleClose}>
                            <Button variant="outlined" color="secondary" style={MODAL_CANCEL_BUTTON} onClick={handleClose}>
                                Cancel
                            </Button>
                        </CloseOnEscape>
                        <Button
                            variant="outlined"
                            type="submit"
                            color="secondary"
                            style={MODAL_CANCEL_BUTTON}
                            onClick={() => {
                                this.setState({ selectedValueForSteps: -1, selectedValueForData: -1, selectedValueForResult: -1 });
                            }}
                        >
                            Reset
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(StepAndDataModal);
