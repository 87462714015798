import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AccountActions, ModalActions } from '../../../store/actions';
import { ActionTypes } from '../../../store/constants';
import Copy from '../images/content_copy_grey_18x18.png';
import { CustomSwitch } from '../../../common/miniComponents';

const styles = (theme) => ({
    input: {
        '&>div>input': {
            padding: '2px 0px 7px',
            width: '50%',
            fontSize: '12px',
        },
    },
    helptext: {
        height: '10px',
        width: '10px',
    },
    copyImage: {
        height: '18px',
        width: '18px',
        cursor: 'pointer',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    noBoxShadow: {
        backgroundColor: 'transparent',
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
    },
    expensionContent: {
        margin: '0 !important',
        cursor: 'default',
    },
    expensionsummary: {
        height: 'auto !important',
        minHeight: 'auto !important',
        paddingLeft: '0px',
    },
    expensionDetail: {
        padding: 0,
    },
    radio: {
        height: 'auto !important',
        minHeight: 'auto !important',
        // marginBottom: '20px'
    },
    formControl: {
        paddingBottom: '10px',
    },
    inputLanguage: {
        width: '100%',
        borderRadius: '3px',
        '&>div>div': {
            fontSize: '12px',
        },
    },
    margin: {},
    button: {
        borderRadius: '3px',
        // minHeight: 0,
        minWidth: '75px',
        padding: '2px 8px',
        textTransform: 'capitalize',

        height: '34px',
        width: '69px',
        border: '1px solid #3B91DF',
        backgroundColor: '#3B91DF',
    },
    buttonContainer: {
        padding: '10px 0px 0px 0px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonSave: {
        background: '#0092e6',
        color: '#fff',
        width: '100px',
        height: '50px',
    },
    buttonCancel: {
        background: 'white',
        color: '#000',
        width: '100px',
        height: '50px',
        marginRight: '10px',
    },
    heading: {
        paddingLeft: '20px',
        fontWeight: 'bold',
        color: '#494949',
        fontSize: '12px',
    },
    sidebarItem: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
    },
    paddingOnXS: {
        padding: 0,
        [theme.breakpoints.only('xs')]: {
            padding: 10,
        },
    },
    errorMessage: {
        fontSize: 11,
        color: 'red',
        display: 'block',
    },
});
class SamlSettings extends React.Component {
    constructor(props) {
        super(props);
        this.aiqAcsUrl = React.createRef();
        this.state = {
            isDirty: false,
            // copied: false,
        };
    }

    componentDidMount() {
        this.props.getSamlConfig(this.props.user.accountId);
    }

    handleSave = () => {
        const formData = {
            isSamlAccount: this.props.samlConfig.isSamlAccount,
            samlMetadataUrl: this.props.samlConfig.metadataUrl,
            samlDomain: this.props.samlConfig.domain,
            samlUserCount: Number(this.props.samlConfig.userCount),
        };
        this.props.updateSamlConfig(formData, this.props.user.accountId);
    };

    render() {
        const { classes, samlConfig } = this.props;
        // const { isDirty } = this.props.state;
        return (
            <div className={classes.container}>
                <Grid item xs={1} sm={1} md={2} lg={2} />
                <Grid item xs={10} sm={10} md={7} lg={7}>
                    <div className={classes.paddingOnXS}>
                        <div className={classes.sidebarItem} style={{ borderBottom: '1px solid #c5ced8', paddingTop: 20 }}>
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    Enable
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={9} lg={9} style={{ paddingLeft: 6 }}>  
                                <CustomSwitch
                                    checked={samlConfig.isSamlAccount}
                                    onChange={(e) => {
                                        this.props.setSamlConfig({ isSamlAccount: e.target.checked });
                                        this.setState({ isDirty: true });
                                    }}
                                    value="isSamlAccount"
                                    h={16}
                                    w={30}
                                    margin="2px 0 7px 0"
                                />
                            </Grid>
                        </div>
                        <div className={classes.sidebarItem} style={{ borderBottom: '1px solid #c5ced8', paddingTop: 20 }}>
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    Metadata URL
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} style={{ paddingLeft: 6 }}>
                                <TextField
                                    // disabled={user.name === USER_NAME}
                                    fullWidth
                                    disabled={!samlConfig.isSamlAccount}
                                    InputProps={{ disableUnderline: true, autoComplete: 'off' }}
                                    className={classes.input}
                                    value={samlConfig.metadataUrl ? samlConfig.metadataUrl : ''}
                                    placeholder="Metadata Url"
                                    aria-label="metadata Url"
                                    id="metadataUrl"
                                    onChange={(e) => {
                                        this.props.setSamlConfig({ metadataUrl: e.target.value });
                                        this.setState({ isDirty: true });
                                    }}
                                    name="name"
                                />
                            </Grid>
                        </div>
                        <div className={classes.sidebarItem} style={{ borderBottom: '1px solid #c5ced8', paddingTop: 20 }}>
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    Domain Name
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} style={{ paddingLeft: 6 }}>
                                <TextField
                                    // disabled={user.name === USER_NAME}
                                    fullWidth
                                    disabled={!samlConfig.isSamlAccount}
                                    InputProps={{ disableUnderline: true, autoComplete: 'off' }}
                                    className={classes.input}
                                    value={samlConfig.domain ? samlConfig.domain : ''}
                                    placeholder="Domain Name"
                                    aria-label="Domain Name"
                                    id="userDetailsName"
                                    onChange={(e) => {
                                        this.props.setSamlConfig({ domain: e.target.value });
                                        this.setState({ isDirty: true });
                                    }}
                                    name="name"
                                />
                            </Grid>
                        </div>
                        <div className={classes.sidebarItem} style={{ borderBottom: '1px solid #c5ced8', paddingTop: 20 }}>
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    User Count
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} style={{ paddingLeft: 6 }}>
                                <TextField
                                    // disabled={user.name === USER_NAME}
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        autoComplete: 'off',
                                        inputProps: {
                                            min: 0,
                                            max: 1000000,
                                        },
                                    }}
                                    disabled={!samlConfig.isSamlAccount}
                                    className={classes.input}
                                    value={samlConfig.userCount ? samlConfig.userCount : ''}
                                    placeholder="User count"
                                    aria-label="User count"
                                    id="userCount"
                                    type="number"
                                    onChange={(e) => {
                                        this.props.setSamlConfig({ userCount: e.target.value });
                                        this.setState({ isDirty: true });
                                    }}
                                    name="userCount"
                                />

                                {samlConfig.userCount > 1000000 && (
                                    <span className={classes.errorMessage}>User count cannot be greater than 1000000</span>
                                )}
                            </Grid>
                        </div>
                        <div className={classes.sidebarItem} style={{ borderBottom: '1px solid #c5ced8', paddingTop: 20 }}>
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    AIQ Metadata Url
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} style={{ paddingLeft: 6, display: 'flex' }}>
                                <TextField
                                    disabled
                                    fullWidth
                                    InputProps={{ disableUnderline: true, autoComplete: 'off' }}
                                    className={classes.input}
                                    value={samlConfig.aiqMetadataUrl ? samlConfig.aiqMetadataUrl : ''}
                                    placeholder="AIQ Metadata Url"
                                    aria-label="AIQ Metadata Url"
                                    id="aiqMetadataUrl"
                                    onChange={(e) => {
                                        this.props.setSamlConfig({ aiqMetadataUrl: e.target.value });
                                        this.setState({ isDirty: true });
                                    }}
                                    name="aiqMetadataUrl"
                                />
                                <CopyToClipboard text={samlConfig.aiqMetadataUrl} /* onCopy={() => this.setState({ copied: true })} */>
                                    <img
                                        aria-hidden
                                        title="copy to clipboard"
                                        alt=""
                                        onClick={() => this.props.alertAction('AIQ Metadata Url Copied to clipboard')}
                                        className={classes.copyImage}
                                        src={Copy}
                                    />
                                </CopyToClipboard>
                            </Grid>
                        </div>
                        <div className={classes.sidebarItem} style={{ borderBottom: '1px solid #c5ced8', paddingTop: 20 }}>
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    AIQ Acs Url
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} style={{ paddingLeft: 6, display: 'flex' }}>
                                <TextField
                                    disabled
                                    fullWidth
                                    InputProps={{ disableUnderline: true, autoComplete: 'off' }}
                                    className={classes.input}
                                    value={samlConfig.aiqAcsUrl ? samlConfig.aiqAcsUrl : ''}
                                    placeholder="AIQ Acs Url"
                                    aria-label="AIQ Acs Url"
                                    id="aiqAcsUrl"
                                    onChange={(e) => {
                                        this.props.setSamlConfig({ aiqAcsUrl: e.target.value });
                                        this.setState({ isDirty: true });
                                    }}
                                    name="aiqAcsUrl"
                                />
                                {/* <Tooltip title={'Copy to Clipboard'}> */}
                                <CopyToClipboard text={samlConfig.aiqAcsUrl} /* onCopy={() => this.setState({ copied: true })} */>
                                    <img
                                        aria-hidden
                                        title="copy to clipboard"
                                        alt=""
                                        onClick={() => this.props.alertAction('AIQ Acs Url Copied to clipboard')}
                                        className={classes.copyImage}
                                        src={Copy}
                                    />
                                </CopyToClipboard>
                                {/* </Tooltip> */}
                            </Grid>
                        </div>

                        <div className={classes.sidebarItem} style={{ borderBottom: '1px solid #c5ced8', paddingTop: 20 }}>
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    AIQ Relay State
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} style={{ paddingLeft: 6, display: 'flex' }}>
                                <TextField
                                    disabled
                                    fullWidth
                                    InputProps={{ disableUnderline: true, autoComplete: 'off' }}
                                    className={classes.input}
                                    value={samlConfig.aiqRelayState ? samlConfig.aiqRelayState : ''}
                                    placeholder="AIQ Relay State"
                                    aria-label="AIQ Relay State"
                                    id="aiqrelay"
                                    onChange={(e) => {
                                        this.props.setSamlConfig({ aiqRelayState: e.target.value });
                                        this.setState({ isDirty: true });
                                    }}
                                    name="aiqrelay"
                                />
                                {/* <Tooltip title={'Copy to Clipboard'}> */}
                                <CopyToClipboard text={samlConfig.aiqRelayState} /* onCopy={() => this.setState({ copied: true })} */>
                                    <img
                                        aria-hidden
                                        title="copy to clipboard"
                                        alt=""
                                        onClick={() => this.props.alertAction('AIQ Acs Url Copied to clipboard')}
                                        className={classes.copyImage}
                                        src={Copy}
                                    />
                                </CopyToClipboard>
                                {/* </Tooltip> */}
                            </Grid>
                        </div>

                        <div className={classes.buttonContainer}>
                            <Button
                                color="secondary"
                                className={classes.button}
                                disabled={
                                    !this.state.isDirty ||
                                    !(
                                        this.props.samlConfig.metadataUrl !== '' &&
                                        this.props.samlConfig.domain !== '' &&
                                        this.props.samlConfig.userCount !== '' &&
                                        this.props.samlConfig.userCount <= 1000000
                                    )
                                }
                                onClick={() => {
                                    this.handleSave();
                                    this.setState({ isDirty: false });
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={1} sm={1} md={3} lg={3} />
            </div>
        );
    }
}
SamlSettings.defaultProps = {
    classes: PropTypes.shape({}).isRequired,
    samlConfig: {
        metaDataUrl: PropTypes.string,
        domainName: PropTypes.string,
        userCount: PropTypes.number,
    },
};

const mapStateToProps = (state) => {
    return {
        state,
        samlConfig: state.accountReducer.samlConfig,
        user: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSamlConfig: (accountId) => dispatch(AccountActions.getSamlConfig(accountId)),
        updateSamlConfig: (formData, accountId) => dispatch(AccountActions.updateSamlConfig(formData, accountId)),
        setSamlConfig: (form) => dispatch({ type: ActionTypes.GET_SAML_CONFIG_SUCCESS, payload: form }),
        alertAction: (alertText) => dispatch(ModalActions.toggleSnackBar(alertText, '', true)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SamlSettings));
