import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/styles";
import styles from '../Styles';

import real_device_logo from '../../../assets/icons/devices/real.svg';
import emulator_logo from '../../../assets/icons/devices/emulator.svg';

const DeviceTypeIcon = ({deviceType,classes}) => {

    const getLogo = (deviceType) => {

        if(!deviceType) {
            return null
        }

        const device_type_map = {
            real: real_device_logo,
            emulator: emulator_logo


        }

        return device_type_map[deviceType] || real_device_logo
    }

    return (
        
        <React.Fragment>
            {
                deviceType &&
                <img src={getLogo(deviceType)}/>
            }
        </React.Fragment>
    )

}

DeviceTypeIcon.propTypes = {
    deviceType:PropTypes.string.isRequired
}
export default withStyles(styles)(DeviceTypeIcon)

 