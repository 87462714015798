import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faFileAlt, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';

import { styles } from './styles';

const NoProject = ({ classes, gotoProject, gotoDashboard, handleNewProjectOpen }) => (
    <div>
        <Typography variant="h1" className={classes.notExistColor}>
            Project does not exist!
        </Typography>
        <Typography className={classes.addProject} style={{ fontSize: '14px' }}>
            Add a project by clicking on the{' '}
            <FontAwesomeIcon
                icon={faPlusCircle}
                label="faPlusCircle"
                id="faPlusCircle"
                onClick={handleNewProjectOpen}
                style={{ fontSize: 20 }}
                className={classes.iconColor}
            />{' '}
            , or{' '}
        </Typography>

        <Typography className={classes.notExistColor}>
            go to
            <span aria-hidden onClick={gotoProject} className={classes.noProjectText}>
                <FontAwesomeIcon icon={faFileAlt} aria-label="faFileAlt" id="faFileAlt" style={{ marginRight: '3px' }} />
                Project List
            </span>{' '}
            /
            <span aria-hidden onClick={gotoDashboard} className={[classes.noProjectText, classes.extraSpace].join(' ')}>
                <FontAwesomeIcon icon={faTachometerAlt} aria-label="faTachometerAlt" id="faTachometerAlt" style={{ marginRight: '3px' }} />
                Dashboard
            </span>
            to get started
        </Typography>
    </div>
);

NoProject.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    handleNewProjectOpen: PropTypes.func.isRequired,
    gotoDashboard: PropTypes.func.isRequired,
    gotoProject: PropTypes.func.isRequired,
};

export default withStyles(styles)(NoProject);
