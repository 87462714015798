import { createStyles, withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import { generalModalActions } from '../../../store/actions';
import { BLACK_FONT, FLEX_CENTRE } from '../../../common/cssConstants';
import DatePicker from '../../modal/GeneralModal/DatePicker';
import { isDateCorrect, checkKeyInObject, checkArrayLength, getFormatedDate, makeLocalDate } from '../../../utils';
import { defaultStartDate, defaultAccountEndDate } from '../../../common/constants';
import { CustomSwitch } from '../../../common/miniComponents';

const styles = () =>
    createStyles({
        font: {
            fontSize: 12,
            color: BLACK_FONT,
        },
        labelColor: {
            '&>label': {
                fontWeight: 500,
                color: BLACK_FONT,
                fontSize: '12px',
                transform: 'translate(0, 1.5px)',
            },
            '&>div': {
                '&:before': {
                    borderColor: '#979797 !important',
                },
                '&:after': {
                    borderColor: '#979797 !important',
                },
            },
        },
        root: {
            padding: '0px 30px',
            width: 500,
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 321px)',
        },
        label: {
            color: BLACK_FONT,
            fontWeight: 500,
            fontSize: '12px',
        },
        calendarClassStart: {
            textTransform: 'capitalize',
            top: -10,
            left: 60,
            '&>.react-datepicker__triangle': {
                display: 'none',
            },
        },
        calendarClassEnd: {
            textTransform: 'capitalize',
            top: -10,
            left: -60,
            '&>.react-datepicker__triangle': {
                display: 'none',
            },
        },
        customDatePicker: {
            background: 'transparent',
            border: 'none',
            color: BLACK_FONT,
            fontSize: 12,
            fontWeight: 500,
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            outline: 'none',
            padding: '6px 0 7px 0',
            width: '100%',
        },
        datePicker: {
            margin: '0px 0px 16px 0px',
        },
        datePickerContainer: {
            ...FLEX_CENTRE,
            margin: '16px 0px 8px 0px',
            justifyContent: 'space-between',
        },
        datePickerWrapper: {
            display: 'flex',
            width: '45%',
            flexDirection: 'column',
        },
        activeInactiveContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        testError: {
            color: 'red',
            fontSize: 11,
            margin: 0,
        },
    });

let isMounted = false;
class AdminAccountInfo extends Component {
    state = {
        isFirst: false,
        didChange: false,
        name: '',
        prevName: '',
        description: '',
        selectedDateStart: defaultStartDate,
        selectedDateEnd: defaultAccountEndDate,
        isAccountActive: false,
        isDuplicateName: false,
        isPastedDateSame: false
    };

    componentDidMount() {
        const { isUpdate, tabsData, account, group, isForAccount, isUpdateGroup } = this.props;
        isMounted = true;
        if (isUpdate || checkKeyInObject(tabsData.adminAccountInfo, 'state', 'bool')) {
            let previousData = {};
            if (checkKeyInObject(tabsData.adminAccountInfo, 'state', 'bool')) {
                previousData = tabsData.adminAccountInfo.state;
            } else if (isUpdate) {
                const name = checkKeyInObject(isForAccount ? account : group, isForAccount ? 'accountName' : 'name', 'value', '');
                previousData = {
                    name,
                    prevName: name,
                    description: account.description,
                    selectedDateStart: new Date(makeLocalDate(account.startDate)).getTime(),
                    selectedDateEnd: new Date(makeLocalDate(account.endDate)).getTime(),
                    isAccountActive: !account.disabledStatus,
                };
            }
            this.setStateIfComponentMounted(
                {
                    ...previousData,
                    isFirst: true,
                    didChange: checkKeyInObject(previousData, 'didChange') ? previousData.didChange : false,
                },
                () => {
                    if (isUpdate && account) {
                        this.props.updateTabData('account', { ...account });
                    }
                },
            );
        } else if (isUpdateGroup || checkKeyInObject(tabsData.associateUsersToGroup, 'state', 'bool')) {
            let previousData = {};
            if (checkKeyInObject(tabsData.associateUsersToGroup, 'state', 'bool')) {
                previousData = tabsData.associateUsersToGroup.state;
            } else if (isUpdateGroup) {
                const name = checkKeyInObject(isForAccount ? account : group, isForAccount ? 'accountName' : 'name', 'value', '');
                previousData = {
                    name,
                    prevName: name,
                    description: checkKeyInObject(isForAccount ? account : group, 'description', 'value', ''),
                    selectedDateStart: new Date(group.start_date).getTime(),
                    selectedDateEnd: new Date(group.end_date).getTime(),
                    isAccountActive: checkKeyInObject(group, 'disabled_status', 'value', false),
                };
            }
            this.setStateIfComponentMounted({
                ...previousData,
                isFirst: true,
                didChange: checkKeyInObject(previousData, 'didChange') ? previousData.didChange : false,
            });
        }
    }

    componentWillUnmount() {
        isMounted = false;
        const { updateTabData } = this.props;
        updateTabData('adminAccountInfo', { state: { ...this.state } });
    }

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted)
            this.setState({ didChange: this.state.isFirst, ...obj }, () => {
                callback();
                this.props.updateTabData('adminAccountInfo', { state: { ...this.state } });
            });
    };

    calenderRef1 = React.createRef();

    calenderRef2 = React.createRef();

    checkAccountName = () => {
        if (this.state.name !== this.state.prevName) {
            const { accounts, groups, isForAccount, isUpdate, isUpdateGroup, account, group } = this.props;
            let isNameValid;
            if (checkArrayLength(accounts) && isForAccount) {
                isNameValid = accounts.find(({ accountName }) => `${accountName}`.toLowerCase().trim() === `${this.state.name}`.toLowerCase().trim());
                if (isUpdate && isNameValid && account && isNameValid.accountId === account.accountId) {
                    isNameValid = null;
                }
            } else if (checkArrayLength(groups) && !isForAccount) {
                isNameValid = groups.find(({ name }) => `${name}`.toLowerCase().trim() === `${this.state.name}`.toLowerCase().trim());
                if (isUpdateGroup && isNameValid && group && isNameValid.id === group.id) {
                    isNameValid = null;
                }
            }
            this.setStateIfComponentMounted({ isDuplicateName: Boolean(isNameValid), prevName: this.state.name });
        }
    };

    render() {
        const { classes, isForAccount } = this.props;
        const { name, description, selectedDateStart, selectedDateEnd, isAccountActive, isDuplicateName, isPastedDateSame } = this.state;

        return (
            <div className={classes.root}>
                <div>
                    <TextField
                        id={isForAccount ? 'AccountNameTextField' : 'GroupNameTextField'}
                        aria-label={isForAccount ? 'Account Name TextField' : 'Group Name TextField'}
                        value={name || ''}
                        InputLabelProps={{ shrink: true }}
                        label={isForAccount ? 'Account Name' : 'Group Name'}
                        InputProps={{
                            classes: {
                                input: classes.font,
                            },
                            autoComplete: 'off',
                        }}
                        fullWidth
                        margin="normal"
                        className={classes.labelColor}
                        onChange={(e) => {
                            this.setStateIfComponentMounted({ name: e.target.value, prevName: name });
                        }}
                        onBlur={this.checkAccountName}
                        autoFocus
                    />
                    {isDuplicateName || !name || !name.trim() ? (
                        <div>
                            {isDuplicateName && (
                                <div className={classes.testError}>{isForAccount ? 'Account' : 'Group'} with same name already exists</div>
                            )}
                            {(!name || !name.trim()) && (
                                <div className={classes.testError}>{isForAccount ? 'Account' : 'Group'} name must not be empty</div>
                            )}
                        </div>
                    ) : null}
                </div>
                <div>
                    <TextField
                        id="DescriptionTextField"
                        aria-label="Description TextField"
                        value={description || ''}
                        InputLabelProps={{ shrink: true }}
                        label="Description"
                        InputProps={{
                            classes: {
                                input: classes.font,
                            },
                            autoComplete: 'off',
                        }}
                        fullWidth
                        margin="normal"
                        className={classes.labelColor}
                        onChange={(e) => {
                            this.setStateIfComponentMounted({ description: e.target.value });
                        }}
                    />
                </div>
                <div className={classes.datePicker}>
                    <div className={classes.datePickerContainer}>
                        <div
                            className={classes.datePickerWrapper}
                            ref={(node) => {
                                this.calenderRef1 = node;
                            }}
                        >
                            <span className={classes.label}>Start time (Optional)</span>
                            <DatePicker
                                calendarClassName={classes.calendarClassStart}
                                selected={selectedDateStart !== defaultStartDate ? isDateCorrect(selectedDateStart, 'value', null) : null}
                                onChange={(date) => {
                                    if (date) {
                                        this.setStateIfComponentMounted({ selectedDateStart: date.getTime() });
                                    } else {
                                        this.setStateIfComponentMounted({ selectedDateStart: defaultStartDate });
                                    }
                                }}
                                popperPlacement="auto-left"
                                dateFormat="MMMM d, yyyy"
                                className={`${classes.customDatePicker}`}
                                calenderRef={this.calenderRef1}
                            />
                        </div>
                        <div
                            className={classes.datePickerWrapper}
                            ref={(node) => {
                                this.calenderRef2 = node;
                            }}
                        >
                            <span className={classes.label}>End time (Optional)</span>
                            <DatePicker
                                calendarClassName={classes.calendarClassEnd}
                                selected={
                                    selectedDateEnd !== defaultAccountEndDate
                                        ? isDateCorrect(selectedDateEnd, 'value', null, getFormatedDate(defaultAccountEndDate, 'YYYY-MM-D', null))
                                        : null
                                }
                                onChange={(date) => {
                                    if (date) {
                                        this.setStateIfComponentMounted({ selectedDateEnd: date.getTime() });
                                    } else {
                                        this.setStateIfComponentMounted({ selectedDateEnd: defaultAccountEndDate });
                                    }
                                }}
                                onChangeRaw={(e) => {
                                    if (selectedDateStart >= (new Date(e.target.value)).getTime()) {
                                        this.setStateIfComponentMounted({ isPastedDateSame: true });
                                    }
                                    else{
                                        this.setStateIfComponentMounted({ isPastedDateSame: false });
                                    }
                                }}
                                onCalendarClose={() => {
                                    this.setStateIfComponentMounted({ isPastedDateSame: false });
                                }}
                                popperPlacement="auto-left"
                                dateFormat="MMMM d, yyyy"
                                className={`${classes.customDatePicker}`}
                                minDate={new Date(selectedDateStart).setDate(new Date(selectedDateStart).getDate() + 1)}
                                calenderRef={this.calenderRef2}
                            />
                        </div>
                    </div>
                    {new Date(selectedDateStart) >= new Date(selectedDateEnd) || isPastedDateSame ? (
                        <div>
                            <div className={classes.testError}>"End time" must be greater than "Start time".</div>
                        </div>
                    ) : null}
                </div>
                {isForAccount ? (
                    <div className={classes.activeInactiveContainer}>
                        <span className={classes.label}>Active/Inactive</span>
                        <CustomSwitch
                            checked={isAccountActive}
                            onChange={(e) => {
                                this.setStateIfComponentMounted({ isAccountActive: e.target.checked });
                            }}
                            value="isAccountActive"
                            w={34}
                            h={16}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

AdminAccountInfo.defaultProps = {
    name: '',
    description: '',
    selectedDateStart: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
    selectedDateEnd: new Date(new Date().setHours(23, 59, 0, 0)).getTime(),
    isAccountActive: false,
    isDuplicateName: false,
    isForAccount: true,
};

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
        accounts: state.accountReducer.accounts,
        groups: checkKeyInObject(state.userReducer.userGroups, 'Groups', 'value', []),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminAccountInfo));
