import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Tooltip from '../../../src/components/Tooltip/index';

import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Search } from '@material-ui/icons';

import { Hidden, IconButton } from '@material-ui/core';
import ExecutionTasks from './ExecutionTasks';
import ProjectServices from '../../services/projectServices';
import { ModalActions, ProjectActions, selectionExecutionAction, sortSelectedExecution } from '../../store/actions';
import { checkKeyInObject, checkArrayLength } from '../../utils/utils';
import SelectCustom from '../modal/GeneralModal/Select';


import ViewList from 'mdi-material-ui/FormatListBulleted';

// Custom Accordion Starts
const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    expanded: {
        margin: 'auto',
    },
})(MuiExpansionPanel);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#8898A8 !important',
        borderBottom: '1px solid #8898A8',
        display: 'flex',
        marginBottom: -1,
        height: '45px',
        cursor: 'default !important',
        minHeight: '35px !important',
        padding: '15px 18px 15px 12px',
        borderRadius: '3px',
        border: 'solid 0.5px #8898A8',
        '& :last-child': {
            paddingRight: 0,
        },
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        '&$expanded': {
            margin: '0px',
        },
    },
    expanded: {},
})((props) => <MuiExpansionPanelSummary {...props} />);

AccordionSummary.muiName = 'AccordionSummary';

const AccordionDetails = withStyles(() => ({
    root: {
        background: '#f3f3f3',
        height: 'calc(100vh - 96px)',
        padding: 0,
    },
}))(MuiExpansionPanelDetails);
// Custom Accordion Ends

// Calculate screen with on runtime
// const screenWidth = window.innerWidth ||
//     document.documentElement.clientWidth ||
//     document.getElementsByTagName('body')[0].clientWidth;

// Set taskpanel width according to screen size
const taskPanelWidth = '350px;';

const styles = (theme) => ({
    root: {
        borderBottom: '1px solid #C4C4C4',
        color: theme.palette.primary.dark,
        opacity: 1,
        minWidth: '55%',
    },
    dividerStyle: {
        margin: '0px 8px 10px 8px',
    },
    title: {
        color: '#ffffff',
        fontSize: 14,
        marginLeft: '8px',
        //marginTop: 5,
        fontWeiht: '500px',
        letterSpacing: '0.36px',
        paddingTop: 1,
    },
    listContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'calc(100vh - 154px)',
    },
    listContainerPanel: {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'calc(100vh - 140px)',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(93vh - 154px)',
        },
    },
    select: {
        fontSize: 12,
        '&::before': {
            display: 'none',
        },
    },
    executionListContainer: {
        marginLeft: 5,
        overflowX: 'hidden',
    },
    chevronStyle: {
        cursor: 'pointer',
    },
    titleStyle: {
        cursor: 'pointer',
    },
    white_space: {
        marginLeft: 5,
    },
    sortStyle: {
        marginLeft: 105,
    },
    sort: {
        display: 'flex',
        alignItems: 'center',
        height: 45,
        justifyContent: 'space-around',
        borderBottom: '1px solid #979797',
        padding: '15px 0px 16px 6px',
    },
    ParentExpansionIconStyle: {
        fontSize: '30px',
        marginRight: '22px',
        width: '30px',
        height: '30px',
        color: '#ffffff',
    },
    iconButton: {
        width: '40px',
        height: '40px',
        marginLeft: '20px',
        marginRight: '-10px',
        // color: '#ffffff',
        //fontSize:"20px",
    },
    HeaderBarIcon: {
        fontSize: '20px',
        color: '#ffffff',
    },
    taskPanelPaper: {
        marginTop: 46,
        overflow: 'hidden',
        position: 'relative',
        width: taskPanelWidth,
    },
    menuItem: {
        display: 'flex',
        fontSize: 12,
        justifyContent: 'flex-start',
        padding: '6px 10px',
        lineHeight: 2,
    },
    taskPanelPaperPanel: {
        overflow: 'hidden',
        position: 'relative',
        width: taskPanelWidth,
        [theme.breakpoints.up('sm')]: {
            marginTop: '46px',
            height: 'calc(100vh - 46px)',
        },
    },
    maximize: {
        color: '#4a4a4a',
        width: '30px',
        height: '30px',
    },
    boxShadow: {
        boxShadow: '4 2px 4px 0 rgba(0,0,0,0.35)',
    },
    fullScreenSpan: {
        backgroundColor: '#ffffff',
        borderRadius: '100%',
        height: '30px',
        width: '30px',
        textAlign: 'center',
        padding: 1,
        display: 'inline-block',
        margin: '1px 4px',
        cursor: 'pointer',
        '&>svg': {
            width: 13,
            height: 13,
            marginBottom: 10,
        },
    },
    iconContainer: {
        width: '55%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    circleIcon: {
        height: '24px',
        color: '#4f4949',
        cursor: 'pointer',
    },
    imgIcon: {
        //width: '30px',
        height: '20px',
        //fontSize:"20px",
        color: '#ffffff',
        marginRight: '5px',
        // marginRight: '22px',
    },
    selectCustom: {
        width: '100%',
        height: '45px',
        padding: '9px 18px 16px 21px',
        borderBottom: '1px solid #979797',
    },
    moreIconsBtn: {
        color: '#ffffff',
        marginRight: '4px',
    },
    faIcons: {
        fontSize: 17,
    },
});

const statusMap = { '-1': 'All', '0': 'InProcess', '1': 'Executed', '2': 'Error', '7': 'Stopped', '8':'Queued' };

class Tasks extends React.Component {
    constructor() {
        super();
        this.isTimeOutRunning = false;
        this.isExecRunning = false;
        this.state = {
            expanded: 'taskContainer', // To expand/collapse Panel
            defaultValue: 'InProcess',
            tasks: [],
            previousSortedType: 'sortAsc',
            highLightExecutionId: 0,
            selectedValues: [],
        };
    }

    componentDidMount() {
        const { toggleSidebar } = this.props;
        const { executionTasksStatus, getExecutionTasks, projectId = 0, testCaseId = -1 } = this.props;
        const [_projectId, _testCaseId, isCustomCaseId] = this.getURLProject_Case_ID(projectId, testCaseId);
        getExecutionTasks(_projectId, isCustomCaseId ? -1 : executionTasksStatus, _testCaseId);
        if (Number(executionTasksStatus) === 0)
            this.interval = setInterval(() => {
                this.getExecutionTasks();
            }, 15000);
        const highLightExecutionId =
            this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.execId
                ? this.props.history.location.state.execId
                : 0;
        this.updateState({ defaultValue: statusMap[`${executionTasksStatus}`], highLightExecutionId });
        const showStateTask = localStorage.getItem('expandedTaskbar');
        if (showStateTask && showStateTask === 'show') {
            // Fist resize middle content then expande sidebar
            this.updateState({ expanded: 'taskContainer' }, () => {
                this.isTimeOutRunning = false;
            });
        } else if (showStateTask && showStateTask === 'hide') {
            // First collapse sidebar then resize middle content
            this.updateState({ expanded: false }, () => {
                this.isTimeOutRunning = true;
                setTimeout(() => {
                    toggleSidebar();
                    this.isTimeOutRunning = false;
                }, 50);
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { executionData, executionTasksStatus, forceOpenCloseRightPanel } = nextProps;
        if (`${executionTasksStatus}` !== '0') {
            this.startStopExecution(false);
        } else if (!this.isExecRunning) {
            const { projectId, testCaseId } = this.props;
            const _projectId = projectId || 0;
            const _testCaseId = testCaseId || -1;
            this.startStopExecution(true, executionTasksStatus, _projectId, _testCaseId);
        }

        const highLightExecutionId =
            this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.execId
                ? this.props.history.location.state.execId
                : 0;
        this.setState({
            highLightExecutionId,
            defaultValue: statusMap[`${executionTasksStatus}`],
            tasks: !executionData ? null : [], // just to show 'Loading...' while executions are fetching from
        });

        this.setState({
            tasks:
                executionData && executionData.length > 0
                    ? executionData.sort((a, b) => (new Date(b.initiatedOn).getTime() < new Date(a.initiatedOn).getTime() ? -1 : 1))
                    : [],
        });
        if (forceOpenCloseRightPanel !== null) {
            this.openCloseTaskContainer('taskContainer', forceOpenCloseRightPanel);
        }
    }

    componentWillUnmount() {
        this.startStopExecution(false);
    }

    onClickMore = () => {
        const { defaultValue } = this.state;
        const status = this.getStatus(defaultValue);
        if (status !== 0) {
            const { executionTaskStatusPageNo, executionsLoading, isExecutionTaskStatusLastPage, projectId, testCaseId } = this.props;
            if (status !== 0 && status != null && !executionsLoading && !isExecutionTaskStatusLastPage) {
                const _projectId = projectId || 0;
                const _testCaseId = testCaseId || -1;
                this.startStopExecution(true, status, _projectId, _testCaseId, Number(executionTaskStatusPageNo) + 1);
            }
        }
    };

    getExecutionTasks(data = {}) {
        const { openTaskPanel, getExecutionTasks } = this.props;
        const { expanded } = this.state;
        const isExpanded = (openTaskPanel && expanded) === 'taskContainer';
        if (isExpanded) {
            const { projectId = 0, testCaseId = -1 } = this.props;
            const { val, openPanel, executionTasksStatus = this.props.executionTasksStatus } = data;
            let _projectId = projectId;
            let _testCaseId = testCaseId;
            let isCustomCaseId;
            if (val !== undefined || openPanel !== undefined) {
                [_projectId, _testCaseId, isCustomCaseId] = this.getURLProject_Case_ID(projectId, testCaseId);
            }
            getExecutionTasks(_projectId, isCustomCaseId ? -1 : executionTasksStatus, _testCaseId, 1, true, val, openPanel);
        }
    }

    getURLProject_Case_ID = (projectId, testCaseId) => {
        let _projectId = projectId;
        let _testCaseId = testCaseId;
        let isCustomCaseId = false;
        if ((!projectId || !testCaseId) && window.location.pathname.toLowerCase().includes('/projects/')) {
            const arr = window.location.pathname.split('/');
            const ind = arr.findIndex((val) => val === 'details');
            if (!projectId) {
                _projectId = arr[ind + 1];
            }
            if (!testCaseId && arr.length > ind + 2) {
                isCustomCaseId = !!Number(arr[ind + 2]);
                _testCaseId = isCustomCaseId ? arr[ind + 2] : testCaseId;
            }
        }
        return [_projectId, _testCaseId, isCustomCaseId];
    };

    getStatus = (val) => {
        switch (val) {
            case 'Executed':
                return 1;
            case 'InProcess':
                return 0;
            case 'Error':
                return 2;
            case 'All':
                return -1;
            case 'Stopped':
                return 7;
            case 'Queued':
                return 8;
                
            default:
                return null;
        }
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    // Use this function for Execution -- [set isExecute = true] for execute it will automatically start interval if executionTasksStatus is 0
    // -- [set isExecute = false] for clearInterval
    startStopExecution = (
        isExecute,
        executionTasksStatus,
        __projectId = 0,
        __testCaseId = -1,
        page = 1,
        executionsPolling = false,
        val = null,
        openPanel = true,
    ) => {
        this.isExecRunning = false;
        clearInterval(this.interval);
        if (isExecute) {
            const { getExecutionTasks } = this.props;
            const [_projectId, _testCaseId, isCustomCaseId] = this.getURLProject_Case_ID(__projectId || 0, __testCaseId || -1);
            clearInterval(this.interval);
            getExecutionTasks(_projectId, isCustomCaseId ? -1 : executionTasksStatus, _testCaseId, page, executionsPolling, val, openPanel);
            if (Number(executionTasksStatus) === 0) {
                this.isExecRunning = true;
                this.interval = setInterval(() => {
                    this.getExecutionTasks({ val, openPanel, executionTasksStatus });
                }, 15000);
            }
        }
    };

    showExecutionVideo = (execUrl, caseName) => {
        const { showSnackBar, toggleModal } = this.props;
        if (execUrl) {
            toggleModal('video', null, execUrl, caseName);
        } else {
            showSnackBar('There is no video available yet');
        }
    };

    showExecutionStatus = (data) => {
        const { showSnackBar, toggleModal } = this.props;
        // @params modalName:string, route:string, url:string, modalData:any
        if (checkKeyInObject(data, 'classes')) {
            toggleModal('statusModal', null, null, data);
        } else {
            showSnackBar('There is no Execution data available');
        }
    };

    sortOuter = () => {
        const { selectedExecution, sortSelectedExecution, selectedExePreviousSortedType } = this.props;       
        let sortOrder = '';
        const { defaultValue, tasks } = this.state;
        const oldTasks = selectedExecution.length === 0 ? [...tasks] : [...selectedExecution];

        let { previousSortedType } = this.state;
        let dec = selectedExecution.length === 0 ? previousSortedType : selectedExePreviousSortedType;
        if (dec === 'sortDesc') {
            oldTasks.sort(function compare(a, b) {
                const dateA = new Date(a.initiatedOn);
                const dateB = new Date(b.initiatedOn);
                return dateB - dateA;
            });

            sortOrder = 'sortAsc';

        } else {
            oldTasks.sort(function compare(a, b) {
                const dateA = new Date(a.initiatedOn);
                const dateB = new Date(b.initiatedOn);
                return dateA - dateB;
            });

            sortOrder = 'sortDesc';
        }
        const newTasks = [...oldTasks];

        if (selectedExecution.length === 0) {
            this.setState({
                tasks: newTasks,
                previousSortedType: sortOrder,
                defaultValue,
            });
        } else {
            const data = {
                sortOrder,
                tasks: newTasks
            }
            sortSelectedExecution(data);
    }}

    sort = (val) => {
        const { projectId, testCaseId } = this.props;
        // const tasks = ProjectServices.activeExecutionsTasks(this.props.projectName);
        const status = this.getStatus(val);
        const _projectId = projectId || 0;
        const _testCaseId = testCaseId || -1;

        this.startStopExecution(true, status, _projectId, _testCaseId, null, null, val);
    };

    handleChange = (event) => {
        this.setState({ defaultValue: event.target.value, tasks: this.sort(event.target.value) });
    };

    changeScroll = (e) => {
        const { defaultValue } = this.state;
        const {selectedExecution} = this.props
        const status = this.getStatus(defaultValue);
        if (status !== 0 && e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight+50) {
            const { executionTaskStatusPageNo, executionsLoading, isExecutionTaskStatusLastPage, projectId, testCaseId } = this.props;
            if (status !== 0 && status != null && !executionsLoading && !isExecutionTaskStatusLastPage) {
                const _projectId = projectId || 0;
                const _testCaseId = testCaseId || -1;
                this.startStopExecution(selectedExecution.length > 0 ? false : true, status, _projectId, _testCaseId, Number(executionTaskStatusPageNo) + 1);
            }
        }
    };

    // SideBar Parent Expand/Collapse starts
    toggleTaskContainer = (panel) => (event, expanded) => {
        this.openCloseTaskContainer(panel, expanded);
    };

    // expanded: true [for open]
    // expanded: false [for close]
    openCloseTaskContainer = (panel, expanded) => {
        const { toggleSidebar } = this.props;
        localStorage.setItem('expandedTaskbar', expanded ? 'show' : 'hide');
        if (!this.isTimeOutRunning) {
            if (expanded) {
                // Fist resize middle content then expande sidebar
                toggleSidebar();
                this.isTimeOutRunning = true;
                setTimeout(() => {
                    this.setState({ expanded: panel }, () => {
                        this.isTimeOutRunning = false;
                    });
                }, 50);
            } else {
                // First collapse sidebar then resize middle content
                this.setState({ expanded: false }, () => {
                    this.isTimeOutRunning = true;
                    setTimeout(() => {
                        toggleSidebar();
                        this.isTimeOutRunning = false;
                    }, 50);
                });
            }
        }
    };
    convertToSelectValues = (inputArray = []) => {
        let outputArray = [];
        outputArray = inputArray.map((element) => {
            return {
                aiqExecution: element.aiqExecution,
                aiqReportData: element.aiqReportData,
                browser: element.browser,
                classes: element.classes,
                errorsCount: element.errorsCount,
                executionStatus: element.executionStatus,
                executionVideoUrl: element.executionVideoUrl,
                initiatedOn: element.initiatedOn,
                projectId: element.projectId,
                projectName: element.projectName,
                reportUrl: element.reportUrl,
                statusMessage: element.statusMessage,
                tcErrors: element.tcErrors,
                tcWarnings: element.tcWarnings,
                testCaseName: element.testCaseName,
                testcaseId: element.testcaseId,
                warningsCount: element.warningsCount,
                value: element.executionId,
                label: element.executionName,
            };
        });
        return outputArray;
    };
    changeSelection = (event) => {
        const { selectionExecutionAction } = this.props;
        const newArr = JSON.parse(JSON.stringify(event))
        const data ={
            newArr,
            event,
        }
        selectionExecutionAction(data);
    };

    refreshExecution = () => {
        const {defaultValue} = this.state
        const { selectionExecutionAction } = this.props;
        const data ={
            newArr: [],
            event: [],  
        }
        selectionExecutionAction(data);
        this.setState({ tasks: this.sort(defaultValue) });
    }
    // SideBar Parent Expand/Collapse ends

    render() {
        const {
            classes,
            executionsLoading,
            executionsPolling,
            openTaskPanel,
            toggleModal,
            selectedExecution,
            selectedExePreviousSortedType,
            searchedInput
        } = this.props;
        const { defaultValue, open, previousSortedType, tasks } = this.state;
        const _tasks = [];

        const [_projectId, _testCaseId] = this.getURLProject_Case_ID();
        if (checkArrayLength(tasks)) {
            tasks.forEach((task) => {
                if (
                    !_projectId ||
                    (_projectId && `${_projectId}` === `${task.projectId}` && _testCaseId) ||
                    !_testCaseId ||
                    (_testCaseId && `${_testCaseId}` === `${task.testcaseId}` && _projectId === task.projectId)
                ) {
                    _tasks.push(task);
                }
            });
        }
        const convertedItems = this.convertToSelectValues(_tasks);
        const sortedType = selectedExecution.length === 0 ? previousSortedType : selectedExePreviousSortedType;

        const content = (
            <div style={{ width: '100%', backgroundColor: '#fff' }}>
                <SelectCustom
                    value={searchedInput}
                    onChange={(data) => {
                        this.changeSelection(data);
                    }}
                    className={classes.selectCustom}
                    isBorder={false}
                    options={convertedItems}
                    selectedExecution = {selectedExecution}
                    isSearchForTaskpane
                    isMultiSelect
                    readOnly={false}
                    placeholder={!checkArrayLength(selectedExecution) ? 'Search Execution' : ''}
                    styleWithIcon={{ display: 'flex', width: 'calc(100% - 30px)', alignItems: 'center', marginLeft: 30 }}
                    startAdornment={<Search style={{ position: 'absolute' }} />}
                />

                <div className={classes.sort}>
                    <div style={{ color: '#4f4949' }}>
                        {
                            sortedType === 'sortAsc' ? (
                                <Tooltip data="Sort Ascending">
                                    <SortRoundedIcon
                                        className={classes.circleIcon}
                                        style={{transform: "rotate(180deg)" }}
                                        alt="SortDownIcon"
                                        onClick={this.sortOuter}
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip data="Sort Descending">
                                    <SortRoundedIcon className={classes.circleIcon} onClick={this.sortOuter} />
                                </Tooltip>
                            )
                        }
                    </div>
                    <FormControl style={{ width: '74%' }}>
                        <Select
                            open={open}
                            onClose={this.handleClose}
                            onOpen={this.handleOpen}
                            value={defaultValue}
                            onChange={(event) => this.handleChange(event)}
                            disabled={false}
                            className={classes.select}
                        >
                            <MenuItem value="All" className={classes.menuItem}>
                                All
                            </MenuItem>
                            <MenuItem value="Executed" className={classes.menuItem}>
                                Success
                            </MenuItem>
                            <MenuItem value="Error" className={classes.menuItem}>
                                Failed
                            </MenuItem>
                            <MenuItem value="InProcess" className={classes.menuItem}>
                                In Progress
                            </MenuItem>
                            <MenuItem value="Queued" className={classes.menuItem}>
                                Queued
                            </MenuItem>
                            <MenuItem value="Stopped" className={classes.menuItem}>
                                Stopped
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <div className={!openTaskPanel ? classes.listContainer : classes.listContainerPanel} onScroll={this.changeScroll}>
                        <ExecutionTasks
                            showExecutionStatus={this.showExecutionStatus}
                            getExecutionDetails={ProjectServices.getExecutionDetails}
                            tasks={selectedExecution.length === 0 ? _tasks : selectedExecution}
                            selectedExecution= {selectedExecution}
                            executionsLoading={executionsLoading}
                            executionsPolling={executionsPolling}
                            showExecutionVideo={this.showExecutionVideo}
                            highLightExecutionId={this.state.highLightExecutionId}
                            history={this.props.history}
                            toggleSwipeable={this.props.toggleSwipableTaskPanel}
                        />
                    </div>
                </div>
            </div>
        );

        const data = [
            <AccordionSummary key="1">
                <div style={{ display: 'flex', marginLeft: '9px', alignItems: 'center', cursor: 'default' }}>
                    <Tooltip data='Executions'>
                    <ViewList style={{ fontSize: '17px', color: '#ffffff' }} />
                    </Tooltip>
                    <Typography className={classes.title}>Execution</Typography>
                </div>
                <div className={classes.iconContainer}>
                    <Tooltip data="Refresh">
                    <IconButton className={classes.iconButton} onClick={this.refreshExecution}>
                        <AutorenewRoundedIcon alt="Refresh" className={classes.HeaderBarIcon} />
                    </IconButton>
                    </Tooltip>
                    <Tooltip data="Zoom In">
                        <IconButton 
                            className={classes.iconButton}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // Need setTimeout to close modal.
                                setTimeout(() => {
                                    this.props.toggleSwipableTaskPanel(false);
                                });
                                toggleModal('ExecutionModal', null, null);
                            }}
                        >
                      <ZoomOutMapIcon
                            className={classes.HeaderBarIcon}
                            alt="Full Screen"
                            title='Fullscreen'
                        />
                        </IconButton>
                    </Tooltip>
                    <Tooltip data="Close">
                        <IconButton 
                            className={classes.iconButton}
                            onClick={() => this.props.toggleSwipableTaskPanel(false)}

                        >
                            <RemoveCircleOutlineOutlinedIcon
                                className={classes.HeaderBarIcon}
                                alt="Close"
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            </AccordionSummary>,
            <AccordionDetails key="2" style={{ height: 'calc(100vh)' }}>
                {content}
            </AccordionDetails>,
        ];
        return (
            <div className={!openTaskPanel ? classes.taskPanelPaper : classes.taskPanelPaperPanel}>
                <Hidden only="xs">
                    <Accordion expanded onChange={!openTaskPanel ? this.toggleTaskContainer('taskContainer') : null}>
                        {data}
                    </Accordion>
                </Hidden>
                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                    <Accordion expanded onChange={() => this.props.toggleSwipableTaskPanel(false)}>
                        {data}
                    </Accordion>
                </Hidden>
            </div>
        );
    }
}

Tasks.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    executionTasksStatus: PropTypes.string.isRequired,
    executionsLoading: PropTypes.bool.isRequired,
    executionsPolling: PropTypes.bool,
    getExecutionTasks: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    toggleSwipableTaskPanel: PropTypes.func, // only for mobile
};
Tasks.defaultProps = {
    executionsPolling: false,
    toggleSwipableTaskPanel: () => {}, // empty function for web
};

const mapStateToProps = (state) => {
    return {
        // project reducer
        executionTasksStatus: state.projectReducer.executionTasksStatus,
        executionData: state.projectReducer.executions,
        executionsLoading: state.projectReducer.executionsLoading,
        executionTaskStatusPageNo: state.projectReducer.executionTaskStatusPageNo,
        executionsPolling: state.projectReducer.executionsPolling,
        isExecutionTaskStatusLastPage: state.projectReducer.isExecutionTaskStatusLastPage,
        isLoading: state.projectReducer.isLoading,
        projects: state.projectReducer.projects,
        forceOpenCloseRightPanel: state.projectReducer.forceOpenCloseRightPanel,
        // general reducera
        // query: state.generalReducer.queryValue,
        // task reducer
        projectId: state.taskReducer.projectId,
        testCaseId: state.taskReducer.testCaseId,
        openTaskPanel: state.generalReducer.openTaskPanel,
        selectedExecution: state.selectedExecutionReducer.selectedExecution,
        selectedExePreviousSortedType: state.selectedExecutionReducer.selectedExePreviousSortedType,
        searchedInput:state.selectedExecutionReducer.searchedInput,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getExecutionTasks: (...args) => dispatch(ProjectActions.getExecutionTasks(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        toggleSidebar: () => dispatch(ProjectActions.toggleTaskList()),
        selectionExecutionAction: (data) => dispatch(selectionExecutionAction(data)),
        sortSelectedExecution: (data) => dispatch(sortSelectedExecution(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Tasks)));
