import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import google from './../signin/google.png';
import github from './../signin/github.png';
import jira from './../signin/jiraNew.png';
import { styles } from './style';

const Loginbuttons = (props) => {
    const { classes } = props;
    return (
        <div className={classes.buttonsArea}>
            <div
                aria-hidden
                className={classes.loginButtons}
                onClick={() => {
                    window.location.href = `./platform/v1/auth/google/login?hostUrl=${
                        (window.location.protocol === 'https:' ? 'https://' : 'http://') + window.location.hostname
                    }`;
                }}
            >
                <img src={google} style={{ width: '20px' }} alt="" />
                <p className={classes.platform}> Google</p>
            </div>
            <div className={classes.loginButtons} style={{ cursor: 'default' }}>
                <img src={github} className={classes.opacity} style={{ width: '22px' }} alt="" />
                <p className={[classes.platform, classes.opacity].join(' ')}>GitHub</p>
            </div>
            <div className={classes.loginButtons} style={{ cursor: 'default' }}>
                <img src={jira} className={classes.opacity} style={{ width: '20px' }} alt="" />
                <p className={[classes.platform, classes.opacity].join(' ')}>JIRA</p>
            </div>
        </div>
    );
};

export default withStyles(styles)(Loginbuttons);
