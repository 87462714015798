import axios from 'axios';
import React from 'react';
import config from '../config'

class SetCookies extends React.Component {
    componentDidMount() {
        axios
            .post(`${config.api.endpoint}/setAuthCookie`, this.props.location.search.split('?token=')[1], {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: `Bearer ${this.props.location.search.split('?token=')[1]}`,
                },
            })
            .then(() => {
                // console.log(response);
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }

    render() {
        return <div>Please wait....</div>;
    }
}

export default SetCookies;
