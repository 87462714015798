// import packages
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import utils
import { TestCaseActions } from '../../../store/actions';
import { checkArrayLength, checkObject } from '../../../utils';
// import custom components
import ScreenShotModal from '../Case/ScreenShotModal';

class ExecutionHelper extends Component {
    getLatestSteps = () => {
        const { instrNumArray, testSteps } = this.props;
        const _testSteps = [];
        if (checkArrayLength(instrNumArray)) {
            instrNumArray.forEach((instrNum) => {
                if (checkObject(testSteps) && testSteps[`${instrNum}`]) {
                    _testSteps.push(testSteps[instrNum]);
                }
            });
        }
        return _testSteps;
    };

    handleModalTreverseStep = (isScreenshotModalOpen, info, direction) => {
        const { testSteps } = this.props;
        const _testSteps = Object.values(testSteps);
        let { currentStepScreenshotModalIndex } = info;
        if (checkArrayLength(_testSteps)) {
            if (direction === 'next' && info.currentStepScreenshotModalIndex < _testSteps.length - 1) {
                currentStepScreenshotModalIndex = info.currentStepScreenshotModalIndex + 1;
            } else if (direction === 'prev' && info.currentStepScreenshotModalIndex > 0) {
                currentStepScreenshotModalIndex = info.currentStepScreenshotModalIndex - 1;
            }
        }
        this.props.toggleScreenShotModal(isScreenshotModalOpen, currentStepScreenshotModalIndex);
    };

    render() {
        const { currentStepScreenshotModalIndex, isScreenshotModalOpen, testSteps, selectedProject } = this.props;

        let modal = null;
        const _testSteps = Object.values(testSteps);
        if (isScreenshotModalOpen && checkArrayLength(_testSteps)) {
            const screenShortSteps = this.getLatestSteps();
            modal = (
                <ScreenShotModal
                    screenshotmodal={isScreenshotModalOpen}
                    handleModal={this.props.toggleScreenShotModal}
                    handleModalTreverseStep={this.handleModalTreverseStep}
                    testStep={
                        checkArrayLength(screenShortSteps) && screenShortSteps[currentStepScreenshotModalIndex]
                            ? screenShortSteps[currentStepScreenshotModalIndex]
                            : _testSteps[currentStepScreenshotModalIndex]
                    }
                    testSteps={
                        checkArrayLength(screenShortSteps) && screenShortSteps[currentStepScreenshotModalIndex] ? screenShortSteps : _testSteps
                    }
                    currentStepScreenshotModalIndex={currentStepScreenshotModalIndex}
                    stepsLength={checkArrayLength(screenShortSteps) ? screenShortSteps.length : _testSteps}
                    isFullScreenShot={selectedProject.isFullScreenshot}
                />
            );
        }
        return <div>{modal}</div>;
    }
}

ExecutionHelper.propTypes = {
    classes: PropTypes.shape({}),
};

ExecutionHelper.defaultProps = {
    classes: {},
};

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        selectedProject: state.projectReducer.selectedProject,
        currentStepScreenshotModalIndex: state.testCaseReducer.currentStepScreenshotModalIndex,
        isScreenshotModalOpen: state.testCaseReducer.isScreenshotModalOpen,
        testSteps: state.executionReducer.testSteps,
        instrNumArray: state.executionReducer.instrNumArray,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleScreenShotModal: (...args) => dispatch(TestCaseActions.toggleScreenShotModal(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExecutionHelper);
