import { ActionTypes } from '../constants';

class generalModalActions {
    static changeTab(index) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.CHANGE_TAB,
                payload: index,
            });
        };
    }

    static clearGeneralModal() {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.CLEAR_GENERAL_MODAL,
            });
        };
    }

    static updateTabData(key, data) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.UPDATE_TAB_DATA,
                payload: { key, data },
            });
        };
    }
}

export default generalModalActions;
