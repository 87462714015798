// import materials
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// import packages
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// import custom icons
import Customers from '../images/Customers.svg';
import Profile from '../images/profile.svg';
// import Groups from './../images/account.svg';
import People from '../images/people.svg';
import btmImg from './AutonomiQ_AI_image.svg';
// import Custom components
import Cm from '../../../components/Admin/user';

// Styles
import { styles } from './styles';
import { GeneralActions, ModalActions } from '../../../store/actions';
import config from '../../../config';
import { USER_NAME } from '../../../common/constants';

const { companyName } = config;
class AdminConsoleView extends React.Component {
    constructor(props) {
        super(props);
        props.updateTitle('Console');
    }

    componentWillUnmount() {
        this.props.clearSnackBar();
    }

    goToCompenent = (value) => {
        const { history } = this.props;
        history.push(`${value}`);
    };

    render() {
        const { classes, user } = this.props;
        let AppuserFlag = false; // 'false' is indicate user is not an appuser
        if (user && user.role === 'ADMIN') {
            // change the flag value if user has ADMIN role
            if (user.name === USER_NAME) {
                AppuserFlag = true;
            }
        }
        const content = [
            <Cm
                alt="Profile"
                source={Profile}
                title="Profile"
                text="Update Information about you"
                action={() => {
                    this.goToCompenent(user && user.role && user.role.toLocaleUpperCase() === 'SDET' ? '/profile' : '/admin/profile');
                }}
                isDisable={false}
            />,
            <Cm
                alt="Users"
                source={People}
                title="Users"
                text="Add, modify and manage users"
                action={() => {
                    this.goToCompenent('/admin/users');
                }}
                isDisable={false}
            />,
            // <Cm
            //     alt={`Groups`}
            //     source={Groups}
            //     title={`Groups`}
            //     text={`Create and manage groups`}
            //     action={() => { this.goToCompenent('/admin/groups') }}
            //     isDisable={false}
            // />,
        ];
        if (AppuserFlag) {
            content.push(
                <Cm
                    alt="Customers"
                    source={Customers}
                    title="Customers"
                    text="Manage your accounts"
                    action={() => {
                        this.goToCompenent('/admin/accounts');
                    }}
                    isDisable={false}
                />,
            );
        }
        return (
            <div>
                <div className={classes.rootContainer}>
                    <div className={classes.headerContainer}>
                        <Typography variant="h5" className={classes.headerTitle}>
                            Welcome to Admin console
                        </Typography>
                        <Typography className={classes.thanks}>{`Thanks for choosing ${companyName}`}</Typography>
                        <br />
                        <img src={btmImg} alt="" style={{ maxWidth: '100%', margin: '4px 0px 15px 0px', width: '190px' }} />
                    </div>
                    <div className={classes.bodyContainer}>
                        {content.map((item, index) => (
                            <div key={index}>{item}</div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

AdminConsoleView.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearSnackBar: () => dispatch(ModalActions.clearSnackbar()),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminConsoleView));
