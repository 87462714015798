import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChevronRight from '@material-ui/icons/ChevronRight';
import React from 'react';

import { Link } from 'react-router-dom';
import { THREE_DOT_MENU_CSS } from './cssConstants';
import Tooltip from '../components/Tooltip';
import { checkKeyInObject } from '../utils';
import { GOTO_STEP_EDITOR } from './constants';

const styles = () => ({
    cell_: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        paddingLeft: '10px !important',
        width: '100%',
        maxWidth: '100%',
        '&>:first-child': {
            paddingLeft: '10px !important',
            display: 'flex',
        },
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
    },
    faIcons: {
        fontSize: 17,
    },
    iconButton: {
        width: '30px',
        height: '30px',
        marginLeft: 5,
        marginRight: 5,
    },
    root: {
        paddingLeft: '10px',
        width: '100%',
    },
    iconButtonRoot: {
        width: '30px',
        height: '30px',
    },
    btnColor: {
        color: '#ff9700',
    },
    disabledBtnColor: {
        color: '#afb7ac',
    },
});

const SuiteActions = (props) => {
    const { classes, isCaseBtnsDisable, onDeleteTestCase, executionRoute, testCaseRoute, goToTestCase, testCase, removeDeleteCase } = props;
    const isDisableEyeIcon = !(
        checkKeyInObject(testCase, 'last_execution_id', 'value', false) ||
        checkKeyInObject(testCase, 'lastExecutionId', 'value', false) ||
        checkKeyInObject(testCase, 'testexecutionId', 'value', false) || 
        checkKeyInObject(testCase, 'isAiqExecution', 'value', false)
    );
    const removeDelete = !!removeDeleteCase;
    const gotoExectionComponent = (
        <IconButton className={classes.iconButton} aria-label="GotoExecution" disabled={isDisableEyeIcon}>
            <FontAwesomeIcon
                icon={faEye}
                aria-label="faEye"
                id="faEye"
                className={`${classes.moreIconsBtn} ${classes.faIcons} ${isDisableEyeIcon ? classes.disabledBtnColor : classes.btnColor} `}
            />
        </IconButton>
    );

    const renderGotoExectionButton = (_executionRoute) => {
        if (Boolean(_executionRoute) && !isDisableEyeIcon) {
            return (
                <Tooltip data="Go to Execution">
                    <Link to={`${_executionRoute}`}>{gotoExectionComponent}</Link>
                </Tooltip>
            );
        }

        return <Tooltip data="Go to Execution">{gotoExectionComponent}</Tooltip>;
    };

    return (
        <div className={classes.root}>
            <div>
                {!removeDelete ? (
                    <Tooltip data="Delete Test Case">
                        <IconButton className={classes.iconButtonRoot} aria-label="Delete" onClick={onDeleteTestCase} disabled={isCaseBtnsDisable}>
                            <FontAwesomeIcon
                                className={`${classes.moreIconsBtn} ${classes.faIcons} ${
                                    isCaseBtnsDisable ? classes.disabledBtnColor : classes.btnColor
                                }`}
                                icon={faTrashAlt}
                                aria-label="faTrashAlt"
                                id="faTrashAlt"
                            />
                        </IconButton>
                    </Tooltip>
                ) : null}

                {renderGotoExectionButton(executionRoute)}
                <Tooltip data={GOTO_STEP_EDITOR}>
                    <Link to={`${testCaseRoute}`}>
                        <IconButton
                            className={classes.iconButtonRoot}
                            aria-label={GOTO_STEP_EDITOR}
                            onClick={goToTestCase}
                            disabled={isCaseBtnsDisable}
                        >
                            <ChevronRight
                                className={`${classes.moreIconsBtn} ${isCaseBtnsDisable ? classes.disabledBtnColor : classes.btnColor}`}
                                aria-label="chevronRightIcon"
                                id="chevronRightIcon"
                            />
                        </IconButton>
                    </Link>
                </Tooltip>
            </div>
        </div>
    );
};

export default withStyles(styles)(SuiteActions);
