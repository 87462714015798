// material-ui component
import { connect } from 'react-redux';
import React from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';

import AdminLegal from './AdminLegal';
import AdminProfile from './AdminProfile';
import SauceLabsDetails from './SauceLabsDetails';
import AdminSecurity from './AdminSecurity';
import Header from './Header';
import HeaderNav from './HeaderNav';
import { AccountActions, GeneralActions, UserActions, ModalActions } from '../../store/actions';
import { checkObject, checkArrayLength } from '../../utils/utils';
import config from '../../config';
import AdminSettings from './AdminSettings';
import {defaultStartDate,defaultAccountEndDate, SEGMENT_EVENT} from '../../common/constants'
import { BLACK_FONT } from '../../common/cssConstants';
import moment from 'moment';
import { track } from '../../services/Segment';

// constants and variables
let isMounted = false;

const styles = (theme) => ({
    root: {
        width: 'calc(100% - 40px)',
        margin: '20px',
    },
    containerMain: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 99px)',
    },
    titleText: {
        color: BLACK_FONT,
        fontSize: '25px',
        marginTop: '5px',
        fontWeight: 'normal',
        marginLeft: '20px',
    },
    title: {
        display: 'flex',
        marginTop: '15px',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    container: {
        padding: '30px 0 40px 0',
        backgroundColor: '#f4f6f7',
        [theme.breakpoints.only('xs')]: {
            padding: 0,
        },
    },
});

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: {},
            selectedTab: 0,
            userDetails: {
                name: '',
                language: 'US',
                timeZone: 'PDT',
                email: '',
                accountName: '',
            },
            profileImage: null,
            companyImage: null,
        };
        props.updateTitle('Profile');
    }

    componentDidMount() {
        const { getAccounts, getUserById, user } = this.props;
        isMounted = true;
        getUserById(user.id);
        getAccounts(user.accountId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { account, userDetails } = this.state;
        if (!userDetails.name || this.props.userDetails.name !== nextProps.userDetails.name) {
            this.setStateIfComponentMounted({ userDetails: nextProps.userDetails });
        }
        if (!checkObject(account)) {
            this.setStateIfComponentMounted({ account: this.getAccount(nextProps.accounts) });
        }
    }

    componentWillUnmount() {
        isMounted = false;
        this.props.clearSnackBar();
    }

    onDrop = (profileImage) => {
        this.props.showSnackBar('Click on save button to apply changes.', '', false, 8000, true);
        this.setStateIfComponentMounted({
            profileImage,
        });
    };

    onCompanyDrop = async (companyImage) => {
        const {
            accounts,
            updateAccount,
        } = this.props 
        let account = {};
        if (checkArrayLength(accounts)) {
            account = accounts.find((acc) => acc.accountId === this.props.user.accountId);
        }
        console.log(account,'acc',companyImage)
        const accObj = {
            accountId: account.accountId,
            accountName: account.accountName,
            disabledStatus: account.disabledStatus,
            address: account.address,
            customCodeLocation: account.customCodeLocation,
            sePort: account.sePort,
            logoLocation: account.logoLocation,
            isTunnelOn: account.isTunnelOn,
            description: account.description,
            accountStartDate: `${moment(defaultStartDate).format('YYYY-MM-DD')}T00:00:00Z`,
            accountEndDate: `${moment(defaultAccountEndDate).format('YYYY-MM-DD')}T00:00:00Z`,
            execsPerTestcase: account.execsPerTestcase,
        }
        
        const formData = new FormData();
        formData.append('params', JSON.stringify(accObj));
        if (checkArrayLength(companyImage)) {
            formData.append('logoFile', companyImage[0]);
        }        
        await updateAccount(formData)
        this.props.showSnackBar('Changes saved.', '', false, 8000, true);
    };

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted) this.setState(obj, callback);
    };

    getAccount = (accounts) => {
        let account = {};
        if (checkArrayLength(accounts)) {
            account = accounts.find((acc) => acc.accountId === this.props.user.accountId);
        }
        return account;
    };


    trackSegmentEvent = (val) => {


        let eventName = '';
        
        switch (val) {
            case 0:
                eventName = 'PROFILE'
                break;
            case 1:
                eventName = 'SECURITY'
                break;
            case 2:
                eventName = 'SAUCELABS_CREDENTIALS'
                break;
            case 3:
                eventName = 'SETTINGS'
                break;
            case 4:
                eventName = 'LEGAL_AND_COMPLIANCE'
                break;
            default:
                eventName = ''
             
        }

        if(!eventName) {
            return ;
        }
        let segmentEventName = SEGMENT_EVENT.USER_SETTINGS_EVENT[eventName];
        if(segmentEventName) {
            track(segmentEventName,{});
        }
    }

    handleChange = (event, value) => {

       // this.trackSegmentEvent(value)

        this.setStateIfComponentMounted({ selectedTab: value });
    };

    handleSelectionChange = ({ name, value }) => {
        const { userDetails } = this.state;
        userDetails[name] = value;
        this.setStateIfComponentMounted({ userDetails });
    };

    render() {
        const { classes, postUserProfileInfo } = this.props;
        const { account, selectedTab, userDetails, profileImage } = this.state;
        const profileImageLink = userDetails && userDetails?.imgLoc ? `${config.api.endpoint}/testCases/previewScreenShot?screenShotURL=${userDetails.imgLoc}` : '';
        const companyLogoPreivewLink =
            account && account.logoLocation && `${config.api.endpoint}/testCases/previewScreenShot?screenShotURL=${account.logoLocation}`;
        return (
            <div className={classes.containerMain}>
                <div className={classes.root}>
                    <div style={{ backgroundColor: 'white' }}>
                        <Header
                            userDetails={this.props.userDetails}
                            onDrop={this.onDrop}
                            onCompanyDrop={this.onCompanyDrop}
                            profileImageLink={profileImageLink}
                            profileImage={profileImage}
                            companyLogo={companyLogoPreivewLink}
                            selectedTab={selectedTab}
                            companyName={(account && account.accountName) || ''}
                        />
                        <HeaderNav selectedTab={this.state.selectedTab} handleChange={this.handleChange} />
                        <div className={classes.container}>
                            {selectedTab === 0 && (
                                <AdminProfile
                                    userDetails={userDetails}
                                    profileImage={profileImage}
                                    actions={{
                                        postUserProfileInfo,
                                        handleSelectionChange: this.handleSelectionChange,
                                    }}
                                />
                            )}
                            {selectedTab === 1 && <AdminSecurity />}
                            {selectedTab === 2 && <SauceLabsDetails/>}
                            {selectedTab === 3 && <AdminSettings />}
                            {selectedTab === 4 && <AdminLegal/>}
                           
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: state.accountReducer.accounts,
    singleAccount: state.accountReducer.singleAccount,
    user: state.authReducer.user,
    userDetails: state.userReducer.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
    getAccounts: (accountId) => dispatch(AccountActions.getAccounts(accountId)),
    getAccountById: (...args) => dispatch(AccountActions.getAccountById(...args)),
    getUserById: (userId) => dispatch(UserActions.getUserById(userId)),
    postUserProfileInfo: (...args) => dispatch(UserActions.postUserProfileInfo(...args)),
    showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
    clearSnackBar: () => dispatch(ModalActions.clearSnackbar()),
    updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
    updateAccount: (...args) => dispatch(AccountActions.updateAccount(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Admin)));
