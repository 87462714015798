import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CloseOnEscape from 'react-close-on-escape';
import { getFormatedDate } from '../../../utils/utils';
import {
    DIALOG_ROOT,
    MODAL_HEADER_TITLE,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_HEADER_CLOSE_ICON,
    MODAL_SUBMIT_BUTTON,
    MODAL_CANCEL_BUTTON,
    MODAL_FOOTER,
    BLACK_FONT,
} from '../../../common/cssConstants';

const styles = (theme) => ({
    dialogTitleStyle: MODAL_HEADER_TITLE,
    dialog: {
        textAlign: 'center',
        backgroundColor: '#e9effd',
        borderBottom: '1px solid #dcdcdc',
    },
    formControl: {
        width: '100%',
        flexDirection: 'row',
    },
    dialogModal: {
        '&>div': {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    dataName: {
        width: 92,
        float: 'left',
        fontSize: 12,
    },
    dataValue: {
        float: 'left',
        fontSize: 12,
        fontWeight: 'bold',
        maxWidth: 'calc(100% - 100px)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    dialogContent: {
        overflow: 'hidden',
        width: 370,
        padding: '0 !important',
        marginLeft: '30px',
        marginRight: '30px',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 100px)',
        },
    },
    mainHeading: {
        width: '100%',
        float: 'left',
        fontSize: 12,
        color: BLACK_FONT,
        marginBottom: 20,
    },
    deleteButtonDanger: {
        backgroundColor: '#fff !important',
        color: '#ff0000 !important',
        border: '1px solid #ff0000 !important',
        '&:hover': {
            backgroundColor: '#ff0000 !important',
            color: '#fff !important',
        },
    },
});

class DeleteTestSuite extends Component {
    state = {
        isDisabled: false,
    };

    render() {
        const { classes, handleClose, open, deleteTestSuiteData, isDisableCase, deleteButton } = this.props;

        const { isDisabled } = this.state;

        return (
            <div>
                <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" className={classes.dialogModal}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            {`${isDisableCase}` === '0' ? 'Delete' : `${isDisableCase}` === '1' ? 'Disable' : 'Restore'} Test Suite
                        </DialogTitle>
                        <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                            <Close style={MODAL_HEADER_CLOSE_ICON} onClick={() => handleClose(false)} aria-label="closeIcon" id="closeIcon" />
                        </div>
                    </div>
                    <form style={{ overflowY: 'auto', maxHeight: '150px' }}>
                        <DialogContent className={classes.dialogContent}>
                            <Typography className={classes.mainHeading}>
                                Are you sure you want to {`${isDisableCase}` === '0' ? 'delete' : `${isDisableCase}` === '1' ? 'disable' : 'restore'}?
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <div
                                    style={{
                                        width: '100%',
                                        paddingBottom: 2,
                                    }}
                                >
                                    <Typography className={classes.dataName}>Suite name</Typography>
                                    <Typography className={classes.dataValue}>
                                        {`${deleteTestSuiteData ? deleteTestSuiteData.testSuiteName : ''}`}
                                    </Typography>
                                </div>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <div
                                    style={{
                                        width: '100%',
                                        paddingBottom: 2,
                                    }}
                                >
                                    <Typography className={classes.dataName}>Project name</Typography>
                                    <Typography className={classes.dataValue}>
                                        {`${deleteTestSuiteData ? deleteTestSuiteData.projectName : ''}`}
                                    </Typography>
                                </div>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <div
                                    style={{
                                        width: '100%',
                                        paddingBottom: 2,
                                    }}
                                >
                                    <Typography className={classes.dataName}>Last run</Typography>
                                    <Typography className={classes.dataValue}>
                                        {deleteTestSuiteData &&
                                        deleteTestSuiteData.creationTime &&
                                        deleteTestSuiteData.creationTime === deleteTestSuiteData.lastRunTime
                                            ? 'N/A'
                                            : deleteTestSuiteData
                                            ? getFormatedDate(deleteTestSuiteData.lastRunTime, 'MMM D, YYYY', 'N/A')
                                            : 'N/A'}
                                    </Typography>
                                </div>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <div
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Typography className={classes.dataName}>Test cases</Typography>
                                    <Typography className={classes.dataValue}>
                                        {`${
                                            deleteTestSuiteData && deleteTestSuiteData.testCases && deleteTestSuiteData.testCases.length
                                                ? deleteTestSuiteData.testCases.length
                                                : 0
                                        }`}
                                    </Typography>
                                </div>
                            </FormControl>
                        </DialogContent>
                    </form>

                    <DialogActions style={MODAL_FOOTER}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                this.setState({ isDisabled: true });
                                handleClose(true);
                            }}
                            disabled={isDisabled}
                            className={deleteButton === 'Danger' ? classes.deleteButtonDanger : ''}
                            style={MODAL_SUBMIT_BUTTON}
                        >
                            {`${isDisableCase}` === '0' ? 'Delete' : `${isDisableCase}` === '1' ? 'Disable' : 'Enable'}
                        </Button>
                        <CloseOnEscape onEscape={() => handleClose(false)}>
                            <Button variant="outlined" color="secondary" onClick={() => handleClose(false)} style={MODAL_CANCEL_BUTTON}>
                                Cancel
                            </Button>
                        </CloseOnEscape>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(DeleteTestSuite);
