// import material
import { withStyles } from '@material-ui/core/styles';
// import packages
import React from 'react';
import { connect } from 'react-redux';

// import icons
import Add from '@material-ui/icons/Add';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import Delete from '@material-ui/icons/Delete';
import Download from 'mdi-material-ui/Download';
import Edit from '@material-ui/icons/Edit';
import SettingsBackupRestore from '@material-ui/icons/SettingsBackupRestore';
import Image from '@material-ui/icons/InsertPhoto';

// import custom component
import Tooltip from '../../../../components/Tooltip';
import { SelectedTestCaseActions, TestCaseActions } from '../../../../store/actions';
import { checkArrayLength, checkKeyInObject } from '../../../../utils';
import { TestStepUtils } from '../../../../utils/TestStepUtils';
import { MUI_ACTION_MENU_ICON } from '../../../../common/cssConstants';
import { MenuOptions } from '../../../../components/Button/MenuOptions';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import GpsFixedOutlinedIcon from '@material-ui/icons/GpsFixedOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { IconButton } from '@material-ui/core';
const styles = () => ({
    actionIcon: {
        fontSize: 22,
        height: '24px',
        width: '24px',
        padding: '3px',
    },
    actionIconDisable: {
        color: '#bfbfc0',
        cursor: 'not-allowed',
        pointerEvents: 'none',
    },
    actionIconEnable: {
        color: '#5F7B96',
        cursor: 'pointer',
        pointerEvents: 'all',
    },
    alignItemC: {
        alignItems: 'center',
    },
    checkBoxIcon: {
        color: '#0092e5',
        cursor: 'pointer',
        fontSize: '20px',
    },
    checkBoxIconBlank: {
        color: '#bac5d0',
        cursor: 'pointer',
        fontSize: '20px',
    },
    deleteIcon: {
        fontSize: 20,
        cursor: 'pointer',
    },
    moreIcons: MUI_ACTION_MENU_ICON,

    rotateIconStyle: {
        color: '#0092e6',
        cursor: 'pointer',
        fontSize: 20,
       
    },
    updateAction: {
        position: 'absolute',
        top: '50%',
        right: '17%',
        transform: 'translate(0, -50%)',
    },
});

class RowActionButtons extends React.Component {
    renderItems(index) {
        const {
            actionType,
            classes,
            cacheXpaths,
            isExecution,
            onClickAlertDialogOpen,
            stepsType,
            toggleStepCacheXpath,
            testStep,
            toggleScreenShotModal,
            actionFunctions,
        } = this.props;
        let items = [];
        if (!isExecution) {
            items = [
                {
                    action: () => toggleScreenShotModal(true, index),
                    icon: <Image className={classes.moreIcons} />,
                    isDisabled: false,
                    name: 'Screen Shot',
                },
                {
                    action: () => {
                        TestStepUtils.onEditStep(index);
                    },
                    icon: <Edit className={classes.moreIcons} />,
                    isDisabled: actionType === 'edit',
                    name: 'Update Step',
                },
                {
                    action: () => {
                        TestStepUtils.onAddStep(index, true, testStep.instrNum, {
                            // this param is used to copy step
                            testInstruction: testStep.instr,
                            testData: testStep.data,
                            sendToTestCaseParser: testStep.sendToTestCaseParser,
                        });
                    },
                    icon: <Add className={classes.moreIcons} />,
                    isDisabled: actionType === 'add' && !testStep.isNew,
                    name: 'Add New',
                },
                {
                    action: () => {
                        onClickAlertDialogOpen();
                    },
                    icon: <Delete className={classes.moreIcons} />,
                    isDisabled: actionType === 'delete',
                    name: 'Delete',
                },
            ];
            if (stepsType === 'live') {
                items.push({
                    action: () => {
                        toggleStepCacheXpath(!cacheXpaths[testStep.instrNum], Number(testStep.instrNum));
                    },
                    icon: cacheXpaths[testStep.instrNum] ? (
                        <CheckBox className={classes.moreIcons} />
                    ) : (
                        <CheckBoxBlank className={classes.moreIcons} />
                    ),
                    isDisabled: actionType === 'delete',
                    name: 'CacheXpaths',
                });
            }
            if (!testStep.hasChild) {
                items.splice(3, 0, {
                    action: () => {
                        if (testStep.htmlPath) actionFunctions.callForLiveDebug(testStep.instrNum, () => {}, testStep.htmlPath);
                        else this.props.shwoTost();
                    },
                    icon: <SettingsBackupRestore className={classes.moreIcons} />,
                    isDisabled: false,
                    name: 'Live Debug',
                });
            }
        }
        if (stepsType === 'live') {
            items.push({
                action: () => {
                    TestStepUtils.downloadStepFile(testStep);
                },
                icon: <Download className={classes.moreIcons} />,
                isDisabled: !testStep.downloadFile,
                name: 'Get Downloaded File',
            });
        }
        return items;
    }

    render() {
        const {
            actionType,
            cacheXpaths,
            classes,
            index,
            isExecution,
            isSmartRetryStep,
            isDebugPointStep,
            isWsRunning,
            listView,
            onClickAlertDialogOpen,
            selectedSteps,
            stepsType,
            styles: _styles,
            testStep,
            testCaseId,
            toggleStepCacheXpath,
            wsRunningCaseDataCurrCase,
            actionFunctions,
        } = this.props;

        const sessionId = checkKeyInObject(wsRunningCaseDataCurrCase, 'sessionId');
        const testStepObj = checkKeyInObject(wsRunningCaseDataCurrCase, 'testStepObj');
        const showStepActions =
            !isWsRunning[testCaseId] &&
            !checkArrayLength(selectedSteps) &&
            !['add', 'edit', 'delete'].includes(actionType) &&
            ((sessionId && testStepObj && sessionId === testStepObj.sessionId && ['13', '16'].includes(`${testStepObj.msgType}`)) || // if run from tab 1 & get msgType 13 | 16 in tab 2 don't show tray
                (!sessionId && !testStepObj)); // if no session id then should not have testStepObj i.e not running
        const actionBar = (
            <div
                className="actionButtons extra displayFlex justifyCSA"
                style={{
                    ...(['add', 'edit', 'delete'].includes(actionType)
                        ? {}
                        : listView
                        ? { marginLeft: 0, padding: '4px 0px 0px 10px' }
                        : { marginLeft: 0, padding: '5px 5px 1px 0' }),
                    ..._styles,
                }}
            >
                {!isExecution && [
                    // Commented this condition because of this issue https://github.com/Autonomiq/QA/issues/1656
                    /* testStep && testStep.sendToTestCaseParser && */
                   
                    actionType !== 'edit' && (
                        <Tooltip key={2} data="Update">
                            <IconButton
                                onClick={() => {
                                    TestStepUtils.onEditStep(index);
                                }}
                                style={{ color: '#5F7B96', fontSize: "22px", cursor: 'pointer' }}>
                                <CreateOutlinedIcon  alt='Update'  />
                            </IconButton>
                        </Tooltip>
                    ),
                //     <Tooltip key={1} data="View Screen">
                //     <img
                //     src={viewScreenImg}
                //     alt='View Screen'
                //         className={[
                //             classes.actionIcon,
                //             classes[testStep.hasChild && !testStep.isBlockStep ? 'actionIconDisable' : 'actionIconEnable'],
                //         ].join(' ')}
                //         onClick={() => {
                //             toggleScreenShotModal(true, index);
                //         }}
                //     />
                // </Tooltip>,

                // Commenting add button as it will go outside of this component

                    // !isSmartRetryStep && actionType !== 'add' && !newStep && !testStep.isNew ? (
                    //     <Tooltip key={3} data="Add Step">
                    //         <Add
                    //             className={`${classes.actionIcon} ${classes.actionIconEnable}`} // requesteb by puneet on call 07/04/2020
                    //             onClick={() => {
                    //                 TestStepUtils.onAddStep(
                    //                     index,
                    //                     true,
                    //                     testStep.instrNum,
                    //                     {
                    //                         testInstruction: testStep.instr,
                    //                         testData: testStep.data,
                    //                         sendToTestCaseParser: testStep.sendToTestCaseParser,
                    //                         expectedResults: testStep.expectedResults || '',
                    //                     }, // this parameter is used to copy step
                    //                 );
                    //             }}
                    //         />
                    //     </Tooltip>
                    // ) : null,
                    stepsType === 'live' && actionType !== 'add' && !testStep.hasChild && (
                        <Tooltip key={4} data="Live Debug">
                            <IconButton
                                onClick={() => {
                                    if (testStep.htmlPath) actionFunctions.callForLiveDebug(testStep.instrNum, () => { }, testStep.htmlPath);
                                    else this.props.shwoTost();
                                }}
                                style={{ color: '#5F7B96', marginLeft: '10px', fontSize: "22px", cursor: 'pointer' }}
                            >
                                <GpsFixedOutlinedIcon
                                    // fontSize="medium"

                                    alt='live Debug'
                                />
                            </IconButton>
                        </Tooltip>
                    ),
                    !isSmartRetryStep && !isDebugPointStep && actionType !== 'delete' && (
                        <Tooltip key={5} data="Delete">
                            <IconButton
                            style={{ color: '#5F7B96', marginLeft: '10px', fontSize: "22px", cursor: 'pointer' }}
                             onClick={() => {
                                onClickAlertDialogOpen();
                            }}>
                                <DeleteIcon
                                    // fontSize="medium"
                                    alt='Delete'
                                />
                            </IconButton>
                           
                        </Tooltip>
                    ),
                    // stepsType === 'live' && actionType !== 'delete' && (
                    //     // actionType !== 'delete' this condition needs to be changed according to action types
                    //     <span
                    //         aria-hidden
                    //         key={6}
                    //         onClick={() => {
                    //             toggleStepCacheXpath(!cacheXpaths[testStep.instrNum], Number(testStep.instrNum));
                    //         }}
                    //     >
                    //         <Tooltip data="Use past information">
                    //             {cacheXpaths[testStep.instrNum] ? (
                    //                 <CheckBox className={classes.checkBoxIcon} />
                    //             ) : (
                    //                 <CheckBoxBlank className={classes.checkBoxIconBlank} />
                    //             )}
                    //         </Tooltip>
                    //     </span>
                    // ),
                ]}
                {stepsType === 'live' ? (
                    <Tooltip data="Get Downloaded File">
                        <IconButton style={{ color: '#5F7B96', marginLeft: '10px', fontSize: "22px", cursor: 'pointer' }} disabled={!testStep.downloadFile} 
                         onClick={() => TestStepUtils.downloadStepFile(testStep)}
                        >
                            <GetAppIcon
                                // fontSize="medium"
                                className={`${classes[!testStep.downloadFile ? 'actionIconDisable' : 'actionIconEnable']}`}
                                alt='Get Downloaded File'
                            />
                        </IconButton>
                       
                    </Tooltip>
                ) : null}

                {!isExecution &&  stepsType === 'live' && actionType !== 'delete' &&
                    <span key={6} style={{color: '#5F7B96',fontSize:"22px", marginLeft: '10px', }} onClick={() => { toggleStepCacheXpath(!cacheXpaths[testStep.instrNum], Number(testStep.instrNum)) }}>
                        <Tooltip data={"Use past information"}>
                            {cacheXpaths[testStep.instrNum]
                                ? (<CheckBox className={classes.checkBoxIcon} />)
                                : (<CheckBoxBlank className={classes.checkBoxIconBlank} />)
                            }
                        </Tooltip>
                    </span>
                }

            </div>
        );
        const content = [
            <div key={1} className={[classes.alignItemC, 'icon'].join(' ')}>
                <MenuOptions items={this.renderItems(index)} keepMounted />
            </div>,
            listView ? (
                <div key={2} className={classes.updateAction}>
                    {actionBar}
                </div>
            ) : (
                <div key={2}>{actionBar}</div>
            ),
        ];

        return showStepActions ? content : null;
    }
}

function mapStateToProps(state, props) {
    let wsRunningCaseDataCurrCase = state.projectReducer.wsRunningCaseData;
    wsRunningCaseDataCurrCase = checkKeyInObject(wsRunningCaseDataCurrCase, props.testCaseId, 'value', {});
    return {
        // Project reducer
        isWsRunning: state.projectReducer.isWsRunning,
        wsRunningCaseDataCurrCase,
        // Selected Test Case Reducer
        actionType: state.selectedTestCaseReducer.actionType,
        cacheXpaths: state.selectedTestCaseReducer.cacheXpaths,
        newStep: state.selectedTestCaseReducer.newStep,
        selectedSteps: state.selectedTestCaseReducer.selectedSteps,
        stepsType: state.selectedTestCaseReducer.stepsType,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleStepCacheXpath: (...args) => dispatch(SelectedTestCaseActions.toggleStepCacheXpath(...args)),
        // test case action
        toggleScreenShotModal: (...args) => dispatch(TestCaseActions.toggleScreenShotModal(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RowActionButtons));
