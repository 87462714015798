import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { PieChart, Pie, Cell } from 'recharts';
import { BLACK_FONT } from '../../common/cssConstants';

const styles = (theme) => ({
    circle_text_wrapper_alternate: {
        position: 'absolute',
        left: '0',
        top: '50%',
        width: '100%',
        textAlign: 'center',
        color: '#4d4d4d',
        transform: 'translateY(-50%)',
    },
    progress_value: {
        color: BLACK_FONT,
        fontWeight: 700,
        [theme.breakpoints.down('xs')]: {
            fontSize: 45,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 35,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 35,
        },
    },
    progress_symbol: {
        color: BLACK_FONT,
        marginLeft: '-5px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 18,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 15,
        },
    },
});
function CircularCard(props) {
    const { classes, fromDashboard, fromProjectDetails, fromProjectList_ListView, progress, screenHeight, data, colors, size, strokeWidth } = props;

    const progress_value_style =
        fromProjectDetails || fromProjectList_ListView
            ? { fontSize: '17px', marginTop: '3px' }
            : screenHeight
            ? { fontSize: screenHeight / 12.6 }
            : fromDashboard
            ? { fontSize: '38px' }
            : {};

    const progress_symbol_style = screenHeight
        ? { fontSize: screenHeight / 23.64 }
        : fromDashboard
        ? { fontSize: '15px' }
        : fromProjectList_ListView
        ? { marginLeft: '-3px' }
        : {};

    return (
        <div style={{ position: 'relative' }}>
            <PieChart
                style={{
                    maxWidth: '100%',
                }}
                width={size}
                height={size}
            >
                <Pie
                    data={data}
                    cx={size / 2 - 5}
                    cy={size / 2 - 5}
                    innerRadius={size / 2 - strokeWidth}
                    outerRadius={size / 2}
                    fill="#8884d8"
                    paddingAngle={1}
                    dataKey="value"
                    startAngle={450}
                    endAngle={90}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.name === 'default' ? '#eaeaea' : colors[index % colors.length]} />
                    ))}
                </Pie>
            </PieChart>
            <div className={classes.circle_text_wrapper_alternate} /* style={circle_text_wrapper_alternate_style} */>
                <Typography className={classes.progress_value} style={{ ...progress_value_style, lineHeight: '1', marginBottom: 0 }}>
                    {`${progress}`}{' '}
                    <span className={classes.progress_symbol} style={progress_symbol_style}>
                        %
                    </span>{' '}
                </Typography>
            </div>
        </div>
    );
}

CircularCard.propTypes = {
    progress: PropTypes.number.isRequired,
    colors: PropTypes.instanceOf(Array).isRequired,
    // classes: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         name: PropTypes.string.isRequired,
    //         value: PropTypes.number.isRequired,
    //     }).isRequired
    // ).isRequired,
    classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(CircularCard);
