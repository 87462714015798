import React, { Component } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Popper } from '@material-ui/core';
import PropTypes from 'prop-types';

let prevPos = null;
class DatePicker extends Component {
    updateCalender = () => {
        let withPortalEl = document.getElementsByClassName('react-datepicker-popper')[0];
        if (this.popperRef && this.popperRef.parentElement.style.transform && withPortalEl) {
            if (prevPos !== this.popperRef.parentElement.style.transform) {
                prevPos = this.popperRef.parentElement.style.transform;
                withPortalEl.style.visibility = 'hidden';
            }
        } else {
            prevPos = null;
        }
        const interval = setInterval(() => {
            withPortalEl = document.getElementsByClassName('react-datepicker-popper')[0];
            if (this.popperRef && this.popperRef.parentElement.style.transform && withPortalEl) {
                withPortalEl.style.transform = this.popperRef.parentElement.style.transform;
                withPortalEl.style.position = 'fixed';
                withPortalEl.style.visibility = 'visible';
                withPortalEl.style.zIndex = '1202';
                clearInterval(interval);
            }
        }, 0);
    };

    render() {
        const { calenderRef, ...rem } = this.props;
        const calendarContainer = ({ className, children }) => {
            this.updateCalender();
            return (
                <>
                    <Popper open placement="bottom-start" anchorEl={calenderRef} style={{ visibility: 'hidden' }}>
                        <div
                            ref={(node) => {
                                this.popperRef = node;
                            }}
                        >
                            {children}
                        </div>
                    </Popper>
                    <div className={className}>
                        <div style={{ position: 'relative' }}>{children}</div>
                    </div>
                </>
            );
        };
        return <ReactDatePicker {...rem} calendarContainer={calendarContainer} />;
    }
}

DatePicker.propTypes = {
    calenderRef: PropTypes.any.isRequired,
};
export default DatePicker;
