import { BLACK_FONT } from '../../common/cssConstants';

export const styles = () => ({
    buttonsArea: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '70%',
    },
    loginButtons: {
        boxShadow: '0px 1px 3.5px -1px grey',
        width: '30%',
        height: '40px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        cursor: 'pointer',
    },
    platform: {
        fontSize: 12,
        marginRight: 10,
        color: BLACK_FONT,
    },
    opacity: {
        opacity: '0.2',
    },
});
