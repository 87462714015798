import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { checkObject, checkKeyInObject } from '../../utils';

const styles = () => ({
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    progressContainer: {
        padding: '10px',
        width: '100%',
        '&>:first-child': {
            margin: 0,
            marginBottom: 10,
        },
    },
    progressBarContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
    },
    progressBarLabel: {
        width: 100,
        fontSize: 12,
        marginRight: 10,
    },
    backgroundBar: {
        backgroundColor: 'rgb(217, 224, 231)',
        height: '12px',
        borderRadius: '10px',
        position: 'relative',
        overflow: 'hidden',
        '& > span': {
            position: 'absolute',
            fontSize: 11,
            top: 0,
            left: '50%',
            color: '#000',
            fontWeight: 'bold',
            transform: 'translate(-50%)',
            textShadow: '0.3px -0.2px 2px #fff',
        },
    },
    finite: {
        backgroundColor: '#0068d4',
        borderRadius: '10px',
        height: '100%',
    },
    infinite: {
        animation: 'infiniteLoad 2s infinite linear',
        backgroundImage: 'linear-gradient(to right, transparent, #0068d4, transparent)',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
    },
    '@keyframes infiniteLoad': {
        '0%': {
            transform: 'translateX(-100%)',
        },
        '100%': {
            transform: 'translateX(100%)',
        },
    },
});

const DownloadSnackBar = (props) => {
    const { classes, message, progress } = props;
    const progressBar = [];
    if (checkObject(progress)) {
        Object.keys(progress).forEach((key) => {
            let percentage = 0;
            let fileName = '';
            if (checkKeyInObject(progress[key], 'percentage')) {
                percentage = progress[key].percentage;
                fileName = progress[key].fileName;
            }
            progressBar.push(
                <div key={key} className={classes.progressBarContainer}>
                    {fileName && <div className={`${classes.progressBarLabel} ${classes.noWrap}`}>{fileName}</div>}
                    <div
                        className={classes.backgroundBar}
                        style={{
                            width: `calc(100% - ${fileName ? '110px' : '0px'})`,
                        }}
                    >
                        <div
                            style={Number.isFinite(percentage) ? { width: `${percentage}%` } : {}}
                            className={Number.isFinite(percentage) ? classes.finite : classes.infinite}
                        ></div>
                        {Number.isFinite(percentage) ? <span>{`${percentage}%`}</span> : null}
                    </div>
                </div>,
            );
        });
    }
    return (
        <div className={classes.progressContainer}>
            <p>{message}</p>
            {progressBar}
        </div>
    );
};

DownloadSnackBar.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    message: PropTypes.any,
};

DownloadSnackBar.defaultProps = {
    message: '',
};

const mapStateToProps = (state) => ({
    message: state.modalReducer.snackBarMessage,
    progress: state.modalReducer.progress,
});

export default connect(mapStateToProps, null)(withStyles(styles)(DownloadSnackBar));
