/* eslint-disable no-throw-literal */
import HttpBase from '../../services/HttpBase';
import { checkObject, checkKeyInObject, checkArrayLength, customProgressBar ,checkIsArray } from '../../utils/utils';
import store from '..';
import { ModalActions } from '../actions';

class ProjectMiddleware extends HttpBase {
    async getProjects(page, rowsPerPage) {
        const result = await this.httpClient.get(`v1/projects/${page}/${rowsPerPage}/get_projects `);
        // const result = await this.httpClient.get(`/v1/getprojects`);
        if (result.status === 200 && result.data) {
            return { data: result.data };
        }
        throw 'Could not get projects';
    }

    async ApproveData(dataObj) {
        const payload = {
            sessionId: dataObj.sessionId,
        };
        const { data, status } = await this.httpClient.post(`/alert/approve/${dataObj.testCaseId}/${dataObj.source}`, payload);
        if (status === 200) {
            return data;
        }
        return false;
    }

    async getUserVariables(accountId, projectId) {
        try {
            const { data, status } = await this.httpClient.get(`/uservariable/findall/${accountId}/${projectId}`);
            if (status === 200) {
                return data;
            }
            throw 'Could not get user variables';
        } catch (error) {
            throw error.toString();
        }
    }

    async saveUserVariables(inputObject) {
        try {
            const { data, status } = await this.httpClient.post('/uservariable/save', inputObject);
            if (status === 200) {
                return data;
            }
            throw 'Failed to save user variable';
        } catch (error) {
            if (error.response) {
                throw error.response.statusText;
            }
            throw error;
        }
    }

    async updateUserVariables(inputObject) {
        try {
            const { data, status } = await this.httpClient.post('/v1/uservariable/over_write', inputObject);
            if (status === 200) {
                return data;
            }
            throw 'Failed to save user variable';
        } catch (error) {
            if (error.response) {
                throw error.response.statusText;
            }
            throw error;
        }
    }

    async deleteUserVariable(accountId, projectId, key) {
        const { data, status } = await this.httpClient.post('/uservariable/delete', {
            accountId,
            projectId,
            key,
        });
        if (status === 200) {
            return data;
        }
        throw 'Failed to delete user variable';
    }

    async deleteAllUserVariable(accountId, projectId) {
        const { data, status } = await this.httpClient.get(`/uservariable/deleteall/${accountId}/${projectId}`);
        if (status === 200) {
            return data;
        }
        throw 'Failed to delete all user variable';
    }

    async handleUpdateData(projectId, testDataId, updatedData) {
        const { data, status } = await this.httpClient.post(`/v1/test_data/${projectId}/${testDataId}/update`, updatedData);
        if (status === 200) {
            return data;
        }
        throw 'Failed to update data';
    }

    async updateTestProjectName(projectData) {
        const { projectId, accountId, projectName } = projectData;
        /* const { data } = */
        await this.httpClient.post(`/projects/updateName/${accountId}/${projectId}/${projectName}`, projectData);
        return { data: [] };
    }

    async sendingStopMsg(self) {
        const { sessionId } = self;
        /* const { response } = */
        await this.httpClient.post(
            '', // Todo: This field should filled with proper API
            sessionId,
        );
        return { response: [] };
    }

    async createNewProject(formData) {
        const headerConfig = {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        };
        const result = await this.httpClient.post('/v1/projects', formData, headerConfig);
        return { data: result.data };
    }

    async updateProject(accountId, projectId, formData) {
        const headerConfig = {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        };
        const result = await this.httpClient.post(`projects/updateProject/${accountId}/${projectId}`, formData, headerConfig);
        if (result && result.status === 200) {
            return { data: result.data };
        }
        throw new Error('Failed to update');
    }

    async deleteProjects(projectIds) {
        const data = { projectIds };
        customProgressBar(`disabling_project_${projectIds[0]}`, 'Disabling Project..', 'start'); // Open progress bar
        const result = await this.httpClient.post('/v1/projects/disable', data);
        customProgressBar(`disabling_project_${projectIds[0]}`); // close progress bar
        if (checkKeyInObject(result, 'status') >= 500) {
            store.dispatch(ModalActions.toggleModal('Disable project failed.'));
        }
        if (checkObject(result) && checkArrayLength(result.data)) {
            return { data: result.data };
        }
        // Backend only returns an empty array if fails, sigh
        throw new Error('Unable to delete project.');
    }

    removeUnwantedSteps = (oldSteps) => {
        let testSteps = oldSteps && oldSteps.data && oldSteps.data.test_steps ? JSON.parse(oldSteps.data.test_steps) : [];
        if (testSteps.length > 0) {
            testSteps = testSteps.filter((testStep) => {
                if (testStep.instr !== '' || testStep.data !== '') {
                    return testStep;
                }
                return false;
            });
        }
        return testSteps;
    };

    async getAllTestSteps(testCaseId, flag) {
        let live;
        let recover;
        let original;
        let result;
        let steps;
        const link = `/testCases/getTestCaseInfo/${testCaseId}/${flag}`;
        switch (flag) {
            case 3:
                live = await this.httpClient.get(link);
                break;
            case 2:
                recover = await this.httpClient.get(link);
                break;
            case 1:
                original = await this.httpClient.get(link);
                break;

            default:
                // flag == -1
                steps = await this.httpClient.get(`/testCases/getTestCaseInfo/${testCaseId}/-1`);
                break;
        }

        if (checkObject(steps) && checkKeyInObject(steps, 'data')) {
            const _testSteps = this.removeUnwantedSteps(steps);
            result = {
                originalTestSteps: steps.data.orig_steps ? JSON.parse(steps.data.orig_steps) : [],
                recoverTestSteps: steps.data.recover_steps ? JSON.parse(steps.data.recover_steps) : [],
                testSteps: _testSteps,
            };
            return result;
        }
        // filtering these testStesps who's instr=empty and data=empty
        const testSteps = this.removeUnwantedSteps(live);

        result = {
            originalTestSteps: original && original.data && original.data.orig_steps ? JSON.parse(original.data.orig_steps) : [],
            recoverTestSteps: recover && recover.data && recover.data.recover_steps ? JSON.parse(recover.data.recover_steps) : [],
            testSteps,
        };
        return result;
    }

    async getTestSteps(testCaseId, flag) {
        const result = await this.getAllTestSteps(testCaseId, flag);
        if (
            (result.originalTestSteps && Array.isArray(result.originalTestSteps) && result.originalTestSteps.length) ||
            (result.recoverTestSteps && Array.isArray(result.recoverTestSteps) && result.recoverTestSteps.length) ||
            (result.testSteps && Array.isArray(result.testSteps) && result.testSteps.length)
        ) {
            return result;
        }
        throw 'No Original Steps Found';
    }

    async getSingleTestCase(projectId, testCaseId) {
        const result = await this.httpClient.get(`/v1/projects/${projectId}/testcases/${testCaseId}/gettestcase`);
        if (result && result.data && result.status === 200) {
            return result.data;
        }
        throw 'No Test Case Found';
    }

    //   Test Case Section
    async getTestCases(projectId) {
        const result = await this.httpClient.get(`/v1/projects/${projectId}/testcasesV1`);
        if (result && result.data && Array.isArray(result.data) && result.status === 200) {
            return result.data;
        }
        throw 'No Test Case Found';
    }

    async saveNewTestCase(accountId, projectId, data) {
        const result = await this.httpClient.post(`/testCases/create/${accountId}/${projectId}`, data);
        if (result && result.data && result.status) return { data: result.data };
        throw 'Failed to save new Test Case';
    }

    async recordTestCase(accountId, projectId, discoveryId) {
        const result = await this.httpClientRecorder.get(`/testCases/${accountId}/${projectId}/${discoveryId}/generateRecorderPayload`);
        return { data: result.data };
    }

    async saveTestSuiteCasesOrder(order, suiteId) {
        const { status, data } = await this.httpClient.post('/testSuite/saveOrdinals', {
            tc_ids_to_ordinals: order,
            test_suite_id: suiteId,
        });
        if (status === 200) {
            return data;
        }
        throw new Error('Unable to update test order');
    }

    async deleteTestCaseFromSuite(suiteId, testCaseIds) {
        const requestData = {
            suite_id: suiteId,
            case_ids: testCaseIds,
        };
        // Todo : API Not Working
        const { /* data, */ status } = await this.httpClient.post('/testSuite/deleteCaseFromSuite', requestData);
        if (status === 200) {
            return true;
        }
        throw new Error('Unable to delete test cases');
    }

    async getSingleTestData(testDataId) {
        const result = await this.httpClient.get(`/discovery/${testDataId}/testdata`);
        if (result && result.data && result.status === 200) {
            return result.data;
        }
        throw 'No Test Data Found';
    }

    // Test Data
    async getTestData(projectId, discoveryId) {
        const result = await this.httpClient.get(`/discovery/${projectId}/${discoveryId}/testData`);
        if (result && result.data && result.status === 200) return { data: result.data };
        throw 'No Test Data Found';
    }

    async deleteTestData(testDataId) {
        /* const result = */
        await this.httpClient.post(`/testDatas/${testDataId}/deleteTestData`);
        // if (status === 200) {
        return { data: true };
        // }
        // throw new Error(`Unable to delete test data`);
    }

    async associateDataToTestCase(accountId, projectId, discoveryId, suiteId, testDataId, testcaseIds) {
        const result = await this.httpClient.post(
            `/testDatas/${accountId}/${projectId}/${discoveryId}/${suiteId}/${testDataId}/associate`,
            testcaseIds,
        );
        if (result && result.data && result.data.length) {
            return { data: result.data };
        }
        throw new Error('Unable to associate test data');
    }

    async getAlertTestCasesWithAccount(accountId) {
        const result = await this.httpClient.get(`/alert/getAll/${accountId}`);
        if (result.status === 200) {
            return result.data;
        }
        return [];
    }

    async rejectApproveAllAlertTestCase(info, source, isReject) {
        const api = isReject ? 'rejectAll' : 'approveAll';
        try {
            let payload = {};
            if (isReject) {
                payload = {
                    alertIds: info,
                };
            } else {
                payload = {
                    infos: info,
                };
            }
            const result = await this.httpClient.post(`/alert/${api}/${source}`, payload);
            if (result.status === 200) {
                return { info, source, isReject };
            }
            return null;
        } catch (error) {
            if (error.response) {
                throw `Failed to ${api}`;
            }
            throw error;
        }
    }

    async rejectApproveAlertTestCase(alert_id, source, isReject, sessionId) {
        const api = isReject ? 'reject' : 'approve';
        try {
            if (isReject) {
                const _result = await this.httpClient.get(`/alert/${api}/${alert_id}/${source}`);
                if (_result.status === 200) {
                    return { alert_id, source, isReject };
                }
                return null;
            }

            const payload = {
                sessionId,
            };
            const result = await this.httpClient.post(`/alert/${api}/${alert_id}/${source}`, payload);
            if (result.status === 200) {
                return { alert_id, source, isReject };
            }
            return null;
        } catch (error) {
            if (error.response) {
                throw `Failed to ${api}`;
            }
            throw error;
        }
    }

    //   Test Script
    async executeTestScript(data, onCompelete = () => {}) {
        const { projectId } = data;
        delete data.projectId;

        const result = await this.httpClient.post(`/v1/projects/${projectId}/execute`, data);
        if (result.status === 200) {
            onCompelete();
        }
        return { data: result.data };
    }

    async getExecutionVideo(execId) {
        // NOTE: only 1 test suite per project
        const { data } = await this.httpClient.get(`/testScriptExecutions/videos/${execId}`);
        return { data };
    }

    async generateTestScript(projectId, testScriptIds) {
        const { data } = await this.httpClient.post(`/v1/projects/${projectId}/generate`, testScriptIds);
        if (data) {
            return { data };
        }
        return { data: [] };
    }

    async deleteTestScript(systemId) {
        const data = {
            TestScriptIds: [parseInt(systemId, 10)],
        };
        const result = await this.httpClient.post('/testScripts/disable', data);
        if (result.data.length) {
            return { data: result.data };
        }
        // Backend only returns an empty array if fails, sigh
        throw new Error(`Unable to delete test script with id: ${systemId}`);
    }

    async getExecutionTasks(accountId, projectId, status, page, testCaseId) {
        const result = await this.httpClient.get(
            `/testScriptExecutions/${accountId}/${projectId || 0}/${testCaseId || -1}/${status}/${page - 1}/executions`,
        );
        if (result.status === 200 && result.data) return result.data;
        throw 'Could not found execution status';
    }

    async previewTestData(testDataId) {
        const result = await this.httpClient.get(`/testCases/${testDataId}/previewTestData`);
        if (result.data) {
            if (
                checkKeyInObject(result.data, 'data') &&
                result.data.data &&
                (!result.data.order || !checkArrayLength(JSON.parse(result.data.order)))
            ) {
                const _data = JSON.parse(result.data.data);
                const _order = Object.keys(_data);
                return { data: { data: result.data.data, order: JSON.stringify(_order) }, status: result.status };
            }
            return { data: result.data, status: result.status };
        }
        return { data: { status: 204 } };
    }

    async previewTestScript(testScriptId) {
        const result = await this.httpClient.get(`/testScripts/${testScriptId}/previewTestScript`);
        if (result.data) {
            return { data: { data: result.data, status: result.status } };
        }
        return { data: { status: 204 } };
    }

    async downloadTestScript(testScriptId) {
        const result = await this.httpClient.get(`/testScripts/${testScriptId}/previewTestScript`);
        if (result.data) {
            return { data: { data: result.data, status: result.status } };
        }
        return { data: { status: 204 } };
    }

    async getNonDeletedNotification(accountId) {
        try {
            const result = await this.httpClient.get(`/notifications/GetNonDeletedNotifictionsForAccount/${accountId}`);
            if (result.data) {
                return { data: result.data };
            }
            return { data: { status: 204 } };
        } catch (error) {
            return error;
        }
    }

    async getDeletedNotification(accountId) {
        try {
            const result = await this.httpClient.get(`/notifications/GetDeletedNotifictionsForAccount/${accountId}`);
            if (result.data) {
                return { data: result.data };
            }
            return { data: { status: 200 } };
        } catch (error) {
            return error;
        }
    }

    async getMarkReadNotification(accountId) {
        try {
            const result = await this.httpClient.get(`/notifications/markRead/${accountId}`);
            if (result.data) {
                return { data: result.data };
            }
            return { data: { status: 200 } };
        } catch (error) {
            return error;
        }
    }

    async getMarkNotificationDeleted(notificationId) {
        try {
            const result = await this.httpClient.get(`/notifications/markNotificaitonDeleted/${notificationId}`);
            if (result.data) {
                return { data: result.data };
            }
            return { data: { status: 204 } };
        } catch (error) {
            return error;
        }
    }

    async clearAllNotificaton(ids) {
        try {
            const data = { notification_ids: ids };
            const result = await this.httpClient.post('notifications/markNotificaitonDeleted', data);
            if (result.status === 200) {
                return { data: '' };
            }
            return null;
        } catch (error) {
            return error;
        }
    }

    async postMarkReadNotification(ids) {
        const data = { notification_ids: ids };
        try {
            const result = await this.httpClient.post('notifications/markRead', data);
            if (result.data) {
                return { data: result.data };
            }
            return { data: { status: 200 } };
        } catch (error) {
            return error;
        }
    }

    async getProjectDetails(projectId) {
        const { data, status } = await this.httpClient.get(`/v1/projects/${projectId}/getproject`);
        if (status === 200) {
            return data;
        }
        throw 'Could not get the Project Info';
    }

    async getChangedApis(accountId) {
        const { data, status } = await this.httpClient.get(`/alert/getAllApis/${accountId}`);
        if (status === 200 && data) {
            return data;
        }
        throw 'Could not find any changed api!';
    }

    async initiateSmartRun(changedApis) {
            const { data, status } = await this.httpClient.post('/alert/smartRun', changedApis);
            if (status === 200) {
                return data;
            }
            throw 'Failed to initiate smart run!';
        }
        //   TODO this
        //  async getExecutions(
        //     accountId: string,
        //     testType: 'smoke',
        //     timeFilter: 'week',
        // ): Promise<IProjectServiceResponse> {
        //     try {
        //         const result = await this.httpClient.get(
        //             `/testScriptExecutions/account/${accountId}/reports?&testType=${testType}&timeFilter=${timeFilter}`,
        //         );
        //         return { data: result.data };
        //     } catch (error) {
        //         return { error };
        //     }
        // }

    async uploadProject(formData) {
        const { data, status } = await this.httpClient.post('/v1/upload_project', formData, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        });
        if (status === 200) {
            return data;
        }
        throw new Error('Failed to upload');
    }

    async getProjectByIdAndName() {
        const result = await this.httpClient.get('v1/get_project_id_and_names');
        if (result.status === 200 && result.data) {
            return { data: result.data };
        }
        throw 'Could not get projects';
    }

    async removeFavouriteProject(projectId) {
        // customProgressBar(`Getting_projects_`, 'Getting Project..', 'start'); // Open progress bar
        const result = await this.httpClient.get(`v1/projects/${projectId}/remove_from_fav`);
        // customProgressBar(`Getting_projects_`); // cl
        if (result.status === 200) {
            return { data: true };
        }
        throw `Could not remove project ${projectId}`;
    }

    async addFavouriteProject(projectId) {
        const result = await this.httpClient.get(`v1/projects/${projectId}/add_to_fav`);
        if (result.status === 200) {
            return { data: true };
        }
        throw `Could not add project ${projectId}`;
    }

    async getProjectByName(projectName) {
        const result = await this.httpClient.get(`v1/projects/${projectName}/getprojectbyname`);
        if (result.status === 200 && result.data) {
            return { data: result.data };
        }
        throw `Could not search project ${projectName}`;
    }

    async getFavouriteProjects() {
        const result = await this.httpClient.get('v1/projects/get_fav_projects');
        if (result.status === 200 && result.data) {
            return { data: result.data };
        }
        throw 'Could not get fvourite project';
    }

    async getProjectsforDownload() {
        try {
            const result = await this.httpClient.get('/v1/get_project_data_for_download');
            if (result.status === 200 && result.data) {
                return { data: result.data };
            }
            throw 'Data not Found';
        } catch (error) {
            return { data: [] };
        }
    }

    async getEnvironmentType(accountId) {
        try {
            const result = await this.httpClient.get(`/v1/accounts/executionenvironment`)
            // add unique ids to sauce capablities if id does not exists
            if(result && result.data &&  checkIsArray(result.data) && typeof result.data[0] === 'object') {
                result.data[0].environments = result.data[0].environments.map((env)=>{
                    if(env.environmentType === 'Saucelabs') {
                            if(checkKeyInObject(env,'environment') && checkIsArray(env.environment.platformDetails)){
                                env.environment.platformDetails = env.environment.platformDetails.map((data,i)=>{
                                    if(!data.id) {
                                        data.id = `${data.platform}_${data.platformVersion}_${data.browser}_${data.browserVersion}`;
                                    }
                                    if(!data.label) {
                                        data.label = `${data.platform} ${data.platformVersion} . ${data.browser} ${data.browserVersion}`;
                                    }
                                    return data;
                                })
                            }

                    }
                    if(env.environmentType === 'saucelab_devices') {
                        if(checkKeyInObject(env,'environment') && checkIsArray(env.environment.platformDetails)){
                            env.environment.platformDetails = env.environment.platformDetails.map((data,i)=>{
                                
                                if(!data.deviceName) {
                                    data.deviceName = data.device;
                                }
                                return data;
                            })
                        }

                }
                    return env;  
                })
            }
            return { result: result.data }
        } catch (error) {
            return { result: [] }
        }
    }

    async getTunnelIDForSauceLabs() {
        try {
            const result = await this.httpClient.get(`/v1/getsauceconnectids`)
            return { result: result.data }
        } catch (error) {
            let errorMessage = "Could not get tunnel IDs";
            if (error && typeof error === 'string') {
                errorMessage = error
            } else if (error && error.data && typeof error.data === 'string') {
                errorMessage = error.data
            } else if (error && error.response && error.response.data && typeof error.response.data === 'string') {
                errorMessage = error.response.data
            }


            return { error: errorMessage, result: [] }
        }
    }
    async getExecutionDetailsByScheduleId(scheduleId) {
        return new Promise(async(resolve,reject)=>{
            try {
                const result = await this.httpClientSchedule.get(`/v1/schedules/${scheduleId}/get_schedule`)
                resolve({ result: result.data })
            } catch (error) {
    
                reject({ error: error.response.data })
            }
        })
    }
}

export default new ProjectMiddleware();