import React from 'react';
import PropTypes from 'prop-types';

// custom
import { saveAs } from 'file-saver/FileSaver';
import '../../features/modal/Stepper/uploadWizardScroll.css';
import { BLACK_FONT } from '../../common/cssConstants';
import projectServices from '../../services/projectServices';
import singleFileCreationService from '../../services/singleFileCreationService';
import { replaceCharsForStepsCSV, checkArrayLength } from '../../utils';
import JSONTOCsv from '../../services/JSONToCsv';
import EnhanceTableTestCaseModal from '../../features/modal/GeneralModal/EnhanceTableTestCaseModal';

class CheckList extends React.Component {
    state = {
        allChecked: false,
        testCases: [...this.props.testCases],
    };

    UNSAFE_componentWillMount() {
        /*eslint-disable */
        const { testCases, checkedIds } = this.props;
        if (checkedIds.length) {
            testCases.forEach((testCase, index) => {
                checkedIds.forEach((id) => {
                    if (testCase.testCaseId === id) {
                        this.state.testCases[index] = {
                            ...testCase,
                            checked: true,
                        }; // eslint-disable-line no-param-reassign
                    }
                });
            });
        }
    }

    encodeSignedURL(url) {
        return encodeURIComponent(url);
    }

    openTestCaseLocation = (fileLocation) => {
        const viewerUrl = 'https://docs.google.com/viewer?url=';
        const encodedUrl = this.encodeSignedURL(fileLocation);
        const finalViewerLocation = viewerUrl + encodedUrl;
        window.open(finalViewerLocation);
    };

    filterTestCases(testCases) {
        const filteredCases = [];
        testCases.forEach((testCase) => {
            if (testCase.checked === true) filteredCases.push(testCase.testCaseId);
        });
        this.props.getTestCaseIds(filteredCases);
    }

    handleToggle = (testCaseId, checked) => {
        const { testCases } = this.state;
        const index = testCases.map((_case) => `${_case.testCaseId}`).indexOf(`${testCaseId}`);
        testCases[index] = { ...testCases[index], checked: checked };
        const allChecked = !testCases.some((testCase) => !testCase.checked);
        this.setState({ testCases, allChecked }, () => {
            this.filterTestCases(testCases);
        });
    };

    handleToggleAll = (isAllChecked) => {
        const testCases = [...this.state.testCases];
        let newTestCases = [];

        if (isAllChecked) {
            newTestCases = testCases.map((testCase) => {
                const newTestCase = { ...testCase, checked: true };
                return newTestCase;
            });
        } else {
            newTestCases = testCases.map((testCase) => {
                const newTestCase = { ...testCase, checked: false };
                return newTestCase;
            });
        }
        this.setState({ testCases: newTestCases, allChecked: isAllChecked }, () => {
            this.filterTestCases(newTestCases);
        });
    };

    previewTestCase = async (testCaseId, testCaseName) => {
        const { toggleModal, testDataValues } = this.props;
        const testCasePreview = await projectServices.previewTestCase(testCaseId);
        if (testCasePreview)
            toggleModal('filePreviewModal', null, null, {
                id: testCaseId,
                data: testCasePreview,
                name: testCaseName,
                APIData: testCaseName,
                modalName: 'CaseModal',
                handleDownload: this.handleDownloadTestCase,
                forceOpenModal: true,
                ...testDataValues,
            });
    };

    handleDownloadTestCase = async (fileName, Steps, Type, fileType = 'xlsx') => {
        if (fileType === 'csv') {
            try {
                let testSteps = Type === 'Live Steps' ? singleFileCreationService.sort(Steps) : Steps;
                let header = {
                    'Test Steps': 'Test Steps',
                    Data: 'Data',
                };
                let data = testSteps.map((step) => {
                    return {
                        'Test Steps': `"${replaceCharsForStepsCSV(step.instr).replace(/["]{1}/g, `""`)}"`,
                        Data: `"${step.data.replace(/["]{1}/g, `""`)}"`,
                    };
                });
                JSONTOCsv.CSVFile(header, data, `${fileName}`);
            } catch (error) {
                console.log(error);
            }
        } else {
            const data = await singleFileCreationService.getCaseFile(Steps, Type, fileName);
            if (data) {
                const blob = new Blob([data], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, `${fileName}.xlsx`);
            } else if (!data) {
                console.log('FAILED TO DOWNLOAD XLSX FILE');
            } else {
                console.log('FAILED TO DOWNLOAD XLSX FILE');
            }
        }
    };

    render() {
        const { query, clearSearch, dataName, handleSearch } = this.props;
        const { allChecked, testCases } = this.state;
        const headers = [
            {
                id: 'All',
                sortable: false,
                numeric: false,
                disablePadding: false,
                label: 'All',
                styles: { paddingLeft: 10, color: BLACK_FONT, fontWeight: 500 },
                width: '80%',
            },
            { id: 'func', sortable: false, numeric: false, disablePadding: false, label: '', styles: { paddingLeft: 10 }, width: '20%' },
        ];
        let _selected = 0;
        for (let i = 0; i < testCases.length; i++) {
            const testCase = testCases[i];
            if (testCase.checked) {
                _selected++;
            }
        }
        const _filterTestCases = query
            ? checkArrayLength(testCases) && testCases.filter((testCase) => testCase.testCaseName.toLowerCase().includes(query.toLowerCase().trim()))
            : testCases;

        return (
            <EnhanceTableTestCaseModal
                headers={headers}
                onSelectAllClick={(e) => {
                    this.handleToggleAll(!allChecked);
                }}
                numSelected={_selected}
                showSelectBtn={true}
                enhancedTableHeadStyle={true}
                testCases={_filterTestCases}
                handleToggle={this.handleToggle}
                previewTestCase={this.previewTestCase}
                query={query}
                clearSearch={clearSearch}
                dataName={dataName}
                handleSearch={handleSearch}
            />
        );
    }
}

CheckList.propTypes = {
    getTestCaseIds: PropTypes.func.isRequired,
    testCases: PropTypes.instanceOf(Object).isRequired,
    checkedIds: PropTypes.instanceOf(Array),
    extendedHeight: PropTypes.bool,
};

CheckList.defaultProps = {
    checkedIds: [],
    extendedHeight: false,
};

export default CheckList;
