// import packages
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import utils
import NavigationPrompt from 'react-router-navigation-prompt';
import { ModalActions, ProjectActions, SelectedTestCaseActions, TestCaseActions, TaskAction } from '../../../store/actions';
import {
    checkKeyInObject,
    checkObject,
    checkArrayLength,
    resetFailedOrDebugStepsInSelectedTestCase,
    getStepsData,
    TestCaseUtils,
    sleep,
    getParamValues,
} from '../../../utils';
// import custom components
import FlowModal from '../../modal/Flow/FlowModal';
import TestStepTagModal from '../../../components/CustomModal/TestStepTagModal';
import { TestCaseMiddleware } from '../../../store/middleware';
import ScreenShotModal from '../Case/ScreenShotModal';
import ConfirmNavigationModal from '../../modal/ConfirmNavigationModal';
import {warningAlertBar} from '../../../services/AlertBarService'
class TestStepsModals extends Component {
    constructor(props) {
        super(props);
        this.is_mounted = false;
        this.setSelectedTestCase = false; // turn true once selected testCase save in seperate reducer
    }

    /* Component lifecycle start */
    UNSAFE_componentWillMount() {
        this.onWillMount();
    }

    componentDidMount() {
        this.onDidMount();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            isSnackBarOpen,
            redoData,
            selectedTestCase,
            snackBarMessage,
            testData,
            testDataColumns,
            undoData,
            wsRunningCaseData,
            updateTestCaseId,
            getExecutionTasks,
        } = nextProps;

        const pathArray = getParamValues();
        const newProjectId = pathArray[1];
        const newTestCaseId = pathArray[2];

        if (`${newProjectId}` !== `${this.projectId}` || `${newTestCaseId}` !== `${this.testCaseId}`) {
            this.projectId = Number(newProjectId) ? Number(newProjectId) : 0;
            this.testCaseId = Number(newTestCaseId) ? Number(newTestCaseId) : 0;
            setTimeout(() => {
                if(!(isNaN(this.testCaseId))) {
                    updateTestCaseId(this.testCaseId);
                    getExecutionTasks(this.projectId, -1, this.testCaseId, 1, false, null, false);
                }            
            }, 210);
        }

        if (!this.initialDataFetched && `${this.testCaseId}` !== '-1') {
            this.getInitialData(selectedTestCase);
        }

        if (checkArrayLength(testData) && !checkObject(testDataColumns)) {
            this.getCaseData(testData, this.testCaseId);
        }

        const { testSteps: _testSteps, stepsType } = TestCaseUtils.getStepsAndTypes(selectedTestCase);
        // If we update testCaseId in url from replaceSate then
        // We can not use react router param,
        this.testCaseId = window.location && window.location.pathname && window.location.pathname.split('/').pop();

        if (checkArrayLength(_testSteps) && !this.setSelectedTestCase && stepsType) {
            this.setSelectedTestCase = true;
            this.props.setSelectedTestCaseData({
                testCaseId: selectedTestCase.testCaseId,
                testSteps: _testSteps,
                testCaseStatus: selectedTestCase.status,
                stepsType,
            });
        }

        // isMsgType2Sent checks if running testCase is run by current user or not
        if (
            checkKeyInObject(wsRunningCaseData, `${this.testCaseId}.isMsgType2Sent`) === false // is running testCase ran by current user or not
        ) {
            if (checkArrayLength(redoData) || checkArrayLength(undoData)) {
                this.props.emptyUndoRedoArrays();
            }
            // if (!isSnackBarOpen) {
            //     nextProps.toggleSnackBar('Generation/Edit in progress. Some features will not be accessible.', '', false, null, true);
            // }
            warningAlertBar({
                message:{
                    title:'Script is being generated',
                    description:'Once the script generation is complete, you can run your test.'
                },
                duration:20000,

            })
            
        } else if (
            checkObject(this.props.wsRunningCaseData) &&
            !checkObject(wsRunningCaseData) &&
            !checkKeyInObject(wsRunningCaseData, this.testCaseId) &&
            isSnackBarOpen &&
            snackBarMessage === 'Generation/Edit in progress. Some features will not be accessible.'
        ) {
            this.props.toggleSnackBar();
        }
    }

    componentDidUpdate() {
        if (this.is_mounted) {
            const { selectedTab, undoData } = this.props;
            if (!(!checkArrayLength(undoData) || `${selectedTab}` === '1')) {
                window.onbeforeunload = () => true;
            } else {
                window.onbeforeunload = undefined;
            }
        }
    }

    componentWillUnmount() {
        this.onWillUnmount();
    }
    /* Component lifecycle end */

    onWillMount = () => {
        const { isWsRunning, selectedTestCase, emptyUrlValidation } = this.props;
        const tcId = getParamValues(2);
        this.testCaseId = Number(tcId) ? Number(tcId) : 0;

        if (
            `${this.testCaseId}` !== '-1' &&
            checkObject(selectedTestCase) &&
            (!isWsRunning[selectedTestCase.testCaseId] || !('originalTestSteps' in selectedTestCase)) &&
            checkArrayLength(selectedTestCase.testSteps)
        ) {
            selectedTestCase.testSteps = [];
            selectedTestCase.recoverTestSteps = [];
        }
        this.setSelectedTestCase = false;
        emptyUrlValidation();
        if (this.props.isSnackBarOpen) {
            this.props.toggleSnackBar();
        }
    };

    onDidMount = async () => {
        this.is_mounted = true;
        this.initialDataFetched = false; // True: once getInitialData invoke. check if getInitialData needs to invoke
        const { isSnackBarOpen, testData, testDataColumns, user, userVariables, wsRunningCaseData } = this.props;
        this.props.handleonClick_nextPrevTestStepModal(this.onClick_nextPrevTestStepModal);
        const pathArray = getParamValues();
        this.projectId = Number(pathArray[1]) ? Number(pathArray[1]) : 0;
        this.testCaseId = Number(pathArray[2]) ? Number(pathArray[2]) : 0;

        this.getTestCaseFromStore();

        if (!checkArrayLength(userVariables)) {
            this.props.getUserVariables(user.accountId, this.projectId);
        }

        if (this.projectId && `${checkKeyInObject(this.props.selectedProject, 'projectId')}` !== `${this.projectId}`) {
            this.props.getProjectDetails(this.projectId, false, () => {
                this.getExecutionTasks();
            });
        } else {
            this.getExecutionTasks();
        }

        if (!checkArrayLength(testData)) {
            this.props.getTestData(this.projectId);
        } else if (!checkObject(testDataColumns)) {
            this.getCaseData(testData, this.testCaseId);
        }

        const isTestCaseAlreadyRunning =
            `${this.testCaseId}` !== '-1' ? await TestCaseMiddleware.getTestCaseGenerationStatus(Number(this.testCaseId)) : false;
        // eslint-disable-next-line no-console
        console.log(`[PLATFORM] TESTCASE ${this.testCaseId}, isGenerating: ${isTestCaseAlreadyRunning}`);
        this.props.setTestCaseGenerationStatus(isTestCaseAlreadyRunning);
        if (isTestCaseAlreadyRunning) {
            /* Disable retry button for execution generation test api promise start */
            TestCaseUtils.allowRetryTestCase(this.testCaseId, false);
            /* Disable retry button for execution generation test api promise end */
        } else {
            this.props.clearWsData(this.testCaseId, 'TestSteps-DidMount');
        }

        if (isTestCaseAlreadyRunning  && checkKeyInObject(wsRunningCaseData, `${this.testCaseId}.isMsgType2Sent`) !== true) {
            warningAlertBar({
                message:{
                    title:'Script is being generated',
                    description:'Once the script generation is complete, you can run your test.'
                },
                duration:20000,

            })
         //   this.props.toggleSnackBar('AAGeneration/Edit in progress. Some features will not be accessible.', '', false, null, true);
        } else if (!checkKeyInObject(wsRunningCaseData, this.testCaseId) && isSnackBarOpen) {
            this.props.toggleSnackBar();
        }
    };

    onWillUnmount = () => {
        setTimeout(() => {
            this.props.getExecutionTasks(0, 0, -1, 1, false, null, false);
        }, 100);
        window.onbeforeunload = undefined;
        this.initialDataFetched = false;
        this.is_mounted = false;
    };

    onClick_nextPrevTestStepModal = async () => {
        this.onWillUnmount();
        await sleep(200);
        this.onWillMount();
        await sleep(200);
        this.onDidMount();
    };

    getExecutionTasks = () => {
        setTimeout(() => {
            this.props.getExecutionTasks(this.projectId, -1, this.testCaseId, 1, false, null, false);
        }, 210);
    };

    /* Getting Cases start */
    getInitialData = async (selectedTestCase) => {
        const { isWsRunning, failedStepsData, debugStepsData } = this.props;

        const testCase = checkObject(selectedTestCase) ? JSON.parse(JSON.stringify(selectedTestCase)) : {};

        if (checkObject(testCase)) {
            if (`${testCase.testCaseId}` !== '-1' && (!isWsRunning[testCase.testCaseId] || !('originalTestSteps' in testCase))) {
                // assign false to initialDataFetched to prevent UNSAFE_componentWillReceiveProps by invoking getInitialData
                this.initialDataFetched = true;
                this.props.updateReRenderPermission(true);
                // if failedStepsData OR debugStepsData found and we've steps in reducer asign steps in selected testSteps
                if (checkKeyInObject(failedStepsData, this.testCaseId) && checkArrayLength(failedStepsData[this.testCaseId].wsRunningTestSteps)) {
                    await resetFailedOrDebugStepsInSelectedTestCase(failedStepsData[this.testCaseId], this.testCaseId);
                } else if (
                    checkKeyInObject(debugStepsData, this.testCaseId) &&
                    checkArrayLength(debugStepsData[this.testCaseId].wsRunningTestSteps)
                ) {
                    await resetFailedOrDebugStepsInSelectedTestCase(debugStepsData[this.testCaseId], this.testCaseId);
                } else {
                    await getStepsData(this.testCaseId, 0, () => {}, 'TestStepInitialData');
                }
            }
        }
    };

    getTestCaseFromStore = async () => {
        const { getSingleTestCase, selectedTestCase, clearTestCase } = this.props;
        if (!checkObject(selectedTestCase) || (checkObject(selectedTestCase) && `${selectedTestCase.testCaseId}` !== `${this.testCaseId}`)) {
            clearTestCase();
            await getSingleTestCase(this.projectId, this.testCaseId);
        } else if (!this.initialDataFetched) {
            this.getInitialData(selectedTestCase);
        }
    };

    getCaseData = (testData, testCaseId) => {
        const file = testData.find((data) => {
            if (checkArrayLength(data.testCasesIds) && data.testCasesIds.map(String).indexOf(`${testCaseId}`) !== -1) {
                return data;
            }
            return false;
        });

        if (checkObject(file) && file.testDataId) {
            this.props.previewTestData(file.testDataId);
        }
    };
    /* Getting Cases end */

    /* General methods start  */
    getLatestSteps = () => {
        const { instrNumArray, testSteps } = this.props;
        const _testSteps = [];
        if (checkArrayLength(instrNumArray)) {
            instrNumArray.forEach((instrNum) => {
                if (checkObject(testSteps) && testSteps[`${instrNum}`]) {
                    _testSteps.push(testSteps[instrNum]);
                }
            });
        }
        return _testSteps;
    };
    /* General methods end  */

    /* Screenshot modal start */
    handleModalTreverseStep = (isScreenshotModalOpen, info, direction) => {
        const { selectedTestCase } = this.props;
        let { currentStepScreenshotModalIndex } = info;
        if (checkArrayLength(checkKeyInObject(selectedTestCase, 'testSteps'))) {
            if (direction === 'next' && info.currentStepScreenshotModalIndex < selectedTestCase.testSteps.length - 1) {
                currentStepScreenshotModalIndex = info.currentStepScreenshotModalIndex + 1;
            } else if (direction === 'prev' && info.currentStepScreenshotModalIndex > 0) {
                currentStepScreenshotModalIndex = info.currentStepScreenshotModalIndex - 1;
            }
        }
        this.props.toggleScreenShotModal(isScreenshotModalOpen, currentStepScreenshotModalIndex);
    };
    /* Screenshot modal end */

    render() {
        const {
            blockData,
            currentStepScreenshotModalIndex,
            isBlockModalOpen,
            isScreenshotModalOpen,
            isTagModalOpen,
            selectedTestCase,
            selectedProject,
        } = this.props;

        let modal = null;

        if (isBlockModalOpen) {
            modal = (
                <FlowModal
                    blockData={blockData}
                    // allSteps={/* testStepsArray */ []}
                    open={isBlockModalOpen}
                    handleClose={this.props.toggleBlockModal}
                    modalName="add"
                    projectId={this.projectId}
                />
            );
        } else if (isTagModalOpen) {
            modal = (
                <TestStepTagModal open={isTagModalOpen} handleClose={this.props.toggleTagModal} data={{ name: 'testCaseSteps', selectedTestCase }} />
            );
        } else if (isScreenshotModalOpen && checkArrayLength(checkKeyInObject(selectedTestCase, 'testSteps'))) {
            const screenShortSteps = this.getLatestSteps();
            modal = (
                <ScreenShotModal
                    screenshotmodal={isScreenshotModalOpen}
                    handleModal={this.props.toggleScreenShotModal}
                    handleModalTreverseStep={this.handleModalTreverseStep}
                    testStep={
                        checkArrayLength(screenShortSteps) && screenShortSteps[currentStepScreenshotModalIndex]
                            ? screenShortSteps[currentStepScreenshotModalIndex]
                            : selectedTestCase.testSteps[currentStepScreenshotModalIndex]
                    }
                    testSteps={
                        checkArrayLength(screenShortSteps) && screenShortSteps[currentStepScreenshotModalIndex]
                            ? screenShortSteps
                            : selectedTestCase.testSteps
                    }
                    currentStepScreenshotModalIndex={currentStepScreenshotModalIndex}
                    stepsLength={checkArrayLength(screenShortSteps) ? screenShortSteps.length : selectedTestCase.testSteps.length}
                    testCaseId={selectedTestCase.testCaseId}
                    isFullScreenShot={selectedProject.isFullScreenshot}
                    testCase={selectedTestCase}
                />
            );
        }
        return (
            <div>
                {modal}
                <NavigationPrompt when={!(!checkArrayLength(this.props.undoData) || `${this.props.selectedTab}` === '1')}>
                    {({ onConfirm, onCancel }) => (
                        <ConfirmNavigationModal
                            onConfirm={onConfirm}
                            onCancel={onCancel}
                            msg="Your recent changes to the test steps are not saved. Are you sure you want to leave before saving?"
                            leaveBtn="Leave this page"
                            stayBtn="Stay on the page"
                        />
                    )}
                </NavigationPrompt>
            </div>
        );
    }
}

TestStepsModals.propTypes = {
    classes: PropTypes.shape({}),
};

TestStepsModals.defaultProps = {
    classes: {},
};

const mapStateToProps = (state) => {
    return {
        // auth reducer
        user: state.authReducer.user,
        // General Reducer
        // Modal Reducer
        isSnackBarOpen: state.modalReducer.isSnackBarOpen,
        snackBarMessage: state.modalReducer.snackBarMessage,
        // Project Reducer
        failedStepsData: state.projectReducer.failedStepsData,
        debugStepsData: state.projectReducer.debugStepsData,
        isWsRunning: state.projectReducer.isWsRunning,
        selectedProject: state.projectReducer.selectedProject,
        selectedTestCase: state.projectReducer.selectedTestCase,
        testData: state.projectReducer.testData,
        testDataColumns: state.projectReducer.testDataColumns,
        userVariables: state.projectReducer.userVariables,
        wsRunningCaseData: state.projectReducer.wsRunningCaseData,
        // Selected Testcase Reducer
        instrNumArray: state.selectedTestCaseReducer.instrNumArray,
        testSteps: state.selectedTestCaseReducer.testSteps,
        undoData: state.selectedTestCaseReducer.undoData,
        redoData: state.selectedTestCaseReducer.redoData,
        // Testcase Reducer
        blockData: state.testCaseReducer.blockData,
        currentStepScreenshotModalIndex: state.testCaseReducer.currentStepScreenshotModalIndex,
        isBlockModalOpen: state.testCaseReducer.isBlockModalOpen,
        isScreenshotModalOpen: state.testCaseReducer.isScreenshotModalOpen,
        isTagModalOpen: state.testCaseReducer.isTagModalOpen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // modal actions
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        // TestCase action
        toggleBlockModal: (...args) => dispatch(TestCaseActions.toggleBlockModal(...args)),
        toggleScreenShotModal: (...args) => dispatch(TestCaseActions.toggleScreenShotModal(...args)),
        toggleTagModal: (...args) => dispatch(TestCaseActions.toggleTagModal(...args)),
        // Selected TestCase actions
        emptyUndoRedoArrays: (...args) => dispatch(SelectedTestCaseActions.emptyUndoRedoArrays(...args)),
        setTestCaseGenerationStatus: (...args) => dispatch(SelectedTestCaseActions.setTestCaseGenerationStatus(...args)),
        setSelectedTestCaseData: (...args) => dispatch(SelectedTestCaseActions.setSelectedTestCaseData(...args)),
        // project action
        clearTestCase: () => dispatch(ProjectActions.clearTestCase()),
        clearWsData: (...args) => dispatch(ProjectActions.clearWsData(...args)),
        emptyUrlValidation: () => dispatch(ProjectActions.emptyUrlValidation()),
        getSingleTestCase: (...args) => dispatch(ProjectActions.getSingleTestCase(...args)),
        getTestData: (...args) => dispatch(ProjectActions.getTestData(...args)),
        getUserVariables: (...args) => dispatch(ProjectActions.getUserVariables(...args)),
        previewTestData: (...args) => dispatch(ProjectActions.previewTestData(...args)),
        getExecutionTasks: (...args) => dispatch(ProjectActions.getExecutionTasks(...args)),
        // Need to remove this when api provided by platform
        getProjectDetails: (...args) => dispatch(ProjectActions.getProjectDetails(...args)),
        // task action
        updateTestCaseId: (...args) => dispatch(TaskAction.updateTestCaseId(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestStepsModals);
