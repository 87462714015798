import { Toolbar, Typography, CircularProgress, Menu } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import SortDescending from 'mdi-material-ui/SortDescending';
import SortAscending from 'mdi-material-ui/SortAscending';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faLaptop,
    faSortAlphaDown,
    faSortAlphaUp,
    faStar,
    faBan,
    faAlignJustify,
    faGripHorizontal,
} from '@fortawesome/free-solid-svg-icons';
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import { saveAs } from 'file-saver/FileSaver';
import MoreVert from '@material-ui/icons/MoreVert';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { ProjectActions, ModalActions, GeneralActions } from '../../store/actions';
import { MODAL_SUBMIT_BUTTON, MODAL_CANCEL_BUTTON, BLACK_FONT } from '../../common/cssConstants';
import { getRelativeTime, checkKeyInObject, checkArrayLength } from '../../utils/utils';
import JSONTOCsv from '../../services/JSONToCsv';
import SingleFileCreationService from '../../services/singleFileCreationService';

import ActionMenu from '../Menu/ActionMenu';
import Tooltip from '../Tooltip';
import UploadProject from '../ProjectHeader/UploadProject';
import { ProjectUtils } from '../../utils/ProjectUtils';
import projectMiddleware from '../../store/middleware/projectMiddleware';
import { Hidden } from '../Hidden';

const styles = (theme) => ({
    projectToolbar: {
        ...theme.mixins.toolbar,
        minHeight: '62px',
        padding: '0 2%',
        display: 'flex',
        '&>div': {
            display: 'flex',
        },
    },
    border: {
        borderRadius: '0px',
        padding: '1px !important',
    },
    addIcon: {
        fontSize: 35,
        color: '#fff',
    },
    floatButton: {
        width: '60px',
        height: '60px',
        backgroundColor: '#1BABAB',
        position: 'fixed',
        top: '80vh',
        right: '5vw',
        zIndex: '1000000',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cachedBtn: {
        minHeight: '0px',
        padding: '0px',
        minWidth: '0px',
        textTransform: 'unset',
        '&:hover': {
            backgroundColor: '#e1e1e1',
        },
    },
    BlackSvg: {
        color: '#4A4A4A',
    },
    newCustomButtonText: {
        color: BLACK_FONT,
        fontSize: '12px',
        margin: '7px 20px 6px 0px',
    },
    blackOutlinedButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        borderRadius: '5px',
        backgroundColor: '#F4F6F7',
        marginRight: 10,
        border: '1px solid #C4C4C4',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        '&:hover': {
            backgroundColor: '#e1e1e1',
        },
    },
    BlueButtonSvg: {
        height: 20,
        width: 'auto',
        margin: '5px 8px 5px 20px',
    },
    moreIcons: {
        color: '#8898A8',
        marginRight: 6,
        marginLeft: 8,
        fontSize: 18,
    },
    faIconClass: {
        fontSize: 15,
        marginRight: 13,
        marginLeft: 18,
    },
    sortIcon: {
        margin: '0px 11px 0px 5px',
    },
    actionEditTitleContainer: {
        width: '100%',
        pointerEvents: 'none',
    },
    actionTitleContainerWidth: {
        width: '100%',
        height: '48px',
    },
    actionTitle: {
        color: '#000000',
        marginLeft: '10px',
        fontSize: '11px',
        borderBottom: '1px solid #7E7E7E',
        fontWeight: 'bold',
    },
    horizontalLine: {
        backgroundColor: '#DCDCDC',
        height: '9px',
        borderTop: 'solid',
        marginRight: '-13px',
    },
});

const CustomButton = ({
    btnClass,
    buttonRef,
    id,
    dataRh,
    disabled,
    Icon,
    onClick,
    svgClass,
    flag,
    stopIndicate,
    faIcon,
    txtStyle,
    buttonTitle,
    disableSvgClass,
    enableSvgClass,
    iconStyle,
    circularProgressStyle,
}) => {
    return (
        <span ref={buttonRef}>
            <Tooltip data={disabled ? null : dataRh}>
                <Button data-testid={id} className={btnClass} onClick={onClick} disabled={disabled}>
                    {disabled && flag && !stopIndicate ? (
                        <CircularProgress size={21} style={circularProgressStyle} />
                    ) : Icon ? (
                        <Icon className={`${svgClass} ${disabled ? disableSvgClass : enableSvgClass}`} style={iconStyle}></Icon>
                    ) : faIcon ? (
                        <FontAwesomeIcon className={svgClass} style={iconStyle} icon={faIcon} aria-label="faIcon" id="faIcon" />
                    ) : null}
                    {buttonTitle && <Typography className={txtStyle}>{buttonTitle}</Typography>}
                </Button>
            </Tooltip>
        </span>
    );
};

class ProjectsToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            isDisplayOpen: false,
            isActionMenuOpen: false,
        };
    }

    // handleNewProjectOpen = () => {
    //     const modalData = { user: this.props.user, updateProject: false };
    //     this.props.toggleModal('updateProject', null, null, modalData);
    // };

    handleDownloadProjects = async (downloadType) => {
        const { data } = await projectMiddleware.getProjectsforDownload();
        // close modal
        if (downloadType === 'csv') {
            try {
                const header = {
                    'Project Name': 'Project Name',
                    'Success %': 'Success %',
                    Cases: 'Cases',
                    'Data Count': 'Data Count',
                    'Last Activity': 'Last Activity',
                };
                const downloadData = data.map((project) => {
                    return {
                        'Project Name': `"${project.name.replace(/["]{1}/g, '""')}"`,
                        'Success %': `"${project.success_ratio}%"`,
                        Cases: `"${project.number_of_cases}"`,
                        'Data Count': `"${project.number_of_datas}"`,
                        'Last Activity': `"${getRelativeTime(project.last_activity).replace(/["]{1}/g, '""')}"`,
                    };
                });
                JSONTOCsv.CSVFile(header, downloadData, 'Projects');
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        } else {
            const downloadData = await SingleFileCreationService.getProjectFile(data);
            if (downloadData) {
                const blob = new Blob([downloadData], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, 'Projects.xlsx');
            }
        }
    };

    handleActionMenuPopupToggle = (flag) => {
        this.setState({ isActionMenuOpen: flag });
    };

    handleActionMenuRef = (node) => {
        this.anchorActionMenuEl = node;
    };

    handleDisplayPopupToggle = (flag) => {
        this.setState({ isDisplayOpen: flag });
    };

    handleDisplayButtonRef = (node) => {
        this.anchorDisplayEl = node;
    };

    renderDisplayItems = () => {
        const { classes, order, orderBy, handleRequestSort, listView, handleView, showDisabledProjects, showFavouriteProjects } = this.props;
        const sortIcon =
            order === 'desc' && orderBy === 'projectName' ? (
                <FontAwesomeIcon
                    icon={faSortAlphaUp}
                    aria-label="faSortAlphaUp"
                    id="faSortAlphaUp"
                    className={[classes.moreIcons, classes.moreIconsBtn].join(' ')}
                />
            ) : (
                <FontAwesomeIcon
                    aria-label="faSortAlphaDown"
                    id="faSortAlphaDown"
                    icon={faSortAlphaDown}
                    className={[classes.moreIcons, classes.moreIconsBtn].join(' ')}
                />
            );
        const sortProjectbyLastModifies =
            order === 'desc' && orderBy === 'lastActivityDate' ? (
                <SortAscending className={`${classes.moreIcons} ${classes.sortMaterial}`} />
            ) : (
                <SortDescending className={`${classes.moreIcons} ${classes.sortMaterial}`} />
            );

        const items = [
            {
                // icon: this.subHeader('PROJECTS', false), //<div className={classes.actionEditTitleContainer}>
                icon: (
                    <div className={/* false ? classes.actionTitleContainerWidth : */ classes.actionEditTitleContainer}>
                        {false && <div className={classes.horizontalLine} />}
                        <span className={classes.actionTitle}>PROJECTS</span>
                    </div>
                ),
                isDisabled: true,
            },
            {
                isLink: true,
                route: '/projects',
                action: () => {
                    this.props.queryRemove();
                    this.handleDisplayPopupToggle(false);
                },
                type: 'customProject',
                key: showDisabledProjects ? 'Disabled Projects' : showFavouriteProjects ? 'Starred' : 'All',
                title: 'PROJECTS',
                icon: (
                    <FontAwesomeIcon
                        icon={faWindowRestore}
                        aria-label="faWindowRestore"
                        id="faWindowRestore"
                        className={[classes.moreIcons, classes.moreIconsBtn].join(' ')}
                        style={{ height: 14, width: 20 }}
                    />
                ),
                isDisabled: false,
                name: 'All',
            },
            {
                action: () => {
                    this.props.queryRemove();
                    this.handleDisplayPopupToggle(false);
                },
                isLink: true,
                route: '/projects/disabled',
                type: 'customProject',
                key: showDisabledProjects ? 'Disabled Projects' : showFavouriteProjects ? 'Starred' : 'All',
                title: 'PROJECTS',
                icon: (
                    <FontAwesomeIcon
                        icon={faBan}
                        aria-label="faBan"
                        id="faBan"
                        className={[classes.moreIcons, classes.moreIconsBtn].join(' ')}
                        style={{ height: 14, width: 20 }}
                    />
                ),
                isDisabled: false,
                name: 'Disabled Projects',
            },
            {
                action: () => {
                    this.props.queryRemove();
                    this.handleDisplayPopupToggle(false);
                },
                isLink: true,
                route: '/projects/starred',
                type: 'customProject',
                key: showDisabledProjects ? 'Disabled Projects' : showFavouriteProjects ? 'Starred' : 'All',
                title: 'PROJECTS',
                icon: (
                    <FontAwesomeIcon
                        icon={faStar}
                        aria-label="faStar"
                        id="faStar"
                        className={[classes.moreIcons, classes.moreIconsBtn].join(' ')}
                        style={{ height: 14, width: 20 }}
                    />
                ),
                isDisabled: false,
                name: 'Starred',
            },
            {
                // icon: this.subHeader('VIEW', false,classes), //<div className={classes.actionEditTitleContainer}>
                icon: (
                    <div className={classes.actionTitleContainerWidth}>
                        {true && <div className={classes.horizontalLine} />}
                        <span className={classes.actionTitle}>VIEW</span>
                    </div>
                ),
                isDisabled: true,
            },
            {
                action: () => {
                    handleView(!listView);
                    this.handleDisplayPopupToggle(false);
                },
                type: 'customProject',
                key: listView ? 'Line View' : 'Grid',
                icon: (
                    <FontAwesomeIcon
                        icon={faGripHorizontal}
                        aria-label="faGripHorizontal"
                        id="faGripHorizontal"
                        className={[classes.moreIcons, classes.moreIconsBtn].join(' ')}
                        style={{ height: 14, width: 20 }}
                    />
                ),
                isDisabled: false,
                name: 'Grid',
            },
            {
                action: () => {
                    handleView(!listView);
                    this.handleDisplayPopupToggle(false);
                },
                type: 'customProject',
                key: listView ? 'Line View' : 'Grid',
                icon: (
                    <FontAwesomeIcon
                        icon={faAlignJustify}
                        aria-label="faAlignJustify"
                        id="faAlignJustify"
                        className={[classes.moreIcons, classes.moreIconsBtn].join(' ')}
                        style={{ height: 14, width: 20 }}
                    />
                ),
                isDisabled: false,
                name: 'Line View',
            },
            {
                // icon: this.subHeader('SORT', false), //<div className={classes.actionEditTitleContainer}>
                icon: (
                    <div className={classes.actionTitleContainerWidth}>
                        {true && <div className={classes.horizontalLine} />}
                        <span className={classes.actionTitle}>SORT</span>
                    </div>
                ),
                isDisabled: true,
            },
            {
                action: () => {
                    handleRequestSort('projectName');
                },
                icon: sortIcon,
                title: 'SORT',
                isDisabled: false,
                name: order === 'desc' ? ' Sort Ascending' : ' Sort Descending',
            },
            {
                action: () => {
                    handleRequestSort('lastActivityDate');
                },
                icon: sortProjectbyLastModifies,
                title: 'SORT',
                isDisabled: false,
                name: ' Sort by last modified',
            },
        ];

        return <ActionMenu items={items} ref={this.ref} />;
    };

    renderActionMenuItems() {
        const { classes, toggleModal, uploadProject, showDisabledProjects, showFavouriteProjects } = this.props;
        const items = [
            {
                action: () => {
                    toggleModal('GeneralModal', null, null, {
                        title: 'Upload Project',
                        closeIconAction: () => {
                            toggleModal();
                        },
                        component: [
                            {
                                content: <UploadProject />,
                                buttons: [
                                    {
                                        name: 'Import',
                                        variant: 'contained',
                                        buttonClass: '',
                                        style: { ...MODAL_SUBMIT_BUTTON },
                                        action: () => {
                                            const { tabsData } = this.props;
                                            const formData = new FormData();
                                            if (checkKeyInObject(tabsData, 'uploadedProject') && checkArrayLength(tabsData.uploadedProject)) {
                                                formData.append('project_file', tabsData.uploadedProject[0]);
                                                uploadProject(formData);
                                            }
                                            toggleModal();
                                        },
                                        isDisabled: (tabsData) => {
                                            return !checkKeyInObject(tabsData, 'uploadedProject') || !checkArrayLength(tabsData.uploadedProject);
                                        },
                                    },
                                    {
                                        name: 'Cancel',
                                        variant: 'outlined',
                                        style: { ...MODAL_CANCEL_BUTTON },
                                        action: () => {
                                            toggleModal();
                                        },
                                        isDisabled: false,
                                    },
                                ],
                            },
                        ],
                    });
                    this.handleActionMenuPopupToggle(false);
                },
                icon: (
                    <ExitToApp
                        className={[classes.moreIcons, classes.moreIconsBtn].join(' ')}
                        style={{ fontSize: 18, marginRight: 7 }}
                        aria-label="exitToAppIcon"
                        id="exitToAppIcon"
                    />
                ),
                isDisabled: showDisabledProjects || showFavouriteProjects,
                name: ' Upload Project',
            },
            {
                action1: () => {
                    this.handleDownloadProjects('xlsx');
                    this.handleActionMenuPopupToggle(false);
                },
                action2: () => {
                    this.handleDownloadProjects('csv');
                    this.handleActionMenuPopupToggle(false);
                },
                type: 'customPlain',
                icon: (
                    <FontAwesomeIcon
                        className={[classes.moreIcons, classes.moreIconsBtn].join(' ')}
                        icon={faDownload}
                        aria-label="faDownload"
                        id="faDownload"
                        style={{ fontSize: 16, marginRight: 7, marginLeft: 13 }}
                    />
                ),
                name: ['Download List XLSX', 'CSV'],
            },
        ];
        return <ActionMenu items={items} ref={this.ref} />;
    }

    render() {
        const { classes } = this.props;
        const { isDisplayOpen, isActionMenuOpen } = this.state;
        return (
            <Toolbar className={classes.projectToolbar}>
                <div>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <div
                            aria-hidden
                            className={classes.floatButton}
                            onClick={() => ProjectUtils.toggleUpdateModal(false, null, null, this.props.history)}
                        >
                            <Add className={classes.addIcon} aria-label="addIcon" id="addIcon" />
                        </div>
                    </Hidden>
                    <div style={{ marginLeft: '6px', display: 'flex' }}>
                        <Hidden only={['xs']}>
                            <div className={classes.blackOutlinedButton}>
                                <CustomButton
                                    id="display"
                                    faIcon={faLaptop}
                                    buttonTitle="Display"
                                    txtStyle={classes.newCustomButtonText}
                                    btnClass={classes.cachedBtn}
                                    svgClass={classes.BlueButtonSvg}
                                    iconStyle={{ color: '#4A4A4A' }}
                                    buttonRef={(node) => {
                                        if (node) this.handleDisplayButtonRef(node);
                                    }}
                                    onClick={() => {
                                        this.handleDisplayPopupToggle(true);
                                    }}
                                />
                                <Menu
                                    id="simple-menu"
                                    anchorEl={this.anchorDisplayEl}
                                    keepMounted
                                    open={isDisplayOpen}
                                    onClose={() => {
                                        this.handleDisplayPopupToggle(false);
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    getContentAnchorEl={null}
                                >
                                    {this.renderDisplayItems()}
                                </Menu>
                            </div>

                            <div className={classes.blackOutlinedButton}>
                                <CustomButton
                                    Icon={MoreVert}
                                    aria-label="moreVertIcon"
                                    id="moreVertIcon"
                                    buttonTitle="More"
                                    txtStyle={classes.newCustomButtonText}
                                    btnClass={classes.cachedBtn}
                                    svgClass={classes.BlueButtonSvg}
                                    enableSvgClass={classes.BlackSvg}
                                    buttonRef={(node) => {
                                        if (node) this.handleActionMenuRef(node);
                                    }}
                                    onClick={() => {
                                        this.handleActionMenuPopupToggle(true);
                                    }}
                                />
                                <Menu
                                    id="simple-menu"
                                    anchorEl={this.anchorActionMenuEl}
                                    keepMounted
                                    open={isActionMenuOpen}
                                    onClose={() => {
                                        this.handleActionMenuPopupToggle(false);
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    getContentAnchorEl={null}
                                >
                                    {this.renderActionMenuItems()}
                                </Menu>
                            </div>
                        </Hidden>
                    </div>
                </div>
            </Toolbar>
        );
    }
}

ProjectsToolbar.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    toggleModal: PropTypes.func.isRequired,
    // history: PropTypes.shape({}).isRequired,
};

// export default withStyles(styles)(ProjectsToolbar);
const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        tabsData: state.generalModalReducer.tabsData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadProject: (...args) => dispatch(ProjectActions.uploadProject(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectsToolbar));
