// material import
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// packages import
import PropTypes from 'prop-types';
import React from 'react';
import { BLACK_FONT } from '../../common/cssConstants';

const Cm = ({ action, isDisable, alt, source, title, text, classes }) => {
    return (
        <div
            aria-hidden
            className={!isDisable ? classes.item : classes.disableItem}
            onClick={() => {
                if (!isDisable) action();
            }}
        >
            <img src={source} alt={alt} className={classes.svgIcon} />
            <Typography variant="body2" style={{ marginTop: 10, fontWeight: 'bold', color: BLACK_FONT }}>
                {title}
            </Typography>
            <Typography align="center" style={{ marginTop: 2, fontSize: 12, color: BLACK_FONT }}>
                {text}
            </Typography>
        </div>
    );
};

Cm.propTypes = {
    isDisable: PropTypes.bool.isRequired,
    action: PropTypes.func.isRequired,
    alt: PropTypes.string.isRequired,
    classes: PropTypes.shape({}).isRequired,
    source: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

// Theme-dependent styles
const styles = (theme) => ({
    item: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    disableItem: {
        alignItems: 'center',
        cursor: 'not-allowed',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    svgIcon: {
        height: 70,
        marginBottom: 7,
        marginTop: '-7px',
        width: 70,
        [theme.breakpoints.only('xs')]: {
            height: 50,
            width: 50,
        },
    },
});

export default withStyles(styles)(Cm);
