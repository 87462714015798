import React, { memo, useEffect, useState } from "react";
import {
  Card,
  CardContent,
} from "@material-ui/core";
import "./index.css";
import LoadingSkelton from "./LoadingSkelton";
import OverallHealthData from "./OverallHealthData";
import { useDispatch, useSelector } from "react-redux";
import { DashboardActions } from "../../store/actions";

const OverallHealth = () => {
    
  let [dataReady, setDataReady] = useState(false);
  const [overallHealthData, setOverallHealthData] = useState({});

  const dispatch = useDispatch();

  const dashboard_data = useSelector(
    (state) => state.dashboardReducer.dashboardData
  );

  useEffect(() => {
    dispatch(DashboardActions.getDashboardInfo("Week"));
  }, []);

  useEffect(() => {
    if (
      dashboard_data &&
      dashboard_data.project_stats &&
      dashboard_data.suite_stats
    ) {
      fetchOverallHealthData(dashboard_data);
    }
  }, [dashboard_data]);

  const fetchOverallHealthData = (dashboard_data) => {
    let projectData = {
      progress: 0,
      passed: 0,
      failed: 0,
      statics: [],
    };
    let suitData = {
      progress: 0,
      passed: 0,
      failed: 0,
      statics: [],
    };

    projectData.progress = dashboard_data?.project_stats?.cumulative_stats;

    if (
      dashboard_data.project_stats &&
      dashboard_data.project_stats.prj_stats
    ) {
      let fail = 0;
      let pass = 0;

      dashboard_data.project_stats.prj_stats.forEach((stats) => {
        fail = fail + stats.total_tests_failed_count;
        pass = pass + stats.total_tests_passed_count;
      });

      projectData.passed = pass;
      projectData.failed = fail;

      if (fail === 0 && pass === 0) {
        projectData.statics.push({
          name: "default",
          value: 1,
        });
      } else {
        projectData.statics.push({
          name: "Passed",
          value: pass,
        });

        projectData.statics.push({
          name: "Failed",
          value: fail,
        });
      }
    }

    if (dashboard_data.suite_stats) {
      const pass = dashboard_data.suite_stats.total_passed || 0;
      const fail = dashboard_data.suite_stats.total_failed || 0;

      suitData.passed = pass;
      suitData.progress = dashboard_data.suite_stats.cummulative_stats || 0;
      suitData.failed = fail;

      if (fail === 0 && pass === 0) {
        suitData.statics.push({
          name: "default",
          value: 1,
        });
      } else {
        suitData.statics.push({
          name: "Passed",
          value: pass,
        });

        suitData.statics.push({
          name: "Failed",
          value: fail,
        });
      }
    }

    setOverallHealthData((preData) => {
      return { ...preData, projectData: projectData, suiteData: suitData };
    });
    setDataReady(true);
  };

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          {dataReady && <OverallHealthData data={overallHealthData} />}
          {!dataReady && <LoadingSkelton />}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default memo(OverallHealth);
