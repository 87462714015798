// imports
import React from 'react';
import store from '../store';
import { ModalActions } from '../store/actions';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON } from '../components/Button/Btn';
import ConfirmationModal from '../features/modal/GeneralModal/ConfirmationModal';

class TestDataUtils {
    toggleConfirmationModal = ({ data = [], dataName, handleButtonClick }) => {
        const { toggleModal } = this.props;
        toggleModal(
            'GeneralModal',
            null,
            null,
            {
                title: 'Replace Association',
                closeIconAction: () => {
                    toggleModal('GeneralModal', null, null, null, false, true);
                },
                component: [
                    {
                        content: (
                            <ConfirmationModal
                                data={data}
                                dataName={dataName}
                                msg={
                                    <>
                                        Following Test Case(s) have already associated data. <br /> Do you want to replace it with
                                    </>
                                }
                            />
                        ),
                        buttons: [
                            NEXT_SUBMIT_BUTTON({
                                name: 'Replace',
                                action: () => {
                                    handleButtonClick();
                                },
                            }),
                            CANCEL_BUTTON({ action: () => toggleModal('GeneralModal', null, null, null, false, true) }),
                        ],
                    },
                ],
            },
            true,
        );
    };

    props = {
        toggleModal: (...args) => {
            store.dispatch(ModalActions.toggleModal(...args));
        },
    };
}

export const testDataUtils = new TestDataUtils();
