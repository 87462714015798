import Close from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CloseOnEscape from 'react-close-on-escape';
import PropTypes from 'prop-types';

import { MODAL_HEADER_TITLE, MODAL_HEADER_CLOSE_ICON_CONT, MODAL_HEADER_CLOSE_ICON, MODAL_EXECUTION_TITLE } from '../../common/cssConstants';
import Tooltip from '../../components/Tooltip';

const styles = () => ({
    dialogTitleStyle: MODAL_HEADER_TITLE,
    dialogTitleForExecutionModal: MODAL_EXECUTION_TITLE,
    closeIconWrapper: MODAL_HEADER_CLOSE_ICON_CONT,
    closeIcon: MODAL_HEADER_CLOSE_ICON,
    top: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    suiteNameStyle: {
        paddingLeft: '30px',
        color: '#595959',
        fontSize: '15px',
        overflow: 'hidden',
        textOverflow: "ellipsis",
        maxWidth: '400px',
        whiteSpace: 'nowrap'
    },
});

const DialogTopComponent = (props) => {
    const { classes, icon, closeIcon, title, titleStyle, closeIconAction, onEscapeAction, suiteName } = props;
    return [
        <div className={classes.top} key={`${Math.random()}-${title}`}>
            <DialogTitle id="form-dialog-title" classes={{ root: title === "Execution" ? `${classes.dialogTitleForExecutionModal}` : `${classes.dialogTitleStyle}` }} style={{ ...titleStyle }}>
                {icon}
                {title}
            </DialogTitle>
            <CloseOnEscape
                onEscape={() => {
                    if (onEscapeAction) onEscapeAction();
                    else closeIconAction();
                }}
            >
                {closeIconAction ? (
                    <div aria-hidden className={classes.closeIconWrapper} style={{zIndex: 1500}} onClick={closeIconAction}>
                        {closeIcon ||
                            <Tooltip data='Close'>
                                <Close className={classes.closeIcon} aria-label="closeIcon" id="closeIcon" />
                            </Tooltip>
                        }
                    </div>
                ) : null}
            </CloseOnEscape>

        </div>,
        suiteName &&
        <div className={classes.suiteNameStyle} key={`${Math.random()}-${suiteName}`}>
            <Tooltip data={suiteName}>
                <span>
                    {suiteName}
                </span>
            </Tooltip>
        </div>
    ];
};
DialogTopComponent.propTypes = {
    title: PropTypes.string,
    titleStyle: PropTypes.object,
    closeIconAction: PropTypes.func,
    icon: PropTypes.node,
    closeIcon: PropTypes.node,
    onEscapeAction: PropTypes.func,
};
DialogTopComponent.defaultProps = {
    title: '',
    titleStyle: {},
    closeIconAction: () => { },
    icon: null,
    closeIcon: null,
    onEscapeAction: null,
};

export const DialogTop = withStyles(styles)(DialogTopComponent);
