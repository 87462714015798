import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ProjectTime from './ProjectTime';
import { getRelativeTime, getFormatedDate } from '../../utils/utils';

// custom components
import ProjectCardActions from './ProjectCardActions';

const styles = () => ({
    menu: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

function ProjectName(props) {
    const { header, classes, gear, onDelete, project, toggleModal, projectId, showDisabledProjects, user, isFavourite, handleFavourite } = props;
    return (
        <div>
            <Grid>
                {gear ? (
                    <Grid item xs className={classes.menu}>
                        <ProjectTime
                            time={
                                user && user.isRelativeDateFormat ? getRelativeTime(header.date) : getFormatedDate(header.date, 'MMM DD YYYY', 'N/A')
                            }
                        />
                        <ProjectCardActions
                            showDisabledProjects={showDisabledProjects}
                            handleUpdateProject={props.handleUpdateProject}
                            handleProjectNameEdit={props.handleProjectNameEdit}
                            projectId={projectId}
                            project={project}
                            onDelete={onDelete}
                            toggleModal={toggleModal}
                            handleFavourite={handleFavourite}
                            isFavourite={isFavourite}
                        />
                    </Grid>
                ) : (
                    <Grid item xs />
                )}
            </Grid>
        </div>
    );
}

ProjectName.propTypes = {
    // name: PropTypes.string.isRequired,
    // project: PropTypes.shape({}).isRequired,
    // gear: PropTypes.bool,
    // classes: PropTypes.shape({}).isRequired,
    // onDelete: PropTypes.func,
    // toggleUpload: PropTypes.func,
};

ProjectName.defaultProps = {
    gear: true,
    onDelete: null,
    toggleUpload: () => {},
};

export default withStyles(styles)(ProjectName);
