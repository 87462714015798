// import materials
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography, Hidden } from '@material-ui/core';

// import packages
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';
import CoreList from '@material-ui/core/List';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import Slider from 'react-slick';
import ListItem from '@material-ui/core/ListItem';

// import custom components
import ExecutionStepRow from './ExecutionStepRow';
import { ExecutionActions, TestCaseActions, ProjectActions } from '../../../../store/actions';
import { checkArrayLength, TestCaseUtils, removeAllListeners, addListener, checkKeyInObject, updateSliderSetting, getParamValues } from '../../../../utils';
import WSService from '../../../../services/WSService';
import { TestStepUtils } from '../../../../utils/TestStepUtils';
import SliderImage from '../../Case/SliderImage';
import LoadingStepRow from '../../TestSteps/LiveSteps/LoadingRow';

const sliderHeight = 106;
const sortableListHeight = 215;
const styles = (theme) => ({
    circularProgress: {
        margin: '70px 45% 26px',
        color: '#4885ed',
    },
    noStepMessage: {
        fontSize: 17,
        textAlign: 'center',
        paddingTop: 24,
    },
    root: {
        width: '100%',
    },
    sortableListItemContainer: {
        overflowY: 'auto',
        '&>div': {
            minHeight: 'inherit',
            maxHeight: 'inherit',
            outline: 'none',
            '&>div': {
                backgroundColor: '#fff',
                width: '100% !important',
                maxWidth: 'unset !important',
            },
        },
    },
    sortableListItemContainerForWebkit: {
        '-webkit-transition': 'max-height 0.5s, min-height 0.5s',
        transition: 'max-height 0.5s, min-height 0.5s',
    },
    sliderMask: {
        position: 'absolute',
        width: 'calc(100% - 20px)',
        zIndex: 100,
        top: 10,
        left: 10,
        height: 'calc(100% - 15px)',
        background: 'rgba(0,0,0,0.4)',
    },
    ulParent: {
        '&>ul': {
            padding: '0px',
            paddingTop: '0px',
            minHeight: `calc(100vh - ${sortableListHeight + sliderHeight}px)`,
        },
    },
    screenshotCollapseButton: {
        position: 'absolute',
        height: '30px',
        width: '30px',
        right: -20,
        top: -30,
        borderRadius: '5px 5px 0 0',
        backgroundColor: '#1168CD',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    collapseIcon: {
        height: 20,
        width: 20,
        color: '#FFFFFF',
    },
    listViewScreenShotContainer: {
        width: 'calc(100% + 40px)',
        backgroundColor: 'rgb(216, 216, 216)',
        marginLeft: -20,
        overflow: 'hidden',
        [theme.breakpoints.only('xs')]: {
            width: '95%',
            marginLeft: 8,
        },
    },
    subListViewScreenShotContainer: {
        position: 'relative',
        margin: '0 auto',
        width: '90%',
        [theme.breakpoints.only('xs')]: {
            width: '85%',
        },
    },
});
const sliderSettings = {
    dots: false,
    speed: 500,
    slidesToScroll: 1,
    infinite: false,
};
class ExecutionTestSteps extends React.Component {
    state = {
        sortableListItemContainerHeight: 200,
        hoverCase: null,
        selectedHoverImage: null,
        showSlider: false,
        listPosition: 1,
        resetListPos: false,
        slidesToShow: 4,
        newObj: null
    };

    async componentDidMount() {
        const { setExecutionStepsData, executionSteps, executionResultDataSteps, userVariables, getUserVariables, user } = this.props;
        if (checkArrayLength(executionSteps)) {
            setExecutionStepsData(executionSteps);
        } else if (checkArrayLength(executionResultDataSteps)) {
            setExecutionStepsData(executionResultDataSteps);
        }
        if (!checkArrayLength(userVariables)) {
            getUserVariables(user.accountId, getParamValues()[1]);
        }
        addListener(window, 'resize', this.calculateHeight);
        this.ws = await WSService.getWebSocketInstance();
        this.reviceWSEvents();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { executionSteps, setExecutionStepsData, instrNumArray, executionResultDataSteps } = nextProps;
        if (checkArrayLength(executionSteps) && !checkArrayLength(instrNumArray)) {
            setExecutionStepsData(executionSteps);
            this.setState({newObj: executionSteps})
        } else if (checkArrayLength(executionResultDataSteps) && !checkArrayLength(instrNumArray)) {
            setExecutionStepsData(executionResultDataSteps);
            this.setState({newObj: executionResultDataSteps})
        }
    }

    componentDidUpdate(prevProps,prevState) {
        if(prevProps.executionSteps !== prevState.newObj) {
            const { setExecutionStepsData, executionResultDataSteps,executionSteps } = this.props;
            if (checkArrayLength(executionSteps) && this.state.newObj !== executionSteps) {
                setExecutionStepsData(executionSteps);
                this.setState({newObj: executionSteps})
            } else if (checkArrayLength(executionResultDataSteps) && this.state.newObj !== executionResultDataSteps) {
                setExecutionStepsData(executionResultDataSteps);
                this.setState({newObj: executionResultDataSteps})
            }
        }
        this.calculateHeight();
        this.updateSliderSetting();
    }

    componentWillUnmount() {
        removeAllListeners(window, 'resize');
    }

    intervalHover;

    slider;

    ws = {};

    scrollDiff = 0;

    prevScrollPos = 0;

    calculateHeight = () => {
        if (
            checkKeyInObject(TestStepUtils.stepsParentRef, 'current.offsetHeight') &&
            TestStepUtils.stepsParentRef.current.offsetHeight !== this.state.sortableListItemContainerHeight
        ) {
            this.setState(
                {
                    sortableListItemContainerHeight: TestStepUtils.stepsParentRef.current.offsetHeight,
                },
                () => {
                    if (this.state.listPosition === 1) {
                        this.setState({ listPosition: undefined }, () => {
                            setTimeout(() => {
                                this.setState({ resetListPos: true });
                                const innerScrollContainer = document.getElementsByClassName('ReactVirtualized__Grid__innerScrollContainer');
                                if (innerScrollContainer && innerScrollContainer[0]) {
                                    innerScrollContainer[0].parentElement.scrollTo(0, 0);
                                }
                            }, 300);
                        });
                    }
                },
            );
        }
    };

    reviceWSEvents = () => {
        const actions = {};

        const data = {
            callingFrom: 'ExecutionTestSteps',
            paramExecutionId: this.props.executionId,
        };
        // Invoking web socket receiver
        TestCaseUtils.receiveWSEvents(this.ws, actions, data);
    };

    showEmptyMessageOrLoader = () => {
        const { classes, executionStepsLoading } = this.props;
        return executionStepsLoading ? (
            <div style={{ width: '100%' }}>
                <CircularProgress className={classes.circularProgress} size={60} />
            </div>
        ) : (
            <Typography variant="caption" className={classes.noStepMessage}>
                No test steps
            </Typography>
        );
    };

    /* Screen Shot Slider Start */
    updateSliderSetting = () => {
        if (this.props.listView) {
            const slidesToShow = updateSliderSetting(this.state.slidesToShow);
            if (slidesToShow) {
                this.setState({ slidesToShow });
            }
        }
    };
    createSliderImages = () => {
        const { classes, listView, instrNumArray, testSteps, toggleScreenShotModal } = this.props;
        const { hoverCase } = this.state;
        const screenShotsSlides = [];
        if (checkArrayLength(instrNumArray)) {
            instrNumArray.forEach((instrNum, index) => {
                const testStep = testSteps[instrNum];
                if (testStep.screenshotSmallPaths && testStep.screenshotSmallPaths.length && listView) {
                    screenShotsSlides.push(
                        <SliderImage
                            key={`slider-${testStep.instrNum}`}
                            hoverCase={hoverCase}
                            currentIndex={index}
                            alt={testStep.instr}
                            sliderMask={classes.sliderMask}
                            src={TestStepUtils.getPreviewImage(testStep)}
                            handleModal={toggleScreenShotModal}
                            totalLength={instrNumArray.length}
                            selectedHoverImage={this.selectedHoverImage}
                            ind={index}
                        />,
                    );
                }
            });
        }
        return { screenShotsSlides };
    };

    changeIndex = (index) => {
        const { listView } = this.props;
        if (listView && this.state.hoverCase !== index) {
            clearTimeout(this.intervalHover);
            this.intervalHover = setTimeout(() => {
                if (index !== null) {
                    if (this.slider) this.slider.slickGoTo(index);
                }
                this.setState({ hoverCase: index });
            }, 200);
        }
    };

    selectedHoverImage = (index) => {
        this.setState({ selectedHoverImage: index });
    };
    /* Screen Shot Slider End */

    render() {
        const { classes, executionId, instrNumArray, listView, projectId, testSteps, query, userVariables } = this.props;
        const { sortableListItemContainerHeight, selectedHoverImage, showSlider, listPosition, resetListPos, slidesToShow } = this.state;

        const _instrNumArray = [];
        if (checkArrayLength(instrNumArray)) {
            instrNumArray.forEach((instrNum, index) => {
                const testStep = testSteps[instrNum];
                if (
                    !query ||
                    (query &&
                        ((checkKeyInObject(testStep, 'instr') && testStep.instr.toLowerCase().includes(query.toLowerCase())) ||
                            (checkKeyInObject(testStep, 'data') && testStep.data.toLowerCase().includes(query.toLowerCase()))))
                ) {
                    if (instrNumArray[index]) {
                        _instrNumArray.push(instrNumArray[index]);
                    }
                }
            });
        }

        const getRow = ({ index, isScrolling, style }) => {
            const step = _instrNumArray[index];
            const ind = instrNumArray.indexOf(step);
            if ((isScrolling && !TestStepUtils.isAutoScrolling && this.scrollDiff > 300) || !resetListPos) {
                return <LoadingStepRow length={1} listView={listView} key={`item-${ind}`} itemKey={`item-${index}`} style={style} />;
            }
            const data = (
                <span key={`item-${ind}`} style={{ ...style, cursor: 'default' }}>
                    <ExecutionStepRow
                        index={ind}
                        instrNum={step}
                        listView={listView}
                        projectId={projectId}
                        executionId={executionId}
                        testStep={testSteps[step]}
                        getPreviewImage={TestStepUtils.getPreviewImage}
                        selectedHoverImage={selectedHoverImage}
                        changeIndex={this.changeIndex}
                        userVariables={userVariables}
                    />
                </span>
            );
            return data;
        };
        const totalRows = checkArrayLength(_instrNumArray) ? _instrNumArray.length : 0;
        const content = (
            <List
                rowCount={totalRows}
                width={1}
                height={sortableListItemContainerHeight}
                className="scrollTable"
                rowHeight={listView ? 37 : 123}
                style={{ width: '100%' }}
                rowRenderer={getRow}
                noRowsRenderer={this.showEmptyMessageOrLoader}
                scrollToIndex={listPosition === 1 ? totalRows - 1 : undefined}
                onScroll={(param) => {
                    this.scrollDiff = Math.abs(param.scrollTop - this.prevScrollPos);
                    this.prevScrollPos = param.scrollTop;
                }}
            />
        );

        const { screenShotsSlides } = this.createSliderImages();

        return (
            <div className={classes.root}>
                <div className={classes.ulParent}>
                    <CoreList>
                        <ListItem disableGutters style={{ padding: 0 }}>
                            <div className={classes.root}>
                                <div
                                    ref={TestStepUtils.stepsParentRef}
                                    className={`${classes.sortableListItemContainer} ${listView ? classes.sortableListItemContainerForWebkit : ''}`}
                                    style={{
                                        maxHeight: `calc(100vh - ${
                                            sortableListHeight + (listView && screenShotsSlides.length && !showSlider ? sliderHeight : 0)
                                        }px)`,
                                        minHeight: `calc(100vh - ${
                                            sortableListHeight + (listView && screenShotsSlides.length && !showSlider ? sliderHeight : 0)
                                        }px)`,
                                    }}
                                >
                                    {content}
                                </div>
                            </div>
                        </ListItem>
                    </CoreList>
                    {listView ? (
                        <div style={{ position: 'relative' }}>
                            {checkArrayLength(screenShotsSlides) && (
                                <div
                                    aria-hidden
                                    className={classes.screenshotCollapseButton}
                                    onClick={() => {
                                        this.setState({ showSlider: !showSlider }, () => {
                                            setTimeout(() => {
                                                this.calculateHeight();
                                            }, 500);
                                        });
                                    }}
                                >
                                    {showSlider ? (
                                        <KeyboardArrowUp className={classes.collapseIcon} aria-label="keyboardArrowUpIcon" id="keyboardArrowUpIcon" />
                                    ) : (
                                        <KeyboardArrowDown
                                            className={classes.collapseIcon}
                                            aria-label="keyboardArrowDownIcon"
                                            id="keyboardArrowDownIcon"
                                        />
                                    )}
                                </div>
                            )}
                            <div className={classes.listViewScreenShotContainer}>
                                <div className={classes.subListViewScreenShotContainer} id="subListViewScreenShotContainer">
                                    <Hidden only={['xs', 'sm']}>
                                        <Slider
                                            ref={(_ref) => {
                                                this.slider = _ref;
                                            }}
                                            {...sliderSettings}
                                            slidesToShow={slidesToShow}
                                        >
                                            {screenShotsSlides}
                                        </Slider>
                                    </Hidden>
                                    <Hidden only={['md', 'lg', 'xl']}>
                                        <Slider
                                            ref={(_ref) => {
                                                this.slider = _ref;
                                            }}
                                            {...sliderSettings}
                                            slidesToShow="1"
                                        >
                                            {screenShotsSlides}
                                        </Slider>
                                    </Hidden>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        // project reducer
        executionSteps: checkKeyInObject(state.projectReducer.executionRunningData[props.executionId], 'steps', 'value', []),
        // execution reducer
        //executionDetailsSteps: checkKeyInObject(state.executionReducer.executionDetails, 'Steps', 'value', []),
        executionResultDataSteps: checkKeyInObject(state.executionReducer.executionDetails, 'resultData', 'value', []),
        executionStepsLoading: state.executionReducer.executionStepsLoading,
        instrNumArray: state.executionReducer.instrNumArray,
        listView: state.executionReducer.listView,
        testSteps: state.executionReducer.testSteps,
        query: state.generalReducer.queryValue,
        user: state.authReducer.user,
        userVariables: state.projectReducer.userVariables,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setExecutionStepsData: (...args) => dispatch(ExecutionActions.setExecutionStepsData(...args)),
        toggleScreenShotModal: (...args) => dispatch(TestCaseActions.toggleScreenShotModal(...args)),
        getUserVariables: (...args) => dispatch(ProjectActions.getUserVariables(...args)),
    };
};

ExecutionTestSteps.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionTestSteps));
