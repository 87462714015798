import { ActionTypes } from '../constants';
import SuiteReportMiddleware from '../middleware/suiteReportMiddleware';

class SuiteReportAction {
    static getExecutions(obj, getWithoutLoading = false) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_SUITE_REPORT_EXECUTIONS,
                payload: { getWithoutLoading },
            });
            return SuiteReportMiddleware.getExecutions(obj)
                .then((data) =>
                    dispatch({
                        type: ActionTypes.GET_SUITE_REPORT_EXECUTIONS_SUCCESS,
                        payload: { data },
                    }),
                )
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_SUITE_REPORT_EXECUTIONS_FAILURE,
                        payload: error,
                    }),
                );
        };
    }
    static executeFailedTestCases(jobId,testSuiteId,requestData) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.EXECUTE_FAILED_TESTCASES,
                payload: { jobId },
            });
            return SuiteReportMiddleware.executeFailedTestCases(jobId,testSuiteId,requestData)
                .then((data) =>
                    dispatch({
                        type: ActionTypes.EXECUTE_FAILED_TESTCASES_SUCCESS,
                        payload: { data },
                    }),
                )
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.EXECUTE_FAILED_TESTCASES_FAILURE,
                        payload: error,
                    }),
                );
        };
    }
}

export default SuiteReportAction;
