// material-ui component
import { withStyles } from '@material-ui/core/styles';
import { TableSortLabel, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
// lib import
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
// import icons
import Help from '@material-ui/icons/Help';
import { faChevronDown, faChevronUp, faSortDown, faEraser, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableRow from '../Table/TableRow';
import TableCell from '../Table/TableCell';
import TableHead from '../Table/TableHead';
import { resizerX } from '../../utils/utils';
import Tooltip from '../Tooltip';

  
// constants and variables
const styles = () => ({
    sortIconAwaysVisible: {
        visibility: 'hidden',
    },
    sortIcon: {
        fontSize: 10,
        marginLeft: 10,
        color: '#9B9B9B',
        display: 'inline-block !important',
        right: 5,
    },
    moreIcons: {
        fontSize: 18,
        marginLeft: 10,
        color: '#9B9B9B',
        display: 'inline-block !important',
        right: 5,
    },
    label: {
        cursor: 'pointer',
        '&:hover': {
            '& $sortIconAwaysVisible': {
                visibility: 'visible',
            },
        },
    },
    resizeableDiv: {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        zIndex: 2,
        display: 'none',
    },
    actionsBtn: {
        width: '24px',
        height: '24px',
        cursor: 'pointer',
        color: '#141416',
    },
    tootTipLabel:{
        position: 'static',
        width: '213px',
        height: '48px',
        left: '8px',
        top: '4px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#FFFFFF',
    },
    paperBody:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '4px 8px',
        position: 'relative',
        width: '229px',
        height: '56px',
        left: '6px',
        top: '-90px', 
        backgroundColor: '#1E2127',
        borderRadius: '4px',
    },
    helpIcon:{
        fontSize:18,
        color: '#5F7B96',
        marginRight: 5,
        marginLeft: 10,
        display: 'inline-block !important',
        right: 5,
    },
});

const EnhancedTableHeadBase = ({
    classes,
    headers,
    order,
    orderBy,
    showSelectBtn,
    showDeleteBtn,
    showEraseBtn,
    showDownloadBtn,
    rowCount,
    numSelected,
    inActiveCount,
    isColSpan,
    onSelectAllClick,
    onDeleteClick,
    onDownloadClick,
    resizeable,
    selectbuttonColIndex,
    deletebuttonColIndex,
    erasebuttonColIndex,
    downloadbuttonColIndex,
    checkBoxClass,
    tableHeadStyle,
    elementPrefix,
    tableRowStyle,
    noResizeColsIndex,
    colsWidth,
    onRequestSort,
    cellHeight,
}) => {
    useEffect(() => {
        prepareResizer();
    });

    const prepareResizer = () => {
        if (resizeable) {
            headers.forEach((data, index) => {
                if (index !== headers.length - 1 && noResizeColsIndex.findIndex((i) => i === index) === -1) {
                    resizerX(`r${index}`, `element${index}`, `element${index + 1}`, colsWidth, elementPrefix);
                }
            });
        }
    };

    const createSortHandler = (property) => {
        onRequestSort(property);
    };

    return (
        <TableHead isTableHeadClass={true} dataTypePrefix={`${elementPrefix}`} style={{ ...tableHeadStyle }}>
            {resizeable && <div id={`${elementPrefix}mask`} className={classes.resizeableDiv}></div>}
            <TableRow
                style={{
                    ...tableRowStyle,
                }}
                isTableRowStyle={true}
                showBorder={'none'}
            >
                {headers.map((column, index) =>
                    (column.status && column.status === 'InActive') || (column.checked && column.checked === true) ? null : (
                        <TableCell
                            dataTypePrefix={`${elementPrefix}`}
                            isTableCellClass={true} 
                            colSpan={isColSpan ? (column.colSpan || 1) + (inActiveCount && index === 0 ? inActiveCount : 0) : 1}
                            key={column.id}
                            width={column.width}
                            style={column.tableCellStyles}
                            nowrap={column.wrap === false}
                            centerV={column.centerV}
                        >
                            <div
                                id={`${elementPrefix}element${index}`}
                                className={`${column.sortable ? classes.label : ''} THCellWrapper`}
                                onClick={() => {
                                    if (column.sortable) createSortHandler(column.id);
                                }}
                                aria-hidden
                            >
                                <span style={column.styles ? { ...column.styles, display: 'flex' } : { display: 'flex' }}>
                                    {/* {index === selectbuttonColIndex && showSelectBtn ? (
                                        <Checkbox
                                            indeterminate={numSelected > 0 && numSelected < rowCount}
                                            className={checkBoxClass}
                                            checked={numSelected === rowCount && numSelected > 0}
                                            onChange={(e) => {
                                                if (rowCount > 0) {
                                                    onSelectAllClick(e);
                                                }
                                            }}
                                            style={{
                                                marginLeft: 5,
                                                color: numSelected === rowCount && rowCount !== 0 ? '#3b91df' : '#bac4ce',
                                                cursor: rowCount === 0 ? 'not-allowed' : 'pointer',
                                            }}
                                        />
                                    ) : null} */}
                                    {(index === erasebuttonColIndex) && showEraseBtn && numSelected > 0 ? (
                                        <Tooltip data="Erase">
                                            <IconButton  className={classes.IconButton} onClick={onDeleteClick}>
                                                <FontAwesomeIcon
                                                    icon={faEraser}
                                                    aria-label="faEraser"
                                                    id="faEraser"
                                                    className={classes.moreIcons}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    ) : null}
                                    {index === deletebuttonColIndex && showDeleteBtn && numSelected > 0 ? (
                                            <Tooltip data="Delete">
                                                <IconButton className={classes.IconButton} onClick={onDownloadClick}>
                                                    <FontAwesomeIcon
                                                        icon={faTrashAlt}
                                                        aria-label="faTrashAlt"
                                                        id="faTrashAlt"
                                                        className={classes.moreIcons}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        
                                    ) : null}
                                    
                                    {index === downloadbuttonColIndex && showDownloadBtn && numSelected > 0 ? (
                                            <Tooltip data="Download">
                                                <IconButton className={classes.IconButton} onClick={onDownloadClick}>
                                                    <FontAwesomeIcon
                                                        icon={faDownload}
                                                        aria-label="faDownload"
                                                        id="faDownload"
                                                        className={classes.moreIcons}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        
                                    ) : null}

                                    {column.sortable ? (
                                        <TableSortLabel hideSortIcon className="sortLable">
                                            <Typography variant="body2" className="THSortText" noWrap={!column.wrap}>
                                                <span
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <span style={{ color: column.color }}>{column.label}</span>
                                                    {orderBy === column.id ? (
                                                        order === 'desc' ? (
                                                            <FontAwesomeIcon
                                                                icon={faChevronDown}
                                                                aria-label="faChevronDown"
                                                                id="faChevronDown"
                                                                className={classes.sortIcon}
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faChevronUp}
                                                                aria-label="faChevronUp"
                                                                id="faChevronUp"
                                                                className={classes.sortIcon}
                                                            />
                                                        )
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon={faChevronDown}
                                                            aria-label="faChevronDownhover"
                                                            id="faChevronDownhover"
                                                            className={`${classes.sortIcon} ${classes.sortIconAwaysVisible}`}
                                                        />
                                                    )}
                                                </span>
                                            </Typography>
                                        </TableSortLabel>
                                    ) : (
                                        <Typography variant="body2" noWrap className="THText" style={{ color: column.color }}>
                                            {column.label}
                                        </Typography>
                                    )}
                                    {column.tooltipAllowed ? (
                                        <Tooltip                         
                                        paperBody={classes.paperBody}
                                        tootTipLabel={classes.tootTipLabel}
                                        data="Test cases that are linked to this data.">
                                            <Help
                                                className={`${classes.helpIcon}`}
                                                aria-label="helpIcon"
                                                id="helpIcon"
                                            />
                                        </Tooltip>
                                    ) : null}
                                </span>
                            </div>
                            {index !== headers.length - 1 && noResizeColsIndex.findIndex((i) => i === index) === -1 && resizeable && (
                                <span
                                    style={{
                                        height: cellHeight ? cellHeight : 40,
                                        display: 'inline-block',
                                        borderRight: '1px #ECECEC solid',
                                        width: 4,
                                        position: 'absolute',
                                        cursor: 'col-resize',
                                        zIndex: 1,
                                        right: '0',
                                        top: '7',
                                    }}
                                    id={`${elementPrefix}r${index}`}
                                >
                                    <FontAwesomeIcon
                                        style={{ color: '#9B9B9B', position: 'absolute', top: '-9px', right: '-4px' }}
                                        icon={faSortDown}
                                        aria-label="faSortDown"
                                        id="faSortDown"
                                    />
                                </span>
                            )}
                        </TableCell>
                    ),
                )}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHeadBase.propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            label: PropTypes.string | PropTypes.object,
            styles: PropTypes.shape({}),
            tableCellStyles: PropTypes.shape({}),
            width: PropTypes.string.isRequired,
            status: PropTypes.string,
            checked: PropTypes.bool,
            colSpan: PropTypes.number,
            wrap: PropTypes.bool,
            centerV: PropTypes.bool,
        }),
    ).isRequired,
    numSelected: PropTypes.number,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    onSelectAllClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onDownloadClick: PropTypes.func,
    onRequestSort: PropTypes.func,
    rowCount: PropTypes.number,
    selectbuttonColIndex: PropTypes.number,
    deletebuttonColIndex: PropTypes.number,
    erasebuttonColIndex: PropTypes.number,
    downloadbuttonColIndex:PropTypes.number,
    colsWidth: PropTypes.shape({}),
    resizeable: PropTypes.bool,
    showSelectBtn: PropTypes.bool,
    showDeleteBtn: PropTypes.bool,
    showEraseBtn: PropTypes.bool,
    showDownloadBtn:PropTypes.bool,
    elementPrefix: PropTypes.string,
    tableRowStyle: PropTypes.shape({}),
    inActiveCount: PropTypes.number,
    isColSpan: PropTypes.bool,
    checkBoxClass: PropTypes.string,
    tableHeadStyle: PropTypes.shape({}),
    noResizeColsIndex: PropTypes.arrayOf(PropTypes.number),
};

EnhancedTableHeadBase.defaultProps = {
    numSelected: 0,
    order: 'asc',
    orderBy: '',
    onSelectAllClick: () => {},
    onDeleteClick: () => {},
    onDownloadClick: () => {},
    onRequestSort: () => {},
    rowCount: 0,
    selectbuttonColIndex: 0,
    deletebuttonColIndex: null,
    erasebuttonColIndex: null,
    downloadbuttonColIndex: null,
    colsWidth: {},
    resizeable: false,
    showSelectBtn: false,
    showDeleteBtn: false,
    showEraseBtn: false,
    showDownloadBtn:false,
    elementPrefix: '',
    tableRowStyle: {},
    inActiveCount: 0,
    isColSpan: false,
    checkBoxClass: '',
    tableHeadStyle: {},
    noResizeColsIndex: [],
};

export default withStyles(styles)(EnhancedTableHeadBase);
