import { ActionTypes } from '../constants';
// import { checkObject } from '../../utils';

const initialState = {
    error: null,
    isError: false,
    isLoading: true,
    success: null,
    sideBarCount: {
        num_of_projects: 0,
        num_of_test_cases: 0,
        num_of_test_suits: 0,
        num_of_users: 0,
        num_of_accounts: 0,
        num_of_groups: 0,
    },
};

export default function sidebarReducer(state = initialState, { payload, type }) {
    switch (type) {
        case ActionTypes.DECREASE_SIDEBAR_COUNT: {
            const obj = { ...state };
            const { name, count } = payload;
            obj.sideBarCount[name] -= count;
            return obj;
        }

        case ActionTypes.INCREASE_SIDEBAR_COUNT: {
            const obj = { ...state };
            const { name, count } = payload;
            obj.sideBarCount[name] += count;
            return obj;
        }

        case ActionTypes.SIDEBAR_COUNT_SUCCESS:
            return {
                ...state,
                isLoading:false,
                sideBarCount: payload.data,
            };
        case ActionTypes.SIDEBAR_COUNT_FAILURE:
            return {
                ...state,
                isLoading:false,
                error: null,
                // error: payload.message, //unComment if want to show error message
            };

        default:
            return state;
    }
}
