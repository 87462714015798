// imports
import { saveAs } from 'file-saver/FileSaver';
import _ from 'lodash';

import JSONTOCsv from '../services/JSONToCsv';
import singleFileCreationService from '../services/singleFileCreationService';
import store from '../store';
import WSService from '../services/WSService';
import {
    checkKeyInObject,
    checkArrayLength,
    checkObject,
    isJson,
    isLocalHost,
    getStepsData,
    getParamValues,
    cmpMultiPointNumber,
    extension,
} from './utils';
import {
    ModalActions,
    ProjectActions,
    SelectedTestCaseActions,
    TestCaseActions,
    TestStepActions,
    ExecutionActions,
    UserActions,
    ProjectsActions,
} from '../store/actions';
import { TestCaseMiddleware } from '../store/middleware';
import { TestStepUtils } from './TestStepUtils';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON } from '../components/Button/Btn';
import { removeKeysFromObjAndSubObj } from '.';
import { track } from '../services/Segment'
import { SEGMENT_EVENT } from '../common/constants'
import {warningAlertBar} from '../services/AlertBarService'
// Global variables
let reArrangeStepObj = {
    newlyStepIncr: 0,
    deletedStep: [],
};
const isRunInstr = /^run\s*\${/;

class TestCase {
    // Class Level Variables
    caseInterval;

    compoundStepsArr = [];

    filteredTestSteps = [];

    getUpdateCase;

    isRetryButtonDisabled = {};

    totalNumberOfStepsInMsgType3 = 0; // length of steps

    testStepsFromPlatform = []; // Array used to store steps data comming from platform (ws response)

    getDummyTestCase = (projectId, projectName, appUrl) => {
        return {
            testCaseName: 'New Test Case',
            createdTime: new Date(),
            discoveryId: projectId,
            testCaseId: -1,
            projectName,
            disabledStatus: false,
            status: -2,
            testSteps: [
                {
                    instr: 'open website',
                    data: appUrl,
                    instrNum: '1',
                    sendToTestCaseParser: true,
                    status: '5',
                    expectedResults: '',
                    xpath: '',
                },
            ],
            originalTestSteps: [
                {
                    data: appUrl,
                    instr: 'Open website',
                },
            ],
        };
    };

    /* Record Test Case block start */
    stopUpdate() {
        clearInterval(this.caseInterval);
    }

    getUpdate() {
        const project = store.getState().projectReducer.selectedProject;
        this.caseInterval = setInterval(() => {
            this.getUpdateCase = localStorage.getItem('addedNewCase');
            if (this.getUpdateCase) {
                store.dispatch(ProjectActions.getTestCases(project.id));
                localStorage.removeItem('addedNewCase');
                this.stopUpdate();
            }
        }, 500);
    }

    async debugStep(accountId, projectSystemId, discoveryId, RecordData, fIndex, htmlPath, callBack = () => {}, callBackForDebug = () => {}) {
        const reqTimeOut = setTimeout(() => {
            store.dispatch(UserActions.webSocketErr('SessionIds'));
        }, 30000);
        const project = store.getState().projectReducer.selectedProject;
        localStorage.removeItem('addedNewCase'); // Need to Ask Mohsin about it's use
        this.getUpdate();
        const ws = await WSService.getWebSocketInstance();
        const sessionIds = await WSService.generateSessionIds(5);

        clearTimeout(reqTimeOut);

        store.dispatch(
            ProjectActions.recordTestCase({
                accountId,
                projectId: projectSystemId,
                discoveryId,
                sessionIds,
                RecordData,
                fIndex,
                callBack,
                callBackForDebug,
                htmlPath,
            }),
        );

        const actions = {
            getTestCases: async () => {
                await store.dispatch(ProjectActions.getTestCases(project.id));
            },
        };
        const data = {
            callingFrom: 'TestCaseUtils',
        };
        // Invoking web socket receiver
        TestCaseUtils.receiveWSEvents(ws, actions, data);
        return null;
    }

    async recordTestCase({
        accountId,
        projectSystemId,
        discoveryId,
        RecordData = () => {},
        fIndex,
        callBack = () => {},
        idModal,
        callBackForDebug = () => {},
    }) {
        const reqTimeOut = setTimeout(() => {
            RecordData();
            store.dispatch(UserActions.webSocketErr('SessionIds'));
        }, 30000);
        localStorage.removeItem('addedNewCase'); // Need to Ask Mohsin about it's use
        this.getUpdate();
        const ws = await WSService.getWebSocketInstance();
        const sessionIds = await WSService.generateSessionIds(5);

        clearTimeout(reqTimeOut);
        RecordData();

        store.dispatch(
            ProjectActions.recordTestCase({
                accountId,
                projectId: projectSystemId,
                discoveryId,
                sessionIds,
                RecordData,
                fIndex,
                callBack,
                callBackForDebug,
                idModal,
            }),
        );

        const actions = {
            getTestCases: async () => {
                await store.dispatch(ProjectActions.getTestCases(projectSystemId));
            },
        };
        const data = {
            callingFrom: 'TestCaseUtils',
        };
        // Invoking web socket receiver
        TestCaseUtils.receiveWSEvents(ws, actions, data);
        return null;
    }
    /* Record Test Case block end */

    updateStepsRequestPayload = async (projectId, testCaseId, stepsToSend, sessionId, debugPoints = [], isCreationMode = false, platformBrowserDetails = []) => {
        const res = await store.dispatch(
            TestStepActions.updateSteps({ projectId, testCaseId }, { testSteps: stepsToSend, sessionId, debugPoints, isCreationMode, platformDetails: platformBrowserDetails, }),
        );
        return res;
    };

    /**
    |--------------------------------------------------
    | RECEIVE WEB SOCKET MESSAGES START
    |--------------------------------------------------
    */
    // msgType = 3
    addNewInstruction = (testStepObj) => {
        this.totalNumberOfStepsInMsgType3++;
        const _firstEditedStep = store.getState().projectReducer.firstEditedStep;
        const { selectedTestCase } = store.getState().projectReducer;
        const { status, subInstructions } = testStepObj;
        const subInstr = !(!subInstructions || !subInstructions.length);

        let stepObj = {};
        if (
            _firstEditedStep &&
            checkKeyInObject(selectedTestCase, 'testSteps') &&
            this.totalNumberOfStepsInMsgType3 < selectedTestCase.testSteps.length
        ) {
            // after MsgType 14
            selectedTestCase.testSteps.some((step) => {
                if (Number(step.instrNum) === Number(testStepObj.instrNum)) {
                    if (Number(step.instrNum < Number(_firstEditedStep))) {
                        stepObj = {
                            ...testStepObj,
                            hasChild: subInstr,
                            status: `${step.status}`,
                            screenshotNo: step.screenshotNo,
                            screenshotPaths: step.screenshotPaths,
                            screenshotSmallPaths: step.screenshotSmallPaths,
                        };
                    } else {
                        stepObj = {
                            ...testStepObj,
                            hasChild: subInstr,
                            status: `${status}`,
                        };
                    }
                    return true;
                }
                stepObj = { ...testStepObj, status: `${status}`, hasChild: subInstr };
                return false;
            });
        } else {
            stepObj = { ...testStepObj, status: `${status}`, hasChild: subInstr };
        }

        this.testStepsFromPlatform.push(stepObj);
        if (subInstr) {
            subInstructions.forEach((teststep) => {
                this.addNewInstruction(teststep);
            });
        }
    };

    removeStepPlayButton = (paramTestCaseId) => {
        const { instrNumArray, testSteps } = store.getState().selectedTestCaseReducer;
        const { debugStepsData, failedStepsData } = store.getState().projectReducer;
        const isMsgType13Received = checkKeyInObject(failedStepsData, paramTestCaseId) && failedStepsData[paramTestCaseId].sessionId;
        const isMsgType16Received = checkKeyInObject(debugStepsData, paramTestCaseId) && debugStepsData[paramTestCaseId].sessionId;
        if ((isMsgType13Received || isMsgType16Received) && checkArrayLength(instrNumArray) && checkObject(testSteps)) {
            const indexesToRemove = [];
            Object.keys(testSteps).forEach((_instrNum) => {
                if (checkKeyInObject(testSteps[_instrNum], 'showSmartRetryButton') || checkKeyInObject(testSteps[_instrNum], 'showDebugPlayButton')) {
                    delete testSteps[_instrNum].showDebugPlayButton;
                    delete testSteps[_instrNum].showSmartRetryButton;
                }
                if (
                    !checkKeyInObject(testSteps[_instrNum], 'instr', 'value', false) ||
                    checkKeyInObject(testSteps[_instrNum], 'isNew', 'value', false)
                ) {
                    const emptyStepIndexs = checkArrayLength(instrNumArray) ? instrNumArray.indexOf(_instrNum) : -1;
                    if (emptyStepIndexs > -1) {
                        indexesToRemove.push(emptyStepIndexs);
                    }
                }
            });
            if (checkArrayLength(indexesToRemove)) {
                TestStepUtils.onCancelAddStep(indexesToRemove, false);
            }
        }
    };

    // msgType = 4
    addSpinnerToRunningInstruction = (testStepObj, sessionId, paramTestCaseId) => {
      
       

        const { testCaseId, stepInProgress: instrNum, screenshotPath, status } = testStepObj;
        const testStep = {
            ...testStepObj,
            instrNum,
            screenshotSmallPaths: screenshotPath,
            status: `${status}`,
        };
        if(instrNum.indexOf('.')!== -1){
            store.dispatch(SelectedTestCaseActions.toggleExpandedRow(instrNum.split('.')[0],true));
        }
        delete testStep.screenshotPath;
        store.dispatch(ProjectActions.updateTestCaseStepsStatus(testCaseId, testStep, sessionId));
        if (`${testCaseId}` === `${paramTestCaseId}`) {
            this.removeStepPlayButton(paramTestCaseId);
            store.dispatch(SelectedTestCaseActions.updateStepStatus(instrNum, testStep, '4'));

        }
    };

    // msgType = 5
    setStatusOfFinishedInstruction = (testStepObj, sessionId, paramTestCaseId) => {
        
        const { projectId, testCaseId, instrNum, status, duration, } = testStepObj;

        if (projectId && testCaseId && instrNum) {
            const testStep = {
                ...testStepObj,
                status: `${status}`,
                duration: `${duration}`,
                isDownloadingFileCase: false,
                // detectChanged: true, Need to check with Haseeb, why it is used here (TAHIR)
            };
            delete testStep.data; // remove data to avoid variable overwriting, because getting hardcode data value.
            store.dispatch(ProjectActions.updateTestCaseStepsStatus(testCaseId, testStep, sessionId));
            if (`${testCaseId}` === `${paramTestCaseId}`) {
                this.removeStepPlayButton(paramTestCaseId);
                store.dispatch(SelectedTestCaseActions.updateStepStatus(instrNum, testStep, '5'));
            }
        }
    };

    // msgType = 7
    processTestCaseFinished = (/* data */) => {
        // TODO: what to do when the test case is done?
    };

    // msgType = 8
    msgType8Receiver = async (actions, data, dataFromPlatform, testStepObj, rest = {}) => {
        const { projectId, testCaseId, testDataId, executionId } = dataFromPlatform;
        const { paramTestCaseId, paramProjectId, paramExecutionId } = rest;
        const { debugPointList } = this.props.projectsReducer;

        delete debugPointList[testCaseId];
        this.props.updatedebugPointList(debugPointList);

        if (executionId) {
            store.dispatch(ExecutionActions.updateExecutionStatus(executionId));
            if (`${paramExecutionId}` === `${executionId}`) {
                await store.dispatch(
                    ExecutionActions.getExecution(paramExecutionId, (steps) => {
                        store.dispatch(ExecutionActions.setExecutionStepsData(steps));
                    }),
                );
            }
            store.dispatch(ExecutionActions.removeExecutionStepsById(executionId, this.testStepsFromPlatform));
        } else {
            const { wsRunningCaseData } = store.getState().projectReducer;
            const isMessageType15Sent = checkKeyInObject(wsRunningCaseData, testCaseId) ? wsRunningCaseData[testCaseId].isMessageType15Sent : false;
            const isMessageType18Sent = checkKeyInObject(wsRunningCaseData, testCaseId) ? wsRunningCaseData[testCaseId].isMessageType18Sent : false;
            const { instrNumArray } = store.getState().selectedTestCaseReducer;
            const platformTestSteps = (dataFromPlatform && dataFromPlatform.testSteps) || [];
            // if msgType 15/18 not sent
            if (!isMessageType15Sent && !isMessageType18Sent) {
                this.allowRetryTestCase(testCaseId, true); // invoke only if msgType 15 and 18 not sent
            }
            if (data.callingFrom === 'LiveTestStep' && (isMessageType15Sent || isMessageType18Sent)) {
                // IF belongs to liveTestSteps
                // need to run before getSingleTestCase call
                this.allowRetryTestCase(testCaseId, false);
            }

            const isGenerating = await TestCaseMiddleware.getTestCaseGenerationStatus(Number(testCaseId));
            await store.dispatch(ProjectActions.toggleWsRunning(testCaseId, platformTestSteps, isGenerating));

            if (
                data.callingFrom === 'CaseTable' ||
                data.callingFrom === 'UploadWizard' ||
                data.callingFrom === 'LiveTestStep' ||
                data.callingFrom === 'TestCaseUtils'
            ) {
                if (`${paramTestCaseId}` === `${testCaseId}`) {
                    store.dispatch(SelectedTestCaseActions.emptyUndoRedoArrays()); // remove undo redo data on msgType 8
                    store.dispatch(SelectedTestCaseActions.setActionType()); // reset actionType on msgType 8
                }
                if (
                    // These conditions are for RabbitMQ Build UI issue (Sonata) #1632
                    (`${paramProjectId}` === `${projectId}` || `${paramTestCaseId}` === `${testCaseId}`) &&
                    !isMessageType15Sent &&
                    !isMessageType18Sent
                ) {
                    await store.dispatch(ProjectActions.getSingleTestCase(projectId, testCaseId, platformTestSteps, false, 'TestCaseUtilsMsgType8'));
                }

                if (testDataId > 0 && window.location.href.includes(projectId)) {
                    store.dispatch(ProjectActions.getSingleTestData(testDataId)); // Function belongs to uploadWizardNew
                }
                if (isMessageType15Sent) {
                    // IF belongs to liveTestSteps or CaseTable
                    // need to run after getSingleTestCase call
                    this.runTestSteps({ projectId, paramTestCaseId: testCaseId }, '2');
                    if (checkKeyInObject(actions, 'UpdateScriptMessageJsonFromCaseTable')) {
                        actions.UpdateScriptMessageJsonFromCaseTable({ sendFifteen: false });
                    }
                    // if (data.skipRetry) { Need to check
                    //     this.allowRetryTestCase(testCaseId, true); // invoke only if save button pressed after msgType15 sent
                    // }
                    // send empty array as steps to reset
                    // store.dispatch(ProjectActions.toggleMsgType15Flag(testCaseId, [], false));
                }
                if (isMessageType18Sent) {
                    // IF belongs to liveTestSteps or CaseTable
                    this.runTestSteps({ projectId, paramTestCaseId: testCaseId }, '2');
                    if (checkKeyInObject(actions, 'UpdateScriptMessageJsonFromCaseTable')) {
                        actions.UpdateScriptMessageJsonFromCaseTable({ sendNineteen: false });
                    }
                }
            }

            store.dispatch(ProjectActions.toggleReloadStatus(false)); // Function belongs to liveTestSteps & CaseTable

            if (checkArrayLength(instrNumArray)) {
                store.dispatch(SelectedTestCaseActions.setAllCacheXpaths(true)); // set cacheXpaths to check, checkboxes on each teststeps
            }
        }
    };

    // msgType = 10
    processScriptGenerationFinished = (/* data */) => {
        // TODO: use this information to update the status of script generation for the entire test case.
    };

    getConsoleData = ({
        testSteps,
        aiqExecution,
        scriptId,
        skipRetry,
        status,
        statusMessage,
        testDataId,
        totalTime,
        executionId,
        // ...other
    }) => {
        return {
            testSteps,
            aiqExecution,
            scriptId,
            skipRetry,
            status,
            statusMessage,
            testDataId,
            totalTime,
            executionId,
            // other
        };
    };

    receiveWSEvents(ws, actions = {}, data = {}) {
        const { user } = store.getState().authReducer;
        ws.onmessage = async (evt) => {
            const { autoScroll, creationMode } = store.getState().selectedTestCaseReducer;
            const testStepObj = JSON.parse(evt.data);
            if (testStepObj.msgType !== 1) {
                const pathArray = getParamValues();
                let paramTestCaseId = -2; // -2 is because it will not match accidently with testCaseId, if testCaseId is null
                let paramProjectId = null;
                let paramExecutionId = null;

                if (
                    checkArrayLength(pathArray) &&
                    pathArray.length > 4 &&
                    pathArray[0] === 'projects' &&
                    pathArray[3] === 'executions' &&
                    pathArray[4]
                ) {
                    paramExecutionId = pathArray[4];
                }

                const dataFromPlatform = testStepObj && testStepObj.msgJson && isJson(testStepObj.msgJson) && JSON.parse(testStepObj.msgJson);
                const { executionId } = dataFromPlatform;

                if (executionId) {
                    delete dataFromPlatform.testCaseId;
                    store.dispatch(ExecutionActions.setExecutionMsgtypeData(testStepObj));
                } else {
                    paramProjectId = pathArray[1];
                    paramTestCaseId = checkKeyInObject(data, 'paramTestCaseId') ? data.paramTestCaseId : null; // paramTestCaseId from caseTable component
                    // check if testCaseId exist in url params
                    if (
                        checkArrayLength(pathArray) &&
                        pathArray.length > 2 &&
                        pathArray[0] === 'projects' &&
                        pathArray[3] !== 'executions' &&
                        pathArray[2]
                    ) {
                        paramTestCaseId = pathArray[2];
                    }
                    store.dispatch(ProjectActions.setWsStatus(testStepObj));
                }

                const { projectId, testCaseId } = dataFromPlatform;

                if (isLocalHost() || (testStepObj.msgType !== 4 && testStepObj.msgType !== 5)) {
                    const consoleMessage = `DATA RECEIVED from WS with msgType: , ${testStepObj.msgType}, [TESTCASE]: , ${dataFromPlatform.testCaseId},  [SESSIONID] , ${testStepObj.sessionId}, `;
                    const color = testStepObj.msgType === 8 ? 'Green' : 'Orange';
                    // eslint-disable-next-line no-console
                    console.log(
                        `%c${consoleMessage}`,
                        `color:${color};`,
                        testStepObj.msgType === 8 ? this.getConsoleData(dataFromPlatform) : { ...dataFromPlatform },
                    );
                }
                if (testStepObj.msgJson) {
                    switch (testStepObj.msgType) {
                        case 3: {
                            dataFromPlatform.testSteps.forEach((step, ind) => {
                                const addStep = {
                                    ...step,
                                    projectId,
                                    testCaseId,
                                    ind,
                                };
                                this.addNewInstruction(addStep);
                                store.dispatch(SelectedTestCaseActions.setDebugPointLoader({ testCaseId }, false));
                            });
                            if (executionId) {
                                store.dispatch(ExecutionActions.setExecutionStepsById(executionId, this.testStepsFromPlatform));
                            } else {
                                this.allowRetryTestCase(testCaseId, true); // Function belongs to liveTestSteps

                                store.dispatch(SelectedTestCaseActions.setSmartRetryLoader({ testCaseId }, false));
                                if (testCaseId === paramTestCaseId) {
                                    store.dispatch(ProjectActions.toggleReloadStatus(true)); // Function belongs to liveTestSteps & CaseTable
                                    store.dispatch(
                                        SelectedTestCaseActions.setSelectedTestCaseData({
                                            testCaseId,
                                            testSteps: this.testStepsFromPlatform,
                                            testCaseStatus: dataFromPlatform.status,
                                            stepsType: 'live',
                                            platformDebugPointList: dataFromPlatform.debugPoints,
                                        }),
                                    );
                                }
                                this.totalNumberOfStepsInMsgType3 = 0; // reset length;
                                store.dispatch(ProjectActions.callForOriginalStep(true)); // Function belongs to liveTestStep & CaseTable
                                if (checkKeyInObject(actions, 'getTestCases')) {
                                    await actions.getTestCases(); // Function belongs to TestCaseUtils
                                }
                                if (data.callingFrom === 'TestCaseUtils') {
                                    store.dispatch(ProjectActions.getProjectDetails(projectId, true));
                                }
                                store.dispatch(ProjectActions.updateTestCaseSteps(testCaseId, this.testStepsFromPlatform, testStepObj.sessionId));
                                store.dispatch(SelectedTestCaseActions.toggleStopIcon(false)); // Function belongs to liveTestStep
                            }
                            this.testStepsFromPlatform = [];
                            break;
                        }
                        case 4: {
                            if (executionId) {
                                store.dispatch(ExecutionActions.updateExecutionStatus(executionId, 4));
                                const { stepInProgress: instrNum, screenshotPath, status } = dataFromPlatform;
                                if (`${executionId}` === `${paramExecutionId}`) {
                                    store.dispatch(ExecutionActions.updateExecutionStepsInProjectReducer(executionId));
                                    if (autoScroll) {
                                        TestStepUtils.scrollToSpecificStep(
                                            `${projectId}_${executionId}_${dataFromPlatform.stepInProgress}`,
                                            false,
                                            true,
                                        );
                                    }
                                }
                                const testStep = {
                                    ...dataFromPlatform,
                                    instrNum,
                                    screenshotSmallPaths: screenshotPath,
                                    status: `${status}`,
                                };
                                delete testStep.screenshotPath;
                                store.dispatch(ExecutionActions.updateExecutionStepStatus(instrNum, testStep, '4', executionId, paramExecutionId));
                            } else if (testCaseId === paramTestCaseId) {
                                store.dispatch(ProjectActions.toggleReloadStatus(true)); // Function belongs to liveTestSteps & CaseTable
                                if (autoScroll) {
                                    // Function belongs to liveTestSteps
                                    TestStepUtils.scrollToSpecificStep(`${projectId}_${testCaseId}_${dataFromPlatform.stepInProgress}`);
                                }
                                this.addSpinnerToRunningInstruction(dataFromPlatform, testStepObj.sessionId, paramTestCaseId);
                            }
                            break;
                        }
                        case 5: {
                            if (executionId) {
                                store.dispatch(ExecutionActions.updateExecutionStatus(executionId, 4));
                                const { projectId: _projectId, instrNum, status, duration } = dataFromPlatform;
                                if (`${executionId}` === `${paramExecutionId}`) {
                                    store.dispatch(ExecutionActions.updateExecutionStepsInProjectReducer(executionId));
                                    TestStepUtils.updateExecutionStepDownloadKey(instrNum, 'isDownloadingFileExec', false);
                                }
                                if (_projectId && instrNum) {
                                    const testStep = {
                                        ...dataFromPlatform,
                                        status: `${status}`,
                                        duration: `${duration}`,
                                    };
                                    delete testStep.data;
                                    store.dispatch(
                                        ExecutionActions.updateExecutionStepStatus(instrNum, testStep, '5', executionId, paramExecutionId),
                                    );
                                }
                            } else {
                                if (testCaseId === paramTestCaseId) {
                                    store.dispatch(ProjectActions.toggleReloadStatus(true)); // Function belongs to liveTestSteps & CaseTable
                                }
                                this.setStatusOfFinishedInstruction(dataFromPlatform, testStepObj.sessionId, paramTestCaseId);
                            }
                            break;
                        }
                        case 7: {
                            // this.processTestCaseFinished(dataFromPlatform);
                            break;
                        }
                        case 8: {
                            if (creationMode) {
                                store.dispatch(SelectedTestCaseActions.toggleCreationMode());
                            }
                            this.msgType8Receiver(actions, data, dataFromPlatform, testStepObj, {
                                paramTestCaseId,
                                paramProjectId,
                                paramExecutionId,
                            });
                            break;
                        }
                        case 10: {
                            // this.processScriptGenerationFinished(dataFromPlatform);
                            break;
                        }
                        case 11: {
                            if (testCaseId === paramTestCaseId) {
                                store.dispatch(ProjectActions.toggleReloadStatus(false)); // Function belongs to liveTestSteps & CaseTable
                            }
                            break;
                        }
                        case 12: {
                            // eslint-disable-next-line no-console
                            console.log('Going to get all notifications');
                            const { accountId } = user;
                            store.dispatch(ProjectActions.getNonDeletedNotification(accountId));
                            break;
                        }
                        case 13: {
                            if (testCaseId) {
                                store.dispatch(ProjectActions.saveFailedStep(dataFromPlatform, testStepObj.sessionId)); // Function belongs to liveTestSteps or CaseTable
                            }
                            break;
                        }
                        case 16: {
                            if (testCaseId) {
                                store.dispatch(ProjectActions.saveDebugStep(dataFromPlatform, testStepObj.sessionId));
                                if (`${testCaseId}` === `${paramTestCaseId}` && !creationMode) {
                                    TestStepUtils.handleDebug(testCaseId, dataFromPlatform.instrNum);
                                }
                            }
                            break;
                        }
                        case 19: {
                            if (paramExecutionId && `${executionId}` === `${paramExecutionId}`) {
                                TestStepUtils.updateExecutionStepDownloadKey(dataFromPlatform.stepInProgress, 'isDownloadingFileExec', true);
                            } else if (`${testCaseId}` === `${paramTestCaseId}`) {
                                TestStepUtils.updateStepDownloadKey(dataFromPlatform.stepInProgress, 'isDownloadingFileCase');
                            }
                            break;
                        }
                        default:
                            break;
                    }
                }
            }
        };
        return null;
    }
    /**
    |--------------------------------------------------
    | RECEIVE WEB SOCKET MESSAGES END
    |--------------------------------------------------
    */

    /**
    |--------------------------------------------------
    | RUN | SAVE | STOP TESTCASE START
    |--------------------------------------------------
    */

    receiveWSEventsCaller = async (callingFrom) => {
        this.ws = await WSService.getWebSocketInstance();
        const { autoScroll } = store.getState().selectedTestCaseReducer;

        const actions = {};

        const data = { autoScroll, callingFrom };

        // Invoking web socket receiver
        this.receiveWSEvents(this.ws, actions, data);
    };

    allowRetryTestCase = (testCaseId, flag = true) => {
        if (flag) {
            delete this.isRetryButtonDisabled[testCaseId];
        } else {
            this.isRetryButtonDisabled = { ...this.isRetryButtonDisabled, [testCaseId]: false };
        }
    };

    arrangeSteps = (step, ind, subObj) => {
        const { cacheXpaths } = store.getState().selectedTestCaseReducer;
        while (ind < step.subInstructions.length) {
            let obj;
            const subInstruction = step.subInstructions[ind];
            if (subInstruction.detectChanged) {
                // if change is detected then we don't need to send
                // xpath and stepId of following steps.
                this.detectChanged = true; // used for StepId only
            }
            if (!subInstruction.hasChild) {
                if (subObj.data || subObj.instr || subObj.columnName || subObj.xpath || subObj.stepId || subObj.expectedResults) {
                    obj = {
                        data: `${subObj.data}$$aiq$$${subInstruction.data}`,
                        instr: `${subObj.instr}$$aiq$$${subInstruction.instr}`,
                        columnName: subInstruction.detectChanged ? '' : `${subObj.columnName}$$aiq$$${subInstruction.columnName}`,
                        xpath: cacheXpaths[subInstruction.instrNum]
                            ? `${subObj.xpath}$$aiq$$${subInstruction.xpath || ''}`
                            : `${subObj.xpath}$$aiq$$`,
                        stepId: !this.detectChanged ? `${subObj.stepId}$$aiq$$${subInstruction.stepId}` : '',
                        expectedResults: `${checkKeyInObject(subObj, 'expectedResults', 'value', '')}$$aiq$$${checkKeyInObject(
                            subInstruction,
                            'expectedResults',
                            'value',
                            '',
                        )}`,
                    };
                } else {
                    obj = {
                        data: `${subInstruction.data}`,
                        instr: `${subInstruction.instr}`,
                        columnName: subInstruction.detectChanged ? '' : `${subInstruction.columnName}`,
                        xpath: cacheXpaths[subInstruction.instrNum] ? `${subInstruction.xpath || ''}` : '',
                        stepId: !this.detectChanged ? `${subInstruction.stepId}` : '',
                        expectedResults: `${checkKeyInObject(subInstruction, 'expectedResults', 'value', '')}`,
                    };
                }
                subObj = obj;
            } else {
                this.arrangeSteps(subInstruction, 0, subObj);
            }
            ind += 1;
        }

        // if (checkKeyInObject(subObj, 'xpath') && subObj.xpath.includes('$$aiq$$') && !subObj.xpath.includes('@aiq@')) {
        //     subObj.xpath = '';
        // }
        const alreadyThere = this.compoundStepsArr.find((data) => data.instr.includes(subObj.instr));
        if (!alreadyThere) this.compoundStepsArr.push({ ...subObj });
    };

    reStructureTestSteps = (step, index) => {
        // const {} = store.getState().selectedTestCaseReducer;
        const valArr = checkKeyInObject(step, 'instrNum') ? `${step.instrNum}`.split('.') : [index];
        const val = valArr.length;

        // Deleted step will contain intrNum as current + 2
        // In which 1 is for addition index because its starting from 1 rather than 0
        // Another 1 is for one level next instruction after delete

        if (reArrangeStepObj.deletedStep.includes(Number(valArr[0]) - 2) && valArr.length === 1) {
            // Need to re-arrange steps again after delete
            reArrangeStepObj.newlyStepIncr--;
            reArrangeStepObj.deletedStep.shift();
        }

        // Main instruction index - len + newly added Step Index increament
        const mainInstrIndex = valArr[0] - 1 + reArrangeStepObj.newlyStepIncr;

        try {
            switch (val) {
                case 1:
                    this.filteredTestSteps.push({ ...step, subInstructions: [] });
                    break;
                case 2:
                    this.filteredTestSteps[this.filteredTestSteps.length - 1].subInstructions.push({ ...step, subInstructions: [] });
                    break;
                case 3:
                    this.filteredTestSteps[mainInstrIndex].subInstructions[valArr[1] - 1].subInstructions.push({ ...step, subInstructions: [] });
                    break;
                case 4:
                    this.filteredTestSteps[mainInstrIndex].subInstructions[valArr[1] - 1].subInstructions[valArr[2] - 1].subInstructions.push({
                        ...step,
                        subInstructions: [],
                    });
                    break;
                case 5:
                    this.filteredTestSteps[mainInstrIndex].subInstructions[valArr[1] - 1].subInstructions[valArr[2] - 1].subInstructions[
                        valArr[3] - 1
                    ].subInstructions.push({ ...step, subInstructions: [] });
                    break;
                default:
                    break;
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log({ reStructureTestSteps: error });
        }
    };

    createStepsToSend = (sortedSteps /* , msgType = '' */) => {
        const { cacheXpaths, /* editedStepOfMsgType13, */ stepsType, undoData } = store.getState().selectedTestCaseReducer;
        const Steps = checkArrayLength(sortedSteps) ? JSON.parse(JSON.stringify(sortedSteps)) : [];

        this.filteredTestSteps = [];
        const stepsToSend = [];
        Steps.forEach((step, index) => {
            const s = JSON.parse(JSON.stringify(step));
            // // commented due to change in msgType 14 structure Don't remove code (Tahir)
            // if (isMsg14 && checkObject(editedStepOfMsgType13) && checkObject(s) &&
            //     `${s.instrNum}` === `${editedStepOfMsgType13.instrNum}`
            // ) {
            //     s = editedStepOfMsgType13 // replace edited step with original in testSteps array before restructuring
            // }
            this.reStructureTestSteps(s, index);
        });

        const tempSteps = checkArrayLength(this.filteredTestSteps) ? JSON.parse(JSON.stringify(this.filteredTestSteps)) : [];
        tempSteps.forEach((step) => {
            let subInstrStep = { data: '', instr: '', columnName: '', xpath: '', stepId: '', expectedResults: '' };
            if (step.hasChild) {
                this.arrangeSteps(step, 0, subInstrStep);
            }
            // checking if step has changed or not[edit, delete]
            // this.detectChanged responsible to send stepId
            // On add and edit we have "detectChanged" property in object
            // On delete we don't have any 'detectChanged' property in object because object already deleted
            const isAnyStepDeleted =
                checkArrayLength(undoData) &&
                undoData.some((item) => {
                    // Because we don't have any flag/tag in deleted step(step not in array)
                    if (`${item.action}` === 'delete') {
                        /**
                            [{
                                action: 'delete', stepsDataToSave: [{rowIndex: number, testSteps: object}]
                            }]
                        */
                        // in multiDelete we are storing steps in accending order
                        // So all the time we have ordered array in stepsDataToSave
                        return (
                            checkKeyInObject(step, 'instrNum') &&
                            checkKeyInObject(item.stepsDataToSave[0], 'testStep') &&
                            item.stepsDataToSave[0].testStep.instrNum &&
                            // eslint-disable-next-line eqeqeq
                            item.stepsDataToSave[0].testStep.instrNum == step.instrNum - 1
                        );
                    }
                    return false;
                });
            if (step.detectChanged || isAnyStepDeleted) {
                this.detectChanged = true; // used for stepId only
            }
            if (checkKeyInObject(step, 'instr')) {
                if (!step.hasChild || step.isBlockStep || stepsType === 'recover') {
                    // if (step.sendToTestCaseParser || stepsType === 'recover') { removed this condition due to #1511 and NLP/issues/721  issues

                    stepsToSend.push({
                        data: step.data,
                        instr: step.instr,
                        columnName: step.detectChanged ? '' : step.columnName,
                        expectedResults: checkKeyInObject(step, 'expectedResults', 'value', ''),
                        xpath: (cacheXpaths[step.instrNum] && stepsType === 'live') || stepsType === 'recover' ? step.xpath || '' : '',
                        stepId: !this.detectChanged ? `${step.stepId || ''}` : '',
                    });
                    this.compoundStepsArr = [];
                } else {
                    for (let i = 0; i < this.compoundStepsArr.length; i++) {
                        subInstrStep = {
                            data: `${subInstrStep.data}${!subInstrStep.data ? '' : '$$aiq$$'}${this.compoundStepsArr[i].data}`,
                            instr: `${subInstrStep.instr}${!subInstrStep.instr ? '' : '$$aiq$$'}${this.compoundStepsArr[i].instr}`,
                            columnName: `${subInstrStep.columnName}${!subInstrStep.columnName ? '' : '$$aiq$$'}${
                                this.compoundStepsArr[i].columnName || ''
                            }`,
                            xpath: `${subInstrStep.xpath}${!subInstrStep.xpath ? '' : '$$aiq$$'}${this.compoundStepsArr[i].xpath || ''}`,
                            expectedResults: `${checkKeyInObject(subInstrStep, 'expectedResults', 'value', '')}${
                                !checkKeyInObject(subInstrStep, 'expectedResults', 'bool', false) ? '' : '$$aiq$$'
                            }${checkKeyInObject(this.compoundStepsArr[i], 'expectedResults', 'value', '')}`,
                            stepId: `${subInstrStep.stepId}${!subInstrStep.stepId ? '' : '$$aiq$$'}${this.compoundStepsArr[i].stepId || ''}`,
                        };
                    }
                    this.compoundStepsArr = [];
                    stepsToSend.push(subInstrStep);
                }
            }
        });

        reArrangeStepObj = { newlyStepIncr: 0, deletedStep: [] };
        return stepsToSend;
    };

    updateFlows = async (brokenDownSteps, step, sortedSteps, index, projectId) => {
        let shouldUpdate = true;
        const ind = brokenDownSteps.findIndex((stp) => stp.instrNum === step.instrNum);
        if (ind > -1) {
            const excludeKeys = ['actualResult', 'screenshotPaths', 'screenshotSmallPaths', 'status', 'screenshotNo', 'hasChild'];
            const newSteps = removeKeysFromObjAndSubObj(brokenDownSteps[ind], excludeKeys);
            const oldSteps = removeKeysFromObjAndSubObj(step, excludeKeys);
            shouldUpdate = !_.isEqual(newSteps, oldSteps);
        }
        if (shouldUpdate) {
            await TestStepUtils.updateFlowAtPlatform({ sortedSteps, step, index, projectId: Number(projectId) });
        }
    };

    runTestSteps = async (paramData = {}, msgType = '', onComplete = () => {}, onFail = () => {}, showMessage=false) => {
        const { projectId, paramTestCaseId } = paramData;
        const { failedStepsData, debugStepsData, wsRunningCaseData } = store.getState().projectReducer;
        const { instrNumArray, testSteps, creationMode } = store.getState().selectedTestCaseReducer;
        const wsRunningForCurrentCase = checkKeyInObject(wsRunningCaseData, paramTestCaseId, 'bool') ? wsRunningCaseData[paramTestCaseId] : {};
        store.dispatch(ProjectActions.toggleReloadStatus(true));
        store.dispatch(SelectedTestCaseActions.toggleStopIcon(false));
        store.dispatch(SelectedTestCaseActions.emptyUndoRedoArrays()); // to empty undo/redo array

        const sortedSteps = checkArrayLength(instrNumArray) && instrNumArray.map((instrNum) => testSteps[instrNum]);
        const stepsToSend = this.createStepsToSend(sortedSteps, msgType);
        const brokenDownSteps = this.getBreakDownSteps();
        const debugPoints = [];
        let firstEditedStepInstrNum;
        sortedSteps.map(async (step, index) => {
            if (step.isFirstEditedStep && !firstEditedStepInstrNum) {
                firstEditedStepInstrNum = step.instrNum;
            }
            if (checkKeyInObject(step, 'debug', 'value', false)) {
                debugPoints.push(`${step.instrNum}`);
            }
            if (checkKeyInObject(step, 'isChanged', 'value', false)) {
                await this.updateFlows(brokenDownSteps, step, sortedSteps, index, projectId);
            }
        });
        try {
            if (msgType === '14' && wsRunningForCurrentCase.isMsgType13Received) {
                onComplete();
                const singleStep = sortedSteps.find((step) => step.showSmartRetryButton);
                const failedInstrNum = checkKeyInObject(singleStep, 'instrNum', 'value', '1');
                firstEditedStepInstrNum = cmpMultiPointNumber(firstEditedStepInstrNum, '<', failedInstrNum)
                    ? firstEditedStepInstrNum
                    : failedInstrNum;
                const { wsRunningTestSteps } = failedStepsData[paramTestCaseId];
                const wsRunningTestStepsIndex = wsRunningTestSteps.map((wsStep) => wsStep.instrNum).indexOf(singleStep.instrNum);
                const msgJson = {
                    testCaseId: Number(paramTestCaseId),
                    testSteps: stepsToSend,
                    firstEdited: firstEditedStepInstrNum,
                };

                console.info(`%cmyMessage object sending in msgType: ${msgType}`, 'color: DodgerBlue', msgJson);
                // functions from msgType 2 and 3 start
                if (wsRunningTestStepsIndex > -1 && checkObject(singleStep)) {
                    wsRunningTestSteps[wsRunningTestStepsIndex] = {
                        ...wsRunningTestSteps[wsRunningTestStepsIndex],
                        instr: singleStep.instr,
                        data: singleStep.data,
                        expectedResults: checkKeyInObject(singleStep, 'expectedResults', 'value', ''),
                    };
                }
                store.dispatch(ProjectActions.updateTestCaseSteps(paramTestCaseId, wsRunningTestSteps)); // msgType = 3 actiion
                this.allowRetryTestCase(paramTestCaseId, true);
                store.dispatch(ProjectActions.toggleReloadStatus(true));
                store.dispatch(ProjectActions.resetFailedStep(paramTestCaseId, failedStepsData[paramTestCaseId].sessionId, firstEditedStepInstrNum));
                // functions from msgType 2 and 3 end
                WSService.sendMessage(
                    JSON.stringify({
                        msgJson: JSON.stringify(msgJson),
                        msgType: 14,
                        sessionId: failedStepsData[paramTestCaseId].sessionId,
                        accountId: WSService.getAccountId(),
                    }),
                );
            } else if (msgType === '17' && wsRunningForCurrentCase.isMsgType16Received) {
                onComplete();
                const singleStep = sortedSteps.find((step) => step.showDebugPlayButton);
                const failedInstrNum = checkKeyInObject(singleStep, 'instrNum', 'value', '1');
                firstEditedStepInstrNum = cmpMultiPointNumber(firstEditedStepInstrNum, '<', failedInstrNum)
                    ? firstEditedStepInstrNum
                    : failedInstrNum;
                const { wsRunningTestSteps } = debugStepsData[paramTestCaseId];
                const wsRunningTestStepsIndex = wsRunningTestSteps.map((wsStep) => wsStep.instrNum).indexOf(singleStep.instrNum);
                const msgJson = {
                    testCaseId: Number(paramTestCaseId),
                    testSteps: stepsToSend,
                    firstEdited: firstEditedStepInstrNum,
                    debugPoints: creationMode === true ? [] : debugPoints,
                };
                console.info(`%cmyMessage object sending in msgType: ${msgType}`, 'color: DodgerBlue', msgJson);
                // functions from msgType 2 and 3 start
                if (wsRunningTestStepsIndex > -1 && checkObject(singleStep)) {
                    wsRunningTestSteps[wsRunningTestStepsIndex] = {
                        ...wsRunningTestSteps[wsRunningTestStepsIndex],
                        instr: singleStep.instr,
                        data: singleStep.data,
                        expectedResults: singleStep.expectedResults,
                    };
                }
                store.dispatch(ProjectActions.updateTestCaseSteps(paramTestCaseId, wsRunningTestSteps)); // msgType = 3 actiion
                this.allowRetryTestCase(paramTestCaseId, true);
                store.dispatch(ProjectActions.toggleReloadStatus(true));
                store.dispatch(ProjectActions.resetDebugStep(paramTestCaseId, debugStepsData[paramTestCaseId].sessionId, firstEditedStepInstrNum));
                // functions from msgType 2 and 3 end
                WSService.sendMessage(
                    JSON.stringify({
                        msgJson: JSON.stringify(msgJson),
                        msgType: 17,
                        sessionId: debugStepsData[paramTestCaseId].sessionId,
                        accountId: WSService.getAccountId(),
                    }),
                );
            } else if (wsRunningForCurrentCase.isMsgType13Received && !wsRunningForCurrentCase.isMessageType15Sent) {
                await this.sendMessageTypeFifteen(paramTestCaseId, sortedSteps);
            } else if (wsRunningForCurrentCase.isMsgType16Received && !wsRunningForCurrentCase.isMessageType18Sent) {
                await this.sendMessageTypeEighteen(paramTestCaseId, sortedSteps);
            } else {
                // isWaitingForMsgTypeTwoResponse = true;
                await this.sendMessageTypeTwo(paramData, stepsToSend, debugPoints, onFail,showMessage);
                // isWaitingForMsgTypeTwoResponse = false;
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('runTestSteps', error);
        }
        // detectChanged has to reset on its initial state.
        // if we retry again without going off the screen we can use StepId
        this.detectChanged = false;
        this.receiveWSEventsCaller('LiveTestStep');
        store.dispatch(ProjectActions.callRetry(false));
        localStorage.removeItem(`${`DebugCase_${paramTestCaseId}`}`);
        if (`${msgType}` !== '14' && `${msgType}` !== '17') {
            onComplete();
        }
    };

    // msgType = 2
    sendMessageTypeTwo = async (paramData, stepsToSend, debugPoints, onFail,showMessage) => {
        const { projectId, paramTestCaseId } = paramData;
        const { creationMode } = store.getState().selectedTestCaseReducer;
        const _projectId = Number(projectId);
        const _testcaseId = Number(paramTestCaseId);
        const newSessionId = await WSService.getNewSessionId(WSService.getAccountId());

        /* Disable retry button for execution generation test api promise start */
        this.allowRetryTestCase(paramTestCaseId, false);
        /* Disable retry button for execution generation test api promise end */

        const isTestCaseAlreadyRunning = await TestCaseMiddleware.getTestCaseGenerationStatus(paramTestCaseId);
        if (!isTestCaseAlreadyRunning) {
            store.dispatch(ProjectActions.saveSessionId(newSessionId, `${paramTestCaseId}`, 'LiveTestSteps'));
            let consoleMessage = `DATA SENT through retry API request with [PROJECT]: , ${_projectId}, [TESTCASE]: , ${_testcaseId},  [SESSIONID] , ${newSessionId}, `;
            let color = 'DodgerBlue';
            // eslint-disable-next-line no-console
            console.log(`%c${consoleMessage}`, `color:${color};`, [...stepsToSend]);
            // to prevent calling updateSteps api before clicking on generate button
            if(!showMessage) {
                const res = await this.updateStepsRequestPayload(
                    _projectId,
                    _testcaseId,
                    stepsToSend,
                    newSessionId,
                    creationMode === true ? [] : debugPoints,
                    creationMode,
                );
                if (checkKeyInObject(res, 'type') && res.type === 'UPDATE_STEPS_SUCCESS') {
                    consoleMessage = `RECEIVED retry API success respons with [PROJECT]: , ${_projectId}, [TESTCASE]: , ${_testcaseId},  [SESSIONID] , ${newSessionId}, `;
                    color = 'Green';
                    // eslint-disable-next-line no-console
                    console.log(`%c${consoleMessage}`, `color:${color};`);
                } else {
                    this.allowRetryTestCase(paramTestCaseId, true);
                    store.dispatch(ProjectActions.clearWsData(paramTestCaseId, 'runApiFailed'));
                    store.dispatch(ProjectActions.onSaveOrRetryApiFailed(paramTestCaseId));
                    consoleMessage = `RECEIVED retry API failure respons with [PROJECT]: , ${_projectId}, [TESTCASE]: , ${_testcaseId},  [SESSIONID] , ${newSessionId}, `;
                    color = 'red';
                    // eslint-disable-next-line no-console
                    console.log(`%c${consoleMessage}`, `color:${color};`);
                    store.dispatch(ProjectActions.removeMsgType2Data([`${paramTestCaseId}`], 'LiveTestSteps'));
                    this.allowRetryTestCase(paramTestCaseId, true);
                    if (typeof onFail === 'function') onFail();
                }
            }

        } else {

            warningAlertBar({
                message:{
                    title:'Script is being generated',
                    description:'Once the script generation is complete, you can run your test.'
                },
                duration:20000,

            })
            
           // store.dispatch(ModalActions.toggleSnackBar('Generation/Edit in progress. Some features will not be accessible.', '', false, null, true));
        }
    };

    // msgType = 15
    sendMessageTypeFifteen = (paramTestCaseId, sortedSteps = []) => {
        const { failedStepsData } = store.getState().projectReducer;
        console.info('Sending msgType: 15', failedStepsData[paramTestCaseId].sessionId);
        WSService.sendMessage(
            JSON.stringify({
                msgType: 15,
                sessionId: failedStepsData[paramTestCaseId].sessionId,
                accountId: WSService.getAccountId(),
            }),
        );
        store.dispatch(ProjectActions.toggleMsgType15Flag(paramTestCaseId, sortedSteps, true));
    };

    // msgType = 18
    sendMessageTypeEighteen = (paramTestCaseId, sortedSteps = []) => {
        const { debugStepsData } = store.getState().projectReducer;
        console.info('Sending msgType: 18', debugStepsData[paramTestCaseId].sessionId);
        WSService.sendMessage(
            JSON.stringify({
                msgType: 18,
                sessionId: debugStepsData[paramTestCaseId].sessionId,
                accountId: WSService.getAccountId(),
            }),
        );
        store.dispatch(ProjectActions.toggleMsgType18Flag(paramTestCaseId, sortedSteps, true));
    };

    /* Breakdown steps start */
    arrangeSubInstructionsForBreakDownSteps = (subInstructions, prefixIndex) => {
        const { cacheXpaths } = store.getState().selectedTestCaseReducer;
        let StepInstructionNew = '';
        const subInstArray = subInstructions.map((subStep, ind) => {
            StepInstructionNew = `${StepInstructionNew}.${subStep.instr}`;
            delete subStep.id;
            delete subStep.isNew;
            if (subStep.isBlockStep && checkArrayLength(subStep.subInstructions) && subStep.instr && !isRunInstr.test(subStep.instr.toLowerCase())) {
                subStep.isBlockStep = false;
                subStep.subInstructions = [];
            }
            if (checkArrayLength(subStep.subInstructions)) {
                subStep.subInstructions = this.arrangeSubInstructionsForBreakDownSteps(subStep.subInstructions, `${prefixIndex}.${ind + 1}`);
            }

            delete subStep.id;
            delete subStep.isNew;
            if (!cacheXpaths[subStep.instrNum]) {
                subStep.xpath = '';
            }
            if (subStep.detectChanged) {
                delete subStep.detectChanged;
                subStep.columnName = '';
                subStep.status = 5; // not yet started
                subStep.duration = 0;
                subStep.actualResult = '';
                //  subStep.expectedResults = ''; // Not sure why we are reseting this value commenting to solve github issue: 1812
            }

            const obj = { ...subStep, instrNum: `${prefixIndex}.${ind + 1}` };
            if (checkKeyInObject(subStep, 'sendToTestCaseParser')) {
                obj.sendToTestCaseParser = subStep.sendToTestCaseParser;
            }
            return obj;
        });
        StepInstructionNew = StepInstructionNew.split('.');
        StepInstructionNew.splice(0, 1);
        StepInstructionNew = StepInstructionNew.join(' \\');
        return { StepInstructionNew, subInstArray };
    };

    getBreakDownSteps = () => {
        const { cacheXpaths, stepsType } = store.getState().selectedTestCaseReducer;
        const arrangedSteps = checkArrayLength(this.filteredTestSteps) ? JSON.parse(JSON.stringify(this.filteredTestSteps)) : [];
        const brokenDownSteps = [];
        if (arrangedSteps.length) {
            arrangedSteps.forEach((step, index) => {
                if (step.isBlockStep && checkArrayLength(step.subInstructions) && step.instr && !isRunInstr.test(step.instr.toLowerCase())) {
                    step.isBlockStep = false;
                    step.subInstructions = [];
                }
                if (checkArrayLength(step.subInstructions)) {
                    const { StepInstructionNew, subInstArray } = this.arrangeSubInstructionsForBreakDownSteps(step.subInstructions, `${index + 1}`);
                    step.subInstructions = subInstArray;
                    // if not a Flow parent
                    if (!(step.isBlockStep && checkArrayLength(step.subInstructions) && step.instr && isRunInstr.test(step.instr.toLowerCase()))) {
                        step.instr = StepInstructionNew;
                    }
                }
                delete step.id;
                delete step.isNew;
                if (!cacheXpaths[step.instrNum] && stepsType === 'live') {
                    step.xpath = '';
                }
                if (step.detectChanged) {
                    delete step.detectChanged;
                    step.columnName = '';
                    step.status = '5'; // not yet started
                    step.duration = 0;
                    step.actualResult = '';
                }
                const obj = { ...step, instrNum: `${index + 1}` };
                if (checkKeyInObject(step, 'sendToTestCaseParser')) {
                    obj.sendToTestCaseParser = step.sendToTestCaseParser;
                }
                brokenDownSteps.push(obj);
            });
        }
        return brokenDownSteps;
    };
    /* Breakdown steps end */

    saveTestSteps = (testCaseId, projectId, showMessage=false) => {
        const { instrNumArray, testSteps, testCaseStatus, creationMode } = store.getState().selectedTestCaseReducer;
        const sortedSteps = checkArrayLength(instrNumArray) && instrNumArray.map((instrNum) => testSteps[instrNum]);
        const recoverSteps = this.createStepsToSend(sortedSteps);
        const brokenDownSteps = this.getBreakDownSteps();

        store.dispatch(SelectedTestCaseActions.toggleSaveIcon(true));
        store.dispatch(SelectedTestCaseActions.toggleStopIcon(false));
        store.dispatch(SelectedTestCaseActions.emptyUndoRedoArrays()); // to empty undo/redo array
        store.dispatch(ProjectActions.toggleReloadStatus(true)); // show msg on refresh until msgType 8 received

        function onSuccess() {
            // functions to run on success
            const { debugPointList } = store.getState().projectsReducer;
            // showMessage parameter to persist debug point list after clicking on generate button
            if (checkKeyInObject(debugPointList, testCaseId) && !showMessage) {
                // commenting to persist debug point on generation
                delete debugPointList[testCaseId];
            }
            store.dispatch(ProjectsActions.updatedebugPointList(debugPointList));
            TestCaseUtils.allowRetryTestCase(testCaseId, true);
            store.dispatch(ProjectActions.toggleReloadStatus(false)); // allow to refresh page
            store.dispatch(SelectedTestCaseActions.toggleSaveIcon(false));
        }
        function onFail() {
            // functions to run on fail
            TestCaseUtils.allowRetryTestCase(testCaseId, true);
            store.dispatch(ProjectActions.toggleReloadStatus(false)); // allow to refresh page
            store.dispatch(SelectedTestCaseActions.toggleSaveIcon(false));
        }

        if (checkArrayLength(sortedSteps)) {
            sortedSteps.map(async (step, index) => {
                if (checkKeyInObject(step, 'isChanged', 'value', false)) {
                    await this.updateFlows(brokenDownSteps, step, sortedSteps, index, projectId);
                }
            });
        }

        store.dispatch(
            TestStepActions.updateLiveAndRecoverSteps(
                { recoverSteps, brokenDownSteps, isCreationMode: creationMode },
                { projectId, testCaseId, testCaseStatus },
                onSuccess,
                onFail,
                showMessage
            ),
        );

        this.detectChanged = false;
    };

    sendStopMsg = async (projectId, testCaseId) => {
        this.ws = await WSService.getWebSocketInstance();
        try {
            const { wsRunningCaseData } = store.getState().projectReducer;
            const { instrNumArray, testSteps } = store.getState().selectedTestCaseReducer;
            if (checkKeyInObject(wsRunningCaseData[testCaseId], 'sessionId', 'bool')) {
                const { sessionId } = wsRunningCaseData[testCaseId];
                if (wsRunningCaseData[testCaseId].isMsgType13Received) {
                    console.info('Sending stop request in msgType: 15 for sessionId: ', sessionId);
                    WSService.sendMessage(JSON.stringify({ msgType: 15, sessionId, accountId: WSService.getAccountId() }));
                } else if (wsRunningCaseData[testCaseId].isMsgType16Received) {
                    console.info('Sending stop request in msgType: 18 for sessionId: ', sessionId);
                    WSService.sendMessage(JSON.stringify({ msgType: 18, sessionId, accountId: WSService.getAccountId() }));
                } else {
                    const myMessage = {};
                    let instrNum = '-1';
                    if (checkArrayLength(instrNumArray) && checkObject(testSteps)) {
                        instrNumArray.some((instrNumber) => {
                            if (`${testSteps[instrNumber].status}` === '4') {
                                instrNum = `${instrNumber}`;
                                return true;
                            }
                            return false;
                        });
                    }
                    const dataFromPlatform = {
                        instrNum,
                        projectId,
                        screenshotNo: '',
                        screenshotPaths: [],
                        status: '6',
                        testCaseId,
                    };
                    // stop UI updation forcefully if stop button pressed
                    this.setStatusOfFinishedInstruction(dataFromPlatform, sessionId, testCaseId);
                    WSService.sendMessage(JSON.stringify({ msgJson: JSON.stringify(myMessage), msgType: 11, sessionId }));
                    console.info('Sending stop request in msgType: 11 for sessionId: ', sessionId);
                }
            } else {
                // eslint-disable-next-line no-console
                console.log('Could not sending stop request. No SessionID available');
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Could not sending stop request through ws', e);
        }
    };

    /* Save New Test Case start */
    saveNewTestCase = (caseData, saveButtonClicked = false, onComplete = () => {},showMessage=false) => {
        const { user } = store.getState().authReducer;
        const { instrNumArray, testSteps } = store.getState().selectedTestCaseReducer;
        const { desc, paramTestCaseId, paramProjectId, testCaseName } = caseData;

        TestCaseUtils.allowRetryTestCase(paramTestCaseId, false);

        if (saveButtonClicked) {
            store.dispatch(SelectedTestCaseActions.toggleSaveIcon(true));
        }

        function onFail() {
            store.dispatch(ModalActions.toggleSnackBar('Failed to save test case.'));
        }
        function onSuccess(savedTestCaseId, savedProjectId) {
            setTimeout(() => {
                TestCaseUtils.allowRetryTestCase(paramTestCaseId, true); // use paramTestCaseId to get old testCaseId (before save/run testCaseId)
                TestCaseUtils.allowRetryTestCase(savedTestCaseId, false); // use paramTestCaseId to get old testCaseId (before save/run testCaseId)
                if (saveButtonClicked) {
                    TestCaseUtils.saveTestSteps(savedTestCaseId, savedProjectId);
                } else {
                    // showMessage parameter to prevent calling updateSteps api twice
                    TestCaseUtils.runTestSteps({ paramTestCaseId: savedTestCaseId, projectId: savedProjectId }, '', () => {}, () => {}, showMessage);
                    onComplete();
                }
            }, '500');
            store.dispatch(ModalActions.toggleSnackBar('Test case save successfully', '', true));
        }

        const _testSteps = checkArrayLength(instrNumArray)
            ? instrNumArray.map((instrNum) => ({
                  columnName: checkKeyInObject(testSteps[instrNum], 'columnName') || '',
                  data: checkKeyInObject(testSteps[instrNum], 'data') || '',
                  instr: checkKeyInObject(testSteps[instrNum], 'instr') || '',
                  recorderData: checkKeyInObject(testSteps[instrNum], 'recorderData') || '',
                  stepId: checkKeyInObject(testSteps[instrNum], 'stepId') || '',
                  xpath: checkKeyInObject(testSteps[instrNum], 'xpath') || '',
              }))
            : [];
        const data = {
            case_name: testCaseName || '',
            desc: desc || '',
            test_steps: _testSteps,
        };
        store.dispatch(ProjectActions.saveNewTestCase(user.accountId, paramProjectId, data, onSuccess, onFail));
    };
    /* Save New Test Case end */

    /* Recover Original Test Steps Start */
    // Function used to map original testSteps onto recoverTestSteps
    sendOriginalSteps = async (projectId, platformDetails ,onComplete = () => {}) => {
        const { selectedTestCase } = store.getState().projectReducer;
        const originalTestSteps =
            checkKeyInObject(selectedTestCase, 'originalTestSteps') && checkArrayLength(selectedTestCase.originalTestSteps)
                ? selectedTestCase.originalTestSteps[0].instr && selectedTestCase.originalTestSteps[0].instr.includes('import')
                    ? selectedTestCase.originalTestSteps.slice(1)
                    : selectedTestCase.originalTestSteps
                : [];

        this.ws = await WSService.getWebSocketInstance();
        const newSessionId = await WSService.generateSessionIds(1);

        console.info(
            'DATA SENT through retry API request with [PROJECT]: ',
            projectId,
            ' [TESTCASE]: ',
            selectedTestCase.testCaseId,
            ' [SESSIONID] ',
            newSessionId[0],
            [...originalTestSteps],
        );
        const res = await this.updateStepsRequestPayload(Number(projectId), Number(selectedTestCase.testCaseId), originalTestSteps, newSessionId[0], [], false, platformDetails);
        if (checkKeyInObject(res, 'type') && res.type === 'UPDATE_STEPS_SUCCESS') {
            store.dispatch(ProjectActions.saveSessionId(newSessionId[0], `${selectedTestCase.testCaseId}`, 'sendOriginalSteps'));
            onComplete();
            console.info(
                'RECEIVED retry API success respons with [PROJECT]: ',
                projectId,
                ' [TESTCASE]: ',
                selectedTestCase.testCaseId,
                ' [SESSIONID] ',
                newSessionId[0],
            );
        } else {
            console.info(
                'RECEIVED retry API failure respons with [PROJECT]: ',
                projectId,
                ' [TESTCASE]: ',
                selectedTestCase.testCaseId,
                ' [SESSIONID] ',
                newSessionId[0],
            );
        }

        this.receiveWSEventsCaller('LiveTestStep');
    };
    /* Recover Original Test Steps Start */

    /* If code breaks on smart retry re-check from here start */
    // // Check if any step in reducer is available for smart retry
    // isAnySmartRetryStep = (testCaseId) => {
    //     const failedStep = store.getState().projectReducer.failedStepsData[testCaseId];
    //     const { instrNumArray } = store.getState().selectedTestCaseReducer;
    //     let isAnySmartRetryStep = checkArrayLength(instrNumArray) && instrNumArray.some(instrNum => {
    //         return checkObject(failedStep) && Number(instrNum) === Number(failedStep.instrNum);
    //     });
    //     return isAnySmartRetryStep;
    // }

    // // Return true if original smart retry step deleted
    // getNewSmartRetryIndex = (testCaseId) => {
    //     const failedStep = store.getState().projectReducer.failedStepsData[testCaseId];
    //     const { undoData } = store.getState().selectedTestCaseReducer;
    //     let newSmartRetryStepIndex = null;

    //     checkObject(failedStep) && checkArrayLength(undoData) &&
    //         undoData.some((data) => {
    //             if (data.action === 'delete' && checkArrayLength(data.stepsDataToSave) &&
    //                 checkKeyInObject(data.stepsDataToSave[0], 'rowIndex')) {
    //                 isNewSmartRetryStepIndex = data.stepsDataToSave[0].rowIndex - 1;
    //             }
    //         });
    //     return newSmartRetryStepIndex;
    // }
    /* If code breaks on smart retry re-check from here end */
    /**
    |--------------------------------------------------
    | RUN | SAVE | STOP TESTCASE END
    |--------------------------------------------------
    */

    /**
    |--------------------------------------------------
    | GENERAL FUNCTIONS START
    |--------------------------------------------------
    */
    filterTestSteps = (obj, testSteps, instrNumArray) => {
        if (checkKeyInObject(obj, 'actionType') === 'add') {
            store.dispatch(SelectedTestCaseActions.addStepInStepsArray({ testSteps, instrNumArray }));
        } else if (checkKeyInObject(obj, 'actionType') === 'edit') {
            const { instrNumArray: _instrNumArray, testSteps: _testSteps } = store.getState().selectedTestCaseReducer;
            const { failedStepsData, debugStepsData } = store.getState().projectReducer;
            const paramTestCaseId = getParamValues(2);
            const __instrNum = _instrNumArray[obj.index];
            const isMsgType13Received = checkKeyInObject(failedStepsData, paramTestCaseId) && failedStepsData[paramTestCaseId].instrNum;
            const isMsgType16Received = checkKeyInObject(debugStepsData, paramTestCaseId) && debugStepsData[paramTestCaseId].instrNum;
            if (__instrNum) {
                const step = {
                    ..._testSteps[__instrNum],
                    data: checkKeyInObject(obj, 'testStep.data', 'value', ''),
                    instr: checkKeyInObject(obj, 'testStep.instr', 'value', ''),
                    expectedResults: checkKeyInObject(obj, 'testStep.expectedResults', 'value', ''),
                    detectChanged: true,
                    screenshotSmallPaths: [],
                    screenshotPaths: [],
                    screenshotNo: 0,
                };
                // Add isFirstEditedStep only if msgType 13 and 16 received
                if (isMsgType13Received || isMsgType16Received) {
                    step.isFirstEditedStep = true; // add isFirstEditedStep key here to avoid from sending it in unod array
                }
                store.dispatch(SelectedTestCaseActions.editStepInStepsArray(step));
            }
        }
    };

    handleCRUDActions = (index, actionType, testStep, instr = '', testData = '', expectedResults = '', alreadyAdded = false) => {
        const { failedStepsData, debugStepsData } = store.getState().projectReducer;
        const { testSteps } = store.getState().selectedTestCaseReducer;
        const paramTestCaseId = getParamValues(2);

        let parentInstrNum = `${testStep.instrNum}`.split('.');
        parentInstrNum.pop();
        parentInstrNum = parentInstrNum.join('.');
        const parentStep = parentInstrNum ? testSteps[parentInstrNum] : testStep.instrNum;

        if ((checkKeyInObject(testStep, 'isBlockStep') || checkKeyInObject(testStep, 'isNew')) && checkKeyInObject(parentStep, 'isBlockStep')) {
            // for issue https://github.com/Autonomiq/QA/issues/632
            TestStepUtils.flowStepUpdated(testStep);
        }
        if (actionType === 'add') {
            // when actionType is add we receive array of object in instr
            const data = instr;
            const stepsDataToSave = [];
            const { newInstrNums, updatedTestSteps, updatedInstrNums } = TestStepUtils.createMultiNewInstrNumbers(
                testStep.instrNum,
                index,
                data.length,
                alreadyAdded,
            );
            const isMsgType13Received = checkKeyInObject(failedStepsData, paramTestCaseId) && failedStepsData[paramTestCaseId].instrNum;
            const isMsgType16Received = checkKeyInObject(debugStepsData, paramTestCaseId) && debugStepsData[paramTestCaseId].instrNum;
            data.forEach((datum, i) => {
                const _testStep = {
                    ...testStep,
                    id: newInstrNums[i],
                    instrNum: newInstrNums[i],
                    data: datum.testData,
                    instr: datum.testInstruction,
                    expectedResults: checkKeyInObject(datum, 'expectedResults', 'value', ''),
                    detectChanged: true,
                };

                stepsDataToSave.push({
                    rowIndex: index + i,
                    testStep: { ..._testStep },
                });

                // Add isFirstEditedStep only if msgType 13 and 16 received
                if (isMsgType13Received || isMsgType16Received) {
                    updatedTestSteps[newInstrNums[i]] = { ..._testStep, isFirstEditedStep: true }; // add isFirstEditedStep key here to avoid from sending it in unod array
                } else {
                    updatedTestSteps[newInstrNums[i]] = { ..._testStep };
                }
            });
            this.filterTestSteps({ stepsDataToSave, actionType }, updatedTestSteps, updatedInstrNums);
            store.dispatch(
                SelectedTestCaseActions.saveTemp({
                    action: actionType,
                    stepsDataToSave,
                }),
            );
            return newInstrNums;
        }
        if (actionType === 'edit') {
            this.filterTestSteps({ index, actionType, testStep: { data: testData, instr, expectedResults } });
            store.dispatch(
                SelectedTestCaseActions.saveTemp({
                    action: actionType,
                    stepsDataToSave: [{ rowIndex: index, testStep }],
                }),
            );
        }
        return null;
    };

    handleStepsDelete = (stepsDataToSave) => {
        if (checkArrayLength(stepsDataToSave)) {
            stepsDataToSave.forEach((stepDetail) => {
                // IntrsNum is starting from 1 rather than 0.
                const stepMainInd = stepDetail.testStep.instrNum.split('.').shift() - 1;
                reArrangeStepObj.deletedStep.push(stepMainInd);
            });
            store.dispatch(SelectedTestCaseActions.saveTemp({ action: 'delete', stepsDataToSave }));
            const indexesToRemove = stepsDataToSave.map((stepDetails) => stepDetails.rowIndex);
            store.dispatch(SelectedTestCaseActions.deleteStepsFromStepsArray(indexesToRemove));
        }
    };

    // onClick to update test case name
    updateTestCaseNameAndDesc = (testCase, from) => {
        const { testCaseName, desc } = this.props.tabsData.updateTestCase.state;
        if (testCaseName) {
            const pathArray = getParamValues();
            const newProjectId = pathArray[1];
            const newTestCaseId = pathArray[2];
            const testCaseData = {
                testCaseId: testCase.testCaseId || newTestCaseId,
                name: typeof testCaseName === 'string' ? testCaseName.trim() : testCaseName,
                desc: typeof desc === 'string' ? desc.trim() : '',
                projectId: testCase.discoveryId || newProjectId,
            };
            this.props.updateTestCaseName(testCaseData, from);
        }
        this.props.toggleModal();
    };

    modalForUpdateTestCaseName = ({ testCase, content, from }) => {
        const { toggleModal } = this.props;
        toggleModal('GeneralModal', null, null, {
            title: 'Update Test Case',
            closeIconAction: () => {
                toggleModal();
            },
            component: [
                {
                    content,
                    buttons: [
                        NEXT_SUBMIT_BUTTON({
                            name: 'Update',
                            action: () => {
                                this.updateTestCaseNameAndDesc(testCase, from);
                            },
                            isDisabled: (data) => {
                                let testCaseName = '';
                                if (checkKeyInObject(data, 'updateTestCase.state.testCaseName', 'bool', false)) {
                                    testCaseName = data.updateTestCase.state.testCaseName;
                                }
                                return !testCaseName.trim();
                            },
                        }),
                        CANCEL_BUTTON({
                            action: () => {
                                toggleModal();
                            },
                        }),
                    ],
                },
            ],
        });
    };

    addRemoveCaseTag = (params) => {
        const { action, tag_id, testCaseIds, testCases } = params;
        testCaseIds.forEach((id) => {
            const caseIndex = testCases.findIndex((_case) => `${_case.testCaseId}` === `${id}`);
            if (caseIndex >= 0) {
                if (action === 'add') {
                    if (!testCases[caseIndex].tags) {
                        testCases[caseIndex].tags = [];
                    }
                    testCases[caseIndex].tags.push(tag_id);
                } else if (action === 'remove' && testCases[caseIndex].tags) {
                    const tagIndex = testCases[caseIndex].tags.filter((tag) => `${tag}` === `${tag_id}`);
                    if (tagIndex >= 0) {
                        testCases[caseIndex].tags.splice(tagIndex, 1);
                    }
                }
            }
        });
        return testCases;
    };

    /**
    |--------------------------------------------------
    | GENERAL FUNCTIONS END
    |--------------------------------------------------
    */
    /**
    |--------------------------------------------------
    | DOWNLOAD STEPS FUNCTIONS START
    |--------------------------------------------------
    */
    handleDownloadTestCase = async (type, downloadType, onComplete = () => {}) => {
        let { selectedTestCase } = store.getState().projectReducer;
        if (type === 'Live Steps' && !checkArrayLength(checkKeyInObject(selectedTestCase, 'recoverTestSteps'))) {
            const _steps = await getStepsData(selectedTestCase, 2);
            selectedTestCase = { ...selectedTestCase, ..._steps };
        } else if (!checkArrayLength(checkKeyInObject(selectedTestCase, 'originalTestSteps'))) {
            const _steps = await getStepsData(selectedTestCase, 1);
            selectedTestCase = { ...selectedTestCase, ..._steps };
        }
        this.downloadRequestCase(selectedTestCase.testCaseName, selectedTestCase, type, downloadType);
        onComplete();
    };

    downloadRequestCase = async (fileName, testCase, type, downloadType = 'xlsx') => {
        const caseSteps = type === 'Live Steps' ? testCase.recoverTestSteps : testCase.originalTestSteps;
        // check caseSteps because we don't have recover teststeps in create new testcase unless we save new test case
        if (checkArrayLength(caseSteps)) {
            const filterCaseSteps = [];
            caseSteps.forEach((step) => {
                if ((step.instrNum && !step.instrNum.includes('.')) || !step.instrNum) {
                    filterCaseSteps.push(step);
                }
            });
            if (downloadType === 'csv') {
                try {
                    const header = { 'Test Steps': 'Test Steps', Data: 'Data', 'Expected Result': 'Expected Result' };
                    const data = filterCaseSteps.map((step) => {
                        return {
                            'Test Steps': `"${step.instr.replace(/["]{1}/g, '""')}"`,
                            Data: `"${step.data.replace(/["]{1}/g, '""')}"`,
                            'Expected Result': `"${checkKeyInObject(step, 'expectedResults', 'value', '').replace(/["]{1}/g, '""')}"`,
                        };
                    });
                    JSONTOCsv.CSVFile(header, data, `${fileName}`); // Removed type from downloaded file name - https://github.com/Autonomiq/WebUIRedux/issues/1469
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            } else {
                const data = await singleFileCreationService.getCaseFile(filterCaseSteps, type);
                if (data) {
                    const blob = new Blob([data], { type: 'application/octet-stream' });
                    saveAs(blob, `${fileName}.xlsx`); // Removed type from downloaded file name - https://github.com/Autonomiq/WebUIRedux/issues/1469
                }
            }
        }
    };

    downloadFileZip = async (name, id, isExecution = false) => {
        const { downloadTestStepsFile } = this.props;
        const response = await downloadTestStepsFile(id, isExecution);
        if (response) {
            const contentType = response.headers['content-type'];
            const contentDisposition = response.headers['content-disposition'];
            const ext = extension(contentType);
            const downloadFilename = contentDisposition?.split('filename=')[1] ? contentDisposition.split('filename=')[1] : `filename.${ext}`;
            singleFileCreationService.showFile(response.data, 'download', ` ${downloadFilename || name || 'filename'}.zip`, 'application/zip');
        }
    };

    /**
    |--------------------------------------------------
    | DOWNLOAD STEPS FUNCTIONS END
    |--------------------------------------------------
    */

    getStepsAndTypes = (selectedTestCase) => {
        let testSteps = [];
        let stepsType = '';
        if (checkArrayLength(selectedTestCase.testSteps)) {
            testSteps = selectedTestCase.testSteps;
            stepsType = 'live';
        } else if (checkArrayLength(selectedTestCase.recoverTestSteps)) {
            testSteps = selectedTestCase.recoverTestSteps;
            stepsType = 'recover';
        }
        return { testSteps, stepsType };
    };

    props = {
        toggleModal: (...args) => store.dispatch(ModalActions.toggleModal(...args)),
        updateTestCaseName: (testCaseData, from) => store.dispatch(TestCaseActions.updateTestCaseName(testCaseData, from)),
        downloadTestStepsFile: (...args) => store.dispatch(TestStepActions.downloadTestStepsFile(...args)),
        updatedebugPointList: (...args) => store.dispatch(ProjectsActions.updatedebugPointList(...args)),
        get tabsData() {
            return store.getState().generalModalReducer.tabsData;
        },
        get projectsReducer() {
            return store.getState().projectsReducer;
        },
    };
}

export const TestCaseUtils = new TestCase();
