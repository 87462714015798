// import materials
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faBars } from '@fortawesome/free-solid-svg-icons';

// import material
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { TableSortLabel, IconButton } from '@material-ui/core';

// import packages
import React from 'react';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement, arrayMove, sortableHandle } from 'react-sortable-hoc';

import Close from '@material-ui/icons/Close';
import { TABLES_CSS, BLACK_FONT } from '../../../../common/cssConstants';
import Tooltip from '../../../../components/Tooltip';
import { generalModalActions } from '../../../../store/actions';
import {
    checkKeyInObject,
    sort,
    getFormatedDate,
    getExecutionStatus,
    checkArrayLength,
    checkObject,
    getUserPreference,
} from '../../../../utils/utils';
import AlertPopUpModal from './AlertPopUpModal';
import SearchInput from '../../../modal/GeneralModal/SearchInput';

const styles = (theme) => ({
    dialogContentAddUser: {
        paddingBottom: 12,
        '&:first-child': {
            padding: 0,
        },
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    checkBox: {
        '&>span>svg': {
            fontSize: '20px',
        },
        height: '40px',
        width: '20px',
        marginRight: '10px',
    },
    checkBoxRow: {
        '&>span>svg': {
            fontSize: '20px',
        },
        height: '40px',
        width: '20px',
        marginRight: '40px',
    },
    horizontalRow: {
        width: 'calc(100% + 55px)',
        marginLeft: '-55px',
        border: 0,
        borderTop: '1px solid #DCDCDC',
    },
    label: {
        alignSelf: 'center',
        width: 90,
        '& > p': {
            color: BLACK_FONT,
        },
    },
    rootDialogContent: {
        width: '600px',
        overflowX: 'hidden',
    },
    row: {
        padding: '0px 0px 5px 30px',
        display: 'flex',
        justifyContent: 'start',
    },
    searchInput: {
        marginRight: '20px',
        width: 250,
    },
    cellRole: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: '4px 10px 0px 0px',
    },
    tableRows: {
        cursor: 'pointer',
        backgroundColor: '#f6f6f6',
    },
    inputField: {
        padding: '0px 0px 2px 0px',
        fontSize: '12px',
    },
    barIcon: {
        color: '#F39B31',
        height: '15px',
        width: '20px',
        marginRight: '10px',
        cursor: 'not-allowed',
    },
    sortIcon: {
        fontSize: 10,
        marginLeft: 10,
        color: '#9B9B9B',
    },
    extraPadding: {
        paddingLeft: '10px !important',
        borderBottom: '2px solid rgba(224, 224, 224, 1)',
    },
    tableCont: {
        maxHeight: 'calc(100vh - 403px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.only('xs')]: {
            overflowX: 'scroll',
            maxWidth: '100%',
        },
    },
    table: {
        width: '100%',
        minWidth: 600,
        // display: 'table',
        borderSpacing: 0,
        borderCollapse: 'collapse',
        padding: '0',
        listStyle: 'none',
        overflow: 'hidden',
        margin: '-8px 0 0 0',
    },
    tableHeadTR: {
        height: '40px',
        color: 'inherit',
        // display: 'table-row',
        outline: 'none',
        verticalAlign: 'middle',
        padding: '0 30px',
        borderBottom: '2px solid rgba(224, 224, 224, 1)',

    },
    tableBodyTR: {
        color: 'inherit',
        // display: 'table-row',
        outline: 'none',
        verticalAlign: 'middle',
        padding: '0 30px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    tableTH: {
        // display: 'table-cell',
        textAlign: 'left',
        verticalAlign: 'inherit',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        fontWeight: '500',
        height: '40px',
        display: 'inline-flex',
        '& span:first-child': {
            display: 'flex',
        },
    },
    tableTD: {
        // display: 'table-cell',
        verticalAlign: 'inherit',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.8125rem',
        fontWeight: '400',
        height: '40px',
        // padding: '0 30px',
        display: 'inline-flex',
    },
    selectedItem: {
        zIndex: '999999999 !important',
        listStyle: 'none',
        boxShadow: '3px 2px 11px 0px #cdcdcd, -3px -2px 11px 0px #cdcdcd',
    },
    dragAble: {
        '& .dragAble': {
            '& svg': {
                cursor: 'all-scroll !important',
            },
        },
    },
    errorMessage: {
        fontSize: 12,
        color: 'red',
        marginLeft: 30,
        display: 'block',
    },
    testSuiteName: {
        color: BLACK_FONT,
        fontSize: 16,
        paddingLeft: 30,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '30px',
        marginBottom: 5,
    },
    closeIcon: {
        color: '4A4A4A',
        fontSize: 16,
    },
    closeIconButton: {
        height: 16,
        width: 16,
    },
    sortableWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 30px',
        height: 25,
    },
    tableItem: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
});

const headers = [
    { id: 'testCaseName', sortable: true, numeric: false, disablePadding: true, label: 'Case', styles: { width: '30%' } },
    { id: 'createdTime', sortable: true, numeric: false, disablePadding: true, label: 'Created', styles: { width: 'calc(60% / 3)' } },
    { id: 'lastRun', sortable: true, numeric: false, disablePadding: true, label: 'Last Run', styles: { width: 'calc(60% / 3)' } },
    { id: 'status', sortable: false, numeric: false, disablePadding: true, label: 'Last Status', styles: { width: 'calc(60% / 3)' } },
    { id: 'action', sortable: false, numeric: false, disablePadding: true, label: '', styles: { width: '10%' } },
];
let dontMove = []; // array of cases which are not dragable
let isMounted = false;
let gotValue = false;
let checkBoxClicked = false;
class SuiteOrganizeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: '',
            order: 'asc',
            orderBy: 'testCaseName',
            caseDataArray: [],
            allChecked: false,
            isSortChange: false,
            didChange: false,
            isFirst: false,
            showAlertPopUp: !getUserPreference('dontShowMultiSelectAlert'),
        };
    }

    UNSAFE_componentWillMount() {
        checkBoxClicked = false;
        isMounted = true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!gotValue) {
            let newAssociatedCaseIds = [];
            let oldAssociatedCaseIds = [];
            let newCaseDataArray = [];
            const oldCaseDataArray = [];
            let organizeCaseData = [];
            let organizedDataAvailable = false;
            const associationState = checkKeyInObject(checkKeyInObject(nextProps.tabsData, 'association'), 'state');
            const organizeState = checkKeyInObject(checkKeyInObject(nextProps.tabsData, 'organize'), 'state');

            if (checkKeyInObject(organizeState, 'caseDataArray') && checkArrayLength(Object.keys(organizeState.caseDataArray))) {
                organizeCaseData = organizeState.caseDataArray;
            }
            if (
                !(
                    checkKeyInObject(associationState, 'didChange') &&
                    checkKeyInObject(associationState, 'selectedTestCaseIds') &&
                    !checkArrayLength(Object.keys(associationState.selectedTestCaseIds))
                )
            ) {
                if (
                    checkKeyInObject(associationState, 'selectedTestCaseIds') &&
                    checkKeyInObject(associationState, 'didChange') &&
                    checkArrayLength(Object.keys(associationState.selectedTestCaseIds))
                ) {
                    newAssociatedCaseIds = Object.keys(associationState.selectedTestCaseIds).join(':').split(':'); // join/split use to convert id in string
                }
                if (checkArrayLength(checkKeyInObject(nextProps.organizeTestSuiteData, 'testCases'))) {
                    oldAssociatedCaseIds = nextProps.organizeTestSuiteData.testCases.map((testCase) => `${testCase.testCaseId}`);
                }

                if (checkArrayLength(newAssociatedCaseIds) && checkArrayLength(oldAssociatedCaseIds)) {
                    const tempOldAssociatedCaseIds = [];
                    oldAssociatedCaseIds.forEach((oldCaseId) => {
                        if (newAssociatedCaseIds.includes(`${oldCaseId}`)) {
                            tempOldAssociatedCaseIds.push(oldCaseId);
                        }
                    });
                    oldAssociatedCaseIds = tempOldAssociatedCaseIds;

                    const tempNewAssociatedCaseIds = [];
                    newAssociatedCaseIds.forEach((newCaseId) => {
                        if (!oldAssociatedCaseIds.includes(`${newCaseId}`)) {
                            tempNewAssociatedCaseIds.push(newCaseId);
                        }
                    });
                    newAssociatedCaseIds = tempNewAssociatedCaseIds;
                }

                if (checkArrayLength(newAssociatedCaseIds)) {
                    newCaseDataArray = nextProps.testCaseDropDownData.filter((testCase) => newAssociatedCaseIds.includes(`${testCase.testCaseId}`));
                }
                if (checkArrayLength(checkKeyInObject(nextProps, 'organizeTestSuiteData.testCases', 'value', false))) {
                    nextProps.organizeTestSuiteData.testCases.forEach((_case) => {
                        // Using forEach here to maintain cases order.
                        const temp = oldAssociatedCaseIds.find((testCaseId) => `${testCaseId}` === `${_case.testCaseId}`);
                        if (temp) {
                            oldCaseDataArray.push(_case);
                        }
                    });
                }
            }

            if (checkArrayLength(organizeCaseData)) {
                if (
                    !checkKeyInObject(associationState, 'didChange') ||
                    JSON.stringify([...oldCaseDataArray, ...newCaseDataArray].map((_case) => _case.testCaseId).sort()) ===
                        JSON.stringify(organizeCaseData.map((_proj) => _proj.testCaseId).sort())
                ) {
                    organizedDataAvailable = true;
                }
            }

            this.setStateIfComponentMounted({
                caseDataArray: organizedDataAvailable ? organizeCaseData : [...oldCaseDataArray, ...newCaseDataArray],
                isFirst: true,
            });
            gotValue = true;
        }
    }

    componentWillUnmount() {
        gotValue = false;
        this.onUnmount();
        // isMounted = false;
    }

    onUnmount = () => {
        isMounted = false;
        this.props.updateTabData('organize', { state: { ...this.state } });
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { caseDataArray, isSortChange } = this.state;
        const selectedCasesCount = this.filterCases(caseDataArray).length;
        if (`${dontMove.indexOf(oldIndex)}` === '-1') {
            let movedArray = JSON.parse(JSON.stringify(caseDataArray));
            if (selectedCasesCount === 0) {
                movedArray = arrayMove(movedArray, oldIndex, newIndex);
            } else if (oldIndex < newIndex) {
                for (let i = 0; i < selectedCasesCount; i++) {
                    movedArray = arrayMove(movedArray, oldIndex, newIndex + selectedCasesCount - 1);
                }
            } else {
                for (let i = 0; i < selectedCasesCount; i++) {
                    movedArray = arrayMove(movedArray, oldIndex + i, newIndex + i);
                }
            }
            const status = oldIndex === newIndex ? isSortChange : true;
            this.setStateIfComponentMounted({
                caseDataArray: movedArray,
                isSortChange: status, // isSortChange is use to show/hide header messages for drag and drop
            });
        }
    };

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted)
            this.setState({ didChange: this.state.isFirst, ...obj }, () => {
                callback();
                this.props.updateTabData('organize', { state: { ...this.state } });
            });
    };

    handleRequestSort = (property) => {
        if (!this.state.isSortChange) {
            const orderBy = property;
            let order = 'desc';

            if (this.state.orderBy === property && this.state.order === 'desc') {
                order = 'asc';
            }

            const currentPageCases = sort(this.state.caseDataArray, order, orderBy);

            this.setStateIfComponentMounted({ order, orderBy, caseDataArray: currentPageCases });
        }
    };

    handleToggle = (id) => (e) => {
        checkBoxClicked = true;
        const { caseDataArray } = this.state;
        const index = caseDataArray.map((_case) => _case.testCaseId).indexOf(id);
        caseDataArray[index] = { ...caseDataArray[index], checked: e.target.checked };
        const allChecked = !caseDataArray.some((_case) => !_case.checked);
        this.setStateIfComponentMounted({ caseDataArray, allChecked });
    };

    handleToggleAll = (isAllChecked) => {
        const caseDataArray = [...this.state.caseDataArray];
        let newCaseData = [];

        if (isAllChecked) {
            newCaseData = caseDataArray.map((_case) => {
                const newTestCase = { ..._case, checked: true };
                return newTestCase;
            });
        } else {
            newCaseData = caseDataArray.map((_case) => {
                const newTestCase = { ..._case, checked: false };
                return newTestCase;
            });
        }
        this.setStateIfComponentMounted({ caseDataArray: newCaseData, allChecked: isAllChecked });
    };

    filterCases = (_cases, key = 'testCaseId') => {
        const filteredCases = [];
        _cases.forEach((_case) => {
            if (_case.checked === true && checkKeyInObject(_case, key)) filteredCases.push(_case[key]);
        });
        return filteredCases;
    };

    handleSearchUsers = (e) => {
        const query = e.target.value;
        this.setStateIfComponentMounted({ query });
    };

    // Use to Drag only selected steps
    shouldCancelStart = (e) => {
        if (e && e.path && e.path.length) {
            for (let i = 0; i < e.path.length; i++) {
                if (e.path[i] && e.path[i].dataset && e.path[i].dataset.moveable && e.path[i].dataset.moveable.toLowerCase() === 'false') {
                    return true; // Cancel Dragable
                }
            }
        }
        return false; // Allow Dragable
    };

    clearSearch = () => {
        this.setStateIfComponentMounted({ query: '' });
    };

    handleDeleteTestCase = (testCaseId) => {
        const { caseDataArray } = this.state;
        const { updateTabData, tabsData, associateTestSuiteData } = this.props;
        if (checkArrayLength(caseDataArray)) {
            const tempCaseDataArray = caseDataArray.filter((_case) => _case.testCaseId !== testCaseId);
            this.setStateIfComponentMounted({ caseDataArray: [...tempCaseDataArray] }, () => {
                let association_state = checkKeyInObject(tabsData, 'association.state', 'value', {
                    selectedTestCaseIds: {},
                    selectedTestCaseIdsForDelete: {},
                    testSuiteName: '',
                    testCaseIds: {},
                    querry: '',
                    isSubmitButtonPressed: false,
                    isFirst: false,
                    didChange: false,
                });

                if (!checkKeyInObject(tabsData, 'association.state', 'bool')) {
                    const _selectedTestCaseIds = {};
                    if (checkObject(associateTestSuiteData) && checkArrayLength(associateTestSuiteData.testCases)) {
                        associateTestSuiteData.testCases.forEach((testCase) => {
                            _selectedTestCaseIds[testCase.testCaseId] = true;
                        });
                    }
                    association_state = {
                        ...association_state,
                        testSuiteName: checkKeyInObject(associateTestSuiteData, 'testSuiteName') ? associateTestSuiteData.testSuiteName : '',
                        selectedTestCaseIds: _selectedTestCaseIds,
                        testCaseIds: _selectedTestCaseIds,
                        isFirst: true,
                    };
                }

                if (checkArrayLength(Object.keys(association_state.selectedTestCaseIds))) {
                    const updatedSelectedCases = {};
                    tempCaseDataArray.forEach((_case) => {
                        updatedSelectedCases[`${_case.testCaseId}`] = true;
                    });
                    const _selectedTestCaseIdsForDelete = JSON.parse(JSON.stringify(association_state.selectedTestCaseIdsForDelete));
                    _selectedTestCaseIdsForDelete[testCaseId] = true;
                    updateTabData('association', {
                        state: {
                            ...association_state,
                            selectedTestCaseIds: { ...updatedSelectedCases },
                            selectedTestCaseIdsForDelete: { ..._selectedTestCaseIdsForDelete },
                            didChange: true,
                        },
                    });
                }
            });
        }
    };

    errMsg = () => {
        const { classes, tabsData } = this.props;
        let error = null;
        const suiteInfoError = checkKeyInObject(tabsData, 'suiteInfo.state.error');
        const dateTimeError = checkKeyInObject(this.props.tabsData, 'schedule.state.dateTimeError');
        if (suiteInfoError) {
            error = <span className={classes.errorMessage}>{suiteInfoError}</span>;
        } else if (dateTimeError) {
            error = <span className={classes.errorMessage}>{dateTimeError}</span>;
        }
        return error;
    };

    render() {
        const { classes } = this.props;
        const { query, order, orderBy, allChecked, caseDataArray, isSortChange, showAlertPopUp } = this.state;

        const currentPageCases = checkArrayLength(caseDataArray)
            ? caseDataArray.filter((_case) => {
                  return _case && _case.testCaseName && _case.testCaseName.toLowerCase().includes(query.toLowerCase().trim());
              })
            : [];

        const numSelected = this.filterCases(caseDataArray).length;
        const rowCount = caseDataArray.length;

        const SortableList = SortableContainer(() => {
            const SortableItem = SortableElement(({ value, _styles, _classes, dataMoveAble }) => {
                return (
                    <li className={_classes} style={_styles} data-moveable={dataMoveAble}>
                        {value}
                    </li>
                );
            });
            let disableCondition = numSelected <= 0; // Disable sorting if true.
            let dataIndex = -1;
            const dataMerge = [];
            let isPrevSelected = false; // its checking that if steps are selected in order if thy are in order then its true otherwise its false
            let foundSelected = false; // Use to check if consecutive steps are selected set false if not,
            let selectedIndex = -1; // First selected step's index
            dontMove = []; // array of steps which are not dragable (Temporary)
            return (
                <div className={classes.tableCont}>
                    <ul className={classes.table}>
                        <li className={classes.tableHeadTR}>
                            {headers.map(
                                (column, index) =>
                                    (column.status && column.status === 'InActive') || (column.checked && column.checked === true) ? null : (
                                        <span
                                            key={column.id}
                                            className={[classes.borders, classes.tableTH].join(' ')}
                                            style={column.styles ? { ...column.styles } : {}}
                                        >
                                            <span style={{ padding: '0 0 0 20px !important' }}>
                                                {index === 0 ? (
                                                    <Checkbox
                                                        indeterminate={(numSelected > 0 && numSelected < rowCount) || rowCount === 0}
                                                        checked={numSelected === rowCount}
                                                        onChange={() => {
                                                            this.handleToggleAll(!allChecked);
                                                        }}
                                                        style={{ color: numSelected === rowCount && rowCount > 0 ? '#F39B31' : '#bac4ce' }}
                                                        className={classes.checkBoxRow}
                                                    />
                                                ) : null}
                                                {column.sortable && !isSortChange ? (
                                                    <TableSortLabel
                                                        hideSortIcon
                                                        onClick={() => {
                                                            this.handleRequestSort(column.id);
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                fontSize: TABLES_CSS.headingFontSize,
                                                                color: TABLES_CSS.normalFontColor,
                                                                fontWeight: TABLES_CSS.headingFontWeight,
                                                            }}
                                                            noWrap
                                                        >
                                                            {column.label}
                                                            {orderBy === column.id ? (
                                                                order === 'desc' ? (
                                                                    <FontAwesomeIcon
                                                                        icon={faChevronDown}
                                                                        aria-label="faChevronDown"
                                                                        id="faChevronDown"
                                                                        className={classes.sortIcon}
                                                                    />
                                                                ) : (
                                                                    <FontAwesomeIcon
                                                                        icon={faChevronUp}
                                                                        aria-label="faChevronUp"
                                                                        id="faChevronUp"
                                                                        className={classes.sortIcon}
                                                                    />
                                                                )
                                                            ) : null}
                                                        </Typography>
                                                    </TableSortLabel>
                                                ) : (
                                                    <TableSortLabel hideSortIcon>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                fontSize: TABLES_CSS.headingFontSize,
                                                                color: TABLES_CSS.normalFontColor,
                                                                fontWeight: TABLES_CSS.headingFontWeight,
                                                            }}
                                                            noWrap
                                                        >
                                                            {column.label}
                                                        </Typography>
                                                    </TableSortLabel>
                                                )}
                                            </span>
                                        </span>
                                    ),
                                this,
                            )}
                        </li>
                        {currentPageCases.forEach((testCase, index) => {
                            const isChecked = checkKeyInObject(testCase, 'checked', 'value', false);
                            const DragHandle = sortableHandle(() => (
                                <span className="dragAble">
                                    <FontAwesomeIcon className={classes.barIcon} icon={faBars} aria-label="faBars" id="faBars" />
                                </span>
                            ));
                            const data = [];
                            data.push(
                                <span
                                    key={`${index}-1`}
                                    className={[classes.cellRole, classes.tableTD].join(' ')}
                                    style={{ width: headers[0].styles.width, backgroundColor: isChecked && '#FCF9E5' }}
                                >
                                    <div className={classes.tableItem}>
                                        <Checkbox
                                            checked={testCase.checked === true}
                                            color="primary"
                                            disableRipple
                                            onClick={this.handleToggle(testCase.testCaseId)}
                                            style={{
                                                color: testCase.checked === true ? '#F39B31' : '#bac4ce',
                                            }}
                                            className={classes.checkBox}
                                        />
                                        <DragHandle />
                                        <Typography
                                            noWrap
                                            style={{
                                                color: TABLES_CSS.normalFontColor,
                                                fontSize: TABLES_CSS.normalFontSize,
                                                display: 'inline-block',
                                            }}
                                        >
                                            <Tooltip data={testCase.testCaseName ? testCase.testCaseName : null}>
                                                {testCase.testCaseName ? testCase.testCaseName : ''}
                                            </Tooltip>
                                        </Typography>
                                    </div>
                                </span>,
                            );
                            data.push(
                                <span
                                    key={`${index}-2`}
                                    className={[classes.cellRole, classes.tableTD].join(' ')}
                                    style={{ width: headers[1].styles.width, backgroundColor: isChecked && '#FCF9E5' }}
                                >
                                    <div className={classes.tableItem}>
                                        <Typography
                                            noWrap
                                            style={{
                                                fontSize: TABLES_CSS.normalFontSize,
                                                color: TABLES_CSS.normalFontColor,
                                            }}
                                        >
                                            <Tooltip data={getFormatedDate(testCase.createdTime, 'MMM D, YYYY, HH:mm:ss', null)}>
                                                {getFormatedDate(testCase.createdTime, 'MMM D, YYYY', 'N/A')}
                                            </Tooltip>
                                        </Typography>
                                    </div>
                                </span>,
                            );
                            data.push(
                                <span
                                    key={`${index}-3`}
                                    className={[classes.cellRole, classes.tableTD].join(' ')}
                                    style={{ width: headers[2].styles.width, backgroundColor: isChecked && '#FCF9E5' }}
                                >
                                    <div className={classes.tableItem}>
                                        <Typography
                                            noWrap
                                            style={{
                                                fontSize: TABLES_CSS.normalFontSize,
                                                color: TABLES_CSS.normalFontColor,
                                            }}
                                        >
                                            {testCase.lastRun && testCase.lastRun !== testCase.createdTime
                                                ? getFormatedDate(testCase.lastRun, 'MMM D, YYYY', 'N/A')
                                                : 'N/A'}
                                        </Typography>
                                    </div>
                                </span>,
                            );
                            data.push(
                                <span
                                    key={`${index}-4`}
                                    className={[classes.cellRole, classes.tableTD].join(' ')}
                                    style={{ width: headers[3].styles.width, backgroundColor: isChecked && '#FCF9E5' }}
                                >
                                    <div className={classes.tableItem}>
                                        <Typography
                                            noWrap
                                            style={{
                                                fontSize: TABLES_CSS.normalFontSize,
                                                color: TABLES_CSS.normalFontColor,
                                            }}
                                        >
                                            {getExecutionStatus(testCase)}
                                        </Typography>
                                    </div>
                                </span>,
                            );
                            data.push(
                                <span
                                    key={`${index}-5`}
                                    className={[classes.cellRole, classes.tableTD].join(' ')}
                                    style={{ width: headers[4].styles.width, backgroundColor: isChecked && '#FCF9E5' }}
                                >
                                    <div className={classes.tableItem}>
                                        <IconButton
                                            onClick={() => {
                                                this.handleDeleteTestCase(testCase.testCaseId);
                                            }}
                                            aria-label="Clear"
                                            id="clearTestCase"
                                            className={classes.closeIconButton}
                                        >
                                            <Close className={classes.closeIcon} />
                                        </IconButton>
                                    </div>
                                </span>,
                            );
                            if (disableCondition || !testCase.checked || !isPrevSelected) {
                                // if no steps selected or current step is not selected or previous to current step is not selected then
                                // increase index for single step in dataMerge
                                dataIndex++;
                            }
                            if (!disableCondition && testCase.checked) {
                                // If current step is selected set isPrevSelected 'true' for next step
                                isPrevSelected = true;
                                if (selectedIndex === -1) {
                                    // If there's no prior selectedIndex i.e -1
                                    selectedIndex = dataIndex; // Set current dataIndex as first selected index
                                    foundSelected = true; // set foundSelect true as it's first index we assume it as consecutive with next steps
                                } else if (selectedIndex !== -1 && selectedIndex !== dataIndex) {
                                    // If we select step 1,2 then skip step 3 and then again select 4th one then
                                    // step-in this condition and set foundSelected false (read it's initialization comment)
                                    // and disableCondition true (read it's initialization comment)
                                    foundSelected = false;
                                    disableCondition = true;
                                }
                            } else {
                                // otherwise set isPrevSelected 'false' for next step
                                isPrevSelected = false;
                            }
                            if (!dataMerge[dataIndex]) {
                                // If value for current index of dataMerge is undefined initialize it with emty array
                                dataMerge[dataIndex] = [];
                            }
                            dataMerge[dataIndex].push(data);
                        })}
                        {dataMerge.length > 0 ? (
                            dataMerge.map((data, index) => {
                                if ((disableCondition && numSelected > 0) || query !== '') {
                                    return (
                                        <li
                                            key={`item-${index}`}
                                            className={[classes.tableRows, classes.tableBodyTR].join(' ')}
                                            style={{ cursor: 'default' }}
                                        >
                                            {data}
                                        </li>
                                    );
                                }

                                if (!(index === selectedIndex && foundSelected) && numSelected > 0) {
                                    // if current step is not selected or foundSelected is false
                                    // push index in array for none dragable li
                                    dontMove.push(index);
                                }
                                return (
                                    <SortableItem
                                        dataMoveAble={(index === selectedIndex && foundSelected) || numSelected === 0}
                                        key={`item-${index}`}
                                        index={index}
                                        _classes={
                                            !(index === selectedIndex && foundSelected) && numSelected > 0
                                                ? [classes.tableRows, classes.tableBodyTR].join(' ')
                                                : [classes.tableRows, classes.tableBodyTR, classes.dragAble].join(' ')
                                        }
                                        styles={{ cursor: 'default' }}
                                        id={index}
                                        value={data}
                                    />
                                );
                            })
                        ) : (
                            <li
                                key="item-nodata"
                                className={[classes.tableRows, classes.tableBodyTR].join(' ')}
                                style={{ cursor: 'default', height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <Typography style={{ textAlign: 'center' }}>No test case available.</Typography>
                            </li>
                        )}
                    </ul>
                </div>
            );
        });

        return (
            <div>
                <form autoComplete="off">
                    <DialogContent classes={{ root: classes.dialogContentAddUser }}>
                        {showAlertPopUp && checkBoxClicked && (
                            <AlertPopUpModal
                                handleClose={() => {
                                    this.setState({ showAlertPopUp: false });
                                }}
                                localStorageKey="dontShowMultiSelectAlert"
                                heading="Change order of cases"
                                message="You can drag & drop single case or multiple cases."
                                checkBoxMessage="Don’t show this message again."
                                top={0}
                            />
                        )}
                        <div className={classes.testSuiteName}>
                            <Tooltip data={` ${checkKeyInObject(this.props.tabsData, 'suiteInfo.state.testSuiteName', 'value', '')}`}>
                                {checkKeyInObject(this.props.tabsData, 'suiteInfo.state.testSuiteName', 'value', '')}
                            </Tooltip>
                        </div>
                        <div>
                            {this.errMsg()}
                            <div className={classes.rootDialogContent}>
                                <hr className={classes.horizontalRow} />
                                <div className={classes.sortableWrapper}>
                                    <span style={{ display: 'flex', alignItems: 'center' }} />
                                    <SearchInput
                                        id="SuiteOrganizeModal"
                                        placeholder="Search"
                                        onChange={(e) => this.handleSearchUsers(e)}
                                        value={query}
                                        style={{ width: 250 }}
                                        clearSearch={this.clearSearch}
                                    />
                                </div>
                                <hr className={classes.horizontalRow} />
                                <SortableList
                                    lockAxis="y"
                                    useDragHandle
                                    onSortStart={(/* node, index, collection */) => {}} // Function is required as onSortStart prop
                                    shouldCancelStart={this.shouldCancelStart}
                                    onSortEnd={this.onSortEnd}
                                    helperClass={classes.selectedItem}
                                />
                            </div>
                        </div>
                    </DialogContent>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        testCases: state.projectReducer.testCases,
        tabsData: state.generalModalReducer.tabsData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SuiteOrganizeModal));
