import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import TaskTile from '../../components/Task/TaskTile';
import TaskMessageTile from '../../components/Task/TaskMessageTile';

const styles = () => ({
    container: {
        width: 'inherit',
    },
    listItem: {
        paddingLeft: 8,
        paddingRight: 0,
        paddingTop: 4,
        paddingBottom: 4,
        borderBottom: '#eee solid 1px',
    },
});

function ExecutionTasks(props) {
    const {
        classes,
        tasks,
        showExecutionVideo,
        getExecutionDetails,
        showExecutionStatus,
        executionsLoading,
        executionsPolling,
        highLightExecutionId,
        history,
        toggleSwipeable,
        selectedExecution

    } = props;
    let content;
    if (tasks && tasks.length) {
        content = tasks.map((task, i) => (
            <ListItem
                className={classes.listItem}
                key={i}
                style={{ backgroundColor: `${task.executionId}` === `${highLightExecutionId}` ? '#f8ff90' : '' }}
            >
                <TaskTile
                    index={i}
                    task={task}
                    ExecutionDetails={getExecutionDetails}
                    showExecutionStatus={showExecutionStatus}
                    showExecutionVideo={showExecutionVideo}
                    history={history}
                    toggleSwipeable={toggleSwipeable}
                />
            </ListItem>
        ));
        if (executionsLoading && !executionsPolling) {
            content.push(
                <ListItem className={classes.listItem} key="Loading">
                    {selectedExecution.length === 0 ? <TaskMessageTile message="Loading..." /> : null}
                </ListItem>,
            );
        }
    } else {
        content = (
            <ListItem className={classes.listItem}>
                <TaskMessageTile message={executionsLoading && !executionsPolling ? 'Loading...' : 'No tasks executed'} />
            </ListItem>
        );
    }
    return (
        <div className={classes.container}>
            <List>{content}</List>
        </div>
    );
}

ExecutionTasks.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    executionsLoading: PropTypes.bool.isRequired,
    tasks: PropTypes.array,
    executionsPolling: PropTypes.bool,
};

ExecutionTasks.defaultProps = {
    tasks: [],
    executionsPolling: false,
};

export default withStyles(styles)(ExecutionTasks);
