import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';

// fontawesome icons
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faListUl, faTag, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ContentCopy from 'mdi-material-ui/ContentCopy';
import Edit from '@material-ui/icons/Edit';
// import Delete from '@material-ui/icons/Delete';
// import AddToPhotos from '@material-ui/icons/AddToPhotos';
import Disable from '@material-ui/icons/NotInterested';
import File from 'mdi-material-ui/File';
import FileDocument from 'mdi-material-ui/FileDocument';
import FileDownload from '@material-ui/icons/GetApp';
import FileExcel from 'mdi-material-ui/FileExcel';
// import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import FormatListNumbered from 'mdi-material-ui/FormatListNumbered';
import Restore from '@material-ui/icons/PowerSettingsNew';

import UpdateTestCaseHeaderTopDetailContent from '../TestSteps/Header/UpdateTestCaseModalContent';
import { ModalActions, TestCaseActions } from '../../../store/actions';
import { TestCaseUtils, checkArrayLength, getStepsData, getProjectDropDownData, checkKeyInObject } from '../../../utils';
import DeleteTestCaseDialog from '../../plan/case/DeleteTestCase';
import EditTestCase from '../../plan/case/EditTestCase';
import { THREE_DOT_MENU_CSS, BLACK_FONT, MUI_ACTION_MENU_ICON } from '../../../common/cssConstants';
import { MenuOptions } from '../../../components/Button/MenuOptions';
import singleFileCreationService from '../../../services/singleFileCreationService';
import { CustomPopup } from '../../../components/CustomModal/CustomModal';
import Tooltip from '../../../components/Tooltip';

const styles = () => ({
    actionEditTitleContainer: {
        width: '100%',
        pointerEvents: 'none',
    },
    actionTitleContainerWidth: {
        width: '100%',
        height: '48px',
    },
    actionTitle: {
        color: '#000000',
        marginLeft: '10px',
        fontSize: '11px',
        borderBottom: '1px solid #7E7E7E',
        fontWeight: 'bold',
    },
    horizontalLine: {
        backgroundColor: '#DCDCDC',
        height: '9px',
        borderTop: 'solid',
        marginRight: '-13px',
    },
    moreIcons: MUI_ACTION_MENU_ICON,
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
    },
    moreIconsBtnDisabled: {
        color: THREE_DOT_MENU_CSS.iconDisableColor,
    },
    message: {
        float: 'left',
        fontSize: 12,
        color: BLACK_FONT,
    },
    disabled: {
        color: '#BAC4CE',
    },
    iconButton: {
        width: '30px',
        height: '30px',
    },
});

const TestCaseMenuOption = ({
    classes,
    match,
    testCase,
    callingFrom,
    extend,
    iconButtonDisabled,
    MoreVertStyle,
    previewTestScript,
    previewTestData,
    previewTestCase,
    // runTestSteps,
    handleClickRow,
}) => {
    // const { classes, testCase, callingFrom } = props;
    const [disableDialogOpen, setDisableDialogOpen] = useState(false);
    const [enableDialogOpen, setEnableDialogOpen] = useState(false);
    // const [openDeleteCaseDialog, setOpenDeleteCaseDialog] = useState(false);
    const [moveCaseDialogOpen, setMoveCaseDialogOpen] = useState(false);
    const [openTagMenuId, setOpenTagMenuId] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const redux = useSelector((state) => {
        return {
            testData: state.projectReducer.testData,
            user: state.authReducer.user,
            projectsByIdAndName: state.projectReducer.projectsByIdAndName,
            wsRunningCaseData: state.projectReducer.wsRunningCaseData[checkKeyInObject(testCase, 'testCaseId')],
            isWsRunning: state.projectReducer.isWsRunning[checkKeyInObject(testCase, 'testCaseId')],
        };
    });
    const dispatch = useDispatch();

    const props = {
        toggleModal: (...args) => {
            dispatch(ModalActions.toggleModal(...args));
        },
        deleteTestCase: (...args) => {
            return dispatch(TestCaseActions.deleteTestCase(...args));
        },
        moveTestCase: (...args) => {
            return dispatch(TestCaseActions.moveTestCase(...args));
        },
        // cloneTestCase: (...args) => {
        //     dispatch(TestCaseActions.cloneTestCase(...args));
        // },
    };

    const handleCloseDeleteDialog = async (flag) => {
        if (flag) {
            const type = disableDialogOpen ? 1 : 0;
            if (callingFrom === 'projectDetails' || callingFrom === 'plan') {
                await dispatch(TestCaseActions.disableTestCase([testCase.testCaseId], type, callingFrom));
            }
            setTimeout(() => {
                setDisableDialogOpen(false);
                setEnableDialogOpen(false);
                // Don't need this now
                // this.getTestCasesAndData(projectId, true);
            }, 250);
        } else {
            setDisableDialogOpen(false);
            setEnableDialogOpen(false);
        }
    };

    const modalForUpdateTestCaseName = () => {
        TestCaseUtils.modalForUpdateTestCaseName({
            testCase,
            from: callingFrom,
            content: <UpdateTestCaseHeaderTopDetailContent desc={testCase.desc} testCaseName={testCase.testCaseName} />,
        });
    };

    const handleEditSubmit = async (testCaseName, selectedProjectId, selectedProjectName) => {
        const response = await props.moveTestCase(testCase.testCaseId, testCaseName, parseInt(selectedProjectId, 10), selectedProjectName);
        if (response) {
            setMoveCaseDialogOpen(false);
        }
    };

    const copyUrlToClipboardNonSecureFunction = (string) => {
        function handler(event) {
            event.clipboardData.setData('text/plain', string);
            event.preventDefault();
            document.removeEventListener('copy', handler, true);
        }

        document.addEventListener('copy', handler, true);
        document.execCommand('copy');
    };

    const copyUrlToClipboard = (testCaseId) => {
        const url = `${window.location.href.replace('/cases', '')}/${testCaseId}`;
        try {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(url);
            } else {
                copyUrlToClipboardNonSecureFunction(url);
            }
            dispatch(TestCaseActions.copiedSuccessFully());
        } catch (e) {
            dispatch(TestCaseActions.copyingFailed());
        }
    };

    const subHeader = (title, seperator) => (
        <div className={seperator ? classes.actionTitleContainerWidth : classes.actionEditTitleContainer}>
            {/* <div className={classes.actionTitleContainerWidth}> */}
            {/* {seperator && <hr className={classes.horizontalLine} />} */}
            {seperator && <div className={classes.horizontalLine} />}
            <span className={classes.actionTitle}>{title}</span>
        </div>
    );

    const handleTagModalClose = () => {
        setAnchorEl(null);
        setOpenTagMenuId(0);
    };

    const moveCase = () => ({
        action: () => {
            setMoveCaseDialogOpen(true);
        },
        icon: (
            <FontAwesomeIcon
                style={{ fontSize: THREE_DOT_MENU_CSS.faIconFontSize, padding: '1px' }}
                icon={faExchangeAlt}
                aria-label="faExchangeAlt"
                id="faExchangeAlt"
                className={classes.moreIcons}
            />
        ),
        isDisabled: false,
        name: ' Move Test Case',
    });


    const StepEditor = () => ({
        isLink: true,
        route: `/projects/${testCase.discoveryId}/${testCase.testCaseId}`,
        icon: <FormatListNumbered className={classes.moreIcons} />,
        isDisabled: !testCase.testCaseId || !testCase.discoveryId,
        name: ' Step Editor',
    });

    const copyUrl = () => ({
        action: () => {
            copyUrlToClipboard(testCase.testCaseId);
        },
        icon: <ContentCopy className={[classes.moreIcons, !testCase.testCaseId ? classes.moreIconsBtnDisabled : classes.moreIconsBtn].join(' ')} />,
        isDisabled: !testCase.testCaseId,
        name: ' Copy Url',
    });

    const disabledOption = () => ({
        action: () => {
            setDisableDialogOpen(true);
        },
        icon: <Disable className={classes.moreIcons} aria-label="disableIcon" id="disableIcon" />,
        isDisabled: testCase.disabledStatus,
        name: ' Disable Test Case',
    });

    const ProjectDetailsTestCaseOptions = () => {
        const scriptId = testCase && testCase.testScriptId;

        let flag = false;
        let dataId = '';
        let dataName = '';
        if (checkArrayLength(redux.testData)) {
            redux.testData.some((testDatum) => {
                const testCaseIds = checkArrayLength(testDatum.testCasesIds) ? testDatum.testCasesIds : [];
                testCaseIds.some((id) => {
                    if (parseInt(id, 10) === testCase.testCaseId) {
                        dataId = testDatum.testDataId;
                        dataName = testDatum.testDataFileName;
                        flag = true;
                    }
                    return flag;
                });
                return flag;
            });
        }
        const caseLink = checkKeyInObject(match, 'params.projectId', 'bool', false)
            ? `/projects/${match.params.projectId}/${testCase.testCaseId}`
            : '';
        const menu = [
            {
                icon: subHeader('PREVIEW & DOWNLOAD', true),
                isDisabled: true,
            },
            {
                isLink: !!caseLink,
                route: caseLink,
                icon: (
                    <FontAwesomeIcon
                        style={{ fontSize: THREE_DOT_MENU_CSS.faIconFontSize, padding: '1px' }}
                        icon={faEye}
                        aria-label="faEye"
                        id="faEye"
                        className={[classes.moreIcons, classes.moreIconsBtn].join(' ')}
                    />
                ),
                isDisabled: false,
                name: ' Preview',
            },
            {
                action: () => {
                    getStepsData(testCase, 2, (_testCase) => {
                        previewTestCase(_testCase);
                    });
                },
                icon: (
                    <FileExcel
                        className={[classes.moreIcons, !testCase.testCaseId ? classes.moreIconsBtnDisabled : classes.moreIconsBtn].join(' ')}
                    />
                ),
                isDisabled: !testCase.testCaseId /* testCase.location */,
                name: ' File',
            },
            {
                action: () => {
                    previewTestData(dataId, dataName);
                },
                icon: <FileDocument className={[classes.moreIcons, !dataId ? classes.moreIconsBtnDisabled : classes.moreIconsBtn].join(' ')} />,
                isDisabled: !dataId,
                name: ' Data',
            },
            {
                action: () => {
                    previewTestScript(scriptId,"test script");
                },
                icon: <File className={[classes.moreIcons, !scriptId ? classes.moreIconsBtnDisabled : classes.moreIconsBtn].join(' ')} />,
                isDisabled: !scriptId,
                name: ' Script',
            },
            {
                action: () => {
                    if (testCase.reportUrl) singleFileCreationService.getDownloadAiqReport(testCase.reportUrl, `${testCase.testCaseName}_Report`);
                },
                icon: (
                    <FileDownload
                        className={[
                            classes.moreIcons,
                            !testCase || (testCase && Boolean(redux.isWsRunning)) // (!!redux.isWsRunning)
                                ? classes.moreIconsBtnDisabled
                                : classes.moreIconsBtn,
                        ].join(' ')}
                        aria-label="fileDownloadIcon"
                        id="fileDownloadIcon"
                    />
                ),
                isDisabled: !testCase || (testCase && Boolean(redux.isWsRunning)) || testCase.status === 0 || !testCase.reportUrl,
                name: ' Report',
            },
            // {
            //     icon: subHeader('ACTION', true),
            //     isDisabled: true,
            // },
            // {
            //     action: () => {
            //         runTestSteps(testCase);
            //     },
            //     icon: (
            //         <PlayCircleOutline
            //             className={[classes.moreIcons, redux.isWsRunning ? classes.moreIconsBtnDisabled : classes.moreIconsBtn].join(' ')}
            //             aria-label="playCircleOutlineIcon"
            //             id="playCircleOutlineIcon"
            //         />
            //     ),
            //     isDisabled: Boolean(redux.isWsRunning) || testCase.isGenerating || redux.wsRunningCaseData, // (wsRunningCaseData && wsRunningCaseData),
            //     name: ' Generate',
            // },
        ];
        return menu;
    };

    const renderItems = () => {
        let items = [
            {
                icon: subHeader('EDIT', false), // <div className={classes.actionEditTitleContainer}>
                isDisabled: true,
            },
            {
                action: () => {
                    modalForUpdateTestCaseName();
                },
                icon: <Edit className={`${classes.moreIcons} ${classes.moreIconsBtn}`} aria-label="editIcon" id="editIcon" />,
                isDisabled: false,
                name: ' Update Test Case',
            },
            // {
            //     action: () => {
            //         onCloneHandler();
            //     },
            //     icon: <AddToPhotos className={`${classes.moreIcons} ${classes.moreIconsBtn}`} aria-label="photosIcon" id="photosIcon" />,
            //     isDisabled: false,
            //     name: ' Clone case',
            // },
            // {
            //     action: () => {
            //         setOpenDeleteCaseDialog(true);
            //     },
            //     icon: (
            //         <Delete
            //             className={[
            //                 classes.moreIcons,
            //                 // (!isReady) ? classes.moreIconsBtnDisabled : classes.moreIconsBtn
            //                 classes.moreIconsBtn,
            //             ].join(' ')}
            //             aria-label="deleteIcon"
            //             id="deleteicon"
            //         />
            //     ),
            //     // isDisabled: !isReady || checkArrayLength(testCase.tags),
            //     isDisabled: false, // remove condition due to this issue https://github.com/Autonomiq/QA/issues/789
            //     name: ' Delete',
            //     dataRh: checkArrayLength(testCase.tags)
            //         ? 'Unable to delete tagged test case. Remove all tags from this test case before deleting it'
            //         : null,
            // },
        ];

        if (callingFrom === 'projectDetails') {
            items.splice(3, 0, copyUrl());
            if (!testCase.disabledStatus) {
                items.splice(4, 0, disabledOption());
            }
            items = [...items, ...ProjectDetailsTestCaseOptions()];

            if (extend) {
                const options = [
                    {
                        action: (e) => handleClickRow(e, testCase),
                        icon: (
                            <FontAwesomeIcon
                                style={{ fontSize: THREE_DOT_MENU_CSS.faIconFontSize, padding: '1px' }}
                                icon={faListUl}
                                aria-label="faListUl"
                                id="faListUl"
                                className={[classes.moreIcons, classes.moreIconsBtn].join(' ')}
                            />
                        ),
                        isDisabled: !testCase.testScriptId || testCase.testScriptId == 0,
                        name: ' Show Executions',
                    },
                ];
                items = [...items, ...options];
            }
        } else if (callingFrom === 'plan') {
            items.splice(2, 0, moveCase());
            if (!testCase.disabledStatus) {
                items.splice(4, 0, disabledOption());
            }
            items = [
                ...items,
                {
                    icon: subHeader('GO TO', true),
                    isDisabled: true,
                },
                StepEditor(),
            ];
        }

        return items;
    };

    return (
        <div>
            {testCase.disabledStatus ? (
                <Tooltip data="Enable Case">
                    <IconButton
                        aria-label="More"
                        aria-owns="simple-menu"
                        onClick={() => {
                            setEnableDialogOpen(true);
                        }}
                        className={classes.iconButton}
                        disabled={iconButtonDisabled}
                    >
                        <Restore
                            className={`${classes.moreIconsBtn}} ${iconButtonDisabled ? classes.disabled : ''}`}
                            aria-label="restoreIcon"
                            id="restoreIcon"
                        />
                    </IconButton>
                </Tooltip>
            ) : (
                <MenuOptions
                    items={renderItems()}
                    keepMounted
                    iconButtonDisabled={iconButtonDisabled}
                    MoreVertStyle={MoreVertStyle}
                    handleMenuToggle={setAnchorEl}
                />
            )}

            {/* {
                disableDialogOpen ? (
                    <DeleteTestCaseDialog
                        handleClose={handleCloseDeleteDialog}
                        open={disableDialogOpen}
                        deleteTestCaseData={testCase}
                        isDisableCase={1}
                    />
                ) : null
            } */}
            {
                /* openDeleteCaseDialog || */ disableDialogOpen || enableDialogOpen ? (
                    <DeleteTestCaseDialog
                        deleteButton="" /* {openDeleteCaseDialog ? 'Danger' : ''} */
                        handleClose={(flag) => {
                            // if (openDeleteCaseDialog) {
                            //     hadleCloseDeleteAlertModal(flag);
                            // } else {
                            handleCloseDeleteDialog(flag);
                            // }
                        }}
                        open={/* openDeleteCaseDialog ||  */ disableDialogOpen || enableDialogOpen}
                        isDisableCase={/* openDeleteCaseDialog ? 0 :  */ disableDialogOpen ? 1 : enableDialogOpen ? 2 : -1}
                        deleteTestCaseData={testCase}
                    />
                ) : null
            }
            {moveCaseDialogOpen ? (
                <EditTestCase
                    handleClose={() => {
                        setMoveCaseDialogOpen(false);
                    }}
                    open={moveCaseDialogOpen}
                    isSubmitDisabled={false}
                    projectDropDownData={getProjectDropDownData(redux.projectsByIdAndName)}
                    handleSubmit={handleEditSubmit}
                    editTestCaseData={testCase}
                />
            ) : null}
            {!testCase.disabledStatus && openTagMenuId === testCase.testCaseId && (
                <CustomPopup
                    anchorEl={anchorEl}
                    open={openTagMenuId > 0}
                    handleClose={handleTagModalClose}
                    data={{
                        name: 'plan',
                        selectedTestCase: testCase,
                    }}
                />
            )}
        </div>
    );
};

const TestCaseMenuOptions = withStyles(styles)(TestCaseMenuOption);
export { TestCaseMenuOptions };
