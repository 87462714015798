import React from 'react';

const AiqSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18.4" viewBox="0 0 20 23">
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" transform="translate(-777 -32) translate(777 32)">
            <path
                fill="#5f7b96"
                d="M11.451 16.186a5.304 5.304 0 01-1.487.202c-3.582 0-6.557-2.888-6.557-6.515a6.44 6.44 0 013.853-5.91L6.18.739C2.596 2.216.027 5.709.027 9.873c0 5.44 4.462 9.873 9.937 9.873.879 0 1.758-.135 2.569-.336l-1.082-3.224z"
            />
            <path
                fill="#5f7b96"
                d="M10.032 0c-.879 0-1.69.134-2.434.336L8.68 3.56a8.374 8.374 0 011.352-.135c3.583 0 6.557 2.888 6.557 6.515 0 2.552-1.487 4.836-3.718 5.843l1.082 3.224a9.835 9.835 0 006.016-9.067C19.9 4.433 15.507 0 10.032 0z"
            />
            <path
                fill="#5f7b96"
                d="M12.6500535 15.5315054L13.1912384 13.9860951 16.7765882 22.9225988 13.6647752 19.4286274 12.920646 20.638079 9.2 11.5z"
            />
        </g>
    </svg>
);

export default AiqSvg;
