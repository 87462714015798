// import materials
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

// import packages
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Disable from '@material-ui/icons/NotInterested';
import Restore from '@material-ui/icons/PowerSettingsNew';

// fa icons
import { faChevronDown, faChevronUp, faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import custom component
import moment from 'moment';
import { MUI_ACTION_MENU_ICON, THREE_DOT_MENU_CSS, TABLES_CSS, BLACK_FONT, NO_WRAP } from '../../../common/cssConstants';
import { EnhancedTableHead } from '../../../components/EnhancedTable';
import { UserActions, ModalActions, GeneralActions, generalModalActions } from '../../../store/actions';
import {
    checkArrayLength,
    checkKeyInObject,
    sort,
    getCountAvatar,
    getFormatedDate,
    getRelativeTime,
    resizeAllCols,
    makeLocalDate,
} from '../../../utils/utils';
import AlertModal from '../../projectDetails/Case/AlertModal';
import Tooltip from '../../../components/Tooltip';
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import TableCell from '../../../components/Table/TableCell';
import TableRow from '../../../components/Table/TableRow';
import TableFooter from '../../../common/TableFooter';
import { USER_NAME } from '../../../common/constants';
import { MenuOptions } from '../../../components/Button/MenuOptions';
import ExpandBtn from '../../../components/Button/ExpandBtn';
import AdminAccountInfo from '../modal/AdminAccountInfo';
import AssociationModal from '../modal/AssociationModal';
import { NEXT_SUBMIT_BUTTON, CANCEL_BUTTON, SAVE_OUTLINE_BUTTON } from '../../../components/Button/Btn';

function createData(group) {
    return {
        id: group.id,
        accountId: group.account_id,
        description: group.description,
        name: group.name,
        disabled_status: group.disabled_status,
        end_date: group.end_date,
        start_date: group.start_date,
        users: group.users,
    };
}

// headers for user
const subHeaders = [
    { id: 'name', sortable: true, parentIndex: 0, numeric: false, disablePadding: true, label: 'User', styles: { paddingLeft: 10 } },
    { id: 'email', sortable: true, parentIndex: 1, numeric: false, disablePadding: true, label: 'Email', styles: { paddingLeft: 10 } },
    { id: 'startDate', sortable: true, parentIndex: 2, numeric: false, disablePadding: true, label: 'Start Date', styles: { paddingLeft: 10 } },
    { id: 'endDate', sortable: true, parentIndex: 3, numeric: false, disablePadding: true, label: 'End Date', styles: { paddingLeft: 10 } },
    { id: 'Time', sortable: true, parentIndex: 4, numeric: false, disablePadding: true, label: 'Last Login', styles: { paddingLeft: 10 } },
    { id: 'role', sortable: true, parentIndex: 5, numeric: false, disablePadding: true, label: 'Role', styles: { paddingLeft: 10 } },
    { id: 'accoount', sortable: false, parentIndex: 5, numeric: false, disablePadding: true, label: 'Account', styles: { paddingLeft: 10 } },
];

const styles = (theme) => ({
    headerActionContainer: {
        color: '#1168CD',
        display: 'flex',
        justifyContent: 'space-between',
        width: 'auto',
        marginRight: '20px',
        alignSelf: 'flex-end',
    },
    headerContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
    },
    row: {
        height: 40,
    },
    menuButton: {
        marginLeft: 6,
        height: 32,
        width: 32,
    },
    circleIcon: {
        fontSize: 28,
        color: '#1068cd',
    },
    selectedText: {
        color: BLACK_FONT,
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '3px',
    },
    multiActionsBar: {
        display: 'flex',
        alignItems: 'center',
    },
    multiCloseIcon: {
        color: '#4a4a4a',
        cursor: 'pointer',
        fontSize: 22,
        marginLeft: 10,
    },
    multiIcon: {
        color: '#3b91df',
        fontSize: 22,
        cursor: 'pointer',
        marginRight: 5,
    },
    multiIconDisbaled: {
        color: '#BCBCBC',
        fontSize: 22,
        cursor: 'not-allowed',
        marginRight: 5,
    },
    checkBox: {
        height: 40,
        marginRight: '10px',
        width: '20px',
    },
    checkBoxIcon: {
        color: '#0092e5',
    },
    selectionMessageBar: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f0f7ea',
        padding: '0 15px',
        height: 45,
        justifyContent: 'space-between',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    },
    rowIndicator: {
        display: 'inline-block',
        width: '4px',
        marginRight: '16px',
        height: '40px',
        backgroundColor: '#00aead',
    },
    root: {
        width: 'calc(100% - 40px)',
        margin: '0px 20px',
        marginTop: '10px',
        borderRadius: 0,
        boxShadow: 'none',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: 'calc(100% / 6)',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    currentCell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        paddingLeft: '0px !important',
        width: 'calc(100% / 6)',
    },
    tableRows: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.07) !important',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    tableRows1: {
        backgroundColor: '#F0FFFF',
    },
    pointer: {
        cursor: 'pointer',
    },
    editIcon: {
        height: '30px',
        width: '30px',
        color: '#5F7B96',
        marginLeft: 5,
    },
    body: {
        maxHeight: 'calc( 100vh - 176px )',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        borderRadius: 5,
        overflowX: 'auto',
        overflowY: 'auto',
        backgroundColor: '#fff',
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    noWrap: NO_WRAP,
    sortIcon: {
        fontSize: 10,
        marginLeft: 10,
        color: '#9B9B9B',
    },
    noDataText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '88px',
    },
    center: {
        display: 'flex',
        placeContent: 'center',
    },
    moreIcons: {
        ...MUI_ACTION_MENU_ICON,
        marginLeft: 5,
        color: '#5F7B96',
    },
    iconButton: {
        width: '30px',
        height: '30px',
    },
    wrapperAccountNameItems: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    wrapperAccountLength: {
        backgroundColor: '#AAD3F7',
        minWidth: '22px',
        height: '20px',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    accountLength: {
        fontSize: '12px',
        color: '#4A4A4A',
        paddingTop: '2px',
        fontWeight: 'bold',
        margin: 0,
    },
    accountNameText: {
        paddingLeft: '5px',
    },
    WrapperPaddingLeft: {
        paddingLeft: '10px',
        width: '100%',
    },
    width100per: {
        paddingLeft: '10px',
        width: '100%',
    },
    typography: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
        display: 'inline-block',
    },
    userTableRow: {
        height: '40px',
        background: TABLES_CSS.subHeaderRowColor,
    },
    userTypography: {
        fontSize: TABLES_CSS.headingFontSize,
        color: TABLES_CSS.headingFontColor,
        fontWeight: TABLES_CSS.headingFontWeight,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapperAvatarUsername: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: 'calc(100% - 70px)',
        overflow: 'hidden',
    },
    avatar: {
        margin: '0px 10px 0px 0px',
        color: 'rgb(255, 255, 255)',
        backgroundColor: '#1babab',
        width: '25px',
        height: '25px',
        fontSize: '14px',
    },
    username: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
        display: 'inline-block',
    },
    activeStatus: {
        height: '19px',
        width: '55px',
        minWidth: '55px',
        fontFamily: 'Roboto',
        borderRadius: '8px',
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center',
        color: 'rgb(255, 255, 255)',
        paddingTop: 2,
        marginRight: '10px',
    },
    userActiveColor: {
        backgroundColor: '#1babab',
    },
    dateTypography: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
        display: 'inline-block',
        paddingTop: 4,
    },
    userRole: {
        color: '#000000',
    },
    wrapperEditUserIcon: {
        display: 'flex',
        alignItems: 'left',
    },
    noUserText: {
        fontSize: 14,
        textAlign: 'center',
    },
    container: {
        minHeight: 'calc(100vh - 212px)',
    },
    deleteIcon: {
        marginLeft: 8,
        marginRight: '9px !important',
        fontSize: '18px',
    },
    paddingLeft0: {
        paddingLeft: '0px !important',
    },
    expandedRowContainer: {
        paddingLeft: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContext: 'flex-start',
    },
    menuItemCont: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    disabledColor: {
        backgroundColor: '#BAC4CE',
    },
});
let clearError;
let clearSuccess;
class GroupList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedRow: null,
            data: [],
            order: 'asc',
            orderBy: 'name',
            page: 0,
            rowsPerPage: 20,
            selectedGroups: [],
            userOrder: 'asc',
            userOrderBy: 'name',
        };
        this.colsWidth = {};
        props.updateTitle('Groups');
    }

    componentDidMount() {
        this.fetchUsers();
        if (this.props.user && this.props.user.accountId) {
            this.props.getGroups(this.props.user.accountId);
        }
        clearError = this.props.clearError;
        clearSuccess = this.props.clearSuccess;
        const { headers } = this.props;
        for (let i = 0, j = 0; i < headers.length && j < subHeaders.length; i++) {
            const header = headers[i];
            if (header.colSpan && header.colSpan > 1) {
                for (let k = j; k < j + header.colSpan; k++) {
                    const subHeader = subHeaders[k];
                    subHeader.width = `calc(${header.width} / ${header.colSpan})`;
                    subHeader.parentIndex = i;
                }
                j += header.colSpan;
            } else {
                header.colSpan = 1;
                const subHeader = subHeaders[j];
                subHeader.width = header.width;
                subHeader.parentIndex = i;
                j++;
            }
        }
    }

    componentDidUpdate() {
        resizeAllCols(this.colsWidth);
    }

    static getDerivedStateFromProps(nextProps) {
        const { toggleSnackBar, error, success, user, userGroups } = nextProps;
        if (error) {
            toggleSnackBar(error, '');
            clearError();
        } else if (success) {
            toggleSnackBar(success, '', true);
            clearSuccess();
            this.fetchUsers();
            if (user && user.accountId) {
                nextProps.getGroups(user.accountId);
            }
        }
        const groups = userGroups && userGroups.Groups ? userGroups.Groups : [];
        const data = groups && groups.length ? groups.map((group) => createData(group)) : [];
        return { data };
    }

    componentWillUnmount() {
        this.props.queryRemove();
        this.props.clearSnackBar();
    }

    getAccountNameById = (id) => {
        const { accounts } = this.props;
        if (checkArrayLength(accounts)) {
            const account = accounts.find((acc) => acc.accountId === id);
            return checkKeyInObject(account, 'accountName', 'value', 'N/A');
        }
        return 'N/A';
    };

    clearSelectedGroups = () => this.setState({ selectedGroups: [] });

    fetchUsers = () => {
        const {
            user: { accountId, name },
        } = this.props;
        if (`${accountId}` === '1' && name === USER_NAME) {
            this.props.getUsers();
        } else {
            this.props.getUserByAccountId(accountId);
        }
    };

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    closeModal = () => {
        this.props.toggleModal();
        setTimeout(() => {
            this.props.clearGeneralModal();
        }, 500);
    };

    handleClick = (event, id) => {
        let selected = [...this.state.selectedGroups];
        const selectedIndex = selected.indexOf(id);

        if (selectedIndex === -1) {
            selected.push(id);
        } else if (selectedIndex >= 0) {
            selected = selected.slice(0, selectedIndex).concat(selected.slice(selectedIndex + 1));
        }

        this.setState({ selectedGroups: selected });
    };

    handleChangePage = (page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    // handleSelectAllClick = (event, currentPageData) => {
    //     const checked = event.target.checked;
    //     this.selectDeselectAll(checked, currentPageData);
    // };

    // selectDeselectAll = (checked, currentPageData) => {
    //     let selected = [...this.state.selectedGroups];
    //     currentPageData.forEach(group => {
    //         const index = selected.indexOf(group.id);
    //         if (checked && index === -1) {
    //             selected.push(group.id);
    //         } else if (!checked && index >= 0) {
    //             selected = selected.slice(0, index).concat(selected.slice(index + 1));
    //         }
    //     });
    //     this.setState({ selectedGroups: selected });
    // };

    handleCloseAlertDialog = () => {
        this.setState({ openAlertDialog: false });
    };

    toggleDeleteModal = () => {
        this.setState({ openAlertDialog: true });
    };

    // isSelected = (id) => this.state.selectedGroups.indexOf(id) !== -1;

    handleRowClick(rowId) {
        const currentExpandedRow = this.state.expandedRow;
        const isRowCurrentlyExpanded = currentExpandedRow === rowId;
        this.setState({ expandedRow: !isRowCurrentlyExpanded ? rowId : null });
    }

    handleRequestUserSort = (property) => {
        const userOrderBy = property;
        let userOrder = 'desc';
        if (this.state.userOrderBy === property && this.state.userOrder === 'desc') {
            userOrder = 'asc';
        }
        this.setState({ userOrder, userOrderBy });
    };

    enableDisableGroups = (groupIds, status) => {
        const {
            enableDisableGroups,
            getGroups,
            user: { accountId },
        } = this.props;
        const { data } = this.state;
        const ids = [];
        const _groupIds = checkArrayLength(groupIds) ? groupIds : this.state.selectedGroups;
        _groupIds.forEach((id) => {
            const grp = data.find((group) => group.id === id);
            if (grp && `${id}` === `${grp.id}`) {
                if ((!grp.disabled_status ? 1 : 0) === status) {
                    ids.push(id);
                }
            }
        });
        const onComplete = () => {
            getGroups(accountId);
        };
        enableDisableGroups({ group_ids: ids, status }, onComplete);
    };

    updateGroup = (group) => {
        const {
            tabsData,
            getGroups,
            user: { accountId },
        } = this.props;
        if (checkKeyInObject(group, 'id', 'bool', false)) {
            const obj = {
                group_id: group.id,
                name: tabsData.adminAccountInfo.state.name,
                description: tabsData.adminAccountInfo.state.description,
                start_date: `${moment(tabsData.adminAccountInfo.state.selectedDateStart).format('YYYY-MM-DD')}T00:00:00Z`,
                end_date: `${moment(tabsData.adminAccountInfo.state.selectedDateEnd).format('YYYY-MM-DD')}T00:00:00Z`,
            };
            const disableGroupUsers = []; // disable_group_users
            let newUsers = []; // new_users
            const prevUsersIds = checkArrayLength(checkKeyInObject(group, 'users', 'value', false)) ? group.users.map((user) => user.id) : [];

            if (checkKeyInObject(tabsData, 'associateUsersToGroup', 'bool', false)) {
                prevUsersIds.forEach((prev) => {
                    if (!tabsData.associateUsersToGroup.state.selectedUserIds.includes(prev)) {
                        disableGroupUsers.push(prev);
                    }
                });
                newUsers = [...new Set([...newUsers, ...tabsData.associateUsersToGroup.state.selectedUserIds])];
            }
            const userObj = { disable_group_users: [...disableGroupUsers], new_users: [...newUsers] };
            const finalObj = { ...obj, ...userObj };
            this.props.updateGroup(finalObj, () => {
                getGroups(accountId);
            });
            this.closeModal();
        }
    };

    isSubmitDisable = (tabsData) => {
        return (
            Boolean(
                checkKeyInObject(tabsData, 'adminAccountInfo.state.isDuplicateName', 'value', false) ||
                    !checkKeyInObject(tabsData, 'adminAccountInfo.state.name', 'value', false),
            ) ||
            !checkKeyInObject(tabsData, 'adminAccountInfo.state.selectedDateStart') ||
            !checkKeyInObject(tabsData, 'adminAccountInfo.state.selectedDateEnd') ||
            new Date(tabsData.adminAccountInfo.state.selectedDateStart) >= new Date(tabsData.adminAccountInfo.state.selectedDateEnd)
        );
    };

    currentRowIndicator = () => {
        const { classes } = this.props;
        return <span className={classes.rowIndicator}></span>;
    };

    renderMenuItems = (index, group) => {
        const { classes, toggleModal, changeTab, currentTab } = this.props;
        const items = [
            {
                action: () => {
                    toggleModal('GeneralModal', null, null, {
                        title: 'Update Group',
                        dialogContentStyle: classes.dialogContentStyle,
                        closeIconAction: this.closeModal,
                        component: [
                            {
                                name: 'Group Info',
                                content: <AdminAccountInfo isUpdateGroup group={group} isForAccount={false} />,
                                buttons: [
                                    NEXT_SUBMIT_BUTTON({
                                        name: 'Next',
                                        action: () => {
                                            changeTab(currentTab + 1);
                                        },
                                        isDisabled: (/* tabsData */) => {
                                            return false;
                                        },
                                    }),
                                    CANCEL_BUTTON({ action: this.closeModal }),
                                    SAVE_OUTLINE_BUTTON({
                                        action: () => {
                                            this.updateGroup(group);
                                        },
                                        isDisabled: this.isSubmitDisable,
                                    }),
                                ],
                            },
                            {
                                name: 'Users',
                                content: <AssociationModal group={group} isUpdate />,
                                buttons: [
                                    NEXT_SUBMIT_BUTTON({
                                        name: 'Update',
                                        action: () => {
                                            this.updateGroup(group);
                                        },
                                        isDisabled: this.isSubmitDisable,
                                    }),
                                    CANCEL_BUTTON({ action: this.closeModal }),
                                ],
                            },
                        ],
                    });
                },
                icon: (
                    <FontAwesomeIcon
                        className={`${classes.moreIcons} ${classes.deleteIcon}`}
                        style={{ fontSize: 16 }}
                        icon={faPencilAlt}
                        aria-label="faPencilAlt"
                        id="faPencilAlt"
                    />
                ),
                isDisabled: group.disabled_status,
                name: ' Update Group',
            },
            {
                action: () =>
                    toggleModal('groupModal', null, null, {
                        modaltype: 'EnableDisable',
                        groupData: {
                            groupIds: [group.id],
                            groupNames: [group.name],
                            members: [group.users ? group.users.length : 0],
                            _multiple: false,
                            action: `${group.disabled_status ? 'enable' : 'disable'}`,
                            submitFunction: () => this.enableDisableGroups([group.id], !group.disabled_status ? 1 : 0),
                        },
                        clearSelectedGroups: this.clearSelectedGroups,
                    }),
                icon: group.disabled_status ? (
                    <Restore className={classes.moreIcons} style={{ marginRight: 8 }} aria-label="restoreIcon" id="restoreIcon" />
                ) : (
                    <Disable aria-label="disableIcon" id="disableIcon" className={classes.moreIcons} />
                ),
                isDisabled: false,
                name: `${group.disabled_status ? ' Enable Group' : ' Disable Group'}`,
            },
            {
                action: () =>
                    toggleModal('groupModal', null, null, {
                        modaltype: 'Delete',
                        groupData: {
                            groupIds: [group.id],
                            groupNames: [group.name],
                            members: [group.users ? group.users.length : 0],
                            _multiple: false,
                            action: 'delete',
                        },
                        clearSelectedGroups: this.clearSelectedGroups,
                    }),
                icon: (
                    <FontAwesomeIcon
                        className={`${classes.moreIcons} ${classes.deleteIcon}`}
                        icon={faTrashAlt}
                        aria-label="faTrashAlt"
                        id="faTrashAlt"
                    />
                ),
                isDisabled: false,
                name: ' Delete Group',
            },
        ];

        return items;
    };

    renderItem(index, groups) {
        const group = groups[index];
        const { expandedRow, userOrderBy, userOrder } = this.state;
        const { classes, headers } = this.props;
        const clickCallback = () => this.handleRowClick(group.id);
        // const isSelected = this.isSelected(group.id);
        const conditionalColor = group.disabled_status ? '#BAC4CE' : '#000000';
        const itemRows = [
            <TableRow
                hover
                tabIndex={-1}
                key={group.id}
                className={classes.tableRows}
                style={{ height: '40px', backgroundColor: TABLES_CSS.rowColor }}
            >
                <TableCell
                    className={`${classes.noWrap} ${classes.minWidth} ${expandedRow === group.id ? classes.currentCell : classes.cell}`}
                    width={headers[0].width}
                    data-resize={`element${0}_c`}
                >
                    <div className={`${classes.width100per} ${classes.paddingLeft0}`}>
                        <div className={classes.flexCenter}>
                            <div className={classes.wrapperAccountNameItems}>
                                <div className={`${classes.width100per} ${classes.expandedRowContainer}`}>
                                    {expandedRow === group.id ? this.currentRowIndicator() : null}
                                    {/* <IconButton
                                        onClick={(event) => this.handleClick(event, group.id)}
                                        variant="contained"
                                        color="primary"
                                        className={classes.checkBox}
                                        tabIndex={-1}
                                    >
                                        {isSelected ?
                                            <CheckBox
                                                className={classes.checkBoxIcon}
                                                aria-label="checkBoxIcon" id="checkBoxIcon"
                                            /> :
                                            <CheckBoxBlank
                                                className={classes.checkBoxIcon}
                                                style={{ color: '#bac5d0' }}
                                                aria-label="checkBoxBlankIcon"
                                                id="checkBoxBlankIcon"
                                            />
                                        }
                                    </IconButton> */}
                                    <span className={`${classes.wrapperAccountLength} ${group.disabled_status ? classes.disabledColor : ''}`}>
                                        <Typography className={`${classes.noWrap} ${classes.accountLength}`}>
                                            {group.users ? group.users.length : 0}
                                        </Typography>
                                    </span>
                                    <Typography
                                        className={`${classes.noWrap} ${classes.typography} ${classes.accountNameText}`}
                                        style={{ color: conditionalColor }}
                                    >
                                        <Tooltip data={group.name ? group.name : null}>{group.name}</Tooltip>
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </TableCell>
                <TableCell className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`} width={headers[1].width} data-resize={`element${1}_c`}>
                    <div className={`${classes.width100per} ${classes.WrapperPaddingLeft}`}>
                        <Typography className={`${classes.noWrap} ${classes.typography}`} style={{ color: conditionalColor }}>
                            <Tooltip data={group.description}>{group.description ? group.description : 'No Description'}</Tooltip>
                        </Typography>
                    </div>
                </TableCell>
                <TableCell className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`} width={headers[2].width} data-resize={`element${2}_c`}>
                    <div className={`${classes.width100per} ${classes.WrapperPaddingLeft}`}>
                        <Typography className={`${classes.noWrap} ${classes.typography}`} style={{ color: conditionalColor }}>
                            <Tooltip data={group.start_date}>{getFormatedDate(group.start_date, 'MMM D, YYYY', '-')}</Tooltip>
                        </Typography>
                    </div>
                </TableCell>
                <TableCell className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`} width={headers[3].width} data-resize={`element${3}_c`}>
                    <div className={`${classes.width100per} ${classes.WrapperPaddingLeft}`}>
                        <Typography className={`${classes.noWrap} ${classes.typography}`} style={{ color: conditionalColor }}>
                            <Tooltip data={group.end_date}>{getFormatedDate(group.end_date, 'MMM D, YYYY', '-')}</Tooltip>
                        </Typography>
                    </div>
                </TableCell>
                <TableCell className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`} width={headers[4].width} data-resize={`element${4}_c`}>
                    <div className={`${classes.width100per} ${classes.WrapperPaddingLeft}`}>
                        <Typography className={`${classes.noWrap} ${classes.typography}`} style={{ color: conditionalColor }}>
                            {checkKeyInObject(group, 'isRelativeDateFormat')
                                ? getRelativeTime(group.last_login)
                                : getFormatedDate(group.last_login, 'MMM D, YYYY', '-')}
                        </Typography>
                    </div>
                </TableCell>
                <TableCell className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`} width={headers[5].width} data-resize={`element${5}_c`}>
                    <div className={`${classes.width100per} ${classes.WrapperPaddingLeft} ${classes.menuItemCont}`}>
                        <MenuOptions
                            items={this.renderMenuItems(index, group)}
                            keepMounted
                            // iconButtonDisabled={isSelected}
                            // MoreVertStyle={isSelected ? { color: '#BAC4CE' } : {}}
                        />
                        <ExpandBtn flag={expandedRow === group.id} action={clickCallback} />
                    </div>
                </TableCell>
            </TableRow>,
        ];

        if (expandedRow === group.id) {
            itemRows.push(
                <TableRow className={classes.userTableRow} key={`${group.id}-subHeader`}>
                    {subHeaders.map((column, ind) => (
                        <TableCell
                            width={column.width}
                            data-sub-header="subHeader"
                            data-child-resize={`${ind}_${column.parentIndex}_${headers[column.parentIndex].colSpan}`}
                            className={`${classes.minWidth} ${classes.cell}`}
                            key={`user-${column.id}`}
                        >
                            {column.sortable ? (
                                <div
                                    aria-hidden
                                    onClick={() => this.handleRequestUserSort(column.id)}
                                    className={`${classes.width100per} ${classes.pointer}`}
                                >
                                    <Typography variant="body2" className={classes.userTypography}>
                                        {column.label}
                                        {userOrderBy === column.id ? (
                                            userOrder === 'desc' ? (
                                                <FontAwesomeIcon
                                                    icon={faChevronDown}
                                                    aria-label="faChevronDown"
                                                    id="faChevronDown"
                                                    className={classes.sortIcon}
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faChevronUp}
                                                    aria-label="faChevronUp"
                                                    id="faChevronUp"
                                                    className={classes.sortIcon}
                                                />
                                            )
                                        ) : null}
                                    </Typography>
                                </div>
                            ) : (
                                <div className={classes.nonSortableContainer} key={column.id}>
                                    <Typography variant="body2" className={classes.userTypography}>
                                        {column.label}
                                    </Typography>
                                </div>
                            )}
                        </TableCell>
                    ))}
                </TableRow>,
            );
        }

        if (expandedRow === group.id) {
            const filteredUser = sort(
                group.users,
                userOrder,
                userOrderBy,
                userOrderBy === 'Time' || userOrderBy === 'startDate' || userOrderBy === 'endDate' ? 'time' : '',
            );
            if (checkArrayLength(filteredUser)) {
                filteredUser.map((user, i) =>
                    itemRows.push(
                        <TableRow
                            hover
                            tabIndex={-1}
                            style={{
                                height: '40px',
                                display: expandedRow === group.id ? 'table-row' : 'none',
                            }}
                            className={classes.tableRows1}
                            key={`row-expanded-${user.name}-${i}`}
                        >
                            <TableCell
                                padding="none"
                                className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                width={subHeaders[0].width}
                                data-child-resize={`${0}_${subHeaders[0].parentIndex}_${headers[subHeaders[0].parentIndex].colSpan}`}
                            >
                                <div className={classes.width100per}>
                                    <div className={classes.flexCenter}>
                                        <div className={classes.wrapperAccountNameItems}>
                                            <span className={classes.wrapperAvatarUsername}>
                                                <Avatar className={classes.avatar}>
                                                    {user && user.name && user.name.length ? user.name[0].toUpperCase() : ''}
                                                </Avatar>
                                                <Typography className={`${classes.username} ${classes.noWrap}`}>
                                                    <Tooltip data={user.name}>{user.name}</Tooltip>
                                                </Typography>
                                            </span>
                                            <span className={`${classes.activeStatus} ${classes.userActiveColor}`}>
                                                {user.deleted === false ? 'Active' : 'Inactive'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                width={subHeaders[1].width}
                                data-child-resize={`${1}_${subHeaders[1].parentIndex}_${headers[subHeaders[1].parentIndex].colSpan}`}
                            >
                                <div className={classes.width100per}>
                                    <Typography className={`${classes.dateTypography} ${classes.noWrap}`}>{user.email}</Typography>
                                </div>
                            </TableCell>
                            <TableCell
                                className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                width={subHeaders[2].width}
                                data-child-resize={`${2}_${subHeaders[2].parentIndex}_${headers[subHeaders[2].parentIndex].colSpan}`}
                            >
                                <div className={classes.width100per}>
                                    <Typography className={`${classes.dateTypography} ${classes.noWrap}`}>
                                        {getFormatedDate(makeLocalDate(user.startDate), 'MMM D, YYYY', '-')}
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell
                                className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                width={subHeaders[3].width}
                                data-child-resize={`${3}_${subHeaders[3].parentIndex}_${headers[subHeaders[3].parentIndex].colSpan}`}
                            >
                                <div className={classes.width100per}>
                                    <Typography className={`${classes.dateTypography} ${classes.noWrap}`}>
                                        {getFormatedDate(makeLocalDate(user.endDate), 'MMM D, YYYY', '-')}
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell
                                className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                width={subHeaders[4].width}
                                data-child-resize={`${4}_${subHeaders[4].parentIndex}_${headers[subHeaders[4].parentIndex].colSpan}`}
                            >
                                <div className={classes.width100per}>
                                    <Typography className={`${classes.dateTypography} ${classes.noWrap}`}>
                                        {checkKeyInObject(user, 'isRelativeDateFormat')
                                            ? getRelativeTime(checkKeyInObject(user, '_.Time', 'value', '-'))
                                            : getFormatedDate(checkKeyInObject(user, '_.Time', 'value', ''), 'MMM D, YYYY', '-')}
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell
                                className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                width={subHeaders[5].width}
                                data-child-resize={`${5}_${subHeaders[5].parentIndex}_${headers[subHeaders[5].parentIndex].colSpan}`}
                            >
                                <div className={classes.width100per}>
                                    <div className={classes.flexCenter}>
                                        <span
                                            className={`${classes.activeStatus} ${classes.userRole}`}
                                            style={{
                                                backgroundColor:
                                                    user.role === 'AIQSA'
                                                        ? '#A2D2F2'
                                                        : user.role === 'ADMIN'
                                                        ? '#F3E0BC'
                                                        : user.role === 'SDET'
                                                        ? '#92EABD'
                                                        : '',
                                            }}
                                        >
                                            {user.role}
                                        </span>
                                        {/* Update Single User */}
                                        {/* <div className={classes.wrapperEditUserIcon}>
                                                                            {showAction(activeUser, user) ? (
                                                                                <IconButton
                                                                                    color="inherit"
                                                                                    aria-label="edit user"
                                                                                    id="Edituser"
                                                                                    name="Edituser"
                                                                                    onClick={this.toggleModal('Edit', user, account)}
                                                                                    classes={{ root: classes.iconButton }}
                                                                                > */}
                                        {/* TODO: Uncomment line and check if icon size is proper */}
                                        {/* <FontAwesomeIcon icon={faPencilAlt} aria-label="faPencilAlt" id="faPencilAlt" className={classes.moreIconsBtn} /> */}
                                        {/* <Edit className={classes.moreIcons} aria-label="editIcon" id="editIcon" />
                                                                                </IconButton>
                                                                            ) : null}
                                                                        </div> */}
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                width={subHeaders[6].width}
                                data-child-resize={`${6}_${subHeaders[6].parentIndex}_${headers[subHeaders[6].parentIndex].colSpan}`}
                            >
                                <div className={`${classes.username} ${classes.noWrap} ${classes.width100per}`}>
                                    {this.getAccountNameById(user.userAccount)}
                                </div>
                            </TableCell>
                        </TableRow>,
                    ),
                );
            } else {
                itemRows.push(
                    <TableRow
                        hover
                        tabIndex={-1}
                        key={`row-expanded-${group.id}-empty`}
                        style={{
                            height: '40px',
                            display: expandedRow === group.id ? 'table-row' : 'none',
                        }}
                        className={`${classes.tableRows1} ${classes.center}`}
                    >
                        <TableCell className={classes.cell} colSpan={6}>
                            <Typography className={classes.noUserText}>No user available</Typography>
                        </TableCell>
                    </TableRow>,
                );
            }
        }
        return itemRows;
    }

    render() {
        const { classes, query = '', /* toggleModal, */ headers, /* users, */ sideBarCount, isLoading, loadingComponent } = this.props;
        const {
            data,
            order,
            orderBy,
            rowsPerPage,
            page,
            openAlertDialog,
            // selectedGroups,
            expandedRow,
        } = this.state;

        let groups = data || [];
        groups = groups.map((group) => {
            return {
                ...group,
                last_login: checkArrayLength(group.users)
                    ? checkKeyInObject(sort(group.users, 'desc', 'Time', 'time')[0], '_.Time', 'value', '')
                    : '',
            };
        });

        // let enabledGroupIds = [];
        // let enabledGroupName = [];

        // let disabaledGroupIds = [];
        // let disabledGroupName = [];

        // let members = [];

        const allItemRows = [];

        // let _multiple = 0;

        // selectedGroups && selectedGroups.map(groupId => {
        //     const grp = groups.find((group) => group.id === groupId);
        //     if (grp) {
        //         if (!grp.disabled_status) {
        //             disabaledGroupIds.push(grp.id);
        //             disabledGroupName.push(grp.name);
        //         } else {
        //             enabledGroupIds.push(grp.id);
        //             enabledGroupName.push(grp.name);
        //         }
        //         _multiple++;
        //         members.push(grp.users ? grp.users.length : 0);
        //     }
        // });

        // _multiple = _multiple > 1;

        if (query && checkArrayLength(groups)) {
            groups = groups.filter((group) => checkKeyInObject(group, 'name') && group.name.toLowerCase().includes(query.toLowerCase()));
        }

        const sortedGroups = sort(
            groups,
            order,
            orderBy,
            orderBy === 'last_login' || orderBy === 'start_date' || orderBy === 'end_date' ? 'time' : orderBy === 'users' ? 'len' : '',
        );

        const currentPageData = sortedGroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        // let _selected = 0;
        const _total = currentPageData.length;

        // currentPageData.forEach(group => {
        //     const index = selectedGroups.indexOf(group.id);
        //     if (index >= 0) {
        //         _selected++;
        //     }
        // });

        // let inActiveCount = 0;
        // for (let i = 0; i < headers.length; i++) {
        //     if (headers[i].status == "InActive") {
        //         inActiveCount = inActiveCount + (headers[i].colSpan ? headers[i].colSpan : 1);
        //     }
        // }

        for (let i = 0; i < currentPageData.length; i++) {
            allItemRows.push(this.renderItem(i, currentPageData));
        }

        return (
            <div>
                <div className={classes.root}>
                    <div className={classes.container}>
                        <div className={classes.body}>
                            {currentPageData.length ? (
                                <Table>
                                    <Hidden only={['xs']}>
                                        <EnhancedTableHead
                                            headers={headers}
                                            currentPageData={currentPageData}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={this.handleRequestSort}
                                            // onSelectAllClick={(e) => { this.handleSelectAllClick(e, currentPageData) }}
                                            // numSelected={_selected}
                                            rowCount={_total}
                                            // showSelectBtn={true}
                                            // inActiveCount={inActiveCount}
                                            isColSpan={expandedRow}
                                            colsWidth={this.colsWidth}
                                            resizeable
                                        />
                                    </Hidden>
                                    <Hidden smUp>
                                        <EnhancedTableHead
                                            headers={headers}
                                            currentPageData={currentPageData}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={this.handleRequestSort}
                                            // onSelectAllClick={(e) => { this.handleSelectAllClick(e, currentPageData) }}
                                            // numSelected={_selected}
                                            rowCount={_total}
                                            // showSelectBtn={true}
                                            // inActiveCount={inActiveCount}
                                            isColSpan={expandedRow}
                                            colsWidth={this.colsWidth}
                                            resizeable
                                        />
                                    </Hidden>
                                    <TableBody style={{ maxHeight: 'calc(100vh - 259px)' }}>{allItemRows}</TableBody>
                                </Table>
                            ) : isLoading ? (
                                loadingComponent
                            ) : (
                                <Typography className={classes.noDataText} variant="subtitle1">
                                    No Group available
                                </Typography>
                            )}
                        </div>
                    </div>
                    <TableFooter
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        page={page}
                        handleChangePage={this.handleChangePage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        applyCustomClasses
                        labelRowsPerPage="Groups per page:"
                        labelForCounter="Groups"
                        countAvatar={getCountAvatar(sideBarCount.num_of_groups)}
                        containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                    />
                    {openAlertDialog && <AlertModal open={openAlertDialog} handleCloseAlertDialog={this.handleCloseAlertDialog} />}
                </div>
            </div>
        );
    }
}

GroupList.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    toggleModal: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    toggleSnackBar: PropTypes.func.isRequired,
    queryRemove: PropTypes.func.isRequired,
    query: PropTypes.string,
};

GroupList.defaultProps = {
    query: '',
};

const mapStateToProps = (state) => {
    return {
        sideBarCount: state.sidebarReducer.sideBarCount,
        userGroups: state.userReducer.userGroups,
        users: state.userReducer.users,
        headers: state.userReducer.rows,
        success: state.userReducer.success,
        user: state.authReducer.user,

        // general reducer
        query: state.generalReducer.queryValue,
        currentTab: state.generalModalReducer.currentTab,
        tabsData: state.generalModalReducer.tabsData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: () => dispatch(UserActions.getUsers()),
        updateGroup: (...args) => dispatch(UserActions.updateGroup(...args)),
        getUserByAccountId: (accountId) => dispatch(UserActions.getUserByAccountId(accountId)),
        getGroups: (accountId) => dispatch(UserActions.getGroups(accountId)),
        clearError: () => dispatch(UserActions.clearError()),
        clearSuccess: () => dispatch(UserActions.clearSuccess()),
        enableDisableGroups: (...args) => dispatch(UserActions.enableDisableGroups(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        clearSnackBar: () => dispatch(ModalActions.clearSnackbar()),
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        clearGeneralModal: (...args) => dispatch(generalModalActions.clearGeneralModal(...args)),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
        changeTab: (...args) => dispatch(generalModalActions.changeTab(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupList));
