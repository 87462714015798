import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// icon(s)
import YoutubeIcon from '../../assets/images/youtubeIcon.png';
import Tooltip from '../Tooltip';

const styles = () => ({
    trayIcon: {
        fontSize: 16,
        margin: 4,
    },
    enabledIcon: {
        color: '#4A4A4A',
        cursor: 'pointer',
    },
    disabledIcon: {
        color: '#d9d9d9',
        cursor: 'not-allowed',
    },
    ytIcon: {
        marginRight: '46.5px',
        marginTop: '3px',
        height: '18px',
        width: '18px',
    },
    videoIcon: {
        marginTop: '6px',
        height: '18px',
        width: '18px',
    },
});

function showIconWithAction(props) {
    // videoUrl will be isRequired
    const { videoUrl = '', caseName = '', showExecutionVideo, disabled, classes } = props;
    const isDisabled = videoUrl.length === 0 || disabled;
    if (isDisabled) {
        return <img src={YoutubeIcon} id="ondemandVideo" alt="Video" className={classes.ytIcon} />;
    }
    return (
        <Tooltip data="Play video">
            <img id="ondemandVideo" src={YoutubeIcon} onClick={() => showExecutionVideo(videoUrl, caseName)} alt="Video" className={ props.alignVideoIcon ? `${classes.videoIcon}` : `${classes.ytIcon}`  } />
        </Tooltip>
    );
}

function Video(props) {
    return showIconWithAction(props);
}

Video.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    videoUrl: PropTypes.string,
    executionId: PropTypes.number,
    disabled: PropTypes.bool.isRequired,
    showExecutionVideo: PropTypes.func,
};

export default withStyles(styles)(Video);
