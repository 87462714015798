import { ActionTypes } from '../constants';

const initialState = {
    users: [],
    usersbyId: [],
    usersbyEmail: [],
    userGroups: [],
    isLoading: false,
    isLoadingUsers: false,
    success: '',
    error: '',
    expandAdmin: false,
    expandPlan: false,
    expandExecutions: false,
    expandAnalyze: false,
    expandExecute: false,
    showlastSignIn: true,
    showStatus: true,
    userDetails: {},
    rows: [
        {
            id: 'name',
            sortable: true,
            numeric: false,
            disablePadding: true,
            status: 'Lock',
            label: 'Groups',
            styles: { paddingLeft: 10 },
            width: 'calc(calc(100% / 9) * 2)',
        },
        {
            id: 'description',
            sortable: true,
            numeric: false,
            disablePadding: true,
            status: 'Active',
            label: 'Description',
            styles: { paddingLeft: 10 },
            width: 'calc(calc(100% / 9) * 2)',
        },
        {
            id: 'start_date',
            sortable: true,
            numeric: false,
            disablePadding: true,
            label: 'Start Date',
            styles: { paddingLeft: 10 },
            width: 'calc(100% / 9)',
        },
        {
            id: 'end_date',
            sortable: true,
            numeric: false,
            disablePadding: true,
            label: 'End Date',
            styles: { paddingLeft: 10 },
            width: 'calc(100% / 9)',
        },
        {
            id: 'last_login',
            sortable: true,
            numeric: false,
            disablePadding: true,
            label: 'Last Login',
            styles: { paddingLeft: 10 },
            width: 'calc(100% / 9)',
        },
        {
            id: 'funcs',
            sortable: false,
            numeric: false,
            colSpan: 2,
            disablePadding: true,
            status: 'Active',
            label: 'Actions',
            styles: { paddingLeft: 10 },
            width: 'calc(calc(100% / 9) * 2)',
        },
    ],
    userTableArrangment: [
        {
            id: 'name',
            sortable: true,
            numeric: false,
            disablePadding: true,
            label: 'User',
            styles: { paddingLeft: 10 },
            width: 'calc(calc(100% / 21) * 4)',
        },
        {
            id: 'email',
            sortable: true,
            numeric: false,
            disablePadding: true,
            label: 'Email',
            styles: { paddingLeft: 10 },
            width: 'calc(calc(100% / 21) * 4)',
        },
        {
            id: 'startDate',
            sortable: true,
            numeric: false,
            disablePadding: true,
            label: 'Start Date',
            styles: { paddingLeft: 10 },
            width: 'calc(calc(100% / 21) * 2)',
        },
        {
            id: 'endDate',
            sortable: true,
            numeric: false,
            disablePadding: true,
            label: 'End Date',
            styles: { paddingLeft: 10 },
            width: 'calc(calc(100% / 21) * 2)',
        },
        {
            id: 'lastLogin',
            sortable: true,
            numeric: false,
            disablePadding: true,
            label: 'Last Login',
            styles: { paddingLeft: 10 },
            width: 'calc(calc(100% / 21) * 3)',
        },
        {
            id: 'role',
            sortable: true,
            numeric: false,
            disablePadding: true,
            label: 'Role',
            styles: { paddingLeft: 10 },
            width: 'calc(calc(100% / 21) * 2)',
        },
        {
            id: 'account',
            sortable: false,
            numeric: false,
            disablePadding: true,
            label: 'Account',
            styles: { paddingLeft: 10 },
            width: 'calc(calc(100% / 21) * 2)',
        },
        {
            id: 'funcs',
            sortable: false,
            numeric: false,
            disablePadding: true,
            label: 'Actions',
            styles: { paddingLeft: 10 },
            width: 'calc(calc(100% / 21) * 2)',
        },
    ],
};
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };
        case ActionTypes.CLEAR_SUCCESS:
            return {
                ...state,
                success: null,
            };
        case ActionTypes.GET_USER:
            return {
                ...state,
            };
        case ActionTypes.GET_USER_SUCCESS:
            return {
                ...state,
                userDetails: action.payload,
            };

        case ActionTypes.GET_USERS:
            return {
                ...state,
                isLoadingUsers: true,
            };
        case ActionTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                isLoadingUsers: false,
                users: action.payload,
            };
        case ActionTypes.GET_USERS_FAILURE:
            return {
                ...state,
                isLoadingUsers: false,
                error: action.payload.message,
            };

        case ActionTypes.GET_USERS_BY_ID_SUCCESS:
        case ActionTypes.GET_USERS_BY_EMAIL_SUCCESS:
            return {
                ...state,
                users: action.data.data,
            };
        case ActionTypes.GET_GROUPS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ActionTypes.CREATE_USER_FAILURE: 
        return {
            ...state,
            error: action.payload._error,
            success: null,
        };
        case ActionTypes.CREATE_ACCOUNT_FAILURE:
            return {
                ...state,
                error: null,
                success: null,
            };
        case ActionTypes.UPDATE_USER_PERMISSIONS_SUCCESS:
            return {
                ...state,
                success: action.payload,
                error: null,
            };
        case ActionTypes.UPDATE_USER_PERMISSIONS_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
            };
        case ActionTypes.CREATE_USER_SUCCESS:
        case ActionTypes.CREATE_ACCOUNT_SUCCESS:
        case ActionTypes.UPDATE_USER_SUCCESS:
            return {
                ...state,
                success: action.payload.msg,
                error: null,
            };
        case ActionTypes.DELETE_USER_FAILURE:
        case ActionTypes.UPDATE_USER_FAILURE:
            return {
                ...state,
                error: action.payload.message,
                success: null,
            };
        case ActionTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                success: 'Delete successfully',
                error: null,
            };
        case ActionTypes.GET_GROUPS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                userGroups: action.payload,
            };
        }
        case ActionTypes.GET_GROUPS_FAILURE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ActionTypes.EXPAND_SIDEBAR_ITEM: {
            const obj = { ...state };
            switch (action.payload) {
                case 'admin':
                    obj.expandAdmin = !state.expandAdmin;
                    break;
                case 'plan':
                    obj.expandPlan = !state.expandPlan;
                    break;
                case 'analyze':
                    obj.expandAnalyze = !state.expandAnalyze;
                    break;
                case 'execute':
                    obj.expandExecute = !state.expandExecute;
                    break;
                default:
                    break;
            }
            return obj;
        }

        case ActionTypes.EXPAND_ADMIN_SIDEBAR:
            return {
                ...state,
                expandAdmin: !state.expandAdmin,
            };
        case ActionTypes.EXPAND_PLAN_SIDEBAR:
            return {
                ...state,
                expandPlan: !state.expandPlan,
            };
        case ActionTypes.EXPAND_EXECUTIONS_SIDEBAR:
            return {
                ...state,
                expandExecutions: !state.expandExecutions,
            };
        case ActionTypes.EXPAND_ANALYZE_SIDEBAR:
            return {
                ...state,
                expandAnalyze: !state.expandAnalyze,
            };
        case ActionTypes.COLLAPE_ALL_ITEMS_SIDEBAR:
            return {
                ...state,
                expandAnalyze: false,
                expandAdmin: false,
                expandExecutions: false,
                expandPlan: false,
                expandExecute: false,
            };
        case ActionTypes.HANDLE_TABLE_COL: {
            const userHeader = JSON.parse(JSON.stringify(state.userTableArrangment));
            userHeader.forEach((data) => {
                Object.keys(action.payload).forEach((id) => {
                    if (id === data.id) {
                        data.status = action.payload[id];
                    }
                });
            });
            return {
                ...state,
                userTableArrangment: userHeader,
            };
        }
        case ActionTypes.MANAGE_GROUP_HEADERS:
            return {
                ...state,
                rows: action.payload,
            };
        case ActionTypes.CREATE_GROUP_SUCCESS:
        case ActionTypes.UPDATE_GROUP_SUCCESS:
        case ActionTypes.DELETE_GROUPS_SUCCESS:
        case ActionTypes.ENABLE_DISABLE_GROUPS_SUCCESS:
        case ActionTypes.ASSOCIATE_USER_TO_GROUP_SUCCESS:
        case ActionTypes.DISSOCIATE_USER_TO_GROUP_SUCCESS:
        case ActionTypes.POST_USER_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                success: action.payload.message,
            };
        case ActionTypes.GET_USER_FAILURE:
        case ActionTypes.CREATE_GROUP_FAILURE:
        case ActionTypes.UPDATE_GROUP_FAILURE:
        case ActionTypes.DELETE_GROUPS_FAILURE:
        case ActionTypes.ENABLE_DISABLE_GROUPS_FAILURE:
        case ActionTypes.ASSOCIATE_USER_TO_GROUP_FAILURE:
        case ActionTypes.DISSOCIATE_USER_TO_GROUP_FAILURE:
        case ActionTypes.POST_USER_PROFILE_DATE_FAILURE:
            return {
                ...state,
                error: action.payload.message,
            };

        case ActionTypes.WEBSOCKET_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
