// import materials
import { withStyles } from '@material-ui/core/styles';

// import packages
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import custom components
import { ProjectActions, TaskAction, TestSuiteActions } from '../../../store/actions';
import SuiteTable from './SuiteTable';
import CustomButton from '../../../components/Button/CustomButton';

const styles = () => ({
    panelWrapper: {
        width: '100%',
    },
    expansionPanelSummary: {
        minHeight: '0px !important',
        '&>:first-child': {
            position: 'relative',
            margin: '13px 0px 0px 0px',
            cursor: 'default !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
    },
    expandIcon: {
        right: '10px',
    },
    expansionPanelDetails: {
        padding: '0 0px',
    },
    tableheaderRoot: {
        padding: 15,
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '5px 5px 0px 0px',
    },
});

class SuiteList extends Component {
    constructor(props) {
        super(props);
        this.createTestSuite = null;
        this.state = {
            expanded: 'SuiteList',
            showLoader: this.props.showLoader,
        };
    }

    UNSAFE_componentWillReceiveProps({ showLoader }) {
        if (showLoader !== this.setState.showLoader) {
            this.setState({ showLoader });
        }
    }

    handleChange = (panel) => () => {
        this.setState((state) => ({
            expanded: state.expanded !== panel ? panel : false,
        }));
    };

    generateTestSuite = () => {
        // eslint-disable-next-line no-console
        console.log('generateTestSuite clicked');
    };

    receiveFunctionFromChild = (fn) => {
        this.createTestSuite = fn;
    };

    render() {
        const { showLoader } = this.state;
        const {
            associateTestSuites,
            classes,
            date,
            getExecutionTasks,
            getTestSuitesByProject,
            project,
            query,
            testSuiteByProject,
            user,
            updateTestCaseId,
            testCaseDropDownData,
            deleteTestCaseFromSuite,
            handleStoreState,
            isClearData,
        } = this.props;
        return (
            <div>
                <div className={classes.tableheaderRoot}>
                    <CustomButton title="CREATE TEST SUITE" onClick = { this.createTestSuite } />
                </div>
                <div className={classes.panelWrapper}>
                    <SuiteTable
                        associateTestSuites={associateTestSuites}
                        date={date}
                        generateTestSuite={this.generateTestSuite}
                        getExecutionTasks={getExecutionTasks}
                        history={this.props.history}
                        updateTestCaseId={updateTestCaseId}
                        getTestSuitesByProject={getTestSuitesByProject}
                        projectSystemId={project.projectId}
                        query={query}
                        showLoader={showLoader}
                        testSuiteByProject={testSuiteByProject}
                        user={user}
                        testCaseDropDownData={testCaseDropDownData}
                        deleteTestCaseFromSuite={deleteTestCaseFromSuite}
                        handleTestSuitesCasesDirections={this.props.handleTestSuitesCasesDirections}
                        updateTestSuitesDataPropProject={this.props.updateTestSuitesDataPropProject}
                        saveTestSuiteCasesOrder={this.props.saveTestSuiteCasesOrder}
                        receiveFunctionFromChild={this.receiveFunctionFromChild}
                        createTestSuite={this.createTestSuite}
                        aiqExecution={project.aiqExecution}
                        handleStoreState={handleStoreState}
                        isClearData={isClearData}
                    />
                </div>
            </div>
        );
    }
}

SuiteList.propTypes = {
    appUrl: PropTypes.string.isRequired,
    classes: PropTypes.shape({}).isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    query: PropTypes.string,
    project: PropTypes.shape({}).isRequired,
    testCaseDropDownData: PropTypes.arrayOf(Object).isRequired,
    toggleModal: PropTypes.func.isRequired,
};

SuiteList.defaultProps = {
    query: '',
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.projectReducer.isLoading,
        testSuiteByProject: state.projectReducer.testSuites,
        user: state.authReducer.user,
        testCaseDropDownData: state.projectReducer.testCases,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        associateTestSuites: (...args) => dispatch(TestSuiteActions.associateTestSuites(...args)),
        deleteTestCaseFromSuite: (suiteId, TestCaseIds) => dispatch(ProjectActions.deleteTestCaseFromSuite(suiteId, TestCaseIds)),
        getTestSuitesByProject: (projectId, accountId) => dispatch(TestSuiteActions.getTestSuites(projectId, accountId)),
        getExecutionTasks: (...args) => dispatch(ProjectActions.getExecutionTasks(...args)),
        updateTestCaseId: (...args) => dispatch(TaskAction.updateTestCaseId(...args)),
        handleTestSuitesCasesDirections: (data) => dispatch(ProjectActions.handleTestSuitesCasesProjectDirections(data)),
        updateTestSuitesDataPropProject: (data) => dispatch(ProjectActions.updateTestSuitesDataPropProject(data)),
        saveTestSuiteCasesOrder: (order, suiteId, onComplete,msg) => dispatch(ProjectActions.saveTestSuiteCasesOrder(order, suiteId, onComplete,msg)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SuiteList));
