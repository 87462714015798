// import materials
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Icons
import Lock from '@material-ui/icons/Lock';
import { CustomSwitch } from '../../../common/miniComponents';

const styles = (theme) => ({
    root: {
        // paddingLeft: '20px',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 135px)',
        },
    },
    textField: {
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        height: 40,
        alignItems: 'center',
        paddingTop: 10,
    },
    textFieldTitle: {
        width: '89%',
        float: 'left',
        fontSize: 12,
    },
    typo: {
        float: 'left',
        paddingTop: 13,
        fontSize: 16,
        width: '85%',
    },
    actionDiv: {
        width: '100%',
        paddingTop: 0,
    },
    lockIcon: {
        fontSize: 20,
        color: '#979797',
    },
    marginLeft: {
        marginLeft: 15,
    },
});

function ArrangeTableModal({ classes, rows, handleChange }) {
    return (
        <div className={classes.root}>
            <div className={classes.actionDiv}>
                {rows.map((row, index) => {
                    return (
                        <div key={index}>
                            <FormControl className={classes.textField} key={index}>
                                {row.status === 'Lock' ? (
                                    <div>
                                        <Lock className={classes.lockIcon} aria-label="lockIcon" id="lockIcon" />
                                    </div>
                                ) : (
                                    <FormControlLabel
                                        style={{ width: 35 }}
                                        control={<CustomSwitch checked={row.status === 'Active'} onChange={handleChange(row.id)} value={row.id} />}
                                    />
                                )}
                                <Typography className={`${classes.textFieldTitle} ${row.status === 'Lock' && classes.marginLeft} `}>
                                    {row.label}
                                </Typography>
                            </FormControl>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

ArrangeTableModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    rows: PropTypes.instanceOf(Array).isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ArrangeTableModal);
