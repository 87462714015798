import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { faPencilAlt, faTrashAlt, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Clone from '@material-ui/icons/FileCopySharp';
import Disable from '@material-ui/icons/NotInterested';
import Restore from '@material-ui/icons/PowerSettingsNew';
import FlashOn from '@material-ui/icons/FlashOn';
import WarningIcon from '@material-ui/icons/Warning';
import FolderOpen from 'mdi-material-ui/FolderOpen';
import Export from 'mdi-material-ui/Export';
import Close from '@material-ui/icons/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import { connect } from 'react-redux';
import React from 'react';


import Tooltip from '../../../components/Tooltip';
import {
    getCountAvatar,
    checkArrayLength,
    checkKeyInObject,
    sort,
    getExecutionStatus,
    getFormatedDate,
    checkObject,
    resizeAllCols,
    getRelativeTime,
    TestSuiteUtils,
    getMinNearest15Interval,
} from '../../../utils';
import { GeneralActions, ProjectActions, TestSuiteActions, TestCaseActions, ModalActions, generalModalActions } from '../../../store/actions';
import ProjectMiddleware from '../../../store/middleware/projectMiddleware';
import DeleteTestSuiteDialog from './DeleteTestSuite';
import DeleteTestCaseDialog from '../case/DeleteTestCase';
import { EnhancedTableHead } from '../../../components/EnhancedTable';
import { MUI_ACTION_MENU_ICON, THREE_DOT_MENU_CSS, TABLES_CSS, BLACK_FONT } from '../../../common/cssConstants';
import ExecuteScriptModal from '../../projectDetails/Script/ExecuteScriptModal';
import SuiteTableRow from '../../../components/Suite/SuiteTableRow';
import DeleteAlertModal from '../../modal/Delete/DeleteAlertModal';
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import TableCell from '../../../components/Table/TableCell';
import TableRow from '../../../components/Table/TableRow';
import TableFooter from '../../../common/TableFooter';
import SuiteActions from '../../../common/SuiteActions';
import { CustomSwitch } from '../../../common/miniComponents';

const styles = (theme) => ({
    root: {
        width: 'calc(100% - 40px)',
        margin: '0px 20px',
        marginTop: '10px',
        borderRadius: 0,
        boxShadow: 'none',
    },
    nonSortableContainer: {
        cursor: 'default',
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'inherit',
        justifyContent: 'flex-start',
    },
    headerContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 35,
        marginTop: -35,
        width: 'calc(100% - 55px)',
    },
    sortIcon: {
        fontSize: 10,
        marginLeft: 10,
        color: '#9B9B9B',
    },
    tableWrapper: {
        overflowX: 'hidden',
        overflowY: 'auto',
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    },
    wrapperTableRoot: {
        borderRadius: '5px',
        marginBottom: '10px',
    },
    avatar: {
        minWidth: '20px',
        height: '20px',
        borderRadius: '20px',
        textAlign: 'center',
        fontSize: '13px',
        lineHeight: 1.7,
        padding: '0 4px',
        display: 'inline-block',
        backgroundColor: '#3b91df',
        color: '#fff',
        marginRight: '8px',
    },
    selectedText: {
        color: BLACK_FONT,
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '3px',
    },
    multiCloseIcon: {
        color: '#4a4a4a',
        cursor: 'pointer',
        fontSize: 22,
        marginLeft: 10,
    },
    multiIcon: {
        color: '#3b91df',
        fontSize: 22,
        cursor: 'pointer',
        marginRight: 5,
    },
    multiActionsBar: {
        display: 'flex',
        alignItems: 'center',
        zIndex: 999,
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '16.6%',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    cell_: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        paddingLeft: '10px !important',
        width: '100%',
        maxWidth: '100%',
        '&>:first-child': {
            paddingLeft: '10px !important',
            display: 'flex',
        },
    },
    moreIcons: MUI_ACTION_MENU_ICON,
    noDataText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '200px',
    },
    wrapperTable: {
        borderRadius: '5px',
        marginBottom: '10px',
    },
    maxHeight: {
        maxHeight: 'calc(100vh - 163px)',
        height: 'auto',
    },
    fileIcon: {
        color: '#ECECEC',
        fontSize: '50px',
        marginLeft: '10px',
        marginRight: '5px',
    },
    notAvailable: {
        fontSize: '18px',
        color: '#8498AA',
        fontWeight: 'bold',
        marginTop: '10px',
    },
    tableRows1: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.07) !important',
        },
        backgroundColor: '#FCF9E5',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    circularPrgress: {
        position: 'absolute',
        color: '#4885ed',
    },
    noTestCasesAvailableText: {
        textAlign: 'center',
        color: TABLES_CSS.normalFontColor,
        width: '100%',
        padding: 0,
        margin: 0,
    },
    wrapperContent: {
        paddingLeft: '10px',
        width: '100%',
    },
    actionEditTitleContainer: {
        width: '100%',
        pointerEvents: 'none',
    },
    actionTitleContainerWidth: {
        width: '100%',
        height: '47px',
    },
    actionTitle: {
        color: '#000000',
        marginLeft: '10px',
        fontSize: '11px',
        borderBottom: '1px solid #7E7E7E',
        fontWeight: 'bold',
    },
    horizontalLine: {
        backgroundColor: '#DCDCDC',
        height: '9px',
        borderTop: 'solid',
        marginRight: '-13px',
    },
});

const headers = [
    {
        id: 'testSuiteName',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Test Suite',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 6)',
    },
    {
        id: 'projectName',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Project',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 6)',
    },
    {
        id: 'creationTime',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Created',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 6)',
    },
    {
        id: 'lastRunTime',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Last Run',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 6)',
    },
    {
        id: 'suiteExecutionStatus',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Last Status',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 6)',
    },
    { id: 'funcs', sortable: false, numeric: false, disablePadding: true, label: 'Actions', styles: { paddingLeft: 10 }, width: 'calc(100% / 6)' },
];
// headers for case
const subHeaders = [
    {
        id: 'testCaseName',
        sortable: true,
        numeric: false,
        disablePadding: false,
        label: 'Case',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 6)',
    },
    { id: 'extra', sortable: false, numeric: false, disablePadding: true, label: '', styles: { paddingLeft: 10 }, width: 'calc(100% / 6)' },
    {
        id: 'createdTime',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Created',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 6)',
    },
    {
        id: 'lastRun',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Last Generated',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 6)',
    },
    {
        id: 'executionStatus',
        sortable: false,
        numeric: false,
        disablePadding: true,
        label: 'Execution Status',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 6)',
    },
    { id: 'funcs', sortable: false, numeric: false, disablePadding: false, label: 'Actions', styles: { paddingLeft: 10 }, width: 'calc(100% / 6)' },
];
let submitDisable = false;

class TestSuite extends React.Component {
    constructor(props) {
        super(props);
        this.colsWidth = {};
        this.state = {
            order: 'asc',
            orderBy: 'testSuiteName',
            caseOrder: 'asc',
            caseOrderBy: 'ordinal',
            rowExpendStatus: [0, false],
            data: [],
            page: 0,
            rowsPerPage: 20,
            open: null,
            openedId: null,
            deleteTestSuiteData: null,
            isEditModal: false,
            editTestSuiteData: null,
            deleteDialogOpen: false,
            isDisableCase: 0,
            isFiltering: false,
            associateTestSuiteData: null,
            selectedUpDownTemp: [],
            isCaseBtnsDisable: false,
            TestSuiteByProjectTemp: null,
            deleteTestCaseData: null,
            deleteCaseDialogOpen: false,
            openDeleteDialog: false,
            openDisableDialog: false,
            openEnableDialog: false,
            organizeTestSuiteData: null,
            executeDialogOpen: false,
            selectedSuite: null,
            testSuiteId: null,
            selectedSuitesName: [], // for deleting multiple suite,
            isShiftDown: false,
            selectedSuites: {},
            selectedDisabledSuites: {},
            selectedEnabledSuites: {},
            lastSelectedTestSuite: null,
            showDisabledTestSuites: false,
        };
        props.updateTitle('Suites');
    }

    // buttons = [
    //     NEXT_SUBMIT_BUTTON({ action: () => this.props.changeTab(this.props.currentTab + 1) }),
    //     CANCEL_BUTTON({ action: () => this.closeMergeModal() }),
    // ];

    UNSAFE_componentWillMount() {
        if (checkObject(this.props.suiteState)) {
            this.setState((prevState) => ({
                ...prevState,
                ...this.props.suiteState,
            }));
            this.props.clearSuiteState();
        }
    }

    componentDidMount() {
        // Add event listeners for selecting multiple checkboxes
        document.addEventListener('keyup', this.handleKeyUp, false);
        document.addEventListener('keydown', this.handleKeyDown, false);

        for (let i = 0, j = 0; i < headers.length && j < subHeaders.length; i++) {
            const header = headers[i];
            if (header.colSpan && header.colSpan > 1) {
                for (let k = j; k < j + header.colSpan; k++) {
                    const subHeader = subHeaders[k];
                    subHeader.width = `calc(${header.width} / ${header.colSpan})`;
                    subHeader.parentIndex = i;
                }
                j += header.colSpan;
            } else {
                header.colSpan = 1;
                const subHeader = subHeaders[j];
                subHeader.width = header.width;
                subHeader.parentIndex = i;
                j++;
            }
        }
        const { getProjectByIdAndName, projectsByIdAndName } = this.props;
        this.getTestSuiteWithAccount();
        if (!checkArrayLength(projectsByIdAndName)) {
            getProjectByIdAndName();
        }
        const suiteSettingRows = localStorage.getItem('rowsSuitePerPage');
        if (suiteSettingRows) {
            this.updateState({
                rowsPerPage: parseInt(suiteSettingRows, 10),
                selectedSuites: {},
                selectedDisabledSuites: {},
                selectedEnabledSuites: {},
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { testSuiteWithAccount, query } = nextProps;
        if (query && query.length) {
            this.setState({ lastSelectedTestSuite: null });
        }
        const { organizeTestSuiteData } = this.state;
        if (organizeTestSuiteData && testSuiteWithAccount) {
            const _suite = testSuiteWithAccount.find((suite) => suite.testSuiteId === organizeTestSuiteData.testSuiteId);
            if (_suite) {
                this.setState({ organizeTestSuiteData: _suite });
            }
        }
    }

    componentDidUpdate() {
        const { query = '', testSuiteWithAccount } = this.props;
        const { order, orderBy, rowsPerPage, page, lastSelectedTestSuite } = this.state;
        if (lastSelectedTestSuite === null && checkArrayLength(testSuiteWithAccount)) {
            let testSuiteArray = testSuiteWithAccount;
            if (query && checkArrayLength(testSuiteArray)) {
                testSuiteArray = testSuiteArray.filter(
                    (testSuite) => 'testSuiteName' in testSuite && testSuite.testSuiteName.toLowerCase().includes(query.toLowerCase()),
                );
            }
            const currentPageSuites = query
                ? testSuiteArray
                : sort(testSuiteArray, order, orderBy, orderBy === 'creationTime' || orderBy === 'lastRunTime' ? 'time' : '').slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                  );
            if (checkArrayLength(currentPageSuites)) {
                this.updateLastSelectedTestSuite({ lastSelectedTestSuite: { testSuiteId: currentPageSuites[0].testSuiteId } });
            }
        }
        resizeAllCols(this.colsWidth);
    }

    componentWillUnmount() {
        // Remove event listeners for selecting multiple checkboxes
        document.removeEventListener('keyup', this.handleKeyUp);
        document.removeEventListener('keydown', this.handleKeyDown);

        this.props.queryRemove();
        this.props.clearSnackBar();
    }

    onDeleteTestCase = (suite, caseData) => {
        this.setState({
            deleteTestCaseData: caseData,
            deleteCaseDialogOpen: true,
            suite,
        });
    };

    onAssociateTestSuite = (suiteData, isDisableCase = false) => {
        this.setState({
            associateTestSuiteData: suiteData,
            associateDialogOpen: true,
            isDisableCase,
            open: null,
            openedId: null,
        });
    };

    onSuiteMenuClick = (suiteData, isDisableCase) => {
        this.setState({
            deleteTestSuiteData: suiteData,
            deleteDialogOpen: true,
            isDisableCase,
            open: null,
            openedId: null,
        });
    };

    setOpenedId = (event, testSuiteId) => {
        this.setState({
            open: event.currentTarget,
            openedId: testSuiteId,
        });
    };

    getTestSuiteWithAccount = async () => {
        const { getTestSuitesWithAccount } = this.props;
        await getTestSuitesWithAccount();
    };

    // Test Case Implementation Start

    updateLastSelectedTestSuite = (obj) => {
        this.setState(obj);
    };

    handleKeyUp = (e) => {
        if (e.key === 'Shift' && this.state.isShiftDown) {
            this.setState({ isShiftDown: false });
        }
    };

    handleKeyDown = (e) => {
        if (e.key === 'Shift' && !this.state.isShiftDown) {
            this.setState({ isShiftDown: true });
        }
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    select_deSelectAllCurrentPage = (suites, isSelect,showDisabledTestSuites = false) => {
        const { testSuiteWithAccount } = this.props;
        const { selectedSuites, selectedDisabledSuites, selectedEnabledSuites } = this.state;
        const _selectedSuites = JSON.parse(JSON.stringify(selectedSuites));
        const _selectedDisabledSuites = JSON.parse(JSON.stringify(selectedDisabledSuites));
        const _selectedEnabledSuites = JSON.parse(JSON.stringify(selectedEnabledSuites));
        testSuiteWithAccount.forEach((suite) => {
            if (!isSelect && _selectedSuites[suite.testSuiteId]) {
                delete _selectedSuites[suite.testSuiteId];
            } else if (isSelect) {
                _selectedSuites[suite.testSuiteId] = true;
            }
            if (!isSelect && _selectedDisabledSuites[suite.testSuiteId]) {
                delete _selectedDisabledSuites[suite.testSuiteId];
            }
            if (!isSelect && _selectedEnabledSuites[suite.testSuiteId]) {
                delete _selectedEnabledSuites[suite.testSuiteId];
            }
            if (isSelect) {
                if (suite.disabledStatus) {
                    _selectedDisabledSuites[suite.testSuiteId] = true;
                } else {
                    _selectedEnabledSuites[suite.testSuiteId] = true;
                }
            }
        });
        this.setState({
            selectedSuites: showDisabledTestSuites ? _selectedDisabledSuites : _selectedSuites,
            selectedDisabledSuites: _selectedDisabledSuites,
            selectedEnabledSuites: _selectedEnabledSuites,
        });
    };

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleRequestCaseSort = (property) => {
        const caseOrderBy = property;
        let caseOrder = 'desc';
        if (this.state.caseOrderBy === property && this.state.caseOrder === 'desc') {
            caseOrder = 'asc';
        }
        this.setState({ caseOrder, caseOrderBy });
    };

    handleExpend = (id) => {
        const { rowExpendStatus } = this.state;
        if (this.state.TestSuiteByProjectTemp) {
            this.props.updateTestSuitesDataPropPlan(this.state.TestSuiteByProjectTemp);
        }
        this.setState({
            rowExpendStatus: [id, id === rowExpendStatus[0] ? !rowExpendStatus[1] : true],
            TestSuiteByProjectTemp: null,
            isCaseBtnsDisable: false,
            selectedUpDownTemp: [],
        });
    };

    handleChangePage = (page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        localStorage.setItem('rowsSuitePerPage', event.target.value);
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    handleRowsUpDown = (testSuiteId, testCaseId, direction) => {
        const { testSuiteWithAccount } = this.props;
        const tempAr = this.state.selectedUpDownTemp.filter((data) => !(data[0] === testSuiteId && data[1] === testCaseId));
        this.setState((prevState) => ({
            TestSuiteByProjectTemp:
                prevState.selectedUpDownTemp.length > 0 ? prevState.TestSuiteByProjectTemp : JSON.parse(JSON.stringify(testSuiteWithAccount)),
            isCaseBtnsDisable: true,
            selectedUpDownTemp: [...tempAr, [testSuiteId, testCaseId, direction]],
        }));
        this.props.handleTestSuitesCasesDirections({
            testSuiteId,
            testCaseId,
            direction,
        });
    };

    openExecutionModal = (suite) => {
        this.setState({ executeDialogOpen: true, selectedSuite: suite, open: null, openedId: null });
    };

    hangdleCloseExecuteDialog = () => {
        this.setState({ executeDialogOpen: false, selectedSuite: null });
    };

    closeMergeModal = () => {
        submitDisable = false;
        this.props.toggleModal();
        this.setState(
            {
                isEditModal: false,
                editTestSuiteData: null,
                isAlreadySubmit: false,
            },
            () => {
                this.props.clearGeneralModal();
            },
        );
    };

    handleSubmitOrganize = async (testSuiteId, caseData = null) => {
        const suiteId =
            testSuiteId || (checkKeyInObject(this.state.organizeTestSuiteData, 'testSuiteId') ? this.state.organizeTestSuiteData.testSuiteId : null);
        if (!suiteId) return;
        const { tabsData } = this.props;
        const tc_ids_to_ordinals = {};
        const caseDataArray = caseData || checkKeyInObject(checkKeyInObject(checkKeyInObject(tabsData, 'organize'), 'state'), 'caseDataArray');
        if (caseDataArray) {
            caseDataArray.forEach((_case, index) => {
                tc_ids_to_ordinals[_case.testCaseId] = index + 1;
            });
        }
        await this.props.saveTestSuiteCasesOrder(tc_ids_to_ordinals, suiteId, () => {});
    };

    handleSubmitTestCases = async (suiteId = null) => {
        const { associateTestSuiteData } = this.state;
        const { tabsData } = this.props;
        const testSuiteId = suiteId || (associateTestSuiteData && associateTestSuiteData.testSuiteId);
        const { selectedTestCaseIds, selectedTestCaseIdsForDelete } = tabsData.association.state;
        const _selectedTestCaseIds = Object.keys(selectedTestCaseIds).map((item) => parseInt(item, 10));
        const _selectedTestCaseIdsForDelete = Object.keys(selectedTestCaseIdsForDelete).map((item) => parseInt(item, 10));
        if (checkKeyInObject(associateTestSuiteData, 'testCases', 'bool') && checkArrayLength(associateTestSuiteData.testCases)) {
            associateTestSuiteData.testCases.forEach((testCase) => {
                const index = _selectedTestCaseIds.indexOf(testCase.testCaseId);
                if (index !== -1) {
                    _selectedTestCaseIds.splice(index, 1);
                }
            });
        }
        if (checkArrayLength(_selectedTestCaseIdsForDelete)) {
            await this.handleDissociate(testSuiteId, _selectedTestCaseIdsForDelete);
        }
        if (checkArrayLength(_selectedTestCaseIds)) {
            await this.handleAssociate(testSuiteId, _selectedTestCaseIds);
        }
    };

    handleSubmitSuiteInfo = async () => {
        const { tabsData } = this.props;
        const { editTestSuiteData } = this.state;
        const { testSuiteName, recipientsEmails } = tabsData.suiteInfo.state;
        let selectedPlatform = '';
        let selectedBrowser = '';
        let scheduleStartTime = '';
        let hours = 0;
        let postActionData = [];
        selectedPlatform = checkKeyInObject(editTestSuiteData, 'schedulePlatform') || '';
        selectedBrowser = checkKeyInObject(editTestSuiteData, 'scheduleBrowser') || '';
        hours = checkKeyInObject(editTestSuiteData, 'interval') || 0;
        scheduleStartTime = checkKeyInObject(editTestSuiteData, 'scheduleStartTime')
            ? new Date(editTestSuiteData.scheduleStartTime).getTime()
            : new Date().getTime();
        if (
            checkKeyInObject(checkKeyInObject(tabsData, 'postAction'), 'state') ||
            checkArrayLength(checkKeyInObject(editTestSuiteData, 'peTriggers'))
        ) {
            const tempActionsData = checkKeyInObject(checkKeyInObject(tabsData, 'postAction'), 'state')
                ? tabsData.postAction.state.originalTabData
                : checkKeyInObject(editTestSuiteData, 'peTriggers');
            postActionData = tempActionsData.map((data) => {
                return {
                    triggerCondition: data.triggerCondition,
                    conditionType: 'POSTEXEC',
                    objectId: parseInt(data.objectId, 10),
                    objectType: 2,
                    delay: parseInt(data.delay, 10),
                    delayUnit: data.delayUnit,
                };
            });
        }
        const correctDate = new Date(new Date(scheduleStartTime).setMinutes(getMinNearest15Interval(new Date(scheduleStartTime).getMinutes())));
        const obj = {
            testSuiteId: editTestSuiteData.testSuiteId,
            name: testSuiteName.trim(),
            projectId: editTestSuiteData.projectId,
            interval: hours,
            scheduleStartTime: new Date(correctDate).toISOString(),
            schedulePlatform: selectedPlatform,
            scheduleBrowser: selectedBrowser,
            recipientsEmails,
            peActions: postActionData,
        };
        await this.props.editTestSuites(obj, 'plan');
    };

    updateSuite = async () => {
        const { tabsData } = this.props;
        if (
            checkKeyInObject(checkKeyInObject(checkKeyInObject(tabsData, 'suiteInfo'), 'state'), 'didChange') ||
            checkKeyInObject(checkKeyInObject(checkKeyInObject(tabsData, 'postAction'), 'state'), 'didChange') ||
            !this.state.isEditModal
        ) {
            await this.handleSubmitSuiteInfo();
        }
        if (
            checkKeyInObject(checkKeyInObject(checkKeyInObject(tabsData, 'association'), 'state'), 'didChange') ||
            checkKeyInObject(checkKeyInObject(checkKeyInObject(tabsData, 'organize'), 'state'), 'didChange')
        ) {
            if (checkKeyInObject(checkKeyInObject(checkKeyInObject(tabsData, 'association'), 'state'), 'didChange')) {
                await this.handleSubmitTestCases();
            }
            await this.handleSubmitOrganize();
        }
        this.closeMergeModal();
        // this.getTestSuiteWithAccount();
    };

    subHeader = (title, seperator) => {
        const { classes } = this.props;
        return (
            <div className={seperator ? classes.actionTitleContainerWidth : classes.actionEditTitleContainer}>
                {/* <div className={classes.actionTitleContainerWidth}> */}
                {/* {seperator && <hr className={classes.horizontalLine} />} */}
                {seperator && <div className={classes.horizontalLine} />}
                <span className={classes.actionTitle}>{title}</span>
            </div>
        );
    };

    toggleMergeModal = async (flag, suiteData) => {
        if (submitDisable) {
            submitDisable = false;
        }
        const testCaseDropDownData = await ProjectMiddleware.getTestCases(suiteData.projectId);
        this.setState(
            {
                isEditModal: flag,
                editTestSuiteData: suiteData,
                associateTestSuiteData: suiteData,
                organizeTestSuiteData: suiteData,
                postActionData: suiteData,
                open: null,
                openedId: null,
            },
            () => {
                TestSuiteUtils.toggleMergeModal(flag, this.closeMergeModal, {
                    associateTestSuiteData: this.state.associateTestSuiteData,
                    editTestSuiteData: this.state.editTestSuiteData,
                    organizeTestSuiteData: this.state.organizeTestSuiteData,
                    postActionData: this.state.postActionData,
                    testCaseDropDownData,
                    updateSuite: this.updateSuite,
                });
            },
        );
    };

    deleteSelectedSuites = (isConfirm) => {
        if (isConfirm) {
            const onComplete = () => {
                this.setState({
                    openDeleteDialog: false,
                    selectedSuites: {},
                    selectedDisabledSuites: {},
                    selectedEnabledSuites: {},
                });
            };
            const obj = {
                testSuiteIds: Object.keys(this.state.selectedSuites).map((item) => parseInt(item, 10)),
                suiteAction: 0,
                suiteTab: 'plan',
                onComplete,
            };
            TestSuiteUtils.onCloseOrDeleteTestSuite(obj);
        } else {
            this.setState({ openDeleteDialog: false });
        }
    };

    disableSelectedSuites = (isConfirm, isDisable) => {
        const { selectedDisabledSuites, selectedEnabledSuites } = this.state;
        if (isConfirm) {
            const onComplete = () => {
                this.setState({
                    openDisableDialog: false,
                    openEnableDialog: false,
                    selectedSuites: {},
                    selectedDisabledSuites: {},
                    selectedEnabledSuites: {},
                });
            };
            const obj = {
                suiteTab: 'plan',
                onComplete,
            };
            if (isDisable) {
                obj.suiteAction = 1;
                obj.testSuiteIds = Object.keys(selectedEnabledSuites).map((item) => parseInt(item, 10));
            } else {
                obj.suiteAction = 2;
                obj.testSuiteIds = Object.keys(selectedDisabledSuites).map((item) => parseInt(item, 10));
            }
            TestSuiteUtils.onCloseOrDeleteTestSuite(obj);
        } else {
            this.setState({ openDisableDialog: false, openEnableDialog: false });
        }
    };

    handleCloseDeleteDialog = (flag) => {
        const onComplete = () => {
            this.setState({
                deleteTestSuiteData: null,
                deleteDialogOpen: false,
            });
        };
        if (flag) {
            const obj = {
                testSuiteIds: [this.state.deleteTestSuiteData.testSuiteId],
                suiteAction: this.state.isDisableCase,
                suiteTab: 'plan',
                onComplete,
            };
            TestSuiteUtils.onCloseOrDeleteTestSuite(obj);
        } else {
            this.setState({
                deleteTestSuiteData: null,
                deleteDialogOpen: false,
            });
        }
        this.setState({isDisableCase: 0})
    };

    handleAssociate = async (testSuiteId, testCaseIds) => {
        await this.props.associateTestSuites(testSuiteId, testCaseIds);
    };

    handleDissociate = async (testSuiteId, testCaseIds) => {
        await this.props.deleteTestCaseFromSuite(testSuiteId, testCaseIds);
    };
    // association end

    isArrayInArray = (arr, item) => {
        const item_as_string = JSON.stringify(item);
        const contains = arr.some((ele) => {
            return JSON.stringify(ele) === item_as_string;
        });
        return contains;
    };

    handleRowsUpDownConfirm = () => {
        const { testSuiteWithAccount } = this.props;
        const suite = testSuiteWithAccount.find((data) => {
            return data.testSuiteId === this.state.selectedUpDownTemp[0][0];
        });
        if (suite && suite.testCases && suite.testCases.length > 0) {
            const data = {};
            suite.testCases.forEach((row, index) => {
                data[row.testCaseId] = index + 1;
            });
            const onComplete = () => {
                this.setState({
                    TestSuiteByProjectTemp: null,
                    isCaseBtnsDisable: false,
                    selectedUpDownTemp: [],
                });
            };
            this.props.saveTestSuiteCasesOrder(data, this.state.selectedUpDownTemp[0][0], onComplete);
        }
    };

    handleRowsUpDownCancel = () => {
        this.props.updateTestSuitesDataPropPlan(this.state.TestSuiteByProjectTemp);
        this.setState({
            TestSuiteByProjectTemp: null,
            isCaseBtnsDisable: false,
            selectedUpDownTemp: [],
        });
    };

    handleCloseTestCaseDeleteDialog = async (flag) => {
        if (flag) {
            if (this.state.deleteTestCaseData && this.state.deleteTestCaseData.testCaseId) {
                const result = await this.props.deleteTestCaseFromSuite(this.state.suite.testSuiteId, [this.state.deleteTestCaseData.testCaseId]);
                if (result.payload) {
                    const testCases = sort(this.state.suite.testCases, 'asc', 'ordinal').filter(
                        (_case) => `${_case.testCaseId}` !== `${this.state.deleteTestCaseData.testCaseId}`,
                    );
                    await this.handleSubmitOrganize(this.state.suite.testSuiteId, testCases);
                    this.getTestSuiteWithAccount();
                }
            }
        }
        this.setState({
            deleteTestCaseData: null,
            deleteCaseDialogOpen: false,
            suite: null,
            isDisableCase: 0
        });
    };

    currentRowIndicator = () => {
        return (
            <span
                style={{
                    display: 'inline-block',
                    width: '4px',
                    minWidth: '4px',
                    marginRight: '16px',
                    height: '39px',
                    backgroundColor: '#ff9700',
                    position: 'relative',
                }}
            ></span>
        );
    };

    handleStoreState = () => {
        this.props.storeSuiteState({
            caseOrder: this.state.caseOrder,
            caseOrderBy: this.state.caseOrderBy,
            order: this.state.order,
            orderBy: this.state.orderBy,
            page: this.state.page,
            rowExpendStatus: this.state.rowExpendStatus,
            rowsPerPage: this.state.rowsPerPage,
        });
    };

    clearOpenedId = () => {
        this.setState({ open: null, openedId: null });
    };

    handelSelectSuite = (suiteId, isDisable) => {
        const { selectedSuites, selectedDisabledSuites, selectedEnabledSuites } = this.state;
        const _selectedSuites = JSON.parse(JSON.stringify(selectedSuites));
        const _selectedDisabledSuites = JSON.parse(JSON.stringify(selectedDisabledSuites));
        const _selectedEnabledSuites = JSON.parse(JSON.stringify(selectedEnabledSuites));
        this.updateSelectedSuiteState(suiteId, isDisable, _selectedSuites, _selectedDisabledSuites, _selectedEnabledSuites);
        this.setState({
            selectedSuites: _selectedSuites,
            selectedDisabledSuites: _selectedDisabledSuites,
            selectedEnabledSuites: _selectedEnabledSuites,
            lastSelectedTestSuite: { testSuiteId: suiteId },
        });
    };

    updateSelectedSuiteState = (suiteId, isDisable, selectedSuites, selectedDisabledSuites, selectedEnabledSuites, isForDelete = null) => {
        if ((isForDelete === null && selectedSuites[suiteId]) || isForDelete) {
            delete selectedSuites[suiteId];
        } else {
            selectedSuites[suiteId] = true;
        }
        if ((isForDelete === null && selectedDisabledSuites[suiteId]) || isForDelete) {
            delete selectedDisabledSuites[suiteId];
        } else if (isDisable) {
            selectedDisabledSuites[suiteId] = true;
        }
        if ((isForDelete === null && selectedEnabledSuites[suiteId]) || isForDelete) {
            delete selectedEnabledSuites[suiteId];
        } else if (!isDisable) {
            selectedEnabledSuites[suiteId] = true;
        }
    };

    handleSelectWithShift = (currentPageSuites, testSuiteId) => {
        const { selectedSuites, selectedDisabledSuites, selectedEnabledSuites, lastSelectedTestSuite } = this.state;

        let startingIndex = currentPageSuites.findIndex((suite) => suite.testSuiteId === lastSelectedTestSuite.testSuiteId);
        let endIndex = currentPageSuites.findIndex((suite) => suite.testSuiteId === testSuiteId);

        const _selectedSuites = JSON.parse(JSON.stringify(selectedSuites));
        const _selectedDisabledSuites = JSON.parse(JSON.stringify(selectedDisabledSuites));
        const _selectedEnabledSuites = JSON.parse(JSON.stringify(selectedEnabledSuites));

        if (endIndex !== -1 && startingIndex !== -1) {
            if (startingIndex > endIndex) {
                const temp = startingIndex;
                startingIndex = endIndex;
                endIndex = temp;
            }
            if (!checkKeyInObject(_selectedSuites, `${testSuiteId}`, 'value', false)) {
                for (let i = startingIndex; i <= endIndex; i++) {
                    const suiteId = currentPageSuites[i].testSuiteId;
                    const isDisable = currentPageSuites[i].disabledStatus;
                    this.updateSelectedSuiteState(suiteId, isDisable, _selectedSuites, _selectedDisabledSuites, _selectedEnabledSuites, false);
                }
            } else {
                for (let i = startingIndex; i <= endIndex; i++) {
                    const suiteId = currentPageSuites[i].testSuiteId;
                    this.updateSelectedSuiteState(suiteId, null, _selectedSuites, _selectedDisabledSuites, _selectedEnabledSuites, true);
                }
            }
            this.setState({
                selectedSuites: _selectedSuites,
                selectedDisabledSuites: _selectedDisabledSuites,
                selectedEnabledSuites: _selectedEnabledSuites,
                lastSelectedTestSuite: { testSuiteId },
            });
        }
    };

    tableBodyRender = () => {
        const { classes, query = '', user, sideBarCount, testSuiteWithAccount } = this.props;
        const {
            order,
            orderBy,
            caseOrder,
            caseOrderBy,
            rowsPerPage,
            page,
            open,
            openedId,
            rowExpendStatus,
            selectedUpDownTemp,
            isCaseBtnsDisable,
            selectedSuites,
            selectedDisabledSuites,
            selectedEnabledSuites,
            openDeleteDialog,
            openDisableDialog,
            openEnableDialog,
            executeDialogOpen,
            selectedSuite,
            isShiftDown,
            lastSelectedTestSuite,
            showDisabledTestSuites,
        } = this.state;
        let testSuiteArray = [];

        if (checkArrayLength(testSuiteWithAccount)) {
            testSuiteWithAccount.forEach((suite) => {
                testSuiteArray.push({
                    ...suite,
                    suiteExecutionStatus: suite.executionStatus, // to avoid sorting issue due to same key in suite and its child testCases
                });
            });
        }

        testSuiteArray =  showDisabledTestSuites ?  testSuiteArray.filter((data) => data.disabledStatus) : testSuiteArray.filter((data) => !data.disabledStatus);

        if (query && checkArrayLength(testSuiteArray)) {
            testSuiteArray = testSuiteArray.filter(
                (testSuite) => 'testSuiteName' in testSuite && testSuite.testSuiteName.toLowerCase().includes(query.toLowerCase()),
            );
        }


       
        const currentPageSuites = sort(testSuiteArray, order, orderBy, orderBy === 'creationTime' || orderBy === 'lastRunTime' ? 'time' : '').slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        );


        const selectedCount = Object.keys(selectedSuites).length;
        const selectedDisabledCount = Object.keys(selectedDisabledSuites).length;
        const selectedEnabledCount = Object.keys(selectedEnabledSuites).length;
        let _selected = 0;
        for (let i = 0; i < currentPageSuites.length; i++) {
            const suite = currentPageSuites[i];
            if (checkKeyInObject(suite, 'testCases') && checkKeyInObject(suite, 'projectName') && checkArrayLength(suite.testCases)) {
                suite.testCases.forEach((testCase) => {
                    testCase.projectName = suite.projectName;
                });
            }
            if (selectedSuites[suite.testSuiteId]) {
                _selected++;
            }
        }


        return (
            <div className={classes.root}>
                {selectedCount > 0 ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#f0f7ea',
                            padding: '0 15px',
                            height: 45,
                            justifyContent: 'space-between',
                            boxShadow:
                                '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span className={classes.avatar}> {selectedCount} </span>
                            <Typography className={classes.selectedText}>
                                {selectedCount === 1 ? 'Suite is Selected' : 'Suites are Selected'}
                            </Typography>
                            <Close
                                className={classes.multiCloseIcon}
                                onClick={() => {
                                    this.select_deSelectAllCurrentPage(testSuiteArray, false,showDisabledTestSuites);
                                }}
                                aria-label="closeIcon"
                                id="closeIcon"
                            />
                        </div>
                        <span className={classes.multiActionsBar}>
                            <Tooltip data={selectedEnabledCount ? `Disable Suite${selectedEnabledCount > 1 ? 's' : ''}` : null}>
                                <Disable
                                    className={classes.multiIcon}
                                    style={
                                        selectedEnabledCount <= 0
                                            ? {
                                                  color: '#c4c4c4',
                                                  cursor: 'not-allowed',
                                              }
                                            : {}
                                    }
                                    onClick={() => {
                                        if (selectedEnabledCount > 0) {
                                            const temp = [];
                                            testSuiteArray.forEach((suite) => {
                                                if (checkKeyInObject(selectedEnabledSuites, suite.testSuiteId)) {
                                                    temp.push(suite.testSuiteName);
                                                }
                                            });
                                            this.setState({
                                                openDisableDialog: true,
                                                selectedSuitesName: temp,
                                            });
                                        }
                                    }}
                                    aria-label="disableIcon"
                                    id="disableIcon"
                                />
                            </Tooltip>
                            <Tooltip data={selectedDisabledCount ? `Enable Suite${selectedDisabledCount > 1 ? 's' : ''}` : null}>
                                <Restore
                                    className={classes.multiIcon}
                                    style={
                                        selectedDisabledCount <= 0
                                            ? {
                                                  color: '#c4c4c4',
                                                  cursor: 'not-allowed',
                                              }
                                            : {}
                                    }
                                    onClick={() => {
                                        if (selectedDisabledCount > 0) {
                                            const temp = [];
                                            testSuiteArray.forEach((suite) => {
                                                if (checkKeyInObject(selectedDisabledSuites, suite.testSuiteId)) {
                                                    temp.push(suite.testSuiteName);
                                                }
                                            });
                                            this.setState({
                                                openEnableDialog: true,
                                                selectedSuitesName: temp,
                                            });
                                        }
                                    }}
                                    aria-label="restoreIcon"
                                    id="restoreIcon"
                                />
                            </Tooltip>
                            <Tooltip data={selectedCount ? `Delete Suite${selectedCount > 1 ? 's' : ''}` : null}>
                                <FontAwesomeIcon
                                    className={classes.multiIcon}
                                    style={{ fontSize: 17 }}
                                    onClick={() => {
                                        const temp = [];
                                        testSuiteArray.forEach((suite) => {
                                            if (checkKeyInObject(selectedSuites, suite.testSuiteId)) {
                                                temp.push(suite.testSuiteName);
                                            }
                                        });
                                        this.setState({
                                            openDeleteDialog: true,
                                            selectedSuitesName: temp,
                                        });
                                    }}
                                    icon={faTrashAlt}
                                    aria-label="faTrashAlt"
                                    id="faTrashAlt"
                                />
                            </Tooltip>
                        </span>
                    </div>
                ) : null}
                <div
                    style={
                        checkArrayLength(testSuiteArray) || this.props.isSuiteLoading
                            ? { minHeight: `calc(100vh - ${(selectedCount > 0 ? 45 : 0) + 163}px)` }
                            : {}
                    }
                    className={`${classes.wrapperTableRoot} ${classes.wrapperTable} ${classes.maxHeight}`}
                >
                    <div className={`${classes.tableWrapper} TestSuite`}>
                        {checkArrayLength(testSuiteArray) ? (
                            <Table>
                                <Hidden only={['xs']}>
                                    <EnhancedTableHead
                                        headers={headers}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        onSelectAllClick={() => {
                                            this.select_deSelectAllCurrentPage(currentPageSuites, _selected !== currentPageSuites.length,showDisabledTestSuites);
                                        }}
                                        currentPageData={currentPageSuites}
                                        numSelected={_selected}
                                        rowCount={currentPageSuites.length}
                                        showSelectBtn
                                        isColSpan
                                        colsWidth={this.colsWidth}
                                        resizeable
                                    />
                                </Hidden>
                                <Hidden smUp>
                                    <EnhancedTableHead
                                        headers={headers}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        onSelectAllClick={() => {
                                            this.select_deSelectAllCurrentPage(currentPageSuites, _selected !== currentPageSuites.length,showDisabledTestSuites);
                                        }}
                                        currentPageData={currentPageSuites}
                                        numSelected={_selected}
                                        rowCount={currentPageSuites.length}
                                        showSelectBtn
                                        isColSpan
                                        colsWidth={this.colsWidth}
                                    />
                                </Hidden>
                                <TableBody style={{ maxHeight: `calc(100vh - ${(selectedCount > 0 ? 45 : 0) + 202}px)` }}>
                                    {currentPageSuites.map((suite) => {
                                        const _row = [];
                                        _row.push(
                                            <SuiteTableRow
                                                key={suite.testSuiteId}
                                                headers={headers}
                                                actions={{
                                                    clearOpenedId: this.clearOpenedId,
                                                    currentRowIndicator: this.currentRowIndicator,
                                                    handleExpend: this.handleExpend,
                                                    handleRowsUpDownCancel: this.handleRowsUpDownCancel,
                                                    handleRowsUpDownConfirm: this.handleRowsUpDownConfirm,
                                                    handelSelectSuite: this.handelSelectSuite,
                                                    handleSelectWithShift: (...args) => {
                                                        this.handleSelectWithShift(currentPageSuites, ...args);
                                                    },
                                                    onSuiteMenuClick: this.onSuiteMenuClick,
                                                    renderItems: this.renderItems,
                                                    setOpenedId: this.setOpenedId,
                                                }}
                                                data={{
                                                    isCaseBtnsDisable,
                                                    open,
                                                    openedId,
                                                    rowExpendStatus,
                                                    selectedSuites,
                                                    selectedUpDownTemp,
                                                    isShiftDown,
                                                    currentPageSuites,
                                                    lastSelectedTestSuite,
                                                }}
                                                parentComponent="suiteTable"
                                                suite={suite}
                                                user={user}
                                            />,
                                        );
                                        {
                                            if (rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1] && !suite.disabledStatus) {
                                                _row.push(
                                                    <TableRow
                                                        style={{ height: '40px', background: TABLES_CSS.subHeaderRowColor }}
                                                        key={`${suite.testSuiteId}-subHeader`}
                                                    >
                                                        {subHeaders.map((column, ind) => (
                                                            <TableCell
                                                                className={[classes.cell, classes.noWrap].join(' ')}
                                                                width={column.width}
                                                                data-sub-header="subHeader"
                                                                data-child-resize={`${ind}_${column.parentIndex}_${
                                                                    headers[column.parentIndex].colSpan
                                                                }`}
                                                                key={`testCase - ${column.id}`}
                                                            >
                                                                <div style={{ width: '100%' }}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        {column.sortable ? (
                                                                            <div onClick={() => this.handleRequestCaseSort(column.id)} aria-hidden>
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    style={{
                                                                                        fontSize: TABLES_CSS.headingFontSize,
                                                                                        color: TABLES_CSS.headingFontColor,
                                                                                        fontWeight: TABLES_CSS.headingFontWeight,
                                                                                    }}
                                                                                    noWrap
                                                                                >
                                                                                    {column.label}
                                                                                    {caseOrderBy === column.id ? (
                                                                                        caseOrder === 'desc' ? (
                                                                                            <FontAwesomeIcon
                                                                                                icon={faChevronDown}
                                                                                                aria-label="faChevronDown"
                                                                                                id="faChevronDown"
                                                                                                className={classes.sortIcon}
                                                                                            />
                                                                                        ) : (
                                                                                            <FontAwesomeIcon
                                                                                                icon={faChevronUp}
                                                                                                aria-label="faChevronUp"
                                                                                                id="faChevronUp"
                                                                                                className={classes.sortIcon}
                                                                                            />
                                                                                        )
                                                                                    ) : null}
                                                                                </Typography>
                                                                            </div>
                                                                        ) : (
                                                                            <div className={classes.nonSortableContainer} key={column.id}>
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    style={{
                                                                                        fontSize: TABLES_CSS.headingFontSize,
                                                                                        color: TABLES_CSS.headingFontColor,
                                                                                        fontWeight: TABLES_CSS.headingFontWeight,
                                                                                    }}
                                                                                    noWrap
                                                                                >
                                                                                    {column.label}
                                                                                </Typography>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>,
                                                );
                                            }
                                            const testCases = sort(
                                                suite.testCases,
                                                caseOrder,
                                                caseOrderBy,
                                                caseOrderBy === 'createdTime' || caseOrderBy === 'lastRun' ? 'time' : '',
                                            );
                                            if (rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1] && !suite.disabledStatus) {
                                                if (testCases && testCases.length > 0) {
                                                    _row.push(
                                                        testCases.map((testCase) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={-1}
                                                                    style={{
                                                                        height: '40px',
                                                                        display:
                                                                            rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1]
                                                                                ? 'table-row'
                                                                                : 'none',
                                                                    }}
                                                                    className={classes.tableRows1}
                                                                    key={`${suite.testSuiteId}${testCase.testCaseId}`}
                                                                >
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap].join(' ')}
                                                                        width={subHeaders[0].width}
                                                                        data-child-resize={`${0}_${subHeaders[0].parentIndex}_${
                                                                            headers[subHeaders[0].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <div className={classes.wrapperContent}>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Typography
                                                                                    noWrap
                                                                                    style={{
                                                                                        fontSize: TABLES_CSS.normalFontSize,
                                                                                        color: TABLES_CSS.normalFontColor,
                                                                                        display: 'flex',
                                                                                        alignItems: 'center'
                                                                                    }}
                                                                                    className={classes.noWrap}
                                                                                >
                                                                                    {!testCase.isScriptAvailable && 
                                                                                    <Tooltip data={'Generate this test case to use it in scheduled runs.'} toolTipAreaStyle={{margin: '4px 10px 0 -2px'}} >
                                                                                        <WarningIcon style={{fontSize: 15, color: '#F39B31'}}/>
                                                                                    </Tooltip>
                                                                                    }
                                                                                    <div style = {{ 
                                                                                                width: '410px',
                                                                                                overflow: 'hidden',
                                                                                                whiteSpace: 'nowrap',
                                                                                                textOverflow: 'ellipsis'}}>
                                                                                        <Tooltip
                                                                                            data={testCase.testCaseName ? testCase.testCaseName : null} 
                                                                                        >
                                                                                            {testCase.testCaseName}
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </Typography>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap].join(' ')}
                                                                        width={subHeaders[1].width}
                                                                        data-child-resize={`${1}_${subHeaders[1].parentIndex}_${
                                                                            headers[subHeaders[1].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <div className={classes.wrapperContent}>
                                                                            <Typography
                                                                                noWrap
                                                                                style={{
                                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                                    color: TABLES_CSS.normalFontColor,
                                                                                    display: 'inline-block',
                                                                                }}
                                                                            ></Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap].join(' ')}
                                                                        width={subHeaders[2].width}
                                                                        data-child-resize={`${2}_${subHeaders[2].parentIndex}_${
                                                                            headers[subHeaders[2].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <div className={classes.wrapperContent}>
                                                                            <Typography
                                                                                noWrap
                                                                                style={{
                                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                                    color: TABLES_CSS.normalFontColor,
                                                                                    display: 'inline-block',
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                {checkKeyInObject(user, 'isRelativeDateFormat')
                                                                                    ? getRelativeTime(testCase.createdTime)
                                                                                    : getFormatedDate(testCase.createdTime, 'MMM D, YYYY', '')}
                                                                            </Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap].join(' ')}
                                                                        width={subHeaders[3].width}
                                                                        data-child-resize={`${3}_${subHeaders[3].parentIndex}_${
                                                                            headers[subHeaders[3].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <div className={classes.wrapperContent}>
                                                                            <Typography
                                                                                noWrap
                                                                                style={{
                                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                                    color: TABLES_CSS.normalFontColor,
                                                                                    display: 'inline-block',
                                                                                }}
                                                                                className={classes.noWrap}
                                                                            >
                                                                                {testCase.lastRun && testCase.lastRun !== testCase.createdTime
                                                                                    ? checkKeyInObject(user, 'isRelativeDateFormat')
                                                                                        ? getRelativeTime(testCase.lastRun)
                                                                                        : getFormatedDate(testCase.lastRun, 'MMM D, YYYY', '')
                                                                                    : ''}
                                                                            </Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap].join(' ')}
                                                                        width={subHeaders[4].width}
                                                                        data-child-resize={`${4}_${subHeaders[4].parentIndex}_${
                                                                            headers[subHeaders[4].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <div className={classes.wrapperContent}>
                                                                            <Typography
                                                                                noWrap
                                                                                style={{
                                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                                    color: TABLES_CSS.normalFontColor,
                                                                                    display: 'inline-block',
                                                                                }}
                                                                                className={classes.noWrap}
                                                                            >
                                                                                {getExecutionStatus(testCase) === 'N/A'
                                                                                    ? ''
                                                                                    : getExecutionStatus(testCase)}
                                                                            </Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[classes.cell_, classes.noWrap].join(' ')}
                                                                        width={subHeaders[5].width}
                                                                        data-child-resize={`${5}_${subHeaders[5].parentIndex}_${
                                                                            headers[subHeaders[5].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <SuiteActions
                                                                            isCaseBtnsDisable={isCaseBtnsDisable}
                                                                            onDeleteTestCase={() => {
                                                                                this.onDeleteTestCase(suite, testCase);
                                                                            }}
                                                                            executionRoute={
                                                                                checkKeyInObject(testCase, 'lastExecutionId', 'value', 0)
                                                                                    ? `/projects/${suite.projectId}/${testCase.testCaseId}/executions/${testCase.lastExecutionId}`
                                                                                    : null
                                                                            }
                                                                            goToTestCase={() => {
                                                                                this.handleStoreState();
                                                                            }}
                                                                            testCaseRoute={`/projects/${suite.projectId}/${testCase.testCaseId}`}
                                                                            testCase={testCase}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }),
                                                    );
                                                } else {
                                                    _row.push(
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            style={{
                                                                height: '40px',
                                                                display:
                                                                    rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1]
                                                                        ? 'table-row'
                                                                        : 'none',
                                                            }}
                                                            key={`${suite.testSuiteId}empty`}
                                                            className={classes.tableRows1}
                                                        >
                                                            <TableCell className={classes.cell} width="100%">
                                                                <Typography className={classes.noTestCasesAvailableText}>
                                                                    No test cases available.
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>,
                                                    );
                                                }
                                            }
                                        }
                                        return [..._row];
                                    })}
                                </TableBody>
                            </Table>
                        ) : (
                            <div className={`${classes.noDataText} ${classes.maxHeight}`}>
                                {!this.props.isSuiteLoading ? (
                                    <>
                                        <FolderOpen className={classes.fileIcon} />
                                        <Typography className={classes.notAvailable} variant="subtitle1">
                                            Suite does not exist!
                                        </Typography>
                                    </>
                                ) : (
                                    <CircularProgress className={classes.circularPrgress} size={50} thickness={3} />
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <TableFooter
                    count={testSuiteArray.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    applyCustomClasses
                    labelRowsPerPage="Suites per page:"
                    labelForCounter="Test Suites"
                    countAvatar={getCountAvatar(sideBarCount.num_of_test_suits)}
                    containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                />
                {openDisableDialog ? (
                    <DeleteAlertModal
                        open={openDisableDialog}
                        handleClose={(isConfirm) => {
                            this.disableSelectedSuites(isConfirm, true);
                        }}
                        modalfor="disableSuites"
                        name="Test Suites"
                        title="Test Suite Name"
                        list={this.state.selectedSuitesName}
                        deleteButton="Danger"
                    />
                ) : null}
                {openEnableDialog ? (
                    <DeleteAlertModal
                        open={openEnableDialog}
                        handleClose={(isConfirm) => {
                            this.disableSelectedSuites(isConfirm, false);
                        }}
                        modalfor="enableSuites"
                        name="Test Suites"
                        title="Test Suite Name"
                        list={this.state.selectedSuitesName}
                    />
                ) : null}

                {openDeleteDialog ? (
                    <DeleteAlertModal
                        deleteButton="Danger"
                        open={openDeleteDialog}
                        handleClose={this.deleteSelectedSuites}
                        modalfor="multiDelete"
                        name="Test Suites"
                        title="Test Suite Name"
                        list={this.state.selectedSuitesName}
                    />
                ) : null}

                {executeDialogOpen ? (
                    <ExecuteScriptModal
                        projectId={selectedSuite.projectId}
                        name={selectedSuite.testSuiteName}
                        modalState={executeDialogOpen}
                        callingFrom={'Execute'}
                        handleClose={this.hangdleCloseExecuteDialog}
                        suite={selectedSuite}
                        type="suite"
                        aiqExecution={this.props.project.aiqExecution}
                    />
                ) : null}
            </div>
        );
    };

    changeToggle = (disabledSuiteflag) => {
        this.setState({ showDisabledTestSuites: disabledSuiteflag });
    }

    renderItems = (suite, classes) => {
        const isNonGeneratedTestCaseExist = suite?.testCases?.some(testCase => !testCase.isScriptAvailable);
        const tooltipMsg = isNonGeneratedTestCaseExist ? 'You cannot execute this suite because it contains non-generated testcase' : '';

        /* "0": "INPROGRESS", "1": "SUCCESS", "2": "FAILED", */
        let items = [
            {
                icon: this.subHeader('EDIT', false), // <div className={classes.actionEditTitleContainer}>
                isDisabled: true,
            },
            {
                action: () => this.toggleMergeModal(true, suite),
                icon: (
                    <FontAwesomeIcon
                        style={{ fontSize: THREE_DOT_MENU_CSS.faIconFontSize, padding: '1px' }}
                        icon={faPencilAlt}
                        aria-label="faPencilAlt"
                        id="faPencilAlt"
                        className={classes.moreIcons}
                    />
                ), // <Edit className={classes.moreIcons} />,
                isDisabled: false,
                name: ' Update Suite',
            },
            {
                action: () => {
                    this.setState({ open: null, openedId: null });
                    TestSuiteUtils.cloneTestSuite(suite, 'plan');
                },
                icon: <Clone className={classes.moreIcons} aria-label="closeIcon" id="closeIcon" />,
                isDisabled: false,
                name: ' Clone Suite',
            },
            // {
            //     action: () => null, //this.toggleModall('Email',suite),
            //     icon: <Mail className={classes.moreIcons} />,
            //     isDisabled: false,
            //     name: ' Email project group',
            // },
            {
                // if aiqExecution and isgenerating true and it should not
                // for selenium, if script exist, it should be enabled always
                action: () => this.openExecutionModal(suite),
                icon: <FlashOn className={classes.moreIcons} style={{color: isNonGeneratedTestCaseExist ? '#bbbbbb' : ''}} aria-label="flashOnIcon" id="flashOnIcon" />,
                isDisabled: !checkArrayLength(suite.testCases) || isNonGeneratedTestCaseExist, // https://github.com/Autonomiq/WebUIRedux/issues/1914
                name: <Tooltip data={tooltipMsg}>Execute</Tooltip>,
            },
        ];

        const disabledOption = {
            action: () => {
                this.onSuiteMenuClick(suite, 1);
            },
            icon: <Disable className={classes.moreIcons} aria-label="disableIcon" id="disableIcon" />,
            isDisabled: suite.disabledStatus,
            name: ' Disable Suite',
        };
        const restoredOption = {
            // action: () => {
            //     this.onSuiteMenuClick(suite, 2);
            // }, //this.toggleModall('Restore',suite),
            // icon: <Restore className={classes.moreIcons} aria-label="restoreIcon" id="restoreIcon" />,
            // isDisabled: !suite.disabledStatus,
            // name: ' Enable suite',
        };
        const deleteOption = {
            action: () => {
                this.onSuiteMenuClick(suite, 0);
            },
            icon: (
                <FontAwesomeIcon
                    style={{ height: THREE_DOT_MENU_CSS.faDeleteIconHeight, width: THREE_DOT_MENU_CSS.faDeleteIconWidth, padding: '1px' }}
                    icon={faTrashAlt}
                    aria-label="faTrashAlt"
                    id="faTrashAlt"
                    className={classes.moreIcons}
                />
            ),
            isDisabled: false,
            name: ' Delete Suite',
        };

        // if already disabled then we need to restore option only
        if (suite.disabledStatus === true) items = [...items, restoredOption, deleteOption];
        // if not disabled then we need to disabled option only
        else if (!suite.disabledStatus) items = [...items, disabledOption, deleteOption];

        const gotoItems = [
            {
                icon: this.subHeader('GO TO', true), // <div className={classes.actionEditTitleContainer}>
                isDisabled: true,
            },
            {
                isLink: true,
                route: `/projects/${suite.projectId}/suites`,
                icon: <Export className={classes.moreIcons} />,
                isDisabled: !suite.projectId,
                name: ' Project details',
            },
        ];

        items = [...items, ...gotoItems];

        return items;
    };

    render() {
        const { deleteDialogOpen, deleteTestSuiteData, isDisableCase, deleteCaseDialogOpen, deleteTestCaseData, showDisabledTestSuites } = this.state;
        const { classes, openTaskPanel, testSuiteWithAccount } = this.props;
        return (
            <div>
                {deleteDialogOpen ? (
                    <DeleteTestSuiteDialog
                        deleteButton="Danger"
                        handleClose={this.handleCloseDeleteDialog}
                        open={deleteDialogOpen}
                        deleteTestSuiteData={deleteTestSuiteData}
                        isDisableCase={isDisableCase}
                    />
                ) : null}
                {deleteCaseDialogOpen ? (
                    <DeleteTestCaseDialog
                        deleteButton="Danger"
                        handleClose={this.handleCloseTestCaseDeleteDialog}
                        open={deleteCaseDialogOpen}
                        deleteTestCaseData={deleteTestCaseData}
                        isDisableCase={isDisableCase}
                    />
                ) : null}
                { testSuiteWithAccount.length > 0 ? (<div style={{ marginLeft: openTaskPanel ? '32px' : '10px' }} className={classes.headerContainer}>
                    <div style={{ zIndex: 2 }}>
                    <span style = {{marginRight: '11px' }}>Show Disabled Test Suites</span>
                        <Tooltip data="Show Disabled Suites">
                            <CustomSwitch checked={showDisabledTestSuites} onChange={ () => {this.changeToggle(!showDisabledTestSuites); }}  value="isShowDisabled" />
                        </Tooltip>
                    </div>
                </div>) : null }

                {this.tableBodyRender()}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        // auth reducer
        user: state.authReducer.user,
        // general reducer
        query: state.generalReducer.queryValue,
        // project reducer
        project: state.projectReducer.selectedProject,
        projectsByIdAndName: state.projectReducer.projectsByIdAndName,
        sideBarCount: state.sidebarReducer.sideBarCount,
        testSuiteWithAccount: state.projectReducer.testSuiteWithAccount,
        testCasesWithAccount: state.testCaseReducer.testCasesWithAccount,
        suiteState: state.projectReducer.suiteState,
        // general modal reducer
        currentTab: state.generalModalReducer.currentTab,
        tabsData: state.generalModalReducer.tabsData,
        //  suitereport reducer
        isSuiteLoading: state.suiteReportReducer.isLoading,
        openTaskPanel: state.generalReducer.openTaskPanel,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        associateTestSuites: (...args) => dispatch(TestSuiteActions.associateTestSuites(...args)),
        getTestSuitesWithAccount: () => dispatch(TestSuiteActions.getTestSuitesWithAccount()),
        getTestCasesWithAccount: (...args) => dispatch(TestCaseActions.getTestCasesWithAccount(...args)),
        editTestSuites: (...args) => dispatch(TestSuiteActions.editTestSuites(...args)),
        updateTestSuitesDataPropPlan: (data) => dispatch(ProjectActions.updateTestSuitesDataPropPlan(data)),
        handleTestSuitesCasesDirections: (data) => dispatch(ProjectActions.handleTestSuitesCasesDirections(data)),
        saveTestSuiteCasesOrder: (order, suiteId, onComplete) => dispatch(ProjectActions.saveTestSuiteCasesOrder(order, suiteId, onComplete)),
        deleteTestCaseFromSuite: (suiteId, TestCaseIds) => dispatch(ProjectActions.deleteTestCaseFromSuite(suiteId, TestCaseIds)),
        storeSuiteState: (states) => dispatch(ProjectActions.storeSuiteState(states)),
        clearSuiteState: () => dispatch(ProjectActions.clearSuiteState()),
        getProjectByIdAndName: (...args) => dispatch(ProjectActions.getProjectByIdAndName(...args)),
        clearSnackBar: () => dispatch(ModalActions.clearSnackbar()),

        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        changeTab: (...args) => dispatch(generalModalActions.changeTab(...args)),
        clearGeneralModal: (...args) => dispatch(generalModalActions.clearGeneralModal(...args)),
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TestSuite));
