import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import { generalModalActions } from '../../store/actions';
import Filedrop from '../Filedrop/Filedrop';
import '../../features/modal/Stepper/uploadWizardScroll.css';
import { BLACK_FONT } from '../../common/cssConstants';

const styles = (theme) => ({
    countWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            marginTop: '3px',
            marginBottom: '5px',
        },
    },
    testCountStyle: {
        marginTop: '2%',
        color: BLACK_FONT,
        fontSize: 12,
    },
    text: {
        color: BLACK_FONT,
        fontSize: 12,
    },
    mainDialog: {
        padding: '0px 30px !important',
        maxWidth: '600px',
        maxHeight: 'calc(100vh - 263px)',
        [theme.breakpoints.up('sm')]: {
            minWidth: '550px',
        },
        [theme.breakpoints.only('xs')]: {
            minHeight: 'calc(100vh - 265px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
    },
    fileWizardRoot: {
        width: '100%',
        marginRight: 15,
        border: '2px dashed #979797',
        paddingBottom: 5,
        paddingLeft: 5,
    },
});

class UploadProject extends Component {
    state = {
        uploadedFiles: [],
    };

    componentWillUnmount() {
        this.props.clearModalData();
    }

    onFileDropCallback = ([...files]) => {
        files.forEach((file) => {
            const fileExtension = `.${file.name.split('.').pop()}`;
            if (!(this.checkFileType(file.type) || this.checkFileExtension(fileExtension))) {
                // do something for ignored files
            }
            if (this.checkFileType(file.type) || this.checkFileExtension(fileExtension)) {
                this._setState({ uploadedFiles: [...files] });
            }
        });
    };

    checkFileType = (type) => {
        const allowedFileTypes = ['application/zip'];
        return allowedFileTypes.indexOf(type) > -1;
    };

    checkFileExtension = (ext) => {
        const allowedFileExtensions = ['.zip'];
        return allowedFileExtensions.indexOf(ext) > -1;
    };

    _setState = (_state, cb = () => {}) => {
        this.setState(
            {
                ..._state,
            },
            () => {
                cb();
                this.props.updateTabData('uploadedProject', [...this.state.uploadedFiles]);
            },
        );
    };

    render() {
        const { classes } = this.props;
        const { uploadedFiles } = this.state;
        return (
            <DialogContent className={[classes.mainDialog, 'scrollContainer'].join(' ')}>
                <div className={classes.fileWizardRoot}>
                    <Filedrop
                        config={{
                            dropCallback: this.onFileDropCallback,
                            step: 'projectFile',
                            text: 'Select zip File',
                            disabled: false,
                        }}
                        innerStyle={{
                            margin: '40px 0 25px 0',
                        }}
                        isMultiple={false}
                    />
                    <div className={`${classes.countWrapper}`}>
                        <Typography className={classes.testCountStyle}>
                            {uploadedFiles && uploadedFiles.length ? `Selected File : ${uploadedFiles[0].name}` : 'No File is Selected'}
                        </Typography>
                    </div>
                </div>
            </DialogContent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
        clearModalData: (...args) => dispatch(generalModalActions.clearGeneralModal(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UploadProject));
