// import material
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { THREE_DOT_MENU_CSS } from '../../common/cssConstants';
import { MenuOptions } from '../Button/MenuOptions';

// import icons material

const styles = () => ({
    moreIcons: {
        color: THREE_DOT_MENU_CSS.iconColor,
        marginRight: 6,
        marginLeft: 8,
        fontSize: 18,
    },
});

class DownloadModal extends React.Component {
    renderDisplayItems = () => {
        const {handleSubmit, projectName, classes, calledFrom, dataSet, selectedFlow } = this.props;
        const items = [];

        items.push({
            action: () => {
                handleSubmit('csv', { projectName });
            },
            key: 'button1',
            icon: (
                <FontAwesomeIcon
                    className={classes.moreIcons}
                    icon={faFileCsv}
                    aria-label="faFileCsv"
                    id="faFileCsv"
                    style={{ fontSize: 16, marginRight: 7, marginLeft: 13 }}
                />
            ),
            name: 'Download CSV',
        });
        items.push({
            action: () => {
                handleSubmit('xlsx', { projectName });
            },
            key: 'button2',
            icon: (
                <FontAwesomeIcon
                    className={classes.moreIcons}
                    icon={faFileExcel}
                    aria-label="faFileExcel"
                    id="faFileExcel"
                    style={{ fontSize: 16, marginRight: 7, marginLeft: 13 }}
                />
            ),
            name: 'Download XLSX',
            excelDownloadBtnData:
                calledFrom === 'flow'
                    ? {
                          selectedFlow,
                          dataSet,
                          fileName: 'Flows',
                          sheetName: { projectName },
                      }
                    : null,
        });
        return items;
    };

    render() {
        const { keepMounted, containerStyle, btn } = this.props;
        return <MenuOptions items={this.renderDisplayItems()} keepMounted={keepMounted} containerStyle={containerStyle} btn={btn} />;
    }
}

DownloadModal.propTypes = {
    btn: PropTypes.any.isRequired,
    keepMounted: PropTypes.bool,
    handleSubmit: PropTypes.func,
    projectName: PropTypes.string,
    calledFrom: PropTypes.string,
};

DownloadModal.defaultProps = {
    keepMounted: false,
    handleSubmit: () => {},
    projectName: '',
    calledFrom: '',
};

const CustomDownloadModal = withStyles(styles)(DownloadModal);

export { CustomDownloadModal };
