import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '../Tooltip';

// icon(s)

const styles = () => ({});

function showIconWithAction(progressIcon, ExecutionDetails, Executionid, showExecutionStatus, disabled,taskStatus,inprocess) {
    if (inprocess) {
        return (
            <Tooltip data="Execute INPROGRESS">
                <IconButton style={{ width: '18px', marginLeft: '-10%', height: '18px' }}>
                    {progressIcon}
                </IconButton>
            </Tooltip>
        );
    }
    if (disabled) {

        let data = "Execute SUCCESS";
        if(taskStatus === "STOPPED") {
            data = "Execution Stopped"
        }
        if(taskStatus === "QUEUED") {
            data = "Execution Queued"
        }
        

        return (
            <Tooltip data={data}>
                <IconButton style={{ width: '18px', marginLeft: '-10%', height: '18px' }} disabled={disabled} >
                    {progressIcon}
                </IconButton>
            </Tooltip>
        );
    }
    return (
        <Tooltip data="Execute ERROR">
            <IconButton
                style={{ width: '18px', marginLeft: '-10%', height: '18px' }}
                onClick={() => openModal(ExecutionDetails, Executionid, showExecutionStatus)}
            >
                {progressIcon}
            </IconButton>
        </Tooltip>
    );
}

async function openModal(ExecutionDetails, Executionid, showExecutionStatus) {
    const data = await ExecutionDetails(Executionid);
    showExecutionStatus(data, Executionid);
    return data;
}
function Status(props) {
    // executionId will be removed later and
    // videoUrl will be isRequired
    const { progressIcon, ExecutionDetails, Executionid, showExecutionStatus, disabled,taskStatus,inprocess } = props;
    return showIconWithAction(progressIcon, ExecutionDetails, Executionid, showExecutionStatus, disabled,taskStatus,inprocess);
}

Status.propTypes = {
    // progressIcon: PropTypes.shape({}),
    // ExecutionDetails: PropTypes.func,
    // Executionid: PropTypes.number,
    // showExecutionStatus: PropTypes.func,
};

export default withStyles(styles)(Status);
