import { ActionTypes } from '../constants';

class TaskActions {
    static updateProjectId(id) {
        return {
            type: ActionTypes.SET_NEW_PROJECT_ID,
            payload: id,
        };
    }

    static updateTestCaseId(id) {
        return {
            type: ActionTypes.SET_TESTCASE_ID,
            payload: id,
        };
    }
}

export default TaskActions;
