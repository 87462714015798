// import materials
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

// import material
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import { IconButton } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/AddCircle';
import Done from '@material-ui/icons/Done';
import React from 'react';
import { connect } from 'react-redux';
import { SortableContainer } from 'react-sortable-hoc';
import { EnhancedTableHead } from '../../../../components/EnhancedTable/index';
import Table from '../../../../components/Table/Table';
import TableBody from '../../../../components/Table/TableBody';
import TableRow from '../../../../components/Table/TableRow';
import TableCell from '../../../../components/Table/TableCell';

import Select from '../../../modal/GeneralModal/Select';

// import packages

import { BLACK_FONT } from '../../../../common/cssConstants';
import Tooltip from '../../../../components/Tooltip';
import { generalModalActions, TestSuiteActions } from '../../../../store/actions';
import { checkKeyInObject, sort, checkArrayLength, convertToSelectValues } from '../../../../utils/utils';
import SearchInput from '../../../modal/GeneralModal/SearchInput';
import EditTestSuiteDialogContent from './EditTestSuiteDialogContent';

// SuitePostAction
const styles = (theme) => ({
    dialogContentAddUser: {
        paddingBottom: 12,
        '&:first-child': {
            padding: 0,
        },
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    checkBox: {
        '&>span>svg': {
            fontSize: '20px',
        },
        height: '40px',
        width: '20px',
        marginRight: '10px',
    },
    checkBoxRow: {
        '&>span>svg': {
            fontSize: '20px',
        },
        height: '40px',
        width: '20px',
        marginRight: '40px',
    },
    horizontalRow: {
        width: 'calc(100% + 55px)',
        marginLeft: '-55px',
        border: 0,
        borderTop: '1px solid #DCDCDC',
    },
    label: {
        alignSelf: 'center',
        width: 90,
        '& > p': {
            color: BLACK_FONT,
        },
    },
    rootDialogContent: {
        width: '600px',
        overflowX: 'hidden',
    },
    row: {
        padding: '0px 0px 5px 30px',
        display: 'flex',
        justifyContent: 'start',
    },
    searchInput: {
        marginRight: '20px',
        width: 250,
    },
    cellRole: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: '4px 0px 0px 10px',
    },
    tableRows: {
        cursor: 'pointer',
        backgroundColor: '#f6f6f6',
    },
    cell: {
        paddingLeft: '20px',
    },
    inputField: {
        padding: '0px 0px 2px 0px',
        fontSize: '12px',
    },
    barIcon: {
        color: '#F39B31',
        height: '15px',
        width: '20px',
        marginRight: '10px',
        cursor: 'not-allowed',
    },
    sortIcon: {
        fontSize: 10,
        marginLeft: 10,
        color: '#9B9B9B',
    },
    extraPadding: {
        paddingLeft: '10px !important',
        borderBottom: '2px solid rgba(224, 224, 224, 1)',
    },
    tableCont: {
        [theme.breakpoints.only('xs')]: {
            overflowX: 'scroll',
            maxWidth: '100%',
        },
    },
    table: {
        width: '100%',
        minWidth: 600,
        // display: 'table',
        borderSpacing: 0,
        borderCollapse: 'collapse',
        padding: 0,
        listStyle: 'none',
        overflow: 'hidden',
        margin: '-8px 0 0 0',
    },
    tableHeadTR: {
        height: '40px',
        color: 'inherit',
        // display: 'table-row',
        outline: 'none',
        verticalAlign: 'middle',
    },
    tableBodyTR: {
        color: 'inherit',
        // display: 'table-row',
        outline: 'none',
        verticalAlign: 'middle',
    },
    tableTH: {
        // display: 'table-cell',
        textAlign: 'left',
        verticalAlign: 'inherit',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        fontWeight: '500',
        height: '40px',
        display: 'inline-flex',
        '& span:first-child': {
            display: 'flex',
        },
    },
    textField: {
        backgroundColor: '#fff',
        border: '1px solid #ececec',
        borderRadius: '3px',
        '&>div>input': {
            height: 18,
            width: 150,
            padding: '6px 4px 7px',
            fontSize: 12,
            fontWeight: 500,
        },
    },
    tableTD: {
        // display: 'table-cell',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        verticalAlign: 'inherit',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.8125rem',
        fontWeight: '400',
        height: '40px',
        display: 'inline-flex',
    },
    selectedItem: {
        zIndex: '999999999 !important',
        listStyle: 'none',
        boxShadow: '3px 2px 11px 0px #cdcdcd, -3px -2px 11px 0px #cdcdcd',
    },
    dragAble: {
        '& .dragAble': {
            '& svg': {
                cursor: 'all-scroll !important',
            },
        },
    },
    iconBtn: {
        marginLeft: '11px',
        fontSize: '16px',
        color: '#3B91DF',
    },
    errorMessage: {
        fontSize: 12,
        color: 'red',
        marginLeft: 30,
        marginTop: 5,
        display: 'block',
    },
    testSuiteName: {
        color: BLACK_FONT,
        fontSize: 16,
        paddingLeft: 30,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '30px',
        marginBottom: 5,
    },
    testSuiteNameText: {
        color: BLACK_FONT,
        fontSize: 16,
        paddingLeft: 30,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '30px',
        maxWidth: '90%',
    },
    wrapperNameIcon: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    wrapperTrashIcon: {
        marginRight: 30,
    },
    select: {
        backgroundColor: 'white',
        borderRadius: 3,
        padding:'0px 8px 0px 8px',
        width: '90%',
        '&>div': {
            borderBottom: 'none !important',
            transition: 'none !important',
        },
    },
    closeIcon: {
        color: '4A4A4A',
        fontSize: 16,
    },
    closeIconButton: {
        height: 16,
        width: 16,
    },
    editIconButton: {
        height: 16,
        width: 16,
    },
    sortableWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 30px',
        height: 25,
    },
    tableItem: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    errorTextFieldBorder: {
        border: '3px solid #ff000099',
    },
    deleteTooltipWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 30px 0px 30px',
        height: 25,
    },
    alignSpan: {
        display: 'flex',
        alignItems: 'center',
    },
    typography:{
        fontSize: 12, 
        margin: '0 0 5px 30px',
    }
});

const headers = [
    { id: 'projectName', sortable: true, numeric: false, disablePadding: true, label: 'Project', width: '20%', styles: { width: '20%' } },
    {
        id: 'suiteInfo',
        sortable: false,
        numeric: false,
        disablePadding: true,
        label: 'Suite',
        width: 'calc(60% / 3)',
        styles: { width: 'calc(60% / 3)' },
    },
    {
        id: 'conditions',
        sortable: false,
        numeric: false,
        disablePadding: true,
        label: 'Conditions',
        width: 'calc(60% / 3)',
        styles: { width: 'calc(60% / 3)' },
    },
    {
        id: 'delay',
        sortable: false,
        numeric: false,
        disablePadding: true,
        label: 'Delay',
        width: 'calc(60% / 3)',
        styles: { width: 'calc(60% / 3)' },
    },
    { id: 'action', sortable: false, numeric: false, disablePadding: true, label: '', width: '10%', styles: { width: '10%' } },
];

let isMounted = false;
class SuitePostAction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: '',
            didChange: false,
            isFirst: false,
            editSuiteName: '',
            addRowData: [
                {
                    projectName: '',
                    objectName: '',
                    objectId: '',
                    triggerCondition: '',
                    delay: '',
                    delayUnit: '',
                    id: Math.random(),
                },
            ],
            originalTabData: [],
            isUpdate: false,
            editCell: '',
            testSuiteName: '',
            order: 'asc',
            orderBy: 'projectName',
        };
    }

    componentDidMount() {
        const { getProjectAndTestSuiteData } = this.props;
        isMounted = true;
        let editSuiteName = '';

        getProjectAndTestSuiteData();
        if (this.props.isEditModal || checkKeyInObject(this.props.tabsData.suiteInfo, 'state')) {
            const tempSuiteName = checkKeyInObject(this.props.tabsData.suiteInfo, 'state') ? this.props.tabsData.suiteInfo.state : '';
            editSuiteName = checkKeyInObject(tempSuiteName, 'testSuiteName') ? tempSuiteName.testSuiteName : '';
        }
        if (this.props.isEditModal || checkKeyInObject(this.props.tabsData.postAction, 'state')) {
            const postAction = checkKeyInObject(this.props.tabsData.postAction, 'state')
                ? this.props.tabsData.postAction.state.originalTabData
                : this.props.postActionData.peTriggers;
            let tempPostData = [];

            if (postAction && postAction.length > 0) {
                tempPostData = postAction.map((data) => {
                    return {
                        projectName: data.projectName,
                        objectName: data.objectName,
                        objectId: data.objectId.toString(),
                        triggerCondition: data.triggerCondition.toLowerCase(),
                        delay: data.delay.toString(),
                        delayUnit: data.delayUnit,
                        id: Math.random(),
                    };
                });
            }

            if (tempPostData.length > 0) {
                this.setStateIfComponentMounted({
                    addRowData: [this.state.addRowData[0], ...tempPostData],
                    originalTabData: tempPostData,
                    didChange: false,
                    isFirst: true,
                    editSuiteName,
                });
            } else {
                this.setStateIfComponentMounted({
                    editSuiteName,
                });
            }
        }
    }

    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     //const {getProjectAndTestSuiteData } = nextProps;
    //     //getProjectAndTestSuiteData();

    // }

    componentWillUnmount() {
        isMounted = false;
        this.props.updateTabData('postAction', { state: { ...this.state } });
        this.props.checkIfNameExist(true)
    }

    onDeleteTestSuite = () => {
        const { addRowData } = this.state;
        let tempDataArray = [];
        let tempOriginalData = [];

        tempDataArray = addRowData.filter((_proj) => _proj.checked !== true);
        tempOriginalData = tempDataArray.filter((_proj) => !!_proj.projectName);

        this.setStateIfComponentMounted({
            addRowData: [...tempDataArray],
            originalTabData: tempOriginalData,
        });
    };

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted)
            this.setState({ didChange: this.state.isFirst, ...obj }, () => {
                callback();
                this.props.updateTabData('postAction', { state: { ...this.state } });
            });
    };

    getSelectedItems = (selectedItem, ind, itemName, suite_id = 0) => {
        const { addRowData } = this.state;
        const _addRowData = JSON.parse(JSON.stringify(addRowData));

        _addRowData[ind][itemName] = selectedItem;
        if (`${suite_id}` !== '0') {
            _addRowData[ind].objectId = suite_id;
        }

        this.setStateIfComponentMounted({ addRowData: _addRowData });
    };

    handleToggle = (id) => (e) => {
        const { addRowData } = this.state;
        const index = addRowData.map((_proj) => _proj.id).indexOf(id);
        addRowData[index] = { ...addRowData[index], checked: e.target.checked };

        this.setStateIfComponentMounted({ addRowData });
    };

    handleSearchUsers = (e) => {
        const query = e.target.value;
        this.setStateIfComponentMounted({ query });
    };

    // Use to Drag only selected steps

    clearSearch = () => {
        this.setStateIfComponentMounted({ query: '' });
    };

    handleChangeTextField = (event, ind) => {
        const { addRowData } = this.state;
        const obj = {};
        const _addRowData = JSON.parse(JSON.stringify(addRowData));
        _addRowData[ind][event.target.name] = event.target.value;

        this.setStateIfComponentMounted({
            addRowData: _addRowData,
            ...obj,
        });
    };

    handleEdit = (id) => {
        this.setStateIfComponentMounted({
            editCell: id,
            isUpdate: false,
        });
    };

    handleEditSave = (id, arr) => {
        const { addRowData } = this.state;
        const _addRowData = addRowData;
        let bool = true;
        const addTabClicked = false;
        const saveButtonClicked = true;
        for (let i = 0; i < arr.length; i++) {
            if (
                id === arr[i].id &&
                !(
                    arr[i].projectName.trim() &&
                    arr[i].objectName &&
                    arr[i].triggerCondition.trim() &&
                    parseInt(arr[i].delay, 10) >= 0 &&
                    arr[i].delayUnit
                )
            ) {
                const tempData = Object.keys(arr[i]).filter((item) => arr[i][item] === '' || !parseInt(arr[i].delay, 10));
                const error = tempData.length > 0 ? '*Please fill out missing/correct detail' : null;
                this.props.updateTabData('postAction', { state: { ...this.state, error, addTabClicked, saveButtonClicked } });
                bool = false;
                break;
            }
        }

        const tempDataArray = _addRowData.filter((_proj) => !!_proj.projectName);

        if (bool) {
            this.setStateIfComponentMounted({
                isUpdate: true,
                editCell: false,
                originalTabData: tempDataArray,
            });
        }
    };

    handleEditCancel = (id) => {
        const { originalTabData, addRowData } = this.state;
        let postActionDetails = {};
        let _addRowData = [];

        originalTabData.forEach((proj) => {
            if (proj.id === id) postActionDetails = proj;
        });

        _addRowData = addRowData.map((proj) => {
            if (`${proj.id}` === `${postActionDetails.id}`) {
                return postActionDetails;
            }
            return proj;
        });

        this.setStateIfComponentMounted({
            addRowData: _addRowData,
            isUpdate: true,
            editCell: false,
        });
    };

    addTabRow = (id, arr) => {
        const addTabClicked = true;
        const saveButtonClicked = false;
        let tempData = [];
        const val = {
            projectName: '',
            objectName: '',
            objectId: '',
            triggerCondition: '',
            delay: '',
            delayUnit: '',
            id: Math.random(),
        };
        let bool = true;
        for (let i = 0; i < arr.length; i++) {
            if (
                !(
                    arr[i].projectName.trim() &&
                    arr[i].objectName &&
                    arr[i].triggerCondition.trim() &&
                    parseInt(arr[i].delay, 10) >= 0 &&
                    arr[i].delayUnit
                )
            ) {
                tempData = Object.keys(arr[i]).filter((item) => arr[i][item] === '' || !parseInt(arr[i].delay, 10));
                const error = tempData.length > 0 ? '*Please fill out missing/correct detail' : null;
                this.props.updateTabData('postAction', { state: { ...this.state, error, addTabClicked, saveButtonClicked } });
                bool = false;
                break;
            }
        }

        if (bool) {
            this.setStateIfComponentMounted({
                addRowData: [val, ...this.state.addRowData],
                originalTabData: [...this.state.addRowData],
                isUpdate: true,
                didChange: true,
            });
        }
    };

    handleRequestSort = (property) => {
        const { addRowData } = this.state;
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        const _addRowData = addRowData.filter((_proj) => !!_proj.projectName);
        const currentPostAction = sort(_addRowData, order, orderBy);

        this.setStateIfComponentMounted({
            addRowData: [addRowData[0], ...currentPostAction],
            order,
            orderBy,
        });
    };

    errMsg = () => {
        const { classes, tabsData } = this.props;
        let error = null;
        const isError = checkKeyInObject(tabsData, 'suiteInfo.state.error') || checkKeyInObject(tabsData, 'postAction.state.error');
        if (isError) {
            error = <span className={classes.errorMessage}>{isError}</span>;
        }
        return error;
    };

    handleChange = (e) => {
        this.setStateIfComponentMounted({ testSuiteName: e.target.value });
    };

    selectProjectHandler = (selectedItem, ind, itemName, suite_id = 0) => {
        const { addRowData } = this.state;
        const _addRowData = JSON.parse(JSON.stringify(addRowData));

        _addRowData[ind][itemName] = selectedItem;
        if (`${suite_id}` !== '0') {
            _addRowData[ind].objectId = suite_id;
        }

        this.setStateIfComponentMounted({ addRowData: _addRowData });
    };

    render() {
        const { classes, suiteConditions, delayUnit, getProjectAndTestSuiteDetails, tabsData,checkIfNameExist } = this.props;
        const { query, addRowData, isUpdate, editCell, editSuiteName, testSuiteName, order, orderBy } = this.state;

        let addRowDataDetail = [];
        const tempProjectName = getProjectAndTestSuiteDetails ? getProjectAndTestSuiteDetails.filter((_proj) => _proj.suites.length !== 0) : [];
        const projectNames =
            tempProjectName.length > 0
                ? tempProjectName.map((proj, index) => {
                      return { id: index, value: proj.project_name };
                  })
                : [];
        const error = checkKeyInObject(tabsData, 'postAction.state.error', 'bool');
        const addClicked = checkKeyInObject(tabsData, 'postAction.state.addTabClicked', 'value', '');
        const saveButtonClicked = checkKeyInObject(tabsData, 'postAction.state.saveButtonClicked', 'value', '');

        if (query && checkArrayLength(addRowData)) {
            const searchInAddRow = checkArrayLength(addRowData)
                ? addRowData.filter((_data) => {
                      return (
                          _data &&
                          (_data.projectName.toLowerCase().includes(query.toLowerCase().trim()) ||
                              _data.objectName.toLowerCase().includes(query.toLowerCase().trim()) ||
                              _data.triggerCondition.toLowerCase().includes(query.toLowerCase().trim()) ||
                              _data.delayUnit.toLowerCase().includes(query.toLowerCase().trim()))
                      );
                  })
                : [];

            addRowDataDetail = [addRowData[0], ...searchInAddRow];
        } else {
            addRowDataDetail = addRowData;
        }

        const SortableList = SortableContainer(() => {
            return (
                <div className={classes.tableCont}>
                    <Table id="myTbl">
                        <EnhancedTableHead
                            headers={headers}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            currentPageData={addRowDataDetail}
                            numSelected={addRowDataDetail.length - 1}
                            rowCount={addRowDataDetail.length - 1}
                            calledFrom="postAction"
                            isColSpan
                            tableRowStyle={{ borderTop: '1px solid rgb(224, 224, 224)', boxSizing: 'border-box', padding: '0 30px' }}
                        />
                        <TableBody style={{ maxHeight: 'calc(100vh - 470px)' }}>
                            {addRowDataDetail.map((data, index, arr) => {
                                const editCellOnly = editCell === data.id;
                                const suiteInfo = tempProjectName ? tempProjectName.filter((_proj) => _proj.project_name === data.projectName) : [];
                                const notSameSuite =
                                    suiteInfo.length > 0 ? suiteInfo[0].suites.filter((_suite) => _suite.suite_name !== editSuiteName) : null;
                                const tempSuite =
                                    notSameSuite != null
                                        ? notSameSuite.map((suite) => {
                                              return {
                                                  id: suite.suite_id,
                                                  value: suite.suite_name,
                                                  is_suite_executable: suite.is_suite_executable
                                              };
                                          })
                                        : suiteInfo.length > 0
                                        ? suiteInfo[0].suites.map((suite) => {
                                              return {
                                                  id: suite.suite_id,
                                                  value: suite.suite_name,
                                                  is_suite_executable: suite.is_suite_executable
                                              };
                                          })
                                        : [];
                                const errorField = addClicked
                                    ? error && addClicked
                                    : saveButtonClicked
                                    ? error && editCellOnly && saveButtonClicked
                                    : null;
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        className={classes.tableRows}
                                        id={data.id}
                                        key={index}
                                        style={{ height: '50px', backgroundColor: '#f9f9f9', cursor: 'default', padding:'0 30px' }}
                                    >
                                        <TableCell
                                            // width={headers[0].width}
                                        >
                                            {index !== 0 ? (
                                                <Checkbox
                                                    checked={data.checked === true}
                                                    color="primary"
                                                    disableRipple
                                                    onClick={this.handleToggle(data.id)}
                                                    style={{
                                                        color: '#F39B31',
                                                    }}
                                                    className={classes.checkBox}
                                                />
                                            ) : null}
                                        </TableCell>

                                        <TableCell  width="20%">
                                            {index === 0 || editCellOnly ? (
                                                <EditTestSuiteDialogContent
                                                    ariaLabel="project"
                                                    onChange={this.handleChange}
                                                    placeholder="project"
                                                    value={testSuiteName}
                                                    callingFrom="postAction"
                                                    searchValue={data.projectName}
                                                    index={index}
                                                    itemName="projectName"
                                                    isError={!data.projectName && errorField}
                                                    searchOnChange={(_data) => {
                                                        this.selectProjectHandler(_data.value, index, 'projectName');
                                                    }}
                                                    options={convertToSelectValues(projectNames)}
                                                />
                                            ) : (
                                                <Select
                                                    value={data.projectName}
                                                    onChange={(_data) => {
                                                        this.getSelectedItems(_data.value, index, 'projectName');
                                                    }}
                                                    callingFrom="postAction"
                                                    className={classes.select}
                                                    isBorder={false}
                                                    options={convertToSelectValues(projectNames)}
                                                    isMultiSelect={false}
                                                    readOnly
                                                    key={Math.random()}
                                                    isDisableSelect={index !== 0 && !editCellOnly}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell  width={headers[1].width}>
                                            {index === 0 || editCellOnly ? (
                                                <EditTestSuiteDialogContent
                                                    ariaLabel="suite"
                                                    onChange={this.handleChange}
                                                    placeholder="suite"
                                                    value={testSuiteName}
                                                    callingFrom="postAction"
                                                    searchValue={data.objectName}
                                                    index={index}
                                                    itemName="objectName"
                                                    suite_id={data.id}
                                                    isError={!data.objectName && errorField}
                                                    searchOnChange={(_data) => {
                                                        this.selectProjectHandler(_data.value, index, 'objectName', _data.id);
                                                    }}
                                                    options={convertToSelectValues(tempSuite)}
                                                />
                                            ) : (
                                                <Select
                                                    value={data.objectName}
                                                    onChange={(_data) => {
                                                        this.getSelectedItems(_data.value, index, 'objectName', _data.id);
                                                    }}
                                                    callingFrom="postAction"
                                                    className={classes.select}
                                                    isBorder={false}
                                                    options={convertToSelectValues(tempSuite)}
                                                    isMultiSelect={false}
                                                    readOnly
                                                    key={Math.random()}
                                                    isDisableSelect={index !== 0 && !editCellOnly}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell width={headers[2].width}>
                                            {index === 0 || editCellOnly ? (
                                                <EditTestSuiteDialogContent
                                                    ariaLabel="conditions"
                                                    onChange={this.handleChange}
                                                    placeholder="condition"
                                                    value={testSuiteName}
                                                    callingFrom="postAction"
                                                    searchValue={data.triggerCondition}
                                                    index={index}
                                                    itemName="triggerCondition"
                                                    isError={!data.triggerCondition && errorField}
                                                    searchOnChange={(_data) => {
                                                        this.selectProjectHandler(_data.value, index, 'triggerCondition');
                                                    }}
                                                    options={convertToSelectValues(suiteConditions)}
                                                />
                                            ) : (
                                                <Select
                                                    value={data.triggerCondition}
                                                    onChange={(_data) => {
                                                        this.getSelectedItems(_data.value, index, 'triggerCondition');
                                                    }}
                                                    callingFrom="postAction"
                                                    className={classes.select}
                                                    isBorder={false}
                                                    options={convertToSelectValues(suiteConditions)}
                                                    isMultiSelect={false}
                                                    readOnly
                                                    key={Math.random()}
                                                    isDisableSelect={index !== 0 && !editCellOnly}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell  width="10%">
                                            <TextField
                                                id="delay"
                                                type="delay"
                                                name="delay"
                                                disabled={index !== 0 && !editCellOnly}
                                                autoComplete="off"
                                                InputProps={{ disableUnderline: true }}
                                                className={`${classes.textField} ${
                                                    !parseInt(data.delay, 10) && errorField ? classes.errorTextFieldBorder : ''
                                                }`}
                                                value={data.delay}
                                                onChange={(e) => this.handleChangeTextField(e, index)}
                                            />
                                        </TableCell>
                                        <TableCell  width="15%">
                                            {index === 0 || editCellOnly ? (
                                                <EditTestSuiteDialogContent
                                                    ariaLabel="delay"
                                                    onChange={this.handleChange}
                                                    placeholder="delayunit"
                                                    value={testSuiteName}
                                                    callingFrom="postAction"
                                                    searchValue={data.delayUnit}
                                                    index={index}
                                                    itemName="delayUnit"
                                                    isError={!data.delayUnit && errorField}
                                                    searchOnChange={(_data) => {
                                                        this.selectProjectHandler(_data.value, index, 'delayUnit');
                                                    }}
                                                    options={convertToSelectValues(delayUnit)}
                                                />
                                            ) : (
                                                <Select
                                                    value={data.delayUnit}
                                                    onChange={(_data) => {
                                                        this.getSelectedItems(_data.value, index, 'delayUnit');
                                                    }}
                                                    callingFrom="postAction"
                                                    className={classes.select}
                                                    isBorder={false}
                                                    options={convertToSelectValues(delayUnit)}
                                                    isMultiSelect={false}
                                                    readOnly
                                                    key={Math.random()}
                                                    isDisableSelect={index !== 0 && !editCellOnly}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell  width={headers[4].width}>
                                            <div className={classes.actionIconsContainer}>
                                                {index === 0 ? (
                                                    <IconButton className={classes.iconButtonRoot} onClick={() => this.addTabRow(data.id, arr)}>
                                                        <AddIcon className={`${classes.actionIcons} ${classes.disabledIcon}`} />
                                                    </IconButton>
                                                ) : isUpdate || `${data.id}` !== `${editCell}` ? (
                                                    <IconButton
                                                        onClick={() => {
                                                            this.handleEdit(data.id);
                                                        }}
                                                        aria-label="Edit"
                                                        id="editTestCase"
                                                        className={classes.editIconButton}
                                                    >
                                                        <Edit className={classes.moreIcons} />
                                                    </IconButton>
                                                ) : `${data.id}` === `${editCell}` ? (
                                                    <span style={{ display: 'flex' }}>
                                                        <Done
                                                            style={{
                                                                fontSize: 18,
                                                                cursor: 'pointer',
                                                                paddingLeft: '2px',
                                                            }}
                                                            aria-label="doneIcon"
                                                            id="doneIcon"
                                                            onClick={() => this.handleEditSave(data.id, arr)}
                                                        />
                                                        <Cancel
                                                            style={{
                                                                fontSize: 18,
                                                                cursor: 'pointer',
                                                                color: 'red',
                                                                paddingLeft: '2px',
                                                            }}
                                                            aria-label="cancelIcon"
                                                            id="cancelIcon"
                                                            onClick={() => this.handleEditCancel(data.id, arr)}
                                                        />
                                                    </span>
                                                ) : null}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            );
        });

        if(tabsData.suiteInfo.state.testSuiteName === '' ){
            checkIfNameExist(false)
        } else {
            checkIfNameExist(true)
        }

        return (
            <div>
                <form autoComplete="off">
                    <DialogContent classes={{ root: classes.dialogContentAddUser }}>
                        <div className={classes.testSuiteNameText}>
                            <Tooltip data={` ${checkKeyInObject(this.props.tabsData, 'suiteInfo.state.testSuiteName', 'value', '')}`}>
                                {checkKeyInObject(this.props.tabsData, 'suiteInfo.state.testSuiteName', 'value', '')}
                            </Tooltip>
                        </div>
                        <div>
                            {this.errMsg()}
                            <Typography className={classes.typography}>You cannot add suite containing non-generated test cases.</Typography>
                            <div className={classes.deleteTooltipWrapper}>
                                <span className={classes.alignSpan} />
                                <div>
                                    <Tooltip data="Delete">
                                        <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            aria-label="faTrashAlt"
                                            id="faTrashAlt"
                                            className={classes.iconBtn}
                                            onClick={() => this.onDeleteTestSuite()}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={classes.rootDialogContent}>
                                <hr className={classes.horizontalRow} />
                                <div className={classes.sortableWrapper}>
                                    <span className={classes.alignSpan} />

                                    <SearchInput
                                        id="SuitePostAction"
                                        placeholder="Search"
                                        onChange={(e) => this.handleSearchUsers(e)}
                                        value={query}
                                        style={{ width: 250 }}
                                        clearSearch={this.clearSearch}
                                    />
                                </div>
                                <hr className={classes.horizontalRow} />
                                <SortableList lockAxis="y" />
                            </div>
                        </div>
                    </DialogContent>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        testCases: state.projectReducer.testCases,
        tabsData: state.generalModalReducer.tabsData,
        getProjectAndTestSuiteDetails: state.projectReducer.getProjectAndTestSuiteData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
        getProjectAndTestSuiteData: (...args) => dispatch(TestSuiteActions.getProjectAndTestSuiteData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SuitePostAction));
