// import material
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

// import packages
import React from 'react';
import PropTypes from 'prop-types';

// import icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

// custom component
import { checkKeyInObject } from '../../../utils/utils';
import { AccountUtils, generateNumberOptionsForSelect } from '../../../utils';
import Select from '../../modal/GeneralModal/Select';
import { USER_NAME } from '../../../common/constants';

const items = [
    { id: 1, value: 'days', label: 'Days' },
    { id: 2, value: 'weeks', label: 'Weeks' },
    { id: 3, value: 'months', label: 'Months' },
    { id: 4, value: 'years', label: 'Years' },
];
const accountDefaultValues = AccountUtils.default;
// styles
const styles = () => ({
    baseTextStyle: {
        paddingLeft: '0px !important',
        color: '#494949',
        fontSize: '12px',
    },
    bodyWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    buttonContainer: {
        padding: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonSave: {
        background: '#0092e6',
        color: '#fff',
        textTransform: 'capitalize',
    },
    daysContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    daysDefault: {
        marginLeft: 3,
        lineHeight: '1.46429em',
    },
    expansionContent: {
        margin: '0 !important',
        cursor: 'default',
        paddingBottom: '10px',
    },
    expansionDetail: {
        padding: '20px 0px',
        display: 'block',
    },
    expandIconContainer: {
        margin: '-3px 15px 0px 0px',
        padding: 0,
        position: 'absolute',
        right: 0,
        cursor: 'pointer',
        top: 0,
    },
    expansionsummary: {
        height: 'auto !important',
        minHeight: 'auto !important',
        paddingLeft: '0px',
    },
    heading: {
        paddingLeft: '20px',
        fontWeight: 'bold',
        color: '#494949',
        fontSize: '12px',
    },
    headingContainer: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    input: {
        border: '1px solid #8898A8',
        height: 'auto',
        width: 50,
        borderRadius: 2,
        backgroundColor: '#FFFFFF',
        marginLeft: 10,
        marginRight: 10,
        '&>div>input': {
            textAlign: 'right',
            fontSize: 12,
            padding: '5px 10px 5px 0px',
        },
    },
    inputGroupContainer: {
        display: 'flex',
    },
    itemMainWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
        borderBottom: '1px solid #c5ced8',
        paddingTop: 20,
    },
    noBoxShadow: {
        backgroundColor: 'transparent',
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
    },
    select: {
        borderRadius: 3,
        width: '100%',
        height: '100%',
        '&>div': {
            borderBottom: 'none !important',
            transition: 'none !important',
        },
        '& input': {
            textAlign: 'center',
            height: '100%',
        },
    },
    flexStart: { display: 'flex', alignItems: 'flex-start' },
    rightSpace: {
        marginRight: 10,
    },
    expensionsummary: {
        height: 'auto !important',
        minHeight: 'auto !important',
        paddingLeft: '0px',
    },
    expensionContent: {
        margin: '0 !important',
        cursor: 'default',
        paddingBottom: '10px',
    },
    execsPerTestcaseContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    execsPerTestcaseDefault: {
        marginLeft: 3,
        marginRight: 3,
        lineHeight: '1.46429em',
    },
    expensionDetail: {
        padding: '20px 0px',
        display: 'block',
    },
    selectBoxContainer: {
        border: '1px solid #8898A8',
        borderRadius: 2,
        marginLeft: 10,
        marginRight: 10,
        paddingLeft: 5,
        width: 100,
    },
});

function AccountSettings({ actions, classes, data }) {
    const assetsExpand = data.expanded === 'deleteAssets';
    const executionExpended = data.expanded === 'execsPerTestcase';
    const videosExpanded = data.expanded === 'videoAssets';
    const isExecutionDataChange =
        data.execsPerTestcase &&
        `${data.account.execsPerTestcase}` !== `${data.execsPerTestcase}` &&
        !(Number(data.execsPerTestcase) < 5 || Number(data.execsPerTestcase) > 20);
    const APP_USER = `${data.activeUser.accountId}` === '1' && data.activeUser.name === USER_NAME;
    const ADMIN = data.activeUser.role === 'ADMIN';
    console.log("data",data);
    return (
        <div className={classes.bodyWrapper}>
            <Grid item xs={1} sm={1} md={2} lg={2} />
            <Grid item xs={10} sm={10} md={7} lg={7}>
                {APP_USER && (
                    <>
                        <div
                            className={classes.itemMainWrapper}
                            style={{ borderBottom: assetsExpand ? 'none' : '1px solid #BAC4CE', backgroundColor: assetsExpand ? 'white' : '' }}
                        >
                            <Grid item xs={4} sm={4} md={3} lg={3} className={classes.headingContainer}>
                                <Typography gutterBottom className={classes.heading}>
                                    Purge Execution Screenshots/Reports
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={9} lg={9}>
                                <Accordion expanded={assetsExpand} className={classes.noBoxShadow}>
                                    <AccordionSummary
                                        expandIcon={null}
                                        className={classes.expansionsummary}
                                        classes={{ content: classes.expansionContent }}
                                        style={{ borderBottom: assetsExpand ? '1px solid #BAC4CE' : 'none' }}
                                    >
                                        <div className={classes.daysContainer}>
                                            <Typography className={classes.baseTextStyle}>
                                                {'Purge execution screenshots and reports older than '}
                                            </Typography>
                                            {!assetsExpand ? (
                                                <div>
                                                    <span className={`${classes.baseTextStyle} ${classes.daysDefault}`}>
                                                        {data.daysLimit} {data.selectedDateSpan}
                                                    </span>
                                                <span
                                                aria-hidden
                                                onClick={() => actions.handleExpand('deleteAssets')}
                                                className={classes.expandIconContainer}
                                                >
                                                    <ExpandMoreIcon aria-label="ExpandMoreIcon" id="ExpandMoreIcon" />
                                                </span>
                                                </div>

                                                
                                            ) : (
                                                <div>
                                                <span className={classes.inputGroupContainer}>
                                                    <TextField
                                                        InputProps={{ disableUnderline: true, autoComplete: 'off' }}
                                                        name="daysLimit"
                                                        value={data.daysLimit}
                                                        onChange={(e) => actions.handleChangeTextVal(e, 'daysLimit')}
                                                        className={classes.input}
                                                        aria-label="SS/reports to delete"
                                                        id="ReportsToDelete"
                                                    />
                                                    <div className={classes.selectBoxContainer}>
                                                        <Select
                                                            value={data.selectedDateSpan}
                                                            onChange={(d) => {
                                                                actions.handleChangeDateSpan(d.value);
                                                            }}
                                                            className={classes.select}
                                                            isBorder={false}
                                                            isMultiSelect={false}
                                                            readOnly
                                                            options={items}
                                                            key={Math.random()}
                                                        />
                                                    </div>
                                                </span>
                                                
                                                <span
                                                aria-hidden
                                                onClick={() => actions.handleExpand('deleteAssets')}
                                                className={classes.expandIconContainer}
                                                >
                                                    <ExpandLessIcon aria-label="ExpandLessIcon" id="ExpandLessIcon" />
                                                </span>
                                                </div>

                                            )}
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.expansionDetail}>
                                        <Typography className={classes.baseTextStyle}>
                                            This will purge screenshots and reports for executions prior to the period mentioned above.
                                            <br />
                                            Purged screenshots and reports cannot be recovered.
                                        </Typography>
                                    </AccordionDetails>
                                    <div className={classes.buttonContainer}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={`${classes.buttonSave} ${classes.rightSpace}`}
                                            disabled={
                                                data.isAccountReducerLoading ||
                                                !data.daysLimit ||
                                                data.daysLimit < 1 ||
                                                (data.selectedDateSpan === 'days' &&
                                                    data.daysLimit <
                                                        1) /* || (data.daysLimit === accountDefaultValues.daysLimit && data.selectedDateSpan === accountDefaultValues.selectedDateSpan) */
                                            }
                                            onClick={() =>
                                                data.daysLimit &&
                                                !data.isAccountReducerLoading &&
                                                checkKeyInObject(actions, 'toggleDeleteModal') &&
                                                actions.toggleDeleteModal(true)
                                            }
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </Accordion>
                            </Grid>
                        </div>

                        <div
                            className={classes.itemMainWrapper}
                            style={{ borderBottom: videosExpanded ? 'none' : '1px solid #BAC4CE', backgroundColor: videosExpanded ? 'white' : '' }}
                        >
                            <Grid item xs={4} sm={4} md={3} lg={3} className={classes.flexStart}>
                                <Typography gutterBottom className={classes.heading}>
                                    Purge Videos
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={9} lg={9}>
                                <Accordion expanded={videosExpanded} className={classes.noBoxShadow}>
                                    <AccordionSummary
                                        expandIcon={null}
                                        className={classes.expansionsummary}
                                        classes={{ content: classes.expansionContent }}
                                        style={{ borderBottom: videosExpanded ? '1px solid #BAC4CE' : 'none' }}
                                    >
                                        <div className={classes.daysContainer}>
                                            <Typography className={classes.baseTextStyle}>Purge videos older than 15 days</Typography>
                                            <span aria-hidden onClick={() => actions.handleExpand('videoAssets')} className={classes.expandIconContainer}>
                                            {!videosExpanded ? (
                                                <ExpandMoreIcon aria-label="ExpandMoreIcon" id="ExpandMoreIcon" />
                                            ) : (
                                                <ExpandLessIcon aria-label="ExpandLessIcon" id="ExpandLessIcon" />
                                            )}
                                        </span>

                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails className={classes.expensionDetail}>
                                        <Typography className={classes.baseTextStyle}>
                                            This will purge execution videos prior to the period mentioned above.
                                            <br />
                                            Purged execution videos cannot be recovered
                                        </Typography>
                                    </AccordionDetails>
                                    <div className={classes.buttonContainer}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={`${classes.buttonSave} ${classes.rightSpace}`}
                                            // disabled={data.isAccountReducerLoading || !data.daysLimit || data.daysLimit < 3 || data.daysLimit > 30 || (data.daysLimit === accountDefaultValues.daysLimit && data.selectedDateSpan === accountDefaultValues.selectedDateSpan)}
                                            onClick={() =>
                                                data.daysLimit &&
                                                !data.isAccountReducerLoading &&
                                                checkKeyInObject(actions, 'toggleDeleteModal') &&
                                                actions.toggleDeleteModal(true, 'videos')
                                            }
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </Accordion>
                            </Grid>
                        </div>
                    </>
                )}

                {ADMIN && (
                    <div
                        className={classes.itemMainWrapper}
                        style={{ borderBottom: executionExpended ? 'none' : '1px solid #BAC4CE', backgroundColor: executionExpended ? 'white' : '' }}
                    >
                        <Grid item xs={4} sm={4} md={3} lg={3} className={classes.flexStart}>
                            <Typography gutterBottom className={classes.heading}>
                                Purge Execution Results
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={9} lg={9}>
                            <Accordion expanded={executionExpended} className={classes.noBoxShadow}>
                                <AccordionSummary
                                    expandIcon={null}
                                    className={classes.expensionsummary}
                                    classes={{ content: classes.expensionContent }}
                                    style={{ borderBottom: executionExpended ? '1px solid #BAC4CE' : 'none' }}
                                >
                                    <div className={classes.execsPerTestcaseContainer}>
                                        <Typography className={classes.baseTextStyle}>{'Save execution results only for the last '}</Typography>
                                        {!executionExpended ? (
                                            <span className={`${classes.baseTextStyle} ${classes.execsPerTestcaseDefault}`}>
                                                {data.execsPerTestcase}
                                            </span>
                                        ) : (
                                            <div className={classes.selectBoxContainer}>
                                                <Select
                                                    value={data.execsPerTestcase}
                                                    onChange={(d) => {
                                                        actions.handleChangeTextVal(d.value, 'execsPerTestcase');
                                                    }}
                                                    className={classes.select}
                                                    optionStyle = {{maxHeight:180}}
                                                    isBorder={false}
                                                    isMultiSelect={false}
                                                    readOnly
                                                    options={generateNumberOptionsForSelect(5, 20)}
                                                    key="Purge_Execution_Results"
                                                />
                                            </div>
                                        )}
                                        <Typography className={classes.baseTextStyle}>{' iterations'}</Typography>
                                        <span
                                        aria-hidden
                                        onClick={() => actions.handleExpand('execsPerTestcase')}
                                        className={classes.expandIconContainer}
                                    >
                                        {!executionExpended ? (
                                            <ExpandMoreIcon aria-label="ExpandMoreIcon" id="ExpandMoreIcon" />
                                        ) : (
                                            <ExpandLessIcon aria-label="ExpandLessIcon" id="ExpandLessIcon" />
                                        )}
                                    </span>

                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className={classes.expensionDetail}>
                                    <Typography className={classes.baseTextStyle}>
                                        Execution results will be saved only for the last set/number of iterations mentioned above.
                                        <br />
                                        Screenshots and reports will get purged for executions prior to that number.
                                    </Typography>
                                </AccordionDetails>
                                <div className={classes.buttonContainer}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={`${classes.buttonSave} ${classes.rightSpace}`}
                                        disabled={!isExecutionDataChange}
                                        onClick={() => {
                                            if (Number(data.execsPerTestcase) > 4 && checkKeyInObject(actions, 'handleSave')) {
                                                actions.handleSave();
                                            }
                                        }}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Accordion>
                        </Grid>
                    </div>
                )}
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} />
        </div>
    );
}

AccountSettings.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    actions: PropTypes.shape({
        handleChangeTextVal: PropTypes.func,
        handleChangeDateSpan: PropTypes.func,
        handleExpand: PropTypes.func,
        toggleDeleteModal: PropTypes.func,
        handleSave: PropTypes.func,
    }),
    data: PropTypes.shape({
        daysLimit: PropTypes.string,
        expanded: PropTypes.string,
        selectedDateSpan: PropTypes.string,
        execsPerTestcase: PropTypes.string,
        account: PropTypes.shape({}),
    }),
};

AccountSettings.defaultProps = {
    actions: {
        handleChangeTextVal: () => {},
        handleChangeDateSpan: () => {},
        handleExpand: () => {},
        toggleDeleteModal: () => {},
        handleSave: () => {},
    },
    data: {
        expanded: '',
        execsPerTestcase: 10,
        account: {},
        ...accountDefaultValues,
    },
};

export default withStyles(styles)(AccountSettings);
