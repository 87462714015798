// import packages
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ContentLoader from '../../../../components/ContentLoader';

const styles = () => ({
    root: {
        borderBottom: '1px solid #e0e0e0',
        width: '100%',
    },
    screenView: {
        height: 123,
        padding: '16px 0px 16px 20px',
        display: 'flex',
        alignItems: 'center',
    },
    listView: {
        height: 37,
        paddingLeft: 56,
        display: 'flex',
        alignItems: 'center',
    },
    span: {
        marginLeft: 26,
        display: 'inline-block',
    },
});

const LoadingStepRow = ({ classes, listView, itemKey, style = {}, length = 1 }) => {
    const item = (height, width) => (
        <span className={classes.span}>
            <ContentLoader height={height} width={width} />
        </span>
    );
    const data = [];
    // this needs to do because flows and compound statement
    // will not work for single line
    if (listView) {
        for (let i = 0; i < length; i++) {
            const skeleton = (
                <div key={`${itemKey}_${i}`} className={classes.listView}>
                    <ContentLoader height={22} width={400} />
                </div>
            );
            data.push(skeleton);
        }
    } else {
        for (let i = 0; i < length; i++) {
            const skeleton = (
                <div key={`${itemKey}_${i}`} className={classes.screenView}>
                    {item(22, 58)}
                    {item(91, 148)}
                    {item(45, 200)}
                </div>
            );
            data.push(skeleton);
        }
    }
    return (
        <div key={itemKey} style={style}>
            {data}
        </div>
    );
};

export default withStyles(styles)(LoadingStepRow);
