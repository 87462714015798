import isArray from 'lodash/isArray';
import { ActionTypes } from '../constants';
import { TagMiddleware } from '../middleware';

class TagActions {
    static createTag(obj) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.CREATE_TAG });
            try {
                const { data, status } = await TagMiddleware.createTag(obj);
                if (status) {
                    dispatch({
                        type: ActionTypes.CREATE_TAG_SUCCESS,
                        payload: data,
                    });
                    return data;
                }
                throw data;
            } catch (error) {
                dispatch({
                    type: ActionTypes.CREATE_TAG_FAILURE,
                    payload: {
                        error,
                        message: 'unable to create tag',
                    },
                });
                return false;
            }
        };
    }

    static updateTag(obj) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.UPDATE_TAG });
            TagMiddleware.updateTag(obj)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.UPDATE_TAG_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.UPDATE_TAG_FAILURE,
                        payload: {
                            error,
                            message: 'unable to update tag',
                        },
                    });
                });
        };
    }

    static getTags(projectId = null) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.GET_TAGS });
            try {
                const data = await TagMiddleware.getTags(projectId);
                if (isArray(data))
                    return dispatch({
                        type: ActionTypes.GET_TAGS_SUCCESS,
                        payload: data,
                    });
                throw new Error('error');
            } catch (error) {
                return dispatch({
                    type: ActionTypes.GET_TAGS_FAILURE,
                    payload: {
                        message: 'tags not found',
                    },
                });
            }
        };
    }

    static addRemoveCases_Tag(testCaseIds, tag_id, action) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.ADD_REMOVE_CASES_TAG,
                payload: {
                    testCaseIds,
                    tag_id,
                    _action: action,
                },
            });
        };
    }

    static tagTestCase(obj) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.TAGGED_TESTCASE });
            try {
                const status = await TagMiddleware.tagTestCase(obj);
                if (status) {
                    dispatch({
                        type: ActionTypes.TAGGED_TESTCASE_SUCCESS,
                        payload: obj,
                    });
                    return true;
                }
            } catch (error) {
                dispatch({
                    type: ActionTypes.TAGGED_TESTCASE_FAILURE,
                    payload: {
                        error,
                        message: 'tags not found',
                    },
                });
                return false;
            }
            return false;
        };
    }

    static untagTestCase(obj) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.UN_TAGGED_TESTCASE });
            try {
                const status = await TagMiddleware.untagTestCase(obj);
                if (status) {
                    dispatch({
                        type: ActionTypes.UN_TAGGED_TESTCASE_SUCCESS,
                        payload: obj,
                    });
                    return true;
                }
            } catch (error) {
                dispatch({
                    type: ActionTypes.UN_TAGGED_TESTCASE_FAILURE,
                    payload: {
                        error,
                        message: 'tags not found',
                    },
                });
                return false;
            }
            return false;
        };
    }
}

export { TagActions };
