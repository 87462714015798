/* eslint-disable no-throw-literal */
import HttpBase from '../../services/HttpBase';

class TestCase extends HttpBase {
    //   Test Case Section
    async getTestCasesWithAccount(accountId, userId) {
        try {
            const result = await this.httpClient.get(`/discovery/${userId}/${accountId}/testcases`);

            if (result && result.data && result.status === 200) {
                return result.data;
            }
            throw 'No Test Case Found';
        } catch (error) {
            if (error.response) {
                throw error.response.statusText;
            }
            throw error;
        }
    }

    async deleteTestCase(testcaseIds) {
        const { status } = await this.httpClient.post('/testCases/delete', { testcaseIds });
        if (status === 200) {
            return true;
        }
        throw 'Unable to delete test case';
    }

    async getTestCaseGenerationStatus(testcaseId) {
        try {
            const result = await this.httpClient.get(`/v1/testcases/${testcaseId}/isgenerating`);
            if (result.status === 200) {
                return result.data;
            }
            return false; // testCase is not running
        } catch (error) {
            return false;
        }
    }

    async updateTestCaseName(testCaseData) {
        const { testCaseId, name, desc } = testCaseData;
        const result = await this.httpClient.post(`/testCases/update/${testCaseId}`, { name, desc });
        if (result.status >= 200 && result.status <= 300) {
            return result.data;
        }
        return null;
    }

    async disableTestCases(TestCaseIds, type) {
        const data = {
            testcaseIds: TestCaseIds,
        };
        let api = '';
        // Todo : Add Proper API for disable]
        if (type === 1) {
            // Disable
            api = '/testCases/disable';
        } else if (type === 0) {
            // Restore
            api = '/testCases/enable';
        }
        const result = await this.httpClient.post(api, data);
        if (result.status === 200) {
            return result.data;
        }
        // Backend only returns an empty array if fails, sigh
        throw new Error(`Unable to disable test case with id: ${TestCaseIds}`);
    }

    async cloneTestCase(userId, caseId) {
        const result = await this.httpClient.post(`/testCases/${userId}/${caseId}/clone`, {});
        if (result.status === 200) {
            return result.data;
        }
        throw new Error('Unable to clone test case');
    }

    async mergeTestCases(newTestCaseName, testCaseIds, projectId) {
        try {
            let obj = {
                name: newTestCaseName,
                testcaseIds: testCaseIds,
            };
            obj = JSON.stringify(obj);
            const result = await this.httpClient.post(`/v1/projects/${projectId}/mergeTestCases`, obj);
            return result;
        } catch (error) {
            return error;
        }
    }

    async moveTestCase(testCaseId, testCaseName, testProjectId) {
        await this.httpClient.post(`/testCases/modify/${testCaseId}`, {
            testcaseName: testCaseName,
            discoverId: testProjectId,
        });
    }
}

const TestCaseMiddleware = new TestCase();
export { TestCaseMiddleware };
