import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { checkObject } from '../../utils/utils';

const styles = (theme) => {
    const heights = {};
    for (let i = 20; i < 100; i++) {
        heights[`height${i}`] = {
            '& .TableRow': {
                height: i,
            },
        };
    }
    return {
        ...heights,
        tableBody: {
            '& .TableRow': {
                '&.focused': {
                    border: '1px solid #dcdede',
                    backgroundColor: '#fcffff',
                },
                '& .TableCell': {
                    paddingLeft: '5px !important',
                    paddingRight: '5px !important',
                    minWidth: '60px',
                    overflow: 'hidden',
                    [theme.breakpoints.only('xs')]: {
                        minWidth: '200px',
                    },
                },
                '& .TableCell.centerV': {
                    textAlign: 'center',
                    justifyContent: 'center',
                },
                '& .TableCell.nowrap': {
                    '& .nowrap': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        '&>span': {
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            overflow: 'hidden',
                        },
                    },
                },
            },
        },
    };
};

const TableBody = (props) => {
    const { children, style, className, dataTypePrefix, classes, height, isTableBodyStyles, ...rem } = props;
    const classNames = className || '';
    let styles = checkObject(style) ? style : {};
    styles = {
        ...styles,
        display: styles.display ? styles.display : '',
        minWidth: '100%',
        maxWidth: !isTableBodyStyles ? '100%' : 'default',
    };
    return (
        <div 
            data-type={`${dataTypePrefix}TableBody`} 
            {...rem} 
            style={styles} 
            className={`${classNames} scrollTable ${!isTableBodyStyles ? '' : `${classes.tableBody} ${classes[`height${height}`]} TableBody`}`}
        >
            {children}
        </div>
    );
};
TableBody.propTypes = {
    dataTypePrefix: PropTypes.string,
};

TableBody.defaultProps = {
    dataTypePrefix: '',
};

export default withStyles(styles)(TableBody);
