import axios from 'axios';
import config from '../config';

export default class HttpAuthService {
    // TODO: it should come from config file
    static baseUrl = config.usrmgmt.endpoint;

    static get = async (endpoint, params = '') => {
        const url = (HttpAuthService.baseUrl + endpoint + params).trim();
        const response = await axios.get(url);
        return { data: response.data, status: response.status };
    };

    static post = async (endpoint, data) => {
        const response = await axios.post(HttpAuthService.baseUrl + endpoint, data);
        return { data: response.data, status: response.status };
    };

    static put = async (endpoint, data) => {
        const response = await axios.put(HttpAuthService.baseUrl + endpoint, data);
        return { data: response.data, status: response.status };
    };
}
