/* eslint-disable no-underscore-dangle */
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '../../features/modal/GeneralModal/Select';
import { BLACK_FONT } from '../../common/cssConstants';

const styles = () => ({
    form: {
        width: '600px',
    },
    font: {
        fontSize: 12,
        color: BLACK_FONT,
    },
    labelColor: {
        '&>label': {
            color: BLACK_FONT,
            fontSize: '12px',
            transform: 'translate(0, 1.5px)',
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    select: {
        width: '100%',
        marginTop: '16px',
        marginBottom: '8px',
    },
});

const Validation = /^[a-z]+[a-z0-9]*$/;
const isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Fields = (props) => {
    const {
        isDirty,
        markDirtyField,
        classes,
        getSelectedItems,
        handleChange,
        items,
        modalDataEdit,
        isAccount,
        name,
        email,
        // handleEmail,
        selectedItem,
        password,
        accountName,
        emailErrorMessage,
        userNameErrorMessage,
        user,
    } = props;

    const convertToSelectValues = (inputArray = []) => {
        let outputArray = [];
        outputArray = inputArray.map((element) => {
            return {
                value: element.value,
                label: element.value,
            };
        });
        return outputArray;
    };

    return (
        <form className={classes.form} noValidate autoComplete="off">
            {isAccount && (
                <TextField
                    id="AccountName"
                    aria-label="Account Name"
                    value={accountName}
                    InputLabelProps={{ shrink: true }}
                    label="Account Name"
                    InputProps={{
                        classes: {
                            input: classes.font,
                        },
                    }}
                    fullWidth
                    margin="normal"
                    className={classes.labelColor}
                    onChange={handleChange('accountName')}
                />
            )}

            {/* hardcoded. */}
            {isAccount && accountName && !Validation.test(accountName) && (
                <span style={{ fontSize: 11, color: 'red', display: 'block' }}>Don't use capitalized Account name on it.</span>
            )}

            <TextField
                id="UserName"
                value={name}
                label="User Name"
                autoFocus
                aria-label="User Name"
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
                margin="normal"
                InputProps={{
                    classes: {
                        input: classes.font,
                    },
                }}
                onChange={handleChange('userName')}
                disabled={!!modalDataEdit}
                className={classes.labelColor}
            />
            {userNameErrorMessage ? (
                <span style={{ fontSize: 11, color: 'red', display: 'block' }}>{userNameErrorMessage}</span>
            ) : name === '' || Validation.test(name) ? null : (
                <span style={{ fontSize: 11, color: 'red', display: 'block' }}>Username should only be lowercase alphanumeric.</span>
            )}

            <TextField
                id="Email"
                value={email}
                label="Email"
                aria-label="Email"
                InputLabelProps={{
                    shrink: true,
                }}
                // onBlur={handleEmail}
                fullWidth
                margin="normal"
                InputProps={{
                    classes: {
                        input: classes.font,
                    },
                }}
                className={classes.labelColor}
                onChange={handleChange('email')}
                disabled={!!modalDataEdit}
            />

            {emailErrorMessage ? (
                <span style={{ fontSize: 11, color: 'red', display: 'block' }}>{emailErrorMessage}</span>
            ) : (
                email &&
                !isEmail.test(String(email).toLowerCase()) && (
                    <span style={{ fontSize: 11, color: 'red', display: 'block' }}>Invalid email address.</span>
                )
            )}

            {!modalDataEdit && (
                <TextField
                    id="Password"
                    value={password}
                    aria-label="Password"
                    label="Password"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    InputProps={{
                        classes: {
                            input: classes.font,
                        },
                    }}
                    className={classes.labelColor}
                    margin="normal"
                    onChange={handleChange('password')}
                    disabled={!!modalDataEdit}
                    type="password"
                    onFocus={markDirtyField}
                />
            )}

            {password && isDirty && !modalDataEdit && password.length < 6 ? (
                <span style={{ fontSize: 11, color: 'red', display: 'block' }}>Password must be at least 6 characters.</span>
            ) : (
                <span />
            )}

            <Select
                value={selectedItem}
                onChange={(data) => {
                    getSelectedItems(data.value);
                }}
                className={classes.select}
                isBorder
                label="Role"
                options={convertToSelectValues(items)}
                isMultiSelect={false}
                disabled={user && user.role !== 'ADMIN'}
            />
        </form>
    );
};

Fields.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    getSelectedItems: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(Object).isRequired,
    modalDataEdit: PropTypes.shape({}),
    accountName: PropTypes.string,
    isAccount: PropTypes.bool,
    selectedItem: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string,
};

Fields.defaultProps = {
    modalDataEdit: {},
    accountName: '',
    isAccount: false,
    password: '',
};

export default withStyles(styles)(Fields);
