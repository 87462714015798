import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import upload from './images/upload.png';
import record from './images/record.png';
import createTestcase from './images/createTestcase.png';

const styles = () => ({
    button: {
        // width: '163px',
        // height: '35px',
        // display: 'inline-block',
        padding: '8px 30px',
        borderRadius: '3.5px',
        cursor: 'pointer',
        //alignItems: 'center',
        //justifyContent: 'center',
        //display: 'flex',
    },
    text: {
        fontSize: '.875rem',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    iconWrapper: {
        marginRight: 5,
        color: '#2c2828',
        display: 'inline-block',
        height: 18,
        width: 20,
    },
    faIcon: {
        fontSize: 18,
    },
    panelHorizontal: {
        width: '220px',
        transform: 'translateX(-220px)',
        transition: 'transform 400ms ease-in',
    },
    slideIn: {
        transform: 'translateX(0)',
    },
    slideOut: {
        transform: 'translateX(-220px)',
    },
    bitmapImage: {
        width: '17px',
        height: '18px',
        margin: '5.5px 7px 9px 0',
    },
    PopoverStyle: {
        //top: '50px',
    },
    PopoverDivData: {
        marginLeft: '2px',
        paddingBottom: '10px',
        paddingTop: '7px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        paddingLeft: '5px',
    },
    PopoverSpan: {
        //  margin: '0 9px 0 2px',
    },
    rounded: {
        borderRadius: '0px !important',
    },
    popover_class: {
        position: 'absolute !important',
        top: '10px',
        //border: '1px solid black',
        // '&:before': {
        //      content:"",
        //      position: 'absolute',
        //      right: '4px',
        //      top: '-5px',
        //      width: '0',
        //      height: '0',
        //      borderStyle: 'solid',
        //      borderWidth: '0 8px 8px 8px',
        //      borderColor: 'transparent transparent green transparent',
        //      zIndex:'9998',
        // },
    },
    uploadFileInput: {
        display: 'none',
    },
    addTestCaseOptionContainer: {
        display:'flex',
        flexDirection:'column',
        padding:'10px',
        width:'172px'
    },
});

const CustomButton = ({
    title,
    onClick,
    onClickForUpload,
    style,
    open,
    anchorEl,
    createTestCase,
    handleRecord,
    handleUpload,
    onClose,
    className,
    classNameSlider,
    textClassName,
    icon,
    faIcon,
    iconWrapperClassName,
    faIconClassName,
	handleLoadUnpacked,
    handleUploadJSONFile,
    anchorPosition
}) => {
    const classes = makeStyles(styles)();
    return (
        <MuiThemeProvider theme={{}}>
            <Button
                className={`${classes.button} ${className || ''}`}
                style={{ backgroundColor: '#2da6ea', ...style }}
                onClick={(e) => {
                    if (onClick) onClick(e);
                }}
            >
                {icon || faIcon ? (
                    <span className={`${classes.iconWrapper} ${iconWrapperClassName || ''}`}>
                        {faIcon ? <FontAwesomeIcon className={`${classes.faIcon} ${faIconClassName || ''}`} icon={faIcon} /> : icon}
                    </span>
                ) : null}
                <span className={`${classes.text} ${textClassName || ''}`} style={{ color: '#ffffff' }}>
                    {title}
                </span>
            </Button>
            <Popover
                open={open || false}
                anchorEl={anchorEl}
                onClose={onClose}
                className={classes.popover_class}
                style={title === 'Add TEST CASE' ? { top: '29px', left: '66px' } : { top: '-1px', left: '46px' }}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition || { top: 178, left: 258 }} //title?.toLowerCase() === 'upload new data' ? { top: 155, left: 206 } :
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                {title === 'Add TEST CASE' ? (
                        <div className={classes.addTestCaseOptionContainer}>
                            <MenuItem onClick={handleRecord} onContextMenu={handleLoadUnpacked}>
                                <img src={record} alt="Main" className={classes.opacity} style={{ width: '19px' }} />
                                <span style={{fontSize:'12px',marginLeft:'8px'}}>{"Record"}</span>
                            </MenuItem>
                            <MenuItem onClick={handleUpload}>
                                <img src={upload} alt="Main" className={classes.opacity} style={{ width: '19px' }}  />
                                <span style={{fontSize:'12px',marginLeft:'8px'}}>{"Upload"}</span>
                            </MenuItem>
                            <MenuItem onClick={(e) => {if (createTestCase) createTestCase(e);}}>
                                <img src={createTestcase} alt="Main" className={classes.opacity} style={{ width: '19px' }} />
                                <span style={{fontSize:'12px',marginLeft:'8px'}}>{'Write'}</span>
                            </MenuItem>
                    </div>
                ) : (
                    <>
                        <div style={{ overflow: 'hidden', display: 'flex' }}>
                            {/* <span data-testid="Upload Data Definition" className={classes.PopoverSpan}>
                                <input
                                    accept="application/JSON"
                                    className={classes.uploadFileInput}
                                    id="contained-button-file"
                                    type="file"
                                    onChange={handleUploadJSONFile}
                                />
                                <label htmlFor="contained-button-file" className={classes.IconButton}>
                                    <img
                                        src={add}
                                        alt="Main"
                                        className={classes.opacity}
                                        style={{ width: '21px', margin: '9px 20px', cursor: 'pointer' }}
                                        title="Upload Data Definition"
                                    />
                                </label>
                            </span> */}
                            <span data-testid="Upload" className={classes.PopoverSpan}>
                                <IconButton title="Upload" className={classes.IconButton} onClick={onClickForUpload}>
                                    <img src={upload} alt="Main" className={classes.opacity} style={{ width: '19px' }} />
                                </IconButton>
                            </span>
                        </div>
                    </>
                )}
            </Popover>
        </MuiThemeProvider>
    );
};

export default CustomButton;