import axios from 'axios';
import config from '../config';
import createHeader from '../common/createHeader';

export default class HttpService {
    // TODO: it should come from config file
    static baseUrl = config.api.endpoint;

    static get = async (endpoint, params = '') => {
        const headers = createHeader(endpoint);
        const url = (HttpService.baseUrl + endpoint + params).trim();
        const response = await axios.get(url, { headers });
        return { data: response.data, status: response.status };
    };

    static post = async (endpoint, data) => {
        const headers = createHeader(endpoint);
        const response = await axios.post(HttpService.baseUrl + endpoint, data, { headers });
        return { data: response.data, status: response.status };
    };

    static put = async (endpoint, data) => {
        const response = await axios.put(HttpService.baseUrl + endpoint, data);
        return { data: response.data, status: response.status };
    };
}
