/* eslint-disable no-template-curly-in-string */
import React from 'react';
import ReactExport from 'react-export-excel';
import { saveAs } from 'file-saver/FileSaver';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment';
import yaml from 'js-yaml';
import ReactGA from 'react-ga';
import _ from 'lodash';
import store from '../store';
import { ActionTypes } from '../store/constants';
import { ProjectActions, ModalActions } from '../store/actions';
import singleFileCreationService from '../services/singleFileCreationService';
import PreviewTestCase from '../components/Preview/PreviewTestCase';
import { NEXT_SUBMIT_BUTTON, CANCEL_BUTTON } from '../components/Button/Btn';
import FormattedTooltip from '../components/FormattedTooltip';
import Tooltip from '../components/Tooltip';

// Variables
const pctProgress = {};
let status;
const isRunCommand = new RegExp('^\\${(.*?)}$', 'i');

let ___progressInterval = null;
let ___progressPercentage = 0;
let ___progressIncrementor = 5;

/**
|--------------------------------------------------
| ALL MAPPING STATUS
|--------------------------------------------------
*/
export const stepsStatusMap = {
    0: 'Success',
    1: 'Partial',
    2: 'Warning',
    3: 'Fail',
    4: 'In Progress',
    5: 'Not Executed',
    6: 'Stop',
};
export const testCaseStatus = {
    '-3': 'Execution is waiting to start',
    '-2': 'UPLOADED', // status -2 is not comming from platform used for multiple testCases upload 
    '-1': 'NOT RUN',
    '0': 'NO TEST STEP',
    '1': 'PASSED',
    '2': 'FAILED',
    '3': 'STOPPED',
    '4': 'IN PROGRESS', // status 4 is not comming from platform
    '7': 'STOPPED',
};

export const testScriptGenerationStatus = {
    SUCCESS: 1,
    FAILED: 2,
    STOPPED: 3,
    INPROGRESS: 4,
};

export const testScriptExecutionStatus = {
    '0': 'INPROGRESS',
    '1': 'SUCCESS',
    '2': 'FAILED',
    '3': 'PARTIAL',
    '4': 'N/A',
    '7': 'STOPPED',
    '8':'QUEUED'
};

export const projectDetailTabsOrder = {
    cases: 0,
    suites: 1,
    data: 2,
    scripts: 3,
    variables: 4,
    flows: 5,
};

export const dashboardMap = {
    Week: 0,
    Month: 1,
    Year: 2,
};

const invalidYears = Object.freeze({
    string: Object.freeze(['0001', '2001', '2099', '9999']),
    numeric: Object.freeze([1, 2001, 2099, 9999]),
});

export const invertedProjectDetailTabsOrder = _.invert(projectDetailTabsOrder);

export function getProjectDropDownData(projects) {
    return projects.map((project) =>
        // use this when getting data from api
        // { value: project.projectId, label: project.projectName }
        ({
            value: project.projectId.toString(),
            label: project.projectName,
        }),
    );
}

export function getExecutionStatus(testCase) {
    return checkKeyInObject(testCase, 'executionStatus', 'bool') && testScriptExecutionStatus[testCase.executionStatus]
        ? testScriptExecutionStatus[testCase.executionStatus]
        : '';
}

function desc(a, b, orderBy) {
    // Don't change its == to === "Number(b[orderBy]) == b[orderBy]"
    // eslint-disable-next-line eqeqeq
    const isNumber = Number(b[orderBy]) == b[orderBy] && Number(a[orderBy]) == a[orderBy];
    if (
        (b[orderBy] ? (typeof b[orderBy] === 'string' ? (isNumber ? Number(b[orderBy]) : b[orderBy].toLowerCase()) : b[orderBy]) : b[orderBy]) <
        (a[orderBy] ? (typeof a[orderBy] === 'string' ? (isNumber ? Number(a[orderBy]) : a[orderBy].toLowerCase()) : a[orderBy]) : a[orderBy])
    ) {
        return -1;
    }
    if (
        (b[orderBy] ? (typeof b[orderBy] === 'string' ? (isNumber ? Number(b[orderBy]) : b[orderBy].toLowerCase()) : b[orderBy]) : b[orderBy]) >
        (a[orderBy] ? (typeof a[orderBy] === 'string' ? (isNumber ? Number(a[orderBy]) : a[orderBy].toLowerCase()) : a[orderBy]) : a[orderBy])
    ) {
        return 1;
    }
    return 0;
}

export function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

/**
 *
 * @param {array} array the array of object which has property which user want to sort
 * @param {string} order "desc" or "asc"
 * @param {string} orderBy key which to be sort
 * @param {string} type optional -- "len" or "date" or "time"
 */
export function sort(array, order, orderBy, type = '') {
    const getValidDate = (data) => {
        const defaultDate = new Date(`1/1/${order === 'asc' ? '9999' : '0001'}`); // "order === "asc" ? "9999" :" <-- this code is responsible for holding invalid dates at last
        return new Date(isDateCorrect(data, 'value', defaultDate));
    };
    const findKeyInObject = (obj, key, _type) => {
        const { sortingKey } = obj;
        // eslint-disable-next-line no-restricted-syntax
        for (const property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (`${property}` === `${key}`) {
                    let val = obj[key];
                    switch (_type) {
                        case 'len':
                            val = Array.isArray(obj[key]) ? obj[key].length : 0;
                            break;
                        case 'date':
                            val = getValidDate(obj[key]);
                            break;
                        case 'time':
                            val = getValidDate(obj[key]).getTime();
                            break;
                        default:
                            break;
                    }
                    return { sortingKey, isFound: true, [key]: val };
                }
                if (typeof obj[property] === 'object') {
                    const tempObj = Array.isArray(obj[property])
                        ? obj[property].length > 0
                            ? { ...obj[property][0], sortingKey }
                            : { sortingKey }
                        : { ...obj[property], sortingKey };
                    const getObj = findKeyInObject(tempObj, key, _type);
                    if (getObj.isFound) {
                        return getObj;
                    }
                }
            }
        }
        return { sortingKey, isFound: false, [key]: null };
    };

    if (Array.isArray(array)) {
        let arrayTobeSort = [];
        const tempArray = [];
        array.forEach((obj, ind) => {
            if (typeof obj === 'object' && !Array.isArray(obj)) {
                const newObj = { ...obj, sortingKey: ind };
                arrayTobeSort.push(findKeyInObject(newObj, orderBy, type));
                tempArray.push(obj);
            }
        });
        arrayTobeSort = stableSort(JSON.parse(JSON.stringify(arrayTobeSort)), getSorting(order, orderBy));
        const sortedArray = [];
        for (let i = 0; i < arrayTobeSort.length; i++) {
            sortedArray.push(tempArray[arrayTobeSort[i].sortingKey]);
        }
        return sortedArray;
    }
    return array;
}

export function isEmpty(obj) {
    if (obj && Object.keys(obj).length > 0) {
        return Object.keys(obj).some((key) => {
            return !(obj.hasOwnProperty(key) && obj[key] !== '' && obj[key] !== undefined ? obj[key].trim() : '');
        });
    }
    return false;
}
export function deepArrayCompare(arr1, arr2) {
    if (!arr1 || !arr2 || !Array.isArray(arr1) || !Array.isArray(arr2) || !arr1.length || !arr2.length) {
        return false;
    }
    let difference = false;
    for (let i = 0; i < arr1.length; i++) {
        const obj1 = arr1[i];
        const obj2 = arr2[i];
        difference = Object.keys(obj1).some((key) => {
            if (obj1[key] !== obj2[key]) {
                return true;
            }
            return false;
        });
    }
    return difference;
}

export function getTestCaseStatusWithout_ws(testCase) {
    const { wsRunningCaseData } = store.getState().projectReducer;
    return getTestCaseStatus(testCase, wsRunningCaseData);
}

function emptyStatus(testCase) {
    if (checkKeyInObject(testCase, 'executionId', 'bool')) {
        return { code: 'execution', msg: 'Execution is waiting to start', msgSimple: 'Execution is waiting to start' };
    }
    return { code: 'notStartedYet', msg: 'Ready to generate', msgSimple: 'Ready to generate' };
}

export function getTestCaseStatus(testCase, wsRunningCaseData) {
    // testCaseStatus = msg :
    //  5 = editing 4 = in progress 3 = stopped, 2 = failed, 1 = success, 0 = notStartedYet -2 = uploaded
    const isTestCaseIdExistsInWs = checkKeyInObject(wsRunningCaseData, testCase.testCaseId, 'bool');
    const isTestCaseInProgress = isTestCaseIdExistsInWs || !!testCase.isGenerating;
    let instrNumArray = [];

    if (checkKeyInObject(testCase, 'status') || isTestCaseInProgress) {
        status = testCase.status;
        const tooltipMessage = '';
        let msg = '';
        const { executionId } = testCase;
        const _executionRunningData = checkKeyInObject(store.getState().projectReducer.executionRunningData, testCase.executionId, 'value', {});
        const _wsRunningCaseData = isTestCaseIdExistsInWs ? wsRunningCaseData[testCase.testCaseId] : {};

        // data comming for platform is saved in this key as it is
        const testStepObj = executionId
            ? checkKeyInObject(_executionRunningData, 'data.testStepObj', 'value', {})
            : checkKeyInObject(_wsRunningCaseData, 'testStepObj', 'value', {});

        // total length of steps saved in msgType 3 (_wsRunningCaseData)
        // total length of steps send from executionHeader in testCase
        const totalTestSteps = checkKeyInObject(executionId ? testCase : _wsRunningCaseData, 'stepsCount', 'value', 0);

        const pctProgressKey = executionId ? `${testCase.testCaseId}_${executionId}` : testCase.testCaseId;
        if (!executionId) {
            instrNumArray = checkKeyInObject(store.getState().selectedTestCaseReducer, 'instrNumArray', 'value', []);
            // (status is `editing`)
            if (!!_wsRunningCaseData.isMsgType13Received || !!_wsRunningCaseData.isMsgType16Received) {
                // if msgType 13 or 16 received
                status = 5;
            }

            // (status is `in progress without %`)
            if (
                (!!testCase.isGenerating || !!_wsRunningCaseData.isMsgType2Sent) && // isGenerating true or isMsgType2Sent
                !_wsRunningCaseData.isRunning &&
                !_wsRunningCaseData.isMsgType13Received &&
                !_wsRunningCaseData.isMsgType16Received
            ) {
                // and step is not running
                msg = 'In Progress';
                status = 4;
            }

            // (status is `in progress with %`)
            if (
                isTestCaseIdExistsInWs &&
                !!_wsRunningCaseData.isRunning && // step is running
                !_wsRunningCaseData.isMsgType13Received && // and msgType 13 is not received
                !_wsRunningCaseData.isMsgType16Received // and msgType 16 is not received
            ) {
                status = 4;
                if (!totalTestSteps) {
                    // If totalTestSteps is 0 then show status `in progress without %`
                    msg = 'In Progress';
                }
            }
        } else {
            instrNumArray = checkKeyInObject(store.getState().executionReducer, 'instrNumArray', 'value', []);
        }

        // calculate progress percentage through data coming from platform
        if (checkObject(testStepObj) && `${testStepObj.msgType}` === '4') {
            const dataFromPlatform = testStepObj.msgJson ? JSON.parse(testStepObj.msgJson) : {};
            if (checkArrayLength(instrNumArray)) {
                const currentStepIndex = instrNumArray.findIndex((instrNum) => `${instrNum}` === `${dataFromPlatform.stepInProgress}`);
                pctProgress[pctProgressKey] = Math.round((currentStepIndex / instrNumArray.length) * 100);
            } else if (totalTestSteps) {
                const _wsRunningTestSteps = checkKeyInObject(store.getState().projectReducer.wsRunningTestSteps, testCase.testCaseId, 'value', []);
                if (checkArrayLength(_wsRunningTestSteps)) {
                    const currentStepIndex = _wsRunningTestSteps.findIndex((step) => `${step.instrNum}` === `${dataFromPlatform.stepInProgress}`);
                    pctProgress[pctProgressKey] = Math.round((currentStepIndex / _wsRunningTestSteps.length) * 100);
                } else {
                    pctProgress[pctProgressKey] = 0;
                }
            } else {
                pctProgress[pctProgressKey] = 0;
            }
        }

        return getStatus(status, testCase, pctProgress, tooltipMessage, msg);
    }
    return emptyStatus(testCase);
}

export function getStatus(_status, testCase, progressPct, tooltipMessage, msg) {
    let obj = {};
    const pctProgressKey = testCase.executionId ? `${testCase.testCaseId}_${testCase.executionId}` : testCase.testCaseId;
    switch (_status) {
        case -3:
            obj = { code: 'execution', msg: testCase.statusMessage, msgSimple: testCase.statusMessage };
            break;
        case -2:
            obj = { code: 'uploaded', msg: 'Uploaded', msgSimple: 'Uploaded' };
            break;
        case 1:
            obj = { code: 'success', msg: testCase.statusMessage, msgSimple: 'Success' };
            break;
        case 2:
            obj = { code: 'failed', msg: testCase.statusMessage, msgSimple: 'Failed' };
            break;
        case 3:
            obj = { code: 'stopped', msg: testCase.statusMessage, msgSimple: 'Stopped' };
            break;
        case 4:
            obj = {
                code: 'in progress',
                msg: msg || `In Progress - ${isNaN(progressPct[pctProgressKey]) ? 0 : pctProgress[pctProgressKey]}%`,
                msgSimple: 'In Progress',
                tooltipMessage,
            };
            break;
        case 5:
            obj = { code: 'editing', msg: 'Editing', msgSimple: 'Editing', tooltipMessage };
            break;
        case 6:
            obj = { code: 'warning', msg: testCase.statusMessage, msgSimple: 'Warining', tooltipMessage };
            break;
        case 7:
            obj = { code: 'stopped', msg: testCase.statusMessage, msgSimple: 'Stopped' };
            break;
        default:
            obj = emptyStatus(testCase);
            break;
    }
    if (_status !== 4 && checkKeyInObject(pctProgress, pctProgressKey)) {
        delete pctProgress[pctProgressKey];
    }
    
    return obj;
}

// Add User Prefrences in localStorage (currently for checking listView)
export function addUserPreference(name, value) {
    const { user } = store.getState().authReducer;
    if (user.userId) {
        let data = JSON.parse(localStorage.getItem('preferences'));
        const userId = `userId__${user.userId}`;
        if (!data) {
            data = { preferences: {} };
        }
        if (!data.preferences[userId]) {
            data.preferences[userId] = {};
        }
        data.preferences[userId][name.toLowerCase()] = value;
        localStorage.setItem('preferences', JSON.stringify(data));
    }
}

export function getUserPreference(name) {
    const { user } = store.getState().authReducer;
    if (user.userId) {
        const data = JSON.parse(localStorage.getItem('preferences'));
        const userId = `userId__${user.userId}`;
        if (!data || !data.preferences[userId] || data.preferences[userId][name.toLowerCase()] === undefined) {
            return null;
        }
        return data.preferences[userId][name.toLowerCase()];
    }
    return null;
}

// needle => value need to find, haystack => from which array, keys => which key of array need to match
export function handleSearchFromArray(needle, haystack, keys) {
    if (needle && checkArrayLength(haystack) && checkArrayLength(keys)) {
        const filterArray = haystack.filter((item) => {
            const _value = needle.toLowerCase().trim();
            return keys.some((key) => {
                if (item[key].toLowerCase().includes(_value)) {
                    return true;
                }
                return false;
            });
        });
        return filterArray;
    }
    return haystack;
}

/* Date Functions Start */
export function getRelativeTime(date, notFoundValue = '') {
    if (isDateCorrect(date)) {
        const relativeDate = moment(date).fromNow();

        // if (relativeDate.includes('day')) {
        //     const day = Number(relativeDate.replace(/[^\d]/g, ''));
        //     return `${Math.round( day/ 7)}w`;
        // }

        return `${relativeDate}`;
    }
    return notFoundValue;
}

export function getDatesDiff(date1, date2, format, seprator = ':') {
    // format "Y:M:W:D:HH:MM:SS"
    // Y = Years
    // M = Months
    // W = Weeks
    // D = Days
    // HH = Hours
    // MM = Minutes
    // SS = Seconds
    return getSecondsToFormatedTime(moment(date1).diff(moment(date2)) / 1000, format, seprator);
}

export function getSecondsToFormatedTime(sec, format, joinBy = ':', showIndicator = false, showZero = true, isFullWord = false) {
    // format "Y:M:W:D:HH:MM:SS"
    // Y = Years
    // M = Months
    // W = Weeks
    // D = Days
    // HH = Hours
    // MM = Minutes
    // SS = Seconds
    const duration = moment.duration(sec * 1000);
    let elapsed = '';
    const formats = format.split(':');
    const formatsProps = {
        Y: {
            func: (d) => {
                return d.years();
            },
            key: 'years',
            keyShort: 'Y',
            keyLong: ' years',
        },
        M: {
            func: (d) => {
                return d.months();
            },
            key: 'months',
            keyShort: 'M',
            keyLong: ' months',
        },
        W: {
            func: (d) => {
                return d.weeks();
            },
            key: 'weeks',
            keyShort: 'W',
            keyLong: ' weeks',
        },
        D: {
            func: (d) => {
                return d.days();
            },
            key: 'days',
            keyShort: 'D',
            keyLong: ' days',
        },
        HH: {
            func: (d) => {
                return d.hours();
            },
            key: 'hours',
            keyShort: 'h',
            keyLong: ' hours',
        },
        MM: {
            func: (d) => {
                return d.minutes();
            },
            key: 'minutes',
            keyShort: 'Min',
            keyLong: ' minutes',
        },
        SS: {
            func: (d) => {
                return d.seconds();
            },
            key: 'seconds',
            keyShort: 's',
            keyLong: ' seconds',
        },
    };
    for (let i = 0; i < formats.length; i++) {
        const f = formats[i];
        if (formatsProps[f]) {
            let d = formatsProps[f].func(duration);
            d = d > 0 ? d : 0;
            if (d > 0 || showZero) {
                duration.subtract(moment.duration(d, formatsProps[f].key));
                elapsed += `${d > 9 ? d : `0${d}`}${showIndicator ? (isFullWord ? formatsProps[f].keyLong : formatsProps[f].keyShort) : ''}`;
                elapsed += i !== formats.length - 1 ? joinBy : '';
            }
        }
    }
    return elapsed;
}

/**
 *
 * @param {any} date
 * @param {string} returnType "bool" or "value" -> Default "bool"
 * @param {any} notFoundValue if returnType is value and date is invalid then function will return this value -> Default false
 */

export function isDateCorrect(date, returnType = 'bool', notFoundValue = false, invalidDate = null) {
    const dateText = invalidDate ? getFormatedDate(date, 'YYYY-MM-D', null) : null;
    const isDateValid =
        date &&
        new Date(date) instanceof Date &&
        !Number.isNaN(new Date(date)) &&
        !(
            ((invalidDate ? dateText && dateText.includes : date.includes) &&
                invalidYears.string.some((yr) => {
                    return invalidDate ? dateText.includes(yr) || dateText.includes(invalidDate) : date.includes(yr);
                })) ||
            invalidYears.numeric.includes(new Date(date).getFullYear())
        );
    return returnType === 'bool' ? !!isDateValid : isDateValid ? date : notFoundValue;
}

export function getLongDate(date, failMsg = 'N/A') {
    if (isDateCorrect(date)) {
        return moment(date).toDate();
    }
    return failMsg;
}

export function getFormatedDate(date, format, failMsg = '-', invalidDate = null) {
    if (isDateCorrect(date, 'bool', false, invalidDate)) {
        if (format) {
            return moment(moment(date).toISOString(), moment.ISO_8601).format(format);
        }
        return moment(date);
    }
    return failMsg;
}

export function makeLocalDate(date) {
    const reg = new RegExp(/^(.*)T(?:[0-9]{2}:?)+Z$/);
    if (reg.test(date)) {
        const matches = date.match(reg);
        return moment(matches[1]).toString();
    }
    return date;
}

/**
 *
 * @param {Date} date
 * @param {Boolean} isFullWord "bool" -- If true it will return complete word Eg: "months". If false it will return first letter Eg: "M" -> Default "false"
 * @param {Boolean} withoutAgo "bool" -- If true it will not add "ago" at the end -> Default "false"
 * @param {Number} datePartsNum "number" -- If 0 it will return complete date difference till Seconds. If number it will return that number of differences -> Default 2
 * @param {any} failMsg if date is invalid then function will return this value -> Default "" (Empty String)
 */
export function properFromNow(date, isFullWord = false, withoutAgo = false, datePartsNum = 2, failMsg = '') {
    if (isDateCorrect(date)) {
        const miliSeconds = moment(new Date()).diff(moment(new Date(date)));
        const duration = moment.duration(miliSeconds);
        let elapsed = '';
        // don't change the property named key
        const formatsProps = {
            Y: {
                func: (d) => {
                    return d.years();
                },
                key: 'years',
                keyShort: 'Y',
                keyLong: ' years',
            },
            M: {
                func: (d) => {
                    return d.months();
                },
                key: 'months',
                keyShort: 'M',
                keyLong: ' months',
            },
            W: {
                func: (d) => {
                    return d.weeks();
                },
                key: 'weeks',
                keyShort: 'W',
                keyLong: ' weeks',
            },
            D: {
                func: (d) => {
                    return d.days();
                },
                key: 'days',
                keyShort: 'D',
                keyLong: ' days',
            },
            HH: {
                func: (d) => {
                    return d.hours();
                },
                key: 'hours',
                keyShort: 'h',
                keyLong: ' hours',
            },
            MM: {
                func: (d) => {
                    return d.minutes();
                },
                key: 'minutes',
                keyShort: 'Min',
                keyLong: ' minutes',
            },
            SS: {
                func: (d) => {
                    return d.seconds();
                },
                key: 'seconds',
                keyShort: 's',
                keyLong: ' seconds',
            },
        };
        let c = 0;
        Object.keys(formatsProps).some((f) => {
            if (formatsProps.hasOwnProperty(f)) {
                const d = formatsProps[f].func(duration);
                if (d > 0) {
                    c++;
                    duration.subtract(moment.duration(d, formatsProps[f].key));
                    elapsed += `${d}${isFullWord ? formatsProps[f].keyLong : formatsProps[f].keyShort} `;
                }
                return c !== 0 && c === datePartsNum;
            }
            return false;
        });
        return `${elapsed}${withoutAgo ? '' : 'ago'}`.trim();
    }
    return failMsg;
}
/* Date Functions End */

function handleRecursiveTestSteps(step, _testSteps) {
    const { subInstructions, instrType } = step;
    let subInstr;
    let testStep;

    if(instrType){
        subInstr = checkKeyInObject(step,'resultData') ? checkKeyInObject(checkKeyInObject(step,'resultData'),'iterationResult') ?  checkKeyInObject(checkKeyInObject(step,'resultData'),'iterationResult') : checkKeyInObject(step,'resultData') : null;
        testStep = { ...step };
        testStep.hasChild = subInstr;
        if(step.instrNum !== ''){ _testSteps.push(testStep); }
        
        
        if(subInstr) {
            if(checkIsArray(subInstr)){
                checkKeyInObject(subInstr[subInstr.length-1],'steps') ? 
                subInstr[subInstr.length-1].steps.forEach((step)=>{
                    handleRecursiveTestSteps(step, _testSteps)
                }): void(0);
            }else if(checkObject(subInstr)){
                checkKeyInObject(subInstr,'steps') ? subInstr.steps.forEach((step)=>{
                    handleRecursiveTestSteps(step, _testSteps);
                }) : checkKeyInObject(subInstr,'ifElseSteps') ? subInstr.ifElseSteps.forEach((step)=>{ 
                    checkKeyInObject(step,'subsStep') ? handleRecursiveTestSteps(step.subsStep, _testSteps) : handleRecursiveTestSteps(step, _testSteps)
                }) : void(0);
            }
        }
    }
    else{
        subInstr = checkArrayLength(subInstructions);
        testStep = { ...step };
        testStep.hasChild = subInstr;
          
        //  push in _testSteps because array refrance is same in handleClickOpen function
        _testSteps.push(testStep);
        if (subInstr) {
            subInstructions.forEach((teststep) => {
                handleRecursiveTestSteps(teststep, _testSteps);
            });
        } 
    }
    return _testSteps;
}

export function arrangeStepsWithCompoundSteps(steps = []) {
    const _testSteps = [];
    const _steps = steps.filter((step) => (step.instrNum && !step.instrNum.includes('.')) || step.instrType);
    _steps.forEach((step) => handleRecursiveTestSteps(step, _testSteps));
    return _testSteps;
}

export function getObjectKeysLength(obj) {
    return checkObject(obj) ? Object.keys(obj).length : 0;
}

export function checkArrayLength(value) {
    return value && Array.isArray(value) && value.length > 0;
}

/**
 *
 * @param {Array} arr
 * @param {String} returnType // bool | length
 */
export function checkIsArray(arr, returnType = 'bool') {
    if (returnType === 'bool') {
        return Array.isArray(arr);
    }
    return Array.isArray(arr) ? arr.length : 0;
}

export function checkObject(obj) {
    return obj && Object.keys(obj).length > 0;
}

export function isObjectEmpty(obj) {
    return !obj || (obj && !Object.keys(obj).length);
}

export async function resetFailedOrDebugStepsInSelectedTestCase(step, testCaseId) {
    const { wsRunningTestSteps: testSteps, instrNum } = step;
    const obj = {};

    obj.testSteps = testSteps;
    // to set showLoader true in project reducer
    store.dispatch({
        type: ActionTypes.GET_TESTSTEPS,
        payload: { showLoader: true },
    });

    store.dispatch({
        type: ActionTypes.GET_TESTSTEPS_SUCCESS,
        payload: {
            testSteps: { ...testSteps }, // raw form of steps
            steps: { ...obj }, // asigned like this to meet reducer structure
            testCaseId,
            instrNum, // step instruction number
        },
    });
}

export async function getStepsData(testCase, flag = 0, callBack = () => {}, callingFrom = '') {
    const testCaseId = (checkKeyInObject(testCase, 'testCaseId') && testCase.testCaseId) || testCase;
    // this.setState({ showLoader: true });
    const onCompelete = async (response) => {
        // flag = ("live" = 3) or ("recover" = 2) or ("orignal" = 1) or
        // ("original with live if exist else recover" = 0) or ("All" = -1)
        const { originalTestSteps, recoverTestSteps, testSteps } = response;
        const _testSteps = await arrangeStepsWithCompoundSteps(testSteps);
        const _recoverTestSteps = [];

        if (recoverTestSteps) {
            recoverTestSteps.forEach((ele, index) => {
                _recoverTestSteps.push({ ...ele, instrNum: `${index + 1}` });
            });
        }

        const obj = {};

        if (flag === 3 || flag === 0 || flag === -1) obj.testSteps = _testSteps;
        if (flag === 2 || flag === 0 || flag === -1) obj.recoverTestSteps = _recoverTestSteps;
        if (flag === 1 || flag === 0 || flag === -1) obj.originalTestSteps = originalTestSteps;
        // this.setState({ showLoader: false });
        if (checkKeyInObject(testCase, 'testCaseId')) {
            const _testCase = { ...testCase, ...obj };
            callBack(_testCase);
        } else {
            callBack();
        }
        return obj;
    };
    const onFail = () => {
        // this.setState({ showLoader: false });
        return {};
    };
    const res = await store.dispatch(ProjectActions.getTestSteps(testCaseId, flag, onCompelete, callingFrom, onFail));
    return res;
}

export function checkKeyInObject(obj, key, returnType = 'value', notFoundValue = undefined, isDomElement = false) {
    try {
        /* eslint-disable no-unused-expressions */
        let isExists = false;
        if (key && (typeof key === 'string' || typeof key === 'number') && obj) {
            if (typeof key === 'string' && (key.includes('current') || isDomElement)) {
                const keys = key.split('.');
                let val = obj;
                isExists = true;
                keys.some((k) => {
                    if (isExists && val[k]) {
                        val = val[k];
                        return false;
                    }
                    isExists = false;
                    return true;
                });
            } else {
                isExists = _.has(obj, key);
            }
        }
        if (returnType === 'value') {
            let valRet = notFoundValue;
            if (isExists) {
                if (obj[key] || typeof key === 'number') {
                    valRet = obj[key];
                } else if (typeof key === 'string') {
                    const keys = key.split('.');
                    let val = obj;
                    keys.forEach((k) => {
                        val = val[k];
                    });
                    valRet = val;
                }
            }
            return valRet;
        }
        return isExists;
    } catch (error) {
        console.error(error);
        if (returnType === 'value') {
            return notFoundValue;
        }
        return false;
    }
}

export function convertTimeToTwoDigits(time) {
    if (time >= 5184000) {
        return `${Math.floor(time / 2592000).toFixed(0)} months`;
    }
    if (time >= 2592000 && time < 5184000) {
        return `${Math.floor(time / 2592000).toFixed(0)} month`;
    }
    if (time >= 1209600 && time < 2592000) {
        return `${Math.floor(time / 604800).toFixed(0)} weeks`;
    }
    if (time >= 604800 && time < 1209600) {
        return `${Math.floor(time / 604800).toFixed(0)} week`;
    }
    if (time >= 172800 && time < 604800) {
        return `${Math.floor(time / 86400).toFixed(0)} days`;
    }
    if (time >= 86400 && time < 172800) {
        return `${Math.floor(time / 86400).toFixed(0)} day`;
    }
    if (time >= 3600 && time < 86400) {
        return `${Math.floor(time / 3600).toFixed(0)} hr`;
    }
    if (time >= 60 && time < 3600) {
        return `${Math.floor(time / 60).toFixed(0)} min`;
    }
    return `${time.toFixed(0)} sec`;
}

export function getTimeDifferenceInSec(date1, date2) {
    const duration = moment.duration(moment(date2).diff(moment(date1)));
    return duration.asSeconds();
}

export function getCountAvatar(val) {
    const getLabelValue = (_val, isMax2) => {
        let newVal = 0;
        if (_val && _val > 0) {
            if (isMax2) {
                newVal = _val < 100 ? _val : '99+';
            } else {
                newVal = _val < 1000 ? _val : '999+';
            }
        }
        return `${newVal}`;
    };
    return (
        <span>
            <Hidden only={['xs', 'lg', 'xl']}>{getLabelValue(val, true)}</Hidden>
            <Hidden only={['sm', 'md']}>{getLabelValue(val, false)}</Hidden>
        </span>
    );
}

// dataSet => All flows
export function getExcelDownloadBtn(btn, dataSet, fileName, sheetName = '', selectedFlow) {

    let dataSetForSelectedFlow = [];
    if(selectedFlow){

        dataSetForSelectedFlow = dataSet.map((dataSetPropVal) => {
            return {
                columns: dataSetPropVal.columns,
                data: dataSetPropVal.data.map((elem) => elem.filter((filterdElem, i) => (filterdElem.value === selectedFlow[0].name && i === 0)))
            }
        })
        dataSetForSelectedFlow[0].data = dataSetForSelectedFlow[0].data.filter((filElem) => filElem.length > 0)
        selectedFlow[0].steps.forEach((elem, ind) => {
        // we need to put flowName only in first row
            if (ind === 0) {
                dataSetForSelectedFlow[0].data[ind].push(
                    {
                        value: elem.instr,
                        style: dataSetForSelectedFlow[0].data[0][0].style
                    },
                    {
                        value: elem.data,
                        style: dataSetForSelectedFlow[0].data[0][0].style
                    },
                    {
                        value: elem.expectedResults,
                        style: dataSetForSelectedFlow[0].data[0][0].style
                    }
                )
            }
            else {
                dataSetForSelectedFlow[0].data.push([
                    {
                        value: '',
                        style: dataSetForSelectedFlow[0].data[0][0].style
                    },
                    {
                        value: elem.instr,
                        style: dataSetForSelectedFlow[0].data[0][0].style
                    },
                    {
                        value: elem.data,
                        style: dataSetForSelectedFlow[0].data[0][0].style
                    },
                    {
                        value: elem.expectedResults,
                        style: dataSetForSelectedFlow[0].data[0][0].style
                    }
                ])
            }
        })
    }


    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const fileN = checkKeyInObject(sheetName, 'projectName', 'bool', false) ? `${sheetName.projectName}_Flows` : fileName;
    return (
        <span
            onClick={(e) => {
                e.stopPropagation();
            }}
            key="downloadBtn"
            aria-hidden
        >
            <ExcelFile element={btn} filename={fileN}>
                <ExcelSheet dataSet={ checkArrayLength(dataSetForSelectedFlow) ? dataSetForSelectedFlow : dataSet } name={fileN} />
            </ExcelFile>
        </span>
    );
}

export function getValidUrl(url) {
    if (url.substr(0, 7) === 'http://' || url.substr(0, 8) === 'https://' || url.match(isRunCommand)) {
        return url.trim();
    }
    return `https://${url.trim()}`;
}

export function capitalizeString(text) {
    if (typeof text === 'string') {
        const txt = text.toLowerCase();
        return `${txt.charAt(0).toUpperCase()}${txt.slice(1)}`;
    }
    return text;
}
export function minsToHours(min) {
    let minutes = min;

    let hours = 0;
    if (minutes >= 60) {
        hours = Math.floor(minutes / 60);
        minutes %= 60;
    }
    let days = 0;
    if (hours >= 24) {
        days = Math.floor(hours / 24);
        hours %= 24;
    }

    const timeToShow = `${days ? `${days} days ` : ''}${hours ? `${hours} hours ` : ''}${minutes && (!days || !hours) ? `${minutes} minutes ` : ''}`;

    return timeToShow;
}

export function getProgressBarColor(progressVal) {
    let strokeColor;

    if (progressVal > 0 && progressVal <= 25) {
        strokeColor = '#E8001C';
    } else if (progressVal > 26 && progressVal < 75) {
        strokeColor = '#F39B31';
    } else {
        strokeColor = '#00B330';
    }
    return strokeColor;
}
export function getProjectCardProgressColor(progressVal) {
    let strokeColor;

    if (progressVal >= 0 && progressVal < 25) {
        strokeColor = '#E8001C';
    } else if (progressVal >= 25 && progressVal < 75) {
        strokeColor = '#F39B31';
    } else {
        strokeColor = '#00B330';
    }
    return strokeColor;
}
/**
 *
 * @param {string} yamlData string in yaml format
 */
export function yamlToObj(yamlData) {
    let data = {};
    yaml.safeLoadAll(yamlData, (doc) => {
        data = doc;
    });
    return data;
}

export function isJson(str) {
    if (str && typeof str !== 'string') {
        return false;
    }
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export async function createHtmlUtil(testCase, toggleModal, onStart = () => {}) {
    const { testCaseName: name, testSteps } = testCase;

    onStart();
    const { content, preview, error, status: _status } = await singleFileCreationService.getFile(testSteps);
    if (_status && content && preview) {
        const obj = {
            data: preview,
            modalName: 'ReportModal',
            fileName: name,
            handleDownload: () => {
                const blob = new Blob([content], {
                    type: 'text/html;charset=utf-8',
                });
                saveAs(blob, `${name}.html`);
                store.dispatch(ModalActions.toggleSnackBar(`${name}.html Downloading`, '', true));
                toggleModal();
            },
        };
        toggleModal('reportPreviewModal', null, null, obj);
        return { status: 0, msg: '' };
    }
    if (error) {
        store.dispatch(ModalActions.toggleSnackBar(error.toString()));
        return { status: 1, msg: error.toString() };
    }
    return { status: 2, msg: '' };
}

export const generateAsterik = (count) => {
    let temp = '';
    for (let i = 0; i < count; i++) {
        temp += '*';
    }
    return temp;
};

export const isLocalHost = () => {
    return window.location.hostname === 'localhost';
};

// Initialize Google Analytics
export const initializeGA = (code) => {
    if (!isLocalHost()) {
        ReactGA.initialize(code);
    }
};

// Initialize WalkMe
let isAlreadyInitializeWalkMe = false;
export const initializeWalkMe = () => {
    if (!isLocalHost() && !isAlreadyInitializeWalkMe) {
        isAlreadyInitializeWalkMe = true;
        const walkme = document.createElement('script');
        walkme.type = 'text/javascript';
        walkme.async = true;
        walkme.src = 'https://cdn.walkme.com/users/dcefad722b5848efa0ac6566d8dbd227/test/walkme_dcefad722b5848efa0ac6566d8dbd227_https.js';
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(walkme, s);
        window._walkmeConfig = { smartLoad: true };
    }
};

/**
 *
 * @param {any[]} args
 */
export const googleAnalytics = (...args) => {
    if (!isLocalHost) {
        ReactGA.ga(...args);
    }
};

export const copyToClipboardNonSecureFunction = (string) => {
    function handler(event) {
        event.clipboardData.setData('text/plain', string);
        event.preventDefault();
        removeAllListeners(document, 'copy');
    }
    addListener(document, 'copy', handler, true);
    document.execCommand('copy');
};

/* Add/Remove Event Listener -- Start */
const _eventHandlers = {};

export const addListener = (node, event, handler, capture) => {
    if (!(node in _eventHandlers)) {
        // _eventHandlers stores references to nodes
        _eventHandlers[node] = {};
    }
    if (!(event in _eventHandlers[node])) {
        // each entry contains another entry for each event type
        _eventHandlers[node][event] = [];
    }
    // capture reference
    _eventHandlers[node][event].push([handler, capture]);
    node.addEventListener(event, handler, capture);
};

export const removeAllListeners = (node, event) => {
    if (node in _eventHandlers) {
        const handlers = _eventHandlers[node];
        if (event in handlers) {
            const eventHandlers = handlers[event];
            for (let i = eventHandlers.length; i--; ) {
                const handler = eventHandlers[i];
                node.removeEventListener(event, handler[0], handler[1]);
            }
        }
    }
};
/* Add/Remove Event Listener -- End */

/* Start Resizer Work */
function resizer(resizerID, cursor, mousemove, colsWidth, elementPrefix,height) {
    const _resizer = document.getElementById(`${elementPrefix}${resizerID}`);
    if (_resizer) {
        _resizer.mousemove = mousemove;

        _resizer.onmousedown = () => {
            try {
                // console.log("Grip");
                let maxHeight = 'calc(100vh - 423px)';
                const tableBody = document.querySelectorAll(`[data-type="${elementPrefix}TableBody"]`);
                if (tableBody && tableBody[0] && tableBody[0].clientHeight) {
                    maxHeight = `calc(${tableBody[0].clientHeight}px + 41px)`;
                }

                _resizer.style.height = maxHeight;
                _resizer.style.top = '0';
                document.getElementById(`${elementPrefix}mask`).style.display = 'block';
                document.getElementById(`${elementPrefix}mask`).style.cursor = cursor;
                addListener(document.documentElement, 'mousemove', _resizer.doDrag, false);
                addListener(document.documentElement, 'mouseup', _resizer.stopDrag, false);
            } catch (err) {
                // console.log('fail Grip');
                console.error(err);
            }
        };

        _resizer.doDrag = (e) => {
            try {
                if (e.which !== 1) {
                    // console.log("StopMove");
                    _resizer.stopDrag(e);
                    return;
                }
                // console.log("Move");
                _resizer.mousemove(e);
            } catch (err) {
                // console.log('fail Move');
                console.error(err);
            }
        };

        _resizer.stopDrag = () => {
            try {
                // console.log("UnGrip");
                const table = document.querySelectorAll(`[data-type="${elementPrefix}Table"]`);
                if (table && table[0]) {
                    const tableWidth = table[0].clientWidth;
                    Object.keys(colsWidth).forEach((key) => {
                        if (colsWidth.hasOwnProperty(key) && colsWidth[key].toLowerCase().includes('px')) {
                            const curWidth = colsWidth[key].toLowerCase().replace('px', '');
                            const cell = document.getElementById(`${key}`).parentElement;
                            colsWidth[key] = `${Math.ceil((curWidth / tableWidth) * 100)}%`;
                            cell.style.width = colsWidth[key];
                        }
                    });
                    resizeAllCols(colsWidth);
                }

                _resizer.style.height = height;
                document.getElementById(`${elementPrefix}mask`).style.display = 'none';
                document.getElementById(`${elementPrefix}mask`).style.cursor = 'auto';
                removeAllListeners(document.documentElement, 'mousemove');
                removeAllListeners(document.documentElement, 'mouseup');
            } catch (err) {
                // console.log('fail UnGrip');
                console.error(err);
            }
        };
    }
}

function resize_X(x, elm, elm2, colsWidth) {
    const currElm = document.getElementById(elm).parentElement;
    const nxtElm = document.getElementById(elm2).parentElement;
    if (currElm && nxtElm) {
        const nxtElmOldWidth = nxtElm.clientWidth;

        const oldWidth = currElm.clientWidth;

        const newWidth = x - currElm.getBoundingClientRect().x + 3;

        const diff = newWidth - oldWidth;

        const nxtElmNewWidth = nxtElmOldWidth - diff;

        if (newWidth >= 60 && nxtElmNewWidth >= 60) {
            currElm.style.width = `${newWidth}px`;
            nxtElm.style.width = `${nxtElmNewWidth}px`;

            colsWidth[elm] = `${newWidth}px`;
            colsWidth[elm2] = `${nxtElmNewWidth}px`;

            resizeAllCols(colsWidth);
        }
    }
}

export function resizerX(resizerID, elem1, elem2, colsWidth, elementPrefix,height='40px') {
    resizer(
        resizerID,
        'col-resize',
        (e) => {
            resize_X(e.pageX, `${elementPrefix}${elem1}`, `${elementPrefix}${elem2}`, colsWidth);
        },
        colsWidth,
        elementPrefix,
        height
    );
}

export function handleXpathLength(val) {
    let len = 0;
    const helper = (isForLen = false) => {
        let value = val;
        let newValue = '';
        if (value) {
            while (value !== '') {
                let currVal = value;
                const temp = value.match(new RegExp('(.*?){(.*?)}(.*?)', 'i'));
                if (temp && temp.length > 3) {
                    let trimmed = temp[2];
                    if (isForLen && temp[2].length > 40) {
                        len++;
                        if (len > 1) {
                            return;
                        }
                    } else if (!isForLen && temp[2].length > 40) {
                        const c = len === 1 ? 20 : 10;
                        trimmed = `${trimmed.substring(0, c)}...${trimmed.substring(trimmed.length - c, trimmed.length)}`;
                    }
                    currVal = `${temp[1]}{${temp[2]}}${temp[3]}`;
                    newValue = `${newValue}${temp[1]}{${trimmed}}${temp[3]}`;
                } else {
                    newValue = `${newValue}${currVal}`;
                }
                value = value.replace(currVal, '');
            }
        }
        // eslint-disable-next-line consistent-return
        return newValue;
    };
    helper(true);
    return helper();
}

export function resizeAllCols(colsWidth, prefix = '') {
    Object.keys(colsWidth).forEach((key) => {
        if (colsWidth.hasOwnProperty(key)) {
            const rows = document.querySelectorAll(`[data-resize="${prefix}${key}_c"]`);
            for (let i = 0; i < rows.length; i++) {
                const element = rows[i];
                element.style.width = colsWidth[key];
            }
        }
    });

    const tableSubHeader = document.querySelectorAll('[data-sub-header="subHeader"]');
    if (tableSubHeader) {
        for (let i = 0; i < tableSubHeader.length; i++) {
            const header = tableSubHeader[i];
            if (header) {
                const key = header.attributes['data-child-resize'].value;
                if (key) {
                    const parentIndex = key.split('_')[1];
                    const colSpan = key.split('_')[2];
                    const rows = document.querySelectorAll(`[data-child-resize="${key}"]`);
                    for (let j = 0; j < rows.length; j++) {
                        const element = rows[j];
                        element.style.width = `calc(${colsWidth[`element${parentIndex}`]} / ${colSpan})`;
                    }
                }
            }
        }
    }
}

// export function resizerY(resizerID, elem1, elem2) {
//     // resizer(resizerID, "row-resize", function (e) {
//     //     resize_Y(e.pageY, elem1, elem2); // have to create resize_Y for this
//     // });
// }
/* Stop Resizer Work */

export function sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function replaceCharsForStepsCSV(text) {
    if (text && typeof text === 'string') {
        let _text = text;
        // $uieuc$(UI escape Uni Code) is temp text whick is replacing \(back slash) if it is inSide ""(quote marks)
        const quotes = ['"', "'"];
        quotes.forEach((quote) => {
            let reg = null;
            if (quote === "'") {
                reg = /('.*\\.*')+/g;
            } else if (quote === '"') {
                reg = /(".*\\.*")+/g;
            }
            if (reg) {
                let ignoreBackSlash = _text.match(reg);
                while (checkArrayLength(ignoreBackSlash) && ignoreBackSlash[0]) {
                    const tempText = ignoreBackSlash[0].replace(/\\/g, '$uieuc$');
                    _text = _text.replace(ignoreBackSlash[0], tempText);
                    ignoreBackSlash = _text.match(reg);
                }
            }
        });
        return( 
        //  _.trim(_text.replace(/\n|\\/g, '$ud$'), '$ud$')
         _.trim(_text)
             .replace(/\$ud\$/, '. ')
             .replace(/\$uieuc\$/g, '\\')
        )
    }
    return '';
}
export function getParamValues(paramIndex, location) {
    let fullPathName = window.location.pathname.slice(1);
    if (checkKeyInObject(location, 'pathname', 'bool')) {
        fullPathName = location.pathname.slice(1);
    }
    const pathNameArraySplitedByHash = fullPathName.split('#');
    const paramArray = pathNameArraySplitedByHash[0].split('/');
    if (Array.isArray(paramIndex)) {
        return paramIndex.map((ind) => paramArray[ind]);
    }
    if (paramIndex && paramIndex !== -1) {
        return paramArray[paramIndex];
    }
    return paramArray;
}
export function isOnProjectPage() {
    const fullPathName = window.location.pathname.slice(1);
    const pathname = fullPathName.split('#')[0];
    return fullPathName === 'projects' || fullPathName === 'projects/starred' || fullPathName === 'projects/disabled';
}

/**
 * @param key: String --use to open and close progress bar for selected request
 * @param message: String (Optional) --use to show what is in progress
 * @param action: String (default end) --use to decide open or close functions will invoke
 * */
export function customProgressBar(key, message = '', action = 'end') {
    if (action === 'start') {
        const { isSnackBarOpen } = store.getState().modalReducer;
        // open snackbar if not already open
        if (!isSnackBarOpen) {
            store.dispatch(ModalActions.toggleSnackBar(message, '', false, null, false, false, true));
        }
        // custom function to update desired intervals delay
        const customSetInterval = (delay) => {
            return setInterval(() => {
                ___progressPercentage += ___progressIncrementor;
                // decrease ___progressIncrementor as per stages of ___progressPercentage
                switch (___progressPercentage) {
                    case 50:
                        ___progressIncrementor = 4;
                        break;
                    case 60:
                        ___progressIncrementor = 3;
                        break;
                    case 70:
                        ___progressIncrementor = 2;
                        break;
                    case 80:
                        ___progressIncrementor = 1;
                        break;
                    case 90:
                        clearInterval(___progressInterval); // close interval before starting with new delay
                        ___progressInterval = customSetInterval(1000); // increase interval delay
                        ___progressIncrementor = 0.5;
                        break;
                    case 99:
                        ___progressIncrementor = 0; // wait till resquest completes
                        break;
                    default:
                        break;
                }
                // update progress bar with new percentage
                store.dispatch(ModalActions.updateProjectDownloadProgress(key, ___progressPercentage, ''));
            }, delay);
        };
        ___progressInterval = customSetInterval(500);
    } else if (___progressPercentage < 100) {
        // if request completes and ___progressPercentage is still less than 100
        clearInterval(___progressInterval); // close interval before starting with new delay
        ___progressInterval = setInterval(() => {
            // complete progress bar with fast speed
            ___progressPercentage += 1;
            // update progress bar with new percentage
            store.dispatch(ModalActions.updateProjectDownloadProgress(key, ___progressPercentage, ''));
            if (___progressPercentage > 99) {
                // during interval when ___progressPercentage crosses 99
                clearInterval(___progressInterval); // close interval
                store.dispatch(ModalActions.updateProjectDownloadProgress(key)); // close particular progress bar
                // reset all variables
                ___progressInterval = null;
                ___progressPercentage = 0;
                ___progressIncrementor = 5;
            }
        }, 1);
    }
}

export function donwloadThroughLinkClick(response, filename = 'file.txt', type = 'plain/text') {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([response.data], { type }));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const cmpMultiPointNumber = (a, comp, b) => {
    let cmp;
    const compObj = {
        0: ['==', '===', '<=', '>='],
        '-1': ['<', '<=', '<>'],
        1: ['>', '>=', '<>'],
    };
    a = `${a}`.split('.');
    b = `${b}`.split('.');
    const len = Math.max(a.length, b.length);
    let res = 0;
    for (let i = 0; i < len; i++) {
        cmp = parseInt(a[i] || '0', 10) - parseInt(b[i] || '0', 10);
        if (cmp !== 0) {
            res = cmp < 0 ? -1 : 1;
            break;
        }
    }
    return compObj[`${res}`].includes(comp);
};

export const getMinNearest15Interval = (min) => {
    if (min === 0) return 0;
    const diff = min % 15;
    let v = min;
    if (diff > 0) {
        v += 15 - diff;
    }
    return v;
};

export const convertToSelectValues = (inputArray = []) => {
    return inputArray.map((element) => {
        const obj = {
            id: element.id,
            value: element.value,
            label: element.label || element.value,
        }
        if('is_suite_executable' in element){
            obj.is_suite_executable = element.is_suite_executable
        }
        return {
            ...obj
        };
    });
};

export const incrementInstrNum = (instrNum, inc = 1) => {
    return `${instrNum.slice(0, instrNum.lastIndexOf('.'))}.${Number(instrNum.slice(instrNum.lastIndexOf('.') + 1)) + inc}`;
};

/**
 * @param list: Array --on which you want to iterate await function
 * @param func: Function --this function will run on every iteration (same function as we use in map,forEach etc)
 * @param afterLoopComplete: Callback Function (Optional) --this function is called after all iterations are completed (It has a parameter by which you can get all iteration responces in array [If Any])
 * */
export const arrayAwait = (list, func, afterLoopComplete = () => {}) => {
    const functionWithPromise = async (...args) => {
        // a function that returns a promise
        const resp = await func(...args);
        return Promise.resolve(resp);
    };
    const getData = async () => {
        return Promise.all(list.map(functionWithPromise));
    };
    getData().then((data) => {
        afterLoopComplete(data);
    });
};

export const getTextProperties = (value = '', skip = []) => {
    function getStringProperty(str) {
        const p = {
            empty_string: /^$/.test(str),
            only_white_space: /^\s+$/.test(str),
            leading_space: /^\s* \s*\S/.test(str),
            leading_tab: /^\s*\t\s*\S/.test(str),
            leading_new_line: /^\s*[\n\r\v\f]\s*\S/.test(str),
            new_line: /^\s*[\n\r\v\f]\s*$/.test(str),
            trailing_space: /\S\s* \s*$/.test(str),
            trailing_tab: /\S\s*\t\s*$/.test(str),
            trailing_new_line: /\S\s*[\n\r\v\f]\s*$/.test(str),
        };

        p.multi_line = /[\n\r\v\f]/.test(str.trim());
        const multiline = p.multi_line; // nno sure what is best way to check multiline

        // even if you use "m" flag javascript regexp processor may cross comparison accross
        // multiple lines. adding extra letters will gard aginst this behaviour
        str = str.replace(/^/gm, 'm').replace(/$/gm, 'M');

        p.line_with_only_white_space = multiline && /^m\s+M$/m.test(str);
        str = str.replace(/^m\s+M/gm, 'mM');
        p.consecutive_new_lines = multiline && /[\n\r\v\f]mM[\n\r\v\f]/.test(str);

        p.line_with_leading_white_space = multiline && /^m\s+\S/m.test(str);
        p.line_with_trailing_white_space = multiline && /\S\s+M$/m.test(str);

        str = str.replace(/^m\s+|\s+M$/gm, 'x');
        p.consecutive_white_space = /\s\s/.test(str); // other consecutive whitespace
        return p;
    }
    function listprop(o) {
        const s = [];
        Object.keys(o).forEach((key) => {
            const v = key.replace(/_/g, ' ');
            if (o[key] && !skip.includes(v)) {
                s.push(v);
            }
        });
        return s;
    }
    return listprop(getStringProperty(value));
};

/**
 * @param start: Number --Initial value
 * @param end: Number --Ending value (Optional)
 * */
export const generateNumberOptionsForSelect = (start, end) => {
    end = !Number.isNaN(end) ? end : start;
    if (!Number.isNaN(start) && end >= start)
        return [...new Array(end + 1 - start)].map((v, i) => {
            return { id: i + 1, value: `${i + start}`, label: `${i + start}` };
        });
    return [];
};

export const toTitleCase = (str) => {
    if (typeof str === 'string') {
        return str.replace(/\w\S*/g, (txt) => {
            return capitalizeString(txt);
        });
    }
    return str;
};

export const renderFileModal = (toggleModal, testDataValues) => {
    const handleDownload = async (fileType) => {
        await testDataValues.handleDownload(testDataValues.APIData, testDataValues.data, 'Live Steps', fileType);
        toggleModal();
    };

    toggleModal('GeneralModal', null, null, {
        title: testDataValues.name,
        closeIconAction: () => toggleModal(),
        component: [
            {
                content: <PreviewTestCase testSteps={testDataValues.data} />,
                buttons: [
                    NEXT_SUBMIT_BUTTON({
                        name: 'Download CSV',
                        action: () => handleDownload('csv'),
                    }),
                    NEXT_SUBMIT_BUTTON({
                        name: 'Download XLSX',
                        action: () => handleDownload('xlsx'),
                    }),
                    CANCEL_BUTTON({ action: () => toggleModal() }),
                ],
            },
        ],
    });
};

/**
 * @param obj: Object OR Array of objects
 * @param keys: Array of string
 * */
export const removeKeysFromObjAndSubObj = (obj, keys) => {
    if (checkArrayLength(keys)) {
        let newObj = null;
        if (typeof obj !== 'string' && checkObject(obj)) {
            newObj = JSON.parse(JSON.stringify(obj));
            Object.keys(newObj).forEach((key) => {
                if (keys.includes(key)) {
                    delete newObj[key];
                } else if (typeof newObj[key] !== 'string' && (checkObject(newObj[key]) || checkArrayLength(newObj[key]))) {
                    newObj[key] = removeKeysFromObjAndSubObj(newObj[key], keys);
                }
            });
        } else if (checkArrayLength(obj)) {
            newObj = JSON.parse(JSON.stringify(obj));
            newObj.forEach((_obj, ind) => {
                newObj[ind] = removeKeysFromObjAndSubObj(_obj, keys);
            });
        }
        if (newObj) {
            return newObj;
        }
    }
    return obj;
};

/**
 * @param arr: Array
 * @param i: Number: The zero-based location in the array from which to start removing elements.
 * @param len: Number: The number of elements to remove.
 * */
export const removeIndexFromArray = (arr, i, len = 1) => {
    if (checkArrayLength(arr)) {
        arr.splice(i, len);
    }
};

/**
 * @param arr: Array
 * @param i: Number: The zero-based location in the array where to start add elements.
 * @param items: Number: Elements to insert into the array in place of the deleted elements.
 * */
export const pushInMidArray = (arr, i, items) => {
    if (checkArrayLength(arr)) {
        arr.splice(i, 0, items);
    }
};

export const extension = (resType) => {
    switch (resType) {
        case 'application/vnd.ms-excel':
        case 'application/octet-stream':
            return 'xls';

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'xlsx';

        case 'application/pdf':
            return 'pdf';

        case 'text/csv':
            return 'csv';

        case 'image/jpeg':
            return 'jpeg';

        case 'image/png':
            return 'png';

        case 'application/json':
            return 'json';

        default:
            return 'txt';
    }
};

export const getTimeInHours = (schedulingData) => {
    let hours = 0;
    schedulingData.forEach((data) => {
        switch (data.key) {
            case 1: // Month
                hours += data.value * 730;
                break;
            case 2: // Day
                hours += data.value * 24;
                break;
            case 3: // Hour
                hours += data.value;
                break;
            default:
                break;
        }
    });
    return hours;
};

export const isFormattedTooltip = (_status) => ['0', '1', '2', '3'].includes(`${_status}`);

export const getMessageTooltip = (_status) => {
    return isFormattedTooltip(_status) ? FormattedTooltip : Tooltip;
};

export const updateSliderSetting = (slidesToShow) => {
    const btns = document.querySelectorAll('.slick-arrow');
    if (btns) {
        btns.forEach((btn) => {
            btn.setAttribute('tabindex', -1);
        });
    }
    const img = document.getElementsByClassName('slick-slide')[0];
    const imgCont = document.getElementById('subListViewScreenShotContainer');
    if (img && imgCont) {
        const imgWidth = img.clientWidth;
        const imgContWidth = imgCont.parentElement.clientWidth - 80;
        const _slidesToShow = parseInt(imgContWidth / imgWidth, 10);
        if (_slidesToShow && !Number.isNaN(_slidesToShow) && slidesToShow !== _slidesToShow) {
            imgCont.style.width = `${_slidesToShow * imgWidth}px`;
            return _slidesToShow;
        }
    }
    return null;
};

export const checkIsArrayHasDuplicateElements = (array=[],key='id') => {
    
    if(!checkIsArray(array)) {
        throw Error('Please provide first argument of type object as an array')
    }

    
    const valuesAlreadySeen = [];

    for(let i=0;i<array.length;i++) {

        let value = null;

        if(checkObject(array[i])) {
            value = array[i][key]
        } else {
            value = array[i]
        }

        if (valuesAlreadySeen.indexOf(value) !== -1) {
          return true
        }
        valuesAlreadySeen.push(value)
    }
    return false;
}
