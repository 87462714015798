import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignal, faCodeBranch, faUsers } from '@fortawesome/free-solid-svg-icons';
import logo from './AutonomiQ Logo Shape.svg';
import { DIALOG_ROOT, BLACK_FONT } from '../../common/cssConstants';

const styles = () => ({
    dialogRoot: DIALOG_ROOT,
    container: {
        width: '760px',
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#3B91DF',
    },
    headerContent: {
        width: '80%',
        height: '120px',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '55px',
        justifyContent: 'center',
    },
    modalHeading: {
        color: '#fff',
        fontSize: 28,
        fontWeight: 500,
        lineHeight: '37px',
        margin: 0,
    },
    bottomHeading: {
        margin: 0,
        fontSize: '16px',
        color: '#fff',
    },
    dialogCloseButton: {
        display: 'flex',
        width: '20%',
        justifyContent: 'flex-end',
        padding: '10px 10px',
    },
    close: {
        color: '#fff9',
        cursor: 'pointer',
    },
    content: {
        padding: '40px 55px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 216px)',
    },
    heading: {
        margin: '0px 0px 8px 0px',
        fontSize: 16,
        color: BLACK_FONT,
        fontWeight: 500,
        lineHeight: '21px',
    },
    listCard: {
        borderRadius: '8px',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.2)',
        padding: '15px 150px 15px 20px',
        marginBottom: '10px',
    },
    listCardContent: {
        margin: 0,
        fontSize: 14,
        lineHeight: '19px',
        color: '#979797',
    },
    headline: {
        marginBotttom: '5px',
        display: 'flex',
    },
    signalIcon: {
        color: '#1168cd',
        minWidth: '35px',
    },
    logo: {
        marginRight: 10,
    },
});

const data = [
    {
        isRoute: true,
        icon: faSignal,
        modalName: 'businessAnalystTour',
        heading: 'For business analysts',
        description:
            'Explain the intent of what you want to test and validate, and AutonomIQ will automatically generate, execute and maintain the tests for you. It takes only 3 steps and 30 minutes to do the task.',
    },
    {
        isRoute: false,
        icon: faCodeBranch,
        modalName: null,
        heading: 'For developers and testers',
        description:
            'Reduce the time you take to write and maintain scripts. Using AutonomIQ find out how you can focus on doing more product development, as opposed to writing and maintaining test scripts.',
    },
    {
        isRoute: false,
        icon: faUsers,
        modalName: null,
        heading: 'For dev and test managers',
        description:
            'Improve the productivity of your testing and QA teams, while continuing to improve the quality of the product. Now your teams can do more in less amount of time, and release high quality software.',
    },
];

const handleClick = (item, toggleModal) => {
    if (item.isRoute) toggleModal(item.modalName, '', '', { modalName: item.modalName }, true);
};

function middleItem({ classes, toggleModal }) {
    return data.map((item, index) => {
        return (
            <div
                aria-hidden
                key={index}
                className={classes.listCard}
                style={{ cursor: item.isRoute ? 'pointer' : 'default' }}
                onClick={() => {
                    handleClick(item, toggleModal);
                }}
            >
                <div className={classes.headline}>
                    <FontAwesomeIcon icon={item.icon} aria-label="item.icon" id="item.icon" className={classes.signalIcon} />
                    <p className={classes.heading}>{item.heading}</p>
                </div>
                <p className={classes.listCardContent}>{item.description}</p>
            </div>
        );
    });
}

function HomeTour(props) {
    const { classes, open, toggleModal } = props;
    return (
        <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" maxWidth="md" className={classes.dialogRoot}>
            <div className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.headerContent}>
                        <div style={{ display: 'flex', marginBottom: '5px' }}>
                            <img src={logo} alt="" className={classes.logo} />
                            <p className={classes.modalHeading}>Welcome to AutonomIQ Product Tour</p>
                        </div>
                        <p className={classes.bottomHeading}>
                            This guide will help you to find out the the most valuable features depend on your goals. Choose your role to start a tour
                        </p>
                    </div>
                    <div className={classes.dialogCloseButton}>
                        <Close
                            className={classes.close}
                            aria-label="closeIcon"
                            id="closeIcon"
                            onClick={() => {
                                toggleModal();
                            }}
                        />
                    </div>
                </div>
                <div className={classes.content}>{middleItem({ classes, toggleModal })}</div>
            </div>
        </Dialog>
    );
}

HomeTour.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    toggleModal: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(HomeTour);
