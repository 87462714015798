import UserMiddleware from '../middleware/userMiddleware';
import { ActionTypes } from '../constants';
import AuthActions from './authActions';
import { SidebarActions } from './SidebarAction';
import { checkKeyInObject } from '../../utils';

class UserActions {
    static clearError() {
        return {
            type: ActionTypes.CLEAR_ERROR,
        };
    }

    static clearSuccess() {
        return {
            type: ActionTypes.CLEAR_SUCCESS,
        };
    }

    static getUsers() {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.GET_USERS });
            try {
                const data = await UserMiddleware.getUsers();
                if (data)
                    dispatch({
                        type: ActionTypes.GET_USERS_SUCCESS,
                        payload: data.users,
                    });
                // self.isLoggedin = true;
            } catch (error) {
                console.error(error.toString()); // tslint:disable-line no-console
                dispatch({
                    type: ActionTypes.GET_USERS_FAILURE,
                    payload: {
                        error,
                        message: 'user not found',
                    },
                });
            }
        };
    }

    static getUserByAccountId(accountId) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.GET_USERS });
            try {
                const data = await UserMiddleware.getUserByAccountId(accountId);
                if (data)
                    dispatch({
                        type: ActionTypes.GET_USERS_SUCCESS,
                        payload: data.users,
                    });
            } catch (error) {
                dispatch({
                    type: ActionTypes.GET_USERS_FAILURE,
                    payload: {
                        error,
                        message: 'Users not found',
                    },
                });
            }
        };
    }

    static getUserById(userId) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_USER });

            return UserMiddleware.getUserById(userId)
                .then(({ data }) => {
                    dispatch(AuthActions.updateIsRelativeDate(data.isRelativeDateFormat));
                    return dispatch({
                        type: ActionTypes.GET_USER_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: ActionTypes.GET_USER_FAILURE,
                        payload: {
                            error,
                            message: error && error.response && error.response.data ? error.response.data : 'Failed to get user profile details',
                        },
                    });
                });
        };
    }

    static getUserByEmail(email) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_USER });

            return UserMiddleware.getUserByEmail(email)
                .then((response) =>
                    dispatch({
                        type: ActionTypes.GET_USER_SUCCESS,
                        payload: response,
                    }),
                )
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_USER_FAILURE,
                        payload: {
                            error,
                            message: 'user not found',
                        },
                    }),
                );
        };
    }

    static createUser(data) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.CREATE_USER });
            return UserMiddleware.createUser(data)
                .then((response) => {
                    dispatch(SidebarActions.increaseSidebarCount('num_of_users'));
                    dispatch({
                        type: ActionTypes.CREATE_USER_SUCCESS,
                        payload: {
                            data: response,
                            msg: 'Create User Success',
                        },
                    });
                    return { status: true, message: 'Success' };
                })
                .catch((error) => {
                    dispatch({
                        type: ActionTypes.CREATE_USER_FAILURE,
                        payload: {
                            error,
                            _error:typeof error === 'string' ? error :'Error',
                            message: 'Fail to create user',
                        },
                    });
                    return { status: false, message: error };
                });
        };
    }

    static updateUser(data) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.UPDATE_USER });
            return UserMiddleware.updateUser(data)
                .then((response) => {
                    return dispatch({
                        type: ActionTypes.UPDATE_USER_SUCCESS,
                        payload: response,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.UPDATE_USER_FAILURE,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static deleteUser(userId) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.DELETE_USER });
            return UserMiddleware.deleteUser(userId)
                .then((data) => {
                    dispatch(SidebarActions.decreaseSidebarCount('num_of_users', userId.length));
                    dispatch({
                        type: ActionTypes.DELETE_USER_SUCCESS,
                        payload: data,
                    });
                    return data;
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.DELETE_USER_FAILURE,
                        payload: {
                            error,
                            message: 'Failed to delete',
                        },
                    });
                });
        };
    }

    static changesUserStatus(userId, status) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.CHANGE_USER_STATUS });
            return UserMiddleware.changesUserStatus(userId, status)
                .then((data) => {
                    dispatch({
                        type: ActionTypes.CHANGE_USER_STATUS_SUCCESS,
                        payload: data,
                    });
                    return data;
                })
                .catch((error) => {
                    dispatch({
                        type: ActionTypes.CHANGE_USER_STATUS_FAILURE,
                        payload: {
                            error,
                            message: 'Failed to change user status',
                        },
                    });
                    return false;
                });
        };
    }

    // Accounts
    static getAccounts() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_ACCOUNTS });
            return UserMiddleware.getAccounts()
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.GET_ACCOUNTS_SUCCESS,
                        payload: data.accounts,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.GET_ACCOUNTS_FAILURE,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static createAccount(data) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.CREATE_ACCOUNT });
            return UserMiddleware.createAccount(data)
                .then((_data) => {
                    dispatch(SidebarActions.increaseSidebarCount('num_of_accounts'));
                    return dispatch({
                        type: ActionTypes.CREATE_ACCOUNT_SUCCESS,
                        payload: {
                            _data,
                            msg: 'Account Created Successfully',
                        },
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.SHOW_ERROR,
                        payload: {
                            error,
                            message: error,
                        },
                    });
                });
        };
    }

    static createGroup(data, onComplete = () => {}) {
        return (dispatch) => {
            return UserMiddleware.createGroup(data)
                .then((_data) => {
                    onComplete(_data);
                    dispatch(SidebarActions.increaseSidebarCount('num_of_groups'));
                    return dispatch({
                        type: ActionTypes.CREATE_GROUP_SUCCESS,
                        payload: { message: 'Group Created Successfully' },
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.CREATE_GROUP_FAILURE,
                        payload: {
                            error,
                            message: error && error.response && error.response.data ? error.response.data : 'Failed to create group',
                        },
                    });
                });
        };
    }

    static getGroups(accountId) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_GROUPS });
            return UserMiddleware.getGroups(accountId)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.GET_GROUPS_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.GET_GROUPS_FAILURE,
                        payload: {
                            error,
                            message: 'Failed to create group',
                        },
                    });
                });
        };
    }

    static updateGroup(data, cb = () => {}) {
        return (dispatch) => {
            return UserMiddleware.updateGroup(data)
                .then(() => {
                    cb();
                    return dispatch({
                        type: ActionTypes.UPDATE_GROUP_SUCCESS,
                        payload: { message: 'Group updated Successfully' },
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.UPDATE_GROUP_FAILURE,
                        payload: {
                            error,
                            message: 'Failed to update group',
                        },
                    });
                });
        };
    }

    static deleteGroups(groupIds) {
        return (dispatch) => {
            return UserMiddleware.deleteGroups(groupIds)
                .then(() => {
                    dispatch(SidebarActions.decreaseSidebarCount('num_of_groups'));
                    dispatch({
                        type: ActionTypes.DELETE_GROUPS_SUCCESS,
                        payload: { message: 'Groups deleted Successfully' },
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.DELETE_GROUPS_FAILURE,
                        payload: {
                            error,
                            message: 'Failed to delete groups',
                        },
                    });
                });
        };
    }

    static associateUsersWithGroup(payload) {
        return (dispatch) => {
            return UserMiddleware.associateUsersWithGroup(payload)
                .then(() => {
                    return dispatch({
                        type: ActionTypes.ASSOCIATE_USER_TO_GROUP_SUCCESS,
                        payload: { message: 'User Associated Successfully' },
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.ASSOCIATE_USER_TO_GROUP_FAILURE,
                        payload: {
                            error,
                            message: error.response && error.response.data ? error.response.data : 'Failed to associate users',
                        },
                    });
                });
        };
    }

    static dissociateUser(payload) {
        return (dispatch) => {
            return UserMiddleware.dissociateUser(payload)
                .then(() => {
                    return dispatch({
                        type: ActionTypes.DISSOCIATE_USER_TO_GROUP_SUCCESS,
                        payload: { message: 'User Dissociated Successfully' },
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.DISSOCIATE_USER_TO_GROUP_FAILURE,
                        payload: {
                            error,
                            message: error.response && error.response.data ? error.response.data : 'Failed to dissociate users',
                        },
                    });
                });
        };
    }

    static toggleSideBarItemExpansion(type = null) {
        return {
            type: type ? ActionTypes[`EXPAND_${type.toUpperCase()}_SIDEBAR`] : ActionTypes.COLLAPE_ALL_ITEMS_SIDEBAR,
        };
    }

    static handelTableCol(data) {
        return (dispatch) => {
            return dispatch({
                type: ActionTypes.HANDLE_TABLE_COL,
                payload: data,
            });
        };
    }

    static changeStatus(headers) {
        return {
            type: ActionTypes.MANAGE_GROUP_HEADERS,
            payload: headers,
        };
    }

    static enableDisableGroups(obj, onComplete = () => {}) {
        return (dispatch) => {
            return UserMiddleware.enableDisableGroups(obj)
                .then(() => {
                    onComplete();
                    const name = obj.group_ids.length > 1 ? 'Groups' : 'Group';
                    return dispatch({
                        type: ActionTypes.ENABLE_DISABLE_GROUPS_SUCCESS,
                        payload: { message: `${name}${obj.status === 1 ? ' disabled successfully' : ' enabled successfully'}` },
                    });
                })
                .catch((error) => {
                    const name = obj.group_ids && obj.group_ids.length > 1 ? 'groups' : 'group';
                    return dispatch({
                        type: ActionTypes.ENABLE_DISABLE_GROUPS_FAILURE,
                        payload: {
                            error,
                            message: `${obj.status === 1 ? 'Failed to enabled ' : 'Failed to enable '}${name}`,
                        },
                    });
                });
        };
    }

    static postUserProfileInfo(payload, img) {
        return (dispatch, getState) => {
            return UserMiddleware.postUserProfile({ ...payload, id: getState().userReducer.userDetails.id }, img)
                .then(() => {
                    dispatch(AuthActions.updateIsRelativeDate(payload.isRelativeDateFormat));
                    return dispatch({
                        type: ActionTypes.POST_USER_PROFILE_DATA_SUCCESS,
                        payload: { message: 'Profile updated successfully.' },
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.POST_USER_PROFILE_DATE_FAILURE,
                        payload: {
                            message:
                                error.response && error.response.data && error.response.data.error
                                    ? error.response.data.error
                                    : 'Failed to post user profile details',
                        },
                    });
                });
        };
    }

    static sendUserProjectPermission(objData) {
        return (dispatch) => {
            return UserMiddleware.sendUserProjectPermission(objData)
                .then(() => {
                    return dispatch({
                        type: ActionTypes.UPDATE_USER_PERMISSIONS_SUCCESS,
                        payload: 'Successfuly updated user permissions',
                    });
                })
                .catch((err) => {
                    return dispatch({
                        type: ActionTypes.UPDATE_USER_PERMISSIONS_FAILED,
                        payload: `${checkKeyInObject(err, 'response.data', 'value', '')}`.includes(
                            'in permission user is not in same account as the project',
                        )
                            ? 'User is not in same account as the project'
                            : 'Failed to update user permissions.',
                    });
                });
        };
    }

    static StartGenrate(obj) {
        return (dispatch) => {
            return UserMiddleware.SendGenerationData(obj)
                .then(() => {
                    return dispatch({
                        type: ActionTypes.SEND_GENERATION_DATA_SUCCESS,
                        payload: 'Successfully generate',
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.SEND_GENERATION_DATA_FAILED,
                        payload: 'Error is sending generating data',
                    });
                });
        };
    }

    static webSocketErr() {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.WEBSOCKET_ERROR,
                payload: 'Unable to create session with recorder.',
            });
        };
    }
}

export default UserActions;
