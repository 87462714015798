import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';

import { LOGIN_PAGE_CSS, BLACK_FONT } from '../../common/cssConstants';
import { COLORS } from '../../common/Colors';

const AuthListItemStyles = makeStyles(() => ({
    contactBtn: {
        borderRadius: '19.5px',
        backgroundColor: '#0092e6',
        color: LOGIN_PAGE_CSS.darkFontColor,
        '&:hover': {
            color: BLACK_FONT,
        },
    },
    listItemRoot: {
        width: 145,
    },
    listItemGutters: {
        paddingRight: 24,
        paddingLeft: 24,
    },
    contact: {
        color: 'inherit',
        fontWeight: '500',
        fontSize: '14px',
    },
    arrowIcon: {
        color: COLORS.white,
        marginRight: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-right',
        '&:hover': {
            color: BLACK_FONT,
        },
    },
    firstArrow: {
        marginRight: -19,
    },
    listItemIconRoot: {
        minWidth: 0,
    },
    listItemSecondaryAction: {
        top: '46%',
    },
}));

const AuthListItem = (props) => {
    const classes = AuthListItemStyles();

    return (
        <ListItem
            button
            className={classes.contactBtn}
            component={props.href ? 'a' : ''}
            href={props.href}
            target={props.href ? '_blank' : ''}
            classes={{
                root: `${classes.listItemRoot} ${props.listItemRoot}`,
                gutters: classes.listItemGutters,
            }}
            onClick={props.onClick}
        >
            <ListItemText
                disableTypography
                primary={
                    <Typography type="body2" className={classes.contact}>
                        {props.text}
                    </Typography>
                }
            />
            <ListItemSecondaryAction classes={{ root: classes.listItemSecondaryAction }}>
                <ListItemIcon
                    className={classes.arrowIcon}
                    classes={{
                        root: classes.listItemIconRoot,
                    }}
                >
                    <>
                        <ArrowRight className={classes.firstArrow} />
                        <ArrowRight />
                    </>
                </ListItemIcon>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

AuthListItem.propTypes = {
    href: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    listItemRoot: PropTypes.string,
};

AuthListItem.defaultProps = {
    href: '',
    onClick: () => {},
    listItemRoot: '',
};

export { AuthListItem };
