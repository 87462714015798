import React from 'react';
import { ModalActions, TestSuiteActions, generalModalActions } from '../store/actions';
import { NEXT_SUBMIT_BUTTON, CANCEL_BUTTON } from '../components/Button/Btn';
import CloneSuite from '../features/plan/case/CloneSuite';
import { checkKeyInObject } from './utils';
import store from '../store/index';
import SuiteNameandInfo from '../features/plan/suite/Modal/SuiteNameandInfo';
import SuiteAssociationModal from '../features/plan/suite/Modal/SuiteAssociationModal';
import SuiteOrganizeModal from '../features/plan/suite/Modal/SuiteOrganizeModal';
import { SUITE_CONDITIONS, DELAY_UNIT } from '../common/constants';
import SuitePostAction from '../features/plan/suite/Modal/SuitePostAction';

let submitDisable = false;
let isNameExist = true;
class TestSuite {
    buttons = (onCloseModal) => [
        NEXT_SUBMIT_BUTTON({ action: () => {isNameExist ? this.props.changeTab(this.props.currentTab + 1) : this.props.changeTab(this.props.currentTab)}  }),
        CANCEL_BUTTON({ action: () => onCloseModal() }),
    ];

    onCloneTestSuite = async (testSuite, suiteTab) => {
        const { tabsData } = store.getState().generalModalReducer;
        const updatedSuiteData = tabsData.cloneSuite.state;
        if (checkKeyInObject(updatedSuiteData, 'testSuiteName') && checkKeyInObject(updatedSuiteData, 'selectedProjectId')) {
            const _suiteData = {
                testSuiteId: testSuite.testSuiteId,
                suite_name: updatedSuiteData.testSuiteName.trim(),
                project_id: Number(updatedSuiteData.selectedProjectId),
            };
            if (`${updatedSuiteData.selectedProjectId}` !== `${testSuite.projectId}` && suiteTab === 'projectDetails') suiteTab = '';
            store.dispatch(TestSuiteActions.cloneTestSuite(_suiteData, suiteTab));
        }
        this.closeCloneTestSuite();
    };

    closeCloneTestSuite = () => {
        store.dispatch(ModalActions.toggleModal());
        setTimeout(() => {
            store.dispatch(generalModalActions.clearGeneralModal());
        }, 500);
    };

    cloneTestSuite = (suite, suiteTab = 'TestSuite') => {
        store.dispatch(
            ModalActions.toggleModal('GeneralModal', null, null, {
                title: 'Clone Suite',
                closeIconAction: () => {
                    this.closeCloneTestSuite();
                },
                component: [
                    {
                        name: 'CLoneSuite',
                        content: <CloneSuite cloneSuite={suite} />,
                        buttons: [
                            NEXT_SUBMIT_BUTTON({
                                name: 'Clone',
                                action: () => {
                                    this.onCloneTestSuite(suite, suiteTab);
                                },
                                isDisabled: (tabsData) => {
                                    return (
                                        !checkKeyInObject(tabsData, 'cloneSuite.state.testSuiteName') ||
                                        !checkKeyInObject(tabsData, 'cloneSuite.state.selectedProjectId')
                                    );
                                },
                            }),
                            CANCEL_BUTTON({ action: () => this.closeCloneTestSuite() }),
                        ],
                    },
                ],
            }),
        );
    };

    onCloseOrDeleteTestSuite = (obj) => {
        // suiteAction == 0 --> Delete
        // suiteAction == 1 --> Disable
        // suiteAction == 2 --> Restore
        const { suiteAction, testSuiteIds, onComplete, suiteTab } = obj;
        if (suiteAction === 1) {
            store.dispatch(TestSuiteActions.disableTestSuites(testSuiteIds, suiteAction, suiteTab, onComplete));
        } else if (suiteAction === 0) {
            store.dispatch(TestSuiteActions.deleteTestSuites(testSuiteIds, suiteTab, onComplete));
        } else if (suiteAction === 2) {
            store.dispatch(TestSuiteActions.disableTestSuites(testSuiteIds, 0, suiteTab, onComplete));
        }
    };

    checkIfNameExist = (bool) => {
        isNameExist = bool
    }

    toggleMergeModal = (flag, onCloseModal, data) => {
        const { toggleModal } = this.props;
        const { associateTestSuiteData, editTestSuiteData, organizeTestSuiteData, postActionData, testCaseDropDownData, updateSuite } = data;
        if (submitDisable) {
            submitDisable = false;
        }
        toggleModal('GeneralModal', null, null, {
            title: flag ? 'Update Suite' : 'New Test Suite',
            closeIconAction: () => onCloseModal(),
            component: [{
                name: 'Name and Info',
                content: <SuiteNameandInfo isNameExist={isNameExist} checkIfNameExist={this.checkIfNameExist} isEditModal={flag} editTestSuiteData={editTestSuiteData} />,
                buttons: [
                    ...this.buttons(onCloseModal),
                ]
            },
            {
                name: 'Test Cases',
                content: <SuiteAssociationModal checkIfNameExist={this.checkIfNameExist} testCaseDropDownData={testCaseDropDownData} associateTestSuiteData={associateTestSuiteData} />,
                buttons: [
                    ...this.buttons(onCloseModal),

                ],
            },
            {
                name: 'Arrange',
                content: <SuiteOrganizeModal testCaseDropDownData={testCaseDropDownData} organizeTestSuiteData={organizeTestSuiteData} associateTestSuiteData={associateTestSuiteData} />,
                buttons: [
                    ...this.buttons(onCloseModal),

                ],
            },
            {
                name: 'Post Action',
                content: <SuitePostAction checkIfNameExist={this.checkIfNameExist} suiteConditions={SUITE_CONDITIONS} delayUnit={DELAY_UNIT} postActionData={postActionData} isEditModal={flag} />,
                buttons: [
                    NEXT_SUBMIT_BUTTON({
                        name: flag ? 'Save' : 'Finish',
                        action: () => {
                            if (isNameExist) {
                                updateSuite();
                            }
                            submitDisable = true;
                        },
                    }),
                    CANCEL_BUTTON({ action: onCloseModal })
                ]
            },
            ]
        })
    }

    props = {
        toggleModal: (...args) => store.dispatch(ModalActions.toggleModal(...args)),
        changeTab: (...args) => store.dispatch(generalModalActions.changeTab(...args)),
        get currentTab() {
            return store.getState().generalModalReducer.currentTab;
        },
    };
}

export const TestSuiteUtils = new TestSuite();
