// import packages
import React from 'react';
import PropTypes from 'prop-types';

// import material
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import { IconButton,  CircularProgress } from '@material-ui/core';

import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Info from '@material-ui/icons/InfoOutlined';
import { COLORS } from './../../common/Colors';
// import icon

import Select from '../../features/modal/GeneralModal/Select';
import Tooltip from '../Tooltip';

import {  THREE_DOT_MENU_CSS } from '../../common/cssConstants';
import { AccountActions, ModalActions } from '../../store/actions';
import {
    checkKeyInObject,
    convertToSelectValues,
} from '../../utils/utils';

const regexForUserName = /^[\S]{2,70}$/;
const dataCenters = [
    { id: 1, value: 'US West 1', label: 'US West 1' },
    { id: 2, value: 'EU Central 1', label: 'EU Central 1' },
    { id: 3, value: 'APAC Southeast 1', label: 'APAC Southeast 1' },
];

// styles
const styles = () => ({
    button: {
        borderRadius: '3px',
        // minHeight: 0,
        minWidth: '75px',
        padding: '2px 8px',
        textTransform: 'capitalize',
        fontSize: '12px',
        height: '34px',
        width: '69px',
        border: '1px solid #3B91DF',
        backgroundColor: '#3B91DF',
    },
    deleteButton :{
        marginLeft:'12px',
        borderRadius: '3px',
        minWidth: '75px',
        padding: '2px 8px',
        textTransform: 'capitalize',
        fontSize: '12px',
        height: '34px',
        width: '69px',
        border: '1px solid rgb(196,196,196)',
        '&:hover': {
            border: '1px solid rgb(196,196,196)',

          },
    },
    containedSecondary: {
        color: COLORS.white,
        backgroundColor: COLORS.primaryBtn,
    },
    outlinedPrimary: {
        border: `1px solid ${COLORS.border}`,
    },
    iconsButtonContainer: {
        margin:'0 0 5px 5px',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        fontSize: THREE_DOT_MENU_CSS.iconFontSize,
    },
    
    iconButtonRoot: {
        fontSize: '1.0rem',
        height: 25,
        width: 25,
    },
    actionIcons: {
        fontSize: 16,
        color: '#4a4a4a',
        cursor: 'pointer',
    },
    actionButtonsContainer : {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    baseTextStyle: {
        paddingLeft: '12px',
        color: '#494949',
        fontSize: '12px',
        fontWeight:'Bold',
    },
    bodyWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    buttonContainer: {
        padding: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonSave: {
        background: '#0092e6',
        color: '#fff',
        textTransform: 'capitalize',
    },
    expansionContent: {
        margin: '0 !important',
        cursor: 'default',
        paddingBottom: '10px',
    },
    expandIconContainer: {
        margin: '-3px 15px 0px 0px',
        padding: 0,
        position: 'absolute',
        right: 0,
        cursor: 'pointer',
        top: 0,
    },
    expansionsummary: {
        height: 'auto !important',
        minHeight: 'auto !important',
        paddingLeft: '0px',
    },
    heading: {
        paddingLeft: '20px',
        fontWeight: 'bold',
        color: 'rgb(0 0 0 / 87%)',
        fontSize: '12px', 
    },
    headingDet:{
        paddingLeft: '6px',
        fontWeight: 'bold',
        color: '#4a4a4a',
        fontSize: '12px',
        marginTop:'14px',

    },
    headingDetails:{
        fontWeight: 'bold',
        color: '#494949',
        fontSize: '12px',
    },
    headingContainer: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    // input: {
    //     //border: '1px solid #8898A8',
    //     height: 'auto',
    //     width: 100,
    //     borderRadius: 2,
    //     backgroundColor: '#FFFFFF',
    //     marginLeft: 10,
    //     marginRight: 10,
    //     '&>div>input': {
    //         textAlign: 'right',
    //         fontSize: 12,
    //         padding: '5px 10px 5px 0px',
    //     },
    // },
    itemMainWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
        borderBottom: '1px solid #c5ced8',
        paddingTop: 20,
    },
    noBoxShadow: {
        backgroundColor: 'transparent',
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
    },
    select: {
        borderRadius: 3,
        width: '100%',
        height: '100%',
        '&>div': {
            borderBottom: 'none !important',
            transition: 'none !important',
        },
        '& input': {
            // textAlign: 'center',
            height: '100%',
        },
    },
    flexStart: { display: 'flex', alignItems: 'flex-start' },
    rightSpace: {
        marginRight: 10,
    },
    expensionDetail: {
        padding: '20px 0px',
        display: 'block',
    },
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: '#fff',
      },
    inline: {
        display: 'inline',
        fontWeight:'400',
    },
    inputDetails: {
        '&>div>input': {
            padding: '2px 0px 7px',
            width: '50%',
            fontSize: '12px',
            color:'#616161',
        },
    },
    selectionContainer: {
        display: 'flex',
        marginTop: '15px',
        width: '100%',
    },
    sidebarItem: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 6,
    },
    input: {
        '&>div>input': {
            padding: '2px 0px 11px',
            // width: '50%',
            fontSize: '12px',
        },
    },
    radioButtonGroupContainer: {
        display: 'flex',
        // width: '50%',
        paddingRight:'12px'
    },
    error: {
        color:'red',
        fontSize:'11px',
        marginTop:'8px',
        display: 'flex',
        justifyContent:'flex-end'        
    },
    disableDeleteButton:{
        color:'white'
    }
});

class SauceLabsDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edited:false,
            dataReady:false,
            firstTimeSauceUser:false,
            sauceUserName:'',
            sauceAccessKey:'',
            sauceUserNameCompare:'',
            sauceAccessKeyCompare:'',
            sauceDataCenterCompare:'',
            sauceDataCenter:'',
            sauceCredDeleted:false,
            sauceConfigDetails:{},
        };
        
    }

    async componentDidMount() {
        const { getSauceDetails, toggleSnackBar } = this.props;
        const response = await getSauceDetails();
        if(!response?.payload?.message){
            if(response && checkKeyInObject(response,'payload') && !_.isEmpty(response.payload)) {
                this.setupData(response.payload)
            } else if(response  && checkKeyInObject(response,'payload') && _.isEmpty(response.payload)){
                this.setState({ dataReady:true, firstTimeSauceUser:true })
            }
        }else{
            toggleSnackBar('Failed to load sauce credentials', '', false, 2000);
        }
    }

    setupData = (sauceConfig) => {
        this.updateState({ 
            sauceCredDeleted:sauceConfig.deleted||false,
            dataReady: true,
            sauceUserName: !sauceConfig.deleted && sauceConfig.userName ? sauceConfig.userName : '',
            sauceAccessKeyCompare: sauceConfig.password ? sauceConfig.password : '',
            sauceUserNameCompare: sauceConfig.userName ? sauceConfig.userName : '',
            sauceDataCenterCompare: sauceConfig.dataCenterName ? sauceConfig.dataCenterName : '',
            sauceAccessKey: !sauceConfig.deleted && sauceConfig.password ? sauceConfig.password : '',
            sauceDataCenter: !sauceConfig.deleted && sauceConfig.dataCenterName ? sauceConfig.dataCenterName : '',
            sauceCredentialId:sauceConfig.sauceCredentialId?sauceConfig.sauceCredentialId:0,
            sauceConfigDetails:sauceConfig,
        });
        
    }

    updateState = (obj,cb) => {
        this.setState(obj,()=>{
            if(cb) {
                cb();
            }
        });
    };
    
    handleSave = async () => {

        const { user } = this.props;

        this.setState({ edited: false });
            const dataObj = {
                'accountId': user.accountId,
                'environments':[],
            };
        this.handlePostSauceConfig({ dataObj });
      
    };

    handlePostSauceConfig = async (params) => {
        
        const { toggleSnackBar, user,getSauceDetails } = this.props;
        const { sauceUserName, sauceAccessKey, sauceDataCenter, firstTimeSauceUser,sauceCredentialId} = this.state;

        const sauceCredentials = {
                sauceCredentialId,
                userName: sauceUserName,
                password: sauceAccessKey,
                dataCenterName: sauceDataCenter,
                userId:parseInt(user.userId),
                deleted:0
        }

        const requestData = sauceCredentials; // use browser details in this api as Avishkar said on 17 Feb, 2020
        
        try {
            const response = await this.props.updateSauceDetails(firstTimeSauceUser ? 'create':'update',requestData)
            if (!response?.payload?.message) {
                toggleSnackBar('Sauce labs credentials saved successfully', '', true, 2000);
                this.setState({  firstTimeSauceUser:false })
                getSauceDetails().then((res)=>{
                    if(res && checkKeyInObject(res,'payload') && !_.isEmpty(res.payload)) {
                        this.setupData(res.payload)
                    } else if(res  && checkKeyInObject(res,'payload') && _.isEmpty(res.payload)){
                        this.setState({ dataReady:true, firstTimeSauceUser:true })
                    }
                }).catch(()=>{
                    toggleSnackBar('Failed to save sauce credentials', '', false, 2000);
                });
            } 
            else{
                toggleSnackBar('Saving failed', '', false, 2000);
            }
        } catch(err) {
            toggleSnackBar('Saving failed', '', false, 2000);
        }    
    };

    getSaveDisabled = () => {
        const {edited} = this.state;
        // text fileds validation
        let validated = true;
        let { sauceUserName, sauceDataCenter,sauceAccessKey } = this.state

        sauceDataCenter = sauceDataCenter ? sauceDataCenter.trim() :''
        if(!regexForUserName.test(sauceUserName) || !sauceDataCenter || !sauceAccessKey) {
            validated = false
        }
        if(!edited  || !validated) {
            return true
        } 
            return false
        
    }

    performDelete = async() => {

        const { toggleSnackBar, user,getSauceDetails  } = this.props;
        const { sauceUserName, sauceAccessKey, sauceDataCenter,sauceCredentialId,deleting} = this.state;

        if(deleting) {
            return
        }

        const sauceCredentials = {
            sauceCredentialId,
            userName: sauceUserName,
            password: sauceAccessKey,
            dataCenterName: sauceDataCenter,
            userId:parseInt(user.userId),
            deleted:1
        }

        this.setState({
            deleting:true
        });

        try {

            await this.props.updateSauceDetails('update',sauceCredentials)
            this.setState({
                deleting:false,
                firstTimeSauceUser:true
            })
            toggleSnackBar('Saucelabs credentials deleted successfully', '', true, 5000);

            getSauceDetails().then((res)=>{


                if(res && checkKeyInObject(res,'payload')) {
                    this.setupData(res.payload)
                }

            }).catch(()=>{
                toggleSnackBar('Failed to load Saucelabs credentials', '', false, 2000);
            });

            
        } catch(err) {

            toggleSnackBar('Failed to delete Saucelabs credentials', '', false, 2000);
            this.setState({
                deleting:false
            })
        }


    }


    render() {

    const { classes } = this.props;
    const {
       // edited,
        sauceUserName,
        sauceAccessKey,
        sauceDataCenter,
        sauceUserNameCompare,
        sauceDataCenterCompare,
        sauceConfigDetails,
    } = this.state;


    return (
        <React.Fragment>
          { this.state.dataReady && 
          <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={false} sm={1} md={2} lg={2} />
              <Grid item xs={12} sm={10} md={7} lg={7}>
                  <div className={classes.paddingOnXS}>
                      <div className={classes.sidebarItem} style={{ borderBottom: '1px solid #c5ced8', paddingTop: 20 }}>
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                <Grid item xs={8} sm={8} md={8} lg={8} style={{ display: 'flex', alignItems: 'flex-start'}}>
                                    <Typography variant="subtitle1" gutterBottom className={classes.headingDetails}>
                                        Sauce Labs Username
                                    </Typography>
                                    <Tooltip data="Select username from Sauce Labs Account -> User settings -> My Account ->Username">
                                        <IconButton
                                            className={classes.iconsButtonContainer}
                                            aria-label="Info"
                                        >
                                            <Info className={classes.moreIconsBtn} aria-label="infoicon" id="infoIcon" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                               
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} style={{ display: 'flex', paddingLeft: 6 }}>
                                <TextField
                                    disabled={ sauceConfigDetails.userName ? true : false }
                                    fullWidth
                                    spellCheck = {false}
                                    InputProps={{
                                        disableUnderline: true,
                                        autoComplete: 'off',
                                        inputProps: {
                                            min: 0,
                                            max: 1000000,
                                        },
                                    }}
                                    // disabled={!samlConfig.isSamlAccount}
                                    className={classes.input}
                                    value={sauceUserName || ''}
                                    placeholder="Sauce Labs Username"
                                    aria-label="Sauce Labs Username"
                                    id="username"
                                    onChange={(e) => {
                                        this.setState({ sauceUserName: e.target.value });
                                        this.setState({ edited: sauceUserNameCompare !== e.target.value });
                                    }}
                                    name="username"
                                />
                            </Grid>
                      </div>
                      {
                          !regexForUserName.test(sauceUserName) &&
                          <div className={classes.error} >
                              Length should be greater than 2 characters. <br /> White space is not allowed.
                          </div>
                      }
                      <div className={classes.sidebarItem} style={{ borderBottom: '1px solid #c5ced8', paddingTop: 20 }}>
                        <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                            <Grid item xs={8} sm={8} md={8} lg={8} style={{ display: 'flex', alignItems: 'flex-start'}}>
                                <Typography variant="subtitle1" gutterBottom className={classes.headingDetails}>
                                    Sauce Labs Access Key
                                </Typography>
                                <Tooltip data="Enter Sauce Labs Access Key">
                                        <IconButton
                                            className={classes.iconsButtonContainer}
                                            aria-label="Info"
                                        >
                                            <Info className={classes.moreIconsBtn} aria-label="infoicon" id="infoIcon" />
                                        </IconButton>
                                    </Tooltip>
                            </Grid>
                           
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingLeft: 6, display: 'flex' }}>
                            <TextField
                                disabled={ sauceConfigDetails.password ? true : false }
                                fullWidth
                                spellCheck = {false}
                                InputProps={{ disableUnderline: true, autoComplete: 'new-password' }}
                                className={classes.input}
                                value={sauceAccessKey || ''}
                                placeholder="Sauce Labs Access Key"
                                aria-label="Sauce Labs Access Key"
                                type="password"
                                id="sauceAccessKey"
                                onChange={(e) => {
                                    this.setState({ sauceAccessKey: e.target.value });
                                    this.setState({ edited: sauceAccessKey !== e.target.value });
                                }}
                                name="accessKey"
                            />
                        </Grid>
                      </div>
                      {
                          !sauceAccessKey.trim() &&
                          <div className = {classes.error} >
                              Sauce Labs access key must not be empty
                          </div>
                      }
                      <div
                          className={classes.sidebarItem}
                          style={{
                              borderBottom: '1px solid #c5ced8',
                              backgroundColor: '',
                              paddingTop: 20,
                          }}
                      >
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                <Grid item xs={8} sm={8} md={8} lg={8} style={{ display: 'flex', alignItems: 'flex-start'}}>
                                    <Typography variant="subtitle1" gutterBottom className={classes.headingDetails}>
                                        Sauce Labs Data Center
                                    </Typography>
                                    <Tooltip data="Select one of the Data Center">
                                        <IconButton
                                            className={classes.iconsButtonContainer}
                                            aria-label="Info"
                                        >
                                            <Info className={classes.moreIconsBtn} aria-label="infoicon" id="infoIcon" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} style={{  display: 'flex' }}>
                                <Select
                                    value={sauceDataCenter}
                                    onChange={(e) => {
                                        this.setState({sauceDataCenter: e.value})
                                        this.setState({ edited: sauceDataCenterCompare !==  e.value });
                                    }}
                                    className={classes.select}
                                    isBorder={false}
                                    options={convertToSelectValues(dataCenters)}
                                    isMultiSelect={false}
                                    readOnly
                                    key={Math.random()}
                                    isDisableSelect={sauceConfigDetails.dataCenterName ? true : false}
                                />
                            </Grid>
                      </div>
                      {
                          !sauceDataCenter &&
                          <div className = {classes.error} >
                              Sauce Labs data center must not be empty
                          </div>
                      }
                      <div className={classes.buttonContainer}>
                   

                          <Button
                              variant="contained"
                              color="secondary"
                              className={classes.button}
                              disabled={this.getSaveDisabled()}
                              onClick={() => {
                                  this.handleSave();
                              }}
                          >
                              Save
                          </Button>
                          <Button
                                classes={{
                                    containedSecondary: classes.containedSecondary,
                                    outlinedPrimary: classes.outlinedPrimary,
                                    
                                }}
                              //  disabled={this.getSaveDisabled()}
                                className={classes.deleteButton}
                                onClick={() => {
                                    this.performDelete();
                                }}
                          >
                              Delete
                          </Button>
                      </div>
                  </div>
              </Grid>
          <Grid item xs={false} sm={1} md={3} lg={3} />
      </div>
            }
          {
              !this.state.dataReady &&
              <div>
                <CircularProgress style={{ margin: '100px 50% 0px', color: '#4885ed' }} size={60} />
              </div>

          }
        </React.Fragment>
    );
}
}

SauceLabsDetails.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    toggleSnackBar: PropTypes.func.isRequired,
    actions: PropTypes.shape({
        handleExpand: PropTypes.func,
        handleSave: PropTypes.func,
    }),
};

SauceLabsDetails.defaultProps = {
    actions: {
        handleExpand: () => {},
        handleSave: () => {},
    },
    data: {
        expanded: '',
        execsPerTestcase: 10,
        account: {},
        // ...accountDefaultValues,
    },
};

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        sauceDetails:state.accountReducer.sauceDetails,
        environmentConfig:state.accountReducer.environmentConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        getSauceDetails: () => dispatch(AccountActions.getSauceDetails()),
        updateSauceDetails: (type,requestData) => dispatch(AccountActions.updateSauceDetails(type,requestData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SauceLabsDetails));
// export default withStyles(styles)(ExecutionSetting);







