import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { generalModalActions } from '../../../store/actions';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import PlatformBrowserForLocalExecution from './PlatformBrowserForLocalExecution';
import PlatformBrowserForSauceExecution from './PlatformBrowserForSauceExecution';
import ExternalGrid from './ExternalGrid';
import { styles } from './styles';


class ExecuteScriptConfigurationDetails extends Component {
    constructor() {
        super();
         this.state = {
            localContent: [{
                environmentType: 'Local',
                browser: 'Chrome (headless)',
                platformVersion: '',
                browserVersion: '',
                platform: 'Linux',
                browserEl: null,
                platformEl: null,
            }],
            sauceContent: [{
                environmentType: 'Saucelabs',
                browser: 'Chrome',
                platform: 'Windows',
                platformEl: null,
                browserEl: null,
                idEl: null,
                browserVersion: '92.0',
                platformVersion: '',
                tunnelID: '',
            }],
            tabState: 'Saucelabs',

         };

    }

    // async componentWillMount() {
    //     const { sauceContentData, localContentData, tabStateData } = this.props;
    //     console.log("localContentData",localContentData);
    //     this.setState({
    //         localContent: localContentData,
    //         sauceContent: sauceContentData,
    //         tabState: tabStateData
    //     })
    //     console.log("localContent componentWillMount",this.state.localContent);
    // }

    _setState = (obj, cb = () => {}) => {
        const { updateTabData } = this.props;
        this.setState(obj, () => {
            cb();
            updateTabData('UserModal', { state: { ...this.state } });
        });
    };

    // setTabs = (state) => {
    //     let { sauceContent, localContent } = this.state
    //     let _sauceContent = sauceContent.map((val) => {
    //         return {
    //             ...val,
    //             idEl: null,
    //             platformEl: null,
    //             browserEl: null,
    //         }
    //     })
    //     let _localContent = localContent.map((val) => {
    //         return {
    //             ...val,
    //             platformEl: null,
    //             browserEl: null,
    //         }
    //     })

    //     this.setState({ tabState: state, sauceContent: _sauceContent, localContent: _localContent, sauceIDDisable: false, noConfigFound: false })
    // }

    getTabsContent = (state) => {
        let { localContent, sauceContent} = this.props;
        let { classes, sauceID, environmentInfo, callingFrom, sauceCredentialDetails } = this.props
        let {  platformTab, browserTab,  tunnelID, platformVersion, browserVersion, platformLocal, localBrowserValues, configurationSetting, tabState, browserLocal,sauceSysDefault,localSysDefault, sauceIDDisable, sauceBrowserDisable, saucePlatformDisable, localBrowserDisable, localPlatformDisable } = this.props
        if (state.toLowerCase() === 'saucelabs') {
            return <PlatformBrowserForSauceExecution
                platformTab={platformTab}
                callingFrom={callingFrom}
                environmentDetails={environmentInfo}
                browserTab={browserTab}
                tabState={tabState}
                sauceContent={sauceContent}
                sauceSysDefault={sauceSysDefault}
                sauceIDDisable={sauceIDDisable}
                configurationSetting={configurationSetting}
                sauceTunnelIDs={sauceID}
                sauceBrowserDisable={sauceBrowserDisable}
                saucePlatformDisable={saucePlatformDisable}
                tunnelID={tunnelID}
                sauceCredentialDetails = {sauceCredentialDetails}
                platformVersion={platformVersion}
                browserVersion={browserVersion}
                //handleChangeTab={this.props.handleChangeTab}
                classes={classes}
                setState={this.props._setState}
            />
        } else if (state.toLowerCase() === 'local') {
            return <PlatformBrowserForLocalExecution
                environmentDetails={environmentInfo}
                callingFrom={callingFrom}
                tabState={tabState}
                platformLocal={platformLocal}
                localSysDefault={localSysDefault}
                browserLocal={browserLocal}
                configurationSetting={configurationSetting}
                localBrowserValues={localBrowserValues}
                localBrowserDisable={localBrowserDisable}
                localPlatformDisable={localPlatformDisable}
                localContent={localContent}
                classes={classes}
                setState={this.props._setState}
                //setState={(obj) => this.setState(obj)}
            />
        } else if (state.toLowerCase() === 'externalgrid') {
            return <ExternalGrid
 
            />
        } else {
            return
        }
    }

    // handleConfigurationSetting = (e) => {
    //     let { environmentInfo, sauceID, callingFrom } = this.props
    //     let { sauceCredentials } = this.state
    //     const lastConfigurationData = JSON.parse(localStorage.getItem('lastRunConfiguration'))
    //     const lastGenerateConfiguration = JSON.parse(localStorage.getItem('lastGenerateConfiguration'))
    //     let _externalGrid = []
    //     let _localContent = []
    //     let _sauceContent = []
    //     if (lastConfigurationData && e.target.value === 'lastRunConfiguration' && callingFrom.toLowerCase() === 'execute') {
    //         _externalGrid = lastConfigurationData.filter((val) => val.environmentType.toLowerCase() === 'remote')
    //         _localContent = lastConfigurationData.filter((val) => val.environmentType.toLowerCase() === 'local' || val.environmentType.toLowerCase() === 'zalenium')
    //         _sauceContent = lastConfigurationData.filter((val) => val.environmentType.toLowerCase() === 'saucelabs')
    //         _sauceContent = _sauceContent?.map((val) => {
    //             return {
    //                 ...val,
    //                 tunnelID: sauceID ? (sauceID.includes(val.tunnelID) ? val.tunnelID : sauceID[0]) : ''
    //             }
    //         })
    //         this.setState({
    //             configurationSetting: e.target.value,
    //             externalGrid: _externalGrid.length ? _externalGrid : [],
    //             localContent: _localContent.length ? _localContent : [],
    //             sauceContent: _sauceContent,
    //             localContentDisable: _localContent.length ? false : true,
    //             sauceContentDisable: _sauceContent.length && sauceCredentials?.sauceCredentialId ? false : true,
    //             externalGridDisable: _externalGrid.length ? false : true,
    //             lastConfigurationDisable: lastConfigurationData.length ? false : true,
    //             tabState:  _sauceContent?.length && sauceCredentials?.sauceCredentialId ? 'Saucelabs' : _localContent?.length ? 'Local' : _externalGrid?.length ? 'externalGrid' : 'Saucelabs',
    //             noConfigFound: false,
    //             localConfigurationNotFound: _localContent.length ? true : false,
    //             sauceConfigurationNotFound: _sauceContent.length ? true : false,
    //         })
    //     } else if (lastGenerateConfiguration && e.target.value === 'lastRunConfiguration' && callingFrom.toLowerCase() === 'generate') {
    //         _externalGrid = lastGenerateConfiguration.filter((val) => val.environmentType.toLowerCase() === 'remote')
    //         _localContent = lastGenerateConfiguration.filter((val) => val.environmentType.toLowerCase() === 'local' || val.environmentType.toLowerCase() === 'zalenium')
    //         _sauceContent = lastGenerateConfiguration.filter((val) => val.environmentType.toLowerCase() === 'saucelabs')
    //         _sauceContent = _sauceContent?.map((val) => {
    //             return {
    //                 ...val,
    //                 tunnelID: sauceID ? (sauceID.includes(val.tunnelID) ? val.tunnelID : sauceID[0]) : ''
    //             }
    //         })
    //         this.setState({
    //             configurationSetting: e.target.value,
    //             externalGrid: _externalGrid.length ? _externalGrid : [],
    //             localContent: _localContent.length ? _localContent : [],
    //             sauceContent: _sauceContent,
    //             localContentDisable: _localContent.length ? false : true,
    //             sauceContentDisable: _sauceContent.length && sauceCredentials?.sauceCredentialId ? false : true,
    //             externalGridDisable: _externalGrid.length ? false : true,
    //             lastConfigurationDisable: lastGenerateConfiguration.length ? false : true,
    //             tabState: lastGenerateConfiguration[0].environmentType.toLowerCase() === 'zalenium' ? 'Local' : lastGenerateConfiguration[0].environmentType === "remote" ? 'externalGrid' : lastGenerateConfiguration[0].environmentType,
    //             noConfigFound: false,
    //             localConfigurationNotFound: _localContent.length ? true : false,
    //             sauceConfigurationNotFound: _sauceContent.length ? true : false,
    //         })
    //     } else {
    //         if (environmentInfo && environmentInfo?.environments?.length) {
    //             this.setUserDefaultData(environmentInfo)
    //             this.setState({
    //                 configurationSetting: e.target.value,
    //                 localContentDisable: false,
    //                 sauceContentDisable: false,
    //                 externalGridDisable: false,
    //             })

    //         } else {
    //             this.setState({
    //                 localConfigurationNotFound: false,
    //                 sauceConfigurationNotFound: false,
    //                 configurationSetting: e.target.value,
    //                 localContentDisable: false,
    //                 sauceContentDisable: false,
    //                 externalGridDisable: false,
    //             })
    //         }

    //     }

    // }



    render() {
        // const {
        //     sauceContent,
        //     localContent,
        //     tabState,
        // } = this.state;
        //console.log("tabState",tabState);
         const { classes, configurationSetting, lastConfigurationDisable, sauceContentDisable, localContentDisable, externalGridDisable, environmentInfo, calledFrom, tabState } = this.props;

        return (
            <div className={classes.mainWrapper}>
                { (calledFrom === 'executionScriptModal') && (<RadioGroup className={classes.radioGroup} value={configurationSetting} onChange={(e) => this.props.handleConfigurationSetting(e)}>
                    <FormControlLabel className={classes.radioLabel} style={lastConfigurationDisable ? { cursor: 'not-allowed' } : { cursor: 'pointer' }} disabled={lastConfigurationDisable} value="lastRunConfiguration" control={<Radio />} label="Use Last Run Configuration" />
                    <FormControlLabel className={classes.radioLabel} style={!environmentInfo?.environments?.length ? { cursor: 'not-allowed' } : { cursor: 'pointer'}} disabled={!environmentInfo?.environments?.length ? true : false} value="newConfiguration" control={<Radio />} label="Use New Configuration" />
                </RadioGroup>)}
                <div className={classes.stepper}>
                    <div className={!sauceContentDisable ? classes.stepContainer : classes.stepContainerDisabled}>
                        <span onClick={() => this.props.setTabs('Saucelabs')} className={`${!sauceContentDisable ? classes.stepVal : classes.disableStep} ${tabState.toLowerCase() === "saucelabs" ? classes.activeStepVal : ''} `}>Sauce Labs</span>
                    </div>
                    <div className={!localContentDisable ? classes.stepContainer : classes.stepContainerDisabled}>
                        <span onClick={() => this.props.setTabs('Local')} className={`${!localContentDisable ? classes.stepVal : classes.disableStep} ${tabState.toLowerCase() === "local" ? classes.activeStepVal : ''}`}>Local</span>
                    </div>
                    <div className={!externalGridDisable ? classes.stepContainer : classes.stepContainerDisabled}>
                        <span onClick={() => this.props.setTabs('externalGrid')} className={`${!externalGridDisable ? classes.stepVal : classes.disableStep} ${tabState.toLowerCase() === "externalgrid" ? classes.activeStepVal : ''}`}>Remote</span>
                    </div>
                </div>
                {this.props.getTabsContent(tabState)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
        users: state.userReducer.users,
        activeUser: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecuteScriptConfigurationDetails));
