import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import React from "react"
import Message from './Message';

import { withStyles } from '@material-ui/core/styles';
import componentStyles from './styles';

const styles = (theme) => (componentStyles(theme));

const AlertBar = ({classes,type='',open,message,autoHideDuration,isCloseOption,setOpen}) => {

    let action = (
        <IconButton
            key="close"
            aria-label="CloseIconButton"
            color="inherit"
            onClick={
                ()=>{
                    setOpen(false)
                }
            }
        >
            <Close className={classes.closeIcon}  aria-label="closeIcon" id="closeIcon" />
        </IconButton>
    );


const getAlertBarTypeClassName = (type='default') => {

    type = type.toUpperCase();

    let classNameMap = {

        WARNING:classes.warning,
        SUCCESS:classes.success,
        ERROR:classes.error,
        INFO:classes.info,
        DEFAULT:classes.root
    }

    return classNameMap[type];
}
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => {
                if (autoHideDuration) {
                    setOpen(false)
                }
            }}
            autoHideDuration={autoHideDuration}
            ContentProps={{className:getAlertBarTypeClassName(type)}}
            message={<Message message={message}/>}
            action={[isCloseOption?action:null]}
            open={open}
        >
        </Snackbar>

    )

}

AlertBar.defaultProps = {
    message:'',
    open:false,
    autoHideDuration:5000,
    isCloseOption:true,
    style:{}
}


export default  withStyles(styles)(AlertBar);