import { IconButton, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Edit from '@material-ui/icons/Edit';
import Hidden from '@material-ui/core/Hidden';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
    checkArrayLength,
    checkKeyInObject,
    sort,
    getCountAvatar,
    getFormatedDate,
    getRelativeTime,
    resizeAllCols,
    arrayAwait,
} from '../../utils/utils';
import Tooltip from '../../components/Tooltip';
import TableFooter from '../../common/TableFooter';
import Table from '../../components/Table/Table';
import TableBody from '../../components/Table/TableBody';
import TableCell from '../../components/Table/TableCell';
import TableRow from '../../components/Table/TableRow';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON, SAVE_OUTLINE_BUTTON } from '../../components/Button/Btn';
import { generalModalActions, AccountActions, UserActions } from '../../store/actions';
import ModalActions from '../../store/actions/modalActions';
import ExpandBtn from '../../components/Button/ExpandBtn';
import { isEmail } from '../../common/regexConstants';
import { EnhancedTableHead } from '../../components/EnhancedTable';
import { TABLES_CSS, MUI_ACTION_MENU_ICON } from '../../common/cssConstants';
import AdminAccountInfo from '../admin/modal/AdminAccountInfo';
import AccountUser from '../admin/modal/AccountUser';
import { ActionTypes } from '../../store/constants';
import { defaultAccountEndDate } from '../../common/constants';
import { PASSWORD_REGEX } from '../../common/regexConstants';

// function showAction(activeUser, currentUser) {
//     if (activeUser) {
//         const activeUserRole = activeUser.role;
//         if (
//             currentUser &&
//             currentUser.id !== parseInt(activeUser.userId, 10) &&
//             activeUserRole === 'ADMIN'
//         ) {
//             return true;
//         } else if (activeUserRole !== 'ADMIN' && activeUserRole !== '') {
//             return false;
//         }
//     }
//     return false;
// }

const headers = [
    {
        id: 'accountName',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Account Name',
        styles: { paddingLeft: 10 },
        width: 'calc(calc(100% / 8) * 2)',
    },
    {
        id: 'description',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Description',
        styles: { paddingLeft: 10 },
        width: 'calc(calc(100% / 8) * 2)',
    },
    {
        id: 'startDate',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Start Date',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 8)',
    },
    { id: 'endDate', sortable: true, numeric: false, disablePadding: true, label: 'End Date', styles: { paddingLeft: 10 }, width: 'calc(100% / 8)' },
    {
        id: 'lastLogin',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Last Login',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 8)',
    },
    { id: 'funcs', sortable: false, numeric: false, disablePadding: true, label: 'Actions', styles: { paddingLeft: 10 }, width: 'calc(100% / 8)' },
];
// headers for user
const subHeaders = [
    { id: 'name', sortable: true, parentIndex: 0, numeric: false, disablePadding: true, label: 'User', styles: { paddingLeft: 10 } },
    { id: 'email', sortable: true, parentIndex: 1, numeric: false, disablePadding: true, label: 'Email', styles: { paddingLeft: 10 } },
    { id: 'startDate', sortable: true, parentIndex: 2, numeric: false, disablePadding: true, label: 'Start Date', styles: { paddingLeft: 10 } },
    { id: 'endDate', sortable: true, parentIndex: 3, numeric: false, disablePadding: true, label: 'End Date', styles: { paddingLeft: 10 } },
    { id: 'lastLogin', sortable: true, parentIndex: 4, numeric: false, disablePadding: true, label: 'Last Login', styles: { paddingLeft: 10 } },
    { id: 'role', sortable: true, parentIndex: 5, numeric: false, disablePadding: true, label: 'Role', styles: { paddingLeft: 10 } },
];

// Theme-dependent styles
const styles = (theme) =>
    createStyles({
        root: {
            width: 'calc(100% - 40px)',
            margin: '0px 20px',
            marginTop: '10px',
            borderRadius: 0,
            boxShadow: 'none',
        },
        cell: {
            textAlign: 'left',
            flexDirection: 'row',
            padding: 0,
            width: 'calc(100% / 6)',
            '&>:first-child': {
                paddingLeft: '20px !important',
            },
        },
        currentCell: {
            textAlign: 'left',
            flexDirection: 'row',
            padding: 0,
            paddingLeft: '0px !important',
            width: 'calc(100% / 6)',
        },
        tableRows: {
            cursor: 'pointer',
            height: '40px',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.07) !important',
            },
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        tableRows1: {
            height: '40px',
            backgroundColor: '#F0FFFF',
        },
        pointer: {
            cursor: 'pointer',
        },
        editIcon: {
            height: '30px',
            width: '30px',
            color: '#5F7B96',
            marginLeft: 5,
        },
        body: {
            maxHeight: 'calc( 100vh - 176px )',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
            borderRadius: 5,
            overflowX: 'auto',
            overflowY: 'auto',
            backgroundColor: '#fff',
        },
        subRow: {
            backgroundColor: '#f4f9f0',
        },
        minWidth: {
            minWidth: '60px !important',
            [theme.breakpoints.only('xs')]: {
                minWidth: '200px !important',
                maxWidth: '200px !important',
            },
        },
        noWrap: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        },
        sortIcon: {
            fontSize: 10,
            marginLeft: 10,
            color: '#9B9B9B',
        },
        noDataText: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '88px',
        },
        center: {
            display: 'flex',
            placeContent: 'center',
        },
        moreIcons: {
            ...MUI_ACTION_MENU_ICON,
            marginLeft: 5,
            color: '#5F7B96',
        },
        iconButton: {
            width: '30px',
            height: '30px',
        },
        wrapperAccountNameItems: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        wrapperAccountLength: {
            backgroundColor: '#AAD3F7',
            width: '22px',
            height: '20px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        accountLength: {
            fontSize: '12px',
            color: '#4A4A4A',
            paddingTop: '2px',
            fontWeight: 'bold',
            margin: 0,
        },
        accountNameText: {
            fontSize: TABLES_CSS.normalFontSize,
            color: TABLES_CSS.normalFontColor,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            paddingLeft: '5px',
        },
        WrapperPaddingLeft: {
            paddingLeft: '10px',
            width: '100%',
        },
        width100per: {
            paddingLeft: '10px',
            width: '100%',
        },
        typography: {
            fontSize: TABLES_CSS.normalFontSize,
            color: TABLES_CSS.normalFontColor,
            display: 'inline-block',
        },
        userTableRow: {
            height: '40px',
            background: TABLES_CSS.subHeaderRowColor,
        },
        userTypography: {
            fontSize: TABLES_CSS.headingFontSize,
            color: TABLES_CSS.headingFontColor,
            fontWeight: TABLES_CSS.headingFontWeight,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        flexCenter: {
            display: 'flex',
            alignItems: 'center',
        },
        wrapperAvatarUsername: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            maxWidth: 'calc(100% - 70px)',
            overflow: 'hidden',
        },
        avatar: {
            margin: '0px 10px 0px 0px',
            color: 'rgb(255, 255, 255)',
            backgroundColor: '#1babab',
            width: '25px',
            height: '25px',
            fontSize: '14px',
        },
        username: {
            fontSize: TABLES_CSS.normalFontSize,
            color: TABLES_CSS.normalFontColor,
            display: 'inline-block',
        },
        activeStatus: {
            height: '19px',
            width: '55px',
            minWidth: '55px',
            fontFamily: 'Roboto',
            borderRadius: '8px',
            fontSize: '12px',
            lineHeight: '16px',
            textAlign: 'center',
            color: 'rgb(255, 255, 255)',
            paddingTop: 2,
            marginRight: '10px',
        },
        userActiveColor: {
            backgroundColor: '#1babab',
        },
        accountActiveColor: {
            backgroundColor: '#3B91DF',
        },
        dateTypography: {
            fontSize: TABLES_CSS.normalFontSize,
            color: TABLES_CSS.normalFontColor,
            display: 'inline-block',
            paddingTop: 4,
        },
        userRole: {
            color: '#000000',
        },
        wrapperEditUserIcon: {
            display: 'flex',
            alignItems: 'left',
        },
        noUser: {
            textAlign: 'center',
        },
        noUserText: {
            fontSize: 14,
            textAlign: 'center',
        },
        currentRowIndicatorStyle: {
            backgroundColor: '#F4F9F0',
            display: 'inline-block',
            width: '4px',
            minWidth: '4px',
            marginLeft: '-20px',
        },
        currentRowIndicatorColor: {
            backgroundColor: '#00aead',
        },
        container: {
            minHeight: 'calc(100vh - 212px)',
        },
        dialogContentStyle: {
            maxWidth: 'unset',
        },
        rowIndicator: {
            display: 'inline-block',
            width: '4px',
            marginRight: '16px',
            height: '40px',
            backgroundColor: '#00aead',
        },
        disabledBackgroundColor: {
            backgroundColor: '#BAC4CE',
        },
        changedColor: {
            color: '#fff',
        },
    });

const items = [{ value: 'SDET' }, { value: 'ADMIN' }];

class AccountTableBase extends React.Component {
    constructor(props) {
        super(props);
        this.colsWidth = {};
        this.state = {
            searchUser: '',
            order: 'asc',
            orderBy: 'accountName',
            userOrder: 'asc',
            userOrderBy: 'name',
            page: 0,
            rowsPerPage: 20,
            rowExpendStatus: [0, false],
        };
    }

    componentDidMount() {
        for (let i = 0, j = 0; i < headers.length && j < subHeaders.length; i++) {
            const header = headers[i];
            if (header.colSpan && header.colSpan > 1) {
                for (let k = j; k < j + header.colSpan; k++) {
                    const subHeader = subHeaders[k];
                    subHeader.width = `calc(${header.width} / ${header.colSpan})`;
                    subHeader.parentIndex = i;
                }
                j += header.colSpan;
            } else {
                header.colSpan = 1;
                const subHeader = subHeaders[j];
                subHeader.width = header.width;
                subHeader.parentIndex = i;
                j++;
            }
        }
    }

    componentDidUpdate() {
        resizeAllCols(this.colsWidth);
    }

    componentWillUnmount() {
        this.props.queryRemove();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let prevPageNo = parseInt( this.state.pageNo || 0)
        if(!nextProps.query) {
            this.setState({
                page:prevPageNo
            })
        }
    }
    getFilteredAccounts = () => {
        const { accounts, query } = this.props;
        let _accounts = checkArrayLength(accounts) ? accounts : [];
        if (query && checkArrayLength(_accounts)) {
            _accounts = _accounts.filter(
                (account) => checkKeyInObject(account, 'accountName') && account.accountName.toLowerCase().includes(query.toLowerCase()),
            );
        }
        return _accounts;
    };

    getFilteredUsers = (accountId) => {
        const { searchUser } = this.state;
        if (this.props.users) {
            const users = this.props.users.filter(
                (user) => user.accountId === accountId && user.name.toLowerCase().includes(searchUser.toLowerCase()),
            );
            return [
                ...sort(
                    users.filter((user) => checkKeyInObject(user, 'lastLogin', 'value', false)),
                    'desc',
                    'lastLogin',
                    'time',
                ),
                ...users.filter((user) => !checkKeyInObject(user, 'lastLogin', 'value', false)),
            ];
        }
        return [];
    };

    getLatestLoginDate = (users) => {
        let latestLoginDate = 'N/A';
        if (checkArrayLength(users)) {
            const sortedUsers = sort(users, 'desc', 'Time', 'time');
            if (checkKeyInObject(sortedUsers[0], 'lastLogin.Valid')) {
                latestLoginDate = checkKeyInObject(sortedUsers[0], 'lastLogin.Time', 'value', latestLoginDate);
                latestLoginDate = getFormatedDate(latestLoginDate, 'MMM D, YYYY', 'N/A');
            }
        }
        return latestLoginDate;
    };

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleRequestUserSort = (property) => {
        const userOrderBy = property;
        let userOrder = 'desc';
        if (this.state.userOrderBy === property && this.state.userOrder === 'desc') {
            userOrder = 'asc';
        }
        this.setState({ userOrder, userOrderBy });
    };

    searchUser = (event) => {
        this.setState({ searchUser: event.target.value });
    };

    handleExpend = (id) => {
        this.setState((state) => ({
            rowExpendStatus: [id, id === state.rowExpendStatus[0] ? !state.rowExpendStatus[1] : true],
        }));
    };

    handleChangePage = (page) => {
        this.setState({ page });
        if(!this.props.query) {
            this.setState({
                pageNo:page
            })
         }
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    currentRowIndicator = () => {
        const { classes } = this.props;
        return <span className={classes.rowIndicator}></span>;
    };

    closeModal = () => {
        this.props.toggleModal();
        setTimeout(() => {
            this.props.clearGeneralModal();
        }, 500);
    };

    updateAccountSubmit = async () => {
        const {
            updateAccount,
            updateTabData,
            getAccounts,
            fetchUsers,
            tabsData: {
                adminAccountInfo: {
                    state: { name, description, selectedDateStart, selectedDateEnd, isAccountActive },
                },
                account,
            },
            toggleSnackBar,
        } = this.props;
        let users = [];
        if (checkKeyInObject(this.props.tabsData, 'accountUser.state', 'bool', false)) {
            const {
                tabsData: {
                    accountUser: {
                        state: { addRowData },
                    },
                },
            } = this.props;
            if (checkArrayLength(addRowData)) {
                addRowData.forEach((_user, i) => {
                    if (((i === 0 && this.isRowValid(this.props.tabsData, 0)) || i > 0) && _user.userName.trim() && _user.password.trim()) {
                        const userObj = {
                            id: _user.uid,
                            email: _user.email,
                            userName: _user.userName,
                            password: _user.password,
                            isUserActive: _user.isUserActive,
                            role: _user.selectedItem,
                            startDate: `${moment(_user.userDateStart).format('YYYY-MM-DD')}T00:00:00Z`,
                            endDate: `${moment(_user.userDateEnd).format('YYYY-MM-DD')}T00:00:00Z`,
                        };
                        users.push(userObj);
                    }
                });
            }
        } else if (checkKeyInObject(this.props.tabsData, 'account.filteredUsers', 'bool', false)) {
            users = [...this.props.tabsData.account.filteredUsers];
        }

        const accObj = {
            accountId: account.accountId,
            accountName: name,
            disabledStatus: !isAccountActive,
            address: account.address,
            customCodeLocation: account.customCodeLocation,
            sePort: account.sePort,
            logoLocation: account.logoLocation,
            isTunnelOn: account.isTunnelOn,
            description,
            accountStartDate: `${moment(selectedDateStart).format('YYYY-MM-DD')}T00:00:00Z`,
            accountEndDate: `${moment(selectedDateEnd).format('YYYY-MM-DD')}T00:00:00Z`,
            execsPerTestcase: account.execsPerTestcase,
        };

        const formData = new FormData();
        formData.append('params', JSON.stringify(accObj));

        const updateAccountResult = await updateAccount(formData);
        if (checkKeyInObject(updateAccountResult, 'type', 'value', false) === ActionTypes.UPDATE_ACCOUNT_DETAIL_SUCCESS) {
            if (
                checkArrayLength(users) &&
                checkKeyInObject(this.props.tabsData, 'accountUser.state', 'bool', false) &&
                checkKeyInObject(this.props.tabsData, 'accountUser.state.didChange', 'value', false)
            ) {
                arrayAwait(
                    users,
                    async (user) => {
                        if (user.userName || user.name) {
                            if (user.id) {
                                await this.props.updateUserAPI({
                                    userId: user.id,
                                    //name: user.userName || user.name,
                                    email: user.email,
                                    role: user.role,
                                    startDate: user.startDate,
                                    endDate: user.endDate,
                                    isUserInactive: !checkKeyInObject(user, 'isUserActive', 'value', true),
                                });
                            } else {
                                await this.props.createUser({
                                    accountId: `${account.accountId}`,
                                    password: user.password,
                                    name: user.userName || user.name,
                                    email: user.email,
                                    role: user.role,
                                    startDate: user.startDate,
                                    endDate: user.endDate,
                                    isUserInactive: !checkKeyInObject(user, 'isUserActive', 'value', true),
                                });
                            }
                        }
                    },
                    () => {
                        fetchUsers();
                        getAccounts();
                    },
                );
            } else {
                fetchUsers();
                getAccounts();
            }
        } else {
            toggleSnackBar('Failed to update account');
        }
        this.closeModal();
        updateTabData('isDisabledSubmitButton', false);
    };

    isDisableSubmit = (tabsData) => {
        // Check if nothing change in any of two tabs OR account name is empty OR duplicate OR check if start and end date is not empty and end date is not greater then start date
        const accountTab = Boolean(
            (!checkKeyInObject(tabsData, 'adminAccountInfo.state.didChange', 'value', false) &&
                !checkKeyInObject(tabsData, 'accountUser.state.didChange', 'value', false)) ||
                checkKeyInObject(tabsData, 'adminAccountInfo.state.isDuplicateName', 'value', false) ||
                !checkKeyInObject(tabsData, 'adminAccountInfo.state.name', 'value', false) ||
                !checkKeyInObject(tabsData, 'adminAccountInfo.state.selectedDateStart') ||
                !checkKeyInObject(tabsData, 'adminAccountInfo.state.selectedDateEnd') ||
                new Date(tabsData.adminAccountInfo.state.selectedDateStart) >= new Date(tabsData.adminAccountInfo.state.selectedDateEnd),
        );
        // Check if any thing is change in users tab and !(if row is one and is valid OR if rows is multiple and all are valid)
        const userTab = Boolean(
            checkKeyInObject(tabsData, 'accountUser.state.didChange', 'value', false) &&
                !(
                    (checkKeyInObject(tabsData, 'accountUser.state.addRowData', 'value', []).length === 1 && this.isRowValid(tabsData, 0)) ||
                    (checkKeyInObject(tabsData, 'accountUser.state.addRowData', 'value', []).length > 1 && this.isAllRowsValid(tabsData))
                ),
        );
        return accountTab || userTab;
    };

    isRowValid = (tabsData, i) => {
        const userRows = [...checkKeyInObject(tabsData, 'accountUser.state.addRowData', 'value', [])];
        let isNewUserPassValid = true
        const isPastedDateSame = tabsData.accountUser.state.isPastedDateSame;
        if (userRows && userRows[i]) {
            if(!userRows[0].uid){   //For checking the validity for new user's password only
                isNewUserPassValid  =  PASSWORD_REGEX.test(userRows[0].password)                
            }
            return Boolean(
                userRows[i].userName.trim() &&
                    (i !== 0 || checkKeyInObject(tabsData, 'accountUser.state.isUsernameValid', 'value', false)) &&
                    checkKeyInObject(tabsData, 'accountUser.state.isEmailDisabled', 'value', false) &&
                    userRows[i].email.trim() &&
                    isEmail.test(String(userRows[i].email).toLowerCase()) &&
                    userRows[i].password &&
                    userRows[i].password.length >= 6 &&
                    isNewUserPassValid &&
                    userRows[i].selectedItem &&
                    !isPastedDateSame &&
                    new Date(userRows[i].userDateStart) < new Date(userRows[i].userDateEnd),
            );
        }
        return false;
    };

    isAllRowsValid = (tabsData) => {
        const userRows = [...checkKeyInObject(tabsData, 'accountUser.state.addRowData', 'value', [])];
        for (let i = 0; i < userRows.length; i++) {
            if (!this.isRowValid(tabsData, i)) {
                return false;
            }
        }
        return true;
    };

    updateAccount = (account) => {
        const { classes, changeTab, currentTab, toggleModal } = this.props;
        toggleModal('GeneralModal', null, null, {
            title: 'Update Account',
            dialogContentStyle: classes.dialogContentStyle,
            closeIconAction: () => {
                this.closeModal();
            },
            component: [
                {
                    name: 'Account Info',
                    content: <AdminAccountInfo isUpdate account={account} />,
                    buttons: [
                        NEXT_SUBMIT_BUTTON({
                            name: 'Next',
                            action: () => {
                                changeTab(currentTab + 1);
                            },
                            isDisabled: (/* tabsData */) => {
                                return false;
                            },
                        }),
                        CANCEL_BUTTON({ action: this.closeModal }),
                        SAVE_OUTLINE_BUTTON({
                            action: () => {
                                this.updateAccountSubmit();
                            },
                            isDisabled: this.isDisableSubmit,
                        }),
                    ],
                },
                {
                    name: 'Users',
                    content: <AccountUser isUpdate items={items} account={account} />,
                    buttons: [
                        NEXT_SUBMIT_BUTTON({
                            name: 'Save',
                            action: () => {
                                //  this.props.updateTabData('isDisabledSubmitButton', true);
                                this.updateAccountSubmit();
                            },
                            isDisabled: this.isDisableSubmit,
                        }),
                        CANCEL_BUTTON({ action: this.closeModal }),
                    ],
                },
            ],
        });
    };

    render() {
        const { classes, sideBarCount, isLoading, loadingComponent } = this.props;
        const { order, orderBy, userOrder, userOrderBy, rowsPerPage, page, rowExpendStatus } = this.state;

        let filteredAccounts = this.getFilteredAccounts();
        filteredAccounts = filteredAccounts.map((acc) => {
            const filteredUsers = this.getFilteredUsers(acc.accountId);
            return {
                ...acc,
                filteredUsers,
                lastLogin: checkKeyInObject(filteredUsers[0], 'lastLogin', 'value', 'N/A'),
            };
        });
        let currentPageAccounts = JSON.parse(JSON.stringify(filteredAccounts));
        currentPageAccounts = sort(
            currentPageAccounts,
            order,
            orderBy,
            orderBy === 'lastLogin' || orderBy === 'startDate' || orderBy === 'endDate' ? 'time' : '',
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        return (
            <div className={classes.root}>
                <div className={classes.container}>
                    <div className={classes.body}>
                        {checkArrayLength(currentPageAccounts) ? (
                            <Table>
                                <Hidden only={['xs']}>
                                    <EnhancedTableHead
                                        headers={headers}
                                        currentPageData={currentPageAccounts}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        isColSpan
                                        colsWidth={this.colsWidth}
                                        resizeable
                                    />
                                </Hidden>
                                <Hidden smUp>
                                    <EnhancedTableHead
                                        headers={headers}
                                        currentPageData={currentPageAccounts}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        isColSpan
                                        colsWidth={this.colsWidth}
                                        resizeable
                                    />
                                </Hidden>
                                <TableBody style={{ maxHeight: 'calc(100vh - 259px)' }}>
                                    {currentPageAccounts.map((account) => {
                                        const clickCallback = () => this.handleExpend(account.accountId);
                                        const disabledCondition = checkKeyInObject(account, 'disabledStatus', 'value', false);
                                        const conditionalColor = disabledCondition ? '#BAC4CE' : '#000000';
                                        const row = [
                                            <TableRow hover tabIndex={-1} key={account.accountId} className={classes.tableRows}>
                                                <TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${
                                                        rowExpendStatus[0] === account.accountId && rowExpendStatus[1]
                                                            ? classes.currentCell
                                                            : classes.cell
                                                    }`}
                                                    width={headers[0].width}
                                                    data-resize={`element${0}_c`}
                                                >
                                                    <div className={classes.width100per} style={{ paddingLeft: '0px' }}>
                                                        <div className={classes.flexCenter}>
                                                            <div className={classes.wrapperAccountNameItems}>
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContext: 'flex-start' }}>
                                                                    {rowExpendStatus[0] === account.accountId && rowExpendStatus[1]
                                                                        ? this.currentRowIndicator()
                                                                        : null}
                                                                    <span
                                                                        className={`${classes.wrapperAccountLength} ${
                                                                            disabledCondition ? classes.disabledBackgroundColor : ''
                                                                        } `}
                                                                    >
                                                                        <Typography className={`${classes.noWrap} ${classes.accountLength}`}>
                                                                            {account.filteredUsers.length}
                                                                        </Typography>
                                                                    </span>
                                                                    <span
                                                                        className={`${classes.noWrap} ${classes.accountNameText}`}
                                                                        style={{ color: conditionalColor }}
                                                                    >
                                                                        <Tooltip data={account.accountName ? account.accountName : null}>
                                                                            {account.accountName}
                                                                        </Tooltip>
                                                                    </span>
                                                                </div>
                                                                {!checkKeyInObject(account, 'disabledStatus', 'value', false) && (
                                                                    <span className={`${classes.activeStatus} ${classes.accountActiveColor}`}>
                                                                        Active
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                    width={headers[1].width}
                                                    data-resize={`element${1}_c`}
                                                >
                                                    <div className={`${classes.width100per} ${classes.WrapperPaddingLeft}`}>
                                                        <Typography
                                                            className={`${classes.noWrap} ${classes.typography}`}
                                                            style={{ color: conditionalColor }}
                                                        >
                                                            {account.description ? account.description : ''}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                    width={headers[2].width}
                                                    data-resize={`element${2}_c`}
                                                >
                                                    <div className={`${classes.width100per} ${classes.WrapperPaddingLeft}`}>
                                                        <Typography
                                                            className={`${classes.noWrap} ${classes.typography}`}
                                                            style={{ color: conditionalColor }}
                                                        >
                                                            {getFormatedDate(account.startDate, 'MMM D, YYYY', '-')}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                    width={headers[3].width}
                                                    data-resize={`element${3}_c`}
                                                >
                                                    <div className={`${classes.width100per} ${classes.WrapperPaddingLeft}`}>
                                                        <Typography
                                                            className={`${classes.noWrap} ${classes.typography}`}
                                                            style={{ color: conditionalColor }}
                                                        >
                                                            {getFormatedDate(
                                                                account.endDate,
                                                                'MMM D, YYYY',
                                                                '-',
                                                                getFormatedDate(defaultAccountEndDate, 'YYYY-MM-D', null),
                                                            )}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                    width={headers[4].width}
                                                    data-resize={`element${4}_c`}
                                                >
                                                    <div className={`${classes.width100per} ${classes.WrapperPaddingLeft}`}>
                                                        <Typography
                                                            className={`${classes.noWrap} ${classes.typography}`}
                                                            style={{ color: conditionalColor }}
                                                        >
                                                            {checkKeyInObject(account, 'isRelativeDateFormat')
                                                                ? getRelativeTime(checkKeyInObject(account, 'lastLogin', 'value', '-'))
                                                                : getFormatedDate(
                                                                      checkKeyInObject(account, 'lastLogin', 'value', '-'),
                                                                      'MMM D, YYYY, h:mm a',
                                                                      '-',
                                                                  )}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                    width={headers[5].width}
                                                    data-resize={`element${5}_c`}
                                                >
                                                    <div
                                                        className={`${classes.width100per} ${classes.WrapperPaddingLeft}`}
                                                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                                    >
                                                        <IconButton
                                                            color="inherit"
                                                            aria-label="edit account"
                                                            id="Editaccount"
                                                            name="Editaccount"
                                                            onClick={() => {
                                                                this.updateAccount(account);
                                                            }}
                                                            classes={{ root: classes.iconButton }}
                                                        >
                                                            <Edit aria-label="editScheduleIcon" id="editScheduleIcon" className={classes.moreIcons} />
                                                        </IconButton>
                                                        <ExpandBtn
                                                            flag={rowExpendStatus[0] === account.accountId && rowExpendStatus[1]}
                                                            action={() => {
                                                                clickCallback();
                                                            }}
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>,
                                        ];
                                        if (rowExpendStatus[0] === account.accountId && rowExpendStatus[1]) {
                                            row.push(
                                                <TableRow className={classes.userTableRow} key={`${account.accountId}-subHeader`}>
                                                    {subHeaders.map((column, ind) => (
                                                        <TableCell
                                                            width={column.width}
                                                            data-sub-header="subHeader"
                                                            data-child-resize={`${ind}_${column.parentIndex}_${headers[column.parentIndex].colSpan}`}
                                                            className={`${classes.minWidth} ${classes.cell}`}
                                                            key={`user-${column.id}`}
                                                        >
                                                            {column.sortable ? (
                                                                <div
                                                                    aria-hidden
                                                                    onClick={() => this.handleRequestUserSort(column.id)}
                                                                    className={`${classes.width100per} ${classes.pointer}`}
                                                                >
                                                                    <Typography variant="body2" className={classes.userTypography}>
                                                                        {column.label}
                                                                        {userOrderBy === column.id ? (
                                                                            userOrder === 'desc' ? (
                                                                                <FontAwesomeIcon
                                                                                    icon={faChevronDown}
                                                                                    aria-label="faChevronDown"
                                                                                    id="faChevronDown"
                                                                                    className={classes.sortIcon}
                                                                                />
                                                                            ) : (
                                                                                <FontAwesomeIcon
                                                                                    icon={faChevronUp}
                                                                                    aria-label="faChevronUp"
                                                                                    id="faChevronUp"
                                                                                    className={classes.sortIcon}
                                                                                />
                                                                            )
                                                                        ) : null}
                                                                    </Typography>
                                                                </div>
                                                            ) : (
                                                                <div className={classes.nonSortableContainer} key={column.id}>
                                                                    <Typography variant="body2" className={classes.userTypography}>
                                                                        {column.label}
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>,
                                            );
                                        }
                                        if (rowExpendStatus[0] === account.accountId && rowExpendStatus[1]) {
                                            if (checkArrayLength(account.filteredUsers)) {
                                                const filteredUser = sort(
                                                    account.filteredUsers,
                                                    userOrder,
                                                    userOrderBy,
                                                    userOrderBy === 'lastLogin' || userOrderBy === 'startDate' || userOrderBy === 'endDate'
                                                        ? 'time'
                                                        : '',
                                                );
                                                filteredUser.forEach((user, index) => {
                                                    const conditionalBackgroundColor = {
                                                        backgroundColor: checkKeyInObject(user, 'deleted', 'value', false)
                                                            ? '#BAC4CE'
                                                            : user.role === 'AIQSA'
                                                            ? '#A2D2F2'
                                                            : user.role === 'ADMIN'
                                                            ? '#F3E0BC'
                                                            : user.role === 'SDET'
                                                            ? '#92EABD'
                                                            : '',
                                                    };
                                                    row.push(
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            style={{
                                                                display:
                                                                    rowExpendStatus[0] === account.accountId && rowExpendStatus[1]
                                                                        ? 'table-row'
                                                                        : 'none',
                                                            }}
                                                            className={classes.tableRows1}
                                                            key={`row-expanded-${user.name}-${index}`}
                                                        >
                                                            <TableCell
                                                                padding="none"
                                                                className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                width={subHeaders[0].width}
                                                                data-child-resize={`${0}_${subHeaders[0].parentIndex}_${
                                                                    headers[subHeaders[0].parentIndex].colSpan
                                                                }`}
                                                            >
                                                                <div className={classes.width100per}>
                                                                    <div className={classes.flexCenter}>
                                                                        <div className={classes.wrapperAccountNameItems}>
                                                                            <span className={classes.wrapperAvatarUsername}>
                                                                                <Avatar
                                                                                    className={`${classes.avatar} ${
                                                                                        checkKeyInObject(user, 'deleted', 'value', false)
                                                                                            ? classes.disabledBackgroundColor
                                                                                            : ''
                                                                                    } `}
                                                                                >
                                                                                    {user && user.name && user.name.length
                                                                                        ? user.name[0].toUpperCase()
                                                                                        : ''}
                                                                                </Avatar>
                                                                                <Typography className={`${classes.username} ${classes.noWrap}`}>
                                                                                    <Tooltip data={user.name}>{user.name}</Tooltip>
                                                                                </Typography>
                                                                            </span>
                                                                            <span className={`${classes.activeStatus} ${classes.userActiveColor}`}>
                                                                                {user.deleted === false ? 'Active' : 'Inactive'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                width={subHeaders[1].width}
                                                                data-child-resize={`${1}_${subHeaders[1].parentIndex}_${
                                                                    headers[subHeaders[1].parentIndex].colSpan
                                                                }`}
                                                            >
                                                                <div className={classes.width100per}>
                                                                    <Typography className={`${classes.dateTypography} ${classes.noWrap}`}>
                                                                        {user.email}
                                                                    </Typography>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                width={subHeaders[2].width}
                                                                data-child-resize={`${2}_${subHeaders[2].parentIndex}_${
                                                                    headers[subHeaders[2].parentIndex].colSpan
                                                                }`}
                                                            >
                                                                <div className={classes.width100per}>
                                                                    <Typography className={`${classes.dateTypography} ${classes.noWrap}`}>
                                                                        {getFormatedDate(moment(user.startDate).toISOString(), 'MMM D, YYYY', '-')}
                                                                    </Typography>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                width={subHeaders[3].width}
                                                                data-child-resize={`${3}_${subHeaders[3].parentIndex}_${
                                                                    headers[subHeaders[3].parentIndex].colSpan
                                                                }`}
                                                            >
                                                                <div className={classes.width100per}>
                                                                    <Typography className={`${classes.dateTypography} ${classes.noWrap}`}>
                                                                        {getFormatedDate(moment(user.endDate).toISOString(), 'MMM D, YYYY', '-')}
                                                                    </Typography>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                width={subHeaders[4].width}
                                                                data-child-resize={`${4}_${subHeaders[4].parentIndex}_${
                                                                    headers[subHeaders[4].parentIndex].colSpan
                                                                }`}
                                                            >
                                                                <div className={classes.width100per}>
                                                                    <Typography className={`${classes.dateTypography} ${classes.noWrap}`}>
                                                                        {getFormatedDate(user.lastLogin, 'MMM D, YYYY, h:mm a', '-')}
                                                                    </Typography>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                width={subHeaders[5].width}
                                                                data-child-resize={`${5}_${subHeaders[5].parentIndex}_${
                                                                    headers[subHeaders[5].parentIndex].colSpan
                                                                }`}
                                                            >
                                                                <div className={classes.width100per}>
                                                                    <div className={classes.flexCenter}>
                                                                        <span
                                                                            className={`${classes.activeStatus} ${classes.userRole} ${
                                                                                checkKeyInObject(user, 'deleted', 'value', false)
                                                                                    ? classes.changedColor
                                                                                    : ''
                                                                            }`}
                                                                            style={conditionalBackgroundColor}
                                                                        >
                                                                            {user.role}
                                                                        </span>
                                                                        {/* for single user update */}
                                                                        {/* <div className={classes.wrapperEditUserIcon}>
                                                                            {showAction(activeUser, user) ? (
                                                                                <IconButton
                                                                                    color="inherit"
                                                                                    aria-label="edit user"
                                                                                    id="Edituser"
                                                                                    name="Edituser"
                                                                                    onClick={this.toggleModal('Edit', user, account)}
                                                                                    classes={{ root: classes.iconButton }}
                                                                                > */}
                                                                        {/* TODO: Uncomment line and check if icon size is proper */}
                                                                        {/* <FontAwesomeIcon icon={faPencilAlt} aria-label="faPencilAlt" id="faPencilAlt" className={classes.moreIconsBtn} /> */}
                                                                        {/* <Edit className={classes.moreIcons} aria-label="editIcon" id="editIcon" />
                                                                                </IconButton>
                                                                            ) : null}
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>,
                                                    );
                                                });
                                            } else {
                                                row.push(
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={`row-expanded-${account.accountId}-empty`}
                                                        style={{
                                                            display:
                                                                rowExpendStatus[0] === account.accountId && rowExpendStatus[1] ? 'table-row' : 'none',
                                                        }}
                                                        className={`${classes.tableRows1} ${classes.center}`}
                                                    >
                                                        <TableCell className={classes.cell} colSpan={6}>
                                                            <Typography className={classes.noUserText}>No user available</Typography>
                                                        </TableCell>
                                                    </TableRow>,
                                                );
                                            }
                                        }
                                        return row;
                                    })}
                                </TableBody>
                            </Table>
                        ) : isLoading ? (
                            loadingComponent
                        ) : (
                            <Typography className={classes.noDataText} variant="subtitle1">
                                No Account available
                            </Typography>
                        )}
                    </div>
                </div>
                <TableFooter
                    count={filteredAccounts.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    applyCustomClasses
                    labelRowsPerPage="Accounts per page:"
                    labelForCounter="Accounts"
                    countAvatar={getCountAvatar(sideBarCount.num_of_accounts)}
                    containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                />
            </div>
        );
    }
}

AccountTableBase.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    toggleModal: PropTypes.func.isRequired,
    queryRemove: PropTypes.func.isRequired,
};

AccountTableBase.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        // general reducer
        tabsData: state.generalModalReducer.tabsData,
        currentTab: state.generalModalReducer.currentTab,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        getAccounts: () => dispatch(AccountActions.getAccounts()),
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
        updateAccount: (...args) => dispatch(AccountActions.updateAccount(...args)),
        // Modal Action
        clearGeneralModal: (...args) => dispatch(generalModalActions.clearGeneralModal(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        changeTab: (...args) => dispatch(generalModalActions.changeTab(...args)),
        updateUserAPI: (...args) => dispatch(UserActions.updateUser(...args)),
        createUser: (data) => dispatch(UserActions.createUser(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountTableBase));
