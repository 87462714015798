import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FormControlLabel, Typography } from '@material-ui/core';
import { BLACK_FONT } from '../../common/cssConstants';
import { checkKeyInObject, checkObject } from '../../utils';
import { generalModalActions } from '../../store/actions';
import { CustomSwitch } from '../../common/miniComponents';
import config from '../../config';

let isMounted = false;
const isNewNlp = (config.isNewNlp === 'true');
const styles = () => ({
    root: {
        padding: '0 30px',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 320px)',
    },
    partition: {
        display: 'flex'
    },
    typo: {
        paddingTop: 16,
        paddingBottom: 16,
        width: '80%',
        fontSize: 12,
        color: BLACK_FONT,
        marginLeft: 12
    },
    smartNotificationsAlertMessage: {
        fontSize: '9px',
        color: 'red',
        paddingLeft: '63px',
        display: 'block',
        margin: 0,
        marginTop: '-14px',
    },
    errorMessage: {
        fontSize: 12,
        color: 'red',
        display: 'block',
    },
});

class ProjectProperties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullScreenShot: false,
            aiqExecution: true,
            projectStatus: false,
            showTimer: false,
            smartRetry: false,
            smartNotificationsAlertMessage: '',
            isNoExecutionScreenshot: false,
            isAutoExecute: false,
            isFirst: false,
            didChange: false,
            isInteractiveDebug: false,
            shadowDomEnabled:false
        };
    }

    componentDidMount() {
        const {
            modalData: { isUpdateProject, project },
            tabsData,
        } = this.props;
        let previousData = {};
        isMounted = true;
        if (isUpdateProject || checkKeyInObject(tabsData.projectProperties, 'state', 'bool')) {
            if (checkKeyInObject(tabsData.projectProperties, 'state', 'bool')) {
                previousData = tabsData.projectProperties.state;
            } else if (
                isUpdateProject &&
                checkObject(project) &&
                checkKeyInObject(tabsData.projectInfo, 'state', 'bool') &&
                checkKeyInObject(tabsData.projectInfo.state, 'projectAdvanceValues', 'bool')
            ) {
                previousData = this.getDefaultValues(tabsData.projectInfo.state.projectAdvanceValues);
            }
            this.setStateIfComponentMounted({
                ...previousData,
                isFirst: true,
                didChange: checkKeyInObject(previousData, 'didChange') ? previousData.didChange : false,
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            modalData: { isUpdateProject, project },
            tabsData,
        } = this.props;
        if (
            isUpdateProject &&
            checkObject(project) &&
            !(
                checkKeyInObject(tabsData.projectInfo, 'state', 'bool') &&
                checkKeyInObject(tabsData.projectInfo.state, 'projectAdvanceValues', 'bool')
            ) &&
            checkKeyInObject(nextProps.tabsData.projectInfo, 'state', 'bool') &&
            checkKeyInObject(nextProps.tabsData.projectInfo.state, 'projectAdvanceValues', 'bool')
        ) {
            const previousData = this.getDefaultValues(nextProps.tabsData.projectInfo.state.projectAdvanceValues);
            this.setStateIfComponentMounted({
                ...previousData,
                isFirst: true,
                didChange: false,
            });
        }
    }

    componentWillUnmount() {
        const { updateTabData } = this.props;
        isMounted = false;
        updateTabData('projectProperties', { state: { ...this.state } });
    }

    getDefaultValues = (projectAdvanceValues) => ({
        isFullScreenShot: !!projectAdvanceValues.isFullScreenshot,
        aiqExecution: true, //! !projectAdvanceValues.aiqExecution,
        projectStatus: !projectAdvanceValues.cacheXpaths,
        showTimer: !!projectAdvanceValues.showTimer,
        smartRetry: !!projectAdvanceValues.smartRetry,
        isAutoExecute: false, //! !projectAdvanceValues.isAutoExecute? projectAdvanceValues.isAutoExecute: true,
        isNoExecutionScreenshot: projectAdvanceValues.aiqExecution ? !projectAdvanceValues.isNoExecutionScreenshot : true,
        isInteractiveDebug: !!projectAdvanceValues.isInteractiveDebug,
        shadowDomEnabled: !!projectAdvanceValues.shadowDomEnabled
    });

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted)
            this.setState(
                (prevState) => ({ didChange: prevState.isFirst, ...obj }),
                () => {
                    callback();
                    this.props.updateTabData('projectProperties', { state: { ...this.state } });
                },
            );
    };

    getSwitch = ({ classes, value, text, disabled = false }) => (
        <div className={classes.partition}>
            <FormControlLabel
                style={{
                    marginLeft: 0,
                    marginRight: 0,
                }}
                control={<CustomSwitch disabled={disabled} checked={this.state[value]} onChange={this.handleSwitchs} value={value} />}
            />
            <Typography className={classes.typo}>{text}</Typography>
        </div>
    );

    handleSwitchs = (event) => {
        const {
            modalData: { isUpdateProject },
            tabsData,
        } = this.props;
        const obj = { [event.target.value]: event.target.checked };
        if (event.target.value === 'aiqExecution') {
            obj.isAutoExecute = false;
            if (!event.target.checked) {
                obj.isNoExecutionScreenshot = false;
            } else if (
                isUpdateProject &&
                checkKeyInObject(tabsData.projectInfo, 'state', 'bool') &&
                checkKeyInObject(tabsData.projectInfo.state, 'projectAdvanceValues', 'bool')
            ) {
                obj.isNoExecutionScreenshot = !tabsData.projectInfo.state.projectAdvanceValues.isNoExecutionScreenshot;
            }
        }
        if (event.target.value === 'projectStatus') {
            let smartNotificationsAlertMessage = '';
            if (event.target.checked) {
                smartNotificationsAlertMessage = 'Enabling it will disable cache Xpaths option.';
            } else {
                smartNotificationsAlertMessage = 'Disabling it will enable cache Xpaths option.';
            }
            obj.smartNotificationsAlertMessage = smartNotificationsAlertMessage;
        }
        this.setStateIfComponentMounted(obj);
    };

    render() {
        const { classes, tabsData } = this.props;
        const {
            isFullScreenShot,
            aiqExecution,
            projectStatus,
            showTimer,
            smartRetry,
            smartNotificationsAlertMessage,
            /* isAutoExecute, */
            isNoExecutionScreenshot,
            isInteractiveDebug,
            shadowDomEnabled
        } = this.state;

        let errorMessage = null;
        if (
            checkKeyInObject(tabsData, 'projectInfo.state.duplicateName', 'value', false) ||
            checkKeyInObject(tabsData, 'projectInfo.state.isSubmitDisabled', 'value', false)
        ) {
            errorMessage = (
                <div>
                    <span className={classes.errorMessage}>{checkKeyInObject(tabsData, 'projectInfo.state.error', 'value', '')}</span>
                </div>
            );
        }

        return (
            <div className={classes.root}>
                {errorMessage}
                <div style={{ width: '100%' }}>
                    <div>
                        <div>
                            {this.getSwitch({ classes, checked: projectStatus, value: 'projectStatus', text: 'Enable smart notifications' })}
                            {smartNotificationsAlertMessage && smartNotificationsAlertMessage.length > 0 ? (
                                <span className={classes.smartNotificationsAlertMessage}>{smartNotificationsAlertMessage}</span>
                            ) : (
                                ''
                            )}
                        </div>
                        {this.getSwitch({ classes, checked: isInteractiveDebug, value: 'isInteractiveDebug', text: 'Enable interactive debug' })}
                        {this.getSwitch({ classes, checked: shadowDomEnabled, value: 'shadowDomEnabled', text: 'Enable Shadow DOM',disabled: !isNewNlp })}


                        {this.getSwitch({ classes, checked: isFullScreenShot, value: 'isFullScreenShot', text: 'Show full screen images' })}
                        {/* {this.getSwitch({ classes, checked: isAutoExecute && !aiqExecution, value: 'isAutoExecute', text: 'Auto Generate Video', disabled: aiqExecution })} */}
                        {this.getSwitch({
                            classes,
                            checked: isNoExecutionScreenshot && aiqExecution,
                            value: 'isNoExecutionScreenshot',
                            text: 'Show screenshot for AIQ executions',
                            disabled: !aiqExecution,
                        })}
                    </div>
                    <div>
                        {this.getSwitch({ classes, checked: smartRetry, value: 'smartRetry', text: 'Smart retry' })}
                        {this.getSwitch({ classes, checked: showTimer, value: 'showTimer', text: 'Show step timer' })}
                        {/* {this.getSwitch({ classes,  checked: aiqExecution, value: 'aiqExecution', text: 'Execute using AIQ Engine' })} */}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectProperties));
