import { ActionTypes } from '../constants';
import { checkKeyInObject, TestCaseUtils } from '../../utils';

const initialState = {
    blockData: [], // to store block obj
    currentStepScreenshotModalIndex: -1, // to store block obj
    error: null,
    isBlockModalOpen: false, // to show and and hide create block modal
    isLoading: false,
    isScreenshotModalOpen: false, // to show and and hide screenshot modal
    isTagModalOpen: false, // to show and and hide tag modal
    merged_testcases: null,
    success: null,
    testCases: [],
    testCasesObj: {},
    caseOrder: 'desc',
    caseOrderBy: 'createdTime',
    testCasesWithAccount: [],
};

export default function testCaseReducer(state = initialState, { payload, type }) {
    switch (type) {
        case ActionTypes.CLEAR_ERROR: {
            return { ...state, error: null };
        }
        case ActionTypes.CLEAR_SUCCESS: {
            return { ...state, success: null };
        }
        case ActionTypes.TESTCASE_MERGE: {
            return { ...state, isLoading: true, error: null, success: null };
        }
        case ActionTypes.TESTCASE_MERGE_SUCCESS: {
            return { ...state, isLoading: false, success: payload.message, error: null, merged_testcases: payload.data };
        }
        case ActionTypes.TESTCASE_MERGE_FAILURE: {
            return { ...state, isLoading: false, error: payload.message, success: null };
        }

        case ActionTypes.GET_TESTCASES_WITH_ACCOUNT: {
            return { ...state, isLoading: true };
        }

        case ActionTypes.GET_TESTCASES_WITH_ACCOUNT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                testCasesWithAccount: payload,
            };
        }

        case ActionTypes.GET_TESTCASES_WITH_ACCOUNT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload.message,
            };

        case ActionTypes.EDIT_TESTCASE_WITH_ACCOUNT:
        case ActionTypes.CLONE_TESTCASES_WITH_ACCOUNT:
        case ActionTypes.GET_TESTCASES:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.GET_TESTCASES_SUCCESS: {
            const { testCasesObj } = state;
            const _payload = JSON.parse(JSON.stringify(payload));
            const _oldTestCases = JSON.parse(JSON.stringify(testCasesObj));
            const _testCases = [];
            const _testCasesObj = {};
            _payload.forEach((testCase) => {
                if (checkKeyInObject(testCase, 'testCaseId')) {
                    // Need to get testCase from reducer because sometimes
                    // testCase response getting late so we should not loose our
                    // previous properties(testSteps, recoverTestSteps etc) which we have set in testCase
                    const newCase = {
                        ..._oldTestCases[testCase.testCaseId], // if oldTestCase have any extra proeprty then it will be available too
                        ...testCase,
                    };
                    _testCases.push(newCase);
                    _testCasesObj[`${testCase.testCaseId}`] = newCase;
                }
            });
            return {
                ...state,
                testCases: _testCases,
                testCasesObj: _testCasesObj,
                isLoading: false,
            };
        }

        case ActionTypes.UPDATE_TESTCASE_NAME_IN_ACCOUNT_SUCCESS:
            return {
                ...state,
                isLoadingCaseNameUpdate: false,
                testCasesWithAccount: payload,
            };

        case ActionTypes.DISABLE_TESTCASES_WITH_ACCOUNT_SUCCESS: {
            const { testCases, message } = payload;
            const obj = { ...state, isLoading: false, error: null, success: message };
            obj.testCasesWithAccount = state.testCasesWithAccount.map((element) => {
                const data = testCases.find((testCase) => testCase.testcaseId === element.testCaseId);
                if (data) {
                    element.disabledStatus = data.disabledStatus;
                }
                return element;
            });
            return obj;
        }

        case ActionTypes.DISABLE_TESTCASES_WITH_ACCOUNT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
                success: null,
            };

        case ActionTypes.DELETE_TESTCASE_WITH_ACCOUNT_SUCCESS: {
            const filteredCases = state.testCasesWithAccount.filter((element) => !payload.testCaseIds.includes(element.testCaseId));
            return {
                ...state,
                isLoading: false,
                success: 'Testcase Deleted Successfully',
                error: null,
                testCasesWithAccount: filteredCases,
            };
        }

        case ActionTypes.DELETE_TESTCASE_WITH_ACCOUNT_FAILURE:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.CLONE_TESTCASE_WITH_ACCOUNT_SUCCESS: {
            return {
                ...state,
                success: payload.message,
                isLoading: false,
                testCasesWithAccount: [...state.testCasesWithAccount, payload.testCase],
            };
        }

        case ActionTypes.EDIT_TESTCASE_WITH_ACCOUNT_FAILURE:
        case ActionTypes.CLONE_TESTCASE_WITH_ACCOUNT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
                isError: true,
            };

        case ActionTypes.EDIT_TESTCASE_WITH_ACCOUNT_SUCCESS: {
            const { data, message } = payload;
            const obj = { ...state, success: message, isLoading: false };
            const testCasesWithAccount = JSON.parse(JSON.stringify(state.testCasesWithAccount));

            const index = testCasesWithAccount.findIndex((testCase) => testCase.testCaseId === data.testCaseId);
            if (index > -1) {
                testCasesWithAccount[index] = {
                    ...testCasesWithAccount[index],
                    discoveryId: data.projectId,
                    projectName: data.projectName,
                    testCaseName: data.testCaseName,
                };
                obj.testCasesWithAccount = testCasesWithAccount;
            }
            return obj;
        }

        case ActionTypes.ADD_REMOVE_CASES_TAG: {
            const { testCaseIds, tag_id, _action } = payload;
            const _testCasesWithAccount = TestCaseUtils.addRemoveCaseTag({
                action: _action,
                tag_id,
                testCaseIds,
                testCases: JSON.parse(JSON.stringify(state.testCasesWithAccount)),
            });
            return {
                ...state,
                testCasesWithAccount: _testCasesWithAccount,
            };
        }

        case ActionTypes.TOGGLE_BLOCK_MODAL: {
            const { isBlockModalOpen, blockData } = payload;
            return { ...state, isBlockModalOpen, blockData };
        }

        case ActionTypes.TOGGLE_TAG_MODAL: {
            const { isTagModalOpen } = payload;
            return { ...state, isTagModalOpen };
        }

        case ActionTypes.TOGGLE_SCREENSHOT_MODAL: {
            const { currentStepScreenshotModalIndex, isScreenshotModalOpen } = payload;
            return { ...state, currentStepScreenshotModalIndex, isScreenshotModalOpen };
        }

        case ActionTypes.UPDATE_CASE_ORDER: {
            const { order, orderBy } = payload;
            return { ...state, caseOrder: order, caseOrderBy: orderBy };
        }

        case ActionTypes.COPIED_SUCCESS:
            return {
                ...state,
                error: null,
                success: payload,
            };

        case ActionTypes.COPYING_FAILED:
            return {
                ...state,
                error: payload,
                success: null,
            }; 

        default:
            return state;
    }
}
