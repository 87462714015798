import { DialogActions, CircularProgress, Hidden } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Fullscreen from '@material-ui/icons/Fullscreen';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// Keyboard input comprehension node modules
// import onEnter from 'react-onenterkeydown';
import ArrowKeysReact from 'arrow-keys-react';
import CloseOnEscape from 'react-close-on-escape';
import Popper from '@material-ui/core/Popper';
import { connect } from 'react-redux';
import defaultScreenShot from '../../../assets/images/noScreenshot.png';
import config from '../../../config';
import { checkArrayLength, handleXpathLength } from '../../../utils/utils';

import Tooltip from '../../../components/Tooltip';
import { MODAL_CANCEL_BUTTON, MODAL_FOOTER } from '../../../common/cssConstants';
import StatusSnipet from './StatusSnipet';
import FullScreenshotMode from './FullScreenshotMode';
import { TestStepUtils } from '../../../utils/TestStepUtils';
import PreLoadImage from '../../../common/PreLoadImage';
import { CustomSwitch } from '../../../common/miniComponents';

const styles = (theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    dialog: {
        textAlign: 'center',
    },
    dialogContent: {
        overflowY: 'hidden',
        padding: '30px !important',
        '& > div > ul': {
            overflowY: 'auto',
            minHeight: 'calc(100vh - 300px)',
        },
    },
    prevImageContainer: {
        height: '25%',
        'border-top-left-radius': 12,
        background: 'rgba(0, 0, 0, 0.3)',
        'border-bottom-left-radius': 12,
        cursor: 'pointer',
    },
    prevImageContainerDisabled: {
        height: '25%',
        'border-top-left-radius': 12,
        background: 'rgba(0, 0, 0, 0.3)',
        'border-bottom-left-radius': 12,
        cursor: 'not-allowed',
        '& > svg': {
            color: '#ffffff',
        },
    },
    nextImageContainer: {
        height: '25%',
        'border-top-right-radius': 12,
        background: 'rgba(0, 0, 0, 0.3)',
        'border-bottom-right-radius': 12,
        cursor: 'pointer',
    },
    nextImageContainerDisabled: {
        height: '25%',
        'border-top-right-radius': 12,
        background: 'rgba(0, 0, 0, 0.3)',
        'border-bottom-right-radius': 12,
        cursor: 'not-allowed',
        '& > svg': {
            color: '#ffffff',
        },
    },
    treverseStep: {
        textAlign: 'left',
        paddingLeft: '20px',
        color: '#1E69CC',
        cursor: 'pointer',
    },
    treverseStepDisabled: {
        textAlign: 'left',
        paddingLeft: '20px',
        color: '#b4b4b4',
        cursor: 'not-allowed',
    },
    sliderMask: {
        position: 'absolute',
        width: '100%',
        zIndex: 100,
        height: '100%',
        background: 'rgba(0,0,0,0.4)',
        cursor: 'pointer',
    },
    buttons: {
        fontSize: '14px',
        color: 'rgb(66, 174, 0)',
        backgroundColor: 'white',
        cursor: 'pointer',
        padding: 10,
        fontWeight: 500,
    },
    playPauseBtn: {
        marginTop: 4,
        cursor: 'pointer',
        fontSize: 24,
        fontWeight: 'bolder',
    },
    modalPopper: {
        zIndex: 99999,
        maxWidth: 280,
        height: 'auto',
    },
    modalPapper: {
        padding: '4px 10px 0 10px',
    },
    statusIcon: {
        cursor: 'pointer',
    },
    progress: {
        position: 'absolute',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        width: 'calc(100% - calc(23.99px + 23.99px))',
        marginLeft: '23.99px',
    },
    fullScreenIcon: {
        height: 20,
        width: 21,
        color: '#5F7B96',
    },
    fullscreen: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: '#fff',
        zIndex: 10000,
    },
    fullscreenButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 28,
        width: 28,
        borderRadius: '50%',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 2px 1px rgba(0,0,0,0.12), 0 2px 8px 0 rgba(0,0,0,0.7)',
        cursor: 'pointer',
    },
    pipe: {
        display: 'block',
        borderLeft: '1px solid #595959',
        height: 20,
    },
    container: {
        display: 'flex',
        width: '100%',
        height: '50px',
        border: '2px dotted #ADD8E6',
        borderRadius: '15px',
        alignItems: 'center',
    },
    maxWidth850: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 850,
        overflow: 'hidden',
    },
    testStepNumber: {
        paddingLeft: 25,
        color: '#595959',
        // minWidth: '30px',
    },
    banner: {
        display: 'flex',
        marginLeft: '15px',
    },
    testInstruction1: {
        marginRight: '30px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: 500,
        maxWidth: 400,
        display: 'inline-block',
        color: '#1168CD',
    },
    testInstruction2: {
        marginRight: '30px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: 500,
        maxWidth: 700,
        display: 'inline-block',
        color: '#1168CD',
    },
    testData: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 400,
        marginLeft: 20,
        color: '#1BABAB',
    },
    expectedResult: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 300,
        marginLeft: 20,
        color: '#B98700',
    },
    imageWrapper: {
        overflow: 'hidden scroll',
        width: '100%',
        maxHeight: 'calc(100vh - 318px)',
        minHeight: 'calc(100vh - 318px)',
        backgroundColor: '#fff',
    },
    imageContainer: {
        minHeight: 'calc(100vh - 318px)',
    },
});

class ScreenShotModal extends React.Component {
    constructor(props) {
        super(props);
        const testStep = props.testSteps[props.currentStepScreenshotModalIndex];
        this.autoPlayInteval = null;
        this.state = {
            autoPlay: false,
            testInstruction: testStep.instr,
            testData: testStep.data,
            testStepNumber: testStep.instrNum,
            expectedResult: testStep.expectedResults,
            testStepStatus: testStep.status,
            testStepMsg: testStep.message,
            // index: props.index,
            imageNo: testStep.screenshotNo ? Number(testStep.screenshotNo) - 1 : 0,
            isFullScreenShot: props.isFullScreenShot,
            anchorEl: null,
            open: false,
            isImageLoading: false,
            isImageSuccess: true,
            turnOnFullScreenMode: false,
        };

        ArrowKeysReact.config({
            left: () => {
                this.handleKeyPress('prev');
            },
            right: () => {
                this.handleKeyPress('next');
            },
            up: () => {
                this.handleKeyPress('prev');
            },
            down: () => {
                this.handleKeyPress('next');
            },
        });
    }

    componentDidMount() {
        setTimeout(() => {
            const ele = document.querySelector('#materialDialog');
            if (ele) ele.removeAttribute('tabIndex');
        }, 100);
    }

    UNSAFE_componentWillReceiveProps({ testStep: newTestStep }) {
        const {
            testStep: { instr, data, instrNum, status, message, hasChild },
        } = this.props;
        if (
            newTestStep &&
            (instr !== newTestStep.instr ||
                data !== newTestStep.data ||
                instrNum !== newTestStep.instrNum ||
                status !== newTestStep.status ||
                message !== newTestStep.message ||
                hasChild !== newTestStep.hasChild)
        ) {
            this.setState({
                testInstruction: newTestStep.instr,
                testData: newTestStep.data,
                testStepNumber: newTestStep.instrNum,
                expectedResult: newTestStep.expectedResults,
                testStepStatus: newTestStep.status,
                testStepMsg: newTestStep.message,
                imageNo: newTestStep.screenshotNo ? Number(newTestStep.screenshotNo) - 1 : 0,
            });
        }
    }

    getPreviewImage = (testStep) => {
        const { screenshotPaths, screenshotSmallPaths } = testStep;
        const { isFullScreenShot } = this.state;
        let screenShots = null; // [defaultScreenShot]
        if (isFullScreenShot && checkArrayLength(screenshotPaths)) {
            screenShots = screenshotPaths;
        } else if (!isFullScreenShot && checkArrayLength(screenshotSmallPaths)) {
            screenShots = screenshotSmallPaths;
        }
        const paths = [];
        if (checkArrayLength(screenShots)) {
            screenShots.forEach((path) => {
                paths.push(`${config.api.endpoint}/testCases/previewScreenShot?screenShotURL=${path}`);
            });
        }
        return checkArrayLength(paths) ? paths : [];
    };

    handleHover = (event) => {
        const { currentTarget } = event;
        this.setState((state) => ({
            anchorEl: currentTarget,
            open: !state.open,
        }));
    };

    imageCompeleteLoad = () => {
        const { autoPlay } = this.state;
        this.setState({ isImageLoading: false, isImageSuccess: true });
        if (autoPlay && !this.autoPlayInteval) {
            this.autoPlayInteval = true;
            setTimeout(() => {
                this.autoPlayInteval = false;
                const { handleModalTreverseStep, testCaseId, currentStepScreenshotModalIndex, stepsLength } = this.props;
                const currentIndex = currentStepScreenshotModalIndex !== stepsLength - 1 ? currentStepScreenshotModalIndex : -1;
                handleModalTreverseStep(true, { testCaseId, currentStepScreenshotModalIndex: currentIndex, stepsLength }, 'next');
                this.chagneImage();
            }, 2000);
        }
    };

    handleKeyPress = (key) => {
        const { testCaseId, currentStepScreenshotModalIndex, stepsLength } = this.props;

        if (testCaseId && (currentStepScreenshotModalIndex || `${currentStepScreenshotModalIndex}` === '0') && stepsLength) {
            this.props.handleModalTreverseStep(true, { testCaseId, currentStepScreenshotModalIndex, stepsLength }, key);
            this.chagneImage();
        }
    };

    handleEscape = () => {
        if (!this.state.turnOnFullScreenMode) {
            this.props.handleModal(false);
        }
    };

    handleClose = (e) => {
        e.stopPropagation();
    };

    handleSwitch = (event, name) => {
        this.setState({ [name]: event.target.checked });
        if (name === 'isFullScreenShot') {
            this.chagneImage();
        }
    };

    createSliderImages = (paths) => {
        const { classes } = this.props;
        const { imageNo } = this.state;
        const screenShotsSlides = [];
        if (checkArrayLength(paths)) {
            paths.forEach((path, i) => {
                screenShotsSlides.push(
                    <div style={{ position: 'relative' }} key={`slider-${i}`}>
                        <div>
                            {imageNo !== i ? <div aria-hidden className={classes.sliderMask} onClick={() => this.chagneImage(i)}></div> : null}
                            <PreLoadImage alt="" defaultImage={defaultScreenShot} key={`${path}_small`} style={{ maxWidth: '100%' }} src={path} />
                        </div>
                    </div>,
                );
            });
        } else {
            screenShotsSlides.push(
                <div style={{ position: 'relative' }} key="slider-default">
                    <div>
                        <PreLoadImage alt="" key={`${defaultScreenShot}_small`} style={{ maxWidth: '100%' }} src={defaultScreenShot} />
                    </div>
                </div>,
            );
        }
        return { screenShotsSlides };
    };

    chagneImage = (imageNo = null) => {
        this.setState({
            imageNo: imageNo !== null ? imageNo : this.state.imageNo,
        });
    };

    toggleAutoPlay = () => {
        const { autoPlay } = this.state;
        if (autoPlay) {
            this.autoPlayInteval = false;
        } else {
            const { handleModalTreverseStep, testCaseId, currentStepScreenshotModalIndex, stepsLength } = this.props;
            const currentIndex = currentStepScreenshotModalIndex !== stepsLength - 1 ? currentStepScreenshotModalIndex : -1;
            handleModalTreverseStep(true, { testCaseId, currentStepScreenshotModalIndex: currentIndex, stepsLength }, 'next');
            this.chagneImage();
        }
        this.setState({
            autoPlay: !autoPlay,
        });
    };

    handleFullscreenMode = (flag) => {
        this.setState({ turnOnFullScreenMode: flag });
        clearInterval(this.autoPlayInteval);
    };

    render() {
        const {
            classes,
            screenshotmodal,
            handleModal,
            handleModalTreverseStep,
            testStep,
            testCaseId,
            currentStepScreenshotModalIndex,
            stepsLength,
            testSteps,
            userVariables,
            testDataColumns,
        } = this.props;
        // const { screenshotNo } = testStep;
        const {
            expectedResult,
            testStepNumber,
            testStepStatus,
            testStepMsg,
            testInstruction,
            testData,
            imageNo,
            autoPlay,
            anchorEl,
            isImageLoading,
            isImageSuccess,
            turnOnFullScreenMode,
        } = this.state;
        // const defaultStatus = true;
        const paths = this.getPreviewImage(testStep);
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popper' : null;
        const { screenShotsSlides } = this.createSliderImages(paths);
        const content = (
            <div className={classes.container} style={{}}>
                <StatusSnipet
                    testStepStatus={testStepStatus}
                    testStepMsg={testStepMsg || ''}
                    testStepNumber={testStepNumber}
                    id={id}
                    handleHover={this.handleHover}
                    callBackSetState={() => {
                        this.setState({ anchorEl: null });
                    }}
                    callFrom="screenShotModal"
                />
                {open && (
                    <Popper
                        onMouseLeave={() => this.setState({ anchorEl: null, open: false })}
                        className={classes.modalPopper}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        transition
                    >
                        <Paper className={classes.modalPapper}>
                            <Typography className={classes.typography}>{testStepMsg}</Typography>
                        </Paper>
                    </Popper>
                )}
                <span
                    style={{
                        marginRight: 20,
                        display: 'flex',
                        fontWeight: testStepNumber % 1 === 0 ? 400 : 300,
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <span className={classes.maxWidth850}>
                        <span className={classes.testStepNumber}>{`${testStepNumber} `}</span>
                        <span
                            style={{
                                marginRight: '15px',
                            }}
                        />
                        <span className={classes.banner}>
                            {testData ? (
                                <span className={classes.testInstruction1} style={{ paddingLeft: testStepNumber % 1 === 0 ? 0 : 5 }}>
                                    <Tooltip data={`${testInstruction}`}>{handleXpathLength(testInstruction)}</Tooltip>
                                </span>
                            ) : (
                                <span className={classes.testInstruction2} style={{ paddingLeft: testStepNumber % 1 === 0 ? 0 : 5 }}>
                                    <Tooltip data={`${testInstruction}`}>{handleXpathLength(testInstruction)}</Tooltip>
                                </span>
                            )}
                            {testData && <span className={classes.pipe} />}
                            {testData && (
                                <span className={classes.testData}>
                                    <Tooltip data={TestStepUtils.renderDataField(testInstruction, testData, userVariables, testDataColumns)}>
                                        {TestStepUtils.renderDataField(testInstruction, testData, userVariables, testDataColumns)}
                                    </Tooltip>
                                </span>
                            )}

                            {expectedResult && <span style={{ marginLeft: 20 }} className={classes.pipe} />}
                            {expectedResult && (
                                <Tooltip data={`${expectedResult}`}>
                                    <span className={classes.expectedResult}>{`${expectedResult}`}</span>
                                </Tooltip>
                            )}
                        </span>
                    </span>
                    <span>
                        {autoPlay ? (
                            <Tooltip data="Pause">
                                <Pause
                                    className={classes.playPauseBtn}
                                    style={{
                                        color: 'red',
                                    }}
                                    aria-label="pauseIcon"
                                    id="pauseIcon"
                                    onClick={() => {
                                        this.toggleAutoPlay();
                                    }}
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip data="Play">
                                <PlayArrow
                                    className={classes.playPauseBtn}
                                    style={{
                                        color: '#0000FF',
                                    }}
                                    aria-label="playArrowIcon"
                                    id="playArrowIcon"
                                    onClick={() => {
                                        this.toggleAutoPlay();
                                    }}
                                />
                            </Tooltip>
                        )}
                    </span>
                </span>
            </div>
        );
        const isOriginalImageAvailable = checkArrayLength(paths) && paths[imageNo] !== defaultScreenShot && (isImageLoading || isImageSuccess);
        return (
            <div className={classes.root} {...ArrowKeysReact.events}>
                <Dialog
                    disableRestoreFocus
                    open={screenshotmodal}
                    aria-labelledby="form-dialog-title"
                    maxWidth={false}
                    PaperProps={{
                        id: 'materialDialog',
                    }}
                >
                    <DialogContent className={classes.dialogContent}>
                        {/* <div style={{ width: 800 }}>Modal Content WIP</div> */}
                        <Hidden only={['md', 'lg', 'xl']}>
                            <span style={{ display: 'inline-flex' }}>{content}</span>
                        </Hidden>
                        <Hidden only={['xs', 'sm']}>{content}</Hidden>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '96%',
                                paddingRight: 238,
                                margin: 'auto',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <span>Show complete webpage image</span>
                                <CustomSwitch
                                    checked={this.state.isFullScreenShot}
                                    onChange={(e) => {
                                        this.handleSwitch(e, 'isFullScreenShot');
                                    }}
                                    value="isFullScreenShot"
                                    disabled={!this.props.isFullScreenShot || autoPlay}
                                    w={32}
                                    h={16}
                                    margin="0 0 0 13px"
                                    trackBorderColor="#000"
                                    trackColor="#000"
                                    trackColorChk="#1168cd80"
                                    thumbColorChk="#1168cd"
                                />
                            </span>
                            <div
                                aria-hidden
                                className={classes.fullscreenButton}
                                style={autoPlay ? { cursor: 'not-allowed' } : {}}
                                onClick={() => {
                                    if (!autoPlay) this.handleFullscreenMode(true);
                                }}
                            >
                                <Fullscreen className={classes.fullScreenIcon} aria-label="fullscreenIcon" id="fullscreenIcon" />
                            </div>
                        </div>
                        <div style={{ width: 1000 }}>
                            <div
                                style={{
                                    display: 'flex',
                                    float: 'left',
                                    maxWidth: '767px',
                                    minWidth: '767px',
                                    overflow: 'hidden',
                                    maxHeight: 'calc(100vh - 318px)',
                                    position: 'relative',
                                }}
                            >
                                {isImageLoading ? (
                                    <div className={classes.progress}>
                                        <CircularProgress size={50} color="secondary" />
                                    </div>
                                ) : null}
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: '24px' }}>
                                    <div
                                        className={
                                            currentStepScreenshotModalIndex === 0 || autoPlay /* || isChangeScreenShot */
                                                ? classes.prevImageContainerDisabled
                                                : classes.prevImageContainer
                                        }
                                    >
                                        <ChevronLeft
                                            style={{ height: '100%' }}
                                            onClick={() => {
                                                if (currentStepScreenshotModalIndex !== 0 && !autoPlay /* && !isChangeScreenShot */) {
                                                    handleModalTreverseStep(
                                                        true,
                                                        { testCaseId, currentStepScreenshotModalIndex, stepsLength },
                                                        'prev',
                                                    );
                                                    this.chagneImage();
                                                    // this.setState({ imageNo: imageNo > 0 ? imageNo - 1 : 0 })
                                                }
                                            }}
                                            aria-label="chevronLeftIcon"
                                            id="chevronLeftIcon"
                                        />
                                    </div>
                                </div>
                                <div className={classes.imageWrapper}>
                                    <div className={classes.imageContainer}>
                                        {isOriginalImageAvailable ? (
                                            <PreLoadImage
                                                key={`${paths[imageNo && imageNo >= 0 ? imageNo : 0]}_large`}
                                                onStartLoad={() => {
                                                    this.setState({ isImageLoading: true, isImageSuccess: false });
                                                }}
                                                onLoad={() => {
                                                    this.imageCompeleteLoad();
                                                }}
                                                onError={() => {
                                                    this.setState({ isImageLoading: false, isImageSuccess: false });
                                                }}
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    border: '2px solid #ADD8E6',
                                                }}
                                                alt=""
                                                defaultImage={defaultScreenShot}
                                                src={paths[imageNo && imageNo >= 0 ? imageNo : 0]}
                                            />
                                        ) : (
                                            <PreLoadImage
                                                key={`${paths[imageNo && imageNo >= 0 ? imageNo : 0]}_large`}
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    border: '2px solid #ADD8E6',
                                                }}
                                                alt=""
                                                src={defaultScreenShot}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <div
                                        className={
                                            currentStepScreenshotModalIndex === stepsLength - 1 || autoPlay /* || isChangeScreenShot */
                                                ? classes.nextImageContainerDisabled
                                                : classes.nextImageContainer
                                        }
                                    >
                                        <ChevronRight
                                            style={{ height: '100%' }}
                                            onClick={() => {
                                                /*eslint-disable  */
                                                if (currentStepScreenshotModalIndex !== stepsLength - 1 && !autoPlay /*&& !isChangeScreenShot*/) {
                                                    handleModalTreverseStep(
                                                        true,
                                                        { testCaseId, currentStepScreenshotModalIndex, stepsLength },
                                                        'next',
                                                    );
                                                    this.chagneImage();
                                                    //paths ? this.setState({ imageNo: imageNo < paths.length - 1 ? imageNo + 1 : paths.length - 1 }) : null
                                                }
                                            }}
                                            aria-label="chevronRightIcon"
                                            id="chevronRightIcon"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    paddingLeft: 15,
                                    maxWidth: '233px',
                                    minWidth: '233px',
                                    float: 'left',
                                    maxHeight: 'calc(100vh - 318px)',
                                    overflow: 'hidden',
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        maxHeight: 'calc(100vh - 318px)',
                                        minHeight: 'calc(100vh - 318px)',
                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                        padding: 10,
                                        overflowY: 'auto',
                                    }}
                                >
                                    <div>{screenShotsSlides}</div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions style={MODAL_FOOTER}>
                        <CloseOnEscape onEscape={this.handleEscape}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    clearInterval(this.autoPlayInteval);
                                    handleModal(false);
                                }}
                                style={MODAL_CANCEL_BUTTON}
                                color="secondary"
                                disabled={this.state.isButtonDisabled}
                            >
                                Close
                            </Button>
                        </CloseOnEscape>
                    </DialogActions>
                </Dialog>
                {turnOnFullScreenMode ? (
                    <div className={classes.fullscreen}>
                        <FullScreenshotMode
                            handleModalTreverseStep={handleModalTreverseStep}
                            handleFullscreenMode={this.handleFullscreenMode}
                            testStep={testStep}
                            testSteps={testSteps}
                            currentStepScreenshotModalIndex={currentStepScreenshotModalIndex}
                            stepsLength={stepsLength}
                            testCaseId={testCaseId}
                            isFullScreenShot={this.state.isFullScreenShot}
                            projectIsFullScreenShot={this.props.isFullScreenShot}
                            toggleAutoPlay={this.toggleAutoPlay}
                            handleSwitch={this.handleSwitch}
                            getPreviewImage={this.getPreviewImage}
                            parentIsImageLoading={isImageLoading}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

ScreenShotModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    testStep: PropTypes.shape({}).isRequired,
    testSteps: PropTypes.array.isRequired,
    currentStepScreenshotModalIndex: PropTypes.number.isRequired,
    handleModalTreverseStep: PropTypes.func.isRequired,
};

ScreenShotModal.defaultProps = {
    currentStepScreenshotModalIndex: -1,
};

const mapStateToProps = (state, props) => {
    return {
        testDataColumns: state.projectReducer.testDataColumns,
        userVariables: state.projectReducer.userVariables,
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(ScreenShotModal));
