import { useSelector } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { COLORS } from './../../../common/Colors';
import { checkKeyInObject } from '../../../utils';

const styles = () => ({
    containedSecondary: {
        color: COLORS.white,
        backgroundColor: COLORS.primaryBtn,
    },
    outlinedPrimary: {
        // color: COLORS.white,
        border: `1px solid ${COLORS.border}`,
    },
});

function GeneralModalFooter(props) {
    const { buttons, classes } = props;
    const tabsData = useSelector((state) => state.generalModalReducer.tabsData);
    return (
        <React.Fragment>
            {buttons.map((button, ind) => {
                const isDisabled = typeof button.isDisabled === 'function' ? button.isDisabled(tabsData) : button.isDisabled;
                if (isDisabled && checkKeyInObject(button, 'style.borderColor')) delete button.style.borderColor;
                return (
                    <Button
                        id={button.id}
                        data-testid={button.id}
                        key={`${ind}-${button.id}`}
                        variant={button.variant}
                        color="secondary"
                        onClick={button.action}
                        classes={{
                            containedSecondary: classes.containedSecondary,
                            outlinedPrimary: classes.outlinedPrimary,
                        }}
                        style={{ border: isDisabled ? '1px solid #bdbdbd' : '', ...button.style }}
                        disabled={isDisabled}
                    >
                        {button.name}
                    </Button>
                );
            })}
        </React.Fragment>
    );
}

GeneralModalFooter.propTypes = {
    buttons: PropTypes.instanceOf(Array).isRequired,
    // tabsData: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(GeneralModalFooter);
