import React, { Component } from 'react';
import { Input } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { generalModalActions } from '../../../../store/actions';

const styles = () => ({
    width100P: {
        width: '100%',
    },
    textFieldContainer: {
        padding: '0 30px',
        width: '400px',
    },
    textFieldTitle: {
        color: '#595959',
        fontSize: '12px',
    },
    input: {
        fontSize: '12px',
        color: '#000000',
    },
    firstInput: {
        padding: '4px 0px 5px',
        width: '100%',
    },
});

class UpdateTestCaseHeaderTopDetailContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testCaseName: props.testCaseName,
            desc: props.desc,
        };
    }

    /* Component lifecycle start */
    componentDidMount() {
        this.props.updateTabData('updateTestCase', { state: this.state });
    }
    /* Component lifecycle end */

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.props.updateTabData('updateTestCase', { state: this.state });
        });
    };

    render() {
        const { classes } = this.props;
        const { testCaseName, desc } = this.state;
        return (
            <div className={classes.textFieldContainer}>
                <span className={classes.textFieldTitle}>Case Name</span>
                <form>
                    <Input
                        id="testCaseName"
                        type="text"
                        name="testCaseName"
                        value={testCaseName}
                        onChange={(event) => this.handleChange(event)}
                        className={classes.firstInput}
                        classes={{ input: classes.input }}
                        required
                    />
                </form>
                <span className={classes.textFieldTitle}>Case Description</span>
                <form>
                    <Input
                        id="desc"
                        type="text"
                        multiline
                        name="desc"
                        value={desc}
                        onChange={(event) => this.handleChange(event)}
                        className={classes.width100P}
                        classes={{ input: classes.input }}
                    />
                </form>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(UpdateTestCaseHeaderTopDetailContent));
