import store from '../store';
import HttpBase from './HttpBase';
import { checkArrayLength, checkKeyInObject, donwloadThroughLinkClick } from '../utils/utils';
import { ModalActions } from '../store/actions';

class ProjectServices extends HttpBase {
    getExecutionDetails = async (accountId) => {
        // NOTE: only 1 test suite per project
        try {
            const { data } = await this.httpClient.get(`/testScriptExecutions/${accountId}`);
            return data;
            // project._testSuiteId = testSuiteId;
        } catch (error) {
            // ... including try/catch error handling
            console.error('getTestSuites:', error);
            return false;
        }
    };

    previewTestData = async (testDataId, showSnackBar = null, modalType = '') => {
        try {
            const { data, status } = await this.httpClient.get(`/testCases/${testDataId}/previewTestData`);
            if (data && Object.keys(data).length > 0 && status === 200) {
                if (checkKeyInObject(data, 'data') && data.data && (!data.order || !checkArrayLength(JSON.parse(data.order)))) {
                    const _data = JSON.parse(data.data);
                    const _order = Object.keys(_data);
                    return { data: data.data, order: JSON.stringify(_order) };
                }
                return data;
            }
            if (showSnackBar) {
                // console.log(modalType);
                if (modalType === 'edit') showSnackBar('Failed to Update.');
                else showSnackBar('Failed to preview.');
            }

            return false;
        } catch (error) {
            if (showSnackBar) {
                if (modalType === 'edit') showSnackBar('Failed to Update.');
                else showSnackBar('Failed to preview.');
            }
            return false;
        }
    };

    previewTestCase = async (testDataId, showSnackBar = null) => {
        try {
            const { data, status } = await this.httpClient.get(`/testCases/${testDataId}/previewTestCase`);
            if (data && status === 200) {
                return data;
            }
            if (showSnackBar) showSnackBar('Failed to preview.');
            return false;
        } catch (error) {
            if (showSnackBar) showSnackBar('Failed to preview.');
            return false;
        }
    };

    previewTestScript = async (testScriptId, showSnackBar = null) => {
        try {
            const { data, status } = await this.httpClient.get(`/testScripts/${testScriptId}/previewTestScript`);
            if (data && status === 200) {
                return data;
            }
            if (showSnackBar) showSnackBar('Failed to preview.');
            return false;
        } catch (error) {
            if (showSnackBar) showSnackBar('Failed to preview.');
            return false;
        }
    };

    getMarket = async (marketType, showSnackBar = null) => {
        try {
            let url = '/v1/getlocalmarket';
            if (marketType === 'GLOBAL') {
                url = '/v1/getglobalmarket';
            }
            const { data, status } = await this.httpClient.get(url);
            if (data && status === 200) {
                return data;
            }
            if (showSnackBar) showSnackBar('Failed to preview.');
            return false;
        } catch (error) {
            if (showSnackBar) showSnackBar('Failed to preview.');
            return false;
        }
    };

    getSingleProject = (projectId) => {
        return store.getState().projectReducer.projects.find((project) => project.projectId === parseInt(projectId, 10)) || {};
    };

    activeExecutionsTasks = (projectName) => {
        const executionStatusSort = {
            INPROGRESS: 0,
            SUCCESS: 1,
            ERROR: 2,
        };

        function compareProperty(a, b) {
            return a || b ? (!a ? -1 : !b ? 1 : a.localeCompare ? a.localeCompare(b) : a - b) : 0;
        }

        function executionTasksCompare(a, b) {
            return (
                compareProperty(executionStatusSort[a.executionStatus], executionStatusSort[b.executionStatus]) ||
                compareProperty(a.completedOn.getTime(), b.completedOn.getTime()) ||
                compareProperty(a.initiatedOn.getTime(), b.initiatedOn.getTime())
            );
        }
        // Return the sorted (by status) and optionally filtered by project name list of tasks
        return store.getState().projectReducer.executions.length === 0
            ? []
            : store
                  .getState()
                  .projectReducer.executions.filter((executionTask) => (projectName ? executionTask.projectName === projectName : true))
                  .sort(executionTasksCompare);
    };

    downloadTest = async (url, testDataName, encodeURI = null, showSnackBar = null) => {
        try {
            const link = document.createElement('a');
            if (encodeURI) {
                link.href = encodeURI;
            } else {
                const response = await this.httpClient.get(url, { responseType: 'arraybuffer' });
                link.href = window.URL.createObjectURL(
                    new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
                );
            }
            link.setAttribute('download', testDataName);
            link.setAttribute('target', '_self');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            if (showSnackBar) showSnackBar(`${testDataName} Downloading`, '', true);
        } catch (error) {
            if (showSnackBar) {
                showSnackBar('Could not download Test Data..');
            }
        }
    };

    downloadGradle = async (url, data, toggleSnackBar = () => {}, snackBarErrorMessage = 'Could not download gradle..') => {
        try {
            const response = await this.getWithDownload(this.httpClient.get, url, { responseType: 'arraybuffer' }, data);
            if (checkKeyInObject(response, 'status') === 500 || checkKeyInObject(response, 'status') === 502) {
                toggleSnackBar('Downloading Failed.', '', false, null, false, true, true);
            }
            donwloadThroughLinkClick(response, `${data.fileName}.zip`, 'application/zip');
        } catch (error) {
            if (toggleSnackBar) toggleSnackBar(snackBarErrorMessage);
        }
    };

    getActionableElements = async (url) => {
        try {
            const sourceLink = url; // `/page${searchType}/search/${md5sum}/${prefix}`

            if (sourceLink) {
                const { data, error } = await this.httpClient.get(sourceLink);
                if (data) {
                    return data;
                }
                // .then((response) => {
                //     console.log({ response })
                //     const {data} = response;
                //     return data;
                // })
                // .catch((/* err */) => {
                //     console.log(err)
                //     err = 'Could not search Action Elements..';
                // })
                if (error) {
                    // eslint-disable-next-line no-console
                    console.log('Could not search Action Elements..');
                } else {
                    // eslint-disable-next-line no-console
                    console.log('success');
                }
            } else {
                // eslint-disable-next-line no-console
                console.log('Could not search Action Elements..');
            }
        } catch (error) {
            console.error('getActionableElements:', error);
            // self.state = 'error';
            // self.error = error.toString();
        } finally {
            // markLoading(false);
        }
        return null;
    };

    downloadProgress = (toggleSnackBar = () => {}, data = { fileName: '', id: 0, type: '' }) => {
        return (progressEvent) => {
            if (data && (data.type || data.id)) {
                const { isSnackBarOpen } = store.getState().modalReducer;
                const progressPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (!isSnackBarOpen) {
                    toggleSnackBar( data.fileName==='Execution Report'?'Generating preview':'Downloading', '', false, null, false, false, true);
                }
                store.dispatch(ModalActions.updateProjectDownloadProgress(`${data.type}_${data.id}`, progressPercentage, data.fileName));
            }
        };
    };

    getWithDownload = async (_get, url, _options = {}, data = { fileName: '', id: 0, type: '' }, showProgress = true) => {
        try {
            if (!showProgress) {
                return await _get(url, _options);
            }
            const toggleSnackBar = (...args) => store.dispatch(ModalActions.toggleSnackBar(...args));
            const options = {
                ..._options,
                onDownloadProgress: this.downloadProgress(toggleSnackBar, data),
            };
            const response = await _get(url, options);
            setTimeout(() => {
                // to close particular progress bar
                store.dispatch(ModalActions.updateProjectDownloadProgress(`${data.type}_${data.id}`));
            }, 500);
            return response;
        } catch (error) {
            store.dispatch(ModalActions.updateProjectDownloadProgress(`${data.type}_${data.id}`));
            throw error;
        }
    };
}

export default new ProjectServices();
