import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import _ from 'lodash';
import Hidden from '@material-ui/core/Hidden';
// icons
import Disable from 'mdi-material-ui/MinusCircle';
import AccountMultipleMinus from 'mdi-material-ui/AccountMultipleMinus';
import Close from '@material-ui/icons/Close';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Edit from '@material-ui/icons/Edit';
import EnableIcon from '@material-ui/icons/PowerSettingsNew';
import Restore from '@material-ui/icons/SettingsBackupRestore';

// Custom components
import { MUI_ACTION_MENU_ICON, THREE_DOT_MENU_CSS, TABLES_CSS, BLACK_FONT } from '../../../common/cssConstants';
import JSONToCsv from '../../../services/JSONToCsv';
import { GeneralActions, ModalActions, UserActions, ProjectActions, generalModalActions } from '../../../store/actions';
import {
    sort,
    checkArrayLength,
    checkKeyInObject,
    getCountAvatar,
    getFormatedDate,
    resizeAllCols,
    getRelativeTime,
    makeLocalDate,
} from '../../../utils/utils';
import { EnhancedTableHead } from '../../../components/EnhancedTable';
import Tooltip from '../../../components/Tooltip';
import TableFooter from '../../../common/TableFooter';
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import TableCell from '../../../components/Table/TableCell';
import TableRow from '../../../components/Table/TableRow';
import UserModal from '../modal/UserModal';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON } from '../../../components/Button/Btn';
import { isEmail, isAccountName, PASSWORD_REGEX } from '../../../common/regexConstants';
import { USER_NAME } from '../../../common/constants';
import { MenuOptions } from '../../../components/Button/MenuOptions';

const styles = (theme) => ({
    checkBox: {
        height: 25,
        width: 25,
    },
    checkBoxIcon: {
        color: '#0092e5',
        fontSize: '25px',
        marginLeft: '-4px',
    },
    checkBoxChecked: {
        color: '#3B91DF !important',
    },
    iconBtn: {
        height: 30,
        width: 30,
    },
    multiCloseIcon: {
        color: '#4a4a4a',
        cursor: 'pointer',
        fontSize: 22,
        marginLeft: 10,
    },
    AddIcon: {
        color: '#ff9700',
        fontSize: '25px',
    },
    root: {
        width: 'calc(100% - 40px)',
        margin: '0px 20px',
        marginTop: '10px',
        borderRadius: 0,
        boxShadow: 'none',
    },
    headerActionContainer: {
        color: '#1168CD',
        display: 'flex',
        justifyContent: 'space-between',
        width: 'auto',
        marginRight: '20px',
        alignSelf: 'flex-end',
    },
    headerContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    selectionActionContainer: {
        color: '#1168CD',
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '10%',
    },
    selectionDisplayRow: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f0f7ea',
        padding: '0 10px',
        height: 45,
        justifyContent: 'space-between',
        boxShadow: '0px 1px 5px 0px rgba(0,0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    },
    selectedText: {
        color: BLACK_FONT,
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '3px',
    },
    moreIcons: {
        ...MUI_ACTION_MENU_ICON,
        marginLeft: 5,
        color: '#5F7B96',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    cellWithCheckBox: {
        paddingLeft: '0px !important',
    },
    restoreIcon: {
        color: '#1BABAB',
    },
    enableIcon: {
        color: '#5F7B96',
    },
    noDataText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '88px',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    tableRows: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.07) !important',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    pointer: {
        cursor: 'pointer',
    },
    disableCursor: {
        cursor: 'not-allowed !important',
        color: '#BAC4CE !important',
    },
    editIcon: {
        height: '30px',
        width: '30px',
        color: '#5F7B96',
        marginLeft: 5,
    },
    body: {
        maxHeight: 'calc( 100vh - 176px )',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        borderRadius: 5,
        overflowX: 'auto',
        overflowY: 'auto',
        backgroundColor: '#fff',
    },
    wrapperAccountNameItems: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    width100per: {
        paddingLeft: '10px',
        width: '100%',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapperAvatarUsername: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: 'calc(100% - 70px)',
        overflow: 'hidden',
    },
    avatar: {
        margin: '0px 10px 0px 0px',
        color: 'rgb(255, 255, 255)',
        backgroundColor: '#1babab',
        width: '25px',
        height: '25px',
        fontSize: '14px',
    },
    username: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
        display: 'inline-block',
    },
    activeStatus: {
        height: '19px',
        width: '55px',
        minWidth: '55px',
        fontFamily: 'Roboto',
        borderRadius: '8px',
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center',
        color: 'rgb(255, 255, 255)',
        paddingTop: 2,
        marginRight: '10px',
    },
    userActiveColor: {
        backgroundColor: '#1babab',
    },
    dateTypography: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
        display: 'inline-block',
        paddingTop: 4,
    },
    userRole: {
        color: '#000000',
    },
    disableIcon: {
        marginLeft: 6,
    },
    disabledColor: {
        color: 'rgb(186, 196, 206)',
    },
    disabledBackgroundColor: {
        backgroundColor: 'rgb(186, 196, 206)',
    },
    changedColor: {
        color: 'white',
    },
});

class UserList extends React.Component {
    constructor(props) {
        super(props);

        this.colsWidth = {};
        this.state = {
            order: 'asc',
            orderBy: 'name',
            page: 0,
            rowsPerPage: 20,
            selectedUsers: {},
            users: [],
        };

        props.updateTitle('Users');
    }

    componentDidMount() {
        const {
            getGroups,
            currentUser: { accountId },
            getProjects,
        } = this.props;
        this.fetchUsers();
        this.onCreateUser();
        this.onDownloadUsers();
        getProjects();
        getGroups(accountId);
        const userRowsPreference = localStorage.getItem('rowsUserPerPage');
        if (userRowsPreference) {
            this.updateState({
                rowsPerPage: parseInt(userRowsPreference, 10),
                selectedUsers: {},
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            userGroups: { Groups },
            users,
        } = nextProps;
        const _users = JSON.parse(JSON.stringify(users));

        if (checkArrayLength(users) && checkArrayLength(Groups)) {
            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                _users[i] = { ..._users[i], groups: [] };
                for (let j = 0; j < Groups.length; j++) {
                    const group = Groups[j];
                    const isAvailable = group.users ? group.users.map((u) => u.id).indexOf(user.id) : -1;
                    if (isAvailable !== -1) {
                        _users[i].groups.push(group);
                    }
                }
            }
        }
        const obj = { users: _users };
        if (this.state.page !== 0 && this.props.query !== nextProps.query) {
            obj.page = 0;
        }
        this.setState(obj);
    }

    componentDidUpdate() {
        resizeAllCols(this.colsWidth);
    }

    componentWillUnmount() {
        this.props.queryRemove();
        this.props.clearSnackBar();
    }

    onDownloadUsers = () => {
        const { onDownloadClick } = this.props;
        if (onDownloadClick && typeof onDownloadClick === 'function') {
            onDownloadClick(() => {
                this.downloadCSV(this.state.users);
            });
        }
    };

    onCreateUser = () => {
        const { onCreateClick } = this.props;
        if (onCreateClick && typeof onCreateClick === 'function') {
            onCreateClick(() => {
                const { updateTabData } = this.props;
                const items = [{ value: 'SDET' }, { value: 'ADMIN' }];
                this.props.toggleModal('GeneralModal', null, null, {
                    title: 'Create New User',
                    closeIconAction: () => {
                        this.closeModal();
                    },
                    component: [
                        {
                            content: <UserModal convertToSelectValues={this.convertToSelectValues} items={items} modalFor="AddUser" />,
                            buttons: [
                                NEXT_SUBMIT_BUTTON({
                                    name: 'Create',
                                    action: () => {
                                        updateTabData('isDisabledSubmitButton', true);
                                        this.createUser();
                                    },
                                    isDisabled: (tabsData) => {
                                        if (checkKeyInObject(tabsData, 'UserModal.state')) {
                                            const {
                                                UserModal: {
                                                    state: {
                                                        email,
                                                        selectedItem,
                                                        userName,
                                                        password,
                                                        isDuplicateEmail,
                                                        selectedDateStart,
                                                        selectedDateEnd,
                                                        isPastedDateSame
                                                    },
                                                },
                                            } = tabsData;
                                            return !(
                                                email.length &&
                                                isEmail.test(email) &&
                                                userName.length &&
                                                selectedItem.length &&
                                                PASSWORD_REGEX.test(password) &&
                                                !tabsData.isDisabledSubmitButton &&
                                                !isDuplicateEmail &&
                                                !isPastedDateSame &&
                                                new Date(selectedDateStart) < new Date(selectedDateEnd) &&
                                                (isAccountName.test(userName) || isEmail.test(userName))
                                            );
                                        }
                                        return false;
                                    },
                                }),
                                CANCEL_BUTTON({ action: this.closeModal }),
                            ],
                        },
                    ],
                });
            });
        }
    };

    getAccountNameById = (id) => {
        const { accounts } = this.props;
        if (checkArrayLength(accounts)) {
            const acc = accounts.find((_acc) => _acc.accountId === id);
            return checkKeyInObject(acc, 'accountName', 'value', 'N/A');
        }
        return 'N/A';
    };

    getSelectedUsersDetail = () => {
        const { selectedUsers, users } = this.state;
        const _selectedUsers = [];
        Object.keys(selectedUsers).forEach((userId) => {
            users.some((user) => {
                if (`${user.id}` === `${userId}`) {
                    _selectedUsers.push(user);
                }
                return null;
            });
        });
        return _selectedUsers;
    };

    getSelectedUserForUserPermission = () => {
        const { selectedUsers, users } = this.state;
        const _selectedUsers = Object.keys(selectedUsers);
        const usersObj = users.filter(
            (_usr) =>
                _usr &&
                _selectedUsers.includes(`${_usr.id}`) &&
                _usr.role === 'SDET' &&
                !_usr.deleted &&
                !_usr.deletedPermanently &&
                this.props.currentUser.accountId === _usr.accountId,
        );
        return usersObj;
    };

    fetchUsers = () => {
        const {
            currentUser: { accountId, name },
        } = this.props;
        if (`${accountId}` === '1' && name === USER_NAME) {
            this.props.getUsers();
        } else {
            this.props.getUserByAccountId(accountId);
        }
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    downloadCSV(allUsers) {
        const header = {
            name: 'Name', // remove commas to avoid errors
            email: 'Email',
            status: 'Role',
            lastLogin: 'Last Login',
            startDate: 'Start Date',
            endDate: 'End Date',
            account: 'Account',
        };
        const users = allUsers.map((user) => {
            return {
                name: `"${user.name.replace(/["]{1}/g, '""')}"`,
                email: `"${user.email.replace(/["]{1}/g, '""')}"`,
                status: `"${user.role.replace(/["]{1}/g, '""')}"`,
                lastLogin: `"${(user.lastLogin && user.lastLogin.Time && !user.lastLogin.Time.includes('0001')
                    ? `${moment(user.lastLogin).format('YYYY-MM-DD')}`
                    : ''
                ).replace(/["]{1}/g, '""')}"`,
                startDate: `"${getFormatedDate(makeLocalDate(user.startDate), 'YYYY-MM-DD', '').replace(/["]{1}/g, '""')}"`,
                endDate: `"${getFormatedDate(makeLocalDate(user.endDate), 'YYYY-MM-DD', '').replace(/["]{1}/g, '""')}"`,
                account: `"${this.getAccountNameById(user.accountId).replace(/["]{1}/g, '""')}"`,
            };
        });
        const fileTitle = 'Users'; // or 'my-unique-title'
        JSONToCsv.CSVFile(header, users, fileTitle);
    }

    handelSelectUser = (userId) => {
        const { selectedUsers } = this.state;
        const _selectedUsers = JSON.parse(JSON.stringify(selectedUsers));
        if (_selectedUsers[userId]) {
            delete _selectedUsers[userId];
        } else {
            _selectedUsers[userId] = true;
        }
        this.setState({ selectedUsers: _selectedUsers });
    };

    toggleSelectAllUsers = (users, isSelect) => {
        const { selectedUsers } = this.state;
        const { currentUser } = this.props;
        const _selectedUsers = JSON.parse(JSON.stringify(selectedUsers));
        users.forEach((user) => {
            if (user.name !== USER_NAME && !user.deleted && !user.deletedPermanently && `${user.id}` !== `${currentUser.userId}`) {
                if (!isSelect && _selectedUsers[user.id]) {
                    delete _selectedUsers[user.id];
                } else if (isSelect) {
                    _selectedUsers[user.id] = true;
                }
            }
        });
        this.setState({
            selectedUsers: _selectedUsers,
        });
    };

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleChangePage = (page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        localStorage.setItem('rowsUserPerPage', event.target.value);
        this.setState({ rowsPerPage: event.target.value });
    };

    addToGroup = (groups, user = null) => {
        const users = user ? [user] : this.getSelectedUsersDetail();
        const onComplete = () => {
            this.setState({ selectedUsers: {} }, () => {
                const {
                    getGroups,
                    currentUser: { accountId },
                } = this.props;
                this.fetchUsers();
                getGroups(accountId);
            });
        };
        this.props.toggleModal('adminUserModal', null, '', { modaltype: 'AddToGroup', multiUsers: users, groups, onComplete });
    };

    scheduleModal = (user) => {
        this.props.toggleModal('adminUserModal', null, '', { modaltype: 'schedule', user });
    };

    deleteMultipleUsers = () => {
        const users = this.getSelectedUsersDetail();
        const onComplete = () => {
            this.setState({ selectedUsers: {} });
        };
        this.props.toggleModal('adminUserModal', null, '', { modaltype: 'MultiDelete', multiUsers: users, onComplete });
    };

    // isDisabledUserPermission = () => {
    //     const { selectedUsers } = this.state;
    //     let usersObj = this.getSelectedUserForUserPermission()
    //     return !(usersObj.length > 0 && usersObj.length === getObjectKeysLength(selectedUsers));
    // }

    userPermission = () => {
        const usersObj = this.getSelectedUserForUserPermission();
        this.props.toggleModal('adminUserModal', null, '', { modaltype: 'Permissions', users: usersObj, projects: this.props.projects });
    };

    deleteUser = (user) => {
        this.props.toggleModal('adminUserModal', null, '', { modaltype: 'Delete', user });
    };

    disableUser = (user, status) => {
        this.props.toggleModal('adminUserModal', null, '', { modaltype: 'ChangeStatus', user, multiUsers: [], status });
    };

    // Fetch details of all users' ids in selectedUser obj

    convertToSelectValues = (inputArray = []) => {
        return inputArray.map((element) => {
            return {
                value: element.value,
                label: element.value,
            };
        });
    };

    closeModal = () => {
        this.props.toggleModal();
        setTimeout(() => {
            this.props.clearGeneralModal();
        }, 500);
    };

    createUser = async () => {
        const {
            tabsData: {
                UserModal: {
                    state: { email, selectedItem, userName, password, selectedDateStart, selectedDateEnd },
                },
            },
            updateTabData,
            currentUser,
            users,
            createUser,
            toggleSnackBar,
        } = this.props;
        const accountId = (currentUser.userAccount || currentUser.accountId).toString();
        const exist = users.find((usr) => usr.name.toLowerCase() === userName.toLowerCase());
        if (exist && exist.hasOwnProperty('name')) {
            toggleSnackBar('User already exists');
            updateTabData('isDisabledSubmitButton', false);
            return;
        }

        const isCreated = await createUser({
            name: userName,
            email,
            role: selectedItem,
            password,
            accountId: `${accountId}`,
            startDate: `${moment(selectedDateStart).format('YYYY-MM-DD')}T00:00:00Z`,
            endDate: `${moment(selectedDateEnd).format('YYYY-MM-DD')}T00:00:00Z`,
            isUserInActive: false,
        });
        try {
            if (checkKeyInObject(isCreated, 'message.message', 'value', '') === 'Network Error') {
                this.closeModal();
                toggleSnackBar('Your Internet is Disconnected!');
            } else if (checkKeyInObject(isCreated, 'status')) {
                this.fetchUsers();
                this.closeModal();
            } else if (checkKeyInObject(isCreated, 'message')) {
                if (isCreated.message && isCreated.message.includes && (isCreated.message.includes('user') || isCreated.message.includes('email'))) {
                    toggleSnackBar(isCreated.message);
                    updateTabData('isDisabledSubmitButton', false);
                } else {
                    throw new Error('error');
                }
            }
        } catch (error) {
            this.closeModal();
            toggleSnackBar('Unable to create user!');
        }
    };

    updateUser = async (user) => {
        const {
            tabsData: {
                UserModal: {
                    state: { email, selectedItem, selectedDateStart, selectedDateEnd },
                },
            },
            showSnackBar,
        } = this.props;

        const updated = await this.props.updateUser({
            userId: user.id,
            //name: userName,
            email,
            role: selectedItem,
            startDate: `${moment(selectedDateStart).format('YYYY-MM-DD')}T00:00:00Z`,
            endDate: `${moment(selectedDateEnd).format('YYYY-MM-DD')}T00:00:00Z`,
            isUserInactive: user.deleted,
        });

        if (checkKeyInObject(updated, 'type') && updated.type === 'UPDATE_USER_SUCCESS') {
            showSnackBar('User updated successfully', '', true);
            this.fetchUsers();
        } else {
            showSnackBar('Failed to update user');
        }
        this.closeModal();
    };

    tableBodyRender = () => {
        const {
            classes,
            currentUser,
            query = '',
            /* userGroups: { Groups }, */ headers,
            toggleModal,
            sideBarCount,
            isLoading,
            loadingComponent,
        } = this.props;
        const { order, orderBy, rowsPerPage, page, selectedUsers, users } = this.state;

        let _users = users;
        if (query && checkArrayLength(_users)) {
            _users = _users.filter((user) => checkKeyInObject(user, 'name') && user.name.toLowerCase().includes(query.toLowerCase()));
        }
        const currentPageUsers = sort(
            _users,
            order,
            orderBy,
            orderBy === 'lastLogin' || orderBy === 'startDate' || orderBy === 'endDate' ? 'time' : '',
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        // user object to hold ids of selected user
        const selectedCount = Object.keys(selectedUsers).length;

        let _selected = 0;
        let _lessAppUser = 0;
        for (let i = 0; i < _users.length; i++) {
            const user = _users[i];
            if (!(user.name !== USER_NAME && !user.deleted && !user.deletedPermanently && `${user.id}` !== `${currentUser.userId}`)) {
                _lessAppUser++;
            } else if (selectedUsers[user.id]) {
                _selected++;
            }
        }
        // const isDisabledUserPermission = this.isDisabledUserPermission()
        return (
            <div className={classes.root}>
                {selectedCount > 0 ? (
                    <div className={classes.selectionDisplayRow}>
                        <div className={classes.flexCenter}>
                            <Avatar className={classes.avatar}>{selectedCount}</Avatar>
                            <Typography className={classes.selectedText}>
                                {selectedCount === 1 ? 'User is Selected' : 'Users are Selected'}
                            </Typography>
                            <Close
                                className={classes.multiCloseIcon}
                                onClick={() => {
                                    this.toggleSelectAllUsers(users, false);
                                }}
                                aria-label="closeIcon"
                                id="closeIcon"
                            />
                        </div>
                        <span className={classes.selectionActionContainer}>
                            {/* <Tooltip data={isDisabledUserPermission ? null : "Add Permissions"}>
                                <IconButton
                                    className={`${classes.iconBtn}`}
                                    onClick={this.userPermission}
                                    disabled={isDisabledUserPermission}
                                >
                                    <VerifiedUser
                                        className={`${classes.editIcon} ${isDisabledUserPermission ? classes.disableCursor : classes.pointer}`}
                                        aria-label="verifiedUserIcon"
                                        id="verifiedUserIcon"
                                    />
                                </IconButton>
                            </Tooltip> */}
                            {/* <Tooltip data={"Add to Groups"}>
                                <IconButton
                                    className={classes.iconBtn}
                                    onClick={() => this.addToGroup(Groups)}>
                                    <AccountPlus
                                        className={`${classes.editIcon} ${classes.pointer}`}

                                    />
                                </IconButton>
                            </Tooltip> */}
                            <Tooltip data="Delete Multi Users">
                                <IconButton className={classes.iconBtn} onClick={() => this.deleteMultipleUsers()}>
                                    <AccountMultipleMinus className={`${classes.editIcon} ${classes.pointer}`} />
                                </IconButton>
                            </Tooltip>
                        </span>
                    </div>
                ) : null}
                <div style={!_.isEmpty(selectedUsers) ? { minHeight: 'calc(100vh - 257px)' } : { minHeight: 'calc(100vh - 213px)' }}>
                    <div className={classes.body}>
                        {checkArrayLength(_users) ? (
                            <Table>
                                <Hidden only={['xs']}>
                                    <EnhancedTableHead
                                        headers={headers}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        onSelectAllClick={() => {
                                            this.toggleSelectAllUsers(_users, _selected !== _users.length - _lessAppUser);
                                        }}
                                        showSelectBtn
                                        currentPageData={currentPageUsers}
                                        numSelected={_selected}
                                        rowCount={_users.length - _lessAppUser}
                                        colsWidth={this.colsWidth}
                                        resizeable
                                    />
                                </Hidden>
                                <Hidden smUp>
                                    <EnhancedTableHead
                                        headers={headers}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        onSelectAllClick={() => {
                                            this.toggleSelectAllUsers(_users, _selected !== _users.length - _lessAppUser);
                                        }}
                                        showSelectBtn
                                        currentPageData={currentPageUsers}
                                        numSelected={_selected}
                                        rowCount={_users.length - _lessAppUser}
                                        colsWidth={this.colsWidth}
                                    />
                                </Hidden>
                                <TableBody style={{ maxHeight: `calc(100vh - ${(selectedCount > 0 ? 45 : 0) + 259}px)` }}>
                                    {currentPageUsers.map((user) => {
                                        let elem_ind = 0;
                                        const isDeleted = checkKeyInObject(user, 'deleted', 'value', false);
                                        const conditionalBackgroundColor = {
                                            backgroundColor: isDeleted
                                                ? 'rgb(186, 196, 206)'
                                                : user.role === 'AIQSA'
                                                ? '#A2D2F2'
                                                : user.role === 'ADMIN'
                                                ? '#F3E0BC'
                                                : user.role === 'SDET'
                                                ? '#92EABD'
                                                : '',
                                        };
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={user.id}
                                                className={classes.tableRows}
                                                style={{ height: '40px', backgroundColor: TABLES_CSS.rowColor }}
                                            >
                                                <TableCell
                                                    style={{ paddingLeft: '0px !important' }}
                                                    className={[classes.cellWithCheckBox, classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[elem_ind].width}
                                                    data-resize={`element${elem_ind++}_c`}
                                                >
                                                    <div className={classes.width100per}>
                                                        <div className={classes.flexCenter}>
                                                            <div className={classes.wrapperAccountNameItems}>
                                                                <span className={classes.wrapperAvatarUsername}>
                                                                    {user.name !== USER_NAME &&
                                                                    !user.deleted &&
                                                                    !user.deletedPermanently &&
                                                                    `${user.id}` !== `${currentUser.userId}` ? (
                                                                        <IconButton
                                                                            className={classes.checkBox}
                                                                            onClick={() => {
                                                                                this.handelSelectUser(user.id);
                                                                            }}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            style={{ marginRight: '5px' }}
                                                                            tabIndex={-1}
                                                                        >
                                                                            {selectedUsers[user.id] ? (
                                                                                <CheckBox
                                                                                    className={classes.checkBoxIcon}
                                                                                    aria-label="checkBoxIcon"
                                                                                    id="checkBoxIcon"
                                                                                />
                                                                            ) : (
                                                                                <CheckBoxBlank
                                                                                    className={classes.checkBoxIcon}
                                                                                    style={{ color: '#bac5d0' }}
                                                                                    aria-label="checkBoxBlankIcon"
                                                                                    id="checkBoxblankIcon"
                                                                                />
                                                                            )}
                                                                        </IconButton>
                                                                    ) : (
                                                                        <span style={{ marginRight: '5px' }} className={classes.checkBox}></span>
                                                                    )}
                                                                    <Avatar
                                                                        className={`${classes.avatar} ${
                                                                            isDeleted ? classes.disabledBackgroundColor : ''
                                                                        }`}
                                                                    >
                                                                        {user && user.name && user.name.length ? user.name[0].toUpperCase() : ''}
                                                                    </Avatar>
                                                                    <Typography
                                                                        className={`${classes.username} ${classes.noWrap} ${
                                                                            isDeleted ? classes.disabledColor : ''
                                                                        }`}
                                                                    >
                                                                        <Tooltip data={user.name ? user.name : null}>{user.name}</Tooltip>
                                                                    </Typography>
                                                                </span>
                                                                {user.deleted === false && (
                                                                    <span className={`${classes.activeStatus} ${classes.userActiveColor}`}>
                                                                        Active
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[elem_ind].width}
                                                    data-resize={`element${elem_ind++}_c`}
                                                >
                                                    <div className={classes.width100per}>
                                                        <Typography
                                                            className={`${classes.dateTypography} ${classes.noWrap} ${
                                                                isDeleted ? classes.disabledColor : ''
                                                            }`}
                                                        >
                                                            <Tooltip data={user.email ? user.email : null}>{user.email}</Tooltip>
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[elem_ind].width}
                                                    data-resize={`element${elem_ind++}_c`}
                                                >
                                                    <div className={classes.width100per}>
                                                        <Typography
                                                            className={`${classes.dateTypography} ${classes.noWrap} ${
                                                                isDeleted ? classes.disabledColor : ''
                                                            }`}
                                                        >
                                                            {getFormatedDate(moment(user.startDate).toISOString(), 'MMM D, YYYY', '-')}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[elem_ind].width}
                                                    data-resize={`element${elem_ind++}_c`}
                                                >
                                                    <div className={classes.width100per}>
                                                        <Typography
                                                            className={`${classes.dateTypography} ${classes.noWrap} ${
                                                                isDeleted ? classes.disabledColor : ''
                                                            }`}
                                                        >
                                                            {getFormatedDate(moment(user.endDate).toISOString(), 'MMM D, YYYY', '-')}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[elem_ind].width}
                                                    data-resize={`element${elem_ind++}_c`}
                                                >
                                                    <div className={classes.width100per}>
                                                        <Typography
                                                            className={`${classes.dateTypography} ${classes.noWrap} ${
                                                                isDeleted ? classes.disabledColor : ''
                                                            }`}
                                                        >
                                                            {user.lastLogin
                                                                ? checkKeyInObject(user, 'isRelativeDateFormat')
                                                                    ? getRelativeTime(user.lastLogin, '-')
                                                                    : getFormatedDate(user.lastLogin, 'MMM D, YYYY, h:mm a', '-')
                                                                : '-'}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[elem_ind].width}
                                                    data-resize={`element${elem_ind++}_c`}
                                                >
                                                    <div className={classes.width100per}>
                                                        <div className={classes.flexCenter}>
                                                            <span
                                                                className={`${classes.activeStatus} ${classes.userRole} ${
                                                                    isDeleted ? classes.changedColor : ''
                                                                }`}
                                                                style={conditionalBackgroundColor}
                                                            >
                                                                {user.role}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[elem_ind].width}
                                                    data-resize={`element${elem_ind++}_c`}
                                                >
                                                    <div
                                                        className={`${classes.username} ${classes.noWrap} ${classes.width100per} ${
                                                            isDeleted ? classes.disabledColor : ''
                                                        }`}
                                                    >
                                                        {this.getAccountNameById(user.accountId)}
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[elem_ind].width}
                                                    data-resize={`element${elem_ind}_c`}
                                                >
                                                    <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                        {isDeleted ? (
                                                            <Tooltip data="Enable">
                                                                <IconButton
                                                                    className={classes.iconBtn}
                                                                    onClick={() => {
                                                                        this.disableUser(user, 0);
                                                                    }}
                                                                >
                                                                    <EnableIcon
                                                                        className={classes.enableIcon}
                                                                        aria-label="enableIcon"
                                                                        id="enableIcon"
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : (
                                                            user.name !== USER_NAME && (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Tooltip data="Restore">
                                                                        <IconButton
                                                                            className={classes.iconBtn}
                                                                            disabled={selectedUsers[user.id]}
                                                                            onClick={() =>
                                                                                toggleModal('adminUserModal', null, '', { modaltype: 'Reset', user })
                                                                            }
                                                                        >
                                                                            <Restore
                                                                                className={classes.restoreIcon}
                                                                                style={selectedUsers[user.id] ? { color: '#BAC4CE' } : {}}
                                                                                aria-label="restoreIcon"
                                                                                id="restoreIcon"
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip data="Update">
                                                                        <IconButton
                                                                            className={classes.iconBtn}
                                                                            disabled={selectedUsers[user.id]}
                                                                            onClick={() => {
                                                                                const { updateTabData } = this.props;
                                                                                const modalData = { user };
                                                                                const items = [{ value: 'SDET' }, { value: 'ADMIN' }];
                                                                                this.props.toggleModal('GeneralModal', null, null, {
                                                                                    title: 'Update User',
                                                                                    closeIconAction: () => {
                                                                                        this.closeModal();
                                                                                    },
                                                                                    component: [
                                                                                        {
                                                                                            content: (
                                                                                                <UserModal
                                                                                                    convertToSelectValues={this.convertToSelectValues}
                                                                                                    items={items}
                                                                                                    modalFor="UpdateUser"
                                                                                                    modalData={modalData}
                                                                                                />
                                                                                            ),
                                                                                            buttons: [
                                                                                                NEXT_SUBMIT_BUTTON({
                                                                                                    name: 'Update',
                                                                                                    action: () => {
                                                                                                        updateTabData('isDisabledSubmitButton', true);
                                                                                                        this.updateUser(user);
                                                                                                    },
                                                                                                    isDisabled: (tabsData) => {
                                                                                                        if (
                                                                                                            checkKeyInObject(
                                                                                                                tabsData,
                                                                                                                'UserModal.state',
                                                                                                            )
                                                                                                        ) {
                                                                                                            const {
                                                                                                                UserModal: {
                                                                                                                    state: {
                                                                                                                        email,
                                                                                                                        selectedItem,
                                                                                                                        userName,
                                                                                                                        isDuplicateEmail,
                                                                                                                        selectedDateEnd,
                                                                                                                        selectedDateStart,
                                                                                                                        isPastedDateSame
                                                                                                                    },
                                                                                                                },
                                                                                                            } = tabsData;
                                                                                                            return !(
                                                                                                                email.length &&
                                                                                                                isEmail.test(email) &&
                                                                                                                userName.length &&
                                                                                                                selectedItem.length &&
                                                                                                                !tabsData.isDisabledSubmitButton &&
                                                                                                                !isDuplicateEmail &&
                                                                                                                !isPastedDateSame &&
                                                                                                                new Date(selectedDateStart) <
                                                                                                                    new Date(selectedDateEnd)
                                                                                                            );
                                                                                                        }
                                                                                                        return false;
                                                                                                    },
                                                                                                }),
                                                                                                CANCEL_BUTTON({ action: this.closeModal }),
                                                                                            ],
                                                                                        },
                                                                                    ],
                                                                                });
                                                                            }}
                                                                        >
                                                                            <Edit
                                                                                aria-label="editScheduleIcon"
                                                                                id="editScheduleIcon"
                                                                                className={classes.moreIcons}
                                                                                style={selectedUsers[user.id] ? { color: '#BAC4CE' } : {}}
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    {`${user.id}` !== `${currentUser.userId}` ? (
                                                                        <MenuOptions
                                                                            items={this.renderItems(user /* , ind */)}
                                                                            keepMounted
                                                                            containerStyle={{ height: 'auto', width: 'auto' }}
                                                                            iconButtonDisabled={selectedUsers[user.id]}
                                                                            MoreVertStyle={selectedUsers[user.id] ? { color: '#BAC4CE' } : {}}
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        ) : isLoading ? (
                            loadingComponent
                        ) : (
                            <Typography className={classes.noDataText} variant="subtitle1">
                                No User available
                            </Typography>
                        )}
                    </div>
                </div>
                <TableFooter
                    count={_users.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    applyCustomClasses
                    labelRowsPerPage="Users per page:"
                    labelForCounter="Users"
                    countAvatar={getCountAvatar(sideBarCount.num_of_users)}
                    containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                />
            </div>
        );
    };

    renderItems = (user /* , ind */) => {
        const { classes /* , userGroups: { Groups } */ } = this.props;
        const status = user && user.deleted ? 0 : 1;
        const items = [];
        // items.push({
        //     action: () => {
        //         this.addToGroup(Groups, user);
        //     },
        //     icon: <AccountPlus className={classes.moreIcons} />,
        //     isDisabled: false,
        //     name: ' Add to Groups',
        // })
        // {
        //     action: () => {
        //         this.userPermission(user, ind);
        //     },
        //     icon: <Restore className={classes.moreIcons} aria-label="restoreIcon" id="restoreIcon"/>,
        //     isDisabled: user.role == 'ADMIN',
        //     name: ' Projects',
        // },
        items.push({
            action: () => {
                this.deleteUser(user);
            },
            icon: (
                <FontAwesomeIcon
                    style={{ fontSize: THREE_DOT_MENU_CSS.faIconFontSize, paddingLeft: 4, paddingRight: 2 }}
                    className={classes.moreIcons}
                    icon={faTrashAlt}
                    aria-label="faTrashAlt"
                    id="faTrashAlt"
                />
            ),
            isDisabled: false,
            name: ' Delete User',
        });
        items.push({
            action: () => {
                this.disableUser(user, status);
            },
            icon: <Disable className={`${classes.moreIcons} ${classes.disableIcon}`} />,
            isDisabled: false,
            name: user.deleted ? 'Enable User' : 'Disable User',
        });
        return items;
    };

    render() {
        return <div>{this.tableBodyRender()}</div>;
    }
}
const mapStateToProps = (state) => {
    return {
        // auth reducer
        currentUser: state.authReducer.user,

        // user Reducer
        users: state.userReducer.users,
        userGroups: state.userReducer.userGroups,
        headers: state.userReducer.userTableArrangment,
        // general reducer
        query: state.generalReducer.queryValue,
        // Modal Reducer
        tabsData: state.generalModalReducer.tabsData,
        // Project Reducer
        projects: state.projectReducer.projectsByIdAndName,
        // Sidebar Reduce
        sideBarCount: state.sidebarReducer.sideBarCount,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // User Action
        getUsers: () => dispatch(UserActions.getUsers()),
        getGroups: (accountId) => dispatch(UserActions.getGroups(accountId)),
        getUserByAccountId: (accountId) => dispatch(UserActions.getUserByAccountId(accountId)),

        // Modal Action
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        clearSnackBar: () => dispatch(ModalActions.clearSnackbar()),
        // project Action
        getProjects: () => dispatch(ProjectActions.getProjectByIdAndName()),
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
        clearGeneralModal: (...args) => dispatch(generalModalActions.clearGeneralModal(...args)),
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
        createUser: (data) => dispatch(UserActions.createUser(data)),
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        updateUser: (data) => dispatch(UserActions.updateUser(data)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserList));
