// import materials
import { withStyles } from '@material-ui/core/styles';
// import packages
import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Hidden } from '@material-ui/core';
// Custom components
import { Download, Signin } from '../components/index';
import AuthMiddleWare from '../store/middleware/authMiddleware';
import AppBar from '../features/layouts/app/AppBar';
import AccountList from '../features/admin/account/AccountList';
import AdminConsoleView from '../features/admin/console/AdminConsole';
import Confirm from '../features/confirm';
import Callback from '../components/auth/callback';
import ForgotPassword from '../features/forgotPassword';
// import GroupList from '../features/admin/groups/GroupList';
import Preferences from '../features/admin/preferences/index';
import ProjectDetails from '../features/projectDetails/ProjectDetails';
import ProjectsGridList from '../components/dashboard/ProjectsGridList';
import SelfUserRegistration from '../features/userRegistration';
import SideBar from '../features/layouts/app/SideBar';
import StickyHeaderBar from '../components/Header/StickyHeaderBar';
import SuiteReport from '../features/executions/SuiteReport';
import SuiteReportDetail from '../features/executions/SuiteReportDetail';
import ResetPassword from '../features/resetPassword';
import TestCase from '../features/plan/case/TestCase';
import Datasheet from '../features/plan/data';
import TestSteps from '../features/projectDetails/TestSteps/TestSteps';
import Executions from '../features/projectDetails/Executions/Executions';
import TestSuite from '../features/plan/suite/TestSuite';
import ScheduledSuites from '../features/execute/schedule/scheduledSuites';
import { DashboardActions, ProjectActions, GeneralActions, ModalActions } from '../store/actions';
import SetCookies from '../components/SetCookies';
import AdminProfile from '../components/AdminProfile';

import SwipableSideBar from '../features/layouts/app/SwipableSideBar';
import SwipableTaskPanel from '../features/Tasks/SwipableTaskPanel';
import { initializeGA } from '../utils/utils';
import { USER_NAME } from '../common/constants';
import {page} from '../services/Segment';

// Calculate screen with on runtime
const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;

// Set drawer width according to screen size
const drawerWidth = 55;
const taskPanelWidth = 350;

// styles
const styles = () => ({
    container: {
        // marginLeft: drawerWidth,
    }, mainContainer: {
        width: 'calc(100% - 394px)'
    },
    menuButton: {
        color: 'white',
        fontSize: 33,
    },
    bars: {
        color: '#ffffff',
        fontSize: '20px',
    },
    navigations: {
        color: '#fff',
    },
    arrowIcon: {
        color: '#fff',
    },
});
// Just used to forcefully dive in will recieve props
let forcerUpdateSideBarOnBackButton = false;
let forceUpdateBackButton = false;
class ParentApp extends React.Component {
    constructor() {
        super();
        this.state = {
            isAuthenticated: { user: false },
            getDirectRoute: '',
        };
        this.isLogoutSameTab = false;
    }

    componentDidMount() {
        this.props.detectCookies();
        initializeGA('UA-145932231-1');
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location) => {
           
            // the signin route getting captured every time dashboard get refreshed
            // for signin segment page we are doing it in signin component sepratly
        
            if(location.pathname!=='/signin') {
                this.onRouteChanged();
            }

        });
    }

    componentWillUnmount() {
        this.unlisten();
    }
    


    onRouteChanged() {
        page();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!window.location.pathname.includes('/download')) {
            const { location, toggleModal } = nextProps;
            const { location: old_location } = this.props;
            const preRoute = old_location?.pathname !== location?.pathname;
            if (preRoute) {
                if (!old_location.pathname.includes('dashboard') && !this.isLogoutSameTab) {
                    this.setState({ getDirectRoute: old_location.pathname });
                } else if (this.state.getDirectRoute.includes('signin') || old_location.pathname.includes('dashboard') || this.isLogoutSameTab) {
                    this.setState({ getDirectRoute: '' });
                }
                toggleModal(); // this is to close the modal when we use back/forward on browser.
            }
            const { user } = nextProps;
            const { isAuthenticated } = this.state;
            if (!isAuthenticated.user && ((user && user.remember) || user.role)) {
                this.isLogoutSameTab = false;
                this.setState({
                    isAuthenticated: { user: true },
                });
            } else if (isAuthenticated.user && !((user && user.remember) || user.role)) {
                this.setState({
                    isAuthenticated: { user: false },
                });
            }
            forcerUpdateSideBarOnBackButton = !forcerUpdateSideBarOnBackButton;
        }
    }

    update_ForceUpdateBackButtonFlag = (val) => {
        forceUpdateBackButton = val;
    };

    logoutSameTab = () => {
        this.isLogoutSameTab = true;
    };

    render() {
        const { classes, user, openTaskPanel, history , isSidebarCountLoading } = this.props;
        const url = window.location.href;
        const { pathname } = history.location;
        let isOnDashboard = false;
        let isOnDownload = false;
        const showRightPanel = !url.includes('admin') && !url.includes('setting') && !url.includes('/analyze');
        const { isAuthenticated, getDirectRoute } = this.state;
        if (pathname === '/signin') {
            forceUpdateBackButton = true;
        } else if (pathname.includes('/projects')) {
            isOnDashboard = true;
        } else if (pathname.includes('/download')) {
            isOnDownload = true;
        }

        let AdminFlag = false; // 'false' is indicate user has SDET role
        let AppuserFlag = false; // 'false' is indicate user is not an appuser
        if (user && user.role === 'ADMIN') {
            // change the flag value if user has ADMIN role
            AdminFlag = true;
            if (user.name === USER_NAME) {
                AppuserFlag = true;
            }
        }

        const midContent = (
            <div>
                {/* private Routing starts */}
                <Switch>
                    <Route
                        exact
                        path="/oauth2callback"
                        render={(props) => (isAuthenticated.user ? <Redirect to="/projects" /> : <Callback {...props} />)}
                    />
                    {/* <Route
                        exact
                        path="/dashboard"
                        render={(props) => (isAuthenticated.user ? <Dashboard {...props} /> : <Redirect to="/signin" />)}
                    /> */}
                    <Route
                        exact
                        path="/profile"
                        render={(props) =>
                            isAuthenticated.user && !AdminFlag ? (
                                <AdminProfile {...props} />
                            ) : isAuthenticated.user ? (
                                <Redirect to="/projects" />
                            ) : (
                                <Redirect to="/signin" />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/admin"
                        render={(props) =>
                            isAuthenticated.user && AdminFlag ? (
                                <AdminConsoleView {...props} />
                            ) : isAuthenticated.user ? (
                                <Redirect to="/projects" />
                            ) : (
                                <Redirect to="/signin" />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/admin/profile"
                        render={(props) =>
                            isAuthenticated.user && AdminFlag ? (
                                <AdminProfile {...props} />
                            ) : isAuthenticated.user ? (
                                <Redirect to="/projects" />
                            ) : (
                                <Redirect to="/signin" />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/admin/users"
                        render={(props) =>
                            isAuthenticated.user && AdminFlag ? (
                                <AccountList key="user" {...props} />
                            ) : isAuthenticated.user ? (
                                <Redirect to="/projects" />
                            ) : (
                                <Redirect to="/signin" />
                            )
                        }
                    />
                    {/* <Route exact path="/admin/groups" render={(props) => isAuthenticated.user && AdminFlag ? <AccountList key={"group"} {...props} /> : isAuthenticated.user ? <Redirect to="/dashboard" /> : <Redirect to="/signin" />} /> */}
                    <Route
                        exact
                        path="/admin/accounts"
                        render={(props) =>
                            isAuthenticated.user && AppuserFlag ? (
                                <AccountList key="account" {...props} />
                            ) : isAuthenticated.user ? (
                                <Redirect to="/projects" />
                            ) : (
                                <Redirect to="/signin" />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/admin/preferences"
                        render={(props) =>
                            isAuthenticated.user && AdminFlag ? (
                                <Preferences {...props} />
                            ) : isAuthenticated.user ? (
                                <Redirect to="/projects" />
                            ) : (
                                <Redirect to="/signin" />
                            )
                        }
                    />
                    
                    <Route
                        exact
                        path="/reports"
                        render={(props) => (isAuthenticated.user ? <SuiteReport {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/schedule"
                        render={(props) => (isAuthenticated.user ? <ScheduledSuites {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/runs"
                        render={(props) => (isAuthenticated.user ? <TestSuite {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/reports/suite"
                        render={(props) => (isAuthenticated.user ? <SuiteReportDetail {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/reports/suite/:suiteId"
                        render={(props) => (isAuthenticated.user ? <SuiteReportDetail {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/testcases"
                        render={(props) => (isAuthenticated.user ? <TestCase {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/testsuites"
                        render={(props) => (isAuthenticated.user ? <TestSuite {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route exact path="/download" render={(props) => <Download {...props} />} />
                    <Route
                        exact
                        path="/projects"
                        render={(props) =>
                            isAuthenticated.user ? (
                                <ProjectsGridList
                                    {...props}
                                    drawerWidth={drawerWidth}
                                    screenWidth={screenWidth}
                                    taskPanelWidth={taskPanelWidth}
                                    openTaskPanel={openTaskPanel}
                                />
                            ) : (
                                <Redirect to="/signin" />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/projects/disabled"
                        render={(props) =>
                            isAuthenticated.user ? (
                                <ProjectsGridList
                                    {...props}
                                    drawerWidth={drawerWidth}
                                    screenWidth={screenWidth}
                                    taskPanelWidth={taskPanelWidth}
                                    openTaskPanel={openTaskPanel}
                                    projectsType="disabled"
                                />
                            ) : (
                                <Redirect to="/signin" />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/projects/starred"
                        render={(props) =>
                            isAuthenticated.user ? (
                                <ProjectsGridList
                                    {...props}
                                    drawerWidth={drawerWidth}
                                    screenWidth={screenWidth}
                                    taskPanelWidth={taskPanelWidth}
                                    openTaskPanel={openTaskPanel}
                                    projectsType="starred"
                                />
                            ) : (
                                <Redirect to="/signin" />
                            )
                        }
                    />
                    {/* <Route
                        exact
                        path="/projects"
                        render={() => (isAuthenticated.user ? <Redirect to="/projects" /> : <Redirect to="/signin" />)}
                    /> */}
                    <Route
                        exact
                        path="/projects/:projectId"
                        render={(props) => (isAuthenticated.user ? <ProjectDetails {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/projects/:projectId/cases"
                        render={(props) => (isAuthenticated.user ? <ProjectDetails {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/projects/:projectId/suites"
                        render={(props) => (isAuthenticated.user ? <ProjectDetails {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/projects/:projectId/suites/:modalObjectId/:modalName"
                        render={(props) => (isAuthenticated.user ? <ProjectDetails {...props} /> : <Redirect to="/signin" />)}
                    />
                     <Route
                        exact
                        path="/projects/:projectId/data/datasheet/:testDataId"
                        render={(props) => (isAuthenticated.user ? <Datasheet {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/projects/:projectId/data/:modalObjectId/:modalName"
                        render={(props) => (isAuthenticated.user ? <ProjectDetails {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/projects/:projectId/cases/:modalObjectId/:modalName"
                        render={(props) => (isAuthenticated.user ? <ProjectDetails {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/projects/:projectId/:testCaseId/executions/:executionId"
                        render={(props) => (isAuthenticated.user ? <Executions {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/projects/:projectId/:testCaseId/:modalObjectId/:modalName"
                        render={(props) => (isAuthenticated.user ?  <TestSteps {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/projects/:projectId/data"
                        render={(props) => (isAuthenticated.user ? <ProjectDetails {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/projects/:projectId/scripts"
                        render={(props) => (isAuthenticated.user ? <ProjectDetails {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/projects/:projectId/variables"
                        render={(props) => (isAuthenticated.user ? <ProjectDetails {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/projects/:projectId/flows"
                        render={(props) => (isAuthenticated.user ? <ProjectDetails {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route
                        exact
                        path="/projects/:projectId/:testCaseId"
                        render={(props) => (isAuthenticated.user ? <TestSteps {...props} /> : <Redirect to="/signin" />)}
                    />
                    <Route exact path="/signin" render={() => (getDirectRoute ? <Redirect to={getDirectRoute} /> : <Redirect to="/projects" />)} />

                    {/* For rerouting if try to access undefined routes */}
                    <Route render={() => <Redirect to="/projects" />} />
                </Switch>
                {/* private Routing ends */}
            </div>
        );
        const locate = (props, Component) => {
            if (isAuthenticated.user) {
                if (getDirectRoute) {
                    return <Redirect to={getDirectRoute} />;
                }
                return <Redirect to="/projects" />;
            }
            return <Component {...props} />;
        };
        return (
            <div style={{ backgroundColor: '#ececec' }}>
                <div>
                    {isAuthenticated.user && !isOnDownload ? (
                        <div>
                            <Hidden only={['xs']}>
                                <AppBar
                                    saveProject={/* this.saveProject */ () => {}}
                                    handleNotificationOpen={/* handleNotificationOpen */ false}
                                    notifications={/* notification */ {}}
                                    showRightPanel={showRightPanel}
                                    logoutSameTab={this.logoutSameTab}
                                />
                            </Hidden>
                            <div style={{ display: 'flex' }}>
                                <Hidden only={['xs']}>
                                    <SideBar history={history} isUpdate={forcerUpdateSideBarOnBackButton} />
                                </Hidden>
                                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                    <SwipableSideBar
                                        toggleSwipableDrawer={this.props.toggleSwipableDrawer}
                                        openNav={this.props.openNav}
                                        history={history}
                                        isUpdate={forcerUpdateSideBarOnBackButton}
                                    />
                                </Hidden>
                                <Hidden only={['xs']}>
                                    <div
                                        className={openTaskPanel ? classes.mainContainer : classes.container}
                                        style={{
                                            marginLeft: drawerWidth,
                                            width:
                                                openTaskPanel &&
                                                pathname !== '/admin' &&
                                                pathname !== '/admin/profile' &&
                                                pathname !== '/admin/users' &&
                                                pathname !== '/admin/groups' &&
                                                pathname !== '/admin/accounts' &&
                                                pathname !== '/admin/preferences'
                                                    ? `calc(100vw - ${1 + taskPanelWidth + drawerWidth}px)`
                                                    : 'calc(100% - 55px)',
                                        }}
                                    >
                                        <StickyHeaderBar
                                            url={url}
                                            width="100%"
                                            history={history}
                                            forceUpdateBackButton={forceUpdateBackButton}
                                            update_ForceUpdateBackButtonFlag={this.update_ForceUpdateBackButtonFlag}
                                            toggleDashboardSettingGear={this.props.toggleDashboardSettingGear}
                                            showRightPanel={showRightPanel}
                                            toggleSwipableTaskPanel={this.props.toggleSwipableTaskPanel}
                                            openTaskPanel={this.props.openTaskPanel}
                                            isOnDashboard={false}
                                            isSidebarCountLoading={isSidebarCountLoading}
                                        />
                                        <div>{midContent}</div>
                                    </div>
                                </Hidden>
                                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                    <div
                                        className={classes.container}
                                        style={{ width: showRightPanel ? 'calc(100vw - 100px)' : 'calc(100vw - 50px)' }}
                                    >
                                        <div style={{ height: 46 }}>
                                            <AppBar requiredFor="xs" logoutSameTab={this.logoutSameTab} />
                                        </div>
                                        <div style={{ marginLeft: '-50px', width: '100vw' }}>{midContent}</div>
                                    </div>
                                </Hidden>
                                {/* <Hidden only={['xs']} >
                                    {showRightPanel &&
                                        <section className={classes.taskSection}>
                                            <Tasks />
                                        </section>
                                    }
                                </Hidden> */}
                                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                    {showRightPanel && (
                                        <SwipableTaskPanel
                                            toggleSwipableTaskPanel={this.props.toggleSwipableTaskPanel}
                                            openTaskPanel={this.props.openTaskPanel}
                                        />
                                    )}
                                </Hidden>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {/* Public Routing starts */}
                            <Switch>
                                <Route exact path="/oauth2callback" render={(props) => <Callback {...props} />} />
                                <Route exact path="/download" render={(props) => <Download {...props} />} />
                                <Route exact path="/signin" render={(props) => locate(props, Signin)} />
                                <Route exact path="/setCookie" render={(props) => <SetCookies {...props} />} />
                                <Route exact path="/register" render={(props) => locate(props, SelfUserRegistration)} />
                                <Route exact path="/forgotPassword" render={(props) => locate(props, ForgotPassword)} />
                                <Route exact path="/verifytoken" render={(props) => locate(props, ResetPassword)} />
                                <Route exact path="/confirm" render={(props) => locate(props, Confirm)} />
                                {/* For rerouting if try to access undefined routes */}
                                <Route render={() => <Redirect to="/signin" />} />
                            </Switch>
                            {/* Public Routing ends */}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const AppRoutes = (props) => {


    return (
        <BrowserRouter>
            <Route component={connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ParentApp))} {...props} />
        </BrowserRouter>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        detectCookies: () => dispatch(AuthMiddleWare.checkAutCookieData()),
        toggleDashboardSettingGear: (...args) => dispatch(DashboardActions.toggleDashboardSettingGear(...args)),
        preRoute: (route) => dispatch(ProjectActions.preRoute(route)),
        toggleSwipableDrawer: (...args) => dispatch(GeneralActions.toggleSwipableDrawer(...args)),
        toggleSwipableTaskPanel: (...args) => dispatch(GeneralActions.toggleSwipableTaskPanel(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
    };
}
function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
        openNav: state.generalReducer.openNav,
        openTaskPanel: state.generalReducer.openTaskPanel,
        isSidebarCountLoading: state.sidebarReducer.isLoading,

    };
}

export default AppRoutes;
