import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import TableRow from '../../../components/Table/TableRow';
import TableCell from '../../../components/Table/TableCell';
import { EnhancedTableHead } from '../../../components/EnhancedTable';

// custom
import { TABLES_CSS } from '../../../common/cssConstants';
import Tooltip from '../../../components/Tooltip';
import SearchInput from './SearchInput';
import '../Stepper/uploadWizardScroll.css';

const styles = (theme) => ({
    checkBox: {
        height: 25,
        width: 25,
        marginRight: '5px',
    },
    checkBoxIcon: {
        color: '#0092e5',
        fontSize: '20px',
        marginLeft: '-4px',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '16.6%',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    cell_: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        paddingLeft: '10px !important',
        width: '100%',
        maxWidth: '100%',
        '&>:first-child': {
            paddingLeft: '10px !important',
            display: 'flex',
        },
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    checkBoxClass: {
        '&>span>svg': {
            fontSize: '20px',
        },
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#fafafa',
        },
    },
    tableBody: {
        maxHeight: 'calc(100vh - 390px)',
    },
    rootIconButton: {
        paddingLeft: '0px',
        width: '100%',
    },
    containerIconButton: {
        display: 'flex',
        alignItems: 'center',
    },
    testCaseNameText: {
        fontSize: TABLES_CSS.normalFontSize,
        display: 'inline-block',
    },
    rootFaFileExcelIcon: {
        width: '100%',
    },
    containerFaFileExcelIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    faFileExcelIconButton: {
        width: '30px',
        height: '30px',
        marginRight: '10px',
    },
    faFileExcel: {
        color: '#3B91DF',
        fontSize: 20,
    },
    dataAndSearchContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    dataNameTitle: {
        fontSize: 16,
        color: '#3B91DF',
        maxWidth: 270,
    },
    searchInput: {
        marginBottom: '10px',
        width: 230,
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },

    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    tableContainer: {
        width: 560,
        borderRadius: 5,
        overflow: 'hidden',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.2)',
        borderButtomRightRadius: '5px',
        borderButtomLeftRadius: '5px',
        marginBottom: '5px',
    },
});

const EnhanceTableTestCaseModal = (props) => {
    const {
        classes,
        headers,
        testCases,
        numSelected,
        handleToggle,
        onSelectAllClick,
        enhancedTableHeadStyle,
        previewTestCase,
        query,
        clearSearch,
        handleSearch,
        dataName,
    } = props;

    return (
        <div className={classes.root}>
            <div className={classes.dataAndSearchContainer}>
                <span className={`${classes.dataNameTitle} ${classes.noWrap}`}>
                    <Tooltip data={dataName}>{dataName}</Tooltip>
                </span>
                <SearchInput
                    id="AttachData"
                    placeholder="Search"
                    onChange={handleSearch}
                    value={query}
                    classStyle={classes.searchInput}
                    clearSearch={clearSearch}
                />
            </div>
            <div className={classes.tableContainer}>
                <Table>
                    <EnhancedTableHead
                        headers={headers}
                        onSelectAllClick={onSelectAllClick}
                        currentPageData={testCases}
                        numSelected={numSelected}
                        rowCount={testCases.length}
                        showSelectBtn
                        checkBoxClass={classes.checkBoxClass}
                        style={{ paddingRight: enhancedTableHeadStyle && 0 }}
                    />
                    <TableBody className={classes.tableBody}>
                        {testCases &&
                            testCases.map((testCase, index) => (
                                <TableRow
                                    style={{ height: '40px', cursor: 'default' }}
                                    tabIndex={-1}
                                    key={index}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    className={classes.row}
                                >
                                    <TableCell
                                        className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                        width={headers[0].width}
                                        data-resize={`element${0}_c`}
                                    >
                                        <div className={classes.rootIconButton}>
                                            <div className={classes.containerIconButton}>
                                                <IconButton
                                                    onClick={() => {
                                                        handleToggle(testCase.testCaseId, !testCase.checked);
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.checkBox}
                                                    tabIndex={-1}
                                                >
                                                    {testCase.checked ? (
                                                        <CheckBox
                                                            className={classes.checkBoxIcon}
                                                            style={testCase.newAssociation === true ? { color: '#36aebd' } : {}}
                                                            aria-label={`checkBoxIcon_EnhanceTableTestCaseModal_${index}`}
                                                            id={`checkBoxIcon_EnhanceTableTestCaseModal_${index}`}
                                                        />
                                                    ) : (
                                                        <CheckBoxBlank
                                                            className={classes.checkBoxIcon}
                                                            style={{ color: '#bac5d0' }}
                                                            aria-label={`checkBoxBlankIcon_EnhanceTableTestCaseModal_${index}`}
                                                            id={`checkBoxBlankIcon_EnhanceTableTestCaseModal_${index}`}
                                                        />
                                                    )}
                                                </IconButton>
                                                <Typography noWrap className={`${classes.noWrap} ${classes.testCaseNameText}`}>
                                                    <Tooltip data={testCase.testCaseName ? testCase.testCaseName : null}>
                                                        {testCase.testCaseName}
                                                    </Tooltip>
                                                </Typography>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell
                                        className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                        width={headers[1].width}
                                        data-resize={`element${1}_c`}
                                    >
                                        <div className={classes.rootFaFileExcelIcon}>
                                            <div className={classes.containerFaFileExcelIcon}>
                                                <IconButton
                                                    className={classes.faFileExcelIconButton}
                                                    id={`File_EnhanceTableTestCaseModal_${index}`}
                                                    aria-label={`File_EnhanceTableTestCaseModal_${index}`}
                                                    onClick={() => previewTestCase(testCase.testCaseId, testCase.testCaseName)}
                                                >
                                                    <FontAwesomeIcon
                                                        className={classes.faFileExcel}
                                                        icon={faFileExcel}
                                                        id={`faFileExcel_EnhanceTableTestCaseModal_${index}`}
                                                        aria-label={`faFileExcel_EnhanceTableTestCaseModal_${index}`}
                                                    />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default withStyles(styles)(EnhanceTableTestCaseModal);
