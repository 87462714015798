import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Close from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { HelpOutline } from '@material-ui/icons';
import { BLACK_FONT } from '../../../common/cssConstants';
import { checkKeyInObject, checkArrayLength, getTextProperties } from '../../../utils';
import Tooltip from '../../../components/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


const styles = () => ({
    itemDisable: {
        color: 'hsl(0,0%,60%)',
        padding: '8px 12px',
        textAlign: 'center',
        boxSizing: 'border-box',
        fontSize: 12,
        userSelect: 'none',
    },
    item: {
        backgroundColor: 'transparent',
        color: 'inherit',
        cursor: 'default',
        display: 'block',
        padding: '8px 12px',
        width: '100%',
        '-webkit-userSelect': 'none',
        '-moz-userSelect': 'none',
        '-ms-userSelect': 'none',
        userSelect: 'none',
        '-webkit-tap-highlightColor': 'rgba(0,0,0,0)',
        boxSizing: 'border-box',
        fontSize: 12,
        '&:hover': {
            backgroundColor: '#DEEBFF',
        },
        '&:active': {
            backgroundColor: '#B2D4FF',
        },
    },
    itemActive: {
        backgroundColor: '#B2D4FF',
    },
    border: {
        position: 'relative',
        '&::before': {
            left: 0,
            right: 0,
            bottom: 0,
            content: '"\\00a0"',
            position: 'absolute',
            transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderBottom: '1px solid #979797',
            pointerEvents: 'none',
        },
        '&:hover': {
            '&::before': {
                borderBottom: '2px solid #979797',
            },
        },
    },
    input: {
        boxSizing: 'content-box',
        background: '0px center',
        border: '0px',
        opacity: 1,
        outline: '0px',
        padding: '0px',
        fontSize: 12,
        height: 28,
        color: BLACK_FONT,
        width: '100%',
        cursor: 'default',
    },
    wrapper: {
        width: '100%',
        display: 'flex',
    },
    seprator: {
        alignSelf: 'stretch',
        backgroundColor: 'hsl(0,0%,80%)',
        width: 1,
        boxSizing: 'border-box',
    },
    icon: {
        height: 20,
        width: 20,
        color: '#4A4A4A',
        fontSize: 20,
    },
    clear: {
        color: 'hsl(0,0%,80%)',
        display: 'flex',
        padding: '2px 8px 2px 8px',
        transition: 'color 150ms',
        boxSizing: 'border-box',
        fontSize: 15,
        cursor: 'pointer',
        marginRight: 3,
        '&:hover': {
            color: 'hsl(0,0%,60%)',
        },
        '&:active': {
            color: 'hsl(0,0%,0%)',
        },
    },
    helpOutlineWrapper: {
        margin: '0px 5px -4px 0px',
    },
    helpOutline: {
        color: '#17a2b8',
        width: '20px',
    },
    errorItem: {
        fontSize: 12,
        textTransform: 'capitalize',
    },
    removeItem: {
        '-webkit-box-align': 'center',
        alignItems: 'center',
        borderRadius: 2,
        display: 'flex',
        paddingLeft: 4,
        paddingRight: 4,
        boxSizing: 'border-box',
        cursor: 'default',
        '&:hover': {
            backgroundColor: '#FFBDAD',
            color: '#DE350B',
        },
        '&>svg': {
            display: 'inline-block',
            color: '#000000',
            lineHeight: 1,
            strokeWidth: 0,
            height: 9,
            width: 9,
        },
    },
    itemCont: {
        backgroundColor: 'hsl(0,0%,90%)',
        borderRadius: 2,
        display: 'flex',
        margin: 2,
        minWidth: 0,
        boxSizing: 'border-box',
        cursor: 'default',
    },
    itemName: {
        borderRadius: 2,
        color: '#222',
        lineHeight: 'unset',
        overflow: 'hidden',
        alignItems: 'center',
        display: 'flex',
        padding: '4px 3px 2px 3px',
        paddingLeft: 6,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        cursor: 'default',
    },
    textCont: {
        width: '100%',
        boxSizing: 'border-box',
        display: '-webkit-inline-box',
        cursor: 'default',
    },
    valWrapper: {
        width: '100%',
        height: 28,
        overflow: 'hidden',
        cursor: 'default',
        marginLeft: 6,
        overflowX: 'auto',
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    noLeftPadding: {
        paddingLeft: '0px !important',
    },
    noSelect: {
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        userSelect: 'none',
    },
    optionsWrapper: {
        width: '100%',
        maxHeight: 200,
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    optionBody: {
        width: '100%',
        margin: 0,
        padding: 0,
        listStyle: 'none',
        paddingBottom: 4,
        paddingTop: 4,
    },
    select: {
        display: 'inline-flex',
        position: 'relative',
        flexDirection: 'column',
        '&>label': {
            lineHeight: 1,
            padding: 0,
            top: 0,
            left: 0,
            position: 'absolute',
            transformOrigin: 'top left',
            transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            transform: 'translate(0, 1.5px) scale(0.75)',
            '&+div': {
                marginTop: 16,
            },
        },
    },
    roundBorder: {
        border: '1px solid',
        borderColor: 'hsl(0,0%,80%)',
        borderRadius: '4px',
        padding: '3px 0',
    },
    parentSpan: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    parentCheckIconDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        minWidth: 'calc(100% - 20px)',
    },
    checkIcon: {
        fontSize: '16px',
        color: '#169AE8',
    },
    parentCloseIcon: {
        justifySelf: 'flex-end',
        width: 16,
        marginLeft: 4,
        cursor: 'pointer',
    },
    closeIcon: {
        fontSize: '16px',
        color: '#4A4A4A4A',
    },
    createLine: {
        borderBottom: '1px solid #ececec',
        margin: '0 10px',
    },
    inputDisabled: {
        pointerEvents: 'none',
        color: 'rgba(0, 0, 0, 0.38)',
    },
    errorSelect: {
        borderColor: '#ff000099',
        borderStyle: 'solid',
    },
    requiredSpan: {
        color: '#D0021B',
        marginLeft: 10,
    },
    displayNone: {
        display: 'none',
    },
});

class Select extends Component {
    state = {
        isFocus: false,
        val: '',
        selectedOpt: null,
        textInputRef: null,
        wrapperRef: null,
    };

    componentDidMount() {
        this.updateSelected(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateSelected(nextProps);
    }

    updatedSelectedValue() {
        const { isFocus, val } = this.state;
        const { options, value, isMultiSelect } = this.props;
        if (!isFocus) {
            if (isMultiSelect && val) {
                this.updateVal({
                    val: '',
                });
            } else {
                const selectedOpt = options.find((opt) => {
                    return this.checkValue(opt) && opt.value === value;
                });
                if (checkKeyInObject(selectedOpt, 'label') && selectedOpt.label !== val) {
                    this.updateVal({
                        selectedOpt,
                        val: checkKeyInObject(selectedOpt, 'label') ? selectedOpt.label : '',
                    });
                }
                if(!val && !isMultiSelect) {
                    this.updateVal({
                        selectedOpt:null,
                        val: '',
                    });

                      this.props.onChange({})
                }
            }
        }
    }
    shouldClose = true;

    checkValue = (obj) => {
        return checkKeyInObject(obj, 'value', 'value', undefined) !== undefined; // we have to allow 0
    };

    updateSelected = (props) => {
        const { options, value } = props;
        const selectedOpt = options.find((opt) => {
            return this.checkValue(opt) && opt.value === value;
        });
        this.setState({
            selectedOpt,
            val: checkKeyInObject(selectedOpt, 'label') ? selectedOpt.label : this.state.val,
        });
    };

    handlewrapperRef = (node) => {
        this.setState({ wrapperRef: node });
    };

    handletextInputRef = (node) => {
        this.setState({ textInputRef: node });
    };

    updateVal(obj) {
        this.setState(obj);
    }

    render() {
        const {
            className,
            options,
            placeholder,
            onChange,
            isMultiSelect,
            label,
            value,
            isBorder,
            isRoundBorder,
            classes,
            isShowSelectedInList,
            defaultItemValue,
            selectName,
            readOnly,
            startAdornment,
            styleWithIcon,
            isDisabled,
            isDisableSelect,
            isError,
            isMandatory,
            noClearIcon,
            isSearchForTaskpane,
            optionStyle,
            callingFrom
        } = this.props;
        const { selectedOpt, val, isFocus, wrapperRef, textInputRef } = this.state;
        let _options = JSON.parse(JSON.stringify(options));
        if (isMultiSelect && checkArrayLength(value) && checkArrayLength(_options)) {
            const __options = [];
            _options.forEach((opt) => {
                if (!value.find((v) => v.value === opt.value)) {
                    __options.push(opt);
                }
            });
            _options = __options;
        }

        const isSelectDropdown = !isDisableSelect ? 'flex' : 'none';
        if (isFocus && checkArrayLength(_options) && !readOnly) {
            _options = _options.filter((opt) => checkKeyInObject(opt, 'label') && opt.label.toLowerCase().includes(val.toLowerCase().trim()));
        }
        const __width = checkKeyInObject(wrapperRef, 'offsetWidth', 'value', 300, true);

        const defaultItem = _options.find((opt) => opt.value === defaultItemValue);
        _options = _options.filter((opt) => opt.value !== defaultItemValue);

        const isShowDefaultValue =
            !checkArrayLength(value) &&
            defaultItemValue !== undefined &&
            checkKeyInObject(
                options.find((opt) => opt.value === defaultItemValue),
                'label',
                'bool',
            );

        const errors = getTextProperties(val, ['empty string']);
        let distinctSearchedInput;
        if(isSearchForTaskpane){
            const _value = value.map((ele) => (ele.label))
            distinctSearchedInput = [...new Set(_value)];
        }

        return (
            <div
                className={`${className} ${classes.select} ${isRoundBorder ? classes.roundBorder : ''} ${
                    isDisabled || isDisableSelect ? classes.inputDisabled : ''
                }`}
            >
                {label ? (
                    // eslint-disable-next-line jsx-a11y/label-has-for
                    <label htmlFor="searchB">
                        {label}
                        <span className={`${isMandatory ? classes.requiredSpan : classes.displayNone}`}>*Mandatory</span>
                    </label>
                ) : null}
                <div className={`${isBorder ? classes.border : ''}`} style={{ width: '100%' }}>
                    <ClickAwayListener
                        onClickAway={() => isFocus && this.setState({ isFocus: false })}>
                        <div className={classes.wrapper} ref={this.handlewrapperRef}>
                            <Tooltip
                                isElement
                                isOnClick
                                forceClose={!isFocus}
                                toolTipAreaStyle={{ width: __width }}
                                closeOnSecondClick={false}
                                isPointerEvents={false}
                                position="bottom-start"
                                paperBodyStyle={{
                                    backgroundColor: 'hsl(0,0%,100%)',
                                    borderRadius: 4,
                                    overflow: 'hidden',
                                    boxShadow: '0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)',
                                    boxSizing: 'border-box',
                                    padding: 0,
                                    minWidth: __width,
                                    width: 'auto',
                                }}
                                data={
                                    <div className={classes.optionsWrapper} style={optionStyle?optionStyle:{}}>
                                        <div className={classes.optionBody}>
                                            {isShowSelectedInList &&
                                                checkArrayLength(value) &&
                                                value.map((opt, ind) => (
                                                    <div
                                                        aria-hidden
                                                        className={`${classes.item} ${checkKeyInObject(selectedOpt, 'value') &&
                                                            selectedOpt.value === opt.value &&
                                                            classes.itemActive
                                                            }`}
                                                        key={ind}
                                                        onClick={() => {
                                                            this.shouldClose = !isFocus;
                                                            if (callingFrom === undefined || callingFrom === null){
                                                                textInputRef.focus();
                                                            }
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex', width: '100%' }}>
                                                            <span className={classes.parentSpan}>
                                                                <div className={classes.parentCheckIconDiv}>
                                                                    <span style={{ width: 16, marginRight: 12 }}>
                                                                        <CheckIcon className={classes.checkIcon} />
                                                                    </span>
                                                                    <span style={{ width: 'calc(100% - 28px)' }}>{opt.label}</span>
                                                                </div>
                                                                <span
                                                                    aria-hidden
                                                                    className={classes.parentCloseIcon}
                                                                    onClick={() => {
                                                                        onChange(value.filter((v) => v.value !== opt.value));
                                                                    }}
                                                                >
                                                                    <Close className={classes.closeIcon} />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            {isShowSelectedInList && checkArrayLength(value) && <div className={classes.createLine}></div>}
                                            {!isShowDefaultValue && checkKeyInObject(defaultItem, 'label') && (
                                                <div
                                                    aria-hidden
                                                    className={classes.item}
                                                    onClick={() => {
                                                        this.shouldClose = !isFocus;
                                                        if (callingFrom === undefined || callingFrom === null){
                                                            textInputRef.focus();
                                                        }
                                                        this.setState({ val: '' });
                                                        if (isMultiSelect) {
                                                            onChange([]);
                                                        }
                                                    }}
                                                >
                                                    <span style={{ marginLeft: isShowSelectedInList ? 27 : 0 }}>{defaultItem.label}</span>
                                                </div>
                                            )}
                                            {checkArrayLength(_options)
                                                ? _options.map((opt, ind) => (
                                                    <div
                                                        aria-hidden
                                                        className={`${classes.item} ${this.checkValue(selectedOpt) && selectedOpt.value === opt.value && classes.itemActive
                                                            }`}
                                                        onClick={() => {
                                                            if('is_suite_executable' in opt && !opt?.is_suite_executable){
                                                                this.shouldClose = false;
                                                                return null
                                                            }
                                                            if (isMultiSelect && Array.isArray(value)) {
                                                                const a = _options.filter((f) => (f.label === opt.label));
                                                                const v = [...value];
                                                                v.push(...a);
                                                                onChange(v);
                                                                this.shouldClose = false;
                                                                if (callingFrom === undefined || callingFrom === null){
                                                                    textInputRef.focus();
                                                                }
                                                            } else {
                                                                onChange(opt);
                                                                this.setState({ isFocus: false });
                                                            }
                                                        }}
                                                        key={ind}
                                                    >
                                                        <span style={{ marginLeft: isShowSelectedInList ? 27 : 0, color: 'is_suite_executable' in opt && !opt?.is_suite_executable ? 'rgb(187, 187, 187)' : ''}}> {opt.label}</span>
                                                    </div>
                                                ))
                                                : ((isShowSelectedInList && !checkArrayLength(value)) || !isShowSelectedInList) && (
                                                    <div className={classes.itemDisable}>No Options</div>
                                                )}
                                        </div>
                                    </div>
                                }
                            >
                                <span onClick={() => !isFocus && this.setState({ isFocus: true })}>
                                    {startAdornment || null}
                                    <span style={styleWithIcon || { display: 'flex', width: '100%', alignItems: 'center' }}>
                                        <div className={classes.valWrapper} style={callingFrom === "postAction" ? {marginLeft:0} : {}}>
                                            <div
                                                aria-hidden
                                                className={`${classes.textCont} ${isError ? classes.errorSelect : ''}`}
                                                style={!isMultiSelect ? { display: 'block', height: '100%' } : {}}
                                                onClick={() => {
                                                    if (isMultiSelect) {
                                                        this.shouldClose = !isFocus;
                                                        textInputRef.focus();
                                                    }
                                                }}
                                            >
                                                {isSearchForTaskpane ? (
                                                    isMultiSelect && !isShowSelectedInList && checkArrayLength(distinctSearchedInput)
                                                        ? distinctSearchedInput.map((_val, i) => (
                                                            <div
                                                                aria-hidden
                                                                className={`${classes.itemCont} ${i === 0 ? classes.noLeftPadding : ''}`}
                                                                key={i}
                                                                onClick={() => {
                                                                    this.shouldClose = !isFocus;
                                                                    textInputRef.focus();
                                                                }}
                                                            >
                                                                <div className={`${classes.itemName} ${classes.noSelect}`}>{_val}</div>
                                                                <div
                                                                    aria-hidden
                                                                    className={classes.removeItem}
                                                                    onClick={() => {
                                                                        onChange(value.filter((v) => v.label !== _val));
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon={faTimes} aria-label="faTimes" id="faTimes" />
                                                                </div>
                                                            </div>
                                                        ))
                                                        : null
                                                ) : (isMultiSelect && !isShowSelectedInList && checkArrayLength(value)
                                                    ? value.map((_val, i) => (
                                                        <div
                                                            aria-hidden
                                                            className={`${classes.itemCont} ${i === 0 ? classes.noLeftPadding : ''}`}
                                                            key={i}
                                                            onClick={() => {
                                                                this.shouldClose = !isFocus;
                                                                textInputRef.focus();
                                                            }}
                                                        >
                                                            <div className={`${classes.itemName} ${classes.noSelect}`}>{_val.label}</div>
                                                            <div
                                                                aria-hidden
                                                                className={classes.removeItem}
                                                                onClick={() => {
                                                                    onChange(value.filter((v) => v.value !== _val.value));
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faTimes} aria-label="faTimes" id="faTimes" />
                                                            </div>
                                                        </div>
                                                    ))
                                                    : null)
                                                }
                                                {isShowDefaultValue ? (
                                                    <div
                                                        aria-hidden
                                                        className={`${classes.itemCont} ${classes.noLeftPadding}`}
                                                        onClick={() => {
                                                            this.shouldClose = !isFocus;
                                                            textInputRef.focus();
                                                        }}
                                                    >
                                                        <div style={{ paddingRight: 6 }} className={`${classes.itemName} ${classes.noSelect}`}>
                                                            {options.find((opt) => opt.value === defaultItemValue).label}
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {isShowSelectedInList && checkArrayLength(value) && selectName ? (
                                                    <div
                                                        aria-hidden
                                                        className={`${classes.itemCont} ${classes.noLeftPadding}`}
                                                        onClick={() => {
                                                            this.shouldClose = !isFocus;
                                                            textInputRef.focus();
                                                        }}
                                                    >
                                                        <div style={{ paddingRight: 6 }} className={`${classes.itemName} ${classes.noSelect}`}>
                                                            {value.length} {selectName}
                                                        </div>
                                                    </div>
                                                ) : null}


                                                <input
                                                    id="searchB"
                                                    data-testid="searchB"
                                                    onFocus={() => !isFocus && this.setState({ isFocus: true })}
                                                    onBlur={() => {
                                                        setTimeout(() => {
                                                            if (this.shouldClose && isFocus) {
                                                                this.setState({ isFocus: false }, () => {
                                                                    this.updatedSelectedValue();
                                                                });
                                                            } else {
                                                                this.shouldClose = true;
                                                            }
                                                        }, 200);
                                                    }}
                                                    readOnly={Boolean(readOnly)}
                                                    onChange={(e) => {
                                                        this.setState({ val: e.target.value });
                                                    }}
                                                    className={classes.input}
                                                    type="text"
                                                    autoComplete="off"
                                                    value={val}
                                                    placeholder={placeholder}
                                                    ref={this.handletextInputRef}
                                                />
                                            </div>
                                        </div>
                                        <span
                                            aria-hidden
                                            onClick={() => {
                                                this.shouldClose = !isFocus;
                                                if (callingFrom === undefined || callingFrom === null){
                                                    textInputRef.focus();
                                                }
                                            }}
                                            style={{ display: `${isSelectDropdown}`, alignItems: 'center' }}
                                        >
                                            {!noClearIcon && (!!val || (isMultiSelect && checkArrayLength(value))) ? (
                                                <div
                                                    aria-hidden
                                                    className={classes.clear}
                                                    onClick={() => {
                                                        this.setState({ val: '' }, () => {
                                                            if (isMultiSelect) {
                                                                onChange([]);
                                                            }
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTimes} aria-label="faTimes" id="faTimes" />
                                                </div>
                                            ) : null}
                                            {checkArrayLength(errors) && (
                                                <div className={classes.helpOutlineWrapper}>
                                                    <Tooltip
                                                        testid="helpTooltip"
                                                        data={
                                                            <div>
                                                                {errors.map((v, i) => (
                                                                    <div key={i} data-testid="err" className={classes.errorItem}>
                                                                        {v}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        }
                                                        isElement
                                                    >
                                                        <HelpOutline className={classes.helpOutline} aria-label="HelpOutline" id="HelpOutline" />
                                                    </Tooltip>
                                                </div>
                                            )}
                                            <span className={classes.seprator}></span>
                                            <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: 3 }}>
                                                <ArrowDown color="secondary" className={classes.icon} aria-label="arrowDownIcon" id="arrowDownIcon" />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </Tooltip>
                        </div>
                    </ClickAwayListener>

                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Select);
