import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CloseOnEscape from 'react-close-on-escape';
import Close from '@material-ui/icons/Close';
import {
    DIALOG_ROOT,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_HEADER_TITLE,
    MODAL_FOOTER,
    MODAL_SUBMIT_BUTTON,
    MODAL_CANCEL_BUTTON,
    MODAL_HEADER_CLOSE_ICON,
    BLACK_FONT,
} from '../../../common/cssConstants';

const styles = (theme) => ({
    dialogModal: {
        '&>div': {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    dialogTitleStyle: MODAL_HEADER_TITLE,
    dialogContent: {
        overflow: 'hidden',
        minWidth: 370,
        padding: '0px 40px 0px 30px !important',
        maxHeight: 'calc(100vh - 290px)',
        overflowY: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 100px)',
        },
    },
    message: {
        float: 'left',
        fontSize: 12,
        color: BLACK_FONT,
    },
});

const ConfirmNavigationModal = ({ classes, onConfirm, onCancel, msg, leaveBtn, stayBtn }) => {
    return (
        <div
            aria-hidden
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Dialog disableRestoreFocus open aria-labelledby="form-dialog-title" className={classes.dialogModal}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ padding: '30px 0px 0px 0px' }}></div>
                    <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                        <Close style={MODAL_HEADER_CLOSE_ICON} onClick={() => onCancel()} aria-label="closeIcon" id="closeIcon" />
                    </div>
                </div>
                <div>
                    <DialogContent className={classes.dialogContent}>
                        <div
                            style={{
                                width: '100%',
                            }}
                        >
                            <Typography className={classes.message}>{msg}</Typography>
                        </div>
                    </DialogContent>
                </div>
                <DialogActions style={MODAL_FOOTER}>
                    <Button variant="contained" color="secondary" onClick={() => onConfirm()} style={MODAL_SUBMIT_BUTTON}>
                        {leaveBtn}
                    </Button>
                    <CloseOnEscape onEscape={() => onCancel()}>
                        <Button variant="outlined" color="secondary" onClick={() => onCancel()} style={MODAL_CANCEL_BUTTON}>
                            {stayBtn}
                        </Button>
                    </CloseOnEscape>
                </DialogActions>
            </Dialog>
        </div>
    );
};

ConfirmNavigationModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    msg: PropTypes.string.isRequired,
    leaveBtn: PropTypes.string.isRequired,
    stayBtn: PropTypes.string.isRequired,
};
export default withStyles(styles)(ConfirmNavigationModal);
