import React, {  useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from "moment";

const styles = () => ({
    timerContainer: {
    }
})

const CountDownTimer = (props) => {

  const {className,totalTimeStamp,onCountDownFinish,style} = props;

  let [duration, setDuration] = useState(totalTimeStamp);

  let Ref = useRef('');

  useEffect(() => {
    let intervalId = setInterval(() => {
      updateTime();
    }, 1000);

    Ref.current = intervalId;

    return () => {
      clearInterval(Ref.current);
    };
  }, []);

  const updateTime = () => {
    
    setDuration((preDuration) => {
      if(preDuration<=0) {
        clearInterval(Ref.current);
        return preDuration
     } 
        return (preDuration-1)
    });
  };

  const getTimeToDisplay = (seconds) => {
    return moment.utc(seconds*1000).format('mm:ss');
  }

  useEffect(()=>{
    if(duration<=0 && onCountDownFinish) {
      onCountDownFinish();
    }

  },[duration])


  return (
    
      <div style={style} className={className}>
          {getTimeToDisplay(duration)}
      </div>
  )

};

CountDownTimer.propTypes = {
    totalTimeStamp: PropTypes.number.isRequired,
    onCountDownFinish:PropTypes.func
};

CountDownTimer.defaultProps = {
  totalTimeStamp:120
}

export default withStyles(styles)(CountDownTimer);
