import store from '../store';
import ImageAction from '../store/actions/imageAction';

class RequestImageService {
    checkStatus = (response) => {
        try {
            if (response.status >= 200 && response.status < 300) {
                return response;
            }
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        } catch (error) {
            console.error(error);
        }
        return null;
    };

    parseBlob = (response) => {
        if (!response || (response.status === 204 && response.status === 205)) {
            return null;
        }
        return response.blob();
    };

    requestImage = (url, onFail = () => {}) => {
        try {
            const token = localStorage.getItem('token');
            this.props.loadImage(url);
            return fetch(url, {
                headers: new Headers({
                    Authorization: `Bearer ${token}`,
                }),
            })
                .then((response) => {
                    return this.checkStatus(response);
                })
                .then(async (response) => {
                    try {
                        const res = await this.parseBlob(response);
                        if (res) {
                            this.props.imageLoaded(url, window.URL.createObjectURL(res));
                            return res;
                        }
                        throw new Error('Resquest not completed');
                    } catch (error) {
                        onFail();
                        this.props.imageLoadingError(url, error);
                    }
                    return null;
                });
        } catch (error) {
            onFail();
            this.props.imageLoadingError(url, error);
        }
        return null;
    };

    props = {
        loadImage: (...args) => {
            store.dispatch(ImageAction.loadImage(...args));
        },
        imageLoaded: (...args) => {
            store.dispatch(ImageAction.imageLoaded(...args));
        },
        imageLoadingError: (...args) => {
            store.dispatch(ImageAction.imageLoadingError(...args));
        },
        // get user() { return store.getState().authReducer.user },
    };
}

export default new RequestImageService();
