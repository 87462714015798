// import materials
import { withStyles } from '@material-ui/core/styles';
import { Typography, Hidden } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import packages
import React from 'react';
import PropTypes from 'prop-types';
import { BLACK_FONT } from '../../../common/cssConstants';
import { isEmail, isAccountName } from '../../../common/regexConstants';

const styles = (theme) => ({
    input: {
        fontSize: 12,
        color: `${BLACK_FONT} !important`,
        '&>input': {
            padding: '0px !important',
        },
    },
    formControl: {
        width: '100%',
        flexDirection: 'row',
        marginBottom: 10,
    },
    keyHeading: {
        width: '100px',
        float: 'left',
        fontSize: '12px',
    },
    dataValue: {
        float: 'left',
        fontSize: 12,
        fontWeight: 'bold',
        maxWidth: 'calc(100% - 100px)',
        minWidth: 'calc(100% - 100px)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingBottom: 5,
    },
    inputLabel: {
        left: 'inherit',
    },
    selectBox: {
        width: '100%',
        fontSize: 12,
        color: '#4A4A4A !important',
        '&>div': {
            '&>:first-child': {
                padding: '0px !important',
                paddingRight: '25px !important',
            },
            '&>:nth-child(3)': {
                fontSize: '20px !important',
            },
        },
        [theme.breakpoints.only('xs')]: {
            width: '65%',
        },
        '&:before': {
            borderBottom: 'none',
        },
    },
    removeIcon: {
        '&>div>svg': {
            display: 'none',
        },
    },
    textField: {
        // flexBasis: 200,
        width: '100%',
        flexDirection: 'row',
        float: 'left',
        padding: 10,
    },
    textFieldTitle: {
        width: '40%',
        float: 'left',
        fontSize: 14,
        lineHeight: 2,
        /* height: auto, */
        textAlign: 'left',
        color: '#4A4A4A',
    },
    rPModal: {
        width: '100%',
        padding: '0px',
    },
    textWarn: {
        width: '100%',
        float: 'left',
        fontSize: 12,
        textAlign: 'left',
        [theme.breakpoints.only('xs')]: {
            fontSize: 16,
        },
    },
    textEmail: {
        width: '100%',
        float: 'left',
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'left',
        [theme.breakpoints.only('xs')]: {
            fontSize: 14,
        },
    },
    inputErrorBlock: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.only('xs')]: {
            width: '65%',
        },
    },
});

function CreateUserModal({
    userName,
    userNameFix,
    classes,
    handleChange,
    handleSelectChange,
    email,
    selectedRole,
    statusDropDownData,
    password,
    project,
    lastSignIn,
    type,
    user,
}) {
    return type !== 'Reset' ? (
        <div>
            <Hidden only={['xs']}>
                <FormControl className={classes.formControl}>
                    <div
                        style={{
                            width: '100%',
                            paddingBottom: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography className={classes.keyHeading}>Name</Typography>
                        <span
                            className={classes.dataValue}
                            style={{
                                borderBottom: type === 'Add' || !type === 'Edit' || !type === 'Delete' ? '1px solid #c4c4c4' : '',
                            }}
                        >
                            <Input
                                fullWidth
                                id="adornment-userName"
                                value={userName}
                                onChange={handleChange('userName')}
                                disabled={!(type === 'Add' || !type === 'Edit' || !type === 'Delete')}
                                disableUnderline
                                className={classes.input}
                            />
                            {userName === '' || !(!isAccountName.test(userName) && userNameFix !== userName) ? null : (
                                <span style={{ fontSize: 12, color: 'red' }}>Username should only be lowercase alphanumeric.</span>
                            )}
                        </span>
                    </div>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <div
                        style={{
                            width: '100%',
                            paddingBottom: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography className={classes.keyHeading}>Email</Typography>
                        <span
                            className={classes.dataValue}
                            style={{
                                borderBottom: type === 'Delete' || type === 'ChangeStatus' ? '' : '1px solid #c4c4c4',
                            }}
                        >
                            <Input
                                fullWidth
                                id="adornment-email"
                                value={email}
                                disabled={!!(type === 'Delete' || type === 'ChangeStatus')}
                                onChange={handleChange('email')}
                                disableUnderline
                                className={classes.input}
                            />
                            {email === '' || isEmail.test(String(email).toLowerCase()) ? null : (
                                <span style={{ fontSize: 12, color: 'red' }}>Invalid email address.</span>
                            )}
                        </span>
                    </div>
                </FormControl>
                {type === 'Add' ? (
                    <FormControl className={classes.formControl}>
                        <div
                            style={{
                                width: '100%',
                                paddingBottom: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography className={classes.keyHeading}>Password</Typography>
                            <span
                                className={classes.dataValue}
                                style={{
                                    borderBottom: !type === 'Delete' || type === 'ChangeStatus' ? '' : '1px solid #c4c4c4',
                                }}
                            >
                                <Input
                                    fullWidth
                                    id="adornment-password"
                                    type="password"
                                    value={password}
                                    disabled={!!(!type === 'Delete' || type === 'ChangeStatus')}
                                    onChange={handleChange('password')}
                                    disableUnderline
                                    className={classes.input}
                                />
                            </span>
                        </div>
                    </FormControl>
                ) : null}
                <FormControl className={classes.formControl}>
                    <div
                        style={{
                            width: '100%',
                            paddingBottom: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography className={classes.keyHeading}>Role</Typography>
                        <span
                            className={classes.dataValue}
                            style={{
                                borderBottom: type === 'Delete' || type === 'ChangeStatus' || user.role !== 'ADMIN' ? '' : '1px solid #c4c4c4',
                            }}
                        >
                            <Select
                                className={`${classes.selectBox} ${type === 'Delete' || type === 'ChangeStatus' ? classes.removeIcon : ''}`}
                                value={selectedRole}
                                // inputProps={{
                                //     name: 'selectedRole',
                                //     id: 'selectedRole',
                                // }}
                                disabled={!!(type === 'Delete' || type === 'ChangeStatus' || user.role !== 'ADMIN')}
                                onChange={(e) => handleSelectChange(e)}
                                disableUnderline
                            >
                                {statusDropDownData &&
                                    statusDropDownData.map((status, index) => (
                                        <MenuItem
                                            value={status.value}
                                            key={index.toString()}
                                            style={{ paddingLeft: 10, paddingRight: 10, height: 20, fontSize: 12 }}
                                        >
                                            {status.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </span>
                    </div>
                </FormControl>
                {type === 'Add' || type === 'Edit' ? null : (
                    <div>
                        <FormControl className={classes.formControl}>
                            <div
                                style={{
                                    width: '100%',
                                    paddingBottom: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography className={classes.keyHeading}>Last Sign In</Typography>
                                <span
                                    className={classes.dataValue}
                                    style={{
                                        borderBottom: !(type === 'Add' || !type === 'Delete') ? '' : '1px solid #c4c4c4',
                                    }}
                                >
                                    <Input
                                        fullWidth
                                        id="adornment-lastSignIn"
                                        value={lastSignIn}
                                        onChange={handleChange('lastSignIn')}
                                        disabled={!(type === 'Add' || !type === 'Delete')}
                                        disableUnderline
                                        className={classes.input}
                                    />
                                </span>
                            </div>
                        </FormControl>
                    </div>
                )}
            </Hidden>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <div>
                    <FormControl className={classes.textField}>
                        <Typography className={classes.textFieldTitle}>Name</Typography>
                        <span className={classes.inputErrorBlock}>
                            <Input
                                fullWidth
                                id="adornment-userName"
                                value={userName}
                                onChange={handleChange('userName')}
                                disabled={!(type === 'Add' || !type === 'Edit' || !type === 'Delete')}
                                disableUnderline
                                className={classes.input}
                            />
                            {userName === '' || !(!isAccountName.test(userName) && userNameFix !== userName) ? null : (
                                <span style={{ fontSize: 12, color: 'red' }}>Username should only be lowercase alphanumeric.</span>
                            )}
                        </span>
                    </FormControl>
                    <FormControl className={classes.textField}>
                        <Typography className={classes.textFieldTitle}>Email</Typography>
                        <span className={classes.inputErrorBlock}>
                            <Input
                                fullWidth
                                id="adornment-email"
                                value={email}
                                disabled={!!(type === 'Delete' || type === 'ChangeStatus')}
                                onChange={handleChange('email')}
                                disableUnderline
                                className={classes.input}
                            />
                            {email === '' || isEmail.test(String(email).toLowerCase()) ? null : (
                                <span style={{ fontSize: 12, color: 'red' }}>Invalid email address.</span>
                            )}
                        </span>
                    </FormControl>
                    {type === 'Add' ? (
                        <FormControl className={classes.textField}>
                            <Typography className={classes.textFieldTitle}>Password</Typography>
                            <Input
                                fullWidth
                                id="adornment-password"
                                type="password"
                                value={password}
                                disabled={!!(!type === 'Delete' || type === 'ChangeStatus')}
                                onChange={handleChange('password')}
                                disableUnderline
                                className={classes.input}
                            />
                        </FormControl>
                    ) : null}
                    <FormControl className={classes.textField}>
                        <Typography className={classes.textFieldTitle}>Role</Typography>
                        <Select
                            className={`${classes.selectBox} ${type === 'Delete' || type === 'ChangeStatus' ? classes.removeIcon : ''}`}
                            IconComponent="false"
                            value={selectedRole}
                            // inputProps={{
                            //     name: 'selectedRole',
                            //     id: 'selectedRole',
                            // }}
                            disabled={!!(type === 'Delete' || type === 'ChangeStatus' || user.role !== 'ADMIN')}
                            onChange={(e) => handleSelectChange(e)}
                            disableUnderline
                        >
                            {statusDropDownData &&
                                statusDropDownData.map((status, index) => (
                                    <MenuItem
                                        value={status.value}
                                        key={index.toString()}
                                        style={{ paddingLeft: 10, paddingRight: 10, height: 20, fontSize: 12 }}
                                    >
                                        {status.label}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    {type === 'Add' || type === 'Edit' ? null : (
                        <div>
                            <FormControl className={classes.textField}>
                                <Typography className={classes.textFieldTitle}>Last Sign In</Typography>
                                <Input
                                    id="adornment-lastSignIn"
                                    value={lastSignIn}
                                    onChange={handleChange('lastSignIn')}
                                    disabled={!(type === 'Add' || !type === 'Delete')}
                                    style={{ width: '65%' }}
                                    disableUnderline
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl className={classes.textField}>
                                <Typography className={classes.textFieldTitle}>Project</Typography>
                                <Input
                                    id="adornment-project"
                                    value={project}
                                    onChange={handleChange('project')}
                                    disabled={!(type === 'Add' || !type === 'Delete')}
                                    style={{ width: '65%' }}
                                    disableUnderline
                                    className={classes.input}
                                />
                            </FormControl>
                        </div>
                    )}
                </div>
            </Hidden>
        </div>
    ) : (
        <div>
            <div className={classes.rPModal}>
                <Typography className={classes.textWarn}>Do you want to send the reset link to</Typography>
                <Typography className={classes.textEmail}>{email}?</Typography>
            </div>
        </div>
    );
}

CreateUserModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    email: PropTypes.string.isRequired,
    lastSignIn: PropTypes.string,
    password: PropTypes.string,
    project: PropTypes.string,
    selectedRole: PropTypes.string,
    statusDropDownData: PropTypes.instanceOf(Array),
    type: PropTypes.string.isRequired,
    user: PropTypes.instanceOf(Object),
    userName: PropTypes.string,
    userNameFix: PropTypes.string,
    // functions
    handleChange: PropTypes.func.isRequired,
    handleSelectChange: PropTypes.func,
};

CreateUserModal.defaultProps = {
    lastSignIn: '',
    password: '',
    project: '',
    selectedRole: '',
    statusDropDownData: [],
    user: {},
    userName: '',
    userNameFix: '',
    // functions
    handleSelectChange: () => {},
};

export default withStyles(styles)(CreateUserModal);
