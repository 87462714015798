// materials
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { CircularProgress , Button } from '@material-ui/core';

import FlashOn from '@material-ui/icons/FlashOn';
// packages
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
// icons
import StopIcon from '@material-ui/icons/Stop';

// custom
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import OndemandVideo from '@material-ui/icons/OndemandVideo';
import { GeneralActions, ModalActions, TestSuiteActions, SuiteReportAction, ProjectActions } from '../../store/actions';
import { checkArrayLength, checkKeyInObject, checkObject, getFormatedDate, getCountAvatar, sort, checkIsArray } from '../../utils/utils';
import { EnhancedTableHead } from '../../components/EnhancedTable/EnhancedTableHead';
import singleFileCreationService from '../../services/singleFileCreationService';
import Loader from '../../components/Loader';
import { TABLES_CSS, THREE_DOT_MENU_CSS, MUI_ACTION_MENU_ICON } from '../../common/cssConstants';
import TableRow from '../../components/Table/TableRow';
import TableCell from '../../components/Table/TableCell';
import TableBody from '../../components/Table/TableBody';
import Table from '../../components/Table/Table';
import Tooltip from '../../components/Tooltip';
import TableFooter from '../../common/TableFooter';
import { GOTO_STEP_EDITOR } from '../../common/constants';
import ExpandBtn from '../../components/Button/ExpandBtn';
import WSService from '../../services/WSService'
import ConfirmDialog from './ConfirmDialog';

// Platforms Icons
import windows_Icon from '../../assets/icons/platforms/windows.svg';
import linux_Icon from '../../assets/icons/platforms/linux.svg';
import macos_Icon from '../../assets/icons/platforms/macos.svg';
import android_Icon from '../../assets/icons/platforms/android.svg';
import default_platform_icon from '../../assets/icons/platforms/default.svg';

// Devices/Browsers Icons
import firefox_Icon from '../../assets/icons/browsers/firefox.svg';
import chrome_Icon from '../../assets/icons/browsers/chrome.svg';
import microsoftedge_Icon from '../../assets/icons/browsers/microsoftedge.svg';
import ie_Icon from '../../assets/icons/browsers/ie.svg';
import safari_Icon from '../../assets/icons/browsers/safari.svg';
import default_browser_icon from '../../assets/icons/browsers/default.svg';

import real_deviceIcon from '../../assets/icons/devices/real.svg';
import emulator_Icon from '../../assets/icons/devices/emulator.svg';


// styles

const styles = () => ({
    noDataText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '88px',
    },
    root: {
        boxShadow: 'none',
    },
    table: {
        // minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    tableRows: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#FFF !important',
        },
        backgroundColor: '#FFF',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
    },
    _cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '20%',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
    },
    iconButtonStyle: {
        height: '30px',
        marginRight: 10,
        width: '30px',
    },
    stopIconStyle: {
        marginRight: '18.3rem',
        color: '#5f94fe',
        height: '1rem',
        width: '1rem',

    },
    tableRows1: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.07) !important',
        },
        backgroundColor: '#FCF9E5',
        '&:last-of-type': {
            borderBottom: 'none !important',
        },
    },
    gotoIcon: {
        color: '#5f94fe',
        cursor: 'pointer',
        fontSize: 16,
    },
    gotoIconDisabled: {
        color: '#bfbfc0',
        cursor: 'not-allowed',
        fontSize: 16,
        pointerEvents: 'none',
    },
    eyeIcon: {
        color: '#5f94fe',
        cursor: 'pointer',
        fontSize: 20,
    },
    eyeIconDisabled: {
        color: '#bfbfc0 !important',
        cursor: 'not-allowed',
        fontSize: 20,
        pointerEvents: 'none',
    },
    downloadIcon: {
        color: '#5f94fe',
        cursor: 'pointer',
        fontSize: 16,
    },
    downloadIconDisabled: {
        color: '#bfbfc0 !important',
        cursor: 'not-allowed',
        fontSize: 16,
        pointerEvents: 'none',
    },
    playIcon: {
        color: '#5f94fe',
        cursor: 'pointer',
        fontSize: 22,
    },
    playIconDisable: {
        color: '#bfbfc0 !important',
        cursor: 'not-allowed',
        fontSize: 22,
        pointerEvents: 'none',
    },
    /* Filter Button classes start */
    filterButton: {
        borderRadius: '8px',
        color: '#fff',
        cursor: 'pointer',
        fontSize: 13,
        marginRight: 5,
        padding: '0PX 14px',
        display: 'inline-block',
    },
    successFilled: {
        backgroundColor: '#00B330',
        border: '1px solid #00B330',
    },
    successUnFilled: {
        border: '1px solid #00B330',
        color: '#00B330',
    },
    failureFilled: {
        backgroundColor: '#E8001C',
        border: '1px solid #E8001C',
    },
    failureUnFilled: {
        border: '1px solid #E8001C',
        color: '#E8001C',
    },
    noDataAvailable: {
        display: 'flex',
        justifyContent: 'center',
        color: '#4a4a4a',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 7px 0px',
        height: 50,
        alignItems: 'center',
        margin: '0 auto',
        width: '95%',
    },
    maxHeight: { maxHeight: 'calc(100vh - 225px)' },
    minHeight: { minHeight: 'calc(100vh - 228px)' },
    /* Filter Button classes end */
    tableWrapper2: {
        margin: 3,
        overflow: 'hidden',
        borderRadius: 5,
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        width: 'calc(100% - 4px)',
    },
    execFailedTestCasesBtn: {
        cursor: 'pointer',
        margin: 0,
        backgroundColor: '#0092e6',
        color: '#fff',
        borderRadius: '5px',
        boxShadow: 'none',
        justifyContent: 'start',
        padding: '5px 10px',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#0092e6',
            boxShadow: 'none',
        },
    },
    executeIcon: {
        fontSize: 22,

    },
    moreIcons: MUI_ACTION_MENU_ICON,

    blueIcon: {
        color:'#5f94fe !important'
    },

    moreIconsBtnDisabled: {
        color: THREE_DOT_MENU_CSS.iconDisableColor,
    },
    moreIconsBtnEnabled : {
        color:'#5f94fe'
    },
    stopIcon : {
        color:'#5f94fe'
    },
    
    execution_status_btn_enabled: {
        pointerEvents:'none !important',
        color:'white',
        borderColor:'#5f94fe',
        backgroundColor:'#5f94fe',
        maxHeight:'25px !important',
        minHeight:'10px !important',
        width:'92px'

    },
    execution_status_btn_disabled: {
        pointerEvents:'none !important',
        color:'#5f94fe',
        borderColor:'#5f94fe',
        maxHeight:'25px !important',
        minHeight:'10px !important',
        width:'92px'


    },
    restart_execution_status_btn_disabled: {
        pointerEvents:'none !important',
        color:'rgb(243, 155, 49)',
        borderColor:'rgb(243, 155, 49)',
        maxHeight:'25px !important',
        minHeight:'10px !important',
        width:'92px'



    },
    restart_execution_status_btn_enabled: {
        pointerEvents:'none !important',

        borderColor:'rgb(243, 155, 49)',
        color:'white',
        backgroundColor:'rgb(243, 155, 49)',
        maxHeight:'25px !important',
        minHeight:'10px !important',
        width:'92px'



    },


});

const headers = [
    {
        id: 'creationTime',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Execution Time',
        width: '30%',
        styles: { paddingLeft: 10 },
    },
    { id: 'total', sortable: true, numeric: false, disablePadding: true, label: 'Total', width: '15%' },
    { id: 'mode', sortable: true, numeric: false, disablePadding: true, label: 'Mode', width: '15%' },
    { id: 'totalSuccess', sortable: true, numeric: false, disablePadding: true, label: 'Passed', width: '15%' },
    { id: 'totalFail', sortable: true, numeric: false, disablePadding: true, label: 'Failed', width: '10%' },
    { id: 'funcs', sortable: false, numeric: false, disablePadding: true, label: '', width: '15%' },
];

class SuiteReportDetailCaseTable extends React.Component {
    state = {
        data: [],
        downloadFileLoader: {},
        previewFileLoader: {},
        showLoader: false,
        rowExpendStatus: [0, false],
        successFiltered: {},
        failureFiltered: {},
        order: 'desc',
        orderBy: 'jobId',
        retryActionActivated:{},
        executingJobs:{},
        stoppedJobs:{},
        tunnelId:'',
    };

    async componentDidMount() {
        const caseSettingRows = localStorage.getItem('rowsCasePerPage');
        
        const { getTunnelIDForSauceLabs }= this.props
        getTunnelIDForSauceLabs();
        if (caseSettingRows) {
           
            this.setState({
                rowsPerPage: parseInt(caseSettingRows),
            });
        }

        
        this.openFirstAuto();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { sauceID } = nextProps;

        if(checkArrayLength(sauceID)) this.setState({tunnelId: sauceID[0]})
        this.openFirstAuto();
    }

    componentWillUnmount() {
        this.props.queryRemove();
        this.props.clearSnackBar();
    }

    getAllJobs = () => {
        const { data, executions, query = '' } = this.props;
        const { successFiltered, failureFiltered } = this.state;
        const _jobs = {};


        if (checkObject(data)) {
            if (checkKeyInObject(executions, 'jobs') && checkArrayLength(executions.jobs)) {
                executions.jobs.forEach((job) => {
                    if (!checkKeyInObject(_jobs, job.jobId)) {
                        _jobs[job.jobId] = {
                            jobId: job.jobId,
                            creationTime: getFormatedDate(job.creationTime, 'DD-MMM-YYYY HH:mm:ss', 'N/A'),
                            environmentType:job.environmentType,
                            totalSuccess: 0,
                            totalFail: 0,
                            totalStopped: 0,
                            totalQueued:0,
                            total: 0,
                            _testCaseArray: [],
                            etrs: job.etrs,
                            browser:job.browser,
                            browserVersion:job.browserVersion,
                            platform:job.platform,
                            platform_version:job.platform_version,
                            jobExecutionStatus:job.jobExecutionStatus,
                            isRetryExecAvailable:job.isRetryExecAvailable,
                            isSerial:job.isSerial

                        };
                    }
                    const tasks =
                        checkKeyInObject(job, 'etrs') && checkKeyInObject(job.etrs, 'tasks') && checkArrayLength(job.etrs.tasks)
                            ? job.etrs.tasks
                            : [];
                    tasks.forEach((task) => {
                        if (checkObject(task)) {
                            if (
                                checkKeyInObject(task, 'executionStatus') &&
                                checkKeyInObject(task, 'testcaseId') &&
                                checkKeyInObject(task, 'testCaseName') &&
                                checkKeyInObject(task, 'projectName') &&
                                (
                                    !query ||
                                    query && task.testCaseName.toLowerCase().includes(query.toLowerCase())
                                )
                            ) {
                                if (task.executionStatus === 'SUCCESS') {
                                    _jobs[job.jobId].totalSuccess++;
                                    _jobs[job.jobId].total++;
                                } else if (task.executionStatus === 'ERROR') {
                                    _jobs[job.jobId].totalFail++;
                                    _jobs[job.jobId].total++;
                                }  else if (task.executionStatus === 'INPROGRESS' || task.restartExecutionStatus === 'INPROGRESS') {
                                    _jobs[job.jobId].total++;
                                } else if (task.executionStatus === 'STOPPED' || task.restartExecutionStatus === 'STOPPED') {
                                    _jobs[job.jobId].totalStopped++;
                                    _jobs[job.jobId].total++;
                                }  else if (task.executionStatus === 'QUEUED' || task.restartExecutionStatus === 'QUEUED') {
                                    _jobs[job.jobId].totalQueued++;
                                    _jobs[job.jobId].total++;
                                }
                                if (
                                    (!failureFiltered[job.jobId] && task.executionStatus === 'ERROR') ||
                                    (!successFiltered[job.jobId] && task.executionStatus === 'SUCCESS')
                                ) {
                                    _jobs[job.jobId]._testCaseArray.push({
                                        testCaseId: task.testcaseId,
                                        testCaseName: task.testCaseName,
                                        projectName: task.projectName,
                                    });
                                } else {
                                    _jobs[job.jobId]._testCaseArray.push({ testCaseId: task.testcaseId, testCaseName: task.testCaseName, projectName: task.projectName });
                                }
                            }
                        }
                    });
                });
            }
        }
        // Deleting inprogress execution jobs to not show
        const filteredJobs = { ..._jobs };

        for (const jobId in _jobs) {
            if (_jobs[jobId].total === 0) {
               delete filteredJobs[jobId];
            }
        }
        return filteredJobs;
    }


    showExecutionVideo = (execUrl, caseName) => {
        const { showSnackBar, toggleModal } = this.props;
        execUrl
            ? toggleModal('video', null, execUrl, caseName)
            : showSnackBar('There is no video available yet');
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleExpend = (id) => {
        const { rowExpendStatus } = this.state;
        this.setState({
            rowExpendStatus: [
                id,
                id === rowExpendStatus[0] ? !rowExpendStatus[1] : true,
            ],
            retryActionActivated:{}

        });
    };

    currentRowIndicator = () => {
        return (
            <span
                style={{
                    display: 'inline-block',
                    width: '2px',
                    minWidth: '2px',
                    marginRight: '6px',
                    height: '60px',
                    backgroundColor: '#ff9700',
                    position: 'relative',
                }}
            ></span>
        );
    };

    handleFilterClick = (value, flag, jobId) => {
        this.setState({ [value]: { ...this.state[value], [jobId]: flag } });
    };

    downloadExecuteTask = async (reportUrl, caseId, action = 'open', fileName, testCaseId) => {
        if (action === 'open') {
            let previewFileLoader = JSON.parse(JSON.stringify(this.state.previewFileLoader));
            if (!checkKeyInObject(previewFileLoader, caseId)) {
                this.setState({ previewFileLoader: { ...previewFileLoader, [caseId]: true } });
            }
            await singleFileCreationService.downloadExecutionFile(reportUrl, action, fileName,testCaseId, caseId);

            previewFileLoader = JSON.parse(JSON.stringify(this.state.previewFileLoader)); // to get updated state
            if (checkKeyInObject(previewFileLoader, caseId)) {
                delete previewFileLoader[caseId];
                this.setState({ previewFileLoader });
            }
        }
        else {
            let downloadFileLoader = JSON.parse(JSON.stringify(this.state.downloadFileLoader));
            if (!checkKeyInObject(downloadFileLoader, caseId)) {
                this.setState({ downloadFileLoader: { ...downloadFileLoader, [caseId]: true } });
            }
            await singleFileCreationService.downloadExecutionFile(reportUrl, action, fileName, testCaseId, caseId);
            downloadFileLoader = JSON.parse(JSON.stringify(this.state.downloadFileLoader)); // to get updated state
            if (checkKeyInObject(downloadFileLoader, caseId)) {
                delete downloadFileLoader[caseId];
                this.setState({ downloadFileLoader });
            }
        }


    }

    handleChangePage = (page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        localStorage.setItem('rowsCasePerPage', event.target.value);
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    openFirstAuto = () => {
        const { rowExpendStatus } = this.state;
        const _jobs = this.getAllJobs();
        if (rowExpendStatus[0] === 0 && checkObject(_jobs)) {
            this.setState({
                rowExpendStatus: [_jobs[Object.keys(_jobs)[Object.keys(_jobs).length - 1]].jobId, true],
            });
        }
    };

    handleGotoTestCase = (testCaseId, projectId) => {
        if (testCaseId && projectId) {
            this.props.history.push({
                pathname: `/projects/${projectId}/${testCaseId}`
            })
        }
    };

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    generateNewSessionIds = async (testCasesLength) => {
    	const res = await WSService.generateSessionIds(testCasesLength);
        return res;
    };

    getMappedSessionIds = (sessionIds,testCaseIds) => {
        const mappedIds = {};
        if (checkArrayLength(sessionIds)) {
            sessionIds.forEach((element, index) => {
                mappedIds[`${testCaseIds[index]}`] = element;
            });
        } 
        return mappedIds;
    };

    validateBeforeExecuteFailedTestCaes = (data) => {

        const jobId = data && checkObject(data) && data.jobId ? data.jobId : null;
        const tasks = data.etrs && checkObject(data.etrs) && Array.isArray(data.etrs.tasks) ? data.etrs.tasks : [];


        if(!jobId) {
            return false;
        } else if (this.state.executingJobs[jobId]) {
            return this.canRetryExecution(tasks)

        } 
            const retryExecAvailable = this.canRetryExecution(tasks)
            return retryExecAvailable && data.isRetryExecAvailable;
        

    }

    canRetryExecution = (tasks) => {

        // disable retry execution if any of one test case in test suite is stopped
        const stoppedTasks = tasks.filter((task)=>{
            return task.executionStatus === 'STOPPED';
        });

        if(stoppedTasks && checkIsArray(stoppedTasks) && stoppedTasks.length  ) {
            return false
        }

        // disable retry execution if any of one test case in test suite is in progress
        const inprogressTask = tasks.filter((task)=>{
            if(!task.restartExecutionStatus || task.restartExecutionStatus === 'NA') {
                return task.executionStatus === 'INPROGRESS' || task.executionStatus === 'In Progress' || task.executionStatus === 'STOPPED'
            } 
                return task.restartExecutionStatus === 'INPROGRESS' || task.restartExecutionStatus === 'In Progress' || task.restartExecutionStatus === 'STOPPED' 
            
        });

        if(inprogressTask && checkIsArray(inprogressTask) && inprogressTask.length  ) {
            return false
        }


        tasks = tasks.filter((task)=>{
            if(!task.restartExecutionStatus || task.restartExecutionStatus === 'NA') {
                return task.executionStatus === 'ERROR'
            } 
                return  task.restartExecutionStatus === 'ERROR' 
            
        });

        if(!checkArrayLength(tasks)) {
            return false;
        } 
            return true
        

    }

    validateBeforeStoppingExecution = (data) => {

        const jobId = data && checkObject(data) && data.jobId ? data.jobId : null;
        let tasks = data.etrs && checkObject(data.etrs) && Array.isArray(data.etrs.tasks) ? data.etrs.tasks : [];

        if(data.jobExecutionStatus === 'STOPPED') {
            return false;
        }

        // forcefully disable stop button when stop execution api is success
        if(this.state.stoppedJobs[jobId] && this.state.testSuiteStopped) {
            return false;
        }


       
        // disable stop execution if any of one test case in test suite is stopped
        const stoppedTasks = tasks.filter((task)=>{
            return task.executionStatus === 'STOPPED'
       });

       if(stoppedTasks && checkIsArray(stoppedTasks) && stoppedTasks.length  ) {
            return false
       }

        // forcefully disable stop button when stop execution api is success even the component is re rendered by refreshing page

        const session_stoppedJobs =  sessionStorage.getItem('session_stoppedJobs');
        if(jobId && session_stoppedJobs) {
            try {
                const existed_session_stoppedJobs = JSON.parse(session_stoppedJobs);
                if(existed_session_stoppedJobs && typeof existed_session_stoppedJobs === 'object') {
                    if(existed_session_stoppedJobs[jobId]) {
                        return false;
                    }
                
                }


            } catch(err) {

            }

        }

        
        tasks = tasks.filter((task)=>{
            return task.executionStatus === 'INPROGRESS' || task.executionStatus === 'QUEUED'
        });
        
        if(!jobId) {
            return false;
        } else if(!checkArrayLength(tasks)) {
            return false;
        } 
           return true
        

    }

    executeFailedTestCases = async(job={},tunnelId = '') => {

        this.setState({
            executingFailedTestCases:true
        })

       if(!this.validateBeforeExecuteFailedTestCaes(job)) {
           
       } else {

        const {executeFailedTestCases,executions,showSnackBar} = this.props


        let tasks = [];

        if(!job.isSerial) {

         tasks = job.etrs.tasks.filter((task)=>{

            if(!task.execRetryStatus || task.execRetryStatus === 'NA') {
                return task.executionStatus === 'ERROR'
            } 
                return task.executionStatus === 'ERROR' && task.execRetryStatus === 'ERROR'
            
        });

        } else {

          let flag = false;

          job.etrs.tasks.forEach((task)=>{

            let failedTask = false;

             if(!task.execRetryStatus || task.execRetryStatus === 'NA') {
                failedTask =  task.executionStatus === 'ERROR'
            } else {
                failedTask =  task.executionStatus === 'ERROR' && task.execRetryStatus === 'ERROR'
            }

             if(flag || failedTask) {
                 tasks.push(task)
                 flag = true;

             }

        });
    }
        const testCaseIds = [];


        tasks.forEach((task)=>{
                testCaseIds.push(task.testcaseId)
            
    
        });

        const sessionIds = await this.generateNewSessionIds(tasks.length);
        const TestcaseSessionIdMap = this.getMappedSessionIds(sessionIds,testCaseIds)

        let sendData = {}

        if(job.environmentType.toLowerCase() === 'saucelabs'){
             sendData = {
                map:TestcaseSessionIdMap,
                tunnelId:tunnelId || ''
            }
        }else{
             sendData = {
                map:TestcaseSessionIdMap
            }
        }


        executeFailedTestCases(job.jobId,executions.suite_id,sendData).then((res)=>{

            if(res.type === 'EXECUTE_FAILED_TESTCASES_SUCCESS') {
                showSnackBar('Failed Executions Restarted','',true);
                const executingJobs = {...this.state.executingJobs}
                executingJobs[job.jobId]  = true

               this.setState({
                   executingFailedTestCases:false,
                   executingJobs,
                   executedJobs:res.payload && res.payload.data && res.payload.data.execs && checkIsArray(res.payload.data.execs) ? res.payload.data.execs : null
   
               });
   

            } else {
                showSnackBar('Error')
                this.setState({
                    executingFailedTestCases:false
                });

            }



        }).catch((err)=>{
            this.setState({
                executingFailedTestCases:false
            });
        });


       }

    }



     getExecutionPlatformIcon = (platform = '') => {
        const { classes } = this.props;
        platform = platform.trim().toUpperCase().split(' ')[0];

        switch (platform?.toLowerCase()) {
            case 'windows':
                return <img height={14} src={windows_Icon} alt="Windows" className={classes.statusIcon} />;
            case 'linux':
                return <img height={14} src={linux_Icon} alt="Linux" className={classes.statusIcon} />;                ;
            case 'macos':
                return <img height={14} src={macos_Icon} alt="macOs" className={classes.statusIcon} />;
            case 'android':
                return <img height={14} src={android_Icon} alt="Android" className={classes.statusIcon} />;
            default:
                return <img height={14} src={default_platform_icon} alt="Default" className={classes.statusIcon} />;

        }
    }

    getExecutionBrowserIcon = (browserName = '',browserVersion='') => {
        const { classes } = this.props;
             switch (browserName?.toLowerCase()) {
            case 'firefox':
                return <img height={14} src={firefox_Icon} alt="Firefox" className={classes.statusIcon} />;
            case 'chrome':
                return <img height={14} src={chrome_Icon} alt="Chrome" className={classes.statusIcon} />;                ;
            case 'internet explorer':
                return <img height={14} src={ie_Icon} alt="Internet Explorer" className={classes.statusIcon} />;
            case 'safari':
                return <img height={14} src={safari_Icon} alt="Firefox" className={classes.statusIcon} />;
            case 'microsoftedge':
                return <img height={14} src={microsoftedge_Icon} alt="Firefox" className={classes.statusIcon} />;
            case 'edge':
                return <img src={microsoftedge_Icon} alt="Microsoftedge" className={classes.browserIcon} />;
    
            default:
                return <img height={14} src={default_browser_icon} alt="Default" className={classes.statusIcon} />;

        }

        
    }

    setSauceId = (tunnelId )=> {
        this.setState({tunnelId})
    }

    getConfirmDialogRestartExecution = () => {
        const {showConfirmationRestartExecution, job_execute, tunnelId} = this.state
        const {sauceID, toggleSnackBar} = this.props
        const _sauceID = sauceID && checkArrayLength(sauceID) ? sauceID : [];

        if(!showConfirmationRestartExecution) {
            return null
        }
        return (
        <ConfirmDialog 
             title="Execute Failed Test Cases"
             message= "Are you sure you want to execute failed test cases ?"
             confirmButtonText = "Execute"
             toggleSnackBar = {toggleSnackBar}
             sauceIDs = {(job_execute.environmentType.toLowerCase() === 'saucelabs' && checkArrayLength(_sauceID)) ? _sauceID : []}
             environmentType = {job_execute.environmentType}
             setSauceId = {this.setSauceId}
             selectedSauceId = { tunnelId || (checkArrayLength(_sauceID) ? _sauceID[0] : '') }
             handleClose = {(submit)=>{
                 this.setState({
                    showConfirmationRestartExecution:false,
                 },()=>{
                     if(submit) {
                         const {job_execute, tunnelId} = this.state
                         this.executeFailedTestCases(job_execute, tunnelId==='None'?'':tunnelId)
                     }
                 })
             }}
            />
        )
    }

    getConfirmDialogStopExecution = () => {

        const {showConfirmationStopExecution} =  this.state
        const {showSnackBar} = this.props

        if(!showConfirmationStopExecution) {
            return null
        }
        return (
        <ConfirmDialog 
             title="Stop Execution"
             message= "Are you sure you want to stop the execution of running test suite ?"
             confirmButtonText = "Stop"

             handleClose = {(submit)=>{
                 this.setState({
                    showConfirmationStopExecution:false,
                 },()=>{
                     if(submit) {
                        this.setState({
                            stoppingExecution:true
                        })
                        const {job_execute} = this.state                        
                        this.props.stopTestSuite({'jobIds':[job_execute.jobId]}).then((res)=>{


                            if(res && res.payload && res.payload.jobData && checkIsArray(res.payload.jobData) ) {

                                const stoppedJobs = {...this.state.stoppedJobs}
                                stoppedJobs[job_execute.jobId]  = true;


                                let session_stoppedJobs = {};


                                const _session_stoppedJobs =  sessionStorage.getItem('session_stoppedJobs')

                                if(_session_stoppedJobs) {
                                    try {
                                        const existedSessionStoppedJobs  =  JSON.parse(_session_stoppedJobs)
                                        if(existedSessionStoppedJobs && typeof existedSessionStoppedJobs === 'object') {
                                            session_stoppedJobs = {...existedSessionStoppedJobs}
                                        }
                                    } catch(err) {

                                    }
                                }

                                session_stoppedJobs[job_execute.jobId]  = true;


                                sessionStorage.setItem('session_stoppedJobs',JSON.stringify(session_stoppedJobs));

                                
                                let successMessage = 'Some or all of the test cases of this test suite could not be stopped. It might be because, its already 10 hours these executions are started.';
                                let duration = 12000;
                                if((res.payload.isAllTestSuitesStopped)) {
                                    successMessage = 'Execution stopped';
                                    duration=3000
                                }

                                showSnackBar(successMessage,'',true,duration)
                                this.setState({
                                    stoppingExecution:false,
                                    testSuiteStopped:true,
                                    stoppedJobs,
                                    stoppedTasks:res.payload.jobData[0]

                                })
                                } else {
                                showSnackBar('Error')
                                this.setState({
                                    stoppingExecution:false,
                                    testSuiteStopped:false

                                })
                                }



                        }).catch((err)=>{
                            this.setState({
                                stoppingExecution:false
                             })
                        })

                     }
                 })
             }}
            />
        )
    }

    getExecutionStatusBtn = (exec,testCaseId) => {


        const {classes} = this.props;

        let statusText = '';
        const retryActionActivated = checkKeyInObject(this.state.retryActionActivated,testCaseId)
        if(checkKeyInObject(exec, 'executionStatus')) {

            switch(exec.executionStatus) {
                case 'ERROR':
                    statusText = 'FAILED';
                    break;
                case 'INPROGRESS':
                    statusText = 'In Progress';
                    break;
                default:
                    statusText = exec.executionStatus

            }

        } else {
            statusText = 'N/A'
        }

        let btn = null;
        if(retryActionActivated) {
            btn = <Button variant = "outlined" size="small" className = {classes.execution_status_btn_disabled}>{statusText}</Button>
        } else {

            btn = <Button size="small" className = {classes.execution_status_btn_enabled}>{statusText}</Button>

        }

        return btn;

    }


    getRestartExecutionStatusBtn = (exec,testCaseId) => {


        const {classes} = this.props;

        let statusText = '';

        const retryActionActivated = checkKeyInObject(this.state.retryActionActivated,testCaseId)
        if(checkKeyInObject(exec, 'restartExecutionStatus')) {

            switch(exec.restartExecutionStatus) {
                case 'ERROR':
                    statusText = 'FAILED';
                    break;
                case 'INPROGRESS':
                    statusText = 'In Progress';
                    break;
                default:
                    statusText = exec.restartExecutionStatus

            }

        } else {
            statusText = 'N/A'
        }

        let btn = null;
        if(retryActionActivated) {
            btn = <Button size="small" className = {classes.restart_execution_status_btn_enabled}>{statusText}</Button>

        } else {
            btn = <Button variant = "outlined" size="small" className = {classes.restart_execution_status_btn_disabled}>{statusText}</Button>


        }

        return btn;

    }

    getDeviceIcon = (deviceType='',deviceName='') => {
        const { classes } = this.props;
        switch (deviceType?.toLowerCase()) {
        case 'real':
            return <Tooltip data={deviceName} key={deviceName}><img height={14} src={real_deviceIcon} alt="Real" className={classes.statusIcon} /></Tooltip>

        case 'emulator':
            return <Tooltip data={deviceName} key={deviceName}><img height={14} src={emulator_Icon} alt="Emulator" className={classes.statusIcon} /></Tooltip>
            default:
                return null;

        }

    }
 



    render() {
        const { classes, isLoading, suite, query,executions } = this.props;
        const { downloadFileLoader, previewFileLoader, showLoader, rowExpendStatus, successFiltered, failureFiltered, order, orderBy,executingJobs,stoppedJobs } = this.state;
        const _jobs = this.getAllJobs();
        const _currentPageJobs = Object.keys(_jobs);
        let currentJobs = [];
        _currentPageJobs.forEach((jobId) => {
            currentJobs.push(_jobs[jobId]);
        });
        currentJobs = sort(currentJobs, order, orderBy, orderBy === 'creationTime' ? 'time' : '');
        return (
            <div className={classes.root}>
                <div className={`${classes.tableWrapper} ${classes.maxHeight}`}>
                    {isLoading ? (
                        <Typography className={classes.noDataText} >
                            Jobs loading...
                        </Typography>
                    ) : checkArrayLength(currentJobs) ? (
                        <div className={classes.minHeight}>
                            <div className={classes.tableWrapper2}>
                                <Table>
                                    <EnhancedTableHead
                                        headers={headers}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        rowCount={currentJobs.length}
                                    />
                                    <TableBody style={{ maxHeight: 'calc(100vh - 283px)' }}>
                                        {currentJobs.map((job, ind) => {

                                            if(checkKeyInObject(executingJobs,job.jobId)) {


                                                job.etrs.tasks = job.etrs.tasks.map((task)=>{

                                                    if(this.state.executedJobs && checkIsArray(this.state.executedJobs) && checkArrayLength(this.state.executedJobs)) {

                                                        const executedJob = this.state.executedJobs.find((job)=>{
                                                          const testcasId = task.testcaseId || task.testCaseId
                                                           return  job.testcaseId === testcasId
                                                        });

                                                        if(executedJob) {                                    
                                                            task.restartExecutionStatus =  executedJob.status
                                                        }
                                                        return task


                                                    } 
                                                        return task
                                                    


                                                    
                                                })

                                            }
                                            if(checkKeyInObject(stoppedJobs,job.jobId) &&  this.state.testSuiteStopped) {

                                                if(this.state.stoppedTasks) {
                                                    job.etrs.tasks = this.state.stoppedTasks.etrs.tasks

                                                }
                                            }

                                            const isValidSuiteToExecute =  this.validateBeforeExecuteFailedTestCaes(job);
                                            const isValidSuiteToStop = this.validateBeforeStoppingExecution(job)
                                            const _row = [];
                                            const videUrls = [];
                                            if(job.etrs && job.etrs.tasks && checkIsArray(job.etrs.tasks)) {

                                                job.etrs.tasks.forEach((task)=>{
                                                    if(task.exe)
                                                    videUrls.push()
                                                })

                                            }
                                            

                                            _row.push(
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={job.jobId}
                                                    className={classes.tableRows}
                                                    style={{ minHeight: '60px', backgroundColor: '#FFF', borderBottom: '1px solid #e6e6e6', cursor: 'auto' }}
                                                >
                                                    <div style={{ width: '100%' }}>
                                                        <div style={{ width: '100%', display: 'flex', height: '60px' }}>
                                                            <TableCell width={headers[0].width} className={classes.cell}>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        paddingLeft:
                                                                            rowExpendStatus[0] === job.jobId && rowExpendStatus[1] ? 0 : '10px',
                                                                    }}
                                                                >
                                                                    {rowExpendStatus[0] === job.jobId && rowExpendStatus[1]
                                                                        ? this.currentRowIndicator()
                                                                        : null}
                                                                    <Typography
                                                                        noWrap
                                                                        className={classes.noWrap}
                                                                        style={{
                                                                            color: TABLES_CSS.normalFontColor,
                                                                            fontSize: TABLES_CSS.normalFontSize,
                                                                            display: 'inline-block',
                                                                            paddingLeft: 10,
                                                                        }}
                                                                    >
                                                                        {job.creationTime}
                                                                    </Typography>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        padding:'10px'
                                                                    }}
                                                                    >
                                                                {this.getDeviceIcon(job.deviceType,job.deviceName)}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        padding:'10px'
                                                                    }}
                                                                    >
                                                                {this.getExecutionPlatformIcon(job.platform)}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        padding:'10px'
                                                                    }}
                                                                    >
                                                                    {this.getExecutionBrowserIcon(job.browser,job.browserVersion)}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell width={headers[1].width} className={classes.cell}>
                                                                 <div style={{ paddingLeft: '12px' }}>
                                                                    <Typography
                                                                        noWrap
                                                                        className={classes.noWrap}
                                                                        style={{
                                                                            color: TABLES_CSS.normalFontColor,
                                                                            fontSize: TABLES_CSS.normalFontSize,
                                                                            display: 'inline-block',
                                                                        }}
                                                                    >
                                                                        {job.total}
                                                                    </Typography>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell width={headers[2].width} className={classes.cell}>
                                                                <div style={{ paddingLeft: '10px' }}>
                                                                    <Typography
                                                                        noWrap
                                                                        className={classes.noWrap}
                                                                        style={{
                                                                            color: TABLES_CSS.normalFontColor,
                                                                            fontSize: TABLES_CSS.normalFontSize,
                                                                            display: 'inline-block',
                                                                        }}
                                                                    >
                                                                    {executions.jobs[ind].isSerial ? 'Serial' : 'Parallel'}
                                                                </Typography>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell width={headers[3].width} className={classes.cell}>
                                                            <div style={{ paddingLeft: '10px' }}>
                                                                <Typography noWrap className={classes.noWrap}
                                                                    style={{
                                                                        color: TABLES_CSS.normalFontColor,
                                                                        fontSize: TABLES_CSS.normalFontSize,
                                                                        display: 'inline-block',
                                                                    }}
                                                                >
                                                                        <span
                                                                            
                                                                            className={[
                                                                                classes.filterButton,
                                                                                !successFiltered[job.jobId]
                                                                                    ? classes.successFilled
                                                                                    : classes.successUnFilled,
                                                                            ].join(' ')}
                                                                            onClick={() =>
                                                                                this.handleFilterClick(
                                                                                    'successFiltered',
                                                                                    !successFiltered[job.jobId],
                                                                                    job.jobId,
                                                                                )
                                                                            }
                                                                        >
                                                                            <Tooltip data="Success">{job.totalSuccess}</Tooltip>
                                                                        </span>
                                                                    </Typography>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell width={headers[4].width} className={classes.cell}>
                                                                <div style={{ paddingLeft: '10px' }}>
                                                                    <Typography
                                                                        noWrap
                                                                        className={classes.noWrap}
                                                                        style={{
                                                                            color: TABLES_CSS.normalFontColor,
                                                                            fontSize: TABLES_CSS.normalFontSize,
                                                                            display: 'inline-block',
                                                                        }}
                                                                    >
                                                                        <span
                                                                            data-rh="Failed"
                                                                            className={[
                                                                                classes.filterButton,
                                                                                !failureFiltered[job.jobId]
                                                                                    ? classes.failureFilled
                                                                                    : classes.failureUnFilled,
                                                                            ].join(' ')}
                                                                            onClick={() =>
                                                                                this.handleFilterClick(
                                                                                    'failureFiltered',
                                                                                    !failureFiltered[job.jobId],
                                                                                    job.jobId,
                                                                                )
                                                                            }
                                                                        >
                                                                            <Tooltip data="Failed">{job.totalFail}</Tooltip>
                                                                        </span>
                                                                    </Typography>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                width="3%"                                                        >
                                                            {
                                                                rowExpendStatus[0] === job.jobId && rowExpendStatus[1] ?
                                                                (

                                                                    !this.state.executingFailedTestCases ?

                                                                   ( <Tooltip data  = "Execute Failed Test Cases" >
                                                                    <IconButton  className={classes.IconButton} onClick={(e) => {
                                                                        e.stopPropagation();  
                                                                        this.setState({
                                                                            showConfirmationRestartExecution:true,
                                                                            job_execute:job
                                                                        })
                                                                    }}
                                                                     disabled = {!isValidSuiteToExecute || this.state.executingFailedTestCases}
                                                                    >
                                                                        <FlashOn
                                                                            style={{margin:'6px'}}
                                                                            className={[

                                                                                        classes.moreIcons,
                                                                                        `${isValidSuiteToExecute ? classes.moreIconsBtnEnabled : classes.moreIconsBtnDisabled}`
                                                                                      ].join(' ')}
                                                                            aria-label="flashOnIcon"
                                                                            id="flashOnIcon"
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip> ) : (
                                                                <div>
                                                                    <CircularProgress size={22} />
                                                                </div>)

                                                            // <Button
                                                            //     className={classes.execFailedTestCasesBtn}
                                                            //     variant="contained"
                                                            //     color="primary"
                                                            //     disabled={this.state.executingFailedTestCases}
                                                            //  //   style={{ backgroundColor: isNextDisabled ? '#737070' : '#0092e6' }}
                                                            //     onClick={() => this.executeFailedTestCases(job)}
                                                            // >
                                                            //     <Typography style={{ color: '#fff' }}>Execute Failed</Typography>
                                                            // </Button>
                                                                ) :<div></div>

                                                            }
                                                                
                                                        </TableCell>

                                                        <TableCell width="3%"  >
                                                            {
                                                                rowExpendStatus[0] === job.jobId && rowExpendStatus[1] ?
                                                                (
                                                                    !this.state.stoppingExecution ? 

                                                                    (
                                                                    <Tooltip  data  = "Stop" >
                                                                    <IconButton  className={classes.IconButton} onClick={(e) => {
                                                                        e.stopPropagation();  
                                                                        this.setState({
                                                                              showConfirmationStopExecution:true,
                                                                              job_execute:job
                                                                        })
                                                                    }}
                                                                    disabled = {!isValidSuiteToStop || this.state.stoppingExecution}

                                                                    >
                                                                        <StopIcon
                                                                            style={{margin:'6px'}}
                                                                            className={[

                                                                                        classes.moreIcons,
                                                                                        `${isValidSuiteToStop ? classes.stopIcon : classes.moreIconsBtnDisabled}`
                                                                                      ].join(' ')}
                                                                            aria-label="stopExecution"
                                                                            id="stopExecution"
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                ) : (
                                                                <div>
                                                                    <CircularProgress size={22} />
                                                                </div>

                                                                )

                                                                ) :(
                                                                    <div></div>
                                                                )

                                                            }


                                                            </TableCell>
                                                        <TableCell width="4%" className={classes.cell} style={{ justifyContent: 'flex-end' }}>
                                                                <ExpandBtn
                                                                    flag={rowExpendStatus[0] === job.jobId && rowExpendStatus[1]}
                                                                    action={() => this.handleExpend(job.jobId)}
                                                                />
                                                            </TableCell>
                                                        </div>
                                                    {((job.total) !== 0) ? <div>
                                                        {rowExpendStatus[0] === job.jobId && rowExpendStatus[1] ? (
                                                            <TableRow
                                                            style={{ height: '40px', backgroundColor: '#f9f9f9', cursor: 'auto' }} key={`${job.jobId}-subHeader`}>
                                                                <TableCell padding="none" colSpan="2" className={classes._cell} >
                                                                    <TableSortLabel >
                                                                        <div style={{ paddingLeft: '20px' }} >
                                                                            <Typography variant="body2" style={{
                                                                                fontSize: TABLES_CSS.headingFontSize,
                                                                                color: TABLES_CSS.headingFontColor,
                                                                                fontWeight: 500,
                                                                            }} noWrap>
                                                                                Case
                                                                        </Typography>
                                                                        </div>
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                                <TableCell padding="none" className={classes._cell}>
                                                                        <TableSortLabel >
                                                                        <div style={{ paddingLeft: '10px' }}>
                                                                            <Typography variant="body2" style={{
                                                                                fontSize: TABLES_CSS.headingFontSize,
                                                                                color: TABLES_CSS.headingFontColor,
                                                                                fontWeight: 500,
                                                                            }}
                                                                            noWrap>
                                                                                Project
                                                                        </Typography>
                                                                        </div>
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                                <TableCell padding="none" className={classes._cell}>
                                                                    <TableSortLabel >
                                                                        <div style={{ paddingLeft: '10px' }}>
                                                                            <Typography variant="body2" style={{
                                                                                fontSize: TABLES_CSS.headingFontSize,
                                                                                color: TABLES_CSS.headingFontColor,
                                                                                fontWeight: 500,
                                                                            }} noWrap>
                                                                                Execution Status
                                                                        </Typography>
                                                                        </div>
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                                <TableCell padding="none" className={classes._cell}>
                                                                    <TableSortLabel >
                                                                        <div style={{ paddingLeft: '10px' }}>
                                                                            <Typography variant="body2" style={{
                                                                                fontSize: TABLES_CSS.headingFontSize,
                                                                                color: TABLES_CSS.headingFontColor,
                                                                                fontWeight: 500,
                                                                            }} noWrap>
                                                                                Retry Execution Status
                                                                            </Typography>
                                                                        </div>
                                                                    </TableSortLabel>
                                                                </TableCell>

                                                                <TableCell padding="none" className={classes._cell}>
                                                                    <TableSortLabel >
                                                                        <div style={{ paddingLeft: '10px' }}>
                                                                            <Typography variant="body2" style={{
                                                                                fontSize: TABLES_CSS.headingFontSize,
                                                                                color: TABLES_CSS.headingFontColor,
                                                                                fontWeight: 500,
                                                                            }} noWrap>
                                                                                Actions
                                                                        </Typography>
                                                                        </div>
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                                {/* <TableCell padding={'none'} className={classes._cell}>
                                                            <TableSortLabel >
                                                                <div style={{ paddingLeft: '10px' }}>
                                                                    <Typography variant="body2" style={{
                                                                        fontSize: TABLES_CSS.headingFontSize,
                                                                        color: TABLES_CSS.headingFontColor,
                                                                        fontWeight: 500,
                                                                    }} noWrap>
                                                                        Test Execution Video
                                                                        </Typography>
                                                                </div>
                                                            </TableSortLabel>
                                                        </TableCell> */}
                                                            </TableRow>
                                                        ) : null}
                                                        {rowExpendStatus[0] === job.jobId && rowExpendStatus[1] ?
                                                            (job._testCaseArray.map((testCase, index) => {
                                                                let exec = checkKeyInObject(job, 'etrs') && checkArrayLength(job.etrs.tasks) &&
                                                                    job.etrs.tasks.find(task => Number(task.testcaseId) === Number(testCase.testCaseId));
                                                                exec = checkObject(exec) ? exec : {};
                                                                const projectName = checkKeyInObject(suite, 'projectName') ? suite.projectName : testCase.projectName;
                                                                const {testCaseId} = testCase;
                                                                const actionBtnColor = checkKeyInObject(this.state.retryActionActivated,testCaseId) ? 'rgb(243, 155, 49)' : '#5f94fe';
                                                                const retryActionActivated = checkKeyInObject(this.state.retryActionActivated,testCaseId)
                                                                let reportUrl ='';
                                                                let executionVideoUrl = null;
                                                                if(retryActionActivated) {
                                                                    reportUrl = exec.restartExecutionReportUrl
                                                                    executionVideoUrl = exec.restartExecutionVideoUrl
                                                                } else {
                                                                    reportUrl = exec.reportUrl
                                                                    executionVideoUrl = exec.executionVideoUrl

                                                                }
                                                                const showempyspace = !executionVideoUrl


                                                                


                                                                return (
                                                                    <TableRow
                                                                        hover
                                                                        tabIndex={-1}
                                                                        style={{
                                                                            height: '40px',
                                                                            display: rowExpendStatus[0] === job.jobId && rowExpendStatus[1] ? 'table-row' : 'none',
                                                                            backgroundColor: '#fff',
                                                                            cursor: 'auto'
                                                                        }}
                                                                        className={classes.tableRows1}
                                                                        key={`${job.jobId  }${  testCase.testCaseId}`}
                                                                    >
                                                                        <TableCell
                                                                            padding="none"
                                                                            className={classes._cell}
                                                                        >
                                                                            <div style={{ paddingLeft: '20px' }}>
                                                                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                                                                    <Typography
                                                                                        noWrap
                                                                                        style={{
                                                                                            fontSize: TABLES_CSS.normalFontSize,
                                                                                            color: TABLES_CSS.normalFontColor,
                                                                                            display: 'inline-block',
                                                                                            maxWidth: '150px',
                                                                                        }}
                                                                                    // className={classes.noWrap}
                                                                                    >
                                                                                        <Tooltip data={testCase.testCaseName ? testCase.testCaseName : null}>
                                                                                            {testCase.testCaseName}
                                                                                        </Tooltip>
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            className={classes._cell}
                                                                        >
                                                                            <div style={{ maxWidth:'25vh',paddingLeft: '10px' }}>
                                                                                <Typography
                                                                                    noWrap
                                                                                    style={{
                                                                                        fontSize: TABLES_CSS.normalFontSize,
                                                                                        color: TABLES_CSS.normalFontColor,
                                                                                        display: 'inline-block',

                                                                                    }}
                                                                                    className={classes.noWrap}
                                                                                >
                                                                                    <Tooltip data={projectName || null}>
                                                                                        {projectName}
                                                                                    </Tooltip>
                                                                                </Typography>
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            className={classes._cell}
                                                                        >
                                                                            <div style={{height:'25px', paddingLeft: '10px' }}>
                                                                                <Typography
                                                                                    noWrap
                                                                                    style={{
                                                                                        fontSize: TABLES_CSS.normalFontSize,
                                                                                        color: TABLES_CSS.normalFontColor,
                                                                                        display: 'inline-block',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    className={classes.noWrap}
                                                                                    onClick = {()=>{
                                                                                        const retryActionActivated = {}
                                                                                        retryActionActivated[exec.testCaseId] = false;
                                                                                        this.setState({
                                                                                            retryActionActivated
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    {this.getExecutionStatusBtn(exec,testCaseId)}

                                                                                </Typography>
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            className={classes._cell}
                                                                        >
                                                                            <div style={{ height:'25px', paddingLeft: '10px' }}>
                                                                                <Typography
                                                                                    noWrap
                                                                                    style={{
                                                                                        fontSize: TABLES_CSS.normalFontSize,
                                                                                        color: TABLES_CSS.normalFontColor,
                                                                                        display: 'inline-block',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    className={classes.noWrap}
                                                                                    onClick = {()=>{
                                                                                        const retryActionActivated = {}
                                                                                        retryActionActivated[exec.testcaseId] = true;
                                                                                        this.setState({
                                                                                            retryActionActivated
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    {this.getRestartExecutionStatusBtn(exec,testCaseId)}
                                                                                    
                                                                                </Typography>
                                                                            </div>
                                                                        </TableCell>

                                                                        {/* These four icons are placed here on request of Puneet */}
                                                                        <TableCell
                                                                            className={classes._cell}
                                                                        >
                                                                            <div style={{ paddingLeft: '10px' }}>
                                                                                <Typography
                                                                                    noWrap
                                                                                    style={{
                                                                                        fontSize: TABLES_CSS.normalFontSize,
                                                                                        color: TABLES_CSS.normalFontColor,
                                                                                        display: 'inline-block',
                                                                                    }}
                                                                                    className={classes.noWrap}
                                                                                >
                                                                                    <Tooltip data="Preview">
                                                                                        <IconButton
                                                                                            key="btn1"
                                                                                            className={classes.iconButtonStyle}
                                                                                            aria-label="ViewTestCase"
                                                                                            onClick={() => !previewFileLoader[exec.executionId] ? this.downloadExecuteTask(reportUrl, checkKeyInObject(this.state.retryActionActivated,testCaseId)   ?  exec.restartExecutionId : exec.executionId,'open',exec.executionName, exec.testcaseId) : null}
                                                                                            disabled={!reportUrl}
                                                                                        >
                                                                                            {!previewFileLoader[exec.executionId]
                                                                                                ?
                                                                                                <FontAwesomeIcon style = {{color:actionBtnColor}} icon={faEye} aria-label="faEye" id="faEye" className={reportUrl ? classes.eyeIcon : classes.eyeIconDisabled} />
                                                                                                : <CircularProgress size={22} />
                                                                                            }
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    <Tooltip data="Download">
                                                                                        <IconButton
                                                                                            key="btn2"
                                                                                            className={classes.iconButtonStyle}
                                                                                            aria-label="DownloadTestCase"
                                                                                            onClick={() => !downloadFileLoader[exec.executionId] ? this.downloadExecuteTask(reportUrl,checkKeyInObject(this.state.retryActionActivated,testCaseId)   ? exec.restartExecutionId : exec.executionId, 'download', exec.executionName, exec.testcaseId) : null}
                                                                                            disabled={!reportUrl}
                                                                                        >
                                                                                            {!downloadFileLoader[exec.executionId]
                                                                                                ?
                                                                                                <FontAwesomeIcon  style = {{color:actionBtnColor}} icon={faDownload} aria-label="faDownload" id="faDownload" className={reportUrl ? classes.downloadIcon : classes.downloadIconDisabled} />
                                                                                                : <CircularProgress size={22} />
                                                                                            }

                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    {   executionVideoUrl &&
                                                                                        <Tooltip data="Execution Video">
                                                                                            <IconButton
                                                                                                key="btn3"
                                                                                                className={classes.iconButtonStyle}
                                                                                                aria-label="VideoTestCase"
                                                                                                onClick={() => {
                                                                                                    if (executionVideoUrl)
                                                                                                        this.showExecutionVideo(executionVideoUrl, testCase.testCaseName);
                                                                                                }}
                                                                                                disabled={!executionVideoUrl}
                                                                                            >
                                                                                                <OndemandVideo style={{color:actionBtnColor}} className={executionVideoUrl ? classes.playIcon : classes.playIconDisable} aria-label="ondemandVideoIcon" id="ondemandVideoIcon" />
                                                                                            </IconButton>
                                                                                      
                                                                                        </Tooltip>
                                                                                    }
                                                                                    {
                                                                                        showempyspace &&
                                                                                        <span style={{display:'inline-block',width:'40px'}}></span>

                                                                                    }
                                                                                    
                                                                                    <Tooltip data={GOTO_STEP_EDITOR}>
                                                                                        <IconButton
                                                                                            key="btn4"
                                                                                            className={classes.iconButtonStyle}
                                                                                            aria-label={GOTO_STEP_EDITOR}
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                e.stopPropagation();
                                                                                                this.handleGotoTestCase(testCase.testCaseId, exec.projectId);
                                                                                            }}
                                                                                            disabled={!testCase.testCaseId}
                                                                                        >
                                                                                            <FontAwesomeIcon style = {{color:actionBtnColor}} icon={faChevronRight} aria-label="faChevronRight" id="faChevronRight" className={testCase.testCaseId ? classes.gotoIcon : classes.gotoIconDisabled} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Typography>
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })
                                                            ) : null}
                                                    </div> : rowExpendStatus[0] === job.jobId && rowExpendStatus[1] && <div className={classes.noDataAvailable} >
                                                        No test cases{query ? ' found' : ' available'}
                                                    </div>}
                                                </div>
                                            </TableRow>);
                                            return [..._row];
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    ) : (<div className={classes.minHeight}>
                        <Typography className={classes.noDataText} >
                            No Executions available.
                        </Typography>
                    </div>)}
                </div>
                {
                    showLoader
                        ? <Loader />
                        : null
                }
                {
                    this.getConfirmDialogRestartExecution()
                }
                {
                    this.getConfirmDialogStopExecution()
                }
                <TableFooter
                    count={currentJobs.length} // https://github.com/Autonomiq/WebUIRedux/issues/1657
                    showPagination={false}
                    applyCustomClasses
                    labelForCounter="Executions"
                    countAvatar={getCountAvatar(currentJobs.length)}
                    containerStyle={{ marginLeft: '-25px', marginRight: '-25px' }}
                />
            </div >
        );
    }
}

SuiteReportDetailCaseTable.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    suite: PropTypes.instanceOf(Object).isRequired,
    executions: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => {
    return {
        // general reducer
        query: state.generalReducer.queryValue,
        // project reducer
        sauceID: state.projectReducer.sauceID,
        isLoading: state.projectReducer.isLoading,
        wsRunningCaseData: state.projectReducer.wsRunningCaseData,
        executionss: state.projectReducer.executions
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        clearSnackBar: () => dispatch(ModalActions.clearSnackbar()),
        // Modal Action
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        stopTestSuite: (...args) => dispatch(TestSuiteActions.stopTestSuite(...args)),
        executeFailedTestCases: (jobId,testSuiteId,requestData) => dispatch(SuiteReportAction.executeFailedTestCases(jobId,testSuiteId,requestData)),
        getTunnelIDForSauceLabs: () => dispatch(ProjectActions.getTunnelIDForSauceLabs()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SuiteReportDetailCaseTable));
