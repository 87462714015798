import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AddIconCircle from '@material-ui/icons/AddCircle';
import { CircularProgress } from '@material-ui/core';
import Table from '../../../components/Table/Table';
import IconButton from '@material-ui/core/IconButton';
import { checkArrayLength, checkKeyInObject, sort, resizeAllCols, replaceCharsForStepsCSV } from '../../../utils/utils';
import EnhancedTableHead from '../../../components/EnhancedTable/EnhancedTableHead_v2';
import { FlowActions, GeneralActions, ProjectActions } from '../../../store/actions';
import FlowModal from '../../modal/Flow/FlowModal';
import FileDownload from '@material-ui/icons/GetApp';
import ImageSrc from '../../../components/NoDataToShow/assests/layer-group.svg';
import NoDataToShow from '../../../components/NoDataToShow';
import { ProjectDetailsFlowBody } from './FlowBody';
import { styles } from './styles';
import JSONTOCsv from '../../../services/JSONToCsv';
import TableFooter from '../../../common/TableFooter';
import CustomButton from '../../../components/Button/CustomButton';
import { CustomDownloadModal } from '../../../components/CustomModal/CustomDownloadModal';
import Tooltip from '../../../components/Tooltip';

const headers = [
    { id: 'name', sortable: true, numeric: false, disablePadding: false, label: 'FLOW', styles: { paddingLeft: 20 }, width: 'calc(calc(calc(100% / 36) * 12) - 200px)' },
    {
        id: 'creationTime',
        sortable: true,
        numeric: false,
        disablePadding: false,
        label: 'CREATED',
        styles: { paddingLeft: 20},
        width: 'calc(calc(100% / 36) * 9)',
    },
    // { id: 'noOfTestCases', sortable: false, numeric: false, disablePadding: true, label: '# of Cases', styles: { paddingLeft: 10 }, width: "calc(70% / 4)", },
    { id: 'steps', sortable: true, numeric: false, disablePadding: true, label: 'TOTAL STEPS', styles: { paddingLeft: 10}, width: '20%' },
    {
        id: 'clone',
        label: 'CLONE',
        width: '13%',
        centerV: true,
        color: 'rgb(56 55 55 / 50%)',
       
    },
    {
        id: 'delete',
        label: 'DELETE',
        width: '13%',
        centerV: true,
        color: 'rgb(56 55 55 / 50%)',
      
    },
    {
        id: 'download',
        label: 'DOWNLOAD',
        width: '13%',
        centerV: true,
        color: 'rgb(56 55 55 / 50%)',
        
    },
];

let request = null;
class Block extends Component {
    state = {
        action: '',
        blockData: {},
        expanded: 'flowsData',
        isBlockModalOpen: false,
        orderBy: 'creationTime',
        order: 'desc',
        sortedBlocks: this.props.blocks,
        rowsPerPage: 20,
        page: 0,
        selectedBlocks: [],
    };

    componentDidMount() {
        const {
            getUserVariables,
            project: { projectId },
            user: { accountId },
            blocks,
        } = this.props;
        const caseSettingRows = localStorage.getItem('rowsCasePerPage');

        getUserVariables(accountId, projectId);

        if (accountId && projectId && (request !== projectId || !checkArrayLength(blocks))) {
            request = projectId;
            this.props.getFlowDataByProject(projectId);
        }
        if (caseSettingRows) {
            this.updateState({
                rowsPerPage: parseInt(caseSettingRows, 10),
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            project: { projectId },
            user: { accountId },
            blocks,
            refreshFlows,
        } = nextProps;
        const { sortedBlocks,pageNo } = this.state;
        let prevPageNo = parseInt( pageNo || 0)
        if(!nextProps.query) {
            this.setState({
                page : prevPageNo
            })
        }
        if (accountId && projectId && ((request !== projectId && !checkArrayLength(blocks)) || refreshFlows)) {
            request = projectId;
            this.setState({ sortedBlocks: [] });
            nextProps.getFlowDataByProject(projectId);
        }
        if (
            this.isUpdateBlock ||
            (!checkArrayLength(blocks) && checkArrayLength(sortedBlocks)) ||
            (checkArrayLength(blocks) && !checkArrayLength(sortedBlocks))
        ) {
            this.setState({ sortedBlocks: blocks });
        }
    }

    componentDidUpdate() {
        resizeAllCols(this.colsWidth);
    }

    componentWillUnmount() {
        this.props.queryRemove();
    }

    isUpdateBlock = false;

    anchorDownloadEl = null;

    colsWidth = {};

    handleChangePage = (page) => {
        this.setState({ page });
        if(!this.props.query) {
           this.setState({
               pageNo:page
           })
        }
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleChangeRowsPerPage = (event) => {
        localStorage.setItem('rowsCasePerPage', event.target.value);
        this.setState({ rowsPerPage: event.target.value });
    };

    handleChange = (panel) => {
        this.setState((state) => ({
            expanded: state.expanded !== panel ? panel : false,
        }));
    };

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';
        const flowData = [...this.props.blocks];
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        const sortedBlocks = sort(flowData, order, orderBy, orderBy === 'steps' ? 'len' : orderBy === 'creationTime' ? 'date' : '');

        this.setState({ sortedBlocks, order, orderBy });
    };

    shouldUpdateBlock = () => {
        this.isUpdateBlock = true;
    };

    showBlock = () => {};

    /* create Block work start */

    // openCreateBlock = () => {
    //     const { selectedTestCase: { testSteps, discoveryId, recoverTestSteps }, user: { accountId } } = this.props;
    //     const { selectedSteps } = this.state;
    //     let recoverSteps = false;
    //     let currentTabSteps = [];
    //     if (checkArrayLength(testSteps)) {
    //         currentTabSteps = testSteps;
    //     } else {
    //         recoverSteps = true;
    //         currentTabSteps = recoverTestSteps
    //     }
    //     // we have only instrNum in selectedSteps
    //     let steps = currentTabSteps.filter((step) => selectedSteps.some((instrNum) => step.instrNum === instrNum));
    //     if (recoverSteps) {
    //         steps = steps.map((s) => {
    //             delete s.instrNum;
    //             return s;
    //         })
    //     }
    //     if (checkArrayLength(steps)) {
    //         const obj = {
    //             accountId,
    //             projectId: discoveryId,
    //             name: '',
    //             steps
    //         }
    //         this.toggleBlockModal(true, obj);
    //     }

    // }

    toggleBlockModal = (isBlockModalOpen, blockData = {}, action = '') => {
        this.setState({ isBlockModalOpen, blockData, action });
        if (action) {
            this.isUpdateBlock = false;
        }
    };

    /* download popup start */
    handleDownloadPopupToggle = () => {
        // Creating dataSet for XLSX
        const { sortedBlocks } = this.state;
        const filtersortedBlocks = [];
        if (checkArrayLength(sortedBlocks)) {
            sortedBlocks.forEach((block) => {
                if (checkArrayLength(block.steps) && checkKeyInObject(block, 'name')) {
                    filtersortedBlocks.push(block);
                }
            });
        }
        const downloadData = [
            {
                columns: ['Flow Name', 'Instruction', 'Data', 'Expected Result'],
                data: [],
            },
        ];

        filtersortedBlocks.forEach((block) => {
            if (checkKeyInObject(block, 'steps')) {
                block.steps.forEach((step, ind) => {
                    let flowName;
                    if (ind === 0) {
                        flowName = `${block.name}`;
                    } else {
                        flowName = '';
                    }
                    const instr = `${replaceCharsForStepsCSV(step.instr)}`;
                    const stepData = `${replaceCharsForStepsCSV(step.data)}`;
                    const expectedResult = `${replaceCharsForStepsCSV(step.expectedResults)}`;

                    downloadData[0].data.push([
                        { value: flowName, style: { alignment: { vertical: 'top' } } },
                        { value: instr, style: { alignment: { vertical: 'top' } } },
                        { value: stepData, style: { alignment: { vertical: 'top' } } },
                        { value: expectedResult, style: { alignment: { vertical: 'top' } } },
                    ]);
                });
            }
        });
        return downloadData;
    };
    /* download popup end */

    /* download starts */
    handleDownloadBlock = (downloadType, projectFile, blocks) => {
        let { sortedBlocks } = this.state;

        if (checkArrayLength(blocks) && downloadType === 'csv') {
            this.downloadRequestCase(blocks, projectFile);
        }
        else if (checkArrayLength(sortedBlocks) && downloadType === "csv") {
            this.downloadRequestCase(sortedBlocks, projectFile);
        }
        this.handleDownloadPopupToggle(false);
    };

    handleSelectAllClick = (e) => {
        let sortedBlocks = [...this.state.sortedBlocks];
        let flows = e.target.checked ? sortedBlocks.map(flw => flw.name) : [];
        this.setState({ selectedBlocks: flows });
    };

    handleCheckUncheck = (bool, name) => {
        const { selectedBlocks } = this.state;
        const _sortedBlocks = [...selectedBlocks];

        if(bool){
            _sortedBlocks.push(name);
        }else {
            let ind = _sortedBlocks.indexOf(name);
            _sortedBlocks.splice(ind, 1);
        }
        this.setState({ selectedBlocks: _sortedBlocks });
    };


    // Need to move this to utils
    downloadRequestCase = async (sortedBlocks, projectFile) => {
        // Creating dataSet for CSV
        if (sortedBlocks) {
            const filtersortedBlocks = [];
            sortedBlocks.forEach((block) => {
                if (checkArrayLength(block.steps) && checkKeyInObject(block, 'name')) {
                    filtersortedBlocks.push(block);
                }
            });
            try {
                const header = {
                    'Flow Name': 'Flow Name',
                    Instruction: 'Instruction',
                    Data: 'Data',
                    'Expected Result': 'Expected Result',
                };
                const data = [];
                filtersortedBlocks.forEach((block) => {
                    // console.log({ 'block': block })
                    if (checkKeyInObject(block, 'steps')) {
                        block.steps.forEach((step, ind) => {
                            const obj = {};
                            if (ind === 0) {
                                obj['Flow Name'] = `"${block.name.replace(/["]{1}/g, '""')}"`;
                            } else {
                                obj['Flow Name'] = '""';
                            }
                            obj.Instruction = `"${replaceCharsForStepsCSV(step.instr).replace(/["]{1}/g, '""')}"`;
                            obj.Data = `"${replaceCharsForStepsCSV(step.data).replace(/["]{1}/g, '""')}"`;
                            obj['Expected Result'] = `"${replaceCharsForStepsCSV(step.expectedResults).replace(/["]{1}/g, '""')}"`;
                            data.push(obj);
                        });
                    }
                    // return arr;
                });
                JSONTOCsv.CSVFile(header, data, `${projectFile.projectName}_Flows`);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }
    };
    /* download ends */

    /* create Block work end */

    render() {
        const {
            classes,
            project: { projectId, projectName },
            query,
            user,
            isLoading,
        } = this.props;
        const { action, blockData, isBlockModalOpen, order, orderBy, sortedBlocks, rowsPerPage, page, selectedBlocks } = this.state;
        let _sortedBlocks = sortedBlocks;

        if (query && checkArrayLength(_sortedBlocks)) {
            _sortedBlocks = _sortedBlocks.filter(
                (block) => checkKeyInObject(block, 'name') && block.name.toLowerCase().includes(query.toLowerCase()),
            );
        }
        let currentBlocks = sort(_sortedBlocks, order, orderBy, orderBy === 'steps' ? 'len' : orderBy === 'creationTime' ? 'date' : '').slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        );

        const dataSet = this.handleDownloadPopupToggle();

        return (
            <div>
                <div className={classes.minHeight264}>
                    <div style = {{ padding: 15, backgroundColor: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CustomButton title="CREATE NEW FLOW" onClick={() => this.toggleBlockModal(true, {}, 'addFromHeader')} />
                        <div style = {{  display: 'flex' }}>
                           <div style = {{ marginLeft: 10 }}>
                                {/* start dotted menu */}
                                { checkArrayLength(currentBlocks) ? (<CustomDownloadModal
                                        key="DownloadFlow"
                                        handleSubmit={(downloadType, projectFile) => {
                                            this.handleDownloadBlock(downloadType, projectFile);
                                        }}
                                        dataSet={dataSet}
                                        keepMounted
                                        calledFrom="flow"
                                        projectName={projectName}
                                        containerStyle={{ height: 'unset' }}
                                        btn={
                                            <Tooltip data="Download Flow">
                                                <IconButton className={classes.childIconButton} disabled={!checkArrayLength(currentBlocks)} id="downloadFlow" aria-label="Download">
                                                    <FileDownload
                                                        className={!checkArrayLength(currentBlocks) ? classes.childIconDisabled : classes.childIcon}
                                                        aria-label="fileDownloadIcon"
                                                        id="fileDownloadIcon"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    />
                                ) : null}
                                {/* end dotted menu */}
                            </div>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className={classes.minHeight264}>
                            <CircularProgress style={{ margin: '100px 50% 0px', color: '#4885ed' }} size={60} />
                        </div>
                    ) : checkArrayLength(currentBlocks) ? (
                        <div style={{ width: '100%' }}>
                            <div className={classes.tableScrollParent}>
                                <div className={classes.tableContainer}>
                                    <Table aria-labelledby="tableTitle">
                                        <EnhancedTableHead
                                            headers={headers}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={this.handleRequestSort}
                                            colsWidth={this.colsWidth}
                                            //showSelectBtn
                                            //showDeleteBtn
                                            deletebuttonColIndex={1}
                                            noResizeColsIndex={[0, 1, 6, 7, 8, 9, 10, 11]}
                                            numSelected={selectedBlocks.length}
                                            onSelectAllClick={(e) => {
                                                this.handleSelectAllClick(e, _sortedBlocks);
                                            }}
                                            onDeleteClick={() => {}}
                                            rowCount={currentBlocks.length}
                                        />
                                        <ProjectDetailsFlowBody
                                            headers={headers}
                                            shouldUpdateBlock={this.shouldUpdateBlock}
                                            blocks={currentBlocks}
                                            showBlock={this.toggleBlockModal}
                                            deleteFlow={this.props.deleteFlow}
                                            user={user}
                                            handleCheckUncheck={this.handleCheckUncheck}
                                            selectedBlocks={selectedBlocks}
                                            handleDownloadPopupToggle={this.handleDownloadPopupToggle}
                                            handleDownloadBlock={this.handleDownloadBlock}
                                            projectName={projectName}
                                            disableDownloadFlow={!checkArrayLength(currentBlocks)}
                                        />
                                    </Table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <NoDataToShow
                            isIcon={true}
                            imgSrc={ImageSrc}
                            IconSrc={AddIconCircle}
                            selectedTab="flow"
                            buttonTitle = "CREATE NEW FLOW"
                            projectSystemId={projectId}
                            onClick={() => this.toggleBlockModal(true, {}, 'addFromHeader')}
                        />
                    )}
                    {isBlockModalOpen && (
                        <FlowModal
                            blockData={blockData}
                            open={isBlockModalOpen}
                            handleClose={this.toggleBlockModal}
                            modalName={action}
                            projectId={projectId}
                            shouldUpdateBlock={this.shouldUpdateBlock}
                        />
                    )}
                </div>
                <TableFooter
                    count={_sortedBlocks.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    applyCustomClasses
                    labelRowsPerPage="Flows per page:"
                    containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                />
            </div>
        );
    }
}

Block.propTypes = {
    appUrl: PropTypes.string.isRequired,
    blocks: PropTypes.array,
    classes: PropTypes.shape({}).isRequired,
    project: PropTypes.shape({}).isRequired,
    query: PropTypes.string,
    user: PropTypes.shape({}).isRequired,
};

Block.defaultProps = {
    blocks: [],
    query: '',
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.flowReducer.isLoading,
        blocks: state.flowReducer.blocks,
        refreshFlows: state.flowReducer.refreshFlows,
        user: state.authReducer.user,
        // general reducer
        query: state.generalReducer.queryValue,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // FlowData reducser
        // loadingData: () => dispatch(FlowActions.loadingFlow()),
        //
        getFlowDataByProject: (...args) => dispatch(FlowActions.getFlowDataByProject(...args)),
        deleteFlow: (obj) => dispatch(FlowActions.deleteFlow(obj)),
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        getUserVariables: (...args) => dispatch(ProjectActions.getUserVariables(...args)),
    };
};

export const ProjectDetailsFlow = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Block));
