import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    title: {
        fontFamily: 'Roboto !mportant',
        fontSize: 24,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.97)'
    },
    description: {
        marginTop:15,
        fontFamily: 'Roboto',
        fontSize: 16,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.97)'
    },
    container: {

        padding:30,
        marginTop:'-30px'

    }
})
const FirstProjectHeaderInfo = ({classes}) => {

    const [title] = useState('Create your first project');
    const [description] = useState('All the test cases and test suites you create will live under aproject.')

    return (
        <React.Fragment>
            <div className={classes.container}>
            <div className={classes.title}>{title}</div>
            <div className={classes.description}>{description}</div>
            </div>
         </React.Fragment>   
    )

}

export default withStyles(styles)(FirstProjectHeaderInfo);
