import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { DialogContent, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import './index.css';
import cloneDeep from 'lodash/cloneDeep'
import { generalModalActions, ProjectActions, AccountActions, ModalActions } from '../../../store/actions';
import DatePicker from '../../modal/GeneralModal/DatePicker';
import '../../../assets/css/react-datepicker.css';
import { BLACK_FONT, THREE_DOT_MENU_CSS } from '../../../common/cssConstants';
import {
    checkKeyInObject,
    checkArrayLength,
    capitalizeString,
    removeIndexFromArray,
    pushInMidArray,
    checkObject,
    getMinNearest15Interval,
    isObjectEmpty,
} from '../../../utils';
import { getDevices, getSelectedDevice } from '../../../utils/ExecuteScriptModalUtils';
import SchedulingTime from '../../plan/suite/Modal/SchedulingTime';
import config from '../../../config';
import { ExecuteScriptModalConstant } from '../../projectDetails/Script/constant';
import defaultConfig from '../../../config';
import ExternalGrid from '../../projectDetails/Script/ExternalGrid';
import ExecutionErrorDetails from '../../projectDetails/Script/ExecutionErrorDetails';
import ExecuteScriptConfigurationDetails from '../../projectDetails/Script/ExecuteScriptConfigurationDetails';
import PlatformBrowserForLocalExecution from '../../projectDetails/Script/PlatformBrowserForLocalExecution';
import PlatformBrowserForSauceExecution from '../../projectDetails/Script/PlatformBrowserForSauceExecution';
import PlatformBrowserSelectionContainer from '../../projectDetails/Script/PlatformBrowserSelectionContainer';

// Platforms Icons
import windows_Icon from '../../../assets/icons/platforms/windows.svg';
import linux_Icon from '../../../assets/icons/platforms/linux.svg';
import macos_Icon from '../../../assets/icons/platforms/macos.svg';
import android_Icon from '../../../assets/icons/platforms/android.svg';
import default_platform_icon from '../../../assets/icons/platforms/default.svg';


const styles = (theme) => ({
    dialogContent: {
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: 12,
        paddingLeft: 40,
        // width: '700px',
        '&:first-child': {
            paddingTop: 0,
            width: 530,
        },
    },
    padding: {
        padding: '0px',
    },
    button:{
        backgroundColor: '#1168cd',
        color: '#FFF',
        '&:hover':{
            backgroundColor: 'rgb(11, 72, 143)'
        }
    },
    rootDialogContent: {
        width: '100%',
        overflow: 'auto',
    },
    rootDialogContentSpace: {
        marginTop: '10px',
    },
    col1: {
        width: 'calc(50% - 75px)',
        marginLeft: 30,
        float: 'left',
    },
    colFull: {
        width: 'calc(100% - 60px)',
        marginLeft: 30,
        float: 'left',
    },
    col2: {
        width: '50%',
        float: 'right',
    },
    customDatePicker: {
        background: 'transparent',
        border: 'none',
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        outline: 'none',
        padding: '6px 0 7px 0',
        width: 275,
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 90px)',
        },
        fontSize: 12,
        color: BLACK_FONT,
    },
    timeZoneSelect: {
        width: 260,
        fontSize: 12,
        color: BLACK_FONT,
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    menuItem: {
        cursor: 'pointer',
        padding: '10px',
        fontSize: '12px',
        borderBottom: '1px solid #eee',
    },
    errorMessage: {
        display: 'block',
        color: 'red',
        fontSize: 12,
        marginLeft: 30,
        marginBottom: 5,
    },
    font: {
        fontSize: 12,
        color: '#595959',
    },
    chip: {
        margin: theme.spacing(0.5),
        fontSize: '12px',
        height: 27,
    },
    chipRoot: {
        display: 'flex',
    },
    placeholder: {
        '&>div>::-webkit-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
        '&>div>::-moz-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
    },
    label: {
        color: BLACK_FONT,
        fontWeight: 500,
        fontSize: '12px',
    },
    remoteDriverHandlerStyle: {
        alignItems: 'center',
        display: 'flex',
        margin: '15px 10px',
    },
    URLLabel: {
        color: BLACK_FONT,
        fontWeight: '400',
        fontSize: 12,
        paddingTop: '10px',
        width: '14%',
    },
    URLInput: {
        marginTop: '0px',
        marginBottom: '14px',
        '&>:first-child': {
            color: BLACK_FONT,
            padding: '2px',
            fontSize: '12px',
            fontWeight: 500,
        },
    },
    paperURL: {
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.2)',
        marginLeft: '25px',
        width: 'calc(100% - 50px)',
        marginBottom: 20,
        padding: '0 20px',
    },
    executionTextFieldContainer: {
        padding: '0 30px',
        alignItems: 'center',
        display: 'flex',
        marginTop: 10,
    },
    actionIconsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 50,
        marginRight: 30,
    },
    iconButtonRoot: {
        height: 25,
        width: 25,
    },
    actionIcons: {
        fontSize: 16,
        color: '#4a4a4a',
        cursor: 'pointer',
    },
    formContainer: {
        backgroundColor: '#FFF',
        display: 'flex',
        width: '100%',
        position: 'relative',
        // border: '1px solid rgba(224, 224, 224, 1)',
    },
    dialogContentPlatformBrowser: {
        maxHeight: 'calc(100vh - 470px)',
        padding: '0 30px',
        width: '75%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '15px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(193,193,193)',
          }
    },
    radioGroup: {
        marginLeft: 10,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'noWrap',
        '& svg': {
            width: '20px',
            height: '20px'
          }
    },
    radioLabel: {
        width: '50%',
        '&>:nth-child(2)': {
            fontSize: 12,
            color: '#636363',
            marginLeft: 5
        }
    },
    stepper: {
        padding: '16px 0px 0 !important',
        overflow: 'hidden',
        marginBottom: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        // marginLeft: -27,
        [theme.breakpoints.only('xs')]: {
            maxWidth: '257px',
        },
    },
    input: {
        '& > label':{
            fontSize: '12px !important'
        },
        '& > p':{
            color: 'red',
            fontSize: '10px'
        },
        '&>div>input': {
            padding: '2px 0px 11px',
            // width: '50%',
            fontSize: '12px',
        },
    },
    addIcon: {
        fontSize: 21,
        color: '#1168cd',
        cursor: 'pointer',
    },
    disableAddBtn:{
        fontSize: 21,
        color: '#9B9B9B'
    },
    stepContainer: {
        position: 'relative',
        marginRight: 50,
        cursor: 'pointer'
    },
    stepContainerDisabled: {
        position: 'relative',
        marginRight: 50,
        cursor: 'not-allowed',
    },
    rightContainerStyle: {
        // marginTop: '-91px',
        overflow: 'hidden',
        padding:'3px 30px'
    },
    content: {
        // padding: '10px 10px 25px 10px',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '10px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(193,193,193)',
          }
    },
    accordionSummary: {
        padding: '0 30px'
      }, 
    overviewContainer: {
        padding: '10px ​0',
        // borderLeft: '2px solid #ECECEC',
        // borderBottom: '1px solid #ECECEC',
    },
    selectionStyle: {
        fontSize: 12,
        lineHeight: '20px',
    },
    contentContainer: {
        height: 150,
        width: '100%',
        // borderLeft: '2px solid #ECECEC',
        // borderBottom: '1px solid #ECECEC',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '10px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(193,193,193)',
          }
    },
    headingOverview: {
        color: '#000000',
        overflow: 'hidden',
        fontSize: 12,
        fontWeight: 500,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    headingStyle: {
        fontWeight: 'bold',
        fontSize: 12,
        padding: '7px 0',
    },
    platformBrowserselection: {
        margin: '8px 0',
        display: 'flex',
        alignItems: 'center',
    },
    noSelectionStyle: {
        fontSize: 12,
        color: '#9B9B9B',
        lineHeight: '20px',
    },
    stepVal: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        paddingTop: 13,
        fontSize: 12,
        color: '#9B9B9B',
        pointerEvents: 'all'
        // cursor: 'pointer',
    },
    disableStep: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        paddingTop: 13,
        fontSize: 12,
        color: '#9B9B9B',
        pointerEvents: 'none'
    },
    activeStepVal: {
        borderTop: '3px solid #1168CD',
        paddingTop: 10,
        // cursor: 'pointer',
        color: '#1168CD',
    },
    extGridContainer: {
        width: '100%',
        maxHeight: 150,
        overflowY: 'auto'
    },
    environmentPopper: {
        display: 'flex',
        // width: '45%',
        borderBottom: '1px solid #979797',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headingDetails:{
        // paddingLeft: '20px',
        fontWeight: '500',
        color: '#494949',
        fontSize: '12px',
    },
    disabled: {
        color: 'rgba(56, 55, 55, 0.58)',
        opacity: '0.8',
        margin: '5px 0px',
        fontSize: '12px',
        fontWeight: '500',
    },
    disabledEnvironmentPopper: {
        display: 'flex',
        opacity: '0.8',
        pointerEvents: 'none',
        borderBottom: '1px solid #979797',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&>p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    environmentTypetext: {
        color: 'rgba(56, 55, 55, 0.58)',
        margin: '5px 0px',
        fontSize: '12px',
        fontWeight: '500',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    environmentTypeContainer: {
        display: 'flex',
        padding: '10px 5px 10px 3px',
        alignItems: 'center',
        // borderBottom: '1px solid #efefef',
        // borderTop: '1px solid #efefef',
    },
    tabsContainer: {
        width: 700,
        zIndex: 1300,
    },
    textFieldLabelCustom: {
        fontSize: 12,
        fontWeight:'500',
        color: '#000000',
    },
    textFieldInner: {
        fontSize: 12,
        color: 'rgba(56, 55, 55, 0.58)',
        fontWeight: 500,
        margin: '5px 0px',
    },
    textFieldCustom: {
        width: 'calc(100% - 30px)',
        borderBottom: '1px solid #979797',
    },
    textFieldBody: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-between',
        width: 'calc(100% - 30px)',
        borderBottom: '1px solid #979797',
    },
    textFieldIcon: {
        color: '#3B91DF',
        fontSize: 20,
        marginLeft: 10,
    },
    textFieldLabel: {
        color: BLACK_FONT,
        fontWeight: '400',
        fontSize: 12,
        marginTop: 1,
    },
    noDetail: {
        textAlign: 'center',
        fontSize: 12,
        backgroundColor: '#F5F5F5',
        padding: 25,
        marginRight: 30,
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        marginRight: '4px',
    },
    faIcons: {
        fontSize: 17,
        marginTop: -3,
    },
    selectBoxContainer: {
        backgroundColor: '#FFF',
        borderRadius: '3px',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.8)',
        height: 'auto',
        zIndex: 1,
        maxHeight:152,
        overflowY:'auto'
    },
    selectionContainer: {
        display: 'flex',
        marginBottom: '22px',
        width: '100%',
    },
    root: {
        borderBottom: '0.5px solid #C4C4C4',
        borderTop: '0.5px solid #C4C4C4',
        color: theme.palette.primary.dark,
        fontSize: 12,
        lineHeight: '15px',
        backgroundColor: '#F3F3F3',
        opacity: 1,
        maxHeight: 48,
        minHeight: 48,
        fill: '#4A4A4A',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        '&>svg': {
            marginRight: 4
        }
    },
    labelContainer: {
        padding: 0,
        color: '#4a4a4a',
        marginLeft: 6,
        textTransform: 'none',
    },
    versionListContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        maxHeight: '145px',
        overflowY: 'auto',
        width: '100%',
    },
    versionListText: {
        borderRadius: 7,
        cursor: 'pointer',
        margin: '5px',
        maxHeight: 40,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '10px 8px',
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: '#bbbbbb',
        },
    },
    versionListTextForBrowser: {
        width: '14%',
        textAlign: 'center',
    },
    versionListTextForPlatform: {
        width: '18%',
        textAlign: 'left',
    },
    requiredSpan: {
        color: '#D0021B',
        marginLeft: 10,
        fontSize: '12px',
        fontWeight: '500',
    },
    sauceTypePlatform: {
        width: '22%', 
        marginBottom: 20,
        [theme.breakpoints.down('md')]: {
            width: '26%', 
        },
    },
    sauceTypeNotPlatform: {
        width: '22%', 
        marginBottom: 20, 
        marginLeft: 60,
        [theme.breakpoints.down('md')]: {
            width: '26%', 
            marginLeft: 25,
        },
    }
});

const Devices = getDevices();
const { ExecutionModes, PlatformBasedBrowsers } = ExecuteScriptModalConstant;
const { Browser } = ExecuteScriptModalConstant;
let { Platform, newMappedOSX, newMappedOSXVersion } = ExecuteScriptModalConstant;
let PlatformSpecificVersionsArray = [];
let BrowserSpecificVersionsArray = [];

const isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class ScheduleModal extends React.Component {
    constructor(props) {
        super(props);
        this.PlatformBasedBrowsers = JSON.parse(JSON.stringify(PlatformBasedBrowsers));
        this.windowsBrowser = checkKeyInObject(checkKeyInObject(props.prefences,'accounts'),'windowsBrowsers')  || localStorage.getItem('windowsBrowser');
        this.ModalType = checkKeyInObject(checkKeyInObject(props.prefences,'accounts'),'executionEngine') || localStorage.getItem('executionEngine');
        this.state = {
            // startTime: new Date(),
            startTime: new Date(new Date(new Date().getTime()).setMinutes(getMinNearest15Interval(new Date(new Date().getTime()).getMinutes()))),
            endTime: new Date(),
            timeZone: 'IST',
            expandOverview: true,
            schedulingTimeValue: '',
            digitTimeLengthError: false,
            isNotValidEmail: false,
            schedulingData: [
                { key: 1, label: 'Month', value: 0, minVal: 1, maxVal: -1 },
                { key: 2, label: 'Days', value: 0, minVal: 1, maxVal: 31 },
                { key: 3, label: 'Hours', value: 1, minVal: 1, maxVal: 24 },
            ],
            show: true,
            emailValue: '',
            recipientsEmails: [],
            browserDropDownData: [Browser, Browser],
            anchorEl: [null, null],
            browserDialog: [false, false],
            selectedBrowsers: ['Chrome', 'Chrome'],
            selectedPlatform: ['Linux', 'Linux'],
            platformDialog: [false, false],
            selectedTab: [0, 0],
            use_remote_driver: false,
            url: '',
            showSelectedExecutionModeDialog: false,
            selectedExecutionMode: 'Parallel',
            externalGrid: [{
                environmentType: 'remote',
                platform: '',
                platformVersion: '',
                browser: '',
                browserVersion: ''
            }],
            expanded: '',
            localContent: [{
                environmentType: 'Local',
                browser: 'Chrome (headless)',
                platformVersion: '',
                browserVersion: '',
                platform: 'Linux',
                browserEl: null,
                platformEl: null,
            }],
            sauceContent:[{
                environmentType: 'Saucelabs',
                id: 'Windows 10_10_chrome_92',
                browser: 'chrome',
                browserVersion: '92',
                platform: 'Windows 10',
                platformVersion: '10',
                appiumVersion: '',
                deviceName: '',
                deviceOrientation: '',
                label:'Windows 10 . Chrome 92.0',
                idEl: null,
                tunnelID: '',
            }],
            deviceSettings:[{}],
            // deviceSettings:{
            //     autoAcceptAlerts: false,
            //     autoGrantPermissions: false,
            //     enableAnimations: false,
            //     orientation: 'portrait',
            //     options: [{ key: 'abc', value: 'true' }]
            // },
            platformTab: 'Windows',
            browserTab: 'Chrome',
            platformVersion: '',
            browserVersion: '',
            tabState: 'Saucelabs',
            tunnelID: '',
            configurationSetting: 'newConfiguration',
            sauceContentDisable: false,
            localContentDisable: false,
            externalGridDisable: false,
            lastConfigurationDisable: false,
            sauceSysDefault: [],
            localSysDefault: [],
            platformLocal: 'Linux',
            browserLocal: 'Chrome',
            environmentDetails: {},
            localBrowserValues: {},
            sauceTunnelIDs: [],
            sauceIDDisable: false,
            saucePlatformDisable: false,
            sauceBrowserDisable: false,
            localPlatformDisable: false,
            localBrowserDisable: false,
            extPlatform: false,
            extBrowser: false,
            extPlatformVersion: false,
            noConfigFound: false,
            sauceConfigurationNotFound: true,
            localConfigurationNotFound: true,
            duplicateSauceObjects: {},
            duplicateLocalObjects: {},
            duplicateExternalObjects: {},
            showSauceContent: false,
            showLocalContent: false,
            showRemoteContent: false,
            sauceCredentials:{},
            SauceCredentialLoad:false,
            overflowStyle: 'auto',
            saucelabs_capabliites: {},
            sauceContentUpdated:false,
            localContentUpdated:false,
            externalGridUpdated:false,
            deviceNotAvailable:[],
            setUserDefault:false,
        };
        this.calenderRef = React.createRef();
    }

    UNSAFE_componentWillMount() {
        if (checkKeyInObject(this.props.tabsData, 'schedule')) {
            this.setState({ ...this.props.tabsData.schedule.state });
        } else {
            let obj = {};
            obj.anchorEl = this.state.anchorEl;
            obj.browserDropDownData = JSON.parse(JSON.stringify(this.state.browserDropDownData));
            obj.browserDialog = JSON.parse(JSON.stringify(this.state.browserDialog));
            obj.selectedBrowsers = JSON.parse(JSON.stringify(this.state.selectedBrowsers));
            obj.selectedPlatform = JSON.parse(JSON.stringify(this.state.selectedPlatform));
            Platform = checkObject(Devices) ? Object.keys(Devices) : Platform;
            PlatformSpecificVersionsArray = Platform.map((pl) => Object.keys(Devices[pl]));
            const mapForBrowser = `${Platform[0]}.${PlatformSpecificVersionsArray[0][0]}`;
            const browserDetails = checkKeyInObject(Devices, mapForBrowser, 'value', {});
            for (let key = 0; key < obj.selectedPlatform.length; key++) {
                obj.selectedPlatform[key] = PlatformSpecificVersionsArray[0][0];
                obj.browserDropDownData[key] = Object.keys(browserDetails);
                BrowserSpecificVersionsArray = Object.values(browserDetails);
                obj.selectedBrowsers[key] = [`${obj.browserDropDownData[key][0]} ${BrowserSpecificVersionsArray[0][0]}`];
            }
            obj.platformDialog = JSON.parse(JSON.stringify(this.state.platformDialog));
            obj.selectedTab = JSON.parse(JSON.stringify(this.state.selectedTab));
            if (checkKeyInObject(this.props, 'type') === 'Update' && this.props.updateData) {
                let startTime;
                let endTime = new Date();

                if (checkKeyInObject(this.props.updateData, 'start_time')) {
                    startTime = new Date(this.props.updateData.start_time);
                }
                if (checkKeyInObject(this.props.updateData, 'end_time')) {
                    endTime = new Date(this.props.updateData.end_time);
                }

                let month = 0;
                let days = 0;
                let hours = 0;
                if (checkKeyInObject(this.props.updateData, 'schedule_interval')) {
                    hours = this.props.updateData.schedule_interval;
                    month = parseInt(hours / 730, 10);
                    hours %= 730;
                    days = parseInt(hours / 24, 10);
                    hours %= 24;
                }
                obj = {
                    ...obj,
                    show: month > 0 || hours > 0 || days > 0,
                    schedulingData: [
                        { key: 1, label: 'Month', value: month, minVal: 1, maxVal: -1 },
                        { key: 2, label: 'Days', value: days, minVal: 1, maxVal: 31 },
                        { key: 3, label: 'Hours', value: hours, minVal: 1, maxVal: 24 },
                    ],
                    startTime,
                    endTime,
                    use_remote_driver: !!checkKeyInObject(this.props.updateData, 'remote_driver'),
                    url: checkKeyInObject(this.props.updateData, 'remote_driver', 'value', ''),
                    // selectedExecutionMode: checkKeyInObject(this.props.updateData, 'executionMode', 'value', 0) === 1 ? 'Serial' : 'Parallel',
                };
                // if (checkKeyInObject(this.props.updateData, 'exec_details', 'value', []).length > 0) {
                //     for (let i = 1; i <= this.props.updateData.exec_details.length; i++) {
                //         obj.anchorEl[i] = null;

                //         obj.browserDropDownData[i] = Browser;
                //         obj.browserDialog[i] = false;
                //         const browser = TestScriptUtils.getBrowserOptionValue(
                //             checkKeyInObject(this.props.updateData.exec_details[i - 1], 'browser', 'value', ''),
                //         );
                //         obj.selectedBrowsers[i] = browser === 'internetExplorer' || browser === 'MSEdge' || browser === 'edge' ? browser : capitalizeString(browser);
                //         obj.selectedPlatform[i] = capitalizeString(
                //             checkKeyInObject(this.props.updateData.exec_details[i - 1], 'platform', 'value', ''),
                //         );
                //         obj.platformDialog[i] = false;
                //         obj.selectedTab[i] = 0;
                //     }
                // }
            }
            if (this.ModalType === config.executionEngine) {
                let anyWindowBrowser = false;
                (this.windowsBrowser.split(',') || []).forEach((brow) => {
                    if (brow) {
                        anyWindowBrowser = true;
                    }
                    if (brow !== 'ie') {
                        this.PlatformBasedBrowsers.Windows.push(capitalizeString(brow.trim()));
                    }
                });
                if (checkArrayLength(Platform)) {
                    Platform = Platform.filter((pl) => pl === 'Linux' || (anyWindowBrowser && pl === 'Windows')).sort();
                    const browserDropDownData = JSON.parse(JSON.stringify(obj.browserDropDownData));
                    for (let key = 0; key < obj.selectedPlatform.length; key++) {
                        browserDropDownData[key] = this.PlatformBasedBrowsers[obj.selectedPlatform[key]];
                    }
                    obj.browserDropDownData = browserDropDownData;
                }
            }
            this._setState({ ...obj });
        }
    }

    async componentDidMount() {
    
        const { selectedPlatform, sauceContent, localContent, externalGrid, localContentUpdated, sauceContentUpdated, externalGridUpdated } = this.state;
        const {  callingFrom,type,updateData,tabsData, getSauceDetails, toggleSnackBar } = this.props

        const lastConfigurationData = JSON.parse(localStorage.getItem('lastRunConfiguration'))
        let exec_details;
        let _selectedExecutionMode;
        // const lastGenerateConfiguration = JSON.parse(localStorage.getItem('lastGenerateConfiguration'))
        // this._setState({ lastConfigurationDisable: true, configurationSetting: 'newConfiguration' })    
        const envData = await this.props.getEnvironmentType()
        if(type.toLowerCase() == 'update'){
             exec_details = await this.props.getExecutionDetailsByScheduleId(updateData.schedule_id);
             
        }

        const sauceCredentials = await getSauceDetails();

        let _sauceCredentialsDetails;
        if(!sauceCredentials?.payload?.message){
            _sauceCredentialsDetails = sauceCredentials.payload;
            this.setState({
                sauceCredentials:_sauceCredentialsDetails,
                SauceCredentialLoad:true,
            })
        }else{
           // this.props.toggleSnackBar('Sauce Details are not present', '', false, 2000);
            this.setState({ SauceCredentialLoad:true })
        }

        console.log('exec_details',exec_details);
        _selectedExecutionMode = exec_details?.payload?.data?.executionMode ? exec_details?.payload?.data?.executionMode == 1 ? 'Serial' : 'Parallel' : 'Parallel';
        // Set first sauce proxy id as a default value
        // const data = envData?.payload?.data?.environments[0].environmentType == "Saucelabs" && !isObjectEmpty(_sauceCredentialsDetails) ? await this.props.getTunnelIDForSauceLabs() : {};
        const data =  envData?.payload?.data?.environments.some(env => env.environmentType?.toLowerCase() === 'saucelabs' || env.environmentType?.toLowerCase() === 'saucelab_devices') && !isObjectEmpty(_sauceCredentialsDetails) ?  await this.props.getTunnelIDForSauceLabs() : {};
        
        if(!isObjectEmpty(data) && data.error && typeof data.error === 'string') {
            this.props.toggleSnackBar(data.error)
        }
        // if (callingFrom.toLowerCase() === 'generate') {
        //     if (lastGenerateConfiguration) {
        //         let _externalGrid = lastGenerateConfiguration.filter((val) => val.environmentType.toLowerCase() === 'externalgrid')
        //         let _localContent = lastGenerateConfiguration.filter((val) => val.environmentType.toLowerCase() === 'local' || val.environmentType.toLowerCase() === 'zalenium')
        //         let _sauceContent = lastGenerateConfiguration.filter((val) => val.environmentType.toLowerCase() === 'saucelabs')
        //         _sauceContent = _sauceContent?.map((val) => {
        //             return {
        //                 ...val,
        //                 tunnelID: data?.payload?.data ? (data.payload.data.includes(val.tunnelID) ? val.tunnelID : data?.payload?.data[0]) : ''
        //             }
        //         })
        //         this.setState({
        //             externalGrid: _externalGrid,
        //             localContent: _localContent,
        //             sauceContent: _sauceContent,
        //             localContentDisable: _localContent.length ? false : true,
        //             sauceContentDisable: _sauceContent.length ? false : true,
        //             externalGridDisable: _externalGrid.length ? false : true,
        //             lastConfigurationDisable: false,
        //             configurationSetting: 'lastRunConfiguration',
        //             tabState: lastGenerateConfiguration[0].environmentType.toLowerCase() === 'zalenium' ? 'Local' : lastGenerateConfiguration[0].environmentType,
        //             localConfigurationNotFound: _localContent.length ? true : false,
        //             sauceConfigurationNotFound: _sauceContent.length ? true : false,
        //         })
        //     } else {
        //         this.setState({ lastConfigurationDisable: true, configurationSetting: 'newConfiguration' })
        //     }
        // } else {
            if (lastConfigurationData && type.toLowerCase() !== 'update') {
                const _externalGrid = externalGridUpdated ? externalGrid : lastConfigurationData.filter((val) => val.environmentType.toLowerCase() === 'remote')
                let _localContent = localContentUpdated ? localContent : lastConfigurationData.filter((val) => val.environmentType.toLowerCase() === 'local' || val.environmentType.toLowerCase() === 'zalenium')
                let _sauceContent = sauceContentUpdated ? sauceContent : lastConfigurationData.filter((val) => val.environmentType.toLowerCase() === 'saucelabs' || val.environmentType.toLowerCase() === 'saucelab_devices')
                _sauceContent = _sauceContent?.map((val) => {
                    return {
                        ...val,
                        platform: val?.platform?.toLowerCase() === 'windows' ? 'Windows 10' : val.platform,
                        tunnelID: data?.payload?.data ? (data.payload.data.includes(val.tunnelID) ? val.tunnelID : data?.payload?.data[0]) : ''
                    }
                }) 
                _localContent = _localContent?.map((val) => {
                    return {
                        ...val,
                        browser: val?.environmentType?.toLowerCase() === 'zalenium' ? `${val?.browser} (headful)` : `${val?.browser} (headless)`,
                        browserEl: null,
                        platformEl: null,
                    }
                })
                this.setState({
                    externalGrid: tabsData?.schedule?.state?.externalGrid?.length > 0 ? tabsData?.schedule?.state?.externalGrid : tabsData?.schedule?.state?.showRemoteContent ? [] : _externalGrid,
                    localContent: tabsData?.schedule?.state?.localContent?.length > 0 ? tabsData?.schedule?.state?.localContent : tabsData?.schedule?.state?.showLocalContent ? [] : _localContent,
                    sauceContent: tabsData?.schedule?.state?.sauceContent?.length > 0 ? tabsData?.schedule?.state?.sauceContent : tabsData?.schedule?.state?.showSauceContent ? [] : _sauceContent,
                    // localContentDisable: _localContent.length ? false : true,
                    // sauceContentDisable: _sauceContent.length ? false : true,
                    // externalGridDisable: _externalGrid.length ? false : true,
                    lastConfigurationDisable: false,
                    configurationSetting: 'newConfiguration',
                    tabState: lastConfigurationData[0].environmentType.toLowerCase() === 'zalenium' || lastConfigurationData[0].environmentType.toLowerCase() === 'local' ? 'Local' : lastConfigurationData[0].environmentType.toLowerCase() === 'remote' ? 'externalGrid' : lastConfigurationData[0].environmentType.toLowerCase() === 'saucelab_devices' ? 'Saucelabs' : lastConfigurationData[0].environmentType,
                    localConfigurationNotFound: !!_localContent.length,
                    sauceConfigurationNotFound: !!_sauceContent.length,
                })
            } else if (type.toLowerCase() === 'update' && updateData?.exec_details?.length && !sauceContentUpdated && !localContentUpdated && !externalGridUpdated ) {

                const _externalGrid = exec_details.payload.data.pbDetails.filter((val) => val.environmentType.toLowerCase() === 'remote')
                let _localContent = exec_details.payload.data.pbDetails.filter((val) => val.environmentType.toLowerCase() === 'local' || val.environmentType.toLowerCase() === 'zalenium')
                let _sauceContent = exec_details.payload.data.pbDetails.filter((val) => val.environmentType.toLowerCase() === 'saucelabs' || val.environmentType.toLowerCase() === 'saucelab_devices')
                const executionSauceDetails = envData?.payload?.data?.environments?.find((val) => val?.environmentType?.toLowerCase() === 'saucelabs');
                const executionLocalDetails = envData?.payload?.data?.environments?.find((val) => val?.environmentType?.toLowerCase() === 'local');
                const executionZaleniumDetails = envData?.payload?.data?.environments?.find((val) => val?.environmentType?.toLowerCase() === 'zalenium');
                const executionExternalGridDetails = envData?.payload?.data?.environments?.find((val) => val?.environmentType?.toLowerCase() === 'remote');
               
                _sauceContent = _sauceContent?.map((val) => {
                    return {
                        ...val,
                        platform: val?.platform?.toLowerCase() === 'windows' ? 'Windows 10' : val.platform,
                        platformVersion: val?.platform?.toLowerCase() === 'windows' ? '10' : val.platformVersion,
                        tunnelID: data?.payload?.data ? (data.payload.data.includes(val.tunnelID) ? val.tunnelID : data?.payload?.data[0]) : '',
                        browserEl: null,
                        platformEl: null,
                        idEl: null,
                    }
                })

                _localContent = _localContent?.map((val) => {
                    return {
                        ...val,
                        browser: val.environmentType.toLowerCase() === 'zalenium' ? `${val.browser} (headful)` : `${val.browser} (headless)`,
                        browserEl: null,
                        platformEl: null,
                    }
                })
                let platform = ''
                if ( _sauceContent.length) {
                    platform = 
                            _sauceContent[0]?.platform.trim().toLowerCase().includes('window') ? 'Windows' : 
                            _sauceContent[0]?.platform.trim().toLowerCase().includes('mac') ? 'MacOS' : 
                            _sauceContent[0]?.platform.trim().toLowerCase().includes('ios') && _sauceContent[0]?.platform.trim().toLowerCase().includes('real device') ? 'iOS Real Device' : 
                            _sauceContent[0]?.platform.trim().toLowerCase().includes('android') && _sauceContent[0]?.platform.trim().toLowerCase().includes('real device') ? 'Android Real Device' : 
                            _sauceContent[0]?.platform.trim().toLowerCase().includes('ios') ? 'iOS' : 
                            _sauceContent[0]?.platform.trim().toLowerCase().includes('android') ? 'Android' : 
                            _sauceContent[0]?.platform.trim().toLowerCase().includes('linux') ? 'Linux' : 'Linux'
                } else {
                    platform = 
                            sauceContent[0]?.platform.trim().toLowerCase().includes('window') ? 'Windows' : 
                            sauceContent[0]?.platform.trim().toLowerCase().includes('mac') ? 'MacOS' : 
                            sauceContent[0]?.platform.trim().toLowerCase().includes('ios') && sauceContent[0]?.platform.trim().toLowerCase().includes('real device') ? 'iOS Real Device' : 
                            sauceContent[0]?.platform.trim().toLowerCase().includes('android') && sauceContent[0]?.platform.trim().toLowerCase().includes('real device') ? 'Android Real Device' : 
                            sauceContent[0]?.platform.trim().toLowerCase().includes('ios') ? 'iOS' : 
                            sauceContent[0]?.platform.trim().toLowerCase().includes('android') ? 'Android' : 
                            sauceContent[0]?.platform.trim().toLowerCase().includes('linux') ? 'Linux' : 'Linux'
                }
                this.setState({
                    externalGrid: tabsData?.schedule?.state?.externalGrid?.length > 0 ? tabsData?.schedule?.state?.externalGrid : executionExternalGridDetails?.environment?.platformDetails?.length > 0 ? (tabsData?.schedule?.state?.showRemoteContent ? [] : _externalGrid) : [],
                    localContent: tabsData?.schedule?.state?.localContent?.length > 0 ? tabsData?.schedule?.state?.localContent : (executionLocalDetails?.environment?.platformDetails?.length > 0 || executionZaleniumDetails?.environment?.platformDetails?.length > 0) ? (tabsData?.schedule?.state?.showLocalContent ? [] : _localContent) : [],
                    sauceContent: tabsData?.schedule?.state?.sauceContent?.length > 0 ? tabsData?.schedule?.state?.sauceContent : executionSauceDetails?.environment?.platformDetails?.length > 0 ? (tabsData?.schedule?.state?.showSauceContent ? [] : _sauceContent) : [],
                    sauceSysDefault: _sauceContent.length ? [_sauceContent[0]] : [sauceContent[0]],
                    localSysDefault: _localContent.length ? [_localContent[0]] : [localContent[0]],
                    browserTab: _sauceContent.length ? _sauceContent[0]?.browser : sauceContent[0]?.browser,
                    platformVersion: _sauceContent.length ? _sauceContent[0]?.platformVersion : sauceContent[0]?.platformVersion,
                  	platformTab: platform,
                    lastConfigurationDisable: false,
                    configurationSetting: 'newConfiguration',
                    tabState: _sauceContent?.length && executionSauceDetails?.environment?.platformDetails?.length ? 'Saucelabs' : (_localContent?.length && executionZaleniumDetails?.environment?.platformDetails?.length)  || (_localContent?.length && executionLocalDetails?.environment?.platformDetails?.length)  ? 'Local' : (_externalGrid?.length && executionExternalGridDetails?.environment?.platformDetails?.length) ? 'externalGrid' : 'Saucelabs',
                    localConfigurationNotFound: !!_localContent.length,
                    sauceConfigurationNotFound: !!_sauceContent.length,
                    selectedExecutionMode: _selectedExecutionMode,
                })
            } else {
                this.setState({ selectedExecutionMode:_selectedExecutionMode,lastConfigurationDisable: true, configurationSetting: 'newConfiguration' })
            }
        // }

        // Causing sauceConfiguratons not to persists when switching between tabs
        if (data && data?.payload?.data && (callingFrom.toLowerCase() === 'schedule') && type.toLowerCase() !== 'update' && !sauceContentUpdated) {
            const _sauceContent = cloneDeep(sauceContent);
            this._setState({ sauceContent: [{ ..._sauceContent[0], tunnelID: data?.payload?.data[0] || '' }] });
        }


        if (envData && envData.payload.data && (callingFrom.toLowerCase() === 'schedule')) {
            let saucelab = [];
            let saucelabsDevices = [];
            if(envData?.payload?.data?.environments?.some(env => env.environmentType?.toLowerCase() === 'saucelabs')){
                saucelab = envData?.payload?.data?.environments?.find(env => env.environmentType?.toLowerCase() === 'saucelabs').environment.platformDetails?.map(elem => {
                    return{
                       ...elem,
                       environmentType: 'Saucelabs'
                    }
                })
            }
            if(envData?.payload?.data?.environments?.some(env => env.environmentType?.toLowerCase() === 'saucelab_devices')){
                saucelabsDevices = envData?.payload?.data?.environments?.find(env => env.environmentType?.toLowerCase() === 'saucelab_devices').environment.platformDetails?.map(elem => {
                    return{
                       ...elem,
                       environmentType: 'saucelab_devices'
                    }
                })
            }
            if((saucelab.length || saucelabsDevices.length) && _sauceCredentialsDetails?.sauceCredentialId){
                this.constructDataForSauceMapping([...saucelab, ...saucelabsDevices])
            }
            
            this.setUserDefaultData(envData.payload.data, _sauceCredentialsDetails,()=>{this.setState({setUserDefault:true})})
        }
    
        if (this.ModalType === defaultConfig.executionEngine) {
            const anyWindowBrowser = false;
            if (checkArrayLength(Platform)) {
                Platform = Platform.filter((pl) => pl === 'Linux' || (anyWindowBrowser && pl === 'Windows')).sort();
                const browserDropDownData = this.makeStateCopy('browserDropDownData');
                for (let key = 0; key < selectedPlatform.length; key++) {
                    browserDropDownData[key] = this.PlatformBasedBrowsers[selectedPlatform[key]];
                }
                this._setState({ browserDropDownData });
            }
        } else {
            const browserDropDownData = this.makeStateCopy('browserDropDownData');
            const _selectedPlatform = this.makeStateCopy('selectedPlatform');
            const selectedBrowsers = this.makeStateCopy('selectedBrowsers');
            Platform = checkObject(Devices) ? Object.keys(Devices) : Platform;

            PlatformSpecificVersionsArray = Platform.map((pl) => Object.keys(Devices[pl]));

            const mapForBrowser = `${Platform[0]}.${PlatformSpecificVersionsArray[0][0]}`;

            const browserDetails = checkKeyInObject(Devices, mapForBrowser, 'value', {});

            for (let key = 0; key < _selectedPlatform.length; key++) {
                _selectedPlatform[key] = PlatformSpecificVersionsArray[0][0];
                browserDropDownData[key] = Object.keys(browserDetails);
                BrowserSpecificVersionsArray = Object.values(browserDetails);
                selectedBrowsers[key] = [`${browserDropDownData[key][0]} ${BrowserSpecificVersionsArray[0][0]}`];
            }
            this._setState({
                browserDropDownData,
                selectedPlatform: _selectedPlatform,
                selectedBrowsers,
            });
        }
        this.props.updateTabData('schedule', { state: this.state });
        // if (!lastConfigurationData && type.toLowerCase() !== 'update') this.handleConfigurationSetting();
    }

    componentWillUnmount() {
        this.props.updateTabData('schedule', { state: this.state });
    }

    onChangeStartDate = (date) => {
        const { endTime } = this.state;
        if (date < endTime) {
            this._setState({ startTime: date, 
               // didChange:  new Date(this.props.updateData.start_time) == new Date(date) ? false: true 
            });
        }
    };

    onChangeEndDate = (date) => {
        const { startTime } = this.state;
        if (startTime < date) {
            this._setState({ endTime: date, 
               // didChange:  new Date(this.props.updateData.end_time) == new Date(date) ? false: true
            });
        }
    };



    constructDataForSauceMapping = (env) =>{
        const {saucelabs_capabliites} = this.state;
        const platforms = []
        const capabilities_data_map = []

        env.forEach(details => {
            if(details.platform){
                platforms.push(newMappedOSX[details.platform])
            }
        })

        Array.from(new Set(platforms)).forEach( platform => {
            capabilities_data_map.push({
                deviceName: platform,
                deviceLogo: platform?.toLowerCase(),
                deviceVersions: env.map( deviceVer => {
                 if(newMappedOSX[deviceVer.platform] === platform && deviceVer.environmentType?.toLowerCase() === 'saucelabs'){
                        return{
                            name: newMappedOSXVersion[deviceVer.platform],
                            browsers: env.map( browser => {
                                if(browser.environmentType?.toLowerCase() === 'saucelabs' && newMappedOSX[browser.platform] === newMappedOSX[deviceVer.platform] && newMappedOSXVersion[browser.platform] ===  newMappedOSXVersion[deviceVer.platform]){
                                    return{
                                        name: browser.browser,
                                        logoName: browser.browser.toLowerCase(),
                                        versions: env.map(browVer => {
                                            if(browVer.environmentType?.toLowerCase() === 'saucelabs' && newMappedOSX[browVer.platform] === newMappedOSX[browser.platform] && newMappedOSXVersion[browVer.platform] === newMappedOSXVersion[browser.platform] && browVer.browser === browser.browser){
                                                return{
                                                    name: browVer.browserVersion,
                                                    id: browVer.id
                                                }
                                            }

                                            return{}
                                        }).filter(version => Boolean(Object.keys(version).length))
                                    }
                                }
                                return {}
                                
                            }).filter((v,i,a) => a.findIndex(v2 => Object.keys(v2).length && Object.keys(v).length && v2.name === v.name ) === i)
                    }
                }
                if(newMappedOSX[deviceVer.platform] === platform && deviceVer.environmentType?.toLowerCase() === 'saucelab_devices'){
                    return{
                        name: newMappedOSXVersion[`${deviceVer.platform} ${deviceVer.platformVersion}`],
                        devices: env.map( device => {
                            if(device.environmentType?.toLowerCase() === 'saucelab_devices' && newMappedOSX[device.platform] === newMappedOSX[deviceVer.platform] && newMappedOSXVersion[`${device.platform} ${device.platformVersion}`] ===  newMappedOSXVersion[`${deviceVer.platform} ${deviceVer.platformVersion}`]){
                                return{
                                    name: device.device,
                                    logoName: device.device?.toLowerCase()?.includes('emulator') ? 'emulator' : 'real',
                                    id: device.id,
                                    type: device.device?.toLowerCase()?.includes('emulator') ? 'emulator' : 'real'
                                }
                            }
                            return {}
                            
                        }).filter((v,i,a) => a.findIndex(v2 => Object.keys(v2).length && Object.keys(v).length && v2.name === v.name ) === i)
                }
            }
                return {}
            }).filter((v,i,a) => a.findIndex(v2 => Object.keys(v2).length && Object.keys(v).length && v2.name === v.name ) === i)
        })
    })
    this.setState({saucelabs_capabliites: {'capabilities_list': env, 'capabilities_data_map': capabilities_data_map}})
}

setUserDefaultData = (envData, _sauceCredentialsDetails,callBack) => {
    const { sauceID } = this.props
    const systemDefaultForSauce = [{
        environmentType: 'Saucelabs',
        id: 'Windows 10_10_chrome_92',
        browser: 'chrome',
        browserVersion: '92',
        platform: 'Windows 10',
        platformVersion: '10',
        appiumVersion: '',
        deviceName: '',
        deviceOrientation: '',
        label:'Windows 10 . Chrome 92.0',
        idEl: null,
        tunnelID: sauceID[0] || '',
    }]
    const systemDefaultForLocal = [{
        environmentType: 'Local',
        browser: 'Chrome (headless)',
        platformVersion: '',
        browserVersion: '',
        platform: 'Linux',
        browserEl: null,
        platformEl: null,
    }]
    const { sauceContent, localContent, deviceSettings,saucelabs_capabliites, sauceContentUpdated, localContentUpdated, externalGridUpdated, externalGrid } = this.state;
    console.log('deviceSettings',deviceSettings);
    const {type, updateData, execDetails } = this.props;
    let notAvailableDevice = [];
    const externalGridUpdatedSchedule = execDetails?.pbDetails?.length > 0 ? execDetails.pbDetails.filter((val) => val.environmentType.toLowerCase() === 'remote') : [];
    let localContentUpdatedSchedule = execDetails?.pbDetails?.length > 0 ? execDetails.pbDetails.filter((val) => val.environmentType.toLowerCase() === 'zalenium' || val.environmentType.toLowerCase() === 'local' ) : [];
    let sauceContentUpdatedSchedule =  execDetails?.pbDetails?.length > 0 ? execDetails.pbDetails.filter((val) => val.environmentType.toLowerCase() === 'saucelabs' || val.environmentType.toLowerCase() === 'saucelab_devices') : [];

    let _deviceSettings = JSON.parse(JSON.stringify(deviceSettings));
    sauceContentUpdatedSchedule = sauceContentUpdatedSchedule?.length && sauceContentUpdatedSchedule.map((val, ind) => {
                if(val.environmentType?.toLowerCase() === 'saucelab_devices'){
                    if(!Object.keys(deviceSettings[0]).length){
                        if(execDetails?.pbDetails?.length ){
                            _deviceSettings[ind] = {
                                // autoAcceptAlerts: val.autoAcceptAlerts,
                                autoGrantPermissions: val.autoGrantPermission,
                                enableAnimations: val.enableAnimations,
                                orientation: val.deviceOrientation,
                                options: val?.extraCapabilities && val?.extraCapabilities.length ? [...val.extraCapabilities, { key: '', value: '' }] : [{ key: '', value: '' }]
                            }
                        }
                        else{
                            _deviceSettings[ind] = {
                                autoGrantPermissions: val.autoGrantPermission,
                                enableAnimations: val.enableAnimations,
                                orientation: val.orientation,
                                options: val?.extraCapabilities && val?.extraCapabilities.length ? [...val.extraCapabilities, { key: '', value: '' }] : [{ key: '', value: '' }]
                            }
                        }
                    }else{
                        _deviceSettings = deviceSettings.map((val) => {
                            return {
                                orientation: val?.orientation ? val.orientation : 'portrait',
                                enableAnimations:  val.enableAnimations || false,
                                autoGrantPermissions : val.autoGrantPermissions || false,
                                options: val?.options && val?.options.length ?  val.options : [{ key: '', value: '' }],
                            }
                        })
                    }

                }
                else {
                    _deviceSettings[ind] = {}
                }
                return {
                    ...val,
                    label: saucelabs_capabliites.capabilities_list.filter((elem)=> 
                    {return elem.browser.toLowerCase() == val.browser.toLowerCase() && 
                        elem.browserVersion == val.browserVersion && 
                        elem.platform.toLowerCase() == val.platform.toLowerCase() && 
                        elem.environmentType.toLowerCase() == 'saucelabs' 
                        || elem.browser.toLowerCase() == val.browser.toLowerCase() && 
                        elem.device == val.deviceName && 
                        elem.deviceType.toLowerCase() == val.deviceType.toLowerCase() && 
                        elem.platform.toLowerCase() == val.platform.toLowerCase() && 
                        elem.environmentType.toLowerCase() == 'saucelab_devices' 
                    })[0] ?.label || '',
                    id: saucelabs_capabliites.capabilities_list.filter((elem)=> 
                    {return elem.browser.toLowerCase() == val.browser.toLowerCase() && 
                        elem.browserVersion == val.browserVersion && 
                        elem.platform.toLowerCase() == val.platform.toLowerCase() &&
                        elem.environmentType.toLowerCase() == 'saucelabs'
                        || elem.browser.toLowerCase() == val.browser.toLowerCase() && 
                        elem.device == val.deviceName && 
                        elem.deviceType.toLowerCase() == val.deviceType.toLowerCase() && 
                        elem.platform.toLowerCase() == val.platform.toLowerCase() && 
                        elem.environmentType.toLowerCase() == 'saucelab_devices' 
                })[0] ?.id || '',
                }
             });

    notAvailableDevice = sauceContentUpdatedSchedule?.length ?  sauceContentUpdatedSchedule.filter((val) =>  val.label == '' ) : [] ;
    console.log('notAvailableDevice',notAvailableDevice);


    localContentUpdatedSchedule  =  localContentUpdatedSchedule?.length && localContentUpdatedSchedule.map((val) => { 
        return {
            ...val,
            browser: val.environmentType.toLowerCase() === 'zalenium' ? `${val.browser} (headful)` : `${val.browser} (headless)`,
            browserEl: null,
            platformEl: null,
        }
    })

    console.log('_deviceSettings of updateData',_deviceSettings);

    const sauceDeviceDetails = envData.environments.find((val) => val.environmentType.toLowerCase() === 'saucelab_devices');
    const sauceDetails = envData.environments.find((val) => val.environmentType.toLowerCase() === 'saucelabs');
    const localDetails = envData.environments.find((val) => val.environmentType.toLowerCase() === 'local');
    const ZaleniumDetails = envData.environments.find((val) => val.environmentType.toLowerCase() === 'zalenium');
    const externalGridDetails = envData.environments.find((val) => val.environmentType.toLowerCase() === 'remote');
    if(!sauceContentUpdated){
        if ((sauceDetails || sauceDeviceDetails) && _sauceCredentialsDetails?.sauceCredentialId) {
            const sauceUserDefault = sauceDetails?.environment?.platformDetails?.find((elem) => elem.isDefault);
            const sauceDeviceUserDefault = sauceDeviceDetails?.environment?.platformDetails?.find((elem) => elem.isDefault)
            if (sauceUserDefault )  {
                
                const _sauceContent = cloneDeep(sauceContent);
                this.setState({ sauceContent: sauceContentUpdated ? sauceContent : type.toLowerCase() === 'update' && execDetails?.pbDetails?.length ? sauceContentUpdatedSchedule.length ? sauceContentUpdatedSchedule : [] : [{ ...sauceUserDefault, environmentType: 'Saucelabs', idEl: null, tunnelID: sauceID[0] || '' }], 
                                sauceSysDefault: [{ ..._sauceContent[0], ...sauceUserDefault, environmentType: 'Saucelabs' }] ,
                                deviceSettings: type.toLowerCase() === 'update' ?  _deviceSettings : [{}],
                                sauceConfigurationNotFound: true, 
                                platformVersion: sauceUserDefault.platformVersion, 
                                tabState: 'Saucelabs'
                            })
            }
            else if(sauceDeviceUserDefault ){
                let _deviceSettingsForsauceDeviceUserDefault;
                if(sauceDeviceUserDefault.device.toLowerCase().includes('emulator')){
                    _deviceSettingsForsauceDeviceUserDefault = deviceSettings.length ?  deviceSettings?.map((val) => {
                        return {
                            orientation: val?.deviceOrientation ? val.deviceOrientation : 'portrait',
                            options: val?.extraCapabilities && val?.extraCapabilities.length ? [...val.extraCapabilities, { key: '', value: '' }] : [{ key: '', value: '' }],
                            enableAnimations:  val.enableAnimations || false,
                            autoGrantPermissions : val.autoGrantPermissions || false,
                        }
                    }) : [{
                        autoGrantPermissions: false,
                        enableAnimations: false,
                        orientation: 'portrait',
                        options: [{ key: '', value: '' }]
                    }]
                }
                else{
                    _deviceSettingsForsauceDeviceUserDefault =  deviceSettings.length ? deviceSettings?.map((val) => {
                        return {
                            orientation: val?.deviceOrientation ? val.deviceOrientation : 'portrait',
                            enableAnimations:  val.enableAnimations || false,
                            autoGrantPermissions : val.autoGrantPermissions || false,
                            options: val?.extraCapabilities && val?.extraCapabilities.length ? [...val.extraCapabilities, { key: '', value: '' }] : [{ key: '', value: '' }],
                        }
                    }) : [{
                        autoGrantPermissions: false,
                        enableAnimations: false,
                        orientation: 'portrait',
                        options: [{ key: '', value: '' }]
                    }]
                 }
               
    
                this.setState({
                    sauceContent: sauceContentUpdated ? sauceContent : type.toLowerCase() === 'update' && execDetails?.pbDetails?.length ? sauceContentUpdatedSchedule.length ? sauceContentUpdatedSchedule : [] : [{...sauceDeviceUserDefault, environmentType: 'saucelab_devices',idEl: null, tunnelID: sauceID[0] || '', type: sauceDeviceUserDefault.device.toLowerCase().includes('emulator') ? 'emulator' : 'real'}],
                    deviceSettings: type.toLowerCase() === 'update' ?  _deviceSettings : _deviceSettingsForsauceDeviceUserDefault,
                    tabState: 'Saucelabs',
                    sauceConfigurationNotFound: true,
                })
            } 
            else{
                
    
                this.setState({ sauceContent: sauceContentUpdated ? sauceContent : type.toLowerCase() === 'update' && execDetails?.pbDetails?.length  ? sauceContentUpdatedSchedule.length ? sauceContentUpdatedSchedule : [] : sauceDetails?.environment?.platformDetails?.length > 0 ? [{...sauceDetails?.environment?.platformDetails[0],idEl: null,tunnelID: sauceID[0] || '',environmentType: 'Saucelabs'}] : systemDefaultForSauce , 
                                sauceSysDefault: [{...sauceDetails?.environment?.platformDetails[0],idEl: null,tunnelID: sauceID[0] || '',environmentType: 'Saucelabs'}], 
                                platformVersion: sauceDetails?.environment?.platformDetails[0].platformVersion, 
                                deviceSettings: type.toLowerCase() === 'update' ?  _deviceSettings : [{}],
                                sauceConfigurationNotFound: true,
                                tabState: 'Saucelabs'
                            })
            }
    
        } else {
            this.setState({ sauceConfigurationNotFound: false, sauceContent: [], tabState: 'Local' })
        }
    }

    if(!localContentUpdated){
        if (localDetails ) {
            const localUserDefault = localDetails.environment.platformDetails.find((elem) => elem.isDefault)
            if (localUserDefault) {
                const _localContent = cloneDeep(localContent);
                this.setState({ localContent: localContentUpdated ? localContent : type.toLowerCase() === 'update' && execDetails?.pbDetails?.length  ? localContentUpdatedSchedule.length ? localContentUpdatedSchedule : [] : [{ ..._localContent[0], ...localUserDefault, browser: `${localUserDefault.browser} (headless)`, environmentType: 'Local'}],
                                localSysDefault: [{ ..._localContent[0], ...localUserDefault, browser: `${localUserDefault.browser} (headless)`, environmentType: 'Local'}],
                                localConfigurationNotFound: true 
                            })
            } else {    
                this.setState({ localContent: localContentUpdated ? localContent : type.toLowerCase() === 'update' && execDetails?.pbDetails?.length  ? localContentUpdatedSchedule.length ? localContentUpdatedSchedule : [] : localDetails?.environment?.platformDetails?.length > 0 ? [{...localDetails.environment.platformDetails[0],browser: `${localDetails.environment.platformDetails[0].browser} (headless)`, environmentType: 'Local'}] : systemDefaultForLocal,
                                localSysDefault: localDetails?.environment?.platformDetails?.length > 0 ? [{...localDetails.environment.platformDetails[0],browser: `${localDetails.environment.platformDetails[0].browser} (headless)`, environmentType: 'Local'}] : systemDefaultForLocal,
                                localConfigurationNotFound: true
                            })
            }
        } else {
            this.setState({localConfigurationNotFound: false,  localContent: [], tabState: sauceDetails ? 'Saucelabs' : ZaleniumDetails ? 'Local' : 'externalGrid' })
        }
    }

    if(!localContentUpdated){
        if (ZaleniumDetails) {
            const zaleniumUserDefault = ZaleniumDetails.environment.platformDetails.find((elem) => elem.isDefault)
            if (zaleniumUserDefault) {
                const _localContent = cloneDeep(localContent);
                this.setState({ localContent: localContentUpdated ? localContent : type.toLowerCase() === 'update' && execDetails?.pbDetails?.length  ? localContentUpdatedSchedule.length ? localContentUpdatedSchedule : [] : [{ ..._localContent[0], ...zaleniumUserDefault, browser: `${zaleniumUserDefault.browser} (headful)`, environmentType: 'Zalenium' }],
                                localSysDefault: [{ ..._localContent[0], ...zaleniumUserDefault, browser: `${zaleniumUserDefault.browser} (headful)`, environmentType: 'Zalenium' }],
                                localConfigurationNotFound: true })
            } else if(!localDetails?.environment?.platformDetails?.length > 0 && !zaleniumUserDefault){    
                this.setState({ localContent: localContentUpdated ? localContent : type.toLowerCase() === 'update' && execDetails?.pbDetails?.length  ? localContentUpdatedSchedule.length ? localContentUpdatedSchedule : [] : ZaleniumDetails?.environment?.platformDetails?.length > 0 ? [{...ZaleniumDetails.environment.platformDetails[0],browser: `${ZaleniumDetails.environment.platformDetails[0].browser} (headful)`, environmentType: 'Zalenium'}] : systemDefaultForLocal,
                                localSysDefault: ZaleniumDetails?.environment?.platformDetails?.length > 0 ? [{...ZaleniumDetails.environment.platformDetails[0],browser: `${ZaleniumDetails.environment.platformDetails[0].browser} (headful)`, environmentType: 'Zalenium'}] : systemDefaultForLocal,
                                localConfigurationNotFound: true})
            }
        }
    }
    if(!externalGridUpdated){
        if (externalGridDetails && externalGridDetails.environment.platformDetails.length ) {
            this.setState({ externalGrid: externalGridUpdated ? externalGrid : type.toLowerCase() === 'update' && execDetails?.pbDetails?.length  ? externalGridUpdatedSchedule.length ? externalGridUpdatedSchedule : [] : [{...externalGridDetails.environment.platformDetails[0], environmentType: 'remote'}] })
        } else {    
            this.setState({ externalGrid: [] })
        }
    }
        if (notAvailableDevice.length){
            this.setState({ deviceNotAvailable: notAvailableDevice })
        }
        callBack();
        
}

    onClickPlatformBrowserContainer = (key, e, callingFrom = 'browser') => {
        const { browserDialog, platformDialog, selectedTab } = this.state;
        const obj = {
            anchorEl: this.makeStateCopy('anchorEl', e && e.currentTarget, key),
            showSelectedExecutionModeDialog: false,
            selectedTab: this.makeStateCopy('selectedTab', this.ModalType === config.executionEngine ? selectedTab[key] : 0, key),
        };

        if (callingFrom === 'Platform') {
            obj.platformDialog = this.makeStateCopy('platformDialog', !platformDialog[key], key);
            obj.browserDialog = this.makeStateCopy('browserDialog', false, key);
        } else {
            // calling from browser
            obj.platformDialog = this.makeStateCopy('platformDialog', false, key);
            obj.browserDialog = this.makeStateCopy('browserDialog', !browserDialog[key], key);
        }

        this._setState(obj);
    };

    onClickExecutionContainer = (e, key) => {
        this._setState({
            anchorEl: this.makeStateCopy('anchorEl', e && e.currentTarget, key),
            browserDialog: this.makeStateCopy('browserDialog', false, key),
            platformDialog: this.makeStateCopy('platformDialog', false, key),
            showSelectedExecutionModeDialog: !this.state.showSelectedExecutionModeDialog,
        });
    };

    getPlatformDropDownData = () => {
        const { selectedBrowsers, selectedPlatform } = this.state;
        const availablePlatform = [];
        Platform.forEach((platform) => {
            if (
                this.PlatformBasedBrowsers[platform] &&
                checkArrayLength(this.getBrowserDropDownData(this.PlatformBasedBrowsers[platform], '', platform, selectedPlatform, selectedBrowsers))
            ) {
                availablePlatform.push(platform);
            }
        });
        return availablePlatform;
    };

    getBrowserDropDownData = (browserDropDownData, _selectedBrowser, _selectedPlatform, selectedPlatforms, selectedBrowsers) => {
        if (browserDropDownData) {
            const allBrowsers = JSON.parse(JSON.stringify(browserDropDownData));
            let uniqueBrowsers = [];
            if (checkArrayLength(allBrowsers)) {
                allBrowsers.forEach((browser) => {
                    if (uniqueBrowsers.indexOf(browser) === -1) uniqueBrowsers.push(browser);
                });
                uniqueBrowsers = uniqueBrowsers.filter((browser) => {
                    return !selectedBrowsers
                        .slice(1, selectedBrowsers.length)
                        .some((b, j) => _selectedPlatform === selectedPlatforms[j + 1] && browser === b);
                });
                if (
                    _selectedBrowser &&
                    uniqueBrowsers.indexOf(_selectedBrowser) === -1 &&
                    checkKeyInObject(this.PlatformBasedBrowsers, _selectedPlatform, 'value', []).includes(_selectedBrowser)
                ) {
                    uniqueBrowsers.push(_selectedBrowser);
                }
            }
            return uniqueBrowsers;
        }
        return [];
    };

    _setState = (obj, cb = () => {}) => {
        this.setState(obj, (s) => {
            this.props.updateTabData('schedule', { state: this.state });
            cb(s);
        });
    };

    handleTimeChange = (event) => {
        const reg = /^\d{0,4}$/;
        const val = event.target.value;
        if (reg.test(val)) {
            this._setState({ schedulingTimeValue: val , 
                // didChange: this.props.updateData.schedule_interval == val ? false : true 
            });
        } else {
            this._setState({ digitTimeLengthError: true });
            setTimeout(() => this._setState({ digitTimeLengthError: false }), 3000);
        }
    };

    handleTimeSelect = (current) => {
        const { schedulingTimeValue, schedulingData } = this.state;
        if (schedulingTimeValue !== '' && parseInt(schedulingTimeValue, 10) > 0) {
            const schedulingDataCopy = [...schedulingData];
            const chipToChange = schedulingData.indexOf(current);
            schedulingDataCopy[chipToChange].value = parseInt(schedulingTimeValue, 10);
            this._setState({ schedulingData: schedulingDataCopy, schedulingTimeValue: '', show: true });
        }
    };

    handleDeleteFromChip = (data) => () => {
        const { schedulingData } = this.state;
        const schedulingDataCopy = [...schedulingData];
        const chipToChange = schedulingData.indexOf(data);
        schedulingDataCopy[chipToChange].value = 0;
        this._setState({ schedulingData: schedulingDataCopy });
        const checkVal = schedulingData.find((sch) => sch.value > 0);
        if (!checkVal) {
            this._setState({ show: false });
        }
    };

    handleEmailChip = (e) => {
        const value = e.target.value.replace(/\s|[,]+/g, '');
        if (value && !isEmail.test(String(value).toLowerCase())) {
            this._setState({ isNotValidEmail: true });
        } else {
            this._setState({ isNotValidEmail: false });
        }
        this._setState({ emailValue: value });
    };

    addEmailChip = (e) => {
        if (!e.keyCode || e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 188 || e.keyCode === 9) {
            const email = this.state.emailValue;
            if (isEmail.test(String(email).toLowerCase())) {
                const chips = this.state.recipientsEmails.slice();
                if (
                    !chips.some((chip) => {
                        return chip.toLowerCase() === email.toLowerCase();
                    })
                ) {
                    chips.push(email);
                }
                this._setState({ emailValue: '', recipientsEmails: chips });
            }
        }
    };

    removeEmailChip = (index) => {
        const chips = this.state.recipientsEmails.slice();
        chips.splice(index, 1);
        this._setState({ recipientsEmails: chips });
    };

    errMsg = () => {
        const { classes, tabsData, getTimeInHours } = this.props;
        const isNOJobName = typeof checkKeyInObject(tabsData, 'job.state.jobName') !== 'string' || !tabsData.job.state.jobName.trim().length;
        const isNoSelectedValues = !checkKeyInObject(tabsData, 'job.state.selectedValues', 'bool');
        let error = null;
        if (this.state.digitTimeLengthError) {
            error = <span className={classes.errorMessage}>*Maximum 4 digit number is allowed</span>;
        } else if (isNOJobName && isNoSelectedValues) {
            error = <span className={classes.errorMessage}>*Please fill out job tab</span>;
        } else if (isNOJobName) {
            error = <span className={classes.errorMessage}>*Please fill out job name</span>;
        } else if (isNoSelectedValues) {
            error = <span className={classes.errorMessage}>*Please select project on job tab</span>;
        } else if (getTimeInHours(this.state.schedulingData) < 1) {
            error = <span className={classes.errorMessage}>*Please select interval</span>;
        } else if (this.state.isNotValidEmail) {
            error = <span className={classes.errorMessage}>*Please enter valid email</span>;
        }
        return error;
    };

    makeStateCopy = (state, _newVal, key) => {
        let copy = this.state[state];
        if (state !== 'anchorEl') {
            copy = JSON.parse(JSON.stringify(copy));
        }
        if (_newVal !== undefined && (key || key === 0)) {
            copy[key] = _newVal;
        }
        return copy;
    };

    /* Platform / Browser Actions start */

    handleBrowserChange = (selectedBrowsers, selectedTab, key) => {
        this._setState({
            anchorEl: this.makeStateCopy('anchorEl', null, key),
            browserDialog: this.makeStateCopy('browserDialog', false, key),
            selectedTab: this.makeStateCopy('selectedTab', selectedTab, key),
            selectedBrowsers: this.makeStateCopy('selectedBrowsers', selectedBrowsers, key),
        });
    };

    handleTabChange = (event, value, key) => {
        this.setState({ selectedTab: this.makeStateCopy('selectedTab', value, key) });
    };

    resetBrowserVersions = (platformNewVal, key) => {
        const { selectedTab } = this.state;
        const browserDropDownData = this.makeStateCopy('browserDropDownData');
        const selectedBrowsers = this.makeStateCopy('selectedBrowsers');
        const browserDetails = Devices[Platform[selectedTab[key]]][platformNewVal];
        if (browserDetails) {
            browserDropDownData[key] = Object.keys(browserDetails);
            BrowserSpecificVersionsArray = Object.values(browserDetails);
            selectedBrowsers[key] = [`${browserDropDownData[key][0]} ${BrowserSpecificVersionsArray[0][0]}`];
            this.setState({
                browserDropDownData,
                selectedBrowsers,
            });
        }
    };

    handlePlatformChange = (params, key) => {
        const { selectedPlatform, selectedTab, callback = () => {} } = params;
        const obj = {
            platformDialog: this.makeStateCopy('platformDialog', false, key),
            selectedPlatform: this.makeStateCopy('selectedPlatform', selectedPlatform, key),
            selectedTab: this.makeStateCopy('selectedTab', selectedTab, key),
            anchorEl: this.makeStateCopy('anchorEl', null, key),
        };
        if (selectedPlatform !== this.state.selectedPlatform[key]) {
            obj.browserDropDownData = this.makeStateCopy('browserDropDownData', this.PlatformBasedBrowsers[selectedPlatform], key);
            obj.selectedBrowsers = this.makeStateCopy(
                'selectedBrowsers',
                this.getBrowserDropDownData(
                    this.PlatformBasedBrowsers[selectedPlatform],
                    '',
                    selectedPlatform,
                    obj.selectedPlatform,
                    this.state.selectedBrowsers,
                )[0] || '',
                key,
            );
        }
        this._setState(obj, callback);
    };

    handleExecutionMode = (value, key) => {
        this._setState({
            anchorEl: this.makeStateCopy('anchorEl', null, key),
            selectedExecutionMode: value,
            showSelectedExecutionModeDialog: !this.state.showSelectedExecutionModeDialog,
        });
    };

    updateSelectedBrowser = (currentValue, key) => {
        const { selectedBrowsers, selectedPlatform } = this.state;
        const selectedDevice = getSelectedDevice(selectedPlatform[key]);
        let alreadySelectedBrowsers = checkArrayLength(selectedBrowsers[key]) ? [...selectedBrowsers[key]] : [];

        // if selected device is Andriod or iOS only one browser can be selected
        if (selectedDevice === 'Android' || selectedDevice === 'iOS') {
            alreadySelectedBrowsers = [currentValue];
        } else if (checkArrayLength(alreadySelectedBrowsers)) {
            const indexOfCurrentValue = alreadySelectedBrowsers.indexOf(currentValue);
            if (indexOfCurrentValue > -1) {
                alreadySelectedBrowsers.splice(indexOfCurrentValue, 1);
            } else {
                alreadySelectedBrowsers.push(currentValue);
            }
        } else {
            alreadySelectedBrowsers.push(currentValue);
        }
        return alreadySelectedBrowsers;
    };

    closePopper = (selectBox, key) => {
        const obj = { anchorEl: this.makeStateCopy('anchorEl', null, key) };
        if (selectBox === 'Platform') {
            obj.platformDialog = this.makeStateCopy('platformDialog', false, key);
        } else if (selectBox === 'Execution') {
            obj.showSelectedExecutionModeDialog = false;
        } else {
            // calling from browser
            obj.browserDialog = this.makeStateCopy('browserDialog', false, key);
        }
        this._setState(obj);
    };

    addRow = (i) => {
        const { anchorEl, browserDialog, browserDropDownData, platformDialog, selectedBrowsers, selectedPlatform, selectedTab } = this.state;
        const _selectedPlatform = this.getPlatformDropDownData()[0] || '';

        pushInMidArray(anchorEl, i, null);
        pushInMidArray(browserDialog, i, false);
        pushInMidArray(browserDropDownData, i, this.PlatformBasedBrowsers[_selectedPlatform]);
        pushInMidArray(platformDialog, i, false);
        // Don't change the order of next 2 lines
        pushInMidArray(selectedPlatform, i, _selectedPlatform);
        pushInMidArray(
            selectedBrowsers,
            i,
            this.getBrowserDropDownData(
                this.PlatformBasedBrowsers[_selectedPlatform],
                '',
                _selectedPlatform,
                selectedPlatform,
                this.state.selectedBrowsers,
            )[0] || '',
        );
        // Don't change the order of previous  2 lines
        pushInMidArray(selectedTab, i, 0);
        this._setState({
            anchorEl,
            browserDialog,
            browserDropDownData,
            platformDialog,
            selectedBrowsers,
            selectedPlatform,
            selectedTab,
        });
    };

    deleteRow = (i) => {
        const { anchorEl, browserDialog, browserDropDownData, platformDialog, selectedBrowsers, selectedPlatform, selectedTab } = this.state;
        removeIndexFromArray(anchorEl, i);
        removeIndexFromArray(browserDialog, i);
        removeIndexFromArray(browserDropDownData, i);
        removeIndexFromArray(platformDialog, i);
        removeIndexFromArray(selectedBrowsers, i);
        removeIndexFromArray(selectedPlatform, i);
        removeIndexFromArray(selectedTab, i);
        this._setState({
            anchorEl,
            browserDialog,
            browserDropDownData,
            platformDialog,
            selectedBrowsers,
            selectedPlatform,
            selectedTab,
        });
    };

    handleChangeTab = (state, type) => {
        if (type === 'platform') {
            this.setState({ platformTab: state })
        } else if (type === 'browser') {
            this.setState({ browserTab: state })
        }
    }

    setTabs = (state) => {
        const { sauceContent, localContent, } = this.state

        const _sauceContent = sauceContent.map((val) => {
            return {
                ...val,
                idEl: null,
                platformEl: null,
                browserEl: null,
            }
        })
        const _localContent = localContent.map((val) => {
            return {
                ...val,
                platformEl: null,
                browserEl: null,
            }
        })
        this._setState({ tabState: state, sauceContent: _sauceContent, localContent: _localContent, sauceIDDisable: false, noConfigFound: false, overflowStyle: 'auto' })
    }




    validateExternalGridTab = () => {
        const { externalGrid } = this.state
        return externalGrid.some((elem) => !elem.platform.length || !elem.platformVersion.length || !elem.browser.length )
    }
    getTabsContent = (state) => {
        const { classes, sauceID, environmentInfo, callingFrom, type, updateData, sauceCredentialDetails } = this.props
        const { localContent, sauceContent, platformTab, browserTab, externalGrid, tunnelID, platformVersion, browserVersion, platformLocal, localBrowserValues, configurationSetting, isFieldEmpty, tabState, browserLocal,sauceSysDefault,localSysDefault, sauceIDDisable, sauceBrowserDisable, saucePlatformDisable, localBrowserDisable, localPlatformDisable, overflowStyle, saucelabs_capabliites, deviceSettings, sauceContentUpdated,localContentUpdated, externalGridUpdated, deviceNotAvailable } = this.state

        
        if (state.toLowerCase() === 'saucelabs') {
            return <PlatformBrowserForSauceExecution
                saucelabs_capabliites = {saucelabs_capabliites}
                deviceSettings={deviceSettings}
                platformTab={platformTab}
                callingFrom={callingFrom}
                environmentDetails={environmentInfo}
                browserTab={browserTab}
                tabState={tabState}
                sauceCredentialDetails = {sauceCredentialDetails}
                sauceContent={sauceContent}
                sauceSysDefault={sauceSysDefault}
                sauceIDDisable={sauceIDDisable}
                configurationSetting={configurationSetting}
                sauceTunnelIDs={sauceID}
                sauceContentUpdated={sauceContentUpdated}
                deviceNotAvailable = {deviceNotAvailable}
                // exec_details={type.toLowerCase() === 'update' ? updateData?.exec_details || [] : []}
                sauceBrowserDisable={sauceBrowserDisable}
                saucePlatformDisable={saucePlatformDisable}
                tunnelID={tunnelID}
                overflowStyle={overflowStyle}
                platformVersion={platformVersion}
                browserVersion={browserVersion}
                handleChangeTab={(...args) => this.handleChangeTab(...args)}
                classes={classes}
                setState={(obj) => this._setState(obj)}
            />
        } else if (state.toLowerCase() === 'local') {
            return <PlatformBrowserForLocalExecution
                environmentDetails={environmentInfo}
                callingFrom={callingFrom}
                tabState={tabState}
                platformLocal={platformLocal}
                localSysDefault={localSysDefault}
                overflowStyle={overflowStyle}
                localContentUpdated={localContentUpdated}
                // exec_details={type.toLowerCase() === 'update' ? updateData?.exec_details || [] : []}
                browserLocal={browserLocal}
                configurationSetting={configurationSetting}
                localBrowserValues={localBrowserValues}
                localBrowserDisable={localBrowserDisable}
                localPlatformDisable={localPlatformDisable}
                localContent={localContent}
                classes={classes}
                setState={(obj) => this._setState(obj)}
            />
        } else if (state.toLowerCase() === 'externalgrid') {
            return <ExternalGrid
                environmentDetails={environmentInfo}
                externalGrid={externalGrid}
                callingFrom={callingFrom}
                setExternalGrid={(data) => this._setState({ externalGrid: data })}
                configurationSetting={configurationSetting}
                externalGridUpdated={externalGridUpdated}
                validateExternalGridTab={this.validateExternalGridTab}
                classes={classes}
                type="schedule"
                // exec_details={type.toLowerCase() === 'update' ? updateData?.exec_details || [] : []}
                isFieldEmpty={isFieldEmpty}
                setState={(obj) => this._setState(obj)}
            />
        } 
            
        
    }

    // handleConfigurationSetting = () => {
    //     let { environmentInfo } = this.props

    //     // let { sauceContent, localContent } = this.state
    //     // const lastConfigurationData = JSON.parse(localStorage.getItem('lastRunConfiguration'))
    //     // const lastGenerateConfiguration = JSON.parse(localStorage.getItem('lastGenerateConfiguration'))
    //     // let _externalGrid = []
    //     // let _localContent = []
    //     // let _sauceContent = []
    
    //     if (environmentInfo && environmentInfo?.environments?.length) {
    //         this.setUserDefaultData(environmentInfo,()=>{this.setState({setUserDefault:true})})
    //         this._setState({
    //             configurationSetting: 'newConfiguration',
    //             localContentDisable: false,
    //             sauceContentDisable: false,
    //             externalGridDisable: false,
    //         })

    //     } else {
    //         this._setState({
    //             localConfigurationNotFound: false,
    //             sauceConfigurationNotFound: false,
    //             configurationSetting: 'newConfiguration',
    //             localContentDisable: false,
    //             sauceContentDisable: false,
    //             externalGridDisable: false,
    //         })
    //     }

    // }

    getIconToDisplay = (platform) => {
        const { classes } = this.props;
        platform = platform.split(' ')[0];
        switch (platform?.toLowerCase()) {
            case 'windows':
                return <img height={14} src={windows_Icon} alt="Windows" className={classes.statusIcon} />;
            case 'linux':
                return <img height={14} src={linux_Icon} alt="Linux" className={classes.statusIcon} />;                ;
            case 'macos':
                return <img height={14} src={macos_Icon} alt="macOs" className={classes.statusIcon} />;
            case 'android':
                return <img height={14} src={android_Icon} alt="Android" className={classes.statusIcon} />;
            default:
                return <img height={14} src={default_platform_icon} alt="Default" className={classes.statusIcon} />;

        }
    }


    validateEmpty=(elem) => {
        return elem ? elem?.platform?.toLowerCase() === 'select' || elem?.browser?.toLowerCase() === 'select' : true
    }


    render() {
        const { classes, tabsData, projects, sauceCredentialDetails, environmentInfo } = this.props;
        const {
            startTime,
            endTime,
            schedulingTimeValue,
            schedulingData,
            show,
            anchorEl,
            browserDialog,
            browserDropDownData,
            platformDialog,
            selectedPlatform,
            selectedBrowsers,
            showSelectedExecutionModeDialog,
            selectedExecutionMode,
            selectedTab,
            tabState,
            configurationSetting,
            localContentDisable,
            sauceContentDisable,
            externalGridDisable,
            sauceIDDisable,
            sauceBrowserDisable,
            saucePlatformDisable,
            localBrowserDisable,
            localPlatformDisable,
            extPlatform,
            extBrowser,
            extPlatformVersion,
            noConfigFound,
            sauceContent,
            localContent,
            externalGrid,
            localConfigurationNotFound,
            sauceConfigurationNotFound,
            expandOverview,
            sauceCredentials,
            SauceCredentialLoad,
            localSysDefault,
            deviceNotAvailable,
            setUserDefault,
        } = this.state;

        const sauceContentEmpty = sauceContent.some(this.validateEmpty);
        const localContentEmpty = localContent.some(this.validateEmpty);
        const externalGridEmpty = externalGrid.some((elem) => !elem.platform.length || !elem.platformVersion.length || !elem.browser.length )

        let duplicateSauceObjects = {};
        let duplicateLocalObjects = {};

        const duplicateIds = sauceContent.map(v => v.id).filter((v, i, vIds) => vIds.indexOf(v) !== i)
        const duplicates = sauceContent.filter(obj => duplicateIds.includes(obj.id));
  
        console.log('setUserDefault',setUserDefault);
        duplicateSauceObjects = duplicates.length ?  duplicates[0] : {};
                        
        for (let i = 0; i < localContent.length; i++) {
            const objToCheck = localContent[i]
            const localDuplicates = localContent.filter(val => val.browser === objToCheck.browser && val.platform === objToCheck.platform)
            if (localDuplicates.length > 1) {
                duplicateLocalObjects = objToCheck;
               // return
            } else {
                duplicateLocalObjects = {};
            }
        }

        const correctStartDate = new Date(
            new Date(startTime?.getTime() || null).setMinutes(getMinNearest15Interval(new Date(startTime?.getTime() || null).getMinutes())),
        );
        const checkingStartDate = new Date(correctStartDate);

        const correctEndDate = new Date(new Date(endTime.getTime()).setMinutes(getMinNearest15Interval(new Date(endTime.getTime()).getMinutes())));
        const checkingEndDate = new Date(correctEndDate);

        const isToday = new Date(correctStartDate).toDateString() === new Date().toDateString();
        const isTodayEndDate = new Date(correctEndDate).toDateString() === new Date().toDateString();
        const isPrev = new Date(checkingStartDate.setHours(0, 0, 0, 0)) < new Date(new Date().setHours(0, 0, 0, 0));
        const isPrevEndDate = new Date(checkingEndDate.setHours(0, 0, 0, 0)) < new Date(new Date().setHours(0, 0, 0, 0));

        const suites = checkKeyInObject(tabsData, 'job.state.suites', 'value', []);
        const selectedSuites = checkKeyInObject(tabsData, 'job.state.selected_suites', 'value', []);

        const PlatformBrowserSelectionContainerProps = (key) => {
            return {
                anchorEl: anchorEl[key],
                handleTabChange: (...args) => {
                    this.handleTabChange(...args, key);
                },
                closePopper: (...args) => {
                    this.closePopper(...args, key);
                },
                isScheduleModal: true,
                modalType: this.ModalType,
                selectedTab: selectedTab[key],
                onClickPlatformBrowserContainer: (...args) => {
                    this.onClickPlatformBrowserContainer(key, ...args);
                },
            };
        };

        const rows = [];
        let allowMultiple = false;
        const isSauceLab = !(this.ModalType === config.executionEngine);
        if (selectedSuites.length > 0 && suites.length > 0 && projects.length > 0) {
            const suitId = selectedSuites[0]; // can only select one suite
            const selectedSuite = suites.find((suite) => suite.id === suitId);
            if (checkObject(selectedSuite)) {
                const selectedSuiteProject = projects.find((proejct) => proejct.projectId === selectedSuite.projectId);
                if (checkObject(selectedSuiteProject)) {
                    allowMultiple = selectedSuiteProject.aiqExecution && !isSauceLab;
                }
            }
        }
        // const isSauceLab = !(ModalType === defaultConfig.executionEngine);
        const rowCount = allowMultiple ? this.state.selectedPlatform.length - 1 : 1;

        for (let i = 1; i <= rowCount; i++) {
            rows.push(
                <div key={i} style={{ paddingLeft: '15px', display: 'flex', justifyContent: 'space-around' }}>
                    <div className={classes.chipRoot} style={{ flexDirection: 'column', padding: '6px 12px 12px 12px', width: '50%' }}>
                        <PlatformBrowserSelectionContainer
                            {...PlatformBrowserSelectionContainerProps(i)}
                            dropDownData={(() => {
                                // if (isSauceLab) {
                                //     return Platform;
                                // }
                                const platforms = this.getPlatformDropDownData();
                                if (platforms.indexOf(selectedPlatform[i]) === -1) {
                                    platforms.push(selectedPlatform[i]);
                                }
                                return platforms;
                            })()}
                            handlePlatformChange={(...args) => {
                                this.handlePlatformChange(...args, i);
                            }}
                            isOpenDialog={platformDialog[i]}
                            title="Platform"
                            resetBrowserVersions={(version) => this.resetBrowserVersions(version, i)}
                            selectedDropDownValue={selectedPlatform[i]}
                            versionsArray={PlatformSpecificVersionsArray}
                        />
                    </div>
                    <div className={classes.chipRoot} style={{ flexDirection: 'column', padding: '6px 12px 12px 12px', width: '50%' }}>
                        <PlatformBrowserSelectionContainer
                            {...PlatformBrowserSelectionContainerProps(i)}
                            dropDownData={this.getBrowserDropDownData(
                                browserDropDownData[i],
                                selectedBrowsers[i],
                                selectedPlatform[i],
                                selectedPlatform,
                                selectedBrowsers,
                            )}
                            handleBrowserChange={(...args) => {
                                if (isSauceLab) {
                                    this.handleBrowserChange(...args, i);
                                }else{
                                    this.handleBrowserChange(...args, i);
                                }
                                
                            }}
                            isOpenDialog={browserDialog[i]}
                            title="Browser"
                            selectedBrowserArray={isSauceLab ? selectedBrowsers[i] : []}
                            selectedDropDownValue={selectedBrowsers[i]}
                            versionsArray={BrowserSpecificVersionsArray}
                        />
                    </div>
                    {allowMultiple ? (
                        <div className={classes.actionIconsContainer}>
                            {rowCount > 1 ? (
                                <IconButton className={classes.iconButtonRoot} onClick={() => this.deleteRow(i)}>
                                    <CloseIcon className={classes.actionIcons} />
                                </IconButton>
                            ) : null}
                            {this.getPlatformDropDownData().length > 0 ? (
                                <IconButton className={classes.iconButtonRoot} onClick={() => this.addRow(i + 1)}>
                                    <AddIcon className={`${classes.actionIcons}`} />
                                </IconButton>
                            ) : null}
                        </div>
                    ) : null}
                </div>,
            );
        }

        return (
            <DialogContent className={classes.dialogContent} style={{ maxHeight: 'calc(100vh - 320px)' }} classes={{ root: classes.padding }}>
                {this.errMsg()}
                <div
                    className={classes.rootDialogContent}
                    ref={(node) => {
                        this.calenderRef = node;
                    }}
                >
                    <div className={classes.col1}>
                        <span className={classes.label}>Start Time</span>
                        <DatePicker
                            selected={correctStartDate}
                            onChange={this.onChangeStartDate}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            className={classes.customDatePicker}
                            minDate={new Date()}
                            minTime={isPrev || isToday ? new Date() : undefined}
                            maxTime={isPrev ? new Date() : isToday ? new Date(new Date(new Date().setHours(23)).setMinutes(59)) : undefined}
                            calenderRef={this.calenderRef}
                        />
                    </div>
                    <div className={classes.col1}>
                        <span className={classes.label}>End Time</span>
                        <DatePicker
                            selected={correctEndDate}
                            onChange={this.onChangeEndDate}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                            className={classes.customDatePicker}
                            minDate={new Date()}
                            minTime={isPrevEndDate || isTodayEndDate ? new Date() : undefined}
                            maxTime={
                                isPrevEndDate ? new Date() : isTodayEndDate ? new Date(new Date(new Date().setHours(23)).setMinutes(59)) : undefined
                            }
                            calenderRef={this.calenderRef}
                        />
                    </div>
                </div>
                <SchedulingTime
                        handleTimeChange={this.handleTimeChange}
                        schedulingTimeValue={schedulingTimeValue}
                        schedulingData={schedulingData}
                        handleTimeSelect={this.handleTimeSelect}
                        show={show}
                        handleDeleteFromChip={this.handleDeleteFromChip}
                        tab="suite"
                    />
                 <div className={classes.executionTextFieldContainer}>
                    <PlatformBrowserSelectionContainer
                        {...PlatformBrowserSelectionContainerProps(0)}
                        dropDownData={ExecutionModes}
                        handleExecutionMode={(...args) => {
                            this.handleExecutionMode(...args, 0);
                        }}
                        isOpenDialog={showSelectedExecutionModeDialog}
                        onClickExecutionContainer={(...args) => {
                            this.onClickExecutionContainer(...args, 0);
                        }}
                        title="Execution"
                        selectedDropDownValue={selectedExecutionMode}
                    />
                </div>    
               
                <form autoComplete="off" className={classes.formContainer}>
                    <DialogContent className={classes.dialogContentPlatformBrowser} style={{ maxHeight: 445, overflowY: 'unset' }}>
                        <ExecuteScriptConfigurationDetails 
                            configurationSetting = {configurationSetting}
                            sauceContentDisable = {sauceContentDisable}
                            localContentDisable = {localContentDisable}
                            externalGridDisable = {externalGridDisable}
                            classes = {classes}
                            handleChangeTab = {this.handleChangeTab}
                            _setState = {(obj) => this.setState(obj)}
                            getTabsContent = {this.getTabsContent}
                            environmentInfo = {environmentInfo}
                            calledFrom = 'scheduleModal'
                            localContent = {localContent}
                            externalGrid = {externalGrid}
                            sauceContent = {sauceContent}
                            localSysDefault = {localSysDefault}
                            tabState = {tabState}
                            localConfigurationNotFound = {localConfigurationNotFound}
                            sauceConfigurationNotFound = {sauceConfigurationNotFound}
                            sauceCredentials = {sauceCredentials}
                            SauceCredentialLoad = {SauceCredentialLoad}
                            setTabs = {this.setTabs}
                            />
                        {
                            setUserDefault && (<ExecutionErrorDetails
                                tabState = {tabState} 
                                localContentEmpty = {localContentEmpty}
                                sauceContentEmpty = {sauceContentEmpty}
                                externalGridEmpty = {externalGridEmpty}
                                sauceIDDisable = {sauceIDDisable}
                                saucePlatformDisable = {saucePlatformDisable}
                                sauceBrowserDisable = {sauceBrowserDisable}
                                localBrowserDisable = {localBrowserDisable}
                                localPlatformDisable = {localPlatformDisable}
                                extPlatform = {extPlatform}
                                calledFrom = 'scheduleModal'
                                extBrowser = {extBrowser}
                                extPlatformVersion = {extPlatformVersion}
                                noConfigFound = {noConfigFound}
                                duplicateSauceObjects = {duplicateSauceObjects}
                                duplicateLocalObjects = {duplicateLocalObjects}
                                sauceCredentials = {sauceCredentialDetails}
                                configurationSetting = {configurationSetting}
                                deviceNotAvailable = {deviceNotAvailable}
                            />)
                        }
                     </DialogContent>
                    
                </form>
                <Accordion expanded={expandOverview} 
                    style={{width: 'calc(100% - 22px)', boxShadow: 'none', margin: 0, padding: '0px !important'}}
                    onChange={()=>this.setState({expandOverview: !expandOverview})}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className={ classes.accordionSummary }
                        >
                            <div className={classes.overviewContainer}>
                                <Typography className={classes.headingOverview}>Overview</Typography>
                            </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.rightContainerStyle}>
                        <div className={classes.contentContainer}>
                            <Typography className={classes.headingStyle}>Sauce Labs</Typography>
                            {sauceContent.length && sauceContent[0].platform && sauceContent[0].browser && sauceConfigurationNotFound && Object.keys(sauceCredentialDetails).length > 0 ? sauceContent.map((val, ind) => {
                                if (val.platform && val.platform.toLowerCase() !== 'select' && val.browser && val.browser.toLowerCase() !== 'select') {
                                    return (
                                        <div key={`${val}-${ind}`} className={classes.platformBrowserselection}>
                                            {this.getIconToDisplay(val.platform)}
                                            <Typography className={classes.selectionStyle}>{val.label}</Typography>
                                        </div>
                                    )
                                } 
                                    return null
                                
                            }) : (
                                <div className={classes.platformBrowserselection}>
                                    <Typography className={classes.noSelectionStyle}>You have no selection</Typography>
                                </div>
                            )}
                            <Typography className={classes.headingStyle}>Local</Typography>
                            {localContent.length && localContent[0].platform && localContent[0].browser && localConfigurationNotFound ? localContent.map((val, ind) => {
                                if (val.platform && val.platform.toLowerCase() !== 'select' && val.browser && val.browser.toLowerCase() !== 'select') {
                                    return (
                                        <div key={`${val}-${ind}`} className={classes.platformBrowserselection}>
                                            {this.getIconToDisplay(val.platform)}
                                            <Typography className={classes.selectionStyle}>
                                                {configurationSetting.toLowerCase() === 'newconfiguration' ? `${val.platform.toLowerCase() !== 'select' ? val.platform : ''} . ${val.browser.toLowerCase() !== 'select' ? val.browser : ''}`
                                                :
                                                `${val.platform.toLowerCase() !== 'select' ? val.platform : ''} . ${val.browser.toLowerCase() !== 'select' ? val.browser : ''} ${val.environmentType.toLowerCase() === 'local' ? '(headless)' : '(headful)'}`
                                                }
                                            </Typography>
                                        </div>
                                    )
                                } 
                                    return null
                                
                            }) : (
                                <div className={classes.platformBrowserselection}>
                                    <Typography className={classes.noSelectionStyle}>You have no selection</Typography>
                                </div>
                            )}
                            <Typography className={classes.headingStyle}>Remote</Typography>
                            {externalGrid.length && externalGrid[0].platform && externalGrid[0].browser ? externalGrid.map((val, ind) => {
                                if (val.platform && val.platform.toLowerCase() !== 'select' && val.browser && val.browser.toLowerCase() !== 'select') {
                                    return (
                                        <div key={`${val}-${ind}`} className={classes.platformBrowserselection}>
                                            {this.getIconToDisplay(val.platform)}
                                            <Typography className={classes.selectionStyle}>{`${val.platform} . ${val.browser} ${val.browserVersion}`}</Typography>
                                        </div>
                                    )
                                } 
                                    return null
                                
                            }) : (
                                <div className={classes.platformBrowserselection}>
                                    <Typography className={classes.noSelectionStyle}>You have no selection</Typography>
                                </div>
                            )}
                        </div>
                    </AccordionDetails>
                
                </Accordion>
               
            </DialogContent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sauceID: state.projectReducer.sauceID,
        user: state.authReducer.user,
        projects: state.projectReducer.projectsByIdAndName,
        suitesForJob: state.scheduleReducer.suitesForJob,
        testCasesForJob: state.testCaseReducer.testCasesWithAccount,
        tabsData: state.generalModalReducer.tabsData,
        prefences: state.accountReducer.prefences,
        environmentInfo: state.projectReducer.environmentInfo,
        environmentError: state.projectReducer.environmentError,
        sauceCredentialDetails:state.accountReducer.sauceDetails,
        execDetails:state.scheduleReducer.execDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
        getEnvironmentType: () => dispatch(ProjectActions.getEnvironmentType()),
        getExecutionDetailsByScheduleId: (scheduleId) => dispatch(ProjectActions.getExecutionDetailsByScheduleId(scheduleId)),
        getTunnelIDForSauceLabs: () => dispatch(ProjectActions.getTunnelIDForSauceLabs()),
        getSauceDetails: () => dispatch(AccountActions.getSauceDetails()),
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScheduleModal));
