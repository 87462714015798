import { ActionTypes } from '../constants';
import { checkObject } from '../../utils';

const initialState = {
    error: null,
    isError: false,
    isLoading: false,
    success: null,
    autoSuggestComponentState: {},
};

export default function testStepReducer(state = initialState, { payload, type }) {
    switch (type) {
        case ActionTypes.CLEAR_ERROR:
            return { ...state, error: null };
        case ActionTypes.CLEAR_SUCCESS:
            return { ...state, success: null };
        case ActionTypes.UPDATE_LIVE_AND_RECOVER_STEPS:
            return { ...state, error: null, success: null, isLoading: true };
        case ActionTypes.UPDATE_LIVE_AND_RECOVER_STEPS_SUCCESS:
            return { ...state, success: payload.message, error: null, isLoading: false };
        case ActionTypes.UPDATE_LIVE_AND_RECOVER_STEPS_FAILURE:
            return { ...state, success: null, error: payload.message, isLoading: false };
        case ActionTypes.STORE_REMOVE_AUTO_SUGGEST_COMPONENT_STATE: {
            let autoSuggestComponentState = {};
            if (payload.key) {
                autoSuggestComponentState = JSON.parse(JSON.stringify(state.autoSuggestComponentState));
                if (checkObject(payload.state)) {
                    autoSuggestComponentState = { ...autoSuggestComponentState, [payload.key]: payload.state };
                } else {
                    delete autoSuggestComponentState[payload.key];
                }
            }
            return { ...state, autoSuggestComponentState: JSON.parse(JSON.stringify(autoSuggestComponentState)) };
        }
        case ActionTypes.DOWNLOAD_LOGS: {
            return { ...state, success: 'Download will start soon...', error: null };
        }
        case ActionTypes.DOWNLOAD_LOGS_SUCCESS: {
            return { ...state, success: 'Download in progress...', error: null };
        }
        case ActionTypes.DOWNLOAD_LOGS_FAILURE: {
            return { ...state, success: null, error: payload.message };
        }
        case ActionTypes.DOWNLOAD_TESTSTEP_FILE: {
            return { ...state, success: null, error: null };
        }
        case ActionTypes.DOWNLOAD_TESTSTEP_FILE_SUCCESS: {
            return { ...state, success: 'Successfully Downloaded', error: null };
        }
        case ActionTypes.DOWNLOAD_TESTSTEP_FILE_FAILURE: {
            return { ...state, success: null, error: payload.message };
        }
        default:
            return state;
    }
}
