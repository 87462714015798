/* eslint-disable no-underscore-dangle */
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseOnEscape from 'react-close-on-escape';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Close from '@material-ui/icons/Close';
import { Fields, UserData } from '../../../components/User';
import { UserActions, ModalActions } from '../../../store/actions';
import { checkKeyInObject } from '../../../utils/utils';
import {
    DIALOG_ROOT,
    MODAL_HEADER_TITLE,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_HEADER_CLOSE_ICON,
    MODAL_FOOTER,
    MODAL_CANCEL_BUTTON,
    MODAL_SUBMIT_BUTTON,
} from '../../../common/cssConstants';
import { SEGMENT_EVENT, USER_NAME } from '../../../common/constants';
import { track } from '../../../services/Segment';

const buttonText = { Add: 'Create', Remove: 'Confirm', Edit: 'Update' };
const items = [{ value: 'SDET' }, { value: 'ADMIN' }];
const title = {
    Add: 'Create New User',
    Remove: 'Delete User',
    Edit: 'Edit User',
};
const Validation = /^[a-z]+[a-z0-9]*$/;
const isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const styles = () => ({
    dialogRoot: DIALOG_ROOT,
    dialogTitleStyle: MODAL_HEADER_TITLE,
    formdiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

class UserModal extends React.Component {
    constructor(props) {
        super(props);
        const {
            modalData: { user },
        } = props;
        this.state = {
            email: user ? user.email : '',
            emailErrorMessage: '',
            selectedItem: user ? user.role : '',
            userName: user ? user.name : '',
            password: '',
            isDirty: false,
            isDisabled: false,
            userNameErrorMessage: '',
            // this accountId should deliver the current active user's accountId
        };
        this.interval = null;
    }

    componentDidMount() {
        if (this.props.usersStore && this.props.usersStore.role !== 'ADMIN') {
            this.updateState({ selectedItem: this.props.usersStore.role });
        }
    }

    // @computed
    get enableSubmit() {
        const {
            modalData: { modaltype },
        } = this.props;
        const { email, password, selectedItem, userName, isDisabled } = this.state;
        if (isDisabled) {
            return false;
        }
        switch (modaltype /*eslint-disable */) {
            case 'Add':
                return (
                    email.length &&
                    isEmail.test(String(email).toLowerCase()) &&
                    userName.length &&
                    Validation.test(userName) &&
                    selectedItem.length &&
                    password.length > 5
                );

            case 'Remove':
                return true;

            case 'Edit':
                return selectedItem.length;

            default:
                return false;
        }
    }

    getSelectedItems = (selectedItem) => {
        this.setState({ selectedItem });
    };

    markDirtyField = () => this.setState({ isDirty: true });

    fetchUsers = () => {
        const {
            usersStore: { accountId, name },
        } = this.props;
        if (`${accountId}` === `1` && name === USER_NAME) {
            this.props.getUsers();
        } else {
            this.props.getUserByAccountId(accountId);
        }
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    createUser = async () => {
        const { toggleModal, usersStore, users } = this.props;
        const { email, selectedItem, userName, password } = this.state;
        // const accountId = usersStore.activeUser.userAccount || usersStore.activeUser.accountId;
        const accountId = (usersStore.userAccount || usersStore.accountId).toString();
        let exist = users.find((usr) => usr.name.toLowerCase() === userName.toLowerCase());
        if (exist && exist.hasOwnProperty('name')) {
            this.setState({ userNameErrorMessage: 'user already exist' });
            return;
        }
        // create user
        // const isCreated =false
        const isCreated = await this.props.createUser({
            userName,
            email,
            role: selectedItem,
            password,
            accountId,
        });

        if (checkKeyInObject(isCreated, 'status')) {
            this.fetchUsers();
            // close modal
            const eventName = SEGMENT_EVENT.USER_CREATED;
            let segmentData = {
                userName : userName,
                email:email,
                role:selectedItem,
                accountId:accountId
            }
            track(eventName,segmentData)
            toggleModal();
        } else {
            if (checkKeyInObject(isCreated, 'message')) {
                try {
                    if (isCreated && isCreated.message && isCreated.message.includes && isCreated.message.includes('user')) {
                        this.setState({ userNameErrorMessage: isCreated.message });
                    } else if (isCreated.message && isCreated.message.includes && isCreated.message.includes('email')) {
                        this.setState({ emailErrorMessage: isCreated.message });
                    }
                } catch (error) {
                    toggleModal();
                    this.props.toggleSnackBar('Unable to create user!');
                }
            }
        }
    };

    updateUser = async () => {
        const { toggleModal, modalData } = this.props;
        const { selectedItem } = this.state;
        const user = modalData.user;
        // create user
        const isUpdated = await this.props.updateUser({
            userId: user.id,
            role: selectedItem,
            email: user.email,
            //name: user.name,
        });

        if (isUpdated) {
            this.fetchUsers();

            // close modal
            toggleModal();
        }
    };

    deleteUser = async () => {
        const { toggleModal, modalData } = this.props;
        // delete user
        const id = modalData.user.id;
        const isDeleted = await this.props.deleteUser(id);

        if (isDeleted) {
            this.fetchUsers();

            // close modal
            toggleModal();
        }
    };

    handleChange = (name) => (event) => {
        if (name == 'userName' && this.state.userNameErrorMessage) {
            this.setState({ userNameErrorMessage: '', isDisabled: false });
        }
        this.setState({ [name]: event.target.value });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const {
            modalData: { modaltype },
            toggleModal,
        } = this.props;

        switch (modaltype) {
            case 'Add':
                this.createUser();
                break;

            case 'Remove':
                this.deleteUser();
                break;

            case 'Edit':
                this.updateUser();
                break;

            default:
                toggleModal();
                break;
        }
    };
    render() {
        const {
            classes,
            modalData: { modaltype, user },
            open,
            toggleModal,
            usersStore,
        } = this.props;
        const { email, userName, selectedItem, password, isDirty, emailErrorMessage, userNameErrorMessage } = this.state;
        const component = {
            Add: (
                <Fields
                    name={userName}
                    email={email}
                    password={password}
                    getSelectedItems={this.getSelectedItems}
                    handleChange={this.handleChange}
                    selectedItem={selectedItem}
                    items={items}
                    // handleEmail={this.handleEmail}
                    isDirty={isDirty}
                    markDirtyField={this.markDirtyField}
                    userNameErrorMessage={userNameErrorMessage}
                    emailErrorMessage={emailErrorMessage}
                    user={usersStore}
                />
            ),
            Remove: <UserData name={userName} email={email} userRole={selectedItem} />,
            Edit: (
                <Fields
                    getSelectedItems={this.getSelectedItems}
                    handleChange={this.handleChange}
                    selectedItem={selectedItem}
                    items={items}
                    name={userName}
                    email={email}
                    modalDataEdit={user}
                />
            ),
        };
        return (
            <div>
                <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" maxWidth="sm" className={classes.dialogRoot}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            {title[modaltype]}
                        </DialogTitle>
                        <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                            <Close style={MODAL_HEADER_CLOSE_ICON} onClick={() => toggleModal()} aria-label="closeIcon" id="closeIcon" />
                        </div>
                    </div>
                    <DialogContent style={{ padding: '0px 30px' }}>
                        <div className={classes.formdiv}>{component[modaltype]}</div>
                    </DialogContent>
                    <DialogActions style={MODAL_FOOTER}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                                this.setState({ isDisabled: true });
                                this.handleSubmit(e);
                            }}
                            disabled={!this.enableSubmit}
                            style={MODAL_SUBMIT_BUTTON}
                        >
                            {buttonText[modaltype]}
                        </Button>
                        <CloseOnEscape onEscape={() => toggleModal()}>
                            <Button variant="outlined" color="secondary" onClick={() => toggleModal()} style={MODAL_CANCEL_BUTTON}>
                                Cancel
                            </Button>
                        </CloseOnEscape>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

UserModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    modalData: PropTypes.shape({}).isRequired,
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    usersStore: PropTypes.instanceOf(Object).isRequired,
};

// export default withStyles(styles)(UserModal);
const mapStateToProps = (state) => {
    return {
        usersStore: state.authReducer.user,
        users: state.userReducer.users,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: () => dispatch(UserActions.getUsers()),
        getUserByAccountId: (accountId) => dispatch(UserActions.getUserByAccountId(accountId)),
        deleteUser: (data) => dispatch(UserActions.deleteUser(data)),
        updateUser: (data) => dispatch(UserActions.updateUser(data)),
        createUser: (data) => dispatch(UserActions.createUser(data)),
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserModal));
