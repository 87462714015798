import { ActionTypes } from '../constants';

const initialState = {
    isCaseUploaded: false,
    isDataUploaded: false,
    isLoding: false,
};
export default function uploadProgressReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.UPLOAD_DATA_FILE:
        case ActionTypes.UPLOAD_CASE_FILE:
            return {
                ...state,
                isLoding: true,
            };
        case ActionTypes.UPLOAD_CASE_FILE_SUCCESS:
            return {
                ...state,
                isCaseUploaded: true,
                isLoding: false,
            };
        case ActionTypes.UPLOAD_DATA_FILE_FAILURE:
        case ActionTypes.UPLOAD_CASE_FILE_FAILURE:
            return {
                ...state,
                isLoding: false,
            };
        case ActionTypes.UPLOAD_DATA_FILE_SUCCESS:
            return {
                ...state,
                isDataUploaded: true,
                isLoding: false,
            };
        default:
            return state;
    }
}
