import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { THREE_DOT_MENU_CSS } from '../../common/cssConstants';

const styles = () => ({
    btn: {
        width: '22px',
        height: '22px',
        marginRight: '10px',
    },
    icon: {
        color: THREE_DOT_MENU_CSS.iconColor,
        fontSize: THREE_DOT_MENU_CSS.iconFontSize,
    },
    disabledIcon: {
        color: '#BAC4CE',
    },
});
const ExpandBtn = ({ classes, flag, disabledStatus, customClass, action, isChevronButton }) => (
    <IconButton classes={{ root: classes.btn }} aria-label="Expend-Icon" onClick={action}>
        {flag ? (
            <ExpandLess
                className={`${classes.icon} ${customClass} ${disabledStatus ? classes.disabledIcon : ''}`}
                aria-label="expandLessIcon"
                id="expandLessIcon"
            />
        ) : (
            !isChevronButton ?
                <ExpandMore
                    className={`${classes.icon} ${customClass} ${disabledStatus ? classes.disabledIcon : ''}`}
                    aria-label="expandMoreIcon"
                    id="expandMoreIcon"
                />
                :
                <ChevronRightIcon
                    className={`${classes.icon} ${customClass} ${disabledStatus ? classes.disabledIcon : ''}`}
                    aria-label="expandMoreIcon"
                    id="expandMoreIcon"
                />
        )}
    </IconButton>
);

ExpandBtn.propTypes = {
    action: PropTypes.func.isRequired,
    disabledStatus: PropTypes.bool,
    customClass: PropTypes.string,
};

ExpandBtn.defaultProps = {
    disabledStatus: false,
    customClass: '',
};

export default withStyles(styles)(ExpandBtn);
