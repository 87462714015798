import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { checkObject } from '../../utils/utils';

const styles = (theme) => ({
    table: {
        overflowX: 'hidden',
        overflowY: 'hidden',
        [theme.breakpoints.only('xs')]: {
            overflowX: 'auto',
        },
    },
    preventOverflow: {
        // [theme.breakpoints.only('xs')]: {
        //     overflowX: 'auto',
        // },
    },
    noScroll: {
        '&>.TableHead': {
            paddingRight: 0,
        },
        '&>.TableBody': {
            overflowY: 'auto',
        },
    },
});

const Table = (props) => {
    const { children, style, className, classes, dataTypePrefix, removeOverflow, noScroll, ...rem } = props;
    const classNames = className || '';
    let _style = checkObject(style) ? style : {};
    _style = {
        ..._style,
    };
    return (
        <div data-type={`${dataTypePrefix}Table`} {...rem} style={_style} className={`${classNames} ${!removeOverflow ? `${classes.table} ${noScroll ? classes.noScroll : ''}` : classes.preventOverflow}`}>
            {children}
        </div>
    );
};

Table.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    dataTypePrefix: PropTypes.string,
};

Table.defaultProps = {
    dataTypePrefix: '',
};

export default withStyles(styles)(Table);
