import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';

// custom components
import { ModalActions, ProjectActions, UserActions } from '../../store/actions';
// modal components
import AttachDataShow from './AttachData/AttachDataShow';
import AdminUserModal from './Admin/UserModal';
import EditProjectNameModal from './ProjectModal/EditProjectNameModal';
import ExecutionStatusModal from './Executions/ExecutionStatusModal';
import FilePreviewModal from './FilePreviewModal/FilePreviewModal';
import ReportPreviewModal from './ReportPreviewModal/ReportPreviewModal';
import GroupModal from './Admin/GroupModal';
import UploadWizardNew from './Stepper/UploadWizardNew';
import UserModal from './User/UserModal';
import UploadTestData from './UploadTestData/UploadTestData';
import UpdateProject from './UpdateProject/UpdateProject';
import VideoPreviewModal from './Executions/VideoPreviewModal';
import HomeTour from '../../components/Tour/HomeTour';
import SubTourModal from '../../components/Tour/SubTourModal';
import GeneralModal from './GeneralModal/GeneralModal';
import ExecutionModal from './Executions/ExecutionModal';

const styles = {};

const ModalMapping = (props) => {
    // props
    const {
        history,
        isModalVisible,
        modalData,
        modalName,
        selectedProject,
        tempCheckedCaseIds,
        user: { accountId },
        videoUrl,
    } = props;
    // methods
    const { toggleSnackBar, toggleModal, saveTempCheckedCaseIds } = props;
    const modal = [];
    console.log(isModalVisible, ">>>>>", modalName)
    modalName.forEach((name, ind) => {
        if (isModalVisible[`${name}__${ind}`]) {
            switch (name) {
                case 'associatedTestCaseModal': {
                    modal.push(
                        <AttachDataShow
                            key={ind}
                            accountId={accountId}
                            open={isModalVisible[`${name}__${ind}`]}
                            saveTempCheckedCaseIds={saveTempCheckedCaseIds}
                            selectedProject={selectedProject}
                            showSnackBar={toggleSnackBar}
                            tempCheckedCaseIds={tempCheckedCaseIds}
                            testDataValues={modalData[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                            history={history}
                            editable = {true}
                        />,
                    );
                    break;
                }
                case 'associatedTestCaseModalView': {
                    modal.push(
                        <AttachDataShow
                            key={ind}
                            accountId={accountId}
                            open={isModalVisible[`${name}__${ind}`]}
                            saveTempCheckedCaseIds={saveTempCheckedCaseIds}
                            selectedProject={selectedProject}
                            showSnackBar={toggleSnackBar}
                            tempCheckedCaseIds={tempCheckedCaseIds}
                            testDataValues={modalData[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                            history={history}
                            editable = {false}
                        />,
                    );
                    break;
                }
                case 'statusModal': {
                    modal.push(
                        <ExecutionStatusModal
                            key={ind}
                            modalState={isModalVisible[`${name}__${ind}`]}
                            handleClose={toggleModal}
                            data={modalData[`${name}__${ind}`]}
                        />,
                    );
                    break;
                }
                case 'filePreviewModal': {
                    modal.push(
                        <FilePreviewModal
                            key={ind}
                            open={isModalVisible[`${name}__${ind}`]}
                            showSnackBar={toggleSnackBar}
                            testDataValues={modalData[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                            history={history}
                        />,
                    );
                    break;
                }
                case 'reportPreviewModal': {
                    modal.push(
                        <ReportPreviewModal
                            key={ind}
                            open={isModalVisible[`${name}__${ind}`]}
                            showSnackBar={toggleSnackBar}
                            modalData={modalData[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                        />,
                    );
                    break;
                }
                case 'groupModal': {
                    modal.push(
                        <GroupModal
                            key={ind}
                            open={isModalVisible[`${name}__${ind}`]}
                            showSnackBar={toggleSnackBar}
                            modalData={modalData[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                        />,
                    );
                    break;
                }
                case 'adminUserModal': {
                    modal.push(
                        <AdminUserModal
                            key={ind}
                            open={isModalVisible[`${name}__${ind}`]}
                            showSnackBar={toggleSnackBar}
                            modalData={modalData[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                        />,
                    );
                    break;
                }
                case 'uploadData': {
                    modal.push(
                        <UploadTestData
                            key={ind}
                            open={isModalVisible[`${name}__${ind}`]}
                            selectedProjectId={selectedProject && `${selectedProject.projectId}`} // params && params.projectId }
                            showSnackBar={toggleSnackBar}
                            testDataValues={modalData[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                        />,
                    );
                    break;
                }
                case 'uploadTestCase': {
                    modal.push(
                        <UploadWizardNew
                            key={ind}
                            open={isModalVisible[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                            selectedProjectId={modalData[`${name}__${ind}`]}
                            showSnackBar={toggleSnackBar}
                            history={history}
                        />,
                    );
                    break;
                }
                case 'uploadTestCaseStep': {
                    modal.push(
                        <UploadWizardNew
                            key={ind}
                            open={isModalVisible[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                            selectedProjectId={modalData[`${name}__${ind}`]}
                            showSnackBar={toggleSnackBar}
                            history={history}
                        />,
                    );
                    break;
                }
                case 'userModal': {
                    modal.push(
                        <UserModal
                            key={ind}
                            open={isModalVisible[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                            showSnackBar={toggleSnackBar}
                            modalData={modalData[`${name}__${ind}`]}
                        />,
                    );
                    break;
                }
                case 'video': {
                    modal.push(
                        <VideoPreviewModal
                            key={ind}
                            modalState={isModalVisible[`${name}__${ind}`]}
                            handleClose={toggleModal}
                            showSnackBar={toggleSnackBar}
                            videoUrl={videoUrl[`${name}__${ind}`]}
                            caseName={modalData[`${name}__${ind}`]}
                        />,
                    );
                    break;
                }
                case 'renameProject': {
                    modal.push(
                        <EditProjectNameModal
                            key={ind}
                            open={isModalVisible[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                            showSnackBar={toggleSnackBar}
                            modalData={modalData[`${name}__${ind}`]}
                        />,
                    );
                    break;
                }
                case 'updateProject': {
                    modal.push(
                        <UpdateProject
                            key={ind}
                            open={isModalVisible[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                            showSnackBar={toggleSnackBar}
                            modalData={modalData[`${name}__${ind}`]}
                            history={history}
                        />,
                    );
                    break;
                }
                case 'homeTour': {
                    modal.push(<HomeTour key={ind} open={isModalVisible[`${name}__${ind}`]} toggleModal={toggleModal} />);
                    break;
                }
                case 'businessAnalystTour': {
                    modal.push(
                        <SubTourModal
                            key={ind}
                            open={isModalVisible[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                            modalData={modalData[`${name}__${ind}`]}
                        />,
                    );
                    break;
                }
                case 'GeneralModal': {
                    modal.push(
                        <GeneralModal
                            key={ind}
                            open={isModalVisible[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                            modalData={modalData[`${name}__${ind}`]}
                        />,
                    );
                    break;
                }
                case 'ExecutionModal': {
                    modal.push(
                        <ExecutionModal
                            key={ind}
                            open={isModalVisible[`${name}__${ind}`]}
                            toggleModal={toggleModal}
                            modalData={modalData[`${name}__${ind}`]}
                        />,
                    );
                    break;
                }
                default:
                    break;
            }
        }
    });
    return modal;
};

ModalMapping.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    tempCheckedCaseIds: PropTypes.instanceOf(Object).isRequired,
    toggleModal: PropTypes.func.isRequired,
};

ModalMapping.defaultProps = {
    tempCheckedCaseIds: {},
};

const mapStateToProps = (state) => ({
    projects: state.projectReducer.projects,
    selectedProject: state.projectReducer.selectedProject,
    tempCheckedCaseIds: state.projectReducer.tempCheckedCaseIds,
    success: state.userReducer.success,
    user: state.authReducer.user,

    // Modal Reducer
    isModalVisible: state.modalReducer.isModalVisible,
    isSnackBarOpen: state.modalReducer.isSnackBarOpen,
    modalData: state.modalReducer.modalData,
    modalName: state.modalReducer.modalName,
    videoUrl: state.modalReducer.videoUrl,
});

const mapDispatchToProps = (dispatch) => ({
    clearError: () => dispatch(UserActions.clearError()),
    clearSuccess: () => dispatch(UserActions.clearSuccess()),
    toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
    toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
    saveTempCheckedCaseIds: (ids) => dispatch(ProjectActions.saveTempCheckedCaseIds(ids)),
});

export const Modal = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModalMapping));
