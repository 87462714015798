import { ActionTypes } from '../constants';
import DashboardMiddleWare from '../middleware/dashboardMiddleware';
import { dashboardMap } from '../../utils';

class DashboardActions {
    static clearDashboardError() {
        return {
            type: ActionTypes.CLEAR_ERROR,
        };
    }

    static clearDashboardSuccess() {
        return {
            type: ActionTypes.CLEAR_SUCCESS,
        };
    }

    static toggleDashboardSettingGear(flag) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.TOGGLE_DASHBOARD_SETTING_GEAR,
                payload: { settingDialogOpen: flag },
            });
        };
    }

    static dashboardTickerTimeIndicator(value) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.DASHBOARD_TICKER_TIMEINDICATOR,
                payload: {
                    tickerIndicatorValue: value,
                },
            });
            DashboardActions.callDashboardAPI(value, dispatch);
        };
    }

    static getDashboardInfo(indicator) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_DASHBOARD_INFO,
            });
            DashboardActions.callDashboardAPI(indicator, dispatch);
        };
    }

    static callDashboardAPI(indicator, dispatch) {
        DashboardMiddleWare.getDashboardInfo(dashboardMap[indicator])
            .then((data) =>
                dispatch({
                    type: ActionTypes.GET_DASHBOARD_INFO_SUCCESS,
                    payload: data,
                }),
            )
            .catch((error) =>
                dispatch({
                    type: ActionTypes.GET_DASHBOARD_INFO_FAILED,
                    payload: {
                        error,
                        message: 'Failed to fetch dashboard info',
                    },
                }),
            );
    }
}

export default DashboardActions;
