import React, { useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

const CreateVariable = (props) => {
    const [variable, setVariable] = useState('');
    const [value, setValue] = useState('');

    const { classes, onInputHandler } = props;
    return (
        <DialogContent className={classes.dialogContent}>
            <span>
                <TextField
                    autoFocus
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    id="Variable"
                    aria-label="Variable"
                    type="text"
                    placeholder="Variable name"
                    fullWidth
                    value={variable}
                    onChange={(e) => {
                        setVariable(e.target.value);
                        onInputHandler('variable', e);
                    }}
                    className={[classes.textField, classes.noMargin, classes.placeholderInput].join(' ')}
                />
                <span className={classes.error}>&nbsp;{/\s+/g.test(variable) && 'Variable should not contain white spaces.'}</span>
            </span>
            <span>
                <TextField
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    id="Value"
                    aria-label="Value"
                    placeholder="Press enter key to insert multi-line value"
                    fullWidth
                    multiline
                    rowsMax={5}
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                        onInputHandler('value', e);
                    }}
                    className={[classes.textField, classes.minMargin, classes.placeholder].join(' ')}
                />
            </span>
        </DialogContent>
    );
};

export default CreateVariable;
