import { ActionTypes } from '../constants';
import { TestStepMiddleware } from '../middleware';
import { arrangeStepsWithCompoundSteps, checkKeyInObject, checkArrayLength } from '../../utils/utils';
import SelectedTestCaseActions from './selectedTestCaseAction';

class TestStepActions {
    /**
     * @param {Object} params
     * @param {Object} obj
     */
    static updateLiveAndRecoverSteps(obj, params = {}, onSuccess = () => {}, onFail = () => {},showMessage=false) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.UPDATE_LIVE_AND_RECOVER_STEPS });
            try {
                if (checkKeyInObject(obj, 'recoverSteps') && checkKeyInObject(obj, 'brokenDownSteps')) {
                    const isNoRecoverSteps = obj.recoverSteps.some((recStep) => {
                        if (
                            !checkKeyInObject(recStep, 'instr', 'bool') ||
                            !checkKeyInObject(recStep, 'data', 'bool') ||
                            !checkKeyInObject(recStep, 'xpath', 'bool')
                        ) {
                            // eslint-disable-next-line no-console
                            console.log('EMPTY recStep', recStep);
                            return true;
                        }
                        return false;
                    });

                    const isNoBrokenDownSteps = obj.brokenDownSteps.some((bdStep) => {
                        if (
                            !checkKeyInObject(bdStep, 'instr', 'bool') ||
                            !checkKeyInObject(bdStep, 'data', 'bool') ||
                            !checkKeyInObject(bdStep, 'xpath', 'bool')
                        ) {
                            // eslint-disable-next-line no-console
                            console.log('EMPTY bdStep', bdStep);
                            return true;
                        }
                        return false;
                    });

                    if (!isNoRecoverSteps && !isNoBrokenDownSteps) {
                        const { status } = await TestStepMiddleware.updateLiveAndRecoverSteps(obj, params);
                        if (status) {
                            onSuccess();
                            const _testSteps = await arrangeStepsWithCompoundSteps(obj.brokenDownSteps);
                            if (checkArrayLength(_testSteps)) _testSteps.forEach((__step) => delete __step.debug);
                            dispatch(SelectedTestCaseActions.setSelectedTestCaseData({ testCaseId: params.testCaseId, testSteps: _testSteps }));
                            dispatch({
                                type: ActionTypes.GET_TESTSTEPS_SUCCESS,
                                payload: {
                                    testSteps: { testSteps: obj.brokenDownSteps }, // Not confirm why it's sending
                                    steps: {
                                        testSteps: _testSteps, // formated steps
                                        recoverTestSteps: obj.recoverSteps,
                                    },
                                    testCaseId: params.testCaseId,
                                },
                            });
                            return dispatch({
                                type: ActionTypes.UPDATE_LIVE_AND_RECOVER_STEPS_SUCCESS,
                                payload: { message: `${!showMessage ? 'Updated teststeps successfully' : ''}` },
                            });
                        }
                    }
                    throw new Error('error');
                }
                throw new Error('error');
            } catch (error) {
                onFail();
                dispatch({
                    type: ActionTypes.UPDATE_LIVE_AND_RECOVER_STEPS_FAILURE,
                    payload: { message: `${!showMessage ? 'Unable to update teststeps' : ''}` },
                });
            }
            return false;
        };
    }

    /**
     * @param {Object} params
     * @param {Object} obj
     */
    static updateSteps(params, obj /* onSuccess = () => { }, onFail = () => { } */) {
        const _params = { ...params };
        return async (dispatch) => {
            dispatch({ type: ActionTypes.UPDATE_STEPS });
            try {
                const { status } = await TestStepMiddleware.updateSteps(_params, obj);
                if (status) {
                    dispatch({
                        type: ActionTypes.UPDATE_RECOVER_STEPS,
                        payload: {
                            steps: {
                                recoverTestSteps: obj.testSteps,
                            },
                            testCaseId: _params.testCaseId,
                        },
                    });
                    return dispatch({
                        type: ActionTypes.UPDATE_STEPS_SUCCESS,
                        payload: { message: 'teststeps sent successfully' },
                    });
                }
                throw new Error('error');
            } catch (error) {
                // onFail();
                return dispatch({
                    type: ActionTypes.UPDATE_STEPS_FAILURE,
                    payload: { message: 'Unable to sent teststeps' },
                });
            }
        };
    }

    static storeRemoveAutoSuggestComponentState(key, state = {}) {
        return (dispatch) => {
            return dispatch({
                type: ActionTypes.STORE_REMOVE_AUTO_SUGGEST_COMPONENT_STATE,
                payload: { state, key },
            });
        };
    }

    static sendEmailLogs(data) {
        return async (dispatch) => {
            dispatch({
                type: ActionTypes.SEND_EMAIL_LOGS,
            });
            const response = await TestStepMiddleware.EmailLogs(data);
            try {
                if (response) {
                    return dispatch({
                        type: ActionTypes.SEND_EMAIL_LOGS_SUCCESS,
                        payload: 'Successfully sent crash report to support team.',
                    });
                }
                throw new Error('error');
            } catch (error) {
                return dispatch({
                    type: ActionTypes.SEND_EMAIL_LOGS_FAILED,
                    payload: { error: 'Unable to send crash report.' },
                });
                // removed download functionality bcz now download can be done by testStep editor
            }
        };
    }

    static downloadLogs(obj) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.DOWNLOAD_LOGS });
            try {
                const response = await TestStepMiddleware.EmailLogs(obj);
                if (response) {
                    dispatch({ type: ActionTypes.DOWNLOAD_LOGS_SUCCESS });
                    return response;
                }
                throw new Error('error');
            } catch (error) {
                dispatch({
                    type: ActionTypes.DOWNLOAD_LOGS_FAILURE,
                    payload: { message: 'Unable to download logs' },
                });
                return false;
            }
        };
    }

    static downloadTestStepsFile(id, isExecution = false, single = false) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.DOWNLOAD_TESTSTEP_FILE });
            try {
                const response = await TestStepMiddleware.downloadTestStepsFile(id, isExecution, single);
                if (response) {
                    dispatch({ type: ActionTypes.DOWNLOAD_TESTSTEP_FILE_SUCCESS });
                    return response;
                }
                throw new Error('error');
            } catch (error) {
                dispatch({
                    type: ActionTypes.DOWNLOAD_TESTSTEP_FILE_FAILURE,
                    payload: { message: 'Unable to download file' },
                });
                return false;
            }
        };
    }
}
export { TestStepActions };
