import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import CloseOnEscape from 'react-close-on-escape';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { getFormatedDate } from '../../../utils/utils';
import {
    DIALOG_ROOT,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_HEADER_CLOSE_ICON,
    MODAL_FOOTER,
    MODAL_SUBMIT_BUTTON,
    MODAL_CANCEL_BUTTON,
    MODAL_HEADER_TITLE,
    BLACK_FONT,
} from '../../../common/cssConstants';
import { SEGMENT_EVENT } from '../../../common/constants';
import { track } from '../../../services/Segment';

const styles = (theme) => ({
    dialogTitleStyle: MODAL_HEADER_TITLE,
    dialog: {
        textAlign: 'center',
        backgroundColor: '#e9effd',
        borderBottom: '1px solid #dcdcdc',
    },
    keyHeading: {
        width: '100px',
        float: 'left',
        fontSize: '12px',
    },
    formControl: {
        width: '100%',
        flexDirection: 'row',
    },
    mainHeading: {
        width: '100%',
        float: 'left',
        fontSize: 12,
        color: BLACK_FONT,
        marginBottom: 20,
    },
    dialogModal: {
        '&>div': {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    dataName: {
        width: 92,
        float: 'left',
        fontSize: 12,
    },
    dataValue: {
        float: 'left',
        fontSize: 12,
        fontWeight: 'bold',
        maxWidth: 'calc(100% - 100px)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    dialogContent: {
        overflow: 'hidden',
        width: 370,
        padding: '0 !important',
        marginLeft: '30px',
        marginRight: '30px',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 100px)',
        },
    },
    deleteButtonDanger: {
        backgroundColor: '#fff !important',
        color: '#ff0000 !important',
        border: '1px solid #ff0000 !important',
        '&:hover': {
            backgroundColor: '#ff0000 !important',
            color: '#fff !important',
        },
    },
});



class DeleteTestCase extends Component {
    state = {
        isDisabled: false,
    };

    trackSegmentEvent = (isDisableCase,data) => {

        let eventName = `${isDisableCase}` === '0' ? SEGMENT_EVENT.TEST_CASE_EVENT.TEST_CASE_DELETED : `${isDisableCase}` === '1' ? SEGMENT_EVENT.TEST_CASE_EVENT.TEST_CASE_DISABLED : SEGMENT_EVENT.TEST_CASE_EVENT.TEST_CASE_ENABLED;
    

        const segmentData = {

            project_name : data.projectName,
            testcase_id : data.testCaseId,
            testcase_name : data.testCaseName
        }
        track(eventName,segmentData);
    
    }

    render() {
        const { classes, handleClose, open, deleteTestCaseData, isDisableCase, deleteButton} = this.props;
        const { isDisabled } = this.state;
        return (
            <div>
                <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" className={classes.dialogModal}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            {`${isDisableCase}` === '0' ? 'Delete' : `${isDisableCase}` === '1' ? 'Disable' : 'Restore'} Test Case
                        </DialogTitle>
                        <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                            <Close 
                            style={MODAL_HEADER_CLOSE_ICON} 
                            onClick={() => {
                                handleClose(false)
                            }} 
                            aria-label="closeIcon" id="closeIcon" />
                        </div>
                    </div>
                    <form style={{ overflowY: 'auto', maxHeight: '150px' }}>
                        <DialogContent className={classes.dialogContent}>
                            <Typography className={classes.mainHeading}>
                                Are you sure you want to {`${isDisableCase}` === '0' ? 'delete' : `${isDisableCase}` === '1' ? 'disable' : 'restore'}?
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <div
                                    style={{
                                        width: '100%',
                                        paddingBottom: 2,
                                    }}
                                >
                                    <Typography className={classes.keyHeading}>Test case name</Typography>
                                    <Typography className={classes.dataValue}>
                                        {`${deleteTestCaseData ? deleteTestCaseData.testCaseName : ''}`}
                                    </Typography>
                                </div>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <div
                                    style={{
                                        width: '100%',
                                        paddingBottom: 2,
                                    }}
                                >
                                    <Typography className={classes.keyHeading}>Project name</Typography>
                                    <Typography className={classes.dataValue}>
                                        {`${deleteTestCaseData && deleteTestCaseData.projectName ? deleteTestCaseData.projectName : ''}`}
                                    </Typography>
                                </div>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <div
                                    style={{
                                        width: '100%',
                                        paddingBottom: 2,
                                    }}
                                >
                                    <Typography className={classes.keyHeading}>Created Date</Typography>
                                    <Typography className={classes.dataValue}>
                                        {`${deleteTestCaseData ? getFormatedDate(deleteTestCaseData.createdTime, 'MMM D, YYYY', 'N/A') : 'N/A'}`}
                                    </Typography>
                                </div>
                            </FormControl>
                        </DialogContent>
                    </form>
                    <DialogActions style={MODAL_FOOTER}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {

                                this.trackSegmentEvent(isDisableCase,deleteTestCaseData)
                                this.setState({ isDisabled: true });
                                handleClose(true);
                            }}
                            disabled={isDisabled}
                            style={MODAL_SUBMIT_BUTTON}
                            className={deleteButton === 'Danger' ? classes.deleteButtonDanger : ''}
                        >
                            {`${isDisableCase}` === '0' ? 'Delete' : `${isDisableCase}` === '1' ? 'Disable' : 'Enable'}
                        </Button>
                        <CloseOnEscape onEscape={() => handleClose(false)}>
                            <Button variant="outlined" 
                            color="secondary" 
                            onClick={() => handleClose(false)} 
                            style={MODAL_CANCEL_BUTTON}>
                                Cancel
                            </Button>
                        </CloseOnEscape>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(DeleteTestCase);
