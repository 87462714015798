import ProjectMiddleware from '../middleware/projectMiddleware';
import { PluginService } from '../../services/PluginService';
import WSService from '../../services/WSService';
import ProjectServices from '../../services/projectServices';
import { arrangeStepsWithCompoundSteps, checkArrayLength, checkObject, checkKeyInObject, googleAnalytics, getStepsData } from '../../utils/utils';
import { SidebarActions } from './SidebarAction';
import { ActionTypes } from '../constants';
import { TestSuiteActions, SuiteReportAction } from '.';
import SelectedTestCaseActions from './selectedTestCaseAction';
import _config from '../../config';
import ProjectsActions from './projectsActions';
import GeneralActions from './generalActions';
import { TestStepUtils } from '../../utils/TestStepUtils';
import store from '..';
import { TestCaseUtils } from '../../utils';
import { SEGMENT_EVENT } from '../../common/constants';
import { track } from '../../services/Segment';

const getTestCaseById = (testCases, testCaseId) => {
    // testCaseId is getting from array in testData
    const res = testCases.find((testCase) => {
        return testCase && testCase.testCaseId === testCaseId;
    });
    return res;
};
// const getTestDataById = (testData, testDataId) =>
//     testData.find((data) => data.testDataId === parseInt(testDataId));

const createTestCase = (testCase) => ({
    name: testCase.testCaseName,
    generatedAt: testCase.createdTime,
    id: testCase.testCaseId,
});

const getCreatedTestData = (_testCases, testData) => {
    const testCases = [];
    if (testData && checkArrayLength(testData.testCases)) {
        testData.testCases.forEach((id) => {
            const testCase = getTestCaseById(_testCases, id);
            if (testCase) {
                testCases.push(createTestCase(testCase));
            }
        });
    }
    const createdTestData = createTestData(testData, testCases);
    return createdTestData;
};

const createTestData = (testData, testCases) => ({
    testDataId: testData.testDataId,
    discoveryId: testData.discoveryId,
    location: testData.testDataFileLocation,
    testDataFileName: testData.testDataFileName,
    testDataName: testData.testDataName,
    uploadedTime: testData.uploadedTime,
    testCasesIds: testData.testCases,
    type: testData.type,
    associatedTestCases: testCases.length ?
        testCases :
        [{
            name: 'No Associated Test Case',
            generatedAt: undefined,
            id: '0',
        }, ],
});

class ProjectActions {
    static singleCaseRecursiveCall = {};

    static ApproveData(data) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.APPROVE_DATA,
            });
            return ProjectMiddleware.ApproveData(data)
                .then((_data) => {
                    return dispatch({
                        type: ActionTypes.APPROVE_DATA_SUCCESS,
                        payload: _data,
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.APPROVE_DATA_FAILED,
                        payload: {
                            error,
                        },
                    }),
                );
        };
    }

    static clearError() {
        return {
            type: ActionTypes.CLEAR_ERROR,
        };
    }

    static clearSuccess() {
        return {
            type: ActionTypes.CLEAR_SUCCESS,
        };
    }

    static resetDeleteUserVariable() {
        return {
            type: ActionTypes.DELETE_USER_VARIABLES_CLEAR,
        };
    }

    static clearProjectUpdateSuccess() {
        return {
            type: ActionTypes.UPDATE_PROJECT,
        };
    }

    static toggleTaskList() {
        return {
            type: ActionTypes.TASK_PANEL_TOGGLE,
        };
    }

    static callForOriginalStep(data) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.CALL_ORIGINAL_STEP,
                payload: data,
            });
        };
    }

    static handleTestSuitesCasesDirections(data) {
        return {
            type: ActionTypes.HANDLE_UPDATE_TESTCASE_IN_TESTSUITE_DIRECTION,
            data,
        };
    }

    static handleTestSuitesCasesProjectDirections(data) {
        return {
            type: ActionTypes.HANDLE_UPDATE_TESTCASE_IN_TESTSUITE_DIRECTION_PROJECT,
            data,
        };
    }

    static updateTestSuitesDataPropProject(data) {
        return {
            type: ActionTypes.UPDATE_TEST_SUITE_DATA_PROP_PROJECT,
            data,
        };
    }

    static updateTestSuitesDataPropPlan(data) {
        return {
            type: ActionTypes.UPDATE_TEST_SUITE_DATA_PROP_PLAN,
            data,
        };
    }

    static saveTestSuiteCasesOrder(order, suiteId, onComplete = () => {}, msg = "Test Suite Updated Successfully") {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.SAVE_TEST_SUITE_CASES_ORDER,
            });
            return ProjectMiddleware.saveTestSuiteCasesOrder(order, suiteId)
                .then((data) => {
                    onComplete();
                    return dispatch({
                        type: ActionTypes.SAVE_TEST_SUITE_CASES_ORDER_SUCCESS,
                        payload: `${msg}`,
                        data,
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.SAVE_TEST_SUITE_CASES_ORDER_FAILURE,
                        payload: {
                            error,
                            message: 'Fail to update order',
                        },
                    }),
                );
        };
    }

    static getAllProjects(page, rowsPerPage, onComplete = () => {}, isFavourite = false) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_ALL_PROJECTS,
            });
            return ProjectMiddleware.getProjects(page, rowsPerPage)
                .then((data) => {
                    let projects = data;
                    if (projects.data && projects.data.length) {
                        const oldProjects = JSON.parse(JSON.stringify(projects.data));
                        oldProjects.sort(function compare(a, b) {
                            const dateA = new Date(a.lastActivityDate);
                            const dateB = new Date(b.lastActivityDate);
                            return dateB - dateA;
                        });
                        projects = { data: JSON.parse(JSON.stringify(oldProjects)) };
                    }
                    setTimeout(() => {
                        onComplete();
                    }, 50);
                    return dispatch({
                        type: ActionTypes.GET_ALL_PROJECTS_SUCCESS,
                        payload: {...projects, isFavourite },
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_ALL_PROJECTS_FAILURE,
                        payload: {
                            error,
                            message: 'Could not get projects',
                        },
                    }),
                );
        };
    }

    static getActionableElements(url) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_ACTIONABLE_ELEMENTS,
            });
            return ProjectServices.getActionableElements(url)
                .then((data) =>
                    dispatch({
                        type: ActionTypes.GET_ACTIONABLE_ELEMENTS_SUCCESS,
                        payload: data,
                    }),
                )
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_ACTIONABLE_ELEMENTS_FAILURE,
                        payload: {
                            error,
                            message: 'Could not search element',
                        },
                    }),
                );
        };
    }

    static renameProjectName(projectData) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.RENAME_PROJECT_NAME,
            });
            return ProjectMiddleware.updateTestProjectName(projectData)
                .then(() =>
                    dispatch({
                        type: ActionTypes.RENAME_PROJECT_NAME_SUCCESS,
                        payload: projectData,
                    }),
                )
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.RENAME_PROJECT_NAME_FAILURE,
                        payload: {
                            error,
                            message: 'Project not found',
                        },
                    }),
                );
        };
    }

    static clearSelectedCaseDataAndProject() {
        return {
            type: ActionTypes.CLEAR_SELECTED_CASE_DATA_PROJECT,
        };
    }

    static getAlertTestCasesWithAccount(accountId) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_ALERT_TESTCASES_WITH_ACCOUNT,
            });
            return ProjectMiddleware.getAlertTestCasesWithAccount(accountId)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.GET_ALERT_TESTCASES_WITH_ACCOUNT_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.GET_ALERT_TESTCASES_WITH_ACCOUNT_FAILURE,
                        payload: error,
                    });
                });
        };
    }

    static rejectApproveAllAlertTestCase(info, source, isReject, onComplete) {
        return async(dispatch) => {
            try {
                const res = await ProjectMiddleware.rejectApproveAllAlertTestCase(info, source, isReject);
                onComplete();
                return dispatch({
                    type: ActionTypes.REJECT_APPROVE_ALERT_TESTCASE_SUCCESS,
                    payload: res,
                });
            } catch (e) {
                return dispatch({
                    type: ActionTypes.REJECT_APPROVE_ALERT_TESTCASE_FAILURE,
                    payload: e,
                });
            }
        };
    }

    static rejectApproveAlertTestCase(alert_id, source, sessionId, isReject, onComplete) {
        return async(dispatch) => {
            try {
                const res = await ProjectMiddleware.rejectApproveAlertTestCase(alert_id, source, isReject, sessionId);
                onComplete();
                return dispatch({
                    type: ActionTypes.REJECT_APPROVE_ALERT_TESTCASE_SUCCESS,
                    payload: res,
                });
            } catch (e) {
                return dispatch({
                    type: ActionTypes.REJECT_APPROVE_ALERT_TESTCASE_FAILURE,
                    payload: e,
                });
            }
        };
    }

    static createNewProject(data) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.CREATE_PROJECT,
            });

            return ProjectMiddleware.createNewProject(data.formData)
                .then((response) => {
                    googleAnalytics('send', 'event', 'Create Project', 'Create Project', 'Success');
                    dispatch(SidebarActions.increaseSidebarCount('num_of_projects'));
                    return dispatch({
                        type: ActionTypes.CREATE_PROJECT_SUCCESS,
                        payload: {...response },
                    });
                })
                .catch((error) => {
                    googleAnalytics('send', 'event', 'Create Project', 'Create Project', 'Fail');
                    dispatch({
                        type: ActionTypes.CREATE_PROJECT_FAILURE,
                        payload: {
                            error,
                            message: 'Error getting project',
                        },
                    });
                });
        };
    }

    static updateProject(data) {
        return (dispatch) =>
            ProjectMiddleware.updateProject(data.accountId, data.projectId, data.formData)
            .then((response) => {
                return dispatch({
                    type: ActionTypes.UPDATE_PROJECT_SUCCESS,
                    payload: {...response, msg: 'Project succesfully updated' },
                });
            })
            .catch((error) =>
                dispatch({
                    type: ActionTypes.UPDATE_PROJECT_FAILURE,
                    payload: {
                        error,
                        message: 'Failed to update',
                    },
                }),
            );
    }

    static deleteProjects(obj) {
        return (dispatch, getState) => {
            dispatch({
                type: ActionTypes.ON_DELETE_PROJECTS,
            });
            return ProjectMiddleware.deleteProjects([obj.projectId])
                .then((response) => {
                    dispatch(SidebarActions.decreaseSidebarCount('num_of_projects'));
                    dispatch(SidebarActions.increaseSidebarCount('num_of_disable_projects'));
                    dispatch({
                        type: ActionTypes.ON_DELETE_PROJECTS_SUCCESS,
                        payload: {...response },
                    });
                    dispatch(ProjectsActions.getDisabledProjects('ProjectCardActions'));
                    dispatch(this.getProjectByIdAndName());
                    if (checkKeyInObject(obj, 'rowsPerPage')) {
                        const { isFavourite } = getState().projectReducer;

                        dispatch(ProjectActions.getAllProjects(obj.page, obj.rowsPerPage, () => {}, isFavourite));
                    }
                    return true;
                })
                .catch((error) => {
                    dispatch({
                        type: ActionTypes.ON_DELETE_PROJECTS_FAILURE,
                        payload: {
                            error,
                            message: 'Unable to delete project',
                        },
                    });
                    return false;
                });
        };
    }

    static associateDataToTestCase(accountId, projectId, discoveryId, suiteId, testDataId, testcaseIds) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.ATTACH_DATA,
            });
            return ProjectMiddleware.associateDataToTestCase(accountId, parseInt(projectId, 10), discoveryId, suiteId, testDataId, testcaseIds)
                .then(() => {
                    dispatch(this.getTestData(projectId));
                    dispatch({
                        type: ActionTypes.ATTACH_DATA_SUCCESS,
                        payload: {
                            message: 'Data association updated successfully',
                        },
                    });
                    googleAnalytics('send', 'event', 'Link Test Case and Data', 'Link Test Case and Data', 'Success');
                    return {
                        status: 200,
                        message: 'Data association updated successfully',
                    };
                })
                .catch((error) => {
                    dispatch({
                        type: ActionTypes.ATTACH_DATA_FAILURE,
                        payload: {
                            error,
                            message: 'Failed to associate/de-associate data! Try again later',
                        },
                    });
                    googleAnalytics('send', 'event', 'Link Test Case and Data', 'Link Test Case and Data', 'Fail');
                    return {
                        status: 500,
                        message: 'Failed to associate/de-associate data! Try again later',
                    };
                });
        };
    }

    static updateTestCaseSteps(testCaseId, caseData, sessionId = null) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.UPDATE_TEST_CASE_STEPS,
                payload: {
                    testCaseId,
                    caseData,
                    sessionId,
                },
            });
    }

    static updateTestCaseStepsStatus(testCaseId, caseData, sessionId = null) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.UPDATE_TEST_CASE_STEPS_STATUS,
                payload: {
                    testCaseId,
                    caseData,
                    sessionId,
                },
            });
    }

    static toggleWsRunning(testCaseId, steps = null, isGenerating = null) {
        const _steps = steps ? arrangeStepsWithCompoundSteps(steps) : [];
        return (dispatch) =>
            dispatch({
                type: ActionTypes.DELETE_WS_STATUS,
                payload: {
                    testCaseId,
                    steps: _steps,
                    isGenerating,
                },
            });
    }

    static setWsRunning(testCaseId) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.UPADTE_WS_STATUS,
                payload: testCaseId,
            });
    }

    static toggleReloadStatus(status) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.UPADTE_RELOAD_STATUS,
                payload: status,
            });
    }

    static projectChange(status) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.PROJECT_IS_CHANGED,
                payload: status,
            });
    }

    static updateOpenProject(projectId) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.UPDATE_PROJECT_ID,
                payload: projectId,
            });
    }

    static getTestSteps(testCaseId, flag, onCompelete, callingFrom, onFail = () => {}) {
        return (dispatch, getState) => {
            dispatch({
                type: ActionTypes.GET_TESTSTEPS,
                payload: { showLoader: callingFrom !== 'TestCaseUtilsMsgType8' },
            });
            return ProjectMiddleware.getTestSteps(testCaseId, flag)
                .then((response) => {
                    // handleRecursiveTestSteps(response);
                    return onCompelete(response).then((_steps) => {
                        const { selectedTestCase } = getState().projectReducer;
                        const { wsRunningCaseData } = getState().projectReducer;
                        const selectedTestCaseId = getState().selectedTestCaseReducer.testCaseId;
                        if (
                            callingFrom === 'TestStepInitialData' &&
                            `${selectedTestCase.testCaseId}` === `${testCaseId}` &&
                            !selectedTestCase.isGenerating &&
                            checkKeyInObject(wsRunningCaseData, testCaseId) &&
                            !(
                                wsRunningCaseData[testCaseId].isMsgType2Sent ||
                                wsRunningCaseData[testCaseId].isMsgType13Received ||
                                wsRunningCaseData[testCaseId].isMsgType16Received
                            )
                        ) {
                            dispatch(ProjectActions.clearWsData(testCaseId, 'getTestSteps'));
                        }
                        dispatch({
                            type: ActionTypes.GET_TESTSTEPS_SUCCESS,
                            payload: {
                                testSteps: {...response }, // raw form of steps
                                steps: {..._steps }, // formated steps
                                testCaseId,
                            },
                        });
                        if (callingFrom === 'TestCaseUtilsMsgType8' && `${testCaseId}` === `${selectedTestCaseId}`) {
                            const { testSteps: _testSteps, stepsType } = TestCaseUtils.getStepsAndTypes(_steps);
                            dispatch(
                                SelectedTestCaseActions.setSelectedTestCaseData({
                                    testCaseId,
                                    testSteps: _testSteps,
                                    testCaseStatus: selectedTestCase.status,
                                    stepsType,
                                }),
                            );
                        }
                        return _steps;
                    });
                })
                .catch((error) => {
                    onFail();
                    dispatch({
                        type: ActionTypes.GET_TESTSTEPS_FAILURE,
                        payload: {
                            error,
                            message: 'Unable to get test steps',
                        },
                    });
                });
        };
    }

    static createTestCase(data, projectId) {
        return (dispatch) => {
            dispatch(ProjectActions.getProjectDetails(projectId));
            return dispatch({ type: ActionTypes.CREATE_TEST_CASE_SUCCESS, payload: data });
        };
    }

    static emptyUrlValidation() {
        return (dispatch) => {
            return dispatch({ type: ActionTypes.EMPTY_VALID_URL_VALIDATION });
        };
    }

    // getOnly is for only return testCase not dispatch
    static getSingleTestCase(projectId, testCaseId, dataFromPlatform = null, getOnly = false, callingFrom = '') {
        /* callingFrom is to check if it calls from TestCaseUtils for checking if msgType8 calls it? */
        return (dispatch, getState) => {
            return ProjectMiddleware.getSingleTestCase(projectId, testCaseId)
                .then((response) => {
                    if (checkKeyInObject(response, 'disabledStatus', 'value', false)) {
                        // eslint-disable-next-line no-throw-literal
                        throw 'disabled';
                    }
                    if (getOnly) {
                        return response;
                    }
                    if (dataFromPlatform) {
                        const { msgType8Received } = getState().projectReducer;
                        /* call TestCase and TestSteps after msgType 8 */
                        if (
                            callingFrom === 'TestCaseUtilsMsgType8' &&
                            checkObject(response) &&
                            !response.isGenerating &&
                            !ProjectActions.singleCaseRecursiveCall[`${projectId}_${testCaseId}`]
                        ) {
                            if (`${window.location.pathname.split('/').pop()}` === `${testCaseId}`) {
                                getStepsData(testCaseId, 0, () => {}, callingFrom);
                            }
                            dispatch(ProjectActions.clearWsData(testCaseId, 'getSingleTestCase'));
                        }
                        if (checkKeyInObject(msgType8Received, testCaseId) && checkObject(response) && response.isGenerating) {
                            // sometime isGenerating is true for long time so stop after 5 recursive requests
                            if (!ProjectActions.singleCaseRecursiveCall[`${projectId}_${testCaseId}`]) {
                                ProjectActions.singleCaseRecursiveCall[`${projectId}_${testCaseId}`] = 0;
                            }
                            ProjectActions.singleCaseRecursiveCall[`${projectId}_${testCaseId}`]++;
                            if (ProjectActions.singleCaseRecursiveCall[`${projectId}_${testCaseId}`] < 6) {
                                setTimeout(() => {
                                    dispatch(ProjectActions.getSingleTestCase(projectId, testCaseId, dataFromPlatform));
                                }, 2000);
                                const consoleMessage = `CALL DUE TO FALSE STATUS [PROJECT ID] : ${projectId} [TESTCASE ID] : ${testCaseId}`;
                                // eslint-disable-next-line no-console
                                console.log(`%c${consoleMessage}`, 'color: red;', new Date());
                            }
                        } else {
                            if (checkKeyInObject(ProjectActions.singleCaseRecursiveCall, `${projectId}_${testCaseId}`)) {
                                delete ProjectActions.singleCaseRecursiveCall[`${projectId}_${testCaseId}`];
                            }
                            const _dataFromPlatform = arrangeStepsWithCompoundSteps(dataFromPlatform);
                            return dispatch({
                                type: ActionTypes.GET_TESTCASE_SINGLE_SUCCESS,
                                payload: {
                                    testCase: response,
                                    steps: _dataFromPlatform,
                                    testCaseId,
                                },
                            });
                        }
                    } else {
                        return dispatch({
                            type: ActionTypes.GET_TESTCASE_SINGLE_SUCCESS,
                            payload: {
                                testCase: response,
                                testCaseId,
                            },
                        });
                    }
                    return null;
                })
                .catch((error) => {
                    console.error({ error });
                    dispatch({
                        type: ActionTypes.GET_TESTCASE_SINGLE_FAILURE,
                        payload: {
                            error,
                            message: error === 'disabled' ?
                                'Required testcase is disabled.' :
                                checkKeyInObject(error, 'response.data', 'value', '').includes(
                                    'error finding test case: sql: no rows in result set',
                                ) ?
                                'Required testcase is deleted.' :
                                'Unable to get test case',
                        },
                    });
                });
        };
    }

    static getTestCases(projectId, onComplete = () => {}, loading = true) {
        return (dispatch) => {
            if (loading)
                dispatch({
                    type: ActionTypes.GET_TESTCASES,
                    payload: projectId,
                });
            return ProjectMiddleware.getTestCases(projectId)
                .then((response) => {
                    onComplete(response);
                    return dispatch({
                        type: ActionTypes.GET_TESTCASES_SUCCESS,
                        payload: response,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.GET_TESTCASES_FAILURE,
                        payload: {
                            error,
                            message: 'Unable to get test cases',
                        },
                    });
                });
        };
    }

    static clearTestCase() {
        return {
            type: ActionTypes.CLEAR_TEST_CASE_SUCCESS,
            payload: {
                testCase: {},
            },
        };
    }

    static deleteTestData(testDataId) {
        return async(dispatch) => {
            try {
                await ProjectMiddleware.deleteTestData(testDataId);
                dispatch(ProjectActions.decreaseProjectRelatedCount('dataCount'));
                return dispatch({
                    type: ActionTypes.DELETE_TESTDATA_SUCCESS,
                    payload: {
                        testDataId,
                        message: 'Test Data Deleted Successfully',
                    },
                });
            } catch (e) {
                return dispatch({
                    type: ActionTypes.DELETE_TESTDATA_FAILURE,
                    payload: e,
                });
            }
        };
    }

    static deleteTestScript(testScriptId, testCase) {
        return async(dispatch) => {
            try {
                const res = await ProjectMiddleware.deleteTestScript(testScriptId);
                dispatch(ProjectActions.decreaseProjectRelatedCount('scriptCount'));
                return dispatch({
                    type: ActionTypes.DELETE_TESTSCRIPT_SUCCESS,
                    payload: {
                        ...res,
                        testCase,
                        testScriptId,
                        message: 'Script Deleted Successfully',
                    },
                });
            } catch (e) {
                return dispatch({
                    type: ActionTypes.DELETE_TESTSCRIPT_FAILURE,
                    payload: e,
                });
            }
        };
    }

    static deleteTestCaseFromSuite(testSuiteId, testCaseIds) {
        return async(dispatch) => {
            try {
                const response = await ProjectMiddleware.deleteTestCaseFromSuite(testSuiteId, testCaseIds);
                if (response) {
                    return dispatch({
                        type: ActionTypes.DELETE_TESTCASE_FROM_SUITE_SUCCESS,
                        payload: {
                            testCaseIds: [...testCaseIds],
                            testSuiteId,
                            message: '',
                        },
                    });
                }
                // eslint-disable-next-line no-throw-literal
                throw 'Unable to delete test cases';
            } catch (e) {
                return dispatch({
                    type: ActionTypes.DELETE_TESTCASE_FAILURE,
                    payload: {
                        message: '',
                    },
                });
            }
        };
    }

    static executeTestScript(obj, onCompelete = () => {}) {
        return async(dispatch) => {
            try {
                const res = await ProjectMiddleware.executeTestScript({...obj }, onCompelete);
                if (obj.testCaseId) {
                    dispatch(ProjectActions.getSingleTestCase(obj.projectId, obj.testCaseId));
                }
                // Need to check why there is no reducer
                return dispatch({
                    type: ActionTypes.EXECUTE_SCRIPT_SUCCESS,
                    payload: res.data,
                });
            } catch (e) {
                return dispatch({
                    type: ActionTypes.EXECUTE_SCRIPT_FAILURE,
                    payload: {
                        message: 'Generation/AIQ Execution is already in progress.',
                    },
                });
            }
        };
    }

    static getSingleTestData(testDataId) {
        return (dispatch, getState) => {
            return ProjectMiddleware.getSingleTestData(testDataId)
                .then((response) => {
                    let data = response;
                    if (response) {
                        const _testCases = getState().projectReducer.testCases;
                        data = getCreatedTestData(_testCases, response);
                    }
                    return dispatch({
                        type: ActionTypes.GET_TESTDATA_SINGLE_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_TESTDATA_SINGLE_FAILURE,
                        payload: {
                            error,
                            message: 'Unable to get test data',
                        },
                    }),
                );
        };
    }

    static getTestData(projectId, getTestCases = true) {
        return (dispatch, getState) => {
            dispatch({
                type: ActionTypes.GET_TESTDATA,
            });
            return ProjectMiddleware.getTestData(projectId, projectId)
                .then(({ data }) => {
                    const newTestData = [];
                    if (checkArrayLength(data)) {
                        if (getTestCases) {
                            dispatch(ProjectActions.getTestCases(projectId));
                        }
                        const _testCases = getState().projectReducer.testCases;
                        data.forEach((testData) => {
                            newTestData.push(getCreatedTestData(_testCases, testData));
                        });
                    }
                    return dispatch({
                        type: ActionTypes.GET_TESTDATA_SUCCESS,
                        payload: newTestData,
                    });
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    return dispatch({
                        type: ActionTypes.GET_TESTDATA_FAILURE,
                        payload: {
                            error,
                            message: 'Unable to get test data',
                        },
                    });
                });
        };
    }

    static getExecutionVideo = (execId, callback) => {
        return async(dispatch) => {
            // NOTE: only 1 test suite per project
            try {
                const { data } = await ProjectMiddleware.getExecutionVideo(execId);
                if (data.length) {
                    callback(data);
                    return data;
                }
                return dispatch({
                    type: ActionTypes.SET_PROJECT_ERROR,
                    payload: 'This video is not available.',
                });
                // project._testSuiteId = testSuiteId;
            } catch (error) {
                // ... including try/catch error handling
                console.error('getTestSuites:', error);
                return dispatch({
                    type: ActionTypes.SET_PROJECT_ERROR,
                    payload: 'This video is not available.',
                });
                // return false;
            }
        };
    };

    static getExecutionTasks(projectId = 0, status = 0, testCaseId = -1, page = 1, executionsPolling = false, val = null, openPanel = true) {
        /* status > ('Executed': 1, 'InProcess': 0, 'Error': 2, 'All': -1) */
        return (dispatch, getState) => {
            page = page || 1;
            const {
                user: { accountId },
            } = getState().authReducer;
            if (openPanel) {
                dispatch({
                    type: ActionTypes.TASK_PANEL_SHOW_HIDE,
                    payload: true,
                });
                dispatch(GeneralActions.toggleSwipableTaskPanel(true));
            }
            dispatch({
                type: ActionTypes.UPDATE_EXECUTION_TASKS_STATUS,
                payload: {
                    executionTasksStatus: status.toString(),
                    executionsPolling,
                },
            });
            dispatch({
                type: ActionTypes.UPDATE_EXECUTION_TASKS_STATUS_PAGE,
                payload: {
                    page,
                    executionsPolling,
                },
            });
            ProjectMiddleware.getExecutionTasks(accountId, projectId, Number(status), page, testCaseId)
                .then((data) => {
                    let executions = data.tasks;
                    /* To update project.details/suite status */
                    let currentPage = '';
                    let id = '';
                    if ('pathname' in window.location && window.location.pathname) {
                        currentPage =
                            window.location.pathname.split('/')[1] === 'details' ? window.location.pathname.split('/')[1] : window.location.pathname;
                        if (currentPage.includes('/reports/suite')) {
                            id = window.location.pathname.split('/').pop();
                            currentPage = '/reports/suite';
                        }
                    }
                    const oldExecutions = getState().projectReducer.executions;
                    if (
                        checkArrayLength(oldExecutions) &&
                        checkKeyInObject(oldExecutions[0], 'executionStatus') &&
                        oldExecutions[0].executionStatus === 'INPROGRESS' &&
                        (currentPage === '/reports' ||
                            currentPage === '/testsuites' ||
                            currentPage === 'details' ||
                            currentPage === '/reports/suite') &&
                        executions &&
                        oldExecutions.length > executions.length
                    ) {
                        if (currentPage === 'details') {
                            dispatch(TestSuiteActions.getTestSuites({ projectId }, accountId, true));
                        } else if (currentPage === '/testsuites') {
                            dispatch(TestSuiteActions.getTestSuitesWithAccount());
                        } else if (currentPage === '/reports') {
                            dispatch(TestSuiteActions.getReports(true));
                        } else if (currentPage === '/reports/suite' && id) {
                            dispatch(SuiteReportAction.getExecutions({ id: Number(id), type: 2 }, true));
                        }
                    }
                    /* To update project.details/suite status */
                    if (val && val !== 'All') {
                        switch (Number(status)) {
                            case 0:
                                executions = executions.filter((task) => task.executionStatus === 'INPROGRESS');
                                break;
                            case 1:
                                executions = executions.filter((task) => task.executionStatus === 'SUCCESS');
                                break;
                            case 2:
                                executions = executions.filter((task) => task.executionStatus === 'ERROR');
                                break;
                            case 7:
                                executions = executions.filter((task) => task.executionStatus === 'STOPPED');
                                break;
                            case 8:
                                executions = executions.filter((task) => task.executionStatus === 'QUEUED');
                                break;
                            default:
                                return executions;
                        }
                    }
                    // ----------    Checking compeleted executions and get there testcases --------- Start
                    const url = window.location.href;
                    const urlData = url.split('/');
                    let selectedProjectId = 0;
                    if (urlData.length === urlData.indexOf('details') + 2) {
                        selectedProjectId = Number(urlData.pop());
                    }
                    if (getState().projectReducer.executionsStatus === 0 && Number(status) === 0 && selectedProjectId) {
                        const prevExecutionIds = JSON.parse(JSON.stringify(getState().projectReducer.executions)).map(
                            (exe) => `${exe.projectId}-${exe.testcaseId}`,
                        );
                        const curExecutionIds = JSON.parse(JSON.stringify(executions)).map((exe) => `${exe.projectId}-${exe.testcaseId}`);
                        const missingIds = [];
                        prevExecutionIds.forEach((key) => {
                            if (curExecutionIds.indexOf(key) < 0) {
                                missingIds.push(key);
                            }
                        });
                        missingIds.forEach((key) => {
                            const missingProjectId = key.split('-')[0];
                            const missingTestCaseId = key.split('-')[1];
                            if (missingProjectId && missingTestCaseId && `${selectedProjectId}` === `${missingProjectId}`) {
                                dispatch(ProjectActions.getSingleTestCase(missingProjectId, missingTestCaseId));
                            }
                        });
                    }
                    // ----------    Checking compeleted executions and get there testcases --------- End
                    console.log(Number(status))
                    console.log(executions)
                    return dispatch({
                        type: ActionTypes.GET_EXECUTE_TASK_SUCCESS,
                        payload: executions,
                        executionsStatus: Number(status),
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.GET_EXECUTE_TASK_FAILURE,
                        payload: {
                            error,
                            message: 'Unable to get execute task',
                        },
                    });
                });
        };
    }

    static previewTestData(testDataId) {
        return (dispatch) => {
           return ProjectMiddleware.previewTestData(testDataId)
                .then(({ data }) => {
                    if (checkObject(data)) {
                        return dispatch({
                            type: ActionTypes.PREVIEW_TEST_DATA_SUCCESS,
                            payload: data,
                        });
                    }
                    return null;
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.PREVIEW_TEST_DATA_FAILURE,
                        payload: {
                            error,
                            message: 'Unable to get data file',
                        },
                    });
                });
        };
    }

    static clearTestData() {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.PREVIEW_TEST_DATA_CLEAR,
            });
        };
    }

    static generateTestScript(projectId, testScriptIds) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_GENERATE_TEST_SCRIPT,
            });
            ProjectMiddleware.generateTestScript(projectId, testScriptIds)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.GET_GENERATE_TEST_SCRIPT_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    return dispatch({
                        type: ActionTypes.GET_GENERATE_TEST_SCRIPT_FAILURE,
                        payload: {
                            error,
                            message: 'Unable to get genrate test script',
                        },
                    });
                });
        };
    }

    static previewTestScript(testScriptId) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.PREVIEW_TEST_SCRIPT,
            });
            ProjectMiddleware.previewTestScript(testScriptId)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.PREVIEW_TEST_SCRIPT_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.PREVIEW_TEST_SCRIPT_FAILURE,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static downloadTestScript(testScriptId) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.PREVIEW_TEST_SCRIPT,
            });
            ProjectMiddleware.downloadTestScript(testScriptId)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.PREVIEW_TEST_SCRIPT_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.PREVIEW_TEST_SCRIPT_FAILURE,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static getProjectInfo(projectId, onCompelete = () => {}, onFail = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_PROJECTS_INFO,
            });
            ProjectMiddleware.getProjectDetails(projectId)
                .then((data) => {
                    onCompelete(data);
                    return dispatch({
                        type: ActionTypes.GET_PROJECTS_INFO_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    onFail();
                    console.error(error);
                    return dispatch({
                        type: ActionTypes.GET_PROJECTS_INFO_FAILURE,
                        payload: {
                            error,
                            message: checkKeyInObject(error, 'response.data', 'value', '').includes('required project is disabled') ?
                                'Required project is disabled.' :
                                '', // "Fail to get project!" //Replace empty message if want to show error message when no projects found
                        },
                    });
                });
        };
    }

    static saveNewTestCase(accountId, projectId, data, onSuccess = () => {}, onFail = () => {}) {
        return async(dispatch) => {
            dispatch({ type: ActionTypes.SAVE_NEW_TEST_CASE });
            try {
                const result = await ProjectMiddleware.saveNewTestCase(accountId, projectId, data);
                if (result) {
                    onSuccess(result.data.testCaseId, projectId);
                    dispatch(SidebarActions.increaseSidebarCount('num_of_test_cases'));
                    dispatch({ type: ActionTypes.SAVE_NEW_TEST_CASE_SUCCESS, payload: { response: result.data, request: data } });
                    googleAnalytics('send', 'event', 'Create Test Case', 'Create Test Case', 'Success');
                } else {
                    onFail();
                    dispatch({ type: ActionTypes.SAVE_NEW_TEST_CASE_FAILURE, payload: { message: 'Unable to save new Test Case' } });
                    googleAnalytics('send', 'event', 'Create Test Case', 'Create Test Case', 'Fail');
                }
            } catch (error) {
                onFail();
                dispatch({
                    type: ActionTypes.SAVE_NEW_TEST_CASE_FAILURE,
                    payload: {
                        error,
                        message: 'Unable to save new Test Case',
                    },
                });
                googleAnalytics('send', 'event', 'Create Test Case', 'Create Test Case', 'Fail');
            }
        };
    }

    static recordTestCase({
        accountId,
        projectId,
        discoveryId,
        sessionIds,
        RecordData,
        fIndex,
        callBack = () => {},
        callBackForDebug = () => {},
        idModal,
        htmlPath,
    }) {
        const result = false;
        const isChrome = navigator.userAgent.indexOf('Chrome') !== -1;
        const snackbarLink = `https://chrome.google.com/webstore/detail/webrec-smart-record-play/${_config.pluginId}/related?hl=en-GB`;
        const state = 'error';

        return async(dispatch) => {
            dispatch({
                type: ActionTypes.RECORD_TEST_CASE,
            });
            if (isChrome) {
                let pluginId = await localStorage.getItem('pluginId');
                if (!pluginId) {
                    localStorage.setItem('pluginId', _config.pluginId);
                    pluginId = _config.pluginId;
                }
                const errorMsg = 'Unable to launch Recorder. \nPlease ensure you have installed AutonomIQ recorder studio add-on';
                try {
                    const _pluginId = localStorage.getItem('pluginId') || _config.pluginId;
                    const detectExtension = await PluginService.detectInstalledPlugin(_pluginId);
                    if (!detectExtension) {

                        const eventName = SEGMENT_EVENT.RECORDER_INSTALL_INITIATED;
                        const segmentData = {
                            project_id :projectId,
                            testcase_id:RecordData.testCaseId
                        }
                        
                        track(eventName,segmentData);
    

                        return dispatch({
                            type: ActionTypes.PLUGIN_NOT_FOUND,
                            payload: {
                                snackbarLink,
                                state,
                                error: errorMsg,
                                idModal,
                            },
                        });
                    }
                    try {
                        ProjectMiddleware.recordTestCase(accountId, projectId, discoveryId).then(async(_result) => {
                            const token = localStorage.getItem('token');
                            const data = {..._result.data, fIndex, token, RecordData };
                            callBack();
                            callBackForDebug();
                            if (data && data.autUrl && data.payloadInformation) {
                                if (!sessionIds) {
                                	sessionIds = await WSService.generateSessionIds(5);
                                }
                                data.payloadInformation.sessionIds = sessionIds;
                                data.payloadInformation.releaseVersion = _config.releaseVersion;
                                // //////////////Commit For Html FIle test//////////////////
                                if (htmlPath && htmlPath.length) {
                                    PluginService.sendMessageForHTMLTest(data, htmlPath, _pluginId);
                                } else {
                                    data.RecordData = null;
                                    PluginService.sendMessage(data, _pluginId);
                                }

                                const eventName = SEGMENT_EVENT.RECORDER_STARTED;
                                const segmentData = {
                                    project_id :projectId,
                                    testcase_id:RecordData.testCaseId
                                }
                                
                                track(eventName,segmentData);

                                return dispatch({
                                    type: ActionTypes.RECORD_TEST_CASE_SUCCESS,
                                    payload: data,
                                });

            

                                // result = true;
                            }
                            if (data && !data.autUrl) {
                                const eventName = SEGMENT_EVENT.RECORDER_FAILED;
                                const segmentData = {
                                    project_id :projectId,
                                    testcase_id:RecordData.testCaseId,
                                    reason:'project has no URL'
                                }
                                
                                track(eventName,segmentData);

                                return dispatch({
                                    type: ActionTypes.RECORD_TEST_CASE_FAILURE,
                                    payload: {
                                        error: 'Unable to record test case - project has no URL',
                                        message: 'error',
                                        idModal,
                                    },
                                });
                            }
                            return dispatch({
                                type: ActionTypes.RECORD_TEST_CASE_FAILURE,
                                payload: {
                                    error: 'Unable to record test case - request rejected',
                                    message: 'error',
                                    idModal,
                                },
                            });
                        });
                    } catch (error) {
                        console.error('recordTestCase:', error);
                        // error = error.toString();
                        const eventName = SEGMENT_EVENT.RECORDER_FAILED;
                        const segmentData = {
                            project_id :projectId,
                            testcase_id:RecordData?.testCaseId,
                            reason:error ? error.toString() :''
                        }
                        
                        track(eventName,segmentData);

                        return dispatch({
                            type: ActionTypes.RECORD_TEST_CASE_FAILURE,
                            payload: {
                                error: error.toString(),
                                message: 'error',
                            },
                        });
                    }
                } catch (error) {
                    // console.error(error.toString());

                    const eventName = SEGMENT_EVENT.RECORDER_INSTALL_INITIATED;
                    const segmentData = {
                        projectId :projectId,
                        testCaseId:RecordData.testCaseId
                    }

                    track(eventName,segmentData);

                    return dispatch({
                        type: ActionTypes.YOUR_BROSWER_NOT_SPORTED,
                        payload: {
                            snackbarLink,
                            state,
                            error: errorMsg,
                            idModal,
                        },
                    });
                } finally {
                    // markLoading(false);
                }
                return result;
            }
            return null;
        };
    }

    static saveTempCheckedCaseIds(idsObj) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.TEMP_CHECKED_CASE_IDS,
                payload: idsObj,
            });
        };
    }

    static getUserVariables(accountId, projectId, onComplete = () => {}, callback = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_USER_VARIABLES,
            });
            ProjectMiddleware.getUserVariables(accountId, projectId)
                .then((data) => {
                    onComplete(data);
                    callback();
                    return dispatch({
                        type: ActionTypes.GET_USER_VARIABLES_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.GET_USER_VARIABLES_FAILURE,
                        payload: {
                            error,
                            message: 'Could not get user variables',
                        },
                    });
                });
        };
    }

    static saveUserVariables(userVars, isEdit, onComplete = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.SAVE_USER_VARIABLES,
            });
            ProjectMiddleware.saveUserVariables(userVars)
                .then(() => {
                    onComplete();
                    if (!isEdit) {
                        dispatch(ProjectActions.increaseProjectRelatedCount('variablesCount'));
                    }
                    return dispatch({
                        type: ActionTypes.SAVE_USER_VARIABLES_SUCCESS,
                        payload: 'Custom Variable is saved',
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.SAVE_USER_VARIABLES_FAILURE,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static updateUserVariables(userVars, projectId, onComplete = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.SAVE_USER_VARIABLES,
            });
            ProjectMiddleware.updateUserVariables(userVars)
                .then(() => {
                    onComplete();
                    dispatch(ProjectActions.getProjectDetails(projectId, true));
                    return dispatch({
                        type: ActionTypes.SAVE_USER_VARIABLES_SUCCESS,
                        payload: 'Custom Variable is saved',
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.SAVE_USER_VARIABLES_FAILURE,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static deleteUserVariable(accountId, projectId, key, isEditing = false, onComplete = () => {}, showSnackbar = true) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.DELETE_USER_VARIABLES,
            });
            ProjectMiddleware.deleteUserVariable(accountId, projectId, key)
                .then((data) => {
                    if (!isEditing) {
                        dispatch(ProjectActions.decreaseProjectRelatedCount('variablesCount'));
                        dispatch(this.getUserVariables(accountId, projectId, onComplete));
                    } else {
                        onComplete(data);
                    }
                    return dispatch({
                        type: ActionTypes.DELETE_USER_VARIABLES_SUCCESS,
                        payload: showSnackbar ? 'Custom variable deleted successfully.' : '',
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.DELETE_USER_VARIABLES_FAILURE,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static deleteAllUserVariable(accountId, projectId, isEditing = false, onComplete = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.DELETE_ALL_USER_VARIABLES,
            });
            ProjectMiddleware.deleteAllUserVariable(accountId, projectId)
                .then((data) => {
                    if (!isEditing) {
                        dispatch(this.getUserVariables(accountId, projectId, onComplete));
                    } else {
                        onComplete(data);
                    }
                    return dispatch({
                        type: ActionTypes.DELETE_ALL_USER_VARIABLES_SUCCESS,
                        payload: 'All Item is deleted',
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.DELETE_ALL_USER_VARIABLES_FAILURE,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static handleUpdateData(projectId, testDataId, updatedData, callback = () => {}) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.UPDATE_DATA });
            ProjectMiddleware.handleUpdateData(projectId, testDataId, { new_data: updatedData })
                .then((data) => {
                    callback();
                    return dispatch({
                        type: ActionTypes.UPDATE_DATA_SUCCESS,
                        payload: {
                            data,
                            message: 'Data Updated Successfully',
                        },
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.UPDATE_DATA_FAILURE,
                        payload: {
                            error,
                            message: 'Unable to update data',
                        },
                    });
                });
        };
    }

    static getNonDeletedNotification(id) {
        return (dispatch) => {
            ProjectMiddleware.getNonDeletedNotification(id)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.NON_DELETE_NOTIFICATIONS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.SHOW_ERROR,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static getDeletedNotification(id) {
        return (dispatch) => {
            ProjectMiddleware.getDeletedNotification(id)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.DELETE_NOTIFICATIONS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.SHOW_ERROR,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static getMarkReadNotification(id) {
        return (dispatch) => {
            ProjectMiddleware.getMarkReadNotification(id)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.MARK_READ_NOTIFICATIONS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.SHOW_ERROR,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static getMarkNotificationDeleted(id, accountId) {
        return (dispatch) => {
            ProjectMiddleware.getMarkNotificationDeleted(id)
                .then(() => {
                    ProjectMiddleware.getNonDeletedNotification(accountId)
                        .then((data) => {
                            return dispatch({
                                type: ActionTypes.NON_DELETE_NOTIFICATIONS,
                                payload: data,
                            });
                        })
                        .catch((error) => {
                            return dispatch({
                                type: ActionTypes.SHOW_ERROR,
                                payload: {
                                    error,
                                    message: '',
                                },
                            });
                        });
                    // return dispatch({
                    //     type: ActionTypes.MARK_DELETED_NOTIFICATIONS,
                    //     payload: data
                    // });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.SHOW_ERROR,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static clearAllNotificaton(ids) {
        return (dispatch) => {
            ProjectMiddleware.clearAllNotificaton(ids)
                .then(() => {
                    return dispatch({
                        type: ActionTypes.CLEAR_NOTIFICATIONS_SUCCESS,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.SHOW_ERROR,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static postMarkReadNotification(ids, accountId) {
        return (dispatch) => {
            ProjectMiddleware.postMarkReadNotification(ids)
                .then(() => {
                    ProjectMiddleware.getNonDeletedNotification(accountId)
                        .then((data) => {
                            return dispatch({
                                type: ActionTypes.NON_DELETE_NOTIFICATIONS,
                                payload: data,
                            });
                        })
                        .catch((error) => {
                            return dispatch({
                                type: ActionTypes.SHOW_ERROR,
                                payload: {
                                    error,
                                    message: '',
                                },
                            });
                        });
                    // return dispatch({
                    //     type: ActionTypes.MARK_READ_NOTIFICATIONS_DELETED,
                    //     payload: data,
                    // });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.SHOW_ERROR,
                        payload: {
                            error,
                            message: '',
                        },
                    });
                });
        };
    }

    static getProjectDetails(projectId, noLoading = false, cb = () => {}) {
        return (dispatch) => {
            if (!noLoading) {
                dispatch({
                    type: ActionTypes.GET_PROJECT_DETAILS,
                });
            }
            ProjectMiddleware.getProjectDetails(projectId)
                .then((data) => {
                    data.projectName = data.name;
                    cb();
                    return dispatch({
                        type: ActionTypes.GET_PROJECT_DETAILS_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    cb();
                    return dispatch({
                        type: ActionTypes.GET_PROJECT_DETAILS_FAILURE,
                        payload: {
                            error,
                            message: checkKeyInObject(error, 'response.data', 'value', '').includes('required project is disabled') ?
                                'Required project is disabled.' :
                                '', // "Fail to get project!" //Replace empty message if want to show error message when no projects found
                        },
                    });
                });
        };
    }

    static setCurrentProject(project) {
        return {
            type: ActionTypes.GET_PROJECT_DETAILS_SUCCESS,
            payload: project,
        };
    }

    static setSelectedTask(newTasks) {
        return {
            type: ActionTypes.SET_SELECTED_TASK,
            payload: newTasks,
        };
    }

    static callRetry(bool) {
        return {
            type: ActionTypes.CALL_RETRY_FUNC,
            payload: bool,
        };
    }

    static setWsStatus(data) {
        return {
            type: ActionTypes.SET_WS_STATUS,
            payload: data,
        };
    }

    static getChangedApis(accountId) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_CHANGED_APIS,
            });
            ProjectMiddleware.getChangedApis(accountId)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.GET_CHANGED_APIS_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.GET_CHANGED_APIS_FAILURE,
                        payload: {
                            error,
                            message: 'Could not find any changed api',
                        },
                    });
                });
        };
    }

    static initiateSmartRun(changedApis) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.SMART_RUN,
            });
            ProjectMiddleware.initiateSmartRun(changedApis)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.SMART_RUN_SUCCESS,
                        payload: {
                            message: 'Successfully sent changed apis',
                            data,
                        },
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.SMART_RUN_FAILURE,
                        payload: {
                            error,
                            message: 'Failed to initiate smart run!',
                        },
                    });
                });
        };
    }

    static toggleSideBar(flag) {
        return {
            type: ActionTypes.SIDE_BAR_TOGGLE,
            payload: flag,
        };
    }

    static ShowToast() {
        return {
            type: ActionTypes.SHOW_TOAST,
            payload: 'Interactive debugging is unavailable. Please re-generate this test case again',
        };
    }

    /**
     * @param {string} callingFrom
     * @param {string} sessionId
     * @param {string} testCaseId
     */
    static saveSessionId(sessionId, testCaseId, callingFrom) {
        // eslint-disable-next-line no-console
        console.log(`%cisMsgType2Sent: true, callingFrom: ${callingFrom}`, 'color: #e5e505;');
        return (dispatch) =>
            dispatch({
                type: ActionTypes.SAVE_SESSION_ID,
                payload: { sessionId, testCaseId, callingFrom },
            });
    }

    /**
     * @param {array} callingFrom
     * @param {string} testCaseId
     */
    static removeMsgType2Data(testCaseIds, callingFrom) {
        // eslint-disable-next-line no-console
        console.log(`%cisMsgType2Sent: false, callingFrom: ${callingFrom}`, 'color: red;');
        return (dispatch) =>
            dispatch({
                type: ActionTypes.REMOVE_MSGTYPE2_DATA,
                payload: { testCaseIds },
            });
    }

    static saveFailedStep(failedStep, sessionId) {
        return (dispatch) => {
            const caseData = {
                instrNum: failedStep.instrNum,
                status: failedStep.status,
                message: failedStep.message,
                screenshotPaths: failedStep.screenshotPaths,
                screenshotSmallPaths: failedStep.screenshotSmallPaths,
                screenshotNo: failedStep.screenshotNo,
                htmlPath: failedStep.htmlPath,
                detectChanged: true,
            };
            const paramTestCaseId = window.location.pathname.split('/').pop();
            dispatch(ProjectActions.updateTestCaseStepsStatus(failedStep.testCaseId, caseData));
            if (`${failedStep.testCaseId}` === `${paramTestCaseId}`) {
                dispatch(SelectedTestCaseActions.updateStepStatus(failedStep.instrNum, caseData, '5'));
                // set smartPlayButton key
                TestStepUtils.toggleSmartPlayButtonKey(failedStep.instrNum, true, 13);
            }

            dispatch({
                type: ActionTypes.SAVE_FAILED_STEP,
                payload: { failedStep: {...failedStep, showSmartRetryButton: true }, sessionId }, // to show smart retry button even after unmount then remount step editor
            });
        };
    }

    static saveDebugStep(debugStep, sessionId) {
        return (dispatch) => {
            const caseData = {
                instrNum: debugStep.instrNum,
                status: debugStep.status,
                message: debugStep.message,
                screenshotPaths: debugStep.screenshotPaths,
                screenshotSmallPaths: debugStep.screenshotSmallPaths,
                screenshotNo: debugStep.screenshotNo,
                htmlPath: debugStep.htmlPath,
                detectChanged: true,
            };
            const paramTestCaseId = window.location.pathname.split('/').pop();
            dispatch(ProjectActions.updateTestCaseStepsStatus(debugStep.testCaseId, caseData));
            if (`${debugStep.testCaseId}` === `${paramTestCaseId}`) {
                dispatch(SelectedTestCaseActions.updateStepStatus(debugStep.instrNum, caseData, '5'));
                // set smartPlayButton key
                TestStepUtils.toggleSmartPlayButtonKey(debugStep.instrNum, true, 16);
            }

            dispatch({
                type: ActionTypes.SAVE_DEBUG_STEP,
                payload: { debugStep: {...debugStep, showDebugPlayButton: true }, sessionId }, // to show smart debug button even after unmount then remount step editor
            });
        };
    }

    static resetFailedStep(testCaseId, sessionId, firstEditedStep = null) {
        const { failedStepsData } = store.getState().projectReducer;
        if (
            checkKeyInObject(failedStepsData, testCaseId) &&
            `${failedStepsData[testCaseId].sessionId}` === `${sessionId}` &&
            failedStepsData[testCaseId].instrNum
        ) {
            // remove smartPlayButton key
            TestStepUtils.toggleSmartPlayButtonKey(failedStepsData[testCaseId].instrNum, false, 13);
        }
        return (dispatch) => {
            dispatch({
                type: ActionTypes.RESET_FAILED_STEPS,
                payload: { testCaseId, sessionId, firstEditedStep },
            });
        };
    }

    static resetDebugStep(testCaseId, sessionId, firstEditedStep = null) {
        const { debugStepsData } = store.getState().projectReducer;
        if (
            checkKeyInObject(debugStepsData, testCaseId) &&
            `${debugStepsData[testCaseId].sessionId}` === `${sessionId}` &&
            debugStepsData[testCaseId].instrNum
        ) {
            // remove smartPlayButton key
            TestStepUtils.toggleSmartPlayButtonKey(debugStepsData[testCaseId].instrNum, false, 16);
        }
        return (dispatch) => {
            dispatch({
                type: ActionTypes.RESET_DEBUG_STEPS,
                payload: { testCaseId, sessionId, firstEditedStep },
            });
        };
    }

    static toggleMsgType15Flag(testCaseId, steps, isMessageType15Sent) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.MSGTYPE_15_FLAG_TOOGLE,
                payload: { stepsOnMsgType15: steps, testCaseId, isMessageType15Sent },
            });
        };
    }

    static toggleMsgType18Flag(testCaseId, steps, isMessageType18Sent) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.MSGTYPE_18_FLAG_TOOGLE,
                payload: { stepsOnMsgType18: steps, testCaseId, isMessageType18Sent },
            });
        };
    }

    static removeMsgType8Received(testCaseId) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.REMOVE_MESSAGE_TYPE_8_RECEIVED,
                payload: { testCaseId },
            });
        };
    }

    static clearWsData(testCaseId, callingFrom = '') {
        // eslint-disable-next-line no-console
        console.log('clearWsData', { callingFrom });
        return (dispatch) => {
            dispatch({ type: ActionTypes.CLEAR_WS_DATA, payload: { testCaseId, callingFrom } });
        };
    }

    static onSaveOrRetryApiFailed(testCaseId) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.ON_SAVE_RETRY_API_FAILED, payload: { testCaseId } });
        };
    }

    static storeSuiteState(states) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.STORE_SUITE_STATE,
                payload: {...states },
            });
        };
    }

    static clearSuiteState() {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.CLEAR_SUITE_STATE,
            });
        };
    }

    static storeProjectDetailsState(states) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.STORE_PROJECT_DETAILS_STATE,
                payload: {...states },
            });
        };
    }

    static clearProjectDetailsState() {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.CLEAR_PROJECT_DETAILS_STATE,
            });
        };
    }

    static decreaseProjectRelatedCount(name) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.DECREASE_PROJECT_RELATED_COUNTS,
                payload: { name },
            });
        };
    }

    static increaseProjectRelatedCount(name) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.INCREASE_PROJECT_RELATED_COUNTS,
                payload: { name },
            });
        };
    }

    static uploadProject(formData) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.UPLOAD_PROJECT_FILE,
            });
            return ProjectMiddleware.uploadProject(formData)
                .then((response) => {
                    dispatch(SidebarActions.increaseSidebarCount('num_of_projects'));
                    dispatch({
                        type: ActionTypes.UPLOAD_PROJECT_FILE_SUCCESS,
                        payload: { data: response, msg: 'Project upload successfully.' },
                    });
                    return response;
                })
                .catch((error) => {
                    dispatch({
                        type: ActionTypes.UPLOAD_PROJECT_FILE_FAILURE,
                        payload: {
                            error,
                            message: 'Unable to upload',
                        },
                    });
                });
        };
    }

    static setNewStepPosition(newStepPosition) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.SET_NEW_STEP_POSITION,
                payload: { newStepPosition },
            });
        };
    }

    static updateTestCases(testCases) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.UPDATE_CASES,
                payload: { testCases },
            });
        };
    }

    static updateTestCaseDataAssociation(testCasesIds, checkedTestCaseIds, testDataId) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.UPDATE_TESTCASE_DATA_ASSOCIATION,
                payload: { testCasesIdsToRemove: testCasesIds, checkedTestCaseIds, testDataId },
            });
        };
    }

    static getProjectByIdAndName(onComplete = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_ALL_PROJECTS_BY_ID_AND_NAMES,
            });
            return ProjectMiddleware.getProjectByIdAndName()
                .then((data) => {
                    let projectsByIdAndName = data;
                    if (projectsByIdAndName.data && projectsByIdAndName.data.length) {
                        const oldProjectsByIdAndName = JSON.parse(JSON.stringify(projectsByIdAndName.data));
                        oldProjectsByIdAndName.sort(function compare(a, b) {
                            const dateA = new Date(a.lastActivityDate);
                            const dateB = new Date(b.lastActivityDate);
                            return dateB - dateA;
                        });
                        projectsByIdAndName = { data: JSON.parse(JSON.stringify(oldProjectsByIdAndName)) };
                    }
                    setTimeout(() => {
                        onComplete();
                    }, 50);
                    return dispatch({
                        type: ActionTypes.GET_ALL_PROJECTS_BY_ID_AND_NAMES_SUCCESS,
                        payload: projectsByIdAndName,
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_ALL_PROJECTS_BY_ID_AND_NAMES_FAILURE,
                        payload: {
                            error,
                            message: 'Could not get projects',
                        },
                    }),
                );
        };
    }

    static addFavourite(projectId) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.SET_ADD_FAVOURITE,
            });
            ProjectMiddleware.addFavouriteProject(projectId)
                .then(() => {
                    dispatch({
                        type: ActionTypes.SET_ADD_FAVOURITE_SUCCESS,
                        projectId,
                    });
                    // dispatch({
                    //     type: ActionTypes.GET_ALL_PROJECTS,
                    // });
                    // ProjectMiddleware.getProjects(page, rowsPerPage)
                    //     .then((data) => {
                    //         let projects = sortAllOldProject(data);
                    //         setTimeout(() => {
                    //             onComplete()
                    //         }, 50);
                    //         return dispatch({
                    //             type: ActionTypes.GET_ALL_PROJECTS_SUCCESS,
                    //             payload: projects,
                    //         })
                    //     })
                    //     .catch((error) => {
                    //         dispatch({
                    //             type: ActionTypes.GET_ALL_PROJECTS_FAILURE,
                    //             payload: {
                    //                 error,
                    //                 message: 'Failed',
                    //             }
                    //         })
                    //     })
                })
                .catch((error) => {
                    dispatch({
                        type: ActionTypes.SET_ADD_FAVOURITE_FAILURE,
                        payload: {
                            error,
                            message: 'Could not get projects',
                        },
                    });
                    return false;
                });
        };
    }

    static removeFavourite(projectId) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.SET_REMOVE_FAVOURITE,
            });
            ProjectMiddleware.removeFavouriteProject(projectId)
                .then(() => {
                    dispatch({
                        type: ActionTypes.SET_REMOVE_FAVOURITE_SUCCESS,
                        projectId,
                    });
                    // dispatch({
                    //     type: ActionTypes.GET_ALL_PROJECTS,
                    // });
                    // dispatch({
                    //     type: ActionTypes.GET_ALL_FAVOURITE_PROJECTS,
                    // });
                })
                .catch((error) => {
                    dispatch({
                        type: ActionTypes.SET_REMOVE_FAVOURITE_FAILURE,
                        payload: {
                            error,
                            message: 'Could not get projects',
                        },
                    });
                    return false;
                });
        };
    }

    static getFavouriteProjects(onComplete = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_ALL_FAVOURITE_PROJECTS,
            });
            return ProjectMiddleware.getFavouriteProjects()
                .then((data) => {
                    const projects = data;
                    const isFavourite = true;
                    setTimeout(() => {
                        onComplete();
                    }, 50);
                    return dispatch({
                        type: ActionTypes.GET_ALL_FAVOURITE_PROJECTS_SUCCESS,
                        payload: { projects, isFavourite },
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_ALL_FAVOURITE_PROJECTS_FAILURE,
                        payload: {
                            error,
                            message: 'Could not get project',
                        },
                    }),
                );
        };
    }

    static getProjectByName(projectName, onComplete = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_PROJECT_BY_NAME,
            });
            return ProjectMiddleware.getProjectByName(projectName)
                .then((data) => {
                    setTimeout(() => {
                        onComplete();
                    }, 50);
                    return dispatch({
                        type: ActionTypes.GET_PROJECT_BY_NAME_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_PROJECT_BY_NAME_FAILURE,
                        payload: {
                            error,
                            message: 'Could not search project',
                        },
                    }),
                );
        };
    }

    static removeFavouriteProjects() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.SHOW_ONLY_DISABLES_PROJECTS });
        };
    }

    static toggleIdModal(bool) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.TOGGLE_ID_MODAL, payload: { bool, error: ' ', snackbarLink: ' ' } });
        };
    }

    static getEnvironmentType() {
        return (dispatch, getState) => {
            const {
                user: { accountId },
            } = getState().authReducer;
            return ProjectMiddleware.getEnvironmentType(accountId)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.GET_ENVIRONMENT_FOR_LOCAL_EXECUTION_SUCCESS,
                        payload: { data: data.result.pop() },
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_ENVIRONMENT_FOR_LOCAL_EXECUTION_FAILURE,
                        payload: {
                            error,
                            message: 'Could not get environment info',
                        },
                    }),
                );
        };
    }

    static getTunnelIDForSauceLabs() {
        return (dispatch, getState) => {
            return ProjectMiddleware.getTunnelIDForSauceLabs()
                .then((data) => {
                    let sauceConnectIds = ['None',...data?.result?.sauce_connect_ids || []];
                    
                    return dispatch({
                        type: ActionTypes.GET_TUNNEL_ID_FOR_SAUCELABS,
                        payload: { data: sauceConnectIds || [] },
                        error: data.error ? data.error : null

                    });
                })
                .catch((error) => {
                    dispatch({
                        type: ActionTypes.GET_ENVIRONMENT_FOR_LOCAL_EXECUTION_FAILURE,
                        payload: {
                            error,
                            message: 'Could not get tunnel ID',
                        },
                    })
                    return []
                }, );
        };
    }

    static setIsGenerateBtnClicked(data) {
        return {
            type: ActionTypes.IS_GENERATE_BUTTON_CLICKED,
            payload: data,
        };
    }

    static setShowLoaderForGenerate(data) {
        return {
            type: ActionTypes.LOADER_FOR_GENERATE,
            payload: data,
        };
    }

    static getExecutionDetailsByScheduleId(scheduleId) {
        return (dispatch) => {
            return ProjectMiddleware.getExecutionDetailsByScheduleId(scheduleId)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.GET_EXECUTION_DETAILS_BY_SCHEDULE_ID_SUCCESS,
                        payload: { data: data.result },
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_EXECUTION_DETAILS_BY_SCHEDULE_ID_FAILURE,
                        payload: {
                            error,
                            message: 'Could not get execution details',
                        },
                    }),
                );
        };
    }



}

export default ProjectActions;