import { createStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { SwipeableDrawer, MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import SideBarContent from './SideBarContent';
import ListView from '../../../components/UserMenu/ListView';
import logo from './autonomiq-logo.png';

const styles = () =>
    createStyles({
        bars: {
            color: '#ffffff',
            fontSize: '20px',
        },
        customSwipableDrawer: {
            flex: '1 0 auto',
            height: '100vh',
            width: '220px',
            display: 'flex',
            zIndex: '1200',
            outline: 'none',
            position: 'fixed',
            overflowY: 'hidden',
            flexDirection: 'column',
            backgroundColor: '#4a4a4a',
        },
        imageContainer: {
            flex: 4,
            display: 'flex',
            height: '50px',
            alignItems: 'center',
            paddingLeft: '23px',
            backgroundColor: '#fff',
        },
        headerlogo: {
            width: '130px',
            height: '25px',
            cursor: 'pointer',
        },
    });

// interface IListViewProps extends WithStyles<typeof styles> {
//     user: any;
// }

function SwipableSideBar(props) {
    const {
        classes,
        toggleSwipableDrawer,
        openNav,
        history,
        history: {
            location: { pathname },
        },
        isUpdate,
        user,
    } = props;

    function toGoHome() {
        if (pathname && pathname !== '/projects') {
            history.push('/projects', { fromLogo: true });
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#393939',
                height: '42px',
                width: '50px',
            }}
        >
            <FontAwesomeIcon
                icon={faBars}
                aria-label="faBars"
                id="faBars"
                className={classes.bars}
                onClick={() => {
                    toggleSwipableDrawer(true);
                }}
            />
            <SwipeableDrawer
                anchor="left"
                open={openNav}
                onClose={() => {
                    toggleSwipableDrawer(false);
                }}
                onOpen={() => {
                    toggleSwipableDrawer(true);
                }}
                disableSwipeToOpen
                disableDiscovery
                classes={{ paper: classes.customSwipableDrawer }}
            >
                <div
                    tabIndex={0}
                    role="button"
                    onKeyDown={() => {
                        toggleSwipableDrawer(false);
                    }}
                >
                    <div className={classes.imageContainer}>
                        <Route routeName="home">
                            <img aria-hidden src={logo} onClick={toGoHome} className={classes.headerlogo} alt="AutonomIQ logo" />
                        </Route>
                    </div>
                    <MenuItem style={{ backgroundColor: '#393939', height: '46px' }}>
                        <ListView user={user} isForBtn={false} backhistory={history} calledFrom="sideBarContent" />
                    </MenuItem>
                    <SideBarContent history={history} isUpdate={isUpdate} swipable />
                </div>
            </SwipeableDrawer>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
    };
}

export default connect(mapStateToProps, null)(withStyles(styles)(SwipableSideBar));
