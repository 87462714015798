import { THREE_DOT_MENU_CSS } from '../../../common/cssConstants';

export const styles = (theme) => ({
    root: {
        width: '100%',
    },
    IconButton: {
        height: 22,
        width: 22,
    },
    actionButtonsDiv: {
        width: '30px',
    },
    actionCell: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 2,
    },
    expansionPanelDetails: {
        padding: '0 0px',
    },
    expansionPanelSummary: {
        minHeight: '0px !important',
        '&>:first-child': {
            position: 'relative',
            margin: '13px 0px 0px 0px',
            cursor: 'default !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
    },
    iconContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkBoxContainer: {
        '&>:first-child': {
            paddingLeft: '5px !important',
        },
    },
    borders: {
        backgroundColor: '#f4f6f7',
    },
    headerText: {
        fontFamily: 'HelveticaNeue',
        fontSize: '13px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#383737',
    },
    button: {
        width: 22,
        height: 22,
    },
    eyeIconBtn: {
        color: 'rgb(96, 148, 255)',
        height: '20px !important',
        width: '20px !important',
    },
    maxWidthModified: {
        maxWidth: '120px !important',
    },
    moreIcons: {
        color: THREE_DOT_MENU_CSS.iconColor,
        fontSize: 22,
    },
    noPadding: {
        paddingRight: '5px !important',
    },
    noPaddingBoth: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important',
    },
    tableBody: {
        width: '100%',
        '& .TableRow': {
            '& .TableCell': {
                fontSize: 12,
            },
        },
    },
    tableRow: {
        backgroundColor: '#f5f5f5',
        borderTop: 'solid 0.5px rgb(151 151 151 / 69%)',
        height: '40px',
        '&:hover': {
            '& .TableCell': {
                backgroundColor: '#fff',
            },
            backgroundColor: '#fff',
        },
    },
    backgroundWhite: {
        backgroundColor: '#fff',
    },
    focused: {
        background: '#fff',
        '& .TableCell': {
            fontWeight: 'bold',
        },
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    timCreated: {
        color: '#434548',
        fontWeight: '500',
        fontSize: '12px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    flowName: {
        fontSize: '12px',
        fontWeight: '400',
        color: '#3768b3',
        cursor: 'pointer',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '20%',

    },
    IconCell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '20%',
    },
    oval: {
        width: '24px',
        height: '24px',
        backgroundColor: '#d8d8d8',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        color: '#434548',
        fontSize: '15px',
        fontWeight: '500',
    },
    blockSteps: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&:hover': {
            '&>:last-child': {
                color: '#2869b8',
            }
        }
    },
    threeDotsButton: {
        marginLeft: 10,
    },
    minWidth: {
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    tableScrollable: {
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 'calc(100vh - 425px)',
    },
    minHeight: {
        minHeight: 'calc(100vh - 279px)',
    },
    minHeight264: {
        minHeight: 'calc(100vh - 220px)',
    },
    tableContainer: {
        backgroundColor: '#fff',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        overflow: 'hidden',
    },
    viewDetails: {
        fontSize: '12px',
        color: '#434548',
        fontWeight: '500',
        cursor: 'pointer',
    },
    rightBtns: {
        display: 'flex',
    },
    childIcon: {
        fontSize: 18,
        color: '#5F7B96',
    },
    childIconDisabled: {
        fontSize: 18,
        color: 'rgba(0, 0, 0, 0.26)',
    },
});
