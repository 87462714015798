import React from 'react';
import { connect } from 'react-redux';
// import material
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';

// icons material
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import IconButton from '@material-ui/core/IconButton';

import { checkArrayLength, checkKeyInObject } from '../../utils/utils';
import '../../assets/css/react-datepicker.css';
import Select from '../../features/modal/GeneralModal/Select';
import { BLACK_FONT } from '../../common/cssConstants';
import { generalModalActions, ProjectActions } from '../../store/actions';
import SearchInput from '../../features/modal/GeneralModal/SearchInput';

const styles = () => ({
    dialogContent: {
        overflowY: 'auto',
        paddingBottom: 12,
        paddingLeft: 40,
        width: '600px',
        '&:first-child': {
            paddingTop: 0,
            width: 530,
        },
    },
    padding: {
        padding: '0px',
    },
    rootDialogContent: {
        width: '100%',
    },
    font: {
        fontSize: 12,
        color: BLACK_FONT,
    },
    labelColor: {
        margin: '0px',
        width: '100%',
        '&>label': {
            color: `${BLACK_FONT} !important`,
            fontSize: '12px',
            transform: 'translate(0, 1.5px)',
            '&>span': {
                color: 'red',
            },
        },
        '&>div': {
            '&>input::-webkit-input-placeholder': {
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: 300,
                color: '#9B9B9B',
                opacity: 1,
            },
            '&>input::-moz-input-placeholder': {
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: 300,
                color: '#9B9B9B',
                opacity: 1,
            },
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    select: {
        width: '100%',
        '&>label': {
            color: `${BLACK_FONT} !important`,
            fontSize: '12px',
            transform: 'translate(0, 1.5px)',
        },
    },
    subTab: {
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        height: '21px',
        borderRadius: '5px',
        padding: '0 10px',
        fontSize: '12px',
        color: '#9B9B9B',
        cursor: 'pointer',
        minWidth: '67px',
        justifyContent: 'center',
        marginRight: '20px',
    },
    subTabSelected: {
        fontWeight: '500',
        backgroundColor: '#B2D3F4',
        color: '#1168CD',
    },
    checkBoxIcon: {
        color: '#0092e5',
        fontSize: '25px',
        marginLeft: '-4px',
    },
    checkBox: {
        height: 25,
        width: 25,
    },
    data: {
        fontSize: 12,
        color: BLACK_FONT,
    },
    cell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 40,
        padding: '0 30px'
    },
    emptyCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
    },
    _cell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
    },
    row: {
        height: 35,
    },
    subRow: {
        height: 35,
        backgroundColor: '#fafafa',
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    body: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    errorMessage: {
        color: 'red',
        display: 'block',
        fontSize: '12px',
        marginLeft: '30px',
    },
    typography:{
        fontSize: 12, 
        margin: '0 0 10px 31px',
    }
});

class ExecutionScheduleModal extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedTabId: 1,
            jobName: '',
            selectedValues: null,
            suites: [],
            query: {
                1: '',
            },
            selected_suites: [],
            //didChange:false,
        };
    }

    async UNSAFE_componentWillMount() {
        if (checkKeyInObject(this.props.tabsData, 'job')) {
            this.setState({ ...this.props.tabsData.job.state });
        } else if (checkKeyInObject(this.props, 'type') === 'Update' && this.props.updateData) {
            const _suites = [];
            await this.props.getExecutionDetailsByScheduleId(this.props.updateData.schedule_id);
            // const _selectedValues = [];
            if (checkKeyInObject(this.props.updateData, 'testSuiteId')) {
                // _selectedValues.push({ value: this.props.updateData.projectId, label: this.props.updateData.projectName, projectId: this.props.updateData.projectId });
                _suites.push(this.props.updateData.testSuiteId);
                
            }
            this.setState(
                {
                    jobName: checkKeyInObject(this.props.updateData, 'name'),
                    selected_suites: _suites,
                    selectedValues: this.props.execDetails.projectId ,
                  
                },
                () => {
                    this.updateSuites();
                },
            );
        }
    }

    componentWillUnmount() {
        this.props.updateTabData('job', { state: this.state });
    }

    onChangeFields = (e) => {
        this.setState({ [e.target.name]: e.target.value, 
            //didChange: this.props.updateData.name.localeCompare(e.target.value) == 0 ? false : true 
        });
    };

    handleChange = (e) => {
        let { query } = this.state;
        query = { ...query };
        query[this.state.selectedTabId] = e.target.value;
        this.setState({ query });
    };

    clearSearch = () => {
        let { query } = this.state;
        query = { ...query };
        query[this.state.selectedTabId] = '';
        this.setState({ query });
    };

    updateSuites = () => {
        const { selectedValues, selected_suites } = this.state;
        const { suitesForJob } = this.props;
        const filterSelected_suites = selected_suites;
        let updatedSuites = [];
        // let projectIds = selectedValues.map((project) => project.projectId);
        if (selectedValues) {
        const filteredSuites = suitesForJob.filter((project) => `${project.project_id}` === `${selectedValues}`);
        if (checkArrayLength(filteredSuites)) {
            updatedSuites = filteredSuites[0].suites.map((suite) => ({
                name: suite.suite_name,
                id: suite.suite_id,
                is_suite_executable: suite.is_suite_executable,
                projectId: selectedValues,
            }));
        }
    } else {
        this.setState({selectedValues: ''})
    }

        const newSelectedSuites = [];
        filterSelected_suites.forEach((selectedSuiteId) => {
            if (checkArrayLength(updatedSuites) && updatedSuites.find((suite) => suite.id === selectedSuiteId)) {
                
                newSelectedSuites.push(selectedSuiteId);
            }
        });
        

        this.setState({ suites: updatedSuites, selected_suites: newSelectedSuites });
    };

    changeSelection = (values) => {
        let selectedValues = null;
        if (values) {
            selectedValues = values.value;
        }
        this.setState({ selectedValues }, () => {
            this.updateSuites();
        });
    };

    convertToSelectValues = (inputArray = []) => {
        let outputArray = [];
        outputArray = inputArray.map((element) => {
            return {
                value: element.project_id,
                label: element.project_name,
                projectId: element.project_id,
            };
        });
        return outputArray;
    };

    handleCheck = (key, id) => {
        const temp = this.state[`selected_${key}`];
        const _temp = [...temp];
        if (_temp.includes(id)) {
            _temp.splice(_temp.indexOf(id), 1);
        } else if (_temp.length === 0) {
            _temp.push(id);
        } else {
            // eslint-disable-next-line no-alert
            alert('Can only add one');
            return false;
        }
     
        this.setState({ [`selected_${key}`]: _temp , 
        //didChange : this.props.updateData.testSuiteId == id ? false : true 
        });
        return true;
    };

    renderRows = (key) => {
        const { classes } = this.props;
        const { /* expended, */ selectedValues, selectedTabId } = this.state;
        const rows = [];

        if (checkArrayLength(this.state[key])) {
            const filteredRows = this.state.query[selectedTabId]
                ? this.state[key].filter((data) => `${data.name}`.toLowerCase().includes(`${this.state.query[selectedTabId]}`.toLowerCase().trim()))
                : this.state[key];

            filteredRows.forEach((data) => {                
                const isSuiteExecutable = data.is_suite_executable;
                rows.push(
                    <TableRow key={data.id} className={classes.row}>
                        <TableCell className={classes.cell}>
                            <span className={classes.nameContainer}>
                                <IconButton
                                    onClick={() => {
                                        this.handleCheck(key, data.id);
                                    }}
                                    variant="contained"
                                    color="primary"
                                    className={classes.checkBox}
                                    style={{ marginRight: '5px' }}
                                    disabled= {!isSuiteExecutable}
                                >
                                    {this.state[`selected_${key}`].includes(data.id) ? (
                                        <CheckBox className={classes.checkBoxIcon} aria-label="checkBoxIcon" id="checkBoxIcon" />
                                    ) : (
                                        <CheckBoxBlank
                                            className={classes.checkBoxIcon}
                                            style={{ color: '#bac5d0' }}
                                            aria-label="checkBoxBlankIcon"
                                            id="checkBoxBlankIcon"
                                        />
                                    )}
                                </IconButton>
                                <span className={classes.data} style={{color: !isSuiteExecutable ? '#bbbbbb' : ''}}>{`${data.name}`}</span>
                            </span>
                        </TableCell>
                    </TableRow>,
                );
            });
        } else if (selectedValues != null) {
            rows.push(
                <TableRow key="empty" className={classes.row}>
                    <TableCell className={classes.emptyCell}>
                        <span className={classes.data}>{`No ${key} available in selected projects `}</span>
                    </TableCell>
                </TableRow>,
            );
        } else {
            rows.push(
                <TableRow key="empty" className={classes.row}>
                    <TableCell className={classes.emptyCell}>
                        <span className={classes.errorMessage}>No project is selected</span>
                    </TableCell>
                </TableRow>,
            );
        }
        return rows;
    };

    render() {
        const { classes } = this.props;
        const { selectedTabId, jobName, selectedValues, query } = this.state;

        const convertedItems = this.convertToSelectValues(this.props.suitesForJob);
        const isTestSuitesExist = Boolean(this.props.suitesForJob?.find(suite => suite.project_id == selectedValues)?.suites?.length);

        return (
            <DialogContent className={classes.dialogContent} style={{ maxHeight: 'calc(100vh - 320px)' }} classes={{ root: classes.padding }}>
                <div className={classes.rootDialogContent}>
                    <div style={{ margin: '0px 30px 20px 30px' }}>
                        <TextField
                            id="jobName"
                            name="jobName"
                            aria-label="Schedule Name"
                            type="text"
                            label="Schedule Name"
                            onChange={this.onChangeFields}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                value: jobName,
                                classes: { input: classes.font },
                            }}
                            className={classes.labelColor}
                        />
                    </div>
                    <div style={{ margin: '0px 30px 20px 30px' }}>
                        <Select
                            value={selectedValues}
                            onChange={(value) => this.changeSelection(value)}
                            className={classes.select}
                            isBorder
                            label="Project"
                            options={convertedItems}
                            isMultiSelect={false}
                            isMandatory
                            readOnly={false}
                        />
                    </div>
                    <div style={{ margin: '0px 30px 20px 30px', display: 'flex' }}>
                        <span className={`${classes.subTab} ${selectedTabId === 1 ? classes.subTabSelected : ''}`}> Test Suites</span>
                    </div>
                    <div style={{ margin: '0px 30px 15px 30px' }}>
                        <SearchInput
                            id="JobModal"
                            placeholder="Search"
                            onChange={this.handleChange}
                            value={query[selectedTabId]}
                            style={{ maxWidth: 250 }}
                            clearSearch={this.clearSearch}
                        />
                    </div>
                    {isTestSuitesExist && <Typography className={classes.typography}>You can only add testsuite in a schedule which has generated testcases.</Typography>}
                </div>

                <Table>
                    <TableBody className={classes.body}>{selectedTabId === 1 ? this.renderRows('suites') : null}</TableBody>
                </Table>
            </DialogContent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        suitesForJob: state.scheduleReducer.suitesForJob,
        tabsData: state.generalModalReducer.tabsData,
        execDetails:state.scheduleReducer.execDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
        getExecutionDetailsByScheduleId: (scheduleId) => dispatch(ProjectActions.getExecutionDetailsByScheduleId(scheduleId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionScheduleModal));
