// import materials
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Hidden } from '@material-ui/core';
import { isArray } from 'lodash';
// import packages
import React from 'react';
import { connect } from 'react-redux';
import AddCircle from '@material-ui/icons/AddCircle';
import Close from '@material-ui/icons/Close';
import CloseOnEscape from 'react-close-on-escape';

// import icons

// import custom component
import { UserActions, AuthActions, ModalActions } from '../../../store/actions';
import CreateUserModal from '../../admin/modal/CreateUserModal';
import RenameUserModal from '../../admin/modal/RenameUserModal';
import ArrangeTableModal from '../../admin/modal/ArrangeTableModal';
import AddUserToGroup from '../../admin/modal/AddUserToGroupModal';
import MultiDeleteUser from '../../admin/modal/MultiDelete';
import UserPermissions from '../../admin/modal/userPermissionsModal';
import AutoGenerateModal from '../../admin/modal/AutoGenerateModal';
import { checkObject, checkKeyInObject, properFromNow } from '../../../utils/utils';
import CloneVariablesModal from '../CloneVariablesModal';
import CloneAllVariables from '../CloneAllVariables';
import {
    DIALOG_ROOT,
    MODAL_FOOTER,
    MODAL_HEADER_TITLE,
    MODAL_HEADER_CLOSE_ICON,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_SUBMIT_BUTTON,
    MODAL_CANCEL_BUTTON,
    BLACK_FONT,
} from '../../../common/cssConstants';
import { USER_NAME } from '../../../common/constants';

// constants
const buttonText = {
    Add: 'Create',
    Delete: 'Delete',
    ChangeStatus: '',
    MultiDelete: 'Confirm',
    Edit: 'Update',
    Reset: 'Send',
    Rename: 'Create',
    ArrangeTable: 'Save',
    AddToGroup: 'Add',
    Permissions: 'Ok',
    clone_Varaible: 'Clone',
};
const statusDropDownData = [
    { value: 'ADMIN', label: 'ADMIN' },
    { value: 'SDET', label: 'SDET' },
];
const title = {
    Add: 'Create New User',
    Delete: 'Delete User',
    ChangeStatus: '',
    MultiDelete: 'Delete Users',
    Edit: 'Update User',
    Reset: 'Reset password for Admin console',
    Rename: 'Rename User',
    ArrangeTable: 'Manage Columns',
    AddToGroup: 'Add Users to Group',
    Permissions: 'Project Permissions',
    AutoGenerate: 'Auto Generation',
    clone_Varaible: 'Clone Variable',
    cloneAllVariables: 'Clone Variables',
};
const Validation = /^[a-z]+[a-z0-9]*$/;
const isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const styles = (theme) => ({
    cancleButton: {
        border: '1px solid #1168cd',
    },
    dialogRoot: {
        '&>div': {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    dialogContentCustom: {
        overflow: 'hidden',
        width: 370,
        padding: '0 !important',
        marginLeft: '30px',
        marginRight: '30px',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 100px)',
        },
    },
    dialogContentCustomArrangeTable: {
        overflow: 'hidden',
        padding: '0 !important',
        marginLeft: '30px',
        marginRight: '30px',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 100px)',
        },
    },
    dialogContent: {
        overflow: 'hidden',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 296px)',
        paddingBottom: 12,
        paddingLeft: 40,
        '&:first-child': {
            paddingTop: 0,
            width: 530,
            [theme.breakpoints.only('xs')]: {
                width: 'calc(100vw - 100px)',
            },
        },
    },
    mainHeading: {
        width: 'calc(100% - 60px)',
        float: 'left',
        fontSize: 12,
        marginRight: 30,
        color: BLACK_FONT,
        marginBottom: 20,
        marginLeft: '30px',
    },
    dialogContentAddUser: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 232px)',
        paddingBottom: 12,
        '&:first-child': {
            padding: 0,
            width: 590,
            [theme.breakpoints.only('xs')]: {
                width: '100%',
            },
        },
    },
    dialogTitleStyle: MODAL_HEADER_TITLE,
    confirmTitleStyle: {
        backgroundColor: '#FCF9E5',
        borderTop: '1px solid #b2b2b2',
        minHeight: 80,
        paddingBottom: 10,
        paddingTop: 10,
        textAlign: 'center',
        '& > h2': {
            [theme.breakpoints.only('xs')]: {
                fontSize: 14,
            },
        },
    },
    boldText: {
        fontWeight: 'bold',
        color: '#003F9B',
    },
    dialogPaper: {
        borderRadius: 0,
    },
    buttonStyle: {
        padding: '2px 8px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#1067ce',
        borderRadius: '6px',
        textTransform: 'capitalize',
        marginRight: '10px',
    },
    card: {
        backgroundColor: '#FCF9E5',
        borderTop: '1px solid #b2b2b2',
        minHeight: 80,
        paddingBottom: 10,
        paddingTop: 10,
        textAlign: 'center',
    },
    cancelButton: {
        padding: '2px 8px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#1067ce',
        borderRadius: '6px',
        textTransform: 'capitalize',
    },
    dialogContentPermissions: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 232px)',
        paddingBottom: 12,
        '&:first-child': {
            padding: '0 !important',
            width: 590,
        },
    },
    // paperScrollPaper: {
    //     height: 'auto',
    //     overflowY: 'hidden',
    //     maxHeight: 'auto',
    //     overflow: '100%',
    // },
    modifyUserDialog: {
        flex: '1 1 auto',
        padding: '0 24px 24px',
        overflowY: 'auto',
        [theme.breakpoints.only('xs')]: {
            padding: '0 7px 24px',
        },
    },
    deleteButtonDanger: {
        backgroundColor: '#fff !important',
        color: '#ff0000 !important',
        border: '1px solid #ff0000 !important',
        '&:hover': {
            backgroundColor: '#ff0000 !important',
            color: '#fff !important',
        },
    },
});
class AdminUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.interval = null;

        this.state = {
            userName: '',
            userNameFix: '',
            email: '',
            selectedRole: 'ADMIN',
            selectedGroup: 'Select Group',
            password: '',
            project: 'N/a',
            lastSignIn: 'Today',
            firstName: '',
            lastName: '',
            automaticGeneratePassword: false,
            askToChangePasswordNextSignIn: true,
            showPassword: false,
            userHeader:
                checkObject(props.modalData) && checkKeyInObject(props.modalData, 'rows') ? JSON.parse(JSON.stringify(props.modalData.rows)) : {},
            isArrangeButtonDisabled: false,
            isDisabled: false,
        };

        this.interval = null;
    }

    componentDidMount = () => {
        const {
            modalData: { user, modaltype },
        } = this.props;
        if (user && Object.keys(user).length > 0) {
            this.updateState({
                firstName: (user.name && user.name.split(/(?=[A-Z])/)[0]) || '',
                lastName: (user.name && user.name.split(/(?=[A-Z])/)[1]) || '',
                userName: user.name,
                userNameFix: user.name,
                email: modaltype === 'Rename' ? user.email && user.email.split('@')[0] : user.email,
                lastSignIn: user.lastLogin ? properFromNow(user.lastLogin, true) : 'Time not found.',
                selectedRole: user.role,
            });
        }
    };

    get enableSubmit() {
        const {
            modalData: { modaltype },
        } = this.props;
        const { email, userName, selectedRole, selectedGroup, password, isArrangeButtonDisabled, userNameFix, isDisabled } = this.state;
        if (isDisabled) {
            return false;
        }
        switch (modaltype) {
            case 'Add':
                return email.length && password && userName.length && (selectedRole === 'ADMIN' || selectedRole === 'SDET');
            case 'Delete':
                return true;
            case 'ChangeStatus':
                return true;
            case 'MultiDelete':
                return true;
            case 'Edit':
                return (
                    email.length &&
                    isEmail.test(String(email).toLowerCase()) &&
                    userName.length &&
                    (Validation.test(userName) || userNameFix === userName) &&
                    (selectedRole === 'ADMIN' || selectedRole === 'SDET')
                );
            case 'UpdateUser':
                return true;
            case 'Rename':
                return true;
            case 'Reset':
                return true;
            case 'ArrangeTable':
                return isArrangeButtonDisabled;
            case 'AddToGroup':
                return selectedGroup !== 'Select Group';
            case 'Permissions':
                return true;
            default:
                return false;
        }
    }

    setTable = () => {
        const { userHeader } = this.state;
        const obj = {};
        userHeader.forEach((data) => {
            obj[data.id] = data.status;
        });
        localStorage.setItem('tabHandel', JSON.stringify(obj));
        this.props.handelTableCol(obj);
        this.props.toggleModal();
    };

    fetchUsers = () => {
        const {
            currentUser: { accountId, name },
        } = this.props;
        if (`${accountId}` === '1' && name === USER_NAME) {
            this.props.getUsers();
        } else {
            this.props.getUserByAccountId(accountId);
        }
    };

    createUser = async () => {
        const { userName, email, password, selectedRole } = this.state;
        const user = { userName, email, password, role: selectedRole, accountId: this.props.currentUser.accountId.toString() };
        /* let newUser = */ await this.props.createUser(user);
        this.fetchUsers();
        this.props.toggleModal();
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleChange = (name) => (event) => {
        if (name === 'askToChangePasswordNextSignIn') {
            this.setState({ [name]: !this.state.askToChangePasswordNextSignIn });
        } else if (name === 'automaticGeneratePassword') {
            this.setState({ [name]: !this.state.automaticGeneratePassword });
        } else {
            this.setState({ [name]: event.target.value });
        }
    };

    handleSelectChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    updateUser = async (user) => {
        const { userName, selectedRole, email } = this.state;

        const updated = await this.props.updateUser({
            userId: user.id,
            //name: userName,
            email,
            role: selectedRole,
        });

        if (checkKeyInObject(updated, 'UPDATE_USER_SUCCESS')) {
            this.setState({ userNameFix: userName });
        }
        if (checkKeyInObject(updated, 'type') && updated.type === 'UPDATE_USER_SUCCESS') {
            this.props.showSnackBar('User updated successfully', '', true);
        } else {
            this.props.showSnackBar('Failed to update user');
        }

        this.fetchUsers();
        // close modal
        this.props.toggleModal();
    };

    deleteUser = async (user /* , multi */) => {
        const { id } = user;
        const {
            modalData: { multiUsers, onComplete = () => {} },
        } = this.props;
        let isDeleted = false;

        if (user.id) {
            isDeleted = await this.props.deleteUser(id);
        } else if (isArray(multiUsers) && multiUsers.length > 0) {
            const _userIds = multiUsers.map((_user) => _user.id);
            isDeleted = await this.props.deleteUser(_userIds);
        }
        if (isDeleted) {
            onComplete();
            this.fetchUsers();
            // close modal
            this.props.toggleModal();
        }
    };

    changeUserStatus = async (user, status) => {
        const { id } = user;
        let changeStatus = false;

        if (user.id) {
            changeStatus = await this.props.changeUserStatus(id, status);
        }
        if (changeStatus) {
            this.fetchUsers();
            // close modal
            this.props.toggleModal();
            this.props.showSnackBar('User status changed successfully', '', true);
        }
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    resetPasswordMail = async () => {
        const { toggleModal, toggleSnackBar } = this.props;
        const { email } = this.state;
        const res = await this.props.resetPasswordMail(email);
        if (res && res.status) {
            toggleSnackBar(res.message, '', true);
        } else if (res && res.message) {
            toggleSnackBar(res.message);
        }
        toggleModal();
    };

    renameUserNow = async () => {
        const { toggleModal, modalData } = this.props;
        const { firstName, lastName, email } = this.state;
        const { user } = modalData;
        // create user
        const isUpdated = await this.props.updateUser({
            userId: user.id,
            role: user.role,
            email: `${email}@${user.email && user.email.split('@')[1]}`,
            name: user.name === '' ? firstName + lastName : user.name,
            firstName,
            lastName,
        });

        if (isUpdated) {
            this.fetchUsers();

            // close modal
            toggleModal();
        }
    };

    handleChangeArrangeTabCol = (name) => () => {
        const { userHeader } = this.state;
        userHeader.some((u) => {
            if (u.id === name) {
                u.status = u.status === 'Active' ? 'InActive' : 'Active';
                return true;
            }
            return false;
        });
        this.setState({ userHeader, isArrangeButtonDisabled: true });
    };

    clearForm = () => {
        this.setState({
            userName: '',
            userNameFix: '',
            selectedGroup: 'Select Group',
        });
        // close modal
        this.props.toggleModal();
    };

    associate = async () => {
        const {
            associateUsersWithGroup,
            modalData: { multiUsers, onComplete },
        } = this.props;
        const { selectedGroup } = this.state;
        const _users = [];
        if (multiUsers) {
            multiUsers.forEach((user) => {
                _users.push(user.id);
            });
        }
        await associateUsersWithGroup({ group_id: selectedGroup, user_ids: _users });
        onComplete();
        this.clearForm();
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const {
            modalData: { modaltype, user, multiUsers, status },
            toggleModal,
        } = this.props;

        switch (modaltype) {
            case 'Add':
                this.createUser();
                break;

            case 'Delete':
                this.deleteUser(user);
                break;

            case 'ChangeStatus':
                this.changeUserStatus(user, status);
                break;

            case 'MultiDelete':
                this.deleteUser(multiUsers);
                break;

            case 'Edit':
                this.updateUser(user);
                break;
            case 'Rename':
                this.renameUserNow();
                break;
            case 'UpdateUser':
                this.updateUser(user);
                break;
            case 'Reset':
                this.resetPasswordMail(user);
                break;
            case 'ArrangeTable':
                this.setTable();
                break;
            case 'AddToGroup':
                this.associate();
                break;
            default:
                toggleModal();
                break;
        }
    };

    render() {
        const {
            classes,
            modalData: { modaltype, multiUsers, user, groups, users, appUrl, projectId, projects, variableData, allVariables },
            currentUser,
            open,
            toggleModal,
            getUserProjectPermission,
            startGenrate,
        } = this.props;
        const {
            userHeader,
            userName,
            userNameFix,
            email,
            password,
            firstName,
            lastName,
            selectedRole,
            project,
            lastSignIn,
            automaticGeneratePassword,
            askToChangePasswordNextSignIn,
            selectedGroup,
        } = this.state;
        const component = {
            Add: (
                <CreateUserModal
                    userName={userName}
                    email={email}
                    password={password}
                    selectedRole={selectedRole}
                    statusDropDownData={statusDropDownData}
                    handleChange={this.handleChange}
                    handleSelectChange={this.handleSelectChange}
                    type="Add"
                />
            ),
            Edit: (
                <CreateUserModal
                    userName={userName}
                    userNameFix={userNameFix}
                    email={email}
                    selectedRole={selectedRole}
                    statusDropDownData={statusDropDownData}
                    handleChange={this.handleChange}
                    handleSelectChange={this.handleSelectChange}
                    project={project}
                    lastSignIn={lastSignIn}
                    type="Edit"
                    user={currentUser}
                />
            ),
            Delete: (
                <CreateUserModal
                    userName={userName}
                    email={email}
                    selectedRole={selectedRole}
                    statusDropDownData={statusDropDownData}
                    handleChange={this.handleChange}
                    handleSelectChange={this.handleSelectChange}
                    project={project}
                    lastSignIn={lastSignIn}
                    type="Delete"
                />
            ),
            ChangeStatus: (
                <CreateUserModal
                    userName={userName}
                    email={email}
                    selectedRole={selectedRole}
                    statusDropDownData={statusDropDownData}
                    handleChange={this.handleChange}
                    handleSelectChange={this.handleSelectChange}
                    project={project}
                    lastSignIn={lastSignIn}
                    type="ChangeStatus"
                />
            ),
            MultiDelete: <MultiDeleteUser users={multiUsers} type="MultiDelete" />,
            Reset: (
                <CreateUserModal
                    email={email}
                    password={password}
                    handleChange={this.handleChange}
                    handleClickShowPassword={this.handleClickShowPassword}
                    type="Reset"
                />
            ),
            Rename: (
                <RenameUserModal
                    automaticGeneratePassword={automaticGeneratePassword}
                    email={email}
                    endPointEmail={user && user.email && user.email.split('@')[1]}
                    firstName={firstName}
                    lastName={lastName}
                    handleChange={this.handleChange}
                    askToChangePasswordNextSignIn={askToChangePasswordNextSignIn}
                    type="Rename"
                />
            ),
            ArrangeTable: <ArrangeTableModal rows={userHeader} handleChange={this.handleChangeArrangeTabCol} />,
            AddToGroup: (
                <AddUserToGroup users={multiUsers} handleSelectChange={this.handleSelectChange} groups={groups} selectedGroup={selectedGroup} />
            ),
            Permissions: (
                <UserPermissions users={users} projects={projects} getUserProjectPermission={getUserProjectPermission} toggleModal={toggleModal} />
            ),
            AutoGenerate: <AutoGenerateModal toggleModal={toggleModal} appUrl={appUrl} projectId={projectId} startGenrate={startGenrate} />,
            clone_Varaible: <CloneVariablesModal toggleModal={toggleModal} variableData={variableData} />,
            cloneAllVariables: <CloneAllVariables toggleModal={toggleModal} allVariables={allVariables} />,
        };
        return (
            <div>
                <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" className={classes.dialogRoot}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            {modaltype === 'schedule' && (
                                <AddCircle
                                    style={{ position: 'relative', top: '4px', right: '7px', fontSize: '23px' }}
                                    aria-label="addCircleicon"
                                    id="addCircleIcon"
                                />
                            )}
                            <Hidden only={['xs']}>
                                {modaltype === 'ChangeStatus' ? (user.deleted ? 'Enable User' : 'Disable User') : title[modaltype]}
                            </Hidden>
                            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                {title[modaltype] === 'Reset password for Admin console' ? 'Reset password' : title[modaltype]}
                            </Hidden>
                            <br />
                            <span style={{ fontWeight: '600' }}>{modaltype === 'Rename' && user.name ? user.name : null}</span>
                        </DialogTitle>
                        <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                            <Close style={MODAL_HEADER_CLOSE_ICON} onClick={() => toggleModal()} aria-label="closeIcon" id="closeIcon" />
                        </div>
                    </div>

                    {modaltype === 'Delete' && <Typography className={classes.mainHeading}>Are you sure want to delete?</Typography>}

                    {modaltype === 'ChangeStatus' && (
                        <Typography className={classes.mainHeading}>Are you sure want to {user.deleted ? 'enable' : 'disable'}?</Typography>
                    )}

                    {modaltype === 'MultiDelete' && <Typography className={classes.mainHeading}>Are you sure want to delete users?</Typography>}

                    <div style={{ width: '100%' }}>
                        <div style={{ maxWidth: '100vw', overflow: 'auto' }}>
                            {modaltype === 'Permissions' && (
                                <DialogContent className={classes.dialogContentPermissions}>{component[modaltype]}</DialogContent>
                            )}
                            {modaltype !== 'Permissions' && (
                                <DialogContent
                                    style={
                                        modaltype === 'AutoGenerate' || modaltype === 'clone_Varaible' || modaltype === 'cloneAllVariables'
                                            ? {
                                                  maxHeight: 'calc(100vh - 232px)',
                                                  padding: 0,
                                                  margin: 0,
                                                  width: modaltype === 'AutoGenerate' ? 'auto' : '',
                                              }
                                            : null
                                    }
                                    className={modaltype === 'ArrangeTable' ? classes.dialogContentCustomArrangeTable : classes.dialogContentCustom}
                                >
                                    {component[modaltype]}
                                </DialogContent>
                            )}
                        </div>
                    </div>
                    {modaltype !== 'Permissions' &&
                        modaltype !== 'cloneAllVariables' &&
                        modaltype !== 'AutoGenerate' &&
                        modaltype !== 'clone_Varaible' && (
                            <DialogActions style={MODAL_FOOTER}>
                                <Button
                                    // className={classes.buttonStyle}
                                    color="secondary"
                                    disabled={!this.enableSubmit}
                                    style={MODAL_SUBMIT_BUTTON}
                                    className={
                                        modaltype === 'MultiDelete' || modaltype === 'Delete' || modaltype === 'ChangeStatus'
                                            ? classes.deleteButtonDanger
                                            : ''
                                    }
                                    onClick={(e) => {
                                        this.setState({ isDisabled: true });
                                        this.handleSubmit(e);
                                    }}
                                >
                                    {modaltype === 'ChangeStatus' ? (user.deleted ? 'Enable' : 'Disable') : buttonText[modaltype]}
                                </Button>
                                <CloseOnEscape onEscape={() => toggleModal()}>
                                    <Button
                                        // className={classes.cancelButton}
                                        color="secondary"
                                        onClick={() => toggleModal()}
                                        style={MODAL_CANCEL_BUTTON}
                                        variant="outlined"
                                    >
                                        Cancel
                                    </Button>
                                </CloseOnEscape>
                            </DialogActions>
                        )}
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        associateUsersWithGroup: (data) => dispatch(UserActions.associateUsersWithGroup(data)),
        updateUser: (data) => dispatch(UserActions.updateUser(data)),
        deleteUser: (id) => dispatch(UserActions.deleteUser(id)),
        changeUserStatus: (user, status) => dispatch(UserActions.changesUserStatus(user, status)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        createUser: (data) => dispatch(UserActions.createUser(data)),
        getUsers: () => dispatch(UserActions.getUsers()),
        getUserByAccountId: (accountId) => dispatch(UserActions.getUserByAccountId(accountId)),
        handelTableCol: (data) => dispatch(UserActions.handelTableCol(data)),
        authenticateEmail: (email) => dispatch(AuthActions.authenticateEmail(email)),
        resetPasswordMail: (email) => dispatch(AuthActions.resetPasswordMail(email)),
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        getUserProjectPermission: (...arg) => dispatch(UserActions.sendUserProjectPermission(...arg)),
        startGenrate: (...args) => dispatch(UserActions.StartGenrate(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminUserModal));
