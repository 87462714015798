import { ActionTypes } from '../constants';
import { SideBarMiddleware } from '../middleware';

class SidebarActions {
    static getSideBarDataCount() {
        return (dispatch) => {
            SideBarMiddleware.getSideBarDataCount()
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.SIDEBAR_COUNT_SUCCESS,
                        payload: { data },
                    });
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.SIDEBAR_COUNT_FAILURE,
                        payload: {
                            error,
                            message: 'Sidebar data not found',
                        },
                    });
                });
        };
    }

    static increaseSidebarCount(name, count = 1) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.INCREASE_SIDEBAR_COUNT,
                payload: { name, count },
            });
        };
    }

    static decreaseSidebarCount(name, count = 1) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.DECREASE_SIDEBAR_COUNT,
                payload: { name, count },
            });
        };
    }
}

export { SidebarActions };
