import { withStyles } from '@material-ui/core/styles';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { qtcreatorLight } from 'react-syntax-highlighter/styles/hljs';
import React from 'react';
import PropTypes from 'prop-types';

const styles = (/* theme */) => ({});

const PreviewTestScript = (props) => {
    const { children, language } = props;
    return (
        <SyntaxHighlighter language={language} lineNumberStyle={{ color: 'rgba(0, 0, 0, 0.6)' }} showLineNumbers style={qtcreatorLight}>
            {children}
        </SyntaxHighlighter>
    );
};

PreviewTestScript.propTypes = {
    language: PropTypes.string.isRequired,
};

export default withStyles(styles)(PreviewTestScript);
