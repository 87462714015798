// import packages
import PropTypes from 'prop-types';
import React from 'react';

// import material
import { withStyles } from '@material-ui/core/styles';
import { ClickAwayListener, Popper, Tabs, Tab, Typography } from '@material-ui/core';

// import custom files
import { ArrowUpDown } from '../../../components/Button/ArrowUpDown';
import { checkArrayLength } from '../../../utils';
import { styles } from './styles';
import defaultConfig from '../../../config';
import Logos from '../../../assets/logos';
import Tooltip from '../../../components/Tooltip';

const VersionListText = (props) => {
    const {
        id,
        onclick,
        version,
        isPlatform,
        selectedDropDownValue,
        versionListTextProps: { classes, dropDownData, selectedBrowserArray, selectedTab },
    } = props;
    let backgroundColor = '';
    if (
        (isPlatform && selectedDropDownValue === version) ||
        (checkArrayLength(selectedBrowserArray) && selectedTab > -1 && selectedBrowserArray.indexOf(`${dropDownData[selectedTab]} ${version}`) > -1)
    ) {
        backgroundColor = '#bbbbbb';
    }
    return (
        <p
            aria-hidden
            id={id}
            onClick={onclick}
            style={backgroundColor ? { backgroundColor } : {}}
            className={`${classes.versionListText} ${classes[selectedBrowserArray ? 'versionListTextForBrowser' : 'versionListTextForPlatform']}`}
        >
            <Tooltip data={version}>{version}</Tooltip>
        </p>
    );
};

const PlatformBrowserSelectionContainer = (props) => {
    const {
        anchorEl,
        classes,
        closePopper,
        dropDownData,
        handleBrowserChange,
        handleExecutionMode,
        handlePlatformChange,
        isScheduleModal,
        isOpenDialog,
        modalType,
        saucelabsExpanded,
        onClickExecutionContainer,
        onClickPlatformBrowserContainer,
        selectedDropDownValue,
        title,
        selectedTab,
        handleTabChange,
        versionsArray,
        resetBrowserVersions,
        selectedBrowserArray,
    } = props;

    const isExecution = title === 'Execution';
    const isPlatform = title === 'Platform';
    const noBrowserMessage = <p className={classes.noBrowserMessage}>No browser versions for current platform selection</p>;
    const tabIndicatorProps = { style: { backgroundColor: '#F0B637', top: 0, height: 3 } };
    let versionListTextProps = { classes };
    if (title === 'Browser') {
        versionListTextProps = { ...versionListTextProps, dropDownData, selectedBrowserArray, selectedTab };
    }

    return (
        <div className={isExecution || isScheduleModal ? classes.width100P : classes.width50P}>
            <Typography className={classes.textFieldLabelCustom}>{isExecution ? 'Select Execution Mode' : title}</Typography>
            <div className={isExecution ? classes.textFieldCustom : classes.width100P}>
                <div
                    aria-hidden
                    className={`${classes.textFieldBody} ${isExecution ? classes.width100P : ''}`}
                    onClick={(e) => {
                        if (isExecution) {
                            onClickExecutionContainer(e);
                        } else {
                            onClickPlatformBrowserContainer(e, title);
                        }
                    }}
                >
                    <p className={classes.textFieldInner}>
                        {selectedDropDownValue === 'internetExplorer' ? 'Internet Explorer' : selectedDropDownValue}
                    </p>
                    <ArrowUpDown flag={isOpenDialog} />
                </div>
                <Popper
                    open={Boolean(anchorEl) && isOpenDialog}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    style={{
                        zIndex: 1300,
                        width: `${
                            isExecution
                                ? 'calc(50% - 150px)'
                                : modalType === defaultConfig.executionEngine || (saucelabsExpanded !== undefined ? !saucelabsExpanded : '')
                                ? 'calc(50% - 407px)'
                                : '700px'
                        }`,
                    }}
                >
                    <ClickAwayListener onClickAway={() => closePopper(title)}>
                        {modalType === defaultConfig.executionEngine || isExecution || (saucelabsExpanded !== undefined ? !saucelabsExpanded : '')  ? (
                            <div className={classes.selectBoxContainer}>
                                {dropDownData.map((item, index) => (
                                    <div
                                        aria-hidden
                                        key={index.toString()}
                                        style={{
                                            fontWeight: selectedDropDownValue === item ? 700 : 400,
                                            backgroundColor:selectedDropDownValue === item ? '#d8d8d8' : '',
                                            
                                        }}
                                        className={classes.menuItem}
                                        onClick={() => {
                                            if (isPlatform) return handlePlatformChange({ selectedPlatform: item, selectedTab: index });
                                            if (isExecution) return handleExecutionMode(item);
                                            return handleBrowserChange(item, index);
                                        }}
                                    >
                                        {item === 'internetExplorer' ? 'Internet Explorer' : item}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div style={{ width: `calc(100% - ${isPlatform ? '0px' : '30px'})` }} className={classes.selectBoxContainer}>
                                <Tabs value={selectedTab} onChange={handleTabChange} TabIndicatorProps={tabIndicatorProps}>
                                    {dropDownData.map((item, index) => (
                                        <Tab
                                            key={`${index}`}
                                            label={item === 'internetExplorer' ? 'IE' : item}
                                            icon={
                                                Logos[
                                                    item === 'internetExplorer'
                                                        ? 'internetExplorerLogo'
                                                        : item === 'iOS'
                                                        ? 'macosLogo'
                                                        : item === 'MSEdge'
                                                        ? 'edgeLogo'
                                                        : `${item.replace(/ +/g, "").toLowerCase()}Logo`
                                                ]
                                            }
                                            classes={{
                                                root: classes.root,
                                                selected:
                                                    classes[
                                                        index === 0
                                                            ? 'firstTabSelected'
                                                            : index === dropDownData.length - 1
                                                            ? 'lastTabSelected'
                                                            : 'tabSelected'
                                                    ],
                                                wrapper: classes.wrapper,
                                                // labelContainer: classes.labelContainer,
                                            }}
                                            style={{ minWidth: `calc(100% / ${dropDownData.length})` }}
                                        />
                                    ))}
                                </Tabs>
                                <div className={classes.versionListContainer}>
                                    {checkArrayLength(versionsArray[selectedTab])
                                        ? versionsArray[selectedTab].map((version, index) => (
                                              <VersionListText
                                                  key={`${index}`}
                                                  id={`${title.toLowerCase()}-version-${index}`}
                                                  onclick={() => {
                                                      if (isPlatform) {
                                                          handlePlatformChange({
                                                              selectedTab,
                                                              selectedPlatform: `${version}`,
                                                              callback: () => resetBrowserVersions(version),
                                                          });
                                                      } else {
                                                          handleBrowserChange(`${dropDownData[selectedTab]} ${version}`, selectedTab);
                                                      }
                                                  }}
                                                  version={version}
                                                  versionListTextProps={versionListTextProps}
                                                  selectedDropDownValue={selectedDropDownValue}
                                                  isPlatform={isPlatform}
                                              />
                                          ))
                                        : noBrowserMessage}
                                </div>
                            </div>
                        )}
                    </ClickAwayListener>
                </Popper>
            </div>
        </div>
    );
};
PlatformBrowserSelectionContainer.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    dropDownData: PropTypes.instanceOf(Array).isRequired,
    isScheduleModal: PropTypes.bool,
    // isOpenDialog: PropTypes.bool.isRequired,
    // selectedBrowserArray: PropTypes.instanceOf(Array),
    selectedTab: PropTypes.number,
    // selectedDropDownValue: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    versionsArray: PropTypes.instanceOf(Array),
    // functions
    closePopper: PropTypes.func.isRequired,
    handleBrowserChange: PropTypes.func,
    handleExecutionMode: PropTypes.func,
    handlePlatformChange: PropTypes.func,
    handleTabChange: PropTypes.func,
    onClickExecutionContainer: PropTypes.func,
    onClickPlatformBrowserContainer: PropTypes.func,
    resetBrowserVersions: PropTypes.func,
};

PlatformBrowserSelectionContainer.defaultProps = {
    isScheduleModal: false,
    selectedBrowserArray: [],
    type: '',
    versionsArray: [],
    handleTabChange: () => {},
    handleExecutionMode: () => {},
    handleBrowserChange: () => {},
    handlePlatformChange: () => {},
    onClickExecutionContainer: () => {},
    onClickPlatformBrowserContainer: () => {},
    resetBrowserVersions: () => {},
    selectedTab: 0,
};

export default withStyles(styles)(PlatformBrowserSelectionContainer);
