import { checkKeyInObject, checkArrayLength} from './utils';

/**
|--------------------------------------------------
| ALL MAPPING STATUS
|--------------------------------------------------
*/
export const generateArray = (start, end) => {
    const array = [];
    for (let k = start; end && k <= end; k++) {
        array.push(k);
    }
    return array.reverse().map((element) => `${element}.0`);
};

export const getDevices = () => {
    return {
        "Windows": {
            "Windows 10": {
                "Chrome": ["91.0", "92.0"],
                "Firefox": ["89.0", "90.0"],
                "MSEdge": ["91.0"],
                //"internetExplorer": ['11.285'],
            },
        },

        "MacOS": {
            // "macOS High Sierra 10.13": {
            //     "Chrome": ["80.0", "92.0"],
            //     "Firefox": ["74.0", "90.0"],
            //     "Safari": ["11.1", "13.1"],
            //     "MSEdge": ["80.0"],
            // },
            "macOS Catalina 10.15": {
                "Chrome": ["91.0", "92.0"],
                //"Firefox": ["89.0", "90.0"],
                "Safari": ["13.0"],
                //"MSEdge": ["80.0"],
            },
            "macOS Big Sur 11": {
                "Chrome": ["91.0", "92.0"],
               // "Firefox": ["89.0", "90.0"],
                "Safari": ["14.0"],
                //"MSEdge": ["80.0"],
            },
        },
        // "Android": {
        //     "Google Pixel": {
        //         "ANDROID 11.0": ["1.20.2"],
        //        // "ANDROID Real Device 9.0": ["1.20.2"],
        //     },
        // },
        // "ANDROID Real Device": {
        //     "Google Pixel": {
        //         "RealDevice 11.0": ["1.20.2"],
        //     },
        // }, 
        // "iOS": {
        //     "iPad Pro": {
        //         "iOSEmulator 14.5": ["1.21.0"]
        //     },
        // },
        // "iOS Real Device": {
        //     "iPad Pro": {
        //         "iOSRealDevice 14.5": ["1.21.0"]
        //     },
        // },
        // "Android": {
        //     "Android GoogleAPI": {
        //         "ANDROID 8.1": ["1.16.0"],
        //         "ANDROID 9.0": ["1.16.0"],
        //     },
        // },
        // "iOS": {
        //     "iPhone": {
        //         "iOS 13.2": ["1.16.0"]
        //     },
        // },
    };
};

export const getDevicesSupported = (environmentInfo) => {
   // console.log("getSupportedEnv",environmentInfo);

    let Windows = {};
    let MacOS = {};
    let Linux = {}

    if(checkKeyInObject(environmentInfo,'environments')){
        if(checkArrayLength(environmentInfo.environments)){
            environmentInfo.environments.forEach((env,index)=>{  
                if(env.envType.toLowerCase() === 'saucelabs'){
                  let cap = [...env.capabilities];
                  
                  cap.reverse().forEach((item,index)=>{
                      let platforms = `${item.platform_name} ${item.platform_version}`
                      if(item.platform_name.toLowerCase().includes('windows')){
                          if(!Windows.hasOwnProperty(platforms)){
                            Windows[platforms] = {}
                          }
                          if(Windows.hasOwnProperty(platforms)){
                             if(item.browser_name && item.browser_name.toLowerCase().includes('edge')) {
                                item.browser_name = 'MicrosoftEdge'
                             }  
                             
                              if(!Windows[platforms].hasOwnProperty(item.browser_name)){
                                Windows[platforms][item.browser_name]=[item.browser_version];
                              }else{
                                Windows[platforms][item.browser_name].push(item.browser_version)
                              }
                          }
                      }else if(item.platform_name.toLowerCase().includes('macos')){
                          if(!MacOS.hasOwnProperty(platforms)){
                            MacOS[platforms] = {}
                          }
                          if(MacOS.hasOwnProperty(platforms)){
                              if(!MacOS[platforms].hasOwnProperty(item.browser_name)){
                                MacOS[platforms][item.browser_name]=[item.browser_version];
                              }else{
                                MacOS[platforms][item.browser_name].push(item.browser_version)
                              }
                          }
                      }
                  })
                }
                if(env.envType.toLowerCase() === 'zalenium'){
                    env.capabilities.forEach((item,index)=>{
                        let platforms = `${item.platform_name} ${item.platform_version}`
                        platforms = platforms.trim();
                        if(item.platform_name.toLowerCase().includes('linux')){
                            if(!Linux.hasOwnProperty(platforms)){
                                Linux[platforms] = {}
                            }
                            if(Linux.hasOwnProperty(platforms)){
                                if(!Linux[platforms].hasOwnProperty(item.browser_name)){
                                    Linux[platforms][item.browser_name+'(headful)']=[item.browser_version];
                                }else{
                                    Linux[platforms][item.browser_name+'(headful)'].push(item.browser_version)
                                }
                            }
                        }
                    })
                }
                if(env.envType.toLowerCase() === 'local'){
                    env.capabilities.forEach((item,index)=>{
                        let platforms = `${item.platform_name} ${item.platform_version}`
                        platforms = platforms.trim();
                        if(item.platform_name.toLowerCase().includes('linux')){
                            if(!Linux.hasOwnProperty(platforms)){
                                Linux[platforms] = {}
                            }
                            if(Linux.hasOwnProperty(platforms)){
                                if(!Linux[platforms].hasOwnProperty(item.browser_name)){
                                    Linux[platforms][item.browser_name+'(headless)']=[item.browser_version];
                                }else{
                                    Linux[platforms][item.browser_name+'(headless)'].push(item.browser_version)
                                }
                            }
                        }
                    })
                }
            }) 
        }
    }

    return {
        sauceDetails: {
            Windows,
            MacOS
        },
        localDetails:{
            Linux
        }
    }
}

// export const getDevices = () => {
//     return {
//         "Windows": {
//             "Windows 10": {
//                 "Chrome": ["dev", "beta", ...generateArray(26, 80)],
//                 "Firefox": [
//                     "dev", "beta",
//                     ...generateArray(26, 74),
//                     '25.0b2', ...generateArray(22, 24),
//                     '21.0b1', ...generateArray(4, 20),
//                 ],
//                 "MSEdge": [
//                     ...generateArray(79, 80),
//                     '18.17763', '17.17134', '16.16299', '15.15063', '14.14393', '13.10586'
//                 ],
//                 "internetExplorer": ['11.285'],
//             },
//             "Windows 8.1": {
//                 "Chrome": ["dev", "beta", ...generateArray(26, 80)],
//                 "Firefox": [
//                     "dev", "beta",
//                     ...generateArray(26, 74),
//                     '25.0b2', ...generateArray(22, 24),
//                     '21.0b1', ...generateArray(4, 20),
//                 ],
//                 "internetExplorer": [...generateArray(11, 11)],
//             },
//             "Windows 8": {
//                 "Chrome": ["dev", "beta", ...generateArray(26, 80)],
//                 "Firefox": [
//                     "dev", "beta",
//                     ...generateArray(26, 74),
//                     '25.0b2', ...generateArray(22, 24),
//                     '21.0b1', ...generateArray(4, 20),
//                 ],
//                 "internetExplorer": [...generateArray(10, 10)],
//             },
//             "Windows 7": {
//                 "Chrome": ["dev", "beta", ...generateArray(26, 80)],
//                 "Firefox": [
//                     "dev", "beta",
//                     ...generateArray(26, 74),
//                     '25.0b2', ...generateArray(22, 24),
//                     '21.0b1', ...generateArray(4, 20),
//                 ],
//                 "internetExplorer": [...generateArray(9, 11)],
//             },
//         },
//         "Linux": {
//             "Linux": {
//                 "Chrome": [...generateArray(26, 48)],
//                 "Firefox": [...generateArray(4, 45)],
//             }
//         },
//         "MacOS": {
//             "macOS Catalina 10.15": {
//                 "Chrome": ["dev", "beta", ...generateArray(69, 81)],
//                 "Firefox": ["dev", "beta", ...generateArray(4, 75)],
//                 "Safari": [...generateArray(13, 13)],
//                 "MSEdge": [...generateArray(79, 80)],
//             },
//             "macOS Mojave 10.14": {
//                 "Chrome": ["dev", "beta", ...generateArray(27, 81)],
//                 "Firefox": ["dev", "beta", ...generateArray(4, 75)],
//                 "Safari": [...generateArray(12, 12)],
//                 "MSEdge": [...generateArray(79, 80)],
//             },
//             "macOS High Sierra 10.13": {
//                 "Chrome": ["dev", "beta", ...generateArray(27, 81)],
//                 "Firefox": ["dev", "beta", ...generateArray(4, 75)],
//                 "Safari": ["13.0", "12.1", "11.1"],
//                 "MSEdge": [...generateArray(79, 80)],
//             },
//             "macOS Sierra 10.12": {
//                 "Chrome": ["dev", "beta", ...generateArray(27, 81)],
//                 "Firefox": ["dev", "beta", ...generateArray(4, 75)],
//                 "Safari": ["11.0", "10.1"],
//                 "MSEdge": [...generateArray(79, 80)],
//             },
//             "OS X El Capitan 10.11": {
//                 "Chrome": ["dev", "beta", ...generateArray(27, 81)],
//                 "Firefox": ["dev", "beta", ...generateArray(4, 75)],
//                 "Safari": [...generateArray(9, 10)],
//                 "MSEdge": [...generateArray(79, 80)],
//             },
//             "OS X Yosemite 10.10": {
//                 "Chrome": ["dev", "beta", ...generateArray(37, 81)],
//                 "Firefox": ["dev", "beta", ...generateArray(32, 47)],
//                 "Safari": [...generateArray(8, 8)],
//                 "MSEdge": [...generateArray(79, 80)],
//             },
//         },
//         "Android": {
//             "Android": {
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 6.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 5.1": ['1.9.1', '1.8.1', '1.8.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "Android GoogleAPI": {
//                 "ANDROID 10.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.11.1'],
//                 "ANDROID 9.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 6.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 5.1": ['1.9.1', '1.8.1', '1.8.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG Tab S3": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG Tab A 10": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S9 WQHD": {
//                 "ANDROID 9.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S9 Plus WQHD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S9 Plus HD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S9 Plus FHD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S9 HD": {
//                 "ANDROID 9.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S9 FHD": {
//                 "ANDROID 9.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S8 WQHD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S8 Plus WQHD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S8 Plus HD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S8 Plus": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S8 Plus FHD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S8 HD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S8": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S8 FHD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S7 WQHD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S7 HD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S7": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S7 FHD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S7 Edge WQHD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S7 Edge HD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S7 Edge": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S7 Edge FHD": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "SG S6": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "Pixel": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "Pixel C": {
//                 "ANDROID 8.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 8.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.1": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//                 "ANDROID 7.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "Pixel 3a XL": {
//                 "ANDROID 10.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.11.1'],
//             },
//             "Pixel 3a": {
//                 "ANDROID 10.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.11.1'],
//             },
//             "Pixel 3 XL": {
//                 "ANDROID 10.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.11.1'],
//                 "ANDROID 9.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//             "Pixel 3": {
//                 "ANDROID 10.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.11.1'],
//                 "ANDROID 9.0": ['1.9.1', '1.8.1', '1.8.0', '1.16.0', '1.15.0', '1.13.0', '1.12.1', '1.11.1'],
//             },
//         },
//         "iOS": {
//             "iPhone XS": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//             },
//             "iPhone XS Max": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//             },
//             "iPhone XR": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//             },
//             "iPhone X": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPhone": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPhone SE": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],

//             },
//             "iPhone 8": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPhone 8 Plus": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPhone 7": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPhone 7 Plus": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPhone 6s": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPhone 6s Plus": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPhone 6": {
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPhone 6 Plus": {
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPhone 5s": {
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPhone 5": {
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPhone 11": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//             },
//             "iPhone 11 Pro": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//             },
//             "iPhone 11 Pro Max": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//             },
//             "iPad mini 4": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//             },
//             "iPad mini 3": {
//                 "iOS 12.4": ['1.13.0'],
//             },
//             "iPad mini 2": {
//                 "iOS 12.4": ['1.13.0'],
//             },
//             "iPad mini (5th generation)": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//             },
//             "iPad": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPad Pro (9.7 inch)": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPad Pro (12.9 inch)": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPad Pro (12.9 inch) (3rd generation)": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//             },
//             "iPad Pro (12.9 inch) (2nd generation)": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPad Pro (11 inch)": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//             },
//             "iPad Pro (10.5 inch)": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPad Air": {
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPad Air 2": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 10.3": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//             "iPad Air (3rd generation)": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//             },
//             "iPad (7th generation)": {
//                 "iOS 13.2": ['1.16.0'],
//             },
//             "iPad (6th generation)": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//             },
//             "iPad (5th generation)": {
//                 "iOS 13.2": ['1.16.0'],
//                 "iOS 13.0": ['1.16.0', '1.15.0'],
//                 "iOS 12.4": ['1.13.0'],
//                 "iOS 12.2": ['1.13.0', '1.12.1'],
//                 "iOS 12.0": ['1.9.1'],
//                 "iOS 11.3": ['1.9.1', '1.8.1'],
//                 "iOS 11.2": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.1": ['1.9.1', '1.8.1', '1.8.0'],
//                 "iOS 11.0": ['1.9.1', '1.8.1', '1.8.0'],
//             },
//         },
//     }
// }

export const getSelectedDevice = (selectedPlatform = '') => {
    const Devices = getDevices();
    const res = Object.keys(Devices).find((key) => Devices.hasOwnProperty(key) && checkKeyInObject(Devices[key], selectedPlatform, 'bool'));
    if (res) return res;
    return '';
};
