/* eslint-disable no-throw-literal */
import HttpBase from '../../services/HttpBase';

class TagMiddleware extends HttpBase {
    async createTag(obj) {
        const { data, status } = await this.httpClient.post('/v1/tags', obj);
        if (status === 200 && data) return { data, status: true };
        throw 'unable to create';
    }

    async getTags(projectId = null) {
        let url = '/v1/tags';
        if (projectId) url = `/v1/tags/${projectId}/get_tags`;

        const { status, data } = await this.httpClient.get(url);
        if (status === 200 && data) return data;
        return [];
    }

    async updateTag(obj) {
        const { data, status } = await this.httpClient.post('/tag/update', obj);
        if (status === 200 && data) return data;
        throw 'unable to update';
    }

    async tagTestCase(obj) {
        const { status } = await this.httpClient.post('/v1/tags/tag_testcase', obj);
        if (status === 200) return true;
        throw 'unable to update';
    }

    async untagTestCase(obj) {
        const { status } = await this.httpClient.post('/v1/tags/untag', obj);
        if (status === 200) return true;
        throw 'unable to update';
    }
}

export default new TagMiddleware();
