import HttpBase from '../../services/HttpBase';
import { yamlToObj } from '../../utils/utils';
import HttpService from '../../services/HttpService';

class AccountMiddleware extends HttpBase {
    async getAccounts() {
        const result = await this.httpClient.get('/accounts/search');
        if (result && result.data && result.status === 200) {
            return { accounts: result.data };
        }
        throw new Error('Failed to fetch accounts');
    }

    async getAccountById(accountId) {
        const result = await this.httpClient.get(`/accounts/search/${accountId}`);
        if (result && result.data && result.status === 200) {
            return { account: result.data };
        }
        throw new Error('Failed to fetch account');
    }

    getSamlConfig = async (accountId) => {
        const result = await HttpService.get(`/v1/accounts/${accountId}/getsamlconfig`);
        if (result && result.data && result.status === 200) {
            return { samlConfig: result.data };
        }
        throw new Error('Failed to fetch account');
    };

    async getMarket() {
        const [resultGlobal, resultLocal] = await Promise.all([
            this.httpClient.get('/v1/getglobalmarket'),
            this.httpClient.get('/v1/getlocalmarket'),
        ]);
        const data = [];
        let isSuccess = false;
        if (resultGlobal && resultGlobal.data && resultGlobal.status === 200) {
            isSuccess = true;
            Object.keys(yamlToObj(resultGlobal.data)).forEach((key) => {
                if (data.indexOf(key) === -1) {
                    data.push(key);
                }
            });
        }
        if (resultLocal && resultLocal.data && resultLocal.status === 200) {
            isSuccess = true;
            Object.keys(yamlToObj(resultLocal.data)).forEach((key) => {
                if (data.indexOf(key) === -1) {
                    data.push(key);
                }
            });
        }
        if (isSuccess) {
            return { marketYamlData: data };
        }
        throw new Error('Failed to get market');
    }

    async updateAccount(formData) {
        const headerConfig = {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        };
        const result = await this.httpClient.post('v1/accounts/update', formData, headerConfig);
        if (result && result.data && result.status === 200) {
            return { account: result.data };
        }
        throw new Error('Failed to update account');
    }

    updateSamlConfig = async (samlConfig, accountId) => {
        const result = await HttpService.post(`/v1/accounts/${accountId}/updatesamlconfig`, samlConfig);
        if (result.status === 200) {
            return;
        }
        throw new Error('Failed to update samlConfig');
    };

    async uploadYamlFile(formData, isGlobal) {
        const headerConfig = {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        };
        const result = await this.httpClient.post(`/v1/global/${isGlobal}/updatemarket`, formData, headerConfig);
        if (result.status === 200) {
            return { data: result };
        }
        throw Error('Failed to upload file.');
    }

    async GetAccountsPrefences(id) {
        const result = await this.httpClient.get(`/v1/accounts/${id}/getaccountpreferences`);
        if (result && result.data && result.status === 200) {
            return { accounts: result.data };
        }
        throw new Error('Failed to fetch accounts');
    }

    async GetPermissions() {
        const result = await this.httpClient.get(`/betafeatures`);
        if (result && result.data && result.status === 200) {
            return { permission: result.data };
        }
        throw new Error('Failed to fetch details');
    }

    async UpateAccountPrefences(id, data) {
        const result = await this.httpClient.post(`/v1/accounts/${id}/updatetestcasecolumns`, data);
        if (result && result.status === 200) {
            return { data: result.data };
        }
        throw new Error('no alert found');
    }

    async UpateAccountPrefencesDashBoard(id, data) {
        const result = await this.httpClient.post(`/v1/accounts/${id}/updatedashboardcards`, data);
        if (result && result.status === 200) {
            return { accounts: result.data };
        }
        throw new Error('no alert found');
    }

    async deleteBulkObjects(days) {
        const result = await this.httpClient.get(`/v1/days/${days}/removeoldobjects`);
        if (result && result.status === 200) {
            return true;
        }
        throw new Error('Unable to delete objects');
    }

    async deleteBulkVideos() {
        const result = await this.httpClient.get('/v1/removeoldvideos');
        if (result && result.status === 200) {
            return true;
        }
        throw new Error('Unable to delete videos');
    }

    async getsupportedEnvironments(){
        const result = await HttpService.get('/v1/accounts/getsupportedenvironments');
        if (result && result.data && result.status === 200) {
            
            return { supportedEnvironments : result.data };
         }
    }


    async getEnvironmentConfig(accountId){
        const result = await HttpService.get(`/v1/accounts/executionenvironment`);
        if (result && result.data && result.status === 200) {
            return { environmentConfig: result.data };
        }
        throw new Error('Failed to get environment Configuration');
    }


    async getSauceDetails(){
        const result = await HttpService.get('/v1/users/saucecredentials');
        if (result && result.data && result.status === 200) {
            return { sauceCredentials: result.data };
        }
        throw new Error('Failed to get sauce details');
    }


    async updateSauceDetails(type,data){
        var result;
        if(type === 'create'){
             result = await this.httpClient.post(`/v1/users/createsaucecredentials`, data);
        }else if(type === 'update'){
             result = await this.httpClient.post(`/v1/users/updatesaucecredentials`, data);
        }
        if (result && result.status === 200) {
            return { result };
        }
        throw new Error('unable to update or create');
    }

    async postEnvironmentConfig(data){
        const headerConfig = {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        };

        const formData = new FormData();
        formData.append('AccountPreferencesEnvironment', JSON.stringify(data));           

        const result = await this.httpClient.post(`/v1/accounts/executionenvironment`,formData,headerConfig);
        
        if (result && result.data && result.status === 200) {
             //console.log("resultVar",resultVar); 
        }
        throw new Error('Failed to post environment Configuration');
    }

}

export default new AccountMiddleware();
