import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Pause from '@material-ui/icons/Pause';
import PlayArrow from '@material-ui/icons/PlayArrow';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { CircularProgress } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ArrowKeysReact from 'arrow-keys-react';
import CloseOnEscape from 'react-close-on-escape';
import defaultScreenShot from '../../../assets/images/noScreenshot.png';
import { checkArrayLength, handleXpathLength } from '../../../utils/utils';
import Tooltip from '../../../components/Tooltip';
import StatusSnipet from './StatusSnipet';
import ImageResizer from '../../modal/GeneralModal/ImageResizer';
import { TestStepUtils } from '../../../utils/TestStepUtils';
import PreLoadImage from '../../../common/PreLoadImage';
import { CustomSwitch } from '../../../common/miniComponents';

const styles = () => ({
    root: {
        width: '100%',
    },
    dialog: {
        textAlign: 'center',
    },
    testnamedisabled: {
        color: '#4C4C4C',
    },
    dialogContent: {},
    prevImageContainer: {
        height: '25%',
        'border-top-left-radius': 12,
        background: 'rgba(171, 171, 171, 1)',
        'border-bottom-left-radius': 12,
        cursor: 'pointer',
    },
    prevImageContainerDisabled: {
        height: '25%',
        'border-top-left-radius': 12,
        background: 'rgba(171, 171, 171, 1)',
        'border-bottom-left-radius': 12,
        cursor: 'not-allowed',
        '& > svg': {
            color: '#ffffff',
        },
    },
    nextImageContainer: {
        height: '25%',
        borderTopRightRadius: 12,
        background: 'rgba(171, 171, 171, 1)',
        borderBottomRightRadius: 12,
        cursor: 'pointer',
    },
    nextImageContainerDisabled: {
        height: '25%',
        'border-top-right-radius': 12,
        background: 'rgba(171, 171, 171, 1)',
        'border-bottom-right-radius': 12,
        cursor: 'not-allowed',
        '& > svg': {
            color: '#ffffff',
        },
    },
    treverseStep: {
        textAlign: 'left',
        paddingLeft: '20px',
        color: '#1E69CC',
        cursor: 'pointer',
    },
    treverseStepDisabled: {
        textAlign: 'left',
        paddingLeft: '20px',
        color: '#b4b4b4',
        cursor: 'not-allowed',
    },
    sliderMask: {
        position: 'absolute',
        width: '100%',
        zIndex: 100,
        height: '100%',
        background: 'rgba(0,0,0,0.4)',
        cursor: 'pointer',
    },
    buttons: {
        fontSize: '14px',
        color: 'rgb(66, 174, 0)',
        backgroundColor: 'white',
        cursor: 'pointer',
        padding: 10,
        fontWeight: 500,
    },
    playPauseBtn: {
        marginTop: 4,
        cursor: 'pointer',
        fontSize: 24,
        fontWeight: 'bolder',
    },
    modalPopper: {
        zIndex: 99999,
        maxWidth: 280,
        height: 'auto',
    },
    modalPapper: {
        padding: '4px 10px 0 10px',
    },
    statusIcon: {
        cursor: 'pointer',
    },
    progress: {
        position: 'absolute',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        width: 'calc(100% - calc(23.99px + 23.99px))',
        marginLeft: '23.99px',
    },
    topBar: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    arrowCont: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '46px',
        width: '12%',
    },
    arrow: {
        marginRight: 10,
        cursor: 'pointer',
    },
    testCasedetails: {
        display: 'flex',
        height: '46px',
        alignItems: 'center',
        justifyContent: 'start',
        width: '58%',
    },
    maxWidth400: {
        marginRight: '30px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: 500,
        maxWidth: 400,
        color: '#1168CD',
    },
    maxWidth1000: {
        marginRight: '30px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: 500,
        maxWidth: 1000,
        color: '#1168CD',
    },
    noWrap400: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 400,
        color: '#1BABAB',
        marginLeft: '20px',
    },
    bottomContainer: {
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        margin: '0 auto',
        height: 'calc(100vh - 90px)',
        alignItems: 'center',
    },
    leftButton: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: '24px',
        height: '100%',
    },
    rightButton: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    },
    imageContainer: {
        overflow: 'auto',
        width: '100%',
        maxHeight: 'calc(100vh - 90px)',
        minHeight: 'calc(100vh - 90px)',
        backgroundColor: '#fff',
    },
    image: {
        height: '100%',
        width: '100%',
        border: '2px solid #ADD8E6',
        margin: '0px auto',
        display: 'block',
    },
    zoomContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        position: 'fixed',
        bottom: '2px',
        width: '100%',
    },
    statusKey: {
        verticalAlign: 'middle',
        paddingRight: '8px',
        top: '1px',
        position: 'relative',
    },
    firstStep: {
        marginLeft: '16px',
        marginRight: '16px',
        paddingLeft: '0px',
    },
    remainingStep: {
        marginLeft: '0px',
        marginRight: '0px',
        paddingLeft: '47px',
    },
    customBtn: {
        background: '#ECECEC',
        color: '#000000c9',
        fontWeight: 500,
        padding: '2px 10px',
        borderRadius: '7px',
        marginLeft: '20px',
        fontSize: '13px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#c2c2c2',
        },
    },
    playButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    playPauseIcons: {
        cursor: 'pointer',
        fontSize: 28,
        fontWeight: 'bolder',
        color: '#0068d4',
    },
    playPause: {
        background: '#ffffff',
        borderRadius: '5px',
        minWidth: '50px',
        maxWidth: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '32px',
        marginRight: '25px',
    },
    topBanner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        height: '35px',
        borderRadius: '5px',
        minWidth: '60%',
    },
    pipe: {
        display: 'block',
        borderLeft: '1px solid #595959',
        height: 20,
        alignSelf: 'center',
    },
    expectedResult: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 300,
        marginLeft: 20,
        color: '#B98700',
    },
    testStepNumber: {
        paddingLeft: 25,
        minWidth: '30px',
        color: '#595959',
    },
    switchContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '22%',
    },
});

class FullScreenshotMode extends Component {
    constructor(props) {
        super(props);
        const testStep = props.testSteps[props.currentStepScreenshotModalIndex];
        this.autoPlayInteval = null;
        this.state = {
            autoPlay: false,
            testInstruction: testStep.instr,
            testData: testStep.data,
            testStepNumber: testStep.instrNum,
            expectedResult: testStep.expectedResults,
            testStepStatus: testStep.status,
            testStepMsg: testStep.message,
            imageNo: testStep.screenshotNo ? Number(testStep.screenshotNo) - 1 : 0,
            anchorEl: null,
            open: false,
            isImageLoading: false,
            isImageSuccess: true,
            percentValue: 100,
            slideShow: false,
        };

        ArrowKeysReact.config({
            left: () => {
                this.handleKeyPress('prev');
            },
            right: () => {
                this.handleKeyPress('next');
            },
            up: () => {
                this.handleKeyPress('prev');
            },
            down: () => {
                this.handleKeyPress('next');
            },
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { testStep: newTestStep } = nextProps;
        const {
            testStep: { instr, data, instrNum, status, message, hasChild },
        } = this.props;
        if (
            newTestStep &&
            (instr !== newTestStep.instr ||
                data !== newTestStep.data ||
                instrNum !== newTestStep.instrNum ||
                status !== newTestStep.status ||
                message !== newTestStep.message ||
                hasChild !== newTestStep.hasChild)
        ) {
            this.setState({
                testInstruction: newTestStep.instr,
                testData: newTestStep.data,
                testStepNumber: newTestStep.instrNum,
                expectedResult: newTestStep.expectedResults,
                testStepStatus: newTestStep.status,
                testStepMsg: newTestStep.message,
                imageNo: newTestStep.screenshotNo ? Number(newTestStep.screenshotNo) - 1 : 0,
            });
        }
    }

    componentWillUnmount() {
        const { slideShow } = this.state;
        const { toggleAutoPlay } = this.props;

        if (slideShow) {
            toggleAutoPlay();
        }
    }

    handleHover = (event) => {
        const { currentTarget } = event;
        this.setState((state) => ({
            anchorEl: currentTarget,
            open: !state.open,
        }));
    };

    imageCompeleteLoad = () => {
        const { autoPlay } = this.state;
        this.setState({ isImageLoading: false, isImageSuccess: true });
        if (autoPlay && !this.autoPlayInteval) {
            this.autoPlayInteval = true;
            setTimeout(() => {
                this.autoPlayInteval = false;
                const { handleModalTreverseStep, testCaseId, currentStepScreenshotModalIndex, stepsLength } = this.props;
                const currentIndex = currentStepScreenshotModalIndex !== stepsLength - 1 ? currentStepScreenshotModalIndex : -1;
                handleModalTreverseStep(true, { testCaseId, currentStepScreenshotModalIndex: currentIndex, stepsLength }, 'next');
                this.chagneImage();
            }, 2000);
        }
    };

    handleKeyPress = (key) => {
        const { testCaseId, currentStepScreenshotModalIndex, stepsLength } = this.props;

        if (testCaseId && (currentStepScreenshotModalIndex || currentStepScreenshotModalIndex === 0) && stepsLength) {
            this.props.handleModalTreverseStep(true, { testCaseId, currentStepScreenshotModalIndex, stepsLength }, key);
            this.chagneImage();
        }
    };

    handleEscape = () => {
        this.props.handleModal(false);
    };

    handleClose = (e) => {
        e.stopPropagation();
    };

    chagneImage = (imageNo = null) => {
        this.setState({
            imageNo: imageNo !== null ? imageNo : this.state.imageNo,
        });
    };

    toggleAutoPlay = () => {
        const { autoPlay } = this.state;
        if (autoPlay) {
            this.autoPlayInteval = false;
        } else {
            this.chagneImage();
        }
        this.setState({
            autoPlay: !autoPlay,
        });
    };

    resetPercent = () => {
        this.setState({ percentValue: 100 });
    };

    startSlideShow = () => {
        const { slideShow } = this.state;
        const { toggleAutoPlay } = this.props;

        this.setState({ slideShow: !slideShow }, () => {
            toggleAutoPlay();
        });
    };

    render() {
        const {
            classes,
            handleModalTreverseStep,
            testStep,
            testCaseId,
            currentStepScreenshotModalIndex,
            stepsLength,
            handleFullscreenMode,
            isFullScreenShot,
            handleSwitch,
            getPreviewImage,
        } = this.props;
        const {
            expectedResult,
            testStepNumber,
            testStepStatus,
            testStepMsg,
            testInstruction,
            testData,
            imageNo,
            autoPlay,
            anchorEl,
            isImageLoading,
            isImageSuccess,
            slideShow,
            percentValue,
        } = this.state;
        const paths = getPreviewImage(testStep);
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popper' : null;
        const isOriginalImageAvailable = checkArrayLength(paths) && paths[imageNo] !== defaultScreenShot && (isImageLoading || isImageSuccess);
        return (
            <div className={classes.root} {...ArrowKeysReact.events}>
                <div className={classes.topBar}>
                    <CloseOnEscape onEscape={() => handleFullscreenMode(false)}>
                        <div
                            aria-hidden
                            className={classes.arrowCont}
                            onClick={() => {
                                handleFullscreenMode(false);
                            }}
                        >
                            <ArrowBack className={classes.arrow} aria-label="arrowBackIcon" id="arrowBackIcon" /> Exit full screen
                        </div>
                    </CloseOnEscape>
                    <span className={classes.switchContainer}>
                        <span style={{ fontSize: '14px' }}>Show complete webpage image</span>
                        <CustomSwitch
                            checked={isFullScreenShot}
                            onChange={(e) => {
                                handleSwitch(e, 'isFullScreenShot');
                            }}
                            value="isFullScreenShot"
                            disabled={!this.props.projectIsFullScreenShot}
                            w={34}
                            h={18}
                            margin="13px 0 13px 13px"
                            trackBorderColor="#000"
                            trackColor="#000"
                            trackColorChk="#1168cd80"
                            thumbColorChk="#1168cd"
                        />
                    </span>
                    <div className={classes.testCasedetails}>
                        <div className={classes.topBanner}>
                            <StatusSnipet
                                testStepStatus={testStepStatus}
                                testStepMsg={testStepMsg || ''}
                                testStepNumber={testStepNumber}
                                id={id}
                                handleHover={this.handleHover}
                                callBackSetState={() => {
                                    this.setState({ anchorEl: null });
                                }}
                                callFrom="screenShotModal"
                            />
                            <span
                                className={parseInt(testStep.status, 10) !== 5 ? classes.testname : classes.testnamedisabled}
                                style={{
                                    marginLeft: testStepNumber % 1 === 0 ? 0 : -20,
                                    marginRight: 20,
                                    display: 'flex',
                                    fontWeight: testStepNumber % 1 === 0 ? 400 : 300,
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span className={classes.testStepNumber}>{`${testStepNumber} `}</span>
                                    <span
                                        style={{
                                            marginRight: '15px',
                                        }}
                                    />
                                    <span
                                        style={{
                                            display: 'flex',
                                            marginLeft: '15px',
                                        }}
                                    >
                                        {testData ? (
                                            <p className={classes.maxWidth400} style={{ paddingLeft: testStepNumber % 1 === 0 ? 0 : 5 }}>
                                                <Tooltip data={testInstruction}>{handleXpathLength(testInstruction)}</Tooltip>
                                            </p>
                                        ) : (
                                            <p className={classes.maxWidth1000} style={{ paddingLeft: testStepNumber % 1 === 0 ? 0 : 5 }}>
                                                <Tooltip data={testInstruction}>{handleXpathLength(testInstruction)}</Tooltip>
                                            </p>
                                        )}
                                        {testData && <span className={classes.pipe} />}
                                        {testData && (
                                            <p className={classes.noWrap400}>
                                                <Tooltip data={TestStepUtils.isShowAsterik(testInstruction, testData, null)}>
                                                    {TestStepUtils.isShowAsterik(testInstruction, testData)}
                                                </Tooltip>
                                            </p>
                                        )}
                                        {expectedResult && <span style={{ marginLeft: 20 }} className={classes.pipe} />}
                                        {expectedResult && (
                                            <p className={classes.expectedResult}>
                                                <Tooltip data={expectedResult}>{expectedResult}</Tooltip>
                                            </p>
                                        )}
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className={classes.playButtonContainer}>
                        <div onClick={() => this.startSlideShow()} className={classes.playPause} aria-hidden>
                            {slideShow ? (
                                <Pause className={classes.playPauseIcons} aria-label="pauseIcon" id="pauseIcon" />
                            ) : (
                                <PlayArrow className={classes.playPauseIcons} aria-label="playArrowIcon" id="playArrowIcon" />
                            )}
                        </div>
                    </div>
                </div>
                <div className={classes.bottomContainer}>
                    {isImageLoading || this.props.parentIsImageLoading ? (
                        <div className={classes.progress}>
                            <CircularProgress size={50} color="secondary" />
                        </div>
                    ) : null}
                    <div className={classes.leftButton}>
                        <div
                            className={
                                currentStepScreenshotModalIndex === 0 || autoPlay ? classes.prevImageContainerDisabled : classes.prevImageContainer
                            }
                        >
                            <ChevronLeft
                                style={{ height: '100%' }}
                                onClick={() => {
                                    if (currentStepScreenshotModalIndex !== 0 && !autoPlay) {
                                        handleModalTreverseStep(true, { testCaseId, currentStepScreenshotModalIndex, stepsLength }, 'prev');
                                        this.chagneImage();
                                    }
                                }}
                                aria-label="chevronLeftIcon"
                                id="chevronleftIcon"
                            />
                        </div>
                    </div>
                    <div className={classes.imageContainer}>
                        <div>
                            {isOriginalImageAvailable ? (
                                <PreLoadImage
                                    key={paths[imageNo && imageNo >= 0 ? imageNo : 0]}
                                    id="screenShotImg"
                                    onStartLoad={() => {
                                        // console.log('start');
                                        this.setState({ isImageLoading: true, isImageSuccess: false });
                                    }}
                                    onLoad={() => {
                                        // console.log('load');
                                        this.imageCompeleteLoad();
                                    }}
                                    onError={() => {
                                        // console.log('error');
                                        this.setState({ isImageLoading: false, isImageSuccess: false });
                                    }}
                                    className={classes.image}
                                    alt=""
                                    defaultImage={defaultScreenShot}
                                    src={paths[imageNo && imageNo >= 0 ? imageNo : 0]}
                                />
                            ) : (
                                <PreLoadImage key={defaultScreenShot} ref={this.imgRef} alt="" className={classes.image} src={defaultScreenShot} />
                            )}
                        </div>
                    </div>
                    <div className={classes.rightButton}>
                        <div
                            className={
                                currentStepScreenshotModalIndex === stepsLength - 1 || autoPlay
                                    ? classes.nextImageContainerDisabled
                                    : classes.nextImageContainer
                            }
                        >
                            <ChevronRight
                                style={{ height: '100%' }}
                                onClick={() => {
                                    /*eslint-disable  */
                                    if (currentStepScreenshotModalIndex !== stepsLength - 1 && !autoPlay) {
                                        handleModalTreverseStep(true, { testCaseId, currentStepScreenshotModalIndex, stepsLength }, 'next');
                                        this.chagneImage();
                                    }
                                }}
                                aria-label="chevronRightIcon"
                                id="chevronRightIcon"
                            />
                        </div>
                    </div>
                </div>
                {isOriginalImageAvailable ? (
                    <div className={classes.zoomContainer}>
                        <ImageResizer
                            imgId={'screenShotImg'}
                            defaultValue={percentValue}
                            onChange={(value) => {
                                this.setState({ percentValue: value });
                            }}
                        />
                        <div
                            className={classes.customBtn}
                            onClick={() => {
                                this.resetPercent();
                            }}
                        >
                            RESET
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(FullScreenshotMode);
