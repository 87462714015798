import { AuthActions, UserActions, ProjectActions } from '../actions';
import CookieStorage from '../../common/cookieStorage';
import WSService from '../../services/WSService';
import HttpService from '../../services/HttpService';
import HttpAuthService from '../../services/HttpAuthService';

export default class AuthMiddleWare {
    static authKey = 'aut_remember';

    static checkAutCookieData() {
        return (dispatch) => {
            const cookieAuthenticated = CookieStorage.get(this.authKey);
            const data = JSON.parse(localStorage.getItem('tabHandel'));
            if (CookieStorage.get('X-Authorization') !== undefined) {
                localStorage.setItem('token', CookieStorage.get('X-Authorization'));
            }
            if (cookieAuthenticated) {
                WSService.initializeWebSocket(cookieAuthenticated.accountId);
                if (data) {
                    dispatch(UserActions.handelTableCol(data));
                }
                dispatch(
                    AuthActions.signinSuccess(null, {
                        data: cookieAuthenticated,
                    }),
                    dispatch(ProjectActions.getNonDeletedNotification(cookieAuthenticated.accountId)),
                );
                return cookieAuthenticated;
            }

            return dispatch(AuthActions.signinFailure({}));
        };
    }

    static authenticate = async (data) => {
        const {
            username,
            password,
            // check
        } = data;
        const res = await HttpService.post('/v1/auth', {
            username,
            password,
        });
        return res;
    };

    static authenticateEmail = async (email) => {
        if (!email) {
            return {
                error: 'Email is required',
            };
        }
        try {
            const result = await HttpAuthService.post('/auth/v1/forgotpassword', {
                email,
                host: window.location.origin,
            });

            if (result) {
                return result;
            }
            throw new Error('Failed to get response');
        } catch (error) {
            if (error.response) {
                throw error.response.statusText;
            }
            throw new Error(error);
        }
    };

    static resetPasswordMail = async (email) => {
        if (!email) {
            return {
                error: 'Email is required',
            };
        }
        try {
            const result = await HttpAuthService.post('/auth/v1/forgotpassword', {
                email,
                host: window.location.origin,
            });

            if (result) {
                return result;
            }
            throw new Error('Failed to get response');
        } catch (error) {
            if (error.response) {
                throw error.response.statusText;
            }
            throw new Error(error);
        }
    };

    static register = async (obj) => {
        try {
            const { status } = await HttpAuthService.post('/auth/v1/register', { ...obj, host: window.location.origin });
            if (status === 200) return true;
        } catch (error) {
            let _error = error;
            if (_error.response) {
                if (_error.response.data) {
                    _error = _error.response.status === 404 ? 'Something went wrong! Please Try Again.' : _error.response.data;
                    if (_error.error) _error = _error.error;
                }
            }
            throw _error ? _error.toString() : new Error('Something went wrong! Please Try Again.');
        }
        return null;
    };

    static sendconfirmation = async (query) => {
        const { status } = await HttpAuthService.get(`/auth/v1/confirm${query}`);
        if (status === 200) return true;
        return false;
    };

    static getAuthDetails = async (user) => {
        const result = await HttpService.get(`/v1/username/${user}/getauthdetails`);
        if (result.status === 200) {
            if (result && result.data) {
                CookieStorage.setSimple('auth-type', result.data.authType, { expires: 30, path: '' });
                CookieStorage.setSimple('accountId', result.data.accountId, { expires: 30, path: '' });
                return result;
            }
        }
        throw new Error('Failed to fetch account');
    };

    static changePassword = async (data) => {
        const result = await HttpAuthService.post('/auth/v1/changepassword', data);
        if (result && result.data && result.data.error) {
            throw new Error(result.data.error);
        } else {
            return {
                status: true,
            };
        }
    };

    static resetPassword = async (password, token, id) => {

        return new Promise(async(resolve,reject)=>{

            if (!password || !token) {

                reject({
                    error: 'Password is required',
                })
            }
            const obj = {
                password,
                token,
                id,
            };
            try {
                const result = await HttpAuthService.post('/auth/v1/resetpassword', obj);
                if (result && result.status === 200) {
                    resolve(result)
                }
    
    
            } catch(err) {
    
                if(err && err.response) {
                    reject(err.response);
                }
    
            }

        })
        
        
    };

    static userSignUpFalse() {
        return (dispatch) => {
            dispatch(AuthActions.userSignupfalse('hello'));
        };
    }
}
