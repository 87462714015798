import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { ClickAwayListener, Tabs, Tab, Box } from "@material-ui/core";
import { styles } from "./Styles";
import BrowserDetailsContainer from "./BrowserDetailsContainer";
import PlatformIcon from "./PlatformIcon";
import DeviceDetailsContainer from "./DeviceDetailsContainer";
const PlatformBrowserSelectorBody = (props) => {

  const {callingFrom, classes, selected, data, handleClickAway, isDeviceSelectorOpen,onDeviceSelected } = props;

  const [activePlatfrom, setActivePlatfrom] = useState(0);
  const handleChange = (event, newValue) => {
    setActivePlatfrom(newValue);
  };
  return (
    <Box className={callingFrom === "sauceExecution" && classes.device_selector_body}>
      {isDeviceSelectorOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box className={classes.device_selector_options_container}>
            <Tabs value={activePlatfrom} onChange={handleChange} aria-label="platform-tabs" className={classes.tabs}>
              {
                data.map((dc, index) => {
                return (
                  <Tab
                    label={
                      <div className={classes.device_name_container}>
                        <PlatformIcon platformName={dc.deviceLogo} />
                        <span style={{color: activePlatfrom === index ? '#3B91DF' : '#000000', fontWeight: 600}}>{dc.deviceName}</span> 
                        {dc.deviceName?.toLowerCase() === 'android' && <span className={classes.beta}>beta</span>} 
                      </div>
                    }
                    id={`${index}`}
                    aria-controls={`${index}`}
                  />
                );
              })}
            </Tabs>
            {
            data.map((d, index) => {
              return (
                <Box
                  role="tabpanel"
                  hidden={activePlatfrom !== index}
                  id={`${index}`}
                  aria-labelledby={`simple-tab-${index}`}
                  style={callingFrom === "sauceExecution" ? {maxHeight: 200, overflowY: 'auto'} : {}}
                >
                  {activePlatfrom === index && (
                    <Box>
                      {d.deviceVersions.map((dv) => {
                        return (
                          <div>
                            <div className={classes.device_name}>{dv.name}</div>
                            {
                            dv.browsers &&    
                            dv.browsers.map((b) => {
                              return (
                                <BrowserDetailsContainer
                                  onChange={onDeviceSelected}
                                  selected={selected}
                                  browserDetails={b}
                                />
                              );
                            })
                            }
                            {
                                dv.devices &&    
                                    <DeviceDetailsContainer
                                        onChange={onDeviceSelected}
                                        selected={selected}
                                        deviceDetails={dv.devices}
                                    />
                                    
                                

                            }
                          </div>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default withStyles(styles)(PlatformBrowserSelectorBody);
