// import material
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// import packages
import React from 'react';
import PropTypes from 'prop-types';

// custom component
import { checkKeyInObject } from '../../../utils/utils';
import Tooltip from '../../../components/Tooltip';
import { CustomSwitch } from '../../../common/miniComponents';

// styles
const styles = () => ({
    buttonContainer: {
        padding: '10px 0px 0px 0px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonSave: {
        background: '#0092e6',
        color: '#fff',
        textTransform: 'capitalize',
    },
    heading: {
        paddingLeft: '20px',
        fontWeight: 'bold',
        color: '#494949',
        fontSize: '12px',
    },
    input: {
        '&>div>input': {
            padding: '2px 0px 7px',
            width: '50%',
            fontSize: '12px',
        },
    },
    inputBrowsers: {
        width: '100%',
        borderRadius: '3px',
        '&>div>div': {
            fontSize: '12px',
        },
    },
    itemMainWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
        borderBottom: '1px solid #c5ced8',
        paddingTop: 20,
    },
});

function GeneralPreferences(props) {
    const { actions, classes, data } = props;
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item xs={1} sm={1} md={2} lg={2} />
            <Grid item xs={10} sm={10} md={7} lg={7}>
                <div className={classes.itemMainWrapper}>
                    <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                            Tunnel
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Tooltip data="Toggle Tunnel">
                            <CustomSwitch
                                checked={data.isTunnelOn}
                                onChange={checkKeyInObject(actions, 'handleSwitchs') && actions.handleSwitchs}
                                value="isTunnelOn"
                                h={16}
                                w={30}
                                margin="2px 0 7px 0"
                            />
                        </Tooltip>
                    </Grid>
                </div>
                <div className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.buttonSave}
                        disabled={data.buttonDisabled}
                        onClick={() => checkKeyInObject(actions, 'handleSave') && actions.handleSave()}
                    >
                        Save
                    </Button>
                </div>
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} />
        </div>
    );
}

GeneralPreferences.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    actions: PropTypes.shape({
        handleChangeTextVal: PropTypes.func,
        handleExpand: PropTypes.func,
        handleSave: PropTypes.func,
        handleSwitchs: PropTypes.func,
    }),
    data: PropTypes.shape({
        expanded: PropTypes.string,
        isTunnelOn: PropTypes.bool,
        buttonDisabled: PropTypes.bool.isRequired,
    }),
};

GeneralPreferences.defaultProps = {
    actions: {
        handleChangeTextVal: () => {},
        handleExpand: () => {},
        handleSave: () => {},
        handleSwitchs: () => {},
    },
    data: {
        expanded: '',
        isTunnelOn: false,
        buttonDisabled: false,
    },
};

export default withStyles(styles)(GeneralPreferences);
