import { MODAL_HEADER_TITLE, DIALOG_ROOT, BLACK_FONT,THREE_DOT_MENU_CSS } from '../../../common/cssConstants';

export const styles = (theme) => ({
    autoSuggestBox: {
        backgroundColor: '#ECECEC',
        borderRadius: '5px',
        color: '#9B9B9B',
        height: '24px',
        padding: '4px 10px',
        width: '100%',
        fontSize: '12px',
        outline: 'none',
        boxShadow: 'none',
        border: 'none',
    },
    autoSuggestContainer: {
        // marginRight: 30,
        width: '50%',
    },
    checkBox: {
        color: '#3B91DF',
        width: '20px',
        height: '20px',
        marginRight: '8px',
        '&>span>svg': {
            fontSize: '20px',
        },
    },
    dialogTitleStyle: MODAL_HEADER_TITLE,
    dialogContainer: {
        // minWidth: '660px',
        // width: '100%',
        overflow: 'visible',
        boxShadow: 'none',
        maxWidth: '981px',
        width: '53%',
        // maxHeight: 375,
        [theme.breakpoints.down('md')]: {
            width: '74%',
        },
    },
    dialogContent: {
        maxHeight: 'calc(100vh - 470px)',
        marginLeft: '30px',
        padding: '0px',
        width: '60%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '15px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(193,193,193)',
          }
    },  
    // dialogRoot: DIALOG_ROOT,
    dialogRoot: {
        '&>:first-child': {...DIALOG_ROOT['&>:first-child']},
        '&>:nth-child(3)': {
            '&>:first-child':{
                ...DIALOG_ROOT['&>:nth-child(3)']['&>:first-child'],
                overflow:"visible"
            }
        }
    },
    executionFilterContainer: {
        display: 'flex',
        marginTop: '6px',
        marginBottom: '30px',
        width: '100%',
    },
    executionTextFieldContainer: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        paddingTop:'39px',
    },
    firstTabSelected: {
        backgroundColor: `${theme.palette.primary.light} !important`,
        borderRight: '0.5px solid #C4C4C4',
        borderBottom: 'none !important',
        borderTop: 'none !important',
        fill: '#F0B637 !important',
    },
    formContainer: {
        backgroundColor: '#FFF',
        display: 'flex',
        width: '100%',
        position: 'relative',
        // paddingBottom: 20,
        height: 430,
        borderRadius: '15px'
    },
    labelContainer: {
        padding: 0,
        color: '#4a4a4a',
        marginLeft: 6,
        textTransform: 'none',
    },
    lastTabSelected: {
        backgroundColor: `${theme.palette.primary.light} !important`,
        borderLeft: '0.5px solid #C4C4C4',
        borderBottom: 'none !important',
        borderTop: 'none !important',
        fill: '#F0B637 !important',
    },
    menuItem: {
        color: 'rgba(56, 55, 55, 0.58)',
        backgroundColor: 'white',
        cursor: 'pointer',
        fontSize: '12px',
        minWidth: 'calc(50% - 45px)',
        padding: '8px 14px',
    },
    noBrowserMessage: {
        fontSize: 13,
        textAlign: 'center',
        width: '100%',
    },
    noBrowserMessages: {
        fontSize: 13,
        textAlign: 'center',
        width: '100%',
        marginTop: 15,
        marginBottom: 8,
    },
    paperURL: {
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.2)',
        marginLeft: '2px',
        width: 'calc(100% - 30px)',
        marginBottom: 20,
    },
    radioButtonGroupContainer: {
        display: 'flex',
        //width: '50%',
    },
    remoteDriverHandlerStyle: {
        alignItems: 'center',
        display: 'flex',
        margin: '20px 0px',
    },
    root: {
        borderBottom: '0.5px solid #C4C4C4',
        borderTop: '0.5px solid #C4C4C4',
        color: theme.palette.primary.dark,
        fontSize: 12,
        lineHeight: '15px',
        backgroundColor: '#F3F3F3',
        opacity: 1,
        maxHeight: 48,
        minHeight: 48,
        fill: '#4A4A4A',
    },
    selectBoxContainer: {
        backgroundColor: '#FFF',
        borderRadius: '3px',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.8)',
        height: 'auto',
        zIndex: 1,
        maxHeight: 152,
        overflowY:'auto'

    },
    selectionContainer: {
        display: 'flex',
        marginBottom: '22px',
        width: '100%',
        padding: '10px 5px 10px 3px',
        alignItems: 'center',
    },
    suggestion: {
        display: 'block',
        '&>div': {
            padding: '4px 16px 0px 16px',
        },
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        maxHeight: '200px',
        overflowY: 'auto',
    },
    tabSelected: {
        backgroundColor: theme.palette.primary.light,
        borderLeft: '0.5px solid #C4C4C4',
        borderRight: '0.5px solid #C4C4C4',
        borderBottom: '0px solid transparent',
        borderTop: '0px solid transparent',
        fill: '#F0B637',
        fontSize: 12,
        lineHeight: '15px',
    },
    tabsContainer: {
        width: 700,
        zIndex: 1300,
    },
    textFieldLabelCustom: {
        fontSize: 12,
        fontWeight:'500',
        color: '#000000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    textFieldInner: {
        fontSize: 12,
        color: 'rgba(56, 55, 55, 0.58)',
        fontWeight: 500,
        margin: '5px 0px',
    },
    textFieldCustom: {
        width: 'calc(100% - 30px)',
        borderBottom: '1px solid #979797',
    },
    textFieldBody: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-between',
        width: 'calc(100% - 30px)',
        borderBottom: '1px solid #979797',
    },
    textFieldIcon: {
        color: '#3B91DF',
        fontSize: 20,
        marginLeft: 10,
    },
    textFieldLabel: {
        color: BLACK_FONT,
        fontWeight: '400',
        fontSize: 12,
        marginTop: 1,
    },
    URLLabel: {
        color: 'rgba(56, 55, 55, 0.58)',
        fontWeight: '400',
        fontSize: 12,
        paddingTop: '10px',
        width: '14%',
    },
    URLInput: {
        marginTop: '0px',
        marginBottom: '14px',
        '& > p':{
            color: 'red',
            fontSize: '10px',
            marginLeft: 0
        },
        '&>:first-child': {
            border: 'solid 0.5px #e9e9e9',
            backgroundColor: '#f8f8f8',
            padding: '2px',
            fontSize: '12px',
            fontWeight: 500,
            width: 545,
        },
    },
    versionListContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        maxHeight: '145px',
        overflowY: 'auto',
        width: '100%',
    },
    versionListText: {
        borderRadius: 7,
        cursor: 'pointer',
        margin: '5px',
        maxHeight: 40,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '10px 8px',
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: '#bbbbbb',
        },
    },
    versionListTextForBrowser: {
        width: '14%',
        textAlign: 'center',
    },
    versionListTextForPlatform: {
        width: '18%',
        textAlign: 'left',
    },
    width100P: {
        width: '100%',
    },
    width50P: {
        width: '50%',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        '&>svg': {
            marginRight: 4
        }
    },
    actionIconsContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        width: 50,
        marginRight: 30,
    },
    iconButtonRoot: {
        fontSize: '1.0rem',
        height: 25,
        width: 25,
        marginLeft: 20
    },
    actionIcons: {
        fontSize: 16,
        color: '#4a4a4a',
        cursor: 'pointer',
    },
    actionButtonsContainer : {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginRight: 30
    },
    removeActionButton: {
        color:'rgb(255, 66, 50)',
        marginTop:'12px',
        textTransform:'none'
    },
    addActionButton: {
        color:'rgb(45, 166, 234)',
        marginTop:'12px',
        textTransform:'none'
    },
    environmentSelect: {
        width: '45%',
        '&>div': {
            '&>select': {
                color: 'rgba(56, 55, 55, 0.58)',
                fontSize: 12,
                fontWeight: '500'
            },
        }
    },
    environmentPopper: {
        display: 'flex',
        // width: '45%',
        borderBottom: '1px solid #979797',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    disabledEnvironmentPopper: {
        display: 'flex',
        opacity: '0.8',
        pointerEvents: 'none',
        borderBottom: '1px solid #979797',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&>p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    environmentTypetext: {
        color: 'rgba(56, 55, 55, 0.58)',
        margin: '5px 0px',
        fontSize: '12px',
        fontWeight: '500',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    environmentTypeContainer: {
        display: 'flex',
        padding: '20px 10px',
        alignItems: 'center',
        width: '100%',
        flexWrap:"wrap",
        justifyContent:'space-around'
        // borderBottom: '1px solid #efefef',
        // borderTop: '1px solid #efefef',
    },
    radioGroup: {
        marginLeft: 10,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'noWrap',
        "& svg": {
            width: "20px",
            height: "20px"
          }
    },
    radioLabel: {
        width: '50%',
        '&>:nth-child(2)': {
            fontSize: 12,
            color: '#636363',
            marginLeft: 5
        }
    },
    stepper: {
        padding: '16px 0px 0 !important',
        overflow: 'hidden',
        marginBottom: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        // marginLeft: -27,
        [theme.breakpoints.only('xs')]: {
            maxWidth: '257px',
        },
    },
    stepVal: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        paddingTop: 13,
        fontSize: 12,
        color: '#9B9B9B',
        pointerEvents: 'all'
        // cursor: 'pointer',
    },
    disableStep: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        paddingTop: 13,
        fontSize: 12,
        color: '#9B9B9B',
        pointerEvents: 'none'
    },
    activeStepVal: {
        borderTop: '3px solid #1168CD',
        paddingTop: 10,
        // cursor: 'pointer',
        color: '#1168CD',
    },
    extGridContainer: {
        width: '103%',
        maxHeight: 150,
        overflowY: 'auto'
    },
    headingDetails:{
        //paddingLeft: '20px',
        fontWeight: '500',
        color: '#494949',
        fontSize: '12px',
    },
    input: {
        '& > label':{
            fontSize: '12px !important'
        },
        '& > p':{
            color: 'red',
            fontSize: '10px'
        },
        '&>div>input': {
            padding: '2px 0px 11px',
            //width: '50%',
            fontSize: '12px',
        },
    },
    addIcon: {
        fontSize: 21,
        color: '#1168cd',
        cursor: 'pointer',
    },
    disableAddBtn:{
        fontSize: 21,
        color: '#9B9B9B',
        pointerEvents: 'none',
    },
    stepContainer: {
        position: 'relative',
        marginRight: 50,
        cursor: 'pointer'
    },
    stepContainerDisabled: {
        position: 'relative',
        marginRight: 50,
        cursor: 'not-allowed',
    },
    visibleTab:{
        display:'none',
    },
    disabled: {
        color: 'rgba(56, 55, 55, 0.58)',
        opacity: '0.8',
        margin: '5px 0px',
        fontSize: '12px',
        fontWeight: '500',
    },
    button:{
        backgroundColor: '#1168cd',
        color: '#FFF',
        '&:hover':{
            backgroundColor: 'rgb(11, 72, 143)'
        }
    },
    headingOverview: {
        fontSize: 18,
        marginTop: 7
    },
    headingStyle: {
        fontWeight: 'bold',
        fontSize: 12,
        padding: '7px 0',
    },
    noSelectionStyle: {
        fontSize: 12,
        color: '#9B9B9B',
        lineHeight: '20px',
    },
    selectionStyle: {
        fontSize: 12,
        lineHeight: '20px',
        width: 190,
        wordBreak:'break-word'
    },
    platformBrowserselection: {
        margin: '8px 0',
        display: 'flex',
        wordBreak: 'break-all',
        alignItems:'center'
    },
    overviewContainer: {
        padding: '18px 20px',
        borderLeft: '2px solid #ECECEC',
        borderBottom: '1px solid #ECECEC',
    },
    contentContainer: {
        padding: '0px 20px',
        height: '63%',
        borderLeft: '2px solid #ECECEC',
        borderBottom: '1px solid #ECECEC',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '10px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(193,193,193)',
          }
    },
    content: {
        // padding: '10px 10px 25px 10px',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '10px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(193,193,193)',
          }
    }, 
    rightContainerStyle: {
        marginRight: '-25px',
        marginTop: '-91px',
        overflow: 'hidden',
    },
    btnContainer: {
        borderLeft: '2px solid #ECECEC',
        height: 125,
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        marginRight: '4px',
    },
    faIcons: {
        fontSize: 17,
        marginTop: -3,
    },
    noDetail: {
        textAlign: 'center',
        fontSize: 12,
        backgroundColor: '#F5F5F5',
        padding: 25,
        marginRight: 30,
    },
    sauceTypePlatform: {
        width: '22%', 
        marginBottom: 20,
        [theme.breakpoints.down('md')]: {
            width: '26%', 
        },
    },
    sauceTypeNotPlatform: {
        width: '22%', 
        marginBottom: 20, 
        marginLeft: 60,
        [theme.breakpoints.down('md')]: {
            width: '26%', 
            marginLeft: 25,
        },
    },
    platformIcon: {
        width: '18px',
        height: '18px',
        marginRight:'4px'
    },
});