// import materials
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { Close, HelpOutline } from '@material-ui/icons';
import { TextField, IconButton, Popper } from '@material-ui/core';

// import packages
// import PropTypes from 'prop-types';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

// import icons
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// custom variable
import deburr from 'lodash/deburr';
import AutoSuggestUtils from '../../utils/AutoSuggestUtils';
import { checkArrayLength, getTextProperties } from '../../utils/utils';

// custom component
import Tooltip from '../Tooltip';

// Styles
const styles = (theme) => ({
    actionButtons: {
        display: 'flex',
        width: '75px',
        justifyContent: 'flex-end',
        height: 14,
        alignItems: 'center',
        paddingTop: 20,
    },
    divider: {
        height: theme.spacing(2),
    },
    iconWraper: {
        marginLeft: 2,
        marginRight: 5,
        height: 28,
        width: 28,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 30,
    },
    root: {
        alignItems: 'center',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
        '&>div': {
            fontSize: 12,
        },
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        maxHeight: '200px',
        overflowY: 'auto',
    },
    suggestionsListPoper: {
        zIndex: 10000000,
        // transform: 'translate3d(590px, 344px, 0px) !important'
    },
    dataSuggestionsListPoper: {
        zIndex: 10000000,
        // transform: 'translate3d(881px, 335px, 0px) !important'
    },
    textField: {
        marginLeft: 0,
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
    },
    inputWidth1: {
        minWidth: '110px',
    },
    inputWidth: {
        minWidth: '156px',
    },
    input: {
        display: 'block',
        maxHeight: 70,
        overflowY: 'auto',
        '&>div': {
            fontSize: 12,
            display: 'block',
            padding: '14px 0',
            '&>div textarea': {
                overflow: 'hidden',
            },
        },
        '&>div>div>textarea::-webkit-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div>textarea:-moz-placeholder': {
            /* Firefox 18- */ fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div>textarea::-moz-placeholder': {
            /* Firefox 19+ */ fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div>textarea:-ms-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div>textarea::placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#9B9B9B',
            opacity: 1,
        },
    },
    fontSize16: {
        fontSize: 16,
    },
    errorItem: {
        fontSize: 12,
        textTransform: 'capitalize',
    },
    helpOutline: {
        color: '#17a2b8',
        width: '20px',
        margin: '4px 5px 0 5px',
    },
    wrapperAutosuggest: {
        width: '33%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    wrapperAutosuggest1: {
        width: 'calc(33% - 68px)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.label;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </span>
                    ) : (
                        <strong key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </strong>
                    );
                })}
            </div>
        </MenuItem>
    );
};

const renderInputComponent = (inputProps) => {
    const { inputRef = () => {}, ref, ...other } = inputProps;
    return (
        <TextField
            fullWidth
            multiline
            aria-label="Suggestion Field"
            InputProps={{
                disableUnderline: true,
                inputRef: (node) => {
                    ref(node);
                    inputRef(node);
                },
            }}
            {...other}
        />
    );
};

class SuggestionBlock extends React.Component {
    state = {
        suggestions_data: [],
        // suggestions_EV: [],
        suggestions: [],
        value: this.props.instr,
        data: this.props.data,
    };

    componentDidMount() {
        const { autoFocus, data, value } = this.props;
        if (!(!value && data) && autoFocus) {
            const input = this.popperNode;
            const { length } = input.value;
            input.focus();
            input.setSelectionRange(length, length);
        }
        if (!value && data && autoFocus) {
            const input = this.popperNodeData;
            const { length } = input.value;
            input.focus();
            input.setSelectionRange(length, length);
        }
        if (!value && data && autoFocus) {
            const input = this.popperNodeExpectedResult;
            const { length } = input.value;
            input.focus();
            input.setSelectionRange(length, length);
        }
    }

    /* Instruction Suggestion functions start */
    // Teach Autosuggest how to calculate suggestions for any given input value.
    onChange = (event, { newValue }) => {
        const { data } = this.state;
        const { actionType, addStepId, updateStepId, id, steps, changingStep } = this.props;
        this.setState({ value: newValue });
        if ((data.length > 0 && newValue.length > 0 && steps && steps.length - 1 === id) || (!steps && changingStep === -1)) {
            if (actionType === 'edit') {
                updateStepId(true, newValue, id, data, 'FTD');
            } else {
                addStepId(true, newValue, data, 'FTD');
            }
        }
    };

    onChangeData = (event, { newValue }) => {
        const { value } = this.state;
        const { actionType, addStepId, updateStepId, id, steps, changingStep } = this.props;
        this.setState({ data: newValue });
        if ((value.length > 0 && newValue.length > 0 && steps && steps.length - 1 === id) || (!steps && changingStep === -1)) {
            if (actionType === 'edit') {
                updateStepId(true, value, id, newValue, 'FTD');
            } else {
                addStepId(true, value, newValue, 'FTD');
            }
        }
    };

    onDataSuggestionsClearRequested = () => {
        this.setState({
            suggestions_data: [],
        });
    };

    onDataSuggestionsFetchRequested = ({ value }) => {
        if (this.props.clickHandler) {
            this.props.clickHandler('edit');
        }
        this.setState({
            suggestions_data: this.getDataSuggestions(value),
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        if (this.props.clickHandler) {
            this.props.clickHandler('edit');
        }
        this.setState({
            suggestions: this.getSuggestions(value),
        });
    };

    getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = (inputValue && inputValue.length) || 0;
        const NLPsuggestions = AutoSuggestUtils.testInstructionSuggestions;
        return inputLength === 0
            ? []
            : checkArrayLength(NLPsuggestions) &&
                  NLPsuggestions.filter((suggestion) => suggestion.label.slice(0, inputLength).toLowerCase() === inputValue);
    };

    /* Instruction Suggestion functions end */

    /* Data Suggestion functions start */
    getDataSuggestions = (value) => {
        const { userVariables } = this.props;
        const inputValue = value && value.trim().toLowerCase();
        const inputLength = inputValue && inputValue.length;
        let count = 0;
        const DataSuggestions = AutoSuggestUtils.testDataSuggestions;
        const suggestionsData = checkArrayLength(DataSuggestions) ? [...DataSuggestions] : [];
        if (checkArrayLength(userVariables)) {
            userVariables.forEach((userVariable) => {
                suggestionsData.push({
                    label: `\${${userVariable.key}}`,
                });
            });
        }

        return inputLength === 0
            ? []
            : checkArrayLength(suggestionsData) &&
                  suggestionsData.filter((suggestion) => {
                      const keep = count < 5 && suggestion.label.toLowerCase().includes(inputValue);

                      if (keep) {
                          count += 1;
                      }

                      return keep;
                  });
    };

    /* Data Suggestion functions end */

    /* autosuggestProps_EV Functions start */
    getSuggestions_EV = (value) => {
        const inputValue = value && deburr(value.trim()).toLowerCase();
        const inputLength = inputValue && inputValue.length;
        let count = 0;
        const EVSuggestions = AutoSuggestUtils.expectedResults;
        return inputLength === 0
            ? []
            : checkArrayLength(EVSuggestions) &&
                  EVSuggestions.filter((suggestion) => {
                      const keep = count < 25 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

                      if (keep) {
                          count += 1;
                      }

                      return keep;
                  });
    };

    handleSuggestionsFetchRequested_EV = ({ value }) => {
        // this.setState({
        //     suggestions_EV: this.getSuggestions_EV(value),
        // });
    };

    handleSuggestionsClearRequested_EV = () => {
        // this.setState({
        //     suggestions_EV: [],
        // });
    };

    /* autosuggestProps_EV Functions end */

    helpOutlineError = (typeError) => {
        const { classes } = this.props;
        return (
            <div>
                {checkArrayLength(typeError) && (
                    <Tooltip
                        testid="helpTooltip"
                        data={
                            <div>
                                {typeError.map((v, i) => (
                                    <div key={i} data-testid="err" className={classes.errorItem}>
                                        {v}
                                    </div>
                                ))}
                            </div>
                        }
                        isElement
                    >
                        <HelpOutline className={classes.helpOutline} aria-label="HelpOutline" id="HelpOutline" />
                    </Tooltip>
                )}
            </div>
        );
    };

    render() {
        const { suggestions_data, suggestions } = this.state;
        const {
            actionType,
            expectedResults,
            data,
            value,
            classes,
            onChangeData,
            id,
            steps,
            onChangeInstr,
            onChangeExpectedResult,
            clickHandler,
            removeNewStep,
            autoFocus,
        } = this.props;
        const actionTypeErrors = getTextProperties(actionType, ['empty string']);
        const dataErrors = getTextProperties(data, ['empty string']);
        const expectedResultsErrors = getTextProperties(expectedResults, ['empty string']);

        // Autosuggest will pass through all these props to the input.
        const inputPropsExpectedResults = {
            placeholder: 'Expected Result',
            value: expectedResults || '',
            onChange: onChangeExpectedResult(id),
            inputRef: (node) => {
                this.popperNodeExpectedResult = node;
            },
            style: {
                marginLeft: 10,
                width: 'calc(100% - 10px)',
            },
        };
        const inputPropsData = {
            placeholder: 'Data',
            autoFocus: !value && data ? autoFocus : false,
            value: data || '',
            onChange: onChangeData(id),
            inputRef: (node) => {
                this.popperNodeData = node;
            },
            style: {
                marginLeft: 10,
                width: 'calc(100% - 10px)',
            },
        };

        const inputProps = {
            placeholder: 'Action',
            autoFocus: !value && data ? false : autoFocus,
            value: value || '',
            onChange: onChangeInstr(id),
            inputRef: (node) => {
                this.popperNode = node;
            },
        };

        return (
            <div className={classes.root}>
                <div className={classes.textField}>
                    <div className={classes.wrapperAutosuggest}>
                        <Autosuggest
                            renderInputComponent={renderInputComponent}
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}
                            theme={{
                                suggestionsList: classes.suggestionsList,
                                suggestion: classes.suggestion,
                                input: `${classes.inputWidth} ${classes.input} `,
                            }}
                            renderSuggestionsContainer={(options) => (
                                <Popper className={classes.suggestionsListPoper} anchorEl={this.popperNode} open={Boolean(options.children)}>
                                    <Paper {...options.containerProps} square>
                                        {options.children}
                                    </Paper>
                                </Popper>
                            )}
                        />
                        {this.helpOutlineError(actionTypeErrors)}
                    </div>

                    <div className={classes.wrapperAutosuggest}>
                        <Autosuggest
                            renderInputComponent={renderInputComponent}
                            suggestions={suggestions_data}
                            onSuggestionsFetchRequested={this.onDataSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onDataSuggestionsClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputPropsData}
                            theme={{
                                suggestionsList: classes.suggestionsList,
                                suggestion: classes.suggestion,
                                input: `${classes.inputWidth} ${classes.input} `,
                            }}
                            renderSuggestionsContainer={(options) => (
                                <Popper className={classes.dataSuggestionsListPoper} anchorEl={this.popperNodeData} open={Boolean(options.children)}>
                                    <Paper {...options.containerProps} square>
                                        {options.children}
                                    </Paper>
                                </Popper>
                            )}
                        />
                        {this.helpOutlineError(dataErrors)}
                    </div>

                    <div className={classes.wrapperAutosuggest1}>
                        <Autosuggest
                            renderInputComponent={renderInputComponent}
                            suggestions={[]}
                            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested_EV}
                            onSuggestionsClearRequested={this.handleSuggestionsClearRequested_EV}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputPropsExpectedResults}
                            theme={{
                                suggestionsList: classes.suggestionsList,
                                suggestion: classes.suggestion,
                                input: `${classes.inputWidth1} ${classes.input} `,
                            }}
                            renderSuggestionsContainer={(options) => (
                                <Popper
                                    className={classes.dataSuggestionsListPoper}
                                    anchorEl={this.popperNodeExpectedResult}
                                    open={Boolean(options.children)}
                                >
                                    <Paper {...options.containerProps} square>
                                        {options.children}
                                    </Paper>
                                </Popper>
                            )}
                        />
                        {this.helpOutlineError(expectedResultsErrors)}
                    </div>

                    <div className={classes.actionButtons}>
                        <IconButton
                            className={classes.iconWraper}
                            onClick={() => clickHandler('add', id, '')}
                            style={{
                                color:
                                    (value && value.trim() === '') || !value || !(checkArrayLength(steps) && steps.length - 1 !== id)
                                        ? '#a9a9a9'
                                        : '#3B91DF',
                            }}
                            disabled={(value && value.trim() === '') || !value || !(checkArrayLength(steps) && steps.length - 1 !== id)}
                        >
                            <FontAwesomeIcon className={classes.fontSize16} icon={faPlusCircle} aria-label="faPlusCircle" id="faPlusCircle" />
                        </IconButton>
                        <IconButton
                            disabled={!steps || (steps && steps.length - 1 === id)}
                            className={classes.iconWraper}
                            onClick={() => {
                                /* actionType === 'edit' ? removeNewStep(id) :  */ removeNewStep(id);
                            }}
                            style={{
                                color: !steps || (steps && steps.length - 1 === id) ? '#a9a9a9' : '#4A4A4A',
                            }}
                        >
                            <Close
                                className={classes.fontSize16}
                                style={{ color: !steps || (steps && steps.length - 1 === id) ? '#a9a9a9' : '#4A4A4A' }}
                                aria-label="closeIcon"
                                id="closeIcon"
                            />
                        </IconButton>
                    </div>
                </div>
            </div>
        );
    }
}

const SuggestionBox = withStyles(styles)(SuggestionBlock);
export { SuggestionBox };
