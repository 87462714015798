// import materials
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

// import packages
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { GeneralActions } from '../../../store/actions';
import { checkArrayLength, checkKeyInObject } from '../../../utils/utils';

// import icons
import { BLACK_FONT } from '../../../common/cssConstants';
import Tooltip from '../../../components/Tooltip';

// Custom Table Cell
const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 18,
    },
}))(TableCell);

const styles = () => ({
    even: {
        backgroundColor: '#fff',
        margin: '0px !important',
        padding: '0px !important',
        height: 25,
    },
    instrContainer: {
        paddingLeft: 5,
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        fontSize: '14px',
        color: BLACK_FONT,
        paddingRight: '2px',
    },
    instrDataContainer: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: '14px',
        paddingLeft: '3px',
    },
    odd: {
        backgroundColor: '#ffffff',
        height: 25,
    },
    root: {
        overflow: 'auto',
        width: '100%',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        '&::-webkit-scrollbar': {
            width: 15,
            height: 15
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(193,193,193)',
        }
    },
    seperatorStyle: {
        display: 'inline-block',
        fontSize: '12px',
        overflow: 'hidden',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '2%',
    },
    snoStyle: {
        color: '#000',
        paddingLeft: 25,
        minWidth: '53px',
        fontSize: '16px',
    },
    stepInstrDataContainer: {
        display: 'flex',
        maxWidth: '90%',
        margin: '10px 0',
        fontSize: '13px',
    },
    stepInstrDataContainerParent: { display: 'inline-block', width: '100%' },
    testname: {
        color: 'blue',
        display: 'flex',
        fontWeight: 400,
        alignItems: 'center',
        width: '100%',
    },
    tableContainer: { tableLayout: 'fixed' },
    tableCell: {
        margin: '0px !important',
        padding: '0px !important',
        textAlign: 'left',
    },
    tableRowData: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 !important',
        padding: '0 !important',
        justifyContent: 'space-between',
        width: '100%',
    },
});

class OriginalTestStepsTable extends React.Component {
    constructor(props) {
        super(props);
        this.isFirst = true;
        this.state = {
            isWidthShort: false,
        };
    }

    UNSAFE_componentWillMount() {
        this.updateSize();
    }

    componentDidUpdate() {
        this.updateSize();
    }

    componentWillUnmount() {
        this.props.queryRemove();
    }

    updateSize = () => {
        const width = window.innerWidth;
        if (width > 1310) {
            if (!this.state.isWidthShort || this.isFirst) {
                this.isFirst = false;
                this.setState({ isWidthShort: true });
            }
        } else if (this.state.isWidthShort || this.isFirst) {
            this.isFirst = false;
            this.setState({ isWidthShort: false });
        }
    };

    render() {
        const { classes, selectedTestCase, query } = this.props;

        let _originalTestSteps =
            checkKeyInObject(selectedTestCase, 'originalTestSteps') && checkArrayLength(selectedTestCase.originalTestSteps)
                ? JSON.parse(JSON.stringify(selectedTestCase.originalTestSteps))
                : [];

        if (query && checkArrayLength(_originalTestSteps)) {
            _originalTestSteps = _originalTestSteps.filter((step) => {
                return (
                    (checkKeyInObject(step, 'instr') && step.instr.toLowerCase().includes(query.toLowerCase())) ||
                    (checkKeyInObject(step, 'data') && step.data.toLowerCase().includes(query.toLowerCase()))
                );
            });
        }

        return (
            <div className={classes.root} style={{ maxHeight: `calc(100vh - 330px)` }}>
                <Table className={classes.tableContainer}>
                    <TableBody>
                        {checkArrayLength(_originalTestSteps)
                            ? _originalTestSteps.map((testStep, index) => (
                                  <TableRow className={`${index % 2 === 0 ? classes.even : classes.odd} tableRow`} key={index.toString()}>
                                      <CustomTableCell className={classes.tableCell}>
                                          <div className={classes.tableRowData}>
                                              <span className={classes.testname}>
                                                  <span className={classes.snoStyle}>{`${index + 1} `}</span>
                                                  <Tooltip data={`${testStep.instr} ${checkKeyInObject(testStep, 'data') && '|'} ${testStep.data}`}>
                                                      {/* This span with style is neccessary for tolltip */}
                                                      <span className={classes.stepInstrDataContainerParent}>
                                                          <span className={classes.stepInstrDataContainer}>
                                                              <span className={classes.instrContainer}>{testStep.instr}</span>
                                                              {checkKeyInObject(testStep, 'data') && (
                                                                  <span className={classes.seperatorStyle}>|</span>
                                                              )}
                                                              {checkKeyInObject(testStep, 'data') && (
                                                                  <span className={classes.instrDataContainer}>{testStep.data}</span>
                                                              )}
                                                          </span>
                                                      </span>
                                                  </Tooltip>
                                              </span>
                                          </div>
                                      </CustomTableCell>
                                  </TableRow>
                              ))
                            : this.props.showEmptyMessageOrLoader()}
                    </TableBody>
                </Table>
            </div>
        );
    }
}
OriginalTestStepsTable.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    selectedTestCase: PropTypes.instanceOf(Object).isRequired,
    showEmptyMessageOrLoader: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        // General Reducer
        query: state.generalReducer.queryValue,
        // Project Reducers
        selectedTestCase: state.projectReducer.selectedTestCase,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OriginalTestStepsTable));
