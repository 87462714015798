import React from 'react';
import config from '../../config';

function LowCodeLogo({ height, width, onClick = ()=>{} }) {
    const { lowCodeLogoFullDark } = config;

    return (
        <>
            <img height={height} width={width} onClick={ onClick } src={lowCodeLogoFullDark} alt="lowcodeLogoblack" />
        </>
    );
}

export default LowCodeLogo;