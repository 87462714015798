export default class JSONTOCsv {
    // JSON to CSV Converter
    static convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            Object.keys(array[i]).forEach((key) => {
                if (line !== '') line += ',';

                line += array[i][key];
            });
            str += `${line}\r\n`;
        }

        return str;
    };

    static CSVFile = (headers, items, fileTitle) => {
        if (headers) {
            items.unshift(headers);
        }

        // Convert Object to JSON
        const jsonObject = JSON.stringify(items);

        const csv = JSONTOCsv.convertToCSV(jsonObject);

        const exportedFilenmae = `${fileTitle}.csv` || 'export.csv';

        var blob = new Blob(["\ufeff", csv]);
       // const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };
}
