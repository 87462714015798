/* eslint-disable no-throw-literal */
import HttpBase from '../../services/HttpBase';

class ExecutionMiddleware extends HttpBase {
    async executeTag(obj, onCompelete = () => {}) {
        const { status } = await this.httpClient.post('/v1/execute_tag', obj);
        if (status === 200) {
            onCompelete();
            return { status: true };
        }
        throw 'unable to execute';
    }

    async getExecution(executionId) {
        const { status, data } = await this.httpClient.get(`/v1/testexecutions/${executionId}/getexecution`);
        if (status === 200) {
            return { data };
        }
        throw 'unable to get execution details';
    }
}

export default new ExecutionMiddleware();
