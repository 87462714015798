/* eslint-disable no-throw-literal */
import HttpBase from '../../services/HttpBase';

class FlowMiddleware extends HttpBase {
    async createNewFlow(obj) {
        try {
            const result = await this.httpClient.post('/blocks/insert', obj);
            if (result.status === 200) {
                return { status: result.status };
            }
            throw 'Unable to create flow';
        } catch (error) {
            let _error = error;
            if (error && error.response && error.response.data && error.response.data.includes('dup key')) {
                _error = 'Duplicate flow name';
            }
            throw _error;
        }
    }

    async cloneFlow(obj) {
        try {
            const result = await this.httpClient.post('/blocks/clone', obj);
            if (result.status === 200) {
                return { status: result.status };
            }
            throw 'Unable to clone flow';
        } catch (error) {
            let _error = error;
            if (error && error.response && error.response.data && error.response.data.includes('dup key')) {
                _error = 'Duplicate flow name';
            }
            throw _error;
        }
    }

    async updateFlow(obj) {
        const { status } = await this.httpClient.post('/blocks/update', obj);
        if (status === 200) {
            return { status };
        }
        throw 'Unable to update flow';
    }

    async deleteFlow(obj) {
        const payload = {
            account_id: obj.accountId,
            project_id: obj.projectId,
            name: obj.name,
        };
        const { status } = await this.httpClient.post('/v1/blocks/delete', payload);
        if (status === 200) {
            return { status };
        }
        throw 'Unable to delete flow';
    }

    async getFlowData(accountId, projectId) {
        let url = `/blocks/getAll/${accountId}`;
        if (projectId) url = `/blocks/getAll/${accountId}/${projectId}`;
        const { data, status } = await this.httpClient.get(url);
        if (data && status === 200) {
            return data;
        }
        throw 'no flow found';
    }

    async cloneVariable(data) {
        const resp = await this.httpClient.post('/v1/uservariable/clone', data);
        if (resp.status === 200) {
            return { status: resp.status, data: resp.data };
        }
        throw 'Unable to clone variable';
    }

    async cloneAllVariables(data) {
        const resp = await this.httpClient.post('/v1/uservariable/clone', data);
        if (resp.status === 200) {
            return { status: resp.status, data: resp.data };
        }
        throw 'Unable to clone variables';
    }
}
export default new FlowMiddleware();
