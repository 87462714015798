import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import React from 'react';

import { AuthActions, ModalActions } from '../../store/actions/index';
import { styles } from './style';
// images
import ErrorSnackBar from '../../components/ErrorSnackBar';
import config from '../../config';

const { companyName, logoUrl, logoLayout } = config;
class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isConfirmed: false,
        };
    }

    componentDidMount() {
        this.sendconfirmation();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { clearAuthError, error } = nextProps;
        const { history } = this.props;
        if (error) {
            this.props.showSnackBar(error);
            clearAuthError();
            setTimeout(() => {
                history.push('signin');
            }, 2000);
        }
    }

    saveConfirmation(confirmation) {
        this.setState({ isConfirmed: confirmation });
    }

    sendconfirmation = async () => {
        // let urlParams = new URLSearchParams(this.props.location.search);
        // let email = urlParams.get('email');
        // let token = urlParams.get('token');
        // const obj = {
        //     email,
        //     token,
        // };
        // this.props.sendconfirmation(obj);
        await this.props.sendconfirmation(this.props.location.search, (confirmation) => {
            this.saveConfirmation(confirmation);
        });
    };

    render() {
        const { classes, emailSendSuccess: response, isSnackBarOpen } = this.props;
        const { isConfirmed } = this.state;
        if (isConfirmed) this.props.history.push('signin');
        let errorComponent = null;
        if (isSnackBarOpen) errorComponent = <ErrorSnackBar />;
        return (
            <Grid container className={classes.root}>
                <Grid item sm={2} md={3} lg={3} />
                <Grid item sm={8} md={6} lg={6} className={classes.formContainer}>
                    <Card className={classes.card}>
                        <CardMedia
                            image={logoUrl}
                            title={companyName}
                            style={
                                logoLayout === 'rectangle'
                                    ? { marginBottom: -2, height: 60, width: 155, backgroundSize: '140px 30px' }
                                    : { marginBottom: -2, height: 70, width: 120, backgroundSize: '95px 45px' }
                            }
                        />
                    </Card>
                    <Paper className={classes.formStyle}>
                        <div className={[classes.formWrapper, response && classes.formWrapperWithError].join(' ')}>
                            {response && <Typography className={classes.responseText}>{response}</Typography>}
                            <Typography variant="h1" className={classes.forgotInstructionText}>
                                Please wait...
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
                {errorComponent}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.authReducer.isLoading,
        error: state.authReducer.error,
        emailSendSuccess: state.authReducer.emailSendSuccess,
        // modal reducer
        isSnackBarOpen: state.modalReducer.isSnackBarOpen,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        sendconfirmation: (...args) => dispatch(AuthActions.sendconfirmation(...args)),
        clearAuthError: (...args) => dispatch(AuthActions.clearAuthError(...args)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Confirm));
