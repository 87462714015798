// import materials
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

// import material

import Typography from '@material-ui/core/Typography';
import { TableSortLabel } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

// import packages
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement, arrayMove, sortableHandle } from 'react-sortable-hoc';
import { TABLES_CSS } from '../../common/cssConstants';
import Tooltip from '../Tooltip';
import { generalModalActions } from '../../store/actions';
import { getFormatedDate, checkKeyInObject, getTestCaseStatusWithout_ws, checkArrayLength } from '../../utils/utils';
import SearchInput from '../../features/modal/GeneralModal/SearchInput';

const styles = (theme) => ({
    dialogContent: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 326px)',
        padding: '0px !important',
        width: '600px',
        '&:first-child': {
            paddingTop: 0,
            width: 530,
        },
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    rootDialogContent: {
        width: '100%',
        '&>div:first-of-type': {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '3px 30px',
            borderBottom: '1px solid rgb(202, 211, 219)',
            borderTop: '1px solid rgb(202, 211, 219)',
            '& > span': {
                display: 'flex',
                alignItems: 'center',
            },
        },
    },
    searchInput: {
        marginRight: '40px',
        width: 250,
    },
    underLine: {
        borderBottom: '0.5px solid #bdc8d3',
        fontSize: '12px',
        '&:after': {
            borderBottom: '0.5px solid #bdc8d3',
            transition: 0,
        },
        '&:before': {
            borderBottom: '0.5px solid #bdc8d3',
            transition: 0,
        },
    },
    cellRole: {
        textAlign: 'left',
        flexDirection: 'row',
        paddingLeft: '30px',
    },
    tableRows: {
        cursor: 'pointer',
        backgroundColor: '#f6f6f6',
    },
    moreIconsBtn: {
        color: '#8898A8',
        height: '15px',
        width: '20px',
        marginRight: '10px',
        cursor: 'not-allowed',
    },
    extraPadding: {
        paddingLeft: '30px !important',
        borderBottom: '2px solid rgba(224, 224, 224, 1)',
    },
    tableCont: {
        maxHeight: 'calc(100vh - 366px)',
        overflowY: 'auto',
        [theme.breakpoints.only('xs')]: {
            overflowX: 'scroll',
            maxWidth: '100%',
        },
    },
    table: {
        width: '100%',
        borderSpacing: 0,
        borderCollapse: 'collapse',
        padding: 0,
        listStyle: 'none',
        overflow: 'hidden',
        margin: '0',
    },
    tableHeadTR: {
        height: '40px',
        color: 'inherit',
        outline: 'none',
        verticalAlign: 'middle',
    },
    tableBodyTR: {
        color: 'inherit',
        outline: 'none',
        verticalAlign: 'middle',
    },
    tableBodyTRData: {
        cursor: 'default',
    },
    tableBodyTRNoTestCaseSelected: {
        cursor: 'default',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    noTestCaseSelectedText: {
        textAlign: 'center',
    },
    tableTH: {
        textAlign: 'left',
        verticalAlign: 'inherit',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        fontWeight: '500',
        height: '40px',
        display: 'inline-flex',
        '& span:first-child': {
            display: 'flex',
        },
    },
    label: {
        fontSize: TABLES_CSS.headingFontSize,
        color: TABLES_CSS.normalFontColor,
        fontWeight: TABLES_CSS.headingFontWeight,
    },
    tableTD: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        verticalAlign: 'inherit',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.8125rem',
        fontWeight: '400',
        height: '40px',
        display: 'inline-flex',
    },
    tableTDColor: {
        width: '30%',
    },
    wrapperTestCaseNameTooltip: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    testCaseNameText: {
        color: TABLES_CSS.normalFontColor,
        fontSize: TABLES_CSS.normalFontSize,
        display: 'inline-block',
    },
    lastRunText: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
    },
    getExecutionStatusText: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
    },
    tableTDWidth: {
        width: '17%',
    },
    wrapperCloseIcon: {
        padding: '0px 0px 0px 10px',
        width: '19%',
        alignItems: 'center',
    },
    closeIcon: {
        fontSize: '16px',
        cursor: 'pointer',
        color: '#4A4A4A',
    },
    wrapperTestCaseCreatedTime: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    createdTimeShow: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
    },
    selectedItem: {
        zIndex: '999999999 !important',
        listStyle: 'none',
        boxShadow: '3px 2px 11px 0px #cdcdcd, -3px -2px 11px 0px #cdcdcd',
    },
    dragAble: {
        '& .dragAble': {
            '& svg': {
                cursor: 'all-scroll !important',
            },
        },
    },
    closeButton: {
        cursor: 'pointer',
    },
    testError: {
        color: 'red',
        marginTop: 5,
        fontSize: 11,
    },
});

const headers = [
    { id: 'testCaseName', numeric: false, disablePadding: true, label: 'Case', styles: { width: '30%' }, stylesInner: { paddingLeft: '20px' } },
    { id: 'createdTime', numeric: false, disablePadding: true, label: 'Created', styles: { width: '17%' } },
    { id: 'lastRun', numeric: false, disablePadding: true, label: 'Last Run', styles: { width: '17%' } },
    { id: 'status', numeric: false, disablePadding: true, label: 'Last Status', styles: { width: '17%' } },
    { id: 'funcs', numeric: false, disablePadding: true, label: '', styles: { width: '19%' } },
];

let savedSelectedTestCasesIds = [];
let savedSelectedTestCases = [];

class MergeModalArrange extends Component {
    state = {
        query: '',
        selectedTestCasesIds: [],
        selectedTestCases: [],
    };

    UNSAFE_componentWillMount() {
        this.setState({
            selectedTestCases: savedSelectedTestCases,
            selectedTestCasesIds: savedSelectedTestCasesIds,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { selectedTestCasesIds } = this.state;

        const _selectedTestCasesIds = [...nextProps.tabsData.merge.state.selectedTestCaseIds];
        const _selectedTestCases = [];
        if (JSON.stringify(_selectedTestCasesIds) !== JSON.stringify(selectedTestCasesIds)) {
            nextProps.testCases.forEach((_case) => {
                if (_selectedTestCasesIds.includes(_case.testCaseId)) _selectedTestCases.push(_case);
            });
            this.setState({
                selectedTestCases: _selectedTestCases,
                selectedTestCasesIds: _selectedTestCasesIds,
            });
        }
    }

    componentWillUnmount() {
        const { selectedTestCases, selectedTestCasesIds } = this.state;

        savedSelectedTestCasesIds = selectedTestCasesIds;
        savedSelectedTestCases = selectedTestCases;
    }

    onSortStart = (/* node, index, collection */) => {};

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { selectedTestCases } = this.state;
        let movedArray = JSON.parse(JSON.stringify(selectedTestCases));
        movedArray = arrayMove(movedArray, oldIndex, newIndex);

        const updatedIds = movedArray.map((_case) => {
            return _case.testCaseId;
        });
        this.updateSelectedIdsInReducer(updatedIds);

        this.setState({
            selectedTestCases: movedArray,
        });
    };

    clearSearch = () => {
        this.setState({ query: '' });
    };

    deSelect = (testCaseId) => {
        const selectedIds = this.props.tabsData.merge.state.selectedTestCaseIds.filter((Id) => {
            return Id !== testCaseId;
        });
        this.updateSelectedIdsInReducer(selectedIds);
    };

    handleSearch = (e) => {
        const query = e.target.value;
        this.setState({ query });
    };

    updateSelectedIdsInReducer = (selectedIds) => {
        const _state = { ...this.props.tabsData.merge.state };
        _state.selectedTestCaseIds = [...selectedIds];
        this.props.updateTabData('merge', { state: _state });
    };

    // Use to Drag only selected steps
    shouldCancelStart = (e) => {
        if (e && e.path && e.path.length) {
            for (let i = 0; i < e.path.length; i++) {
                if (e.path[i] && e.path[i].dataset && e.path[i].dataset.moveable && e.path[i].dataset.moveable.toLowerCase() === 'false') {
                    return true; // Cancel Dragable
                }
            }
        }
        return false; // Allow Dragable
    };

    render() {
        const { classes, tabsData } = this.props;
        const { selectedTestCases, query } = this.state;

        const newCaseName =
            checkKeyInObject(tabsData, 'merge') && checkKeyInObject(tabsData.merge, 'state') && checkKeyInObject(tabsData.merge.state, 'newCaseName')
                ? tabsData.merge.state.newCaseName.trim()
                : '';
        const isDuplicate =
            checkKeyInObject(tabsData, 'merge') && checkKeyInObject(tabsData.merge, 'state') && checkKeyInObject(tabsData.merge.state, 'isDuplicate')
                ? tabsData.merge.state.isDuplicate
                : '';

        const queryCases = checkArrayLength(selectedTestCases)
            ? selectedTestCases.filter((_case) => {
                  return _case && _case.testCaseName && _case.testCaseName.toLowerCase().includes(query.toLowerCase().trim());
              })
            : [];

        const SortableList = SortableContainer(() => {
            const SortableItem = SortableElement(({ value, styles: _styles, _classes, dataMoveAble }) => {
                return (
                    <li className={_classes} style={_styles} data-moveable={dataMoveAble}>
                        {value}
                    </li>
                );
            });
            const disableCondition = false; // Disable sorting if true.
            let dataIndex = -1;
            const dataMerge = [];
            return (
                <div className={classes.tableCont}>
                    <ul className={classes.table}>
                        <li className={classes.tableHeadTR}>
                            {headers.map(
                                (column) =>
                                    (column.status && column.status === 'InActive') || (column.checked && column.checked === true) ? null : (
                                        <span
                                            key={column.id}
                                            className={[classes.borders, classes.extraPadding, classes.tableTH].join(' ')}
                                            style={column.styles ? { ...column.styles } : {}}
                                        >
                                            <span style={column.stylesInner ? { ...column.stylesInner } : {}}>
                                                <TableSortLabel hideSortIcon>
                                                    <Typography variant="body2" className={classes.label} noWrap>
                                                        {column.label}
                                                    </Typography>
                                                </TableSortLabel>
                                            </span>
                                        </span>
                                    ),
                                this,
                            )}
                        </li>
                        {queryCases.forEach((testCase, index) => {
                            const DragHandle = sortableHandle(() => (
                                <span className="dragAble">
                                    <FontAwesomeIcon className={classes.moreIconsBtn} icon={faBars} aria-label="faBars" id="faBars" />
                                </span>
                            ));
                            const data = [];
                            data.push(
                                <span key={`${index}-1`} className={`${classes.cellRole} ${classes.tableTD} ${classes.tableTDColor}`}>
                                    <div className={classes.wrapperTestCaseNameTooltip}>
                                        <DragHandle />
                                        <Typography className={classes.testCaseNameText} noWrap>
                                            <Tooltip data={testCase.testCaseName ? testCase.testCaseName : null}>
                                                {testCase.testCaseName ? testCase.testCaseName : ''}
                                            </Tooltip>
                                        </Typography>
                                    </div>
                                </span>,
                            );
                            data.push(
                                <span key={`${index}-2`} className={`${classes.cellRole} ${classes.tableTD} ${classes.tableTDWidth}`}>
                                    <div className={classes.wrapperTestCaseCreatedTime}>
                                        <Typography className={classes.createdTimeShow} noWrap>
                                            <Tooltip data={getFormatedDate(testCase.createdTime, 'MMM D, YYYY, HH:mm:ss', null)}>
                                                {getFormatedDate(testCase.createdTime, 'MMM D, YYYY', 'N/A')}
                                            </Tooltip>
                                        </Typography>
                                    </div>
                                </span>,
                            );
                            data.push(
                                <span key={`${index}-3`} className={`${classes.cellRole} ${classes.tableTD} ${classes.tableTDWidth}`}>
                                    <div className={classes.wrapperTestCaseNameTooltip}>
                                        <Typography className={classes.lastRunText} noWrap>
                                            {testCase.lastRun && testCase.lastRun !== testCase.createdTime
                                                ? getFormatedDate(testCase.lastRun, 'MMM D, YYYY', 'N/A')
                                                : 'N/A'}
                                        </Typography>
                                    </div>
                                </span>,
                            );
                            data.push(
                                <span key={`${index}-4`} className={`${classes.cellRole} ${classes.tableTD} ${classes.tableTDWidth}`}>
                                    <div className={classes.wrapperTestCaseNameTooltip}>
                                        <Typography noWrap className={classes.getExecutionStatusText}>
                                            <Tooltip data={checkKeyInObject(getTestCaseStatusWithout_ws(testCase), 'msg', 'value', null)}>
                                                {checkKeyInObject(getTestCaseStatusWithout_ws(testCase), 'msg', 'value', 'N/A')}
                                            </Tooltip>
                                        </Typography>
                                    </div>
                                </span>,
                            );
                            data.push(
                                <span
                                    key={`${index}-5`}
                                    className={`${classes.cellRole} ${classes.tableTD} ${classes.tableTDWidth} ${classes.wrapperCloseIcon}`}
                                >
                                    <Close
                                        className={classes.closeIcon}
                                        onClick={() => {
                                            this.deSelect(testCase.testCaseId);
                                        }}
                                        aria-label="closeIcon"
                                        id="closeIcon"
                                    />
                                </span>,
                            );
                            dataIndex++;
                            if (!dataMerge[dataIndex]) {
                                // If value for current index of dataMerge is undefined initialize it with emty array
                                dataMerge[dataIndex] = [];
                            }
                            dataMerge[dataIndex].push(data);
                        })}
                        {dataMerge.length > 0 ? (
                            dataMerge.map((data, index) => {
                                if (disableCondition || query !== '') {
                                    return (
                                        <li
                                            key={`item-${index}`}
                                            className={`${classes.tableRows} ${classes.tableBodyTR} ${classes.tableBodyTRData}`}
                                        >
                                            {data}
                                        </li>
                                    );
                                }
                                return (
                                    <SortableItem
                                        dataMoveAble
                                        key={`item-${index}`}
                                        index={index}
                                        _classes={[classes.tableRows, classes.tableBodyTR, classes.dragAble].join(' ')}
                                        styles={{ cursor: 'default' }}
                                        id={index}
                                        value={data}
                                    />
                                );
                            })
                        ) : (
                            <li key="item-nodata" className={`${classes.tableRows} ${classes.tableBodyTR} ${classes.tableBodyTRNoTestCaseSelected}`}>
                                <Typography className={classes.noTestCaseSelectedText}>No test case selected.</Typography>
                            </li>
                        )}
                    </ul>
                </div>
            );
        });
        return (
            <DialogContent classes={{ root: classes.dialogContent }}>
                <div>
                    <div className={classes.rootDialogContent}>
                        <div>
                            <span>
                                {isDuplicate ? <Typography className={classes.testError}>Test case name already exists.</Typography> : null}
                                {!newCaseName ? <Typography className={classes.testError}>Please enter test case name.</Typography> : null}
                            </span>
                            <SearchInput
                                id="MergeModal_Arrange"
                                placeholder="Search"
                                onChange={this.handleSearch}
                                value={this.state.query}
                                style={{ marginRight: '40px', width: 250 }}
                                clearSearch={this.clearSearch}
                            />
                        </div>
                        <SortableList
                            lockAxis="y"
                            useDragHandle
                            onSortStart={this.onSortStart}
                            shouldCancelStart={this.shouldCancelStart}
                            onSortEnd={this.onSortEnd}
                            helperClass={classes.selectedItem}
                        />
                    </div>
                </div>
            </DialogContent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
        testCases: state.projectReducer.testCases,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MergeModalArrange));
