// Table Header Array
export const headers = [
    {
        id: 'testCaseName',
        sortable: true,
        label: 'CASE',
        tableCellStyles : { marginLeft: 15 },
        width: 'calc(calc(calc(100% / 36) * 10) - 80px)',
    },
    {
        id: 'createdTime',
        sortable: true,
        label: 'UPLOADED',
        width: 'calc(calc(100% / 36) * 4)',
    },
    {
        id: 'sortingStatus',
        sortable: true,
        label: 'GENERATION STATUS',
        width: 'calc(calc(100% / 36) * 6)',
    },
    {
        id: 'lastRun',
        sortable: true,
        label: 'GENERATED LAST RUN',
        width: 'calc(calc(100% / 36) * 6)',
    },
    {
        id: 'Generate',
        label: 'GENERATE',
        width: 'calc(calc(100% / 36) * 3.2)',
        centerV: true,
        color: 'rgb(56 55 55 / 50%)',
    },
    {
        id: 'Execute',
        label: 'EXECUTE',
        width: 'calc(calc(100% / 36) * 2.2)',
        centerV: true,
        color: 'rgb(56 55 55 / 50%)',
    },
    {
        id: 'Clone',
        label: 'CLONE',
        width: 'calc(calc(100% / 36) * 2.2)',
        centerV: true,
        color: 'rgb(56 55 55 / 50%)',
    },
    {
        id: 'Delete',
        label: 'DELETE',
        width: 'calc(calc(100% / 36) * 2.2)',
        centerV: true,
        color: 'rgb(56 55 55 / 50%)',
    },
    {
        id: 'More',
        label: 'MORE',
        width: 'calc(calc(100% / 36) * 2.2)',
        centerV: true,
        color: 'rgb(56 55 55 / 50%)',
    },
];
