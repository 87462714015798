import store from "../store";
import { checkObject } from "../utils";

const getCommanDataForEverySegmentTrack = () => {

  let globalState = store.getState();

  let userData = {}

  if(globalState && checkObject(globalState) && globalState.authReducer && checkObject(globalState.authReducer) && globalState.authReducer.user && checkObject(globalState.authReducer.user)) {
    let user = globalState.authReducer.user;
    userData = {
      account_id : user.accountId,
      role: user.role,
      user_id: user.userId,
      user_name:user.name
    }

  }

  let data = {

    ...userData,
    path :  window?.location?.pathname,
    hostname: window?.location?.hostname

  }
  return data;
}
const isDevMode = () => {
    let dev_mode = false;
    // const hostName = window?.location?.hostname
    // if(hostName && DEV_HOSTNAMES.indexOf(hostName)!==-1) {
    //     dev_mode = false
    // }
    if(process && process.env.NODE_ENV &&  process.env.NODE_ENV === "development") {
      dev_mode = true;
    }


    return dev_mode
};

const getUniqueUserId = (userId,userName='') => {

  let hostName = (window?.location?.hostname) || '';
  return `${userId}_${hostName}`

}

const identify = (userId,data = {}) => {


  if (isDevMode()) {
      return;
  }
    
  if (!window.analytics) {
      return;
  } 

  if (!userId) {
    throw new Error('User Id required to identify segment user');
  }

  let uniqueId = getUniqueUserId(userId,data.name)



  window.analytics.identify(uniqueId, data);

};

const page = () => {
   
  if (isDevMode()) {
    return;
  }  
  if (!window.analytics) {
      throw new Error('analytics error')
  }
  window.analytics.page();
};


 const track = (eventName , data = {}) => {
  
  if (isDevMode()) {
    return;
  }

  if (!window.analytics || !eventName) {
      return ;
  }

  let commanData = getCommanDataForEverySegmentTrack();

  let segmentData = {
    ...commanData,
    ...data
  }

  window.analytics.track(eventName,segmentData);
};

const logout = () => {




  if (!window.analytics) {
    return ;
  }

  window.analytics.reset();

};


export { identify , page ,track ,logout }