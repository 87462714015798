import React from 'react';
import { Hidden as MuiHidden } from '@material-ui/core';
import { useHiddenContext } from './HiddenProvider';

export const Hidden = ({ children, ...props }) => {
    const { width } = useHiddenContext();
    return (
        <MuiHidden {...props} initialWidth={width || undefined}>
            {children}
        </MuiHidden>
    );
};
