// import material
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import packages
import React from 'react';
// import icons
import Lock from '@material-ui/icons/Lock';
import { BLACK_FONT } from '../../../common/cssConstants';
import { CustomSwitch } from '../../../common/miniComponents';

const styles = () => ({
    col: {
        width: 'inherit',
    },
    label: {
        alignSelf: 'center',
        paddingTop: 2,
        '& > p': {
            color: BLACK_FONT,
            fontSize: 12,
        },
    },
    lockIcon: {
        fontSize: 20,
        color: '#979797',
    },
    root: {
        width: '100%',
        marginTop: 0,
    },
    row: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        height: 40,
    },
    value: {
        display: 'flex',
        minWidth: '35px',
        maxWidth: '35px',
        justifyContent: 'flex-start',
    },
});

class ManageHeaderModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            getCloneRowData: JSON.parse(JSON.stringify(props.headers)),
        };
    }

    statusChange = (status, index) => {
        const updatedState = [...this.state.getCloneRowData];
        updatedState[index].status = status === 'Active' ? 'InActive' : 'Active';
        this.setState({ getCloneRowData: updatedState }, () => this.props.changeHeaderStatus(this.state.getCloneRowData));
    };

    selectStatus = (itemStatus, itemIndex) => {
        const { classes } = this.props;
        if (itemStatus === 'Lock') {
            return <Lock className={classes.lockIcon} aria-label="lockIcon" id="lockIcon" />;
        }
        return (
            <CustomSwitch
                checked={itemStatus === 'Active'}
                onClick={() => this.statusChange(itemStatus, itemIndex)}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
        );
    };

    render() {
        const { classes } = this.props;
        const { getCloneRowData } = this.state;
        return (
            <div className={classes.root}>
                {getCloneRowData.map((item, i) => (
                    <div key={i}>
                        <div className={classes.row}>
                            <div className={[classes.col, classes.value].join(' ')}>{this.selectStatus(item.status, i)}</div>
                            <div className={[classes.col, classes.label].join(' ')}>
                                <Typography>{`${item.label}`}</Typography>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
export default withStyles(styles)(ManageHeaderModal);
