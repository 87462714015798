import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { faTrashAlt, faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Disable from '@material-ui/icons/NotInterested';
import File from 'mdi-material-ui/File';
import Restore from '@material-ui/icons/PowerSettingsNew';
import { connect } from 'react-redux';
import CheckBox from '@material-ui/icons/CheckBox';
import Close from '@material-ui/icons/Close';
import Hidden from '@material-ui/core/Hidden';
import CheckBoxBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CircularProgress from '@material-ui/core/CircularProgress';

import { NO_WRAP, TABLES_CSS, BLACK_FONT } from '../../../common/cssConstants';
import { EnhancedTableHead } from '../../../components/EnhancedTable';
import { ProjectActions, GeneralActions, ModalActions, TestCaseActions } from '../../../store/actions';
import {
    getCountAvatar,
    getTestCaseStatus,
    checkArrayLength,
    checkKeyInObject,
    sort,
    checkObject,
    getFormatedDate,
    resizeAllCols,
    getRelativeTime,
} from '../../../utils/utils';
import CaseStatus from '../../projectDetails/Case/CaseStatus';
import { TestCaseMenuOptions } from '../../projectDetails/Case/CaseMenuOptions';
import { TestCaseUtils } from '../../../utils';
import WSService from '../../../services/WSService';
import DeleteAlertModal from '../../modal/Delete/DeleteAlertModal';
import Tooltip from '../../../components/Tooltip';
import TestStepTagModal from '../../../components/CustomModal/TestStepTagModal';
import TableFooter from '../../../common/TableFooter';
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import TableCell from '../../../components/Table/TableCell';
import TableRow from '../../../components/Table/TableRow';
import { CustomSwitch } from '../../../common/miniComponents';

const styles = (theme) => ({
    checkBox: {
        height: 25,
        width: 25,
    },
    actionPadding: {
        [theme.breakpoints.only('xs')]: {
            minWidth: '80px',
        },
    },
    checkBoxIcon: {
        color: '#0092e5',
        fontSize: '25px',
        marginLeft: '-4px',
    },
    headerContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 35,
        marginTop: -35,
        width: 'calc(100% - 55px)',
    },
    root: {
        width: 'calc(100% - 40px)',
        margin: '0px 20px',
        marginTop: '10px',
        borderRadius: 0,
        boxShadow: 'none',
    },
    tableWrapper: {
        overflowX: 'auto',
        overflowY: 'auto',
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    },
    avatar: {
        minWidth: '20px',
        height: '20px',
        borderRadius: '20px',
        backgroundColor: '#3b91df',
        color: '#fff',
        textAlign: 'center',
        marginRight: '8px',
        fontSize: '13px',
        lineHeight: 1.7,
        padding: '0 4px',
        display: 'inline-block',
    },
    selectedText: {
        color: BLACK_FONT,
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '3px',
    },
    multiCloseIcon: {
        color: '#4a4a4a',
        cursor: 'pointer',
        fontSize: 22,
        marginLeft: 10,
    },
    multiIcon: {
        color: '#3b91df',
        fontSize: 22,
        cursor: 'pointer',
        marginRight: 5,
    },
    multiActionsBar: {
        display: 'flex',
        alignItems: 'center',
        zIndex: 999,
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '16.6%',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    fileIcon: {
        color: '#ECECEC',
        fontSize: '50px',
        marginLeft: '10px',
        marginRight: '5px',
    },
    notAvailable: {
        fontSize: '18px',
        color: '#8498AA',
        fontWeight: 'bold',
        marginTop: '10px',
    },
    noDataText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '200px',
    },
    wrapperTable: {
        borderRadius: '5px',
        marginBottom: '10px',
    },
    maxHeight: {
        maxHeight: 'calc(100vh - 163px)',
        height: 'auto',
    },
    tableRows: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.07) !important',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    tagIconButton: {
        marginRight: 5,
        padding: 0,
        minWidth: 1,
        minHeight: 1,
    },
    tagIconStyle: {
        width: '1em !important',
        height: '1em !important',
        fontSize: '18px',
    },
    tagIcon: {
        color: '#0F94E3',
    },
    noWrap: NO_WRAP,
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    circularProgressIcon: {
        position: 'absolute',
        color: '#4885ed',
    },
});

const headers = [
    { id: 'testCaseName', sortable: true, numeric: false, disablePadding: true, label: 'Case', styles: { paddingLeft: 10 }, width: '25%' },
    {
        id: 'projectName',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Project',
        styles: { paddingLeft: 10 },
        width: 'calc(75% / 5)',
    },
    {
        id: 'createdTime',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Created',
        styles: { paddingLeft: 10 },
        width: 'calc(75% / 5)',
    },
    {
        id: 'lastRun',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Last Generated',
        styles: { paddingLeft: 10 },
        width: 'calc(75% / 5)',
    },
    {
        id: 'sortingStatus',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Last Status',
        styles: { paddingLeft: 10 },
        width: 'calc(75% / 5)',
    },
    { id: 'funcs', sortable: false, numeric: false, disablePadding: true, label: 'Actions', styles: { paddingLeft: 10 }, width: 'calc(75% / 5)' },
];
class TestCase extends React.Component {
    constructor(props) {
        super(props);
        this.colsWidth = {};
        this.state = {
            order: 'asc',
            orderBy: 'testCaseName',
            page: 0,
            rowsPerPage: 20,
            isCustomPopupOpen: false, // to toggle tag popup
            selectedCases: {},
            selectedDisabledCases: {},
            selectedEnabledCases: {},
            openDeleteDialog: false,
            openDisableDialog: false,
            openEnableDialog: false,
            selectedTestCaseNames: [], // for deleting multiple casess
            isShiftDown: false,
            lastSelectedTestCase: null,
            showDisabledTestCases:false,
        };
        props.updateTitle('Cases');
    }

    componentDidMount() {
        // Add event listeners for selecting multiple checkboxes
        document.addEventListener('keyup', this.handleKeyUp, false);
        document.addEventListener('keydown', this.handleKeyDown, false);

        const { projects, getProjectByIdAndName } = this.props;
        if (!checkArrayLength(projects)) {
            getProjectByIdAndName();
        }
        this.getTestCaseWithAccount();
        const caseSettingRows = localStorage.getItem('rowsCasePerPage');
        if (caseSettingRows) {
            this.updateState({
                rowsPerPage: parseInt(caseSettingRows, 10),
                selectedCases: {},
                selectedDisabledCases: {},
                selectedEnabledCases: {},
            });
        }

        this.callReceiveWs();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { query } = nextProps;
        if (query && query.length) {
            this.setState({ lastSelectedTestCase: null });
        }
    }

    componentDidUpdate() {
        const { testCasesWithAccount } = this.props;
        const { lastSelectedTestCase } = this.state;
        if (lastSelectedTestCase === null && checkArrayLength(testCasesWithAccount)) {
            const { currentPageCases } = this.getCurrentPageCases();
            if (checkArrayLength(currentPageCases)) {
                this.updateState({ lastSelectedTestCase: { testCaseId: currentPageCases[0].testCaseId } });
            }
        }
        resizeAllCols(this.colsWidth);
    }

    componentWillUnmount() {
        // Remove event listeners for selecting multiple checkboxes
        document.removeEventListener('keyup', this.handleKeyUp);
        document.removeEventListener('keydown', this.handleKeyDown);
        this.props.queryRemove();
        this.props.clearSnackBar();
    }

    getTestCaseWithAccount = () => {
        const { getTestCasesWithAccount, user } = this.props;
        getTestCasesWithAccount(user.accountId, user.userId);
    };

    getCurrentPageCases = (showDisabledTestCases = false ) => {
        const { query = '', testCasesWithAccount, user } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;
        let testCaseArray = testCasesWithAccount;
        if (query && checkArrayLength(testCaseArray)) {
            testCaseArray = testCaseArray.filter(
                (testCase) => 
                (checkKeyInObject(testCase, 'testCaseName', 'value', '').toLowerCase().includes(query.toLowerCase()) ||
                    checkKeyInObject(testCase, 'projectName', 'value', '').toLowerCase().includes(query.toLowerCase()))
            );
        }
        testCaseArray = checkArrayLength(testCaseArray)
            ? testCaseArray.map((singleTestCase) => {
                  return {
                      ...singleTestCase,
                      sortingStatus: this.getCaseStatus(singleTestCase),
                      lastRun:
                          singleTestCase.lastRun && singleTestCase.lastRun !== singleTestCase.createdTime
                              ? checkKeyInObject(user, 'isRelativeDateFormat')
                                  ? getRelativeTime(singleTestCase.lastRun)
                                  : getFormatedDate(singleTestCase.lastRun, 'DD MMM YYYY', '')
                              : '',
                  };
              })
            : [];

        testCaseArray = showDisabledTestCases ? testCaseArray.filter( (testCase)=> testCase.disabledStatus) : testCaseArray.filter( (testCase)=> !testCase.disabledStatus);
        const currentPageCases =
            checkArrayLength(testCaseArray) &&
            sort(testCaseArray, order, orderBy, orderBy === 'lastRun' || orderBy === 'createdTime' ? 'time' : '').slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            );
        return { currentPageCases, testCaseArray };
    };

    getTestCaseArray = () => {
        const { selectedCases, order, orderBy } = this.state;
        const { testCasesWithAccount } = this.props;
        const selectedTestCaseArray = [];
        if (checkObject(selectedCases) && checkArrayLength(testCasesWithAccount)) {
            Object.keys(selectedCases).forEach((s_case) => {
                testCasesWithAccount.some((tc) => {
                    if (Number(tc.testCaseId) === Number(s_case)) {
                        selectedTestCaseArray.push(tc);
                        return true;
                    }
                    return false;
                });
            });
        }
        return sort(selectedTestCaseArray, order, orderBy, orderBy === 'lastRun' || orderBy === 'createdTime' ? 'time' : '');
    };

    getCaseStatus = (testCase) => {
        return getTestCaseStatus(testCase, this.props.wsRunningCaseData).msgSimple;
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleTagButtonRef = (node) => {
        this.anchorEl = node;
    };

    handleClose = (event) => {
        if (this.anchorEl && this.anchorEl.contains(event.target)) {
            return;
        }
        this.handleTagPopupToggle(false);
    };

    handleTagPopupToggle = (flag) => {
        this.setState({ isCustomPopupOpen: flag });
    };

    handelSelectCase = (caseId, isDisable) => {
        const { selectedCases, selectedDisabledCases, selectedEnabledCases } = this.state;
        const _selectedCases = JSON.parse(JSON.stringify(selectedCases));
        const _selectedDisabledCases = JSON.parse(JSON.stringify(selectedDisabledCases));
        const _selectedEnabledCases = JSON.parse(JSON.stringify(selectedEnabledCases));
        if (_selectedCases[caseId]) {
            delete _selectedCases[caseId];
        } else {
            _selectedCases[caseId] = true;
        }
        if (_selectedDisabledCases[caseId]) {
            delete _selectedDisabledCases[caseId];
        } else if (isDisable) {
            _selectedDisabledCases[caseId] = true;
        }
        if (_selectedEnabledCases[caseId]) {
            delete _selectedEnabledCases[caseId];
        } else if (!isDisable) {
            _selectedEnabledCases[caseId] = true;
        }
        this.setState({
            selectedCases: _selectedCases,
            selectedDisabledCases: _selectedDisabledCases,
            selectedEnabledCases: _selectedEnabledCases,
            lastSelectedTestCase: { testCaseId: caseId },
        });
    };

    handleKeyUp = (e) => {
        if (e.key === 'Shift' && this.state.isShiftDown) {
            this.setState({ isShiftDown: false });
        }
    };

    handleKeyDown = (e) => {
        if (e.key === 'Shift' && !this.state.isShiftDown) {
            this.setState({ isShiftDown: true });
        }
    };

    updateSelectedCaseState = (caseId, isDisable, selectedCases, selectedDisabledCases, selectedEnabledCases, isForDelete = null) => {
        if ((isForDelete === null && selectedCases[caseId]) || isForDelete) {
            delete selectedCases[caseId];
        } else {
            selectedCases[caseId] = true;
        }
        if ((isForDelete === null && selectedDisabledCases[caseId]) || isForDelete) {
            delete selectedDisabledCases[caseId];
        } else if (isDisable) {
            selectedDisabledCases[caseId] = true;
        }
        if ((isForDelete === null && selectedEnabledCases[caseId]) || isForDelete) {
            delete selectedEnabledCases[caseId];
        } else if (!isDisable) {
            selectedEnabledCases[caseId] = true;
        }
    };

    handleSelectWithShift = (testCaseId) => {
        const { selectedCases, selectedDisabledCases, selectedEnabledCases, lastSelectedTestCase } = this.state;
        const { currentPageCases } = this.getCurrentPageCases();

        let startingIndex = currentPageCases.findIndex((_case) => _case.testCaseId === lastSelectedTestCase.testCaseId);
        let endIndex = currentPageCases.findIndex((_case) => _case.testCaseId === testCaseId);

        const _selectedCases = JSON.parse(JSON.stringify(selectedCases));
        const _selectedDisabledCases = JSON.parse(JSON.stringify(selectedDisabledCases));
        const _selectedEnabledCases = JSON.parse(JSON.stringify(selectedEnabledCases));

        if (endIndex !== -1 && startingIndex !== -1) {
            if (startingIndex > endIndex) {
                const temp = startingIndex;
                startingIndex = endIndex;
                endIndex = temp;
            }
            if (!checkKeyInObject(_selectedCases, `${testCaseId}`, 'value', false)) {
                for (let i = startingIndex; i <= endIndex; i++) {
                    const caseId = currentPageCases[i].testCaseId;
                    const isDisable = currentPageCases[i].disabledStatus;
                    this.updateSelectedCaseState(caseId, isDisable, _selectedCases, _selectedDisabledCases, _selectedEnabledCases, false);
                }
            } else {
                for (let i = startingIndex; i <= endIndex; i++) {
                    const caseId = currentPageCases[i].testCaseId;
                    this.updateSelectedCaseState(caseId, null, _selectedCases, _selectedDisabledCases, _selectedEnabledCases, true);
                }
            }
            this.setState({
                selectedCases: _selectedCases,
                selectedDisabledCases: _selectedDisabledCases,
                selectedEnabledCases: _selectedEnabledCases,
                lastSelectedTestCase: { testCaseId },
            });
        }
    };

    select_deSelectAllCurrentPage = (cases, isSelect) => {
        const { selectedCases, selectedDisabledCases, selectedEnabledCases } = this.state;
        const { testCasesWithAccount } = this.props;
        const _selectedCases = JSON.parse(JSON.stringify(selectedCases));
        const _selectedDisabledCases = JSON.parse(JSON.stringify(selectedDisabledCases));
        const _selectedEnabledCases = JSON.parse(JSON.stringify(selectedEnabledCases));
        if (testCasesWithAccount) {
            testCasesWithAccount.forEach((_case) => {
                if (!isSelect && _selectedCases[_case.testCaseId]) {
                    delete _selectedCases[_case.testCaseId];
                } else if (isSelect) {
                    _selectedCases[_case.testCaseId] = true;
                }
                if (!isSelect && _selectedDisabledCases[_case.testCaseId]) {
                    delete _selectedDisabledCases[_case.testCaseId];
                }
                if (!isSelect && _selectedEnabledCases[_case.testCaseId]) {
                    delete _selectedEnabledCases[_case.testCaseId];
                }
                if (isSelect) {
                    if (_case.disabledStatus) {
                        _selectedDisabledCases[_case.testCaseId] = true;
                    } else {
                        _selectedEnabledCases[_case.testCaseId] = true;
                    }
                }
            });
        }
        this.setState({
            selectedCases: _selectedCases,
            selectedDisabledCases: _selectedDisabledCases,
            selectedEnabledCases: _selectedEnabledCases,
        });
    };

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleChangePage = (page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        localStorage.setItem('rowsCasePerPage', event.target.value);
        this.setState({ rowsPerPage: event.target.value });
    };

    deleteSelectedCases = async (isConfirm) => {
        if (isConfirm) {
            const response = await this.props.deleteTestCase(
                Object.keys(this.state.selectedCases).map((item) => parseInt(item, 10)),
                'plan',
            );
            if (response) {
                this.setState({
                    openDeleteDialog: false,
                    selectedCases: {},
                    selectedDisabledCases: {},
                    selectedEnabledCases: {},
                });
            }
        } else {
            this.setState({ openDeleteDialog: false });
        }
    };

    disableSelectedCases = async (isConfirm, isDisable) => {
        if (isConfirm) {
            const onComplete = () => {
                this.setState({
                    openDisableDialog: false,
                    openEnableDialog: false,
                    selectedCases: {},
                    selectedDisabledCases: {},
                    selectedEnabledCases: {},
                });
            };

            if (isDisable) {
                const response = await this.props.disableTestCase(
                    Object.keys(this.state.selectedEnabledCases).map((item) => parseInt(item, 10)),
                    1,
                    'plan',
                );
                if (response) onComplete();
            } else {
                const response = await this.props.disableTestCase(
                    Object.keys(this.state.selectedDisabledCases).map((item) => parseInt(item, 10)),
                    0,
                    'plan',
                );
                if (response) onComplete();
            }
        } else {
            this.setState({ openDisableDialog: false, openEnableDialog: false });
        }
    };

    callReceiveWs = async () => {
        const actions = {};

        const data = { callingFrom: 'TestCase' };

        this.ws = await WSService.getWebSocketInstance();

        // Invoking web socket receiver
        TestCaseUtils.receiveWSEvents(this.ws, actions, data);
    };

    changeToggle = (disabledCasesflag) => {
        this.setState({ showDisabledTestCases: disabledCasesflag });
    }

    render() {
        const { classes, num_of_test_cases, user, openTaskPanel } = this.props;
        const {
            order,
            orderBy,
            rowsPerPage,
            page,
            isCustomPopupOpen,
            selectedCases,
            selectedDisabledCases,
            selectedEnabledCases,
            openDeleteDialog,
            openDisableDialog,
            openEnableDialog,
            isShiftDown,
            showDisabledTestCases,
        } = this.state;

        const { currentPageCases, testCaseArray } = this.getCurrentPageCases(showDisabledTestCases);


        const selectedCount = Object.keys(selectedCases).length;
        const selectedDisabledCount = Object.keys(selectedDisabledCases).length;
        const selectedEnabledCount = Object.keys(selectedEnabledCases).length;
        let _selected = 0;
        const disabledRowContentColor = '#BAC4CE';
        for (let i = 0; i < currentPageCases.length; i++) {
            const _case = currentPageCases[i];
            if (selectedCases[_case.testCaseId]) {
                _selected++;
            }
        }
        return (
            <div>
                {isCustomPopupOpen && (
                    <TestStepTagModal
                        open={isCustomPopupOpen}
                        handleClose={this.handleClose}
                        data={{
                            name: 'plan',
                            selectedTestCase: this.getTestCaseArray(),
                        }}
                    />
                )}
                <div style={{ marginLeft: openTaskPanel ? '32px' : '10px' }} className={classes.headerContainer}>
                    <div style={{ zIndex: 2 }}>
                    <span style = {{marginRight: '11px' }}>Show Disabled Test Cases</span>
                        <Tooltip data="Show Disabled Cases">
                            <CustomSwitch checked={showDisabledTestCases} onChange={() => { this.changeToggle(!showDisabledTestCases)}}  value="isShowDisabled" />
                        </Tooltip>
                    </div>
                </div>

                <div className={classes.root}>
                    {selectedCount > 0 ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#f0f7ea',
                                padding: '0 15px',
                                height: 45,
                                justifyContent: 'space-between',
                                boxShadow:
                                    '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span className={classes.avatar}> {selectedCount} </span>
                                <Typography className={classes.selectedText}>
                                    {selectedCount === 1 ? 'Case is selected' : 'Cases are selected'}
                                </Typography>
                                <Close
                                    className={classes.multiCloseIcon}
                                    onClick={() => {
                                        this.select_deSelectAllCurrentPage(testCaseArray, false);
                                    }}
                                    aria-label="closeIcon"
                                    id="closeIcon"
                                />
                            </div>
                            <span className={classes.multiActionsBar}>
                                
                                <Tooltip data={selectedEnabledCount ? `Disable Test Case${selectedEnabledCount > 1 ? 's' : ''}` : null}>
                                    <Disable
                                        className={classes.multiIcon}
                                        style={
                                            selectedEnabledCount <= 0
                                                ? {
                                                      color: '#c4c4c4',
                                                      cursor: 'not-allowed',
                                                  }
                                                : {}
                                        }
                                        onClick={() => {
                                            if (selectedEnabledCount > 0) {
                                                const temp = [];
                                                testCaseArray.forEach((_case) => {
                                                    if (checkKeyInObject(selectedCases, _case.testCaseId)) {
                                                        temp.push(_case.testCaseName);
                                                    }
                                                });
                                                this.setState({
                                                    openDisableDialog: true,
                                                    selectedTestCaseNames: temp,
                                                });
                                            }
                                        }}
                                        aria-label="disableIcon"
                                        id="disableIcon"
                                    />
                                </Tooltip>
                                <Tooltip data={selectedDisabledCount ? `Enable Case${selectedDisabledCount > 1 ? 's' : ''}` : null}>
                                    <Restore
                                        className={classes.multiIcon}
                                        style={
                                            selectedDisabledCount <= 0
                                                ? {
                                                      color: '#c4c4c4',
                                                      cursor: 'not-allowed',
                                                  }
                                                : {}
                                        }
                                        onClick={() => {
                                            if (selectedDisabledCount > 0) {
                                                const temp = [];
                                                testCaseArray.forEach((_case) => {
                                                    if (checkKeyInObject(selectedCases, _case.testCaseId)) {
                                                        temp.push(_case.testCaseName);
                                                    }
                                                });
                                                this.setState({
                                                    openEnableDialog: true,
                                                    selectedTestCaseNames: temp,
                                                });
                                            }
                                        }}
                                        aria-label="restoreIcon"
                                        id="restoreIcon"
                                    />
                                </Tooltip>
                                <Tooltip data={`Delete Test Case${selectedCount > 1 ? 's' : ''}`}>
                                    <FontAwesomeIcon
                                        className={classes.multiIcon}
                                        style={{ fontSize: 17 }}
                                        icon={faTrashAlt}
                                        aria-label="faTrashAlt"
                                        id="faTrashAlt"
                                        onClick={() => {
                                            const temp = [];
                                            testCaseArray.forEach((_case) => {
                                                if (checkKeyInObject(selectedCases, _case.testCaseId)) {
                                                    temp.push(_case.testCaseName);
                                                }
                                            });
                                            this.setState({
                                                openDeleteDialog: true,
                                                selectedTestCaseNames: temp,
                                            });
                                        }}
                                    />
                                </Tooltip>
                            </span>
                        </div>
                    ) : null}
                    <div
                        style={
                            checkArrayLength(testCaseArray) || this.props.isCaseLoading
                                ? { minHeight: `calc(100vh - ${(selectedCount ? 45 : 0) + 163}px)` }
                                : {}
                        }
                        className={`${classes.wrapperTable} ${classes.maxHeight}`}
                    >
                        <div className={classes.tableWrapper}>
                            {checkArrayLength(testCaseArray) ? (
                                <Table>
                                    <Hidden only={['xs']}>
                                        <EnhancedTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={this.handleRequestSort}
                                            headers={headers}
                                            onSelectAllClick={() => {
                                                this.select_deSelectAllCurrentPage(currentPageCases, _selected !== currentPageCases.length);
                                            }}
                                            currentPageData={currentPageCases}
                                            numSelected={_selected}
                                            rowCount={currentPageCases.length}
                                            showSelectBtn
                                            colsWidth={this.colsWidth}
                                            resizeable
                                        />
                                    </Hidden>
                                    <Hidden smUp>
                                        <EnhancedTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={this.handleRequestSort}
                                            headers={headers}
                                            onSelectAllClick={() => {
                                                this.select_deSelectAllCurrentPage(currentPageCases, _selected !== currentPageCases.length);
                                            }}
                                            currentPageData={currentPageCases}
                                            numSelected={_selected}
                                            rowCount={currentPageCases.length}
                                            showSelectBtn
                                            colsWidth={this.colsWidth}
                                        />
                                    </Hidden>
                                    <TableBody style={{ maxHeight: `calc(100vh - ${(selectedCount > 0 ? 45 : 0) + 202}px)` }}>
                                        {currentPageCases.map((singleTestCase) => {
                                            const conditionalColor = singleTestCase.disabledStatus
                                                ? disabledRowContentColor
                                                : TABLES_CSS.normalFontColor;
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={singleTestCase.testCaseId}
                                                    className={classes.tableRows}
                                                    style={{ height: '40px', backgroundColor: TABLES_CSS.rowColor }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="none"
                                                        className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                        width={headers[0].width}
                                                        data-resize={`element${0}_c`}
                                                    >
                                                        <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        if (isShiftDown) {
                                                                            this.handleSelectWithShift(singleTestCase.testCaseId);
                                                                        } else {
                                                                            this.handelSelectCase(
                                                                                singleTestCase.testCaseId,
                                                                                singleTestCase.disabledStatus,
                                                                            );
                                                                        }
                                                                    }}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.checkBox}
                                                                    style={{ marginRight: '5px' }}
                                                                    tabIndex={-1}
                                                                >
                                                                    {selectedCases[singleTestCase.testCaseId] ? (
                                                                        <CheckBox
                                                                            className={classes.checkBoxIcon}
                                                                            aria-label="checkBoxIcon"
                                                                            id="checkBoxIcon"
                                                                        />
                                                                    ) : (
                                                                        <CheckBoxBlank
                                                                            className={classes.checkBoxIcon}
                                                                            style={{ color: '#bac5d0' }}
                                                                            aria-label="checkBoxBlankIcon"
                                                                            id="checkBoxblankIcon"
                                                                        />
                                                                    )}
                                                                </IconButton>
                                                                <Typography
                                                                    noWrap
                                                                    className={classes.noWrap}
                                                                    style={{
                                                                        color: conditionalColor,
                                                                        fontSize: TABLES_CSS.normalFontSize,
                                                                        display: 'inline-block',
                                                                    }}
                                                                >
                                                                    <Tooltip data={singleTestCase.testCaseName ? singleTestCase.testCaseName : null}>
                                                                        {singleTestCase.testCaseName}
                                                                    </Tooltip>
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                        width={headers[1].width}
                                                        data-resize={`element${1}_c`}
                                                    >
                                                        <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                            <Typography
                                                                noWrap
                                                                className={classes.noWrap}
                                                                style={{
                                                                    color: conditionalColor,
                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                    display: 'inline-block',
                                                                }}
                                                            >
                                                                <Tooltip data={singleTestCase.projectName ? singleTestCase.projectName : null}>
                                                                    {singleTestCase.projectName}
                                                                </Tooltip>
                                                            </Typography>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                        width={headers[2].width}
                                                        data-resize={`element${2}_c`}
                                                    >
                                                        <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                            <Typography
                                                                noWrap
                                                                className={classes.noWrap}
                                                                style={{
                                                                    color: conditionalColor,
                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                    display: 'inline-block',
                                                                }}
                                                            >
                                                                {checkKeyInObject(user, 'isRelativeDateFormat')
                                                                    ? getRelativeTime(singleTestCase.createdTime)
                                                                    : getFormatedDate(singleTestCase.createdTime, 'DD MMM YYYY', '')}
                                                            </Typography>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                        width={headers[3].width}
                                                        data-resize={`element${3}_c`}
                                                    >
                                                        <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                            <Typography
                                                                noWrap
                                                                className={classes.noWrap}
                                                                style={{
                                                                    color: conditionalColor,
                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                    display: 'inline-block',
                                                                }}
                                                            >
                                                                {singleTestCase.lastRun}
                                                            </Typography>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                        width={headers[4].width}
                                                        data-resize={`element${4}_c`}
                                                    >
                                                        <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                            <Typography
                                                                noWrap
                                                                className={classes.noWrap}
                                                                style={{
                                                                    color: conditionalColor,
                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                    display: 'block',
                                                                }}
                                                            >
                                                                {/* {singleTestCase.sortingStatus} */}
                                                                <CaseStatus
                                                                    testCase={singleTestCase}
                                                                    customStyles={{
                                                                        fontSize: TABLES_CSS.normalFontSize,
                                                                        color: conditionalColor,
                                                                    }}
                                                                    is_nowrap
                                                                />
                                                            </Typography>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                        width={headers[5].width}
                                                        data-resize={`element${5}_c`}
                                                    >
                                                        <div style={{ paddingLeft: '10px', width: '100%' }} className={classes.actionPadding}>
                                                            <TestCaseMenuOptions
                                                                callingFrom="plan"
                                                                testCase={singleTestCase}
                                                                iconButtonDisabled={selectedCases[singleTestCase.testCaseId]}
                                                                MoreVertStyle={
                                                                    selectedCases[singleTestCase.testCaseId] ? { color: disabledRowContentColor } : {}
                                                                }
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            ) : (
                                <div className={`${classes.noDataText} ${classes.maxHeight}`}>
                                    {!this.props.isCaseLoading ? (
                                        <>
                                            <File className={classes.fileIcon} />
                                            <Typography className={classes.notAvailable} variant="subtitle1">
                                                Case does not exist!
                                            </Typography>
                                        </>
                                    ) : (
                                        <CircularProgress className={classes.circularProgressIcon} size={50} thickness={3} />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <TableFooter
                        count={testCaseArray.length || 0}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        page={page}
                        handleChangePage={this.handleChangePage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        applyCustomClasses
                        labelRowsPerPage="Cases per page:"
                        labelForCounter="Test Cases"
                        countAvatar={getCountAvatar(num_of_test_cases)}
                        containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                    />
                    {openDisableDialog ? (
                        <DeleteAlertModal
                            deleteButton="Danger"
                            open={openDisableDialog}
                            handleClose={(isConfirm) => {
                                this.disableSelectedCases(isConfirm, true);
                            }}
                            modalfor="disableCases"
                            name="Test Case"
                            title="Test Case Name"
                            list={this.state.selectedTestCaseNames}
                        />
                    ) : null}
                    {openEnableDialog ? (
                        <DeleteAlertModal
                            open={openEnableDialog}
                            handleClose={(isConfirm) => {
                                this.disableSelectedCases(isConfirm, false);
                            }}
                            modalfor="enableCases"
                            name="Test Case"
                            title="Test Case Name"
                            list={this.state.selectedTestCaseNames}
                        />
                    ) : null}
                    {openDeleteDialog ? (
                        <DeleteAlertModal
                            deleteButton="Danger"
                            open={openDeleteDialog}
                            handleClose={this.deleteSelectedCases}
                            modalfor="multiDelete"
                            name="Test Cases"
                            title="Test Case Name"
                            list={this.state.selectedTestCaseNames}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // auth reducer
        user: state.authReducer.user,
        // general reducer
        query: state.generalReducer.queryValue,
        // project reducer
        projects: state.projectReducer.projectsByIdAndName,
        wsRunningCaseData: state.projectReducer.wsRunningCaseData,
        // testCaseReducer
        isCaseLoading: state.testCaseReducer.isLoading,
        num_of_test_cases: state.sidebarReducer.sideBarCount.num_of_test_cases,
        testCasesWithAccount: state.testCaseReducer.testCasesWithAccount,
        openTaskPanel: state.generalReducer.openTaskPanel,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTestCasesWithAccount: (...args) => dispatch(TestCaseActions.getTestCasesWithAccount(...args)),
        deleteTestCase: (testId, from) => dispatch(TestCaseActions.deleteTestCase(testId, from)),
        disableTestCase: (...args) => dispatch(TestCaseActions.disableTestCase(...args)),
        getProjectByIdAndName: (...args) => dispatch(ProjectActions.getProjectByIdAndName(...args)),
        // Modal Action
        clearSnackBar: () => dispatch(ModalActions.clearSnackbar()),
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TestCase));
