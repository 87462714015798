import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormControl, Chip, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { checkArrayLength, checkKeyInObject } from '../../../../utils/utils';
import { generalModalActions } from '../../../../store/actions';

let isMounted = false;

const styles = () => ({
    formControl: {
        width: '100%',
        padding: '0 30px',
    },
    font: {
        fontSize: 12,
        color: '#595959',
    },
    labelColor: {
        '&>label': {
            color: '#595959',
            fontSize: '12px',
            transform: 'translate(0, 1.5px)',
            '&>span': {
                color: 'red',
            },
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    placeholder: {
        '&>div>::-webkit-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
        '&>div>::-moz-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
    },
    chipEmailRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        minHeight: 50,
        width: '100%',
        flexDirection: 'row',
        padding: '0 30px',
        marginTop: '10px',
    },
    errorMessage: {
        marginBottom: 10,
        fontSize: 12,
        color: 'red',
        display: 'block',
    },
});

const isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class SuiteNameandInfo extends Component {
    state = {
        testSuiteName: '',
        recipientsEmails: [],
        emailValue: '',
        isFirst: false,
        didChange: false,
        isNotValidEmail: false,
        isValidName: false
    };

    componentDidMount() {
        isMounted = true;
        if (this.props.isEditModal || checkKeyInObject(this.props.tabsData.suiteInfo, 'state')) {
            const prevData = checkKeyInObject(this.props.tabsData.suiteInfo, 'state')
                ? this.props.tabsData.suiteInfo.state
                : this.props.editTestSuiteData;
            this.setStateIfComponentMounted({
                testSuiteName: checkKeyInObject(prevData, 'testSuiteName') ? prevData.testSuiteName : '',
                recipientsEmails: checkKeyInObject(prevData, 'recipientsEmails') ? prevData.recipientsEmails : [],
                isFirst: true,
                emailValue: checkKeyInObject(prevData, 'emailValue') ? prevData.emailValue : '',
                isNotValidEmail: checkKeyInObject(prevData, 'isNotValidEmail') ? prevData.isNotValidEmail : false,
                didChange: checkKeyInObject(prevData, 'didChange') ? prevData.didChange : false,
            });
        }
        this.updatedTabsData();
    }

    componentWillUnmount() {
        this.onUnmount();
    }

    onUnmount = () => {
        isMounted = false;
        this.updatedTabsData();
    };

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted)
            this.setState({ didChange: this.state.isFirst, ...obj }, () => {
                callback();
                this.updatedTabsData();
            });
    };

    updatedTabsData = () => {
        const error = !(typeof this.state.testSuiteName === 'string' && Boolean(this.state.testSuiteName.trim()))
            ? '*Please fill out Name and Info tab'
            : '' || this.state.isNotValidEmail
            ? '*Please fill valid email in Name and Info tab'
            : '';
        this.props.updateTabData('suiteInfo', { state: { ...this.state, error } });
    };

    handleChange = (e) => {
        this.setStateIfComponentMounted({
            testSuiteName: e.target.value,
        });
    };

    handleEmailChip = (e) => {
        const value = e.target.value.replace(/\s|[,]+/g, '');
        if (value && !isEmail.test(String(value).toLowerCase())) {
            this.setState({ isNotValidEmail: true });
        } else {
            this.setState({ isNotValidEmail: false });
        }
        this.setStateIfComponentMounted({ emailValue: value });
    };

    addEmailChip = (e) => {
        if (!e.keyCode || e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 188 || e.keyCode === 9) {
            const email = this.state.emailValue;
            if (isEmail.test(String(email).toLowerCase())) {
                const chips = this.state.recipientsEmails.slice();
                if (
                    !chips.some((chip) => {
                        return chip.toLowerCase() === email.toLowerCase();
                    })
                ) {
                    chips.push(email);
                }
                this.setStateIfComponentMounted({ emailValue: '', recipientsEmails: chips });
            }
        }
    };

    removeEmailChip = (index) => {
        const chips = this.state.recipientsEmails.slice();
        chips.splice(index, 1);
        this.setStateIfComponentMounted({ recipientsEmails: chips });
    };

    render() {
        const { classes } = this.props;
        const { testSuiteName, emailValue, recipientsEmails, isNotValidEmail } = this.state;
        return (
            <div style={{ width: 600, maxHeight: 'calc(100vh - 320px)', overflow: 'hidden', overflowY: 'auto' }}>
                <FormControl className={classes.formControl}>
                    {isNotValidEmail && <span className={classes.errorMessage}>*Please enter valid email</span>}
                    <TextField
                        id="testSuiteName"
                        aria-label="Test Suite Name"
                        onChange={this.handleChange}
                        margin="normal"
                        fullWidth
                        placeholder="Suite Name"
                        autoFocus
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            value: testSuiteName,
                            classes: {
                                input: classes.font,
                            },
                        }}
                        style={{ margin: 0, marginTop: 10.5 }}
                        className={`${classes.labelColor} ${classes.placeholder}`}
                        required
                    />
                </FormControl>
                <div className={classes.formControl}>
                    <TextField
                        id="emailValue"
                        aria-label="Email"
                        onChange={this.handleEmailChip}
                        onKeyDown={this.addEmailChip}
                        onBlur={this.addEmailChip}
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        fullWidth
                        placeholder="Email Addresses for suite report"
                        type="text"
                        InputProps={{
                            classes: { input: classes.font },
                            value: emailValue,
                        }}
                        style={{ margin: 0, marginTop: 10.5 }}
                        className={`${classes.labelColor} ${classes.placeholder}`}
                    />
                </div>
                {checkArrayLength(recipientsEmails) && (
                    <div className={classes.chipEmailRoot} style={{ justifyContent: 'start' }}>
                        {recipientsEmails.map((data, index) => {
                            return (
                                <Chip className={classes.chip} key={index} icon={null} label={data} onDelete={() => this.removeEmailChip(index)} />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}

SuiteNameandInfo.propTypes = {
    tabsData: PropTypes.shape({}).isRequired,
    editTestSuiteData: PropTypes.shape({}),
    isEditModal: PropTypes.bool,
    updateTabData: PropTypes.func.isRequired,
};

SuiteNameandInfo.defaultProps = {
    isEditModal: false,
    editTestSuiteData: {},
};

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SuiteNameandInfo));
