class ExecuteScriptModalConstant {
    static Browser = ['Chrome(headful)', 'Firefox(headful)','Chrome(headless)','Firefox(headless)', 'internetExplorer', 'Safari', 'MSEdge'];
   // static Browser = ['Chrome','Firefox', 'internetExplorer', 'Safari', 'MSEdge'];
    static Platform = ['Linux', 'Windows','MacOS', 'Android', 'iOS'];

    static PlatformBasedBrowsers = { Linux: ['Chrome(headful)','Chrome(headless)','Firefox(headful)','Firefox(headless)'], Windows: ['internetExplorer'] };
     // static PlatformBasedBrowsers = { Linux: ['Chrome','Firefox'], Windows: ['internetExplorer'] };

    static ExecutionModes = ['Parallel', 'Serial'];

    static ExecuteOptions = [
        { name: 'Complete suite', value: 'suite' },
        { name: 'Filter by tag', value: 'tags' },
    ];

    static EnvironmentOptions = [
        { name: 'Local', value: 'Local' },
        { name: 'Zalenium', value: 'Zalenium' },
        { name: 'External Grid', value: 'External Grid' },
        { name: 'Saucelabs', value: 'Saucelabs' },
    ];

    static default = [
        {name:'', value:'isDefault'}
    ]

    static MappedOSX = {
        'macOS Catalina 10.15': 'macOS 10.15',
        'macOS Mojave 10.14': 'macOS 10.14',
        'macOS High Sierra 10.13': 'macOS 10.13',
        'macOS Big Sur 11': 'macOS 11.00',
        'macOS Sierra 10.12': 'macOS 10.12',
        'macOS Ventura 13': 'macOS 13',
        'OS X El Capitan 10.11': 'OS X 10.11',
        'OS X Yosemite 10.10': 'OS X 10.10',
        'Windows 10':'Windows 10',
        'WINDOWS 10':'Windows 10',
        'WINDOWS':'Windows 10',
        'windows':'Windows 10'
    };

    static newMappedOSX = {
        'macOS 10.15': 'macOS',
        'macOS 10.14': 'macOS',
        'macOS 10.13': 'macOS',
        'macOS 11.00': 'macOS',
        'macOS 10.12': 'macOS',
        'macOS 13': 'macOS',
        'OS X 10.11': 'OS X',
        'OS X 10.10': 'OS X',
        'Windows 11': 'Windows',
        'Windows 10': 'Windows',
        'Android': 'Android',
        
    };

    static newMappedOSXVersion = {
        'macOS 10.15': 'macOS Catalina',
        'macOS 10.14': 'macOS Mojave',
        'macOS 10.13': 'macOS High Sierra',
        'macOS 11.00': 'macOS Big Sur',
        'macOS 10.12': 'macOS Sierra',
        'macOS 10.11': 'OS X El Capitan',
        'macOS 10.10': 'OS X Yosemite',
        'macOS 13': 'macOS Ventura',
        'Windows 10': 'Windows 10',
        'Windows 11': 'Windows 11',
        'Android 12': 'Android 12',
        'Android 11': 'Android 11',
    }

    static MappedOSXVersion = {
        'macOS Catalina 10.15': '10.15',
        'macOS Mojave 10.14': '10.14',
        'macOS High Sierra 10.13': '10.13',
        'macOS Big Sur 11': '11',
        'macOS Sierra 10.12': '10.12',
        'macOS Ventura 13': '13',
        'OS X El Capitan 10.11': '10.11',
        'OS X Yosemite 10.10': '10.10',
        'Windows 10': '10',
        'WINDOWS 10': '10',
        'macOS 10.15': '10.15',
        'macOS 10.14': '10.14',
        'macOS 10.13': '10.13',
        'macOS 11.00': '11',
        'macOS 10.12': '10.12',
        'OS X 10.11': '10.11',
        'OS X 10.10': '10.10',
        'WINDOWS': '10',
        'windows':'10'


    }

    static MappedOSXReverse = {
        'macOS 10.15':'macOS Catalina 10.15',
        'macOS 10.14':'macOS Mojave 10.14',
        'macOS 10.13':'macOS High Sierra 10.13',
        'macOS 11.00':'macOS Big Sur 11',
        'macOS 10.12':'macOS Sierra 10.12',
        'macOS 13': 'macOS Ventura 13',
        'OS X 10.11':'OS X El Capitan 10.11',
        'OS X 10.10':'OS X Yosemite 10.10',
        'Windows 10':'Windows 10',
    };



    static MappedBrowserReverse = {
        'chrome':'Chrome',
        'firefox':'Firefox',
        'safari':'Safari',
        'MicrosoftEdge':'MicrosoftEdge',
    };

}
export { ExecuteScriptModalConstant };
