// import material
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

// import icons - material

// import packages
import React from 'react';

import { BLACK_FONT } from '../../common/cssConstants';

const styles = () => ({
    radioGroup: {
        flexDirection: 'row',
        flexWrap: 'unset',
        '&>:first-child': {
            marginLeft: '0px !important',
        },
        '&>:nth-child(2)': {
            marginLeft: '1px !important',
            //marginRight: '0px !important',
        },
        '&>label': {
            '&>:nth-child(1)': {
                color: '#3B91DF',
                '&>span>svg': {
                    fontSize: 20,
                },
            },
            '&>:nth-child(2)': {
                fontSize: 12,
                color: BLACK_FONT,
            },
        },
    },
    radio: {
        height: '20px',
        width: '20px',
        marginRight: '10px',
    },
});

const RadioBtn = ({ classes, labels = [], name, value, action }) => (
    <RadioGroup aria-label={name} name={name} className={classes.radioGroup} value={value} onChange={action}>
        {labels.map((label, index) => (
            <FormControlLabel key={index} value={label.value} control={<Radio className={classes.radio} />} label={label.name} />
        ))}
    </RadioGroup>
);

const RadioBtnGroup = withStyles(styles)(RadioBtn);
export { RadioBtnGroup };
