import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// icons
import Build from '@material-ui/icons/Build';
import Clear from '@material-ui/icons/Clear';
import moment from 'moment';
import { getRelativeTime } from '../../utils/utils';
import Tooltip from '../Tooltip';
import { BLACK_FONT } from '../../common/cssConstants';

const styles = () => ({
    container: {
        marginBottom: 3,
        width: '100%',
    },
    statusActive: {
        color: '#43cb83',
    },
    statusInActive: {
        color: '#da4e35',
    },
    statusInProgress: {
        color: '#eac129',
    },
    iconStyle: {
        fontSize: 15,
        color: '#4A4A4A',
    },
    progressIconStyle: {
        fontSize: 18,
    },
    progressIconSection: {
        display: 'flex',
        alignItems: 'center',
        float: 'left',
        width: '10%',
        marginTop: 8,
    },
    typographyStyle: {
        fontSize: 13,
    },
    timeStyle: {
        fontSize: 12,
    },
    subHeadngStyle: {
        fontSize: 13,
        color: BLACK_FONT,
    },
    ShowDownload: {
        color: '#757575',
        cursor: 'pointer',
    },
    disabledDownload: {
        color: '#d9d9d9',
        cursor: 'not-allowed',
    },
    info: {
        float: 'left',
        width: '80%',
    },
    closeIcon: {
        position: 'absolute',
        top: 5,
        right: 13,
        marginTop: 0,
        width: 'auto',
        '& svg': {
            fontSize: 20,
        },
        '&:hover': {
            color: '#003ca3',
        },
    },
});
moment.locale('en', {
    relativeTime: {
        past: '%s',
        s(number) {
            if (number < 60) {
                return `${number}s`;
            }
            const minute = Math.round(number / 60);
            return `${minute}m`;
        },
        ss: '%ss',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd(number) {
            if (number < 7) {
                return `${number}d`;
            }

            const weeks = Math.round(number / 7);
            return `${weeks}w`;
        },
        M: '1Month',
        MM: '%dMonths',
        y: '1Y',
        yy: '%dY',
    },
});
const NotificatonList = (props) => {
    const { classes, notifyData, handleCloseNotificaton, markReadNotify } = props;
    return (
        <div className={classes.container}>
            <div
                className={classes.progressIconSection}
                style={
                    notifyData.isRead
                        ? {}
                        : {
                              color: '#003ca3',
                          }
                }
            >
                <Build aria-label="buildIcon" id="buildIcon" />
            </div>
            <div aria-hidden className={classes.info} onClick={() => markReadNotify(notifyData.notificationId)}>
                <Typography
                    noWrap
                    className={classes.typographyStyle}
                    style={
                        notifyData.isRead
                            ? { maxWidth: '275px' }
                            : {
                                  fontWeight: 'bold',
                                  color: '#003ca3',
                                  maxWidth: '275px',
                              }
                    }
                >
                    <Tooltip data={notifyData.description}> {notifyData.description} </Tooltip>
                </Typography>
                {/* <Typography noWrap className={classes.subHeadngStyle}>
                    {notifyData.description}
                </Typography> */}
                <Typography
                    noWrap
                    className={`${classes.typographyStyle} ${classes.timeStyle}`}
                    style={
                        notifyData.isRead
                            ? {}
                            : {
                                  color: '#003ca3',
                              }
                    }
                >
                    {getRelativeTime(notifyData.lastModifiedTime)}
                </Typography>
            </div>
            <div className={[classes.progressIconSection, classes.closeIcon].join(' ')}>
                <Clear aria-label="clearIcon" id="clearIcon" onClick={() => handleCloseNotificaton(notifyData.notificationId)} />
            </div>
        </div>
    );
};

export default withStyles(styles)(NotificatonList);
