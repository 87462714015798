import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Tooltip from '../Tooltip';

const styles = () => ({
    RobotoMonoImportant: {
        fontFamily: 'Roboto Mono !important',
    },
    showText: {
        width: '100%',
        padding: '0px 8px 8px',
        fontWeight: 500,
        fontSize: 12,
        overflow: 'hidden',
    },
});

const FormattedTooltip = ({ children, classes, data, ...others }) => {
    return (
        <Tooltip
            isElement
            data={<Typography dangerouslySetInnerHTML={{ __html: data }} className={`${classes.showText} ${classes.RobotoMonoImportant}`} />}
            {...others}
        >
            {children}
        </Tooltip>
    );
};

export default withStyles(styles)(FormattedTooltip);
