import axios from 'axios';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver/FileSaver';
import config from '../config';
import {
    getExecutionStatus,
    getRelativeTime,
    checkArrayLength,
    getFormatedDate,
    stepsStatusMap,
    checkKeyInObject,
    replaceCharsForStepsCSV,
} from '../utils/utils';
import store from '../store';
import { ModalActions } from '../store/actions';
import projectServices from './projectServices';

const { companyName } = config;
let testSteps;
class SingleFileCreationService {
    stepRow = (step, colStatus, forPreview, images) => {
        return `<tr>
                    <td valign='top' width='150'><div class="download-tdChildContainer">${step.instrNum}</div> </td>
                    <td valign='top' width='150'><div class="download-tdChildContainer">${step.instr}</div> </td>
                    <td valign='top' width='150'><div class="download-tdChildContainer">${step.data}</div> </td>
                    <td valign='top' width='150'><div class="download-tdChildContainer download-nowrap">${colStatus}</div> </td>
                    <td valign='top' width='200'><div class="download-tdChildContainer download-nowrap">${
                        step.stepTime ? getFormatedDate(step.stepTime, 'DD MMM YYYY hh:mm:ss') : ''
                    }</div> </td>
                    <td valign='top'>${
                        images[step.instrNum]
                            ? `<img width="300px"  ${forPreview ? '' : `onclick="showImagePreview('${images[step.instrNum]}')"`} src="${
                                  images[step.instrNum]
                              }">`
                            : ''
                    }</td>
                </tr>`;
    };

    showTable = (images, forPreview = false) => {
        const table = `
                <style>
                    * {
                        font-family: sans-serif;
                    }
                    .download-image-preview {
                        background-color: white;
                        height: 100vh;
                        position: fixed;
                        top: 0;
                        width: 100%;
                        margin-left: -8px;
                    }
                    .download-image-hide{
                        display:none;
                    }
                    .download-closeIcon {
                        position: absolute;
                        right: 12.5px;
                        top: 6px;
                        font-size: 19px;
                        color: #ffffff;
                    }
                    .download-closeIconContainer {
                        background-color: #f0ad4e;
                        border-radius: 50%;
                        cursor: pointer;
                        height: 40px;
                        right: 20px;
                        position: absolute;
                        top: 3px;
                        width: 40px;
                    }
                    .download-closeIconContainer:hover {
                        background-color: #ed9c28
                    }
                    .download-tdChildContainer {
                        display: flex;
                        justify-content: center;
                        padding:2px 10px 0px 10px
                    }
                    .download-nowrap {
                        white-space: nowrap
                    }
                    #downloadTable {
                        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
                        border-collapse: collapse;
                        margin: auto;
                    }
                    
                    #downloadTable td/* , #downloadTable th */ {
                        border: 1px solid #ddd;
                        padding: 8px;
                    }
                    
                    #downloadTable tr:nth-child(odd){
                        background-color: #ebebeb;
                    }
                    
                    #downloadTable tr:nth-child(even){
                        background-color: #fff;
                    }
                    
                    #downloadTable th {
                        padding-top: 12px;
                        padding-bottom: 12px;
                        text-align: left;
                        background-color: #003ea1;
                        color: white;
                    }
                </style>
                ${
                    forPreview
                        ? ''
                        : `<script>
                    function showImagePreview (src) {
                        let imageContainer = document.getElementById('download-image-preview');
                        imageContainer.classList.toggle('download-image-hide');
                        let image = imageContainer.getElementsByTagName('img')[0];
                        image.src = src;
                        const screeHeight = document.getElementById('download-image-preview').clientHeight;
                        image.height = screeHeight.toString();
                        imageContainer.parentNode.style.overflow = 'hidden';
                    }
                    function hideImagePreview( ) {
                        let imageContainer = document.getElementById('download-image-preview');
                        imageContainer.classList.toggle('download-image-hide');
                        let image = imageContainer.getElementsByTagName('img')[0];
                        image.src = '';
                        imageContainer.parentNode.style.overflow = 'auto';
                    }
                </script>
                <div id="download-image-preview" class="download-image-preview download-image-hide" >
                    <div class="download-closeIconContainer" onclick="hideImagePreview()">
                        <div class="download-closeIcon">&#10006;</div>
                    </div>
                    <img style="margin: 0 auto;display: block;height: auto;width: 100%;" src="" />
                </div>`
                }
                <table cellspacing=0 id="downloadTable">
                    <thead style="text-align: -webkit-center">
                        <tr>
                            <th><div class="download-tdChildContainer download-nowrap">No.</div></th>
                            <th><div class="download-tdChildContainer download-nowrap">Test Steps</div></th>
                            <th><div class="download-tdChildContainer download-nowrap">Data</div></th>
                            <th><div class="download-tdChildContainer download-nowrap">Status</div></th>
                            <th><div class="download-tdChildContainer download-nowrap">Step Time</div></th>
                            <th><div class="download-tdChildContainer download-nowrap">Screenshot</div></th>
                        </tr>
                    </thead>
                    <tbody>
                ${
                    testSteps &&
                    testSteps.length &&
                    testSteps
                        .map((testStep, index) => {
                            let tr = '';
                            let subTable = '';
                            const colStatus = stepsStatusMap[testStep.status || '5'];
                            tr = this.stepRow(testStep, colStatus, forPreview, images);
                            if (testStep && testStep.hasChild) {
                                const clonedTestSteps = [...testSteps];
                                for (let i = 0; i < clonedTestSteps.length; i++) {
                                    const step = clonedTestSteps[i];
                                    const splitedValue = step ? step.instrNum.split('.') : [];
                                    if (step && splitedValue.length === 2 && splitedValue[0] === index + 1) {
                                        const _colStatus = stepsStatusMap[step.status || '5'];
                                        subTable += this.stepRow(step, _colStatus, forPreview, images);
                                        const splicingIndex = testSteps.map((x) => x.instrNum).indexOf(step.instrNum);
                                        testSteps.splice(splicingIndex, 1);
                                        if (step.hasChild) {
                                            subTable += this.subStepTableCreate(testSteps, splitedValue.pop(), 1, images, forPreview, step.instrNum);
                                        }
                                    } else if (step && splitedValue[0] > index + 1) {
                                        break;
                                    }
                                }
                            }
                            return tr + subTable;
                        })
                        .join('')
                }
                    </tbody>
                </table>
            `;
        return table;
    };

    subStepTableCreate = (_testSteps, index, level, images, forPreview, parentInstrNum = 0) => {
        let subTable = '';
        const clonedTestSteps = [..._testSteps];
        for (let i = 0; i < clonedTestSteps.length; i++) {
            const step = clonedTestSteps[i];
            const splitedValue = step ? step.instrNum.split('.') : [];
            const childValue = splitedValue.slice(0, level + 1).join('.');
            if (Number(parentInstrNum) === Number(childValue)) {
                if (step && splitedValue.length === Number(level) + 2) {
                    const colStatus = stepsStatusMap[step.status || '5'];
                    subTable += this.stepRow(step, colStatus, forPreview, images);
                    const splicingIndex = _testSteps.map((x) => x.instrNum).indexOf(step.instrNum);
                    _testSteps.splice(splicingIndex, 1);
                    if (step.hasChild) {
                        subTable += this.subStepTableCreate(_testSteps, Number(splitedValue.pop()), Number(level) + 1, images, forPreview);
                    }
                }
            }
        }
        // subTable += '</table></td></tr>';
        return subTable;
    };

    toDataURL = (url) =>
        fetch(url)
            .then((response) => response.blob())
            .then(
                (blob) =>
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    }),
            );

    getImageBase64 = async (_testSteps) => {
        let images = [];
        const self = this;
        return new Promise((resolve) => {
            const promises = _testSteps.map(async (item) => {
                if (checkArrayLength(item.screenshotPaths)) {
                    const path = `${config.api.endpoint}/testCases/previewScreenShot?screenShotURL=${item.screenshotPaths[0]}`;
                    const dataUrl = await self.toDataURL(path);
                    images = { ...images, [item.instrNum]: dataUrl };
                } else {
                    images = { ...images, [item.instrNum]: '' };
                }
            });
            Promise.all(promises).then(() => {
                resolve([self.showTable(images), self.showTable(images, true)]);
            });
        });
    };

    stepSort = (a, b, withEqual) => {
        if (a.length > 1) {
            if (!withEqual ? a.length < b.length : a.length <= b.length) {
                a = `${a[0]}.${a.slice(1, a.length).join('')}0`;
            } else {
                a = `${a[0]}.${a.slice(1, a.length).join('')}`;
            }
        } else {
            a = a.join('');
        }
        return a;
    };

    sort(_testSteps) {
        const steps = [..._testSteps];
        if (steps.length > 0) {
            for (let i = 0; i < steps.length - 1; i++) {
                for (let j = 0; j < steps.length - i - 1; j++) {
                    let a = steps[j] && steps[j].instrNum ? steps[j].instrNum.split('.') : [];
                    let b = steps[j + 1] && steps[j + 1].instrNum ? steps[j + 1].instrNum.split('.') : [];
                    a = this.stepSort(a, b);
                    b = this.stepSort(b, a, true);
                    if (parseFloat(a) > parseFloat(b)) {
                        [steps[j + 1], steps[j]] = [steps[j], steps[j + 1]];
                    }
                }
            }
        }
        return steps;
    }

    getFile = async (steps) => {
        testSteps = this.sort(steps);
        const filesData = await this.getImageBase64(testSteps);
        const content = filesData[0];
        const preview = filesData[1];
        return content ? { content, preview, status: true } : { error: 'Failed to parse file', status: false };
    };

    getFileInfo = async (link, showProgress = true) => {
        const minioLink = this.getMinioLink(link);
        try {
            return await this.getDownloadFile(minioLink, true, '', showProgress).then((response) => {
                const data = new Uint8Array(response.data);
                const workbook = XLSX.read(data, { type: 'array' });
                const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
                if (first_worksheet && first_worksheet['!ref'] && first_worksheet['!ref'].split(':').length === 2) {
                    first_worksheet['!ref'] = `A1:${first_worksheet['!ref'].split(':')[1]}`; // issue Autonomiq/QA#1829
                }
                const rows = XLSX.utils.sheet_to_json(first_worksheet, { header: 1, blankrows: false, defval: null });
                return rows;
            });
        } catch (error) {
            // console.log('getTestSuites', 'Rows not generated');
            return null;
        }
    };

    downloadExecutionFile = async (link, action = 'download', fileName = 'Execution Report', testCaseId, executionId) => {
        const downloadLink =  `${config.api.endpoint}/v1/testacases/${testCaseId}/testexecutions/${executionId}/downloadreport`;
        try {
            return await this.getDownloadFile(downloadLink, true, fileName).then(async (response) => {
                if (!('TextDecoder' in window)) console.warn('Sorry, this browser does not support TextDecoder...');

                const enc = new TextDecoder('utf-8');
                const arr = new Uint8Array(response.data);
                const blob = new Blob([enc.decode(arr)]);
                const blobText = await blob.text();
                if (!blobText || blobText.includes('<Error>')) {
                    store.dispatch(ModalActions.clearSnackbar());
                    store.dispatch(ModalActions.toggleSnackBar('Report not available', '', false, 10000, false, false, false));
                    console.warn('Sorry. File can not be downloaded.');
                    return;
                }
                if (action === 'open') {
                    this.showFile(blob, action);
                } else {
                    saveAs(blob, `${fileName}.html`);
                }
            });
        } catch (error) {
            console.error('getTestSuites:', error);
            return null;
        }
    };

    getMinioLink = (link) => {
        let minioLink = `${config.api.endpoint}/testCases/getOrigTestCase?testCaseURL=${link}`;
        if (link && link.includes('//')) {
            link = link.split('//');
            link = link && link.length > 1 && link[1];
            minioLink = `${config.api.endpoint}/testCases/getOrigTestCase?testCaseURL=${link}`;
        }
        return minioLink;
    };

    getDownloadFile = async (minioLink, isArrayBuffer = false, fileName = 'File', showProgress = true) => {
        const token = localStorage.getItem('token');
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        if (isArrayBuffer) {
            options.responseType = 'arraybuffer';
        }
        const response = await projectServices.getWithDownload(
            axios.get,
            minioLink,
            options,
            { fileName, id: Math.random(), type: 'report' },
            showProgress,
        );
        return response;
    };

    showFile = (blob, action = 'open', downloadFileName = 'Suite_Report.html', type = 'text/html') => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([blob], { type });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        if (action === 'download') {
            link.download = downloadFileName;
        }
        link.target = '_blank';
        link.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(data);
        }, 100);
    };

    getProjectFile = async (projects, fileName = 'projects') => {
        try {
            const data = [['Project Name', 'Success %', 'Cases', 'Last Activity']];
            projects.forEach((val) => {
                data.push([val.name, `${val.success_ratio}%`, val.number_of_cases, val.number_of_datas, getRelativeTime(val.last_activity)]);
            });
            const wb = XLSX.utils.book_new();
            wb.Props = {
                Title: fileName,
                Subject: 'Projects',
                Author: companyName || '',
                CreatedDate: new Date(),
            };
            wb.SheetNames.push('Projects');
            const ws = XLSX.utils.aoa_to_sheet(data);
            wb.Sheets.Projects = ws;
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const datas = this.JsonToXlsx(wbout);
            return datas;
        } catch (error) {
            console.error('No File Found:', error);
            return null;
        }
    };

    getSuiteReport = async (selectedDateFormat, testSuiteReport, getExecutionCount, fileName = 'SuiteReport') => {
        try {
            const data = [['Suite Name', 'Start Time', 'Total', 'Pass', 'Fail', 'Product Bug', 'Automation Bug']];
            testSuiteReport.forEach((report) => {
                data.push([
                    `${report.suite_name}`,
                    `${
                        selectedDateFormat.value === 'actualDate'
                            ? getFormatedDate(report.creation_time, 'MMM DD YYYY', 'N/A')
                            : getRelativeTime(report.creation_time)
                    }`,
                    `${report.total}`,
                    `${report.passed}`,
                    `${report.failed}`,
                    `${report.errors_count}`,
                    `${report.warnings_count}`,
                ]);
            });
            const wb = XLSX.utils.book_new();
            wb.Props = {
                Title: fileName,
                Subject: 'SuiteReport',
                Author: companyName || '',
                CreatedDate: new Date(),
            };
            wb.SheetNames.push('SuiteReport');
            const ws = XLSX.utils.aoa_to_sheet(data);
            wb.Sheets.SuiteReport = ws;
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const datas = this.JsonToXlsx(wbout);
            return datas;
        } catch (error) {
            console.error('No File Found:', error);
            return null;
        }
    };

    getCaseFile = async (steps, type, fileName = 'Test Steps') => {
        testSteps = type === 'Live Steps' ? this.sort(steps) : steps;
        try {
            const data = [['Test Steps', 'Data', 'Expected Result']];
            testSteps.forEach((val) => {
                // if (!val.hasChild)
                // Need to replace all enter keys in parent instruction to period(.)
                // in recoverSteps we are getting enter key in parent instructions
                data.push([val.instr, val.data, val.expectedResults]);
            });
            const wb = XLSX.utils.book_new();
            wb.Props = {
                Title: fileName,
                Subject: 'Steps',
                Author: companyName || '',
                CreatedDate: new Date(),
            };
            wb.SheetNames.push('Steps');
            const ws = XLSX.utils.aoa_to_sheet(data);
            wb.Sheets.Steps = ws;
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const datas = this.JsonToXlsx(wbout);
            return datas;
        } catch (error) {
            console.error('No File Found:', error);
            return null;
        }
    };

    JsonToXlsx = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    };

    getFlowFile = async (filtersortedBlocks, fileName = 'Flows') => {
        try {
            const data = [['Flow Name', 'Instruction', 'Data']];
            filtersortedBlocks.forEach((block) => {
                if (checkKeyInObject(block, 'steps')) {
                    block.steps.forEach((step, ind) => {
                        let flowName;
                        if (ind === 0) {
                            flowName = `${block.name.replace(/["]{1}/g, '""')}`;
                        } else {
                            flowName = '';
                        }
                        const instr = `${replaceCharsForStepsCSV(step.instr).replace(/["]{1}/g, '""')}`;
                        const stepData = `${replaceCharsForStepsCSV(step.data).replace(/["]{1}/g, '""')}`;

                        data.push([flowName, instr, stepData]);
                    });
                }
            });

            const wb = XLSX.utils.book_new();
            wb.Props = {
                Title: fileName,
                Subject: 'Flows',
                Author: companyName || '',
                CreatedDate: new Date(),
            };
            wb.SheetNames.push('Flows');
            const ws = XLSX.utils.aoa_to_sheet(data);
            wb.Sheets.Flows = ws;
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const datas = this.JsonToXlsx(wbout);
            return datas;
        } catch (error) {
            console.error('No File Found:', error);
            return null;
        }
    };

    getSuiteFile = async (testCases, fileName) => {
        try {
            const data = [['Case', 'Created', 'LastStatus', 'Last Accessed']];
            testCases.forEach((val) => {
                const lastRun = val.lastRun !== val.createdTime ? getFormatedDate(val.lastRun, 'MMM D, YYYY', 'N/A') : 'N/A';
                const createdTime = getFormatedDate(val.createdTime, 'MMM D, YYYY', 'N/A');
                const status = getExecutionStatus(val);
                data.push([val.testCaseName, createdTime, status, lastRun]);
            });
            const wb = XLSX.utils.book_new();
            wb.Props = {
                Title: fileName,
                Subject: 'Suites',
                Author: companyName || '',
                CreatedDate: new Date(),
            };
            wb.SheetNames.push('Suites');
            const ws = XLSX.utils.aoa_to_sheet(data);
            wb.Sheets.Suites = ws;
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const datas = this.JsonToXlsx(wbout);
            return datas;
        } catch (error) {
            console.error('No File Found:', error);
            return null;
        }
    };

    getHorizontalVariableFile = async (variables,fileName) => {
        try {
            const data = [];
            data.push(variables[0].columns)
            let valueArr = []
            variables[0].data[0].forEach((val) => {
                if (typeof val === 'string') {
                    valueArr = [...valueArr,val]
                } else {
                    valueArr = [...valueArr,val?.value]
                }
            });
            data.push(valueArr)
            const wb = XLSX.utils.book_new();
            wb.Props = {
                Title: fileName,
                Subject: 'Variables',
                Author: companyName || '',
                CreatedDate: new Date(),
            };
            wb.SheetNames.push('Variables');
            const ws = XLSX.utils.aoa_to_sheet(data);
            wb.Sheets.Variables = ws;
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const datas = this.JsonToXlsx(wbout);
            return datas;
        } catch (error) {
            console.error('No File Found:', error);
            return null;
        }
    }

    getDownloadAiqReport = async (reportURL, reportName = 'Suite_Report.html', showErrorIfFail = false, showFileNewTab = false, testcaseId = null, testexecutionId = null) => {
        try {
            return await this.getDownloadFile(`${config.api.endpoint}/v1/testacases/${testcaseId}/testexecutions/${testexecutionId}/downloadreport`, false, reportName).then(
                async (response) => {
                    const resFileName = `${response.headers['content-disposition']}`.match(/filename=.*\.((?:\w(?!\.))+$)/);
                    const repFileName = `${reportName}`.match(/\.((?:\w(?!\.))+$)/);
                    let ext = '.html';
                    if (checkArrayLength(resFileName) && resFileName[1]) {
                        ext = `.${resFileName[1]}`;
                    } else if (checkArrayLength(repFileName) && repFileName[1]) {
                        ext = '';
                    }
                    const newBlob = new Blob([response.data], { type: 'text/html;charset=utf-8'});
                    if (response.data.includes('<Error>') && showErrorIfFail) {
                        store.dispatch(ModalActions.clearSnackbar());
                        store.dispatch(ModalActions.toggleSnackBar('Report not available', '', false, null, false, false, false));
                        return;
                    }
                    // IE doesn't allow using a blob object directly as link href
                    // instead it is necessary to use msSaveOrOpenBlob
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob);
                        return;
                    }

                    // For other browsers:
                    // Create a link pointing to the ObjectURL containing the blob.
                    const data = window.URL.createObjectURL(newBlob);
                    const link = document.createElement('a');
                    link.href = data;
                    link.download = reportName + ext;
                    link.target = '_blank';
                    link.click();
                    setTimeout(() => {
                        window.URL.revokeObjectURL(data);
                    }, 100);

                    if(showFileNewTab)  window.open(data,'_blank');
                },
            );
        } catch (error) {
            console.error('DownloadAiqReport:', error);
            return null;
        }
    };

    downloadArtifact = async (testData) => {
        const { testDataId, testDataName, testDataFileName = '', isPasswordColumnFound = false } = testData;
        try {
            return await this.getDownloadFile(
                `${config.api.endpoint}/v1/testdatas/${testDataId}/downloadArtifact`,
                true,
                `${testDataFileName || testDataName}`,
            ).then(async (response) => {
                if (!('TextDecoder' in window)) {
                    console.warn('Sorry, this browser does not support TextDecoder...');
                }

                const blob = new Blob([response.data]);
                saveAs(blob, `${testDataFileName || testDataName}`);
            });
        } catch (error) {
            console.error('Download Artifact:', error);
            return null;
        }
    };
}
export default new SingleFileCreationService();
