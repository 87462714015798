import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { TABLES_CSS } from '../../../../common/cssConstants';
import CaseStatus from '../../Case/CaseStatus';

const styles = () => ({
    root: {
        marginTop: '-30px',
        marginBottom: '13px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItem: 'center',
    },
    avatar: {
        minWidth: '20px',
        height: '25px',
        borderRadius: '20px',
        backgroundColor: '#f4f6f7',
        color: '#fff',
        textAlign: 'center',
        lineHeight: 2.2,
        paddingRight: '20px',
        paddingLeft: '15px',
        display: 'inline-block',
        maxWidth: '280px',
        marginRight: 20,
    },
});

const HeaderTop = (props) => {
    const {
        classes,
        selectedTestCase,
        /* stepsType, */
    } = props;
    return (
        <div className={classes.root}>
            {/* <div className={classes.recoverMsgContainer}>
                    {stepsType !== 'live' &&
                        <span className={classes.recoverMsgText}> *Recover Test Steps</span>
                    }
                </div> */}
            <div className={classes.avatar}>
                <CaseStatus
                    is_nowrap
                    testCase={selectedTestCase}
                    customStyles={{
                        fontSize: TABLES_CSS.normalFontSize,
                        color: TABLES_CSS.normalFontColor,
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        // project reducer
        selectedTestCase: state.projectReducer.selectedTestCase,
        // selected test case reducer
        // stepsType: state.selectedTestCaseReducer.stepsType,
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(HeaderTop));
