import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { ProjectActions } from '../../store/actions';
import { styles } from './styles';
import ErrorSnackBar from '../../components/ErrorSnackBar';
import { checkObject, checkArrayLength, getTestCaseStatus, checkKeyInObject } from '../../utils';
import projectServices from '../../services/projectServices';

let allTestCases_forTesting = {};
class ProjectDetailsHelper extends React.Component {
    constructor(props) {
        super(props);
        this.testCaseOrDataIsLoading = props.thisTestCaseOrDataIsLoading;
    }

    UNSAFE_componentWillMount() {
        this.props.clearSelectedCaseDataAndProject();
        if (checkObject(this.props.projectDetailsState)) {
            this.props.clearProjectDetailsState();
        }
    }

    componentDidMount() {
        const { handleHelperCallback } = this.props;
        handleHelperCallback(this.update_testCaseOrDataIsLoading);
        window.testingFunction_allTestCases = this.testingFunction_allTestCases;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            testCases,
            testData,
            project,
            projects,
            thisProjectId,
            update_testCaseOrDataIsLoading,
            update_isProjectChanged,
            getTestCasesAndData,
            isProjectChanged,
            thisTestCaseOrDataIsLoading,
            isLoading,
            isLoadingTestData,
        } = nextProps;
        const { thisTestCaseOrDataIsLoading: old_thisTestCaseOrDataIsLoading } = this.props;
        allTestCases_forTesting = testCases;

        if (`${checkKeyInObject(project, 'projectId')}` !== `${thisProjectId}` && projects && projects.length) {
            const _project = projectServices.getSingleProject(thisProjectId);
            if (checkObject(_project)) {
                this.props.setCurrentProject(_project);
            }
        }

        if (thisTestCaseOrDataIsLoading !== old_thisTestCaseOrDataIsLoading) {
            this.testCaseOrDataIsLoading = thisTestCaseOrDataIsLoading;
        }
        let isRecentChange = false;
        if (
            checkObject(project) &&
            (!checkArrayLength(testCases) || !checkArrayLength(testData)) &&
            !this.testCaseOrDataIsLoading &&
            isProjectChanged
        ) {
            this.update_testCaseOrDataIsLoading(true);
            update_testCaseOrDataIsLoading(true);
            update_isProjectChanged(false);
            getTestCasesAndData(thisProjectId, true);
            isRecentChange = true;
        }
        if (checkObject(project) && checkArrayLength(testCases) && !isLoading && !isLoadingTestData && this.testCaseOrDataIsLoading) {
            const { removeMsgType2Data, wsRunningCaseData } = this.props;
            const testCaseIdsToRemove = [];
            testCases.forEach((testCase) => {
                if (!testCase.isGenerating && checkKeyInObject(wsRunningCaseData, testCase.testCaseId)) {
                    testCaseIdsToRemove.push(`${testCase.testCaseId}`);
                }
            });
            removeMsgType2Data(testCaseIdsToRemove, 'ProjectDetails');
        }
        if (checkObject(project) && !isLoading && !isLoadingTestData && this.testCaseOrDataIsLoading && !isRecentChange) {
            this.update_testCaseOrDataIsLoading(false);
            update_testCaseOrDataIsLoading(false);
        }
    }

    update_testCaseOrDataIsLoading = (v) => {
        this.testCaseOrDataIsLoading = v;
    };

    testingFunction_allTestCases = () => {
        // eslint-disable-next-line no-console
        console.time('Time took to execute this function');
        const { wsRunningCaseData } = this.props;
        const testCases = [];
        if (checkArrayLength(allTestCases_forTesting)) {
            allTestCases_forTesting.forEach((testCase) => {
                testCases.push({
                    ...testCase,
                    testCaseStatusFromFunction: getTestCaseStatus(testCase, wsRunningCaseData),
                });
            });
        }
        // eslint-disable-next-line no-console
        console.log(testCases);
        // eslint-disable-next-line no-console
        console.timeEnd('Time took to execute this function');
    };

    render() {
        const { isSnackBarOpen } = this.props;

        return isSnackBarOpen ? <ErrorSnackBar /> : null;
    }
}

ProjectDetailsHelper.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    isSnackBarOpen: PropTypes.bool.isRequired,
};

ProjectDetailsHelper.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        isSnackBarOpen: state.modalReducer.isSnackBarOpen,
        projectDetailsState: state.projectReducer.projectDetailsState,
        testCases: state.projectReducer.testCases,
        testData: state.projectReducer.testData,
        wsRunningCaseData: state.projectReducer.wsRunningCaseData,
        projects: state.projectReducer.projects, // Need to remove this after getting new api from platform
        project: state.projectReducer.selectedProject,
        isLoadingTestData: state.projectReducer.isLoadingTestData,
        isLoading: state.projectReducer.isLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // Project
        clearProjectDetailsState: () => dispatch(ProjectActions.clearProjectDetailsState()),
        clearSelectedCaseDataAndProject: () => dispatch(ProjectActions.clearSelectedCaseDataAndProject()),
        setCurrentProject: (...args) => dispatch(ProjectActions.setCurrentProject(...args)),
        removeMsgType2Data: (...args) => dispatch(ProjectActions.removeMsgType2Data(...args)),

        // Modal

        // Tag
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectDetailsHelper));
