import HttpBase from '../../services/HttpBase';

class SuiteReportMiddleware extends HttpBase {
    async getExecutions(obj) {
        const result = await this.httpClient.get(`v1/jobs/${obj.id}/${obj.type}/get_executions`);
        if (result && result.data && result.status === 200) {
            return result.data;
        }
        throw new Error('no execution found');
    }
    executeFailedTestCases = async(jobId,testSuiteId,requestData) => {

        return new Promise(async(resolve,reject)=>{


        try {
 
            const result =  await this.httpClient.post(`/v1/test_suite/${testSuiteId}/${jobId}/restart_failed_executions`,requestData)
            if(result && result.data && result.status === 200) {
                resolve(result.data) 
            } else {
                throw new Error('error')
            }
    
           } catch(err) {
               reject(err)
           }

        })

    }
 
}
export default new SuiteReportMiddleware();
