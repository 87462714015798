import background from './Autonomiq-Forgot-BG.svg';

export const styles = () => ({
    autonomiqLogo: {
        height: '30px',
        margin: '6px 24px 0px',
        width: '191px',
        marginLeft: '4px',
        marginBottom: '20px',
    },
    card: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
    },
    errorText: {
        color: 'red',
        fontSize: 12,
    },
    formContainer: {
        marginTop: '8%',
    },
    loginText: {
        color: '#1168CD',
        marginTop: '13px',
        cursor: 'pointer',
        textAlign: 'center',
    },
    formStyle: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        border: '8px solid #F0B637',
        borderRadius: '52px',
        display: 'flex',
        justifyContent: 'center',
        width: '85%',
    },
    formWrapper: {
        padding: '50px',
        display: 'flex',
        flexDirection: 'column',
    },
    formWrapperWithError: {
        paddingTop: '20px !important',
    },
    inputAdornment: {
        color: 'gray',
    },
    raisedBtn: {
        '&:hover': {
            backgroundColor: '#1168CD',
            color: '#FFFFFF',
        },
        margin: '20px auto 0px auto',
        backgroundColor: '#1168CD',
        border: 'none',
        borderRadius: '10px',
        color: '#FFFFFF',
        lineHeight: '22px',
        padding: 10,
        width: '86%',
    },
    responseText: {
        color: '#2FBF71',
        textAlign: 'center',
        marginBottom: 10,
    },
    root: {
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flexGrow: 1,
        minHeight: '100vh',
        backgroundPosition: '0px -90px',
    },
    forgotInstructionText: {
        color: 'gray',
    },
});
