// import material
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import React from 'react';
import { generalModalActions } from '../../../store/actions';
import { EnhancedTableHead } from '../../../components/EnhancedTable';
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import TableCell from '../../../components/Table/TableCell';
import TableRow from '../../../components/Table/TableRow';
import { TABLES_CSS, BLACK_FONT } from '../../../common/cssConstants';
import SearchInput from '../../modal/GeneralModal/SearchInput';

import { sort, checkKeyInObject, checkArrayLength } from '../../../utils/utils';
import Tooltip from '../../../components/Tooltip';

const styles = (theme) => ({
    root: {
        padding: '0px 30px',
        width: 600,
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 321px)',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(0.25),
    },
    col: {
        width: 'inherit',
    },
    firstRowValue: {
        // margin: '10px 0px 0px 24px'
        fontWeight: 500,
    },
    formControl: {
        width: '100%',
        flexDirection: 'row',
    },
    horizontalRow: {
        width: '100%',
        border: 0,
        borderTop: '1px solid #DCDCDC',
    },
    label: {
        alignSelf: 'center',
        width: 90,
        '& > p': {
            color: BLACK_FONT,
        },
    },
    rootDialogContent: {
        width: '100%',
    },
    row: {
        display: 'flex',
        justifyContent: 'start',
    },
    selectBox: {
        width: '100%',
    },
    value: {
        display: 'flex',
        justifyContent: 'space-between',
        '& > p': {
            fontSize: '16px',
            marginTop: '10px',
        },
    },
    font: {
        fontSize: 12,
        color: '#595959',
    },
    labelColor: {
        margin: '0px 10px 0px 0px',
        width: 200,
        '&>label': {
            color: '#595959',
            fontSize: '12px',
            transform: 'translate(0, 1.5px)',
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    cellRole: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    tableRows: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#fafafa',
        },
    },
    checkBoxIcon: {
        color: '#0092e5',
        fontSize: '20px',
        marginLeft: '-4px',
    },
    nextPrev: {
        width: '34px',
        height: '18px',
        float: 'left',
        position: 'relative',
        overflow: 'hidden',
        marginTop: '2px',
    },
    prevIcon: {
        top: '-7px',
        height: '16px',
        cursor: 'pointer',
        display: 'inline-block',
        zIndex: 2,
        position: 'absolute',
        overflow: 'hidden',
        marginLeft: '4px',
        '&>svg': {
            fontSize: 25,
            '&:hover': {
                color: '#6094ff',
            },
        },
    },
    checkBoxClass: {
        '&>span>svg': {
            fontSize: '20px',
        },
    },
    nextIcon: {
        top: '1px',
        height: '17px',
        cursor: 'pointer',
        display: 'inline-block',
        zIndex: 1,
        position: 'absolute',
        overflow: 'hidden',
        marginLeft: '4px',
        '&>svg': {
            fontSize: 25,
            '&:hover': {
                color: '#6094ff',
            },
        },
    },
    inputField: {
        padding: '0px 0px 2px 0px',
        fontSize: '12px',
    },
    checkBox: {
        height: 25,
        width: 25,
    },
    tableContainer: {
        marginTop: 1,
        paddingBottom: 5,
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    activeStatus: {
        height: '19px',
        width: '55px',
        minWidth: '55px',
        fontFamily: 'Roboto',
        borderRadius: '8px',
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center',
        color: 'rgb(255, 255, 255)',
        paddingTop: 2,
        marginRight: '10px',
        display: 'inline-block',
    },
    userRole: {
        color: '#000000',
    },
    userActiveColor: {
        backgroundColor: '#1babab',
    },
});

const headers = [
    { id: 'name', sortable: true, numeric: false, disablePadding: true, label: 'All', styles: { paddingLeft: 10 }, width: '40%' },
    { id: 'role', sortable: false, numeric: false, disablePadding: true, label: '', styles: { paddingLeft: 10 }, width: '30%' },
    { id: 'active', sortable: false, numeric: false, disablePadding: true, label: '', styles: { paddingLeft: 10 }, width: '30%' },
];

let isMounted = false;
class AssociateGroupUsers extends React.Component {
    state = {
        isFirst: false,
        didChange: false,
        query: '',
        order: 'asc',
        orderBy: 'name',
        userDataArray: [],
        selectedUserIds: [],
        allChecked: false,
    };

    componentDidMount() {
        const { isUpdate, usersDropDownData, /* selectedUsersIds, */ tabsData, group } = this.props;
        isMounted = true;
        const userDataArray = JSON.parse(JSON.stringify(usersDropDownData));
        let previousData = {
            userDataArray: JSON.parse(JSON.stringify(userDataArray)),
        };
        if (isUpdate || checkKeyInObject(tabsData.associateUsersToGroup, 'state', 'bool')) {
            if (checkKeyInObject(tabsData.associateUsersToGroup, 'state', 'bool')) {
                previousData = tabsData.associateUsersToGroup.state;
            } else if (isUpdate) {
                const _selectedUserIds = [];
                if (checkArrayLength(checkKeyInObject(group, 'users', 'value', []))) {
                    group.users.forEach((user) => {
                        _selectedUserIds.push(user.id);
                    });
                }
                if (_selectedUserIds && _selectedUserIds.length && userDataArray && userDataArray.length) {
                    userDataArray.forEach((user, index) => {
                        _selectedUserIds.forEach((id) => {
                            if (user.id === id) {
                                userDataArray[index].checked = true;
                            }
                        });
                    });
                }
                previousData = {
                    userDataArray: JSON.parse(JSON.stringify(userDataArray)),
                    selectedUserIds: JSON.parse(JSON.stringify(_selectedUserIds)),
                };
            }
        }
        this.setStateIfComponentMounted({
            ...previousData,
            isFirst: true,
            didChange: checkKeyInObject(previousData, 'didChange') ? previousData.didChange : false,
        });
    }

    componentWillUnmount() {
        isMounted = false;
        const { updateTabData } = this.props;
        updateTabData('associateUsersToGroup', { state: { ...this.state } });
    }

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted)
            this.setState({ didChange: this.state.isFirst, ...obj }, () => {
                callback();
                this.props.updateTabData('associateUsersToGroup', { state: { ...this.state } });
            });
    };

    filterUsers = (users) => {
        const filteredUsers = [];
        users.forEach((user) => {
            if (user.checked === true) filteredUsers.push(user.id);
        });
        return filteredUsers;
    };

    handleToggle = (id, checked) => {
        const { userDataArray } = this.state;
        const index = userDataArray.map((user) => user.id).indexOf(id);
        userDataArray[index] = { ...userDataArray[index], checked };
        const allChecked = !userDataArray.some((user) => !user.checked);
        this.setStateIfComponentMounted({ userDataArray, allChecked, selectedUserIds: this.filterUsers(userDataArray) });
    };

    handleToggleAll = (isAllChecked) => {
        const userDataArray = [...this.state.userDataArray];
        let newUserData = [];

        if (isAllChecked) {
            newUserData = userDataArray.map((user) => {
                const newTestCase = { ...user, checked: true };
                return newTestCase;
            });
        } else {
            newUserData = userDataArray.map((user) => {
                const newTestCase = { ...user, checked: false };
                return newTestCase;
            });
        }
        this.setStateIfComponentMounted({ userDataArray: newUserData, allChecked: isAllChecked, selectedUserIds: this.filterUsers(userDataArray) });
    };

    handleSearchUsers = (e) => {
        const query = e.target.value;
        this.setStateIfComponentMounted({ query });
    };

    clearSearch = () => {
        this.setStateIfComponentMounted({ query: '' });
    };

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setStateIfComponentMounted({ order, orderBy });
    };

    render() {
        const { query, order, orderBy, allChecked, userDataArray, selectedUserIds } = this.state;
        const { classes, tabsData } = this.props;

        const filteredUsers = checkArrayLength(userDataArray)
            ? userDataArray.filter((user) => {
                  return user && user.name && user.name.toLowerCase().includes(query.toLowerCase().trim());
              })
            : [];
        const selectedUsers = [];
        const nonSelectedUsers = checkArrayLength(filteredUsers)
            ? filteredUsers.filter((user) => {
                  if (user && selectedUserIds.includes(user.id)) {
                      selectedUsers.push(user);
                      return false;
                  }
                  return true;
              })
            : [];
        const currentPageUsers = [...sort(selectedUsers, order, orderBy), ...sort(nonSelectedUsers, order, orderBy)];
        return (
            <div className={classes.root}>
                <div className={classes.rootDialogContent}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {checkKeyInObject(tabsData, 'adminAccountInfo.state.name', 'value', '')}
                        </span>
                        <SearchInput
                            id="AssociateGroupUsers"
                            placeholder="Search"
                            onChange={(e) => this.handleSearchUsers(e)}
                            value={this.state.query}
                            style={{ width: 250 }}
                            clearSearch={this.clearSearch}
                        />
                    </div>
                    <div className={classes.tableContainer}>
                        <div
                            style={{
                                borderRadius: 5,
                                overflow: 'hidden',
                                boxShadow: '0 2px 7px 0 rgba(0,0,0,0.2)',
                            }}
                        >
                            <Table>
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    headers={headers}
                                    onSelectAllClick={() => {
                                        this.handleToggleAll(!allChecked);
                                    }}
                                    numSelected={this.filterUsers(userDataArray).length}
                                    rowCount={userDataArray.length}
                                    showSelectBtn
                                    checkBoxClass={classes.checkBoxClass}
                                />
                                <TableBody>
                                    {currentPageUsers.map((user, index) => {
                                        return (
                                            <TableRow key={index} className={classes.tableRows} style={{ height: '40px', cursor: 'default' }}>
                                                <TableCell
                                                    className={[classes.cellRole, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[0].width}
                                                    data-resize={`element${0}_c`}
                                                >
                                                    <div style={{ paddingLeft: '0px', width: '100%' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.handleToggle(user.id, !user.checked);
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                className={classes.checkBox}
                                                                style={{ marginRight: '5px' }}
                                                                tabIndex={-1}
                                                            >
                                                                {user.checked ? (
                                                                    <CheckBox
                                                                        className={classes.checkBoxIcon}
                                                                        aria-label="checkBoxIcon"
                                                                        id="checkBoxIcon"
                                                                    />
                                                                ) : (
                                                                    <CheckBoxBlank
                                                                        className={classes.checkBoxIcon}
                                                                        style={{ color: '#bac5d0' }}
                                                                        aria-label="checkBoxBlankIcon"
                                                                        id="checkBoxBlankIcon"
                                                                    />
                                                                )}
                                                            </IconButton>
                                                            <Typography
                                                                noWrap
                                                                style={{
                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                    display: 'inline-block',
                                                                }}
                                                                className={classes.noWrap}
                                                            >
                                                                <Tooltip data={user.name ? user.name : null}>{user.name}</Tooltip>
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cellRole, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[1].width}
                                                    data-resize={`element${1}_c`}
                                                >
                                                    <div style={{ paddingLeft: '0px', width: '100%' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Typography
                                                                noWrap
                                                                style={{
                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                    display: 'inline-block',
                                                                }}
                                                                className={classes.noWrap}
                                                            >
                                                                <span
                                                                    className={`${classes.activeStatus} ${classes.userRole}`}
                                                                    style={{
                                                                        backgroundColor:
                                                                            user.role === 'AIQSA'
                                                                                ? '#A2D2F2'
                                                                                : user.role === 'ADMIN'
                                                                                ? '#F3E0BC'
                                                                                : user.role === 'SDET'
                                                                                ? '#92EABD'
                                                                                : '',
                                                                    }}
                                                                >
                                                                    {user.role}
                                                                </span>
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cellRole, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[1].width}
                                                    data-resize={`element${1}_c`}
                                                >
                                                    <div style={{ paddingLeft: '0px', width: '100%' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Typography
                                                                noWrap
                                                                style={{
                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                    display: 'inline-block',
                                                                }}
                                                                className={classes.noWrap}
                                                            >
                                                                <span className={`${classes.activeStatus} ${classes.userActiveColor}`}>
                                                                    {user.deleted === false ? 'Active' : 'Inactive'}
                                                                </span>
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
        usersDropDownData: state.userReducer.users,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AssociateGroupUsers));
