import React from 'react';
import { checkObject } from '../../utils/utils';

// Components

const TableRow = (props) => {
    const { children, style, hover, dataTypePrefix, className, focused, focusCustom, width, isTableRowStyle, showBorder, ...rem } = props;
    let styles = checkObject(style) ? style : {};
    styles = {
        ...styles,
        display: 'inline-flex',
        width: width ? width : '100%',
        borderCollapse: 'collapse',
        // borderBottom: !showBorder ? '' : isTableRowStyle ? '1px solid rgba(224, 224, 224, 1)' : 'inehrit',
        borderBottom: showBorder ? showBorder : '1px solid rgba(224, 224, 224, 1)',
        outline: 'none',
    };
    return (
        <div 
            data-type={`${dataTypePrefix}TableRow`} 
            {...rem} 
            style={styles}
            className={!isTableRowStyle ? className : `${className} TableRow ${focused ? focusCustom || 'focused' : ''}`}
        >
            {children}
        </div>
    );
};

TableRow.defaultProps = {
    dataTypePrefix: '',
};

export default TableRow;
