import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { checkArrayLength } from '../../../utils/utils';
import DeleteAlertModal from '../../modal/Delete/DeleteAlertModal';
import TableBody from '../../../components/Table/TableBody';
import FlowRow from './FlowRow';

class FlowBody extends Component {
    state = {
        openDeleteFlow: false,
        deleteBlock: {},
        checkedAll: false,
        isHovered: null
    };

    cloneFlow = (block) => {
        this.props.showBlock(true, block, 'clone');
    };

    viewFlow = (block) => {
        this.props.showBlock(true, block, 'view');
    };

    deleteFlow = (block) => {
        this.setState({ openDeleteFlow: true, deleteBlock: block });
    };

    confirmDeleteFlow = (isConfirm) => {
        const { deleteFlow, shouldUpdateBlock } = this.props;
        if (isConfirm) {
            deleteFlow(this.state.deleteBlock);
            shouldUpdateBlock();
        }
        this.setState({ openDeleteFlow: false, deleteBlock: {} });
    };

    render() {
        const { classes, blocks } = this.props;
        const { openDeleteFlow, isHovered } = this.state;
        if (checkArrayLength(blocks))
            return [
                <TableBody isTableBodyStyles={true} height={50} style={{maxHeight: 'calc(100vh - 340px)'}} className={classes.tableBody} key="row">
                    {blocks.map((block, index) => (
                        <FlowRow 
                            block={block} 
                            key={index} 
                            {...this.props} 
                            openDeleteFlow={openDeleteFlow} 
                            index={index}
                            isHovered={isHovered}
                            setHovered={(ind) => this.setState({isHovered: ind})}
                            cloneFlow={this.cloneFlow}
                            viewFlow={this.viewFlow}
                            deleteFlow={this.deleteFlow}
                            confirmDeleteFlow={this.confirmDeleteFlow}
                        />
                    ))}
                </TableBody>,
                openDeleteFlow ? (
                    <DeleteAlertModal
                        deleteButton="Danger"
                        key="modal"
                        handleClose={this.confirmDeleteFlow}
                        open={openDeleteFlow}
                        title="Flow"
                        modalfor="Flow"
                        name={this.state.deleteBlock && this.state.deleteBlock.name ? this.state.deleteBlock.name : ''}
                    />
                ) : null,
            ];
        return null;
    }
}

FlowBody.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    showBlock: PropTypes.func.isRequired,
};

FlowBody.defaultProps = {};

export const ProjectDetailsFlowBody = withStyles(styles)(FlowBody);
