import { ActionTypes } from '../constants';

const initialState = {
    executions: [],
    error: null,
    isLoading: false,
    success: null,
};

export default function suiteReportReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_ERROR: {
            return { ...state, error: null };
        }

        case ActionTypes.CLEAR_SUCCESS: {
            return { ...state, success: null };
        }

        case ActionTypes.GET_TESTSUITS_WITH_ACCOUNT: {
            return { ...state, isLoading: true };
        }

        case ActionTypes.GET_TESTSUITS_WITH_ACCOUNT_SUCCESS: {
            return { ...state, isLoading: false };
        }

        case ActionTypes.GET_TESTSUITS_WITH_ACCOUNT_FAILURE: {
            return { ...state, isLoading: false };
        }

        case ActionTypes.GET_SINGLE_TESTSUITE: {
            return { ...state, isLoading: true };
        }

        case ActionTypes.GET_SINGLE_TESTSUITE_SUCCESS: {
            return { ...state, isLoading: false };
        }

        case ActionTypes.GET_SUITE_REPORT_EXECUTIONS_SUCCESS: {
            return { ...state, isLoading: false, executions: action.payload.data };
        }

        case ActionTypes.GET_SUITE_REPORT_EXECUTIONS_FAILURE: {
            return { ...state, isLoading: false, error: action.payload.error.message };
        }

        case ActionTypes.GET_SUITE_REPORT_EXECUTIONS:
        case ActionTypes.GET_REPORTS: {
            let obj = { ...state };
            if (!action.payload.getWithoutLoading) {
                obj = {
                    ...obj,
                    isLoading: true,
                };
            }
            return obj;
        }

        case ActionTypes.GET_REPORTS_SUCCESS: {
            return { ...state, isLoading: false };
        }

        case ActionTypes.GET_REPORTS_FAILURE: {
            return { ...state, isLoading: false };
        }

        default:
            return state;
    }
}
