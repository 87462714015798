import React , { memo } from "react";
import { withStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { ArrowUpDown } from "../../components/Button/ArrowUpDown";
import { styles } from './Styles';

const PlatformBrowserSelectorHeader  = ({callingFrom,isDeviceSelectorOpen,onClick,title,selected,classes}) => {



    return (
        <React.Fragment>
        <Typography className={classes.textFieldLabelCustom}>{title}</Typography>
            <Box className={classes.width100P}>
                <Box className={`${classes.textFieldBody} ${callingFrom === "sauceExecution" && classes.sauceExecution}`} onClick={onClick}>
                    <Typography className={classes.textFieldInner}>{selected.label}</Typography>
                    <ArrowUpDown flag={isDeviceSelectorOpen} />
                </Box>
            </Box>
        </React.Fragment>    
    )

}
export default memo(withStyles(styles)(PlatformBrowserSelectorHeader));