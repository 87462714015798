// materials
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button, Typography } from '@material-ui/core';
// packages
import React from 'react';
import PropTypes from 'prop-types';
// custom
import { BLACK_FONT } from '../../common/cssConstants';
import { checkObject } from '../../utils';
import Tooltip from '../../components/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';

import { MUI_ACTION_MENU_ICON , THREE_DOT_MENU_CSS} from '../../common/cssConstants';

// style
const styles = (theme) => ({
    headerContainer: {
        margin: '0 0 0 25px',
        width: 'calc(100% - 50px)',
    },
    filterContainer: {
        height: '50px',
        marginTop: '20px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: '5px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        justifyContent: 'space-between',
    },
    suiteName: {
        color: BLACK_FONT,
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '16px',
        margin: 0,
        alignSelf: 'center',
        paddingLeft: '15px',
        maxWidth: '80%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '60%',
        },
    },
    contentContainer : {
        display: 'flex',
        marginLeft:'15px',
        [theme.breakpoints.down('lg')]: {
            width: '60%',
        },
    },
    suiteDetailContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '50%',
    },
    suiteDetail: {
        display: 'flex',
        // padding: '0px 0px 0px 70px',
    },
    suiteDetailValue: {
        color: '#3B91DF',
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '16px',
    },
    suiteDetailKey: {
        color: BLACK_FONT,
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '16px',
        marginRight: '5px',
    },
    nextPrevNameCont: {
        display: 'flex',
        marginLeft: '15px',
    },
    navigationPrev: {
        cursor: 'pointer',
        margin: '0 5px 0px 0px',
        width: 20,
        backgroundColor: '#0092e6',
        color: '#fff',
        borderRadius: '5px',
        boxShadow: 'none',
        textTransform: 'capitalize',
        justifyContent: 'flex-end',
        padding: '5px 10px',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#0092e6',
            boxShadow: 'none',
        },
    },
    navigationNext: {
        cursor: 'pointer',
        margin: 0,
        width: 20,
        backgroundColor: '#0092e6',
        color: '#fff',
        borderRadius: '5px',
        boxShadow: 'none',
        justifyContent: 'start',
        textTransform: 'capitalize',
        padding: '5px 10px',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#0092e6',
            boxShadow: 'none',
        },
    },
    refreshIcon: MUI_ACTION_MENU_ICON,

    refreshIconBtnDisabled: {
        color: THREE_DOT_MENU_CSS.iconDisableColor,
    },

    refreshIconBtnEnabled : {
        color:'#5f94fe'
    },

});

function SuiteCaseReportHeader(props) {
    const { classes, data: {
        isNextDisabled,
        isPrevDisabled,
        suiteName,
        updatedExecutionStatus,
        totalTestsFailedCount,
        totalTestsPassedCount,
        nextIndex,
        prevIndex,
        testSuiteReports,
    }, actions } = props;
    return (
        <div className={classes.headerContainer}>
            <div className={classes.filterContainer}>
                <div className={classes.contentContainer}>
                    <div >
                        <Tooltip data={checkObject(testSuiteReports[prevIndex]) ? testSuiteReports[prevIndex].suite_name : null}>
                            <Button
                                className={classes.navigationPrev}
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: isPrevDisabled ? '#737070' : '#0092e6' }}
                                onClick={() => actions.handleSuiteChange('prev')}
                            >
                                <KeyboardArrowLeft
                                    style={{ fontSize: '20px', marginRight: '4px' }}
                                    aria-label="keyboardArrowLeftIcon"
                                    id="keyboardArrowLeftIcon"
                                />
                                <Typography style={{ color: '#fff' }}>Prev</Typography>
                            </Button>
                        </Tooltip>
                        <Tooltip data={checkObject(testSuiteReports[nextIndex]) ? testSuiteReports[nextIndex].suite_name : null}>
                            <Button
                                className={classes.navigationNext}
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: isNextDisabled ? '#737070' : '#0092e6' }}
                                onClick={() => actions.handleSuiteChange('next')}
                            >
                                <Typography style={{ color: '#fff' }}>Next</Typography>
                                <KeyboardArrowRight
                                    style={{ fontSize: '20px', marginRight: '4px' }}
                                    aria-label="keyboardArrowRightIcon"
                                    id="keyboardArrowRightIcon"
                                />
                            </Button>
                        </Tooltip>
                            <Tooltip data = {'Refresh'} >
                                <IconButton 
                                  style={{marginLeft:'4px'}}
                                  className={classes.IconButton}
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      props.reload();
                                }}
                                disabled = {props.isLoading}
                                >
                                <RefreshIcon
                                className={[

                                    classes.refreshIcon,
                                    `${!props.isLoading ? classes.refreshIconBtnEnabled : classes.refreshIconBtnDisabled}`
                                    ].join(' ')}
                                aria-label="refreshIcon"
                                id="refreshIcon"
                                />
                                </IconButton>
                            </Tooltip> 
                    </div>
                    <p className={classes.suiteName}>
                        <Tooltip data={` ${suiteName}`}>
                            {suiteName}
                        </Tooltip>
                    </p>
                </div>
                <div className={classes.suiteDetailContainer}>
                    {/* <div className={classes.suiteDetail}>
                        <p className={classes.suiteDetailKey}>{`Total Cases:`}</p>
                        <p className={classes.suiteDetailValue}>{totalTestsFailedCount + totalTestsPassedCount}</p>
                    </div> */}
                    {/* <span style={{ borderRight: '1px solid #cdcdcd', height: '35px' }} /> */}
                    <div className={classes.suiteDetail}>
                        <p className={classes.suiteDetailKey}>{`Suite Status:`}</p>
                        <p className={classes.suiteDetailValue}>{updatedExecutionStatus}</p>
                    </div>
                    <span style={{ borderRight: '1px solid #cdcdcd', height: '35px' }} />
                    <div className={classes.suiteDetail}>
                        <p className={classes.suiteDetailKey}>Pass/Fail Count:</p>
                        <p className={classes.suiteDetailValue}>{`${totalTestsPassedCount}(Pass)/${totalTestsFailedCount}(Fail)`}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

SuiteCaseReportHeader.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    actions: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({
        suiteName: PropTypes.string.isRequired,
        totalTestsFailedCount: PropTypes.number.isRequired,
        totalTestsPassedCount: PropTypes.number.isRequired,
    }).isRequired,
};
SuiteCaseReportHeader.defaultProps = {};

export default withStyles(styles)(SuiteCaseReportHeader);
