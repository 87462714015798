class TestScriptUtil {
    getBrowserOptionValue = (browser) => {
        let updateBrowserName = browser;
        switch (updateBrowserName.toLowerCase()) {
            case 'edgebeta':
                updateBrowserName = 'Edge-beta';
                break;
            case 'edge-beta':
                updateBrowserName = 'Edge';
                break;
            case 'internet explorer':
            case 'ie':
                updateBrowserName = 'internetExplorer';
                break;
            case 'internetexplorer':
                updateBrowserName = 'internetExplorer';
                break;
            case 'microsoftedge':
                updateBrowserName = 'edge';
                break;
            default:
                break;
        }
        return updateBrowserName;
    };

    getBrowserValueForSend = (browser, modaltype = 'zalenium') => {
        let updateBrowserName = browser;
        switch (updateBrowserName.toLowerCase()) {
            case 'edge-beta':
                updateBrowserName = (modaltype === 'saucelabs') ? 'MicrosoftEdge' : 'EdgeBeta';
                break;
            case 'edge':
                updateBrowserName = (modaltype === 'saucelabs') ? 'MicrosoftEdge' : 'EdgeBeta';
                break;
            case 'internet explorer':
                updateBrowserName = (modaltype === 'saucelabs') ? 'internet explorer' : 'Internet Explorer';
                break;
            case 'ie':
                updateBrowserName = (modaltype === 'saucelabs') ? 'internet explorer' : 'Internet Explorer';
                break;
            case 'internetexplorer':
                updateBrowserName = (modaltype === 'saucelabs') ? 'internet explorer' : 'Internet Explorer';
                break;
            case 'msedge':
                updateBrowserName = (modaltype === 'saucelabs') ? 'MicrosoftEdge' : 'EdgeBeta';
                break;
            case 'realdevice':
                updateBrowserName = (modaltype === 'saucelabs') ? 'Android Real Device' : 'Android Real Device';
                break;
            case 'iosrealdevice':
                updateBrowserName = (modaltype === 'saucelabs') ? 'IOS Real Device' : 'IOS Real Device';
                break;
            case 'iosemulator':
                updateBrowserName = (modaltype === 'saucelabs') ? 'IOS Emulator' : 'IOS Emulator';
                break;
            case 'safari':
                updateBrowserName = (modaltype === 'saucelabs') ? 'safari' : updateBrowserName;
                break;
            case 'chrome':
                updateBrowserName = (modaltype === 'saucelabs') ? 'chrome' : updateBrowserName;
                break;
            case 'firefox':
                updateBrowserName = (modaltype === 'saucelabs') ? 'firefox' : updateBrowserName;
                break;
        
            default:
                break;
        }
        return updateBrowserName;
    };

    getPlatformValueForSend = (platform) => {
        const reg = new RegExp(/windows|macos|android|ios|linux?/gi);
        let updatePlatformName = platform.match(reg)[0];
        switch (updatePlatformName.toLowerCase()) {
            case 'macos':
                updatePlatformName = 'Apple';
                break;
            case 'android':
                updatePlatformName = 'Android';
                break;
            case 'windows':
                updatePlatformName = 'Windows';
                break;
            case 'ios':
                updatePlatformName = 'Apple';
                break;
            default:
                break;
        }
        return updatePlatformName;
    };
}

export const TestScriptUtils = new TestScriptUtil();
