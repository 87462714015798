import { ActionTypes } from '../constants';
import ProjectsMiddleWare from '../middleware/projectsMiddleware';
import { SidebarActions } from './SidebarAction';

class ProjectsActions {
    static getDisabledProjects(callingFrom = '') {
        return (dispatch) => {
            if (callingFrom !== 'ProjectModal' && callingFrom !== 'ProjectCardActions') {
                dispatch({ type: ActionTypes.GET_DISABLED_PROJECTS });
            }
            ProjectsMiddleWare.getDisabledProjects()
                .then((data) =>
                    dispatch({
                        type: ActionTypes.GET_DISABLED_PROJECTS_SUCCESS,
                        payload: { data },
                    }),
                )
                .catch(() =>
                    dispatch({
                        type: ActionTypes.GET_DISABLED_PROJECTS_FAILURE,
                        payload: {
                            message: 'Could not get disabled projects',
                        },
                    }),
                );
        };
    }

    static enableProject(projectIds) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.ENABLE_PROJECTS });
            ProjectsMiddleWare.enableProject(projectIds)
                .then((data) => {
                    dispatch(SidebarActions.increaseSidebarCount('num_of_projects'));
                    dispatch(SidebarActions.decreaseSidebarCount('num_of_disable_projects'));
                    return dispatch({
                        type: ActionTypes.ENABLE_PROJECTS_SUCCESS,
                        payload: { data, message: 'Enabled project(s) successfully.' },
                    });
                })
                .catch(() => {
                    dispatch({
                        type: ActionTypes.ENABLE_PROJECTS_FAILURE,
                        payload: {
                            message: 'Could not able to enable project',
                        },
                    });
                });
        };
    }

    static showEnableProjects() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.SHOW_ENABLE_PROJECTS });
        };
    }

    static updatedebugPointList(debugPointList) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.UPDATE_DEBUG_POINT_LIST,
                payload: { debugPointList },
            });
        };
    }
}

export default ProjectsActions;
