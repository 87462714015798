import React from "react";
import PropTypes from 'prop-types';

import macos_logo from '../../../assets/icons/platforms/macos.svg';
import windows_logo from '../../../assets/icons/platforms/windows.svg';
import linux_logo from '../../../assets/icons/platforms/linux.svg';
import android_logo from '../../../assets/icons/platforms/android.svg';
import default_logo from '../../../assets/icons/platforms/default.svg';

const PlatformIcon = ({platformName,classes}) => {

    const getLogo = (platformName) => {

        if(!platformName) {
            return null
        }

        const platform_map = {
            macos:macos_logo,
            windows:windows_logo,
            linux:linux_logo,
            android:android_logo
        }

        return platform_map[platformName] || default_logo
    }

    return (

        <React.Fragment>
            {
                platformName  &&
                <img width={16} style={{marginRight:'12px'}}  src={getLogo(platformName)}/>

            }
        </React.Fragment>
    )

}

PlatformIcon.propTypes = {
    platformName:PropTypes.string.isRequired
}
export default PlatformIcon

 