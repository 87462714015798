import React from 'react';
import './box.css';

class SelectSerachBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }

    render() {
        const { options, value, onSelect, onChange, height = 160 } = this.props;
        const { show } = this.state;
        return (
            <section style={{ width: '65%' }} className="main">
                <form className="search" onSubmit={(e) => e.preventDefault()}>
                    <input
                        style={{ border: 'none', borderBottom: '1px solid', boxShadow: 'none', fontSize: 12 }}
                        className="PSN"
                        onFocus={() => this.setState({ show: true })}
                        onChange={(e) => {
                            onChange(e.target.value);
                            this.setState({ show: true });
                        }}
                        type="text"
                        value={value}
                        placeholder="Search"
                    />
                    {show && (
                        <ul className="results" style={{ maxHeight: height }} onMouseLeave={() => this.setState({ show: false })}>
                            {options.map((data, key) => {
                                if (data.label.toLowerCase().includes(value.toLowerCase())) {
                                    return (
                                        <li key={key} aria-hidden onClick={() => onSelect(data)} className="names">
                                            {data.label}
                                        </li>
                                    );
                                }
                                return null;
                            })}
                        </ul>
                    )}
                </form>
            </section>
        );
    }
}

export default SelectSerachBox;
