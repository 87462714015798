
export const isAccountName = /^[a-z]+[a-z0-9_]*$/;

export const isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// PASSWORD_REGEX regex will test the string for following conditions 
// At least 8 characters
// At least one capital letter
// At least one small letter
// At least one special character
// At least one number
// Maximum 64 characters
// No spaces

export const PASSWORD_REGEX = /^(?!.*[\s])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,64}$/

export const PASSWORD_REGEX_APP_USER = /^(?!.*[\s])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{15,64}$/

export const ATLEAST_ONE_CAPITAL_REGEX = /^(?=.*[A-Z])/;

export const ATLEAST_ONE_SMALL_REGEX = /^(?=.*[a-z])/;

export const ATLEAST_ONE_SPECIAL_CHAR_REGEX = /^(?=.*[@#$!%*?&])/;

export const ATLEAST_ONE_NUMBER_REGEX = /(?=.*\d)/;