import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';

import { useStyles } from './CaseRow/styles';
import useContainer from './container';
import { checkArrayLength } from '../../../utils';
import TableBody from '../../../components/Table/TableBody';
import Table from '../../../components/Table/Table';
import EnhancedTableHead from '../../../components/EnhancedTable/EnhancedTableHead_v2';
import NoDataToShow from '../../../components/NoDataToShow';
//import NoDataToShow from './emptyFirstTime';
import ImgSrc from '../../../components/NoDataToShow/assests/caseTable.svg';
import TableFooter from '../../../common/TableFooter';
import CaseTableRow from './CaseRow/CaseTableRow';
import { headers } from './constants';
import CaseHeader from './CaseHeader';

const CaseTable = (props) => {
    const classes = useStyles();
    const {
        redux,
        state,
        handleRequestSort,
        handleChangePage,
        handleChangeRowsPerPage,
        createTestCase,
        recordTestCase,
        handleSelectAllClick,
        handleSelect,
    } = useContainer(props);

    const [currentPageCases, setCurrentPageCases] = useState([]);
    const [showTagArea, setShowTagArea] = useState(false);
    const [isHovered, setHovered] = useState(null)

    const { filteredTestCases, page, rowsPerPage } = state;
    useEffect(() => {
        const _currentPageCases = filteredTestCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        const _showTagArea = checkArrayLength(_currentPageCases) && _currentPageCases.some((tc) => checkArrayLength(tc.tags));
        setCurrentPageCases(_currentPageCases);
        setShowTagArea(_showTagArea);
    }, [filteredTestCases, page, rowsPerPage]);

    return (
        <div className={classes.root}>
            {(props.showLoader || redux.isLoading || redux.isLoadingCases || (!redux.getPreferences && !redux.getPreferencesErr)) &&
            !checkArrayLength(state.filteredTestCases) ? (
                <div style={{ minHeight: 'calc(100vh - 214px)' }}>
                    <CircularProgress style={{ margin: '100px 50% 0px', color: '#4885ed' }} size={60} />
                </div>
            ) : checkArrayLength(state.filteredTestCases) ? (
                [
                    <div className={classes.tableRoot} key="root">
                        <CaseHeader
                            appName={`${redux.project?.projectName} App`}
                            appUrl={redux.project?.appUrl || ''}
                            date={redux.project?.lastActivityDate}
                            recordTestCase={recordTestCase}
                            projectSystemId={redux.project?.projectId}
                            createTestCase={createTestCase}
                            numSelected={checkArrayLength(currentPageCases) ?  currentPageCases.filter((testCase) => state.selectedCases.includes(testCase.testCaseId)).length : 0}
                            testCaseCount = { checkArrayLength(currentPageCases) }
                        />
                        <div className={classes.tableContainer}>
                            <Table aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    //showSelectBtn
                                    showDeleteBtn = {currentPageCases.filter((testCase) => state.selectedCases.includes(testCase.testCaseId)).length === 1 ? true : false}
                                    deletebuttonColIndex={1}
                                    headers={headers}
                                    order={state.order}
                                    orderBy={state.orderBy}
                                    onRequestSort={handleRequestSort}
                                    noResizeColsIndex={[0, 1, 6, 7, 8, 9, 10, 11]}
                                    numSelected={currentPageCases.filter((testCase) => state.selectedCases.includes(testCase.testCaseId)).length}
                                    onSelectAllClick={(e) => {
                                        handleSelectAllClick(e, currentPageCases);
                                    }}
                                    onDeleteClick={() => {}}
                                    rowCount={currentPageCases.length}
                                />
                                <TableBody isTableBodyStyles={true} height={50} style={{ maxHeight: 'calc(100vh - 330px)' }} className={classes.tableRowContainer}>
                                    {checkArrayLength(currentPageCases) &&
                                        currentPageCases.map((testCase, i) => {
                                            if (testCase) {
                                                return (
                                                    <CaseTableRow
                                                        key={i}
                                                        index={i}
                                                        heads={headers}
                                                        showTagArea={showTagArea}
                                                        getPreferences={redux.getPreferences}
                                                        testCase={testCase}
                                                        // cols={state.cols}
                                                        isHovered={isHovered}
                                                        setHovered={setHovered}
                                                        isChecked={state.selectedCases.includes(testCase.testCaseId)}
                                                        projectId={props.projectSystemId}
                                                        history={props.history}
                                                        match={props.match}
                                                        previewTestCase={props.previewTestCase}
                                                        previewTestScript={props.previewTestScript}
                                                        previewTestData={props.previewTestData}
                                                        handleSelect={handleSelect}
                                                    />
                                                );
                                            }
                                            return null;
                                        })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>,
                    <TableFooter
                        key="footer"
                        count={state.filteredTestCases.length}
                        rowsPerPage={state.rowsPerPage}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        page={state.page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        applyCustomClasses
                        labelRowsPerPage="Cases per page:"
                        containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                    />,
                ]
            ) : ([
                    <div className={classes.tableRoot} key="root">
                        <CaseHeader
                            appName={`${redux.project?.projectName} App`}
                            appUrl={redux.project?.appUrl || ''}
                            date={redux.project?.lastActivityDate}
                            recordTestCase={recordTestCase}
                            projectSystemId={redux.project?.projectId}
                            createTestCase={createTestCase}
                            numSelected={checkArrayLength(currentPageCases) ?  currentPageCases.filter((testCase) => state.selectedCases.includes(testCase.testCaseId)).length : 0}
                            testCaseCount = { checkArrayLength(currentPageCases) }
                        />
                        <NoDataToShow
                            isIcon={true}
                            imgSrc={ImgSrc}
                            IconSrc={AddIcon}
                            selectedTab="case"
                            buttonTitle = "ADD TEST CASE"
                            projectSystemId={redux.project?.projectId}
                            onClick={createTestCase}
                        />
                    </div>,
                    <TableFooter
                        key="footer"
                        count={state.filteredTestCases.length}
                        rowsPerPage={state.rowsPerPage}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        page={state.page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        applyCustomClasses
                        labelRowsPerPage="Cases per page:"
                        containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                    />,
                ] )}
        </div>
    );
};

export default CaseTable;