import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import Tooltip from '../../../components/Tooltip';
import ConfirmationDialogContent from './ConfirmationDialogContent';

const styles = (theme) => ({
    dialogContent: {
        overflow: 'hidden',
        minWidth: 'calc(100vh - 250px)',
        padding: '0 40px 0 30px !important',
        maxHeight: 'calc(100vh - 290px)',
        overflowY: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 100px)',
        },
    },
    dataName: {
        color: '#3B91DF',
        fontWeight: 'bold',

        display: 'inline-block',
        maxWidth: 'calc(100% - 210px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        margin: '0 5px -6px 5px',
    },
});

const ConfirmationModal = ({ classes, data, msg, dataName }) => {
    const { oldDataToChange, newData } = data;
    return (
        <div>
            <DialogContent className={classes.dialogContent}>
                {msg && (
                    <div>
                        <Typography>
                            {msg}
                            <span className={classes.dataName}>
                                <Tooltip data={dataName}>{dataName}</Tooltip>
                            </span>
                            ?
                        </Typography>
                    </div>
                )}

                <ConfirmationDialogContent caseData={oldDataToChange} title="FROM" />

                <ConfirmationDialogContent caseData={newData} title="TO" />
            </DialogContent>
        </div>
    );
};

export default withStyles(styles)(ConfirmationModal);
