import React from 'react';

// Components
import ExecutionHeaderTop from './ExecutionHeaderTop';
import ExecutionHeaderMiddle from './ExecutionHeaderMiddle';

const ExecutionHeader = (props) => {
    const { history, paramTestCaseId, paramProjectId, paramExecutionId, total_time } = props;

    return (
        <div>
            <ExecutionHeaderTop paramTestCaseId={paramTestCaseId} paramExecutionId={paramExecutionId} />
            <ExecutionHeaderMiddle history={history} paramTestCaseId={paramTestCaseId} paramProjectId={paramProjectId} paramExecutionId={paramExecutionId} total_time={total_time} />
            
        </div>
    );
};

export default ExecutionHeader;
