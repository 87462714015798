import { combineReducers } from 'redux';

import accountReducer from './accountReducer';
import authReducer from './authReducer';
import flowReducer from './FlowReducer';
import executionReducer from './ExecutionReducer';
import dashboardReducer from './dashboardReducer';
import alertReducer from './alertReducer';
import suiteReportReducer from './suiteReportReducer';
import modalReducer from './modalReducers';
import generalReducer from './generalReducers';
import projectReducer from './projectReducer';
import selectedTestCaseReducer from './selectedTestCaseReducer';
import scheduleReducer from './scheduleReducer';
import uploadProgressReducer from './uploadProgressReducer';
import userReducer from './userReducer';
import taskReducer from './taskReducer';
import tagReducer from './TagReducer';
import tunnelReducer from './TunnelReducer';
import testStepReducer from './testStepReducer';
import projectsReducer from './projectsReducer';
import generalModalReducer from './generalModalReducer';
import testCaseReducer from './TestCaseReducer';
import imagesReducer from './imagesReducer';
import sidebarReducer from './sidebarReducer';
import selectedExecutionReducer from './selectedExecutionReducer';
import alertBarReducer from './alertBarReducer';

import { ActionTypes } from '../constants';

const appReducer = combineReducers({
    accountReducer,
    authReducer,
    dashboardReducer,
    alertReducer,
    executionReducer,
    flowReducer,
    generalReducer,
    modalReducer,
    projectReducer,
    projectsReducer,
    suiteReportReducer,
    selectedTestCaseReducer,
    tagReducer,
    taskReducer,
    tunnelReducer,
    testStepReducer,
    uploadProgressReducer,
    scheduleReducer,
    userReducer,
    generalModalReducer,
    testCaseReducer,
    imagesReducer,
    sidebarReducer,
    selectedExecutionReducer,
    alertBarReducer
});

const RootReducer = (state, action) => {
    if (action.type === ActionTypes.LOGOUT_SUCCESS) {
        state = undefined;
    }

    return appReducer(state, action);
};

export {
    accountReducer,
    authReducer,
    flowReducer,
    dashboardReducer,
    alertReducer,
    generalReducer,
    modalReducer,
    projectReducer,
    suiteReportReducer,
    selectedTestCaseReducer,
    uploadProgressReducer,
    userReducer,
    taskReducer,
    testStepReducer,
    testCaseReducer,
    imagesReducer,
    // Root Reducer
    RootReducer,
    selectedExecutionReducer,
    alertBarReducer
};
