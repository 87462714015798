import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// icons
import MoreVert from '@material-ui/icons/MoreVert';

import { THREE_DOT_MENU_CSS } from '../../common/cssConstants';
import ActionMenu from '../Menu/ActionMenu';
import { checkArrayLength } from '../../utils';

const styles = () => ({
    iconButton: {
        height: 'auto',
        width: 'auto',
       
    },
    actionButtonsDiv: {
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBotton: '3rem',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        fontSize: THREE_DOT_MENU_CSS.iconFontSize,
        width: 22,
        height: 22,
    },
});

class MenuActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleMenuToggle = (event) => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget });
        this.props.handleMenuToggle(event.currentTarget);
        if(typeof this.props.column === 'number' && typeof this.props.row === 'number') {
            this.props.handleHoverOnTable(this.props.row, this.props.column)
        }
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        if(typeof this.props.column === 'number' && typeof this.props.row === 'number') {
            this.props.handleHoverOnTable(null,null)
        }
    };

    render() {
        const { classes, btn, containerStyle, items, iconButtonDisabled, keepMounted, MoreVertStyle, customClasses } = this.props;
        const { anchorEl } = this.state;
        return (
            <div className={`${classes.actionButtonsDiv} ${customClasses}`} style={containerStyle || {}}>
                {btn ? (
                    <span aria-hidden onClick={(e) => this.handleMenuToggle(e)}>
                        {btn}
                    </span>
                ) : (
                    <IconButton className={classes.iconButton} disabled={iconButtonDisabled} onClick={(e) => this.handleMenuToggle(e)}>
                        <MoreVert
                            aria-label="MoreVert"
                            id="MoreVert"
                            aria-owns={anchorEl ? 'simple-menu' : null}
                            aria-haspopup="true"
                            className={`${classes.moreIconsBtn}`}
                            style={{ ...MoreVertStyle }}
                        />
                    </IconButton>
                )}
                {anchorEl ? (
                    <Menu style={{marginTop:'2rem'}} id="simple-menu" anchorEl={anchorEl} placement="bottom-end" keepMounted={keepMounted} open={Boolean(anchorEl)} onClose={this.handleMenuClose}>
                        <ActionMenu
                       
                            placement="left"
                            items={
                                !checkArrayLength(items)
                                    ? items
                                    : items.map((item) => {
                                          return {
                                              ...item,
                                              action: (e) => {
                                                  if (item.action) {
                                                      item.action(e);
                                                      this.handleMenuClose();
                                                  }
                                              },
                                          };
                                      })
                            }
                        />
                    </Menu>
                ) : null}
            </div>
        );
    }
}

MenuActions.propTypes = {
    customClasses: PropTypes.string,
    classes: PropTypes.shape({}).isRequired,
    containerStyle: PropTypes.object,
    items: PropTypes.array.isRequired,
    iconButtonDisabled: PropTypes.bool,
    keepMounted: PropTypes.bool,
    MoreVertStyle: PropTypes.object,
    handleMenuToggle: PropTypes.func,
};

MenuActions.defaultProps = {
    containerStyle: {},
    iconButtonDisabled: false,
    keepMounted: false,
    MoreVertStyle: {},
    handleMenuToggle: () => {},
};

const MenuOptions = withStyles(styles)(MenuActions);
export { MenuOptions };
