import React from "react";
import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const InstructionAlertModal = ({open,instructionTitle='',instructionMessage='',instructionButtonText='OK',instructionType='default',onConfirm}) => {

const getTitleColor = () => {

    let titleColorMap = {
        default:'',
        success:'#5D9D52',
        error:'red',
        warning:'#FDF3D1',
        info:'#2DA6EA'
    }

    return titleColorMap[instructionType]

}

const onClose = () => {
    if(onConfirm) {
        onConfirm()
    }

}
return (
    <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
          {
              instructionTitle &&
              <DialogTitle style={{
                  color:getTitleColor()
              }} id="alert-dialog-title">
                  {instructionTitle}
              </DialogTitle>
          }
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              {instructionMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            {instructionButtonText}
          </Button>
        </DialogActions>
      </Dialog>

    )

}

export default InstructionAlertModal;