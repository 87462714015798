import React from 'react';
// import ListIcon from 'mdi-material-ui/FormatListNumbers';
import ListIcon from 'mdi-material-ui/FormatListNumbered';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = () => ({
    noStepText: {
        color: '#78909c',
        textAlign: 'center',
        fontSize: '20px',
        paddingTop: 8,
    },
    noStepContainer: {
        height: '180px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
    },
    listIcon: {
        fontSize: 48,
        color: '#8898A8',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
});

const NoStepContainer = ({ text, classes }) => {
    return (
        <div className={classes.noStepContainer}>
            <span className={classes.iconContainer}>
                <ListIcon className={classes.listIcon} />
                <Typography variant="h1" className={classes.noStepText}>
                    {text}
                </Typography>
            </span>
        </div>
    );
};

export default withStyles(styles)(NoStepContainer);
