// import material
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, IconButton } from '@material-ui/core';

// import packages
import React from 'react';
import { connect } from 'react-redux';

// custom
import {
    checkArrayLength,
    checkObject,
    checkKeyInObject,
    addListener,
    removeAllListeners,
    copyToClipboardNonSecureFunction,
} from '../../../../utils/utils';
import { SelectedTestCaseActions, ModalActions, TestCaseActions } from '../../../../store/actions';
import { TestStepUtils } from '../../../../utils/TestStepUtils';
import DeleteAlertModal from '../../../modal/Delete/DeleteAlertModal';
import Tooltip from '../../../../components/Tooltip';
import { BLACK_FONT } from '../../../../common/cssConstants';
import { NEW_MOCK_STEP } from '../../../../common/constants';

import FilesIcon from '../../../../assets/images/copy.svg';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';

const style = () => ({
    actionBtn: {
        padding: '0px 4px',
        minHeight: 0,
        borderColor: '#b8c4cf',
        textTransform: 'capitalize',
        borderRadius: '2px',
        minWidth: '80px',
        height: '20px',
        backgroundColor: '#fff',
        '& p': {
            color: '#3B91DF',
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
    },
    dragActionButtonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    dragActionButtonsStyle: {
        color: '#0000FF',
        cursor: 'pointer',
        fontWeight: 500,
    },
    root: {
        minWidth: '332px',
        height: 34,
        backgroundColor: '#ffffff',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '290px',
    },
    text: {
        fontSize: '12px',
        paddingTop: '2px',
        color: BLACK_FONT,
    },
    avatar: {
        minWidth: '18px',
        height: '18px',
        borderRadius: '18px',
        backgroundColor: '#3B91DF',
        color: '#fff',
        textAlign: 'center',
        marginRight: '8px',
        fontSize: '14px',
        lineHeight: 1.4,
        padding: '0 4px',
        display: 'inline-block',
    },
    iconBtn: {
        marginLeft: '11px',
        fontSize: '16px',
        marginTop: '9px',
        color: '#3B91DF',
    },
    selectBtn: {
        maxWidth: '125px',
    },
    flowBtn: {
        marginLeft: '8px',
    },
    copyBtn: {
        fontSize: '21px',
        marginTop: '7px',
    },
    containerFirstChild: {
        alignItems: 'center',
        display: 'flex',
        paddingLeft: '10px',
    },
    sortableActionContainer: {
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'flex-end',
        paddingRight: '20px',
    },
    stepTimerGrid: {
        display: 'flex',
        marginTop: '7px',
        justifyContent: 'flex-end',
        paddingRight: '20px',
    },
    stepTimerText: {
        color: '#587c99',
        fontWeight: '500',
        fontSize: '12px',
        marginRight: '10px',
    },
    ml10:{
        marginLeft: '10px'
    }
});

class HeaderBottom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAlertDialog: false,
        };
    }

    /* Component life cycle start */
    async componentDidMount() {
        // /* Un Comment if want to allow user to copy steps by pressing Ctrl+C */
        // addListener(document, "copy", (event) => {
        //     event.preventDefault();
        //     const { selectedSteps } = this.props;
        //     if (checkArrayLength(selectedSteps)) {
        //         this.copySteps(selectedSteps);
        //     }
        // })
        addListener(document, 'paste', (event) => {
            if (this.props.isShowPasteButton && this.isValidFocusedElementForPaste()) {
                const coppiedData = event.clipboardData.getData('text/plain');
                if (coppiedData) {
                    this.pasteSteps(coppiedData);
                }
            }
        });
    }

    componentWillUnmount() {
        removeAllListeners(document, 'copy');
        removeAllListeners(document, 'paste');
    }

    /* Delete steps start */
    onClickAlertDialogOpen = () => {
        this.setState({ openAlertDialog: true });
        this.props.setActionType('delete');
    };

    onClickAlertDialogClose = (choice) => {
        const { selectedSteps } = this.props;
        if (choice) {
            TestStepUtils.handleDeleteSteps(selectedSteps);
        }
        this.props.setActionType('');
        this.setState({ openAlertDialog: false });
    };
    /* Delete steps end */

    getPasteData = async () => {
        if (navigator.clipboard && navigator.clipboard.readText) {
            try {
                let coppiedData = '';
                coppiedData = await navigator.clipboard.readText(); // no need to validate
                if (coppiedData) {
                    this.pasteSteps(coppiedData);
                }
            } catch (error) {
                this.props.toggleSnackBar('Permission denied. To paste manually, press Ctrl+v or Cmd+v for mac.', '', false, 10000, true);
            }
        } else {
            this.props.toggleSnackBar('For security reason please use Ctrl+v or Cmd+v for mac to do paste.', '', false, 10000, true);
        }
    };

    removeChild = (instrArray) => {
        let filteredArray = JSON.parse(JSON.stringify(instrArray));
        for (let i = 0; i < filteredArray.length; i++) {
            const child = filteredArray[i];
            filteredArray = filteredArray.filter((current) => {
                return `${current}`.indexOf(`${child}.`) !== 0;
            });
        }
        return filteredArray;
    };

    /* Component life cycle end */

    /* Copy pase functionality start */
    copySteps = (selectedSteps) => {
        const filter_selectedSteps = this.removeChild(selectedSteps);
        const { testSteps, instrNumArray } = this.props;
        if (checkObject(testSteps)) {
            let dataToBeCopy = '';
            instrNumArray.forEach((instrNum) => {
                if (filter_selectedSteps.includes(instrNum)) {
                    if (dataToBeCopy !== '') {
                        dataToBeCopy += '\n';
                    }
                    const testStep = testSteps[instrNum];
                    let instruction = checkKeyInObject(testStep, 'instr', 'value', '');
                    let data = checkKeyInObject(testStep, 'data', 'value', '');
                    let expectedResults = checkKeyInObject(testStep, 'expectedResults', 'value', '');
                    const childSteps = instrNumArray.filter((id) => `${id}`.indexOf(`${instrNum}.`) === 0);
                    if (checkArrayLength(childSteps)) {
                        instruction = '"';
                        data = '"';
                        expectedResults = '"';
                        childSteps.forEach((childInstrNum, ind) => {
                            const childTestStep = testSteps[childInstrNum];
                            instruction += `${checkKeyInObject(childTestStep, 'instr', 'value', '')}`.replace(/"/g, '""');
                            data += `${checkKeyInObject(childTestStep, 'data', 'value', '')}`.replace(/"/g, '""');
                            expectedResults += `${checkKeyInObject(childTestStep, 'expectedResults', 'value', '')}`.replace(/"/g, '""');
                            if (ind < childSteps.length - 1) {
                                instruction += '\n';
                                data += '\n';
                                expectedResults += '\n';
                            }
                        });
                        instruction += '"';
                        data += '"';
                        expectedResults += '"';
                    }
                    dataToBeCopy += `${instruction}\t${data}\t${expectedResults}`;
                }
            });
            if (dataToBeCopy) {
                if (navigator.clipboard && navigator.clipboard.writeText) {
                    navigator.clipboard.writeText(dataToBeCopy);
                } else {
                    copyToClipboardNonSecureFunction(dataToBeCopy);
                }
                this.props.toggleSnackBar('Copied steps successfully.', '', true);
            }
        }
    };

    pasteSteps = (coppiedData) => {
        const { instrNumArray } = this.props;
        coppiedData = coppiedData.replace(/["]{2}/g, '$quotes$');

        const parsedStringData = coppiedData.match(/\t"([^"]+)"/gm);
        if (checkArrayLength(parsedStringData)) {
            for (let i = 0; i < parsedStringData.length; i++) {
                coppiedData = coppiedData.replace(parsedStringData[i], parsedStringData[i].replace(/\n/g, '\\').replace(/"/g, ''));
            }
        }

        const parsedStringInstruction = coppiedData.match(/^"([^"]+)"/gm);
        if (checkArrayLength(parsedStringInstruction)) {
            for (let i = 0; i < parsedStringInstruction.length; i++) {
                coppiedData = coppiedData.replace(parsedStringInstruction[i], parsedStringInstruction[i].replace(/\n/g, '.').replace(/"/g, ''));
            }
        }

        coppiedData = coppiedData.replace(/\$quotes\$/g, '"');

        const data = [];
        const rows = coppiedData.split(/\n/g);
        rows.forEach((row) => {
            if (row) {
                const cols = row.replace(/(\r\n|\n|\r)/gm, '').split('\t');
                if (cols[0]) {
                    data.push({
                        testInstruction: cols[0] || '',
                        testData: cols[1] || '',
                        expectedResults: cols[2] || '',
                    });
                }
            }
        });
        if (data.length) {
            let lastInstrNum = '1';
            let index = 1;
            let newIndex = 1;
            if (checkArrayLength(instrNumArray)) {
                lastInstrNum = `${instrNumArray[instrNumArray.length - 1]}`.split('.')[0];
                index = instrNumArray.indexOf(lastInstrNum);
                newIndex = instrNumArray.length;
            }
            let { newInstrNums } = TestStepUtils.createMultiNewInstrNumbers(`${lastInstrNum}`, index);
            const testStep = {
                ...NEW_MOCK_STEP,
                id: newInstrNums[0],
                instrNum: newInstrNums[0],
            };
            newInstrNums = TestStepUtils.handleAddSteps(data, testStep, newIndex);
            this.props.selectMultipleSteps(newInstrNums);
            this.props.toggleSnackBar('Successfully paste steps.', '', true);
        }
    };

    isValidFocusedElementForPaste = () => {
        const focusedElement = document.activeElement;
        return !(
            focusedElement.tagName === 'TEXTAREA' ||
            (focusedElement.tagName === 'INPUT' &&
                (focusedElement.getAttribute('type') === 'text' || focusedElement.getAttribute('type') === 'password'))
        );
    };
    /* Copy pase functionality end */

    /* create Block work start */
    openCreateBlock = () => {
        const {
            selectedTestCase: { discoveryId },
            user: { accountId },
            selectedSteps,
            stepsType,
            testSteps,
        } = this.props;
        let currentTabSteps = [];
        let steps = [];
        let prevStepInstrNum = '';
        let isFirstSteps = '';
        let errorMessage = '';
        let parentSelected = '';
        if (checkObject(testSteps)) {
            currentTabSteps = Object.values(testSteps);
        }

        if (checkArrayLength(selectedSteps)) {
            // Need to sort selected steps
            selectedSteps.sort((a, b) => a - b);
            // we have only instrNum in selectedSteps
            isFirstSteps = selectedSteps[0];
            selectedSteps.some((instrNum) => {
                const isBlockStep =
                    checkArrayLength(currentTabSteps) && currentTabSteps.some((step) => `${step.instrNum}` === `${instrNum}` && step.isBlockStep);
                if (isBlockStep) {
                    errorMessage = 'Block steps are not allowed in another block';
                    return true;
                }
                if (instrNum && !`${instrNum}`.includes('.') && checkKeyInObject(testSteps[instrNum], 'hasChild', 'value', false)) {
                    errorMessage = 'Compound steps are not allowed';
                    return true;
                }
                if (instrNum && `${instrNum}`.includes('.') && !checkKeyInObject(testSteps[instrNum], 'isNew')) {
                    parentSelected = instrNum.split('.').shift();
                    if (`${prevStepInstrNum}` !== `${parentSelected}`) {
                        errorMessage = 'Child steps are not allowed';
                        return true;
                    }
                } else if (`${instrNum}` !== `${isFirstSteps}` && `${prevStepInstrNum}` !== `${instrNum - 1}` && selectedSteps.length > 1) {
                    if (`${prevStepInstrNum}` !== `${instrNum.split('.').shift()}`) {
                        errorMessage = 'Select only adjacent steps';
                        return true;
                    }
                }
                prevStepInstrNum = instrNum.split('.').shift();
                return false;
            });
            if (!errorMessage) {
                if (checkArrayLength(currentTabSteps)) {
                    currentTabSteps.forEach((step) => {
                        if (selectedSteps.some((instrNum) => step.instrNum === instrNum && (!instrNum.includes('.') || step.isNew))) {
                            const instr = step.instr && step.instr.split('\\').join('.');
                            steps.push({
                                stepId: step.stepId,
                                xpath: step.xpath,
                                instr,
                                instrNum: step.instrNum,
                                data: step.data,
                                expectedResults: step.expectedResults,
                            });
                        }
                    });
                }
                if (stepsType === 'recover') {
                    steps = steps.map((s) => ({
                        stepId: s.stepId,
                        xpath: s.xpath,
                        instr: s.instr,
                        instrNum: s.instrNum,
                        expectedResults: '',
                        data: s.data,
                    }));
                }
                if (checkArrayLength(steps)) {
                    const obj = {
                        accountId,
                        projectId: discoveryId,
                        name: '',
                        steps,
                    };
                    this.props.toggleBlockModal(true, obj);
                }
            } else {
                prevStepInstrNum = '';
                this.props.toggleSnackBar(errorMessage);
                errorMessage = '';
            }
        }
    };
    /* create Block work end */

    render() {
        const { classes, instrNumArray, selectedSteps, isShowPasteButton } = this.props;
        const { openAlertDialog } = this.state;
        
        return (
            <div className={classes.root}>
                <Checkbox  
                    onChange={(e) => {
                        this.props.toggleSelectAllTestSteps();
                    }}
                    style={{
                       
                        marginLeft: '20px',
                        color: selectedSteps === instrNumArray ? '#3b91df' : '#bac4ce',
                    }}
                    indeterminate={selectedSteps.length > 0  
                        && selectedSteps.length < instrNumArray.length  }
                    checked={checkArrayLength(instrNumArray) &&
                    checkArrayLength(selectedSteps) &&
                    instrNumArray.length === selectedSteps.length}  
                />
                <Tooltip data="Paste steps from clipboard">
                    <IconButton disabled={!isShowPasteButton} 
                     onClick={() => {
                        this.getPasteData();
                    }}
                     className={classes.ml10}>
                        <AssignmentOutlinedIcon
                            style={{ width: 24, cursor: 'pointer', fontSize: '22px', color: isShowPasteButton ? '#5F7B96' : '#C4C4C4' }}
                            aria-label="pasteStepsIcon"
                            id="pasteStepsIcon"
                           

                        />
                    </IconButton>
                </Tooltip>
                <Tooltip data="Copy selected steps to clipboard">
                    <IconButton disabled={selectedSteps.length <= 0} 
                     onClick={() => {
                        this.copySteps(selectedSteps);
                    }}
                    className={classes.ml10}>
                        <FileCopyOutlinedIcon
                            src={FilesIcon}
                            style={{ width: 24, cursor: 'pointer', fontSize: '22px', color: selectedSteps.length > 0 ? '#5F7B96' : '#C4C4C4' }}
                            aria-label="copyStepsIcon"
                            id="copyStepsIcon"
                           

                        />
                    </IconButton>
                </Tooltip>
                <Tooltip data="New Flow">
                    <IconButton disabled={selectedSteps.length <= 0} 
                    onClick={() => {
                        this.openCreateBlock();
                    }}
                    className={classes.ml10}>
                        <AccountTreeRoundedIcon
                            style={{
                                width: 24, cursor: 'pointer', fontSize: '22px',
                                // color: '#5F7B96',
                                color: selectedSteps.length > 0 ? '#5F7B96' : '#C4C4C4'
                            }}
                            aria-label="faLayerGroup"
                            id="faLayerGroup"
                            

                        />
                    </IconButton>
                </Tooltip>
                <Tooltip data="Delete">

                    <IconButton disabled={selectedSteps.length <= 0} 
                     onClick={() => {
                        this.onClickAlertDialogOpen();
                    }}
                    style={{marginLeft:10}}>
                        <DeleteIcon
                            style={{ width: 24, cursor: 'pointer', fontSize: '22px', color: selectedSteps.length > 0 ? '#5F7B96' : '#C4C4C4' }}
                            aria-label="faTrashAlt"
                            id="faTrashAlt"
                            className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                        />
                   </IconButton>
                </Tooltip>
                {openAlertDialog ? (
                    <DeleteAlertModal
                        deleteButton="Danger"
                        modalfor="liveTestSteps"
                        open={openAlertDialog}
                        handleClose={this.onClickAlertDialogClose}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // Auth Reducer
        user: state.authReducer.user,
        // project reducer
        selectedProject: state.projectReducer.selectedProject,
        selectedTestCase: state.projectReducer.selectedTestCase,
        // selected test case reducer
        instrNumArray: state.selectedTestCaseReducer.instrNumArray,
        testSteps: state.selectedTestCaseReducer.testSteps,
        listView: state.selectedTestCaseReducer.listView,
        stepsType: state.selectedTestCaseReducer.stepsType,
        selectedSteps: state.selectedTestCaseReducer.selectedSteps,
        isShowPasteButton: state.selectedTestCaseReducer.isShowPasteButton,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // modal actions
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        // Test Case Action
        toggleBlockModal: (...args) => dispatch(TestCaseActions.toggleBlockModal(...args)),
        // Selected Test Case Action
        setActionType: (flag) => dispatch(SelectedTestCaseActions.setActionType(flag)),
        toggleSelectAllTestSteps: () => dispatch(SelectedTestCaseActions.toggleSelectAllTestSteps()),
        selectMultipleSteps: (...args) => dispatch(SelectedTestCaseActions.selectMultipleSteps(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(HeaderBottom));
