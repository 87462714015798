/* eslint-disable no-template-curly-in-string */
// imports
import React from 'react';
import store from '../store';
import { checkKeyInObject, checkArrayLength, checkObject, getParamValues, generateAsterik, checkIsArray, extension } from './utils';
import { SelectedTestCaseActions, GeneralActions, ProjectsActions, TestStepActions } from '../store/actions';
import { TestCaseUtils } from '.';
import { NEW_MOCK_STEP } from '../common/constants';
import WSService from '../services/WSService';
import { FlowMiddleware } from '../store/middleware';
import singleFileCreationService from '../services/singleFileCreationService';
import config from '../config';
import defaultScreenShot from '../assets/images/noScreenshot.png';
import { ActionTypes } from '../store/constants';
// Global variables

class TestStepsUtil {
    // Class Level Variables
    stepsParentRef = React.createRef();

    stepsRef = {}; // for scroll to current running step [ object of refs ]

    /**
    |--------------------------------------------------
    | GENERAL FUNCTIONS START
    |--------------------------------------------------
    */
    setCacheXpath = (flag, selectedTestCaseReducer, instrNumArray = [], testSteps = {}) => {
        let _cacheXpaths = selectedTestCaseReducer.cacheXpaths;
        const _instrNumArray = checkArrayLength(instrNumArray) ? instrNumArray : selectedTestCaseReducer.instrNumArray;
        const _testSteps = checkObject(testSteps) ? testSteps : selectedTestCaseReducer.testSteps;
        if (checkArrayLength(_instrNumArray) && checkObject(_testSteps)) {
            if (flag) {
                _instrNumArray.forEach((instrNum) => {
                    _cacheXpaths = { ..._cacheXpaths, [instrNum]: !!_testSteps[instrNum].xpath };
                });
            } else {
                _instrNumArray.forEach((instrNum) => {
                    _cacheXpaths = { ..._cacheXpaths, [instrNum]: false };
                });
            }
        }
        return _cacheXpaths;
    };

    getStepsArray = () => {
        const { instrNumArray, testSteps } = store.getState().selectedTestCaseReducer;
        const testStepsArray = [];
        if (checkArrayLength(instrNumArray) && checkObject(testSteps)) {
            instrNumArray.map((instrNum) => {
                return testSteps[instrNum];
            });
        }
        return testStepsArray;
    };

    isShowAsterik = (key, value, empty) => {
        return this.isPassword(key) && value ? (empty !== undefined ? empty : generateAsterik(value.length)) : value;
    };

    isPassword = (key) => key && /pass[_\s-]*wo?r?d/i.test(key.toLowerCase());

    renderDataField = (testInstruction, testData, userVariables, testDataColumns) => {
        const re = new RegExp('^\\${(.*?)}$', 'i');
        if (testData && testData.match(re) && !testData.toLowerCase().includes("password") && userVariables.some(variable => variable.key === testData.replace(/[${}]/g, ''))) {
            // concat variable with its value if variable name doesn't contain password keyword and exist on variable TAB"
            //https://saucedev.atlassian.net/browse/TIQ-1318
            return `${testData} -> ${this.dataHoverPreview(testData, testInstruction, 'testData', userVariables, testDataColumns)}`;
        }
     
        return  this.isShowAsterik(testInstruction, testData);
    };

    dataHoverPreview = (testData, testInstruction, callingFrom, userVariables, testDataColumns) => {
        const re = new RegExp('^\\${(.*?)}$', 'i');
        let result = null;

        let varKey = null;

        if (testData && (typeof testData === 'string' || testData instanceof String)) {
            varKey = testData.match(re);
        }
        // searching them out from the given user variables array
        // unless the test data include ${} syntax within it.

        if (testData && varKey && Object.keys(varKey).length > 1) {
            if (checkArrayLength(userVariables)) {
                userVariables.forEach((userVariable) => {
                    if (`${userVariable.key}` === `${varKey[1]}`) {
                        result = userVariable.value;
                        // return result;   //forEach does'nt return anything.
                    }
                });
            }
            if (!result && checkObject(testDataColumns) && checkKeyInObject(testDataColumns, 'data') && testDataColumns.data) {
                const _testData = JSON.parse(testDataColumns.data);
                Object.keys(_testData).forEach((key) => {
                    if (`${key}` === `${varKey[1]}`) {
                        result = checkArrayLength(_testData[key]) ? _testData[key][0] : null;
                        // return result;   //forEach does'nt return anything.
                    }
                });
            }
        }
        result = result || testData;
        return result && typeof result === 'string' ? `${callingFrom === 'popper' ? ' | ' : ''}${result}` : '';
    };

    flowStepUpdated = (testStep) => {
        const { testSteps } = store.getState().selectedTestCaseReducer;
        const parentInstrNumSplitted = testStep.instrNum.split('.');
        // splitted it because of . in current instruction
        const parentInstrNum = parentInstrNumSplitted[parentInstrNumSplitted.length - 2];
        //  Last one is current step so we need to get second last instr num
        if (checkObject(testSteps) && checkObject(testSteps[parentInstrNum])) {
            testSteps[parentInstrNum].isChanged = true;
            store.dispatch(SelectedTestCaseActions.updateStepStatus(parentInstrNum, testSteps[parentInstrNum]));
        }
    };

    updateFlowAtPlatform = async (params) => {
        const { sortedSteps, step: parentBlockStep, index: parentBlockIndex, projectId } = params;
        const { blocks } = store.getState().flowReducer;
        const __testSteps = sortedSteps.slice(parentBlockIndex);
        const blockSteps = [];
        let isCallEnable = false;
        let response = false;
        let name = parentBlockStep.instr;

        const groups = name.match(/run\s+\$\{(.*)\}/i);
        if (checkArrayLength(groups) && groups.length > 1) {
            name = groups[1];
        }

        if (name) {
            isCallEnable = checkArrayLength(blocks) ? blocks.some((bl) => bl.name === name && bl.projectId === projectId) : false;
        }

        if (isCallEnable) {
            __testSteps.forEach((step) => {
                if (`${step.instrNum}`.indexOf(`${parentBlockStep.instrNum}.`) === 0) {
                    blockSteps.push({
                        instr: step.instr,
                        data: step.data,
                        expectedResults: step.expectedResults,
                        xpath: step.detectChanged ? '' : step.xpath, // if change is detected then we don't need to send xpath & stepId
                        stepId: step.detectChanged ? '' : step.stepId,
                    });
                }
            });

            const blockData = {
                accountId: WSService.getAccountId(),
                curPrjId: projectId,
                name,
                projectId,
                steps: blockSteps,
            };

            const result = await FlowMiddleware.updateFlow(blockData);
            if (result && result.status === 200) {
                response = true;
            }
        }
        return response;
    };

    toggleSmartPlayButtonKey = (instrNum, show, msgType = 16) => {
        const { testSteps } = store.getState().selectedTestCaseReducer;
        const key = msgType === 16 ? 'showDebugPlayButton' : 'showSmartRetryButton';
        if (checkKeyInObject(testSteps, instrNum)) {
            if (show) {
                testSteps[instrNum] = { ...testSteps[instrNum], [key]: show };
            } else {
                delete testSteps[instrNum][key];
            }
        }
        store.dispatch(SelectedTestCaseActions.updateTestStepsObject(testSteps));
    };

    /**
    |--------------------------------------------------
    | GENERAL FUNCTIONS END
    |--------------------------------------------------
    */
    /**
    |--------------------------------------------------
    | ADD STEPS START
    |--------------------------------------------------
    */
    getInstrNumToMatch = (instrNum) => {
        const split = instrNum.split('.');
        return split.slice(0, split.length - 1).join('.');
    };

    getIncrementedInstrNum = (instrNum, incrementor, stepLevel, preceder) => {
        if (stepLevel === undefined || preceder === undefined) {
            stepLevel = instrNum.split('.').length - 1;
            preceder = instrNum.slice(0, instrNum.lastIndexOf('.') + 1);
        }
        if (!instrNum) {
            instrNum = '0';
        }
        if (instrNum.indexOf(preceder) === 0) {
            const splittedRunningIndex = instrNum.split('.');
            if (splittedRunningIndex[stepLevel]) {
                const incr = Number(splittedRunningIndex[stepLevel]) + incrementor;
                if (incr > 0) {
                    splittedRunningIndex[stepLevel] = incr;
                } else {
                    splittedRunningIndex.splice(stepLevel, 1);
                }
                instrNum = splittedRunningIndex.join('.');
            }
        }
        return instrNum;
    };

    onAddStep = (currentIndex, flag, instrNum = -1, copyData = {}) => {
        if (flag) {
            // Function accepts selected instruction number and index
            //  return new intsruction number and index
            const { newInstrNums, newIndexes, updatedInstrNums, updatedTestSteps } = this.createMultiNewInstrNumbers(`${instrNum}`, currentIndex, 1);
            const newInstrNum = newInstrNums[0];
            const newIndex = newIndexes[0];
            const newStepObj = {
                ...NEW_MOCK_STEP,
                id: `${newInstrNum}`,
                instrNum: `${newInstrNum}`,
                copyData,
            };

            if (checkKeyInObject(copyData, 'sendToTestCaseParser', 'bool')) {
                newStepObj.sendToTestCaseParser = copyData.sendToTestCaseParser;
            }
            updatedTestSteps[newInstrNum] = newStepObj;

            // Scroll to specific step
            setTimeout(() => {
                const paramIdsArray = getParamValues([1, 2]);
                const paramProjectId = paramIdsArray[0];
                const paramTestCaseId = paramIdsArray[1];
                if (paramProjectId && paramTestCaseId && newInstrNum) {
                    this.scrollToSpecificStep(`${paramProjectId}_${paramTestCaseId}_${newInstrNum}`);
                }
            }, 500);

            // Set new step position with new Index to put new step on right position.
            store.dispatch(SelectedTestCaseActions.addDummyStepInStepsArray(flag, updatedInstrNums, updatedTestSteps, newIndex)); // to add in reducer
        }
    };

    createMultiNewInstrNumbers = (selectedInstrNums, index, count = 1, alreadyAdded = false) => {
        // if we give this function selectedInstrNum array instead of single selectedInstrNums we don't give it index, count and alreadyAdded
        // and selectedInstrNums if array it must be sorted
        const newInstrNums = [];
        const newIndexes = [];
        let updatedInstrNums = [];
        let updatedTestSteps = {};
        let remainingInstrNumArray = [];
        let { instrNumArray, testSteps } = store.getState().selectedTestCaseReducer;
        const updateData = (_index, new_instrNum) => {
            let __selectedInstrNum = '';
            if (checkArrayLength(selectedInstrNums)) {
                __selectedInstrNum = instrNumArray[_index] || '0';
                if (new_instrNum && __selectedInstrNum.split('.').length - 1 < new_instrNum.split('.').length - 1) {
                    __selectedInstrNum = new_instrNum;
                }
            } else {
                __selectedInstrNum = `${selectedInstrNums}` || '0';
            }
            const stepLevel = __selectedInstrNum.split('.').length - 1;
            const preceder = __selectedInstrNum.slice(0, __selectedInstrNum.lastIndexOf('.') + 1);

            updatedTestSteps = JSON.parse(JSON.stringify(testSteps));
            remainingInstrNumArray = [...instrNumArray];
            updatedInstrNums = [
                ...remainingInstrNumArray.splice(
                    0,
                    _index + 1 + remainingInstrNumArray.filter((instr) => instr.indexOf(`${__selectedInstrNum}.`) === 0).length,
                ),
            ]; // split in to 2 arrays 0 to index & index + 1 to end

            const fillNewArray = (instrNum) => {
                let instr = instrNum || updatedInstrNums[updatedInstrNums.length - 1];
                let shouldAdd = true;
                if (instrNum === 0 && typeof selectedInstrNums === 'string') {
                    if (instr && instr.indexOf(`${selectedInstrNums}.`) === 0) {
                        instr = selectedInstrNums;
                    } else if (!alreadyAdded && updatedInstrNums.indexOf(selectedInstrNums) === -1) {
                        instr = selectedInstrNums;
                        shouldAdd = false;
                    }
                }
                if (alreadyAdded) {
                    newInstrNums.push(selectedInstrNums);
                    newIndexes.push(updatedInstrNums.indexOf(selectedInstrNums));
                }
                for (let j = 0; j < count - (alreadyAdded ? 1 : 0); j++) {
                    const newInstrNum =
                        new_instrNum || this.getIncrementedInstrNum(instr, j + (instrNum ? 0 : shouldAdd ? 1 : 0), stepLevel, preceder);
                    newInstrNums.push(newInstrNum);
                    newIndexes.push(updatedInstrNums.push(newInstrNum) - 1);
                    updatedTestSteps[newInstrNum] = {};
                }
            };
            if (checkArrayLength(remainingInstrNumArray)) {
                for (let i = 0; i < remainingInstrNumArray.length; i++) {
                    const instrNum = remainingInstrNumArray[i];
                    if (instrNum.indexOf(preceder) !== 0) {
                        if (i === 0) {
                            fillNewArray();
                        }
                        updatedInstrNums = [...updatedInstrNums, ...remainingInstrNumArray.splice(i, remainingInstrNumArray.length)];
                        break;
                    }
                    if (i === 0) {
                        fillNewArray(instrNum);
                    }
                    const updatedInstrNum = this.getIncrementedInstrNum(instrNum, count - (alreadyAdded ? 1 : 0), stepLevel, preceder);
                    updatedInstrNums.push(updatedInstrNum);
                    updatedTestSteps[updatedInstrNum] = { ...testSteps[instrNum], instrNum: updatedInstrNum };
                }
            } else {
                fillNewArray(0);
            }
            testSteps = JSON.parse(JSON.stringify(updatedTestSteps));
            instrNumArray = JSON.parse(JSON.stringify(updatedInstrNums));
        };
        if (checkArrayLength(selectedInstrNums)) {
            selectedInstrNums.forEach((_instrNum) => {
                const prev_instrNum = this.getIncrementedInstrNum(_instrNum, -1);
                index = instrNumArray.indexOf(prev_instrNum);
                updateData(index, _instrNum);
            });
        } else {
            updateData(index);
        }

        return { newInstrNums, newIndexes, updatedInstrNums, updatedTestSteps };
    };

    removeNewInsrNumAndIndex = (indexes) => {
        let updatedInstrNums = [];
        let updatedTestSteps = {};
        let remainingInstrNumArray = [];
        let __selectedInstrNum = '';

        let { instrNumArray, testSteps } = store.getState().selectedTestCaseReducer;

        indexes.sort((a, b) => (a > b ? -1 : 1)); // sort indexes in desc order - instead of indexes.sort().reverse()
        indexes.forEach((index) => {
            updatedTestSteps = JSON.parse(JSON.stringify(testSteps));
            remainingInstrNumArray = [...instrNumArray];
            __selectedInstrNum = `${remainingInstrNumArray[index]}`;
            updatedInstrNums = [...remainingInstrNumArray.splice(0, index + 1)]; // split in to 2 arrays 0 to index & index + 1 to end

            const stepLevel = __selectedInstrNum.split('.').length - 1;
            const preceder = __selectedInstrNum.slice(0, __selectedInstrNum.lastIndexOf('.') + 1);

            delete updatedTestSteps[updatedInstrNums.pop()]; // Remove selectedInstrNum instrNum

            if (checkArrayLength(remainingInstrNumArray)) {
                for (let i = 0; i < remainingInstrNumArray.length; i++) {
                    const instrNum = remainingInstrNumArray[i];
                    if (instrNum.indexOf(preceder) !== 0) {
                        updatedInstrNums = [...updatedInstrNums, ...remainingInstrNumArray.splice(i, remainingInstrNumArray.length)];
                        break;
                    }
                    const updatedInstrNum = this.getIncrementedInstrNum(instrNum, -1, stepLevel, preceder);
                    updatedInstrNums.push(updatedInstrNum);
                    delete updatedTestSteps[instrNum];
                    updatedTestSteps[updatedInstrNum] = { ...testSteps[instrNum], instrNum: updatedInstrNum };
                }
            }
            testSteps = JSON.parse(JSON.stringify(updatedTestSteps));
            instrNumArray = JSON.parse(JSON.stringify(updatedInstrNums));
        });
        return { instrNumArray: updatedInstrNums, testSteps: updatedTestSteps };
    };

    onCancelAddStep = (indexes, flag) => {
        const { instrNumArray, testSteps } = this.removeNewInsrNumAndIndex(checkArrayLength(indexes) ? indexes : [indexes]);
        store.dispatch(SelectedTestCaseActions.removeDummyStepInStepsArray(flag, instrNumArray, testSteps)); // remove from reducer
    };

    handleAddSteps = (data, testStep, index, alreadyAdded = false) => {
        const paramIdsArray = getParamValues([1, 2]);
        const paramProjectId = paramIdsArray[0];
        const paramTestCaseId = paramIdsArray[1];

        const newInstrNums = TestCaseUtils.handleCRUDActions(index, 'add', testStep, data, '', '', alreadyAdded);
        // Remove filter
        store.dispatch(GeneralActions.queryRemove());
        // Scroll to specific step
        setTimeout(() => {
            this.scrollToSpecificStep(`${paramProjectId}_${paramTestCaseId}_${testStep.instrNum}`);
        }, 500);
        return newInstrNums;
    };
    /**
    |--------------------------------------------------
    | ADD STEPS END
    |--------------------------------------------------
    */

    /**
    |--------------------------------------------------
    | EDIT STEPS START
    |--------------------------------------------------
    */
    onEditStep = (currentIndex) => {
        // To handle, step index need to be edit
        store.dispatch(SelectedTestCaseActions.openEditModeForStep(currentIndex));
    };

    onCancelEditStep = () => {
        store.dispatch(SelectedTestCaseActions.closeEditModeForStep());
    };

    handleEditSteps = (testInstruction, testData, index, testStep, expectedResults) => {
        const paramIdsArray = getParamValues([1, 2]);
        const paramProjectId = paramIdsArray[0];
        const paramTestCaseId = paramIdsArray[1];
        if (testInstruction && testInstruction.length > 0) {
            TestCaseUtils.handleCRUDActions(index, 'edit', testStep, testInstruction, testData, expectedResults);
        }

        // To uncheck, checkbox of edited step
        store.dispatch(SelectedTestCaseActions.toggleStepCacheXpath(false, testStep.instrNum));

        // Remove filter
        store.dispatch(GeneralActions.queryRemove());
        // Scroll to specific step
        setTimeout(() => {
            this.scrollToSpecificStep(`${paramProjectId}_${paramTestCaseId}_${testStep.instrNum}`);
        }, 500);
    };
    /**
    |--------------------------------------------------
    | EDIT STEPS END
    |--------------------------------------------------
    */
    /**
    |--------------------------------------------------
    | DELETE STEPS START
    |--------------------------------------------------
    */

    handleDeleteSteps = (stepsToDelete) => {
        const { instrNumArray, selectedSteps, testSteps } = store.getState().selectedTestCaseReducer;

        const _stepsToDelete = [];
        if (checkArrayLength(stepsToDelete)) {
            // separating parent steps and the steps which parent are not in "stepsToDelete" array
            const sorterStepsToDelete = instrNumArray.filter((x) => stepsToDelete.findIndex((y) => `${x}` === `${y}`) >= 0); // Sorting steps
            let parent = -1;
            sorterStepsToDelete.forEach((step) => {
                if (step.indexOf(`${parent}.`) !== 0) {
                    parent = step;
                    _stepsToDelete.push(step);
                }
            });
        }
        const testStepsToDelete = [];
        if (checkArrayLength(instrNumArray) && checkArrayLength(_stepsToDelete)) {
            _stepsToDelete.forEach((instrNum) => {
                if (instrNumArray.indexOf(instrNum) > -1) {
                    const currStep = checkKeyInObject(testSteps, `${instrNum}`, 'value', {});
                    /* TO add key to track if any step from block delete start */
                    let parentInstrNum = `${instrNum}`.split('.');
                    parentInstrNum.pop();
                    parentInstrNum = parentInstrNum.join('.');
                    const parentStep = parentInstrNum ? checkKeyInObject(testSteps, `${parentInstrNum}`, 'value', {}) : instrNum;

                    if (checkKeyInObject(currStep, 'isBlockStep') && checkKeyInObject(parentStep, 'isBlockStep')) {
                        this.flowStepUpdated(currStep);
                    }
                    /* TO add key to track if any step from block delete end */

                    testStepsToDelete.push({
                        index: instrNumArray.indexOf(instrNum),
                        testStepNumber: instrNum,
                        hasChild: checkKeyInObject(currStep, 'hasChild', 'value', false),
                        subInstructions: checkKeyInObject(currStep, 'subInstructions', 'value', []),
                    });
                    const addChilds = (_currStep) => {
                        if (
                            checkKeyInObject(_currStep, 'hasChild', 'value', false) &&
                            checkKeyInObject(_currStep, 'subInstructions', 'bool') &&
                            checkArrayLength(_currStep.subInstructions)
                        ) {
                            const subInstructions = instrNumArray.filter((instr) => instr.indexOf(`${_currStep.instrNum}.`) === 0);
                            if (checkArrayLength(subInstructions)) {
                                subInstructions.forEach((subInstr) => {
                                    const _subStep = checkKeyInObject(testSteps, `${subInstr}`, 'value', {});
                                    if (!_stepsToDelete.includes(_subStep.instrNum)) {
                                        testStepsToDelete.push({
                                            index: instrNumArray.indexOf(_subStep.instrNum),
                                            testStepNumber: _subStep.instrNum,
                                            hasChild: checkKeyInObject(_subStep, 'hasChild', 'value', false),
                                            subInstructions: checkKeyInObject(_subStep, 'subInstructions', 'value', []),
                                        });
                                    }
                                    addChilds(_subStep);
                                });
                            }
                        }
                    };
                    addChilds(currStep);
                }
            });
        }
        let testStep = {};
        const stepsDataToSave = []; // To save data for redo/undo functionality
        if (checkArrayLength(instrNumArray)) {
            if (checkArrayLength(testStepsToDelete)) {
                testStepsToDelete.forEach((step) => {
                    const { index: rowIndex, testStepNumber } = step;
                    instrNumArray.some((instrNum) => {
                        if (`${testStepNumber}` === `${instrNum}`) {
                            testStep = testSteps[instrNum];
                            return true;
                        }
                        return false;
                    });
                    stepsDataToSave.push({ rowIndex, testStep });
                });
            }

            TestCaseUtils.handleStepsDelete(stepsDataToSave);
            if (checkArrayLength(selectedSteps)) {
                store.dispatch(SelectedTestCaseActions.emptySelectedSteps());
            }
        }
    };

    /**
    |--------------------------------------------------
    | DELETE STEPS END
    |--------------------------------------------------
    */
    /**
    |--------------------------------------------------
    | STEP SELECTION START
    |--------------------------------------------------
    */
    selectStepHelper = (step, isNotSelected, stateSelectedSteps, instrNumArray) => {
        let selectedSteps = [];
        const stepId = step.instrNum;
        if (isNotSelected) {
            selectedSteps = [...stateSelectedSteps, stepId, ...instrNumArray.filter((instr) => instr.indexOf(`${stepId}.`) === 0)];
        } else {
            selectedSteps = stateSelectedSteps.filter(
                (selectedStepId) => `${selectedStepId}` !== `${stepId}` && `${selectedStepId}`.indexOf(`${stepId}.`) !== 0,
            );
        }
        return selectedSteps;
    };

    isExpandedRow = (instrNum, expandInstrNo) => {
        for (var i = 0; i < expandInstrNo.length; i++) {
            if (instrNum.indexOf(expandInstrNo[i]) === -1) {
                continue;
    
            } else {
                return true;
            }
        }
        return false;
    };

    /**
    |--------------------------------------------------
    | STEP SELECTION END
    |--------------------------------------------------
    */
    /**
    |--------------------------------------------------
    | DEBUG POINTS START
    |--------------------------------------------------
    */
    handleDebug = (testCaseId, instrNum, onlyDebugPointsList = false) => {
        const { testSteps } = store.getState().selectedTestCaseReducer;
        const { failedStepsData, debugStepsData } = store.getState().projectReducer;
        let { debugPointList } = store.getState().projectsReducer;

        if (checkKeyInObject(testSteps, instrNum, 'bool') && checkObject(testSteps[instrNum]) && !onlyDebugPointsList) {
            const isMsgType13Received = checkKeyInObject(failedStepsData, testCaseId) && failedStepsData[testCaseId].instrNum;
            const isMsgType16Received = checkKeyInObject(debugStepsData, testCaseId) && debugStepsData[testCaseId].instrNum;
            let obj = { debug: !testSteps[instrNum].debug };

            // Add isFirstEditedStep only if msgType 13 and 16 received
            if (isMsgType13Received || isMsgType16Received) {
                obj = { ...obj, isFirstEditedStep: !testSteps[instrNum].debug };
            }

            testSteps[instrNum] = { ...testSteps[instrNum], ...obj };
            store.dispatch(SelectedTestCaseActions.updateTestStepsObject(testSteps));
        }

        if (checkKeyInObject(debugPointList, testCaseId, 'bool') && checkIsArray(debugPointList[testCaseId])) {
            const indexToRemove = debugPointList[testCaseId].indexOf(instrNum);
            if (indexToRemove > -1) {
                debugPointList[testCaseId].splice(indexToRemove, 1);
                if (!checkArrayLength(debugPointList[testCaseId])) delete debugPointList[testCaseId]; // if all instr removed for testCase remove case from debugList
            } else {
                debugPointList = {
                    ...debugPointList,
                    [testCaseId]: [...debugPointList[testCaseId], instrNum],
                };
            }
        } else {
            debugPointList = { ...debugPointList, [testCaseId]: [instrNum] };
        }
        store.dispatch(ProjectsActions.updatedebugPointList(debugPointList));
    };
    /**
    |--------------------------------------------------
    | DEBUG POINTS END
    |--------------------------------------------------
    */

    /**
    |--------------------------------------------------
    | DEBUG TEST STEPS LIVE START
    |--------------------------------------------------
     */

    updateLiveDebugStep = (updateStep, index, testStep) => {
        const { instr, data, action, xpath } = updateStep;
        const instrNum = updateStep.stepToEdit;
        const updateData = {
            instr,
            action,
            xpath: `${xpath} --heal`,
            data,
            screenshotNo: '',
            screenshotSmallPaths: [],
            screenshotPaths: [],
            status: '5',
            detectChanged: true,
            expectedResults: '',
            instrNum,
        };
        store.dispatch(SelectedTestCaseActions.updateStepStatus(instrNum, updateData));

        store.dispatch(
            SelectedTestCaseActions.saveTemp({
                action: 'edit',
                stepsDataToSave: [
                    {
                        rowIndex: index,
                        testStep,
                    },
                ],
            }),
        );
    };

    /**
    |--------------------------------------------------
    | DEBUG TEST STEPS END
    |--------------------------------------------------
    */

    /**
    |--------------------------------------------------
    | DOWNLOAD FILE START
    |--------------------------------------------------
    */

    downloadStepFile = async (testStep) => {
        const url = `v1/downloadFile?fileURL=${testStep.downloadFile}`;
        const response = await store.dispatch(TestStepActions.downloadTestStepsFile(url, false, true));

        if (response) {
            const contentType = response.headers['content-type'];
            const contentDisposition = response.headers['content-disposition'];
            const ext = extension(contentType);
            const downloadFilename =
                contentDisposition && contentDisposition.split('filename=')[1] ? contentDisposition.split('filename=')[1] : `filename.${ext}`;
            singleFileCreationService.showFile(response.data, 'download', downloadFilename, contentType);
        }
    };

    updateStepDownloadKey = (instrNum, key) => {
        const { testSteps } = store.getState().selectedTestCaseReducer;
        testSteps[instrNum] = { ...testSteps[instrNum], [key]: true };
        store.dispatch(SelectedTestCaseActions.updateTestStepsObject(testSteps));
    };

    updateExecutionStepDownloadKey = (instrNum, key, value) => {
        const { testSteps } = store.getState().executionReducer;
        const { instrNumArray } = store.getState().executionReducer;
        if (checkKeyInObject(testSteps, 'instrNum')) {
            // we don't need to make loop because we already have object
            testSteps[instrNum][key] = value;
            store.dispatch({
                type: ActionTypes.SET_EXECUTION_DATA,
                payload: { instrNumArray, testStepsObj: testSteps },
            });
        }
    };
    /**
    |--------------------------------------------------
    | DOWNLOAD FILE END
    |--------------------------------------------------
    */

    /**
     |--------------------------------------------------
     | AUTO SCROLL START
     |--------------------------------------------------
     */
    // Scroll to running step
    isAutoScrolling = false;

    scrollToSpecificStep = (instrNum, isHighLight, scrollExecution) => {
        this.isAutoScrolling = true;
        let instrNumArray = [];
        let listView = false;
        if (scrollExecution) {
            instrNumArray = store.getState().executionReducer.instrNumArray;
            listView = store.getState().executionReducer.listView;
        } else {
            instrNumArray = store.getState().selectedTestCaseReducer.instrNumArray;
            listView = store.getState().selectedTestCaseReducer.listView;
        }
        const { expandInstrNo } = store.getState().selectedTestCaseReducer;
        const _instrNum = instrNum ? instrNum.split('_').pop() : '1';
        
        let count = 0;
        let expandCount = 0;
        instrNumArray.forEach((num)=>{
            if(num && !(Number(num) % 1 === 0)){
                count += 1
                if(Number(_instrNum )> Number(num)){
                    expandCount += 1;
                }
            }
        });
        setTimeout(
            () => {
                if (checkKeyInObject(TestStepUtils.stepsParentRef, 'current.children', 'value', [], true).length > 0) {
                    const stepIndexNew = checkArrayLength(instrNumArray) ? instrNumArray.indexOf(_instrNum) : 0;
                    const stepIndex = checkArrayLength(expandInstrNo) ? stepIndexNew : stepIndexNew - expandCount; //added condition to subtract no.of row(substeps of block step) if not expanded block instruction while calculating stepIndex

                    const rowHeight = listView ? 37 : 123;
                    const parent = TestStepUtils.stepsParentRef.current.children[0];
                    if (
                        checkKeyInObject(parent, 'scrollTo', 'bool', '', true) &&
                        checkKeyInObject(parent, 'children', 'bool', '', true) &&
                        parent.children[0]
                    ) {
                        const calcOffset = (parent.clientHeight - rowHeight) / 2; // For Scroll To Center [Current Step]

                        const offsetTop = stepIndex * rowHeight - (calcOffset > 0 ? calcOffset : 0);
                        // add height and maxHeight to help to go to steps which are out of container height
                        if(!expandInstrNo.length) {
                            parent.children[0].style.height = `${(instrNumArray.length - count) * rowHeight}px`;
                            parent.children[0].style.maxHeight = `${(instrNumArray.length - count) * rowHeight}px`;
                        } else {
                            // parent.children[0].style.height = `${(instrNumArray.length - count) * rowHeight}px`;
                            parent.children[0].style.maxHeight = `${instrNumArray.length * rowHeight}px`;
                        }

                        parent.scrollTo({ top: offsetTop, behavior: 'smooth' });
                        parent.onscroll = () => {
                            const diff = Math.abs(parent.scrollTop - offsetTop);
                            if (diff < rowHeight) {
                                this.isAutoScrolling = false;
                                if (isHighLight) {
                                    if (checkKeyInObject(TestStepUtils.stepsRef[instrNum], 'current', 'bool', '', true)) {
                                        const child = TestStepUtils.stepsRef[instrNum].current;
                                        const prevColor = child.style.backgroundColor;
                                        child.style.backgroundColor = 'rgb(248, 255, 144)';
                                        setTimeout(() => {
                                            child.style.backgroundColor = prevColor;
                                        }, 2000);
                                    }
                                }
                                parent.onscroll = null; // remove listener
                            }
                        };
                    } else {
                        this.isAutoScrolling = false;
                    }
                } else {
                    this.isAutoScrolling = false;
                }
            },
            listView ? 500 : 0,
        );
    };
    /**
    |--------------------------------------------------
    | AUTO SCROLL END
    |--------------------------------------------------
    */

    /**
    |--------------------------------------------------
    | GET IMAGE URL
    |--------------------------------------------------
    */
    getPreviewImage = ({ screenshotNo, screenshotSmallPaths }) => {
        if (screenshotSmallPaths && screenshotSmallPaths.length > 0) {
            const previewIndex = screenshotNo > 0 ? screenshotNo - 1 : 0;
            return `${config.api.endpoint}/testCases/previewScreenShot?screenShotURL=${screenshotSmallPaths[previewIndex]}`;
        }
        return defaultScreenShot;
    };

    checkScrollSpeed = (() => {
        let lastOffset;
        let lastDate;
        const clear = () => {
            lastOffset = -1;
            lastDate = new Date().getTime();
        };
        clear();
        return (scrollTop) => {
            const timeStamp = new Date().getTime();
            let speedInpxPerMs = 0;
            if (lastOffset !== -1) {
                const delayInMs = timeStamp - lastDate;
                const offset = scrollTop - lastOffset;
                speedInpxPerMs = offset / delayInMs;
            }
            // if (speedInpxPerMs > 10 || speedInpxPerMs < -10)
            //     console.log(speedInpxPerMs);
            lastDate = timeStamp;
            lastOffset = scrollTop;
            return speedInpxPerMs;
        };
    })();
}

export const TestStepUtils = new TestStepsUtil();
