import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function AlertModal(props) {
    const { onClickAlertDialogClose, open, msg } = props;
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>{msg || 'Are you sure you want to delete?'}</DialogContent>
                <DialogActions>
                    <Button onClick={() => onClickAlertDialogClose(false)} variant="outlined" color="secondary">
                        No
                    </Button>
                    <Button onClick={() => onClickAlertDialogClose(true)} color="secondary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AlertModal;
