import BaseHttp from '../../services/HttpBase';
import { checkKeyInObject, checkArrayLength } from '../../utils/utils';

class UploadProgressService extends BaseHttp {
    async uploadProgress(url, formData, config /* , source */) {
        try {
            const { data, status } = await this.httpClient.post(url, formData, config);
            if (status === 200) {
                if (checkKeyInObject(data, 'failed') || checkKeyInObject(data, 'success')) {
                    const response = {};
                    response.status = checkKeyInObject(data, 'status');
                    if (checkArrayLength(data.failed)) {
                        response.failed = data.failed;
                    }
                    if (checkArrayLength(data.success)) {
                        response.success = data.success;
                    }
                    return response;
                }
                throw new Error('Failed to upload');
            } else {
                throw new Error('Failed to upload');
            }
        } catch (error) {
            let _error = error;
            if (error && error.response && error.response.status === 413) {
                _error = 'File too large to upload';
            }
            throw _error;
        }
    }
}

export default new UploadProgressService();
