import { ActionTypes } from '../constants';
import { checkKeyInObject, checkObject } from '../../utils';
import store from '..';
import { ModalActions } from '../actions';

const initialState = {
    anchorEl: null,
    autoHideDuration: 400,
    isLoading: false,
    isSuccess: false,
    isWarning: false,
    loader: false,
    isSnackBarOpen: false,
    route: '',
    snackBarMessage: '',
    snackbarLink: '',
    modalName: [],
    isModalVisible: {},
    modalData: {},
    videoUrl: {},
    progress: {},
};

export default function modalReducers(state = initialState, { type, payload }) {
    switch (type) {
        case ActionTypes.TOGGLE_MODAL: {
            const { modalName, route, videoUrl, modalData, forceOpen, close,modalState } = payload;
            if (!modalName && !modalState) {
                return {
                    ...state,
                    isModalVisible: {},
                    modalData: {},
                    modalName: [],
                    videoUrl: {},
                    route,
                };
            }

            const stateData = {};
            Object.assign(stateData, {
                isModalVisible: { ...state.isModalVisible },
                modalData: { ...state.modalData },
                videoUrl: { ...state.videoUrl },
                modalName: [...state.modalName],
            });
            const _isModalVisible = stateData.isModalVisible;
            const _modalData = stateData.modalData;
            const _modalName = stateData.modalName;
            const _videoUrl = stateData.videoUrl;

            if (close) {
                let index = -1;
                if (_modalName.indexOf(modalName) > -1) {
                    index = _modalName.lastIndexOf(modalName);
                    _modalName.splice(index, 1);
                }
                delete _videoUrl[`${modalName}__${index}`];
                delete _modalData[`${modalName}__${index}`];
                delete _isModalVisible[`${modalName}__${index}`];
                return {
                    ...state,
                    isModalVisible: _isModalVisible,
                    modalData: _modalData,
                    modalName: _modalName,
                    videoUrl: _videoUrl,
                    route,
                };
            }

            if (modalName) {
                const newInd = _modalName.length;
                _modalName.push(modalName);
                _isModalVisible[`${modalName}__${newInd}`] = forceOpen || !_isModalVisible[`${modalName}__${newInd}`];
                _modalData[`${modalName}__${newInd}`] = modalData;
                _videoUrl[`${modalName}__${newInd}`] = videoUrl;
                return {
                    ...state,
                    isModalVisible: _isModalVisible,
                    modalData: _modalData,
                    modalName: _modalName,
                    videoUrl: _videoUrl,
                    route,
                };
            }

            if(modalState){
                delete _isModalVisible[modalState]
                _modalName.pop()
                return {
                    ...state,
                    isModalVisible: _isModalVisible,
                    modalData: {},
                    modalName: _modalName,
                    videoUrl: {},
                    route,
                };
            }
            return { ...state, route };
        }

        case ActionTypes.TOGGLE_SNACKBAR: {
            const { isSnackBarOpen, snackBarMessage } = { ...state };
            if (payload.forceOpen) {
                return { ...state, isSnackBarOpen: payload.forceOpen, ...payload };
            }
            if (!snackBarMessage || !payload.snackBarMessage) {
                return { ...state, isSnackBarOpen: !isSnackBarOpen, ...payload };
            }
            return { ...state };
        }

        case ActionTypes.CLEAR_SNACKBAR: {
            return {
                ...state,
                autoHideDuration: 400,
                isSuccess: false,
                isWarning: false,
                snackbarLink: '',
                snackBarMessage: '',
                isSnackBarOpen: false,
            };
        }

        case ActionTypes.UPDATE_PROJECT_DOWNLOAD_PROGRESS: {
            let _progress = { ...state.progress };
            if (!checkKeyInObject(payload, 'key')) {
                _progress = {};
            } else if (checkKeyInObject(payload, 'progress') === undefined) {
                delete _progress[payload.key];
            } else {
                const { key, progress, fileName } = payload;
                _progress[key] = { percentage: progress, fileName };
            }
            if (!checkObject(_progress)) {
                setTimeout(() => {
                    if (state.isSnackBarOpen) store.dispatch(ModalActions.toggleSnackBar());
                }, 100);
            }
            return { ...state, progress: _progress };
        }

        default:
            return state;
    }
}
