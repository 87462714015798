import { BLACK_FONT } from '../../common/cssConstants';

export const styles = (theme) => ({
    root: {
        color: '#aaaaaa',
        fontSize: 12,
        lineHeight: '15px',
        opacity: 1,
        textTransform: 'capitalize',
        padding: 0,
    },
    tabSelected: {
        backgroundColor: '#494949',
        borderBottom: '0px solid transparent',
        borderTop: '0.5px solid transparent',
        color: 'white',
        fontSize: 12,
        lineHeight: '15px',
    },
    wrapper: {
        flexDirection: 'row',
        fontSize: '10px',
        justifyContent: 'end',
        display: 'inline'
        // paddingLeft: '15px',
    },
    wrapperCenter: {
        flexDirection: 'row',
        fontSize: '10px',
        justifyContent: 'center',
    },
    labelContainer: {
        padding: '6px 5px 22px 5px',
    },
    firstTabSelected: {
        backgroundColor: theme.palette.primary.light,
        borderLeft: '0px solid transparent',
        borderRight: '0.5px solid #e9e9e9',
        borderBottom: '0px solid transparent',
        borderTop: '0.5px solid transparent',
        color: 'theme.palette.primary.dark',
        fontSize: 12,
        lineHeight: '15px',
    },
    lastTabSelected: {
        backgroundColor: theme.palette.primary.light,
        borderLeft: '0.5px solid #e9e9e9',
        borderRight: '0px solid transparent',
        borderBottom: '0px solid transparent',
        borderTop: '0.5px solid transparent',
        color: theme.palette.primary.dark,
        fontSize: 12,
        lineHeight: '15px',
    },
    projectComponent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'inherit',
    },
    tabRoot: {
        backgroundColor: '#494949',
        height: '50px',
    },
    gridContainer: {
        backgroundColor: '#ececec',
        height: 'calc(100vh - 97px)',
        minHeight: 'calc(100vh - 97px)',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    headerContainer: {
        margin: '0 0 0 20px',
        paddingTop: '16px',
        width: 'calc(100% - 42px)',
        float: 'left',
    },
    projectNameBg: {
        paddingLeft: 10,
        paddingRight: 10,
        border: '0.5px solid #C4C4C44F',
        backgroundColor: theme.palette.primary.light,
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        border: '2px solid blue',
    },
    routerLink: {
        textDecoration: 'none',
        color: 'initial',
    },
    projectNameContainer: {
        display: 'inline-block',
        verticalAlign: 'middle',
        minWidth: '150px',
        '&>p': {
            position: 'relative',
            fontSize: '20px',
            fontWeight: '600',
        },
    },
    headerOne: {
        display: 'flex',
        alignItems: 'center',
        // marginTop: '10px',
    },
    headerTwo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '15px',
        // margin: '10px 0 0 0',
        '&>div': {
            float: 'left',
            '&>p': {
                display: 'flex',
                alignItems: 'center',
                '&>span': {
                    display: 'inline-block',
                    marginTop: '2px',
                },
            },
        },
    },
    iconStyle: {
        fontSize: '13px',
        color: theme.palette.primary.dark,
    },
    upBtn: {
        display: 'inline-block',
        height: 21,
        marginLeft: 0,
        top: '-6px',
        zIndex: 2,
        position: 'absolute',
        cursor: 'pointer',
        overflow: 'hidden',
        '&>svg': {
            fontSize: 30,
            '&:hover': {
                color: '#6094ff',
            },
        },
    },
    downBtn: {
        display: 'inline-block',
        height: 23,
        marginLeft: 0,
        top: 6,
        zIndex: 1,
        position: 'absolute',
        cursor: 'pointer',
        overflow: 'hidden',
        '&>svg': {
            fontSize: 30,
            '&:hover': {
                color: '#6094ff',
            },
        },
    },
    noProjectContainer: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.light,
        width: '100%',
    },
    noProject: {
        paddingLeft: theme.spacing(1),
    },
    typography: {
        fontSize: '25px !important',
        fontWeight: 'normal !important',
        color: BLACK_FONT,
    },
    typographySpan: {
        marginTop: 3,
        width: 36,
        height: 29,
        float: 'right',
        position: 'relative',
    },
    textBold: {
        fontWeight: 'bold',
    },
    headerNavRoot: {
        background: '#494949',
        display: 'flex',
        justifyContent: 'center',
    },
});
