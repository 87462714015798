import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { BLACK_FONT } from '../../common/cssConstants';

const styles = () => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    item: {
        color: '#9b9b9b',
        marginLeft: '3px',
    },
    dataItem: {
        color: BLACK_FONT,
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    clock: {
        color: '#9b9b9b',
    },
});

function ProjectTime(props) {
    const { classes, time, customStyles, is_nowrap, callFrom } = props;
    return (
        <Grid className={classes.root}>
            {callFrom === 'data' ? '' : <FontAwesomeIcon icon={faClock} aria-label="faClock" id="faClock" className={classes.clock} />}
            <Typography
                className={`${callFrom === 'data' ? classes.dataItem : classes.item} ${is_nowrap ? classes.noWrap : ''}`}
                style={customStyles && customStyles.fontSize ? { fontSize: customStyles.fontSize } : {}}
            >
                {time}
            </Typography>
        </Grid>
    );
}

ProjectTime.propTypes = {
    // time: PropTypes.string.isRequired,
    // classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ProjectTime);
