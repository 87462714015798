import UploadProgressMiddleware from '../middleware/uploadProgressMiddleware';
import { ActionTypes } from '../constants';
import { checkKeyInObject, checkArrayLength, googleAnalytics } from '../../utils/utils';
import ProjectActions from './projectActions';
import { SidebarActions } from './SidebarAction';

const getTestCaseById = (testCases, testCaseId) => {
    // testCaseId is getting from array in testData
    const res = testCases.find((testCase) => {
        return testCase && testCase.testCaseId === testCaseId;
    });
    return res;
};

const createTestCase = (testCase) => ({
    name: testCase.testCaseName,
    generatedAt: testCase.createdTime,
    id: testCase.testCaseId,
});

const getCreatedTestData = (_testCases, testData) => {
    const testCases = [];
    if (checkArrayLength(testData.testCases)) {
        testData.testCases.forEach((id) => {
            const testCase = getTestCaseById(_testCases, id);
            if (testCase) {
                testCases.push(createTestCase(testCase));
            }
        });
    }
    const createdTestData = createTestData(testData, testCases);
    return createdTestData;
};

const createTestData = (testData, testCases) => ({
    testDataId: testData.testDataId,
    discoveryId: testData.discoveryId,
    location: testData.testDataFileLocation,
    testDataFileName: testData.testDataFileName,
    uploadedTime: testData.uploadedTime,
    type: testData.type,
    associatedTestCases: checkArrayLength(testCases.length)
        ? testCases
        : [
              {
                  name: 'No Associated Test Case',
                  generatedAt: undefined,
                  id: '0',
              },
          ],
});
class uploadProgressActions {
    static uploadProgressFile(params, files, multipleTestCase = false) {
        const uploadConfig = {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        };
        const url = `/v1/projects/${params.projectId}/testcases/upload`;

        return (dispatch) => {
            dispatch({
                type: ActionTypes.UPLOAD_CASE_FILE,
            });
            dispatch({
                type: ActionTypes.UPADTE_RELOAD_STATUS,
                payload: true,
            });
            return UploadProgressMiddleware.uploadProgress(url, files, uploadConfig, 'uploadProgressFile')
                .then((response) => {
                    if (checkKeyInObject(response, 'success')) {
                        dispatch(ProjectActions.getProjectDetails(params.projectId));
                        dispatch(ProjectActions.getTestData(params.projectId));
                        dispatch(SidebarActions.increaseSidebarCount('num_of_test_cases'));
                        dispatch({
                            type: ActionTypes.UPLOAD_CASE_FILE_SUCCESS,
                            payload: { successArray: response.success, multipleTestCase },
                        });
                    } else if (checkKeyInObject(response, 'failed')) {
                        if (response.failed.length < 2) {
                            dispatch({
                                type: ActionTypes.UPLOAD_CASE_FILE_FAILURE,
                                payload: { message: 'Empty test case.' },
                            });
                        }
                    }
                    return response;
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.UPLOAD_CASE_FILE_FAILURE,
                        payload: {
                            error,
                            message: error === 'File too large to upload' ? error : 'Unable to upload',
                        },
                    }),
                );
        };
    }

    static uploadTestDataFile(params, files, onComplete = () => {}, multipleTestData = false) {
        const uploadConfig = {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        };
        // system id is discoveryId which is same as projectId
        const url = `/testDatas/${params.accountId}/${params.projectId}/${params.systemId}/uploadTestData`;

        return (dispatch, getState) => {
            dispatch({
                type: ActionTypes.UPLOAD_DATA_FILE,
            });
            return UploadProgressMiddleware.uploadProgress(url, files, uploadConfig, 'uploadTestDataFile')
                .then((response) => {
                    const newTestData = [];
                    if (checkKeyInObject(response, 'success')) {
                        onComplete(response); // Need to verify it's use
                        const _testCases = getState().projectReducer.testCases;
                        dispatch(ProjectActions.increaseProjectRelatedCount('dataCount'));
                        response.success.forEach((testDatum) => {
                            newTestData.push(getCreatedTestData(_testCases, testDatum));
                        });
                        googleAnalytics('send', 'event', 'Upload Test Data', 'Upload Test Data', 'Success');
                    } else if (checkKeyInObject(response, 'failed')) {
                        if (response.failed.length < 2) {
                            dispatch({
                                type: ActionTypes.UPLOAD_DATA_FILE_FAILURE,
                                payload: { message: 'Failed to upload' },
                            });
                            googleAnalytics('send', 'event', 'Upload Test Data', 'Upload Test Data', 'Fail');
                        }
                    }
                    dispatch({
                        type: ActionTypes.UPLOAD_DATA_FILE_SUCCESS,
                        payload: { newTestData, multipleTestData },
                    });
                    return response;
                })
                .catch((error) => {

                    dispatch({
                        type: ActionTypes.UPLOAD_DATA_FILE_FAILURE,
                        payload: {
                            error,
                            message: error === 'File too large to upload' ? error : 'Unable to upload',
                        },
                    });
                    googleAnalytics('send', 'event', 'Upload Test Data', 'Upload Test Data', 'Fail');
                });
        };
    }
}

export default uploadProgressActions;
