import React, { memo } from "react";
import ContentLoader from "../../components/ContentLoader";
const LoadingSkelton = () => {
  return (
    <div className="container-root">

      <div className="container-title">
        <ContentLoader width={160} height={18} />
      </div>
      <div className="container-content">

        <div className="section-root">
        <div style={{width:'100%'}}>

          <div className="section-title">
            <ContentLoader width={100} height={18} />
          </div>
          <div className="section-content">
            <ContentLoader shape="circle" size={134} />
            <div className="section-content-numbers">
              <div style={{ display: "flex", alignItems: "center" }}>
                <ContentLoader shape="circle" size={20} />

                <div style={{ paddingLeft: "8px" }}>
                  <ContentLoader width={80} height={16} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <ContentLoader shape="circle" size={20} />

                <div style={{ paddingLeft: "8px" }}>
                  <ContentLoader width={80} height={16} />
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div className="section-root">
        <div style={{width:'100%'}}>

          <div className="section-title">
            <ContentLoader width={100} height={18} />
          </div>
          <div className="section-content">
            <ContentLoader shape="circle" size={134} />
            <div className="section-content-numbers">
              <div style={{ display: "flex", alignItems: "center" }}>
                <ContentLoader shape="circle" size={20} />

                <div style={{ paddingLeft: "8px" }}>
                  <ContentLoader width={80} height={16} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <ContentLoader shape="circle" size={20} />

                <div style={{ paddingLeft: "8px" }}>
                  <ContentLoader width={80} height={16} />
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="section-root">
        <div style={{width:'100%'}}>

          <div className="section-title">
            <ContentLoader width={100} height={18} />
          </div>
          <div className="section-content">
            <ContentLoader shape="circle" size={134} />
            <div className="section-content-numbers">
              <div style={{ display: "flex", alignItems: "center" }}>
                <ContentLoader shape="circle" size={20} />

                <div style={{ paddingLeft: "8px" }}>
                  <ContentLoader width={80} height={16} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <ContentLoader shape="circle" size={20} />

                <div style={{ paddingLeft: "8px" }}>
                  <ContentLoader width={80} height={16} />
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LoadingSkelton);
