// import material
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Chip from '@material-ui/core/Chip';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// import packages
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

// import icons material
import WindowClose from 'mdi-material-ui/WindowClose';

// import custom
import { checkArrayLength, checkObject, checkKeyInObject } from '../../utils/utils';
import { TagActions } from '../../store/actions';
import AutoSuggestTag from './AutoSuggestTag';
import Tooltip from '../Tooltip';
import { BLACK_FONT } from '../../common/cssConstants';

const styles = (theme) => ({
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: '-1px',
            width: 15,
            height: 15,
            left: '50%',
            transform: ' translate(-50%, -50%) rotate(45deg)',
            background: 'white',
        },
    },
    arrowBottom: {
        '&::after': {
            borderBottom: 'solid 1px #b1b1b0',
            borderRight: 'solid 1px #b1b1b0',
        },
    },
    arrowTop: {
        '&::after': {
            borderTop: 'solid 1px #b1b1b0',
            borderLeft: 'solid 1px #b1b1b0',
        },
    },
    chip: {
        marginRight: 5,
        backgroundColor: '#EBF0F3',
        marginBottom: 3,
        borderRadius: 4,
        height: 29,
    },
    chipContainer: {
        alignItems: 'flex-start',
        display: 'flex',
        minHeight: 50,
        padding: '10px 15px',
        maxHeight: '120px',
        overflowY: 'auto',
    },
    chipBody: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        width: 'calc(100% - 100px)',
    },
    horizontalRow: {
        border: 0,
        borderTop: '1px solid #DCDCDC',
    },
    inputFieldContainer: {
        display: 'flex',
        width: '100%',
    },
    inputFieldButton: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        height: 31,
        minHeight: 10,
        minWidth: 40,
        width: 55,
        boxShadow: 'none',
        marginLeft: '-1px',
    },
    enableFieldButton: {
        backgroundColor: '#3B91DF !important',
    },
    disabledFieldButton: {
        backgroundColor: '#DDD !important',
        color: '#fff',
    },
    label: {
        width: 100,
        '& > p': {
            color: BLACK_FONT,
            fontWeight: 'bold',
            marginTop: '5px',
            fontSize: '14px',
        },
    },
    root: {
        width: 400,
        marginTop: 10,
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 50px)',
        },
    },
    row: {
        alignItems: 'center',
        borderBottom: '0.5px solid #BAC4CE',
        display: 'flex',
        height: '55.5px',
        justifyContent: 'start',
        padding: '0px 15px',
    },
    svg: {
        fontSize: 15,
    },
    caseName: {
        color: 'rgb(0, 104, 212)',
        fontWeight: 'bold',
        marginRight: '10px',
        maxWidth: '80px',
        width: '80px',
        overflow: 'hidden',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        marginTop: '6px',
        float: 'left',
        fontSize: '14px',
    },
});

class Popup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionPerforming: null,
            arrowRef: null,
            currentTags: null,
            removedTagIndex: null,
            removedTagKey: null,
            tag: '',
        };
    }

    componentDidMount() {
        const {
            getTags,
            data: { name, projectId },
        } = this.props;
        if (name === 'testCaseSteps') getTags(projectId);
        else getTags();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            tags,
            data: { selectedTestCase },
        } = nextProps;
        const { currentTags } = this.state;
        if (!currentTags && checkArrayLength(tags) && (checkArrayLength(selectedTestCase) || checkObject(selectedTestCase))) {
            const _testCaseTags = [];
            if (checkArrayLength(selectedTestCase)) {
                selectedTestCase.forEach((testCase) => {
                    _testCaseTags.push({
                        [testCase.testCaseId]: [testCase.testCaseName, this.setCurrentTags(testCase, tags)],
                        key: testCase.testCaseId,
                    });
                });
            } else if (checkObject(selectedTestCase)) {
                _testCaseTags.push({
                    [selectedTestCase.testCaseId]: [selectedTestCase.testCaseName, this.setCurrentTags(selectedTestCase, tags)],
                    key: selectedTestCase.testCaseId,
                });
            }
            if (checkArrayLength(_testCaseTags)) this.setState({ currentTags: _testCaseTags });
        }
    }

    setCurrentTags = (selectedTestCase, tags) => {
        const { tags: testCaseTags } = selectedTestCase;
        const _testCaseTags = [];

        if (checkArrayLength(testCaseTags) && checkArrayLength(tags)) {
            testCaseTags.forEach((tc_t) => {
                const tagObj = tags.find((tag) => Number(tag.tag_id) === Number(tc_t));
                if (checkObject(tagObj)) {
                    _testCaseTags.push(tagObj);
                }
            });
        }
        return _testCaseTags;
    };

    handleArrowRef = (node) => {
        this.setState({ arrowRef: node });
    };

    handleChange = (tag) => {
        this.setState({ tag: tag ? tag.trim() : '' });
    };

    handleOnAddClick = async () => {
        const { tag } = this.state;
        const {
            data: { selectedTestCase },
            tags,
        } = this.props; // props
        const { createTag } = this.props; // methods

        if (tag) {
            /*  when create and tag will be seperate set this state in respective functions
                create and tag is on single click now that's why it's here. */
            this.setState({ actionPerforming: 'tag' });
            // currently tag is not case sensitive
            const tagAlreadyExists = tags.find((t) => t.name.toLowerCase() === tag.toLowerCase());
            // if tag is already exists in db associate it with testCase
            if (checkObject(tagAlreadyExists)) {
                const obj = this.createTagObj(tagAlreadyExists);
                this.tagTestCase(obj, tagAlreadyExists);
            } else {
                let tagCreated = null;
                let dataToSend = {};
                if (checkArrayLength(selectedTestCase)) {
                    dataToSend = {
                        name: tag,
                        project_id: selectedTestCase[0] && selectedTestCase[0].discoveryId ? Number(selectedTestCase[0].discoveryId) : null,
                    };
                } else {
                    dataToSend = { name: tag, project_id: Number(selectedTestCase.discoveryId) };
                }
                tagCreated = await createTag(dataToSend);
                if (tagCreated) {
                    const obj = this.createTagObj(tagCreated);
                    this.tagTestCase(obj, tagCreated);
                } else {
                    this.setState({ actionPerforming: '' });
                }
            }
        }
    };

    createTagObj = (tag) => {
        const {
            data: { selectedTestCase },
        } = this.props; // props
        let testCaseIds = [];
        if (checkArrayLength(selectedTestCase)) {
            // send testCaseIds to tag only if tag is not already present
            selectedTestCase.forEach((s_case) => {
                if (!checkArrayLength(s_case.tags) || (checkArrayLength(s_case.tags) && s_case.tags.indexOf(Number(tag.tag_id)) === -1)) {
                    testCaseIds.push(s_case.testCaseId);
                }
            });
        } else {
            testCaseIds = [Number(selectedTestCase.testCaseId)];
        }
        return { tag_id: tag.tag_id, tc_ids: testCaseIds };
    };

    tagTestCase = async (obj, tag) => {
        const { currentTags } = this.state;
        const {
            tagTestCase,
            data: { selectedTestCase, name },
            addRemoveCases_Tag,
        } = this.props; // methods
        const isTagged = await tagTestCase(obj);
        if (isTagged) {
            const _currentTags = JSON.parse(JSON.stringify(currentTags));
            const testCaseIds = [];
            if (checkArrayLength(selectedTestCase)) {
                selectedTestCase.forEach((_case) => {
                    testCaseIds.push(_case.testCaseId);
                    const __index = _currentTags.map((item) => item.key).indexOf(_case.testCaseId);
                    if (__index >= 0) {
                        _currentTags[__index][_case.testCaseId][1].push(tag);
                    }
                });
            } else {
                testCaseIds.push(selectedTestCase.testCaseId);
                _currentTags[0][selectedTestCase.testCaseId][1].push(tag);
            }
            if (name === 'plan') {
                addRemoveCases_Tag(testCaseIds, tag.tag_id, 'add');
            }
            this.setState({ currentTags: _currentTags, tag: '', actionPerforming: '' });
        } else {
            this.setState({ actionPerforming: '' });
        }
    };

    removeTagFromTestCase = async (tag, index, id) => {
        const {
            data: { selectedTestCase, name },
            untagTestCase,
            addRemoveCases_Tag,
        } = this.props;
        const currentTags = JSON.parse(JSON.stringify(this.state.currentTags));
        let testCaseId = null;

        this.setState({ actionPerforming: 'untag', removedTagIndex: index, removedTagKey: id });

        if (checkArrayLength(selectedTestCase)) {
            testCaseId = selectedTestCase.find((_case) => `${_case.testCaseId}` === `${id}`).testCaseId;
        } else {
            testCaseId = selectedTestCase.testCaseId;
        }
        const isUnTagged = await untagTestCase({ tag_id: tag.tag_id, tc_id: Number(testCaseId) });
        if (isUnTagged) {
            const __index = currentTags.map((item) => item.key).indexOf(id);
            if (__index >= 0) {
                currentTags[__index][id][1] = currentTags[__index][id][1].filter((t) => Number(t.tag_id) !== Number(tag.tag_id));
            }
            this.setState({ currentTags, actionPerforming: '', removedTagIndex: null, removedTagKey: null });
            if (name === 'plan') {
                addRemoveCases_Tag([testCaseId], tag.tag_id, 'remove');
            }
        } else {
            this.setState({ actionPerforming: '', removedTagIndex: null, removedTagKey: null });
        }
    };

    arrowComponent = (arrowClass) => {
        const { classes } = this.props;
        return <span className={`${classes.arrow} ${classes[arrowClass]}`} ref={this.handleArrowRef} />;
    };

    render() {
        const {
            anchorEl,
            classes,
            handleClose,
            isLoading,
            open,
            tags,
            data: { selectedTestCase },
        } = this.props;
        const { actionPerforming, arrowRef, currentTags, removedTagIndex, removedTagKey, tag } = this.state;
        let isTagsAdded = false;
        if (Array.isArray(selectedTestCase) && checkArrayLength(selectedTestCase)) {
            isTagsAdded = selectedTestCase.some(
                (testCase) => checkKeyInObject(testCase, 'tags') && Array.isArray(testCase.tags) && checkArrayLength(testCase.tags),
            );
        } else if (checkKeyInObject(selectedTestCase, 'tags') && Array.isArray(selectedTestCase.tags) && checkArrayLength(selectedTestCase.tags)) {
            isTagsAdded = true;
        }

        return (
            <Popper
                id={open ? 'no-transition-popper' : null}
                open={open}
                anchorEl={anchorEl}
                transition
                disablePortal
                style={{ zIndex: 90 }}
                placement="bottom"
                modifiers={{
                    arrow: {
                        enabled: true,
                        element: arrowRef,
                    },
                }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <div>
                            {placement !== 'top' ? this.arrowComponent('arrowTop') : null}
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <div className={classes.root}>
                                        <div className={[classes.row, classes.inputFieldContainer].join(' ')}>
                                            <AutoSuggestTag
                                                actions={{
                                                    handleChange: this.handleChange,
                                                    handleSearchActionElement: () => {},
                                                    onEnterKeyPress: this.handleOnAddClick,
                                                }}
                                                placeholder="Type to add a tag"
                                                data={{ currentTags, tags, val: tag }}
                                            />
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className={[
                                                    classes.inputFieldButton,
                                                    !tag || actionPerforming === 'tag' ? classes.diableFieldButton : classes.enableFieldButton,
                                                ].join(' ')}
                                                onClick={() => this.handleOnAddClick()}
                                                disabled={!tag || actionPerforming === 'tag'}
                                            >
                                                {actionPerforming === 'tag' && isLoading ? (
                                                    <CircularProgress style={{ position: 'absolute', color: '#4885ed' }} size={25} thickness={7} />
                                                ) : (
                                                    'Add'
                                                )}
                                            </Button>
                                        </div>
                                        <div className={classes.chipContainer}>
                                            <div className={classes.label}>
                                                {isTagsAdded ? <Typography>Current Tags</Typography> : <Typography>No Tags Added.</Typography>}
                                            </div>
                                            {isTagsAdded ? (
                                                <div className={classes.chipBody}>
                                                    {checkArrayLength(currentTags) &&
                                                        currentTags.map((item, ind) => {
                                                            const { key } = item;
                                                            const _tags = item[key];
                                                            return (
                                                                <div
                                                                    style={
                                                                        checkArrayLength(selectedTestCase)
                                                                            ? {
                                                                                  width: '100%',
                                                                                  paddingBottom: 1,
                                                                                  borderBottom: '1px solid #ccc',
                                                                                  marginBottom: 4,
                                                                              }
                                                                            : { width: '100%' }
                                                                    }
                                                                    key={ind}
                                                                >
                                                                    {checkArrayLength(selectedTestCase) && isTagsAdded ? (
                                                                        <Tooltip data={_tags[0]}>
                                                                            {' '}
                                                                            <span className={classes.caseName}>{_tags[0]}</span>{' '}
                                                                        </Tooltip>
                                                                    ) : null}
                                                                    <div
                                                                        style={{
                                                                            width: `calc(100% - ${
                                                                                checkArrayLength(selectedTestCase) ? '90px' : '0px'
                                                                            })`,
                                                                            float: 'left',
                                                                        }}
                                                                    >
                                                                        {checkArrayLength(_tags[1]) ? (
                                                                            _tags[1].map((_tag, index) => {
                                                                                return (
                                                                                    <Chip
                                                                                        key={index}
                                                                                        label={_tag.name}
                                                                                        onDelete={() => {
                                                                                            if (
                                                                                                !(
                                                                                                    actionPerforming === 'untag' &&
                                                                                                    removedTagIndex === index &&
                                                                                                    removedTagKey === key &&
                                                                                                    isLoading
                                                                                                )
                                                                                            )
                                                                                                this.removeTagFromTestCase(_tag, index, key);
                                                                                        }}
                                                                                        className={classes.chip}
                                                                                        deleteIcon={
                                                                                            actionPerforming === 'untag' &&
                                                                                            removedTagIndex === index &&
                                                                                            removedTagKey === key &&
                                                                                            isLoading ? (
                                                                                                <CircularProgress
                                                                                                    style={{ cursor: 'default', color: '#4885ed' }}
                                                                                                    size={15}
                                                                                                    thickness={7}
                                                                                                />
                                                                                            ) : (
                                                                                                <WindowClose className={classes.svg} />
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <Chip
                                                                                label="-"
                                                                                onDelete={null}
                                                                                className={classes.chip}
                                                                                deleteIcon={<WindowClose className={classes.svg} />}
                                                                                style={{ visibility: 'hidden' }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                            {placement === 'top' ? this.arrowComponent('arrowBottom') : null}
                        </div>
                    </Grow>
                )}
            </Popper>
        );
    }
}

Popup.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        selectedTestCase: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
    }),
    isLoading: PropTypes.bool.isRequired,
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            disabled_status: PropTypes.bool,
            name: PropTypes.string,
            project_id: PropTypes.number,
            tag_id: PropTypes.number,
        }),
    ),
};

Popup.defaultProps = {
    tags: [],
    data: {
        name: 'testCaseSteps', // ['plan', 'testCaseSteps']
        selectedTestCase: null,
    },
};

const mapStateToProps = (state) => {
    return {
        // Tag Reducer
        isLoading: state.tagReducer.isLoading,
        tags: state.tagReducer.tags,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTags: (...args) => dispatch(TagActions.getTags(...args)),
        createTag: (...args) => dispatch(TagActions.createTag(...args)),
        tagTestCase: (...args) => dispatch(TagActions.tagTestCase(...args)),
        untagTestCase: (...args) => dispatch(TagActions.untagTestCase(...args)),
        addRemoveCases_Tag: (...args) => dispatch(TagActions.addRemoveCases_Tag(...args)),
    };
};

const CustomPopup = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Popup));

export { CustomPopup };
