import React from 'react'
//Importing utils
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core'

const useStyles = makeStyles({
    datasheetRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        backgroundColor: '#ffffff',
        borderRadius: '5px 5px 0 0',
        padding: '12px 10px 5px 0px',
        overflow: 'hidden',
        marginTop: '20px',
    },
    mainStyle: {
        color: '#00528a',
        margin: '1px 0px',
        overflow: 'hidden',
        fontSize: 14,
        maxWidth: 300,
        fontWeight: 'bold !important',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    subTitle: {
        color: '#676262',
        overflow: 'hidden',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 300,
        whiteSpace: 'nowrap',
        fontStretch: 'normal',
        textOverflow: 'ellipsis',
        letterSpacing: 'normal',
    },
    btnStyle: {
        fontWeight: '500',
        fontSize: '0.875rem',
        backgroundColor: '#2da6ea',
        color: '#fff',
        padding: '3px 25px',
        marginTop: 4,
        '&:hover': {
            backgroundColor: '#2da6ea',
        },
    },
    textContainer: {
        marginLeft: 15,
        marginTop: 5,
    },
    marginRight15: {
        marginRight: 15
    },
    error:{
        color: 'red',
        fontSize:12,
    },
});

const DatasheetHeaderMidddle = (props) => {
    const { sheetData, prevSheetData, isColumnEmpty, isDupColNamesFound, mainTitle, action, updateSheet, handleSave, handleCancel, handleDownload } = props
    const classes = useStyles();
    const isEdit = JSON.stringify(sheetData.map(row => row.map(cell => cell.value))) === JSON.stringify(prevSheetData.map(row => row.map(cell => cell.value)))
    return (
        <div className={classes.datasheetRoot}>
            <div className={classes.textContainer}>
                <Typography className={classes.mainStyle}>{mainTitle}</Typography>
                {(isColumnEmpty && sheetData.length > 0 && sheetData[0].some((elem) => elem.value?.trim() === '')) &&
                <Typography variant="body2" className={classes.error}>*Column name should not be empty</Typography>
                }
                {isDupColNamesFound &&
                <Typography variant="body2" className={classes.error}>*Column name should be unique</Typography>
                }  
            </div>
            <div className={classes.marginRight15}>               
                <Button
                    variant='contained'
                    onClick={updateSheet ? handleCancel : () => action(!updateSheet)}
                    disabled={!sheetData.length}
                    className={`${classes.btnStyle} ${classes.marginRight15}`}
                >
                    {updateSheet ? 'Cancel' : 'Edit'}
                </Button>
                <Button
                    onClick={!isEdit || updateSheet ? handleSave : handleDownload}
                    variant='contained'
                    disabled={!sheetData.length || (updateSheet && isEdit)}
                    style={updateSheet && isEdit ? { backgroundColor: 'rgba(0, 0, 0, 0.12)' } : {}}
                    className={classes.btnStyle}
                >
                    {`${!isEdit || updateSheet ? 'Save' : 'Download'}`}
                </Button>
            </div>
        </div>
    )
}

export default DatasheetHeaderMidddle
