import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { checkArrayLength } from '../../../utils/utils';
import Tooltip from '../../../components/Tooltip';
import { NO_WRAP } from '../../../common/cssConstants';

const styles = () => ({
    noWrap: NO_WRAP,
    title: {
        color: '#3B91DF',
        fontWeight: 'bold',
        paddingBottom: 5,
        marginTop: '10%',
        fontSize: 15,
    },
    root: {
        border: '1px solid #F0F0F0',
        marginTop: '5px',
    },
    caseDataContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        borderBottom: '1px solid #F0F0F0',
        fontSize: 14,
    },
    case: {
        width: '50%',
        fontWeight: 'bold',
    },
    data: {
        borderLeft: '1px solid #F0F0F0',
    },
    caseDataSubContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        borderBottom: '1px solid #F0F0F0',
    },
    background: {
        backgroundColor: '#FAFAFA',
    },
    caseName: {
        width: '50%',
    },
    wrapperItem: {
        width: '50%',
        height: '50%',
        borderLeft: '1px solid #F0F0F0',
        display: 'flex',
        flexDirection: 'column',
    },
    item: {
        height: '50%',
        padding: 9,
        fontSize: 14,
    },
    wrapperRoot: {
        marginTop: '6%',
    },
});

const Item = ({ classes, item, i, itemClass = '' }) => (
    <span key={i} className={`${classes.noWrap} ${classes.item} ${itemClass}`}>
        <Tooltip data={item}>{item}</Tooltip>
    </span>
);

const ConfirmationDialogContent = ({ classes, title, caseData }) => (
    <div className={classes.wrapperRoot}>
        <span className={classes.title}>{title}</span>
        <div className={classes.root}>
            <div className={classes.caseDataContainer}>
                <div className={`${classes.item} ${classes.case}`}>Case</div>
                <div className={`${classes.item} ${classes.case} ${classes.data}`}>Data</div>
            </div>
            {checkArrayLength(caseData) &&
                caseData.map((Obj, index) => (
                    <div key={index.toString()} className={`${classes.caseDataSubContainer} ${index % 2 ? classes.background : ''}`}>
                        <Item classes={classes} item={Obj.case} key={Obj.case.toString()} i={Obj.case.toString()} itemClass={classes.caseName} />
                        <div className={classes.wrapperItem}>
                            {checkArrayLength(Obj.data) ? (
                                Obj.data.map((item, i) => <Item classes={classes} item={item} key={i.toString()} i={i.toString()} />)
                            ) : (
                                <Item classes={classes} item={Obj.data} />
                            )}
                        </div>
                    </div>
                ))}
        </div>
    </div>
);

export default withStyles(styles)(ConfirmationDialogContent);
