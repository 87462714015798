import { MODAL_SUBMIT_BUTTON, MODAL_CANCEL_BUTTON, /* MODAL_DELETE_BUTTON, */ MODAL_OUTLINE_BUTTON } from '../../common/cssConstants';

const CANCEL_BUTTON = ({ name = 'Cancel', variant = 'outlined', buttonClass = '', style = {}, action = () => {}, isDisabled = false, ...rest }) => ({
    name,
    variant,
    buttonClass,
    style: { ...MODAL_CANCEL_BUTTON, ...style },
    action,
    isDisabled,
    ...rest,
});

const NEXT_SUBMIT_BUTTON = ({
    name = 'Next',
    variant = 'contained',
    buttonClass = '',
    style = {},
    action = () => {},
    isDisabled = false,
    ...rest
}) => ({
    name,
    variant,
    buttonClass,
    style: { ...MODAL_SUBMIT_BUTTON, ...style },
    action,
    isDisabled,
    ...rest,
});

const SAVE_OUTLINE_BUTTON = ({
    name = 'Save without going through all tabs',
    variant = 'outlined',
    buttonClass = '',
    style = {},
    action = () => {},
    isDisabled = false,
    ...rest
}) => ({ name, variant, buttonClass, style: { ...MODAL_OUTLINE_BUTTON, ...style }, action, isDisabled, ...rest });

const DANGER_OUTLINE_BUTTON = ({
    name = 'Delete',
    variant = 'outlined',
    buttonClass = '',
    style = {},
    action = () => {},
    isDisabled = false,
    ...rest
}) => ({ name, variant, buttonClass, style: { ...MODAL_SUBMIT_BUTTON, ...style }, action, isDisabled, ...rest });

const SAVE_PROJECT_BUTTON = ({
    name = 'Save Project',
    variant = 'contained',
    buttonClass = '',
    style = {},
    action = () => {},
    isDisabled = false,
    ...rest
}) => ({
    name,
    variant,
    buttonClass,
    style: { ...MODAL_SUBMIT_BUTTON, ...style },
    action,
    isDisabled,
    ...rest,
});

export { CANCEL_BUTTON, DANGER_OUTLINE_BUTTON, NEXT_SUBMIT_BUTTON, SAVE_OUTLINE_BUTTON , SAVE_PROJECT_BUTTON };
