import HttpBase from "./HttpBase";

class TestStepConfigurationService extends HttpBase {

    getTestStepConfigSetting = (testCaseId) => {
        
        return new Promise(async(resolve,reject)=>{
            const url = `/properties/${testCaseId}/gettestcaseproperties`
            try {
                const { data } = await this.httpClient.get(url);
                if(data) {
                    resolve(data)
                }
            } catch (error) {
                reject(error)
            }
        });
    }

    async updateTestStepConfigSetting(testCaseId,data) {
        
        return new Promise(async(resolve,reject)=>{
        const url = `/properties/${testCaseId}/updatetestcaseproperties`;
        try {
            const  result  = await this.httpClient.post(url,data);
            if(result && result.data) {
                resolve(result.data)
            }
        } catch (error) {
            reject(error)
        }
    });
    }
}

export default new TestStepConfigurationService(); 