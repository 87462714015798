import { ActionTypes } from '../constants';
import { addUserPreference, checkObject } from '../../utils';

const initialState = {
    testSteps: {},
    error: null,
    execute: '',
    executionDetails: {},
    executionStepsLoading: false,
    instrNumArray: [],
    isLoading: false,
    listView: false,
    success: null,
    executionStatus: {}, // store execution status while running
};

const executionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.CLEAR_EXECUTION_REDUCER:
            return {
                ...initialState,
            };

        case ActionTypes.EXECUTE_TAG:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.EXECUTE_TAG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
                success: 'Tag executed successfully',
            };

        case ActionTypes.EXECUTE_TAG_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: 'Failed to execute tag',
                success: null,
            };
        //= ===================================;
        case ActionTypes.CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };
        case ActionTypes.CLEAR_SUCCESS:
            return {
                ...state,
                success: null,
            };
        //= ===================================;

        case ActionTypes.GET_EXECUTION_DETAILS:
            return {
                ...state,
                isLoading: true,
                executionStepsLoading: true,
            };

        case ActionTypes.GET_EXECUTION_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                executionStepsLoading: false,
                error: null,
                executionDetails: payload.data,
            };

        case ActionTypes.GET_EXECUTION_DETAILS_FAILURE:
            return {
                ...state,
                isLoading: false,
                executionStepsLoading: false,
                error: 'Failed to get execution',
                success: null,
            };

        case ActionTypes.SET_EXECUTION_DATA: {
            const { instrNumArray, testStepsObj } = payload;
            const obj = {
                ...state,
                instrNumArray,
                testSteps: testStepsObj,
            };
            return obj;
        }

        case ActionTypes.UPDATE_EXECUTION_VIEW_TYPE: {
            const { flag } = payload;
            setTimeout(() => {
                addUserPreference('executionlistview', flag);
            }, 500);
            return { ...state, listView: flag };
        }

        case ActionTypes.UPDATE_SINGLE_EXECUTION_STEP_STATUS: {
            const { instrNum, data, msgType, executionId, paramExecutionId } = payload;
            if (`${executionId}` === `${paramExecutionId}`) {
                const currentStep = checkObject(state.testSteps[instrNum]) ? JSON.parse(JSON.stringify(state.testSteps[instrNum])) : {};
                let testSteps = { ...state.testSteps };

                if (msgType === '5') {
                    const parentInstrNum = instrNum.split('.').shift();
                    let parentStep = checkObject(state.testSteps[parentInstrNum]) ? JSON.parse(JSON.stringify(state.testSteps[parentInstrNum])) : {};
                    parentStep = { ...parentStep, status: data.status };
                    testSteps = { ...testSteps, [parentInstrNum]: { ...parentStep } };
                }

                testSteps = { ...testSteps, [instrNum]: { ...currentStep, ...data } };
                const obj = { ...state, testSteps };
                return obj;
            }
            return { ...state };
        }

        case ActionTypes.UPDATE_EXECUITON_STATUS: {
            const { executionId, status } = payload;
            const executionStatus = JSON.parse(JSON.stringify(state.executionStatus));
            if (status || status === 0) {
                executionStatus[executionId] = status;
            } else {
                delete executionStatus[executionId];
            }
            return { ...state, executionStatus };
        }

        default:
            return state;
    }
};

export default executionReducer;
