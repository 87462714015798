import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { DIALOG_ROOT, MODAL_FOOTER } from '../../../common/cssConstants';
import { checkArrayLength, checkKeyInObject } from '../../../utils/utils';
import { generalModalActions } from '../../../store/actions';
import GeneralModalFooter from './GeneralModalFooter';
import { DialogTop } from '../../../components/Modal/ModalTop';

const styles = (theme) => ({
    dialogRoot: {
        ...DIALOG_ROOT,
        '&>:nth-child(2)': {
            ...DIALOG_ROOT['&>:nth-child(2)'],
            position: 'unset',
        },
    },
    dialogContent: {
        padding: '0px !important',
        maxWidth: '700px',
    },
    stepVal: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        paddingTop: 13,
        fontSize: 12,
        color: '#9B9B9B',
        cursor: 'pointer',
    },
    activeStepVal: {
        borderTop: '3px solid #1168CD',
        paddingTop: 10,
        color: '#1168CD',
    },
    stepContainer: {
        position: 'relative',
        marginRight: 50,
    },
    stepper: {
        padding: '0px 30px !important',
        overflow: 'hidden',
        marginBottom: 30,
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        [theme.breakpoints.only('xs')]: {
            maxWidth: '257px',
        },
    },
    positionRelative: {
        position: 'relative',
        overflowY:'auto',
    },
    visibleTab:{
        display:'none',
    },
    typography:{
        fontSize: 12, 
        margin: '0 0 5px 30px',
    }
});

const GeneralModal = (props) => {
    let modalFooterStyle = props.modalData.component[props.currentTab].modalFooterStyle || {}
    return (
        <div data-testid="generalModal">
            <Dialog
                disableRestoreFocus
                disableEnforceFocus
                open={props.open}
                aria-labelledby="form-dialog-title"
                maxWidth={false}
                className={props.classes.dialogRoot}
            >
                <DialogTop
                    title={props.modalData.title}
                    closeIcon={props.modalData.closeIcon}
                    closeIconAction={props.modalData.closeIconAction}
                    onEscapeAction={props.modalData.onEscapeAction}
                />
                <div className={props.classes.positionRelative}>
                    <DialogContent className={`${props.classes.dialogContent} ${props.modalData.dialogContentStyle || ''}`}>
                        {checkArrayLength(props.modalData.component) && props.modalData.component.length > 1 && (
                            <div className={props.classes.stepper}>
                                {props.modalData.component.map((component, index) => {
                                    const isDisabled = checkKeyInObject(props.tabsData, 'organize') ? (props.currentTab === 1 && checkKeyInObject(checkKeyInObject(checkKeyInObject(props.tabsData, 'association'), 'state'), 'didChange', 'value', true) ? typeof component.isDisabled === "function" ? component.isDisabled(props.tabsData) : component.isDisabled : false ) : (typeof component.isDisabled === "function" ? component.isDisabled(props.tabsData) : component.isDisabled)
                                    return <div
                                        aria-hidden
                                        className={`${props.classes.stepContainer} ${
                                            component.stepContainerFromProps ? component.stepContainerFromProps : ''
                                        }${ isDisabled ? props.classes.visibleTab : ''}`}
                                        key={index}
                                        onClick={() => props.changeTab(index)}
                                    >
                                        <span
                                            className={`${props.classes.stepVal} ${index === props.currentTab ? props.classes.activeStepVal : ''} ${
                                                index === props.currentTab && component.activeStepStyleFromProps
                                                    ? component.activeStepStyleFromProps
                                                    : ''
                                            }`}
                                        >
                                            {component.name}

                                        </span>
                                    </div>
                                })}
                            </div>
                        )}
                        {props?.currentTab === 1 && (props?.modalData?.title === "New Test Suite" || props?.modalData?.title === "Update Suite") && <Typography className={props.classes.typography}>You can only add generated test cases to test suites.</Typography>}
                        {props.modalData.component[props.currentTab].content}
                    </DialogContent>
                    <DialogActions style={{...MODAL_FOOTER,...modalFooterStyle}}>
                        <GeneralModalFooter buttons={props.modalData.component[props.currentTab].buttons} />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => ({
    currentTab: state.generalModalReducer.currentTab,
    tabsData: state.generalModalReducer.tabsData,
});

const mapDispatchToProps = (dispatch) => {
    return {
        changeTab: (...args) => dispatch(generalModalActions.changeTab(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GeneralModal));
