// import material
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

// import libs
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import icons
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chart from 'mdi-material-ui/ChartLine';
import Logout from '@material-ui/icons/PowerSettingsNew';
import Flash from 'mdi-material-ui/Flash';
import projects_icon from '../../../assets/icons/features/projects_icon.svg';
import schedule_icon from '../../../assets/icons/features/schedule_icon.svg';
import reports_icon from '../../../assets/icons/features/reports_icon.svg';
import projects_icon_white from '../../../assets/icons/features/projects_icon_white.svg';
import schedule_icon_white from '../../../assets/icons/features/schedule_icon_white.svg';
import reports_icon_white from '../../../assets/icons/features/reports_icon_white.svg';

import ClockTimeTwoOutline  from 'mdi-material-ui/ClockTimeTwoOutline';

// import custom components

import { GeneralActions, SidebarActions, UserActions, AuthActions } from '../../../store/actions';
import './sideBarStyle.css';
import { isOnProjectPage } from '../../../utils/utils';
import { BLACK_FONT } from '../../../common/cssConstants';
import { USER_NAME, AUTONOMIQ_COPYRIGHT, AUTONOMIQ_VERSION, AUTONOMIQ_WEBSITE, AUTONOMIQ_YEAR } from '../../../common/constants';
import { SEGMENT_EVENT } from '../../../common/constants';
import { track } from '../../../services/Segment';
import Tooltip from '../../../components/Tooltip';
const styles = ({ palette, spacing, typography }) => ({
    expand: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    expandIconContainer: {
        width: '20%',
        display: 'flex',
        justifyContent: 'center',
    },
    inset: {
        paddingLeft: '40px !important',
    },
    title: {
        backgroundColor: 'rgba(0, 0, 0, 0.22) !important',
        cursor: 'default',
        height: 50,
        padding: '15px 0px 0px 22px',
    },
    listItemParent: {
        color: palette.primary.main,
        height: 26,
        minHeight: 26,
        '&:hover': {
            backgroundColor: 'transparent',
            '& p': {
                color: 'white',
            },
            '& span': {
                color: 'white',
            },
            '& svg': {
                color: 'white',
            },
        },
    },
    listItem: {
        color: palette.primary.main,
        // height: 26,
        // minHeight: 26,
        height: 'auto',
        padding: '0px !important',
        '& a': {
            color: palette.primary.main,
            display: 'inline-flex',
            flex: 1,
            height: '100%',
            padding: '12px 16px',
            textDecoration: 'none',
            alignItems: 'center',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            '& a': {
                color: 'white',
            },
            '& p': {
                color: 'white',
            },
            '& span': {
                color: 'white',
            },
            '& svg': {
                color: 'white',
            },
        },
    },
    secondary: {
        color: '#BAC4CE',
        paddingTop: spacing(0.5),
    },
    secondary1: {
        color: '#BAC4CE',
        paddingBottom: spacing(0.5),
    },
    textIcon: {
        width: 24,
        textAlign: 'center',
    },
    secondary_selected: {
        color: '#fff',
        fontSize: typography.caption.fontSize + 2,
        paddingTop: spacing(0.5),
    },
    selected: {
        backgroundColor: 'transparent !important',
        color: '#ffffff',
        '& > div > div p': {
            color: '#ffffff',
        },
    },
    muiMenuItem_root: {
        boxSizing: 'content-box',
        display: 'flex',
        paddingTop: 12,
        paddingBottom: 12,
        justifyContent: 'flex-start',
        paddingRight: 16,
        paddingLeft: 16,
        borderTop: '0px solid #616161',
        borderBottom: '0px solid #616161',
    },
    subSelected: {
        backgroundColor: 'rgba(0, 0, 0, 0.22)',
        borderLeft: '5px #F0B637 solid',
        '& > div p': {
            color: '#fff',
        },
    },
    subSelectedDark: {
        backgroundColor: 'black !important',
        borderLeft: '5px #F0B637 solid',
        paddingLeft: '11px !important',
        '& > div p': {
            color: '#fff',
        },
    },
    avatar: {
        minWidth: '35px',
        height: '20px',
        borderRadius: '20px',
        backgroundColor: '#b0c8fa',
        color: `${BLACK_FONT} !important`,
        '& span': {
            color: `${BLACK_FONT} !important`,
        },
        textAlign: 'center',
        marginRight: '2px',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: 1.7,
        padding: '0 4px',
        display: 'inline-block',
        overflow: 'hidden',
        marginLeft: '10px',
        marginTop: '4px',
        boxSizing: 'border-box',
    },
    listItemText: {
        marginTop: 0,
        marginBottom: 0,
        padding: '0 16px 0 25px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    copyVer: {
        margin: '20px 10px 10px',
        textAlign: 'center',
    },
    copyright: {
        marginBottom: 10,
        color: '#fff',
        fontSize: 11,
    },
    website: {
        lineHeight: 1,
        color: '#b5cdf2',
        textDecoration: 'none',
        fontSize: 11,
    },
    version: {
        color: '#fff',
        fontSize: 10,
    },
});

class SideBarContent extends Component {
    constructor() {
        super();
        this.state = {
            selectedItem: '/projects',
        };
    }

    componentDidMount() {
        this.highlightLocationOnSideBar();
        this.props.getSideBarDataCount();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isUpdate !== this.props.isUpdate) {
            this.highlightLocationOnSideBar();
        }
    }

    // Highlight side bar as location changes
    highlightLocationOnSideBar = () => {
        // expand -> expandable as per selectedItem
        //  selectedItemId = 3 -> Admin
        //  selectedItemId = 4 -> Plan
        //  selectedItemId =  -> Analyze
        const { selectedItem } = this.state;
        const { location } = this.props.history;
        let url = selectedItem;
        let newUrl = location && location.pathname;
        // Check specially on refresh if selectedItem not equal to '/dashboard'
        if (newUrl && newUrl.includes('/projects')) {
            newUrl = '/projects';
        }
        if (newUrl && newUrl !== url) {
            url = newUrl;
            this.setState({ selectedItem: url });
        }
        this.expandOrCollaps(url);
    };

    // SideBar Parent Expand/Collapse ends
    expandOrCollaps = (url) => {
        //  use toggleSideBarItemExpansionFlag withour param to collapse all
        if (url.includes('/admin')) {
            this.toggleSideBarItemExpansion('admin');
        } else if (url.includes('/analyze')) {
            this.toggleSideBarItemExpansion('analyze');
        } else {
            this.toggleSideBarItemExpansion();
        }
    };

    toggleSideBarItemExpansion = (name = null) => {
        // Close all collapsables then open wrt to name
        this.props.toggleSideBarItemExpansionFlag();
        if (name) {
            this.props.toggleSideBarItemExpansionFlag(name);
        }
    };

    // Redirections
    routeToView = (name,page) => {



        // if(page) {
        //     page = page.toUpperCase();
        //     const eventName = SEGMENT_EVENT.MAIN_NAVIGATION_EVENT[page];
        //     track(eventName,{});
        // }

        const { history, swipable, toggleSwipableDrawer } = this.props;
        const { location } = history;

        if (swipable) toggleSwipableDrawer(false);
        if (location && location.pathname && location.pathname !== name) {
            this.setState({ selectedItem: name }, () => {
                this.expandOrCollaps(name);
            });
        }
    };

    render() {
        const {
            classes,
            expandAdmin,
            expandExecutions,
            user,
            sideBarCount,
            swipable,
            showDisabledProjects,
            isSideBarOpened,
        } = this.props;
        const { selectedItem } = this.state;
        let AdminFlag = false; // 'false' is indicate user has SDET role
        let AppuserFlag = false; // 'false' is indicate user is not an appuser
        if (user && user.role === 'ADMIN') {
            // change the flag value if user has ADMIN role
            AdminFlag = true;
            if (user.name === USER_NAME) {
                AppuserFlag = true;
            }
        }
        const subSelected = isSideBarOpened ? classes.subSelectedDark : classes.subSelected;
        const content = (
            <div className={`container ${classes.container}`} style={{ maxHeight: `calc(100vh - ${swipable ? '120px' : '100px'})` }}>
                <div>
                        <MenuItem
                                button
                                className={classes.listItem}
                                onClick={() => this.routeToView('/projects','Projects')}
                                classes={{
                                    //selected: subSelected,
                                    root: classes.muiMenuItem_root,
                                }}
                                selected={isOnProjectPage()}
                            >
                                <Link to="/projects" >
                                    <div className={classes.expand}>
                                        <div style={{ display: 'inline-flex', width: '80%' }}>
                                                {
                                                    isOnProjectPage() ? <Tooltip  data={!(isSideBarOpened) ?'Projects':''}><img style={{marginTop:isSideBarOpened?'0px':'6px'}}  src={projects_icon_white}/> </Tooltip> : <Tooltip  data={!(isSideBarOpened) ?'Projects ':'' }><img style={{marginTop:isSideBarOpened?'0px':'6px'}} src={projects_icon}/></Tooltip>
                                                }
                                            <ListItemText
                                                className={classes.listItemText}
                                                classes={{ secondary: classes.secondary1 }}
                                                secondary={
                                                    <span style={{ display: 'flex' }}>
                                                        <span style={isOnProjectPage() ? { color: '#fff' } : {color:'#B6CDF2'}} className={classes.secondary}>
                                                            Projects
                                                        </span>
                                                        
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </MenuItem>
                            <MenuItem
                                button
                                className={classes.listItem}
                                onClick={() => this.routeToView('/schedule','Schedules')}
                                classes={{
                                   // selected: subSelected,
                                    root: classes.muiMenuItem_root,
                                }}
                                selected={selectedItem.includes('/schedule')}
                                style={{ borderTop: '1px solid #616161', borderBottom: expandExecutions ? '' : '1px solid #616161' }}
                            >
                                <Link to="/schedule">
                                    <div className={classes.expand}>
                                        <div style={{ display: 'inline-flex', width: '80%' }}>
                                        {
                                            selectedItem === '/schedule' ? <Tooltip  data={!(isSideBarOpened)?'Schedules  ':''}><img style={{marginTop:isSideBarOpened?'0px':'4px'}} src={schedule_icon_white}/></Tooltip>:<Tooltip  data={!(isSideBarOpened)?'  Schedules ':''}><img style={{marginTop:isSideBarOpened?'0px':'4px'}} src={schedule_icon}/></Tooltip>

                                        }


                                            <ListItemText
                                                className={classes.listItemText}
                                                classes={{ secondary: classes.secondary1 }}
                                                secondary={
                                                    <span style={{ display: 'flex' }}>
                                                        <span
                                                            style={selectedItem === '/schedule' ? { color: '#fff' } : {color:'#B6CDF2'}}
                                                            className={classes.secondary}
                                                        >
                                                            Schedules
                                                        </span>
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </MenuItem>
                    
                            <MenuItem
                                button
                                className={classes.listItem}
                                onClick={() => this.routeToView('/reports','Reports')}
                                classes={{
                                   // selected: subSelected,
                                    root: classes.muiMenuItem_root,
                                }}
                                selected={selectedItem.includes('/reports')}
                                style={{ borderBottom: '1px solid #616161'}}
                                
                            >
                                <Link to="/reports" >
                                    <div className={classes.expand}>
                                        <div style={{ display: 'inline-flex', width: '80%' }}>
                                        {
                                            selectedItem === '/reports' ? <Tooltip data={!(isSideBarOpened)?'Reports':''}> <img style={{marginTop:isSideBarOpened?'0px':'8px'}} src={reports_icon_white}/></Tooltip>:<Tooltip data={!(isSideBarOpened) ? 'Reports ':''}><img style={{marginTop:isSideBarOpened?'0px':'8px'}} src={reports_icon}/></Tooltip>
                                        }
                                            <ListItemText
                                                className={classes.listItemText}
                                                classes={{ secondary: classes.secondary1 }}
                                                secondary={
                                                    <span style={{ display: 'flex' }}>
                                                        <span
                                                            style={selectedItem === '/reports' ? { color: '#fff' } : {color:'#B6CDF2'}}
                                                            className={classes.secondary}
                                                        >
                                                            Reports
                                                        </span>
                                                    </span>
                                                }
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </MenuItem>
                           {/* Executions Tab End */}
                    {/* {!AdminFlag ? (
                        <MenuItem
                            button
                            className={classes.listItem}
                            onClick={() => this.routeToView('/profile','Profile')}
                            classes={{
                                selected: classes.selected,
                                root: classes.muiMenuItem_root,
                            }}
                            selected={selectedItem === '/profile'}
                            style={{ borderTop: '1px solid #616161', borderBottom: expandAdmin ? '' : '1px solid #616161' }}
                        >
                            <Link to="/profile" style={selectedItem === '/profile' ? { paddingLeft: 11 } : {}}>
                                <div style={{ display: 'inline-flex' }}>
                                    <FontAwesomeIcon icon={faUser} aria-label="faUser" id="faUser" style={{ margin: '5px 5px 0px 7px' }} />
                                    <ListItemText className={classes.listItemText} classes={{ secondary: classes.secondary }} secondary="Profile" />
                                </div>
                            </Link>
                        </MenuItem>
                    ) : null} */}

                    {swipable && (
                        <MenuItem
                            button
                            onClick={() => {
                                this.props.logout();
                            }}
                            // selected={selectedItem === '/dashboard'}
                            className={classes.listItemParent}
                            classes={{  root: classes.muiMenuItem_root }}
                        >
                            <div style={{ display: 'inline-flex' }}>
                                <Logout aria-label="logoutIcon" id="logoutIcon" />
                                <ListItemText className={classes.listItemText} classes={{ secondary: classes.secondary }} secondary="Log out" />
                            </div>
                        </MenuItem>
                    )}
                </div>
                {isSideBarOpened ? (
                    <div className={classes.copyVer}>
                        <div className={classes.copyright}>
                            {AUTONOMIQ_YEAR}{' '}
                            <a className={classes.website} href={`${AUTONOMIQ_WEBSITE}`}>
                                AutonomIQ.io
                            </a>
                            {AUTONOMIQ_COPYRIGHT}
                            {/* <div className={classes.website}><a href={`${AUTONOMIQ_WEBSITE}`}>{AUTONOMIQ_WEBSITE.replace(/https?:[/]{2}/, '')}</a></div> */}
                            {/* <div className={classes.copyright}>{AUTONOMIQ_COPYRIGHT}</div> */}
                        </div>
                        <div className={classes.version}>{AUTONOMIQ_VERSION}</div>
                    </div>
                ) : null}
            </div>
        );
        return content;
    }
}

SideBarContent.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
        expandAdmin: state.userReducer.expandAdmin,
        expandPlan: state.userReducer.expandPlan,
        expandExecutions: state.userReducer.expandExecutions,
        expandAnalyze: state.userReducer.expandAnalyze,
        sideBarCount: state.sidebarReducer.sideBarCount,
        showDisabledProjects: state.projectsReducer.showDisabledProjects,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleSideBarItemExpansionFlag: (...args) => dispatch(UserActions.toggleSideBarItemExpansion(...args)),
        getSideBarDataCount: () => dispatch(SidebarActions.getSideBarDataCount()),
        toggleSwipableDrawer: (...args) => dispatch(GeneralActions.toggleSwipableDrawer(...args)),
        logout: () => dispatch(AuthActions.logout()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SideBarContent));
