// import material
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

// import packages
import React from 'react';
import { connect } from 'react-redux';

// import icons
import ClearAllRoundedIcon from '@material-ui/icons/ClearAllRounded';


import ArtTrack from '@material-ui/icons/ArtTrack';
import Download from 'mdi-material-ui/Download';
import FileDownload from '@material-ui/icons/GetApp';
import PlayForWork from '@material-ui/icons/PlayForWork';
import { faClock, faBolt, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSafari, faChrome, faFirefox, faInternetExplorer, faLinux, faWindows, faApple, faAndroid, faEdge} from '@fortawesome/free-brands-svg-icons';
// import custom copmponents or services
import { BLACK_FONT, THREE_DOT_MENU_CSS, EXECUTION_BUTTON } from '../../../../common/cssConstants';
import Tooltip from '../../../../components/Tooltip';
import { ExecutionActions, SelectedTestCaseActions , ProjectActions} from '../../../../store/actions';
import { TestCaseUtils, checkObject, checkArrayLength, getParamValues, checkKeyInObject, getFormatedDate, TestScriptUtils } from '../../../../utils';
import { MenuOptions } from '../../../../components/Button/MenuOptions';
import TestCaseTimer from '../../TestSteps/Header/TestCaseTimer';
import SingleFileCreationService from '../../../../services/singleFileCreationService';
import { Link } from 'react-router-dom';
import moment from 'moment';


// Devices/Browsers Icons
import firefox_Icon from '../../../../assets/icons/browsers/firefox.svg';
import chrome_Icon from '../../../../assets/icons/browsers/chrome.svg';
import microsoftedge_Icon from '../../../../assets/icons/browsers/microsoftedge.svg';
import ie_Icon from '../../../../assets/icons/browsers/ie.svg';
import safari_Icon from '../../../../assets/icons/browsers/safari.svg';
import default_browser_icon from '../../../../assets/icons/browsers/default.svg';

// Platfroms Icons
// Devices/Browsers Icons
import windows_Icon from '../../../../assets/icons/platforms/windows.svg';
import linux_Icon from '../../../../assets/icons/platforms/linux.svg';
import android_Icon from '../../../../assets/icons/platforms/android.svg';
import macos_Icon from '../../../../assets/icons/platforms/macos.svg';
import default_platform_icon from '../../../../assets/icons/platforms/default.svg';

// Device Type Icons
import real_device_Icon from '../../../../assets/icons/devices/real.svg'
import emulator_Icon from '../../../../assets/icons/devices/emulator.svg'
import TestStepConfigSettingService from '../../../../services/TestStepConfigSettingService';

const style = () => ({
    cachedBtn: {
        minHeight: '0px',
        padding: '0px',
        minWidth: '0px',
        textTransform: 'unset',
        '&:hover': {
            backgroundColor: '#e1e1e1',
        },
    },
    displayButtonIcons: {
        height: 20,
        width: 20,
    },
    stepTimerText: {
        color: '#587c99',
        fontWeight: '500',
        fontSize: '12px',
        marginRight: '10px',
    },
    text: {
        fontSize: '12px',
        paddingTop: '2px',
        color: BLACK_FONT,
    },
    moreIconDisplay: {
        width:' 0.70em',
    },
    moreIconsBtnDisabled: {
        color: THREE_DOT_MENU_CSS.iconDisableColor,
    },
    moreIconsBtnEnabled: {
        color: 'rgba(0, 0, 0, 0.87)',
    },
    avatar: {
        minWidth: '18px',
        height: '18px',
        borderRadius: '18px',
        backgroundColor: '#5F7B96',
        color: '#fff',
        textAlign: 'center',
        marginRight: '8px',
        fontSize: '14px',
        lineHeight: 1.4,
        padding: '0 4px',
        display: 'inline-block',
    },
    headerMiddleRoot: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        backgroundColor: '#ffffff',
        borderBottom: '1px solid #e0e0e0',
        padding: '10px 10px 20px 10px',
        overflow: 'hidden',
        boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        marginTop: '20px',
    },
    headerMiddleLeftContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'calc(100%)',
    },
    descContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    caseDetailContainer: {
        marginLeft: 15,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden',
    },
    iconsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    midHeader: { 
        display: 'flex', 
        width: '100%', 
        paddingTop: 20, 
    },
    ActionHeader: {
        minWidth: '320px',
        display: 'flex',
        alignItems: 'center',    
    },
    headerTitle: {
        marginLeft: '270px',
        opacity: '0.88',
        fontFamily: 'HelveticaNeue',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '0.75',
        letterSpacing: 'normal',
        color: '#043452',
    },
    editTestCaseIcon: {
        width: '20px',
    },
    btnText: {
        // color: EXECUTION_BUTTON,
        color:'rgba(0, 0, 0, 0.54)',
        fontSize: '12px',
        margin: '7px 20px 6px 3px',
    },
    outlinedButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        marginRight: 10,
        border: `1px solid ${EXECUTION_BUTTON}`,
        '&:hover': {
            backgroundColor: '#e1e1e1',
        },
    },
    BlueButtonSvg: {
        height: 20,
        width: 'auto',
        margin: '5px 8px 5px 20px',
    },
    // moreIcons: {
    //     color: '#8898A8',
    //     marginRight: THREE_DOT_MENU_CSS.iconMarginRight,
    //     marginLeft: THREE_DOT_MENU_CSS.iconMarginLeft,
    // },
    iconWrapper:{
        display:'flex',
        marginRight: '-7px',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
    },
    faIcons: {
        fontSize: 17,
    },
    iconButton: {
        width: '30px',
        marginLeft:'20px',
    },
    disableHoverEffect: {
        '&:hover': {
            background: 'none',
        },
    },
    name: {
        color: '#00528a',
        fontSize: '14px !important',
        fontWeight: 'bold !important',
        margin: '1px 0px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    displayTimer: {
        display: 'flex',
        alignItems: 'center',
    },
    actionItems: {
        display: 'flex',
        width: '100%',
        alignItems: 'center' ,  

    },
    linkStyle: {
        textDecoration: 'none',
        color: 'rgb(136 152 168)'
    },
    browserIcon: {
        width: '18px',
        height: '18px',
    },
    emulatorIcon: {
        width:'18px',
        height:'18px'
    },
    editstepBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 16px',
        margin:'6px',
        fontFamily: 'Roboto',
        textTransform: 'none',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight:'1.4',
        borderRadius: '4px',
        color:'#3B91DF',
        background: '#fffff',
        border:'solid 1px #3B91DF',
        minWidth:'144px',
        '&:hover': {
            backgroundColor: '#ffffff'
        }
    },
});

const browserDetails = [ faChrome, faEdge, faFirefox, faInternetExplorer, faSafari];
const platformDetails = [faLinux, faWindows, faApple, faAndroid];

// const CustomButton = ({
//     btnClass,
//     buttonRef,
//     dataRh,
//     disabled,
//     Icon,
//     onClick,
//     svgClass,
//     flag,
//     stopIndicate,
//     faIcon,
//     txtStyle,
//     buttonTitle,
//     disableSvgClass,
//     enableSvgClass,
//     iconStyle,
//     circularProgressStyle,
// }) => {
//     return (
//         <span ref={buttonRef}>
//             <Tooltip data={disabled ? null : dataRh}>
//                 <Button className={btnClass} onClick={onClick} disabled={disabled}>
//                     {disabled && flag && !stopIndicate ? (
//                         <CircularProgress size={21} style={circularProgressStyle} />
//                     ) : Icon ? (
//                         <Icon className={`${svgClass} ${disabled ? disableSvgClass : enableSvgClass}`} style={iconStyle}></Icon>
//                     ) : faIcon ? (
//                         <FontAwesomeIcon className={`${svgClass} ${disabled ? disableSvgClass : enableSvgClass}`} style={iconStyle} icon={faIcon} />
//                     ) : null}
//                     {buttonTitle && <Typography className={txtStyle}>{buttonTitle}</Typography>}
//                 </Button>
//             </Tooltip>
//         </span>
//     );
// };

class ExecutionHeaderMiddle extends React.Component {
    get isAnyDownloadFileAvailable() {
        const { testSteps, instrNumArray } = this.props;
        return (
            checkObject(testSteps) &&
            checkArrayLength(instrNumArray) &&
            instrNumArray.some((instrNum) => testSteps[instrNum] && testSteps[instrNum].downloadFile)
        );
    }

    componentDidMount() {

        const {toggleShadowDom, getProjectDetails,selectedProject} = this.props;
        getParamValues(selectedProject.id)


        const testCaseId = getParamValues(2);


        TestStepConfigSettingService.getTestStepConfigSetting(testCaseId).then((res)=>{
            if(res && res.shadowDom) {
                toggleShadowDom(true)
            } if(res && !(res.shadowDom)) {
                toggleShadowDom(false);
            }
        })
    }

    renderActionMenuItems() {
        const { classes } = this.props;
        const { isAnyDownloadFileAvailable } = this;
        const paramExecutionId = getParamValues(4);
        const items = [
            {
                action: () => {
                    TestCaseUtils.downloadFileZip('Execution', paramExecutionId, true);
                },
                icon: (
                    <Download
                        className={`${classes.moreIcons} ${classes.displayButtonIcons18}`}
                        style={{ color: isAnyDownloadFileAvailable ? '#8898A8' : '#C4C4C4' }}
                        aria-label="downloadIconExecution"
                        id="downloadIconExecution"
                    />
                ),
                isDisabled: !isAnyDownloadFileAvailable,
                name: 'Get Downlaoded Files',
            },
        ];
        return items;
    }

    renderDisplayItems = () => {
        const { autoScroll, classes, listView, updateExeuctionViewType, executionDetails, paramTestCaseId, paramExecutionId } = this.props;
        const items = [
            {
                action: () => {
                    updateExeuctionViewType(!listView);
                },
                type: 'custom',
                key: listView,
                icon: <ArtTrack className={`${classes.moreIcons} ${classes.displayButtonIcons}`} />,
                name: ['Screen', 'Line View'],
            },
            {
                action: () => {
                    this.props.toggleAutoScroll();
                },
                type: 'customToggle',
                key: autoScroll,
                icon: <PlayForWork className={`${classes.moreIcons} ${classes.displayButtonIcons}`} />,
                isDisabled: false,
                name: 'Auto Scroll',
            },
            {
                action: () => {
                    SingleFileCreationService.getDownloadAiqReport(executionDetails.reportUrl, `${executionDetails.name}_Report`, false, true, paramTestCaseId, paramExecutionId);
                },
                icon: (
                    <FileDownload
                        className={[
                            classes.moreIconDisplay,
                            !executionDetails 
                                ? classes.moreIconsBtnDisabled
                                : classes.moreIconsBtnEnabled,
                        ].join(' ')}
                        aria-label="fileDownloadIcon"
                        id="fileDownloadIcon"
                    />
                ),
                isDisabled: !executionDetails || executionDetails.status === 0 ,
                name: ' Download Execution Report',
            }
        ];
        return items;
    };

    browserFilter = (item) => {
        const {executionDetails} = this.props;
        if(executionDetails && checkKeyInObject(executionDetails,'browser')){
            if (item.iconName.toLowerCase().replace('-','').includes(TestScriptUtils.getBrowserOptionValue(executionDetails.browser).toLowerCase().trim())){
                return true;
            } 
            return false;
        }
    }

    platformFilter = (item) => {
        const {executionDetails} = this.props;
        if(executionDetails && checkKeyInObject(executionDetails,'platform')){
            if (item.iconName.toLowerCase().replace('-','').includes(TestScriptUtils.getPlatformValueForSend(executionDetails.platform).toLowerCase().trim())){
                return true
            } 
            return false;
        }
    }

    getBrowserNameToDisplay = (browserName) => {
        if(typeof browserName!=='string' || !browserName) {
            return ''
        }
        if(browserName.toLowerCase().includes('edge')) {
            return 'Microsoft Edge'
        }
        return `${browserName.charAt(0).toUpperCase()}${browserName.slice(1)}`;

    }

    getPlatformNameToDisplay = (platformName) => {
        if(typeof platformName!=='string' || !platformName) {
            return ''
        }
        if(platformName.toLowerCase().includes('mac')) {
            return  `macOS ${platformName.split(' ')[1]|| ''}`
        }
        return `${platformName.charAt(0).toUpperCase()}${platformName.slice(1)}`;

    }

    getBrowserIcon = (browserName={}) => {
        const { classes } = this.props;
        const browser_name = browserName.iconName;

        switch (browser_name?.toLowerCase()) {
            case 'firefox':
                return <img src={firefox_Icon} alt="Firefox" className={classes.browserIcon} />;
            case 'chrome':
                return <img src={chrome_Icon} alt="Chrome" className={classes.browserIcon} />;
            case 'internet explorer':
                return <img src={ie_Icon} alt="Internet Explorer" className={classes.browserIcon} />;
            case 'safari':
                return <img src={safari_Icon} alt="Safari" className={classes.browserIcon} />;
            case 'microsoftedge':
                return <img src={microsoftedge_Icon} alt="Microsoftedge" className={classes.browserIcon} />;
            case 'edge':
                return <img src={microsoftedge_Icon} alt="Microsoftedge" className={classes.browserIcon} />;
    
            default:
                return <img src={default_browser_icon} alt="Firefox" className={classes.browserIcon} />;

        }

    }

    getPlatformIcon = (platformName={}) => {
        const { classes } = this.props;
        const platform_name = platformName.iconName;

        switch (platform_name?.toLowerCase()) {
            case 'windows':
                return <img src={windows_Icon} alt="Windows" className={classes.browserIcon} />;
            case 'linux':
                return <img src={linux_Icon} alt="Linux" className={classes.browserIcon} />;
            case 'android':
                return <img src={android_Icon} alt="Android" className={classes.browserIcon} />;
            case 'macos':
                return <img src={macos_Icon} alt="MacOS" className={classes.browserIcon} />;
            case 'apple':
                return <img src={macos_Icon} alt="MacOS" className={classes.browserIcon} />;
            case 'ios':
                return <img src={macos_Icon} alt="MacOS" className={classes.browserIcon} />;
            case 'apple':
                return <img src={macos_Icon} alt="MacOS" className={classes.browserIcon} />;
            default:
                return <img src={default_platform_icon} alt="Default" className={classes.browserIcon} />;

        }

    }

    getDeviceTypeIcon = (type='') => {
        const { classes } = this.props;
        switch (type?.toLowerCase()) {
            case 'emulator':
                return <img src={emulator_Icon} alt="Emulator" className={classes.emulatorIcon} />;
            case 'real':
                return <img src={real_device_Icon} alt="Real" className={classes.emulatorIcon} />;
            default:
                return null;
        }
    }

    render() {
        const { classes, history, paramProjectId, paramTestCaseId, executionDetails, total_time, executionDetailsSteps, selectedProject, instrNumArray ,shadowDom } = this.props;
        const { isAnyDownloadFileAvailable, browserFilter, platformFilter } = this;
        let browser = [];
        let platform = [];
        if(executionDetails && checkKeyInObject(executionDetails,'browser') && checkKeyInObject(executionDetails,'platform')){
            browser =  browserDetails.filter(browserFilter);
            platform = platformDetails.filter(platformFilter);
        }

        const executionStartTime = moment(executionDetails.startTime).toISOString();

        
        return (
            <div className={classes.headerMiddleRoot}>
                <div className={classes.headerMiddleLeftContainer}>
                        <div className={classes.caseDetailContainer}>
                            <div className={classes.descContainer} style={{ marginTop: 5}}>
                                <div style={{ maxWidth: '100%', display: 'flex', alignItems: 'center' }}>
                                    <p className={classes.name}>
                                        <Tooltip data={checkKeyInObject(executionDetails, 'name', 'value', 'Execution Name')}>
                                            {checkKeyInObject(executionDetails, 'name', 'value', 'Execution Name')}
                                        </Tooltip>
                                    </p>
                                    <div style={{display:'flex', marginLeft: '20px'}}>
                                    <IconButton disableRipple className={`${classes.iconButton} ${classes.disableHoverEffect}`} style={{cursor: 'auto'}} aria-label="Execute details">
                                        <FontAwesomeIcon className={`${classes.moreIconsBtn} ${classes.faIcons}`} icon={faBolt} aria-label="faBolt" id="faBolt" />
                                    </IconButton>
                                        <Typography className={classes.btnText} style = {{ margin: '7px 12px 6px -2px', fontSize: '12px' }}>
                                            {getFormatedDate(executionStartTime, 'MMM,DD,YYYY',null)}
                                        </Typography>
                                    </div>
                                    <div className={classes.iconWrapper} style={{marginLeft:'1px'}}>
                                    <IconButton disableRipple className={`${classes.iconButton} ${classes.disableHoverEffect}`} style={{cursor: 'auto'}} aria-label=" Total Time Taken ">
                                        <FontAwesomeIcon className={`${classes.moreIconsBtn} ${classes.faIcons}`} icon={faClock} aria-label="faClock" id="faClock" />
                                    </IconButton>
                                        <Typography className={classes.btnText}>{getFormatedDate(executionStartTime, 'HH:mm:ss', null)}</Typography>
                                    </div>
                                    { executionDetails.platform && (
                                      <div className={classes.iconWrapper}>
                                        <IconButton disableRipple className={`${classes.iconButton} ${classes.disableHoverEffect}`} style={{cursor: 'auto'}} aria-label="Platform" >
                                            {this.getPlatformIcon(checkArrayLength(platformDetails.filter(platformFilter)) ? platformDetails.filter(platformFilter)[0] : platform[0])}
                                        </IconButton>
                                        <Typography className={classes.btnText}>{this.getPlatformNameToDisplay(executionDetails.platform)}</Typography>
                                      </div>
                                    )}
                                    { executionDetails.browser && (
                                      <div className={classes.iconWrapper}>                                    
                                        <IconButton disableRipple className={`${classes.iconButton} ${classes.disableHoverEffect}`} style={{cursor: 'auto'}} aria-label="Browser" >
                                            {this.getBrowserIcon(checkArrayLength(browserDetails.filter(browserFilter)) ? browserDetails.filter(browserFilter)[0] : browser[0] )}
                                        </IconButton>
                                        <Typography className={classes.btnText}>{`${this.getBrowserNameToDisplay(executionDetails.browser)} ${executionDetails.browserVersion}`}</Typography>
                                      </div>
                                    )}    
                                    { (executionDetails.deviceType || (executionDetails.deviceName && executionDetails.deviceName!=='<nil>')) && (
                                      <div className={classes.iconWrapper}>                                    
                                         {
                                            executionDetails.deviceType &&
                                            <IconButton disableRipple className={`${classes.iconButton} ${classes.disableHoverEffect}`} style={{cursor: 'auto'}} aria-label="DeviceType" >
                                                {this.getDeviceTypeIcon(executionDetails.deviceType)}
                                            </IconButton>
                                        } 
                                        {
                                            (executionDetails.deviceName && executionDetails.deviceName!=='<nil>') &&
                                            <Typography className={classes.btnText}>{`${executionDetails.deviceName}`}</Typography>
                                        }
                                      </div>
                                    )}
                                    { executionDetails.environmentType && (
                                      <div className={classes.iconWrapper}>                                    
                                        <IconButton disableRipple className={`${classes.iconButton} ${classes.disableHoverEffect}`} style={{cursor: 'auto'}} aria-label="environmentType" >
                                            <FontAwesomeIcon className={`${classes.moreIconsBtn} ${classes.faIcons}`} icon={faCog} aria-label="environmentTypeIcon" id="environmentTypeIcon" />
                                        </IconButton>
                                        <Typography className={classes.btnText}>{executionDetails.environmentType}</Typography>
                                      </div>
                                    )} 
                                    { shadowDom && (
                                      <div className={classes.iconWrapper}>                                    
                                        <Typography className={classes.btnText}>Shadow DOM enabled</Typography>
                                      </div>
                                    )}   
                                </div>
                            </div>
                        </div>
                    <div className={classes.iconsContainer}>
                        <Button onClick ={() => {
                                    history.push(`/projects/${paramProjectId}/${paramTestCaseId}`);
                                }} className={classes.editstepBtn} variant="outlined">Edit Test Case</Button>
                        
                        <Tooltip data="Get Downloaded Files">
                            <IconButton
                                className={classes.iconButton} 

                                onClick = {() => { TestCaseUtils.downloadFileZip('Execution',  getParamValues(4), true) ; }}
                                disabled={ ! isAnyDownloadFileAvailable}
                                >
                                <Download
                                    className={`${classes.moreIcons} ${classes.displayButtonIcons18}`}
                                    style={{ color: isAnyDownloadFileAvailable ? '#8898A8' : '#C4C4C4' }}
                                    aria-label="downloadIconExecution"
                                    id="downloadIconExecution"
                                />
                            </IconButton>
                        </Tooltip>
                        <MenuOptions
                            items={this.renderDisplayItems()}
                            keepMounted
                            containerStyle={{ width: '100%' , marginLeft: '20px'}}
                           
                        />
                    </div>
                </div>
                <div className={classes.midHeader}>
                <div className={classes.actionItems}>
                    <div className={classes.ActionHeader}>
                        <Typography className={classes.headerTitle}>
                            ACTION
                        </Typography>
                    </div>
                    <div className={classes.ActionHeader}>
                        <Typography className={classes.headerTitle}>
                            DATA
                        </Typography>
                    </div>
                </div>
                    <div className={classes.displayTimer}>
                        <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                            <Typography className={classes.stepTimerText}> Total steps </Typography>
                            <span className={classes.avatar}>{checkArrayLength(instrNumArray) ? instrNumArray.length : 0}</span>
                        </div>
                        <div style={{ whiteSpace: 'nowrap' }}>
                        {selectedProject.showTimer ? (
                            <>
                                <span className={classes.stepTimerText}>Time elapsed</span>
                                <TestCaseTimer calledFrom="Execution" executionDetailsSteps={executionDetailsSteps} total_time={total_time} />
                            </>
                        ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        //  execution reducer
        executionDetailsSteps: checkObject(state.executionReducer.testSteps) ? Object.values(state.executionReducer.testSteps) : [],
        listView: state.executionReducer.listView,
        testSteps: state.executionReducer.testSteps,
        instrNumArray: state.executionReducer.instrNumArray,
        executionDetails: state.executionReducer.executionDetails,
        // selected Test Case Reducer
        autoScroll: state.selectedTestCaseReducer.autoScroll,
        // projectReducer
        selectedProject: state.projectReducer.selectedProject,
        executions: state.projectReducer.selectedProject,
        shadowDom: state.projectReducer.selectedProject.shadowDomEnabled ? state.projectReducer.selectedProject.shadowDomEnabled: state.selectedTestCaseReducer.shadowDom
        
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // execution action
        updateExeuctionViewType: (...args) => dispatch(ExecutionActions.updateExeuctionViewType(...args)),
        // selected Test Case Action
        toggleAutoScroll: () => dispatch(SelectedTestCaseActions.toggleAutoScroll()),
        toggleShadowDom: (val) => dispatch(SelectedTestCaseActions.toggleShadowDom(val)),
        getProjectDetails: (projectId) => dispatch(ProjectActions.getProjectDetails(projectId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ExecutionHeaderMiddle));
