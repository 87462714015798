// import material
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// import packages
import React from 'react';

// import icons
import Lock from '@material-ui/icons/Lock';
import { BLACK_FONT } from '../../../common/cssConstants';

const styles = (theme) => ({
    col: {
        width: 'inherit',
    },
    firstRowValue: {
        color: '#a3a3a3 !important',
    },
    formControl: {
        width: '100%',
        flexDirection: 'row',
    },
    horizontalRow: {
        width: 'calc(100% + 78px)',
        marginLeft: '-55px',
        border: 0,
        borderTop: '1px solid #DCDCDC',
    },
    label: {
        alignSelf: 'center',
        width: 90,
        '& > p': {
            color: BLACK_FONT,
        },
    },
    lockContainer: {
        marginLeft: 200,
    },
    lockIcon: {
        fontSize: 22,
        color: '#003F9B',
    },
    marginNormal: {
        marginBottom: 0,
        marginTop: 0,
    },
    placeholder: {
        color: '#a3a3a3',
        fontSize: 'inherit',
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        paddingLeft: 15,
    },
    row: {
        padding: '3px 0px',
        display: 'flex',
        justifyContent: 'start',
    },
    selectBox: {
        width: '100%',
    },
    value: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '77%',
        '& > p': {
            color: BLACK_FONT,
            fontSize: 16,
            fontWeight: '600',
        },
    },
});

function EditGroupModal({ groupName, classes /* , handleChange */, handleSelectChange /* , member */, selectedStatus, statusDropDownData }) {
    return (
        <div className={classes.root}>
            <div className={classes.row}>
                <div className={[classes.col, classes.label].join(' ')}>
                    <Typography>Name</Typography>
                </div>
                <div className={[classes.col, classes.value].join(' ')}>
                    <Typography className={classes.firstRowValue}>{groupName}</Typography>
                    <Lock className={classes.lockIcon} aria-label="lockIcon" id="locKIcon" />
                </div>
            </div>
            <hr className={classes.horizontalRow} />
            {/* <div className={classes.row}>
				<div className={[classes.col, classes.label].join(' ')}>
					<Typography>
						{`Members`}
					</Typography>
				</div>
				<div className={[classes.col, classes.value].join(' ')}>
                    <TextField
                        id="member"
                        aria-label="Number"
                        value={member}
                        placeholder="Member"
                        InputProps={{
                            disableUnderline: true,
                        }}
                        fullWidth
                        margin="normal"
                        onChange={handleChange('member')}
                        classes={{marginNormal: classes.marginNormal}}
                    />
				</div>
			</div>
			<hr className={classes.horizontalRow}/> */}
            <div className={classes.row}>
                <div className={[classes.col, classes.label].join(' ')}>
                    <Typography>Status</Typography>
                </div>
                <div className={[classes.col, classes.value].join(' ')}>
                    <FormControl className={classes.formControl}>
                        <Select
                            className={classes.selectBox}
                            value={selectedStatus}
                            onChange={(e) => handleSelectChange(e)}
                            disableUnderline
                            inputProps={{
                                name: 'selectedStatus',
                                id: 'selected-status',
                            }}
                        >
                            {statusDropDownData &&
                                statusDropDownData.map((status, index) => (
                                    <MenuItem value={status.value} key={index.toString()}>
                                        {status.label}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <hr className={classes.horizontalRow} />
        </div>
    );
}

export default withStyles(styles)(EditGroupModal);
