import { withStyles } from '@material-ui/core/styles';
import Error from '@material-ui/icons/Error';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty';
import Pause from '@material-ui/icons/Pause';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import PropTypes from 'prop-types';
import { faCheck, faPencilAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Tooltip from '../../../components/Tooltip';
import { BLACK_FONT } from '../../../common/cssConstants';
import { getTestCaseStatusWithout_ws } from '../../../utils';

const styles = () => ({
    root: {
        width: '100%',
    },
    statusIcon: {
        position: 'relative',
        marginRight: '2px',
        fontSize: 15,
    },
    success: {
        color: '#50E3C2',
    },
    cellPadding: {
        paddingTop: '24px',
        paddingBottom: '24px',
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
});

const statusColorMap = {
    uploaded: '#50E3C2',
    success: '#00B330',
    editing: '#0092e6',
    'in progress': '#F0B637',
    failed: '#E8001C',
    notStartedYet: '#0092E6',
    warning: '#fbcc17',
    stopped: `${BLACK_FONT}`,
};

function CaseStatus(props) {
    const { testCase, showText, classes, customStyles, is_nowrap } = props;
    const status = getTestCaseStatusWithout_ws(testCase);
    const statusMap = {
        uploaded: <FontAwesomeIcon icon={faCheck} aria-label="faCheck" id="faCheck" className={classes.statusIcon} />,
        success: <FontAwesomeIcon icon={faCheck} aria-label="faCheck" id="faCheck" className={classes.statusIcon} />,
        editing: <FontAwesomeIcon icon={faPencilAlt} aria-label="faPencilAlt" id="faPencilAlt" className={classes.statusIcon} />,
        'in progress': (
            <HourglassEmpty
                className={classes.statusIcon}
                aria-label="hourglassEmptyIcon"
                id="hourglassEmptyIcon"
                // onClick={() => {
                //     this.handleClickOpen(systemId);
                // }}
            />
        ),
        failed: (
            <Error
                className={classes.statusIcon}
                aria-label="errorIcon"
                id="errorIcon"
                // onClick={() => {
                //     this.handleClickOpen(systemId);
                // }}
            />
        ),
        stopped: (
            <Pause
                className={classes.statusIcon}
                aria-label="pauseIcon"
                id="pauseIcon"
                // onClick={() => {
                //     this.handleClickOpen(systemId);
                // }}
            />
        ),
        notStartedYet: (
            <DirectionsRun className={classes.statusIcon} style={{ fontSize: 19 }} aria-label="directionsRunIcon" id="directionsRunIcon" />
        ),
        warning: (
            <FontAwesomeIcon
                icon={faExclamationTriangle}
                aria-label="faExclamationTriangle"
                id="faExclamationTriangle"
                className={classes.statusIcon}
            />
        ),
    };
    return (
        <Tooltip data={status.tooltipMessage ? status.tooltipMessage : null}>
            <span style={{ color: statusColorMap[status.code] }} className={classes.wrapper}>
                {statusMap[status.code]}
                {showText ? (
                    <span className={is_nowrap ? classes.noWrap : ''} style={{ color: customStyles.color, position: 'relative', marginLeft: '5px' }}>
                        <Tooltip data={!status.tooltipMessage && is_nowrap ? status.msg : null}>
                            <span style={{padding:'1vh'}}>{status.msg}</span>
                        </Tooltip>
                    </span>
                ) : null}
            </span>
        </Tooltip>
    );
}

CaseStatus.propTypes = {
    showText: PropTypes.bool,
    // systemId: PropTypes.string.isRequired,
    classes: PropTypes.shape({}).isRequired,
};

CaseStatus.defaultProps = {
    showText: true,
};

export default withStyles(styles)(CaseStatus);
