// import packages
import React from 'react';
// import materials
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = () => ({
    root: {
        padding: ' 0px 30px 30px 30px',
        borderBottom: ' 1px solid #e1e1e1',
    },
    emailCont: {
        fontSize: 16,
    },
    textField: {
        width: '60%',
    },
});

function RenameUserModal({ classes, handleChange, email, firstName, lastName, endPointEmail }) {
    return (
        <div className={classes.root}>
            <TextField id="firstName" aria-label="First Name*" fullWidth value={firstName} onChange={handleChange('firstName')} margin="normal" />
            <TextField
                id="lastName"
                aria-label="Last Name*"
                fullWidth
                disabled={!lastName}
                value={lastName}
                onChange={handleChange('lastName')}
                margin="normal"
            />
            <div>
                {' '}
                <TextField
                    id="primaryName"
                    aria-label="Primary Email"
                    className={classes.textField}
                    value={email}
                    onChange={handleChange('email')}
                    margin="normal"
                />
                <span className={classes.emailCont}>@{endPointEmail}</span>
            </div>
        </div>
    );
}

export default withStyles(styles)(RenameUserModal);
