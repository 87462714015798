import { ActionTypes } from '../constants';

class GeneralActions {
    static queryRemove() {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.UPDATE_QUERY,
                payload: { queryValue: '', forceClearQuery: true },
            });
    }

    static querySave(queryValue = '') {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.UPDATE_QUERY,
                payload: { queryValue, forceClearQuery: false },
            });
    }

    static toggleSwipableDrawer(open) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.TOGGLE_SWIPABLE_DRAWER,
                payload: { open },
            });
    }

    static toggleSwipableTaskPanel(open) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.TOGGLE_SWIPABLE_TASK_PANEL,
                payload: { open },
            });
    }

    static toggleDisableQuery(flag) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.TOGGLE_DISABLE_QUERY,
                payload: { disabledQuery: flag },
            });
    }

    static updateTitle(title) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.UPDATE_TITLE,
                payload: { title },
            });
    }
}

export default GeneralActions;
