import { ActionTypes } from '../constants';

const initialState = {
    isLoading: false,
    error: null,
    tags: [],
    success: null,
};

const tagReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.UN_TAGGED_TESTCASE:
        case ActionTypes.TAGGED_TESTCASE:
        case ActionTypes.UPDATE_TAGS:
        case ActionTypes.GET_TAGS:
        case ActionTypes.CREATE_TAG:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CREATE_TAG_SUCCESS:
            return {
                ...state,
                tags: [...state.tags, payload],
                error: null,
                success: 'Tag created successfully',
            };

        case ActionTypes.CREATE_TAG_FAILURE:
            return {
                ...state,
                tags: [],
                error: 'Failed to create tag',
                success: null,
            };

        case ActionTypes.GET_TAGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tags: payload,
            };

        case ActionTypes.GET_TAGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                tags: [],
            };

        case ActionTypes.UPDATE_TAGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tags: payload,
                error: null,
                success: 'Tag updated successfully',
            };

        case ActionTypes.UPDATE_TAGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                tags: [],
                error: 'Failed to update tag',
                success: null,
            };

        case ActionTypes.TAGGED_TESTCASE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
            };

        case ActionTypes.TAGGED_TESTCASE_FAILURE:
            return {
                ...state,
                isLoading: false,
                success: null,
            };

        case ActionTypes.UN_TAGGED_TESTCASE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: 'Tag removed successfully.',
                error: null,
            };

        case ActionTypes.UN_TAGGED_TESTCASE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
                success: null,
            };

        //= ===================================;
        case ActionTypes.CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };
        case ActionTypes.CLEAR_SUCCESS:
            return {
                ...state,
                success: null,
            };
        //= ===================================;

        default:
            return state;
    }
};

export default tagReducer;
