/* eslint-disable no-throw-literal */
import HttpBase from '../../services/HttpBase';

class ScheduleMiddleware extends HttpBase {
    async getSchedules() {
        const result = await this.httpClientSchedule.get('/v1/schedules/');
        if (result.status === 200 && result.data) {
            return {
                schedules: result.data,
                status: result.status,
            };
        }
        throw 'Could not get schedules';
    }

    async deleteSchedule(id) {
        const payload = {
            schedule_ids: [id],
        };
        const result = await this.httpClientSchedule.post('/v1/schedules/delete_schedules', payload);

        if (result.status === 200) {
            return { status: result.status };
        }
        throw 'Could not delete schedules';
    }

    async addJob(data) {
        const result = await this.httpClientSchedule.post('v1/schedules/', data);

        if (result.status === 200) {
            return { data: result.data };
        }
        return null;
    }

    async updateJob(id, data) {
        const result = await this.httpClientSchedule.post(`/v1/schedules/${id}/update_schedule`, data);

        if (result.status === 200) {
            return {
                updatedJob: result.data,
                id,
            };
        }
        throw 'Could not update schedule';
    }

    async excuteScedule(jobId, is_paused) {
        const result = await this.httpClientSchedule.get(`/v1/schedules/${jobId}/${is_paused}/pause_and_resume`);

        if (result.status === 200) {
            return {
                Success: true,
            };
        }
        throw 'Could not execute';
    }

    async getTestSuitesForSchedules() {
        try {
            const result = await this.httpClient.get('/v1/test_suite/get_project_and_suites');
            if (result.status === 200) {
                return result.data;
            }
            return [];
        } catch (error) {
            if (error.response) {
                throw error.response.statusText;
            }
        }
        return [];
    }

    async getTestCasesByJobId(jobId) {

            return new Promise(async(resolve,reject)=>{

                try {
                    const result = await this.httpClient.get(`/v1/jobs/${jobId}/get_executions`);
                    if (result && result.status === 200 && result.data) {
                        resolve({testCases:result.data})
                    }
    
                } catch(err) {
                    reject(err);
                } 



            })       
        
    }
}

export default new ScheduleMiddleware();
