import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import componentStyles from './styles';

const styles = (theme) => (componentStyles(theme));

const Message = ({classes,message=''}) => {

    return (
        <>
            {
                message && !Array.isArray(message) &&  typeof message === 'object' && (message.title || message.description) ?
                    <div className={classes.messageContainer}>
                    {
                        message.title &&
                        <div className={classes.messageContainerTitle}>
                            {message.title}
                        </div>
                    }
                    {

                        message.description &&
                        <div className={classes.messageContainerDescription}>
                            {message.description}
                        </div>
                    }
                </div> : <div className={classes.messageContainer}>{message}</div>
            }
        </>
    )
}

export default  withStyles(styles)(Message);