import moment from 'moment';
import { ActionTypes } from '../constants';
import { checkObject, checkArrayLength, checkKeyInObject, isJson } from '../../utils/utils';

const initialState = {
    addProject: [],
    alertTestCasesWithAccount: [],
    callRetryFunc: false,
    changedApis: [],
    deletedNotify: {},
    deleteUserVariableSuccess: null,
    disableReload: false,
    error: null,
    executions: [],
    executionsStatus: -1, // status > ('Executed': 1, 'InProcess': 0, 'Error': 2, 'All': -1)
    executionsLoading: false,
    executionsPolling: false,
    executionTasksStatus: '0',
    executionTaskStatusPageNo: 1,
    executionTest: [],
    failedStepsData: {}, // object to store testcaseId as key and {} as value
    debugStepsData: {}, // object to store testcaseId as key and {} as value
    firstEditedStep: null, //  // use to recover screenshot and step status after msgTyp 14 sent
    forceOpenCloseRightPanel: null,
    generateTestScript: [],
    invalidUrl: null,
    isError: false,
    isExecutionTaskStatusLastPage: false,
    isLoading: false,
    isLoadingCaseNameUpdate: false,
    isLoadingCases: false,
    isLoadingTestData: false,
    isRefreshDuringWsRunning: true, // True if refresh during ws running, false on msgType 3, on msgType 8 true again
    isRightPanelOpened: null,
    isSideBarOpened: false,
    isTestCaseNameUpdate: false,
    isWsRunning: {}, // struct: {testCaseId: true | false} true if receive 3, 4, 5 and false on 8, 13, 16 and clearWsData
    markReadNotify: {},
    markReadNotifyDeleted: {},
    markDeletedNotify: {},
    msgType8Received: {},
    nonDeletedNotify: {},
    openProjectId: null,
    // previousLoadedProjectId: 0,
    projects: [],
    projectsByIdAndName: [],
    projectChange: false,
    queryValue: null,
    recoderCall: [],
    selectedExecuteTask: -1,
    selectedProject: {},
    selectedTestCase: {},
    shiftToLive: false,
    singleTestSuite: {},
    snackbarLink: '',
    stepsModalDataLoading: false,
    stepsOnMsgType15: [],
    stepsOnMsgType18: [],
    success: null,
    tempCheckedCaseIds: {},
    testCases: [],
    testData: [],
    testDataColumns: {},
    testStepObj: {},
    testSuites: [],
    testSuiteWithAccount: [],
    upadteProjectSuccess: false,
    userVariables: [],
    wsRunningTestSteps: {}, // To save ws running test steps, so we don't have to call get steps API
    wsRunningCaseData: {}, // To save ws running test case data
    wsKeys: {}, // To save last run testCases' data b/w msgType 15 and 2
    projectDetailsState: {},
    suiteState: {},
    executionReports: [],
    executionRunningData: {}, // structure for (executionRunningData) -> {executionId: {steps: [Steps Array], data: {any other data}}}
    isFavourite: false,
    isLoadingGAP: false,
    isLoadingGAPBN: false,
    favouriteProjects: [],
    isFavouriteProjectsLoading: false,
    getProjectAndTestSuiteData: [],
    isprojectAndTestSuiteLoading: false,
    suiteConditions: [],
    delayUnit: [],
    showRecorderInstalltion: false,
    environmentError: null,
    environmentInfo: {},
    sauceID: [],
    isGenerateBtnClicked: {},
    showLoaderForGenerate: {},
};

export default function projectReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.UPDATE_PROJECT: {
            return { ...state, upadteProjectSuccess: false };
        }

        case ActionTypes.UPDATE_PROJECT_SUCCESS: {
            const _projects = JSON.parse(JSON.stringify(state.projects));
            let _selectedProject = JSON.parse(JSON.stringify(state.selectedProject));
            if (action.payload.data && action.payload.data.id) {
                const project = action.payload.data;
                const index = _projects.findIndex((x) => `${x.projectId}` === `${project.id}`);
                project.projectName = project.name;
                _projects[index] = { ..._projects[index], ...project };
                if (checkObject(_selectedProject)) {
                    _selectedProject = { ..._selectedProject, ...project };
                }
            }
            return {
                ...state,
                upadteProjectSuccess: true,
                projects: _projects,
                selectedProject: _selectedProject,
                success: action.payload.msg,
            };
        }

        case ActionTypes.CREATE_PROJECT_SUCCESS: {
            const _projects = JSON.parse(JSON.stringify(state.projects));
            const project = action.payload.data;
            if (project && project.id) {
                const projectToAdd = {
                    ...project,
                    projectId: project.id,
                    projectName: project.name,
                };
                _projects.push(projectToAdd);
            }
            return {
                ...state,
                projects: _projects,
                success: action.payload.msg,
            };
        }

        case ActionTypes.UPDATE_PROJECT_ID:
            return {
                ...state,
                openProjectId: action.payload,
            };

        case ActionTypes.PROJECT_IS_CHANGED:
            return {
                ...state,
                projectChange: action.payload,
            };

        // msgType = 3
        case ActionTypes.UPDATE_TEST_CASE_STEPS: {
            const { testCaseId, caseData, sessionId } = action.payload;
            let wsRunningCaseData = JSON.parse(JSON.stringify(state.wsRunningCaseData));
            const _testCases = state.testCases.map(tC => Object.assign({}, tC));
            let { selectedTestCase } = state;
            const _failedStepsData = state.failedStepsData;
            const _debugStepsData = state.debugStepsData;
            const obj = {
                ...state,
                isRefreshDuringWsRunning: false, // Need to verify(Tahir)
                firstEditedStep: null,
            };

            const isWsRunning = { ...state.isWsRunning, [testCaseId]: true };
            const wsRunningTestSteps = { ...state.wsRunningTestSteps, [testCaseId]: caseData };
            const isGenerateBtnClicked = { ...state.isGenerateBtnClicked, [testCaseId]: false };
            const showLoaderForGenerate = { ...state.showLoaderForGenerate, [testCaseId]: false };
            const isStart = state.wsRunningCaseData[testCaseId] ? state.wsRunningCaseData[testCaseId].isStart : false;
            if (isStart === false) {
                // Don't remove this commented Line (Haseeb)
                // if want to stop time on msgType 13 unComment
                // const newStartTime = new Date(new Date().setMilliseconds(new Date(startTime) - new Date(stopAt)));
                // wsRunningCaseData = { ...state.wsRunningCaseData, [testCaseId]: { ...wsRunningCaseData[testCaseId], stopAt: null, isStart: true, startTime: newStartTime } };
            } else {
                if (state.wsRunningCaseData && state.wsRunningCaseData.recorderTestCase) {
                    // This is for recorder
                    // To save sessionsId
                    const { recorderTestCase } = state.wsRunningCaseData;
                    if (recorderTestCase.sessionIds.includes(sessionId)) {
                        let cond = false;
                        // check if obj against key exists or not to avoid duplication in key
                        if (_failedStepsData && !_failedStepsData[testCaseId]) {
                            _failedStepsData[testCaseId] = { sessionId };
                            cond = true;
                        }
                        if (_debugStepsData && !_debugStepsData[testCaseId]) {
                            _debugStepsData[testCaseId] = { sessionId };
                            cond = true;
                        }
                        if (cond) {
                            // set key isMsgType2Sent true, means testCase is recorded by current user
                            wsRunningCaseData[testCaseId] = { ...state.wsRunningCaseData[testCaseId], isMsgType2Sent: true };
                            delete wsRunningCaseData.recorderTestCase;
                        }
                    }
                }
                wsRunningCaseData = {
                    ...state.wsRunningCaseData,
                    [testCaseId]: { ...wsRunningCaseData[testCaseId], startTime: new Date(), isStart: true },
                };
            }

            // if wsRunningCaseData[testCaseId] is not present means someone else ran this testCase.
            // So set isMsgType2Sent false
            if (!checkKeyInObject(wsRunningCaseData, testCaseId)) {
                wsRunningCaseData[testCaseId] = {
                    sessionId,
                    isMsgType2Sent: false,
                    isMsgType13Received: false,
                    isMsgType16Received: false,
                    isRunning: true,
                };
            } else {
                // exists update isRunning as true
                wsRunningCaseData[testCaseId] = {
                    ...wsRunningCaseData[testCaseId],
                    isMsgType13Received: false,
                    isMsgType16Received: false,
                    isRunning: true,
                };
            }

            obj.isWsRunning = isWsRunning;
            obj._failedStepsData = _failedStepsData;
            obj._debugStepsData = _debugStepsData;
            obj.wsRunningTestSteps = wsRunningTestSteps;
            obj.wsRunningCaseData = wsRunningCaseData;
            obj.isGenerateBtnClicked = isGenerateBtnClicked;
            obj.showLoaderForGenerate = showLoaderForGenerate;

            if (checkObject(selectedTestCase) && `${selectedTestCase.testCaseId}` === testCaseId) {
                let _udpatedSteps = caseData;
                if (
                    checkKeyInObject(wsRunningCaseData, testCaseId) &&
                    checkKeyInObject(wsRunningCaseData[testCaseId], 'firstEditedStep') &&
                    checkArrayLength(checkKeyInObject(state.selectedTestCase, 'testSteps')) &&
                    checkArrayLength(caseData)
                ) {
                    _udpatedSteps = [
                        ...state.selectedTestCase.testSteps.slice(0, wsRunningCaseData[testCaseId].firstEditedStep),
                        ...caseData.slice(wsRunningCaseData[testCaseId].firstEditedStep),
                    ];
                }
                selectedTestCase = {
                    ...selectedTestCase,
                    isGenerating: true, // Set it true to get status as inprogress when msgType 8 received but waiting for singleTestCase RabbitMQ Build UI issue (Sonata) #1632
                    status: 4, // Set status as 4 (Inprogress) due to same issue as above
                    testSteps: _udpatedSteps,
                };
                obj.selectedTestCase = selectedTestCase;
            }
            const testCases = [];
            _testCases.forEach((testCase) => {
                if (testCase) {
                    if (`${testCase.testCaseId}` === testCaseId) {
                        let _udpatedSteps = caseData;
                        if (
                            checkKeyInObject(wsRunningCaseData, testCaseId) &&
                            checkKeyInObject(wsRunningCaseData[testCaseId], 'firstEditedStep') &&
                            checkArrayLength(checkKeyInObject(state.selectedTestCase, 'testSteps')) &&
                            checkArrayLength(caseData)
                        ) {
                            _udpatedSteps = [
                                ...state.selectedTestCase.testSteps.slice(0, wsRunningCaseData[testCaseId].firstEditedStep),
                                ...caseData.slice(wsRunningCaseData[testCaseId].firstEditedStep),
                            ];
                        }
                        testCases.push({
                            ...testCase,
                            isGenerating: true, // Set it true to get status as inprogress when msgType 8 received but waiting for singleTestCase RabbitMQ Build UI issue (Sonata) #1632
                            status: 4, // Set status as 4 (Inprogress) due to same issue as above
                            testSteps: _udpatedSteps,
                        });
                    } else {
                        testCases.push(testCase);
                    }
                }
            });
            if (checkArrayLength(testCases)) obj.testCases = testCases;
            return obj;
        }
        // msgType = 4,5
        case ActionTypes.UPDATE_TEST_CASE_STEPS_STATUS: {
            const { testCaseId, caseData, sessionId } = action.payload;
            const { instrNum } = caseData;
            const _testCases = state.testCases.map(tC => Object.assign({}, tC));
            const _wsRunningCaseData = state.wsRunningCaseData;
            let { wsRunningTestSteps } = state;
            let { selectedTestCase } = state;
            const _debugStepsData = state.debugStepsData;
            let _testSteps = [];
            const isGenerateBtnClicked = {...state.isGenerateBtnClicked, [testCaseId]: false};
            const showLoaderForGenerate = {...state.showLoaderForGenerate, [testCaseId]: false};
            

            const obj = {
                ...state,
            };
            if (!checkKeyInObject(_wsRunningCaseData, testCaseId)) {
                _wsRunningCaseData[testCaseId] = {
                    sessionId,
                    isMsgType2Sent: false,
                    isMsgType13Received: false,
                    isMsgType16Received: false,
                    isRunning: true,
                };
            } else {
                _wsRunningCaseData[testCaseId] = {
                    ..._wsRunningCaseData[testCaseId],
                    isMsgType13Received: false,
                    isMsgType16Received: false,
                    isRunning: true,
                };
                delete _wsRunningCaseData[testCaseId].failedStepForOtherUser;
            }

            if (checkObject(wsRunningTestSteps) && wsRunningTestSteps[testCaseId]) {
                // wsRunningTestSteps is used to store all websockets running steps with testCase Ids
                // So if user closes live test step modal we can show their previous steps too on next until
                // web socket running.
                const index = wsRunningTestSteps[testCaseId].map((step) => step.instrNum).indexOf(instrNum);
                if (index > -1) {
                    wsRunningTestSteps[testCaseId][index] = {
                        ...wsRunningTestSteps[testCaseId][index],
                        ...caseData,
                    };
                    obj.wsRunningTestSteps = wsRunningTestSteps;
                }
                _testSteps = wsRunningTestSteps[testCaseId];
                if (checkObject(selectedTestCase) && `${selectedTestCase.testCaseId}` === `${testCaseId}`) {
                    selectedTestCase = {
                        ...selectedTestCase,
                        testSteps: _testSteps,
                    };
                    obj.selectedTestCase = selectedTestCase;
                }
            } else if (
                checkObject(selectedTestCase) &&
                `${selectedTestCase.testCaseId}` === `${testCaseId}` &&
                checkArrayLength(selectedTestCase.testSteps)
            ) {
                // if flow comes here it mean did not receive msgType 3,
                // e.g, user opened this test case from middle
                _testSteps = JSON.parse(JSON.stringify(selectedTestCase.testSteps));
                const index = _testSteps.map((step) => step.instrNum).indexOf(instrNum);
                if (index > -1) {
                    _testSteps[index] = {
                        ..._testSteps[index],
                        ...caseData,
                    };
                }

                wsRunningTestSteps = { ...state.wsRunningTestSteps, [testCaseId]: _testSteps };
                selectedTestCase = { ...selectedTestCase, status: 4, testSteps: _testSteps };

                obj.wsRunningTestSteps = wsRunningTestSteps;
                obj.selectedTestCase = selectedTestCase;
            }
            const isWsRunning = { ...state.isWsRunning, [testCaseId]: true };
            const testCases = [];
            _testCases.forEach((testcase) => {
                if (testcase) {
                    if (`${testcase.testCaseId}` === testCaseId) {
                        if (
                            !_wsRunningCaseData[testCaseId].stepsCount &&
                            (checkArrayLength(_testSteps) || (checkKeyInObject(testcase, 'testSteps') && checkArrayLength(testcase.testSteps)))
                        ) {
                            // Check if stepsCount is not available and _testSteps or testcase.testSteps are available then Update stepsCount with one of these steps length
                            if (checkArrayLength(_testSteps)) {
                                _wsRunningCaseData[testCaseId] = { ..._wsRunningCaseData[testCaseId], stepsCount: _testSteps.length };
                            } else {
                                _wsRunningCaseData[testCaseId] = { ..._wsRunningCaseData[testCaseId], stepsCount: testcase.testSteps.length };
                            }
                        }
                        testCases.push({
                            ...testcase,
                            status: 4, // Set status as 4 (Inprogress) due to get status as inprogress when msgType 8 received but waiting for singleTestCase RabbitMQ Build UI issue (Sonata) #1632
                            testSteps: _testSteps,
                        });
                    } else {
                        testCases.push(testcase);
                    }
                }
            });
            if (
                checkKeyInObject(_debugStepsData, testCaseId, 'bool') &&
                checkKeyInObject(_debugStepsData[testCaseId], 'instrNum', 'bool') &&
                `${_debugStepsData[testCaseId].sessionId}` === `${sessionId}`
            ) {
                _debugStepsData[testCaseId] = { sessionId };
                obj.debugStepsData = _debugStepsData;
            }
            obj.isWsRunning = isWsRunning;
            obj.wsRunningCaseData = _wsRunningCaseData;
            obj.testCases = testCases;
            obj.isGenerateBtnClicked = isGenerateBtnClicked;
            obj.showLoaderForGenerate = showLoaderForGenerate;
            return obj;
        }
        // msgType = 8
        case ActionTypes.DELETE_WS_STATUS: {
            const {
                payload: { steps, testCaseId, isGenerating },
            } = action;
            const isWsRunning = JSON.parse(JSON.stringify(state.isWsRunning));
            const wsRunningTestSteps = JSON.parse(JSON.stringify(state.wsRunningTestSteps));
            const wsRunningCaseData = JSON.parse(JSON.stringify(state.wsRunningCaseData));
            let selectedTestCase = JSON.parse(JSON.stringify(state.selectedTestCase));
            const failedStepsData = JSON.parse(JSON.stringify(state.failedStepsData));
            const debugStepsData = JSON.parse(JSON.stringify(state.debugStepsData));
            const isGenerateBtnClicked = JSON.parse(JSON.stringify(state.isGenerateBtnClicked));
            const showLoaderForGenerate = JSON.parse(JSON.stringify(state.showLoaderForGenerate));

            let msgType8Received = JSON.parse(JSON.stringify(state.msgType8Received));
            const testCases = state.testCases.map(tC => Object.assign({}, tC));

            const isTimeAlailable = checkKeyInObject(wsRunningCaseData, testCaseId) && checkKeyInObject(wsRunningCaseData[testCaseId], 'startTime');
            const sec = isTimeAlailable ? moment(new Date()).diff(moment(wsRunningCaseData[testCaseId].startTime)) / 1000 - 1 : 0;
            delete isWsRunning[testCaseId];
            delete wsRunningTestSteps[testCaseId];
            delete wsRunningCaseData[testCaseId];
            delete failedStepsData[testCaseId];
            delete debugStepsData[testCaseId];
            delete isGenerateBtnClicked[testCaseId];
            delete showLoaderForGenerate[testCaseId];

            const obj = {
                ...state,
                disableReload: false,
            };
            obj.isWsRunning = isWsRunning;
            obj.wsRunningTestSteps = wsRunningTestSteps;
            obj.wsRunningCaseData = wsRunningCaseData;
            obj.failedStepsData = failedStepsData;
            obj.debugStepsData = debugStepsData;
            obj.isGenerateBtnClicked = isGenerateBtnClicked;
            obj.showLoaderForGenerate = showLoaderForGenerate;

            if (checkKeyInObject(selectedTestCase, 'testCaseId') && `${selectedTestCase.testCaseId}` === `${testCaseId}` && checkArrayLength(steps)) {
                let selectedTestCaseSteps = steps;
                if (checkArrayLength(state.stepsOnMsgType15) || checkArrayLength(state.stepsOnMsgType18)) {
                    if (checkArrayLength(state.stepsOnMsgType15)) {
                        selectedTestCaseSteps = JSON.parse(JSON.stringify(state.stepsOnMsgType15));
                    } else {
                        selectedTestCaseSteps = JSON.parse(JSON.stringify(state.stepsOnMsgType18));
                    }
                    steps.forEach((step) => {
                        const ind = selectedTestCaseSteps.map((s) => s.instrNum).indexOf(step.instrNum);
                        if (ind > -1) {
                            selectedTestCaseSteps[ind] = {
                                ...step,
                                instr: selectedTestCaseSteps[ind].instr,
                                data: selectedTestCaseSteps[ind].data,
                                expectedResults: selectedTestCaseSteps[ind].expectedResults,
                            };
                        }
                    });
                }
                selectedTestCase = {
                    ...selectedTestCase,
                    testSteps: selectedTestCaseSteps,
                };
                if (isTimeAlailable) {
                    selectedTestCase = {
                        ...selectedTestCase,
                        totalTime: sec,
                    };
                }
                obj.selectedTestCase = selectedTestCase;
            }

            if (!isWsRunning || (isWsRunning && Object.keys(isWsRunning).length < 1)) obj.isRefreshDuringWsRunning = true;

            if (!checkKeyInObject(msgType8Received, testCaseId)) {
                msgType8Received = { ...msgType8Received, [testCaseId]: true };
            }

            testCases.some((testcase, index) => {
                // isGenerating can be true or false but can not be null
                if (checkObject(testcase) && `${testcase.testCaseId}` === `${testCaseId}` && isGenerating !== null) {
                    testCases[index] = { ...testcase, isGenerating };
                    return true;
                }
                return false;
            });

            obj.msgType8Received = msgType8Received;
            obj.testCases = testCases;
            return obj;
        }
        case ActionTypes.UPADTE_WS_STATUS: {
            const { payload: testCaseId } = action;
            const isWsRunning = { ...state.isWsRunning };
            isWsRunning[testCaseId] = true;
            return {
                ...state,
                isWsRunning: { ...isWsRunning },
            };
        }
        case ActionTypes.UPADTE_RELOAD_STATUS:
            return {
                ...state,
                disableReload: action.payload,
            };

        case ActionTypes.GET_PROJECT_BY_NAME_SUCCESS: {
            const _projects = checkArrayLength(state.projects) ? JSON.parse(JSON.stringify(state.projects)) : [];
            const _favouriteProjects = checkArrayLength(state.favouriteProjects) ? JSON.parse(JSON.stringify(state.favouriteProjects)) : [];
            if (action.payload.data) {
                if (action.payload.data.isFavourite) {
                    if (_favouriteProjects.findIndex((fp) => fp.projectId === action.payload.data.projectId) === -1) {
                        _favouriteProjects.push(action.payload.data);
                    }
                }
                if (_projects.findIndex((p) => p.projectId === action.payload.data.projectId) === -1) {
                    _projects.push(action.payload.data);
                }
            }
            return {
                ...state,
                projects: _projects,
                favouriteProjects: _favouriteProjects,
                error: null,
                success: null,
            };
        }
        case ActionTypes.GET_PROJECT_BY_NAME_FAILURE:
            return {
                ...state,
                error: action.payload.message,
                success: null,
            };
        case ActionTypes.GET_ALL_PROJECTS:
            return {
                ...state,
                isLoading: true,
                isLoadingGAP: true,
                error: null,
                success: null,
            };
        case ActionTypes.GET_ALL_PROJECTS_BY_ID_AND_NAMES:
            return {
                ...state,
                // isLoading: true,
                isLoadingGAPBN: true,
                error: null,
                success: null,
            };
        case ActionTypes.GET_ALL_PROJECTS_SUCCESS:
            return {
                ...state,
                isFavourite: action.payload.isFavourite || false,
                projects: action.payload.data,
                isLoading: false,
                isLoadingGAP: false,
                error: null,
            };
        case ActionTypes.GET_ALL_PROJECTS_BY_ID_AND_NAMES_SUCCESS:
            return {
                ...state,
                projectsByIdAndName: action.payload.data,
                // isLoading: false,
                isLoadingGAPBN: false,
                error: null,
            };
        case ActionTypes.GET_ALL_PROJECTS_BY_ID_AND_NAMES_FAILURE:
            return {
                ...state,
                // isLoading: false,
                isLoadingGAPBN: false,
            };
        case ActionTypes.GET_ALL_PROJECTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoadingGAP: false,
                error: action.payload.message || action.payload.error,
                success: null,
            };
        case ActionTypes.GET_ADD_PROJECTS:
            return {
                ...state,
                addProject: action.payload.data,
            };
        case ActionTypes.GET_PROJECT_DETAILS:
        case ActionTypes.GET_USER_VARIABLES:
        case ActionTypes.CREATE_EXECUTE_TEST_SUITE:
        case ActionTypes.GET_ALERT_TESTCASES_WITH_ACCOUNT:
        case ActionTypes.GET_TESTSUITS_WITH_ACCOUNT:
        case ActionTypes.ASSOCIATE_TESTSUITS:
        case ActionTypes.SAVE_NEW_TEST_CASE:
        case ActionTypes.DELETE_TESTCASE:
        case ActionTypes.ON_DELETE_PROJECTS:
        case ActionTypes.ATTACH_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.ON_DELETE_PROJECTS_FAILURE:
        case ActionTypes.ATTACH_DATA_SUCCESS:
        case ActionTypes.ATTACH_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.GET_ALL_FAVOURITE_PROJECTS:
            return {
                ...state,
                isFavouriteProjectsLoading: true,
                // isFavourite: action.data,
            };
        case ActionTypes.GET_ALL_FAVOURITE_PROJECTS_SUCCESS: {
            return {
                ...state,
                isFavourite: action.payload.isFavourite,
                favouriteProjects: action.payload.projects.data,
                isFavouriteProjectsLoading: false,
            };
        }
        case ActionTypes.GET_ALL_FAVOURITE_PROJECTS_FAILURE: {
            return {
                ...state,
                isFavouriteProjectsLoading: true,
                error: action.payload.message || action.payload.error,
                success: null,
            };
        }
        case ActionTypes.SHOW_ONLY_DISABLES_PROJECTS: {
            return { ...state, isFavourite: false };
        }
        case ActionTypes.SET_ADD_FAVOURITE:
        case ActionTypes.SET_REMOVE_FAVOURITE:
            return {
                ...state,
                isLoading: true,
                isFavouriteProjectsLoading: true,
            };
        case ActionTypes.SET_REMOVE_FAVOURITE_SUCCESS: {
            const { projectId } = action;
            const { isFavourite } = state;
            let updatedProjects = isFavourite ? JSON.parse(JSON.stringify(state.favouriteProjects)) : JSON.parse(JSON.stringify(state.projects));
            updatedProjects = !isFavourite
                ? updatedProjects.map((project) => {
                      if (`${project.projectId}` === `${projectId}`) {
                          project.isFavourite = false;
                      }
                      return project;
                  })
                : updatedProjects.filter((project) => {
                      if (`${project.projectId}` !== `${projectId}`) {
                          return project;
                      }
                      return false;
                  });

            return {
                ...state,
                favouriteProjects: isFavourite ? updatedProjects : state.favouriteProjects,
                projects: isFavourite ? state.projects : updatedProjects,
                isLoading: false,
                isFavouriteProjectsLoading: false,
            };
        }
        case ActionTypes.SET_ADD_FAVOURITE_FAILURE:
        case ActionTypes.SET_REMOVE_FAVOURITE_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFavouriteProjectsLoading: false,
            };
        case ActionTypes.SET_ADD_FAVOURITE_SUCCESS: {
            const { projectId } = action;
            let updatedProjects = JSON.parse(JSON.stringify(state.projects));
            updatedProjects = updatedProjects.map((project) => {
                if (`${project.projectId}` === `${projectId}`) {
                    project.isFavourite = true;
                }
                return project;
            });

            return {
                ...state,
                projects: updatedProjects,
                isLoading: false,
                isFavouriteProjectsLoading: false,
            };
        }
        case ActionTypes.ON_DELETE_PROJECTS_SUCCESS: {
            const { isFavourite } = state;
            let oldProjects = isFavourite ? JSON.parse(JSON.stringify(state.favouriteProjects)) : JSON.parse(JSON.stringify(state.projects));
            oldProjects = oldProjects.filter((element) => {
                return element.projectId !== action.payload.data[0].id;
            });
            return {
                ...state,
                projects: isFavourite ? state.projects : oldProjects,
                favouriteProjects: isFavourite ? oldProjects : state.favouriteProjects,
                success: action.payload.msg,
                isLoading: false,
            };
        }
        case ActionTypes.UPLOAD_PROJECT_FILE_SUCCESS: {
            const oldProjects = JSON.parse(JSON.stringify(state.projects));
            const project = action.payload.data;
            oldProjects.unshift({ projectId: project.id, projectName: project.name, ...project });
            return {
                ...state,
                projects: oldProjects,
                success: action.payload.msg,
                isLoading: false,
            };
        }

        case ActionTypes.REMOVE_ALERTS_SUCCESS:
            return {
                ...state,
                success: action.payload.message,
                isLoading: false,
            };
        case ActionTypes.DELETE_TESTDATA_SUCCESS: {
            let _testData = [...state.testData];
            // Filter and remove testData which has deleted successfully
            _testData = _testData.filter((data) => data.testDataId !== action.payload.testDataId);

            return {
                ...state,
                testData: _testData,
                isLoading: false,
                success: action.payload.message,
            };
        }

        /**
        |--------------------------------------------------
        | TEST CASE IN PROJECT DETAILS || START
        |--------------------------------------------------
        */

        case ActionTypes.DELETE_TESTCASE_SUCCESS: {
            const { testCaseIds, message } = action.payload;
            let testCases = [...state.testCases];
            testCases = testCases.filter((testCase) => {
                return !testCaseIds.includes(testCase.testCaseId);
            });
            return {
                ...state,
                testCases,
                isLoading: false,
                selectedProject: { ...state.selectedProject, caseCount: state.selectedProject.caseCount - 1 },
                success: message,
            };
        }

        case ActionTypes.GET_PROJECT_DETAILS_FAILURE:
        case ActionTypes.GET_TESTSUITS_WITH_ACCOUNT_FAILURE:
        case ActionTypes.GET_TESTCASES_FAILURE:
        case ActionTypes.EXECUTE_SCRIPT_FAILURE:
        case ActionTypes.DELETE_TESTCASE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.message,
            };

        case ActionTypes.UPDATE_TESTCASE_NAME: {
            return {
                ...state,
                isLoadingCaseNameUpdate: true,
                error: null,
                success: null,
            };
        }

        case ActionTypes.UPDATE_TESTCASE_NAME_SUCCESS: {
            const {
                testCaseData: { testCaseId, name: testCaseName, desc },
                testCases,
                from,
                msg,
            } = action.payload;
            let selectedTestCase = JSON.parse(JSON.stringify(state.selectedTestCase));

            const obj = {
                ...state,
                isLoadingCaseNameUpdate: false,
                success: msg,
            };
            if (from === 'stepEditor' && checkObject(selectedTestCase) && `${selectedTestCase.testCaseId}` === `${testCaseId}`) {
                selectedTestCase = { ...selectedTestCase, testCaseName, desc };
                obj.selectedTestCase = selectedTestCase;
            } else if (from === 'projectDetails' && testCases) {
                obj.testCases = testCases;
            }
            return obj;
        }

        case ActionTypes.UPDATE_TESTCASE_NAME_FAILURE:
            return {
                ...state,
                isLoadingCaseNameUpdate: false,
                error: action.payload.message,
                success: null,
            };

        case ActionTypes.DISABLE_TESTCASES_SUCCESS: {
            const { TestCaseIds, message } = action.payload;
            const obj = { ...state, isLoading: false, error: null, success: message };

            obj.testCases = state.testCases.filter((element) => !TestCaseIds.includes(element.testCaseId));
            return obj;
        }

        case ActionTypes.DISABLE_TESTCASES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.message,
                success: null,
            };

        case ActionTypes.CLONE_TESTCASE_SUCCESS: {
            const testData = JSON.parse(JSON.stringify(state.testData));
            const { message, testCase } = action.payload;
            
            return {
                ...state,
                isLoading: false,
                success: message,
                testCases: [...state.testCases, testCase],
                testData,
                selectedProject: { ...state.selectedProject, caseCount: state.selectedProject.caseCount + 1 },
            };
        }
        case ActionTypes.SAVE_NEW_TEST_CASE_SUCCESS: {
            const newState = JSON.parse(JSON.stringify(state));
            // let selectedTestCase = { ...newState.selectedTestCase };
            const { request, response } = action.payload;
            response.testSteps = [...request.test_steps];
            response.originalTestSteps = [...request.test_steps];
            const testCases = [];
            newState.testCases.forEach((_testCase) => {
                if (_testCase) {
                    if (_testCase.testCaseName === request.case_name) {
                        _testCase = response;
                    }
                    testCases.push(_testCase);
                }
            });
            const selectedTestCase = { ...response };
            const newUrl = `/projects/${selectedTestCase.discoveryId}/${selectedTestCase.testCaseId}`;
            window.history.replaceState('', '-1', newUrl);
            return {
                ...newState,
                isLoading: false,
                testCases,
                selectedTestCase,
            };
        }

        /**
        |--------------------------------------------------
        | TEST CASE IN PROJECT DETAILS || END
        |--------------------------------------------------
        */

        case ActionTypes.DELETE_TESTCASE_FROM_SUITE_SUCCESS: {
            let testSuites = JSON.parse(JSON.stringify(state.testSuites));
            const updatedTestSuite = testSuites.find((suite) => `${suite.testSuiteId}` === `${action.payload.testSuiteId}`);
            if (checkObject(updatedTestSuite) && checkArrayLength(updatedTestSuite.testCases)) {
                updatedTestSuite.testCases = updatedTestSuite.testCases.filter(
                    (testCase) => !action.payload.testCaseIds.includes(testCase.testCaseId),
                );
            }
            testSuites = testSuites.map((testSuite) => {
                if (`${testSuite.testSuiteId}` === `${action.payload.testSuiteId}`) {
                    testSuite = { ...updatedTestSuite };
                }
                return testSuite;
            });
            return {
                ...state,
                testSuites,
            };
        }

        case ActionTypes.DELETE_TESTSCRIPT_SUCCESS: {
            const _testCases = state.testCases.map(tC => Object.assign({}, tC));
            const testCases = [];
            _testCases.forEach((testCase) => {
                if (testCase) {
                    
                    testCases.push(testCase);
                }
            });
            return {
                ...state,
                testCases,
                success: action.payload.message,
            };
        }
        case ActionTypes.GET_TESTSTEPS:
            return {
                ...state,
                stepsModalDataLoading: action.payload.showLoader,
            };
        case ActionTypes.UPDATE_RECOVER_STEPS: {
            const { testCaseId, steps } = action.payload;
            const { testCases, selectedTestCase } = { ...state };
            const _steps = { ...steps };
            const _testCases = [];
            testCases.forEach((testCase) => {
                if (testCase) {
                    if (`${testCase.testCaseId}` === `${testCaseId}`) {
                        testCase = { ...testCase, ..._steps };
                    }
                    _testCases.push(testCase);
                }
            });
            let _selectedTestCase = { ...selectedTestCase };
            if (checkObject(selectedTestCase) && `${selectedTestCase.testCaseId}` === `${testCaseId}`) {
                _selectedTestCase = {
                    ...selectedTestCase,
                    ..._steps,
                };
            }
            return {
                ...state,
                testCases: _testCases,
                selectedTestCase: _selectedTestCase,
            };
        }
        case ActionTypes.GET_TESTSTEPS_SUCCESS: {
            const { testCaseId, testSteps, steps } = action.payload;
            const wsRunningTestSteps = JSON.parse(JSON.stringify(state.wsRunningTestSteps));
            const instrNum = action.payload.instrNum ? action.payload.instrNum : null;
            let parentInstrNum = -1; // Parent instruction number, if failedStep is child
            let faieldStepStatus = 0; // status of failedStep
            let index = -1; // index number of parent step of failed step in selectedTestCase/testCase object

            const { selectedTestCase, testCases } = { ...state };
            let _steps = { ...steps };

            const _testCases = [];
            testCases.forEach((testCase) => {
                if (testCase) {
                    if (`${testCase.testCaseId}` === `${testCaseId}`) {
                        if (
                            `${selectedTestCase.testCaseId}` === `${testCaseId}` &&
                            checkArrayLength(wsRunningTestSteps[testCaseId]) &&
                            checkArrayLength(steps.testSteps)
                        ) {
                            _steps = { ...steps, testSteps: wsRunningTestSteps[testCaseId] };
                        }
                        testCase = { ...testCase, ..._steps };
                        if (instrNum) {
                            parentInstrNum = instrNum.split('.').shift();
                            if (testCase.testSteps) {
                                testCase.testSteps.forEach((step, ind) => {
                                    if (`${step.instrNum}` === `${parentInstrNum}`) {
                                        index = ind;
                                    }
                                    if (`${step.instrNum}` === `${instrNum}`) {
                                        faieldStepStatus = step.status;
                                    }
                                });
                            }

                            if (index > -1) {
                                // if index found, change parentStep's status with failedStep's status
                                testCase.testSteps[index] = {
                                    ...testCase.testSteps[index],
                                    status: faieldStepStatus,
                                };
                            }
                        }
                    }
                    _testCases.push(testCase);
                }
            });

            const obj = {
                ...state,
                testCases: _testCases,
                testSteps,
                stepsModalDataLoading: false,
            };
            if (checkObject(selectedTestCase) && `${selectedTestCase.testCaseId}` === `${testCaseId}`) {
                // to update when WS running and user comes from other test case
                if (checkArrayLength(wsRunningTestSteps[testCaseId]) && checkArrayLength(steps.testSteps)) {
                    _steps = { ...steps, testSteps: wsRunningTestSteps[testCaseId] };
                }
                const updatedSelectedSteps = {
                    ...selectedTestCase,
                    ..._steps,
                };
                if (index > -1) {
                    updatedSelectedSteps[index] = {
                        ...updatedSelectedSteps[index],
                        status: faieldStepStatus,
                    };
                }
                obj.selectedTestCase = updatedSelectedSteps;
            }
            return obj;
        }
        case ActionTypes.GET_TESTSTEPS_FAILURE:
            return {
                ...state,
                stepsModalDataLoading: false,
                error: action.payload.message,
            };

        case ActionTypes.CREATE_TEST_CASE_SUCCESS: {
            const _testCases = state.testCases.map(tC => Object.assign({}, tC));
            if (action.payload) {
                _testCases.unshift(action.payload);
            }
            return {
                ...state,
                testCases: _testCases,
                selectedTestCase: action.payload,
            };
        }

        case ActionTypes.UPLOAD_CASE_FILE_SUCCESS: {
            const _testCases = state.testCases.map(tC => Object.assign({}, tC));
            const obj = { ...state };
            if (checkKeyInObject(action.payload, 'successArray') && checkArrayLength(action.payload.successArray)) {
                action.payload.successArray.forEach((res) => {
                    const testCase = {
                        testCaseId: res.test_case_id,
                        testCaseName: res.name,
                        createdTime: res.creation_time,
                        status: action.payload.multipleTestCase ? -2 : 4, // -2 is for multipleTestCase upload
                    };
                    _testCases.unshift(testCase);
                });
            }
            if (!action.payload.multipleTestCase) {
                obj.success = 'Test case uploaded successfully';
            }
            obj.testCases = _testCases;
            return obj;
        }

        case ActionTypes.GET_PROJECTS_INFO_FAILURE:
        case ActionTypes.UPDATE_PROJECT_FAILURE:
        case ActionTypes.CREATE_PROJECT_FAILURE:
        case ActionTypes.GET_TESTDATA_SINGLE_FAILURE:
        case ActionTypes.SAVE_TEST_SUITE_CASES_ORDER_FAILURE:
        case ActionTypes.UPLOAD_DATA_FILE_FAILURE:
        case ActionTypes.UPLOAD_CASE_FILE_FAILURE:
            return {
                ...state,
                error: action.payload.message,
            };

        case ActionTypes.UPLOAD_DATA_FILE_SUCCESS: {
            const _testData = JSON.parse(JSON.stringify(state.testData));
            const obj = { ...state };
            if (!action.payload.multipleTestData) {
                obj.success = 'Test data uploaded successfully';
            }
            obj.testData = [...action.payload.newTestData, ..._testData];
            return obj;
        }

        case ActionTypes.GET_TESTCASE_SINGLE_SUCCESS: {
            let _testCasesCopy = state.testCases.map(tC => Object.assign({}, tC));
            const selectedTestCase = JSON.parse(JSON.stringify(state.selectedTestCase));
            const { steps, testCase, testCaseId } = action.payload;
            const obj = {
                ...state,
            };
            if (testCase && `${testCase.testCaseId}` === `${testCaseId}`) {
                _testCasesCopy = _testCasesCopy.map((_testCase) => {
                    if (`${_testCase.testCaseId}` === `${testCase.testCaseId}`) {
                        const item = steps
                            ? {
                                  ..._testCase,
                                  ...testCase,
                                  testSteps: steps, // steps updated because of stepId getting on msgType 8
                              }
                            : {
                                  ..._testCase,
                                  ...testCase,
                              };
                        return item;
                    }
                    return _testCase;
                });
                obj.testCases = _testCasesCopy;
                if (!checkObject(selectedTestCase)) {
                    obj.selectedTestCase = {
                        ...state.selectedTestCase,
                        ...testCase,
                    };
                } else if (checkObject(selectedTestCase) && `${selectedTestCase.testCaseId}` === `${testCase.testCaseId}`) {
                    obj.selectedTestCase = steps
                        ? {
                              ...selectedTestCase,
                              ...testCase,
                              testSteps: steps,
                          }
                        : {
                              ...state.selectedTestCase,
                              ...testCase,
                          };
                }
            }
            return obj;
        }

        case ActionTypes.EMPTY_VALID_URL_VALIDATION:
            return {
                ...state,
                invalidUrl: null,
            };

        case ActionTypes.GET_TESTCASE_SINGLE_FAILURE:
            return {
                ...state,
                error: action.payload.message,
                invalidUrl: true,
            };

        case ActionTypes.CLEAR_TEST_CASE_SUCCESS:
            return {
                ...state,
                selectedTestCase: action.payload.testCase,
            };

        case ActionTypes.GET_TESTCASES:
            return {
                ...state,
                isLoading: true,
                isLoadingCases: true,
                // Need to remove if getTestcases works fine without this
                // testCases: action.payload === state.previousLoadedProjectId ? state.testCases : [],
                // previousLoadedProjectId: action.payload
            };
        case ActionTypes.GET_TESTCASES_SUCCESS: {
            // const { testCases } = state;
            const _payload = JSON.parse(JSON.stringify(action.payload));
            const _testCases = state.testCases.map(tC => Object.assign({}, tC));
            const newTestCases = [];
            _payload.forEach((testCase) => {
                if (testCase) {
                    // Need to get testCase from reducer because sometimes
                    // testCase response getting late so we should not loose our
                    // previous properties(testSteps, recoverTestSteps etc) which we have set in testCase
                    const _oldTestCase =
                        checkArrayLength(_testCases) &&
                        _testCases.find((oldTestCase) => {
                            return oldTestCase && `${oldTestCase.testCaseId}` === `${testCase.testCaseId}`;
                        });
                    newTestCases.push({
                        ..._oldTestCase, // if oldTestCase have any extra proeprty then it will be available too
                        ...testCase,
                    });
                }
            });
            return {
                ...state,
                testCases: newTestCases,
                isLoading: false,
                isLoadingCases: false,
            };
        }

        case ActionTypes.DELETE_TESTSUITES_SUCCESS: {
            const { data, message, suiteTab } = action.payload;
            const obj = {
                ...state,
                success: message,
                error: null,
            };
            if (suiteTab === 'projectDetails') {
                obj.testSuites = state.testSuites.filter((suite) => !data.includes(suite.testSuiteId));
                obj.selectedProject = { ...state.selectedProject, noOfEnvironments: state.selectedProject.noOfEnvironments - 1 };
            } else if (suiteTab === 'plan') {
                obj.testSuiteWithAccount = state.testSuiteWithAccount.filter((element) => !data.includes(element.testSuiteId));
            }
            return obj;
        }
        case ActionTypes.COPYING_FAILED:
        case ActionTypes.DELETE_TESTSUITES_FAILURE: {
            return {
                ...state,
                error: action.payload,
                success: null,
            };
        }
        case ActionTypes.DISABLE_TESTSUITES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                success: null,
            };

        case ActionTypes.DISABLE_TESTSUITES_SUCCESS: {
            const {
                data: { testSuiteIds, disabledStatus },
                message,
                suiteTab,
            } = action.payload;
            const obj = {
                ...state,
                success: message,
                error: null,
            };
            if (suiteTab === 'projectDetails') {
                obj.testSuites = state.testSuites.filter((suite) => !testSuiteIds.includes(suite.testSuiteId));
                obj.selectedProject = { ...state.selectedProject, noOfEnvironments: state.selectedProject.noOfEnvironments - 1 };
            } else if (suiteTab === 'plan') {
                obj.testSuiteWithAccount = state.testSuiteWithAccount.map((element) => {
                    if (testSuiteIds.includes(element.testSuiteId)) {
                        element.disabledStatus = disabledStatus === 1;
                    }
                    return element;
                });
            }
            return obj;
        }

        case ActionTypes.GET_TESTSUITS: {
            let obj = { ...state };
            if (!action.payload.getWithoutLoading) {
                obj = {
                    ...obj,
                    isLoading: true,
                    testSuites: [],
                };
            }
            return obj;
        }
        case ActionTypes.GET_TESTSUITS_SUCCESS: {
            let _selectedProject = JSON.parse(JSON.stringify(state.selectedProject));
            if (checkObject(_selectedProject) && checkObject(action.payload.project)) {
                if (_selectedProject.projectId === action.payload.project.projectId) {
                    _selectedProject = {
                        ..._selectedProject,
                        noOfEnvironments: checkArrayLength(action.payload.data) ? action.payload.data.length : 0,
                    };
                }
            }
            return {
                ...state,
                // Need to check why projects are updating here
                // projects: state.projects.map((project) => {
                //     if (project.projectId === action.payload.project.projectId)
                //     return {
                //         ...project,
                //         _testSuiteId: action.payload.data[0].testSuiteId,
                //     };
                //     else
                //     return {
                //         ...project,
                //     };
                // }),
                selectedProject: _selectedProject,
                testSuites: action.payload.data,
                isLoading: false,
            };
        }
        case ActionTypes.GET_TESTSUITS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case ActionTypes.CLONE_TESTSUITE_SUCCESS:
        case ActionTypes.ADD_TESTSUITE_SUCCESS: {
            const { data, message, suiteTab } = action.payload;
            const obj = {
                ...state,
                success: message,
                error: null,
            };
            if (suiteTab === 'projectDetails') {
                obj.testSuites = [...state.testSuites, data];
                obj.selectedProject = { ...state.selectedProject, noOfEnvironments: state.selectedProject.noOfEnvironments + 1 };
            } else if (suiteTab === 'plan') {
                obj.testSuiteWithAccount = [...state.testSuiteWithAccount, data];
            }
            return obj;
        }
        case ActionTypes.CLONE_TESTSUITE_FAILURE:
        case ActionTypes.EDIT_TESTSUITS_FAILURE:
        case ActionTypes.ADD_TESTSUITS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isError: true,
            };

        case ActionTypes.GET_PROJECTS_AND_TESTSUITES:
            return {
                ...state,
                error: action.payload,
                isProjectAndTestSuiteLoading: true,
            };
        case ActionTypes.GET_PROJECTS_AND_TESTSUITES_SUCCESS:
            return {
                ...state,
                getProjectAndTestSuiteData: action.payload,
                isProjectAndTestSuiteLoading: false,
            };
        case ActionTypes.GET_PROJECTS_AND_TESTSUITES_FAILURE:
            return {
                ...state,
                error: action.payload.message,
                isError: true,
                isProjectAndTestSuiteLoading: false,
            };

        case ActionTypes.EDIT_TESTSUITS_SUCCESS: {
            const { data, message, suiteTab } = action.payload;
            const obj = {
                ...state,
                success: message,
                error: null,
            };
            if (suiteTab === 'projectDetails') {
                obj.testSuites = state.testSuites.map((suite) => {
                    let updatedSuite = suite;
                    if (`${data.testSuiteId}` === `${suite.testSuiteId}`) {
                        updatedSuite = data;
                    }
                    return updatedSuite;
                });
            } else if (suiteTab === 'plan') {
                obj.testSuiteWithAccount = state.testSuiteWithAccount.map((suite) => {
                    let updatedSuite = suite;
                    if (`${data.testSuiteId}` === `${suite.testSuiteId}`) {
                        updatedSuite = data;
                    }
                    return updatedSuite;
                });
            }
            return obj;
        }

        case ActionTypes.ASSOCIATE_TESTSUITS_SUCCESS: {
            let testSuites = JSON.parse(JSON.stringify(state.testSuites));
            let updatedTestSuite = testSuites.find((suite) => `${suite.testSuiteId}` === `${action.payload.testSuiteId}`);
            if (checkArrayLength(updatedTestSuite)) {
                updatedTestSuite = updatedTestSuite[0];
                if (checkObject(updatedTestSuite) && checkArrayLength(state.testCases)) {
                    if (!updatedTestSuite.testCases) {
                        updatedTestSuite.testCases = [];
                    }
                    state.testCases.forEach((_case) => {
                        if (action.payload.testCaseIds.includes(_case.testCaseId)) {
                            updatedTestSuite.testCases.push(_case);
                        }
                    });
                }
                testSuites = testSuites.map((testSuite) => {
                    if (`${testSuite.testSuiteId}` === `${action.payload.testSuiteId}`) {
                        testSuite = { ...updatedTestSuite };
                    }
                    return testSuite;
                });
            }
            return {
                ...state,
                testSuites,
                success: '',
                // success: action.payload.message,
                error: null,
                isError: false,
                isLoading: false,
            };
        }
        case ActionTypes.ASSOCIATE_TESTSUITS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                success: null,
                isError: true,
            };
        case ActionTypes.GET_TESTSUITS_WITH_ACCOUNT_SUCCESS:
            return {
                ...state,
                testSuiteWithAccount: action.payload,
                isLoading: false,
            };
        case ActionTypes.GET_ALERT_TESTCASES_WITH_ACCOUNT_SUCCESS:
            return {
                ...state,
                alertTestCasesWithAccount: action.payload,
                isLoading: false,
            };
        case ActionTypes.GET_ALERT_TESTCASES_WITH_ACCOUNT_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.payload.message,
            };
        case ActionTypes.REJECT_APPROVE_ALERT_TESTCASE_SUCCESS:
            return {
                ...state,
                success: '',
                isLoading: false,
            };
        case ActionTypes.REJECT_APPROVE_ALERT_TESTCASE_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.payload,
            };
        case ActionTypes.CREATE_EXECUTE_TEST_SUITE_SUCCESS:
            return {
                ...state,
                success: action.payload,
                isLoading: false,
            };
        case ActionTypes.CREATE_EXECUTE_TEST_SUITE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.message,
                isError: true,
            };
        case ActionTypes.HANDLE_UPDATE_TESTCASE_IN_TESTSUITE_DIRECTION: {
            // THIS CODE IS PERFORMING UP DOWN IN TEST CASE OF TEST SUITES
            const oldObjectIndex = state.testSuiteWithAccount.findIndex((element) => element.testSuiteId === action.data.testSuiteId);
            const oldObject = state.testSuiteWithAccount[oldObjectIndex];
            const totalTestCases = oldObject.testCases ? oldObject.testCases.length : 0;
            if (totalTestCases) {
                const oldIndexOfTestCase = oldObject.testCases.findIndex((element) => element.testCaseId === action.data.testCaseId);
                if (
                    (oldIndexOfTestCase === 0 && action.data.direction === 'up') ||
                    (oldIndexOfTestCase === oldObject.testCases.length - 1 && action.data.direction === 'down')
                ) {
                    return { ...state };
                }
                let temp = {};
                if (action.data.direction === 'up') {
                    temp = { ...oldObject.testCases[oldIndexOfTestCase - 1] };
                    oldObject.testCases[oldIndexOfTestCase - 1] = { ...oldObject.testCases[oldIndexOfTestCase] };
                    oldObject.testCases[oldIndexOfTestCase] = { ...temp };
                } else {
                    temp = { ...oldObject.testCases[oldIndexOfTestCase + 1] };
                    oldObject.testCases[oldIndexOfTestCase + 1] = { ...oldObject.testCases[oldIndexOfTestCase] };
                    oldObject.testCases[oldIndexOfTestCase] = { ...temp };
                }
                const oldTestSuites = JSON.parse(JSON.stringify(state.testSuiteWithAccount));
                oldTestSuites[oldObjectIndex] = oldObject;
                return {
                    ...state,
                    testSuiteWithAccount: oldTestSuites,
                };
            }
            return { ...state };
        }
        case ActionTypes.UPDATE_TEST_SUITE_DATA_PROP_PROJECT: {
            return {
                ...state,
                testSuites: action.data,
            };
        }
        case ActionTypes.UPDATE_TEST_SUITE_DATA_PROP_PLAN: {
            return {
                ...state,
                testSuiteWithAccount: action.data,
            };
        }
        case ActionTypes.SAVE_TEST_SUITE_CASES_ORDER_SUCCESS: {
            const oldOrderedtestSuitesWithAccount = checkArrayLength(state.testSuiteWithAccount)
                ? JSON.parse(JSON.stringify(state.testSuiteWithAccount))
                : [];
            const oldOrderedtestSuites = checkArrayLength(state.testSuites) ? JSON.parse(JSON.stringify(state.testSuites)) : [];
            const updatedObject = { ...state };
            if (oldOrderedtestSuitesWithAccount.length) {
                const updatedOrderSuiteWA = oldOrderedtestSuitesWithAccount.findIndex((element) => {
                    return `${element.testSuiteId}` === `${action.data.testSuiteId}`;
                });
                if (updatedOrderSuiteWA >= 0) {
                    oldOrderedtestSuitesWithAccount[updatedOrderSuiteWA] = action.data;
                    updatedObject.success = action.payload;
                    updatedObject.testSuiteWithAccount = oldOrderedtestSuitesWithAccount;
                }
            }
            if (oldOrderedtestSuites.length) {
                const updatedOrderSuite = oldOrderedtestSuites.findIndex((element) => {
                    return `${element.testSuiteId}` === `${action.data.testSuiteId}`;
                });
                if (updatedOrderSuite >= 0) {
                    oldOrderedtestSuites[updatedOrderSuite] = action.data;
                    updatedObject.success = action.payload;
                    updatedObject.testSuites = oldOrderedtestSuites;
                }
            }
            return updatedObject;
        }

        case ActionTypes.HANDLE_UPDATE_TESTCASE_IN_TESTSUITE_DIRECTION_PROJECT: {
            // THIS CODE IS PERFORMING UP DOWN IN TEST CASE OF TEST SUITES
            const oldObjectIndex = state.testSuites.findIndex((element) => element.testSuiteId === action.data.testSuiteId);
            const oldObject = state.testSuites[oldObjectIndex];
            const totalTestCases = oldObject.testCases ? oldObject.testCases.length : 0;
            if (totalTestCases) {
                const oldIndexOfTestCase = oldObject.testCases.findIndex((element) => element.testCaseId === action.data.testCaseId);
                if (
                    (oldIndexOfTestCase === 0 && action.data.direction === 'up') ||
                    (oldIndexOfTestCase === oldObject.testCases.length - 1 && action.data.direction === 'down')
                ) {
                    return { ...state };
                }
                let temp = {};
                if (action.data.direction === 'up') {
                    temp = { ...oldObject.testCases[oldIndexOfTestCase - 1] };
                    oldObject.testCases[oldIndexOfTestCase - 1] = { ...oldObject.testCases[oldIndexOfTestCase] };
                    oldObject.testCases[oldIndexOfTestCase] = { ...temp };
                } else {
                    temp = { ...oldObject.testCases[oldIndexOfTestCase + 1] };
                    oldObject.testCases[oldIndexOfTestCase + 1] = { ...oldObject.testCases[oldIndexOfTestCase] };
                    oldObject.testCases[oldIndexOfTestCase] = { ...temp };
                }
                const oldTestSuites = JSON.parse(JSON.stringify(state.testSuites));
                oldTestSuites[oldObjectIndex] = oldObject;
                return {
                    ...state,
                    testSuites: oldTestSuites,
                };
            }
            return { ...state };
        }

        case ActionTypes.GET_TESTDATA_SINGLE_SUCCESS: {
            const _testData = JSON.parse(JSON.stringify(state.testData));
            let duplicateDataObj = false;
            if (action.payload) {
                _testData.forEach((testDatum, index) => {
                    if (`${testDatum.testDataId}` === `${action.payload.testDataId}`) {
                        duplicateDataObj = true;
                        _testData[index] = { ...testDatum, ...action.payload };
                    }
                });
                if (!duplicateDataObj) {
                    _testData.unshift(action.payload);
                }
            }
            return {
                ...state,
                testData: _testData,
            };
        }

        case ActionTypes.GET_TESTDATA:
            return {
                ...state,
                isLoadingTestData: true,
            };
        case ActionTypes.GET_TESTDATA_SUCCESS:
            return {
                ...state,
                testData: action.payload,
                isLoadingTestData: false,
            };
        case ActionTypes.GET_TESTDATA_FAILURE:
            return {
                ...state,
                isLoadingTestData: false,
            };
        case ActionTypes.PREVIEW_TEST_DATA_SUCCESS:
            return {
                ...state,
                testDataColumns: action.payload,
            };
        case ActionTypes.PREVIEW_TEST_DATA_CLEAR:
            return {
                ...state,
                testDataColumns: {},
            };
        case ActionTypes.GET_TESTEXECUTESCRIPT:
            return {
                ...state,
                executionTest: action.payload.data,
            };

        case ActionTypes.UPDATE_EXECUTION_TASKS_STATUS_PAGE: {
            return {
                ...state,
                executionTaskStatusPageNo: action.payload.page,
                executions: `${action.payload.page}` === '1' && !action.payload.executionsPolling ? [] : state.executions,
                isExecutionTaskStatusLastPage: `${action.payload.page}` === '1' ? false : state.isExecutionTaskStatusLastPage,
            };
        }

        case ActionTypes.UPDATE_EXECUTION_TASKS_STATUS: {
            return {
                ...state,
                executionTasksStatus: action.payload.executionTasksStatus,
                executionsLoading: true,
                executionsPolling: action.payload.executionsPolling,
            };
        }

        case ActionTypes.GET_EXECUTE_TASK_SUCCESS: {
            let executions = action.payload;
            let isLast = false;
            if (state.executionTaskStatusPageNo > 1) {
                executions = state.executions;
                isLast = true;
                if (action.payload.length > 0) {
                    executions = executions.concat(action.payload);
                    isLast = false;
                }
            }
            return {
                ...state,
                executions,
                executionsLoading: false,
                isExecutionTaskStatusLastPage: isLast,
                executionsStatus: action.executionsStatus,
            };
        }

        case ActionTypes.GET_EXECUTE_TASK_FAILURE: {
            return {
                ...state,
                // executionTasksStatus: [],
                executionsLoading: false,
            };
        }

        case ActionTypes.GET_GENERATE_TEST_SCRIPT:
            return {
                ...state,
                generateTestScript: action.payload.data,
            };
        case ActionTypes.TASK_PANEL_TOGGLE:
            return {
                ...state,
                isRightPanelOpened: !state.isRightPanelOpened,
                forceOpenCloseRightPanel: null,
            };
        case ActionTypes.TASK_PANEL_SHOW_HIDE:
            return {
                ...state,
                forceOpenCloseRightPanel:
                    (state.isRightPanelOpened === true && action.payload === true) ||
                    (state.isRightPanelOpened === false && action.payload === false) ||
                    state.isRightPanelOpened === null
                        ? null
                        : action.payload,
            };
        case ActionTypes.RECORD_TEST_CASE_SUCCESS: {
            // save sessionId to check if testcase is running with current browser session id or not
            const { payloadInformation } = action.payload;
            const obj = {
                ...state,
                recoderCall: action.payload.data,
            };
            if (payloadInformation && payloadInformation.sessionIds) {
                const { projectId, sessionIds, token, userId } = payloadInformation;
                let wsRunningCaseData = JSON.parse(JSON.stringify(state.wsRunningCaseData));
                wsRunningCaseData = {
                    ...wsRunningCaseData,
                    recorderTestCase: {
                        sessionIds,
                        projectId,
                        userId,
                        token,
                    },
                };
                obj.wsRunningCaseData = wsRunningCaseData;
            }
            return obj;
        }
        case ActionTypes.RECORD_TEST_CASE_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                showRecorderInstalltion: action.payload.idModal,
            };
        case ActionTypes.GET_USER_VARIABLES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userVariables: action.payload,
            };
        case ActionTypes.GET_USER_VARIABLES_FAILURE:
            return {
                ...state,
                error: action.payload.message || action.payload.error,
            };
        case ActionTypes.SEND_EMAIL_LOGS_SUCCESS:
        case ActionTypes.SAVE_USER_VARIABLES_SUCCESS:
            return {
                ...state,
                success: action.payload,
            };
        case ActionTypes.SEND_EMAIL_LOGS_FAILED:
        case ActionTypes.GET_NOTIFICATIONS_FAILED:
        case ActionTypes.SAVE_USER_VARIABLES_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case ActionTypes.DELETE_USER_VARIABLES_CLEAR:
        case ActionTypes.DELETE_USER_VARIABLES:
            return {
                ...state,
                deleteUserVariableSuccess: null,
            };
        case ActionTypes.DELETE_USER_VARIABLES_SUCCESS:
            return {
                ...state,
                success: action.payload,
                deleteUserVariableSuccess: true,
            };
        case ActionTypes.DELETE_USER_VARIABLES_FAILURE:
            return {
                ...state,
                error: action.payload.error.message,
            };

        case ActionTypes.CLOSE_INSTALLATION_MODAL:
            return {
                ...state,
                showRecorderInstalltion: false,
                snackbarLink: '',
                recorderInstallationModalOpened:false
            };
        case ActionTypes.PLUGIN_NOT_FOUND:
        case ActionTypes.YOUR_BROSWER_NOT_SPORTED:
            return {
                ...state,
                snackbarLink: action.payload.snackbarLink,
                error: action.payload.error,
                showRecorderInstalltion: action.payload.idModal,
                recorderInstallationModalOpened:true
            };
        case ActionTypes.SHOW_TOAST:
        case ActionTypes.SET_PROJECT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case ActionTypes.CLEAR_ERROR:
            return {
                ...state,
                error: null,
                snackbarLink: '',
            };
        case ActionTypes.CLEAR_SUCCESS:
            return {
                ...state,
                success: null,
            };
        case ActionTypes.TEMP_CHECKED_CASE_IDS:
            return {
                ...state,
                tempCheckedCaseIds: action.payload,
            };
        case ActionTypes.SHOW_ERROR:
            return {
                ...state,
                error: action.payload.message || action.payload.error || 'Action Failed', // action.payload.error.message,
                message: action.payload.message,
                success: null,
            };
        case ActionTypes.NON_DELETE_NOTIFICATIONS:
            return {
                ...state,
                nonDeletedNotify: action.payload,
            };
        case ActionTypes.CLEAR_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                nonDeletedNotify: [],
            };
        case ActionTypes.DELETE_NOTIFICATIONS:
            return {
                ...state,
                deletedNotify: action.payload,
            };
        case ActionTypes.MARK_DELETED_NOTIFICATIONS:
            return {
                ...state,
                markDeletedNotify: action.payload,
            };
        case ActionTypes.MARK_READ_NOTIFICATIONS:
            return {
                ...state,
                markReadNotify: action.payload,
            };
        case ActionTypes.MARK_READ_NOTIFICATIONS_DELETED:
            return {
                ...state,
                markReadNotifyDeleted: action.payload,
            };
        case ActionTypes.GET_PROJECT_DETAILS_SUCCESS: {
            const selectedProject = {
                ...action.payload,
                projectId: action.payload.projectId || action.payload.id,
                projectName: action.payload.projectName || action.payload.name,
            };
            return {
                ...state,
                selectedProject,
                executionTaskStatusPageNo: 0,
                isLoading: false,
            };
        }
        case ActionTypes.UPDATE_CASES: {
            return {
                ...state,
                testCases: action.payload.testCases,
            };
        }
        case ActionTypes.CLEAR_SELECTED_CASE_DATA_PROJECT: {
            return {
                ...state,
                selectedProject: {},
                selectedTestCase: {},
                testCases: [],
                testData: [],
            };
        }
        case ActionTypes.SET_SELECTED_TASK:
            return {
                ...state,
                selectedExecuteTask: action.payload,
            };
        case ActionTypes.CALL_RETRY_FUNC:
            return {
                ...state,
                callRetryFunc: action.payload,
            };
        case ActionTypes.SET_WS_STATUS: {
            const dataFromPlatform = isJson(action.payload.msgJson) ? JSON.parse(action.payload.msgJson) : '';
            const { testCaseId } = dataFromPlatform;
            let wsRunningCaseData = JSON.parse(JSON.stringify(state.wsRunningCaseData));

            const obj = {
                ...state,
                testStepObj: {
                    ...state.testStepObj,
                    [testCaseId]: action.payload,
                },
            };
            if (action.payload.msgType === 3) {
                // msgType = 3
                wsRunningCaseData = {
                    ...state.wsRunningCaseData,
                    [testCaseId]: {
                        ...state.wsRunningCaseData[testCaseId],
                        stepsCount: dataFromPlatform.testSteps.length,
                        testStepObj: action.payload,
                    },
                };
                obj.wsRunningCaseData = wsRunningCaseData;
            } else if (action.payload.msgType !== 1) {
                wsRunningCaseData = {
                    ...state.wsRunningCaseData,
                    [testCaseId]: { ...state.wsRunningCaseData[testCaseId], testStepObj: action.payload },
                };
                obj.wsRunningCaseData = wsRunningCaseData;
            }
            return obj;
        }
        case ActionTypes.GET_CHANGED_APIS:
            return {
                ...state,
                changedApis: [],
                error: null,
                isLoading: true,
            };
        case ActionTypes.GET_CHANGED_APIS_SUCCESS: {
            let responseData = action.payload;
            responseData = responseData && responseData.length > 0 ? responseData.sort() : responseData;
            const changedApis = responseData.map((data) => {
                return { api: data, checked: false };
            });
            return {
                ...state,
                changedApis,
                error: null,
                isLoading: false,
            };
        }
        case ActionTypes.GET_CHANGED_APIS_FAILURE:
            return {
                ...state,
                changedApis: [],
                error: action.payload.message || action.payload.error,
                isLoading: false,
            };
        case ActionTypes.SMART_RUN: {
            return {
                ...state,
                error: null,
                isLoading: true,
                success: null,
            };
        }
        case ActionTypes.SMART_RUN_SUCCESS: {
            const tempAlertTestCasesWithAccount = JSON.parse(JSON.stringify(state.alertTestCasesWithAccount));
            if (action.payload.data && action.payload.data.length) {
                action.payload.data.forEach((element) => {
                    tempAlertTestCasesWithAccount.push(element);
                });
            }
            return {
                ...state,
                error: null,
                isLoading: false,
                changedApis: [],
                alertTestCasesWithAccount: tempAlertTestCasesWithAccount,
                success: action.payload.message,
            };
        }
        case ActionTypes.SMART_RUN_FAILURE:
            return {
                ...state,
                error: action.payload.message || action.payload.error,
                isLoading: false,
                success: null,
            };
        case ActionTypes.COPIED_SUCCESS:
            return {
                ...state,
                error: null,
                success: action.payload,
            };

        case ActionTypes.CALL_ORIGINAL_STEP:
            return {
                ...state,
                shiftToLive: action.payload,
            };

        case ActionTypes.SIDE_BAR_TOGGLE:
            return {
                ...state,
                isSideBarOpened: action.payload !== undefined ? action.payload : !state.isSideBarOpened,
            };
        case ActionTypes.SEND_EMAIL_LOGS_SUCCESS_DOWNLOAD:
            return {
                ...state,
                success: action.payload.success,
            };

        // msgType = 2
        case ActionTypes.SAVE_SESSION_ID: {
            const { sessionId, testCaseId, callingFrom } = action.payload;

            const obj = { ...state };

            if (
                !['ExecuteScriptModal->executeTestSuite', 'ExecuteScriptModal->executeTestScript', 'ExecuteScriptModal->executeTag'].includes(
                    callingFrom,
                )
            ) {
                const _failedStepsData = JSON.parse(JSON.stringify(state.failedStepsData));
                const _debugStepsData = JSON.parse(JSON.stringify(state.debugStepsData));
                const _wsRunningCaseData = JSON.parse(JSON.stringify(state.wsRunningCaseData));
                const wsKeys = JSON.parse(JSON.stringify(state.wsKeys));

                if (checkKeyInObject(wsKeys, testCaseId)) {
                    delete wsKeys[testCaseId].betweenMsgType15and2;
                    delete wsKeys[testCaseId].betweenMsgType18and2;
                }

                obj.wsKeys = wsKeys;

                if (_failedStepsData && !_failedStepsData[testCaseId]) {
                    // check if obj against key exists or not to avoid duplication in key
                    _failedStepsData[testCaseId] = { sessionId };
                    obj.failedStepsData = _failedStepsData;
                }
                if (_debugStepsData && !_debugStepsData[testCaseId]) {
                    // check if obj against key exists or not to avoid duplication in key
                    _debugStepsData[testCaseId] = { sessionId };
                    obj.debugStepsData = _debugStepsData;
                }
                if (!checkKeyInObject(_wsRunningCaseData, testCaseId)) {
                    _wsRunningCaseData[testCaseId] = { sessionId, isMsgType2Sent: true, isMsgType13Received: false, isMsgType16Received: false };
                    obj.wsRunningCaseData = _wsRunningCaseData;
                }
            }
            return obj;
        }
        // msgType = 2 failed
        case ActionTypes.REMOVE_MSGTYPE2_DATA: {
            const { testCaseIds } = action.payload;
            const _failedStepsData = JSON.parse(JSON.stringify(state.failedStepsData));
            const _debugStepsData = JSON.parse(JSON.stringify(state.debugStepsData));
            const _wsRunningCaseData = JSON.parse(JSON.stringify(state.wsRunningCaseData));
            const _wsKeys = JSON.parse(JSON.stringify(state.wsKeys));

            const obj = { ...state };

            if (checkArrayLength(testCaseIds)) {
                testCaseIds.forEach((testCaseId) => {
                    delete _failedStepsData[testCaseId];
                    delete _debugStepsData[testCaseId];
                    delete _wsRunningCaseData[testCaseId];
                    delete _wsKeys[testCaseId];
                });
            }

            obj.failedStepsData = _failedStepsData;
            obj.debugStepsData = _debugStepsData;
            obj.wsRunningCaseData = _wsRunningCaseData;
            obj.wsKeys = _wsKeys;

            return obj;
        }
        // msgType = 13
        case ActionTypes.SAVE_FAILED_STEP: {
            const { failedStep, sessionId } = action.payload;
            const { testCaseId } = failedStep;

            const obj = {
                ...state,
            };

            const _failedStepsData = JSON.parse(JSON.stringify(state.failedStepsData));
            const isWsRunning = JSON.parse(JSON.stringify(state.isWsRunning));
            const wsRunningTestSteps = JSON.parse(JSON.stringify(state.wsRunningTestSteps));
            const isGenerateBtnClicked = JSON.parse(JSON.stringify(state.isGenerateBtnClicked));
            const showLoaderForGenerate = JSON.parse(JSON.stringify(state.showLoaderForGenerate));
            const _testCases = state.testCases.map(tC => Object.assign({}, tC));
            let selectedTestCase = JSON.parse(JSON.stringify(state.selectedTestCase));
            let wsRunningCaseData = JSON.parse(JSON.stringify(state.wsRunningCaseData));
            // this key `failedStepForOtherUser` will be removed (Tahir)
            wsRunningCaseData = {
                ...state.wsRunningCaseData,
                [testCaseId]: {
                    ...wsRunningCaseData[testCaseId],
                    stopAt: new Date(),
                    isStart: false,
                    failedStepForOtherUser: failedStep,
                    isRunning: false,
                    isMsgType13Received: true,
                    isMessageType15Sent: false,
                },
            };

            // to update parent instr status
            if (
                checkKeyInObject(failedStep, 'instrNum') &&
                failedStep.instrNum.includes('.') &&
                checkObject(wsRunningTestSteps) &&
                wsRunningTestSteps[testCaseId]
            ) {
                const parentInstrNum = failedStep.instrNum.split('.').shift();
                // wsRunningTestSteps is used to store all websockets running steps with testCase Ids
                // So if user closes live test step modal we can show their previous steps too on next until
                // web socket running.
                const index = wsRunningTestSteps[testCaseId].map((step) => step.instrNum).indexOf(parentInstrNum);
                if (index > -1) {
                    wsRunningTestSteps[testCaseId][index] = {
                        ...wsRunningTestSteps[testCaseId][index],
                        status: failedStep.status,
                    };
                }

                if (checkObject(selectedTestCase) && `${selectedTestCase.testCaseId}` === `${testCaseId}`) {
                    selectedTestCase = {
                        ...selectedTestCase,
                        testSteps: wsRunningTestSteps[testCaseId],
                    };
                    obj.selectedTestCase = selectedTestCase;
                }

                const testCases = [];
                _testCases.forEach((testcase) => {
                    if (testcase) {
                        if (`${testcase.testCaseId}` === testCaseId) {
                            testCases.push({
                                ...testcase,
                                testSteps: wsRunningTestSteps[testCaseId],
                            });
                        } else {
                            testCases.push(testcase);
                        }
                    }
                });
                obj.testCases = testCases;
            }

            delete isWsRunning[testCaseId];
            delete wsRunningTestSteps[testCaseId];
            delete isGenerateBtnClicked[testCaseId];
            delete showLoaderForGenerate[testCaseId];

            obj.isWsRunning = isWsRunning;
            obj.wsRunningTestSteps = wsRunningTestSteps;
            obj.wsRunningCaseData = wsRunningCaseData;
            obj.isGenerateBtnClicked = isGenerateBtnClicked;
            obj.showLoaderForGenerate = showLoaderForGenerate;

            if (!isWsRunning || (isWsRunning && Object.keys(isWsRunning).length < 1)) obj.isRefreshDuringWsRunning = true;

            if (checkKeyInObject(_failedStepsData, testCaseId) && _failedStepsData[testCaseId].sessionId === `${sessionId}`) {
                // to show smart retry button even after unmount then remount step editor
                const childIndex = state.wsRunningTestSteps[testCaseId].map((step) => step.instrNum).indexOf(failedStep.instrNum);
                if (childIndex > -1) {
                    state.wsRunningTestSteps[testCaseId][childIndex] = {
                        ...state.wsRunningTestSteps[testCaseId][childIndex],
                        showSmartRetryButton: failedStep.showSmartRetryButton,
                    };
                }

                // sessionId msgType 2 me save karwate hen,
                // FailedStepsData me agar TestCaseId he to is ka mtlab he hm ne run kiya he

                _failedStepsData[testCaseId] = {
                    ..._failedStepsData[testCaseId],
                    instrNum: failedStep.instrNum,
                    xpath: failedStep.xpath,
                    wsRunningTestSteps: state.wsRunningTestSteps[testCaseId],
                };
                obj.failedStepsData = _failedStepsData;
            } else {
                delete _failedStepsData[testCaseId];
                obj.failedStepsData = _failedStepsData;
            }
            return obj;
        }
        // msgType = 14
        case ActionTypes.RESET_FAILED_STEPS: {
            const { firstEditedStep, sessionId, testCaseId } = action.payload;
            const _failedStepsData = JSON.parse(JSON.stringify(state.failedStepsData));
            let wsRunningCaseData = JSON.parse(JSON.stringify(state.wsRunningCaseData));

            wsRunningCaseData = {
                ...wsRunningCaseData,
                [testCaseId]: {
                    ...wsRunningCaseData[testCaseId],
                    isRunning: true,
                    isMsgType13Received: false,
                    firstEditedStep,
                },
            };

            delete wsRunningCaseData[testCaseId].failedStepForOtherUser;
            const obj = {
                ...state,
                firstEditedStep,
            };

            obj.wsRunningCaseData = wsRunningCaseData;

            if (checkKeyInObject(_failedStepsData, testCaseId) && `${_failedStepsData[testCaseId].sessionId}` === `${sessionId}`) {
                _failedStepsData[testCaseId] = { sessionId };

                obj.failedStepsData = _failedStepsData;
            }
            return obj;
        }
        // msgType = 15
        case ActionTypes.MSGTYPE_15_FLAG_TOOGLE: {
            const { stepsOnMsgType15, testCaseId, isMessageType15Sent } = action.payload;
            let wsRunningCaseData = JSON.parse(JSON.stringify(state.wsRunningCaseData));
            let wsKeys = JSON.parse(JSON.stringify(state.wsKeys));

            wsRunningCaseData = {
                ...wsRunningCaseData,
                [testCaseId]: {
                    ...wsRunningCaseData[testCaseId],
                    isMessageType15Sent,
                },
            };

            wsKeys = {
                ...wsKeys,
                [testCaseId]: {
                    ...wsKeys[testCaseId],
                    betweenMsgType15and2: true,
                },
            };
            return {
                ...state,
                stepsOnMsgType15,
                wsRunningCaseData,
                wsKeys,
            };
        }
        // msgType = 16
        case ActionTypes.SAVE_DEBUG_STEP: {
            const { debugStep, sessionId } = action.payload;
            const { testCaseId } = debugStep;

            const obj = {
                ...state,
            };

            const _debugStepsData = JSON.parse(JSON.stringify(state.debugStepsData));
            const isWsRunning = JSON.parse(JSON.stringify(state.isWsRunning));
            const wsRunningTestSteps = JSON.parse(JSON.stringify(state.wsRunningTestSteps));
            const isGenerateBtnClicked = JSON.parse(JSON.stringify(state.isGenerateBtnClicked));
            const showLoaderForGenerate = JSON.parse(JSON.stringify(state.showLoaderForGenerate));
            const _testCases = state.testCases.map(tC => Object.assign({}, tC));
            let selectedTestCase = JSON.parse(JSON.stringify(state.selectedTestCase));
            let wsRunningCaseData = JSON.parse(JSON.stringify(state.wsRunningCaseData));
            wsRunningCaseData = {
                ...wsRunningCaseData,
                [testCaseId]: {
                    ...wsRunningCaseData[testCaseId],
                    stopAt: new Date(),
                    isStart: false,
                    isRunning: false,
                    isMsgType16Received: true,
                    isMessageType18Sent: false,
                },
            };
            // to update parent instr status
            if (
                checkKeyInObject(debugStep, 'instrNum') &&
                debugStep.instrNum.includes('.') &&
                checkObject(wsRunningTestSteps) &&
                wsRunningTestSteps[testCaseId]
            ) {
                const parentInstrNum = debugStep.instrNum.split('.').shift();
                // wsRunningTestSteps is used to store all websockets running steps with testCase Ids
                // So if user leaves live test step screen we can show their previous steps too on next until
                // web socket running.
                const index = wsRunningTestSteps[testCaseId].map((step) => step.instrNum).indexOf(parentInstrNum);
                if (index > -1) {
                    wsRunningTestSteps[testCaseId][index] = {
                        ...wsRunningTestSteps[testCaseId][index],
                        status: debugStep.status,
                    };
                }

                if (checkObject(selectedTestCase) && `${selectedTestCase.testCaseId}` === `${testCaseId}`) {
                    selectedTestCase = {
                        ...selectedTestCase,
                        testSteps: wsRunningTestSteps[testCaseId],
                    };
                    obj.selectedTestCase = selectedTestCase;
                }

                const testCases = [];
                _testCases.forEach((testcase) => {
                    if (testcase) {
                        if (`${testcase.testCaseId}` === `${testCaseId}`) {
                            testCases.push({
                                ...testcase,
                                testSteps: wsRunningTestSteps[testCaseId],
                            });
                        } else {
                            testCases.push(testcase);
                        }
                    }
                });
                obj.testCases = testCases;
            }

            delete isWsRunning[testCaseId];
            delete wsRunningTestSteps[testCaseId];
            delete isGenerateBtnClicked[testCaseId];
            delete showLoaderForGenerate[testCaseId];

            obj.isWsRunning = isWsRunning;
            obj.wsRunningTestSteps = wsRunningTestSteps;
            obj.wsRunningCaseData = wsRunningCaseData;
            obj.isGenerateBtnClicked = isGenerateBtnClicked;
            obj.showLoaderForGenerate = showLoaderForGenerate;

            if (!checkObject(isWsRunning)) obj.isRefreshDuringWsRunning = true;

            if (checkKeyInObject(_debugStepsData, testCaseId) && `${_debugStepsData[testCaseId].sessionId}` === `${sessionId}`) {
                // to show smart debug button even after unmount then remount step editor
                const childIndex = state.wsRunningTestSteps[testCaseId].map((step) => step.instrNum).indexOf(debugStep.instrNum);
                if (childIndex > -1) {
                    state.wsRunningTestSteps[testCaseId][childIndex] = {
                        ...state.wsRunningTestSteps[testCaseId][childIndex],
                        showDebugPlayButton: debugStep.showDebugPlayButton,
                    };
                }
                // sessionId msgType 2 me save karwate hen,
                // DebugStepsData me agar TestCaseId he to is ka mtlab he hm ne run kiya he
                _debugStepsData[testCaseId] = {
                    ..._debugStepsData[testCaseId],
                    instrNum: debugStep.instrNum,
                    xpath: debugStep.xpath,
                    wsRunningTestSteps: state.wsRunningTestSteps[testCaseId],
                };
            } else {
                delete _debugStepsData[testCaseId];
            }
            obj.debugStepsData = _debugStepsData;
            return obj;
        }
        // msgType = 17
        case ActionTypes.RESET_DEBUG_STEPS: {
            const { firstEditedStep, sessionId, testCaseId } = action.payload;
            const _debugStepsData = JSON.parse(JSON.stringify(state.debugStepsData));
            let wsRunningCaseData = JSON.parse(JSON.stringify(state.wsRunningCaseData));

            wsRunningCaseData = {
                ...wsRunningCaseData,
                [testCaseId]: {
                    ...wsRunningCaseData[testCaseId],
                    isRunning: true,
                    isMsgType16Received: false,
                    firstEditedStep,
                },
            };

            const obj = {
                ...state,
                firstEditedStep,
            };

            obj.wsRunningCaseData = wsRunningCaseData;

            if (checkKeyInObject(_debugStepsData, testCaseId) && `${_debugStepsData[testCaseId].sessionId}` === `${sessionId}`) {
                _debugStepsData[testCaseId] = { sessionId };
                obj.debugStepsData = _debugStepsData;
            }
            return obj;
        }
        // msgType = 18
        case ActionTypes.MSGTYPE_18_FLAG_TOOGLE: {
            const { stepsOnMsgType18, testCaseId, isMessageType18Sent } = action.payload;
            let wsRunningCaseData = JSON.parse(JSON.stringify(state.wsRunningCaseData));
            let wsKeys = JSON.parse(JSON.stringify(state.wsKeys));

            wsRunningCaseData = {
                ...wsRunningCaseData,
                [testCaseId]: {
                    ...wsRunningCaseData[testCaseId],
                    isMessageType18Sent,
                },
            };

            wsKeys = {
                ...wsKeys,
                [testCaseId]: {
                    ...wsKeys[testCaseId],
                    betweenMsgType18and2: true,
                },
            };
            return {
                ...state,
                stepsOnMsgType18,
                wsRunningCaseData,
                wsKeys,
            };
        }
        // To add and remove tags form selectedTestCase
        // This reducer also in tagReducer
        case ActionTypes.TAGGED_TESTCASE_SUCCESS: {
            const selectedTestCase = { ...state.selectedTestCase };
            if (action.payload.tc_ids.includes(selectedTestCase.testCaseId)) {
                selectedTestCase.tags.push(action.payload.tag_id);
                return {
                    ...state,
                    selectedTestCase,
                };
            }
            return {
                ...state,
            };
        }
        case ActionTypes.UN_TAGGED_TESTCASE_SUCCESS: {
            const selectedTestCase = { ...state.selectedTestCase };
            if (selectedTestCase.testCaseId === action.payload.tc_id) {
                selectedTestCase.tags = selectedTestCase.tags.filter((t) => t !== action.payload.tag_id);
                return {
                    ...state,
                    selectedTestCase,
                };
            }
            return {
                ...state,
            };
        }

        case ActionTypes.GET_SINGLE_TESTSUITE:
        case ActionTypes.UPDATE_DATA:
            return {
                ...state,
                error: null,
                success: null,
                isLoading: true,
            };

        case ActionTypes.UPDATE_DATA_SUCCESS: {
            return {
                ...state,
                error: null,
                success: action.payload.message,
                isLoading: false,
            };
        }

        case ActionTypes.GET_SINGLE_TESTSUITE_FAILURE:
        case ActionTypes.UPDATE_DATA_FAILURE:
            return {
                ...state,
                error: action.payload.message,
                success: null,
                isLoading: false,
            };

        case ActionTypes.REMOVE_MESSAGE_TYPE_8_RECEIVED: {
            const {
                payload: { testCaseId },
            } = action;
            const msgType8Received = JSON.parse(JSON.stringify(state.msgType8Received));
            if (checkKeyInObject(msgType8Received, testCaseId)) {
                delete msgType8Received[testCaseId];
            }
            return {
                ...state,
                msgType8Received,
            };
        }

        case ActionTypes.GET_SINGLE_TESTSUITE_SUCCESS: {
            const { data } = action.payload;
            const testSuiteWithAccount = checkArrayLength(state.testSuiteWithAccount) ? JSON.parse(JSON.stringify(state.testSuiteWithAccount)) : [];
            const testSuites = checkArrayLength(state.testSuites) ? JSON.parse(JSON.stringify(state.testSuites)) : [];
            const obj = {
                ...state,
                error: null,
                success: null,
                isLoading: false,
                singleTestSuite: data,
            };

            if (checkArrayLength(state.testSuiteWithAccount) && checkObject(data)) {
                state.testSuiteWithAccount.some((suite, index) => {
                    if (Number(data.testSuiteId) === Number(suite.testSuiteId)) {
                        testSuiteWithAccount.splice(index, 1, data);
                        return true;
                    }
                    return false;
                });
                obj.testSuiteWithAccount = testSuiteWithAccount;
            }
            if (checkArrayLength(state.testSuites) && checkObject(data)) {
                state.testSuites.some((suite, index) => {
                    if (Number(data.testSuiteId) === Number(suite.testSuiteId)) {
                        testSuites.splice(index, 1, data);
                        return true;
                    }
                    return false;
                });
                obj.testSuites = testSuites;
            }
            return obj;
        }

        case ActionTypes.CLEAR_WS_DATA: {
            const { testCaseId } = action.payload;
            const failedStepsData = JSON.parse(JSON.stringify(state.failedStepsData));
            const debugStepsData = JSON.parse(JSON.stringify(state.debugStepsData));
            const isWsRunning = JSON.parse(JSON.stringify(state.isWsRunning));
            const wsRunningTestSteps = JSON.parse(JSON.stringify(state.wsRunningTestSteps));
            const wsRunningCaseData = JSON.parse(JSON.stringify(state.wsRunningCaseData));

            delete failedStepsData[testCaseId];
            delete debugStepsData[testCaseId];
            delete isWsRunning[testCaseId];
            delete wsRunningTestSteps[testCaseId];
            delete wsRunningCaseData[testCaseId];
            
            const obj = { ...state, success: null, isLoading: false };

            obj.failedStepsData = failedStepsData;
            obj.debugStepsData = debugStepsData;
            obj.isWsRunning = isWsRunning;
            obj.wsRunningTestSteps = wsRunningTestSteps;
            obj.wsRunningCaseData = wsRunningCaseData;

            return obj;
        }

        case ActionTypes.ON_SAVE_RETRY_API_FAILED: {
            const { testCaseId } = action.payload;
            let wsKeys = JSON.parse(JSON.stringify(state.wsKeys));

            wsKeys = {
                ...wsKeys,
                [testCaseId]: {
                    ...wsKeys[testCaseId],
                    isSaveOrRetryApiFailed: true,
                },
            };

            const obj = { ...state, success: null, isLoading: false };
            obj.error = 'Unable to update test steps';
            obj.wsKeys = wsKeys;

            return obj;
        }

        case ActionTypes.STORE_SUITE_STATE: {
            return { ...state, suiteState: action.payload };
        }

        case ActionTypes.CLEAR_SUITE_STATE: {
            return { ...state, suiteState: {} };
        }

        case ActionTypes.STORE_PROJECT_DETAILS_STATE: {
            return { ...state, projectDetailsState: action.payload };
        }

        case ActionTypes.CLEAR_PROJECT_DETAILS_STATE: {
            return { ...state, projectDetailsState: {} };
        }

        case ActionTypes.DECREASE_PROJECT_RELATED_COUNTS: {
            const obj = { ...state };
            const { payload } = action;
            const selectedProject = checkObject(state.selectedProject) ? JSON.parse(JSON.stringify(state.selectedProject)) : {};

            if (checkObject(selectedProject)) {
                selectedProject[payload.name] -= 1;
                obj.selectedProject = selectedProject;
            }
            return obj;
        }

        case ActionTypes.INCREASE_PROJECT_RELATED_COUNTS: {
            const obj = { ...state };
            const { payload } = action;
            const selectedProject = checkObject(state.selectedProject) ? JSON.parse(JSON.stringify(state.selectedProject)) : {};

            if (checkObject(selectedProject)) {
                selectedProject[payload.name] += 1;
                obj.selectedProject = selectedProject;
            }
            return obj;
        }

        case ActionTypes.GET_REPORTS_SUCCESS: {
            const obj = { ...state };
            const reports = action.payload;
            if (checkArrayLength(reports)) {
                obj.executionReports = reports;
            }
            return obj;
        }

        case ActionTypes.GET_REPORTS_FAILURE: {
            const { message } = action.payload;
            return { ...state, error: message };
        }

        case ActionTypes.SET_EXECUTION_STEPS_BY_ID: {
            const { steps, executionId } = action.payload;
            const executionRunningData = {
                steps,
                data: checkKeyInObject(state.executionRunningData[`${executionId}`], 'data', 'value', {}),
            };
            return {
                ...state,
                executionRunningData: {
                    ...state.executionRunningData,
                    [`${executionId}`]: executionRunningData,
                },
            };
        }

        case ActionTypes.SET_EXECUTION_MSGTYPE_DATA: {
            const dataFromPlatform = isJson(action.payload.msgJson) ? JSON.parse(action.payload.msgJson) : '';
            if (dataFromPlatform) {
                const { executionId } = dataFromPlatform;
                if (executionId) {
                    const executionRunningData = {
                        steps: checkKeyInObject(state.executionRunningData[`${executionId}`], 'steps', 'value', []),
                        data: { testStepObj: action.payload },
                    };
                    return {
                        ...state,
                        executionRunningData: {
                            ...state.executionRunningData,
                            [`${executionId}`]: executionRunningData,
                        },
                    };
                }
            }
            return { ...state };
        }

        case ActionTypes.REMOVE_EXECUTION_STEPS_BY_ID: {
            const executionRunningData = JSON.parse(JSON.stringify(state.executionRunningData));
            delete executionRunningData[`${action.payload.executionId}`];
            return {
                ...state,
                executionRunningData,
            };
        }

        case ActionTypes.UPDATE_SINGLE_EXECUTION_STEP_STATUS: {
            const { instrNum, data, msgType, executionId } = action.payload;
            const testSteps = checkKeyInObject(state.executionRunningData[`${executionId}`], 'steps', 'value', []);

            const currentStepIndex = checkArrayLength(testSteps) ? testSteps.findIndex((step) => `${step.instrNum}` === `${instrNum}`) : -1;
            const currentStep = checkObject(testSteps[currentStepIndex]) ? testSteps[currentStepIndex] : {};
            if (currentStepIndex >= 0) {
                testSteps[currentStepIndex] = { ...currentStep, ...data };
            }

            if (msgType === '5') {
                const parentInstrNum = instrNum.split('.').shift();

                const parentStepIndex = checkArrayLength(testSteps) ? testSteps.findIndex((step) => `${step.instrNum}` === `${parentInstrNum}`) : -1;
                let parentStep = checkObject(testSteps[parentStepIndex]) ? testSteps[parentStepIndex] : {};
                parentStep = { ...parentStep, status: data.status };
                if (parentStepIndex >= 0) {
                    testSteps[parentStepIndex] = parentStep;
                }
            }
            const executionRunningData = {
                steps: testSteps,
                data: checkKeyInObject(state.executionRunningData[`${executionId}`], 'data', 'value', {}),
            };
            return {
                ...state,
                executionRunningData: {
                    ...state.executionRunningData,
                    [`${executionId}`]: executionRunningData,
                },
            };
        }

        case ActionTypes.SET_NEW_STEP_POSITION: {
            const { newStepPosition } = action.payload;
            return { ...state, newStepPosition };
        }

        case ActionTypes.UPDATE_TESTCASE_DATA_ASSOCIATION: {
            const { testCasesIdsToRemove, checkedTestCaseIds, testDataId } = action.payload;
            const testCases = [...state.testCases];
            if (checkArrayLength(testCasesIdsToRemove) && checkArrayLength(testCases)) {
                // remove prevTestData from testCases locally
                testCasesIdsToRemove.forEach((id) => {
                    const singleCaseIndex = testCases.findIndex((testCase) => `${testCase.testCaseId}` === `${id}`);
                    let singleCase = testCases[singleCaseIndex];
                    
                    testCases[singleCaseIndex] = singleCase;
                });
            }
            if (checkArrayLength(checkedTestCaseIds) && checkArrayLength(testCases)) {
                // add data to testCase locally
                checkedTestCaseIds.forEach((id) => {
                    const singleCaseIndex = testCases.findIndex((testCase) => `${testCase.testCaseId}` === `${id}`);
                    const singleCase = testCases[singleCaseIndex];
                    
                    testCases[singleCaseIndex] = singleCase;
                });
            }
            return { ...state, testCases };
        }

        case ActionTypes.TOGGLE_ID_MODAL: {
            const {
                payload: { bool, error, snackbarLink },
            } = action;
            return {
                ...state,
                showRecorderInstalltion: bool,
                snackbarLink,
                error,
            };
        }

        case ActionTypes.GET_ENVIRONMENT_FOR_LOCAL_EXECUTION_FAILURE: {
            const {message} = action.payload
            return {
                ...state,
                environmentError: message
            }
        }

        case ActionTypes.GET_ENVIRONMENT_FOR_LOCAL_EXECUTION_SUCCESS: {
            const {data} = action.payload
            return {
                ...state,
                environmentInfo: data
            }
        }

        case ActionTypes.GET_TUNNEL_ID_FOR_SAUCELABS_FAILURE: {
            const {message} = action.payload
            return {
                ...state,
                environmentError: message
            }
        }

        case ActionTypes.GET_TUNNEL_ID_FOR_SAUCELABS: {
            const {data} = action.payload
            return {
                ...state,
                sauceID: data
            }
        }
        case ActionTypes.IS_GENERATE_BUTTON_CLICKED:
            return {
                ...state,
                isGenerateBtnClicked: {...state.isGenerateBtnClicked, ...action.payload},
            };
            
        case ActionTypes.LOADER_FOR_GENERATE:
            return {
                ...state,
                showLoaderForGenerate: {...state.showLoaderForGenerate, ...action.payload},
        };

        // case ActionTypes.SAVE_USER_VARIABLES:
        // case ActionTypes.RECORD_TEST_CASE:
        // case ActionTypes.GET_GENERATE_TEST_SCRIPT_FAILURE:
        // case ActionTypes.CLONE_TESTSUITE:
        // case ActionTypes.EDIT_TESTSUITS:
        // case ActionTypes.ADD_TESTSUITE:
        // case ActionTypes.DELETE_TESTSCRIPT_FAILURE:
        // case ActionTypes.DELETE_TESTSCRIPT:
        // case ActionTypes.DELETE_TESTDATA_FAILURE:
        // case ActionTypes.GET_EXECUTE_TEST_SCRIPT:
        // case ActionTypes.GET_EXECUTE_TEST_SCRIPT_SUCCESS:
        // case ActionTypes.GET_EXECUTE_TEST_SCRIPT_FAILURE:
        // case ActionTypes.DELETE_TESTDATA:
        // case ActionTypes.GET_ADD_PROJECTS_SUCCESS:
        // case ActionTypes.GET_ADD_PROJECTS_FAILURE:
        // case ActionTypes.GET_PROJECT_BY_NAME:
        //     return {
        //         ...state,
        //     };

        // case ActionTypes.RECORD_TEST_CASE: {
        //     return {
        //         ...state,
        //         showRecorderInstalltion: false,
        //     };
        // }

        default:
            return state;
    }
}
