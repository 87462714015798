import { makeStyles } from '@material-ui/core/styles';
import { THREE_DOT_MENU_CSS, TABLES_CSS, NO_WRAP } from '../../../../common/cssConstants';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    tableRoot: {
        minHeight: 'calc(100vh - 220px)',
    },
    tableContainer: {
        backgroundColor: '#fff',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        overflow: 'hidden',
    },
    actionButtonsDiv: {
        fontSize: THREE_DOT_MENU_CSS.iconFontSize,
        width: '30px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    eyeIconBtn: {
        color: 'rgb(96, 148, 255)',
        '&:hover': THREE_DOT_MENU_CSS.hover,
    },
    // moreIcons: MUI_ACTION_MENU_ICON,

    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '20%',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    shareButton: {
        color: 'rgb(240, 182, 55)',
    },
    tableRowContainer: {
        '& .TableRow': {
            '& .TableCell': {
                fontSize: 12,
            },
        },
    },
    tableRow: {
        backgroundColor: '#f5f5f5',
        borderTop: 'solid 0.5px rgb(151 151 151 / 69%)',
        height: '40px',
        '&:hover': {
            '& .TableCell': {
             //   fontWeight: 'bold',
                backgroundColor: '#fff',
                '& .IconCell': {
                    // '& .iconContainer': {
                        // '& .IconButton': {
                            backgroundColor: 'red'
                        // }
                    // }
                },
            },
            backgroundColor: '#fff',
        },
    },
    hover: {
        minHeight: '40px', 
        backgroundColor: TABLES_CSS.rowColor,
        '&:hover': {
            fontWeight: 'bold',
            backgroundColor: TABLES_CSS.focusRowColor,
        },
    },
    focused: {
        background: '#fff !important',
        '& .TableCell': {
        //    fontWeight: 'bold',
        },
    },
    showTagIcon: {
        display:'contents !important',
        alignItems: 'center',
    },
    caseName: {
        display: "grid",
         '& a': {
            textDecoration: 'none',
            color: '#3768b3 !important',
        },
    },
    listIcon: {
        fontSize: '1.15rem',
        height: '20px !important',
        width: '20px !important',
    },
    activeIcon: {
        color: '#00aead',
        '&:hover': THREE_DOT_MENU_CSS.hover,
    },
    disabledIcon: {
        color: THREE_DOT_MENU_CSS.iconDisableColor,
    },
    noPadding: {
        paddingRight: '5px !important',
    },
    placeNameAndIcon: {
        display: 'flex',
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    noWrap: NO_WRAP,
    wrapperTextContent: {
        width: '100%',
        display: 'block',
    },
    tagIcon: {
        marginLeft: 0,
        fontSize: THREE_DOT_MENU_CSS.faIconFontSize,
    },
    testCaseNameText: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
        width: '100%',
    },
    wrapperCreatedTimeText: {
        fontSize: TABLES_CSS.normalFontSize,
        color: TABLES_CSS.normalFontColor,
    },
    createdTimeText: {
        display: 'inline-block',
        verticalAlign: 'text-bottom',
    },
    fontColor: {
        color: TABLES_CSS.normalFontColor,
    },
    gridContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    actionsBtn: {
        width: '24px',
        height: '24px',
        cursor: 'pointer',
        color: '#141416',
        fontSize: '20px',
    },
    actionsBtnDisabled: {
        cursor: 'not-allowed',
        color: '#bac4ce',
    },
        bitmapImage:{
        width: '17px',
        height: '18px',
        margin: '0.5px 6px 0px 0',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        fontSize: 22,
    },
    moreIcons: {
        fontSize: 22,
    },
    moreIconsBtnDisabled: {
        color: THREE_DOT_MENU_CSS.iconDisableColor,
        //fontSize: '22px',
    },
    moreIconsBtnEnabled : {
        color: THREE_DOT_MENU_CSS.iconColor,
        //fontSize: '22px',
    },
    btnColor: {
        color: '#ff9700',
    },
    disableImg:{
        display:'none',
    },

}));
