/* eslint-disable no-template-curly-in-string */
// import materials
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

// import packages
// import PropTypes from 'prop-types';
import React from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { connect } from 'react-redux';

// import icons
import Cancel from '@material-ui/icons/Close';
import Copy from '@material-ui/icons/FileCopySharp';

import Done from '@material-ui/icons/Done';
import Code from '@material-ui/icons/DeveloperMode';
import FullScreen from '@material-ui/icons/Fullscreen';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import { Popper } from '@material-ui/core';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { HelpOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { getValidUrl, checkKeyInObject, checkArrayLength, getTextProperties } from '../../../../utils/utils';
import Tooltip from '../../../../components/Tooltip';
import DeleteAlertModal from '../../../modal/Delete/DeleteAlertModal';
import { TestStepUtils } from '../../../../utils/TestStepUtils';
import CodeEditorModal from '../Modal/CodeEditorModal';
import { BLACK_FONT, MODAL_CANCEL_BUTTON } from '../../../../common/cssConstants';
import { ProjectActions, ModalActions, TestStepActions, generalModalActions } from '../../../../store/actions';
import { autoSuggestCompStyles } from './AutoSuggestComponentStyles';
import UpdateTestStep from './UpdateTestStep';
import AutoSuggestUtils from '../../../../utils/AutoSuggestUtils';

let isEnterPressed = false; // use to close suggestion box on enter

// Variables and Constants
const NLPsuggestions = AutoSuggestUtils.testInstructionSuggestions;
let Concat_NLPsuggestions = NLPsuggestions;

const DataSuggestions = AutoSuggestUtils.testDataSuggestions;
const EVSuggestions = AutoSuggestUtils.expectedResults;
let getListview = '';
let autosuggestProps_Instr = {};
let autosuggestProps_Data = {};
let autosuggestProps_EV = {};
// functions
function renderInputComponent(inputProps) {
    const { classes, inputRef = () => {}, ref, id, ...other } = inputProps;

    return (
        <TextField
            fullWidth
            multiline
            rowsMax={getListview ? '2' : '3'}
            aria-label={id}
            id={id}
            InputProps={{
                inputRef: (node) => {
                    ref(node);
                    inputRef(node);
                },
                classes: {
                    input: classes.input,
                },
            }}
            {...other}
        />
    );
}

function renderSuggestion_Instr(suggestion, { isHighlighted }) {
    let _query = suggestion.label;
    const element = [];
    let index = 0;
    while (_query !== '') {
        const re = /^(?:([^{}[\]()]*)(?:([{]+[^{}]+[}]+)|([[]+[^[\]]+[\]]+)|([(]+[^()]+[)]+))*)/gi.exec(_query);
        if (re[0]) {
            _query = _query.replace(re[0], '');
        }
        if (re[1]) {
            element.push(
                <span className="inconsolataFontFamilyImportant" key={`${index}_1`} style={{ color: '#1168CD', backgroundColor: '#F39B314d' }}>
                    {re[1]}
                </span>,
            );
        }
        if (re[2]) {
            element.push(
                <span className="inconsolataFontFamilyImportant" style={{ color: BLACK_FONT }} key={`${index}_2`}>
                    {re[2]}
                </span>,
            );
        } else if (re[3]) {
            element.push(
                <span className="inconsolataFontFamilyImportant" style={{ color: BLACK_FONT }} key={`${index}_2`}>
                    {re[3]}
                </span>,
            );
        } else if (re[4]) {
            element.push(
                <span className="inconsolataFontFamilyImportant" style={{ color: BLACK_FONT }} key={`${index}_2`}>
                    {re[4]}
                </span>,
            );
        }
        index++;
        if (index > 5) {
            break;
        }
    }
    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                <span style={{ fontSize: 12 }}>{element}</span>
            </div>
        </MenuItem>
    );
}

function renderSuggestion_Data(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);
    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <span key={String(index)} className="inconsolataFontFamilyImportant" style={{ fontSize: 12, fontWeight: 600 }}>
                            {part.text}
                        </span>
                    ) : (
                        <span key={String(index)} className="inconsolataFontFamilyImportant" style={{ fontSize: 12 }}>
                            {part.text}
                        </span>
                    );
                })}
            </div>
        </MenuItem>
    );
}

function getSuggestions(value, items) {
    const inputValue = value && deburr(value.trim()).toLowerCase();
    const inputLength = inputValue && inputValue.length;
    return inputLength === 0 || isEnterPressed
        ? []
        : checkArrayLength(items) &&
              items.filter((suggestion) => {
                  // it only matches start characters
                  return suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;
              });
}

function getSuggestions_Instr(value) {
    return getSuggestions(value, Concat_NLPsuggestions);
}

function getSuggestions_Data(value) {
    return getSuggestions(value, DataSuggestions);
}

function getSuggestions_EV(value) {
    return getSuggestions(value, EVSuggestions);
}

function getSuggestionValue(suggestion) {
    return suggestion && suggestion.label;
}

const codeArray = ['exec _js', 'exec _py', 'exec _sh', 'exec _bash'];
class AutoSuggestComponent extends React.Component {
    state = {
        expectedResults: this.props.expectedResults || '',
        isCancelEditOpen: false,
        cancelEditAction: () => {},
        isExpectedResult: Boolean(this.props.expectedResults),
        openCodeModal: false,
        suggestions_Data: [],
        // suggestions_EV: [],
        suggestions_Instr: [],
        testData: this.props.testData,
        testInstruction: this.props.testInstruction,
        testDataBackUp: '',
        testInstructionBackUp: '',
    };

    /* Component life cycle start */
    UNSAFE_componentWillMount() {
        this.isClose = false;
        this.isCodeStep =
            typeof this.props.testInstruction === 'string' && codeArray.some((c) => this.props.testInstruction.toLowerCase().includes(c));
    }

    componentDidMount() {
        const { autoSuggestComponentState } = this.props;
        this.openExpectedResult = false;
        this.updateState(autoSuggestComponentState);
    }

    componentDidUpdate() {
        const { isExpectedResult, expectedResults } = this.state;
        if (!isExpectedResult && Boolean(expectedResults) && !this.openExpectedResult) {
            this.openExpectedResult = true;
            this.updateState({ isExpectedResult: true });
        }
    }

    componentWillUnmount() {
        if (!this.isClose) {
            const { testData, testInstruction, testDataBackUp, testInstructionBackUp, isExpectedResult, expectedResults } = this.state;
            const { stepKey, storeRemoveAutoSuggestComponentState } = this.props;
            storeRemoveAutoSuggestComponentState(stepKey, {
                testData,
                testInstruction,
                testDataBackUp,
                testInstructionBackUp,
                isExpectedResult,
                expectedResults,
            });
        }
    }
    /* Component life cycle end */

    onCloseModal = () => {
        setTimeout(() => {
            const { stepKey, storeRemoveAutoSuggestComponentState } = this.props;
            storeRemoveAutoSuggestComponentState(stepKey);
            this.tempTestStepToAdd = {};
            this.isCodeStep = false;
            this.isClose = true;
        }, 300);
    };

    onKeyDown = (e) => {
        if (isEnterPressed) isEnterPressed = false;
        if (e.keyCode === 27) {
            e.stopPropagation();
            e.preventDefault();
            if (this.props.isCancelAllowed) {
                this.props.isCancelAllowed(() => {
                    const { testInstruction, testData } = this.state;
                    this.setState({
                        isCancelEditOpen: true,
                        testDataBackUp: testData,
                        testInstructionBackUp: testInstruction,
                        cancelEditAction: this.cancelEdit,
                    });
                });
            } else {
                this.isCancelAllowed();
            }
        } else if (e.keyCode === 13) {
            isEnterPressed = true;
            this.handleSuggestionsClearRequested_DATA();
            this.handleSuggestionsClearRequested();
        }
    };

    onClose = (isClose = false) => {
        const { tabsData, clearGeneralModal, toggleModal } = this.props;
        toggleModal();
        if (!isClose) {
            this.setState(
                {
                    testInstruction: checkKeyInObject(tabsData, 'testInstruction.state.testInstruction', 'value', this.state.testInstruction),
                    testData: checkKeyInObject(tabsData, 'testData.state.testData', 'value', this.state.testData),
                    expectedResults: checkKeyInObject(tabsData, 'expectedResults.state.expectedResults', 'value', this.state.expectedResults),
                },
                () => {
                    this.updateDataForMultiLineAutoSuggest();
                    clearGeneralModal();
                },
            );
        } else {
            clearGeneralModal();
        }
    };

    onEscape = () => {
        setTimeout(() => {
            const { testInstruction, testData, expectedResults } = this.state;
            const { tabsData } = this.props;
            const tab_testInstruction = checkKeyInObject(tabsData, 'testInstruction.state.testInstruction', 'value');
            const tab_testData = checkKeyInObject(tabsData, 'testData.state.testData', 'value');
            const tab_expectedResults = checkKeyInObject(tabsData, 'expectedResults.state.expectedResults', 'value');
            if (
                (tab_testInstruction !== undefined && testInstruction !== tab_testInstruction) ||
                (tab_testData !== undefined && testData !== tab_testData) ||
                (tab_expectedResults !== undefined && expectedResults !== tab_expectedResults)
            ) {
                this.setState({
                    isCancelEditOpen: true,
                    cancelEditAction: (isConfirm) => {
                        this.setState({
                            isCancelEditOpen: false,
                            cancelEditAction: () => {},
                        });
                        if (isConfirm) {
                            this.onClose(true);
                        }
                    },
                });
            } else {
                this.onClose(true);
            }
        }, 100);
    };

    /* autosuggestProps_Instr function start */
    getSuggestions_Instr_forFlow = (value, flows) => {
        const inputValue = value && deburr(value.trim()).toLowerCase();
        const inputLength = inputValue && inputValue.length;
        const suggestions = [];
        if (!(inputLength === 0 || isEnterPressed)) {
            if (flows && flows.length) {
                flows.forEach((flow) => {
                    const keep = checkKeyInObject(flow, 'name') && flow.name && flow.name.slice(0, inputLength).toLowerCase() === inputValue;

                    if (keep) {
                        suggestions.push({ label: `Run \${${flow.name}}` });
                    }
                });
            }
        }
        return suggestions;
    };

    tempTestStepToAdd = {};

    isCodeStep = false;

    isClose = false;

    updateState = (obj) => {
        this.setState(obj);
    };

    handleSuggestionsFetchRequested = ({ value }) => {
        let isChanged = false;
        if (Array.isArray(value.toLowerCase().match(/^run [/$]{(.+)}.+$/))) {
            isChanged = true;
            const flowName = value.toLowerCase().match(/^run [/$]{(.+)}.+$/)[1];
            NLPsuggestions.forEach((val, ind) => {
                if (val.label && val.label.toLowerCase().indexOf('run ${') === 0) {
                    if (val.label.toLowerCase().indexOf('for [number] times') !== -1) {
                        NLPsuggestions[ind] = { label: `Run \${${flowName}} for [number] times` };
                    } else if (val.label.toLowerCase().indexOf('for all rows') !== -1) {
                        NLPsuggestions[ind] = { label: `Run \${${flowName}} for all rows` };
                    }
                }
            });
        } else if (value && value.trim().toLowerCase().indexOf('run ${') === 0) {
            const { allFlows } = this.props;
            let flowName = '';
            if (
                Array.isArray(
                    value
                        .trim()
                        .toLowerCase()
                        .match(/^run [/$]{(.*)}$/),
                )
            ) {
                flowName = value
                    .trim()
                    .toLowerCase()
                    .match(/^run [/$]{(.*)}$/)[1];
            } else {
                flowName = value.trim().toLowerCase().replace('run ${', '');
            }
            this.setState({
                suggestions_Instr: this.getSuggestions_Instr_forFlow(flowName, allFlows),
            });
            return null;
        }
        if (!isChanged) {
            NLPsuggestions.forEach((val, ind) => {
                if (val.label && val.label.toLowerCase().indexOf('run ${') === 0) {
                    if (val.label.toLowerCase().indexOf('for [number] times') !== -1) {
                        NLPsuggestions[ind] = { label: 'Run ${flow name} for [number] times' };
                    } else if (val.label.toLowerCase().indexOf('for all rows') !== -1) {
                        NLPsuggestions[ind] = { label: 'Run ${flow name} for all rows' };
                    }
                }
            });
        }
        this.setState({
            suggestions_Instr: getSuggestions_Instr(value),
        });
        return false;
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions_Instr: [],
        });
    };
    /* autosuggestProps_Instr function end */

    /* autosuggestProps_Data Functions start */
    handleSuggestionsFetchRequested_DATA = ({ value }) => {
        this.setState({
            suggestions_Data: getSuggestions_Data(value),
        });
    };

    handleSuggestionsClearRequested_DATA = () => {
        this.setState({
            suggestions_Data: [],
        });
    };
    /* autosuggestProps_Data Functions end */

    /* autosuggestProps_EV Functions start */
    handleSuggestionsFetchRequested_EV = ({ value }) => {
        this.setState({
            suggestions_EV: getSuggestions_EV(value),
        });
    };

    handleSuggestionsClearRequested_EV = () => {
        this.setState({
            suggestions_EV: [],
        });
    };
    /* autosuggestProps_EV Functions end */

    handleSearch = (field) => async (event, val) => {
        const newValue = val ? val.newValue : event.target.value;

        // Save instruction and data with ref to use in code editor
        this.tempTestStepToAdd = { ...this.tempTestStepToAdd, [field]: newValue };

        // TODO: md5sum always have string value but null.
        const { md5sum } = this.props;

        this.setState({ [field]: newValue }, this.updateDataForMultiLineAutoSuggest);

        // separate value into two part (Prefix, Suffix)
        const { value } = event.target;

        let prefix = '';
        let suffix = '';

        if (value !== undefined) {
            const trimmed_value = value.trim();

            if (this.hasWhiteSpace(trimmed_value)) {
                const words = trimmed_value.split(' ');

                prefix = words[0];

                if (words && words.length > 0) {
                    for (let i = 1; i < words.length; i++) {
                        suffix = `${suffix} ${words[i]}`;
                    }
                }

                // Get rid of the whitespace at both side.
                suffix = suffix ? suffix.trim() : '';
            } else {
                prefix = trimmed_value;
            }
        }

        if (prefix.toLowerCase().startsWith('s')) {
            prefix = 'select';
        } else if (prefix.toLowerCase().startsWith('e')) {
            prefix = 'entry';
        } else if (prefix.toLowerCase().startsWith('c')) {
            prefix = 'click';
        } else {
            prefix = 'entry'; // It should be All elements
        }
        if (
            (prefix.toLowerCase() === 'select' || prefix.toLowerCase() === 'entry' || prefix.toLowerCase() === 'click') &&
            suffix &&
            suffix.length > 0 &&
            md5sum !== ''
        ) {
            const url = `/page${prefix}/search/${md5sum}/${suffix}`;

            // TODO: Update NLPsuggestions Array by what we've got from getActionableElements

            const NLPsuggestions_appendix = [];
            // above array is the place where all the suggestion should be in (syntax support + founded element)
            let elementsArray = await this.props.getActionableElements(url);
            elementsArray = elementsArray && Object.keys(elementsArray) && Object.keys(elementsArray).length > 0 && elementsArray.payload;

            // initialize & re-assign the autoSuggestion array.
            if (elementsArray && elementsArray.length > 0) {
                for (let j = 0; j < elementsArray.length; j++) {
                    let cmd_prefix = '';

                    switch (prefix.toLowerCase()) {
                        case 'entry':
                            cmd_prefix = 'Enter ';
                            break;

                        case 'select':
                            cmd_prefix = 'Select ';
                            break;

                        case 'click':
                            cmd_prefix = 'Click ';
                            break;
                        default:
                            break;
                    }

                    NLPsuggestions_appendix.push({
                        label: cmd_prefix + elementsArray[j],
                    });

                    if (NLPsuggestions && NLPsuggestions.length) {
                        for (let i = 0; i < NLPsuggestions.length; i++) {
                            NLPsuggestions_appendix.push(NLPsuggestions[i]);
                        }
                    }
                }

                Concat_NLPsuggestions = NLPsuggestions_appendix;
            } else {
                Concat_NLPsuggestions = NLPsuggestions;
            }
        }
    };

    handelSave = () => {
        const { testInstruction, testData, expectedResults } = this.state;
        const { handleEdit, index } = this.props;
        if (testInstruction.trim()) {
            let validTestData = testData?.trim();
            if (testData && testInstruction && testInstruction.trim().toLowerCase() === 'open website') {
                validTestData = getValidUrl(testData);
            }
            handleEdit(testInstruction, validTestData, expectedResults, index);
            this.onCloseModal();
        }
    };

    isCancelAllowed = () => {
        const { testInstruction, testData } = this.state;
        const { index, actionType } = this.props;
        if (testInstruction !== this.props.testInstruction || testData !== this.props.testData) {
            this.setState({
                isCancelEditOpen: true,
                testDataBackUp: testData,
                testInstructionBackUp: testInstruction,
                cancelEditAction: this.cancelEdit,
            });
        } else {
            if (actionType === 'edit') TestStepUtils.onCancelEditStep();
            else TestStepUtils.onCancelAddStep(index, false); // false is passing to aassign in newStep flag
            this.onCloseModal();
        }
    };

    cancelEdit = (isConfirm) => {
        const { index, actionType, onCloseModal } = this.props;
        this.setState({
            isCancelEditOpen: false,
            testData: this.state.testDataBackUp,
            testInstruction: this.state.testInstructionBackUp,
            testDataBackUp: '',
            testInstructionBackUp: '',
            cancelEditAction: () => {},
        });
        if (isConfirm) {
            if (actionType === 'edit') TestStepUtils.onCancelEditStep();
            else TestStepUtils.onCancelAddStep(index, false); // false is passing to aassign in newStep flag
            if (onCloseModal) {
                onCloseModal();
            } else {
                this.onCloseModal();
            }
        }
    };

    hasWhiteSpace = (stnce) => {
        return stnce.indexOf(' ') >= 0;
    };

    // Save current step's data and instruction to copy on next step
    handleCopy = () => {
        const { testStep } = this.props;
        if (testStep.copyData && Object.keys(testStep.copyData).length > 0) {
            this.setState(
                {
                    testInstruction: testStep.copyData.testInstruction ? testStep.copyData.testInstruction : '',
                    testData: testStep.copyData.testData ? testStep.copyData.testData : '',
                    expectedResults: testStep.copyData.expectedResults ? testStep.copyData.expectedResults : '',
                    isExpectedResult: !!testStep.copyData.expectedResults,
                },
                this.updateDataForMultiLineAutoSuggest,
            );
        }
    };

    toggleCodeEditorModal = (flag) => {
        this.setState({ openCodeModal: flag });
    };

    toggleExpectedResultField = (flag) => {
        this.setState({ isExpectedResult: flag }, this.updateDataForMultiLineAutoSuggest);
    };

    toggleUpdateStepModal = () => {
        const { expectedResults, testData, testInstruction } = this.state;
        const { classes, toggleModal } = this.props;

        toggleModal('GeneralModal', null, null, {
            title: 'Update Step',
            closeIcon: (
                <div className={classes.closeIconOval}>
                    <FullscreenExitIcon className={classes.fullScreenExitStyle} aria-label="fullscreenExitIcon" id="fullscreenExitIcon" />
                </div>
            ),
            closeIconAction: () => {
                this.onClose();
            },
            onEscapeAction: () => {
                this.onEscape();
            },
            component: [
                {
                    name: 'Action',
                    content: <UpdateTestStep tabName="testInstruction" value={testInstruction} placeholder="Action" />,
                    buttons: [
                        {
                            name: 'Cancel',
                            variant: 'outlined',
                            style: { ...MODAL_CANCEL_BUTTON },
                            action: () => {
                                this.onEscape();
                            },
                            isDisabled: false,
                        },
                    ],
                },
                {
                    name: 'Data',
                    content: <UpdateTestStep tabName="testData" value={testData} placeholder="Data" />,
                    buttons: [
                        {
                            name: 'Cancel',
                            variant: 'outlined',
                            style: { ...MODAL_CANCEL_BUTTON },
                            action: () => {
                                this.onEscape();
                            },
                            isDisabled: false,
                        },
                    ],
                    activeStepStyleFromProps: classes.testDataTitleStyle,
                },
                {
                    name: 'Expected Result',
                    content: (
                        <UpdateTestStep
                            handleSearch={this.handleSearch}
                            tabName="expectedResults"
                            value={expectedResults}
                            placeholder="Expected Result"
                        />
                    ),
                    buttons: [
                        {
                            name: 'Cancel',
                            variant: 'outlined',
                            style: { ...MODAL_CANCEL_BUTTON },
                            action: () => {
                                this.onEscape();
                            },
                            isDisabled: false,
                        },
                    ],
                    activeStepStyleFromProps: classes.expectedValuesTitleStyle,
                },
            ],
        });
    };

    updateDataForMultiLineAutoSuggest = () => {
        const obj = {
            testInstruction: this.state.testInstruction,
            testData: this.state.testData,
            isExpectedResult: this.state.isExpectedResult,
            expectedResults: this.state.expectedResults,
        };
        this.props.getData(obj);
    };

    render() {
        const { actionType, classes, index, listView, marketYamlData, testStep, userVariables, rootStyle, autoFocus } = this.props;
        const {
            expectedResults,
            isCancelEditOpen,
            isExpectedResult,
            openCodeModal,
            suggestions_Data,
            suggestions_Instr,
            testData,
            testInstruction,
            testDataBackUp,
            testInstructionBackUp,
        } = this.state;
        getListview = listView;

        let suggestions = [];
        suggestions = suggestions_Instr;
        if (!isEnterPressed && testInstruction && testInstruction.toLowerCase().indexOf('market:') === 0) {
            suggestions = [];
            marketYamlData.forEach((key) => {
                suggestions.push({
                    label: `Market:${key}`,
                });
            });
        }
        autosuggestProps_Instr = {
            renderInputComponent,
            suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue,
            renderSuggestion: renderSuggestion_Instr,
        };

        suggestions = [...suggestions_Data];
        if (!isEnterPressed && testData) {
            if (userVariables && Array.isArray(userVariables) && userVariables.length > 0) {
                userVariables.forEach((userVariable) => {
                    const _label = `\${${userVariable.key}}`;
                    if (_label.toLowerCase().indexOf(testData) === 0) {
                        suggestions.push({
                            label: _label,
                        });
                    }
                });
            }
        }

        autosuggestProps_Data = {
            renderInputComponent,
            suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested_DATA,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested_DATA,
            getSuggestionValue,
            renderSuggestion: renderSuggestion_Data,
        };

        autosuggestProps_EV = {
            renderInputComponent,
            suggestions: [],
             onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested_EV,
             onSuggestionsClearRequested: this.handleSuggestionsClearRequested_EV,
            getSuggestionValue,
            renderSuggestion: renderSuggestion_Data,
        };
        const errorsInstr = getTextProperties(isCancelEditOpen ? testInstructionBackUp : testInstruction, ['empty string', 'multi line']);
        const errorsData = getTextProperties(isCancelEditOpen ? testDataBackUp : testData, ['empty string', 'multi line']);
        const errorsExpected = getTextProperties(expectedResults, ['empty string', 'multi line']);
        return (
            <div className={classes.root} style={rootStyle || {}}>
                <div className={`${classes.textField} ${classes.width42}`}>
                    <Autosuggest
                        {...autosuggestProps_Instr}
                        inputProps={{
                            classes,
                            placeholder: 'Action',
                            autoFocus,
                            id: `testInstruction-${index}`,
                            value: isCancelEditOpen ? testInstructionBackUp : testInstruction,
                            onKeyDown: this.onKeyDown,
                            onChange: this.handleSearch('testInstruction'),
                            inputRef: (node) => {
                                this.popperNode = node;
                            },
                        }}
                        theme={{
                            suggestionsList: classes.suggestionsList,
                            suggestion: classes.suggestion,
                            input: `${classes.placeholder} ${checkArrayLength(errorsInstr) ? classes.adjustInfo : ''}`,
                        }}
                        renderSuggestionsContainer={(options) => (
                            <Popper className={classes.zIndex} anchorEl={this.popperNode} open={Boolean(options.children)}>
                                <Paper {...options.containerProps} square className={classes.paperStyle}>
                                    {options.children}
                                </Paper>
                            </Popper>
                        )}
                        className={classes.width42}
                    />
                    {checkArrayLength(errorsInstr) && (
                        <div className={classes.helpOutlineWrapper}>
                            <Tooltip
                                testid="helpTooltipInstr"
                                data={
                                    <div>
                                        {errorsInstr.map((v, i) => (
                                            <div key={i} data-testid="err" className={classes.errorItem}>
                                                {v}
                                            </div>
                                        ))}
                                    </div>
                                }
                                isElement
                            >
                                <HelpOutline className={classes.helpOutline} aria-label="HelpOutlineInstr" id="HelpOutlineInstr" />
                            </Tooltip>
                        </div>
                    )}
                </div>
                <div className={classes.divider} />
                <div style={{ width: isExpectedResult ? '21%' : '42%' }} className={classes.textField}>
                    <Autosuggest
                        {...autosuggestProps_Data}
                        inputProps={{
                            classes,
                            placeholder: 'Data',
                            id: `testData-${index}`,
                            value: isCancelEditOpen ? testDataBackUp : testData,
                            onKeyDown: this.onKeyDown,
                            onChange: this.handleSearch('testData'),
                            inputRef: (node) => {
                                this.popperNode2 = node;
                            },
                        }}
                        theme={{
                            suggestionsList: classes.suggestionsList,
                            suggestion: classes.suggestion,
                            input: `${classes.placeholder} ${checkArrayLength(errorsData) ? classes.adjustInfo : ''}`,
                        }}
                        renderSuggestionsContainer={(options) => (
                            <Popper className={classes.zIndex} anchorEl={this.popperNode2} open={Boolean(options.children)}>
                                <Paper {...options.containerProps} square>
                                    {options.children}
                                </Paper>
                            </Popper>
                        )}
                    />
                    {checkArrayLength(errorsData) && (
                        <div className={classes.helpOutlineWrapper}>
                            <Tooltip
                                testid="helpTooltipData"
                                data={
                                    <div>
                                        {errorsData.map((v, i) => (
                                            <div key={i} data-testid="err" className={classes.errorItem}>
                                                {v}
                                            </div>
                                        ))}
                                    </div>
                                }
                                isElement
                            >
                                <HelpOutline className={classes.helpOutline} aria-label="HelpOutlineData" id="HelpOutlineData" />
                            </Tooltip>
                        </div>
                    )}
                </div>
                {isExpectedResult && (
                    <div style={{ width: 'calc(21% - 16px)' }} className={classes.textField}>
                        <Autosuggest
                            {...autosuggestProps_EV}
                            inputProps={{
                                classes,
                                placeholder: 'Expected Result',
                                value: expectedResults,
                                id: `expectedResults-${index}`,
                                onKeyDown: this.onKeyDown,
                                onChange: this.handleSearch('expectedResults'),
                                inputRef: (node) => {
                                    this.popperNode3 = node;
                                },
                            }}
                            theme={{
                                suggestionsList: classes.suggestionsList,
                                suggestion: classes.suggestion,
                                input: `${classes.placeholder} ${checkArrayLength(errorsExpected) ? classes.adjustInfo : ''}`,
                            }}
                            renderSuggestionsContainer={(options) => (
                                <Popper className={classes.zIndex} anchorEl={this.popperNode3} open={Boolean(options.children)}>
                                    <Paper {...options.containerProps} square>
                                        {options.children}
                                    </Paper>
                                </Popper>
                            )}
                        />
                        {checkArrayLength(errorsExpected) && (
                            <div className={classes.helpOutlineWrapper}>
                                <Tooltip
                                    testid="helpTooltipExpected"
                                    data={
                                        <div>
                                            {errorsExpected.map((v, i) => (
                                                <div key={i} data-testid="err" className={classes.errorItem}>
                                                    {v}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    isElement
                                >
                                    <HelpOutline className={classes.helpOutline} aria-label="HelpOutlineExpected" id="HelpOutlineExpected" />
                                </Tooltip>
                            </div>
                        )}
                    </div>
                )}
                <Tooltip data="Update Step">
                    <FullScreen className={`${classes.iconsGeneralStyle} ${classes.updateIcon}`} onClick={() => this.toggleUpdateStepModal()} />
                </Tooltip>
                {actionType === 'edit' && (
                    <>
                        <Tooltip data="Cancel">
                            <Cancel
                                className={`${classes.iconsGeneralStyle} ${classes.cancelIcon}`}
                                aria-label="cancelIcon"
                                id="cancelIcon"
                                onClick={() => this.isCancelAllowed()}
                            />
                        </Tooltip>
                        <Tooltip data="Save">
                            <Done
                                className={`${classes.iconsGeneralStyle} ${classes.saveIcon} ${
                                    testInstruction.trim() === '' ? classes.disableIcon : classes.enableSaveIcon
                                }`}
                                aria-label="doneIcon"
                                id="doneIcon"
                                onClick={() => {
                                    if (testInstruction.trim()) {
                                        this.handelSave();
                                    }
                                }}
                            />
                        </Tooltip>
                    </>
                )}
                {actionType === 'add' ? (
                    <Tooltip data="Copy">
                        <Copy
                            className={`${classes.iconsGeneralStyle} ${classes.copyIcon} ${
                                testInstruction.trim() === '' && testData.trim() === '' ? classes.enableCopyIcon : classes.disableIcon
                            }`}
                            aria-label="copyIcon"
                            id="copyIcon"
                            onClick={() => (testInstruction.trim() === '' && testData.trim() === '' ? this.handleCopy() : null)}
                        />
                    </Tooltip>
                ) : null}
                <Tooltip data="Code">
                    <Code
                        className={`${classes.iconsGeneralStyle} ${classes.copyIcon} ${
                            this.isCodeStep || actionType === 'add' ? classes.enableCodeIcon : classes.disableIcon
                        }`}
                        aria-label="codeIcon"
                        id="codeIcon"
                        onClick={() => (this.isCodeStep || actionType === 'add' ? this.toggleCodeEditorModal(true) : null)}
                    />
                </Tooltip>
                <Tooltip data="Expected Result">
                    <LocalLibrary
                        className={`${classes.iconsGeneralStyle} ${classes.erIcon}`}
                        aria-label="localLibraryIcon"
                        id="localLibraryIcon"
                        onClick={() => this.toggleExpectedResultField(!isExpectedResult)}
                    />
                </Tooltip>
                {isCancelEditOpen ? (
                    <DeleteAlertModal
                        deleteButton="Danger"
                        open={isCancelEditOpen}
                        modalfor="EditStep"
                        handleClose={this.state.cancelEditAction}
                        msg="Step was modified. Do you want to discard changes"
                        cancelBtnText="Continue Edit"
                        submitBtnText="Discard Changes"
                    />
                ) : null}
                {openCodeModal && (
                    <CodeEditorModal
                        actions={{
                            toggleCodeEditorModal: this.toggleCodeEditorModal,
                            onCloseModal: () => {
                                if (actionType === 'edit') {
                                    this.onCloseModal();
                                }
                            },
                            updateCodeData: (_testInstruction, _testData) => {
                                if (actionType === 'add') {
                                    this.tempTestStepToAdd = {
                                        ...this.tempTestStepToAdd,
                                        testInstruction: _testInstruction,
                                        testData: _testData,
                                    };
                                    this.setState({ testInstruction:_testInstruction, testData:_testData }, this.updateDataForMultiLineAutoSuggest);
                                }
                            },
                        }}
                        data={{
                            codeEditorCallingAction: actionType,
                            stepIndex: index,
                            currentTestStep: { ...testStep, instr: testInstruction, data: testData },
                            tempTestStepToAdd: this.tempTestStepToAdd,
                        }}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        // Account Reducer
        marketYamlData: state.accountReducer.marketYamlData,
        // general modal reducer
        tabsData: state.generalModalReducer.tabsData,
        // flow reducer
        allFlows: state.flowReducer.blocks,
        // selected test case reducer
        actionType: state.selectedTestCaseReducer.actionType,
        // test step reducer
        autoSuggestComponentState: state.testStepReducer.autoSuggestComponentState[props.stepKey],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getActionableElements: (url) => dispatch(ProjectActions.getActionableElements(url)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        clearGeneralModal: (...args) => dispatch(generalModalActions.clearGeneralModal(...args)),
        storeRemoveAutoSuggestComponentState: (...args) => dispatch(TestStepActions.storeRemoveAutoSuggestComponentState(...args)),
    };
};

AutoSuggestComponent.propTypes = {
    getData: PropTypes.func,
    handleEdit: PropTypes.func,
    autoFocus: PropTypes.bool,
    isMultiAutoSuggest: PropTypes.bool,
};

AutoSuggestComponent.defaultProps = {
    getData: () => {},
    handleEdit: () => {},
    autoFocus: true,
    isMultiAutoSuggest: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(autoSuggestCompStyles)(AutoSuggestComponent));
