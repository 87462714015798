import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/styles";
import styles from '../Styles';

import chrome_device_logo from '../../../assets/icons/browsers/chrome.svg';
import firefox_device_logo from '../../../assets/icons/browsers/firefox.svg';
import microsoftedge_device_logo from '../../../assets/icons/browsers/microsoftedge.svg'
import safari_device_logo from '../../../assets/icons/browsers/safari.svg';
import ie_logo from '../../../assets/icons/browsers/ie.svg';
import default_logo from '../../../assets/icons/browsers/default.svg';
import opera_logo from '../../../assets/icons/browsers/opera.svg';

const BrowserIcon = ({browserName,classes}) => {

    const getLogo = (browserName) => {

        if(!browserName) {
            return null
        }

        const browser_map = {
            chrome :chrome_device_logo,
            firefox:firefox_device_logo,
            microsoftedge:microsoftedge_device_logo,
            safari:safari_device_logo,
            ie:ie_logo,
            internetexplorer:ie_logo,
            opera:opera_logo

        }

        return browser_map[browserName] || default_logo
    }

    return (
        
        <React.Fragment>
            {
                browserName &&
                <img width={16} src={getLogo(browserName)}/>

            }
        </React.Fragment>
    )

}

BrowserIcon.propTypes = {
    browserName:PropTypes.string.isRequired
}
export default withStyles(styles)(BrowserIcon)

 