import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { HelpOutline } from '@material-ui/icons';
import { connect } from 'react-redux';
import AutoSuggestGeneralComponent from '../../../../components/AutoSuggest/AutoSuggestGeneralComponent';
import { checkKeyInObject, checkArrayLength, getTextProperties } from '../../../../utils';
import Tooltip from '../../../../components/Tooltip';

const styles = () => ({
    root: {
        width: '450px',
        margin: '0 30px 6px',
        borderRadius: '5px',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.2)',
        '& > div > div > div > div > div > div > textarea': {
            fontFamily: "'Roboto Mono' !important",
            fontSize: 12,
        },
    },
    errorItem: {
        fontSize: 12,
        textTransform: 'capitalize',
    },
    helpOutline: {
        color: '#17a2b8',
        width: '20px',
        margin: '4px 5px 0 5px',
    },
});

const UpdateTestStep = (props) => {
    const { value, tabName, tabsData, classes, placeholder } = props;
    const [val, setValue] = useState(value);

    useEffect(() => {
        const updatedValue = checkKeyInObject(tabsData, `${tabName}.state.${tabName}`, 'value', value);
        setValue(updatedValue);
    }, [tabName, tabsData, value]);

    const errors = getTextProperties(val, ['empty string']);

    return (
        <div className={classes.root}>
            <AutoSuggestGeneralComponent tabName={tabName} value={val} handleChange={setValue} placeholder={placeholder} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                {checkArrayLength(errors) && (
                    <Tooltip
                        testid="helpTooltip"
                        data={
                            <div>
                                {errors.map((v, i) => (
                                    <div key={i} data-testid="err" className={classes.errorItem}>
                                        {v}
                                    </div>
                                ))}
                            </div>
                        }
                        isElement
                    >
                        <HelpOutline className={classes.helpOutline} aria-label="HelpOutline" id="HelpOutline" />
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(UpdateTestStep));
