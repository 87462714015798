import React, { useState } from 'react'
import Tooltip from '../../../components/Tooltip';
import { Button, IconButton, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';


const ExternalGrid = ({ externalGrid, configurationSetting, classes, validateExternalGridTab, isFieldEmpty, callingFrom,setState,environmentDetails,type, externalGridUpdated }) => {

    const [isHovered, setHover] = useState(null)

    if (configurationSetting !== 'lastRunConfiguration') {
        if (environmentDetails?.environments?.length) {
            let externalGridObj = environmentDetails.environments.find((val) => val.environmentType.toLowerCase() === 'remote')
            if (!externalGridObj?.environment?.platformDetails?.length) {
                return <Typography className={classes.noDetail}>Remote configuration not found.</Typography>
            }
        } else{
            return <Typography className={classes.noDetail}>Remote configuration not found.</Typography>
        }
    }

    const addRowForExtGrid = (text) => {
        let _externalGrid = []
        const externalGridDetails = environmentDetails.environments.find((val) => val.environmentType.toLowerCase() === 'remote');
        if (externalGridDetails && externalGridDetails.environment.platformDetails.length) {
            _externalGrid = [{...externalGridDetails.environment.platformDetails[0], environmentType: 'remote'}];
    }
        setState({externalGrid: _externalGrid, noConfigFound: false, isFieldEmpty: false,showRemoteContent: false, externalGridUpdated: true})
    }
    const handleChangeForExtGrid = (e, ind) => {
        let _externalGrid = [...externalGrid]
        _externalGrid[ind][e.target.name] = e.target.value
        if (e.target.name === 'platform' && e.target.value) {setState({extPlatform: false})}
        if (e.target.name === 'platformVersion' && e.target.value) {setState({extPlatformVersion: false})}
        if (e.target.name === 'browser' && e.target.value) {setState({extBrowser: false})}
        if (e.target.name === 'browserVersion' && e.target.value) {setState({extBrowserVersion: false})}
        setState({externalGrid: _externalGrid, externalGridUpdated: true})
    }
    const deleteRowForExtGrid = (ind) => {
        let _externalGrid = [...externalGrid]
        _externalGrid = _externalGrid.filter((_, i) => i !== ind)
        if (_externalGrid.length === 0) {
            setState({showRemoteContent: true})
        }
        setState({externalGrid: _externalGrid, externalGridUpdated: true})
    }

    return (
        <div className={`${classes.extGridContainer} ${classes.content}`}>
            {externalGrid.length ? (externalGrid.map((elem, ind) => (
                <div
                    key={`${ind}-${elem.platform}`}
                    style={{ backgroundColor: ind % 2 === 0 ? '#F5F5F5' : '#fff' }}
                    onMouseEnter={() => setHover(ind)}
                    onMouseLeave={() => setHover(null)}
                >
                    <div style={{ display: 'flex', alignItems: 'flex-end', padding: 5 }}>
                        <div style={{ width: '40%' }}>
                            <Typography variant="subtitle1" className={classes.headingDetails}>
                                Platform {(callingFrom === 'schedule') && (<span className={classes.requiredSpan}>*</span>)}
                            </Typography>
                            <TextField
                                helperText={!elem.platform.length && isFieldEmpty ? '*Required' : ''}
                                fullWidth
                                disabled
                                placeholder={'Enter Platform'}
                                style={configurationSetting === 'lastRunConfiguration' ? { borderBottomColor: '#979797', opacity: '0.8' } : { borderBottomColor: '#979797' }}
                                InputProps={{ autoComplete: 'off' }}
                                className={classes.input}
                                value={elem.platform}
                                onChange={(e) => handleChangeForExtGrid(e, ind)}
                                name="platform"
                            />
                        </div>
                        <div style={{ width: '40%', marginLeft: 50 }}>
                            <Typography variant="subtitle1" gutterBottom className={classes.headingDetails}>
                                Platform Version {(callingFrom === 'schedule') && (<span className={classes.requiredSpan}>*</span>)}
                            </Typography>
                            <TextField
                                helperText={!elem.platformVersion.length && isFieldEmpty ? '*Required' : ''}
                                fullWidth
                                disabled
                                placeholder={'Enter Platform Version'}
                                style={configurationSetting === 'lastRunConfiguration' ? { borderBottomColor: '#979797', opacity: '0.8' } : { borderBottomColor: '#979797' }}
                                InputProps={{ autoComplete: 'off' }}
                                className={classes.input}
                                value={elem.platformVersion}
                                onChange={(e) => handleChangeForExtGrid(e, ind)}
                                name="platformVersion"
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', padding: 5 }} >

                        <div style={{ width: '40%' }}>
                            <Typography variant="subtitle1" gutterBottom className={classes.headingDetails}>
                                Browser {(callingFrom === 'schedule') && (<span className={classes.requiredSpan}>*</span>)}
                            </Typography>
                            <TextField
                                helperText={!elem.browser.length && isFieldEmpty ? '*Required' : ''}
                                fullWidth
                                disabled
                                placeholder={'Enter Browser'}
                                style={configurationSetting === 'lastRunConfiguration' ? { borderBottomColor: '#979797', opacity: '0.8' } : { borderBottomColor: '#979797' }}
                                InputProps={{ autoComplete: 'off' }}
                                className={classes.input}
                                value={elem.browser}
                                onChange={(e) => handleChangeForExtGrid(e, ind, 'browser')}
                                name="browser"
                            />
                        </div>
                        <div style={{ width: '40%', marginLeft: 50 }}>
                            <Typography variant="subtitle1" gutterBottom className={classes.headingDetails}>
                               Browser Version   {/* Browser Version {(callingFrom == 'schedule') && (<span className={classes.requiredSpan}>*</span>)} */}
                            </Typography>
                            <TextField
                               // helperText={!elem.browserVersion.length && isFieldEmpty ? '*Required' : ''}
                                fullWidth
                                disabled
                                placeholder={'Enter Browser Version'}
                                style={configurationSetting === 'lastRunConfiguration' ? { borderBottomColor: '#979797', opacity: '0.8' } : { borderBottomColor: '#979797' }}
                                InputProps={{ autoComplete: 'off' }}
                                className={classes.input}
                                value={elem.browserVersion}
                                onChange={(e) => handleChangeForExtGrid(e, ind)}
                                name="browserVersion"
                            />
                        </div>
                        {isHovered === ind && configurationSetting !== 'lastRunConfiguration' && callingFrom !== 'Generate'  && (
                            <div>
                                <Tooltip data='Delete'>
                                    <IconButton disabled={configurationSetting === 'lastRunConfiguration'} className={classes.iconButtonRoot} onClick={() => deleteRowForExtGrid(ind)}>
                                        <CloseIcon className={`${configurationSetting === 'lastRunConfiguration' ? classes.disableAddBtn : classes.actionIcons}`} />
                                    </IconButton>
                                </Tooltip>

                            </div>
                        )
                        }

                    </div>

                </div>
            ))) :
                (<>
                    {configurationSetting !== 'lastRunConfiguration' && (
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                size="small"
                                className={classes.button}
                                startIcon={<Add />}
                                onClick={() => addRowForExtGrid("add platform")}
                            >
                                Add Platform
                            </Button>
                        </div>
                    )}
                </>
                )}
           
        </div>
    )
}
export default ExternalGrid
