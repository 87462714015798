// import material
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
// import packages
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

// import custom
import GeneralPreferences from './GeneralPreferences';
import SamlSettings from './SamlSettings';
import AccountSettings from './AccountSettings';
import ExecutionSetting from '../../../components/AdminProfile/ExecutionSetting';
import Header from '../../../components/AdminProfile/Header';
import HeaderNav from '../../../components/AdminProfile/HeaderNav';
import { AccountActions, TunnelActions, ModalActions, GeneralActions } from '../../../store/actions';
import { checkArrayLength, checkObject } from '../../../utils/utils';
import config from '../../../config';
import projectServices from '../../../services/projectServices';
import { AccountUtils } from '../../../utils';
import DeleteAlertModal from '../../modal/Delete/DeleteAlertModal';
import { SEGMENT_EVENT } from '../../../common/constants';
import { track } from '../../../services/Segment';
// constants and variables
let isMounted = false;
// styles
const styles = () => ({
    container: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 99px)',
    },
    root: {
        width: 'calc(100% - 40px)',
        margin: '0px 20px',
        marginTop: '20px',
        backgroundColor: 'white',
    },
    tabBoyWrapper: {
        backgroundColor: '#f4f6f7',
        padding: '30px 0 40px 0',
    },
});

class Preferences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyLogoPreivew: null,
            companyLogoFile: null,
            expanded: '',
            execsPerTestcase: 5,
            selectedTab: 0,
            isTunnelOn: false,
            isGlobal: false,
            openDeleteAlertModal: false,
            uploadedYaml: null,
            modalFor: '',
            title: '',
            name: '',
            ...AccountUtils.default,
            //selectedTab: [0,0],
        };
        props.updateTitle('Preferences');
    }

    componentDidMount() {
        const { getAccounts, user } = this.props;
        isMounted = true;
        getAccounts(user.accountId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { singleAccount } = nextProps;
        const { execsPerTestcase } = this.state;
        this.setStateIfComponentMounted({
            isTunnelOn: singleAccount.isTunnelOn || false,
            execsPerTestcase: singleAccount.execsPerTestcase || execsPerTestcase,
        });
    }

    componentWillUnmount() {
        isMounted = false;
        this.props.clearSingleAccount();
    }

    onDrop = (companyLogo) => {
        const preview = checkArrayLength(companyLogo) && companyLogo[0].preview ? companyLogo[0].preview : null;
        this.setStateIfComponentMounted({ companyLogoPreivew: preview, companyLogoFile: companyLogo });
    };

    onDropYaml = (files) => {
        if (checkArrayLength(files)) {
            const file = files[0];
            const fileExtension = `.${file.name.split('.').pop()}`;
            if (this.checkFileExtension(fileExtension, ['.yaml', '.yml'])) {
                this.setStateIfComponentMounted({
                    uploadedYaml: file,
                });
            } else {
                this.props.showSnackBar("Please select '.yaml' file.");
            }
        }
    };

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted) this.setState(obj, callback);
    };

    checkFileExtension = (ext, allowedFileExtensions) => {
        return allowedFileExtensions.indexOf(ext) > -1;
    };

    trackSegmentEvent = (val) => {

        let eventName = '';
        
        switch (val) {
            case 0:
                eventName = 'GENERAL'
                break;
            case 1:
                eventName = 'EXECUTION_ENVIRONMENT'
                break;
            case 2:
                eventName = 'SAML'
                break;
            case 3:
                eventName = 'SETTINGS'
                break;
            default:
                eventName = ''
             
        }

        if(!eventName) {
            return ;
        }
        let segmentEventName = SEGMENT_EVENT.USER_SETTINGS_EVENT[eventName];
        if(segmentEventName) {
            track(segmentEventName,{});
        }
    }

    handleChange = (event, value) => {
        const alreadySelectedTab = this.state.selectedTab;
        if(alreadySelectedTab!==value) {
            this.trackSegmentEvent(value);
            this.setStateIfComponentMounted({ selectedTab: value });
        }
    };

    handleSwitchs = (event) => {
        const obj = { [event.target.value]: event.target.checked };
        this.setStateIfComponentMounted(obj);
    };

    handleSave = () => {
        const {
            updateAccount,
            singleAccount: {
                accountId,
                accountName,
                address,
                customCodeLocation,
                disabledStatus,
                description,
                logoLocation,
                sePort,
                startDate,
                endDate,
            },
        } = this.props;
        const { companyLogoFile, isTunnelOn, execsPerTestcase } = this.state;
        const dataToSend = {
            accountId,
            accountName,
            address,
            customCodeLocation,
            disabledStatus,
            description,
            isTunnelOn,
            logoLocation,
            sePort,
            execsPerTestcase: Number(execsPerTestcase),
            accountStartDate: startDate,
            accountEndDate: endDate,
        };

        const formData = new FormData();
        formData.append('params', JSON.stringify(dataToSend));

        if (companyLogoFile) {
            formData.append('logoFile', companyLogoFile[0]);
        }
        updateAccount(formData);
    };

    handleUploadYaml = (cb = () => {}) => {
        const { uploadYamlFile } = this.props;
        const { uploadedYaml, isGlobal } = this.state;
        if (checkObject(uploadedYaml)) {
            const formData = new FormData();
            formData.append('market', uploadedYaml);
            const onCompelete = () => {
                this.setStateIfComponentMounted({
                    uploadedYaml: null,
                });
            };
            uploadYamlFile(formData, isGlobal, onCompelete, cb);
        } else {
            cb();
        }
    };

    handleDownloadMarketPlace = async (marketData, fileName) => {
        const encodeURL = `data:text/plain;charset=utf-8,${encodeURIComponent(marketData)}`;
        projectServices.downloadTest(null, fileName, encodeURL, this.props.showSnackBar);
    };

    previewMarketPlace = async (marketType) => {
        const { toggleModal } = this.props;
        const filename = `YAML MARKET_${marketType}.yaml`;
        const marketData = await projectServices.getMarket(marketType);
        if (marketData)
            toggleModal('filePreviewModal', null, null, {
                data: marketData,
                name: `YAML MARKET_${marketType}`,
                modalName: 'MarketModal',
                fileName: filename,
                handleDownload: () => {
                    this.handleDownloadMarketPlace(marketData, filename);
                },
            });
    };

    handleExpand = (panel) => {
        this.setStateIfComponentMounted({ expanded: this.state.expanded === panel ? '' : panel });
    };

    handleChangeTextVal = (e, name) => {
        let val = '';
        if (name === 'execsPerTestcase') {
            val = e;
        } else {
            val = e.target.value;
        }
        if (name === 'daysLimit' || name === 'execsPerTestcase') {
            if (!/^[0-9]*$/.test(val)) {
                val = this.state[name];
            }
        }
        this.setStateIfComponentMounted({ [name]: val });
    };

    // handleTabChange = (event, value, key) => {
    //     this.setState({ selectedTab: this.makeStateCopy('selectedTab', value, key) });
    // };

    // closePopper = (selectBox, key) => {
    //     const obj = { anchorEl: this.makeStateCopy('anchorEl', null, key) };
    //     if (selectBox === 'Platform') {
    //         obj.platformDialog = this.makeStateCopy('platformDialog', false, key);
    //     } else if (selectBox === 'Execution') {
    //         obj.showSelectedExecutionModeDialog = false;
    //     } else {
    //         // calling from browser
    //         obj.browserDialog = this.makeStateCopy('browserDialog', false, key);
    //     }
    //     this.setState(obj);
    // };

    // onClickPlatformBrowserContainer = (key, e, callingFrom = 'browser') => {
    //     const { browserDialog, platformDialog, selectedTab } = this.state;
    //     const obj = {
    //         anchorEl: this.makeStateCopy('anchorEl', e && e.currentTarget, key),
    //         showSelectedExecutionModeDialog: false,
    //         selectedTab: this.makeStateCopy('selectedTab', this.ModalType === defaultConfig.executionEngine ? selectedTab[key] : 0, key),
    //     };

    //     if (callingFrom === 'Platform') {
    //         obj.platformDialog = this.makeStateCopy('platformDialog', !platformDialog[key], key);
    //         obj.browserDialog = this.makeStateCopy('browserDialog', false, key);
    //     } else {
    //         // calling from browser
    //         obj.platformDialog = this.makeStateCopy('platformDialog', false, key);
    //         obj.browserDialog = this.makeStateCopy('browserDialog', !browserDialog[key], key);
    //     }

    //     this.setState(obj);
    // };

    handleChangeDateSpan = (val) => {
        this.setStateIfComponentMounted({ selectedDateSpan: val });
    };

    toggleDeleteModal = (flag, asset = 'reports') => {
        const { daysLimit, selectedDateSpan } = this.state;
        let modalfor = '';
        let title = '';
        let name = '';
        if (asset === 'videos') {
            modalfor = 'Videos';
            title = 'Videos';
            name = 'Videos older than 15 days';
        } else {
            modalfor = 'Screenshots/Reports';
            title = 'Screenshots/Reports';
            name = `Screenshots/Reports older than ${daysLimit} ${selectedDateSpan}`;
        }
        this.setState({ modalfor, title, name });
        this.setState({ openDeleteAlertModal: flag });
    };

    removeAssets = (choice, asset = 'reports') => {
        if (choice) {
            if (asset === 'Videos') {
                this.props.deleteBulkVideos();
            } else {
                const { daysLimit, selectedDateSpan } = this.state;
                const daysMap = { days: 1, weeks: 7, months: 30, years: 360 };
                const totalDays = daysLimit && daysLimit > 0 ? daysLimit * daysMap[selectedDateSpan] : 0;
                this.props.deleteBulkObjects(totalDays, daysLimit, selectedDateSpan);
            }
        }

        this.toggleDeleteModal(false);
    };

    renderTab = () => {
        const { isAccountReducerLoading, singleAccount: account, activeUser } = this.props;
        const { daysLimit, expanded, execsPerTestcase, selectedTab, selectedDateSpan, isTunnelOn } = this.state;
        switch (selectedTab) {
            case 0:
                return (
                    <GeneralPreferences
                        actions={{
                            handleSwitchs: this.handleSwitchs,
                            handleSave: this.handleSave,
                            //handleExpand: this.handleExpand,
                            //handleChangeTextVal: this.handleChangeTextVal,
                        }}
                        data={{
                            expanded,
                            isTunnelOn,
                            isAccountReducerLoading,
                            buttonDisabled: account.isTunnelOn === isTunnelOn,
                        }}
                    />
                );
            case 1:
                return (
                    <ExecutionSetting
                    actions={{
                        handleSave: this.handleSave,
                        toggleDeleteModal: this.toggleDeleteModal,
                        handleChangeDateSpan: this.handleChangeDateSpan,
                        handleExpand: this.handleExpand,
                        handleChangeTextVal: this.handleChangeTextVal,
                        //handleTabChange:this.handleTabChange,
                        //closePopper:this.closePopper,
                        //onClickPlatformBrowserContainer:this.onClickPlatformBrowserContainer,
                    }}
                    data={{
                        expanded,
                        execsPerTestcase: `${execsPerTestcase}`,
                        account,
                        daysLimit,
                        isAccountReducerLoading,
                        selectedDateSpan,
                        activeUser,
                    }}
                    />

                    
                );
            // case 2: return <UploadMarketplace
            //     actions={{
            //         handleSwitchs: this.handleSwitchs,
            //         onDrop: this.onDropYaml,
            //         handleSave: this.handleUploadYaml,
            //         previewMarketPlace: this.previewMarketPlace
            //     }}
            //     data={{
            //         selectedFile: uploadedYaml,
            //         isGlobal,
            //     }}
            // />
            case 2:
                return <SamlSettings/>

            case 3:
                return( <AccountSettings
                    actions={{
                        handleSave: this.handleSave,
                        toggleDeleteModal: this.toggleDeleteModal,
                        handleChangeDateSpan: this.handleChangeDateSpan,
                        handleExpand: this.handleExpand,
                        handleChangeTextVal: this.handleChangeTextVal,
                    }}
                    data={{
                        expanded,
                        execsPerTestcase: `${execsPerTestcase}`,
                        account,
                        daysLimit,
                        isAccountReducerLoading,
                        selectedDateSpan,
                        activeUser,
                    }}
                />
                )
            default:
                return null;
        }
    };

    render() {
        const { classes, singleAccount: account } = this.props;
        const { companyLogoPreivew, openDeleteAlertModal, selectedTab, modalfor, title, name } = this.state;
        const companyLogoPreivewLink =
            companyLogoPreivew ||
            (account && account.logoLocation && `${config.api.endpoint}/testCases/previewScreenShot?screenShotURL=${account.logoLocation}`);
        return (
            <div className={classes.container}>
                <div className={classes.root}>
                    <Header
                        companyLogo={companyLogoPreivewLink}
                        companyName={(account && account.accountName) || 'Company Name'}
                        isPreference
                        onDrop={this.onDrop}
                        selectedTab={selectedTab}
                    />
                    <HeaderNav isPreference selectedTab={selectedTab} handleChange={this.handleChange} />
                    <div className={classes.tabBoyWrapper}>{this.renderTab()}</div>
                </div>
                {openDeleteAlertModal && (
                    <DeleteAlertModal
                        deleteButton="Danger"
                        handleClose={(choice) => this.removeAssets(choice, title)}
                        open={openDeleteAlertModal}
                        modalfor={modalfor}
                        title={title}
                        name={name}
                    />
                )}
            </div>
        );
    }
}

Preferences.propTypes = {
    // data
    singleAccount: PropTypes.instanceOf(Object),
    user: PropTypes.instanceOf(Object).isRequired,
    // actions
    getAccounts: PropTypes.func.isRequired,
    updateAccount: PropTypes.func.isRequired,
};

Preferences.defaultProps = {
    singleAccount: {},
};

const mapStateToProps = (state) => ({
    accounts: state.accountReducer.accounts,
    activeUser: state.authReducer.user,
    singleAccount: state.accountReducer.singleAccount,
    user: state.authReducer.user,
    isLoading: state.tunnelReducer.isLoading,
    isAccountReducerLoading: state.accountReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    deleteBulkVideos: (...args) => dispatch(AccountActions.deleteBulkVideos(...args)),
    deleteBulkObjects: (...args) => dispatch(AccountActions.deleteBulkObjects(...args)),
    getAccounts: (accountId) => dispatch(AccountActions.getAccounts(accountId)),
    clearSingleAccount: (accountId) => dispatch(AccountActions.clearSingleAccount(accountId)),
    getAccountById: (...args) => dispatch(AccountActions.getAccountById(...args)),
    updateAccount: (...args) => dispatch(AccountActions.updateAccount(...args)),
    uploadYamlFile: (...args) => dispatch(AccountActions.uploadYamlFile(...args)),
    toggleTunnel: (...args) => dispatch(TunnelActions.toggleTunnel(...args)),
    showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
    toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
    updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Preferences)));
