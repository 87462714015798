import { ActionTypes } from '../constants';

const initialState = {

    autoHideDuration:5000,
    type: 'default',
    open: false,
    message:'',
    showCloseButton:true

};

export default function alertBarReducer(state = initialState, { type, payload }) {

    switch (type) {

        case ActionTypes.TOGGLE_ALERTBAR: {
            return {
                ...state,
                ...payload,
            
            };
        }

        default:
            return state;
    }
}
