import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { TextField, Typography, IconButton } from '@material-ui/core';
import Help from '@material-ui/icons/Help';

// icons
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Tooltip from '../Tooltip';
import { generalModalActions, ProjectActions, ProjectsActions } from '../../store/actions';
import { checkKeyInObject, checkObject, checkArrayLength } from '../../utils';

let isMounted = false;

const styles = () => ({
    root: {
        padding: '0 30px',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 320px)',
    },
    font: {
        fontSize: 12,
        color: '#595959',
    },
    displayButtonIcons: {
        height: 14,
        width: 17,
    },
    tootTipLabel:{
        position: 'static',
        width: '213px',
        height: '48px',
        left: '8px',
        top: '4px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#FFFFFF',
    },
    paperBody:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '4px 8px',
        position: 'relative',
        width: '229px',
        height: '56px',
        left: '6px',
        top: '-90px', 
        backgroundColor: '#1E2127',
        borderRadius: '4px',
    },
    labelColor: {
        '&>label': {
            color: '#595959',
            fontSize: '12px',
            transform: 'translate(0, 1.5px)',
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    showSettings: {
        color: '#1168cd',
        cursor: 'pointer',
        fontSize: 12,
        paddingTop: '6px',
        textDecoration: 'underline',
    },
    iconContainer: {
        borderBottom: '1px solid #959595',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginBottom: 8,
    },
    removeIcon: {
        color: 'red',
    },
    circleIcon: {
        color: '#1068cd',
    },
    circleIconDisabled: {
        color: 'rgba(0, 0, 0, 0.26)',
    },
    testError: {
        color: 'red',
        textAlign: 'left',
        fontSize: 11,
    },
    errorMessage: {
        fontSize: 12,
        color: 'red',
        display: 'block',
    },
    // requiredField: {
    //     fontColor: 'red',
    //     '&:after': {
    //         content: "*",
    //         color: 'red',
    //     },
    // },
});

class ProjectNameAndInfo extends Component {
    constructor() {
        super();
        this.state = {
            projectName: '',
            projectURL: '',
            description: '',
            duplicateName: false,
            duplicateDisableProName: false,
            isValidUrl: false,
            isSubmitDisabled: true,
            ShowMe: false,
            projectXPATH: [{ name: 'xpath-0', value: '' }],
            isFirst: false,
            didChange: false,
            projectAdvanceValues: {},
        };
    }

    componentDidMount() {
        const {
            modalData: { isUpdateProject, project, projectId },
            tabsData,
            getProjectInfo,
            disabledProjects,
            getDisabledProjects,
        } = this.props;
        let previousData = {};
        isMounted = true;
        if (!checkArrayLength(disabledProjects)) {
            // If Disabled Projects are not available in reducer, then get them
            getDisabledProjects('ProjectModal');
        }
        if (isUpdateProject || checkKeyInObject(tabsData.projectInfo, 'state', 'bool')) {
            if (checkKeyInObject(tabsData.projectInfo, 'state', 'bool')) {
                previousData = tabsData.projectInfo.state;
            } else if (isUpdateProject && checkObject(project)) {
                previousData = {
                    projectName: project.projectName || '',
                    projectURL: project.appUrl || '',
                    description: project.description || '',
                };
                const onCompelete = (projectAdvanceValues) => {
                    if (projectAdvanceValues && Object.keys(projectAdvanceValues).length > 0) {
                        const _projectXPATH = [];
                        if (projectAdvanceValues.xpathAttributes) {
                            JSON.parse(projectAdvanceValues.xpathAttributes).forEach((value, index) => {
                                _projectXPATH.push({
                                    name: `xpath-${index}`,
                                    value: `${value}`,
                                });
                            });
                        }
                        // check if projextXpath has any value
                        const hasXPATH = !!(_projectXPATH && _projectXPATH.length > 0);
                        if (isMounted) {
                            this.setStateIfComponentMounted(
                                {
                                    projectAdvanceValues,
                                    projectName: projectAdvanceValues.name,
                                    projectURL: projectAdvanceValues.appUrl,
                                    description: projectAdvanceValues.description,
                                    projectXPATH: hasXPATH ? _projectXPATH : this.state.projectXPATH,
                                },
                                () => {
                                    this.setStateIfComponentMounted({
                                        isErrorInProjectUrl: this.projectURLInError,
                                        isSubmitDisabled: !this.enableSubmit,
                                    });
                                },
                            );
                        } else {
                            const error = this.updateErrorMessage();
                            const obj = {
                                projectAdvanceValues,
                                projectName: projectAdvanceValues.name,
                                projectURL: projectAdvanceValues.appUrl,
                                description: projectAdvanceValues.description,
                                projectXPATH: hasXPATH ? _projectXPATH : this.state.projectXPATH,
                            };
                            this.props.updateTabData('projectInfo', {
                                state: { ...this.state, ...obj, error },
                            });
                        }
                    }
                };
                getProjectInfo(projectId, onCompelete); // for dispatch the Project Info
            }
            this.setStateIfComponentMounted(
                {
                    ...previousData,
                    isFirst: true,
                    didChange: checkKeyInObject(previousData, 'didChange') ? previousData.didChange : false,
                },
                () => {
                    if (this.state.projectName || this.state.projectURL) {
                        this.setStateIfComponentMounted({
                            isErrorInProjectUrl: this.projectURLInError,
                            isSubmitDisabled: !this.enableSubmit,
                        });
                    }
                },
            );
        }
    }

    componentWillUnmount() {
        const { updateTabData } = this.props;
        isMounted = false;
        const error = this.updateErrorMessage();
        updateTabData('projectInfo', { state: { ...this.state, error } });
    }

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted)
            this.setState({ didChange: this.state.isFirst, ...obj }, () => {
                callback();
                const error = this.updateErrorMessage();
                this.props.updateTabData('projectInfo', {
                    state: { ...this.state, error },
                });
            });
    };

    get enableSubmit() {
        return (
            this.state.projectName && Boolean(this.state.projectURL)
            // this.validateUrl(this.state.projectURL)
        );
    }

    get projectURLInError() {
        if (this.state.projectURL.length === 0) {
            return true;
        }
        return false;
        // return !this.validateUrl(this.state.projectURL);
    }

    getProjectName = (projectName) => {
        return typeof projectName === 'string' ? projectName.toLowerCase() : projectName;
    };

    updateErrorMessage = () => {
        const { duplicateDisableProName, duplicateName, isValidUrl, projectName, projectURL } = this.state;
        let error = '';
        if (duplicateDisableProName) {
            error = '*Project name already exists in disabled projects.';
        } else if (duplicateName) {
            error = '*Project name already exists.';
        } else if (!(typeof projectName === 'string' && Boolean(projectName.trim()))) {
            error = '*Please fill out Project name in Name and Info tab';
        } else if (!(typeof projectURL === 'string' && Boolean(projectURL.trim()))) {
            error = '*Please fill out Project URL in Name and Info tab';
        } else if (!isValidUrl) {
            error = '*Project URL is not valid.';
        }
        return error;
    };

    handleNameChange = (event) => {
        const { value } = event.currentTarget;
        const { duplicateName, duplicateDisableProName, projectURL, isSubmitDisabled } = this.state;
        let obj = { projectName: value.trim() };
        let callback = () => {};
        if (duplicateName || duplicateDisableProName) {
            obj = {
                ...obj,
                duplicateName: false,
                duplicateDisableProName: false,
            };
        }
        if (isSubmitDisabled && projectURL) {
            callback = this.checkSameProjectName;
        }
        this.setStateIfComponentMounted(obj, callback);
    };

    handleDescriptionChange = (event) => {
        const { value } = event.currentTarget;
        this.setStateIfComponentMounted({ description: value });
    };

    checkSameProjectName = () => {
        const { enabledProjects, disabledProjects } = this.props;
        const sameProjects = this.isDuplicateName(enabledProjects);
        const sameDisabledProjects = this.isDuplicateName(disabledProjects);
        if (checkArrayLength(sameDisabledProjects)) {
            this.setStateIfComponentMounted({
                isSubmitDisabled: true,
                duplicateDisableProName: true,
            });
        } else if (checkArrayLength(sameProjects)) {
            this.setStateIfComponentMounted({
                isSubmitDisabled: true,
                duplicateName: true,
            });
        } else {
            this.setStateIfComponentMounted({
                isSubmitDisabled: !this.enableSubmit,
                duplicateName: false,
                duplicateDisableProName: false,
            });
        }
    };

    isDuplicateName = (projectsArray) => {
        const {
            modalData: { isUpdateProject, projectId },
        } = this.props;
        const { projectName } = this.state;
        let duplicateProjects = [];
        if (checkArrayLength(projectsArray)) {
            duplicateProjects = projectsArray.filter(
                (project) =>
                    this.getProjectName(project.projectName) === this.getProjectName(projectName) &&
                    (!isUpdateProject || `${project.projectId}` !== `${projectId}`),
            );
        }
        return duplicateProjects;
    };

    // Validation from App URL is remove as per request of GCVFI (issue #1619)

    // customURL = new RegExp('^(https?:\/\/(www[.]{1})?)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*[.]{1}[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$' + // URL
    //     '|^((https?:\/\/(www[.]{1})?)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])[.]{1}?){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(:[0-9]{1,5})?(\/.*)?$' + // OR ip (v4) address
    //     '|^((https?:\/\/(www[.]{1})?)?localhost(:[0-9]{1,5})?(\/.*)?)$', 'i'); // OR localhost

    // emailRegex = /\S+@\S+\.\S+/;

    // validateUrl = (url) => {
    //     const isValidUrl = this.customURL.test(String(url).toLowerCase());
    //     this.setState({ isValidUrl });
    //     return isValidUrl;
    // }

    handleURLChange = (event) => {
        const { value } = event.currentTarget;

        // In callback, check if url is valid and
        // If form ready to submit
        this.setStateIfComponentMounted({ projectURL: value.trim() }, () => {
            this.setStateIfComponentMounted({
                isErrorInProjectUrl: this.projectURLInError,
                isSubmitDisabled: !this.enableSubmit,
            });
        });
    };

    callAdvancedShow = () => {
        this.setStateIfComponentMounted({ ShowMe: !this.state.ShowMe });
    };

    handleXPATHChange = (event, ind) => {
        const { value, name } = event.currentTarget;
        const projectXPATH = [...this.state.projectXPATH];

        projectXPATH.forEach((xpath) => {
            if (xpath.name === name) projectXPATH[ind].value = value;
        });

        this.setStateIfComponentMounted({ projectXPATH });
    };

    addNewField = (e) => {
        e.preventDefault();
        const { projectXPATH } = this.state;

        const _projectXPATH = [...projectXPATH];
        _projectXPATH.push({
            name: `xpath-${projectXPATH.length}`,
            value: '',
        });
        this.setStateIfComponentMounted({ projectXPATH: _projectXPATH });
    };

    removeCurrentField = (name) => (e) => {
        /*eslint-disable */
        e.preventDefault();
        const { projectXPATH } = this.state;
        const _projectXPATH = [...projectXPATH];

        _projectXPATH.some((xpath, index) => {
            if (xpath.name === name) {
                _projectXPATH.splice(index, 1);
                return true;
            }
        });

        this.setStateIfComponentMounted({ projectXPATH: _projectXPATH });
    };

    render() {
        const {
            classes,
            modalData: { isUpdateProject },
            /* disabledProjects, */ tabsData,
        } = this.props;
        const { projectName, projectURL, description, isErrorInProjectUrl, ShowMe, projectXPATH } = this.state;

        let errorMessage = null;
        if (
            checkKeyInObject(tabsData, 'projectInfo.state.duplicateName', 'value', false) ||
            checkKeyInObject(tabsData, 'projectInfo.state.isSubmitDisabled', 'value', false)
        ) {
            errorMessage = (
                <div>
                    <span className={classes.errorMessage}>{checkKeyInObject(tabsData, 'projectInfo.state.error', 'value', '')}</span>
                </div>
            );
        }

        return (
            <div className={classes.root}>
                {errorMessage}
                <TextField
                    autoFocus
                    spellCheck = {false}
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        classes: {
                            input: classes.font,
                        },
                    }}
                    data-testid="projectName"
                    id="projectName"
                    label="Project name"
                    type="text"
                    fullWidth
                    onChange={this.handleNameChange}
                    className={classes.labelColor}
                    onBlur={() => {
                        this.checkSameProjectName();
                    }}
                    value={projectName}
                    error={!projectName}
                    required
                />

                <TextField
                    margin="normal"
                    spellCheck = {false}
                    InputLabelProps={{ shrink: true, 
                        required: false 
                    }}
                    InputProps={{
                        classes: {
                            input: classes.font,
                        },
                    }}
                    data-testid="projectURL"
                    id="projectURL"
                    label = {
                        <div >
                            <span>
                                URL to start testing <span>&#42;</span>
                            </span>
                            <Tooltip 
                            paperBody={classes.paperBody}
                            tootTipLabel={classes.tootTipLabel}
                            data="The URL is starting point for your application under test.">
                                <Help
                                    className={`${classes.moreIcons} ${classes.displayButtonIcons}`}
                                    aria-label="helpIcon"
                                    id="helpIcon"
                                />
                            </Tooltip>
                        </div>
                      }
                    //label="App URL"
                    type="text"
                    fullWidth
                    onChange={this.handleURLChange}
                    className={`${classes.labelColor}`}
                    value={projectURL}
                    required
                    error={isErrorInProjectUrl}
                />

                <TextField
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        classes: {
                            input: classes.font,
                        },
                    }}
                    data-testid="description"
                    id="description"
                    label="Project Description"
                    type="text"
                    fullWidth
                    onChange={this.handleDescriptionChange}
                    className={classes.labelColor}
                    value={description}
                />
                {isUpdateProject || ShowMe ? (
                    <div>
                        {ShowMe ? (
                            <Typography className={classes.showSettings} onClick={this.callAdvancedShow}>
                                Hide Advanced Settings
                            </Typography>
                        ) : null}
                        {projectXPATH &&
                            projectXPATH.map((xpath, index) =>
                                index + 1 !== projectXPATH.length ? (
                                    <div
                                        key={index.toString()}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <TextField
                                            key={index.toString()}
                                            margin="normal"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.font,
                                                },
                                            }}
                                            name={xpath.name}
                                            value={xpath.value}
                                            label="Selector Hints for Testing (optional)"
                                            className={classes.labelColor}
                                            fullWidth
                                            onChange={(e) => this.handleXPATHChange(e, index)}
                                            type="text"
                                        />
                                        <div className={classes.iconContainer}>
                                            <Tooltip data={'Remove'}>
                                                <IconButton color="inherit" aria-label="Remove" onClick={this.removeCurrentField(xpath.name)}>
                                                    <RemoveCircleIcon className={classes.removeIcon} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        key={index.toString()}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <TextField
                                            margin="normal"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.font,
                                                },
                                            }}
                                            name={xpath.name || `xpath-${projectXPATH ? projectXPATH.length - 1 : 0}`}
                                            value={xpath.value}
                                            label="Selector Hints for Testing (optional)"
                                            className={classes.labelColor}
                                            fullWidth
                                            onChange={(e) => this.handleXPATHChange(e, index)}
                                            type="text"
                                        />
                                        <div className={classes.iconContainer}>
                                            <Tooltip data={'Add'}>
                                                <IconButton
                                                    disabled={xpath.value === ''}
                                                    color="inherit"
                                                    aria-label="Add"
                                                    onClick={(e) => this.addNewField(e)}
                                                >
                                                    <AddCircleIcon className={xpath.value === '' ? classes.circleIconDisabled : classes.circleIcon} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                ),
                            )}
                    </div>
                ) : (
                    <Typography className={classes.showSettings} onClick={this.callAdvancedShow}>
                        Show Advanced Settings
                    </Typography>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
        enabledProjects: state.projectReducer.projects,
        disabledProjects: state.projectsReducer.disabledProjects,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
        getProjectInfo: (projectId, onCompelete, onFail) => dispatch(ProjectActions.getProjectInfo(projectId, onCompelete, onFail)),
        getDisabledProjects: (...args) => dispatch(ProjectsActions.getDisabledProjects(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectNameAndInfo));
