import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
// icons
import Close from '@material-ui/icons/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import testStep from './testSteps.gif';
import projectScreen from './projects.gif';
import smartMain from './smartMain.png';
import jobs from './jobs.gif';
import script from './script.gif';
import { checkKeyInObject } from '../../utils';

const screenshots = [testStep, script, jobs, smartMain, projectScreen];
const title = ['Step Editor', 'Project Details - Case Tab', 'Jobs', 'Smart Maintenance', 'Dashboard & Reports'];
const heading = [
    'Express your test cases in simple English or any language',
    'The system will auto-generate appropriate test scripts',
    'You can schedule your test executions at your convenience',
    'Leverage the power of AI to auto-correct or generate new tests',
    'Sit back and control the quality of the product from your couch',
];
const bottomHeadingContent = [
    'Instead of trying to learn specific keywords or computer language, it is often easier to describe what you want to test in your own words and let the machine take care of the translation',
    'Reduce the wait time to get results using manual approaches. Once you describe your intent, we will automatically generate test cases and scripts required for test execution.',
    'No more wait for testing teams or test infrastructure to become available. Use our cloud version, integrations to standard CI/CD tools and schedule your test executions at your convenience.',
    'As your application changes, AutonomIQ is smart enough to generate the corrected version of the cases and scripts. No more maintenance time!!',
    'We provide rich dashboards and reports to help ensure that your are releasing good product and provide planning data to ensure if more resources or investment in quality and testing teams.',
];

const styles = () => ({
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '15px',
    },
    backIcon: {
        marginRight: '5px',
    },
    backToTour: {
        color: '#3B91DF',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    bottomHeading: {
        margin: 0,
        fontSize: '14px',
        color: '#fff',
    },
    bullet: {
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        borderRadius: '50%',
        height: 10,
        width: 10,
        margin: '0px 8px',
    },
    carouselContainer: {
        display: 'flex',
        position: 'absolute',
        bottom: '-30px',
        left: '50%',
        transform: 'translate(-50%)',
    },
    container: {
        width: '760px',
        minWidth: '760px',
    },
    close: {
        color: '#9B9A9A',
        cursor: 'pointer',
    },
    chevron: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        backgroundColor: '#fff',
        borderRadius: '50%',
        width: 45,
        height: 45,
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        cursor: 'pointer',
    },
    chevronDisabled: { cursor: 'default' },
    dialogCloseButton: {
        display: 'flex',
        width: '20%',
        justifyContent: 'flex-end',
    },
    paper: {
        boxShadow: '0 0 45px 0 rgba(0,0,0,0.8)',
        borderRadius: '15px',
        overflow: 'visible',
    },
    disabledIcon: {
        color: '#E6E6E6',
        cursor: 'default',
    },
    enabledIcon: {
        color: '#9B9B9B',
        cursor: 'pointer',
    },
    image: {
        width: '100%',
        height: 'auto',
        border: '1px solid #979797',
        borderRadius: '5px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    },
    title: {
        color: '#6496FF',
        fontSize: 10,
        margin: '8px 0 0 0',
    },
    left: { left: '-75px' },
    lowerContanier: {
        height: '130px',
        backgroundColor: '#3B91DF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0px 40px',
    },
    midContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        maxHeight: 'calc(100vh - 330px)',
        overflowY: 'auto',
        padding: '30px 50px',
    },
    modalHeading: {
        color: '#ffffff',
        fontSize: 22,
        fontWeight: 500,
        lineHeight: '29px',
        margin: '0px 0px 8px 0px',
    },
    parentContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    right: { right: '-75px' },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        borderRadius: '15px',
    },
    selectedBullet: {
        backgroundColor: '#FFFFFF',
    },
    treverseIconStyle: {
        fontSize: 22,
    },
    upperContainer: {
        height: '100px',
    },
    unSelectedBullet: {
        backgroundColor: '#9B9A9A',
    },
});

class BusinessAnalystTourModal extends Component {
    state = { index: 0 };

    handleTraversal = (action, index = -1) => {
        if (index === -1) {
            this.setState((state) => ({ index: action === 'prev' ? state.index - 1 : state.index + 1 }));
        } else {
            this.setState({ index });
        }
    };

    render() {
        const { classes, open, toggleModal, modalData } = this.props;
        const { index } = this.state;
        const carouselBullets = screenshots.map((...val) => {
            return (
                <span
                    aria-hidden
                    onClick={() => this.handleTraversal('', val[1])}
                    key={val[1]}
                    className={[classes.bullet, val[1] === index ? classes.selectedBullet : classes.unSelectedBullet].join(' ')}
                ></span>
            );
        });
        const disableRghtChevron = index === screenshots.length - 1;
        const disableLeftChevron = index === 0;
        return (
            <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" maxWidth="md" classes={{ paper: classes.paper }}>
                <div className={classes.root}>
                    <div
                        aria-hidden
                        className={[classes.chevron, classes.left, disableLeftChevron ? classes.chevronDisabled : ''].join(' ')}
                        onClick={() => (!disableLeftChevron ? this.handleTraversal('prev') : null)}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            aria-label="faChevronLeft"
                            id="faChevronLeft"
                            className={[classes.treverseIconStyle, !disableLeftChevron ? classes.enabledIcon : classes.disabledIcon].join(' ')}
                            style={{ marginRight: 2 }}
                        />
                    </div>
                    <div className={classes.parentContainer}>
                        <div className={classes.container}>
                            <div className={classes.upperContainer}>
                                <div className={classes.actionContainer}>
                                    <div
                                        aria-hidden
                                        className={classes.backToTour}
                                        onClick={() => {
                                            if (checkKeyInObject(modalData, 'modalName')) {
                                                toggleModal(modalData.modalName, '', '', null, false, true);
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} aria-label="faArrowLeft" id="faArrowLeft" className={classes.backIcon} />
                                        <p style={{ margin: 0 }}>back to tour</p>
                                    </div>
                                    <div className={classes.dialogCloseButton}>
                                        <Close
                                            className={classes.close}
                                            aria-label="closeIcon"
                                            id="closeIcon"
                                            onClick={() => {
                                                toggleModal();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.midContainer}>
                                <div>
                                    <img src={screenshots[index]} alt="" className={classes.image} />
                                    <p className={classes.title}>{title[index]}</p>
                                </div>
                            </div>
                            <div className={classes.lowerContanier}>
                                <p className={classes.modalHeading}>{heading[index]}</p>
                                <p className={classes.bottomHeading}>{bottomHeadingContent[index]}</p>
                            </div>
                        </div>
                    </div>
                    <div
                        aria-hidden
                        className={[classes.chevron, classes.right, disableRghtChevron ? classes.chevronDisabled : ''].join(' ')}
                        onClick={() => (!disableRghtChevron ? this.handleTraversal('next') : null)}
                    >
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            aria-label="faChevronRight"
                            id="faChevronRight"
                            className={[classes.treverseIconStyle, !disableRghtChevron ? classes.enabledIcon : classes.disabledIcon].join(' ')}
                            style={{ marginLeft: 2 }}
                        />
                    </div>
                </div>
                <div className={classes.carouselContainer}>{carouselBullets}</div>
            </Dialog>
        );
    }
}

BusinessAnalystTourModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    toggleModal: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(BusinessAnalystTourModal);
