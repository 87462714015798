import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { ProjectActions } from '../../../store/actions';

const styles = (/* theme */) => ({
    dialog: {
        textAlign: 'center',
    },
    circleIcon: {
        color: '#1068cd',
    },
    circleIconDisabled: {
        color: 'rgba(0, 0, 0, 0.26)',
    },
    iconContainer: {
        borderBottom: '1px solid #959595',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginBottom: 8,
    },
    removeIcon: {
        color: 'red',
    },
});

class EditProjectNameModal extends React.Component {
    state = {
        isSubmitDisabled: false,
        projectName: '',
    };

    componentDidMount = () => {
        this.updateState({
            projectName: this.props.modalData.project.projectName,
        });
    };

    // Is the form data valid (enough)?
    get enableSubmit() {
        return this.state.projectName.length > 0;
    }

    updateState = (obj) => {
        this.setState(obj);
    };

    handleNameChange = (event) => {
        const { value } = event.currentTarget;
        // In callback, check if form ready to submit
        this.setState({ projectName: value.trim() }, () => {
            this.setState({ isSubmitDisabled: !this.enableSubmit });
        });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const { projectName } = this.state;
        // let onComplete = () => {
        //     this.setState({
        //         projectName: '',
        //         projectURL: '',
        //         projectXPATH: [{ name: 'xpath-0', value: '' }],
        //     });
        // };
        const projectNameUpdateData = {
            projectName,
            projectId: this.props.modalData.project.projectId,
            accountId: this.props.modalData.user.accountId,
        };
        // await ProjectActions.renameProjectName(projectNameUpdateData);
        const isUpdated = await this.props.renameProjectName(projectNameUpdateData);
        if (isUpdated) {
            this.props.toggleModal();
        }
    };

    render() {
        const { classes, open, handleClose } = this.props;
        const { isSubmitDisabled } = this.state;
        return (
            <div>
                <Dialog disableRestoreFocus open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" className={classes.dialog}>
                        Edit Project Project
                    </DialogTitle>
                    <form onSubmit={this.handleSubmit}>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                id="projectName"
                                aria-label="Project name"
                                type="text"
                                fullWidth
                                value={this.state.projectName}
                                onChange={this.handleNameChange}
                                required
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="secondary" onClick={() => this.props.toggleModal()}>
                                Cancel
                            </Button>
                            <Button variant="contained" type="submit" color="secondary" disabled={isSubmitDisabled}>
                                OK
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    }
}

EditProjectNameModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    modalData: PropTypes.instanceOf(Object).isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        renameProjectName: (data) => dispatch(ProjectActions.renameProjectName(data)),
    };
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(EditProjectNameModal));
