import { ActionTypes } from '../constants';

const initialState = {
    queryValue: null,
    forceClearQuery: false,
    openNav: false,
    openTaskPanel: false,
    disabledQuery: false,
    title: null,
};

export default function generalReducers(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.UPDATE_QUERY:
            return {
                ...state,
                queryValue: action.payload.queryValue ? action.payload.queryValue.trim() : '',
                forceClearQuery: action.payload.forceClearQuery,
            };

        case ActionTypes.ON_DELETE_PROJECTS_SUCCESS:
            return {
                ...state,
                forceClearQuery: true,
                queryValue: null,
            };
        case ActionTypes.TOGGLE_SWIPABLE_DRAWER:
            return {
                ...state,
                openNav: action.payload.open,
            };
        case ActionTypes.TOGGLE_SWIPABLE_TASK_PANEL:
            return {
                ...state,
                openTaskPanel: action.payload.open,
            };
        case ActionTypes.TOGGLE_DISABLE_QUERY:
            return {
                ...state,
                disabledQuery: action.payload.disabledQuery,
            };

        case ActionTypes.UPDATE_TITLE:
            return {
                ...state,
                title: action.payload.title,
            };

        default:
            return state;
    }
}
