/* global chrome */
import baseConfig from '../config';
import { checkKeyInObject, checkArrayLength } from '../utils';

export class PluginService {
    static async detectInstalledPlugin(pluginId) {
        return new Promise((resolve, reject) => {
            try {
                const img = new Image();
                img.src = `chrome-extension://${pluginId}/icons/logo.png`;
                img.onload = () => {
                    img.src = null;
                    resolve(true);
                };
                img.onerror = () => {
                    reject(new Error(false));
                };

                // Previous Implementation

                // chrome.runtime.sendMessage(config.pluginId, { message: 'version' }, (reply) => {
                //     if (reply && reply.version) {
                //         resolve(true);
                //     } else {
                //         reject(false); // eslint-disable-line prefer-promise-reject-errors
                //     }
                // });
            } catch (err) {
                reject(new Error(false));
            }
        });
    }

    static async pauseRecording(bool) {
        return new Promise(() => {
            chrome.runtime.sendMessage(baseConfig.pluginId, { message: bool });
        });
    }

    static async sendMessage(data, pluginId) {
        data.payloadInformation.ip = `${window.location.protocol}//${window.location.hostname}`;
        const locationArray = checkKeyInObject(baseConfig, 'api.endpoint') ? baseConfig.api.endpoint.split('/') : [];
        if (checkArrayLength(locationArray) && !!locationArray[0]) {
            data.payloadInformation.ip = `${locationArray[0]}//${locationArray[2]}`;
        }
        // Previous Implementation

        // chrome.runtime.sendMessage(config.pluginId, {
        //     eventType: 'autonomiq',
        //     payload: data,
        // });
        const evt = new CustomEvent(pluginId, { detail: data });
        document.dispatchEvent(evt);
    }

    static async sendMessageForHTMLTest(data, htmlPath, pluginId) {
        data.payloadInformation.ip = `${window.location.protocol}//${window.location.hostname}`;
        const locationArray = checkKeyInObject(baseConfig, 'api.endpoint') ? baseConfig.api.endpoint.split('/') : [];
        if (checkArrayLength(locationArray) && !!locationArray[0]) {
            data.payloadInformation.ip = `${locationArray[0]}//${locationArray[2]}`;
        }

        const token = localStorage.getItem('token');
        const completePath = data?.payloadInformation?.ip ? `${data.payloadInformation.ip}/platform/v1/gethtmlfile?htmlFileURL=${htmlPath}&token=Bearer ${token}` : `${window.location.protocol}//${window.location.hostname}/platform/v1/gethtmlfile?htmlFileURL=${htmlPath}&token=Bearer ${token}`;
        data.autUrl = completePath;
        data.payloadInformation.autUrl = completePath;
        data.payloadInformation.type = 'stepDebugging';
        const evt = new CustomEvent(pluginId, { detail: data });
        document.dispatchEvent(evt);
    }
}
