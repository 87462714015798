import { ActionTypes } from '../constants';

const initialState = {
    user: {},
    isLoading: false,
    isError: false,
    isLogOut: false,
    isLoggedIn: false,
    error: null,
    emailSendSuccess: null,
    authInfo: {},
};
export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        case ActionTypes.CLEAR_SUCCESS:
            return {
                ...state,
                emailSendSuccess: null,
            };

        case ActionTypes.SIGNUP:
            return {
                ...state,
                user: {},
                isLoading: true,
                emailSendSuccess: false,
            };

        case ActionTypes.SIGNUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                emailSendSuccess: action.payload.message || 'A confirmation link has been sent to your email.',
                isError: false,
                error: null,
            };

        case ActionTypes.SIGNUP_FAILURE:
            return {
                ...state,
                isLoading: false,
                emailSendSuccess: null,
                isError: true,
                error: action.payload.error || action.payload.message,
            };

        case ActionTypes.CONFIRM_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };

        case ActionTypes.SIGNIN_SUCCESS:
            return {
                ...state,
                user: action.payload.data,
                isLoading: false,
                isLoggedIn: true,
                error: null,
                authInfo: {},
            };

        case ActionTypes.SIGNIN_FAILURE:
            return {
                ...state,
                error: action.payload,
                user: action.error,
            };

        case ActionTypes.LOGOUT_FAILURE:
            return {
                ...state,
            };

        case ActionTypes.SET_AUTH_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case ActionTypes.FORGOT_PASSWORD_SUCCESS:
        case ActionTypes.ADMIN_RESET_PASSWORD_EMAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                emailSendSuccess: action.payload,
            };

        case ActionTypes.SIGNIN:
        case ActionTypes.GET_AUTH_DETAILS:
            return {
                ...state,
                isLoading: true,
                error: null,
            };

        case ActionTypes.GET_AUTH_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                authInfo: action.payload,
                error: null,
            };

        case ActionTypes.ADMIN_RESET_PASSWORD_EMAIL_FAILURE:
        case ActionTypes.FORGOT_PASSWORD_FAILURE:
        case ActionTypes.GET_AUTH_DETAILS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.message,
            };

        case ActionTypes.ADMIN_RESET_PASSWORD_EMAIL:
        case ActionTypes.FORGOT_PASSWORD:
        case ActionTypes.LOGOUT:
        case ActionTypes.RESET_PASSWORD:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: action.payload,
                error: null,
            };

        case ActionTypes.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.message,
                success: null,
            };

        case ActionTypes.UPDATE_IS_RELATIVE_DATE: {
            let user = { ...state.user };
            user = { ...user, isRelativeDateFormat: action.payload.isRelativeDateFormat };
            return {
                ...state,
                user,
            };
        }

        default:
            return state;
    }
}
