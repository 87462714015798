// import materials
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';

// import packages
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';

// import icons

// import custom components
import { Hidden } from '@material-ui/core';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import _ from 'lodash';
import SliderImage from '../../Case/SliderImage';
import SortableList from './SortableList';
import { GeneralActions, ModalActions, TestCaseActions, TestStepActions } from '../../../../store/actions';
import { checkKeyInObject, checkArrayLength, addListener, removeAllListeners, updateSliderSetting } from '../../../../utils/utils';
import { TestStepUtils } from '../../../../utils/TestStepUtils';

const sliderHeight = 106;
const sortableListHeight = 302;

// Styles
const styles = (theme) => ({
    root: {
        width: '100%',
        backgroundColor:"white"
    },
    sortableListItemContainer: {
        overflowY: 'auto',
        borderRadius: '0px 0px 5px 5px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        '&>div': {
            minHeight: 'inherit',
            maxHeight: 'inherit',
            width: '100% !important',
            outline: 'none',
            '&>div': {
                backgroundColor: '#fff',
                maxWidth: 'unset !important',
                width: '100% !important',
                maxHeight: 'unset !important',
                borderBottomRightRadius: '5px',
                borderBottomLeftRadius: '5px',
                overflow: 'hidden',
                boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
            },
        },
    },
    sortableListItemContainerForWebkit: {
        '-webkit-transition': 'max-height 0.5s, min-height 0.5s',
        transition: 'max-height 0.5s, min-height 0.5s',
    },
    ulParent: {
        '&>ul': {
            padding: '0px',
            paddingTop: '0px',
            minHeight: `calc(100vh - ${sortableListHeight + sliderHeight}px)`,
        },
    },
    selectedItem: {
        zIndex: '999999999 !important',
        listStyle: 'none',
        boxShadow: '3px 2px 11px 0px #cdcdcd, -3px -2px 11px 0px #cdcdcd',
    },
    sliderMask: {
        position: 'absolute',
        width: 'calc(100% - 20px)',
        zIndex: 100,
        top: 10,
        left: 10,
        height: 'calc(100% - 15px)',
        background: 'rgba(0,0,0,0.4)',
    },
    listViewScreenShotContainer: {
        width: 'calc(100% + 40px)',
        backgroundColor: 'rgb(216, 216, 216)',
        marginLeft: -20,
        overflow: 'hidden',
        [theme.breakpoints.only('xs')]: {
            width: '95%',
            marginLeft: 8,
        },
    },
    subListViewScreenShotContainer: {
        position: 'relative',
        margin: '0 auto',
        width: '90%',
        [theme.breakpoints.only('xs')]: {
            width: '85%',
        },
    },
    paginationContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    screenshotCollapseButton: {
        position: 'absolute',
        height: '30px',
        width: '30px',
        right: -20,
        top: -30,
        borderRadius: '5px 5px 0 0',
        backgroundColor: '#1168CD',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    collapseIcon: {
        height: 20,
        width: 20,
        color: '#FFFFFF',
    },
});

// Variables and constants
const sliderSettings = {
    dots: false,
    speed: 500,
    slidesToScroll: 1,
    infinite: false,
};
class TestStepsTable extends React.Component {
    state = {
        hoverCase: null,
        selectedHoverImage: null,
        isSliderHide: !!JSON.parse(localStorage.getItem('isSliderHide')),
        sortableListItemContainerHeight: 200,
        listPosition: 1,
        resetListPos: false,
        isSelectChange: false,
        slidesToShow: 4,
    };

    componentDidMount() {
        this.calculateHeight();
        addListener(window, 'resize', this.calculateHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!_.isEqual(nextProps.selectedSteps, this.props.selectedSteps)) {
            this.setState({ isSelectChange: !this.state.isSelectChange });
        }
    }

    componentDidUpdate() {
        this.calculateHeight();
        this.updateSliderSetting();
    }

    componentWillUnmount() {
        this.props.queryRemove();
        this.props.clearSnackBar();
        setTimeout(() => {
            this.props.storeRemoveAutoSuggestComponentState();
        }, 300);
        removeAllListeners(window, 'resize');
    }

    slider;
    intervalHover;

    calculateHeight = () => {
        if (
            checkKeyInObject(TestStepUtils.stepsParentRef, 'current.offsetHeight') &&
            TestStepUtils.stepsParentRef.current.offsetHeight !== this.state.sortableListItemContainerHeight
        ) {
            this.setState(
                {
                    sortableListItemContainerHeight: TestStepUtils.stepsParentRef.current.offsetHeight,
                },
                () => {
                    if (this.state.listPosition === 1) {
                        this.setState({ listPosition: undefined }, () => {
                            setTimeout(() => {
                                this.setState({ resetListPos: true });
                                const innerScrollContainer = document.getElementsByClassName('ReactVirtualized__Grid__innerScrollContainer');
                                if (
                                    innerScrollContainer &&
                                    innerScrollContainer[0] &&
                                    innerScrollContainer[0].parentElement &&
                                    innerScrollContainer[0].parentElement.scrollTo
                                ) {
                                    innerScrollContainer[0].parentElement.scrollTo(0, 0);
                                }
                            }, 300);
                        });
                    }
                },
            );
        }
    };

    /* Screen Shot Slider Start */
    updateSliderSetting = () => {
        if (this.props.listView) {
            const slidesToShow = updateSliderSetting(this.state.slidesToShow);
            if (slidesToShow) {
                this.setState({ slidesToShow });
            }
        }
    };
    createSliderImages = () => {
        const { classes, listView, instrNumArray, testSteps, toggleScreenShotModal } = this.props;
        const { hoverCase } = this.state;
        const screenShotsSlides = [];
        let md5sum_value = '';

        instrNumArray.forEach((instrNum, index) => {
            const testStep = testSteps[instrNum];
            if (checkArrayLength(checkKeyInObject(testStep, 'screenshotSmallPaths', 'value', [])) && listView) {
                screenShotsSlides.push(
                    <SliderImage
                        key={`slider-${testStep.instrNum}`}
                        hoverCase={hoverCase}
                        currentIndex={index}
                        alt={testStep.instr}
                        sliderMask={classes.sliderMask}
                        src={TestStepUtils.getPreviewImage(testStep)}
                        handleModal={toggleScreenShotModal}
                        totalLength={instrNumArray.length}
                        selectedHoverImage={this.selectedHoverImage}
                        ind={index}
                    />,
                );
            }
            if (checkKeyInObject(testStep, 'md5sum', 'value', false)) {
                md5sum_value = testStep.md5sum;
            }
        });
        return { screenShotsSlides, md5sum_value };
    };

    changeIndex = (index) => {
        const { listView } = this.props;
        if (listView && this.state.hoverCase !== index) {
            clearTimeout(this.intervalHover);
            this.intervalHover = setTimeout(() => {
                if (index !== null) {
                    if (this.slider) this.slider.slickGoTo(index);
                }
                this.setState({ hoverCase: index });
            }, 200);
        }
    };
    /* Screen Shot Slider End */

    selectedHoverImage = (index) => {
        this.setState({ selectedHoverImage: index });
    };

    toggelShowSlider = () => {
        this.setState(
            {
                isSliderHide: !this.state.isSliderHide,
            },
            () => {
                setTimeout(() => {
                    this.calculateHeight();
                }, 500);
            },
        );
        localStorage.setItem('isSliderHide', JSON.stringify(!this.state.isSliderHide));
    };

    render() {
        const {
            actionFunctions,
            classes,
            instrNumArray,
            listView,
            selectedSteps,
            testCaseId,
            projectId,
            returnData,
            query,
            newStepIndex,
            testSteps,
            autoSuggestCount,
            showEmptyMessageOrLoader,
        } = this.props;
        const {
            selectedHoverImage,
            isSliderHide,
            sortableListItemContainerHeight,
            listPosition,
            resetListPos,
            isSelectChange,
            slidesToShow,
        } = this.state;
        const { screenShotsSlides, md5sum_value } = this.createSliderImages();
        const _instrNumArray = [];
        instrNumArray.forEach((instrNum, index) => {
            const testStep = testSteps[instrNum];
            if (
                !query ||
                +index === +newStepIndex ||
                (query &&
                    checkKeyInObject(testStep, 'instr', 'bool') &&
                    (testStep.instr.toLowerCase().includes(query.toLowerCase()) ||
                        (checkKeyInObject(testStep, 'data', 'bool') &&
                            testStep.data.toLowerCase().includes(query.toLowerCase()) &&
                            !TestStepUtils.isPassword(testStep.instr.toLowerCase()))))
            ) {
                if (instrNumArray[index]) {
                    _instrNumArray.push(instrNumArray[index]);
                }
            }
        });
        // const currentPageTestSteps = _instrNumArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage); uncomment this when apply pagination

        return checkArrayLength(_instrNumArray) ? (
            <div className={classes.ulParent}>
                <List>
                    <ListItem disableGutters style={{ padding: 0 }}>
                        <div className={classes.root}>
                            <div
                                ref={TestStepUtils.stepsParentRef}
                                className={`${classes.sortableListItemContainer} ${listView ? classes.sortableListItemContainerForWebkit : ''}`}
                                style={{
                                    maxHeight: `calc(100vh - ${
                                        sortableListHeight + (listView && screenShotsSlides.length && !isSliderHide ? sliderHeight : 0)
                                    }px)`,
                                    minHeight: `calc(100vh - ${
                                        sortableListHeight + (listView && screenShotsSlides.length && !isSliderHide ? sliderHeight : 0)
                                    }px)`,
                                }}
                            >
                                <SortableList
                                    actionFunctions={actionFunctions}
                                    changeIndex={this.changeIndex}
                                    helperClass={classes.selectedItem}
                                    listView={listView}
                                    md5sum_value={md5sum_value}
                                    projectId={projectId}
                                    returnData={returnData}
                                    selectedSteps={selectedSteps}
                                    selectedHoverImage={selectedHoverImage}
                                    height={sortableListItemContainerHeight}
                                    listPosition={listPosition}
                                    resetListPos={resetListPos}
                                    isSelectChange={isSelectChange}
                                    testCaseId={testCaseId}
                                    autoSuggestCount={autoSuggestCount}
                                    newStepIndex={newStepIndex}
                                    instrNumArray={_instrNumArray}
                                    originalInstrNumArray={instrNumArray}
                                />
                            </div>
                        </div>
                    </ListItem>
                </List>
                {listView ? (
                    <div style={{ position: 'relative' }}>
                        {checkArrayLength(screenShotsSlides) && (
                            <div
                                aria-hidden
                                className={classes.screenshotCollapseButton}
                                onClick={() => {
                                    this.toggelShowSlider();
                                }}
                            >
                                {isSliderHide ? (
                                    <KeyboardArrowUp className={classes.collapseIcon} aria-label="keyboardArrowUpIcon" id="keyboardArrowUpIcon" />
                                ) : (
                                    <KeyboardArrowDown
                                        className={classes.collapseIcon}
                                        aria-label="keyboardArrowDownIcon"
                                        id="keyboardArrowDownIcon"
                                    />
                                )}
                            </div>
                        )}
                        <div className={classes.listViewScreenShotContainer}>
                            <div className={classes.subListViewScreenShotContainer} id="subListViewScreenShotContainer">
                                <Hidden only={['xs', 'sm']}>
                                    <Slider
                                        ref={(_ref) => {
                                            this.slider = _ref;
                                        }}
                                        {...sliderSettings}
                                        slidesToShow={slidesToShow}
                                    >
                                        {screenShotsSlides}
                                    </Slider>
                                </Hidden>
                                <Hidden only={['md', 'lg', 'xl']}>
                                    <Slider
                                        ref={(_ref) => {
                                            this.slider = _ref;
                                        }}
                                        {...sliderSettings}
                                        slidesToShow="1"
                                    >
                                        {screenShotsSlides}
                                    </Slider>
                                </Hidden>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        ) : (
            showEmptyMessageOrLoader(checkArrayLength(instrNumArray) && query ? 'Step not found!' : '')
        );
    }
}

TestStepsTable.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
    return {
        // General Reducer
        query: state.generalReducer.queryValue,
        // Selected Test Case Reducer
        instrNumArray: state.selectedTestCaseReducer.instrNumArray,
        listView: state.selectedTestCaseReducer.listView,
        newStepIndex: state.selectedTestCaseReducer.newStepIndex,
        testSteps: state.selectedTestCaseReducer.testSteps,
        selectedSteps: state.selectedTestCaseReducer.selectedSteps,
        autoSuggestCount: state.selectedTestCaseReducer.autoSuggestCount,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        clearSnackBar: () => dispatch(ModalActions.clearSnackbar()),
        // test case action
        toggleScreenShotModal: (...args) => dispatch(TestCaseActions.toggleScreenShotModal(...args)),
        // test step action
        storeRemoveAutoSuggestComponentState: (...args) => dispatch(TestStepActions.storeRemoveAutoSuggestComponentState(...args)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TestStepsTable));
