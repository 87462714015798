/* eslint-disable no-throw-literal */
import HttpBase from '../../services/HttpBase';

class TunnelMiddlware extends HttpBase {
    /**
     *
     * @param {Object} obj: { flag }
     */
    async toggleTunnel(obj) {
        const result = await this.httpClient.post('/', obj);
        if (result.status === 200) {
            return { status: result.status };
        }
        throw 'Failed to toggle tunnel';
    }
}
export default new TunnelMiddlware();
