import { MUI_ACTION_MENU_ICON, BLACK_FONT } from '../../common/cssConstants';

export const styles = (theme) => ({
    root: {
        borderBottom: '0.5px solid #e9e9e9',
        // borderTop: '0.5px solid #e9e9e9',
        color: '#aaaaaa',
        fontSize: 12,
        lineHeight: '15px',
        opacity: 1,
    },
    tab_avatar: {
        minWidth: '22px',
        height: '22px',
        borderRadius: '22px',
        backgroundColor: '#c4c4c4',
        color: BLACK_FONT,
        textAlign: 'center',
        marginRight: '8px',
        fontSize: '13px',
        lineHeight: 1.9,
        padding: '0 4px',
        display: 'inline-block',
        overflow: 'hidden',
        marginLeft: '10px',
    },
    labelBody: {
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        fontSize: '20px',
        color: '#fff',
    },
    linkTo:{
        //display: 'inline-block',
        fontSize: '20px',
        //borderBottom: '3px solid orange',
        textDecoration: 'none',
    },
    linkToB:{
        display: 'inline-block',
        fontSize: '20px',
        borderBottom: '3px solid orange',
    },
    absLink: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
    },
    tabRoot: {
        borderBottom: '0.5px solid #e9e9e9',
        color: '#aaaaaa',
        fontSize: 12,
        lineHeight: '15px',
        opacity: 1,
        borderTop: '3px solid #494949',
        position: 'relative',
        padding: 0,
        [theme.breakpoints.up('xs')]: {
            minWidth: 'calc(100% / 6)',
            width: 'calc(100% / 6)',
            maxWidth: 'calc(100% / 6)',
        },
        [theme.breakpoints.only('xs')]: {
            minWidth: '150px',
            width: 'auto',
        },
    },
    tabSelected: {
        backgroundColor: '#ffffff',
        borderLeft: '0.5px solid #e9e9e9',
        borderRight: '0.5px solid #e9e9e9',
        borderBottom: '0px solid transparent',
        borderTop: '3px solid #F39B31',
        borderRadius: '5px',
        color: '#4a4a4a',
        fontSize: 12,
        lineHeight: '15px',
    },
    moreIcons: MUI_ACTION_MENU_ICON,
    wrapper: {
        flexDirection: 'row',
        fontSize: '13px',
        justifyContent: 'flex-start',
        paddingLeft: '15px',
    },
    labelContainer: {
        padding: '6px 5px 29px 5px',
    },
    firstTabSelected: {
        backgroundColor: '#ffffff',
        borderLeft: '0px solid transparent',
        borderRight: '0.5px solid #e9e9e9',
        borderBottom: '0px solid transparent',
        borderTop: '3px solid #F39B31',
        borderRadius: '5px',
        color: '#4a4a4a',
        fontSize: 12,
        lineHeight: '15px',
    },
    lastTabSelected: {
        backgroundColor: '#ffffff',
        borderLeft: '0.5px solid #e9e9e9',
        borderRight: '0px solid transparent',
        borderBottom: '0px solid transparent',
        borderTop: '3px solid #F39B31',
        borderRadius: '5px',
        color: '#4a4a4a',
        fontSize: 12,
        lineHeight: '15px',
    },
    projectComponent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'inherit',
    },
    tabsRoot: {
        backgroundColor: '#494949',
        height: 50,
        width: '100%',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    gridContainer: {
        backgroundColor: '#ececec',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    headerContainer: {
        margin: '10px 0 12px 20px',
        width: 'calc(100% - 42px)',
        height: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    },
    stepEditorStyle: {
        margin: '10px 0 0 0',
        width: '100%',
    },
    buttonsContainer: {
        display: 'flex',
        marginLeft: 15,
        maxWidth: '50%',
        overflow: 'hidden',
    },
    projectDetailsContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '63%',
    },
    pipe: {
        borderRight: '1px solid #CDCDCD',
        height: '35px',
    },
    subtContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    threeDotsButton: {
        width: 30,
        height: 30,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    projectNameBg: {
        paddingLeft: 10,
        paddingRight: 10,
        border: '0.5px solid #C4C4C44F',
        backgroundColor: theme.palette.primary.light,
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        border: '2px solid blue',
    },
    routerLink: {
        textDecoration: 'none',
        color: 'initial',
    },
    projectNameContainer: {
        display: 'flex',
        verticalAlign: 'middle',
        minWidth: '150px',
        '&>p': {
            position: 'relative',
            fontSize: '20px',
            fontWeight: '600',
        },
    },
    headerOne: {
        display: 'flex',
        alignItems: 'center',
        // marginTop: '10px',
    },
    headerTwo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '15px',
        // margin: '10px 0 0 0',
        '&>div': {
            float: 'left',
            '&>p': {
                display: 'flex',
                alignItems: 'center',
                '&>span': {
                    display: 'inline-block',
                    marginTop: '2px',
                },
            },
        },
    },
    iconStyle: {
        fontSize: '13px',
        color: theme.palette.primary.dark,
    },
    list_item_icon: {
        marginRight: 5,
    },
    menu_item: {
        height: 10,
        paddingLeft: 2,
    },
    moreIconsBtn: {
        color: '#5F7B96',
        marginLeft: 20,
        marginRight: 10,
    },
    noProjectContainer: {
        padding: theme.spacing(1),
        backgroundColor: '#ececec',
        width: '100%',
    },
    noProject: {
        // paddingLeft: theme.spacing(1),
        paddingTop: 20,
        paddingLeft: 20,
        color: '#424242',
    },
    notExistIcon: {
        color: '#78909c',
        textAlign: 'center',
        marginTop: 12,
    },
    notExistColor: {
        color: '#78909c',
        textAlign: 'center',
        marginBottom: 12,
        fontSize: '20px',
    },
    addProject: {
        color: '#78909c',
        textAlign: 'center',
        marginBottom: 12,
        fontSize: '14px',
    },
    iconColor: {
        // backgroundColor:'#03a9f4',
        color: '#0068d5',
    },
    addIcon: {
        color: '#0068d5',
        position: 'relative',
        top: '2px',
        cursor: 'pointer',
    },
    typography: {
        fontSize: '14px !important',
        fontWeight: 'bold !important',
        color: BLACK_FONT,
    },
    url: {
        fontSize: '12px !important',
        color: '#3B91DF',
    },
    typography_pdhnormalt: {
        fontSize: '12px',
        color: '#3B91DF',
        fontWeight: 'bold',
        marginLeft: 5,
    },
    typographySpan: {
        marginTop: 3,
        width: 36,
        height: 29,
        marginRight: 35,
    },
    textBold: {
        fontWeight: 'bold',
        color: BLACK_FONT,
        fontSize: '12px',
    },
    textNew: {
          width: 'fit-content',
          height: '17.5px',
          margin: '6.5px 0px 5.5px 0px',
          fontFamily: 'HelveticaNeue',
          fontSize: '14px',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          color: '#1c1616',
    },
    
    iconHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 45,
        marginRight: 10,
    },
    piImage: {
        width: '70px',
        height: '80px',
        border: '0px solid green',
        marginTop: '20px',
        margin: 'auto',
    },
    cont: {
        display: 'flex',
        width: '100%',
        minHeight: '50px',
        alignItems: 'flex-end',
        marginBottom: 10,
    },
    divSize: {
        minWidth: 'calc(100% / 6)',
    },
    s3: {
        [theme.breakpoints.only('xs')]: {
            minWidth: 'calc(90% / 2) !important',
        },
    },
    s_3dot: {
        [theme.breakpoints.only('xs')]: {
            minWidth: '10% !important',
        },
    },
    hide: {
        display: 'none',
    },
    message: {
        float: 'left',
        fontSize: 12,
        color: BLACK_FONT,
    },
    boldFont: {
        color: BLACK_FONT,
        fontWeight: 500,
        marginLeft: 3,
    },
    tabWrapper: {
        width: 'calc(100% - 40px)',
        margin: '0 20px',
        paddingRight: '1px',
    },
    noProjectText: {
        backgroundColor: 'white',
        padding: 7,
        border: '1px solid #bdbdbd',
        borderRadius: 3,
        cursor: 'pointer',
        fontSize: '12px',
        marginLeft: '2px',
    },
    extraSpace: {
        marginLeft: '3px',
        marginRight: '2px',
    },
    navigationBackDisabled: {
        cursor: 'default',
        margin: 0,
        width: 20,
        backgroundColor: '#737070',
        color: '#fff',
        borderRadius: '5px',
        boxShadow: 'none',
        textTransform: 'capitalize',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '5px 0px',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#737070',
        },
    },
    navigationBack: {
        cursor: 'pointer',
        margin: 0,
        width: 20,
        backgroundColor: '#0092e6',
        color: '#fff',
        borderRadius: '5px',
        boxShadow: 'none',
        textTransform: 'capitalize',
        justifyContent: 'flex-end',
        padding: '5px 0px',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#0092e6',
            boxShadow: 'none',
        },
    },
    navigationForDisabled: {
        cursor: 'default',
        margin: 0,
        width: 20,
        backgroundColor: '#737070',
        color: '#fff',
        borderRadius: '5px',
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'start',
        textTransform: 'capitalize',
        padding: '5px 0px',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#737070',
        },
    },
    navigationFor: {
        cursor: 'pointer',
        margin: 0,
        width: 20,
        backgroundColor: '#0092e6',
        color: '#fff',
        borderRadius: '5px',
        boxShadow: 'none',
        justifyContent: 'start',
        textTransform: 'capitalize',
        padding: '5px 0px',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#0092e6',
            boxShadow: 'none',
        },
    },
    bitmapImage:{
        width: '17px',
        height: '18px',
        margin: '0.5px 6px 0px 0',
    }
});
