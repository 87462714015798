// import packages
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { List } from 'react-virtualized';

// import custom component
import TestStepsTableData from './TestStepTableData';
import LoadingStepRow from './LoadingRow';
import { TestStepUtils } from '../../../../utils/TestStepUtils';

// Global
let oldrowDataInstructionNumber = [];
let isDiff = 0;
let prevScrollPos = 0;
let scrollDiff = 0;




const SortableList = ({
    actionFunctions,
    listView,
    md5sum_value,
    testCaseId,
    projectId,
    instrNumArray,
    changeIndex,
    returnData,
    // eslint-disable-next-line no-unused-vars
    rowsChange, // using for rerender
    selectedHoverImage,
    originalInstrNumArray,
    autoSuggestCount,
    newStepIndex,
    listPosition,
    resetListPos,
    height,
    isSelectChange,
    expandInstrNo,
    
}) => {
    let dataIndex = -1;
    const rowData = [];
    const rowDataInstructionNumber = [];
    let testingData = null;
    let isMultiAutoSuggestIndex = -1;
    let multiAutoSuggestIndexHeight = 0;
    // const isAnySmartRetryStep = TestCaseUtils.isAnySmartRetryStep(testCaseId); // If code breaks on smart retry re-check from here
    instrNumArray.forEach((instrNum) => {
       if(TestStepUtils.isExpandedRow(instrNum, expandInstrNo) || instrNum.indexOf('.') === -1  || listView) {
        // let isNewSmartRetryStep = ! isAnySmartRetryStep && TestCaseUtils.getNewSmartRetryIndex(testCaseId) === index;
        const ind = originalInstrNumArray.indexOf(instrNum); // to get original index of array even after filtering by query
        const isMultiAutoSuggest = newStepIndex === ind;
        if (isMultiAutoSuggest) {
            multiAutoSuggestIndexHeight = 57 + autoSuggestCount * 37;
            multiAutoSuggestIndexHeight = multiAutoSuggestIndexHeight < 118 ? 118 : multiAutoSuggestIndexHeight;
        }
        testingData = (
          <TestStepsTableData
                key={ind}
                actionFunctions={actionFunctions}
                changeIndex={changeIndex}
                index={ind}
                instrNum={instrNum}
                // isNewSmartRetryStep={isNewSmartRetryStep}
                listView={listView}
                isExpRow = {TestStepUtils.isExpandedRow(instrNum, expandInstrNo)}

                md5sum={md5sum_value}
                projectId={projectId}
                returnData={returnData}
                selectedHoverImage={selectedHoverImage}
                testCaseId={testCaseId}
                height={isMultiAutoSuggest ? multiAutoSuggestIndexHeight : listView ? 37 : 123}
                isSelectChange={isSelectChange}
            />
        );
        dataIndex++;
        rowData[dataIndex] = testingData;
        rowDataInstructionNumber[dataIndex] = instrNum;
        if (isMultiAutoSuggest) {
            isMultiAutoSuggestIndex = dataIndex;
        }
    }
});
    if (!_.isEqual(rowDataInstructionNumber, oldrowDataInstructionNumber)) {
        if (rowDataInstructionNumber.length + (isDiff === 1 ? 2 : 1) !== oldrowDataInstructionNumber.length + isDiff) {
            isDiff = isDiff === 1 ? 2 : 1;
        }
    } else {
        isDiff = isDiff === 3 ? 4 : 3;
    }
    oldrowDataInstructionNumber = rowDataInstructionNumber;
    function getRow({ index, isScrolling, style }) {
        const data = rowData[index];
        if ((isScrolling && !TestStepUtils.isAutoScrolling && scrollDiff > 300) || !resetListPos) {
            return <LoadingStepRow listView={listView} key={`item-${index}`} itemKey={`item-${index}`} style={style} />;
        }
        if (isMultiAutoSuggestIndex !== -1 && isDiff && rowData.length <= index) {
            return null;
        }
        return (
            <span key={`item-${index}`} style={{ ...style, cursor: 'default' }}>
                {data}
            </span>
        );
    }

    const rowHeights = [];
    if (isMultiAutoSuggestIndex !== -1) {
        for (let index = 0; index < rowData.length + isDiff; index++) {
            if (isDiff && rowData.length <= index) {
                rowHeights.push(0);
            } else {
                const _height = isMultiAutoSuggestIndex === index ? multiAutoSuggestIndexHeight + 5 : listView ? 37 : 123;
                rowHeights.push(_height);
            }
        }
    }
    return (
        <List
            rowCount={rowData.length + (isMultiAutoSuggestIndex !== -1 ? isDiff : 0)}
            width={1}
            height={height}
            className="scrollTable"
            rowHeight={
                isMultiAutoSuggestIndex === -1
                    ? listView
                        ? 37
                        : 123
                    : ({ index }) => {
                          return rowHeights[index];
                      }
            }
            rowRenderer={getRow}
            scrollToIndex={listPosition === 1 ? rowData.length + (isMultiAutoSuggestIndex !== -1 ? isDiff : 0) - 1 : undefined}
            onScroll={(param) => {
                scrollDiff = Math.abs(param.scrollTop - prevScrollPos);
                prevScrollPos = param.scrollTop;
            }}
        />
    );
};


const mapStateToProps = (state) => {
    let rowsChange = false;
    if (state.selectedTestCaseReducer.actionType === 'add' || state.selectedTestCaseReducer.actionType === 'delete') {
        rowsChange = true;
    }
    return {
        expandInstrNo: state.selectedTestCaseReducer.expandInstrNo || [],
        rowsChange,
    };
};
export default connect(mapStateToProps, null)(SortableList);
