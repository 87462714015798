import { ActionTypes } from '../constants';
import { TestCaseMiddleware } from '../middleware/TestCaseMiddleware';
import ProjectMiddleware from '../middleware/projectMiddleware';
import ProjectActions from './projectActions';
import { SidebarActions } from './SidebarAction';
import { TestCaseHelpers } from '../../utils';
import store from '../../store'
import {ModalActions} from '../../store/actions'

class TestCaseActions {
    static getTestCasesWithAccount(accountId, userId) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_TESTCASES_WITH_ACCOUNT,
            });
            return TestCaseMiddleware.getTestCasesWithAccount(accountId, userId)
                .then((response) => {
                    return dispatch({
                        type: ActionTypes.GET_TESTCASES_WITH_ACCOUNT_SUCCESS,
                        payload: response,
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_TESTCASES_WITH_ACCOUNT_FAILURE,
                        payload: {
                            error,
                            message: 'Unable to get test cases',
                        },
                    }),
                );
        };
    }

    static deleteTestCase(testCaseIds, from) {
        return async (dispatch) => {
            try {
                await TestCaseMiddleware.deleteTestCase(testCaseIds);
                // I think we don't need now
                // onComplete();
                dispatch(SidebarActions.decreaseSidebarCount('num_of_test_cases'));
                let actionType = ActionTypes.DELETE_TESTCASE_SUCCESS;
                if (from === 'plan') {
                    actionType = ActionTypes.DELETE_TESTCASE_WITH_ACCOUNT_SUCCESS;
                }
                dispatch({
                    type: actionType,
                    payload: {
                        testCaseIds,
                        message: 'Test Case Deleted Successfully',
                    },
                });
                return true;
            } catch (e) {
                // onComplete();
                dispatch({
                    type: ActionTypes.DELETE_TESTCASE_WITH_ACCOUNT_FAILURE,
                    payload: e,
                });
                return false;
            }
        };
    }

    static updateTestCaseName(testCaseData, from) {
        return async (dispatch, getState) => {
            if (from === 'stepEditor') dispatch({ type: ActionTypes.UPDATE_TESTCASE_NAME });
            const response = await ProjectMiddleware.getTestCases(testCaseData.projectId);
            const isDuplicate = TestCaseHelpers.checkDuplicateTestCaseName(response, testCaseData);

            if (!isDuplicate) {
                if (`${testCaseData.testCaseId}` === '-1') {
                    return dispatch({
                        type: ActionTypes.UPDATE_TESTCASE_NAME_SUCCESS,
                        payload: { testCaseData, from },
                    });
                }
                return TestCaseMiddleware.updateTestCaseName(testCaseData)
                    .then(() => {
                        const obj = { type: ActionTypes.UPDATE_TESTCASE_NAME_SUCCESS };

                        if (from === 'projectDetails') {
                            const testCases = [...getState().projectReducer.testCases];
                            obj.payload = {
                                testCaseData,
                                from,
                                testCases: TestCaseHelpers.updateTestCase(testCases, testCaseData),
                                msg: 'Test Case Update Successfully',
                            };
                        } else if (from === 'stepEditor') {
                            obj.payload = {
                                testCaseData,
                                from,
                                msg: 'Test Case Update Successfully',
                            };
                        } else if (from === 'plan') {
                            const testCasesWithAccount = [...getState().testCaseReducer.testCasesWithAccount];
                            obj.type = ActionTypes.UPDATE_TESTCASE_NAME_IN_ACCOUNT_SUCCESS;
                            obj.payload = TestCaseHelpers.updateTestCase(testCasesWithAccount, testCaseData);
                        }

                        return dispatch(obj);
                    })
                    .catch((error) =>
                        dispatch({
                            type: ActionTypes.UPDATE_TESTCASE_NAME_FAILURE,
                            payload: {
                                error,
                                message: 'Unable to update test cases',
                            },
                        }),
                    );
            }
            return dispatch({
                type: ActionTypes.UPDATE_TESTCASE_NAME_FAILURE,
                payload: {
                    message: 'Test case name already exists',
                },
            });
        };
    }

    static mergeTestCases(newTestCaseName, testCaseIds, projectId) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.TESTCASE_MERGE });
            try {
                const result = await TestCaseMiddleware.mergeTestCases(newTestCaseName, testCaseIds, projectId);
                if (result.status === 200) {
                    await dispatch(ProjectActions.getTestCases(projectId));
                    store.dispatch(ModalActions.toggleSnackBar('Test case merged successfully','',true))
                    return dispatch({
                        type: ActionTypes.TESTCASE_MERGE_SUCCESS,
                        payload: { message: '', data: result.data },
                    });
                }

                throw new Error('error');
            } catch (error) {
                dispatch({ type: ActionTypes.TESTCASE_MERGE_FAILURE, payload: { message: 'Failed to merge testcases' } });
                store.dispatch(ModalActions.toggleSnackBar('Unable to merge test cases'))
            }
            return null;
        };
    }

    static disableTestCase(TestCaseIds, type, from) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.DISABLE_TESTCASES,
            });
            return TestCaseMiddleware.disableTestCases(TestCaseIds, type)
                .then((data) => {
                    if (from === 'plan') {
                        dispatch({
                            type: ActionTypes.DISABLE_TESTCASES_WITH_ACCOUNT_SUCCESS,
                            payload: {
                                testCases: data,
                                type,
                                message: `${
                                    type === 0
                                        ? `Test ${TestCaseIds.length > 1 ? 'cases' : 'case'} restored`
                                        : `Test ${TestCaseIds.length > 1 ? 'cases' : 'case'} disabled`
                                } successfully`,
                            },
                        });
                    } else {
                        dispatch({
                            type: ActionTypes.DISABLE_TESTCASES_SUCCESS,
                            payload: {
                                TestCaseIds,
                                type,
                                message: `${
                                    type === 0
                                        ? `Test ${TestCaseIds.length > 1 ? 'cases' : 'case'} restored`
                                        : `Test ${TestCaseIds.length > 1 ? 'cases' : 'case'} disabled`
                                } successfully`,
                            },
                        });
                    }
                    return true;
                })
                .catch((error) => {
                    dispatch({
                        type: ActionTypes.DISABLE_TESTCASES_FAILURE,
                        payload: error,
                    });
                    return false;
                });
        };
    }

    static cloneTestCase(userId, testCaseId, from) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.CLONE_TESTCASE,
            });

            return TestCaseMiddleware.cloneTestCase(userId, testCaseId)
                .then((data) => {
                    dispatch(SidebarActions.increaseSidebarCount('num_of_test_cases'));
                    let actionType = ActionTypes.CLONE_TESTCASE_SUCCESS;
                    if (from === 'plan') {
                        actionType = ActionTypes.CLONE_TESTCASE_WITH_ACCOUNT_SUCCESS;
                    }
                    dispatch({
                        type: actionType,
                        payload: {
                            testCase: data,
                            message: 'Test case cloned succesfully',
                        },
                    });
                    return true;
                })
                .catch(() => {
                    dispatch({
                        type: ActionTypes.CLONE_TESTCASE_WITH_ACCOUNT_FAILURE,
                        payload: 'Failed to clone test case',
                    });
                    return false;
                });
        };
    }

    static moveTestCase(testCaseId, testCaseName, projectId, projectName) {
        return async (dispatch) => {
            try {
                dispatch({
                    type: ActionTypes.EDIT_TESTCASE_WITH_ACCOUNT,
                });
                await TestCaseMiddleware.moveTestCase(testCaseId, testCaseName, projectId);
                dispatch({
                    type: ActionTypes.EDIT_TESTCASE_WITH_ACCOUNT_SUCCESS,
                    payload: {
                        data: { testCaseId, projectId, projectName, testCaseName },
                        message: 'Test Case Updated Successfully',
                    },
                });
                return true;
            } catch (error) {
                dispatch({
                    type: ActionTypes.EDIT_TESTCASE_WITH_ACCOUNT_FAILURE,
                    payload: 'Failed to Update Test Case',
                });
                return false;
            }
        };
    }

    static toggleBlockModal = (isBlockModalOpen, blockData) => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.TOGGLE_BLOCK_MODAL,
                payload: { isBlockModalOpen, blockData },
            });
    };

    static toggleTagModal = (isTagModalOpen) => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.TOGGLE_TAG_MODAL,
                payload: { isTagModalOpen },
            });
    };

    static toggleScreenShotModal = (isScreenshotModalOpen, currentStepScreenshotModalIndex) => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.TOGGLE_SCREENSHOT_MODAL,
                payload: { isScreenshotModalOpen, currentStepScreenshotModalIndex },
            });
    };

    static updateCaseOrder = (order, orderBy) => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.UPDATE_CASE_ORDER,
                payload: { order, orderBy },
            });
    };

    static copiedSuccessFully(message = 'URL copied successfully') {
        return {
            type: ActionTypes.COPIED_SUCCESS,
            payload: message,
        };
    }

    static copyingFailed(message = 'Failed to copy url') {
        return {
            type: ActionTypes.COPYING_FAILED,
            payload: message,
        };
    }
}
export default TestCaseActions;
