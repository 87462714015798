import React from 'react';
// import material
import { Search, Close, HelpOutline } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core';
import Tooltip from '../../../components/Tooltip';
import { checkArrayLength, getTextProperties } from '../../../utils/utils';

const styles = () => ({
    labelColor: {
        margin: '0px',
        width: '100%',
        '&>label': {
            color: '#595959 !important',
            fontSize: '12px',
            transform: 'translate(0, 1.5px)',
            '&>span': {
                color: 'red',
            },
        },
        '&>div': {
            '&>input::-webkit-input-placeholder': {
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: 300,
                color: '#9B9B9B',
                opacity: 1,
            },
            '&>input::-moz-input-placeholder': {
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: 300,
                color: '#9B9B9B',
                opacity: 1,
            },
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    font: {
        fontSize: 12,
        color: '#595959',
    },
    helpOutline: {
        color: '#17a2b8',
        width: '20px',
        margin: '4px 5px 0 5px',
    },
    closeIcon: {
        color: '#8E8E93',
        width: '20px',
    },
    errorItem: {
        fontSize: 12,
        textTransform: 'capitalize',
    },
});

const SearchInput = ({ id, placeholder, onChange, value, clearSearch, style, classes, classStyle }) => {
    const errors = getTextProperties(value, ['empty string']);
    return (
        <TextField
            id={`SearchInput_${id}`}
            aria-label={`SearchInput_${id}`}
            placeholder={placeholder}
            type="text"
            onChange={onChange}
            InputProps={{
                value,
                classes: { input: classes.font },
                startAdornment: (
                    <InputAdornment position="start">
                        <Search style={{ color: '#8E8E93' }} aria-label="searchIcon_JobModal" id="searchIcon_JobModal" />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        {value && (
                            <Close onClick={clearSearch} className={classes.closeIcon} aria-label="closeIcon_JobModal" id="closeIcon_JobModal" />
                        )}
                        {checkArrayLength(errors) && (
                            <Tooltip
                                testid="helpTooltip"
                                data={
                                    <div>
                                        {errors.map((v, i) => (
                                            <div key={i} data-testid="err" className={classes.errorItem}>
                                                {v}
                                            </div>
                                        ))}
                                    </div>
                                }
                                isElement
                            >
                                <HelpOutline className={classes.helpOutline} aria-label="HelpOutline" id="HelpOutline" />
                            </Tooltip>
                        )}
                    </InputAdornment>
                ),
            }}
            style={style}
            className={classStyle || classes.labelColor}
        />
    );
};

SearchInput.defaultProps = {
    placeholder: '',
    value: '',
    style: {},
};
SearchInput.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    clearSearch: PropTypes.func.isRequired,
    value: PropTypes.string,
    style: PropTypes.shape({}),
};

export default withStyles(styles)(SearchInput);
