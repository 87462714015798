import { ActionTypes } from '../constants';

class ImageAction {
    static loadImage(url) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.LOAD_IMAGE,
                payload: { url },
            });
        };
    }

    static imageLoaded(url, image) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.LOAD_IMAGE_SUCCESS,
                payload: {
                    url,
                    image,
                },
            });
        };
    }

    static imageLoadingError(url, error) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.LOAD_IMAGE_FAILURE,
                payload: {
                    url,
                    error,
                },
            });
        };
    }
}

export default ImageAction;
