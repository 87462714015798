import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// icons
import Cancel from '@material-ui/icons/Cancel';
import Refresh from '@material-ui/icons/Refresh';
import FileExcel from 'mdi-material-ui/FileExcel';
import Tooltip from '../Tooltip';

const styles = () => ({
    bar1Determinate: {
        borderRadius: '2px',
        backgroundColor: '#d2d5d9',
    },
    container: {
        marginTop: 1,
        position: 'relative',
    },
    closeButtonStyle: {
        fontSize: 18,
        margin: '5px 5px 0px 0px',
        cursor: 'pointer',
    },
    reloadButtonStyle: {
        color: '#ea0303',
    },
    fileName: {
        padding: '0px 10px',
    },
});

const showName = (step, files) => {
    const { file } = files;
    if (file[step]) return file[step].name;
    if (file.file) return file.file.name;
    return file.name;
};

function FileList(props) {
    const { classes, file, index, removeFile, step } = props;
    return (
        <ListItem key={index} classes={{ container: classes.container }}>
            <ListItemIcon>
                <FileExcel />
            </ListItemIcon>
            <ListItemText
                className={classes.fileName}
                disableTypography
                primary={
                    <Typography noWrap style={{ maxWidth: 158 }}>
                        <Tooltip data={step && showName(step, file)}>{step && showName(step, file)} </Tooltip>
                    </Typography>
                }
                secondary={
                    !file.status || file.status !== 'ignored' ? (
                        <div>
                            {/* <LinearProgress className={classes.bar1Determinate} color="secondary" variant="determinate" value={file.progress} /> */}
                            <Typography>{file.message}</Typography>
                        </div>
                    ) : null
                }
            />
            <ListItemSecondaryAction onClick={() => removeFile(step, index)}>
                {file.status !== 'error' ? (
                    <Cancel className={classes.closeButtonStyle} aria-label="cancelIcon" id="cancelIcon" />
                ) : (
                    <Refresh className={classes.reloadButtonStyle} aria-label="refreshIcon" id="refreshIcon" />
                )}
            </ListItemSecondaryAction>
        </ListItem>
    );
}

FileList.propTypes = {
    file: PropTypes.instanceOf(Object).isRequired,
    index: PropTypes.number.isRequired,
    step: PropTypes.string.isRequired,
    classes: PropTypes.shape({}).isRequired,
    removeFile: PropTypes.func.isRequired,
};

export default withStyles(styles)(FileList);
