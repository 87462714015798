// import materials
import { withStyles } from '@material-ui/core/styles';

// import packages
import React from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import { Popper, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

// import icons
import { checkArrayLength, checkKeyInObject } from '../../utils/utils';
import './styles.css';

// Styles
const styles = () => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    suggestion: {
        display: 'block',
        padding: '7px 12px',
        fontSize: '14px',
        cursor: 'pointer !important',
    },
    textField: {
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: 'auto',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        border: '1px solid #cfd7de',
    },
    placeholder: {
        '&>div>div>:nth-child(3)': { fontSize: 12, paddingLeft: 10 },
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        maxHeight: '200px',
        overflowY: 'auto',
        width: '336px',
    },
});

// Variables and Constants

let Concat_TagSuggestions = [];
// functions
function getSuggestions(value, currentTags) {
    const inputValue = value && deburr(value.trim()).toLowerCase();
    const inputLength = inputValue && inputValue.length;
    return inputLength > 1 && checkArrayLength(Concat_TagSuggestions)
        ? Concat_TagSuggestions.filter((suggestion) => {
              const suggestionLabel = checkKeyInObject(suggestion, 'label') && suggestion.label.toLowerCase();
              // Don't show already tagged value in suggestion
              const _currentTagsCount = {};
              if (checkArrayLength(currentTags)) {
                  currentTags.forEach((item) => {
                      const { key } = item;
                      const tags = item[key];
                      tags[1].forEach((tag) => {
                          if (_currentTagsCount[tag.name]) {
                              _currentTagsCount[tag.name][1]++;
                          } else {
                              _currentTagsCount[tag.name] = [tag, 1];
                          }
                      });
                  });
              }
              const _currentTags = [];
              // eslint-disable-next-line no-restricted-syntax
              for (const tag in _currentTagsCount) {
                  if (_currentTagsCount.hasOwnProperty(tag) && _currentTagsCount[tag][1] === currentTags.length) {
                      _currentTags.push(_currentTagsCount[tag][0]);
                  }
              }
              if (
                  !checkArrayLength(_currentTags) ||
                  (checkArrayLength(_currentTags) && !_currentTags.some((tag) => tag.name && tag.name.toLowerCase() === suggestionLabel))
              ) {
                  return suggestionLabel.includes(inputValue);
              }
              return false;
          })
        : [];
}

function getSuggestionValue(suggestion) {
    return suggestion && suggestion.label;
}

function renderSuggestion(suggestion) {
    return <span>{suggestion.label}</span>;
}
function renderInputComponent(inputProps) {
    const { inputRef = () => {}, ref, ...other } = inputProps;

    return (
        <TextField
            fullWidth
            multiline
            rowsMax="3"
            aria-label="Tag Field"
            id="tagField"
            InputProps={{
                disableUnderline: true,
                inputRef: (node) => {
                    ref(node);
                    inputRef(node);
                },
            }}
            {...other}
        />
    );
}
class AutoSuggestTags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
        };
    }

    onKeyDown = (event) => {
        if (event.keyCode === 13) {
            this.props.actions.onEnterKeyPress();
        }
    };

    /* autosuggestPropsTags function start */
    handleSuggestionsFetchRequested = ({ value }) => {
        const {
            data: { currentTags },
        } = this.props;
        this.setState({
            suggestions: getSuggestions(value, currentTags),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    /* autosuggestPropsTags function end */

    handleSearch = (event, { newValue }) => {
        const {
            data: { tags },
        } = this.props;
        this.props.actions.handleChange(newValue, event);
        if (checkArrayLength(tags)) {
            Concat_TagSuggestions = tags.map((item) => {
                return {
                    label: item.name,
                    id: item.tag_id,
                };
            });
        }
    };

    hasWhiteSpace = (stnce) => {
        return stnce.indexOf(' ') >= 0;
    };

    handleRef = (node) => {
        this.popperNode = node;
    };

    render() {
        const {
            actions,
            classes,
            placeholder,
            // theme,
            data: { val },
            disabled,
            style = {},
        } = this.props;
        const { suggestions } = this.state;
        let _suggestions = [];
        _suggestions = [...suggestions];

        const autosuggestPropsTags = {
            renderInputComponent,
            suggestions: _suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue,
            renderSuggestion,
        };
        return (
            <div className={classes.root}>
                <div className={classes.textField} style={style} ref={this.handleRef}>
                    <Autosuggest
                        {...autosuggestPropsTags}
                        inputProps={{
                            placeholder,
                            value: val,
                            onChange: this.handleSearch,
                            onBlur: actions.setCurrentTag,
                            onKeyDown: this.onKeyDown,
                            disabled,
                        }}
                        theme={{
                            suggestionsList: classes.suggestionsList,
                            suggestion: classes.suggestion,
                            input: classes.placeholder,
                        }}
                        renderSuggestionsContainer={(options) => (
                            <Popper style={{ zIndex: 10000000 }} anchorEl={this.popperNode} open={Boolean(options.children)} placement="bottom-start">
                                <Paper {...options.containerProps} square style={{ borderRadius: 5, boxShadow: '0 2px 7px 0 rgba(0,0,0,0.8)' }}>
                                    {options.children}
                                </Paper>
                            </Popper>
                        )}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(AutoSuggestTags);
