/* eslint-disable no-throw-literal */
import moment from 'moment';
import { checkArrayLength } from '../../utils/utils';
import HttpBase from '../../services/HttpBase';

class UserMiddleware extends HttpBase {
    async getUsers() {
        const result = await this.httpClientUsrmgmt.get('/usermgmt/v1/users/');
        if (result.status === 200 && result.data && result.data.user) {
            return { users: result.data.user };
        }
        throw 'Users Not Found';
    }

    async getUserById(userId) {
        try {
            const result = await this.httpClientUsrmgmt.get(`/usermgmt/v1/users/${userId}`);
            if (result.data && result.data.user) {
                return { data: result.data.user };
            }
            return { data: result.data };
        } catch (error) {
            throw new Error(error);
        }
    }

    async getUserByEmail(email) {
        try {
            const result = await this.httpClientUsrmgmt.get(`/usermgmt/v1/users/email/${email}`);
            if (result.data && result.data.user) {
                return { data: result.data.user };
            }
            return { data: result.data };
        } catch (error) {
            throw new Error(error);
        }
    }

    async getUserByAccountId(accountId) {
        const result = await this.httpClientUsrmgmt.get(`/usermgmt/v1/users/account/${accountId}`);
        if (result.status === 200 && result.data && result.data.user) {
            return { users: result.data.user };
        }
        throw 'Users Not Found';
    }

    // Username and email cannot be empty. Username and email cannot be duplicates.
    // No min/max length requirements (at least not for now).
    // They cannot create the user without selecting a role.
    async createUser(data) {
        try {
            let _data = {
                startDate: `${moment('1-1-2000').format('YYYY-MM-DD')}T00:00:00Z`,
                endDate: `${moment('12-31-3000').format('YYYY-MM-DD')}T00:00:00Z`,
            };
            if (data.accountId) {
                _data = {
                    ..._data,
                    ...data,
                    accountId: data.accountId,
                    host: window.location.origin,
                };
            } else {
                _data = {
                    ..._data,
                    ...data,
                    host: window.location.origin,
                };
            }
            const bodyFormData = new FormData();
            bodyFormData.append('params', JSON.stringify(_data));
            const result = await this.httpClientUsrmgmt.post('usermgmt/v1/users/', bodyFormData);
            if (result.data && result.data.user) {
                return { data: { users: result.data.user } };
            }
            if (result.status === 500) {
                throw 'Please try again.';
            } else if (result.status === 401) {
                throw 'sorry! you are not allowed for this operation';
            }
            return { data: result.data };
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.error) {
                throw error.response.data.error;
            } else {
                throw error;
            }
        }
    }

    async updateUser(data) {
        try {
            const bodyFormData = new FormData();
            bodyFormData.append('params', JSON.stringify(data));
            const result = await this.httpClientUsrmgmt.post(`usermgmt/v1/users/${data.userId}/update `, bodyFormData);
            if (result.data && result.data.user) {
                return { data: { users: result.data.user } };
            }
            return { data: result.data };
        } catch (error) {
            throw new Error(error);
        }
    }

    async deleteUser(userId) {
        try {
            let _userId = userId;
            if (typeof _userId !== 'object') {
                _userId = [_userId];
            }
            const resultPost = await this.httpClientUsrmgmt.post('/usermgmt/v1/users/delete', { user_ids: _userId });
            if (resultPost && resultPost.status === 200) {
                return { data: resultPost.data };
            }
            throw 'Error.';
        } catch (error) {
            throw new Error(error);
        }
    }

    async changesUserStatus(userId, status) {
        try {
            const result = await this.httpClientUsrmgmt.get(`/usermgmt/v1/users/${userId}/${status}/changeStatus`);
            if (result && result.status === 200) {
                return { data: result.data };
            }
            throw 'Error.';
        } catch (error) {
            throw new Error(error);
        }
    }

    async getAccounts() {
        try {
            const result = await this.httpClient.get('/accounts/search');
            if (result && result.data && result.status === 200) {
                return { accounts: result.data };
            }
            throw new Error('Account Not Found');
        } catch (error) {
            throw new Error(error);
        }
    }

    async createAccount(data) {
        try {
            const bodyFormData = new FormData();
            bodyFormData.append('params', JSON.stringify({ ...data }));
            // Creating account and user both with one endpoint
            const result = await this.httpClient.post('accountanduser', bodyFormData);
            if (result && result.data && result.data.accounts) {
                return {
                    data: { accounts: result.data.accounts },
                    status: result.status,
                };
            }
            if (result.status === 500) {
                throw 'Please try again.';
            } else if (result.status === 401) {
                throw 'sorry! you are not allowed for this operation';
            }
            return {
                data: result.data,
                status: result.status,
            };
        } catch (error) {
            if (error && error.response && error.response.data) {
                if (error.response.data.includes('User already exists')) {
                    throw error.response.data.split(':')[1];
                }
                throw error.response.data;
            } else {
                throw error;
            }
        }
    }

    async createGroup(data) {
        const result = await this.httpClient.post('/v1/groups/create_group', data);
        if (result && result.data) {
            return {
                data: { groups: result.data },
                status: result.status,
            };
        }
        if (result.status === 500) {
            throw 'Please try again.';
        } else if (result.status === 401) {
            throw 'sorry! you are not allowed for this operation';
        }
        return {
            data: result.data,
            status: result.status,
        };
    }

    async getGroups(accountId) {
        const result = await this.httpClient.get(`userGroups/${accountId}/getGroups`);
        if (result && result.data) {
            return result.data;
        }
        if (result.status === 500) {
            throw 'Please try again.';
        } else if (result.status === 401) {
            throw 'sorry! you are not allowed for this operation';
        }
        return {
            data: result.data,
            status: result.status,
        };
    }

    async updateGroup(data) {
        const result = await this.httpClient.post('/v1/groups/update_group', data);
        if (result && result.data) {
            return {
                data: { groups: result.data },
                status: result.status,
            };
        }
        if (result.status === 500) {
            throw 'Please try again.';
        } else if (result.status === 401) {
            throw 'sorry! you are not allowed for this operation';
        }
        return {
            data: result.data,
            status: result.status,
        };
    }

    async deleteGroups(group_ids) {
        const result = await this.httpClient.post('userGroups/deleteGroup', group_ids);
        if (result && result.status === 200) {
            return {
                status: result.status,
            };
        }
        if (result.status === 500) {
            throw 'Please try again.';
        } else if (result.status === 401) {
            throw 'sorry! you are not allowed for this operation';
        }
        return {
            data: result.data,
            status: result.status,
        };
    }

    async deleteUserFromGroup(user_ids) {
        const result = await this.httpClient.post('userGroups/deleteUserFromGroup', user_ids);
        if (result && result.data) {
            return {
                data: { groups: result.data },
                status: result.status,
            };
        }
        if (result.status === 500) {
            throw 'Please try again.';
        } else if (result.status === 401) {
            throw 'sorry! you are not allowed for this operation';
        }
        return {
            data: result.data,
            status: result.status,
        };
    }

    async associateUsersWithGroup(payload) {
        const result = await this.httpClient.post('userGroups/addUserToGroup', payload);
        if (result && result.status === 200) {
            return {
                status: result.status,
            };
        }
        if (result.status === 500) {
            throw 'Please try again.';
        } else if (result.status === 401) {
            throw 'sorry! you are not allowed for this operation';
        }
        return {
            data: result.data,
            status: result.status,
        };
    }

    async dissociateUser(payload) {
        const result = await this.httpClient.post('userGroups/deleteUserFromGroup', payload);
        if (result && result.status === 200) {
            return {
                status: result.status,
            };
        }
        if (result.status === 500) {
            throw 'Please try again.';
        } else if (result.status === 401) {
            throw 'sorry! you are not allowed for this operation';
        }
        return {
            data: result.data,
            status: result.status,
        };
    }

    async enableDisableGroups(obj) {
        const result = await this.httpClient.post('/v1/groups/enabledisablegroup', obj);
        if (result && result.status === 200) {
            return {
                status: result.status,
            };
        }
        if (result.status === 500) {
            throw 'Please try again.';
        } else if (result.status === 401) {
            throw 'sorry! you are not allowed for this operation';
        }
        return {
            data: result.data,
        };
    }

    async postUserProfile(payload, img) {
        const bodyFormData = new FormData();
        bodyFormData.append(
            'params',
            JSON.stringify({
                //name: payload.name,
                accountName: payload.accountName,
                email: payload.email,
                role: payload.role,
                language: payload.language,
                timeZone: payload.timeZone,
                isRelativeDateFormat: payload.isRelativeDateFormat,
                startDate: payload.startDate,
                endDate: payload.endDate,
            }),
        );
        if (checkArrayLength(img)) {
            bodyFormData.append('imgFile', img[0]);
        }
        const result = await this.httpClientUsrmgmt.post(`usermgmt/v1/users/${payload.id}/update`, bodyFormData);
        if (result && result.status === 200) {
            return {
                status: result.status,
            };
        }
        if (result.status === 500) {
            throw 'Please try again.';
        } else if (result.status === 401) {
            throw 'sorry! you are not allowed for this operation';
        }
        return {
            data: result.data,
            status: result.status,
        };
    }

    async sendUserProjectPermission(objData) {
        await this.httpClient.post('/v1/userprojectassoc/update', objData);
    }

    async SendGenerationData(obj) {
        const url = `${window.location.protocol}//${window.location.hostname}`;
        const result = await this.httpClient.post(`${url}/autonomous/v1/crawlwithouttestcase`, obj);
        if (result && result.status === 200) {
            return {
                status: result.status,
            };
        }
        if (result.status === 500 || result.status === 401) {
            throw 'Error is sending generating data';
        }
        return {
            data: result.data,
        };
    }
}

export default new UserMiddleware();
