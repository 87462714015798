import React, { useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import AddIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import { ArrowUpDown } from '../../../components/Button/ArrowUpDown';
import Tooltip from '../../../components/Tooltip';
import { IconButton, Popper, ClickAwayListener, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';

const PopperElement = ({ classes, localContent, type, arrItems, title, ind, setState, disabled,localPlatformDisable,localBrowserDisable,localPlatformV,configurationSetting, mandatory }) => {
    const onAwayClick = (type, ind) => {
        let _localContent = cloneDeep(localContent);
        if (type === 'platform') {
            _localContent[ind]['platformEl'] = null
        } else {
            _localContent[ind]['browserEl'] = null
        }
        setState({ localContent: _localContent,overflowStyle: 'auto', localContentUpdated: true })
    }

    const handleEnvironmentType = (e, type, ind) => {
        let _localContent = cloneDeep(localContent);
        if (type === 'platform') {
            _localContent[ind]['platformEl'] = e.currentTarget
        } else {
            _localContent[ind]['browserEl'] = e.currentTarget
        }
        setState({ localContent: _localContent, overflowStyle: 'clip', localContentUpdated: true })
    }

    const handleTypeChange = (type, item, ind) => {
        let _localContent = cloneDeep(localContent);
        if (type === 'platform') {
            let value = localPlatformV.find(val => val.platform === item)
            if (value) {
                _localContent[ind]['platform'] = item
                _localContent[ind]['platformEl'] = null
                _localContent[ind]['browserVersion'] = value.browserVersion
                _localContent[ind]['platformVersion'] = value.platformVersion
                _localContent[ind]['appiumVersion'] = value.appiumVersion
                _localContent[ind]['deviceName'] = value.deviceName
                _localContent[ind]['deviceOrientation'] = value.deviceOrientation
                _localContent[ind]['environmentType'] = item.includes("headful") ? "Zalenium" : "Local"
                setState({ localContent: _localContent, platformLocal: item, localPlatformDisable: false,overflowStyle: 'auto' , localContentUpdated: true})
            }
        } else {
            let value = localPlatformV.find(val => val.browser === item.replace(/headful|headless/g,"").replace('()',"").trim())
            if(value) {
                _localContent[ind]['platform'] = value.platform
                _localContent[ind]['platformEl'] = null
                _localContent[ind]['browserVersion'] = value.browserVersion
                _localContent[ind]['platformVersion'] = value.platformVersion
                _localContent[ind]['appiumVersion'] = value.appiumVersion
                _localContent[ind]['deviceName'] = value.deviceName
                _localContent[ind]['deviceOrientation'] = value.deviceOrientation
                _localContent[ind]['environmentType'] = item.includes("headful") ? "Zalenium" : "Local"
                _localContent[ind]['browser'] = item
                _localContent[ind]['browserEl'] = null
                setState({ localContent: _localContent, browserLocal: item.split(' ')[0], localBrowserDisable: false,overflowStyle: 'auto', localContentUpdated: true })
            }
        }
    }
    return (
        <div style={{ width: '40%' }}>
            <Typography className={classes.textFieldLabelCustom}>{title}{mandatory && (<span className={classes.requiredSpan}>*</span>)}</Typography>
            <div
                onClick={(e) => {
                    handleEnvironmentType(e, type, ind)
                }}
                className={disabled ? classes.disabledEnvironmentPopper : classes.environmentPopper}
            >
                <Typography className={disabled ? classes.disabled : classes.environmentTypetext}>
                    {type === 'platform' ? localContent[ind][type]
                    : type === 'browser' && configurationSetting.toLowerCase() !== 'newconfiguration' ? `${localContent[ind][type]} ${localContent[ind]['environmentType'].toLowerCase() === 'local' ? '(headless)' : '(headful)'}` 
                    : localContent[ind][type]
                    }
                </Typography>
                <ArrowUpDown flag={type === 'platform' ? Boolean(localContent[ind]['platformEl']) : Boolean(localContent[ind]['browserEl'])} />
            </div>
            {type === 'platform' && (!localContent[ind][type] || localContent[ind][type] === 'Select') && localPlatformDisable && <Typography style={{color: 'red',fontSize: 10}}>Platform is required</Typography> }
            {type === 'browser' && (!localContent[ind][type] || localContent[ind][type] === 'Select') && localBrowserDisable && <Typography style={{color: 'red',fontSize: 10}}>Browser is required</Typography> }
            <div>
                <Popper
                    open={type === 'platform' ? Boolean(localContent[ind]['platformEl']) : Boolean(localContent[ind]['browserEl'])}
                    anchorEl={type === 'platform' ? localContent[ind]['platformEl'] : localContent[ind]['browserEl']}
                    placement='bottom-start'
                    style={{
                        zIndex: 1300,
                        width: `21%`,
                    }}
                >
                    <ClickAwayListener onClickAway={() => onAwayClick(type, ind)}>
                        <div className={classes.selectBoxContainer}>
                            {arrItems.map((item, index) => (
                                <div
                                    aria-hidden
                                    key={`${index.toString()}-${item}`}
                                    style={{
                                        fontWeight: 400,
                                        backgroundColor: '#fff'

                                    }}
                                    className={classes.menuItem}
                                    onClick={(e) => {
                                        handleTypeChange(type, item, ind)
                                    }}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>
                    </ClickAwayListener>
                </Popper>
            </div>
        </div>
    )
}

const PlatformBrowserForLocalExecution = ({
    localContent,
    classes,
    setState,
    environmentDetails,
    platformLocal,
    callingFrom,
    configurationSetting,
    localBrowserDisable,
    localPlatformDisable,
    localSysDefault,
    overflowStyle,
    localContentUpdated,
}) => {

    const [isHovered, setHover] = useState(null)
    // Mapping platform and browsers
    let platformDetails = [], browserDetails = [], localPlatformV = [];

    const filterPlatformBrowsers = (platformBrowserCombination,type) => {
        let platforms = [], browsers = [], platformBrowsersV = [], zaleniumDetails = {}, localEnvironment = {};
        zaleniumDetails = platformBrowserCombination.find((val) => val.environmentType === 'Zalenium')
        if (zaleniumDetails?.environment?.platformDetails?.length) {
            zaleniumDetails = zaleniumDetails.environment.platformDetails.map((val) => {return {...val,environmentType: 'Zalenium'}})
            platformBrowsersV = [...zaleniumDetails]
        }
        localEnvironment = platformBrowserCombination.find((val) => val.environmentType.toLowerCase() === 'local')
        if(localEnvironment?.environment?.platformDetails?.length) {
            localEnvironment = localEnvironment.environment.platformDetails.map((val) => {return {...val,environmentType: 'Local'}})
            platformBrowsersV = [...localEnvironment,...platformBrowsersV]
        }
        if (!zaleniumDetails && !localEnvironment && configurationSetting !== 'lastRunConfiguration') {
            return <Typography className={classes.noDetail}>No local configuration found</Typography>
        }
        platforms = Array.from(new Set(platformBrowsersV.map(platform => platform.platform)))
        let platformbrowsers = platformBrowsersV.filter(val => val.platform === platformLocal)
        browsers = Array.from(new Set(platformbrowsers.map((browser) => {
            if (browser?.environmentType?.toLowerCase() === 'zalenium') {
                return `${browser.browser} (headful)`
            } else if (browser?.environmentType?.toLowerCase() === 'local') {
                return `${browser.browser} (headless)`
            } else {
                return undefined
            }
        }))).filter(Boolean)
        return [platforms,browsers,platformBrowsersV]
    }

    if (configurationSetting !== 'lastRunConfiguration') {
        if (environmentDetails?.environments?.length) {
            let newCombo = filterPlatformBrowsers(environmentDetails?.environments, 'executescript')
            if (newCombo.length > 0) {
                platformDetails = newCombo[0]
                browserDetails = newCombo[1]
                localPlatformV = newCombo[2]
            } else {
                return <Typography className={classes.noDetail}>Local configuration not found</Typography>
            }
        } else {
            return <Typography className={classes.noDetail}>Local configuration not found</Typography>
        }
    }

    const addExecutionRow = (text) => {
        let _localContent = cloneDeep(localContent);
        _localContent.push(localSysDefault[0])
        setState({ localContent: _localContent, noConfigFound: false,localConfigurationNotFound: true,showLocalContent : false , localContentUpdated: true})
    }

    const deleteLocalRow = (index) => {
        let _localContent = cloneDeep(localContent);
        let filteredContent = _localContent.filter((_, ind) => ind !== index)
        if(filteredContent.length === 0) {
            setState({showLocalContent : true})
        }
        setState({ localContent: filteredContent, localContentUpdated: true })
    }

    return (
        <div className={classes.content} style={{ maxHeight: 150, overflowY: overflowStyle, width: '100%' }}>
            {localContent.length ? (localContent.map((_val, ind) => {
                return (
                    <div
                        key={`${_val.platform}_${ind}`}
                        className={classes.environmentTypeContainer}
                        style={{  backgroundColor: ind % 2 === 0 ? '#F5F5F5' : '#fff' }}
                        onMouseEnter={() => setHover(ind)}
                        onMouseLeave={() => setHover(null)}
                    >
                        <PopperElement
                            arrItems={platformDetails?.length ? platformDetails : ['Linux']}
                            type={'platform'}
                            disabled={configurationSetting === 'lastRunConfiguration'}
                            localContent={localContent}
                            mandatory = {callingFrom === 'schedule' ? true : false}
                            configurationSetting={configurationSetting}
                            localPlatformV={localPlatformV}
                            title={'Platform'}
                            ind={ind}
                            localPlatformDisable={localPlatformDisable}
                            classes={classes}
                            setState={setState}
                        />
                        <PopperElement
                            arrItems={browserDetails?.length ? browserDetails : ['Chrome']}
                            type={'browser'}
                            localContent={localContent}
                            mandatory = {callingFrom === 'schedule' ? true : false}
                            localPlatformV={localPlatformV}
                            configurationSetting={configurationSetting}
                            disabled={configurationSetting === 'lastRunConfiguration'}
                            title={'Browser'}
                            ind={ind}
                            localBrowserDisable={localBrowserDisable}
                            classes={classes}
                            setState={setState}
                        />
                        <div style={{ width: '8%' }}>
                            {isHovered === ind && configurationSetting !== 'lastRunConfiguration' && callingFrom !== 'Generate' && (
                                <Tooltip data='Delete'>
                                    <IconButton disabled={configurationSetting === 'lastRunConfiguration'} className={classes.iconButtonRoot} aria-label="GotoExecution" onClick={() => deleteLocalRow(ind)}>
                                        <CloseIcon className={`${configurationSetting === 'lastRunConfiguration' ? classes.disableAddBtn : classes.actionIcons}`} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )
            })) :
                (<>
                    {configurationSetting !== 'lastRunConfiguration' && (
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                size="small"
                                className={classes.button}
                                startIcon={<Add />}
                                onClick={() => addExecutionRow("add platform")}
                            >
                                Add Platform
                            </Button>
                        </div>
                    )}
                </>
                )
            }
           {(localContent.length && callingFrom !== 'Generate' && configurationSetting !== 'lastRunConfiguration') ? (<div style={{ textAlign: "end", marginTop: '-13px', paddingRight: `${localContent.length > 2 ? '15px' : '18px'}` }}>
                <Tooltip data='Add'>
                    <IconButton disabled={(configurationSetting === 'lastRunConfiguration' || localPlatformV.length === localContent.length)} className={classes.iconButtonRoot} aria-label="GotoExecution" onClick={() => addExecutionRow()}>
                        <AddIcon className={`${(configurationSetting === 'lastRunConfiguration' || localPlatformV.length === localContent.length) ? classes.disableAddBtn : classes.addIcon}`} />
                    </IconButton>
                </Tooltip>

            </div>) : null
            }

        </div>
    )
}

export default PlatformBrowserForLocalExecution
