import { ActionTypes } from '../constants';

const initialState = {
    loading: {},
    images: {},
    error: false,
};

export default function imagesReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_EXECUTION_REDUCER: {
            return { ...initialState };
        }
        case ActionTypes.CLEAR_SELECTED_TESTCASE_REDUCER: {
            return { ...initialState };
        }
        case ActionTypes.LOAD_IMAGE: {
            const loading = JSON.parse(JSON.stringify(state.loading));
            loading[action.payload.url] = true;
            return {
                ...state,
                loading,
                error: false,
            };
        }
        case ActionTypes.LOAD_IMAGE_SUCCESS: {
            const loading = JSON.parse(JSON.stringify(state.loading));
            loading[action.payload.url] = false;
            const images = JSON.parse(JSON.stringify(state.images));
            images[action.payload.url] = action.payload.image;
            return {
                ...state,
                loading,
                images,
            };
        }
        case ActionTypes.LOAD_IMAGE_FAILURE: {
            const loading = JSON.parse(JSON.stringify(state.loading));
            const images = JSON.parse(JSON.stringify(state.images));
            images[action.payload.url] = action.payload.url;
            loading[action.payload.url] = false;

            return {
                ...state,
                loading,
                error: action.payload.error,
                images,
            };
        }
        default:
            return state;
    }
}
