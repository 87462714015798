// import material
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import packages
import React from 'react';
import { connect } from 'react-redux';
// import icons
import { PlayArrow } from '@material-ui/icons';
// import custom
import { SelectedTestCaseActions } from '../../../../store/actions';
import { TestCaseUtils, checkKeyInObject } from '../../../../utils';

const styles = () => ({
    replayButtonContainer: {
        alignItems: 'center',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
    },
    replayButtonContainerDisabled: {
        backgroundColor: '#bdbdbd',
        cursor: 'default',
    },
    replayButtonContainerEnable: {
        backgroundColor: '#0092e6',
        cursor: 'pointer',
    },
    replayButtonContainerScreenShot: {
        height: 21.6,
        top: 64,
        width: 40,
    },
    replayButtonContainerList: {
        left: 4,
        top: 7,
        borderRadius: 3,
        '& [class*="replayButtonStyle"]': {
            fontSize: 18,
        },
    },
    replayButtonStyle: {
        color: 'white',
        fontSize: 24,
    },
});

function SmartPlayButton(props) {
    const {
        classes,
        closePopper,
        disableReplayButton,
        handleReplayPopoverEnter,
        handleReplayPopoverLeave,
        isSmartRetryStep,
        isSmartRetryLoader,
        isDebugPointStep,
        isDebugPointLoader,
        isReplayButtonDisabled,
        listView,
        projectId,
        setDebugPointLoader,
        setSmartRetryLoader,
        testStep,
        testCaseId,
        wsRunningCaseDataCurrCase,
    } = props;

    function setSmartPlayButtonLoader(params, flag, _isSmartRetry) {
        if (_isSmartRetry) {
            setSmartRetryLoader(params, flag);
        } else {
            setDebugPointLoader(params, flag);
        }
    }

    const isSmartRetry = isSmartRetryStep || isSmartRetryLoader;

    const msgType = isSmartRetry ? '14' : '17';
    const isPlayButtonStep = isSmartRetryStep || isDebugPointStep;
    const isPlayButtonLoader = isSmartRetryLoader || isDebugPointLoader;

    const sessionId = checkKeyInObject(wsRunningCaseDataCurrCase, 'sessionId');
    const testStepObj = checkKeyInObject(wsRunningCaseDataCurrCase, 'testStepObj');
    const showSmartButton =
        (isPlayButtonStep || isPlayButtonLoader) &&
        sessionId &&
        testStepObj &&
        sessionId === testStepObj.sessionId &&
        ['13', '16'].includes(`${testStepObj.msgType}`);

    const stepDepth = (`${testStep.instrNum}`.match(/\./g) || []).length;
    const content = (
        <div
            aria-hidden
            style={!isPlayButtonStep && stepDepth > 0 ? { left: `${49 * stepDepth}px` } : {}}
            onMouseEnter={handleReplayPopoverEnter}
            onMouseLeave={() => handleReplayPopoverLeave()}
            className={`${classes.replayButtonContainer} ${
                classes[isReplayButtonDisabled ? 'replayButtonContainerDisabled' : 'replayButtonContainerEnable']
            } ${classes[listView ? 'replayButtonContainerList' : 'replayButtonContainerScreenShot']}`}
            onClick={() => {
                if (!isReplayButtonDisabled) {
                    TestCaseUtils.runTestSteps({ projectId, paramTestCaseId: testCaseId }, msgType, disableReplayButton);
                    setSmartPlayButtonLoader({ testCaseId, instrNum: testStep.instrNum }, true, isSmartRetry);
                    closePopper({ showPopUp: false, anchorElReplay: null });
                }
            }}
        >
            {isPlayButtonLoader ? (
                <CircularProgress aria-label="smartPlayLoader" id="smartPlayLoader" size={17} style={{ color: '#2792e6' }} />
            ) : (
                <PlayArrow className={classes.replayButtonStyle} aria-label="smartPlayArrowicon" id="smartPlayArrowicon" />
            )}
        </div>
    );

    return showSmartButton ? content : null;
}

const mapStateToProps = (state, props) => {
    let wsRunningCaseDataCurrCase = state.projectReducer.wsRunningCaseData;
    wsRunningCaseDataCurrCase = checkKeyInObject(wsRunningCaseDataCurrCase, props.testCaseId, 'value', {});
    return {
        wsRunningCaseDataCurrCase,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // Selected TestCase Action
        setSmartRetryLoader: (...args) => dispatch(SelectedTestCaseActions.setSmartRetryLoader(...args)),
        setDebugPointLoader: (...args) => dispatch(SelectedTestCaseActions.setDebugPointLoader(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SmartPlayButton));
