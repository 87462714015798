import React from 'react';
import { DialogTop } from '../../../../src/components/Modal/ModalTop';
import {MODAL_HEADER_CLOSE_ICON_CONT, MODAL_HEADER_CLOSE_ICON, THREE_DOT_MENU_CSS } from '../../../../src/common/cssConstants';

import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Search } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';

import Tooltip from '../../../components/Tooltip'
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ModalActions, ProjectActions, GeneralActions, selectionExecutionAction, sortSelectedExecution } from '../../../store/actions';
import { checkArrayLength } from '../../../utils/utils';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import Table from '../../../components/Table/Table';
import EnhancedTableHead from '../../../components/EnhancedTable/EnhancedTableHead_v2';
import TableBody from '../../../components/Table/TableBody';
import ExecutionRow from './ExecutionRow';
import SelectCustom from '../../modal/GeneralModal/Select';

const styles = () => ({
    dialogContainer: {
        width: '80%',
        maxWidth: '970px',
        minHeight: '550px',
        height: '550px',
        backgroundColor: '#ffffff',
        boxShadow: '0 0 45px 0 rgb(0 0 0 / 80%)',
        borderRadius: '15px',
    },
    closeIconWrapper: MODAL_HEADER_CLOSE_ICON_CONT,
    closeIcon: MODAL_HEADER_CLOSE_ICON,

    dialogHeader: {
        position: 'relative',
        padding: '30px 45px 18px 30px',
        backgroundColor: '#ffffff',
        display: 'flex',
        alignItems: 'center',
    },
    imgIcon: {
        color: '#ffffff',
        width: '22px',
        height: '22px',
        cursor: 'pointer',
    },
    faIcon:{
        color: THREE_DOT_MENU_CSS.iconColor, 
        marginRight: '4px', 
        fontSize:"22px",
        cursor: 'pointer'
    },
    statusIcon: {
        width: '18px',
        height: '18px',
    },
    statusInActive: {
        color: '#da4e35',
        marginLeft: 2,
    },
    statusInQue: {
        color: '#eac129',
    },
    progressIconStyle: {
        fontSize: 24,
    },
    execution: {
        fontSize: '18px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: '0.41px',
        color: '#000000',
        margin: '0 10px 0 20px',
    },
    IconButtonRefresh: {
        marginLeft: 10,
    },
    headerContent: {
        display: 'flex',
        alignItems: 'center',
    },
    iconImgContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    refresh: {
        fontSize: '16px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#ffffff',
        marginLeft: 20,
    },
    rightHeaderContent: {
        position: 'absolute',
        top: '16px',
        right: '16px',
    },
    headerBottom: {
        height: '60px',
        marginLeft:"auto",
        padding: '0.5px 35px 60px 24.5px',
        backgroundColor: '#fff',
    },
    headingContainer: {
        display: 'flex',
        flex: 2,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    projectHeading: {
        opacity: 0.47,
        fontSize: '15px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.41px',
        color: '#3d3737',
    },
    projectName: {
        fontSize: '16px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#646262',
    },
    actionsContent: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    actionIconContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    sortIcon: {
        margin: '10px 10px 10px 0',
        width: '24px',
        height: '24px',
        cursor: 'pointer',
    },
    filterIcon: {
        width: '24px',
        height: '25px',
        margin: '0 0px 5px 32px',
        cursor:"pointer"
    },
    menuItem: {
        fontSize: '12px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000000',
    },
    menuItem2: {
        fontSize: '12px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000000',
    },
    dateAndStatus: {
        fontSize: '12px',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#000000',
    },
    name: {
        fontSize: '12px',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#3768b3',
    },
    link: {
        color: '#3768b3',
        textDecoration: 'none'
    },
    browserIcon: {
        marginRight: '10px',
        color: 'black',
        // fontSize: 20,
        marginTop: '-35px',
        height: '18px',
        width: '18px',
    },
    loadingState: {
        textAlign: 'center',
        fontSize: 12,
        marginTop: 10,
        marginBottom: 10,
    },
    selectCustom: {
        width: '100%',
        marginTop: '16px',
        marginBottom: '8px',
    },
    tableRow: {
        "&:hover": {
            backgroundColor: '#f5f5f5'
          }
    }
});

const headers = [
    {
        id: 'cb',
        sortable: false,
        numeric: false,
        disablePadding: true,
        width: '40px',
        style: { display: 'flex', alignItems: 'center', justifyContent: 'center',overflow: 'initial' },
        tableCellStyles: { paddingBottom: 20, paddingLeft: 10, justifyContent: 'center' },
    },
    {
        id: 'name',
        color: "rgb(0 0 0 / 73%)",
        sortable: false,
        numeric: false,
        disablePadding: false,
        label: 'TEST CASE',
        styles: { paddingBottom: 20, marginLeft: -46},
        width: 'calc(calc(100% / 36) * 10)',
    },
    {
        id: 'creationTime',
        sortable: false,
        numeric: false,
        disablePadding: false,
        label: 'START TIME',
        color: "rgb(0 0 0 / 73%)",
        styles: { paddingBottom: 20 },
        width: 'calc(calc(100% / 36) * 9)',
    },
    {
        id: 'delete',
        label: 'PREVIEW',
        width: 'calc(calc(100% / 36) * 6)',
        centerV: true,
        color: 'rgba(56, 55, 55, 0.5)',
        tableCellStyles: { paddingBottom: 20 },
    },
    {
        id: 'download',
        label: 'DOWNLOAD',
        width: 'calc(calc(100% / 36) * 6)',
        centerV: true,
        color: 'rgba(56, 55, 55, 0.5)',
        tableCellStyles: { paddingBottom: 20 },
    },
    {
        id: 'platform',
        label: 'PLATFORM',
        width: 'calc(calc(100% / 36) * 6)',
        centerV: true,
        color: 'rgba(56, 55, 55, 0.5)',
        tableCellStyles: { paddingBottom: 20 },
    },
    {
        id: 'browser',
        label: 'BROWSER',
        width: 'calc(calc(100% / 36) * 6)',
        centerV: true,
        color: 'rgba(56, 55, 55, 0.5)',
        tableCellStyles: { paddingBottom: 20 },
    },
];

// let timer;
// const currenttime = 0;
const statusMap = { '-1': 'All', '0': 'InProcess', '1': 'Executed', '2': 'Error','7': 'Stopped' ,'8':'Queued' };

class ExecutionModal extends React.Component {
    constructor() {
        super();
        this.isTimeOutRunning = false;
        this.isExecRunning = false;
        this.state = {
            expanded: 'taskContainer',
            defaultValue: 'InProcess',
            tasks: [],
            previousSortedType: 'sortAsc',
            highLightExecutionId: 0,
        };
    }

    componentDidMount() {
        const { toggleSidebar } = this.props;
        const { executionTasksStatus, getExecutionTasks, projectId = 0, testCaseId = -1, selectedExecution } = this.props;
        const [_projectId, _testCaseId, isCustomCaseId] = this.getURLProject_Case_ID(projectId, testCaseId);
        selectedExecution.length > 0 ? (() => null)() : getExecutionTasks(_projectId, isCustomCaseId ? -1 : executionTasksStatus, _testCaseId);
        if (Number(executionTasksStatus) === 0)
            this.interval = setInterval(() => {
                this.getExecutionTasks();
            }, 15000);
        const highLightExecutionId =
            this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.execId
                ? this.props.history.location.state.execId
                : 0;
        this.updateState({ defaultValue: statusMap[`${executionTasksStatus}`], highLightExecutionId });
        const showStateTask = localStorage.getItem('expandedTaskbar');
        if (showStateTask && showStateTask === 'show') {
            // Fist resize middle content then expande sidebar
            this.updateState({ expanded: 'taskContainer' }, () => {
                this.isTimeOutRunning = false;
            });
        } else if (showStateTask && showStateTask === 'hide') {
            // First collapse sidebar then resize middle content
            this.updateState({ expanded: false }, () => {
                this.isTimeOutRunning = true;
                setTimeout(() => {
                    toggleSidebar();
                    this.isTimeOutRunning = false;
                }, 50);
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { executionData, executionTasksStatus } = nextProps;
        if (`${executionTasksStatus}` !== '0') {
            this.startStopExecution(false);
        } else if (!this.isExecRunning) {
            const { projectId, testCaseId } = this.props;
            const _projectId = projectId || 0;
            const _testCaseId = testCaseId || -1;
            this.startStopExecution(true, executionTasksStatus, _projectId, _testCaseId);
        }

        const highLightExecutionId =
            this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.execId
                ? this.props.history.location.state.execId
                : 0;
        this.setState({
            highLightExecutionId,
            defaultValue: statusMap[`${executionTasksStatus}`],
            tasks: !executionData ? null : [], // just to show 'Loading...' while executions are fetching from
        });

        this.setState({
            tasks:
                executionData && executionData.length > 0
                    ? executionData.sort((a, b) => (new Date(b.initiatedOn).getTime() < new Date(a.initiatedOn).getTime() ? -1 : 1))
                    : [],
        });
    }

    componentWillUnmount() {
        this.startStopExecution(false);
    }

    getExecutionTasks(data = {}) {
        const { getExecutionTasks } = this.props;
        // const isExpanded = (openTaskPanel && expanded) === 'taskContainer';
        const { projectId = 0, testCaseId = -1 } = this.props;
        const { val, openPanel, executionTasksStatus = this.props.executionTasksStatus } = data;
        let _projectId = projectId;
        let _testCaseId = testCaseId;
        let isCustomCaseId;
        if (val !== undefined || openPanel !== undefined) {
            [_projectId, _testCaseId, isCustomCaseId] = this.getURLProject_Case_ID(projectId, testCaseId);
        }
        getExecutionTasks(_projectId, isCustomCaseId ? -1 : executionTasksStatus, _testCaseId, 1, true, val, openPanel);
    }

    getURLProject_Case_ID = (projectId, testCaseId) => {
        let _projectId = projectId;
        let _testCaseId = testCaseId;
        let isCustomCaseId = false;
        if ((!projectId || !testCaseId) && window.location.pathname.toLowerCase().includes('/projects/')) {
            const arr = window.location.pathname.split('/');
            const ind = arr.findIndex((val) => val === 'details');
            if (!projectId) {
                _projectId = arr[ind + 1];
            }
            if (!testCaseId && arr.length > ind + 2) {
                isCustomCaseId = !!Number(arr[ind + 2]);
                _testCaseId = isCustomCaseId ? arr[ind + 2] : testCaseId;
            }
        }
        return [_projectId, _testCaseId, isCustomCaseId];
    };

    getStatus = (val) => {
        switch (val) {
            case 'Executed':
                return 1;
            case 'InProcess':
                return 0;
            case 'Error':
                return 2;
            case 'All':
                return -1;
            case 'Stopped':
                return 7;
            case 'Queued':
                return 8;
            default:
                return null;
        }
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    // Use this function for Execution -- [set isExecute = true] for execute it will automatically start interval if executionTasksStatus is 0
    // -- [set isExecute = false] for clearInterval
    startStopExecution = (
        isExecute,
        executionTasksStatus,
        __projectId = 0,
        __testCaseId = -1,
        page = 1,
        executionsPolling = false,
        val = null,
        openPanel = true,
    ) => {
        this.isExecRunning = false;
        clearInterval(this.interval);
        if (isExecute) {
            const { getExecutionTasks } = this.props;
            const [_projectId, _testCaseId, isCustomCaseId] = this.getURLProject_Case_ID(__projectId || 0, __testCaseId || -1);
            clearInterval(this.interval);
            getExecutionTasks(_projectId, isCustomCaseId ? -1 : executionTasksStatus, _testCaseId, page, executionsPolling, val, openPanel);
            if (Number(executionTasksStatus) === 0) {
                this.isExecRunning = true;
                this.interval = setInterval(() => {
                    this.getExecutionTasks({ val, openPanel, executionTasksStatus });
                }, 15000);
            }
        }
    };

    sortOuter = () => {
        const { selectedExecution, sortSelectedExecution, selectedExePreviousSortedType } = this.props;        
        let sortOrder = '';
        const { defaultValue, tasks } = this.state;
        const oldTasks = selectedExecution.length === 0 ? [...tasks] : [...selectedExecution];
        let { previousSortedType } = this.state;
        let dec = selectedExecution.length === 0 ? previousSortedType : selectedExePreviousSortedType;
        if (dec === 'sortDesc') {
            oldTasks.sort(function compare(a, b) {
                const dateA = new Date(a.initiatedOn);
                const dateB = new Date(b.initiatedOn);
                return dateB - dateA;
            });

            sortOrder = 'sortAsc';

        } else {
            oldTasks.sort(function compare(a, b) {
                const dateA = new Date(a.initiatedOn);
                const dateB = new Date(b.initiatedOn);
                return dateA - dateB;
            });

            sortOrder = 'sortDesc';
        }
        const newTasks = [...oldTasks];

        if (selectedExecution.length === 0) {
            this.setState({
                tasks: newTasks,
                previousSortedType: sortOrder,
                defaultValue,
            });
        } else {
            const data = {
                sortOrder,
                tasks: newTasks
            }
            sortSelectedExecution(data);
        }
    };

    sort = (val) => {
        const { projectId, testCaseId } = this.props;
        // const tasks = ProjectServices.activeExecutionsTasks(this.props.projectName);
        const status = this.getStatus(val);
        const _projectId = projectId || 0;
        const _testCaseId = testCaseId || -1;

        this.startStopExecution(true, status, _projectId, _testCaseId, null, null, val);
    };

    handleChange = (event) => {
        this.setState({ defaultValue: event.target.value, tasks: this.sort(event.target.value) });
    };

    handleClose = (e) => {
        this.props.toggleModal()
        e.stopPropagation();
    };

    changeSelection = (event) => {
        const { selectionExecutionAction } = this.props;
        const newArr = JSON.parse(JSON.stringify(event))
        const data ={
            newArr,
            event,
        }
        selectionExecutionAction(data);
    };

    changeScroll = (e) => {
        const { defaultValue } = this.state;
        const { selectedExecution } = this.props;
        const status = this.getStatus(defaultValue);
        if (status !== 0 && Math.floor(e.target.scrollHeight) - Math.floor(e.target.scrollTop )<= Math.floor(e.target.clientHeight)+20) {
            const { executionTaskStatusPageNo, executionsLoading, isExecutionTaskStatusLastPage, projectId, testCaseId } = this.props;
            if (status !== 0 && status != null && !executionsLoading && !isExecutionTaskStatusLastPage) {
                const _projectId = projectId || 0;
                const _testCaseId = testCaseId || -1;
                this.startStopExecution(
                    selectedExecution.length > 0 ? false : true,
                    status,
                    _projectId,
                    _testCaseId,
                    Number(executionTaskStatusPageNo) + 1,
                );
            }
        }
    };

    convertToSelectValues = (inputArray = []) => {
        let outputArray = [];
        outputArray = inputArray.map((element) => {
            return {
                aiqExecution: element.aiqExecution,
                aiqReportData: element.aiqReportData,
                browser: element.browser,
                classes: element.classes,
                errorsCount: element.errorsCount,
                executionStatus: element.executionStatus,
                executionVideoUrl: element.executionVideoUrl,
                initiatedOn: element.initiatedOn,
                projectId: element.projectId,
                projectName: element.projectName,
                reportUrl: element.reportUrl,
                statusMessage: element.statusMessage,
                tcErrors: element.tcErrors,
                tcWarnings: element.tcWarnings,
                testCaseName: element.testCaseName,
                testcaseId: element.testcaseId,
                warningsCount: element.warningsCount,
                value: element.executionId,
                label: element.executionName,
            };
        });
        return outputArray;
    };

    showExecutionVideo = (execUrl, caseName) => {
        const { showSnackBar, toggleModal } = this.props;
        if (execUrl) {
            toggleModal('video', null, execUrl, caseName);
        } else {
            showSnackBar('There is no video available yet');
        }
    };

    refreshExecution = () => {
        const {defaultValue} = this.state
        const { selectionExecutionAction } = this.props;
        selectionExecutionAction([]);
        this.setState({ tasks: this.sort(defaultValue) });
    }

    render() {
        const {
            classes,
            open,
            executionsLoading,
            executionsPolling,
            disabledProjects,
            showDisabledProjects,
            isFavourite,
            favouriteProjects,
            selectedExecution,
            selectedExePreviousSortedType,
            searchedInput,
        } = this.props;
        const { defaultValue, tasks, previousSortedType } = this.state;
        const _tasks = [];
        const [_projectId, _testCaseId] = this.getURLProject_Case_ID();
        if (checkArrayLength(tasks)) {
            tasks.forEach((task) => {
                if (
                    !_projectId ||
                    (_projectId && `${_projectId}` === `${task.projectId}` && _testCaseId) ||
                    !_testCaseId ||
                    (_testCaseId && `${_testCaseId}` === `${task.testcaseId}` && _projectId === task.projectId)
                ) {
                    _tasks.push(task);
                }
            });
        }
        const localProjectsCopy = showDisabledProjects ? disabledProjects : isFavourite ? favouriteProjects : _tasks;
        const convertedItems = this.convertToSelectValues(localProjectsCopy);
        const sortedType = selectedExecution.length === 0 ? previousSortedType : selectedExePreviousSortedType;
        /* eslint-disable jsx-a11y/media-has-caption */

        let content;
        if ((_tasks && _tasks.length) || (selectedExecution && selectedExecution.length)) {
            content =
                selectedExecution.length > 0
                    ? selectedExecution.map((execution, index) => (
                          <ExecutionRow
                              execution={execution}
                              showExecutionVideo={this.showExecutionVideo}
                              key={index}
                              headers={headers}
                              toggleSwipableTaskPanel={this.props.toggleSwipableTaskPanel}
                              {...this.props}
                          />
                      ))
                    : _tasks?.map((execution, index) => (
                          <ExecutionRow
                              execution={execution}
                              showExecutionVideo={this.showExecutionVideo}
                              key={index}
                              headers={headers}
                              toggleSwipableTaskPanel={this.props.toggleSwipableTaskPanel}
                              {...this.props}
                          />
                      ));

            if (executionsLoading && !executionsPolling) {
                content.push(<Typography className={classes.loadingState}>Loading...</Typography>);
            }
        } else {
            content = (
                <Typography className={classes.loadingState}>
                    {executionsLoading && !executionsPolling ? 'Loading...' : 'No tasks executed'}
                </Typography>
            );
        }

        return (
            <Dialog
                disableRestoreFocus
                open={open}
                onClose={this.handleClose}
                classes={{ paper: classes.dialogContainer }}
                aria-labelledby="simple-dialog-title"
            >
                <DialogTop title="Execution" closeIconAction={() => this.props.toggleModal()} />
                <div className={classes.headerBottom}>
                    
                    <div className={classes.actionsContent}>
                        <div className={classes.actionIconContainer}>
                            {sortedType === 'sortAsc' ? (
                                <Tooltip data="Sort Ascending">
                                    <SortRoundedIcon className={classes.sortIcon} style={{transform: "rotate(180deg)"}} alt="SortDownIcon" onClick={this.sortOuter}/>
                                </Tooltip>
                            ) : (
                                <Tooltip data="Sort Descending">
                                    <SortRoundedIcon className={classes.sortIcon} onClick={this.sortOuter} />
                                </Tooltip>
                            )}
                            <FormControl style={{ marginBottom: '5px', width: '200px' }}>
                                <SelectCustom
                                    value={searchedInput}
                                    onChange={(data) => {
                                        this.changeSelection(data);
                                    }}
                                    className={classes.selectCustom}
                                    isBorder
                                    options={convertedItems}
                                    isMultiSelect
                                    isSearchForTaskpane
                                    readOnly={false}
                                    placeholder={!checkArrayLength(selectedExecution) ? 'Search Execution' : ''}
                                    styleWithIcon={{ display: 'flex', width: 'calc(100% - 30px)', alignItems: 'center', marginLeft: 30 }}
                                    startAdornment={<Search style={{ position: 'absolute' }} />}
                                />
                            </FormControl>
                        </div>
                        <div className={classes.actionIconContainer}>
                            <Tooltip data="Filter">
                                <FontAwesomeIcon icon={faFilter} aria-label="faFilter" id="faFilter" className={classes.filterIcon} />
                            </Tooltip>
                            <FormControl style={{ marginBottom: '5px', width: '100px' }}>
                                <Select
                                    value={defaultValue}
                                    onChange={(event) => this.handleChange(event)}
                                    disabled={false}
                                    className={classes.menuItem2}
                                    style={{paddingLeft:"10px"}}
                                >
                                    <MenuItem value="All" className={classes.menuItem}>
                                        All
                                    </MenuItem>
                                    <MenuItem value="Executed" className={classes.menuItem}>
                                        Success
                                    </MenuItem>
                                    <MenuItem value="Error" className={classes.menuItem}>
                                        Failed
                                    </MenuItem>
                                    <MenuItem value="InProcess" className={classes.menuItem}>
                                        In Progress
                                    </MenuItem>
                                    <MenuItem value="Queued" className={classes.menuItem}>
                                        Queued
                                    </MenuItem>
                                    <MenuItem value="Stopped" className={classes.menuItem}>
                                        Stopped
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <Table>
                    <EnhancedTableHead headers={headers} />
                    <TableBody isTableBodyStyles={true} height={50} style={{ maxHeight: 'calc(44vh)' }} className={classes.tableBody} key="row" onScroll={this.changeScroll}>
                        {content}
                    </TableBody>
                </Table>
            </Dialog>
        );
    }
}

ExecutionModal.propTypes = {};

const mapStateToProps = (state) => {
    return {
        // project reducer
        executionTasksStatus: state.projectReducer.executionTasksStatus,
        executionData: state.projectReducer.executions,
        executionsLoading: state.projectReducer.executionsLoading,
        executionTaskStatusPageNo: state.projectReducer.executionTaskStatusPageNo,
        executionsPolling: state.projectReducer.executionsPolling,
        isExecutionTaskStatusLastPage: state.projectReducer.isExecutionTaskStatusLastPage,
        isLoading: state.projectReducer.isLoading,
        projects: state.projectReducer.projects,
        forceOpenCloseRightPanel: state.projectReducer.forceOpenCloseRightPanel,
        // general reducera
        // query: state.generalReducer.queryValue,
        // task reducer
        disabledProjects: state.projectsReducer.disabledProjects,
        showDisabledProjects: state.projectsReducer.showDisabledProjects,
        isFavourite: state.projectReducer.isFavourite,
        favouriteProjects: state.projectReducer.favouriteProjects,
        projectId: state.taskReducer.projectId,
        testCaseId: state.taskReducer.testCaseId,
        openTaskPanel: state.generalReducer.openTaskPanel,
        selectedExecution: state.selectedExecutionReducer.selectedExecution,
        selectedExePreviousSortedType: state.selectedExecutionReducer.selectedExePreviousSortedType,
        searchedInput: state.selectedExecutionReducer.searchedInput,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getExecutionTasks: (...args) => dispatch(ProjectActions.getExecutionTasks(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        toggleSidebar: () => dispatch(ProjectActions.toggleTaskList()),
        getProjectByName: (...args) => dispatch(ProjectActions.getProjectByName(...args)),
        querySave: (data) => dispatch(GeneralActions.querySave(data)),
        toggleSwipableTaskPanel: (...args) => dispatch(GeneralActions.toggleSwipableTaskPanel(...args)),
        selectionExecutionAction: (data) => dispatch(selectionExecutionAction(data)),
        sortSelectedExecution: (data) => dispatch(sortSelectedExecution(data)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionModal));
