// imports
import { checkArrayLength } from './utils';

class Account {
    getAccount = (accounts, accountId) => {
        let account = {};
        if (checkArrayLength(accounts)) {
            account = accounts.find((acc) => acc.accountId === accountId);
        }
        return account;
    };

    // eslint-disable-next-line class-methods-use-this
    get default() {
        return {
            daysLimit: '6',
            selectedDateSpan: 'months',
        };
    }
}

const AccountUtils = new Account();
export { AccountUtils };
