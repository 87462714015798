/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PasswordTextarea from 'password_textarea';
import Table from '../Table/Table';
import TableBody from '../Table/TableBody';
import TableCell from '../Table/TableCell';
import TableRow from '../Table/TableRow';
import { resizeAllCols, checkKeyInObject, checkArrayLength } from '../../utils/utils';
import { GeneralActions } from '../../store/actions';
import PreviewTestDataBody from './PreviewTestDataBody';
import { EnhancedTableHead } from '../EnhancedTable/EnhancedTableHead';
import { BLACK_FONT } from '../../common/cssConstants';

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        borderRadius: '5px',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.2)',
    },
    table: {
        // minWidth: 600,
        overflowX: 'auto !important',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        alignItems: 'flex-start !important',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    rowData1: {
        // padding:12,
        border: '1px solid #9a9a9a',
        textAlign: '-webkit-center',
        // marginTop: 1
    },
    rowData: {
        border: '1px solid #9a9a9a',
    },
    subHeading: {
        fontWeight: 500,
        fontSize: 14,
    },
    text: {
        fontSize: '12px',
    },
    customInputMultiline: {
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: '0px',
        '&>div>textarea': {
            resize: 'none',
            whiteSpace: 'pre',
            overflow: 'hidden',
            fontSize: '12px',
            color: BLACK_FONT,
            fontWeight: 400,
        },
    },
    passwordArea: {
        width: '100%',
        margin: 0,
        border: 0,
        display: 'inline-flex',
        padding: 0,
        position: 'relative',
        minWidth: 0,
        flexDirection: 'column',
        overflowX: 'hidden',
        overflowY: 'auto',
        '&>div': {
            width: '100%',
            padding: '6px 0 7px',
            display: 'inline-flex',
            position: 'relative',
            fontSize: '1rem',
            lineHeight: '1.1875em',
            '&>textarea': {
                width: '100%',
                border: 0,
                margin: 0,
                display: 'block',
                minWidth: 0,
                flexGrow: 1,
                boxSizing: 'content-box',
                background: 'none',
                verticalAlign: 'middle',
                '-webkit-tap-highlight-color': 'transparent',
                outline: 0,
                resize: 'none',
                whiteSpace: 'pre',
                overflow: 'hidden',
                fontSize: '12px',
                color: BLACK_FONT,
                fontWeight: 400,
            },
        },
    },
    body: {
        maxWidth: '100% !important',
    },
});
class PreviewTestData extends React.Component {
    constructor() {
        super();
        this.colsWidth = {};
        this.state = {
            testDataPreview: [],
            headers: [],
        };
    }

    /* Component lifecycle start */
    UNSAFE_componentWillMount() {
        this.updateData();
    }

    componentDidMount() {
        this.props.toggleDisableQuery(true);
        this.updateData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateData(nextProps);
    }

    componentDidUpdate() {
        resizeAllCols(this.colsWidth, 'm_');
    }

    componentWillUnmount() {
        this.props.toggleDisableQuery(false);
    }
    /* Component lifecycle end */
    clearSelection(e) {
        if (e.detail > 2) {
            if (document.selection && document.selection.empty) {
                document.selection.empty();
            } else if (window.getSelection) {
                window.getSelection().removeAllRanges();
            }
        }
    }
    updateData = (props = this.props) => {
        const { testData } = props;
        const testDataPreview = [];
        if (checkKeyInObject(testData, 'data') && checkKeyInObject(testData, 'order') && testData.data && testData.order) {
            const data = typeof testData.data === 'object' ? testData.data : JSON.parse(testData.data);
            const order = typeof testData.order === 'object' ? testData.order : JSON.parse(testData.order);
            if (checkArrayLength(order)) {
                order.forEach((key) => {
                    if (checkKeyInObject(data, key)) {
                        testDataPreview.push([key, data[key]]);
                    }
                });
            }
        }
        const headers = [];
        if (testDataPreview) {
            testDataPreview.forEach((item, index) => {
                headers.push({
                    id: index,
                    sortable: false,
                    numeric: false,
                    disablePadding: false,
                    label: `${item[0]}`.indexOf('&&hiddenCol&&') === 0 ? '' : item[0],
                    styles: { paddingLeft: 10, minWidth: 60 },
                    width: `calc(100% / ${testDataPreview.length})`,
                });
            });
        }
        this.setState({ testDataPreview, headers });
    };

    render() {
        const { classes, modalType, testData, handleEdit, handleKeyUp,handlePasteEvent } = this.props;
        const { testDataPreview, headers } = this.state;
        return (
            <div style={{ display: 'inline-block', minWidth: '100%' }}>
                <div
                    className={classes.root}
                    style={{ width: `${testDataPreview ? `${testDataPreview.length * 150}px` : 'auto'}`, minWidth: '100%' }}
                >
                    <Table
                        dataTypePrefix="m_"
                        style={{ width: `${testDataPreview ? `${testDataPreview.length * 150}px` : 'auto'}`, minWidth: '100%' }}
                    >
                        <EnhancedTableHead
                            headers={headers}
                            style={{ paddingRight: 0, minWidth: 150 }}
                            colsWidth={this.colsWidth}
                            elementPrefix="m_"
                            resizeable
                        />
                        <TableBody dataTypePrefix="m_" style={{ maxHeight: 'unset', overflow: 'hidden' }} className={classes.body}>
                            {modalType === 'edit' ? (
                                <TableRow style={{ height: 'auto', cursor: 'default' }} dataTypePrefix="m_">
                                    {testDataPreview &&
                                        testDataPreview.map((valueDt, index) => (
                                            <TableCell
                                                style={{ backgroundColor: '#FAFAFA' }}
                                                className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                width={headers[index].width}
                                                data-resize={`m_element${index}_c`}
                                                dataTypePrefix="m_"
                                                key={index}
                                            >
                                                <div style={{ width: '100%' }}>
                                                    <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 24, marginBottom: 24 }}>
                                                        {valueDt[0].toLowerCase().includes('password') ? (
                                                            <div className={classes.passwordArea}>
                                                                <div>
                                                                    <PasswordTextarea
                                                                        rows={Array.isArray(valueDt[1]) ? valueDt[1].length : null}
                                                                        value={
                                                                            Array.isArray(valueDt[1])
                                                                                ? valueDt[1].length > 1
                                                                                    ? valueDt[1].join('\n')
                                                                                    : valueDt[1][0]
                                                                                : ''
                                                                        }
                                                                        onChange={(v) => {
                                                                            handleEdit(valueDt[0], v);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <TextField
                                                                type="text"
                                                                aria-label="Name"
                                                                id="name"
                                                                fullWidth
                                                                onClick={(e) => { this.clearSelection(e) }}
                                                                multiline={true}
                                                                onKeyUp={(e) => { handleKeyUp(e) }}
                                                                InputProps={{ disableUnderline: true }}
                                                                name="name"
                                                                className={classes.customInputMultiline}
                                                                rows={Array.isArray(valueDt[1]) ? valueDt[1].length : null}
                                                                value={
                                                                    Array.isArray(valueDt[1])
                                                                        ? valueDt[1].length > 1
                                                                            ? valueDt[1].join('\n')
                                                                            : valueDt[1][0]
                                                                        : ''
                                                                }
                                                                onChange={(e) => {
                                                                    handleEdit(valueDt[0], e.target.value);
                                                                }}
                                                                onPaste={(e)=>{handlePasteEvent(e)}}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </TableCell>
                                        ))}
                                </TableRow>
                            ) : (
                                <PreviewTestDataBody testData={testData} headers={headers} />
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    }
}

PreviewTestData.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    testData: PropTypes.instanceOf(Object),
};

PreviewTestData.defaultProps = {
    testData: {},
};

const mapDispatchToProps = (dispatch) => {
    return {
        // General Action
        toggleDisableQuery: (...args) => dispatch(GeneralActions.toggleDisableQuery(...args)),
    };
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(PreviewTestData));
