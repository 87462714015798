import { ProjectsActions, SelectedTestCaseActions, TestStepActions, ProjectActions } from '../../../../../store/actions';

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        selectedTestCase: state.projectReducer.selectedTestCase,
        autoScroll: state.selectedTestCaseReducer.autoScroll,
        creationMode: state.selectedTestCaseReducer.creationMode,
        selectedSteps: state.selectedTestCaseReducer.selectedSteps,
        shadowDom: state.selectedTestCaseReducer.shadowDom,
        shadowDomEnabled: state.projectReducer.selectedProject.shadowDomEnabled,
        testSteps: state.selectedTestCaseReducer.testSteps,
        instrNumArray: state.selectedTestCaseReducer.instrNumArray,
        listView: state.selectedTestCaseReducer.listView,
        isWsRunning: state.projectReducer.isWsRunning,
        debugPointList: state.projectsReducer.debugPointList,
        actionType: state.selectedTestCaseReducer.actionType,
        isSaveRequestPending: state.testStepReducer.isLoading,
        saveBtnPressLoader: state.selectedTestCaseReducer.saveBtnPressLoader,
        wsRunningCaseData: state.projectReducer.wsRunningCaseData,
        isGenerateBtnClicked: state.projectReducer.isGenerateBtnClicked,
        selectedProject: state.projectReducer.selectedProject,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleAutoScroll: () => dispatch(SelectedTestCaseActions.toggleAutoScroll()),
        toggleShadowDom: (val) => dispatch(SelectedTestCaseActions.toggleShadowDom(val)),
        toggleCreationMode: () => dispatch(SelectedTestCaseActions.toggleCreationMode()),
        downloadLogs: (...args) => dispatch(TestStepActions.downloadLogs(...args)),
        toggleListView: (...args) => dispatch(SelectedTestCaseActions.toggleListView(...args)),
        setAllCacheXpaths: (...args) => dispatch(SelectedTestCaseActions.setAllCacheXpaths(...args)),
        updatedebugPointList: (...args) => dispatch(ProjectsActions.updatedebugPointList(...args)),
        updateTestStepsObject: (...args) => dispatch(SelectedTestCaseActions.updateTestStepsObject(...args)),
        getProjectDetails: (projectId) => dispatch(ProjectActions.getProjectDetails(projectId)),
    };
};

export { mapStateToProps, mapDispatchToProps };
