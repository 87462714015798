import React from 'react';
import { ActionTypes } from '../constants';
import { FlowMiddleware } from '../middleware';
import { ProjectActions } from '.';

class FlowActions {
    static clearFlows() {
        return {
            type: ActionTypes.CLEAR_FLOWS,
        };
    }

    /**
     * @param {Object} obj
     */
    static createNewFlow(obj, callback) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.CREATE_FLOW_DATA });
            try {
                const { status } = await FlowMiddleware.createNewFlow(obj);

                if (status === 200) {
                    dispatch(ProjectActions.increaseProjectRelatedCount('flowsCount'));
                    callback();
                    // callback to clear form and close modal
                    return dispatch({
                        type: ActionTypes.CREATE_FLOW_DATA_SUCCESS,
                        payload: { message: 'Flow Created Successfully' },
                    });
                }
                throw new Error('error');
            } catch (error) {
                let _error = 'Failed to create flow';
                if (error === 'Duplicate flow name' || error.message === 'Duplicate flow name') {
                    _error = 'Duplicate flow name is not allowed';
                }
                dispatch({
                    type: ActionTypes.CREATE_FLOW_DATA_FAILURE,
                    payload: { message: _error },
                });
            }
            return null;
        };
    }

    /**
     * @param {Object} obj
     */
    static cloneFlow(obj, callback = () => {}) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.CLONE_FLOW });
            try {
                const { status } = await FlowMiddleware.cloneFlow(obj);

                if (status === 200) {
                    // callback to clear form and close modal
                    callback();
                    dispatch(ProjectActions.increaseProjectRelatedCount('flowsCount'));
                    return dispatch({
                        type: ActionTypes.CLONE_FLOW_SUCCESS,
                        payload: { message: 'Flow Cloned Successfully' },
                    });
                }
                throw new Error('error');
            } catch (error) {
                let _error = 'Failed to clone flow';
                if (error === 'Duplicate flow name' || error.message === 'Duplicate flow name') {
                    _error = 'Duplicate flow name is not allowed';
                }
                dispatch({
                    type: ActionTypes.CLONE_FLOW_FAILURE,
                    payload: { message: _error },
                });
            }
            return null;
        };
    }

    /**
     * @param {Object} obj
     */
    static updateFlow(obj, callback) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.UPDATE_FLOW_DATA });
            try {
                const { status } = await FlowMiddleware.updateFlow(obj);

                if (status === 200) {
                    callback();
                    // callback to clear form and close modal
                    return dispatch({
                        type: ActionTypes.UPDATE_FLOW_DATA_SUCCESS,
                        payload: { message: 'Flow Updated Successfully' },
                    });
                }
                throw new Error('error');
            } catch (error) {
                dispatch({
                    type: ActionTypes.UPDATE_FLOW_DATA_FAILURE,
                    payload: { message: 'Failed to update flow' },
                });
            }
            return null;
        };
    }

    static deleteFlow(obj, callback = () => {}) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.DELETE_FLOW });
            try {
                const { status } = await FlowMiddleware.deleteFlow(obj);
                if (status === 200) {
                    callback();
                    dispatch(FlowActions.getFlowDataByProject(obj.projectId));
                    dispatch(ProjectActions.decreaseProjectRelatedCount('flowsCount'));
                    // callback to clear form and close modal
                    return dispatch({
                        type: ActionTypes.DELETE_FLOW_SUCCESS,
                        payload: {
                            message: (
                                <span>
                                    Flow <b>{obj.name}</b> deleted successfully
                                </span>
                            ),
                        },
                    });
                }
                throw new Error('error');
            } catch (error) {
                dispatch({
                    type: ActionTypes.DELETE_FLOW_FAILURE,
                    payload: { message: 'Failed to delete flow' },
                });
            }
            return null;
        };
    }

    /**
     * @param {string} accountId
     * @param {string} projectId
     */
    static getFlowDataByProject(projectId) {
        return async (dispatch, getState) => {
            dispatch({ type: ActionTypes.GET_FLOW_DATA });
            try {
                const {
                    user: { accountId },
                } = getState().authReducer;
                const data = await FlowMiddleware.getFlowData(accountId, projectId);

                if (data) {
                    return dispatch({ type: ActionTypes.GET_FLOW_DATA_SUCCESS, payload: data });
                }
                throw new Error('error');
            } catch (error) {
                dispatch({ type: ActionTypes.GET_FLOW_DATA_FAILURE });
            }
            return null;
        };
    }

    /**
     * @param {string} accountId
     */
    static getFlowDataByAccount(accountId) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.GET_FLOW_DATA });
            try {
                const data = await FlowMiddleware.getFlowData(accountId);

                if (data) {
                    return dispatch({ type: ActionTypes.GET_FLOW_DATA_SUCCESS });
                }
                throw new Error('error');
            } catch (error) {
                dispatch({ type: ActionTypes.GET_FLOW_DATA_FAILURE });
            }
            return null;
        };
    }

    static cloneVariable(obj, callback = () => {}) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.CLONE_VARIABLE });
            try {
                const data = await FlowMiddleware.cloneVariable(obj);
                if (data.data && data.data.length) {
                    callback(true);
                    return dispatch({
                        type: ActionTypes.CLONE_VARIABLE_FAILED,
                        payload: {
                            message: `${data.data.length === obj.vars.length ? 'V' : 'Some v'}ariable${
                                data.data.length > 1 ? 's' : ''
                            } already exists in selected project.`,
                        },
                    });
                }
                callback();
                return dispatch({
                    type: ActionTypes.CLONE_VARIABLE_SUCCESS,
                    payload: { message: 'Successfully clone variable' },
                });
            } catch (err) {
                callback(true);
                dispatch({ type: ActionTypes.CLONE_VARIABLE_FAILED, payload: { message: 'Error in cloning variable' } });
            }
            return null;
        };
    }

    static clearErr() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.CLEAR_CLONE_ERR });
        };
    }

    static cloneAllVariables(obj, callback = () => {}) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.CLONE_ALL_VARIABLES });
            try {
                const data = await FlowMiddleware.cloneAllVariables(obj);
                if (data.data && data.data.length) {
                    return dispatch({
                        type: ActionTypes.CLONE_ALL_VARIABLES_SUCCESS,
                        payload: { message: '', notClonedVars: data.data },
                    });
                }
                callback();
                return dispatch({
                    type: ActionTypes.CLONE_ALL_VARIABLES_SUCCESS,
                    payload: { message: 'Successfully clone all variables', notClonedVars: null },
                });
            } catch (err) {
                dispatch({ type: ActionTypes.CLONE_ALL_VARIABLES_FAILED, payload: { message: 'Error in cloning variables' } });
            }
            return null;
        };
    }
}
export default FlowActions;
