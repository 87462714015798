import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CloseOnEscape from 'react-close-on-escape';
import Close from '@material-ui/icons/Close';
import {
    DIALOG_ROOT,
    MODAL_FOOTER,
    MODAL_SUBMIT_BUTTON,
    MODAL_CANCEL_BUTTON,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_HEADER_CLOSE_ICON,
    BLACK_FONT,
} from '../../../common/cssConstants';

const styles = () => ({
    buttonStyle: {
        padding: '2px 8px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#1067ce',
        borderRadius: '6px',
        textTransform: 'capitalize',
        marginRight: '10px',
    },
    cancelButton: {
        padding: '2px 8px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#1067ce',
        borderRadius: '6px',
        textTransform: 'capitalize',
    },
    dialogRoot: {
        ...DIALOG_ROOT,
        '&>div': {
            borderRadius: '0px',
        },
    },
    dialogActions: {
        overflow: 'hidden',
        minWidth: 370,
        padding: '0 30px !important',
        maxHeight: 'calc(100vh - 290px)',
        overflowY: 'auto',
    },
    heading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '30px 45px 30px 30px',
        '& > h2': {
            fontSize: 18,
            color: BLACK_FONT,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        },
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AlertModal extends Component {
    state = {
        isDisabled: false,
    };

    render() {
        const { onClickAlertDialogClose, open, heading, msg, cancelButtonText, confirmButtonText, classes, isOnlyShow } = this.props;
        const { isDisabled } = this.state;
        return (
            <div
                aria-hidden
                onKeyDown={(e) => {
                    if (e.keyCode === 27) {
                        onClickAlertDialogClose(false);
                    } else if (e.keyCode === 13) {
                        onClickAlertDialogClose(true);
                    }
                }}
            >
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={classes.dialogRoot}
                >
                    {heading ? (
                        <div>
                            <div className={classes.heading}>
                                <Typography variant="title">{heading}</Typography>
                            </div>
                            <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                                <Close
                                    style={MODAL_HEADER_CLOSE_ICON}
                                    onClick={() => {
                                        this.setState({ isDisabled: true });
                                        onClickAlertDialogClose(true);
                                    }}
                                    aria-label="closeIcon"
                                    id="closeIcon"
                                />
                            </div>
                        </div>
                    ) : null}
                    <DialogContent className={classes.dialogActions}>
                        <Typography style={{ fontSize: 15 }}>{msg && msg !== '' ? msg : 'Are you sure you want to delete?'}</Typography>
                    </DialogContent>
                    <DialogActions style={MODAL_FOOTER}>
                        {isOnlyShow ? null : (
                            <CloseOnEscape onEscape={() => onClickAlertDialogClose(false)}>
                                <Button
                                    style={MODAL_CANCEL_BUTTON}
                                    onClick={() => onClickAlertDialogClose(false)}
                                    variant="contained"
                                    color="secondary"
                                >
                                    {cancelButtonText || 'No'}
                                </Button>
                            </CloseOnEscape>
                        )}
                        <Button
                            style={MODAL_SUBMIT_BUTTON}
                            onClick={() => {
                                this.setState({ isDisabled: true });
                                onClickAlertDialogClose(true);
                            }}
                            variant="outlined"
                            color="secondary"
                            disabled={isDisabled}
                        >
                            {confirmButtonText || 'Yes'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(AlertModal);
