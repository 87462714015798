import { ActionTypes } from '../constants';

const initialState = {
    isLoading: false,
    error: null,
    success: null,
};

const tunnelReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.TOGGLE_TUNNEL:
            return {
                ...state,
                error: null,
                isLoading: true,
            };
        case ActionTypes.TOGGLE_TUNNEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.TOGGLE_TUNNEL_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload.message,
            };

        default:
            return state;
    }
};

export default tunnelReducer;
