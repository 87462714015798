import { createStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import * as React from 'react';
import FileDocumentOutline from 'mdi-material-ui/FileDocumentOutline';
import PlayBoxMultiple from 'mdi-material-ui/PlayBoxMultiple';
import { Link } from 'react-router-dom';
//import { USER_NAME, AUTONOMIQ_COPYRIGHT, AUTONOMIQ_VERSION, AUTONOMIQ_WEBSITE, AUTONOMIQ_YEAR } from '../../../common/constants';
import { BLACK_FONT, NO_DECORATION,  } from '../../common/cssConstants';
import { checkKeyInObject } from '../../utils';
import {logout as segmentLogout , track} from '../../services/Segment';
import { SEGMENT_EVENT, USER_NAME } from '../../common/constants';

const styles = createStyles({
    avatar: {
        backgroundColor: '#1168CD',
        height: 25,
        width: 25,
        marginRight: 10,
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 20px 0px 12px;',
    },
    listItemStyle1: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 20px 12px 12px;',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#e1e1e1',
        },
    },
    listItemStyle2: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 20px 12px 42px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#e1e1e1',
        },
    },
    typography: {
        color: '#ffffff',
    },
    viewProfileButton: {
        minHeight: 28,
        padding: '3px 14px',
        '&>span': {
            fontSize: 12,
            color: '#1168CD',
            letterSpacing: 'normal',
        },
    },
    blueOutlinedButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        maxWidth: 110,
        borderRadius: '3px',
        backgroundColor: '#EBF0F3',
        marginRight: 10,
        border: '1px solid #1168CD',
        '&:hover': {
            backgroundColor: '#e1e1e1',
        },
    },
    primaryText: {
        fontSize: 12,
        color: BLACK_FONT,
    },
    secondaryText: {
        fontSize: 12,
        color: '#4A4A4A',
    },
    textContainer: {
        padding: 0,
    },
    viewProfileButtonCont: {
        padding: '10px 35px',
        marginLeft: 10,
    },
    containerViewProfile: {
        boxSizing: 'border-box',
        borderBottom: '1px solid #ECECEC',
        backgroundColor: '#EBF0F3',
    },
    container: {
        boxSizing: 'border-box',
        borderBottom: '1px solid #ECECEC',
    },
    communityText: {
        fontSize: 12,
        color: BLACK_FONT,
        margin: 0,
    },
    logoutText: {
        fontSize: 12,
        fontWeight: 500,
        color: '#1168CD',
        margin: 0,
    },
    groupWorkIcon: {
        height: 20,
        width: 20,
        marginRight: 10,
        color: '#8898A8',
    },
    mainDiv:{
        display:'block',
    },
    noDecoration: NO_DECORATION,
});

class UserMenu extends React.Component {
    logout = () => {
        this.props.logout();
        segmentLogout();

        const {  user } = this.props;
        


    };

    toLetterAvatar(name) {
        let letters = '';
        const nameArr = name.split(' ').splice(0, 2);
        if (nameArr.length) {
            nameArr.forEach((part) => {
                letters += this.splitFirstLetter(part);
            });
        }
        return letters;
    }

    splitFirstLetter = (value = '') => {
        let val;
        if (value !== '') {
            val = value.charAt(0).toUpperCase();
        }
        return val;
    };

    render() {
        const { classes, user } = this.props;
        console.log("user",user);
        let AdminFlag = false; // 'false' is indicate user has SDET role
        let AppuserFlag = false; // 'false' is indicate user is not an appuser
        if (user && user.role === 'ADMIN') {
            // change the flag value if user has ADMIN role
            AdminFlag = true;
            if (user.name === USER_NAME) {
                AppuserFlag = true;
            }
        }

        const viewProfileLink =
            checkKeyInObject(user, 'role', 'bool', false) && user.role.toLocaleUpperCase() === 'SDET' ? '/profile' : '/admin/profile';
        return (
            <div className={classes.mainDiv}>
                <div className={classes.containerViewProfile}>
                    <div className={classes.listItemStyle}>
                        <Avatar className={classes.avatar}>
                            <Typography className={classes.typography}>{this.toLetterAvatar(user.name)}</Typography>
                        </Avatar>
                        <ListItemText
                            primary={user.name}
                            secondary={user.email}
                            className={classes.textContainer}
                            classes={{ primary: classes.primaryText, secondary: classes.secondaryText }}
                        />
                    </div>
                    <div className={classes.viewProfileButtonCont}>
                        <div className={classes.blueOutlinedButton}>
                            <Link to={viewProfileLink} className={classes.noDecoration}>
                                <Button className={classes.viewProfileButton}>View Profile</Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div >
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.youtube.com/playlist?list=PL67l1VPxOnT4Y7M-hPRPU9xKSMtDIxfzD"
                        className={`${classes.noDecoration} ${classes.listItemStyle1}`}
                    >
                        <PlayBoxMultiple className={classes.groupWorkIcon} />
                        <p className={classes.communityText}>Video Tutorials</p>
                    </a>
                </div>
                <div className={classes.container}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://docs.saucelabs.com/dev/low-code/"
                        className={`${classes.noDecoration} ${classes.listItemStyle1}`}
                    >
                        <FileDocumentOutline className={classes.groupWorkIcon} />
                        <p className={classes.communityText}>View Documentation</p>
                    </a>
                </div>
                {AdminFlag ? (
                <div className={classes.container}>
                        <Link to={`/admin/${AppuserFlag ? 'accounts' : 'users'}`} className={`${classes.noDecoration} ${classes.listItemStyle1}`}>
                            <p className={classes.communityText}>{`${AppuserFlag ? 'Account Management' : 'User Management'}`}</p>
                        </Link>
                        <Link to="/admin/preferences"  className={`${classes.noDecoration} ${classes.listItemStyle1}`}>
                            <p className={classes.communityText}>Preferences</p>
                        </Link>
                </div> ) : null }
                <div aria-hidden className={classes.listItemStyle2} onClick={this.logout}>
                    <p className={classes.logoutText}>Logout</p>
                </div>
            </div>
        );
    }
}

UserMenu.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    user: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(UserMenu);
