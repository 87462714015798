import { ActionTypes } from '../constants';
import { TunnelMiddleware } from '../middleware';

class TunnelActions {
    /**
     *
     * @param {Object} obj: { flag }
     */
    static toggleTunnel(obj) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.TOGGLE_TUNNEL });
            return TunnelMiddleware.toggleTunnel(obj)
                .then((result) => {
                    return dispatch({
                        type: ActionTypes.TOGGLE_TUNNEL_SUCCESS,
                        payload: { tunnelStatus: result.isTunnelOn },
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.TOGGLE_TUNNEL_FAILURE,
                        payload: { message: 'failed to toggle tunnel' },
                    });
                });
        };
    }
}

export { TunnelActions };
