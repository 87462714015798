import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import { BLACK_FONT } from '../../../common/cssConstants';

const styles = (theme) => ({
    container: {
        width: '100%',
        padding: '0 30px',
        minWidth: '300px',
    },
    formControl: {
        width: '100%',
        flexDirection: 'column',
    },
    dataName: {
        maxWidth: 'calc(100% - 96px)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.only('xs')]: {
            width: 0,
        },
    },
    keyHeading: {
        width: 92,
        float: 'left',
        fontSize: 12,
        [theme.breakpoints.only('xs')]: {
            width: 72,
        },
    },
    dataValue: {
        float: 'left',
        fontSize: 12,
        fontWeight: 'bold',
    },
    noWrap: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block',
        overflow: 'hidden',
    },
    mainHeading: {
        width: '100%',
        float: 'left',
        fontSize: 12,
        color: BLACK_FONT,
        marginBottom: 20,
    },
});

const DeleteVariableModal = ({ classes, text, title, value }) => {
    const splittedValue = value && value.length > 0 ? value.trim().split('\n') : [];
    const CustomeTypography = (_text) => <Typography className={classes.keyHeading}>{_text}</Typography>;

    return (
        <div className={classes.container}>
            <Typography className={classes.mainHeading}>{text}</Typography>
            <FormControl className={classes.formControl}>
                <div
                    style={{
                        width: '100%',
                        height: 30,
                    }}
                >
                    {CustomeTypography('Variable')}
                    <Typography className={[classes.dataValue, classes.dataName].join(' ')}>{title}</Typography>
                </div>
                <div
                    style={{
                        width: '100%',
                    }}
                >
                    {CustomeTypography('Value')}
                    <Typography className={classes.dataValue}>
                        {splittedValue.map((element, index) => (
                            <span className={classes.noWrap} key={index}>
                                {element}
                            </span>
                        ))}
                    </Typography>
                </div>
            </FormControl>
        </div>
    );
};
DeleteVariableModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};
export default withStyles(styles)(DeleteVariableModal);
