import { ActionTypes } from '../constants';

class ModalActions {
    // @params modalName:string, route:string, url:string, modalData:any
    /**
     * @param {string} modalName - which modal need to open.
     * @param {string} route - route.
     * @param {string} videoUrl - url of video to show in modal.
     * @param {object} modalData - It could be null or object.
     */
    static toggleModal(modalName, route, videoUrl, modalData, forceOpen = false, close = false,modalState=null) {
        modalName = modalName || '';
        return {
            type: ActionTypes.TOGGLE_MODAL,
            payload: { modalName, route, videoUrl, modalData, forceOpen, close,modalState },
        };
    }

    static toggleSnackBar(
        snackBarMessage,
        snackbarLink = '',
        isSuccess = false,
        autoHideDuration = 4000,
        isWarning = false,
        forceOpen = false,
        openDownloadProgress = false,
    ) {
        return {
            type: ActionTypes.TOGGLE_SNACKBAR,
            payload: {
                autoHideDuration,
                forceOpen,
                isSuccess,
                isWarning,
                openDownloadProgress,
                snackbarLink,
                snackBarMessage,
            },
        };
    }

    static updateProjectDownloadProgress(key, progress, fileName) {
        return {
            type: ActionTypes.UPDATE_PROJECT_DOWNLOAD_PROGRESS,
            payload: { key, progress, fileName },
        };
    }

    static clearSnackbar() {
        return {
            type: ActionTypes.CLEAR_SNACKBAR,
        };
    }

    static closeInstallationModal() {
        return {
            type: ActionTypes.CLOSE_INSTALLATION_MODAL,
        };
    }
}
export default ModalActions;
