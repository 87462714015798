import { green } from '@material-ui/core/colors';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { connect } from 'react-redux';

import { ModalActions } from '../../store/actions';
import DeleteAlertModal from '../../features/modal/Delete/DeleteAlertModal';
import DownloadSnackBar from './DownloadSnackBar';
import { SEGMENT_EVENT } from '../../common/constants';
import { track } from '../../services/Segment';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
    },
    success: {
        backgroundColor: green[600],
        color: theme.palette.error.contrastText,
    },
    warning: {
        backgroundColor: '#fff3cd',
        color: '#856404',
        maxWidth: '100% !important',
        '&>div': {
            fontSize: '17px',
            fontWeight: '500',
        },
    },
    close: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    CloseButton: {
        color: '#ffffff',
        fontWeight: 'bold',
    },
    linkMessage: {
        textDecoration: 'none',
        fontWeight: 'bolder',
        color: 'inherit',
        fontSize: '17px',
    },
    progressRootContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        top: '0px',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        width: '400px',
        minHeight: '70px',
        boxShadow: '0px 0px 5px 1px #888888',
        backgroundColor: '#fff',
        borderRadius: '0px 0px 5px 5px',
        overflow: 'hidden',
    },
    label: {
        margin: '0px 0px 5px 10px',
        fontSize: '14px',
    },
});

class ErrorSnackBar extends Component {
    state = {
        open: true,
        pluginId: '',
        // msg: '',
        successMsg: '',
        showMsg: false,
    };

    componentDidMount() {
        this.getStoredId();
    }

    getStoredId = async () => {
        const storedId = await localStorage.getItem('pluginId');
        this.setState({ pluginId: storedId });
    };

    handleClose = (allowRecall /* , flag */) => {
        const { toggleSnackBar } = this.props;
        this.setState({ open: false });
        if (toggleSnackBar && allowRecall) {
            toggleSnackBar();
        }
    };

    handleChangeId = ({ target: { value, id: key } }) => {
        this.setState({ [key]: value });
    };

    handleReset = async () => {
        const savedId = await localStorage.getItem('pluginId');
        if (savedId) {
            await localStorage.removeItem('pluginId');

            this.setState({ showMsg: true, successMsg: 'Successfully reset custom plugin configuration', pluginId: '' });
        } else {
            this.setState({ showMsg: true, successMsg: "You don't have custom plugin configuration" });
        }
        setTimeout(() => this.setState({ showMsg: false }), 2000);
    };

    handleCloseModal = async (flag) => {
        const { snackbarLink, /* toggleSnackBar, */ closeInstallationModal } = this.props;
        const { showRecorderInstalltion } = this.props;
        const { pluginId } = this.state;

        if (flag) {
            if (showRecorderInstalltion) {
                if (pluginId) {
                    await localStorage.setItem('pluginId', pluginId);
                    window.location.reload();
                }
            } else if (snackbarLink) {

                const eventName = SEGMENT_EVENT.RECORDER_INSTALL_PERFORMED;
                const segmentData = {}
                track(eventName,segmentData);

                window.open(snackbarLink, '_blank');
                this.handleClose(true);
            }
        } else {
            this.handleClose(true);
            closeInstallationModal();
        }
    };

    render() {
        const { classes, autoHideDuration, isSuccess, isWarning, message, snackbarLink, openDownloadProgress, showRecorderInstalltion } = this.props;
        const { open, pluginId, /* showInstallationToast, msg, */ successMsg, showMsg } = this.state;
        const stopClickHide = (message && message.includes && message.includes('plugin is not installed')) || false;
        const errorMessage = message;
        // action will only works if onClose is not present in render
        let action = (
            <IconButton
                key="close"
                aria-label="CloseIconButton"
                color="inherit"
                className={classes.close}
                onClick={
                    // () => {
                    //     this.handleClose(stopClickHide, true)
                    // }
                    this.handleClose
                }
            >
                <Close aria-label="closeIcon" id="closeIcon" />
            </IconButton>
        );
        if (!autoHideDuration) {
            action = null;
        }
        return snackbarLink || showRecorderInstalltion ? (
            <DeleteAlertModal
                handleClose={this.handleCloseModal}
                open={open}
                modalfor="Recorder"
                msg={message}
                submitBtnText={showRecorderInstalltion ? 'Install Plugin' : 'Go To Chrome Web Store'}
                resetBtn={showRecorderInstalltion}
                showRecorderInstalltion={showRecorderInstalltion}
                pluginId={pluginId}
                handleChangeId={this.handleChangeId}
                handleReset={this.handleReset}
                successMsg={successMsg}
                showMsg={showMsg}
            />
        ) : openDownloadProgress ? (
            <div className={`downloadSnackBarAnimation ${classes.progressRootContainer}`}>
                <DownloadSnackBar />
            </div>
        ) : this.props.message ? (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => {
                    if (autoHideDuration) {
                        this.handleClose(!stopClickHide);
                    }
                }}
                open={open}
                message={errorMessage}
                ContentProps={{ className: isSuccess ? classes.success : isWarning ? classes.warning : classes.root }}
                action={[action]}
                autoHideDuration={autoHideDuration}
            />
        ) : (
            null
        );
    }
}

ErrorSnackBar.defaultProps = {
    toggleSnackBar: null,
    message: 'Something went wrong',
};

ErrorSnackBar.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    autoHideDuration: PropTypes.any,
    isSuccess: PropTypes.bool.isRequired,
    message: PropTypes.any,
    snackbarLink: PropTypes.string.isRequired,
    toggleSnackBar: PropTypes.func,
};

ErrorSnackBar.defaultProps = {
    autoHideDuration: 4000,
};

const mapStateToProps = (state) => ({
    autoHideDuration: state.modalReducer.autoHideDuration,
    isSuccess: state.modalReducer.isSuccess,
    isWarning: state.modalReducer.isWarning,
    message: state.modalReducer.snackBarMessage,
    snackbarLink: state.modalReducer.snackbarLink,
    showRecorderInstalltion: state.projectReducer.showRecorderInstalltion,
    openDownloadProgress: state.modalReducer.openDownloadProgress,
});

const mapDispatchToProps = (dispatch) => ({
    toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
    closeInstallationModal: () => dispatch(ModalActions.closeInstallationModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ErrorSnackBar));
