class UserUtils {
    static headers = [
        { id: 'name', sortable: true, numeric: false, disablePadding: true, label: 'Name', locked: true, checked: true },
        { id: 'email', sortable: true, numeric: false, disablePadding: true, label: 'Email', locked: true, checked: true },
        { id: 'role', sortable: true, numeric: false, disablePadding: true, label: 'Role', locked: false, checked: true },
        { id: 'lastSignIn', sortable: true, numeric: false, disablePadding: true, label: 'Last Signin', locked: false, checked: true },
        { id: 'projectName', sortable: true, numeric: false, disablePadding: true, label: 'Project', locked: false, checked: true },
        { id: 'action', sortable: false, numeric: false, disablePadding: true, label: 'Action' },
    ];
}

export { UserUtils };
