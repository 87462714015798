import { Provider } from 'react-redux';
import * as React from 'react';
import ReactHintFactory from 'react-hint';
import { IntlProvider } from 'react-intl';

// import Main from './features/main';
import withRoot from '../config/withRoot';
import AppRoutes from '../config/AppRoutes';
import config from '../config';

import store from '../store';
import { AuthActions } from '../store/actions';
import { en, fr } from '../i18n';

const ReactHint = ReactHintFactory(React);

/**
 * For logging out user on multiple tabs
 */
window.addEventListener('storage', (e) => {
    if (e.key === 'token' && e.oldValue && !e.newValue) {
        store.dispatch(AuthActions.logout());
    }
});


const preventFocusTrappingOnModal = (event) => {
   const dialog = document.querySelector('[role="dialog"]');
   if(event && event.keyCode === 9 && dialog) {
    event.preventDefault();
   }
}
window.addEventListener('keydown',preventFocusTrappingOnModal)
class App extends React.Component {
    UNSAFE_componentWillMount() {
        // commented for DEV
        if (
            !window._env_.REACT_APP_AIQ_COMPANY_NAME ||
            window._env_.REACT_APP_AIQ_COMPANY_NAME !== config.companyName ||
            document.getElementById('title').innerHTML !== config.companyName
        ) {
            document.getElementById('title').innerHTML = config.companyName;
            document.getElementById('favicon').href = config.favicon;
        }
    }

    loadLocaleData = (locale) => {
        switch (locale) {
            case 'fr':
                return fr;
            default:
                return en;
        }
    };

    render() {
        const locale = 'en';
        const message = this.loadLocaleData(locale);
        return (
            <IntlProvider locale={locale} defaultLocale="en" messages={message}>
                <Provider store={store}>
                    <div>
                        <AppRoutes />
                        <ReactHint position="bottom" events delay={10} />
                    </div>
                </Provider>
            </IntlProvider>
        );
    }
}

export default withRoot(App);
