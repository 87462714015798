import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Cancel from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import AutoSuggestComponent from './AutoSuggestComponent';
import Tooltip from '../../../../components/Tooltip';
import DeleteAlertModal from '../../../modal/Delete/DeleteAlertModal';
import { checkArrayLength, checkObject, getValidUrl } from '../../../../utils';
import { TestStepUtils } from '../../../../utils/TestStepUtils';
import { TestStepActions, SelectedTestCaseActions } from '../../../../store/actions';

const styles = () => ({
    cancelSaveIcon: {
        border: '0.5px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: 3,
        backgroundColor: '#FFFFFF',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
        marginRight: 15,
    },
    actionIconWrapper: {
        position: 'absolute',
        right: 0,
    },
    cancelIcon: {
        fontSize: 20,
        cursor: 'pointer',
        color: '#EF6261',
    },
});

class MultilineAutoSuggest extends Component {
    state = {
        data: [],
        isCancelEditOpen: false,
        autoSuggestCount: 1,
    };

    UNSAFE_componentWillMount() {
        this.isClose = false;
    }

    componentDidMount() {
        const { autoSuggestComponentState, updateAutoSuggestCount } = this.props;
        this.updateState(autoSuggestComponentState, () => {
            updateAutoSuggestCount(this.state.autoSuggestCount);
        });
    }

    componentWillUnmount() {
        if (!this.isClose) {
            const { data, autoSuggestCount } = this.state;
            const { stepKey, storeRemoveAutoSuggestComponentState } = this.props;
            storeRemoveAutoSuggestComponentState(stepKey, {
                data,
                autoSuggestCount,
            });
        }
    }

    onCloseModal = () => {
        setTimeout(() => {
            const { data } = this.state;
            const { stepKey, storeRemoveAutoSuggestComponentState } = this.props;
            this.isClose = true;
            storeRemoveAutoSuggestComponentState(stepKey);
            for (let i = 0; i <= data.length; i++) {
                storeRemoveAutoSuggestComponentState(`${stepKey}_${i}`);
            }
        }, 300);
    };

    getData = (data, index) => {
        const temp = JSON.parse(JSON.stringify(this.state.data));
        temp[index] = data;
        if (data.testInstruction.trim()) {
            let count = this.state.autoSuggestCount;
            if (index + 1 === count) {
                count++;
            }
            this.setState({ data: temp, autoSuggestCount: count }, () => {
                this.props.updateAutoSuggestCount(this.state.autoSuggestCount);
            });
        } else {
            this.setState({ data: temp });
        }
    };

    isCancelAllowed = (cb) => {
        const { data } = this.state;
        const { index } = this.props;
        const isDataChanged = data.some((obj) => checkObject(obj) && obj.testData);
        const isInstrChanged = data.some((obj) => checkObject(obj) && obj.testInstruction);
        const isExpectedResultChanged = data.some((obj) => checkObject(obj) && obj.isExpectedResult && obj.expectedResults);
        if (isDataChanged || isInstrChanged || isExpectedResultChanged) {
            if (cb) {
                cb();
            } else {
                this.setState({
                    isCancelEditOpen: true,
                });
            }
        } else {
            TestStepUtils.onCancelAddStep(index, false); // false is passing to aassign in newStep flag
            this.onCloseModal();
        }
        this.props.updateAutoSuggestCount(0);
    };

    cancelEdit = (isConfirm) => {
        if (isConfirm) {
            const { index } = this.props;
            TestStepUtils.onCancelAddStep(index, false); // false is passing to aassign in newStep flag
            this.onCloseModal();
        }
        this.setState({
            isCancelEditOpen: false,
        });
    };

    handleSave = () => {
        const { data } = this.state;
        const { handleAdd, index } = this.props;
        const _data = [];
        data.forEach((element) => {
            if (checkObject(element)) {
                const { testInstruction, testData, expectedResults } = element;
                if (testInstruction.trim()) {
                    let validTestData = testData;
                    if (testData && testInstruction && testInstruction.trim().toLowerCase() === 'open website') {
                        validTestData = getValidUrl(testData);
                    }
                    _data.push({ testInstruction, testData: validTestData, expectedResults });
                }
            }
        });
        handleAdd(_data, index);
        this.onCloseModal();
        this.props.updateAutoSuggestCount(0);
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    render() {
        const {
            md5sum,
            index,
            testData,
            testInstruction,
            expectedResults,
            testStep,
            userVariables,
            listView,
            stepKey,
            rootStyle,
            classes,
        } = this.props;
        const { data, autoSuggestCount, isCancelEditOpen } = this.state;
        const isValid = !(
            !checkArrayLength(data) ||
            data.some(
                (obj) =>
                    checkObject(obj) &&
                    obj.testInstruction.trim() === '' &&
                    (obj.testData.trim() !== '' || (obj.isExpectedResult ? obj.expectedResults.trim() !== '' : false)),
            )
        );

        const autoSuggestComponents = [];
        for (let i = 0; i < autoSuggestCount; i++) {
            autoSuggestComponents.push(
                <AutoSuggestComponent
                    key={i}
                    md5sum={md5sum}
                    index={index}
                    testData={testData}
                    testInstruction={testInstruction}
                    expectedResults={expectedResults}
                    testStep={testStep}
                    userVariables={userVariables}
                    listView={listView}
                    stepKey={`${stepKey}_${i}`}
                    rootStyle={rootStyle}
                    getData={(_data) => {
                        this.getData(_data, i);
                    }}
                    autoFocus={i === 0}
                    isCancelAllowed={this.isCancelAllowed}
                    onCloseModal={this.onCloseModal}
                />,
            );
        }

        return (
            <div>
                <div className={classes.actionIconWrapper} style={{ top: autoSuggestComponents.length > 1 ? -14 : -25 }}>
                    <Tooltip data="Done">
                        <Done
                            className={` ${classes.cancelSaveIcon}`}
                            style={{
                                fontSize: 20,
                                cursor: !isValid ? 'not-allowed' : 'pointer',
                                color: !isValid ? '#a9a9a9' : '#1BABAB',
                            }}
                            aria-label="doneIcon"
                            id="doneIcon"
                            onClick={() => {
                                if (isValid) {
                                    this.handleSave();
                                }
                            }}
                        />
                    </Tooltip>
                    <Tooltip data="Cancel">
                        <Cancel
                            className={`${classes.cancelIcon} ${classes.cancelSaveIcon}`}
                            aria-label="cancelIcon"
                            id="cancelIcon"
                            onClick={() => this.isCancelAllowed()}
                        />
                    </Tooltip>
                </div>
                {autoSuggestComponents.map((autoSuggestComponent) => autoSuggestComponent)}
                {isCancelEditOpen ? (
                    <DeleteAlertModal
                        deleteButton="Danger"
                        open={isCancelEditOpen}
                        modalfor="EditStep"
                        handleClose={this.cancelEdit}
                        msg="Step was modified. Do you want to discard changes"
                        cancelBtnText="Continue Edit"
                        submitBtnText="Discard Changes"
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        // test step reducer
        autoSuggestComponentState: state.testStepReducer.autoSuggestComponentState[props.stepKey],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAutoSuggestCount: (...args) => dispatch(SelectedTestCaseActions.updateAutoSuggestCount(...args)),
        storeRemoveAutoSuggestComponentState: (...args) => dispatch(TestStepActions.storeRemoveAutoSuggestComponentState(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MultilineAutoSuggest));
