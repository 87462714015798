import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

// custome components
import ProjectName from './ProjectName';

const styles = () => ({
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    horizontalRowStyle: {
        height: 1,
        backgroundColor: '#d9d9d9',
        marginBottom: 10,
        border: 'none',
    },
    main: {
        marginTop: -5,
        marginBottom: -5,
    },
    projectHeaderMain: {
        marginTop: '0px',
        marginBottom: '0px',
        padding: '0px 25px',
        backgroundColor: '#f4f6f7',
        height: '30px',
    },
    projectRow: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    projectUrl: {
        color: '#1e69cc',
    },
});

function ProjectHeader(props) {
    const { header, project, classes, showDisabledProjects, user } = props;
    return (
        <div>
            <Grid className={classes.projectHeaderMain}>
                <Grid item xs>
                    <ProjectName
                        user={user}
                        showDisabledProjects={showDisabledProjects}
                        handleUpdateProject={props.handleUpdateProject}
                        handleProjectNameEdit={props.handleProjectNameEdit}
                        project={project}
                        projectId={header.projectId}
                        header={header}
                        onDelete={header.onDelete}
                        toggleModal={header.toggleModal}
                        handleFavourite={header.handleFavourite}
                        isFavourite={project.isFavourite}
                    />
                </Grid>
            </Grid>
        </div>
    );
}
ProjectHeader.propTypes = {
    // project: PropTypes.shape({}).isRequired,
    // header: PropTypes.shape({
    //     name: PropTypes.string.isRequired,
    //     date: PropTypes.instanceOf(Date).isRequired,
    //     time: PropTypes.string.isRequired,
    //     person: PropTypes.number.isRequired,
    //     badge_value: PropTypes.number.isRequired,
    //     badge_name: PropTypes.string.isRequired,
    //     onDelete: PropTypes.func.isRequired,
    //     toggleUpload: PropTypes.func.isRequired,
    // }).isRequired,
    // classes: PropTypes.shape({}).isRequired,
};
export default withStyles(styles)(ProjectHeader);
