import React, { useState } from 'react'
import AddIcon from '@material-ui/icons/AddCircle';
import cloneDeep from 'lodash/cloneDeep'
import CloseIcon from '@material-ui/icons/Close';
import { ArrowUpDown } from '../../../components/Button/ArrowUpDown';
import Tooltip from '../../../components/Tooltip';
import { IconButton, Popper, ClickAwayListener, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import DeviceSettings from './DeviceSettings';
import PlatformBrowserSelector from '../../../common/PlatformBrowserSelector/PlatformBrowserSelector'


const PopperElement = ({ classes, sauceContent, type, arrItems, title, ind, setState, disabled, sauceIDDisable }) => {

    const onAwayClick = (ind) => {
        let _sauceContent = cloneDeep(sauceContent);
        _sauceContent[ind]['idEl'] = null
        setState({ sauceContent: _sauceContent, overflowStyle: 'auto' })
    }

    const handleEnvironmentType = (e, ind) => {
        let _sauceContent = cloneDeep(sauceContent);
        _sauceContent[ind]['idEl'] = e.currentTarget
        setState({ sauceContent: _sauceContent, overflowStyle: 'clip' })
    }

    const handleTypeChange = (item, ind) => {
        let _sauceContent = cloneDeep(sauceContent);
        _sauceContent[ind]['sauceConnectId'] = item
        _sauceContent[ind]['idEl'] = null
        setState({ sauceContent: _sauceContent, sauceIDDisable: false, overflowStyle: 'auto' })
    }
    return (
        <div >
            <Typography className={classes.textFieldLabelCustom}>{title}</Typography>
            <div
                onClick={(e) => {
                    handleEnvironmentType(e, ind)
                }}
                className={disabled ? classes.disabledEnvironmentPopper : classes.environmentPopper}
            >
                <Typography className={disabled ? classes.disabled : classes.environmentTypetext}>{sauceContent[ind][type] && sauceContent[ind][type] !== '' ? sauceContent[ind][type] : arrItems[0] && arrItems[0] !== '' ? arrItems[0] : 'Tunnel id not available'}</Typography>
                <ArrowUpDown flag={Boolean(sauceContent[ind]['idEl'])} />
            </div>

            {type == 'sauceConnectId' && (!sauceContent[ind][type] || sauceContent[ind][type] == 'Select ID') && sauceIDDisable && <Typography style={{ color: 'red', fontSize: 10 }}>ID is required</Typography>}
            <div>
                <Popper
                    open={Boolean(sauceContent[ind]['idEl'])}
                    anchorEl={sauceContent[ind]['idEl']}
                    placement='bottom-start'
                    style={{
                        zIndex: 1300,
                        // width: 'fit-content',
                        // minWidth: 125
                    }}
                >
                    <ClickAwayListener onClickAway={() => onAwayClick(ind)}>
                        <div className={classes.selectBoxContainer}>
                            {arrItems.map((item, index) => (
                                <div
                                    aria-hidden
                                    key={`${index.toString()}-${item}`}
                                    style={{
                                        fontWeight: 400,
                                        backgroundColor: '#fff'

                                    }}
                                    className={classes.menuItem}
                                    onClick={(e) => {
                                        handleTypeChange(item, ind)
                                    }}
                                >
                                    {item}
                                </div>
                            ))}
                        </div>
                    </ClickAwayListener>
                </Popper>
            </div>
        </div>
    )
}

const PlatformBrowserForSauceExecution = ({
    saucelabs_capabliites,
    deviceSettings,
    environmentDetails,
    classes,
    setState,
    sauceContent,
    tunnelID,
    sauceTunnelIDs,
    configurationSetting,
    callingFrom,
    sauceIDDisable,
    sauceCredentialDetails,
    overflowStyle,
    sauceContentUpdated,
    deviceNotAvailable
}) => {
   
    const [isHovered, setHover] = useState(null)
    const isSauceConfigFound = environmentDetails?.environments?.some(env => env?.environmentType?.toLowerCase() === 'saucelabs' || env?.environmentType?.toLowerCase() === 'saucelab_devices');

    if (configurationSetting !== 'lastRunConfiguration' && (!isSauceConfigFound || !sauceCredentialDetails?.sauceCredentialId)) {
        return <Typography className={classes.noDetail}>Sauce configuration not found</Typography>
    }

    const onSelectPlatformBrowser = (index, selectedDevice) => {
        let _sauceContent = [...sauceContent];
        const _deviceSettings = JSON.parse(JSON.stringify(deviceSettings));
        const newSelectedPlatformBrowser = saucelabs_capabliites.capabilities_list.find(dc => dc.id === selectedDevice.id)
        if (selectedDevice?.type) {
            if (newSelectedPlatformBrowser['deviceType'] === 'real') {
                _deviceSettings[index] = {
                    autoGrantPermissions: false,
                    
                    enableAnimations: false,
                    orientation: 'portrait',
                    options: [{ key: '', value: '' }]
                }
            }
            else {
                _deviceSettings[index] = {
                    autoGrantPermissions: false,
                    
                    enableAnimations: false,
                   // orientation: 'portrait',
                    orientation: 'portrait',
                    options: [{ key: '', value: '' }]
                }
            }
        }
        else {
            _deviceSettings[index] = {}
        }

        if (newSelectedPlatformBrowser) {
            _sauceContent = _sauceContent.map((selectedBrowser, i) => {
                selectedBrowser = i == index ? newSelectedPlatformBrowser : selectedBrowser
                return { ...selectedBrowser, idEl: null, tunnelID: _sauceContent[i]['tunnelID'] }
            })

            setState({ sauceContent: _sauceContent, deviceSettings: _deviceSettings, sauceContentUpdated: true })
        }
    }
    const addSauceRow = () => {
        const _sauceContent = [...sauceContent];
        const _deviceSettings = JSON.parse(JSON.stringify(deviceSettings));
        _deviceSettings.push({})
        _sauceContent.push({
            ...saucelabs_capabliites.capabilities_list[0],
            idEl: null,
            tunnelID: sauceTunnelIDs[0],
            environmentType: 'Saucelabs'
        })
        setState({
            sauceContent: _sauceContent, deviceSettings: _deviceSettings, noConfigFound: false, sauceConfigurationNotFound: true, showSauceContent: false, sauceContentUpdated: true
        })
    }
    const deleteSauceRow = (id, index) => {
        let _sauceContent = [...sauceContent];
        let _deviceSettings = JSON.parse(JSON.stringify(deviceSettings));
        _sauceContent = _sauceContent.filter((_, i) => i !== index);
        _deviceSettings = _deviceSettings.filter((_, i) => i !== index);
        let _deviceNotAvailable = _sauceContent?.length ?  _sauceContent.filter((val) =>  val.label === '' ) : [] ;
        setState({ sauceContent: _sauceContent, deviceSettings: _deviceSettings, sauceContentUpdated: true, deviceNotAvailable: _deviceNotAvailable })
    }


    const render = () => {
        const rowsForSauce = [];
        sauceContent.forEach((selectedBrowser, ind) => {

            rowsForSauce.push(
                <div
                    key={ind}
                    style={{ backgroundColor: ind % 2 === 0 ? '#F5F5F5' : '#fff', pointerEvents: configurationSetting === 'lastRunConfiguration' ? 'none' : 'auto' }}
                    className={classes.environmentTypeContainer}
                    onMouseEnter={() => setHover(ind)}
                    onMouseLeave={() => setHover(null)}
                >
                    <div style={{ width: '62%' }}>
                        <PlatformBrowserSelector
                            callingFrom= "sauceExecution"
                            data={saucelabs_capabliites.capabilities_data_map}
                            onChange={(data) => {
                                onSelectPlatformBrowser(ind, data)
                            }}
                            selected={{ id: selectedBrowser.id, label: selectedBrowser.label }}
                            title={'Select Platform'}
                        />
                    </div>
                    <div style={{ width: '30%', pointerEvents: 'auto' }}>
                        <PopperElement
                            arrItems={sauceTunnelIDs}
                            type={'sauceConnectId'}
                            sauceContent={sauceContent}
                            disabled={!sauceTunnelIDs}
                            title={'Sauce Connect Proxy'}
                            ind={ind}
                            sauceIDDisable={sauceIDDisable}
                            classes={classes}
                            setState={setState}
                        />
                    </div>
                    <div style={{ width: '8%' }}>
                        {isHovered === ind && configurationSetting !== 'lastRunConfiguration' && callingFrom !== 'Generate' && (
                            <Tooltip data='delete'>
                                <IconButton disabled={configurationSetting === 'lastRunConfiguration'} className={classes.iconButtonRoot} aria-label="GotoExecution" onClick={() => deleteSauceRow(selectedBrowser.id, ind)}>
                                    <CloseIcon className={`${configurationSetting === 'lastRunConfiguration' ? classes.disableAddBtn : classes.actionIcons}`} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                    {(selectedBrowser?.deviceType?.toLowerCase() === "real" || selectedBrowser?.deviceType?.toLowerCase() === "emulator") && <DeviceSettings deviceSettings={deviceSettings} setState={setState} rowInd={ind} deviceType={selectedBrowser?.deviceType?.toLowerCase()} configurationSetting= {configurationSetting} />}
                </div>
            );


        });

        return rowsForSauce;
    }


    return (
        <div className={classes.content} style={{ maxHeight: 150, overflowY: overflowStyle, width: '100%' }} >

            {render()}

            {!sauceContent.length && configurationSetting !== 'lastRunConfiguration' && (
                <div style={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        size="small"
                        className={classes.button}
                        startIcon={<Add />}
                        onClick={() => addSauceRow()}
                    >
                        Add Platform
                    </Button>
                </div>
            )
            }

            {(sauceContent.length && callingFrom !== 'Generate' && configurationSetting !== 'lastRunConfiguration') ? (<div style={{ textAlign: "end", margin: `-13px 18px 0 0` }}>
                <Tooltip data='Add'>
                    <IconButton disabled={(configurationSetting === 'lastRunConfiguration' || saucelabs_capabliites?.capabilities_list?.length === sauceContent.length)} className={classes.iconButtonRoot} aria-label="GotoExecution" onClick={() => addSauceRow()}>
                        <AddIcon className={`${(configurationSetting === 'lastRunConfiguration' || saucelabs_capabliites?.capabilities_list?.length === sauceContent.length) ? classes.disableAddBtn : classes.addIcon}`} />
                    </IconButton>
                </Tooltip>
            </div>) : null}
        </div>
    )
}

export default PlatformBrowserForSauceExecution
