import { createStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { SwipeableDrawer, Hidden, Drawer } from '@material-ui/core';
import ViewList from 'mdi-material-ui/FormatListBulleted';
import Tasks from '.';

// Set taskpanel width according to screen size
const taskPanelWidth = 350;

const styles = (theme) =>
    createStyles({
        bars: {
            color: '#ffffff',
            fontSize: '20px',
        },
        customSwipableDrawer: {
            flex: '1 0 auto',
            height: '100vh',
            width: taskPanelWidth,
            display: 'flex',
            zIndex: '1200',
            outline: 'none',
            position: 'fixed',
            overflowY: 'hidden',
            flexDirection: 'column',
            backgroundColor: '#4a4a4a',
        },
        iconContainer: {
            alignItems: 'center',
            backgroundColor: '#393939',
            display: 'flex',
            height: '35px',
            justifyContent: 'center',
            cursor: 'pointer',
            width: '35px',
            [theme.breakpoints.up('sm')]: {
                backgroundColor: '#8898A8',
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
                marginTop: -20,
            },
        },
    });

class SwipableTaskPanel extends React.Component {
    render() {
        const { classes, toggleSwipableTaskPanel, openTaskPanel } = this.props;
        const content = !openTaskPanel ? (
            <div
                aria-hidden
                className={classes.iconContainer}
                onClick={() => {
                    toggleSwipableTaskPanel(true);
                }}
            >
                <ViewList className={classes.bars} />
            </div>
        ) : (
            [
                <Hidden only={['md', 'lg', 'xl', 'sm']} key="mobile">
                    <SwipeableDrawer
                        anchor="right"
                        open={openTaskPanel}
                        onClose={() => {
                            toggleSwipableTaskPanel(false);
                        }}
                        onOpen={() => {
                            toggleSwipableTaskPanel(true);
                        }}
                        disableSwipeToOpen
                        classes={{ paper: classes.customSwipableDrawer }}
                    >
                        <div
                            tabIndex={0}
                            role="button"
                            onKeyDown={() => {
                                toggleSwipableTaskPanel(false);
                            }}
                        >
                            <Tasks toggleSwipableTaskPanel={toggleSwipableTaskPanel} />
                        </div>
                    </SwipeableDrawer>
                </Hidden>,
                <Hidden only={['xs']} key="web">
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="right"
                        open={openTaskPanel}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div tabIndex={0} role="button">
                            <Tasks toggleSwipableTaskPanel={toggleSwipableTaskPanel} />
                        </div>
                    </Drawer>
                </Hidden>,
            ]
        );
        return content;
    }
}

export default withStyles(styles)(SwipableTaskPanel);
