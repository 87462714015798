import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, RadioGroup, FormControlLabel, Radio, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UserActions } from '../../store/actions';

const styles = () => ({
    mainWrapper: {
        paddingTop: 20,
        flexDirection: 'row',
        display: 'flex',
    },
    expensionContent: {
        margin: '0 !important',
        cursor: 'default',
        paddingBottom: '10px',
    },
    noBottomPadding: {
        paddingBottom: '0px',
    },
    expensionsummary: {
        height: 'auto !important',
        minHeight: 'auto !important',
        paddingLeft: '0px',
        // borderBottom: '1px solid #BAC4CE'
    },
    expensionDetail: {
        padding: '0 20px',
        display: 'block',
    },
    noBoxShadow: {
        backgroundColor: 'transparent',
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
    },
    heading: {
        paddingLeft: 5,
        fontWeight: 'bold',
        color: '#494949',
        fontSize: 12,
        margin: 0,
        paddingBottom: '0.45em',
        textIndent: 19,
        width: '100%',
    },
    button: {
        minWidth: '75px',
        padding: '2px 8px',
        textTransform: 'capitalize',
        height: '34px',
        width: '69px',
        border: '1px solid #3B91DF',
        borderRadius: '3px',
        backgroundColor: '#3B91DF',
    },
    dateType: {
        color: '#494949',
        margin: 0,
        borderBottom: 'none',
        paddingBottom: '0.45em',
        fontSize: 12,
        marginTop: '1px',
        paddingLeft: 10,
    },
    expandSpan: {
        margin: '-3px 15px 0px 0px',
        padding: 0,
        position: 'absolute',
        right: 0,
        cursor: 'pointer',
        top: 0,
    },
    saveButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 40,
        marginBottom: 15,
    },
    fontSize12: {
        fontSize: 12,
    },
});

class AdminSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: null,
            isRelativeDate: props.userDetails?.isRelativeDateFormat,
        };
    }

    handleDateType = (event) => {
        const { value } = event.target;
        if (value === 'Relative Date (Default)') {
            this.setState({ isRelativeDate: true });
        }
        if (value === 'Actual Date') {
            this.setState({ isRelativeDate: false });
        }
    };

    changeDateType = () => {
        const { userDetails, postUserProfileInfo } = this.props;
        const obj = { ...userDetails, isRelativeDateFormat: this.state.isRelativeDate };
        postUserProfileInfo(obj);
    };

    render() {
        const { classes } = this.props;
        const { expanded, isRelativeDate } = this.state;
        const dateType = isRelativeDate ? 'Relative Date (Default)' : 'Actual Date';
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={false} sm={1} md={2} lg={2} />
                <Grid item xs={12} sm={10} md={7} lg={7}>
                    <div
                        className={classes.mainWrapper}
                        style={{
                            borderBottom: expanded === 'RelativeDate' ? 'none' : '1px solid #BAC4CE',
                            backgroundColor: expanded === 'RelativeDate' ? '#fff' : '',
                        }}
                    >
                        <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <Typography gutterBottom className={classes.heading}>
                                Date Format
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={9} lg={9}>
                            <Accordion className={classes.noBoxShadow}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    className={classes.expensionsummary}
                                    classes={{ content: [classes.expensionContent, classes.noBottomPadding].join(' ') }}
                                >
                                    {!expanded && <Typography className={classes.dateType}>{dateType}</Typography>}
                                </AccordionSummary>
                                <AccordionDetails className={classes.expensionDetail} style={{ paddingRight: 0 }}>
                                    <RadioGroup aria-label="dateType" name="dateType" value={dateType} onChange={(e) => this.handleDateType(e)}>
                                        <FormControlLabel
                                            style={{ height: 20,marginTop:10,marginBottom: 10 }}
                                            value="Relative Date (Default)"
                                            control={<Radio disableRipple />}
                                            label={<Typography className={classes.fontSize12}>Relative Date (Default)</Typography>}
                                        />
                                        <FormControlLabel
                                            value="Actual Date"
                                            control={<Radio disableRipple />}
                                            label={<Typography className={classes.fontSize12}>Actual Date</Typography>}
                                        />
                                    </RadioGroup>
                                    <div className={classes.saveButton}>
                                        <Button variant="contained" color="secondary" className={classes.button} onClick={this.changeDateType}>
                                            Save
                                        </Button>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={false} sm={1} md={2} lg={2} />
            </div>
        );
    }
}

AdminSettings.propTypes = {};

const mapStateToProps = (state) => ({
    userDetails: state.userReducer.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
    postUserProfileInfo: (...args) => dispatch(UserActions.postUserProfileInfo(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminSettings));
