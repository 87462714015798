// Material UI Components
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import React, { Component } from 'react';
// icons
import Mail from '@material-ui/icons/MailOutline';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import Company from 'mdi-material-ui/OfficeBuilding';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import List from '@material-ui/core/List';
import FormControl from '@material-ui/core/FormControl';

import config from '../../config';
import { AuthActions } from '../../store/actions';
import { isEmpty } from '../../utils/utils';
import { AuthListItem } from '../../components/auth/AuthBtnRight';

// images
// import google from '../../components/signin/google.png';
// import github from '../../components/signin/github.png';
// import jira from '../../components/signin/jiraNew.png';
// styles
import { styles } from './style';

import Loginbuttons from '../../components/auth/Loginbuttons';
import CustomTextField from '../../components/auth/CustomTextField';

const { customerDeployment, logoUrl, logoLarge, companyName } = config;

// Material UI Components

class UserRegistration extends Component {
    state = {
        accountName: '',
        email: '',
        isError: false,
        password: '',
        response: null,
        userName: '',
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.emailSendSuccess)
            this.setState({ response: nextProps.emailSendSuccess }, async () => {
                await localStorage.setItem(
                    'registerUser',
                    JSON.stringify({
                        email: this.state.userName,
                        password: this.state.password,
                    }),
                );
            });
        if (nextProps.error)
            this.setState({
                response: nextProps.error,
                isError: true,
            });
    }

    componentWillUnmount() {
        this.props.clearAuthError();
        this.props.clearAuthSuccess();
    }

    handleInput = (event) => {
        this.props.clearAuthError();
        this.props.clearAuthSuccess();
        const { id, value } = event.currentTarget;
        this.setState({
            [id]: value?.trim(),
            response: null,
        });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const { onRegister } = this.props;
        const obj = {
            name: this.state.userName,
            email: this.state.email,
            password: this.state.password,
            accountName: this.state.accountName,
            role: 'ADMIN',
        };
        onRegister(obj);
    };

    handleLogin = () => {
        this.props.history.push('signin');
    };

    render() {
        const { classes, isLoading } = this.props;
        const { accountName, email, isError, response, password, userName } = this.state;

        const isRegisterDisabled = isEmpty({ accountName, email, password, userName }) || isLoading;
        return (
            <div className={classes.root}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <List className={classes.listItems}>
                        <AuthListItem onClick={this.handleLogin} text="Go Back to Login" listItemRoot={classes.listItemRoot} />
                    </List>
                </div>
                <div className={classes.whiteCard}>
                    <div className={classes.container}>
                        <div className={classes.whiteCardLeft}>
                            {`${customerDeployment}` === 'false' && (
                                <Typography component="h1" variant="h1" className={classes.trialText}>
                                    Sign up now to start your free trial
                                </Typography>
                            )}
                            <FormControl style={{ width: '70%', marginTop: 15 }}>
                                <div className={classes.firstTextField}>
                                    <CustomTextField
                                        className={classes.formInput}
                                        id="userName"
                                        aria-label="User Name"
                                        placeholder="Username"
                                        onChange={this.handleInput}
                                        onKeyPress={this.onKeyHandler}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    style={{
                                                        margin: '0px 11px 2px 12px',
                                                        color: '#C4C4C4',
                                                    }}
                                                    position="start"
                                                    className={classes.inputAdornment}
                                                >
                                                    <FontAwesomeIcon icon={faUser} aria-label="faUser" id="faUser" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                        value={userName}
                                    />
                                </div>
                                <div className={classes.textField}>
                                    <CustomTextField
                                        id="email"
                                        aria-label="Email"
                                        placeholder="Your Email"
                                        onChange={this.handleInput}
                                        required
                                        className={classes.formInput}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    style={{
                                                        marginLeft: '8px',
                                                        color: '#C4C4C4',
                                                    }}
                                                    position="start"
                                                    className={classes.inputAdornment}
                                                >
                                                    <Mail aria-label="mailIcon" id="mailIcon" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={email}
                                    />
                                </div>
                                <div className={classes.textField}>
                                    <CustomTextField
                                        className={classes.formInput}
                                        id="password"
                                        aria-label="Password"
                                        placeholder="Password"
                                        onChange={this.handleInput}
                                        onKeyPress={this.onKeyHandler}
                                        type="password"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    style={{
                                                        marginLeft: '14px',
                                                        color: '#C4C4C4',
                                                        marginRight: '13px',
                                                    }}
                                                    position="start"
                                                    className={classes.inputAdornment}
                                                >
                                                    <FontAwesomeIcon icon={faLock} aria-label="faLock" id="faLock" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                        value={password}
                                    />
                                </div>
                                <div className={classes.textField}>
                                    <CustomTextField
                                        id="accountName"
                                        aria-label="Account Name"
                                        placeholder="Company Name"
                                        onChange={this.handleInput}
                                        onKeyPress={this.onKeyHandler}
                                        required
                                        className={classes.formInput}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    style={{
                                                        marginLeft: '8px',
                                                        color: '#C4C4C4',
                                                    }}
                                                    position="start"
                                                    className={classes.inputAdornment}
                                                >
                                                    <Company />
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={accountName}
                                    />
                                </div>
                                <div
                                    className={[
                                        classes.formWrapper,
                                        response && classes.formWrapperWithError,
                                        response ? (isError ? classes.colorError : classes.colorSuccess) : '',
                                    ].join(' ')}
                                >
                                    {response && <Typography className={classes.responseText}>{response}</Typography>}
                                </div>
                            </FormControl>
                            <div className={classes.signupNow}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.raisedBtn}
                                    onClick={this.handleSubmit}
                                    classes={{
                                        disabled: classes.loginDisabled,
                                    }}
                                    disabled={isRegisterDisabled}
                                >
                                    Sign Up Now
                                </Button>
                            </div>
                            <p className={classes.loginWith}>or signup with</p>
                            <Loginbuttons />
                            {companyName.toLocaleLowerCase() === 'autonomiq' ? (
                                <a href="http://support.autonomiq.ai/Privacy-Policy.pdf" className={classes.instructionText}>
                                    {' '}
                                    Privacy Policy
                                </a>
                            ) : null}
                        </div>
                        <div className={classes.whiteCardRight}>
                            {companyName.toLocaleLowerCase() === 'autonomiq' ? (
                                <div>
                                    <img src={logoLarge} className={classes.autoQ} alt="" />
                                    <img src={logoUrl} className={classes.autonomiqLogo} alt="" />
                                </div>
                            ) : (
                                <img src={logoUrl} className={classes.otherLogo} alt="" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.authReducer.isLoading,
        error: state.authReducer.error,
        emailSendSuccess: state.authReducer.emailSendSuccess,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onRegister: (obj) => dispatch(AuthActions.onRegister(obj)),
        clearAuthError: () => dispatch(AuthActions.clearAuthError()),
        clearAuthSuccess: () => dispatch(AuthActions.clearAuthSuccess()),
    };
}

export const SelfUserRegistration = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserRegistration));
