// import materials
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver/FileSaver';
import { Button } from '@material-ui/core';
// import packages
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import icons

import Clone from '@material-ui/icons/FileCopySharp';
import Disable from '@material-ui/icons/NotInterested';
import FlashOn from '@material-ui/icons/FlashOn';
import Restore from '@material-ui/icons/PowerSettingsNew';
import WarningIcon from '@material-ui/icons/Warning';
import FileDownload from '@material-ui/icons/GetApp';
import AddIconCircle from '@material-ui/icons/AddCircle';
import Delete from '@material-ui/icons/Delete';
import ClearAllRoundedIcon from '@material-ui/icons/ClearAllRounded';


import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import custom components
import {
    checkArrayLength,
    checkKeyInObject,
    sort,
    getExecutionStatus,
    getFormatedDate,
    checkObject,
    resizeAllCols,
    getRelativeTime,
    getParamValues,
    TestSuiteUtils,
    getMinNearest15Interval,
} from '../../../utils';
import DeleteTestSuiteDialog from '../../plan/suite/DeleteTestSuite';
import ExecuteScriptModal from '../Script/ExecuteScriptModal';
import DeleteTestCaseDialog from '../../plan/case/DeleteTestCase';
import EnhancedTableHead from '../../../components/EnhancedTable/EnhancedTableHead_v2';
//import { EnhancedTableHead } from '../../../../components/EnhancedTable';
import NoDataToShow from '../../../components/NoDataToShow';
import ImageSrc from '../../../components/NoDataToShow/assests/file-document-box-outline.svg';
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import TableCell from '../../../components/Table/TableCell';
import TableRow from '../../../components/Table/TableRow';

import { GeneralActions, ModalActions, ProjectActions, TestSuiteActions, generalModalActions } from '../../../store/actions/index';
import { MUI_ACTION_MENU_ICON, THREE_DOT_MENU_CSS, TABLES_CSS, BLACK_FONT } from '../../../common/cssConstants';
import SingleFileCreationService from '../../../services/singleFileCreationService';
import Tooltip from '../../../components/Tooltip';
import TableFooter from '../../../common/TableFooter';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON } from '../../../components/Button/Btn';
import { MenuOptions } from '../../../components/Button/MenuOptions';
import ExpandBtn from '../../../components/Button/ExpandBtn';

const styles = (theme) => ({
    AddIcon: {
        color: '#ff9700',
        fontSize: '25px',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '20%',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    cell_: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        paddingLeft: '10px !important',
        width: '100%',
        maxWidth: '100%',
        '&>:first-child': {
            paddingLeft: '10px !important',
        },
    },
    moreIconsBtnDisabled: {
        color: THREE_DOT_MENU_CSS.iconDisableColor,
        fontSize: '22px',
    },
    moreIconsBtnEnabled : {
        color: THREE_DOT_MENU_CSS.iconColor,
        fontSize: '22px',
    },
    currentCell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        paddingLeft: '0px !important',
        width: '20%',
    },
    checkBox: {
        color: '#b8c4cf',
    },
    checkBoxChecked: {
        color: '#3B91DF !important',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        fontSize: '22px',
    },
    IconBtnWarning: {
        color: '#ffc107',
    },
    moreIcons: MUI_ACTION_MENU_ICON,
    nonSortableContainer: {
        cursor: 'default',
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'inherit',
        justifyContent: 'flex-start',
    },
    borders: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    button: {
        marginLeft: '5px',
        minWidth: '75px',
        padding: '2px 8px',
        textTransform: 'capitalize',
        height: '25px',
        width: '120px',
        border: '1px solid #2da6ea',
        borderRadius: '3px',
        backgroundColor: '#2da6ea',
    },
    addCaseButton: {
        margin: '8px 5px 8px 5px',
        minWidth: '75px',
        padding: '2px 8px 3px',
        textTransform: 'capitalize',
        height: '24px',
        width: '120px',
        border: '1px solid #2da6ea',
        borderRadius: '3px',
        backgroundColor: '#2da6ea',
    },
    // tableRow: {
    //     '&:hover': {
    //         backgroundColor: '#F9F9F9 !important',
    //     },
    //     backgroundColor: TABLES_CSS.rowColor,
    //     cursor: 'pointer',
    //     height: '62px',
    // },
    tableRow: {
        backgroundColor: '#f5f5f5',
        borderTop: 'solid 0.5px rgb(151 151 151 / 69%)',
        height: '50px',
        '&:hover': {
            '& .TableCell': {
                backgroundColor: '#fff',
                '& .IconCell': {
                    // '& .iconContainer': {
                        // '& .IconButton': {
                            backgroundColor: 'red'
                        // }
                    // }
                },
            },
          //  fontWeight: 'bold',
            backgroundColor: '#fff',
        },
    },
    focused: {
        background: '#fff !important',
        '& .TableCell': {
            fontWeight: 'bold',
        },
    },
    calender: {
        color: '#4a4a4a',
        fontSize: '16px',
        width: '18px !important',
        marginTop: '-4px',
        marginRight: '12px',
    },
    calenderDisabled: {
        color: '#b7c4cf',
        fontSize: '16px',
        width: '18px !important',
        marginTop: '-4px',
        marginRight: '12px',
    },
    avatar: {
        minWidth: '20px',
        height: '20px',
        borderRadius: '20px',
        backgroundColor: '#d8d8d8',
        color: BLACK_FONT,
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: 1.7,
        padding: '0 4px',
        display: 'inline-block',
        overflow: 'hidden',
        boxSizing: 'border-box',
        margin: '-4px 12px -6px 0',
    },
    sortIcon: {
        fontSize: 10,
        marginLeft: 10,
        color: '#9B9B9B',
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    parentSuiteButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.only('xs')]: {
            display: 'flex',
        },
    },
    minHeight: {
        minHeight: 'calc(100vh - 279px)',
    },
    tableContainer: {
        backgroundColor: '#fff',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        overflow: 'hidden',
    },
});

// Table Header Array
const headers = [
    { id: 'deleteIcon', width: '40px',tableCellStyles: { minWidth: 'unset' }, styles: { paddingLeft: 10 }},
    { id: 'testSuiteName', sortable: true, numeric: false, disablePadding: false, label: 'Suite', tableCellStyles: { color : '#3768b3 !important' }, styles: { paddingLeft: 10 }, width: '30%' },
    {
        id: 'creationTime',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Created',
        styles: { paddingLeft: 10 },
        width: 'calc(26% / 2)',
    },
    {
        id: 'lastRunTime',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Last Run',
        styles: { paddingLeft: 10 },
        width: 'calc(26% / 2)',
    },
    {
        id: 'suiteExecutionStatus',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Last Status',
        styles: { paddingLeft: 10 },
        width: 'calc(26% / 2)',
    },
    { id: 'execute', sortable: false, numeric: false, disablePadding: false, label: 'EXECUTE', styles: { paddingLeft: 10 }, color: 'rgba(56, 55, 55, 0.58)', width: 'calc(30% / 2)' },
    { id: 'delete', sortable: false, numeric: false, disablePadding: false, label: 'DELETE', styles: { paddingLeft: 10 }, color: 'rgba(56, 55, 55, 0.58)', width: 'calc(30% / 2)' },
    { id: 'more', sortable: false, numeric: false, disablePadding: false, label: 'MORE', styles: { paddingLeft: 10 }, color: 'rgba(56, 55, 55, 0.58)', width: 'calc(40% / 4)' },
];
// headers for case
const subHeaders = [
    { id: 'deleteIcon', width: '40px',tableCellStyles: { minWidth: 'unset' }, },
    { id: 'testCaseName', sortable: true, numeric: false, disablePadding: false, label: 'Case', styles: { paddingLeft: 10 }, width: '30%' },
    {
        id: 'createdTime',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Created',
        styles: { paddingLeft: 10 },
        width: 'calc(26% / 2)',
    },
    { id: 'lastRun', sortable: true, numeric: false, disablePadding: true, label: 'Last Run', styles: { paddingLeft: 10 }, width: 'calc(26% / 2)' },
    {
        id: 'executionStatus',
        sortable: false,
        numeric: false,
        disablePadding: true,
        label: 'Execution Status',
        styles: { paddingLeft: 10 },
        width: 'calc(26% / 2)',
    },
    { id: 'execute', sortable: false, numeric: false, disablePadding: false, styles: { paddingLeft: 10 }, color: 'rgba(56, 55, 55, 0.58)', width: 'calc(30% / 2)' },
    { id: 'delete', sortable: false, numeric: false, disablePadding: false, styles: { paddingLeft: 10 }, color: 'rgba(56, 55, 55, 0.58)', width: 'calc(30% / 2)' },
    { id: 'more', sortable: false, numeric: false, disablePadding: false, label: 'MORE', styles: { paddingLeft: 10 }, color: 'rgba(56, 55, 55, 0.58)', width: 'calc(40% / 4)' },
];
let submitDisable = false;

class TestSuite extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'testSuiteName',
        caseOrder: 'asc',
        caseOrderBy: 'ordinal',
        rowExpendStatus: [0, false],
        selected: [],
        data: [],
        page: 0,
        rowsPerPage: 20,
        open: null,
        deleteTestSuiteData: null,
        isEditModal: false,
        editTestSuiteData: null,
        organizeTestSuiteData: null,
        deleteDialogOpen: false,
        isDisableCase: false,
        executeDialogOpen: false,
        deleteCaseDialogOpen: false,
        selectedSuite: null, // Suite obj will be stored when select any suite
        associateTestSuiteData: null,
        deleteTestCaseData: null,
        isCaseBtnsDisable: false,
        selectedUpDownTemp: [],
        TestSuiteByProjectTemp: null,
        prevExecutionTasksStatus: 0,
        shouldUpdate_prevExecutionTasksStatus: true,
        isAlreadySubmit: false,
        selectArr: [],
        selectedAll: false,
        isHovered: null,
        isHoveredTestCase: null,
    };

    /* Component life cycle start */
    UNSAFE_componentWillMount() {
        if (checkObject(this.props.suiteState)) {
            this.setState({
                ...this.state,
                ...this.props.suiteState,
            });
        }
    }

    componentDidMount() {
        const { executionTasksStatus } = this.props;
        this.showModal = true;
        const caseSettingRows = localStorage.getItem('rowsCasePerPage');
        if (caseSettingRows) {
            this.updateState({
                rowsPerPage: parseInt(caseSettingRows, 10),
            });
        }

        this.getUserTestSuiteByProject();

        this.updateState({
            prevExecutionTasksStatus: executionTasksStatus,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { executionTasksStatus, createTestSuite, receiveFunctionFromChild, testSuiteByProject } = this.props;
        const { testSuiteByProject: testSuites, testCaseDropDownData } = nextProps;
        const { shouldUpdate_prevExecutionTasksStatus, organizeTestSuiteData, pageNo } = this.state;
        let prevPageNo = parseInt(pageNo || 0)
        if(!nextProps.query) {
            this.setState({
                page : prevPageNo
            })
        }
        if (
            (`${nextProps.executionTasksStatus}` !== '-1' || shouldUpdate_prevExecutionTasksStatus) &&
            `${nextProps.executionTasksStatus}` !== `${executionTasksStatus}`
        ) {
            this.setState({
                shouldUpdate_prevExecutionTasksStatus: true,
                prevExecutionTasksStatus: nextProps.executionTasksStatus,
            });
        }
        if (createTestSuite === null) {
            receiveFunctionFromChild(this.toggleMergeModal);
        }
        if (organizeTestSuiteData && testSuiteByProject) {
            const _suite = testSuiteByProject.find((suite) => suite.testSuiteId === organizeTestSuiteData.testSuiteId);
            if (_suite) {
                this.setState({ organizeTestSuiteData: _suite });
            }
        }

        if (this.props.isClearData && checkObject(this.props.suiteState)) {
            this.props.clearSuiteState();
        }

        if (getParamValues(3) && getParamValues(4) && checkArrayLength(testSuites) && checkArrayLength(testCaseDropDownData) && this.showModal) {
            const suiteId = getParamValues(3);
            const suiteIndex = testSuites.findIndex((suite) => `${suite.testSuiteId}` === `${suiteId}`);
            if (suiteIndex !== -1 && testSuites[suiteIndex]) {
                const suite = testSuites[suiteIndex];
                this.showModal = false;
                switch (getParamValues(4)) {
                    case 'update':
                        this.toggleMergeModal(true, suite);
                        break;
                    case 'execute':
                        this.openExecutionModal(suite);
                        break;
                    default:
                        this.replaceURL();
                }
            } else {
                this.replaceURL();
            }
        }
    }

    componentDidUpdate() {
        resizeAllCols(this.colsWidth);
    }

    componentWillUnmount() {
        const { getExecutionTasks, queryRemove, projectSystemId, updateTestCaseId } = this.props;
        const { prevExecutionTasksStatus } = this.state;
        getExecutionTasks(projectSystemId, prevExecutionTasksStatus, -1, 1, false, null, false);
        updateTestCaseId(null);
        queryRemove();
        this.props.clearSuiteState();
    }

    /* Component life cycle end */

    onCloseModal = () => {
        submitDisable = false;
        this.props.toggleModal();
        this.replaceURL();
        this.setState(
            {
                isEditModal: false,
                editTestSuiteData: null,
                isAlreadySubmit: false,
            },
            () => {
                this.props.clearModalData();
                setTimeout(() => {
                    this.setState({
                        organizeTestSuiteData: null,
                    });
                }, 100);
            },
        );
    };

    onSelectAllClick = (e, key, con = true) => {
        const { selectArr } = this.state;
        const updated = e.target.checked ? [...selectArr, key] : selectArr.filter((val) => val !== key);
        this.setState({ selectArr: updated, selectedAll: con });
    };

    SelectAll = (e, currentPageVariables) => {
        const select = e.target.checked ? currentPageVariables.map((variable) => variable.testDataId) : [];
        this.setState({ selectArr: select, selectedAll: true });
    };

    onSubmitTestCases = async (suiteId = null) => {
        const { associateTestSuiteData } = this.state;
        const { tabsData } = this.props;
        const testSuiteId = suiteId || (associateTestSuiteData && associateTestSuiteData.testSuiteId);
        const { selectedTestCaseIds, selectedTestCaseIdsForDelete } = tabsData.association.state;
        const _selectedTestCaseIds = Object.keys(selectedTestCaseIds).map((item) => parseInt(item, 10));
        const _selectedTestCaseIdsForDelete = Object.keys(selectedTestCaseIdsForDelete).map((item) => parseInt(item, 10));
        if (checkKeyInObject(associateTestSuiteData, 'testCases', 'bool') && checkArrayLength(associateTestSuiteData.testCases)) {
            associateTestSuiteData.testCases.forEach((testCase) => {
                const index = _selectedTestCaseIds.indexOf(testCase.testCaseId);
                if (index !== -1) {
                    _selectedTestCaseIds.splice(index, 1);
                }
            });
        }
        if (checkArrayLength(_selectedTestCaseIdsForDelete)) {
            await this.handleDissociate(testSuiteId, _selectedTestCaseIdsForDelete);
        }
        if (checkArrayLength(_selectedTestCaseIds)) {
            await this.handleAssociate(testSuiteId, _selectedTestCaseIds /* , this.clearForm */);
        }
    };

    onDeleteTestSuite = (suiteData, isDisableCase) => {
        this.setState({
            deleteTestSuiteData: suiteData,
            deleteDialogOpen: true,
            isDisableCase,
            open: null,
        });
    };

    onSubmitSuiteInfo = async () => {
        const { projectSystemId, tabsData } = this.props;
        const { editTestSuiteData } = this.state;
        const { testSuiteName, recipientsEmails } = tabsData.suiteInfo.state;
        let selectedPlatform = '';
        let selectedBrowser = '';
        let scheduleStartTime = '';
        let hours = 0;
        let postActionData = [];
        selectedPlatform = checkKeyInObject(editTestSuiteData, 'schedulePlatform') || '';
        selectedBrowser = checkKeyInObject(editTestSuiteData, 'scheduleBrowser') || '';
        hours = checkKeyInObject(editTestSuiteData, 'interval') || 0;
        scheduleStartTime = checkKeyInObject(editTestSuiteData, 'scheduleStartTime')
            ? new Date(editTestSuiteData.scheduleStartTime).getTime()
            : new Date().getTime();
        if (
            checkKeyInObject(checkKeyInObject(tabsData, 'postAction'), 'state') ||
            checkArrayLength(checkKeyInObject(editTestSuiteData, 'peTriggers'))
        ) {
            const tempActionsData = checkKeyInObject(checkKeyInObject(tabsData, 'postAction'), 'state')
                ? tabsData.postAction.state.originalTabData
                : checkKeyInObject(editTestSuiteData, 'peTriggers');
            postActionData = tempActionsData.map((data) => {
                return {
                    triggerCondition: data.triggerCondition,
                    conditionType: 'POSTEXEC',
                    objectId: parseInt(data.objectId, 10),
                    objectType: 2,
                    delay: parseInt(data.delay, 10),
                    delayUnit: data.delayUnit,
                };
            });
        }
        const correctDate = new Date(new Date(scheduleStartTime).setMinutes(getMinNearest15Interval(new Date(scheduleStartTime).getMinutes())));

        let __suiteId = null;

        const obj = {
            name: testSuiteName.trim(),
            projectId: parseInt(projectSystemId, 10),
            interval: hours,
            scheduleStartTime: new Date(correctDate).toISOString(),
            schedulePlatform: selectedPlatform,
            scheduleBrowser: selectedBrowser,
            recipientsEmails,
            peActions: postActionData,
        };
        if (this.state.isEditModal) {
            obj.testSuiteId = editTestSuiteData.testSuiteId;
            await this.props.editTestSuites(obj, 'projectDetails');
        } else {
            __suiteId = await this.props.addTestSuites(obj, 'projectDetails');
        }
        return __suiteId;
    };

    onDeleteTestCase = (suite, caseData) => {
        this.setState({
            deleteTestCaseData: caseData,
            deleteCaseDialogOpen: true,
            isDisableCase: 0,
            suite,
        });
    };

    getTestSuite = () => {
        const { filterArray, isFiltering } = this.state;
        const testSuiteArray = filterArray.length || isFiltering ? filterArray : this.props.testSuiteWithAccount;
        return testSuiteArray;
    };

    getUserTestSuiteByProject = () => {
        const { getTestSuitesByProject, user, projectSystemId } = this.props;
        getTestSuitesByProject({ projectId: projectSystemId }, user.accountId);
    };

    colsWidth = {};

    buttons = [
        NEXT_SUBMIT_BUTTON({ action: () => this.props.changeTab(this.props.currentTab + 1) }),
        CANCEL_BUTTON({ action: () => this.onCloseModal() }),
    ];

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleChangeRowsPerPage = (event) => {
        localStorage.setItem('rowsCasePerPage', event.target.value);
        this.setState({ rowsPerPage: event.target.value });
    };

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleRequestCaseSort = (property) => {
        const caseOrderBy = property;
        let caseOrder = 'desc';
        if (this.state.caseOrderBy === property && this.state.caseOrder === 'desc') {
            caseOrder = 'asc';
        }
        this.setState({ caseOrder, caseOrderBy });
    };

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            this.setState(() => ({
                selected: this.props.testSuiteByProject.map((n) => n.testSuiteId),
            }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleExpend = (id) => {
        const { rowExpendStatus } = this.state;
        if (this.state.TestSuiteByProjectTemp) {
            this.props.updateTestSuitesDataPropProject(this.state.TestSuiteByProjectTemp);
        }
        this.setState({
            rowExpendStatus: [id, id === rowExpendStatus[0] ? !rowExpendStatus[1] : true],
            TestSuiteByProjectTemp: null,
            isCaseBtnsDisable: false,
            selectedUpDownTemp: [],
        });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        this.setState({ selected: newSelected });
    };

    handleChangePage = (page) => {
        this.setState({ page });
        if(!this.props.query) {
            this.setState({
                pageNo:page
            })
        }
    };

    handleRowsUpDownConfirm = () => {
        const suite = this.props.testSuiteByProject.find((data) => {
            return data.testSuiteId === this.state.selectedUpDownTemp[0][0];
        });
        if (suite && suite.testCases && suite.testCases.length > 0) {
            const data = {};
            suite.testCases.forEach((row, index) => {
                data[row.testCaseId] = index + 1;
            });
            const onComplete = () => {
                this.setState({
                    TestSuiteByProjectTemp: null,
                    isCaseBtnsDisable: false,
                    selectedUpDownTemp: [],
                });
            };
            this.props.saveTestSuiteCasesOrder(data, this.state.selectedUpDownTemp[0][0], onComplete);
        }
    };

    handleRowsUpDownCancel = () => {
        this.props.updateTestSuitesDataPropProject(this.state.TestSuiteByProjectTemp);
        this.setState({
            TestSuiteByProjectTemp: null,
            isCaseBtnsDisable: false,
            selectedUpDownTemp: [],
        });
    };

    DownloadTestSuites = async (suite) => {
        const fileName = suite.testSuiteName;
        const data = await SingleFileCreationService.getSuiteFile(suite.testCases, fileName);
        if (data) {
            const blob = new Blob([data], {
                type: 'application/octet-stream',
            });
            saveAs(blob, `${fileName}.xlsx`);
            this.props.showSnackBar(`${fileName}.xlsx Downloading`, '', true);
        }
    };

    replaceURL = (suiteId, modalName) => {
        if (suiteId && modalName) {
            this.props.history.replace(`/projects/${getParamValues(1)}/suites/${suiteId}/${modalName}`);
        } else {
            this.props.history.replace(`/projects/${getParamValues(1)}/suites`);
        }
    };

    handleSubmitOrganize = async (testSuiteId, caseData = null) => {
        const suiteId =
            testSuiteId || (checkKeyInObject(this.state.organizeTestSuiteData, 'testSuiteId') ? this.state.organizeTestSuiteData.testSuiteId : null);
        if (!suiteId) return;
        const { tabsData } = this.props;
        const tc_ids_to_ordinals = {};
        const caseDataArray = caseData || checkKeyInObject(checkKeyInObject(checkKeyInObject(tabsData, 'organize'), 'state'), 'caseDataArray');
        if (caseDataArray) {
            caseDataArray.forEach((_case, index) => {
                tc_ids_to_ordinals[_case.testCaseId] = index + 1;
            });
        }
        if((checkKeyInObject(checkKeyInObject(tabsData, 'postAction'), 'state'))){
            await this.props.saveTestSuiteCasesOrder(tc_ids_to_ordinals, suiteId, () => {},"Test Suite Updated Successfully");
        } else if(checkKeyInObject(checkKeyInObject(checkKeyInObject(tabsData, 'association'), 'state'), 'didChange')){
            await this.props.saveTestSuiteCasesOrder(tc_ids_to_ordinals, suiteId, () => {},"Test Suite Added Successfully")
        } else {
            await this.props.saveTestSuiteCasesOrder(tc_ids_to_ordinals, suiteId, () => {},"Test Suite Updated Successfully");
        }
    };

    updateSuite = async () => {
        let suiteId;
        const { tabsData } = this.props;
        if (
            checkKeyInObject(checkKeyInObject(checkKeyInObject(tabsData, 'suiteInfo'), 'state'), 'didChange') ||
            checkKeyInObject(checkKeyInObject(checkKeyInObject(tabsData, 'postAction'), 'state'), 'didChange') ||
            !this.state.isEditModal
        ) {
            suiteId = await this.onSubmitSuiteInfo();
        }
        if (
            checkKeyInObject(checkKeyInObject(checkKeyInObject(tabsData, 'association'), 'state'), 'didChange') ||
            checkKeyInObject(checkKeyInObject(checkKeyInObject(tabsData, 'organize'), 'state'), 'didChange')
        ) {
            if (checkKeyInObject(checkKeyInObject(checkKeyInObject(tabsData, 'association'), 'state'), 'didChange')) {
                await this.onSubmitTestCases(suiteId);
            }
                await this.handleSubmitOrganize(suiteId);
        }
        this.onCloseModal();
        // this.getUserTestSuiteByProject();
    };

    toggleMergeModal = (flag, suiteData = {}) => {
        let _flag = flag;
        if (submitDisable) {
            submitDisable = false;
        }
        this.showModal = false;

        if( _flag && _flag.clientX)  _flag = false; 
        this.setState(
            {
                isEditModal: _flag,
                editTestSuiteData: suiteData,
                associateTestSuiteData: suiteData,
                organizeTestSuiteData: suiteData,
                postActionData: suiteData,
                open: null,
            },
            () => {
                const { testCaseDropDownData } = this.props;
                TestSuiteUtils.toggleMergeModal(_flag, this.onCloseModal, {
                    associateTestSuiteData: this.state.associateTestSuiteData,
                    editTestSuiteData: this.state.editTestSuiteData,
                    organizeTestSuiteData: this.state.organizeTestSuiteData,
                    postActionData: this.state.postActionData,
                    testCaseDropDownData,
                    updateSuite: this.updateSuite,
                });
            },
        );
    };

    showCaseMenuActions = (suite, testCase, classes, extend = false) =>{
        const { isWsRunning, history } = this.props;
        const isDisableEyeIcon = !(
            checkKeyInObject(testCase, 'last_execution_id', 'value', false) ||
            checkKeyInObject(testCase, 'lastExecutionId', 'value', false) ||
            checkKeyInObject(testCase, 'isAiqExecution', 'value', false)
        );
        let executionRoute = checkKeyInObject(testCase, 'lastExecutionId', 'value', 0) ? `/projects/${suite.projectId}/${testCase.testCaseId}/executions/${testCase.lastExecutionId}` : null ;
        let testCaseRoute=`/projects/${suite.projectId}/${testCase.testCaseId}`;                 
        let downloadRoute = `/projects/${suite.projectId}/suites`;             
        let items = [
            {
                action: (e) => {
                    history.push(`${testCaseRoute}`);
                },
                route: `${testCaseRoute}`,
                icon: (
                    
                        <Tooltip data="stepEditor">
                            <IconButton className={classes.IconButton} >
                               <ClearAllRoundedIcon 
                                    className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                    aria-label="StepEditor"
                                    id="StepEditor"
                               /> 
                            </IconButton>
                        </Tooltip>
                    
                ),
                isDisabled: false,
                name: ' Go to Step Editor',
                isOpenNewTab: true
            },
            {
                route: `${executionRoute}`,
                action: () => {
                    history.push(`${executionRoute}`);
                },
                icon: (
                    
                        <Tooltip data="Execute">
                            <IconButton className={classes.IconButton} onClick={(e)=>{}}>
                                <FlashOn
                                    className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                    aria-label="flashOnIcon"
                                    id="flashOnIcon"
                                />
                            </IconButton>
                        </Tooltip>
                    
                ),
                isDisabled: !Boolean(executionRoute) && isDisableEyeIcon,
                name: ' Go to Execution',
                isOpenNewTab: true

            },
            {
                action: () => {
                    if (testCase.reportUrl) SingleFileCreationService.getDownloadAiqReport(testCase.reportUrl, `${testCase.testCaseName}_Report`, false, false, testCase.testCaseId, testCase.lastExecutionId);
                },
                route: `${downloadRoute}`,
                icon: (
                    <FileDownload
                        className={[
                            !testCase || (testCase && Boolean(isWsRunning)) // (!!redux.isWsRunning)
                                ? classes.moreIconsBtnDisabled
                                : classes.moreIconsBtn,
                        ].join(' ')}
                        aria-label="fileDownloadIcon"
                        id="fileDownloadIcon"
                    />
                ),
                isDisabled: !testCase || (testCase && Boolean(isWsRunning)) || testCase.status === 0 || !testCase.reportUrl,
                name: ' Download Execution Report',
                isOpenNewTab: false,
            }
            ]
            return items;
    }

    showMenuActions = (suite, classes, extend = false) => {
        let items = [
            // {
            //     action: (e) => {
            //         e.stopPropagation();
            //         this.replaceURL(suite.testSuiteId, 'update');
            //         this.toggleMergeModal(true, suite);
            //     },
            //     icon: (
            //         <FontAwesomeIcon
            //             style={{ fontSize: THREE_DOT_MENU_CSS.faIconFontSize, padding: '1px' }}
            //             icon={faPencilAlt}
            //             aria-label="faPencilAlt"
            //             id="faPencilAlt"
            //             className={classes.moreIcons}
            //         />
            //     ),
            //     isDisabled: false,
            //     name: ' Update Suite',
            // },
            {
                action: () => {
                    TestSuiteUtils.cloneTestSuite(suite, 'projectDetails');
                },
                icon: <Clone className={classes.moreIcons} aria-label="cloneIcon" id="cloneIcon" />,
                isDisabled: false,
                name: ' Clone Suite',
            },
            {
                action: () => {
                    this.DownloadTestSuites(suite);
                },
                icon: <FileDownload className={classes.moreIcons} aria-label="fileDownloadIcon" id="fileDownloadIcon" />,
                isDisabled: !checkArrayLength(suite.testCases),
                name: ' Download Suite',
            },
        ];
        const disabledOption = {
            action: (e) => {
                e.stopPropagation();
                this.onDeleteTestSuite(suite, 1);
            },
            icon: <Disable className={classes.moreIcons} aria-label="disableIcon" id="disableIcon" />,
            isDisabled: suite.disabledStatus,
            name: ' Disable Suite',
        };
        const restoredOption = {
            action: (e) => {
                e.stopPropagation();
                this.onDeleteTestSuite(suite, 2);
            },
            icon: <Restore className={classes.moreIcons} aria-label="restoreIcon" id="restoreIcon" />,
            isDisabled: !suite.disabledStatus,
            name: ' Enable Suite',
        };

        // const deleteOption = {
        //     action: (e) => {
        //         e.stopPropagation();
        //         this.onDeleteTestSuite(suite, 0);
        //     },
        //     icon: <FontAwesomeIcon className={classes.moreIcons} icon={faTrashAlt} aria-label="faTrashAlt" id="faTrashAlt" />,
        //     isDisabled: false,
        //     name: ' Delete Suite',
        // };

        // if already disabled then we need to restore option only
        if (suite.disabledStatus === true) items = [...items, restoredOption];
        // if not disabled then we need to disabled option only
        else if (!suite.disabledStatus) items = [...items, disabledOption];

        // if (extend) {
        //     // if aiqExecution and isgenerating true and it should not
        //     // for selenium, if script exist, it should be enabled always
        //     extecuteAllOption = {
        //         action: () => this.openExecutionModal(suite),
        //         icon: <FlashOn className={[classes.moreIcons, classes.IconBtnWarning].join(' ')} aria-label="flashOnIcon" id="flashOnIcon" />,
        //         isDisabled: !checkArrayLength(suite.testCases), // https://github.com/Autonomiq/WebUIRedux/issues/1914
        //         name: 'Execute',
        //         dataId: `suite-${suite.testSuiteId}`, // specific for this
        //     };
        //     items = [extecuteAllOption, ...items];
        // }

        return items;
    };

    handleCloseDeleteDialog = async (flag) => {
        const onComplete = () => {
            this.setState({
                deleteTestSuiteData: null,
                deleteDialogOpen: false,
            });
        };
        if (flag) {
            const obj = {
                testSuiteIds: [this.state.deleteTestSuiteData.testSuiteId],
                suiteAction: this.state.isDisableCase,
                suiteTab: 'projectDetails',
                onComplete,
            };
            TestSuiteUtils.onCloseOrDeleteTestSuite(obj);
        } else {
            this.setState({
                deleteTestSuiteData: null,
                deleteDialogOpen: false,
            });
        }
    };

    handleAssociate = async (testSuiteId, testCaseIds) => {
        await this.props.associateTestSuites(testSuiteId, testCaseIds, () => {});
    };
    // association end

    handleDissociate = async (testSuiteId, testCaseIds) => {
        await this.props.deleteTestCaseFromSuite(testSuiteId, testCaseIds);
    };

    // Execution Start
    openExecutionModal = (suite) => {
        this.replaceURL(suite.testSuiteId, 'execute');
        this.showModal = false;
        this.setState({ executeDialogOpen: true, selectedSuite: suite, open: null });
    };

    hangdleCloseExecuteDialog = () => {
        this.replaceURL();
        this.setState({ executeDialogOpen: false, selectedSuite: null });
    };
    // Execution End

    // Test Case Implementation Start

    handleCloseTestCaseDeleteDialog = async (flag) => {
        if (flag) {
            if (this.state.deleteTestCaseData && this.state.deleteTestCaseData.testCaseId) {
                const result = await this.props.deleteTestCaseFromSuite(this.state.suite.testSuiteId, [this.state.deleteTestCaseData.testCaseId]);
                if (result.payload) {
                    const testCases = sort(this.state.suite.testCases, 'asc', 'ordinal').filter(
                        (_case) => `${_case.testCaseId}` !== `${this.state.deleteTestCaseData.testCaseId}`,
                    );
                    await this.handleSubmitOrganize(this.state.suite.testSuiteId, testCases);
                    this.getUserTestSuiteByProject();
                }
            }
        }
        this.setState({
            deleteTestCaseData: null,
            deleteCaseDialogOpen: false,
            suite: null,
        });
    };
    // Test Case Implementation End

    currentRowIndicator = () => {
        return (
            <span
                style={{
                    display: 'inline-block',
                    width: '4px',
                    minWidth: '4px',
                    marginRight: '16px',
                    height: '39px',
                    //backgroundColor: '#ff9700',
                }}
            ></span>
        );
    };

    handleStoreState = () => {
        this.props.storeSuiteState({
            caseOrder: this.state.caseOrder,
            caseOrderBy: this.state.caseOrderBy,
            order: this.state.order,
            orderBy: this.state.orderBy,
            page: this.state.page,
            rowExpendStatus: this.state.rowExpendStatus,
            rowsPerPage: this.state.rowsPerPage,
        });
        this.props.handleStoreState();
    };

    tableBodyRender = () => {
        const { classes, createTestSuite, query = '', testSuiteByProject, user, isLoading } = this.props;
        const {
            // selected,
            order,
            orderBy,
            caseOrder,
            caseOrderBy,
            rowsPerPage,
            page,
            rowExpendStatus,
            selectArr,
            isHovered,
            isHoveredTestCase,
        } = this.state;
        let testSuites = [];
        testSuiteByProject.forEach((suite) => {
            const executionStatus = suite.executionStatus === 'ERROR' ? 'FAILED' : suite.executionStatus === 'NA' ? '' : suite.executionStatus;
            testSuites.push({
                ...suite,
                suiteExecutionStatus: executionStatus, // to avoid sorting issue due to same key in suite and its child testCases
                executionStatus,
            });
        });
        if (query && checkArrayLength(testSuites)) {
            testSuites = testSuites.filter(
                (suite) => checkKeyInObject(suite, 'testSuiteName') && suite.testSuiteName.toLowerCase().includes(query.toLowerCase()),
            );
        }
        const currentPageSuites = sort(testSuites, order, orderBy, orderBy === 'creationTime' || orderBy === 'lastRunTime' ? 'time' : '').slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        );
        if (checkArrayLength(currentPageSuites)) {
            currentPageSuites.forEach((suite) => {
                if (checkKeyInObject(suite, 'testCases') && checkKeyInObject(suite, 'projectName') && checkArrayLength(suite.testCases)) {
                    suite.testCases.forEach((testCase) => {
                        testCase.projectName = suite.projectName;
                    });
                }
            });
        }

        const disabledRowContentColor = '#BAC4CE';
        return (
            <div>
                {isLoading ? (
                    <div className={classes.minHeight}>
                        <CircularProgress style={{ margin: '100px 50% 0px', color: '#4885ed' }} size={60} />
                    </div>
                ) : checkArrayLength(testSuites) ? (
                    <div className={classes.minHeight}>
                        <div className={classes.tableContainer}>
                            <Table aria-labelledby="tableTitle">
                                <Hidden only={['xs']}>
                                    <EnhancedTableHead
                                        showSelectBtn={false}
                                        showDeleteBtn={ selectArr.length === 1 ? true : false }
                                        deletebuttonColIndex={1}
                                        headers={headers}
                                        order={order}
                                        orderBy={orderBy}
                                        numSelected={selectArr.length}
                                        rowCount={testSuites.length}
                                        onSelectAllClick={(e) => this.SelectAll(e, testSuites)}
                                        onRequestSort={this.handleRequestSort}
                                        colsWidth={this.colsWidth}
                                        onDeleteClick = {(e) => { 
                                            let selected = testSuites.filter((suite) => selectArr.includes(suite.testSuiteId))
                                            console.log("selected",selected);
                                            e.stopPropagation();
                                            this.onDeleteTestSuite(selected[0], 0);
                                        }}
                                    />
                                </Hidden>
                                <Hidden smUp>
                                    <EnhancedTableHead
                                        showSelectBtn={false}
                                        showDeleteBtn={ selectArr.length === 1 ? true : false }
                                        deletebuttonColIndex={1}
                                        headers={headers}
                                        order={order}
                                        orderBy={orderBy}
                                        numSelected={selectArr.length}
                                        rowCount={testSuites.length}
                                        onSelectAllClick={(e) => this.SelectAll(e, testSuites)}
                                        onRequestSort={this.handleRequestSort}
                                        colsWidth={this.colsWidth}
                                    />
                                </Hidden>
                                <TableBody height={62} style={{ maxHeight: 'calc(100vh - 325px)',backgroundColor:'#f5f5f5' }}>
                                    {currentPageSuites.map((suite) => {
                                        const isNonGeneratedTestCaseExist = suite.testCases.some(testCase => !testCase.isScriptAvailable)
                                        const tooltipMsg = isNonGeneratedTestCaseExist ? 'You cannot execute this suite because it contains non-generated testcase' : 'Execute';
                                        const isChecked = Boolean(selectArr.includes(suite.testSuiteId));
                                        const _row = [];
                                        const conditionalColor = suite.disabledStatus ? disabledRowContentColor : TABLES_CSS.normalFontColor;
                                        _row.push(
                                            <TableRow
                                                className={classes.tableRow}
                                                focusCustom={classes.focused}
                                                hover 
                                                tabIndex={-1} 
                                                key={suite.testSuiteId} 
                                                focused={isChecked}
                                                onMouseEnter = { () => 
                                                    this.setState({isHovered: suite.testSuiteId})
                                                }
                                                onMouseLeave = { () => 
                                                    this.setState({isHovered: null})
                                                }
                                            >
                                                {/*** <TableCell
                                                    className={`${classes.noWrap} `}
                                                    width={headers[0].width}
                                                    data-resize={`element${0}_c`}
                                                >
                                                    <Checkbox
                                                        checked={Boolean(selectArr.includes(suite.testSuiteId))}
                                                        onChange={(e) => this.onSelectAllClick(e, suite.testSuiteId, false)}
                                                        classes={{
                                                            root: classes.checkBox,
                                                            checked: classes.checkBoxChecked,
                                                        }}
                                                        style={{ height: '19px', width: '20px', color: true ? '#bac5d0' : '#bac4ce', marginLeft: 10 }}
                                                    />
                                                </TableCell> ***/}
                                                <TableCell
                                                    style={headers[1].styles}
                                                    className={`${classes.noWrap}`}
                                                    data-resize={`element${1}_c`}
                                                >
                                                <ExpandBtn
                                                    flag={rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1]}
                                                    action={() => this.handleExpend(suite.testSuiteId)}
                                                    disabledStatus={suite.disabledStatus}
                                                />
                                                </TableCell>
                                                <TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${
                                                        rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1]
                                                            ? classes.currentCell
                                                            : classes.cell
                                                    }`}
                                                    width={headers[1].width}
                                                    style = {headers[1].tableCellStyles}
                                                    data-resize={`element${1}_c`}
                                                >
                                                    <div style={{ paddingLeft: '0px', width: '100%' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1]
                                                                ? this.currentRowIndicator()
                                                                : null}
                                                            <span
                                                                style={{
                                                                    color: '#3768b3',
                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                    cursor:'pointer',
                                                                    overflow: 'hidden', 
                                                                    textOverflow:"ellipsis", 
                                                                    whiteSpace: 'nowrap'
                                                                }}
                                                                onClick = { (e) => {                     
                                                                    e.stopPropagation();
                                                                    this.replaceURL(suite.testSuiteId, 'update');
                                                                    this.toggleMergeModal(true, suite);
                                                                }}
                                                            >
                                                            <span className={classes.avatar}>
                                                                <Tooltip data="No of Cases">
                                                                    {suite.testCases && suite.testCases.length ? suite.testCases.length : 0}
                                                                </Tooltip>
                                                            </span>
                                                            {isNonGeneratedTestCaseExist && <Tooltip data={'This suite contains test cases that are not generated yet.'} toolTipAreaStyle={{margin: '4px 0 0 -2px'}}>
                                                                    <WarningIcon style={{fontSize: 15, color: '#F39B31'}}/>
                                                                </Tooltip>
                                                            }
                                                                <Tooltip data={suite.testSuiteName ? suite.testSuiteName : null}>
                                                                    {suite.testSuiteName}
                                                                </Tooltip>
                                                            </span>

                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                    width={headers[2].width}
                                                    data-resize={`element${2}_c`}
                                                >
                                                    <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                        <span
                                                            style={{
                                                                color: conditionalColor,
                                                                fontSize: TABLES_CSS.normalFontSize,
                                                            }}
                                                        >
                                                            {checkKeyInObject(user, 'isRelativeDateFormat')
                                                                ? getRelativeTime(suite.creationTime)
                                                                : getFormatedDate(suite.creationTime, 'DD MMM YYYY', '')}
                                                        </span>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                    width={headers[3].width}
                                                    data-resize={`element${3}_c`}
                                                >
                                                    <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                        <span
                                                            style={{
                                                                color: conditionalColor,
                                                                fontSize: TABLES_CSS.normalFontSize,
                                                            }}
                                                        >
                                                            <span
                                                                className={classes.noWrap}
                                                                style={{
                                                                    color: conditionalColor,
                                                                    display: 'inline-block',
                                                                    verticalAlign: 'text-bottom',
                                                                }}
                                                            >
                                                                {checkKeyInObject(user, 'isRelativeDateFormat')
                                                                    ? getRelativeTime(suite.lastRunTime)
                                                                    : getFormatedDate(suite.lastRunTime, 'DD MMM YYYY', '')}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                    width={headers[4].width}
                                                    data-resize={`element${4}_c`}
                                                >
                                                    <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                        <span
                                                            style={{
                                                                color: conditionalColor,
                                                                fontSize: TABLES_CSS.normalFontSize,
                                                            }}
                                                        >
                                                            {suite.executionStatus}
                                                        </span>
                                                    </div>
                                                </TableCell>
                                                {/***<TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell_}`}
                                                    width={headers[5].width}
                                                    data-resize={`element${5}_c`}
                                                >
                                                    {( isHovered == suite.testSuiteId || isChecked ) && (
                                                        <Tooltip data="Edit">
                                                            <IconButton className={classes.IconButton} onClick={(e)=>{
                                                                e.stopPropagation();
                                                                this.replaceURL(suite.testSuiteId, 'update');
                                                                this.toggleMergeModal(true, suite);
                                                            }}>
                                                                <img src={edit} alt="Main" className={`${classes.bitmapImage} ${classes.moreIconsBtn}`} style={{ width: '16px' }} alt="" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>***/}
                                                <TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                    width={headers[5].width}
                                                    data-resize={`element${5}_c`}
                                                >
                                                    {( isHovered === suite.testSuiteId || isChecked ) && (
                                                        <Tooltip data={tooltipMsg}>
                                                            <IconButton className={classes.IconButton} onClick={(e)=>{ 
                                                               this.openExecutionModal(suite) 
                                                            }}
                                                            disabled = {!checkArrayLength(suite.testCases) || isNonGeneratedTestCaseExist}
                                                            >
                                                                <FlashOn
                                                                    className={[checkArrayLength(suite.testCases) && !isNonGeneratedTestCaseExist ? classes.moreIconsBtnEnabled : classes.moreIconsBtnDisabled , classes.shareButton].join(' ')}
                                                                    aria-label="flashOnIcon"
                                                                    id="flashOnIcon"
                                                                />   
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                    width={headers[6].width}
                                                    data-resize={`element${6}_c`}
                                                >   
                                                    {( isHovered === suite.testSuiteId || isChecked ) && (
                                                        <Tooltip data="Delete">
                                                            <IconButton  className={classes.IconButton} onClick={(e)=>{
                                                                e.stopPropagation();
                                                                this.onDeleteTestSuite(suite, 0);
                                                            }}>
                                                                <Delete
                                                                    className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                                                    aria-label="Delete"
                                                                    id="Delete" 
                                                                />    
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                    width={headers[7].width}
                                                    data-resize={`element${7}_c`}
                                                >
                                                    {!suite.disabledStatus && ( isHovered === suite.testSuiteId || isChecked ) ? (
                                                                <MenuOptions
                                                                    key={suite.testSuiteId}
                                                                    items={this.showMenuActions(suite, classes, true)}
                                                                    keepMounted
                                                                    MoreVertStyle={suite.disabledStatus ? { color: disabledRowContentColor } : {}}
                                                                />
                                                    ) : null}  
                                                </TableCell>
                                            </TableRow>,
                                        );
                                        if (rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1]) {
                                            _row.push(
                                                <TableRow
                                                    style={{ height: '40px', background: TABLES_CSS.subHeaderRowColor }}
                                                    key={`${suite.testSuiteId}-subHeader`}
                                                >
                                                    {subHeaders.map((column, ind) => (
                                                        <TableCell
                                                            padding="none"
                                                            width={column.width}
                                                            data-resize={`element${ind}_c`}
                                                            className={`${classes.subHeaderCell} ${classes.cell}`}
                                                            key={`testCase - ${column.id}`}
                                                        >
                                                            {column.sortable ? (
                                                                <div
                                                                    aria-hidden
                                                                    onClick={() => this.handleRequestCaseSort(column.id)}
                                                                    style={{ width: '100%', cursor: 'pointer' }}
                                                                >
                                                                    <Typography
                                                                        variant="body2"
                                                                        style={{
                                                                            fontSize: TABLES_CSS.headingFontSize,
                                                                            color: TABLES_CSS.headingFontColor,
                                                                            fontWeight: TABLES_CSS.headingFontWeight,
                                                                            overflow: 'hidden',
                                                                            whiteSpace: 'nowrap',
                                                                        }}
                                                                    >
                                                                        {column.label}
                                                                        {caseOrderBy === column.id ? (
                                                                            caseOrder === 'desc' ? (
                                                                                <FontAwesomeIcon
                                                                                    icon={faChevronDown}
                                                                                    aria-label="faChevronDown"
                                                                                    id="faChevronDown"
                                                                                    className={classes.sortIcon}
                                                                                />
                                                                            ) : (
                                                                                <FontAwesomeIcon
                                                                                    icon={faChevronUp}
                                                                                    aria-label="faChevronUp"
                                                                                    id="faChevronUp"
                                                                                    className={classes.sortIcon}
                                                                                />
                                                                            )
                                                                        ) : null}
                                                                    </Typography>
                                                                </div>
                                                            ) : (
                                                                <div className={classes.nonSortableContainer} key={column.id}>
                                                                    <Typography
                                                                        variant="body2"
                                                                        style={{
                                                                            fontSize: TABLES_CSS.headingFontSize,
                                                                            color: TABLES_CSS.headingFontColor,
                                                                            fontWeight: TABLES_CSS.headingFontWeight,
                                                                        }}
                                                                        noWrap
                                                                    >
                                                                        {column.label}
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>,
                                            );
                                        }
                                        {
                                            const testCases = sort(
                                                suite.testCases,
                                                caseOrder,
                                                caseOrderBy,
                                                caseOrderBy === 'createdTime' || caseOrderBy === 'lastRun' ? 'time' : '',
                                            );
                                            if (rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1]) {
                                                if (testCases && testCases.length > 0) {
                                                    _row.push(
                                                        testCases.map((testCase, index) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={-1}
                                                                    style={{
                                                                        height: '40px',
                                                                        display:
                                                                            rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1]
                                                                                ? 'table-row'
                                                                                : 'none',
                                                                    }}

                                                                    onMouseEnter = { () => { this.setState({ isHoveredTestCase:testCase.testCaseId }) }}
                                                                    onMouseLeave = { ()=> { this.setState({ isHoveredTestCase:null }) }}
                                                                    className={classes.tableRow}
                                                                    key={`${suite.testSuiteId}-${testCase.testCaseId}-${index}`}
                                                                >
                                                                    <TableCell
                                                                        padding="none"
                                                                        className={`${classes.noWrap} ${classes.cell}`}
                                                                        width={headers[0].width}
                                                                        data-resize={`element${0}_c`}
                                                                    >
                                                                    </TableCell>
                                                                    <TableCell
                                                                        padding="none"
                                                                        className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                        width={headers[1].width}
                                                                        data-resize={`element${1}_c`}
                                                                    >
                                                                        <div style={{ width: '100%' }}>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: TABLES_CSS.normalFontSize,
                                                                                        color: TABLES_CSS.normalFontColor,
                                                                                        display: 'flex',
                                                                                        alignItems: 'center'
                                                                                    }}
                                                                                >
                                                                                    {!testCase.isScriptAvailable && <Tooltip data={'Generate this test case to use it in suite.'} toolTipAreaStyle={{margin: '4px 0 0 -2px'}}>
                                                                                        <WarningIcon style={{fontSize: 15, color: '#F39B31'}}/>
                                                                                        </Tooltip>
                                                                                    }
                                                                                    <div style = {{ 
                                                                                                width: '410px',
                                                                                                overflow: 'hidden',
                                                                                                whiteSpace: 'nowrap',
                                                                                                textOverflow: 'ellipsis'}}
                                                                                    >
                                                                                        <Tooltip
                                                                                            data={testCase.testCaseName ? testCase.testCaseName : null}

                                                                                        >
                                                                                            {testCase.testCaseName}
                                                                                        </Tooltip>
                                                                                    </div>

                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                        width={headers[2].width}
                                                                        data-resize={`element${2}_c`}
                                                                    >
                                                                        <div style={{ width: '100%' }}>
                                                                            <span
                                                                                style={{
                                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                                    color: TABLES_CSS.normalFontColor,
                                                                                }}
                                                                            >
                                                                                <Tooltip
                                                                                    data={getFormatedDate(
                                                                                        testCase?.createdTime,
                                                                                        'DD MMM YYYY, HH:mm:ss',
                                                                                        null,
                                                                                    )}
                                                                                >
                                                                                    {checkKeyInObject(user, 'isRelativeDateFormat')
                                                                                        ? getRelativeTime(testCase?.createdTime)
                                                                                        : getFormatedDate(testCase?.createdTime, 'DD MMM YYYY', 'N/A')}
                                                                                </Tooltip>
                                                                            </span>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                        width={headers[3].width}
                                                                        data-resize={`element${3}_c`}
                                                                    >
                                                                        <div style={{ width: '100%' }}>
                                                                            <span
                                                                                style={{
                                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                                    color: TABLES_CSS.normalFontColor,
                                                                                }}
                                                                            >
                                                                                <Tooltip
                                                                                    data={getFormatedDate(
                                                                                        testCase?.lastRun,
                                                                                        'DD MMM YYYY, HH:mm:ss',
                                                                                        null,
                                                                                    )}
                                                                                >
                                                                                    {testCase.lastRun && testCase.lastRun !== testCase.createdTime
                                                                                        ? checkKeyInObject(user, 'isRelativeDateFormat')
                                                                                            ? getRelativeTime(testCase?.lastRun)
                                                                                            : getFormatedDate(testCase?.lastRun, 'DD MMM YYYY', 'N/A')
                                                                                        : 'N/A'}
                                                                                </Tooltip>
                                                                            </span>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                        width={headers[4].width}
                                                                        data-resize={`element${4}_c`}
                                                                    >
                                                                        <div style={{ width: '100%' }}>
                                                                            <span
                                                                                style={{
                                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                                    color: TABLES_CSS.normalFontColor,
                                                                                }}
                                                                            >
                                                                                {getExecutionStatus(testCase)}
                                                                            </span>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                        width={headers[5].width}
                                                                        data-resize={`element${5}_c`}
                                                                    >
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                        width={headers[6].width}
                                                                        data-resize={`element${6}_c`}
                                                                    >
                                                                        { ( isHoveredTestCase === testCase.testCaseId ) && (  
                                                                            <Tooltip data="Delete">
                                                                                <IconButton className={classes.IconButton} onClick={(e)=>{ this.onDeleteTestCase(suite, testCase) }}>
                                                                                    <Delete
                                                                                        className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                                                                        aria-label="Delete"
                                                                                        id="Delete" 
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            )
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={`${classes.noWrap} ${classes.minWidth} ${classes.cell}`}
                                                                        width={headers[7].width}
                                                                        data-resize={`element${7}_c`}
                                                                    >
                                                                    {
                                                                        ( isHoveredTestCase === testCase.testCaseId ) && ( <div className={classes.parentSuiteButtons}>
                                                                        {!suite.disabledStatus ? (
                                                                            <MenuOptions
                                                                                key={suite.testSuiteId}
                                                                                items={this.showCaseMenuActions(suite, testCase, classes, true)}
                                                                                keepMounted
                                                                                MoreVertStyle={suite.disabledStatus ? { color: disabledRowContentColor } : {}}
                                                                            />
                                                                        ) : null}
                                                                        </div> )
                                                                    }
                                                                    
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }),
                                                    );
                                                } else {
                                                    _row.push(
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            style={{
                                                                height: '40px',
                                                                alignItems:'center',
                                                                justifyContent:'center',
                                                                display:
                                                                    rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1]
                                                                        ? 'table-row'
                                                                        : 'none',
                                                            }}
                                                            key={`${suite.testSuiteId}_empty`}
                                                            className={classes.borders}
                                                        >
                                                                <Typography style={{ textAlign: 'center' }}>No test case available.</Typography>
                                                                <Button variant="contained" color="secondary" className={classes.addCaseButton}  
                                                                onClick = { (e) =>{
                                                                    e.stopPropagation();
                                                                    this.replaceURL(suite.testSuiteId, 'update');
                                                                    this.toggleMergeModal(true, suite);
                                                                }} >
                                                                    Add Test Case
                                                                </Button>
                                                        </TableRow>,
                                                    );
                                                }
                                            }

                                            return [..._row];
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                ) : (
                    <div className={classes.minHeight}>
                        <NoDataToShow imgSrc={ImageSrc} IconSrc={AddIconCircle} selectedTab="suite"  buttonTitle = "CREATE TEST SUITE" onClick={createTestSuite} />
                    </div>
                )}
                <TableFooter
                    count={testSuites.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    applyCustomClasses
                    labelRowsPerPage="Suites per page:"
                    containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                />
            </div>
        );
    };

    render() {
        const { projectSystemId, aiqExecution } = this.props;
        const {
            deleteDialogOpen,
            deleteTestSuiteData,
            isDisableCase,
            executeDialogOpen,
            selectedSuite,
            deleteTestCaseData,
            deleteCaseDialogOpen,
        } = this.state;
        return (
            <div>
                {deleteDialogOpen ? (
                    <DeleteTestSuiteDialog
                        deleteButton="Danger"
                        handleClose={this.handleCloseDeleteDialog}
                        open={deleteDialogOpen}
                        deleteTestSuiteData={deleteTestSuiteData}
                        isDisableCase={isDisableCase}
                    />
                ) : null}
                {executeDialogOpen ? (
                    <ExecuteScriptModal
                        projectId={projectSystemId}
                        name={selectedSuite.testSuiteName}
                        modalState={executeDialogOpen}
                        callingFrom={'Execute'}
                        handleClose={this.hangdleCloseExecuteDialog}
                        suite={selectedSuite}
                        type="suite"
                        aiqExecution={aiqExecution}
                    />
                ) : null}
                {deleteCaseDialogOpen ? (
                    <DeleteTestCaseDialog
                        deleteButton="Danger"
                        handleClose={this.handleCloseTestCaseDeleteDialog}
                        open={deleteCaseDialogOpen}
                        deleteTestCaseData={deleteTestCaseData}
                        isDisableCase={isDisableCase}
                    />
                ) : null}
                {this.tableBodyRender()}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        executionTasksStatus: state.projectReducer.executionTasksStatus,
        // general reducer
        query: state.generalReducer.queryValue,
        isLoading: state.projectReducer.isLoading,
        suiteState: state.projectReducer.suiteState,
        currentTab: state.generalModalReducer.currentTab,
        tabsData: state.generalModalReducer.tabsData,
        isWsRunning: state.projectReducer.isWsRunning[checkKeyInObject(checkKeyInObject(props, 'testCase'), 'testCaseId')],
    };
};

const mapDispatchToPorps = (dispatch) => {
    return {
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        // generalModalActions
        storeSuiteState: (states) => dispatch(ProjectActions.storeSuiteState(states)),
        clearSuiteState: () => dispatch(ProjectActions.clearSuiteState()),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        changeTab: (...args) => dispatch(generalModalActions.changeTab(...args)),
        clearModalData: (...args) => dispatch(generalModalActions.clearGeneralModal(...args)),
        // TestSuiteActions
        addTestSuites: (...args) => dispatch(TestSuiteActions.addTestSuites(...args)),
        editTestSuites: (...args) => dispatch(TestSuiteActions.editTestSuites(...args)),
    };
};
TestSuite.propTypes = {
    showSnackBar: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToPorps)(withStyles(styles)(TestSuite));
