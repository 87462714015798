import ScheduleMiddleware from '../middleware/scheduleMiddleware';
import { ActionTypes } from '../constants';

class ScheduleActions {
    static getSchedules() {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_ALL_SCHEDULES,
            });
            return ScheduleMiddleware.getSchedules()
                .then(({ schedules }) => {
                    return dispatch({
                        type: ActionTypes.GET_ALL_SCHEDULES_SUCCESS,
                        payload: schedules,
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_ALL_SCHEDULES_FAILURE,
                        payload: {
                            error,
                            message: 'Could not get schedules',
                        },
                    }),
                );
        };
    }

    static deleteSchedule(scheduleIds, onComplete = () => {}, onFailuer = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.DELETE_SCHEDULES,
            });
            return ScheduleMiddleware.deleteSchedule(scheduleIds)
                .then(() => {
                    onComplete();
                    return dispatch({
                        type: ActionTypes.DELETE_SCHEDULES_SUCCESS,
                        payload: { success: 'Deleted Schedule successfully' },
                    });
                })
                .catch((error) => {
                    onFailuer();
                    return dispatch({
                        type: ActionTypes.DELETE_SCHEDULES_FAILURE,
                        payload: {
                            error,
                            message: 'Could not delete job',
                        },
                    });
                });
        };
    }

    static addJob(data, callBack = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.ADD_JOB,
            });
            return ScheduleMiddleware.addJob(data)
                .then((payload) => {
                    callBack();
                    return dispatch({
                        type: ActionTypes.ADD_JOB_SCCUESS,
                        payload: { data: payload.data, success: 'Successfully add job' },
                    });
                })
                .catch((err) => {
                    return dispatch({
                        type: ActionTypes.ADD_JOB_FAILED,
                        payload: {
                            error: err,
                            message: 'Error in adding job',
                        },
                    });
                });
        };
    }

    static UpdateJob(id, data, callBack = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.UPDATE_JOB,
            });
            return ScheduleMiddleware.updateJob(id, data)
                .then((response) => {
                    callBack();
                    return dispatch({
                        type: ActionTypes.UPDATE_JOB_SCCUESS,
                        success: 'Update job successfully',
                        payload: response,
                    });
                })
                .catch((err) => {
                    return dispatch({
                        type: ActionTypes.UPDATE_JOB_FAILED,
                        payload: {
                            error: err,
                            message: 'Error in updating job',
                        },
                    });
                });
        };
    }

    static excuteScedule(id, is_paused) {
        const isPaused = is_paused === 1 ? 'Paused' : 'Played';
        return (dispatch) => {
            dispatch({
                type: ActionTypes.EXECUTE_SCHEDULE,
            });
            return ScheduleMiddleware.excuteScedule(id, is_paused)
                .then(() => {
                    dispatch(ScheduleActions.getSchedules());
                    return dispatch({
                        type: ActionTypes.EXECUTE_SCHEDULE_SUCCESS,
                        success: `Successfully ${isPaused} `,
                    });
                })
                .catch((err) => {
                    return dispatch({
                        type: ActionTypes.EXECUTE_SCHEDULE_FAILED,
                        payload: {
                            error: err,
                            message: `Error while ${isPaused} `,
                        },
                    });
                });
        };
    }

    static getTestSuitesForSchedules() {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_SUITE_SCHEDULES,
            });
            return ScheduleMiddleware.getTestSuitesForSchedules()
                .then((data) =>
                    dispatch({
                        type: ActionTypes.GET_SUITE_SCHEDULES_SUCCESS,
                        payload: {
                            data,
                        },
                    }),
                )
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_SUITE_SCHEDULES_FAILURE,
                        payload: error,
                    }),
                );
        };
    }

    static getTestCasesByJobId(jobId) {

        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_TESTCASES_BY_JOBID,
            });
            return ScheduleMiddleware.getTestCasesByJobId(jobId)
                .then(({ testCases }) => {

                    return dispatch({
                        type: ActionTypes.GET_TESTCASES_BY_JOBID_SUCCESS,
                        payload: {
                            data:testCases
                        },
                    });

                })
                .catch((error) => {


                     return dispatch({
                        type: ActionTypes.GET_TESTCASES_BY_JOBID_FAILURE,
                        payload: {
                            error:error.response.data,
                            message: 'Could not get test cases',
                        },
                    })
                    
                }
                    
                );
        };

    }
}

export default ScheduleActions;
