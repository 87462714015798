const styles =   () => ({
    option_root : {
        display:'flex',
        width:'100%',
        justifyContent:'space-between'
    },
    option: {
        fontFamily: 'Roboto !important',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#352D2D'
    }
});

export default styles