import React, { Component } from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import { Hidden, Typography, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

// fontawesome icons
import { faPencilAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
// import { faEye } from '@fortawesome/free-regular-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// MUI Icons
import AddIconCircle from '@material-ui/icons/AddCircle';
import Cancel from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import ContentCopy from '@material-ui/icons/FileCopySharp';
import Delete from '@material-ui/icons/Delete';
import Done from '@material-ui/icons/Done';
import FileDownload from '@material-ui/icons/GetApp';

// Custom
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';

import TableCell from '../../../components/Table/TableCell';
import TableRow from '../../../components/Table/TableRow';
import { GeneralActions, ProjectActions, ModalActions, generalModalActions, FlowActions } from '../../../store/actions';
//import { EnhancedTableHead } from '../../../components/EnhancedTable';
import EnhancedTableHead from '../../../components/EnhancedTable/EnhancedTableHead_v2';
import { MenuOptions } from '../../../components/Button/MenuOptions';
import { checkArrayLength, checkKeyInObject, sort, resizeAllCols } from '../../../utils/utils';
import CustomButton from '../../../components/Button/CustomButton';
// fontawesome icons
import NoDataToShow from '../../../components/NoDataToShow';
import {
    BLACK_FONT,
    MUI_ACTION_MENU_ICON,
    MODAL_DELETE_BUTTON,
    TABLES_CSS,
    THREE_DOT_MENU_CSS,
    MODAL_SUBMIT_BUTTON,
} from '../../../common/cssConstants';
import DeleteVariableModal from './DeleteVariableModal';
import Tooltip from '../../../components/Tooltip';
import TableFooter from '../../../common/TableFooter';
import { CANCEL_BUTTON, DANGER_OUTLINE_BUTTON, NEXT_SUBMIT_BUTTON } from '../../../components/Button/Btn';
import { TestStepUtils } from '../../../utils/TestStepUtils';
import CloneVariablesModal from '../../modal/CloneVariablesModal';
import CreateVariable from './CreateVariable';

import VariableImg from '../../../components/NoDataToShow/assests/file-code-regular.svg';

const styles = (theme) => ({
    menu: {
        width: 200,
    },
    textFieldDisable: {
        borderBottom: '1.5px solid #0092e5',
        cursor: 'not-allowed',
    },
    underline:{
        borderBottom: '1.5px solid #0092e5',
        width: '100%',
        display: 'block',
    },
    placeholder: {
        fontSize: 12,
        '&>div>div>:nth-child(3)::-webkit-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
        '&>div>div>:nth-child(3)::-moz-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
    },
    placeholderInput: {
        '&>div>input::-webkit-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
        '&>div>input::-moz-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
    },
    icon: {
        fontSize: 18,
    },
    cell: {
        flexDirection: 'row',
        textAlign: 'left',
        padding: 0,
        width: '20%',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    cellWithoutPadding: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: '4px 0px 0px 10px',
        width: 'calc(100% / 3)',
    },
    faEyeStyle: {
        fontSize: '20px',
        marginRight: '5px',
        marginLeft: '10px',
        cursor: 'pointer',
    },
    faPencilStyle: {
        fontSize: '17px',
        marginRight: '8px',
        marginLeft: '10px',
        marginTop: '1px',
        color: '#5F7B96',
        cursor: 'pointer',
    },
    root: {
        width: '100%',
    },
    tableheaderRoot: {
        padding: 15,
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '5px 5px 0px 0px'
    },
    textField: {
        '&>label': {
            fontSize: '15px',
            color: '#3768b3',
        },
    },
    noMargin: {
        marginTop: '0px !important',
    },
    minMargin: {
        marginTop: '6px !important',
    },
    error: {
        color: 'red',
        fontSize: 12,
    },
    tableRow: {
        backgroundColor: '#f5f5f5',
        borderTop: 'solid 0.5px rgb(151 151 151 / 69%)',
        height: '50px',
        fontSize:'12px',
        '&:hover': {
            '& .TableCell': {
              //  fontWeight: 'bold',
                backgroundColor: '#fff',
                '& .IconCell': {
                    // '& .iconContainer': {
                        // '& .IconButton': {
                            backgroundColor: 'red'
                        // }
                    // }
                },
            },
            backgroundColor: '#fff',
        },
    },
    editableTableRow: {
        backgroundColor: '#f5f5f5',
        borderTop: 'solid 0.5px rgb(151 151 151 / 69%)',
        fontSize:'12px',
        '&:hover': {
            '& .TableCell': {
                backgroundColor: '#fff',
                '& .IconCell': {
                    backgroundColor: 'red'
                },
            },
            backgroundColor: '#fff',
        },
    },
    hover: {
        minHeight: '62px', 
        backgroundColor: TABLES_CSS.rowColor,
        '&:hover': {
            fontWeight: 'bold',
            backgroundColor: TABLES_CSS.focusRowColor,
        },
    },
    focused: {
        background: '#fff',
        '& .TableCell': {
         //   fontWeight: 'bold',
        },
    },
    dialogContent: {
        padding: '0 !important',
        paddingLeft: '30px !important',
        paddingRight: '30px !important',
        width: 450,
    },
    dialogContent1: {
        padding: '0 !important',
        paddingLeft: '30px !important',
        paddingRight: '30px !important',
        width: '600px',
        maxHeight: 'calc(100vh - 273px)',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        fontSize: 22,
    },
    // shareButton: {
    //     color: 'rgb(240, 182, 55)',
    // },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    checkBox: {
        color: '#b8c4cf',
    },
    checkBoxChecked: {
        color: '#3B91DF !important',
    },
    minHeight: {
        minHeight: 'calc(100vh - 279px)',
    },
    minHeight264: {
        minHeight: 'calc(100vh - 264px)',
    },
    tableContainer: {
        backgroundColor: '#fff',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        overflow: 'hidden',
    },
    deleteButtonDanger: MODAL_DELETE_BUTTON,
    message: {
        float: 'left',
        fontSize: 12,
        color: BLACK_FONT,
    },
    contentCopy: MUI_ACTION_MENU_ICON,
    submitButton: MODAL_SUBMIT_BUTTON,
    saveButtonStyle: {
        fontSize: 18,
        marginRight: 15,
    },
    saveButtonEnable: {
        cursor: 'pointer',
        color: '#00aa00',
    },
    saveButtonDisbale: {
        cursor: 'not-allowed',
        color: '#a9a9a9',
    },
    bitmapImage:{
        width: '17px',
        height: '18px',
        margin: '0.5px 6px 0px 0',
    },
    btnColor: {
        color: '#ff9700',
    },
    disabledBtnColor: {
        color: '#afb7ac',
    },
    faIcons: {
        fontSize: 17,
    },
    disableImg:{
        display:'none',
    },
    boldRowData: {
       fontWeight: 'bold',
    },
    previewColor:{
        color: '#3768b3',
    },
    childIcon: {
        fontSize: 18,
        color: '#5F7B96',
    },
    childIconDisabled: {
        fontSize: 18,
        color: 'rgba(0, 0, 0, 0.26)',
    },
});


const headers = [
    { id: 'deleteIcon', width: '40px',tableCellStyles: { minWidth: 'unset' , paddingLeft: '10px'}, },
    { id: 'edit', width: '40px',tableCellStyles: { minWidth: 'unset' }, },
    { id: 'key', sortable: true, numeric: false, disablePadding: false, label: 'VARIABLE', width: 'calc(60% / 2)', tableCellStyles: { color : '#3768b3 !important' } },
    { id: 'value', sortable: true, numeric: false, disablePadding: false, label: 'VALUE', styles: { paddingLeft: 10 }, width: 'calc(70% / 2)' },
    { id: 'clone', sortable: false, numeric: false, disablePadding: false, label: 'CLONE', styles: { paddingLeft: 10 },color: 'rgba(56, 55, 55, 0.58)', width: 'calc(50% / 2)' },
    { id: 'delete', sortable: false, numeric: false, disablePadding: false, label: 'DELETE', styles: { paddingLeft: 10 },color: 'rgba(56, 55, 55, 0.58)', width: 'calc(20% / 2)' },
    //{ id: 'download', sortable: false, numeric: false, disablePadding: false, label: 'DOWNLOAD', styles: { paddingLeft: 10 },color: 'rgba(56, 55, 55, 0.58)', width: 'calc(20% / 2)' },
];
class VariableTab extends Component {
    constructor(props) {
        super(props);
        this.is_mounted = false;
        this.colsWidth = {};
        this.state = {
            disableOkayButton: false,
            userVars: [],
            userVarsOriginalCopy: [],
            currentEditableKey: null,
            currentEditableItem: {},
            variable: '',
            value: '',
            // expanded: 'variableList',
            order: 'asc',
            orderBy: 'key',
            page: 0,
            rowsPerPage: 20,
            showMultilineText: null,
            deleteVariableData: null,
            variableValueEdit: '',
            variableKeyEdit: '',
            selectArr: [],
            currentPageVariables: [],
            selectedAll: false,
            hoverKey:'',
            isHovered:null,
        };
        this.confirmAddVariableCallBack = () => {};
    }

    componentDidMount() {
        this.is_mounted = true;
        this.onDidMount();
        const caseSettingRows = localStorage.getItem('rowsCasePerPage');
        if (caseSettingRows) {
            this.updateState({ rowsPerPage: parseInt(caseSettingRows, 10) });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            project: { projectId },
        } = nextProps;
        const { pageNo } = this.state;
        let prevPageNo = parseInt(pageNo || 0)
        if(!nextProps.query) {
            this.setState({
                page : prevPageNo
            })
        }
        if (`${projectId}` !== `${this.props.project.projectId}`) {
            this.props.queryRemove();
            setTimeout(() => {
                this.onDidMount();
            }, 10);
        }
    }

    componentDidUpdate() {
        resizeAllCols(this.colsWidth);
    }

    componentWillUnmount() {
        this.is_mounted = false;
        this.props.queryRemove();
    }

    onDidMount = () => {
        const {
            project: { projectId },
            user: { accountId },
            getUserVariables,
            getProjectDetails,
        } = this.props;
        const onComplete = (data) => {
            if (data != null) {
                data = data.map((element) => {
                    element.oldKey = element.key;
                    return element;
                });
                this.setStateIfComponentMounted({
                    userVars: data,
                    userVarsOriginalCopy: data,
                });
            }
        };
        getUserVariables(accountId, projectId, onComplete, () => getProjectDetails(projectId));
    };

    onInputHandler = (key, e) => {
        this.setStateIfComponentMounted(
            {
                [key]: e.target.value,
            },
            () => {
                this.updateDisableCondition();
            },
        );
    };

    onEditHandler = () => {
        const { currentEditableItem, variableKeyEdit, variableValueEdit, userVars } = this.state;
        let oldState = userVars && userVars.length > 0 ? userVars.slice(0) : [];
        const exist = userVars.find((varObj) => `${varObj.key}` === `${variableKeyEdit}`);

        // If variable doesn't found
        // If not key is duplicated
        // If edit value of existing key (edit value case)

        if (!exist || (`${exist.value}` !== `${variableValueEdit}` && `${currentEditableItem.oldKey}` === `${variableKeyEdit}`)) {
            let item = {};
            oldState = oldState.map((element) => {
                if (element.oldKey === currentEditableItem.oldKey) {
                    element.key = variableKeyEdit && variableKeyEdit.trim();
                    element.value = variableValueEdit;
                    item = element;
                }
                return element;
            });
            this.setStateIfComponentMounted(
                {
                    userVars: oldState,
                },
                () => {
                    this.handleEditSubmit(item);
                },
            );
        } else {
            this.props.showSnackBar('Custom variable already exist');
        }
    };

    onAddItem = (event) => {
        event.preventDefault();
        // to disable button to prevent multiple clicks | Need to confirm with Haseeb
        this.setStateIfComponentMounted({
            disableOkayButton: true,
        });
        const { value, variable } = this.state;
        const {
            classes,
            project: { projectId },
            user: { accountId },
            getUserVariables,
            toggleModal,
            clearGeneralModal,
        } = this.props;
        const obj = {
            accountId,
            projectId,
            key: variable,
            value,
            oldKey: variable,
        };

        const oldState = this.state.userVars && this.state.userVars.length > 0 ? JSON.parse(JSON.stringify(this.state.userVars)) : [];

        const isAlready = oldState.findIndex((_obj) => _obj.key.toUpperCase() === variable.toUpperCase());

        if (isAlready === -1) {
            const objCopy = { ...obj };
            const onComplete = () => {
                oldState.push(objCopy);
                this.setStateIfComponentMounted({
                    userVars: oldState,
                    variable: '',
                    value: '',
                    disableOkayButton: false,
                    selectArr: [],
                    selectedAll: false,
                });
                toggleModal();
                getUserVariables(
                    accountId,
                    projectId,
                    () => {},
                    () => {},
                );
            };
            delete obj.oldKey;
            this.handleFinalSubmit(obj, false, onComplete);
        } else {
            this.confirmAddVariableCallBack = (isConfirm) => {
                if (isConfirm) {
                    const onComplete = () => {
                        oldState[isAlready] = {
                            ...oldState[isAlready],
                            value,
                        };
                        this.setStateIfComponentMounted({
                            userVars: oldState,
                            variable: '',
                            value: '',
                            disableOkayButton: false,
                        });
                        getUserVariables(
                            accountId,
                            projectId,
                            () => {},
                            () => {},
                        );
                    };
                    delete obj.oldKey;
                    this.handleFinalSaveVariable(obj, projectId, onComplete);
                    toggleModal();
                    clearGeneralModal();
                } else {
                    this.setStateIfComponentMounted({
                        disableOkayButton: false,
                    });
                }
            };
            toggleModal('GeneralModal', null, null, {
                title: 'Replace Variable',
                closeIconAction: () => {
                this.setStateIfComponentMounted({
                    disableOkayButton:false
                });
                    toggleModal('GeneralModal', null, null, null, false, true);
                },
                component: [
                    {
                        content: (
                            <div
                                style={{
                                    width: '100%',
                                    padding: '0 30px',
                                    minWidth: '300px',
                                }}
                            >
                                <Typography className={classes.message}>Variable name is already exist, would you like to overwrite it?</Typography>
                            </div>
                        ),
                        buttons: [
                            NEXT_SUBMIT_BUTTON({
                                name: 'Replace',
                                action: () => {
                                    this.confirmAddVariableCallBack(true);
                                },
                                buttonClass: classes.submitButton,
                            }),
                            CANCEL_BUTTON({
                                action: () => {
                                    toggleModal('GeneralModal', null, null, null, false, true);
                                    this.setStateIfComponentMounted({
                                        disableOkayButton: false,
                                    });
                                },
                            }),
                        ],
                    },
                ],
            });
        }
    };

    onClickAlertDialogOpen = () => {
        const { toggleModal, classes } = this.props;
        toggleModal('GeneralModal', null, null, {
            title: 'Clear Variables',
            closeIconAction: () => {
                toggleModal();
            },
            component: [
                {
                    content: (
                        <div
                            style={{
                                width: '100%',
                                padding: '0 30px',
                                minWidth: '300px',
                            }}
                        >
                            <Typography className={classes.message}>Are you sure you want to clear all variables?</Typography>
                        </div>
                    ),
                    buttons: [
                        DANGER_OUTLINE_BUTTON({
                            name: 'Clear',
                            action: this.clearAllCallBack,
                            buttonClass: classes.deleteButtonDanger,
                        }),
                        CANCEL_BUTTON({
                            action: () => {
                                toggleModal();
                            },
                        }),
                    ],
                },
            ],
        });
    };

    onSelectAllClick = (e, key, con = true) => {
        const { selectArr } = this.state;
        const updated = e.target.checked ? [...selectArr, key] : selectArr.filter((val) => val !== key);
        this.setStateIfComponentMounted({ selectArr: updated, selectedAll: con });
    };

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (this.is_mounted) this.setState(obj, callback);
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleEdit = (item) => {
        this.setStateIfComponentMounted({
            currentEditableItem: item,
            currentEditableKey: item.oldKey,
            variableKeyEdit: item.key,
            variableValueEdit: item.value,
        });
    };

    handleEditCancel = () => {
        const { currentEditableItem, userVars } = this.state;
        let oldState = userVars && userVars.length > 0 ? JSON.parse(JSON.stringify(userVars)) : [];
        oldState = oldState.map((element) => {
            if (element.oldKey === currentEditableItem.oldKey) {
                element = currentEditableItem;
            }
            return element;
        });
        this.setStateIfComponentMounted({
            userVars: oldState,
            currentEditableKey: '',
            currentEditableItem: {},
        });
    };

    handleEditSubmit = (item) => {
        const { deleteUserVariable } = this.props;
        const { userVars } = this.state;
        let oldState = userVars && userVars.length > 0 ? JSON.parse(JSON.stringify(userVars)) : [];
        if (item.key.trim() !== '' && item.value.trim() !== '') {
            oldState = oldState.map((element) => {
                if (element.oldKey === item.oldKey) {
                    element.oldKey = item.key;
                }
                return element;
            });
            const onComplete = (/* data */) => {
                const obj = { ...item };
                delete obj.oldKey;
                this.handleFinalSubmit(obj, true);
            };
            deleteUserVariable(item.accountId, item.projectId, item.oldKey, true, onComplete, false);
            this.setStateIfComponentMounted({
                userVars: oldState,
                currentEditableKey: '',
                currentEditableItem: {},
                selectArr: [],
                selectedAll: false,
            });
        }
    };

    handleDelete = (isConfirm,selectedVar = {}) => {
        if (isConfirm) {
            const { deleteVariableData } = this.state;
            const {
                project: { projectId },
                user: { accountId },
                deleteUserVariable,
            } = this.props;
            const onComplete = (data) => {
                if (data != null) {
                    data = data.map((element) => {
                        element.oldKey = element.key;
                        return element;
                    });
                    this.setStateIfComponentMounted({
                        userVars: data,
                        userVarsOriginalCopy: data,
                        deleteVariableData: null,
                        selectArr: [],
                        selectedAll: false,
                    });
                }
            };
            deleteUserVariable(accountId, projectId, typeof selectedVar === 'object' && selectedVar !== null ? selectedVar.oldKey  : deleteVariableData.oldKey, false, onComplete);
        } else {
            this.setStateIfComponentMounted({
                deleteVariableData: null,
            });
        }
        this.props.toggleModal();
    };

    updateDisableCondition = () => {
        const { disableOkayButton, variable, value } = this.state;
        const inDialogVariableInValid = /\s+/g.test(variable);
        this.props.updateTabData(
            'isDisabledSubmitButton',
            !`${value}`.trim() || !`${variable}`.trim() || inDialogVariableInValid || disableOkayButton,
        );
    };

    handleReset = () => {
        this.setStateIfComponentMounted({
            userVars:
                this.state.userVarsOriginalCopy && this.state.userVarsOriginalCopy.length > 0
                    ? JSON.parse(JSON.stringify(this.state.userVarsOriginalCopy))
                    : [],
            editingKeys: [],
        });
    };

    handleFinalSubmit = async (newUserVariable, isEdit, onComplete = () => {}) => {
        if (newUserVariable.key.trim() !== '' && newUserVariable.value.trim() !== '') {
            this.props.saveUserVariables(newUserVariable, isEdit, onComplete);
        }
    };

    handleFinalSaveVariable = async (newUserVariable, projectId, onComplete = () => {}) => {
        if (newUserVariable.key.trim() !== '' && newUserVariable.value.trim() !== '') {
            this.props.updateUserVariables(newUserVariable, projectId, onComplete);
        }
    };

    clearAllCallBack = () => {
        this.props.toggleModal();
        this.handleClearAll();
    };

    handleClearAll = () => {
        const {
            project: { projectId },
            user: { accountId },
            deleteAllUserVariable,
            getProjectDetails,
        } = this.props;
        const onComplete = (data) => {
            if (data != null) {
                data = data.map((element) => {
                    element.oldKey = element.key;
                    return element;
                });
                this.setStateIfComponentMounted(
                    {
                        userVars: data,
                        userVarsOriginalCopy: data,
                    },
                    () => {
                        getProjectDetails(projectId);
                    },
                );
            }
        };
        deleteAllUserVariable(accountId, projectId, false, onComplete);

        this.setStateIfComponentMounted({
            userVars: [],
        });
    };

    // handleChange = (panel) => {
    //     this.setStateIfComponentMounted((state) => ({
    //         expanded: state.expanded !== panel ? panel : false,
    //     }));
    // };

    handleChangePage = (page) => {
        this.setStateIfComponentMounted({ page });
        if(!this.props.query) {
            this.setState({
                pageNo:page
            })
        }
    };

    handleChangeRowsPerPage = (event) => {
        localStorage.setItem('rowsCasePerPage', event.target.value);
        this.setStateIfComponentMounted({ rowsPerPage: event.target.value });
        if (this.state.selectedAll) {
            this.SelectAll({ target: { checked: true } }, this.props.userVariables);
        }
    };

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setStateIfComponentMounted({ order, orderBy });
    };

    handleEyeIcon = (variableData) => {
        let updatedValue = null;
        if (`${variableData.oldKey}` !== `${this.state.showMultilineText}`) {
            updatedValue = variableData.oldKey;
        }
        this.setStateIfComponentMounted({ showMultilineText: updatedValue });
    };

    SelectAll = (e, currentPageVariables) => {
        const select = e.target.checked ? currentPageVariables.map((variable) => variable.key) : [];
        this.setStateIfComponentMounted({ selectArr: select, selectedAll: true });
    };

    cloneAllVariables = () => {
        const { selectArr, currentPageVariables, selectedAll } = this.state;
        const { userVariables } = this.props;
        const allSelected = currentPageVariables.filter((variable) => selectArr.includes(variable.key));
        this.props.toggleModal('adminUserModal', null, '', {
            modaltype: 'cloneAllVariables',
            allVariables: selectedAll ? userVariables : allSelected,
        });
    };

    handleDeleteVariableModal = () => {
        const { toggleModal, classes } = this.props;
        const { deleteVariableData,selectArr, userVars } = this.state;
        let _uservars = userVars;
        let selectedVar = deleteVariableData;

        if (checkArrayLength(selectArr)) { 
            if(selectArr.length > 1 ){
               return this.clearAllCallBack()
            }
            selectedVar = _uservars.filter((v) => v.key === selectArr);
            selectedVar = selectedVar[0];
        }

        toggleModal('GeneralModal', null, null, {
            title: 'Delete Variable',
            closeIconAction: () => {
                toggleModal();
            },
            component: [
                {
                    content: (
                        <DeleteVariableModal
                            title={selectedVar.key}
                            text="Are you sure you want to  delete?"
                            value={TestStepUtils.isShowAsterik(selectedVar.key, selectedVar.value)}
                        />
                    ),
                    buttons: [
                        DANGER_OUTLINE_BUTTON({
                            action: () => this.handleDelete(true,selectedVar),
                            buttonClass: classes.deleteButtonDanger,
                        }),
                        CANCEL_BUTTON({ action: () => this.handleDelete(false,selectedVar) }),
                    ],
                },
            ],
        });
    };

    handleCreateVariableModal = () => {
        const { toggleModal, classes, clearGeneralModal } = this.props;

        this.setStateIfComponentMounted({
            disableOkayButton:false,
            variable:'',
            value:''
        });

        toggleModal('GeneralModal', null, null, {
            title: 'New Variable',
            closeIconAction: () => {
                toggleModal();
                clearGeneralModal();
            },
            component: [
                {
                    content: <CreateVariable classes={classes} onInputHandler={this.onInputHandler} />,
                    buttons: [
                        NEXT_SUBMIT_BUTTON({
                            name: 'Save',
                            action: this.onAddItem,
                            isDisabled: (tabsData) => {
                                return checkKeyInObject(tabsData, 'isDisabledSubmitButton', 'value', true);
                            },
                        }),
                        CANCEL_BUTTON({
                            action: () => {
                                toggleModal();
                                clearGeneralModal();
                            },
                        }),
                    ],
                },
            ],
        });
    };

    // Clone Variable

    handleCloneSubmit = () => {
        const { tabsData, toggleModal, clearGeneralModal } = this.props;
        const variableData = checkKeyInObject(tabsData, 'cloneVariable.state.variableData');
        const selectedProject = checkKeyInObject(tabsData, 'cloneVariable.state.selectedProject');
        const obj = {
            accountId: variableData[0].accountId,
            projectId: Number(variableData[0].projectId),
            newPrjId: Number(selectedProject),
            key: variableData[0].oldKey,
            value: variableData[0].value,
        };
        this.props.cloneVariable({ vars: [obj] }, (isError) => {
            if (isError) {
                this.props.updateTabData('cloneVariable', {
                    state: { ...checkKeyInObject(tabsData, 'cloneVariable.state', 'value', {}), error: 'clone fail' },
                });
            } else {
                toggleModal();
                clearGeneralModal();
            }
        });
    };

    handleCloneVariableModal = (variableData) => {
        const { toggleModal, classes, projects, clearGeneralModal } = this.props;
        const variableDataArray = [variableData];

        toggleModal('GeneralModal', null, null, {
            title: 'Clone Variables',
            closeIconAction: () => {
                toggleModal();
                clearGeneralModal();
            },
            component: [
                {
                    content: (
                        <DialogContent className={classes.dialogContent1}>
                            <CloneVariablesModal toggleModal={toggleModal} variableData={variableDataArray} projects={projects} />
                        </DialogContent>
                    ),
                    buttons: [
                        NEXT_SUBMIT_BUTTON({
                            name: 'Clone',
                            action: this.handleCloneSubmit,
                            isDisabled: (tabsData) => {
                                return (
                                    Boolean(typeof checkKeyInObject(tabsData, 'cloneVariable.state.selectedProject', 'value') !== 'string') ||
                                    checkKeyInObject(tabsData, 'cloneVariable.state', 'value').variableData[0].projectId.toString() ===
                                        checkKeyInObject(tabsData, 'cloneVariable.state.selectedProject', 'value') ||
                                    Boolean(checkKeyInObject(tabsData, 'cloneVariable.state.error', 'value', ''))
                                );
                            },
                        }),
                        CANCEL_BUTTON({
                            action: () => {
                                toggleModal();
                                clearGeneralModal();
                            },
                        }),
                    ],
                },
            ],
        });
    };

    showNoVar = () => {
        return (
            <div className={this.props.classes.minHeight}>
                <NoDataToShow imgSrc={VariableImg} IconSrc={AddIconCircle} selectedTab="variable" buttonTitle = "ADD VARIABLE" onClick={this.handleCreateVariableModal} />
            </div>
        );
    };

   

    onDownloadClick = (downloadData) =>{
        const {selectArr} = this.state;
        const { project: { projectName },toggleModal } = this.props;
        if(checkArrayLength(selectArr) && selectArr.length > 1){
           
                        
                            const obj = {
                                data: downloadData,
                                name: `${projectName}_Variables`,
                                fileName: `${projectName}_Variables`,
                                modalName: 'VariableModal',
                            };
                            toggleModal('filePreviewModal', null, null, obj);
                      
        }
    }

    renderMenuItems = (downloadData ) => {
        const { userVars} = this.state;
        let badgeValue = checkArrayLength(userVars) ? userVars.length : 0 ;
        const { project: { projectName }, toggleModal, classes } = this.props;
        const items = [];

        items.push({
            action: (e) => {
                            e.stopPropagation();
                            const obj = {
                                data: downloadData,
                                name: `${projectName}_Variables`,
                                fileName: `${projectName}_Variables`,
                                modalName: 'VariableModal',
                            };
                            toggleModal('filePreviewModal', null, null, obj);
            },
            icon: ( <FileDownload
                        className={badgeValue < 1 ? classes.childIconDisabled : classes.childIcon}
                        aria-label="fileDownloadIcon"
                        id="fileDownloadIcon"
                    />
            ),
            isDisabled: badgeValue < 1 ,
            name: 'Download All Variables',
        });

        items.push({
            action: (e) => {
                            e.stopPropagation();
                            if (badgeValue > 0) {
                                this.onClickAlertDialogOpen();
                            }
                        },
            icon: ( <ClearIcon className={badgeValue < 1 ? classes.childIconDisabled : classes.childIcon} aria-label="clearIcon" id="clearIcon" />
            ),
            isDisabled: badgeValue < 1 ,
            name: 'Delete All Variables',
        });

        return items;
    }

    renderItems = (variableData, splittedValue) => {
        const { showMultilineText } = this.state;
        const { classes } = this.props;
        const items = [];

        items.push({
            action: () => {
                if (splittedValue.length > 1) {
                    this.handleEyeIcon(variableData);
                }
            },
            icon: (
                <FontAwesomeIcon
                    icon={faEye}
                    aria-label="faEye"
                    id="faEye"
                    style={{ color: splittedValue.length > 1 ? '#5F7B96' : '#C4C4C4' }}
                    className={classes.faEyeStyle}
                />
            ),
            isDisabled: splittedValue.length <= 1,
            name: showMultilineText === variableData.oldKey ? ' Close' : ' Open',
        });
        items.push({
            action: () => {
                this.handleCloneVariableModal(variableData);
            },
            icon: <ContentCopy className={classes.contentCopy} style={{ cursor: 'pointer' }} aria-label="contentCopyIcon" id="contentCopyIcon" />,
            isDisabled: false,
            name: ' Clone',
        });
        items.push({
            action: () => {
                this.handleEdit(variableData);
            },
            icon: <FontAwesomeIcon className={classes.faPencilStyle} icon={faPencilAlt} aria-label="faPencilAlt" id="faPencilAlt" />,
            isDisabled: false,
            name: ' Update',
        });
        items.push({
            action: () => {
                this.setStateIfComponentMounted(
                    {
                        deleteVariableData: variableData,
                    },
                    () => this.handleDeleteVariableModal(),
                );
            },
            icon: <Delete className={classes.contentCopy} style={{ cursor: 'pointer' }} aria-label="deleteIcon" id="deleteIcon" />,
            isDisabled: false,
            name: ' Delete',
        });
        return items;
    };

    render() {
        const {
            classes,
            query = '',
            // user: { accountId },
            // project: { projectName, projectId },
            isLoading,
        } = this.props;
        const {
            userVars,
            currentEditableKey,
            order,
            orderBy,
            page,
            rowsPerPage,
            showMultilineText,
            variableValueEdit,
            variableKeyEdit,
            selectArr,
            isHovered
        } = this.state;
        const downloadData = [
            {
                columns: ['Variable', 'Value'],
                data: [],
            },
        ];

        let _userVars = sort(userVars, order, orderBy);

        _userVars.forEach((_var) => {
            const splittedValue = _var.value && _var.value.length > 0 ? _var.value.trim().split('\n') : [];
            downloadData[0].data.push([
                { value: _var.key, style: { alignment: { vertical: 'top', wrapText: false } } },
                { value: _var.value, style: { alignment: { vertical: 'top', wrapText: splittedValue.length > 1 } } },
            ]);
        });

        if (query && checkArrayLength(_userVars)) {
            _userVars = _userVars.filter(
                (vars) =>
                    (checkKeyInObject(vars, 'key') && vars.key.toLowerCase().includes(query.toLowerCase())) ||
                    (checkKeyInObject(vars, 'key') &&
                        checkKeyInObject(vars, 'value') &&
                        vars.value.toLowerCase().includes(query.toLowerCase()) &&
                        !TestStepUtils.isPassword(vars.key.toLowerCase())),
            );
        }

        const currentPageVariables = _userVars.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        return (
            <div className={classes.root}>
                {isLoading ? (
                    <div className={classes.minHeight264}>
                        <CircularProgress style={{ margin: '100px 50% 0px', color: '#4885ed' }} size={60} />
                    </div>
                ) : (
                    <div className={classes.minHeight264}>
                        <div className={classes.tableheaderRoot}>
                        <CustomButton title="Add Variable" onClick = {this.handleCreateVariableModal} />
                        <div style = {{  display: 'flex' }}>
                           <div style = {{ marginLeft: 10 }}>
                                {/* start dotted menu */}
                                { checkArrayLength(userVars) ? (<MenuOptions items={this.renderMenuItems(downloadData)} keepMounted />) : null }
                                {/* end dotted menu */}
                            </div>
                        </div>
                        </div>
                        {checkArrayLength(userVars) ? (
                            checkArrayLength(currentPageVariables) ? (
                                <div style={{ width: '100%' }}>
                                    <div className={classes.tableScrollParent}>
                                        <div className={classes.tableContainer}>
                                            <Table>
                                                <Hidden only={['xs']}>
                                                    <EnhancedTableHead
                                                        //showSelectBtn={true}
                                                        //showEraseBtn={ selectArr.length == 1 || selectArr.length == _userVars.length  ? true : false }
                                                        //showDownloadBtn={ selectArr.length == 1 || selectArr.length == _userVars.length  ? true : false }
                                                        // erasebuttonColIndex={2}
                                                        // downloadbuttonColIndex={1}
                                                        headers={headers}
                                                        order={order}
                                                        numSelected={selectArr.length}
                                                        rowCount={_userVars.length}
                                                        onSelectAllClick={(e) => this.SelectAll(e, _userVars)}
                                                        orderBy={orderBy}
                                                        onRequestSort={this.handleRequestSort}
                                                        colsWidth={this.colsWidth}
                                                        noResizeColsIndex={[0, 6, 7, 8, 9, 10, 11]}
                                                        // onDeleteClick={this.handleDeleteVariableModal}
                                                        // onDownloadClick={(e) => {
                                                        //     e.stopPropagation();
                                                        //     this.onDownloadClick(downloadData);
                                                        // }}
                                                        
                                                    />
                                                </Hidden>
                                                <Hidden smUp>
                                                    <EnhancedTableHead
                                                        // showSelectBtn={true}
                                                        // showEraseBtn={ selectArr.length == 1 || selectArr.length == _userVars.length  ? true : false }
                                                        // showDownloadBtn={ selectArr.length == 1 || selectArr.length == _userVars.length  ? true : false } 
                                                        // erasebuttonColIndex={2}
                                                        // downloadbuttonColIndex={1}
                                                        headers={headers}
                                                        order={order}
                                                        numSelected={selectArr.length}
                                                        rowCount={_userVars.length}
                                                        onSelectAllClick={(e) => this.SelectAll(e, _userVars)}
                                                        orderBy={orderBy}
                                                        onRequestSort={this.handleRequestSort}
                                                        colsWidth={this.colsWidth}
                                                        onDeleteClick={this.handleDeleteVariableModal}
                                                        noResizeColsIndex={[0, 6, 7, 8, 9, 10, 11]}
                                                        // onDownloadClick={(e) => {
                                                        //     e.stopPropagation();
                                                        //     this.onDownloadClick(downloadData);
                                                        // }}
                                                        
                                                    />
                                                </Hidden>
                                                <TableBody height={50} style={{ maxHeight: 'calc(100vh - 380px)',backgroundColor:'#f5f5f5' }}>
                                                    {currentPageVariables.map((variableData, index) => {
                                                        let isChecked = Boolean(selectArr.includes(variableData.key));
                                                        const splittedValue =
                                                            variableData.value && variableData.value.length > 0
                                                                ? variableData.value.trim().split('\n')
                                                                : [];
                                                        const isEnableSaveButton =
                                                            variableKeyEdit !== '' &&
                                                            variableValueEdit !== '' &&
                                                            (variableKeyEdit !== variableData.key || variableValueEdit !== variableData.value);
                                                        if (variableData) {
                                                            return (
                                                                <TableRow
                                                                    className={currentEditableKey === variableData.oldKey  ? classes.editableTableRow : classes.tableRow}
                                                                    focusCustom={classes.focused}
                                                                    tabIndex={-1}
                                                                    hover
                                                                    key={index}
                                                                    focused={isChecked}
                                                                    onMouseEnter = { () => 
                                                                        this.setStateIfComponentMounted({isHovered: variableData.key})
                                                                    }
                                                                    onMouseLeave = { () => 
                                                                        this.setStateIfComponentMounted({isHovered: null })
                                                                    }
                                                                    
                                                                >
                                                                    {/***<TableCell
                                                                        style={headers[0].tableCellStyles}
                                                                        width={headers[0].width}
                                                                        data-resize={`element${0}_c`}
                                                                    >
                                                                     <Checkbox
                                                                        checked={isChecked}
                                                                        onChange={(e) => this.onSelectAllClick(e, variableData.key, false)}
                                                                        classes={{
                                                                            root: classes.checkBox,
                                                                            checked: classes.checkBoxChecked,
                                                                        }}
                                                                        style={{ height: '19px', width: '20px', color: true ? '#bac5d0' : '#bac4ce', marginLeft: 10 }}
                                                                    />  
                                                                    </TableCell>***/}
                                                                    <TableCell
                                                                        style={headers[0].tableCellStyles}
                                                                        width={headers[0].width}
                                                                        data-resize={`element${0}_c`}
                                                                    >
                                                                    { currentEditableKey === variableData.oldKey && (
                                                                        <Done
                                                                            className={`${classes.saveButtonStyle} ${
                                                                                classes[
                                                                                    isEnableSaveButton
                                                                                        ? 'saveButtonEnable'
                                                                                        : 'saveButtonDisbale'
                                                                                ]
                                                                            }`}
                                                                            aria-label="doneIcon"
                                                                            id="doneIcon"
                                                                            onClick={() => {
                                                                                if (isEnableSaveButton) this.onEditHandler();
                                                                            }}
                                                                        />)
                                                                    }                   

                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={headers[1].tableCellStyles}
                                                                        width={headers[1].width}
                                                                        data-resize={`element${1}_c`}
                                                                    >
                                                                        <div>
                                                                            <span style={{ display: 'inline-block' }}>
                                                                                {currentEditableKey === variableData.oldKey &&  (
                                                                                    <span style={{ display: 'flex' }}>

                                                                                        <Cancel
                                                                                            style={{
                                                                                                fontSize: 18,
                                                                                                cursor: 'pointer',
                                                                                                color: 'red',
                                                                                            }}
                                                                                            aria-label="cancelIcon"
                                                                                            id="cancelIcon"
                                                                                            onClick={this.handleEditCancel}
                                                                                        />
                                                                                    </span>
                                                                                ) }
                                                                            </span>
                                                                        </div>

                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[
                                                                            classes.cellWithoutPadding,
                                                                            classes.noWrap,
                                                                            classes.minWidth,
                                                                        ].join(' ')}
                                                                        style={headers[2].tableCellStyles}
                                                                        width={headers[2].width}
                                                                        data-resize={`element${2}_c`}
                                                                    >
                                                                        <div>
                                                                            <div style={{ display: 'flex' }}>

                                                                                {currentEditableKey === variableData.oldKey ? (
                                                                                    <div style={{ marginTop: -10, paddingLeft: 10, width: '100%' }}>
                                                                                        <TextField
                                                                                            id="variableKeyEdit"
                                                                                            aria-label="Variable Key Edit"
                                                                                            classes = {{
                                                                                                root: classes.underline, // class name, e.g. `classes-nesting-root-x`
                                                                                            }}
                                                                                            className={
                                                                                                [classes.underline, currentEditableKey !== variableData.oldKey? classes.textFieldDisable : classes.textField].join('')
                                                                                            }
                                                                                            value={variableKeyEdit}
                                                                                            onChange={(e) => {
                                                                                                this.setStateIfComponentMounted({
                                                                                                    variableKeyEdit: e.target.value,
                                                                                                });
                                                                                            }}
                                                                                            // onChange={this.handleChange('fname')}
                                                                                            fullWidth
                                                                                            autoFocus
                                                                                            InputProps={{
                                                                                                disableUnderline: true,
                                                                                            }}
                                                                                            margin="none"

                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <span style ={{color: '#3768b3'}} onClick = {(e) =>{this.handleEdit(variableData)}}>
                                                                                        <Tooltip  data={` ${variableData.key}`}>{variableData.key}</Tooltip>
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                                        width={headers[3].width}
                                                                        data-resize={`element${3}_c`}
                                                                    >
                                                                        <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                                            {currentEditableKey === variableData.oldKey ? (
                                                                                <div style={{ paddingBottom: '10px' }}>
                                                                                    <TextField
                                                                                        id="variableValueEdit"
                                                                                        aria-label="Variable Value Edit"
                                                                                        classes = {{
                                                                                            root: classes.underline, // class name, e.g. `classes-nesting-root-x`
                                                                                        }}
                                                                                        className={
                                                                                            currentEditableKey !== variableData.oldKey
                                                                                                ? classes.textFieldDisable
                                                                                                : classes.textField
                                                                                        }
                                                                                        value={variableValueEdit}
                                                                                        onChange={(e) => {
                                                                                            this.setStateIfComponentMounted({
                                                                                                variableValueEdit: e.target.value,
                                                                                            });
                                                                                        }}
                                                                                        fullWidth
                                                                                        type={
                                                                                            variableData.key.toLowerCase().includes('password')
                                                                                                ? 'password'
                                                                                                : 'text'
                                                                                        }
                                                                                        multiline={
                                                                                            !variableData.key.toLowerCase().includes('password')
                                                                                        }
                                                                                        // rows={0}
                                                                                        rowsMax={5}
                                                                                        // autoFocus
                                                                                        InputProps={{
                                                                                            disableUnderline: true,
                                                                                        }}
                                                                                        margin="none"
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <span>
                                                                                    {showMultilineText === variableData.oldKey ? (
                                                                                        <span>
                                                                                            {splittedValue.map((element, _index) => (
                                                                                                <span
                                                                                                    key={_index}
                                                                                                    className={classes.noWrap}
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        height: 25,
                                                                                                    }}
                                                                                                >
                                                                                                    <Tooltip
                                                                                                        data={` ${TestStepUtils.isShowAsterik(
                                                                                                            variableData.key,
                                                                                                            element,
                                                                                                            null,
                                                                                                        )}`}
                                                                                                    >
                                                                                                        {TestStepUtils.isShowAsterik(
                                                                                                            variableData.key,
                                                                                                            element,
                                                                                                        )}
                                                                                                    </Tooltip>
                                                                                                </span>
                                                                                            ))}
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                height: 39,
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                className={classes.noWrap}
                                                                                                style={{
                                                                                                    display: 'inline-block',
                                                                                                }}
                                                                                            >
                                                                                                <Tooltip
                                                                                                    data={` ${TestStepUtils.isShowAsterik(
                                                                                                        variableData.key,
                                                                                                        splittedValue[0],
                                                                                                        null,
                                                                                                    )}`}
                                                                                                >
                                                                                                    {TestStepUtils.isShowAsterik(
                                                                                                        variableData.key,
                                                                                                        splittedValue[0],
                                                                                                    )}
                                                                                                </Tooltip>
                                                                                            </span>{' '}
                                                                                            {splittedValue.length > 1 ? (
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: '#000000b5',
                                                                                                        marginLeft: '30px',
                                                                                                        display: 'inline-block',
                                                                                                        overflow: 'hidden',
                                                                                                    }}
                                                                                                >
                                                                                                    +{splittedValue.length - 1}
                                                                                                </span>
                                                                                            ) : (
                                                                                                ''
                                                                                            )}
                                                                                        </span>
                                                                                    )}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </TableCell>
                                                                    {/***<TableCell
                                                                        className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                                        width={headers[2].width}
                                                                        data-resize={`element${2}_c`}
                                                                        style={
                                                                            showMultilineText === variableData.oldKey ? { verticalAlign: 'top' } : {}
                                                                        }
                                                                    >
                                                                        <div style={{ paddingLeft: '10px', width: '100%' }}>
                                                                            <span style={{ display: 'inline-block' }}>
                                                                                {currentEditableKey === variableData.oldKey ? (
                                                                                    <span style={{ display: 'flex' }}>
                                                                                        <Done
                                                                                            className={`${classes.saveButtonStyle} ${
                                                                                                classes[
                                                                                                    isEnableSaveButton
                                                                                                        ? 'saveButtonEnable'
                                                                                                        : 'saveButtonDisbale'
                                                                                                ]
                                                                                            }`}
                                                                                            aria-label="doneIcon"
                                                                                            id="doneIcon"
                                                                                            onClick={() => {
                                                                                                if (isEnableSaveButton) this.onEditHandler();
                                                                                            }}
                                                                                        />
                                                                                        <Cancel
                                                                                            style={{
                                                                                                fontSize: 18,
                                                                                                cursor: 'pointer',
                                                                                                color: 'red',
                                                                                            }}
                                                                                            aria-label="cancelIcon"
                                                                                            id="cancelIcon"
                                                                                            onClick={this.handleEditCancel}
                                                                                        />
                                                                                    </span>
                                                                                ) : (
                                                                                    <div className={classes.actionButtonsDiv}>
                                                                                        <MenuOptions
                                                                                            items={this.renderItems(variableData, splittedValue)}
                                                                                            keepMounted
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </TableCell>***/}
                                                                    <TableCell
                                                                        className={[
                                                                            classes.cellWithoutPadding,
                                                                            classes.noWrap,
                                                                            classes.minWidth,
                                                                        ].join(' ')}
                                                                        width={headers[4].width}
                                                                        data-resize={`element${4}_c`}
                                                                    >
                                                                        <div style={{ marginTop: -10, paddingLeft: 15, width: '100%' }}>
                                                                            
                                                                            <span data-testid={`Clone-${index}`}>
                                                                            {
                                                                                ((isHovered === variableData.key) || isChecked) && (                                                    
                                                                                <Tooltip data="Clone">
                                                                                    <IconButton className={classes.IconButton} onClick={(e)=>this.handleCloneVariableModal(variableData)}>
                                                                                    <ContentCopy 
                                                                                        className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                                                                        aria-label="Clone"
                                                                                        id="Clone" 
                                                                                    />
                                                                                        </IconButton>
                                                                                </Tooltip>
                                                                                )
                                                                            }
                                                                            </span>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[
                                                                            classes.cellWithoutPadding,
                                                                            classes.noWrap,
                                                                            classes.minWidth,
                                                                        ].join(' ')}
                                                                        width={headers[5].width}
                                                                        data-resize={`element${5}_c`}
                                                                    >
                                                                        <div style={{ marginTop: -10, paddingLeft: 15, width: '100%' }}>
                                                                            <span data-testid={`Delete-${index}`}>
                                                                            {
                                                                                ((isHovered === variableData.key) || isChecked) && (                                                    
                                                                                <Tooltip data="Delete">
                                                                                    <IconButton className={classes.IconButton} onClick={(e)=>  { 
                                                                                    this.setStateIfComponentMounted(
                                                                                        {
                                                                                            deleteVariableData: variableData,
                                                                                        },
                                                                                        () => this.handleDeleteVariableModal(),
                                                                                    );
                                                                            } }>
                                                                                    <Delete 
                                                                                        className={[classes.moreIconsBtn, classes.shareButton].join(' ')}
                                                                                        aria-label="Delete"
                                                                                        id="Delete" 
                                                                                    />    
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                )
                                                                            }
                                                                            </span>
                                                                        </div>
                                                                    </TableCell>
                                                                    {/***<TableCell
                                                                        className={[
                                                                            classes.cellWithoutPadding,
                                                                            classes.noWrap,
                                                                            classes.minWidth,
                                                                        ].join(' ')}
                                                                        width={headers[7].width}
                                                                        data-resize={`element${7}_c`}
                                                                    >
                                                                        <div style={{ marginTop: -10, paddingLeft: 10, width: '100%' }}>
                                                                        <span data-testid={`Download-${index}`}>
                                                                            {
                                                                                ((hoverKey == variableData.key) || Boolean(selectArr.includes(variableData.key))) && (                                                    
                                                                                <Tooltip data="Download">
                                                                                    <IconButton title="Download" className={classes.IconButton} onClick={() =>{}}>
                                                                                        <img src={download} alt="Main" className={`${classes.bitmapImage} ${classes.moreIconsBtn}`} style={{ width: '16px' }} alt="" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                )
                                                                            }
                                                                        </span>
                                                                           {/*** <span data-testid={`Delete-${index}`}>
                                                                            { (hoverKey) && (
                                                                                <Tooltip data="Download">
                                                                                    <img
                                                                                        src={download}
                                                                                        alt="Main"
                                                                                        className={`${classes.bitmapImage} ${classes.moreIconsBtn} ${classes.faIcons} ${
                                                                                         hoverKey == variableData.key ? classes.btnColor : classes.disableImg
                                                                                        }`}
                                                                                    />
                                                                                <Tooltip data="Download">
                                                                                )
                                                                            }
                                                                            </span>
                                                                        </div>
                                                                    </TableCell>***/}
                                                                </TableRow>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                this.showNoVar()
                            )
                        ) : (
                            this.showNoVar()
                        )}
                    </div>
                )}
                <TableFooter
                    count={_userVars.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    applyCustomClasses
                    labelRowsPerPage="Variables per page:"
                    containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                />
            </div>
        );
    }
}

VariableTab.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

// export default withStyles(styles)(ProjectDetails);
const mapStateToProps = (state) => {
    return {
        isLoading: state.projectReducer.isLoading,
        userVariables: state.projectReducer.userVariables,
        deleteUserVariableSuccess: state.projectReducer.deleteUserVariableSuccess,
        // general reducer
        query: state.generalReducer.queryValue,
        user: state.authReducer.user,

        // Clone Variable
        tabsData: state.generalModalReducer.tabsData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserVariables: (accountId, projectId, onComplete, callback) =>
            dispatch(ProjectActions.getUserVariables(accountId, projectId, onComplete, callback)),
        saveUserVariables: (...args) => dispatch(ProjectActions.saveUserVariables(...args)),
        updateUserVariables: (...args) => dispatch(ProjectActions.updateUserVariables(...args)),
        deleteUserVariable: (...args) => dispatch(ProjectActions.deleteUserVariable(...args)),
        deleteAllUserVariable: (accountId, projectId, isEditing, onComplete) =>
            dispatch(ProjectActions.deleteAllUserVariable(accountId, projectId, isEditing, onComplete)),
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),

        getProjectDetails: (projectId) => dispatch(ProjectActions.getProjectDetails(projectId)),

        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),

        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),

        // Clone Varibale
        cloneVariable: (...args) => dispatch(FlowActions.cloneVariable(...args)),
        clearGeneralModal: (...args) => dispatch(generalModalActions.clearGeneralModal(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VariableTab));
