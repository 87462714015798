import { ActionTypes } from '../constants';

const initialState = {
    error: null,
    isError: false,
    isLoading: false,
    success: null,
    blocks: [],
    refreshFlows: false,
    cloneVarLoading: false,

    cloneAllVarLoading: false,

    notCloned: null,
};

export default function flowReducer(state = initialState, { payload, type }) {
    switch (type) {
        case ActionTypes.CLEAR_ERROR: {
            return { ...state, refreshFlows: false, error: null };
        }
        case ActionTypes.CLEAR_SUCCESS: {
            return { ...state, refreshFlows: false, success: null };
        }
        case ActionTypes.CLEAR_FLOWS: {
            return { ...state, refreshFlows: true, blocks: [] };
        }
        case ActionTypes.CREATE_FLOW_DATA: {
            return { ...state, refreshFlows: false, isLoading: true, isError: false, success: null, error: null };
        }

        case ActionTypes.DELETE_FLOW_SUCCESS:
        case ActionTypes.UPDATE_FLOW_DATA_SUCCESS:
        case ActionTypes.CLONE_FLOW_SUCCESS:
        case ActionTypes.CREATE_FLOW_DATA_SUCCESS:
            return { ...state, refreshFlows: false, isLoading: false, success: payload.message };

        case ActionTypes.DELETE_FLOW_FAILURE:
        case ActionTypes.UPDATE_FLOW_DATA_FAILURE:
        case ActionTypes.CLONE_FLOW_FAILURE:
        case ActionTypes.CREATE_FLOW_DATA_FAILURE:
            return { ...state, refreshFlows: false, isLoading: false, error: payload.message };

        case ActionTypes.CLONE_FLOW: {
            return { ...state, refreshFlows: false, isLoading: true, isError: false, success: null, error: null };
        }

        case ActionTypes.UPDATE_FLOW_DATA: {
            return { ...state, refreshFlows: false, isLoading: true, isError: false, success: null, error: null };
        }

        case ActionTypes.DELETE_FLOW: {
            return { ...state, refreshFlows: false, isLoading: true, isError: false, success: null, error: null };
        }

        case ActionTypes.CLEAR_SELECTED_CASE_DATA_PROJECT: {
            return {
                ...state,
                blocks: [],
                refreshFlows: false,
            };
        }

        case ActionTypes.GET_FLOW_DATA: {
            return { ...state, refreshFlows: false, isLoading: true };
        }

        case ActionTypes.GET_FLOW_DATA_SUCCESS: {
            return { ...state, refreshFlows: false, blocks: payload, isLoading: false };
        }

        case ActionTypes.GET_FLOW_DATA_FAILURE: {
            return { ...state, refreshFlows: false, isLoading: false };
        }

        case ActionTypes.CLONE_VARIABLE: {
            return {
                ...state,
                cloneVarLoading: true,
            };
        }

        case ActionTypes.CLEAR_CLONE_ERR: {
            return {
                ...state,
                notCloned: null,
            };
        }

        case ActionTypes.CLONE_VARIABLE_SUCCESS: {
            const { message } = payload;
            return {
                ...state,
                cloneVarLoading: false,
                success: message,
            };
        }

        case ActionTypes.CLONE_VARIABLE_FAILED: {
            const { message } = payload;
            return {
                ...state,
                cloneVarLoading: false,
                error: message,
            };
        }

        case ActionTypes.CLONE_ALL_VARIABLES: {
            return {
                ...state,
                cloneAllVarLoading: true,
            };
        }

        case ActionTypes.CLONE_ALL_VARIABLES_SUCCESS: {
            const { message, notClonedVars } = payload;
            return {
                ...state,
                cloneAllVarLoading: false,
                success: message,
                notCloned: notClonedVars,
            };
        }

        case ActionTypes.CLONE_ALL_VARIABLES_FAILED: {
            const { message } = payload;
            return {
                ...state,
                cloneAllVarLoading: false,
                error: message,
            };
        }

        default:
            return state;
    }
}
