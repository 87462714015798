// import materials
import { withStyles } from '@material-ui/core/styles';

// import packages
import { connect } from 'react-redux';
import React from 'react';
import { AuthActions } from '../../store/actions';
import HttpService from '../../services/HttpService';

const styles = () => ({
    root: {
        width: '100%',
    },
});

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const url = window.location.href.split('#')[0];
        const redirectUrl = `/v1/auth/google/callback?${url.split('oauth2callback?')[1]}&hostUrl=${
            (window.location.protocol === 'https:' ? 'https://' : 'http://') + window.location.hostname
        }`;
        const result = await HttpService.get(redirectUrl);
        if (result.status === 200 && result.data) {
            this.props.authenticateWithGmail(result);
        } else {
            // console.log(result);
        }
    }

    render() {
        const { classes } = this.props;
        return <div className={classes.root}></div>;
    }
}

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

const mapDispatchToProps = (dispatch) => {
    return {
        authenticateWithGmail: (userdata) => dispatch(AuthActions.authenticateWithGmail(userdata)),
    };
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Dashboard));
