// import materials
import { withStyles } from '@material-ui/core/styles';

// import packages
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

// import custom components
import { ProjectActions } from '../../../../store/actions';
import WSService from '../../../../services/WSService';
import TestStepsTable from './TestStepsTable';
import { TestCaseUtils, getParamValues } from '../../../../utils';
import store from '../../../../store';
import { TestStepUtils } from '../../../../utils/TestStepUtils';

const styles = () => ({
    root: {
        width: '100%',
    },
});

let paramTestCaseId = 0;
class LiveTestSteps extends React.Component {
    state = {
        getProps: false,
        returnData: false,
        // debugIntrNum: '',
    };

    UNSAFE_componentWillMount() {
        const tcId = getParamValues(2);
        paramTestCaseId = Number(tcId) ? Number(tcId) : 0;
    }

    componentDidMount() {
        this.is_mounted = true;

        const { projectId } = this.props;

        this.reviceWSEvents();

        const instrNum = window.location && window.location.hash && window.location.hash.split('#').pop();
        if (instrNum) {
            TestStepUtils.scrollToSpecificStep(`${projectId}_${paramTestCaseId}_${instrNum}`, true);
        }

        window.addEventListener('beforeunload', this.onUnload);

        localStorage.removeItem('closedEvent');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { msgType8Received, removeMsgType8Received, projectId } = nextProps;

        const tcId = getParamValues(2);
        paramTestCaseId = Number(tcId) ? Number(tcId) : 0;

        if (msgType8Received[paramTestCaseId]) {
            // need to remove (Tahir)
            removeMsgType8Received(paramTestCaseId);
        }

        if (this.props.callRetryFunc !== nextProps.callRetryFunc && !this.state.getProps) {
            this.props.callRetry(false);
            setTimeout(() => {
                TestCaseUtils.runTestSteps({ paramTestCaseId, projectId });
            }, 1000);
            this.setStateIfComponentMounted({ getProps: true });
        }
    }

    componentWillUnmount() {
        this.is_mounted = false;
        window.removeEventListener('beforeunload', this.onUnload);
        delete window.testingFunction_getSingleTestCase;
        delete window.testingFunction_getTestSteps;
    }

    onUnload = (event) => {
        if (this.props.disableReload) {
            const confirmationMessage = '';
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        }
        return '';
    };

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (this.is_mounted) this.setState(obj, callback);
    };

    stepsInterval;

    is_mounted = false;

    ws = {};

    /* stepsListener = () => {
        let testCaseId = this.props.selectedTestCase.testCaseId;
        this.stepsInterval = setInterval(async () => {
            let getDebugJSON = await localStorage.getItem(
                `${'DebugCase_' + testCaseId}`,
            );
            let closeEvent = await localStorage.getItem('closedEvent');
            let getDebugingPro = getDebugJSON && JSON.parse(getDebugJSON);
            if (getDebugingPro) {
                console.log(getDebugingPro, 'getDebugingPro');
                let filterSteps = getDebugingPro.testSteps.filter((step) => !!step.hasOwnProperty('isDebuged'));
                let lastAddedStep = filterSteps[filterSteps.length - 1];
                let alreadySteps = this.state.sortedSteps.filter((stp) => stp.instrNum !== this.state.debugIntrNum);
                lastAddedStep['instrNum'] = this.state.debugIntrNum;
                let sortedDebugSteps = [...alreadySteps, lastAddedStep].sort((a, b) => parseFloat(a['instrNum']) - (parseFloat(b['instrNum'])));
                let filteredXpathSteps = sortedDebugSteps.map((stp, ind) => {
                    if (Number(stp.instrNum) > Number(this.state.debugIntrNum)) {
                        stp.xpath = '';
                    }
                    return stp;
                });
                this.setStateIfComponentMounted({
                    returnData: true,
                    sortedSteps: filteredXpathSteps,
                }, () => {
                });
                this.stopUpdate();
                // setTimeout(() => {
                //     TestCaseUtils.runTestSteps({ paramTestCaseId: testCaseId, projectId })
                // }, 2000);
            }
            if (closeEvent && !getDebugJSON) {
                this.setStateIfComponentMounted({
                    returnData: true,
                }, () => {
                    localStorage.removeItem('closedEvent');
                    clearInterval(this.stepsInterval);
                });
            }
        }, 1000);
    }; */

    // stopUpdate() {
    //     clearInterval(this.stepsInterval);
    //     let testCaseId = this.props.selectedTestCase.testCaseId;
    //     localStorage.removeItem(`${'DebugCase_' + testCaseId}`);
    //     localStorage.removeItem('closedEvent');
    // }

    /* Live Debugin start */
    callForLiveDebug = async (fIndex, callBack, htmlPath) => {
        const { selectedTestCase, user } = this.props;
        const RecordData = { ...selectedTestCase };
        const { discoveryId } = RecordData;
        const projectId = discoveryId;
        TestCaseUtils.debugStep(user.accountId, projectId, discoveryId, RecordData, fIndex, htmlPath, callBack, () => {});
        /* this.props.startTestUpdater(true);
        this.setStateIfComponentMounted({ debugIntrNum: fIndex }); */
    };
    /* Live Debugin end */

    /* Retry Test Case Start */

    reviceWSEvents = async () => {
        const { autoScroll } = store.getState().selectedTestCaseReducer;

        const actions = {};

        const data = { autoScroll, callingFrom: 'LiveTestStep' };
        this.ws = await WSService.getWebSocketInstance();
        // Invoking web socket receiver
        setTimeout(() => {
            TestCaseUtils.receiveWSEvents(this.ws, actions, data);
        }, 500);
    };
    /* Retry Test Case End */

    render() {
        const { classes, showEmptyMessageOrLoader, selectedTestCase, changeTab, projectId } = this.props;
        const { returnData } = this.state;

        const actionFunctions = {
            callForLiveDebug: this.callForLiveDebug,
        };

        let content;
        if (selectedTestCase) {
            content = (
                <TestStepsTable
                    actionFunctions={actionFunctions}
                    testCaseId={paramTestCaseId}
                    changeTab={changeTab}
                    projectId={projectId}
                    returnData={returnData}
                    showEmptyMessageOrLoader={showEmptyMessageOrLoader}
                />
            );
        } else {
            content = showEmptyMessageOrLoader();
        }
        return <div className={classes.root}>{content}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        // project reducer
        callRetryFunc: state.projectReducer.callRetryFunc,
        disableReload: state.projectReducer.disableReload,
        msgType8Received: state.projectReducer.msgType8Received,
        // selectedTestCaseReducer
        selectedTestCase: state.projectReducer.selectedTestCase,

        // auth Reducer
        user: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        callRetry: (bool) => dispatch(ProjectActions.callRetry(bool)),
        removeMsgType8Received: (...args) => dispatch(ProjectActions.removeMsgType8Received(...args)),
        // General
    };
};

LiveTestSteps.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LiveTestSteps));
