import { ActionTypes } from '../constants';

const initialState = {
    selectedExecution: [],
    searchedInput: [],
    selectedExePreviousSortedType: 'sortAsc',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SELECTED_EXECUTION:
            return {
                ...state,
                selectedExecution: [...action.payload.event],
                searchedInput: [...action.payload.newArr],
            };
        case ActionTypes.SORT_SELECTED_EXECUTION:
            return {
                ...state,
                selectedExecution: [...action.payload.tasks],
                selectedExePreviousSortedType: action.payload.sortOrder,
            };
        default:
            return state;
    }
};
