import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProjectDropDownData } from '../../../utils/utils';
import EditTestCaseDialogContent from './EditTestCaseDialogContent';
import { generalModalActions } from '../../../store/actions';

let isMounted = false;

class CloneSuite extends Component {
    state = {
        testSuiteName: '',
        selectedProjectId: 'default',
        selectedProjectName: 'default',
    };

    componentDidMount() {
        isMounted = true;
        this.setStateIfComponentMounted(
            {
                testSuiteName: this.props.cloneSuite.testSuiteName,
                selectedProjectId: `${this.props.cloneSuite.projectId}`,
                selectedProjectName: this.props.cloneSuite.projectName,
            },
            () => {},
        );
        this.updatedTabsData();
    }

    componentWillUnmount() {
        isMounted = false;
        this.updatedTabsData();
    }

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted)
            this.setState({ ...obj }, () => {
                callback();
                this.updatedTabsData();
            });
    };

    updatedTabsData = () => {
        this.props.updateTabData('cloneSuite', { state: { ...this.state } });
    };

    handleChange = (e) => {
        this.setStateIfComponentMounted({ testSuiteName: e.target.value });
    };

    selectProjectHandler = (selectedProject) => {
        this.setStateIfComponentMounted({
            selectedProjectId: selectedProject.value,
            selectedProjectName: selectedProject.label,
        });
    };

    render() {
        const { projectsByIdAndName } = this.props;
        const { testSuiteName, selectedProjectId } = this.state;
        const projectDropDownData = getProjectDropDownData(projectsByIdAndName);
        return (
            <EditTestCaseDialogContent
                titleName="Suite"
                ariaLabel="SuiteName"
                onChange={this.handleChange}
                placeholder="Test Suite Name"
                value={testSuiteName}
                searchValue={selectedProjectId}
                searchOnChange={this.selectProjectHandler}
                options={projectDropDownData}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // auth reducer
        user: state.authReducer.user,
        // general reducer
        query: state.generalReducer.queryValue,
        // project reducer
        projectsByIdAndName: state.projectReducer.projectsByIdAndName,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // General Action
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CloneSuite);
