// import material
import { withStyles } from '@material-ui/core/styles';
// import packages
import React from 'react';
import { connect } from 'react-redux';

// import icons
import DebugOn from 'mdi-material-ui/CheckboxBlankCircle';
import DebugOff from 'mdi-material-ui/CheckboxBlankCircleOutline';

// import custom component
import Tooltip from '../../../../components/Tooltip';
import { checkArrayLength, checkKeyInObject } from '../../../../utils';
import { TestStepUtils } from '../../../../utils/TestStepUtils';

const styles = (theme) => ({
    debugContainer: {
        visibility: 'hidden',
    },
    debugIconStyle: {
        cursor: 'pointer',
        fontSize: 16,
        marginRight: 10,
        marginTop: 2,
        [theme.breakpoints.only('xs')]: {
            marginRight: 0,
        },
    },
    debugIconStyleOn: {
        color: '#ff4141',
    },
    debugIconStyleOff: {
        color: '#d86d6d',
    },
});

const DebugPointSelector = (props) => {
    const { classes, creationMode, isWsRunning, selectedSteps, testStep, testCaseId, currentWsRunningCaseData } = props;

    const sessionId = checkKeyInObject(currentWsRunningCaseData, 'sessionId');
    const testStepObj = checkKeyInObject(currentWsRunningCaseData, 'testStepObj');
    const showDebugPoint =
        !(
            isWsRunning[testCaseId] ||
            checkArrayLength(selectedSteps) ||
            (checkKeyInObject(currentWsRunningCaseData, 'isMsgType2Sent') && !checkKeyInObject(currentWsRunningCaseData, 'isMsgType16Received'))
        ) &&
        ((sessionId && testStepObj && sessionId === testStepObj.sessionId && ['13', '16'].includes(`${testStepObj.msgType}`)) || // if run from tab 1 & get msgType 13 | 16 in tab 2 don't show tray
            (!sessionId && !testStepObj)); // if no session id then should not have testStepObj i.e not running
    const val = `${testStep.instrNum}`.split('.').length - 1;

    const debugPoint =
        testStep.debug && !creationMode ? (
            <Tooltip data="Debug point">
                <DebugOn
                    onClick={() => {
                        if (showDebugPoint) {
                            TestStepUtils.handleDebug(testCaseId, testStep.instrNum);
                        }
                    }}
                    className={`${classes.debugIconStyle} ${classes.debugIconStyleOn}`}
                />
            </Tooltip>
        ) : (
            <span className={classes.debugContainer} style={!showDebugPoint || creationMode ? { visibility: 'hidden' } : {}}>
                <Tooltip data="Debug point">
                    <DebugOff
                        onClick={() => TestStepUtils.handleDebug(testCaseId, testStep.instrNum)}
                        className={`${classes.debugIconStyle} ${classes.debugIconStyleOff}`}
                    />
                </Tooltip>
            </span>
        );

    return <span style={{ paddingLeft: val * 47 }}>{debugPoint}</span>;
};

function mapStateToProps(state, props) {
    const { wsRunningCaseData } = state.projectReducer;
    const currentWsRunningCaseData = checkKeyInObject(wsRunningCaseData, props.testCaseId, 'value', {});
    return {
        // Project reducer
        isWsRunning: state.projectReducer.isWsRunning,
        currentWsRunningCaseData,
        // Selected Test Case Reducer
        selectedSteps: state.selectedTestCaseReducer.selectedSteps,
        creationMode: state.selectedTestCaseReducer.creationMode,
    };
}

export default connect(mapStateToProps, null)(withStyles(styles)(DebugPointSelector));
