import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import GeneralModalFooter from '../../modal/GeneralModal/GeneralModalFooter';
import {
    MODAL_SUBMIT_BUTTON,
    MODAL_CANCEL_BUTTON,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_HEADER_CLOSE_ICON,
    WIDTH_100_PER,
} from '../../../common/cssConstants';
import { checkKeyInObject, convertToSelectValues, checkArrayLength } from '../../../utils';
import Select from '../../modal/GeneralModal/Select';

const styles = () => ({
    multiSelectAlertRoot: {
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        left: '50%',
        transform: 'translate(-50%, 0px)',
        width: 400,
        height: 180,
        zIndex: 1,
        borderRadius: '15px 15px 15px 15px',
        boxShadow: '0 0 80px 0 rgba(0,0,0,0.8)',
    },
    innerText: {
        margin: 0,
        fontSize: 12,
        color: '#4A4A4A',
        marginLeft: 30,
        marginTop: 20,
    },
    dontShowCheckbox: {
        color: '#F39B31 !important',
        height: 20,
        width: 20,
        marginRight: 8,
    },
    dontShowMessage: {
        color: '#4A4A4A',
        fontSize: 12,
    },
    dontShowWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 30,
    },
    btnWrapper: {
        marginLeft: 30,
        marginTop: 10,
    },
    overlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: 1,
        height: '100%',
        width: '100%',
    },
    faLightBulb: {
        color: '#F39B31',
        height: 20,
        width: 12,
        marginRight: 10,
    },
    dialogTitleStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '30px 45px 30px 30px',
        '& > h2': {
            fontSize: 18,
            display: 'flex',
            color: '#4A4A4A',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        },
    },
    closeIconWrapper: MODAL_HEADER_CLOSE_ICON_CONT,
    closeIcon: MODAL_HEADER_CLOSE_ICON,
    top: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    content: {
        height: 28,
    },
    contentSelect: {
        height: 44,
        width: 350,
        margin: '10px 0px 35px 30px',
    },
    bold: {
        fontWeight: 500,
    },
    select: {
        backgroundColor: 'white',
        borderRadius: 3,
        ...WIDTH_100_PER,
        // '&>div': {
        //     borderBottom: 'none !important',
        //     transition: 'none !important',
        // }
    },
    groupLabel: {
        color: '#595959',
        fontSize: 12,
    },
    topAlign:{
        top:'-80px'
    },
    topAlignDeleteModal:{
        tops:'21px',
    }
});

const AlertPopUp = (props) => {
    const [selectedGroup, setSelectedGroup] = useState({ id: 0, value: '', label: '' });
    const [groupsOptions, setGroupsOptions] = useState([{ id: 0, value: '', label: '' }]);

    useEffect(() => {
        const { userGroups } = props;
        if (checkKeyInObject(userGroups, 'Groups', 'bool', false) && checkArrayLength(userGroups.Groups)) {
            setSelectedGroup({ id: userGroups.Groups[0].id, value: userGroups.Groups[0].name, label: userGroups.Groups[0].name });
            const _groupsOptions = [];
            userGroups.Groups.forEach((group) => {
                _groupsOptions.push({ id: group.id, value: group.name, label: group.name });
            });
            setGroupsOptions([..._groupsOptions]);
        }
    }, [props]);

    const { title, handleClose, handleSubmit, icon, submitButtonText, classes, user, modalFor, stylesRoot, selectedUsers } = props;
    return (
        <div>
            <div className={classes.overlay}></div>
            <div className={`${classes.multiSelectAlertRoot} ${modalFor === 'deleteUser' ? classes.topAlignDeleteModal : classes.topAlign}`} style={{...stylesRoot }}>
                <div className={classes.top}>
                    <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }} style={{ padding: '30px 45px 5px 30px' }}>
                        {icon}
                        {title}
                    </DialogTitle>
                    <div aria-hidden className={classes.closeIconWrapper} onClick={handleClose}>
                        <Close className={classes.closeIcon} aria-label="closeIcon" id="closeIcon" />
                    </div>
                </div>
                {modalFor === 'resetPassword' ? (
                    <div className={classes.content}>
                        <p className={classes.innerText}>
                            Do you want to send the reset link to{' '}
                            <span className={classes.bold}>{checkKeyInObject(user, 'email', 'value', 'email')}</span>?
                        </p>
                    </div>
                ) : modalFor === 'associate' ? (
                    <div className={classes.contentSelect}>
                        <span className={classes.groupLabel}>Group Name</span>
                        <Select
                            value={selectedGroup.value}
                            onChange={(data) => {
                                setSelectedGroup(data);
                            }}
                            className={classes.select}
                            isBorder
                            options={convertToSelectValues(groupsOptions)}
                            isMultiSelect={false}
                            readOnly
                            key={Math.random()}
                        />
                    </div>
                ) : modalFor === 'deleteUser' ? (
                    <div className={classes.content}>
                        <p className={classes.innerText}>{`Are you sure you want to delete selected ${
                            selectedUsers.length > 1 ? 'users?' : 'user?'
                        }`}</p>
                    </div>
                ) : null}

                <div className={classes.btnWrapper}>
                    <GeneralModalFooter
                        buttons={[
                            {
                                name: submitButtonText,
                                variant: 'contained',
                                style: { ...MODAL_SUBMIT_BUTTON },
                                action: () => {
                                    if (modalFor === 'resetPassword') {
                                        handleSubmit(user);
                                    } else if (modalFor === 'associate') {
                                        handleSubmit(selectedGroup.id);
                                    } else {
                                        handleSubmit();
                                    }
                                    handleClose();
                                },
                            },
                            {
                                name: 'Cancel',
                                variant: 'outlined',
                                style: { ...MODAL_CANCEL_BUTTON },
                                action: () => {
                                    handleClose();
                                },
                                isDisabled: false,
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};
AlertPopUp.defaultProps = {
    top: 0,
    stylesRoot: {},
};

const mapStateToProps = (state) => {
    return {
        userGroups: state.userReducer.userGroups,
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(AlertPopUp));
