import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { TextField, MenuItem, Paper, Popper } from '@material-ui/core';
import deburr from 'lodash/deburr';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Autosuggest from 'react-autosuggest';
import { BLACK_FONT } from '../../common/cssConstants';
import { checkArrayLength, checkKeyInObject, getValidUrl, checkObject } from '../../utils';
import AutoSuggestUtils from '../../utils/AutoSuggestUtils';
import { generalModalActions } from '../../store/actions';
import { autoSuggestCompStyles } from '../../features/projectDetails/TestSteps/LiveSteps/AutoSuggestComponentStyles';

const isEnterPressed = false;

let Generalsuggestions = [];
let Concat_Generalsuggestions = Generalsuggestions;
let autosuggestGeneralProps = {};
let _tabName = '';

function renderInputComponent(inputProps) {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;

    return (
        <TextField
            fullWidth
            multiline
            rowsMax="8"
            InputProps={{
                inputRef: (node) => {
                    ref(node);
                    inputRef(node);
                },
                classes: {
                    input: classes.input,
                },
            }}
            {...other}
        />
    );
}

function renderGeneralSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);
    let _query = suggestion.label;
    const element = [];
    let index = 0;

    if (_tabName === 'testInstruction') {
        while (_query !== '') {
            const re = /^(?:([^{}[\]()]*)(?:([{]+[^{}]+[}]+)|([[]+[^[\]]+[\]]+)|([(]+[^()]+[)]+))*)/gi.exec(_query);
            if (re[0]) {
                _query = _query.replace(re[0], '');
            }
            if (re[1]) {
                element.push(
                    <span className="inconsolataFontFamilyImportant" key={`${index}_1`} style={{ color: '#1168CD', backgroundColor: '#F39B314d' }}>
                        {re[1]}
                    </span>,
                );
            }
            if (re[2]) {
                element.push(
                    <span className="inconsolataFontFamilyImportant" style={{ color: BLACK_FONT }} key={`${index}_2`}>
                        {re[2]}
                    </span>,
                );
            } else if (re[3]) {
                element.push(
                    <span className="inconsolataFontFamilyImportant" style={{ color: BLACK_FONT }} key={`${index}_2`}>
                        {re[3]}
                    </span>,
                );
            } else if (re[4]) {
                element.push(
                    <span className="inconsolataFontFamilyImportant" style={{ color: BLACK_FONT }} key={`${index}_2`}>
                        {re[4]}
                    </span>,
                );
            }
            index++;
            if (index > 5) {
                break;
            }
        }
        return (
            <MenuItem selected={isHighlighted} component="div">
                <div>
                    <span style={{ fontSize: 12 }}>{element}</span>
                </div>
            </MenuItem>
        );
    }
    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, i) => {
                    return part.highlight ? (
                        <span key={String(i)} className="inconsolataFontFamilyImportant" style={{ fontSize: 12, fontWeight: 600 }}>
                            {part.text}
                        </span>
                    ) : (
                        <span key={String(i)} className="inconsolataFontFamilyImportant" style={{ fontSize: 12 }}>
                            {part.text}
                        </span>
                    );
                })}
            </div>
        </MenuItem>
    );
}

function getGeneralSuggestions(value) {
    const inputValue = value && deburr(value.trim()).toLowerCase();
    const inputLength = inputValue && inputValue.length;
    let count = 0;

    if (_tabName === 'testInstruction') {
        return inputLength === 0 || isEnterPressed
            ? []
            : checkArrayLength(Concat_Generalsuggestions)
            ? Concat_Generalsuggestions.filter((suggestion) => {
                  const keep = count < 25 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

                  if (keep) {
                      count += 1;
                  }
                  return keep;
              })
            : [];
    }
    if (_tabName === 'testData' || _tabName === 'expectedResults') {
        return inputLength === 0 || isEnterPressed
            ? []
            : checkArrayLength(Generalsuggestions)
            ? Generalsuggestions.filter((suggestion) => {
                  const keep = count < 25 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

                  if (keep) {
                      count += 1;
                  }
                  return keep;
              })
            : [];
    }
    return false;
}

function getSuggestionValue(suggestion) {
    return suggestion && suggestion.label;
}

class AutoSuggestGeneralComponent extends Component {
    state = {
        generalSuggestions: [],
        testData: this.props.testData,
        testInstruction: this.props.testInstruction,
        expectedResults: this.props.expectedResults || '',
    };

    componentDidMount() {
        const { tabName } = this.props;
        Generalsuggestions = AutoSuggestUtils[`${tabName}Suggestions`];
        Concat_Generalsuggestions = Generalsuggestions;
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        if (this.props.tabName !== nextprops.tabName) {
            Generalsuggestions = AutoSuggestUtils[`${nextprops.tabName}Suggestions`];
            Concat_Generalsuggestions = Generalsuggestions;
            if (this.popperNode) {
                this.popperNode.focus(); // to automatically focus the textarea after changing the tab
            }
        }
    }

    onChange = (e, { newValue }) => {
        if (e.keyCode !== 27) {
            const { handleChange, tabName } = this.props;
            handleChange(newValue);
            this.handleSearch(tabName);
        }
    };

    getSuggestions_Instr_forFlow = (value, flows) => {
        const inputValue = value && deburr(value.trim()).toLowerCase();
        const inputLength = inputValue && inputValue.length;
        const suggestions = [];
        if (!(inputLength === 0 || isEnterPressed) && checkArrayLength(flows)) {
            flows.forEach((flow) => {
                const keep = checkKeyInObject(flow, 'name', 'bool') && flow.name.slice(0, inputLength).toLowerCase() === inputValue;
                if (keep) {
                    suggestions.push({ label: `Run \${${flow.name}}` });
                }
            });
        }
        return suggestions;
    };

    handleSuggestionsFetchRequested = ({ value }) => {
        let isChanged = false;
        if (this.props.tabName === 'testInstruction') {
            if (Array.isArray(value.toLowerCase().match(/^run [/$]{(.+)}.+$/))) {
                isChanged = true;
                const flowName = value.toLowerCase().match(/^run [/$]{(.+)}.+$/)[1];
                this.replaceRunFlowInstr(flowName);
            } else if (value && value.trim().toLowerCase().indexOf('run ${') === 0) {
                const { allFlows } = this.props;
                let flowName = '';
                if (
                    Array.isArray(
                        value
                            .trim()
                            .toLowerCase()
                            .match(/^run [/$]{(.*)}$/),
                    )
                ) {
                    flowName = value
                        .trim()
                        .toLowerCase()
                        .match(/^run [/$]{(.*)}$/)[1];
                } else {
                    flowName = value.trim().toLowerCase().replace('run ${', '');
                }
                this.setState({
                    generalSuggestions: this.getSuggestions_Instr_forFlow(flowName, allFlows),
                });
                return false;
            }
            if (!isChanged) {
                this.replaceRunFlowInstr('flow name');
            }
            this.setState({
                generalSuggestions: getGeneralSuggestions(value),
            });
        } else {
            this.setState({
                generalSuggestions: getGeneralSuggestions(value),
            });
        }
        return null;
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            generalSuggestions: [],
        });
    };

    handleSearch = (field) => async (event, val) => {
        const newValue = val ? val.newValue : event.target.value;

        // Save instruction and data with ref to use in code editor
        this.tempTestStepToAdd = { ...this.tempTestStepToAdd, [field]: newValue };

        // TODO: md5sum always have string value but null.
        const { md5sum } = this.props;

        this.setState({ [field]: newValue });

        // separate value into two part (Prefix, Suffix)
        const { value } = event.target;

        let prefix = '';
        let suffix = '';

        if (value !== undefined) {
            const trimmed_value = value.trim();

            if (this.hasWhiteSpace(trimmed_value)) {
                const words = trimmed_value.split(' ');

                prefix = words[0];

                if (words && words.length > 0) {
                    for (let i = 1; i < words.length; i++) {
                        suffix = `${suffix} ${words[i]}`;
                    }
                }

                // Get rid of the whitespace at both side.
                suffix = suffix ? suffix.trim() : '';
            } else {
                prefix = trimmed_value;
            }
        }

        if (prefix.toLowerCase().startsWith('s')) {
            prefix = 'select';
        } else if (prefix.toLowerCase().startsWith('e')) {
            prefix = 'entry';
        } else if (prefix.toLowerCase().startsWith('c')) {
            prefix = 'click';
        } else {
            prefix = 'entry'; // It should be All elements
        }
        if (
            (prefix.toLowerCase() === 'select' || prefix.toLowerCase() === 'entry' || prefix.toLowerCase() === 'click') &&
            suffix &&
            suffix.length > 0 &&
            md5sum !== ''
        ) {
            const url = `/page${prefix}/search/${md5sum}/${suffix}`;

            // TODO: Update NLPsuggestions Array by what we've got from getActionableElements

            const Generalsuggestions_appendix = [];
            // above array is the place where all the suggestion should be in (syntax support + founded element)
            let elementsArray = await this.props.getActionableElements(url);
            elementsArray = elementsArray && Object.keys(elementsArray) && Object.keys(elementsArray).length > 0 && elementsArray.payload;

            // initialize & re-assign the autoSuggestion array.
            if (elementsArray && elementsArray.length > 0) {
                for (let j = 0; j < elementsArray.length; j++) {
                    let cmd_prefix = '';

                    switch (prefix.toLowerCase()) {
                        case 'entry':
                            cmd_prefix = 'Enter ';
                            break;

                        case 'select':
                            cmd_prefix = 'Select ';
                            break;

                        case 'click':
                            cmd_prefix = 'Click ';
                            break;

                        default:
                            break;
                    }

                    Generalsuggestions_appendix.push({
                        label: cmd_prefix + elementsArray[j],
                    });

                    if (Generalsuggestions && Generalsuggestions.length) {
                        for (let i = 0; i < Generalsuggestions.length; i++) {
                            Generalsuggestions_appendix.push(Generalsuggestions[i]);
                        }
                    }
                }

                Concat_Generalsuggestions = Generalsuggestions_appendix;
            } else {
                Concat_Generalsuggestions = Generalsuggestions;
            }
        }
    };

    handelSave = () => {
        const { testInstruction, testData, expectedResults } = this.state;
        const { handleEdit, handleAdd, actionType, index } = this.props;
        if (testInstruction.trim()) {
            let validTestData = testData;
            if (testData && testInstruction && testInstruction.trim().toLowerCase() === 'open website') {
                validTestData = getValidUrl(testData);
            }
            if (actionType === 'edit') {
                handleEdit(testInstruction, validTestData, expectedResults, index);
            } else {
                handleAdd(testInstruction, validTestData, expectedResults, index);
            }
        }
    };

    replaceRunFlowInstr = (flowName) => {
        if (Generalsuggestions) {
            Generalsuggestions.forEach((val, ind) => {
                if (val.label && val.label.toLowerCase().indexOf('run ${') === 0) {
                    if (val.label.toLowerCase().indexOf('for [number] times') !== -1) {
                        Generalsuggestions[ind] = { label: `Run \${${flowName}} for [number] times` };
                    } else if (val.label.toLowerCase().indexOf('for all rows') !== -1) {
                        Generalsuggestions[ind] = { label: `Run \${${flowName}} for all rows` };
                    }
                }
            });
        }
    };

    hasWhiteSpace = (stnce) => {
        return stnce.indexOf(' ') >= 0;
    };

    // Save current step's data and instruction to copy on next step
    handleCopy = () => {
        const { testStep } = this.props;
        if (checkObject(testStep.copyData)) {
            this.setState({
                testInstruction: checkKeyInObject(testStep, 'copyData.testInstruction', 'value', ''),
                testData: checkKeyInObject(testStep, 'copyData.testData', 'value', ''),
                expectedResults: checkKeyInObject(testStep, 'copyData.expectedResults', 'value', ''),
            });
        }
    };

    render() {
        const { classes, value, marketYamlData, userVariables, updateTabData, tabName, placeholder } = this.props;
        const { testData, testInstruction, generalSuggestions } = this.state;
        _tabName = tabName;

        let suggestions = [];
        suggestions = generalSuggestions;

        if (tabName === 'testInstruction') {
            if (!isEnterPressed && testInstruction && testInstruction.toLowerCase().indexOf('market:') === 0) {
                suggestions = [];
                marketYamlData.forEach((key) => {
                    suggestions.push({
                        label: `Market:${key}`,
                    });
                });
            }
        }
        if (tabName === 'testData') {
            suggestions = [...generalSuggestions];
            if (!isEnterPressed && testData && checkArrayLength(userVariables)) {
                userVariables.forEach((userVariable) => {
                    const _label = `\${${userVariable.key}}`;
                    if (_label.toLowerCase().indexOf(testData) === 0) {
                        suggestions.push({
                            label: _label,
                        });
                    }
                });
            }
        }
        autosuggestGeneralProps = {
            renderInputComponent,
            suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue,
            renderSuggestion: renderGeneralSuggestion,
        };

        return (
            <div>
                <Autosuggest
                    {...autosuggestGeneralProps}
                    inputProps={{
                        classes,
                        placeholder,
                        autoFocus: true,
                        value,
                        onKeyDown: (e) => {
                            if (e.keyCode === 27) {
                                e.stopPropagation();
                                e.preventDefault();
                                updateTabData(tabName, { state: { [tabName]: value } });
                            }
                        },
                        onChange: this.onChange,
                        onBlur: () => updateTabData(tabName, { state: { [tabName]: value } }),
                        inputRef: (node) => {
                            this.popperNode = node;
                        },
                    }}
                    theme={{
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                        input: classes.placeholderGeneral,
                    }}
                    renderSuggestionsContainer={(options) => (
                        <Popper className={classes.zIndex} anchorEl={this.popperNode} open={Boolean(options.children)}>
                            <Paper {...options.containerProps} square className={classes.paperGeneral}>
                                {options.children}
                            </Paper>
                        </Popper>
                    )}
                    className={classes.width42}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(null, mapDispatchToProps)(withStyles(autoSuggestCompStyles)(AutoSuggestGeneralComponent));
