import { ActionTypes } from '../constants';

const initialState = {
    error: null,
    projectId: null,
    testCaseId: null,
    success: null,
};
export default function taskReducer(state = initialState, action) {
    switch (action && action.type) {
        case ActionTypes.CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };
        case ActionTypes.CLEAR_SUCCESS:
            return {
                ...state,
                success: null,
            };
        case ActionTypes.SET_NEW_PROJECT_ID:
            return {
                ...state,
                projectId: action.payload,
            };
        case ActionTypes.SET_TESTCASE_ID:
            return {
                ...state,
                testCaseId: action.payload,
            };
        default:
            return state;
    }
}
