import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { styles } from './styles';

const tabIndicatorProps = {
    style: {
        backgroundColor: '#F0B637',
        top: 0,
        height: 3,
        display: 'none',
    },
};
const HeaderNav = ({ classes, isPreference, selectedTab, handleChange }) => (
    <div className={classes.headerNavRoot}>
        <Grid item xs={10} sm={10} md={isPreference ? 8 : 11} lg={isPreference ? 8 : 11}>
            <Tabs value={selectedTab} onChange={handleChange} TabIndicatorProps={tabIndicatorProps} classes={{ root: classes.tabRoot }}>
                {!isPreference && (
                    <Tab
                        label="Profile"
                        title="Profile Tab"
                        classes={{
                            root: classes.root,
                            selected: classes.tabSelected,
                            wrapper: classes.wrapper,
                        }}
                        style={{
                            minWidth: 'calc(100% / 6)',
                            width: 'calc(100% / 6)',
                        }}
                    />
                )}
                {/* {!isPreference && (
                    <Tab
                        label="Execution Environment"
                        title="Execution Environment"
                        classes={{
                            root: classes.root,
                            selected: classes.tabSelected,
                            wrapper: classes.wrapper,
                        }}
                        style={{
                            minWidth: 'calc(100% / 6)',
                            width: 'calc(100% / 5)',
                        }}
                    />
                )} */}
                {!isPreference && (
                    <Tab
                        label="Security"
                        title="Security Tab"
                        classes={{
                            root: classes.root,
                            selected: classes.tabSelected,
                            wrapper: classes.wrapper,
                        }}
                        style={{
                            minWidth: 'calc(100% / 6)',
                            width: 'calc(100% / 6)',
                        }}
                    />
                )}
                {!isPreference && (
                    <Tab
                        label="Sauce Labs Credentials"
                        title="Sauce Labs Credentials"
                        classes={{
                            root: classes.root,
                            selected: classes.tabSelected,
                            wrapper: classes.wrapper,
                        }}
                        style={{
                            minWidth: 'calc(100% / 6)',
                            width: 'calc(100% / 6)',
                        }}
                    />
                )}
                {!isPreference && (
                    <Tab
                        label="Settings"
                        title="Settings Tab"
                        classes={{
                            root: classes.root,
                            selected: classes.tabSelected,
                            wrapper: classes.wrapper,
                        }}
                        style={{
                            minWidth: 'calc(100% / 6)',
                            width: 'calc(100% / 6)',
                        }}
                    />
                )}
                {!isPreference && (
                    <Tab
                        label="Legal and Compliance"
                        title="Variable Tab"
                        classes={{
                            root: classes.root,
                            selected: classes.tabSelected,
                            wrapper: classes.wrapper,
                        }}
                        style={{
                            minWidth: 'calc(100% / 6)',
                            width: 'calc(100% / 6)',
                        }}
                    />
                )}

                {isPreference && (
                    <Tab
                        label="General"
                        title="General"
                        classes={{
                            root: classes.root,
                            selected: classes.tabSelected,
                            wrapper: classes.wrapperCenter,
                        }}
                        style={{
                            minWidth: 'calc(100% / 6)',
                            width: 'calc(100% / 4)',
                        }}
                    />
                )}


                {isPreference && (
                    <Tab
                        label="Execution Environment"
                        title="Execution Environment"
                        classes={{
                            root: classes.root,
                            selected: classes.tabSelected,
                            wrapper: classes.wrapperCenter,
                        }}
                        style={{
                            minWidth: 'calc(100% / 6)',
                            width: 'calc(100% / 4)',
                        }}
                    />
                )}
                
                {/* {isPreference &&
                    <Tab
                        label="Upload Marketplace"
                        title="Upload Marketplace"
                        classes={{
                            root: classes.root,
                            selected: classes.tabSelected,
                            wrapper: classes.wrapper,
                        }}
                        style={{
                            minWidth: 'calc(100% / 6)',
                            width: 'calc(100% / 4)',
                        }}
                    />} */}
                {isPreference && (
                    <Tab
                        label="SAML"
                        title="SAML"
                        classes={{
                            root: classes.root,
                            selected: classes.tabSelected,
                            wrapper: classes.wrapperCenter,
                        }}
                        style={{
                            minWidth: 'calc(100% / 8)',
                            width: 'calc(100% / 4)',
                        }}
                    />
                )}
                {isPreference && (
                    <Tab
                        label="Settings"
                        title="Account Setting"
                        classes={{
                            root: classes.root,
                            selected: classes.tabSelected,
                            wrapper: classes.wrapperCenter,
                        }}
                        style={{
                            minWidth: 'calc(100% / 6)',
                            width: 'calc(100% / 4)',
                        }}
                    />
                )}                
            </Tabs>
        </Grid>
    </div>
);

HeaderNav.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    selectedTab: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    isPreference: PropTypes.bool,
};

HeaderNav.defaultProps = {
    isPreference: false,
};

export default withStyles(styles)(HeaderNav);
