import { ActionTypes } from '../constants';
import { ExecutionMiddleware } from '../middleware';
import { arrangeStepsWithCompoundSteps, checkArrayLength, checkKeyInObject, isJson } from '../../utils';

class ExecutionActions {
    static executeTag(obj, onCompelete = () => {}) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.EXECUTE_TAG });
            try {
                const { status } = await ExecutionMiddleware.executeTag(obj, onCompelete);
                if (status) {
                    dispatch({
                        type: ActionTypes.EXECUTE_TAG_SUCCESS,
                    });
                    return true;
                }
                throw new Error('');
            } catch (error) {
                dispatch({
                    type: ActionTypes.EXECUTE_TAG_FAILURE,
                    payload: {
                        message: 'unable to execute tag',
                    },
                });
                return false;
            }
        };
    }

    static getExecution(executionId, onSuccess = () => {}, onFail = () => {}) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.GET_EXECUTION_DETAILS });
            try {
                const { data } = await ExecutionMiddleware.getExecution(executionId);
                const dataSteps = isJson(data.steps) ? JSON.parse(data.steps) : [];
                //const resultData = Array.isArray(data.resultData) && checkArrayLength(data.resultData)  ? data.resultData : dataSteps;
                dispatch({
                    type: ActionTypes.GET_EXECUTION_DETAILS_SUCCESS,
                    payload: { data: { ...data, executionId, resultData: dataSteps } },
                });
                onSuccess(dataSteps);
                throw new Error('');
            } catch (error) {
                dispatch({
                    type: ActionTypes.GET_EXECUTION_DETAILS_FAILURE,
                    payload: {
                        message: 'unable to get execution details',
                    },
                });
                onFail();
                return false;
            }
        };
    }

    static setExecutionStepsById(executionId, steps) {
        return async (dispatch) => {
            dispatch({
                type: ActionTypes.SET_EXECUTION_STEPS_BY_ID,
                payload: { executionId, steps },
            });
        };
    }

    static removeExecutionStepsById(executionId) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.REMOVE_EXECUTION_STEPS_BY_ID, payload: { executionId } });
        };
    }

    static setExecutionStepsData(testSteps) {
        const testStepsObj = {};
        const instrNumArray = [];
        const _steps = checkArrayLength(testSteps) ? arrangeStepsWithCompoundSteps(testSteps) : [];
        _steps.forEach((step) => {
            testStepsObj[step.instrNum] = step;
            instrNumArray.push(step.instrNum);
        });
        return (dispatch) => {
            return dispatch({
                type: ActionTypes.SET_EXECUTION_DATA,
                payload: { instrNumArray, testStepsObj },
            });
        };
    }

    static updateExecutionStepStatus(instrNum, data, msgType, executionId, paramExecutionId) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.UPDATE_SINGLE_EXECUTION_STEP_STATUS,
                payload: { instrNum, data: data || {}, msgType, executionId, paramExecutionId },
            });
    }

    static updateExeuctionViewType(flag) {
        return (dispatch) => {
            return dispatch({
                type: ActionTypes.UPDATE_EXECUTION_VIEW_TYPE,
                payload: { flag },
            });
        };
    }

    static clearReducer() {
        return (dispatch) => {
            return dispatch({
                type: ActionTypes.CLEAR_EXECUTION_REDUCER,
            });
        };
    }

    static updateExecutionStatus(executionId, status) {
        return async (dispatch) => {
            dispatch({ type: ActionTypes.UPDATE_EXECUITON_STATUS, payload: { executionId, status } });
        };
    }

    static setExecutionMsgtypeData(data) {
        return (dispatch) => {
            return dispatch({
                type: ActionTypes.SET_EXECUTION_MSGTYPE_DATA,
                payload: data,
            });
        };
    }

    static updateExecutionStepsInProjectReducer(executionId) {
        return (dispatch, getState) => {
            const executionSteps = checkKeyInObject(getState().projectReducer.executionRunningData[executionId], 'steps', 'value', []);
           // const executionDetailsSteps = checkKeyInObject(getState().executionReducer.executionDetails, 'Steps', 'value', []);
            const executionResultDataSteps = checkKeyInObject(getState().executionReducer.executionDetails, 'resultData', 'value', []);

            if (checkArrayLength(executionResultDataSteps) && !checkArrayLength(executionSteps)) {
                return dispatch({
                    type: ActionTypes.SET_EXECUTION_STEPS_BY_ID,
                    payload: { steps: executionResultDataSteps, executionId },
                });
            }
            return null;
        };
    }
}

export { ExecutionActions };
