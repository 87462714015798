import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

// icons
import Email from '@material-ui/icons/Email';

import List from '@material-ui/core/List';
import { SIGNUP_PAGE_CSS } from '../../common/cssConstants';
import { styles } from '../userRegistration/style';
import { AuthActions } from '../../store/actions/index';
import config from '../../config';
import { AuthListItem } from '../../components/auth/AuthBtnRight';
import CustomTextField from '../../components/auth/CustomTextField';
import LowCodeLogo from '../../components/signin/LowCodeLogo';

const { companyName, loginPageLogoUrl,loginPageBrandLogoUrl,loginPageBrandTextUrl,loginPageBrandTextSepratorUrl, loginPageBrandTextLowcodeUrl } = config;
const re = /\S+@\S+\.\S+/;

class ForgotPasswordBase extends React.Component {
    state = {
        allowResubmit: true,
        email: '',
        error: null,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.emailSendSuccess || nextProps.error) {
            this.setState({
                allowResubmit: true,
                email: nextProps.emailSendSuccess ? '' : this.state.email,
            });
        }
    }

    componentWillUnmount() {
        this.props.clearAuthError();
        this.props.clearAuthSuccess();
    }

    handleEmail = (event) => {
        this.props.clearAuthError();
        this.props.clearAuthSuccess();
        const { value } = event.currentTarget;
        this.setState({
            email: value.trim(),
            error: '',
        });
    };

    validateEmail = () => {
        const { email } = this.state;
        let error = '';
        if (!email) {
            error = 'Please Enter Email';
        } else if (!re.test(email)) {
            error = 'Please Enter Valid Email';
        }
        this.setState({
            error,
        });
        return error;
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const error = this.validateEmail();
        if (!error) {
            this.setState({
                allowResubmit: false,
            });
            const { authenticateEmail } = this.props;
            authenticateEmail(this.state.email);
        }
    };

    handleLogin = () => {
        this.props.history.push('signin');
    };

    render() {
        const { classes, error: reducerError, emailSendSuccess } = this.props;
        const { email, allowResubmit, error: stateError } = this.state;
        const error = reducerError || stateError;
        return (
            <div className={classes.root}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <List className={classes.listItems}>
                        <AuthListItem onClick={this.handleLogin} text="Go Back to Login" listItemRoot={classes.listItemRoot} />
                    </List>
                </div>
                <div className={classes.whiteCard}>
                    <div className={classes.container}>
                        <div className={classes.whiteCardTop}>
                            <div className={classes.logoContainer}>
                                <LowCodeLogo height="32px" width="270px"/>
                            </div>
                            <div >
                             <Typography style={{color: '#777777', fontWeight: '400', fontSize:'23.5996px' , fontStyle: 'normal', fontFamily:'Roboto', marginBottom: '1rem'}}> Forgot Your Password</Typography> 
                            </div>
                           
                            <Typography variant="body2" className={classes.instructionTextForgot}>
                                Please enter your email address. A notification will be sent to you with instruction to reset your password
                            </Typography>
                            {emailSendSuccess && (
                                <Typography className={[classes.responseText, classes.colorSuccess].join(' ')}>{emailSendSuccess}</Typography>
                            )}
                            <FormControl style={{ width: '70%', marginTop: 15 }}>
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        width: '100%',
                                        height: SIGNUP_PAGE_CSS.textFieldHeight,
                                    }}
                                >
                                    <CustomTextField
                                        className={`${classes.formInput} ${classes.placeholder}`}
                                        style={{ paddingTop: '4px' }}
                                        autoFocus
                                        id="emailId"
                                        aria-label="Email"
                                        placeholder="Your Email"
                                        onChange={this.handleEmail}
                                        required
                                        value={email}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    style={{
                                                        marginLeft: '10px',
                                                        marginBottom: '2px',
                                                        color: '#c4c4c4',
                                                    }}
                                                    position="start"
                                                    className={classes.inputAdornment}
                                                >
                                                    <Email aria-label="emailIcon" id="emailIcon" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className={classes.formWrapper}>
                                    {error && <Typography className={[classes.responseText, classes.colorError].join(' ')}>{error}</Typography>}
                                </div>
                            </FormControl>
                            <div
                                style={{
                                    width: '67%',
                                    marginRight: '10px',
                                    marginTop: '10px',
                                    paddingBottom: '5px',
                                    height: SIGNUP_PAGE_CSS.submitButtonHeight,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.raisedBtn}
                                    style={{ width: '100%' }}
                                    onClick={this.handleSubmit}
                                    disabled={!allowResubmit || !!stateError}
                                    classes={{
                                        disabled: classes.loginDisabled,
                                    }}
                                >
                                    Send
                                </Button>
                            </div>
                        </div>
                        <div  className={classes.whiteCardRight} style={{backgroundColor:'#0F5286'}}>
                            {companyName.toLocaleLowerCase() === 'autonomiq' ? (
                                <div>
                                    <img src={loginPageLogoUrl} alt=""  />
                                    {/* <img src={logoUrl} alt="" className={classes.autonomiqLogo} /> */}
                                </div>
                            ) : (
                                <img src={loginPageLogoUrl} alt=""  />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.authReducer.isLoading,
        error: state.authReducer.error,
        emailSendSuccess: state.authReducer.emailSendSuccess,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        authenticateEmail: (email) => dispatch(AuthActions.authenticateEmail(email)),
        clearAuthError: () => dispatch(AuthActions.clearAuthError()),
        clearAuthSuccess: () => dispatch(AuthActions.clearAuthSuccess()),
    };
}

export const ForgotPassword = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotPasswordBase));
