import { ActionTypes } from '../constants';
import { checkArrayLength, checkKeyInObject } from '../../utils/utils';

const initialState = {
    disabledProjects: [],
    debugPointList: {}, // {testCaseId: [instrNum]}
    error: null,
    isLoadingDp: false,
    isLoading: false,
    showDisabledProjects: false,
    success: null,
};

export default function projectsReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_ERROR: {
            return { ...state, error: null };
        }
        case ActionTypes.CLEAR_SUCCESS: {
            return { ...state, success: null };
        }
        case ActionTypes.GET_DISABLED_PROJECTS: {
            return { ...state, isLoading: true, isLoadingDp: true, success: null, error: null, showDisabledProjects: true };
        }
        case ActionTypes.GET_DISABLED_PROJECTS_SUCCESS: {
            const { data } = action.payload;
            return { ...state, isLoading: false, isLoadingDp: false, disabledProjects: data.reverse() };
        }
        case ActionTypes.GET_DISABLED_PROJECTS_FAILURE: {
            const { message } = action.payload;
            return { ...state, isLoading: false, isLoadingDp: false, error: message, success: null };
        }
        case ActionTypes.ENABLE_PROJECTS: {
            return { ...state, isLoading: true, success: null, error: null };
        }
        case ActionTypes.ENABLE_PROJECTS_SUCCESS: {
            const { data, message } = action.payload;
            const { disabledProjects } = state;
            const _disabledProjects = checkArrayLength(disabledProjects)
                ? disabledProjects.filter((project) => {
                      return checkArrayLength(data) && checkKeyInObject(data[0], 'id') && data[0].id !== project.projectId;
                  })
                : [];
            return { ...state, isLoading: false, success: message, error: null, disabledProjects: _disabledProjects };
        }
        case ActionTypes.ENABLE_PROJECTS_FAILURE: {
            const { message } = action.payload;
            return { ...state, isLoading: false, success: null, error: message };
        }
        case ActionTypes.SHOW_ENABLE_PROJECTS: {
            return { ...state, showDisabledProjects: false };
        }
        case ActionTypes.UPDATE_DEBUG_POINT_LIST: {
            return { ...state, debugPointList: action.payload.debugPointList };
        }
        default:
            return state;
    }
}
