import React, { memo } from 'react';
import './index.css';

const NoSearchResultFoundMessage  = ({heading,description}) => {
    return (

        <div className={'message_container'}>
            <div className={'message_heading'}>
                {heading}
            </div>
            <div className={'message_description'}>
                {description}
            </div>        
        </div>    
    )


}
NoSearchResultFoundMessage.defaultProps = {
    heading:'We found no results',
    description:'Try updating your search criteria'
}

export default memo(NoSearchResultFoundMessage);