// import materials
import { withStyles } from '@material-ui/core/styles';
// import packages
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { checkKeyInObject, getUserPreference } from '../../../utils';
import { ProjectActions, ExecutionActions, TaskAction } from '../../../store/actions';
import ExecutionHeader from './Header/ExecutionHeader';
import ExecutionTestSteps from './ExecutionSteps/ExecutionTestSteps';
import { TestStepUtils } from '../../../utils/TestStepUtils';
import ExecutionHelper from './ExecutionHelper';

// Styles
const styles = () => ({
    container: {
        display: 'flex',
        height: 'calc(100vh - 185px)',
        justifyContent: 'center',
        minHeight: 100,
        overflow: 'visible',
    },
    root: {
        width: 'calc(100% - 40px)',
        backgroundColor: '#ececec',
        margin: '0 20px',
    },
});

class Executions extends Component {
    state = {};

    componentDidMount() {
        this.updateReducerData(this.props);
        this.props.updateExeuctionViewType(getUserPreference('executionlistview'));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            checkKeyInObject(nextProps.match, 'params.executionId', 'value', 0) !==
            checkKeyInObject(this.props.match, 'params.executionId', 'value', -1)
        ) {
            this.updateReducerData(nextProps);
        }
    }

    componentWillUnmount() {
        this.props.clearReducer();
        this.props.updateProjectId(null);
        this.props.updateTestCaseId(null);
        setTimeout(() => {
            this.props.getExecutionTasks(0, 0, -1, 1, false, null, false);
        }, 100);
    }

    updateReducerData = (props) => {
        const { getExecution, match, updateProjectId, updateTestCaseId, getExecutionTasks } = props;
        if (checkKeyInObject(match, 'params.executionId', 'value', 0)) {
            getExecution(match.params.executionId, () => {
                const instrNum = window.location && window.location.hash && window.location.hash.split('#').pop();
                if (
                    instrNum &&
                    checkKeyInObject(match, 'params.executionId', 'value', 0) &&
                    checkKeyInObject(match, 'params.projectId', 'value', 0)
                ) {
                    TestStepUtils.scrollToSpecificStep(`${match.params.projectId}_${match.params.executionId}_${instrNum}`, true, true);
                }
            });
        }
        if (checkKeyInObject(match, 'params.projectId', 'value', 0)) {
            const projectId = checkKeyInObject(match, 'params.projectId', 'value', -1);
            const testCaseId = checkKeyInObject(match, 'params.testCaseId', 'value', 0);
            this.props.getProjectDetails(match.params.projectId, false, () => {
                setTimeout(() => {
                    getExecutionTasks(projectId, -1, testCaseId, 1, false, null, false);
                }, 210);
            });
            updateProjectId(projectId);
            updateTestCaseId(testCaseId);
        }
    };

    render() {
        const { classes, history, match, total_time } = this.props;

        const liveStepsContent = (
            <ExecutionTestSteps
                projectId={checkKeyInObject(match, 'params.projectId', 'value', 0)}
                executionId={checkKeyInObject(match, 'params.executionId', 'value', 0)}
            />
        );
        return (
            <div className={classes.root}>
                <ExecutionHeader
                    paramProjectId={checkKeyInObject(match, 'params.projectId', 'value', 0)}
                    paramTestCaseId={checkKeyInObject(match, 'params.testCaseId', 'value', 0)}
                    paramExecutionId={checkKeyInObject(match, 'params.executionId', 'value', 0)}
                    history={history}
                    total_time={total_time}
                />
                <div className={classes.container}>{liveStepsContent}</div>
                <ExecutionHelper match={this.props.match} />
            </div>
        );
    }
}

Executions.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
    return {
        // Auth Reducer
        user: state.authReducer.user,
        total_time: state.executionReducer.executionDetails.total_time,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProjectDetails: (...args) => dispatch(ProjectActions.getProjectDetails(...args)),
        getExecution: (...args) => dispatch(ExecutionActions.getExecution(...args)),
        getExecutionTasks: (...args) => dispatch(ProjectActions.getExecutionTasks(...args)),
        updateExeuctionViewType: (...args) => dispatch(ExecutionActions.updateExeuctionViewType(...args)),
        clearReducer: () => dispatch(ExecutionActions.clearReducer()),
        updateProjectId: (...args) => dispatch(TaskAction.updateProjectId(...args)),
        updateTestCaseId: (...args) => dispatch(TaskAction.updateTestCaseId(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Executions));
