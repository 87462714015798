import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { TABLES_CSS } from '../../common/cssConstants';
import { TestStepUtils } from '../../utils/TestStepUtils';
import { checkKeyInObject } from '../../utils';

const CustomTableCell = withStyles((/* theme */) => ({
    head: {},
    body: {
        fontSize: 14,
    },
}))(TableCell);

const styles = (theme) => ({
    root: {
        width: 'calc(100% - 60px)',
        maxHeight: 'calc(100vh - 300px)',
        overflowX: 'auto',
        margin: '8px 30px 5px 30px',
    },
    table: {
        minWidth: 540,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    cell: {
        width: '40%',
        padding: '4px 0 4px 24px',
        wordBreak: 'break-word',
    },
    cell1: {
        width: '30%',
        padding: '4px 0 4px 24px',
        wordBreak: 'break-word',
    },
    text: {
        fontSize: TABLES_CSS.normalFontSize,
        textAlign: 'justify',
    },
    typoClass: {
        fontSize: TABLES_CSS.headingFontSize,
        fontWeight: TABLES_CSS.headingFontWeight,
        color: TABLES_CSS.headingFontColor,
    },
});

const PreviewTestCase = (props) => {
    const { classes, testSteps } = props;
    const prepareDataToDisplay = (item) => {
        const reg = /(?:\r\n|\r|\n|\$\$aiq\$\$)/g;
        const instrSplit = item.instr.split(reg);
        const dataSplit = item.data.split(reg);
        const expectedSplit = checkKeyInObject(item, 'expectedResults', 'bool', false) ? item.expectedResults.split(reg) : [];
        const instr = [];
        const data = [];
        const expected = [];
        instrSplit.forEach((_x, i) => {
            if (instr.length > 0) {
                // if need to show line break instead of . uncomment these lines
                // instr.push(<br key={`bri${i}`} />)
                // data.push(<br key={`brd${i}`} />)
                // expected.push(<br key={`bre${i}`} />)
                if (instrSplit[i]) instr.push(<span key={`bri${i}`}>.</span>);
                if (dataSplit[i]) data.push(<span key={`brd${i}`}>.</span>);
                if (expectedSplit[i]) expected.push(<span key={`bre${i}`}>.</span>);
            }
            instr.push(<span key={`i${i}`}>{instrSplit[i] || ''}&nbsp;</span>);
            data.push(<span key={`d${i}`}>{TestStepUtils.isShowAsterik(instrSplit[i], dataSplit[i]) || ''}&nbsp;</span>);
            expected.push(<span key={`e${i}`}>{expectedSplit[i] || ''}&nbsp;</span>);
        });
        return { instr, data, expected };
    };
    const getCell = ({ data, customStyle = {}, customClass = '', typoStyle = {}, typoClass = '', typoVariant }) => {
        return data ? (
            <CustomTableCell style={customStyle} className={customClass}>
                <Typography align="left" variant={typoVariant} style={typoStyle} className={typoClass}>
                    {data}
                </Typography>
            </CustomTableCell>
        ) : null;
    };
    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {getCell({ data: 'Test Steps', variant: 'subtitle1', typoClass: classes.typoClass })}
                        {getCell({ data: 'Test Data', variant: 'subtitle1', typoClass: classes.typoClass })}
                        {getCell({ data: 'Expected Result', variant: 'subtitle1', typoClass: classes.typoClass })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {testSteps &&
                        testSteps.map((item, index) => {
                            const { instr, data, expected } = prepareDataToDisplay(item);
                            return (
                                <TableRow className={classes.row} key={index.toString()}>
                                    {getCell({ data: instr, customClass: classes.cell, typoClass: classes.text })}
                                    {getCell({ data, customClass: classes.cell1, typoClass: classes.text })}
                                    {getCell({ data: expected, customClass: classes.cell1, typoClass: classes.text })}
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </Paper>
    );
};

PreviewTestCase.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    testSteps: PropTypes.instanceOf(Object),
};

PreviewTestCase.defaultProps = {
    testSteps: {},
};

export default withStyles(styles)(PreviewTestCase);
