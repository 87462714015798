import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    mainImg: {
        height: '72px',
        width: '72px',
        color: 'rgba(0, 0, 0, 0.3)',
        //marginBottom: 20,
        fontSize: '72px',
        paddingTop: '20px',
        // marginLeft: '20px',
    },
    action: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginLeft: '-4%',
    },
    smallIcon: {
        color: 'rgb(96, 148, 255)',
        fontSize: '28px',
        position: 'relative',
        marginRight: 5,
        bottom: '-8px',
    },
    uploadIcon: {
        color: 'rgb(27, 171, 171)',
        marginRight: 10,
        marginLeft: 10,
        fontSize: 20,
    },
    buttonTitle: {

    }
});

function NoDataToShow({ imgSrc, IconSrc, classes, selectedTab, messageText, buttonTitle, onClick }) {
    return (
        <div
            style={{
                textAlign: 'center',
                color: '#909090',
                width: '100%',
                paddingBottom: '10px',
                backgroundColor: '#fff',
            }}
        >
            <img
                src={imgSrc}
                alt="Main"
                className={classes.mainImg}
                style={selectedTab === 'suiteReport' ? { height: 100, marginBottom: 0, width: 100 } : {}}
            />
            {selectedTab === 'script' || selectedTab === 'suiteReport' ? (
                <div style={selectedTab === 'suiteReport' ? { fontSize: '16px' } : { fontSize: '13px' }}>{messageText}</div>
            ) : (
                <div>
                    <div
                        style={{
                            fontSize: '18px',
                            marginBottom: '8px',
                            fontWeight: '500',
                            color:'#1E2127',
                        }}
                    >
                        It's Lonely Here!
                    </div>
                    <div style={{ fontSize: '13px' }}>
                        Click on above button <span style = {{fontWeight:'bold'}} >{buttonTitle}</span>  to
                        
                         start testing with Autonomiq.
                    </div>
                </div>
            )}
        </div>
    );
}

export default withStyles(styles)(NoDataToShow);
