export const autoSuggestCompStyles = (theme) => ({
    cancelIcon: {
        fontSize: 20,
        cursor: 'pointer',
        color: '#EF6261',
    },
    container: {
        position: 'relative',
    },
    divider: {
        height: theme.spacing(2),
    },
    iconsGeneralStyle: {
        float: 'right',
        marginRight: 15,
    },
    adjustInfo: {
        '&>div': { paddingRight: 24 },
    },
    placeholder: {
        '&>div': { paddingBottom: '3px !important' },
        '&>div>div': { height: '24px !important' },
        '&>div>div>:nth-child(3)': { fontSize: 12, fontWeight: 400 },
        '&>div>div>textarea::-webkit-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div>textarea:-moz-placeholder': {
            /* Firefox 18- */ fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div>textarea::-moz-placeholder': {
            /* Firefox 19+ */ fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div>textarea:-ms-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div>textarea::placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            color: '#9B9B9B',
            opacity: 1,
        },
    },
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        padding: '3px',
    },
    suggestion: {
        display: 'block',
        '&>div': {
            padding: '4px 16px 0px 16px',
        },
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        maxHeight: '200px',
        overflowY: 'auto',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 0,
        marginBottom: 0,
        display: 'flex',
        '&>div': {
            '&:first-of-type': {
                flex: '1',
            },
        },
    },
    testDataTitleStyle: {
        color: '#1BABAB',
        borderTop: '3px solid #1BABAB',
    },
    expectedValuesTitleStyle: {
        color: '#B98700',
        borderTop: '3px solid #B98700',
    },
    placeholderGeneral: {
        padding: '0px 15px',
        '&>div': {
            '&:before': {
                borderBottom: 'none !important',
            },
            '&:after': {
                borderBottom: 'none !important',
            },
        },
        '&>div>div>:nth-child(3)': { fontSize: 12, fontWeight: 400 },
        '&>div>div>textarea::-webkit-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div>textarea:-moz-placeholder': {
            /* Firefox 18- */ fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div>textarea::-moz-placeholder': {
            /* Firefox 19+ */ fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div>textarea:-ms-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div>textarea::placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div': {
            height: '130px !important',
        },
    },
    paperStyle: {
        borderRadius: 5,
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.8)',
    },
    zIndex: {
        zIndex: 10000000,
    },
    updateIcon: {
        fontSize: 20,
        cursor: 'pointer',
        color: '#F39B31',
    },
    saveIcon: {
        fontSize: 20,
    },
    copyIcon: {
        fontSize: 18,
    },
    enableCopyIcon: {
        cursor: 'pointer',
        color: '#00aa00',
    },
    enableCodeIcon: {
        cursor: 'pointer',
        color: '#3B91DF',
    },
    enableSaveIcon: {
        cursor: 'pointer',
        color: '#1BABAB',
    },
    disableIcon: {
        cursor: 'not-allowed',
        color: '#a9a9a9',
    },
    erIcon: {
        fontSize: 18,
        cursor: 'pointer',
        color: '#3B91DF',
    },
    paperGeneral: {
        position: 'relative',
        top: '-115px',
        borderRadius: 5,
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.8)',
    },
    width42: {
        width: '42%',
    },
    closeIconOval: {
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.35)',
        borderRadius: '50px',
        cursor: 'pointer',
        display: 'flex',
        height: 20,
        justifyContent: 'center',
        width: 20,
    },
    fullScreenExitStyle: {
        color: '#4A4A4A',
        fontSize: 18,
    },
    helpOutlineWrapper: {
        marginLeft: '-20px',
        zIndex: 1,
    },
    helpOutline: {
        color: '#17a2b8',
        width: '20px',
        marginBottom: '-15px',
    },
    errorItem: {
        fontSize: 12,
        textTransform: 'capitalize',
    },
});
