import { TABLE_PAGE_HEADING, BLACK_FONT } from '../../../common/cssConstants';

// Theme-dependent styles
export const styles = (theme) => ({
    bodyContainer: {
        backgroundColor: '#F4F6F7',
        boxShadow: '0 1px 4px 0 rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'space-around',
        paddingBottom: 40,
        paddingTop: 40,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: '5px',
            paddingRight: '10px',
        },
    },
    gridContainer: {
        marginLeft: 15,
    },
    headerContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '15px 0 24px 0',
    },
    headerTitle: {
        color: '#B2D3F4',
        marginTop: 20,
        fontWeight: 'bold',
        marginBottom: 7,
    },
    menuButton: {
        color: 'white',
        fontSize: 33,
    },
    rootContainer: {
        backgroundColor: '#ffffff',
        maxHeight: 'calc(100vh - 112px)',
        overflow: 'hidden',
        overflowY: 'auto',
        width: 'calc(100% - 40px)',
        margin: '0px 20px',
        marginTop: '20px',
    },
    sectionIcon: {
        color: '#003ea1',
        fontSize: 75,
    },

    headingColor: {
        lineHeight: '15px',
        color: TABLE_PAGE_HEADING.fontColor,
        fontSize: TABLE_PAGE_HEADING.fontSize,
        fontWeight: TABLE_PAGE_HEADING.fontWeight,
    },
    thanks: {
        color: '#8898a8',
        fontWeight: 'bold',
        fontSize: '14px',
        marginTop: 0,
    },
    titleText: {
        color: BLACK_FONT,
        fontSize: '25px',
        marginTop: '5px',
        fontWeight: 'normal',
        marginLeft: '20px',
    },
    title: {
        display: 'flex',
        marginTop: '15px',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
});
