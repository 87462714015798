/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
// import material
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

// import packages
import React from 'react';

// import icons
import ChromeReaderMode from '@material-ui/icons/ChromeReaderMode';
import Help from '@material-ui/icons/Help';
import { IconButton , Button } from '@material-ui/core';

import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import custom copmponents or services
import { checkArrayLength, checkObject, checkKeyInObject, getTestCaseStatus } from '../../../../utils/utils';
import {
    ProjectActions,
    GeneralActions,
    SelectedTestCaseActions,
    TestCaseActions,
    ModalActions,
    TestStepActions,
    ProjectsActions,
} from '../../../../store/actions';
import SendLog from './SendLog';

// import custom copmponents or services
import Tooltip from '../../../../components/Tooltip';
import { TestCaseUtils, getParamValues, sort, checkIsArray } from '../../../../utils';
 import { CustomDownloadModal } from '../../../../components/CustomModal/CustomDownloadModal';
import ConfirmNavigationModal from '../../../modal/ConfirmNavigationModal';
import UpdateTestCaseHeaderTopDetailContent from './UpdateTestCaseModalContent';
import AlertModal from '../../Case/AlertModal';
import singleFileCreationService from '../../../../services/singleFileCreationService';
import ContentLoader from '../../../../components/ContentLoader';
import HeaderBottom from './HeaderBottom';
import { MenuOptions } from '../../../../components/Button/MenuOptions';
import ExecuteScriptModal from '../../Script/ExecuteScriptModal';
import TestCaseTimer from './TestCaseTimer';

import TagIcon from '../../../../assets/images/tagIcon.png';
import Spark from '../../../../assets/images/spark.png';
import execute_icon from '../../../../assets/images/execute_icon.svg';
import generate_icon from '../../../../assets/images/generate_icon.svg';
import generate_icon_grey from '../../../../assets/images/generate_icon_grey.svg';

import ScreenView from '../../../../assets/images/screenView.png';
import ScrollIcon from '../../../../assets/images/autoScroll.png';
import CacheIcon from '../../../../assets/images/cacheIcon.png';
import CreationIcon from '../../../../assets/images/creationMode.png';
import RemoveIcon from '../../../../assets/images/removeIcon.png';
import DownloadLogs from '../../../../assets/images/downloadLogs.png';
import DownloadedFile from '../../../../assets/images/downloadedFile.png';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import UndoRoundedIcon from '@material-ui/icons/UndoRounded';
import RedoRoundedIcon from '@material-ui/icons/RedoRounded';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import {SEGMENT_EVENT} from '../../../../common/constants';
import { track } from '../../../../services/Segment';
import TestStepConfigurationMenu from './TestStepConfigurationMenu';
import { AIQ_BLUE_COLOR, AIQ_GREY_COLOR } from '../../../../common/cssConstants';
import { successAlertBar } from '../../../../services/AlertBarService';


let allowShift = true;
const style = () => ({
    cachedBtn: {
        minHeight: '0px',
        padding: '0px',
        minWidth: '0px',
        height: '24px',
        width: '24px',
        textTransform: 'unset',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#e1e1e1',
        },
        marginRight: 8,
    },
    headerMiddleRoot: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        backgroundColor: '#ffffff',
        borderBottom: '1px solid #e0e0e0',
        padding: '10px 10px 20px 10px',
        overflow: 'hidden',
        boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        marginTop: '20px',
        borderRadius: '5px 5px 0px 0px',
    },
    blueSvg: {
        color: '#3B91DF',
        width: '20px',
        height: '20px',
        cursor: 'pointer',
    },
    lightBlueSvg: {
        color: '#1168CD',
    },
    mr10: {
        marginRight: '10px'
    },
    disabledSvg: {
        backgroundColor: '#9a9a9a',
        display: 'inline-block',
        width: '20px',
        height: '20px',
        cursor: 'not-allowed',
        maskSize: '20px 20px',
        WebkitMaskSize: '20px 20px',
    },
    blueButtonText: {
        color: '#1168CD',
        fontSize: '12px',
    },
    blueButtonIcon: {
        height: '20px',
        width: '20px',
        color: '#1168CD',
        fontSize: '20px',
    },
    nexPrevWrapper: {
        display: 'flex',
    },
    groupButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        boxSizing: 'border-box',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        marginRight: 10,
        width: '100%',
    },
    blueOutlinedButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        marginRight: 10,
        border: '1px solid #1168CD',
        '&:hover': {
            backgroundColor: '#e1e1e1',
        },
    },
    pipe: {
        height: 20,
        boxSizing: 'border-box',
        border: '1px solid #979797',
    },
    redSvg: {
        color: 'red',
    },
    materialSvg: {
        margin: '2px 6px',
        height: '20px',
        width: '20px',
    },
    materialSvgPlay: {
        margin: '2px 6px',
        height: '21px',
        width: '21px',
        border: '1px solid #ff9f00',
        borderRadius: '13px',
    },
    BlueButtonSvg: {
        height: 20,
        width: 'auto',
        margin: '5px 5px 4px 5px',
    },
    faSvg: {
        color: '#5B6391',
        margin: '2px 10px',
        height: 25,
        width: 'auto',
    },
    moreIcons: {
        color: '#5F7B96',
        marginRight: 5,
        marginLeft: 10,
    },
    headerMiddleLeftContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'calc(100%)',
    },
    name: {
        color: '#00528a',
        fontSize: '14px',
        margin: '1px 0px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: 'bold !important',
        maxWidth: '300px'
    },
    desc: {
        fontSize: '12px',
        fontWeight: '300',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        color: '#676262',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        margin: '10px 0 0 0',
    },
    caseDetailContainer: {
        marginLeft: 15,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden',
    },
    caseDetailContainerLoading: {
        marginLeft: 15,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 30,
        overflow: 'hidden',
    },
    loaderWrapper: {
        marginTop: -2,
        marginBottom: 5,
    },
    descContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    displayButtonIcons: {
        height: 20,
        width: 20,
    },
    displayButtonIcons18: {
        height: 20,
        width: 20,
    },
    displayButtonIconsSmall: {
        height: 16,
        width: 20,
    },
    editTestCaseIcon: {
        width: '20px',
    },
    navigationBackDisabled: {
        cursor: 'default',
        margin: 0,
        width: 20,
        backgroundColor: '#737070',
        color: '#fff',
        borderRadius: '5px',
        boxShadow: 'none',
        textTransform: 'capitalize',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '5px 0px',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#737070',
        },
    },
    navigationBack: {
        cursor: 'pointer',
        margin: 0,
        width: 20,
        backgroundColor: '#0092e6',
        color: '#fff',
        borderRadius: '5px',
        boxShadow: 'none',
        textTransform: 'capitalize',
        justifyContent: 'flex-end',
        padding: '5px 0px',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#0092e6',
            boxShadow: 'none',
        },
    },
    navigationForDisabled: {
        cursor: 'default',
        margin: 0,
        width: 20,
        backgroundColor: '#737070',
        color: '#fff',
        borderRadius: '5px',
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'start',
        textTransform: 'capitalize',
        padding: '5px 0px',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#737070',
        },
    },
    navigationFor: {
        cursor: 'pointer',
        margin: 0,
        width: 20,
        backgroundColor: '#0092e6',
        color: '#fff',
        borderRadius: '5px',
        boxShadow: 'none',
        justifyContent: 'start',
        textTransform: 'capitalize',
        padding: '5px 0px',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#0092e6',
            boxShadow: 'none',
        },
    },
    executeIcon: {
        width: '96px',
        height: '30px',
        opacity: '0.93',
        marginBottom: '10px',
        marginRight: '20px',
        borderRadius: '5.5px',
        backgroundColor: '#222020',
        borderBottom: '5px solid #ff9f00',
        marginLeft: 20,
        '& p': {
            color: '#C4C4C4',
            fontWeight: 500,
            fontSize: '12px',
            '&:hover': {
                color: '#000000'
            }
        },
        '& span': {
            width: '85%',
            display: 'flex',
            // alignItems: 'inherit',
            alignItems: 'center',
            justifyContent: 'space-evenly',
        }
    },

    actionBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 16px',
        margin:'6px',
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight:'1.4',
        borderRadius: '4px',
    },
    executeBtn: {
        color:'#ffffff',
        background: AIQ_BLUE_COLOR,
        border:`solid 1px ${AIQ_BLUE_COLOR}`,
        '&:hover': {
            backgroundColor: AIQ_BLUE_COLOR
        }
    },
    disable_executeBtn : {
        color:'#ffffff',
        background: AIQ_GREY_COLOR,
        border:`solid 1px ${AIQ_GREY_COLOR}`,
        cursor:'auto',
        '&:hover': {
            backgroundColor: AIQ_GREY_COLOR,
            color:'#ffffff',
            border:`solid 1px ${AIQ_GREY_COLOR}`,
        },
        '&:disabled': {
            backgroundColor: AIQ_GREY_COLOR,
            color:'#ffffff',
            border:`solid 1px ${AIQ_GREY_COLOR}`,
            '&:hover': {
                backgroundColor: AIQ_GREY_COLOR,
                color:'#ffffff',
                border:`solid 1px ${AIQ_GREY_COLOR}`,
            }

          }  
    },
    saveBtn:{
        color:'#151515',
        background: '#ffffff',
        border:'solid 1px #c6c1c1',
        '&:hover': {
            backgroundColor: '#ffffff'
        }
    },

    generateBtn: {
        color:'#3B91DF',
        background: '#fffff',
        border:'solid 1px #3B91DF',
        '&:hover': {
            backgroundColor: '#ffffff'
        }
    },
    generateDisabled: {
        color:'#c6c1c1',
        background: '#fffff',
        border:'solid 1px #c6c1c1',
        '&:hover': {
            backgroundColor: '#ffffff'
        }
    },
    iconsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    displayTimer: {
        display: 'flex',
        alignItems: 'center',
    },
    sparkIcon: {
        width: '11.5px',
        height: '17px',
    },
    midHeader: {
        display: 'flex',
        width: '100%',
        paddingTop: 20,
    },
    ActionHeader: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    headerTitle: {
        opacity: '0.88',
        fontFamily: 'HelveticaNeue',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1',
        letterSpacing: 'normal',
        color: '#043452',
    },
    iconPlay: {
        display: 'inline-block',
        width: '20px',
        height: '20px',
        cursor: 'pointer',
        maskSize: '20px 20px',
        backgroundColor: 'black',
        WebkitMaskSize: '20px 20px',
        // backgroundImage: `url(${PlayIcon})`,
    },
    avatar: {
        minWidth: '18px',
        height: '18px',
        borderRadius: '18px',
        backgroundColor: '#5F7B96',
        color: '#fff',
        textAlign: 'center',
        marginRight: '8px',
        fontSize: '14px',
        lineHeight: 1.4,
        padding: '0 4px',
        display: 'inline-block',
    },
    clockIcon: {
        width: '1rem',
        height: '1rem',
        marginRight: '5px',
        color: '#5F7B96',
    },
    iconStop: {
        display: 'inline-block',
        width: '20px',
        height: '20px',
        cursor: 'pointer',
        maskSize: '20px 20px',
        WebkitMaskSize: '20px 20px',
        backgroundColor: '#dc5656',
        // backgroundImage: `url(${PlayIcon})`,
    },
    stepTimerText: {
        color: '#587c99',
        fontSize: '12px',
        marginRight: '10px',
    },
    iconReload: {
        display: 'inline-block',
        width: '20px',
        height: '20px',
        cursor: 'pointer',
        maskSize: '20px 20px',
        WebkitMaskSize: '20px 20px',
        backgroundColor: 'black',
        // backgroundImage: `url(${PlayIcon})`,
    },
    tootTipLabel:{
        position: 'static',
        width: '213px',
        height: '48px',
        left: '8px',
        top: '4px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#FFFFFF',
    },
    paperBody:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '4px 8px',
        position: 'relative',
        width: '229px',
        height: '56px',
        left: '6px',
        top: '-90px', 
        backgroundColor: '#1E2127',
        borderRadius: '4px',
    },
});

// const CustomButton = ({
//     btnClass,
//     buttonRef,
//     dataRh,
//     disabled,
//     Icon,
//     onClick,
//     svgClass,
//     flag,
//     stopIndicate,
//     faIcon,
//     txtStyle,
//     buttonTitle,
//     disableSvgClass,
//     enableSvgClass,
//     iconStyle,
//     IconClass,
//     circularProgressStyle,
// }) => {
//     return (
//         <span ref={buttonRef}>
//             <Tooltip data={disabled ? null : dataRh}>
//                 <Button className={btnClass} onClick={disabled ? () => { } : onClick} disabled={disabled}>
//                     {
//                         Icon ? (
//                             <span className={`${disabled ? disableSvgClass : IconClass}`} style={iconStyle}></span>
//                         ) : // <img src={Icon} className={`${svgClass} ${disabled ? disableSvgClass : enableSvgClass}`} style={iconStyle} />
//                             faIcon ? (
//                                 <img src={faIcon} className={`${svgClass} ${disabled ? disableSvgClass : enableSvgClass}`} style={iconStyle} />
//                             ) : null
//                     }
//                     {buttonTitle && <Typography className={txtStyle}>{buttonTitle}</Typography>}
//                 </Button>
//             </Tooltip>
//         </span>
//     );
// };

let paramTestCaseId = 0;
class HeaderMiddle extends React.Component {
    constructor(props) {
        super(props);
        this.isAlreadyCalledCases = false;
        this.state = {
            cacheXpaths: true,
            debugStepsState: false,
            disablDebugStepsState: false,
            dontAllowRetry: {},
            isCustomDownloadOpen: false, // to toggle download popup
            sendEmailModal: false,
            isWidthShort: false,
            prevTestCase: true,
            nextTestCase: true,
            isNextPrev: '',
            isConfirmNavigationModal: false,
            isShowMoreBtn: false,
            showFull: false,
            showNextPrevCaseName: '',
            executeScriptSystemId: [],
            anchorEl: null,
            callingFrom: 'Generate',
            openGenerateModal: false,
            shadowDomEnabledLabelShown:false
        };
        this.textCont = React.createRef();
        this.textDiv = React.createRef();
        this.isSetTimeOut = false;
    }

    /* Component life cycle start */
    componentDidMount() {
        const { debugPointList } = this.props;
        const tcId = getParamValues(2);
        paramTestCaseId = Number(tcId) ? Number(tcId) : 0;
        this.getTestCases();

        if (!checkKeyInObject(debugPointList, paramTestCaseId, 'bool') || !checkIsArray(debugPointList[paramTestCaseId])) {
            this.changeDebugStepsState(paramTestCaseId, false, true, false);
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { isWsRunning, wsKeys, testCases, selectedTestCase, isNextPrev, caseOrder, caseOrderBy, creationMode, wsRunningCaseData , shadowDom } = nextProps;
        const { wsRunningCaseData: prev_wsRunningCaseData } = this.props;
        const tcId = getParamValues(2);
        paramTestCaseId = Number(tcId) ? Number(tcId) : 0;
        const { dontAllowRetry, disablDebugStepsState ,shadowDomEnabledLabelShown } = this.state;
        let obj = { isNextPrev };

        if(shadowDom && !(shadowDomEnabledLabelShown)) {
           // successAlertBar({message:'Shadow DOM scan is enabled.',close:true,duration:12000});
            this.setState({shadowDomEnabledLabelShown:true});
        }

        if (!this.isAlreadyCalledCases) {
            this.getTestCases();
        }
        if (checkArrayLength(testCases) && selectedTestCase) {
            this.setNextPrevTestCases(sort(testCases, caseOrder, caseOrderBy), selectedTestCase);
        }
        if (nextProps.shiftToLive && allowShift) {
            this.props.headerMiddleActions.handleTabs(0);
            allowShift = false;
        }
        if (
            ((checkObject(isWsRunning) &&
                isWsRunning[paramTestCaseId] &&
                (!checkKeyInObject(wsKeys, paramTestCaseId) ||
                    (checkKeyInObject(wsKeys, paramTestCaseId) && !wsKeys[paramTestCaseId].betweenMsgType15and2))) ||
                // add this condition to reset dontAllowRetry key if we receive msgType8 before msgType3 (https://github.com/Autonomiq/QA/issues/1545)
                (checkKeyInObject(prev_wsRunningCaseData, paramTestCaseId) && !checkKeyInObject(wsRunningCaseData, paramTestCaseId))) &&
            checkKeyInObject(dontAllowRetry, paramTestCaseId)
        ) {
            const _dontAllowRetry = dontAllowRetry;
            delete _dontAllowRetry[paramTestCaseId];
            obj = { ...obj, dontAllowRetry: _dontAllowRetry };
        }
        if (checkKeyInObject(selectedTestCase, 'testCaseName') && !checkKeyInObject(this.props.selectedTestCase, 'testCaseName')) {
            obj = { ...obj, showNextPrevCaseName: '' };
        }

        if (creationMode === false && disablDebugStepsState) {
            obj = { ...obj, disablDebugStepsState: false };
        }

        this.setState(obj);
    };

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        const { selectedTab, isSaveRequestPending, saveBtnPressLoader, wsRunningCaseData, updateShowPasteButton } = nextProps;
        const { dontAllowRetry } = nextState;

        const forceDisableRetryNSave =
            checkKeyInObject(wsRunningCaseData, paramTestCaseId) && // case is between msgType 2 and 8
            !wsRunningCaseData[paramTestCaseId].isMsgType2Sent;
        const isRetryButtonDisabledForTestCase = TestCaseUtils.isRetryButtonDisabled[paramTestCaseId];
        const isShowPasteButton = !(
            `${selectedTab}` === '1' ||
            saveBtnPressLoader ||
            forceDisableRetryNSave ||
            checkKeyInObject(dontAllowRetry, paramTestCaseId) ||
            isRetryButtonDisabledForTestCase ||
            !this.checkRetryIcon() ||
            isSaveRequestPending
        );
        updateShowPasteButton(isShowPasteButton);
    }

    componentDidUpdate() {
        window.logActionButtonProperties = this.logActionButtonProperties;
        window.testingFunction_getTestSteps = this.testingFunction_getTestSteps;
        window.testingFunction_getSingleTestCase = this.testingFunction_getSingleTestCase;
        const width = window.innerWidth;
        if (width > 1310) {
            if (!this.state.isWidthShort) {
                this.updateState({
                    isWidthShort: true,
                });
            }
        } else if (this.state.isWidthShort) {
            this.updateState({
                isWidthShort: false,
            });
        }

        if (this.isSetTimeOut) {
            clearTimeout(this.isSetTimeOut);
        }
        this.isSetTimeOut = setTimeout(() => {
            if (
                this.textDiv &&
                checkKeyInObject(this.textDiv, 'current') &&
                checkKeyInObject(this.textDiv.current, 'offsetWidth') &&
                this.textCont &&
                checkKeyInObject(this.textCont, 'current') &&
                checkKeyInObject(this.textCont.current, 'offsetWidth') &&
                this.textCont.current.offsetWidth - this.textDiv.current.offsetWidth < 10
            ) {
                if (!this.state.isShowMoreBtn) {
                    this.setState({ isShowMoreBtn: true });
                }
            } else if (this.state.isShowMoreBtn) {
                this.setState({ isShowMoreBtn: false });
            }
        }, 100);
    }
    /* Component life cycle end */

    get disabledRunButton() {
        const {
            actionType, // have an ongoing action e.g add, edit
            isWsRunning, // true on 3, 4, 5 false on 8, 13, 16 and clearWsData function
            isSaveRequestPending, // while save steps request is pending it's true
            saveBtnPressLoader, // when save steps request is sent it's true, false on success/faliure
            selectedSteps, // array with instruction number of selected steps
            testSteps, // selectedTestCaseReducer object of steps
            wsRunningCaseData,
            selectedTestCase,
            setShowLoaderForGenerate,
            isGenerateBtnClicked
        } = this.props;
        const { openGenerateModal } = this.state;
        const testCaseStatusFromFunction = getTestCaseStatus(selectedTestCase, wsRunningCaseData);

        const {
            dontAllowRetry, // true against testCaseId when user just press run button
        } = this.state;

        const __isMsgType13Received = checkKeyInObject(wsRunningCaseData, paramTestCaseId)
            ? wsRunningCaseData[paramTestCaseId].isMsgType13Received
            : false;
        const __isMsgType16Received = checkKeyInObject(wsRunningCaseData, paramTestCaseId)
            ? wsRunningCaseData[paramTestCaseId].isMsgType16Received
            : false;
        const isRetryButtonDisabledForTestCase = TestCaseUtils.isRetryButtonDisabled[paramTestCaseId]; // true/false against testCaseId
        // forceDisableRetryNSave is true when case is between msgType 2 & 8 and run by other user
        const forceDisableRetryNSave =
            checkKeyInObject(wsRunningCaseData, paramTestCaseId) && // true between msgType 2 and 8
            !wsRunningCaseData[paramTestCaseId].isMsgType2Sent; // isMsgType2Sent is true when case run by current user

        return (
            !checkObject(testSteps) || // no steps
            actionType === 'add' || // step addition is in progress
            actionType === 'edit' || // step updation is in progress
            isGenerateBtnClicked[paramTestCaseId] || // msgtype 3 is not received 
            testCaseStatusFromFunction.code === 'in progress' ||  // Need to get TC status when we have delay in msgtype 3
            openGenerateModal || // generate modal is opened
            checkKeyInObject(isWsRunning, paramTestCaseId, 'bool') || // generation is in progress
            saveBtnPressLoader || // save button pressed
            forceDisableRetryNSave || // generation is in progress, run by other user
            checkKeyInObject(dontAllowRetry, paramTestCaseId) || // run button pressed
            isSaveRequestPending || // save request is in progress
            isRetryButtonDisabledForTestCase || // run button is intentionally disabled
            __isMsgType13Received || // stop at msgType = 13 // temp for debugPoint
            __isMsgType16Received || // stop at msgType = 16    // temp for debugPoint
            checkArrayLength(selectedSteps) // if any step selected
        );
    }

    get isAnyDownloadFileAvailable () {
        const { testSteps, instrNumArray } = this.props;
        return (
            checkObject(testSteps) &&
            checkArrayLength(instrNumArray) &&
            instrNumArray.some((instrNum) => testSteps[instrNum] && testSteps[instrNum].downloadFile)
        );
    }

    setNextPrevTestCases = (testCases, selectedTestCase) => {
        let prev = null;
        let next = null;
        for (let i = 0; i < testCases.length; i++) {
            if (`${selectedTestCase.testCaseId}` === `${testCases[i].testCaseId}`) {
                prev = i > 0 ? testCases[i - 1] : null;
                next = i < testCases.length - 1 ? testCases[i + 1] : null;
                break;
            }
        }
        this.setState({
            prevTestCase: prev,
            nextTestCase: next,
        });
    };

    getTestCases = () => {
        const { testCases, getTestCases, paramProjectId } = this.props;
        if (!checkArrayLength(testCases) && paramProjectId && !this.isAlreadyCalledCases) {
            this.isAlreadyCalledCases = true;
            getTestCases(paramProjectId);
        }
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    /* download popup start */
    handleDownloadButtonRef = (node) => {
        this.anchorDownloadEl = node;
    };

    handleCloseDownload = (event) => {
        if (this.anchorDownloadEl.contains(event.target)) {
            return;
        }
        this.handleDownloadPopupToggle(false);
    };

    handleDownloadPopupToggle = (flag) => {
        this.setState({ isCustomDownloadOpen: flag });
    };
    /* download popup end */

    checkRetryOriginalIcon() {
        const { isWsRunning, selectedTestCase } = this.props;
        return (
            !isWsRunning[paramTestCaseId] && selectedTestCase && checkArrayLength(selectedTestCase.originalTestSteps) && `${paramTestCaseId}` !== '-1'
        );
    }

    checkRetryIcon = () => {
        const { actionType, isWsRunning, selectedTestCase } = this.props;
        return (
            actionType !== 'add' &&
            actionType !== 'edit' &&
            selectedTestCase &&
            (!checkObject(isWsRunning) || (checkObject(isWsRunning) && !isWsRunning[paramTestCaseId])) &&
            (checkArrayLength(selectedTestCase.testSteps) || checkArrayLength(selectedTestCase.recoverTestSteps))
        );
    };

    /* Start functioin to log properties */

    checkStopIcon = (selectedTestCase) => {
        return selectedTestCase && (checkArrayLength(selectedTestCase.testSteps) || checkArrayLength(selectedTestCase.recoverTestSteps));
    };

    /* Start functioin to log properties */
    // buttonName enum(run, save, stop)
    logActionButtonProperties = (buttonName) => {
        const {
            actionType,
            isSaveRequestPending,
            isWsRunning,
            saveBtnPressLoader,
            selectedTab,
            stopRetryFlag,
            selectedTestCase,
            selectedSteps,
            testSteps,
            undoData,
            wsRunningCaseData,
        } = this.props;

        const { dontAllowRetry } = this.state;

        if (buttonName === 'run' || buttonName === 'save' || buttonName === 'stop') {
            const isRetryButtonDisabledForTestCase = TestCaseUtils.isRetryButtonDisabled[paramTestCaseId]; // copied from render function to log
            const forceDisableRetryNSave = checkKeyInObject(wsRunningCaseData, paramTestCaseId) && !wsRunningCaseData[paramTestCaseId].isMsgType2Sent; // copied from render function to log

            const isAnyActionInMiddle = actionType === 'add' || actionType === 'edit' || actionType === 'delete' || checkArrayLength(selectedSteps);

            console.log(`%c=============${buttonName} button's properties start=============`, 'color: #08E8DE;');
            if (buttonName === 'stop') {
                console.log({
                    propertiesUsedInCondition: {
                        isWsRunning,
                        paramTestCaseId,
                        selectedTab,
                        stopRetryFlag,
                        wsRunningCaseData,
                    },
                    disabledCondition:
                        !checkKeyInObject(wsRunningCaseData[paramTestCaseId], 'sessionId', 'bool') ||
                        ((!isWsRunning[paramTestCaseId] || stopRetryFlag || `${selectedTab}` === '1') &&
                            !(
                                wsRunningCaseData[paramTestCaseId] &&
                                (checkKeyInObject(wsRunningCaseData[paramTestCaseId], 'isMsgType13Received') ||
                                    checkKeyInObject(wsRunningCaseData[paramTestCaseId], 'isMsgType16Received'))
                            )),
                });
            } else if (buttonName === 'save') {
                const __isMsgType13Received = checkKeyInObject(wsRunningCaseData, paramTestCaseId)
                    ? wsRunningCaseData[paramTestCaseId].isMsgType13Received
                    : false; // temp for debugPoint
                const __isMsgType16Received = checkKeyInObject(wsRunningCaseData, paramTestCaseId)
                    ? wsRunningCaseData[paramTestCaseId].isMsgType16Received
                    : false; // temp for debugPoint
                console.log({
                    propertiesUsedInCondition: {
                        actionType,
                        dontAllowRetry,
                        paramTestCaseId,
                        forceDisableRetryNSave,
                        isRetryButtonDisabledForTestCase,
                        selectedSteps,
                        isSaveRequestPending,
                        selectedTab,
                        undoData,
                        isAnyActionInMiddle,
                        __isMsgType13Received,
                        __isMsgType16Received,
                    },
                    disabledCondition:
                        __isMsgType13Received ||
                        __isMsgType16Received ||
                        isAnyActionInMiddle ||
                        isSaveRequestPending ||
                        checkKeyInObject(dontAllowRetry, paramTestCaseId) ||
                        isRetryButtonDisabledForTestCase ||
                        (forceDisableRetryNSave && (!undoData.length || `${selectedTab}` === '1')),
                });
            } else {
                // for run
                const testCaseStatusFromFunction = getTestCaseStatus(selectedTestCase, wsRunningCaseData);
                console.log({
                    disabledCondition: this.disabledRunButton,
                    testCaseStatusFromFunction,
                    propertiesUsedInCondition: {
                        actionType,
                        dontAllowRetry,
                        forceDisableRetryNSave,
                        isSaveRequestPending,
                        isRetryButtonDisabled: TestCaseUtils.isRetryButtonDisabled,
                        isWsRunning,
                        paramTestCaseId,
                        saveBtnPressLoader,
                        selectedSteps,
                        testSteps: checkObject(testSteps),
                        wsRunningCaseData,
                    },
                });
            }
            console.log(`%c=============${buttonName} button's properties end=============`, 'color: #08E8DE;');
        } else {
            console.log('PLEASE PASS A VALID BUTTON NAME.');
            console.log('PASS ONE OF THESE NAMES');
            console.log('"run", "save", "stop"');
        }
    };

    testingFunction_getTestSteps = () => {
        console.log(this.props.selectedTestCase.testSteps);
    };

    testingFunction_getSingleTestCase = () => {
        console.log(this.props.selectedTestCase);
    };
    /* End functioin to log properties */

    checkRetryStuts = () => {
        const { dontAllowRetry } = this.state;
        dontAllowRetry[paramTestCaseId] = true;
        this.setState({ dontAllowRetry });
    };

    unCheckRetryStatus = () => {
        const { dontAllowRetry } = this.state;
        delete dontAllowRetry[paramTestCaseId];
        this.setState({ dontAllowRetry });
    };

    changeCacheXpath = () => {
        this.setState({ cacheXpaths: !this.state.cacheXpaths });
    };

    changeDebugStepsState = (testCaseId, flag, onlyState = false, disableDebug = false) => {
        const { debugPointList, testSteps, updatedebugPointList, updateTestStepsObject } = this.props;
        this.setState({ debugStepsState: flag, disablDebugStepsState: disableDebug });

        if (checkObject(testSteps) && !onlyState) {
            if (flag) {
                const debugInstrArray = [];
                Object.keys(testSteps).forEach((instrNum) => {
                    testSteps[instrNum].debug = true;
                    debugInstrArray.push(instrNum);
                });
                debugPointList[testCaseId] = debugInstrArray;
            } else {
                Object.keys(testSteps).forEach((instrNum) => {
                    delete testSteps[instrNum].debug;
                });
                delete debugPointList[testCaseId];
            }
            updateTestStepsObject(testSteps);
            updatedebugPointList(debugPointList);
        }
    };

    downloadLogsFromTestSteps = async () => {
        const {
            paramProjectId,
            user: { userId },
            downloadLogs,
            selectedTestCase,
        } = this.props;
        const data = {
            projectId: paramProjectId,
            testCaseId: paramTestCaseId,
            userId,
            isEmail: 1,
        };
        const response = await downloadLogs(data);
        if (response) {
            singleFileCreationService.showFile(
                response,
                'download',
                ` ${selectedTestCase ? selectedTestCase.testCaseName : 'filename'}.zip`,
                'application/zip',
            );
        }
    };

    modalForUpdateTestCaseName = () => {
        const { selectedTestCase } = this.props;
        const { desc, testCaseName } = selectedTestCase;
        TestCaseUtils.modalForUpdateTestCaseName({
            testCase: selectedTestCase,
            from: 'stepEditor',
            content: <UpdateTestCaseHeaderTopDetailContent desc={desc} testCaseName={testCaseName} tab="stepEditor" />,
        });
    };

    callNextPrevCancel = () => {
        this.setState({ isConfirmNavigationModal: false, isNextPrev: '' });
    };

    callNextPrevSuccess = () => {
        const { headerMiddleActions, history, paramProjectId } = this.props;
        const { isNextPrev, nextTestCase, prevTestCase } = this.state;
        let testCaseId = -1;
        let showNextPrevCaseName = '';
        if (isNextPrev === 'prev') {
            testCaseId = prevTestCase.testCaseId;
            showNextPrevCaseName = prevTestCase.testCaseName;
        } else if (isNextPrev === 'next') {
            testCaseId = nextTestCase.testCaseId;
            showNextPrevCaseName = nextTestCase.testCaseName;
        }
        this.props.emptyUndoRedoArrays();
        this.setState({ isConfirmNavigationModal: false, showNextPrevCaseName }, () => {
            headerMiddleActions.onClick_nextPrev(isNextPrev, () => {
                history.replace(`/projects/${paramProjectId}/${testCaseId}`);
            });
        });
    };

    getExecutionScript = (executeScriptSystemId) => {
        this.setState({ executeScriptSystemId });
        this.handleClose();
    };

    replaceURL = (scriptId, modalName) => {

        if (scriptId && modalName) {
            this.props.history.replace(`/projects/${getParamValues(1)}/${getParamValues(2)}/${scriptId}/${modalName}`);
        } else {
            this.props.history.replace(`/projects/${getParamValues(1)}/${getParamValues(2)}`);
        }
    };

    handleExeScriptClose = () => {
        this.replaceURL();
        this.setState({ executeScriptSystemId: null, openGenerateModal: false });
    };

    toSendOriginalSteps = (platformDetails) => {
        const {dontAllowRetry} = this.state;
        const {headerMiddleActions, paramProjectId} = this.props;
        if (!checkKeyInObject(dontAllowRetry, paramTestCaseId)) {
            TestCaseUtils.sendOriginalSteps(paramProjectId, platformDetails ,headerMiddleActions.handleTabs(0));
            this.checkRetryStuts();
        }
    }

    renderActionMenuItems() {
        const { creationMode, /* headerMiddleActions, */ selectedTab, selectedTestCase, isTagModalOpen, classes, isWsRunning,
            autoScroll, headerMiddleActions, listView, toggleListView } = this.props;
        const { cacheXpaths, debugStepsState, disablDebugStepsState } = this.state;
        const { isAnyDownloadFileAvailable } = this;
        const items = [
            {
                action: () => {
                    selectedTab === 0 ? headerMiddleActions.handleTabs(1) : headerMiddleActions.handleTabs(0);
                },
                type: 'custom',
                key: selectedTab,
                icon: (
                    <ChromeReaderMode
                        className={`${classes.moreIcons} ${classes.displayButtonIconsSmall}`}
                        aria-label="chromeReaderModeIcon"
                        id="chromeReaderModeIcon"
                    />
                ),
                isDisabled: `${selectedTestCase.testCaseId}` === '-1',
                name: ['Current', 'Original Steps'],
            },
            {
                action: () => {
                    toggleListView(!listView);
                },
                type: 'custom',
                key: listView,
                icon: (
                    <img
                        src={ScreenView}
                        className={`${classes.moreIcons} ${classes.displayButtonIcons}`}
                        style={{ color: selectedTab === 1 ? '#C4C4C4' : '#5F7B96' }}
                        aria-label="artTrackIcon"
                        id="artTrackIcon"
                        alt=''
                    />
                ),
                isDisabled: selectedTab === 1, // Disable for Original steps
                name: ['Screen', 'Line View'],
            },
            {
                action: () => {
                    this.props.toggleAutoScroll();
                },
                type: 'customToggle',
                key: autoScroll,
                icon: (
                    <img
                        src={ScrollIcon}
                        className={`${classes.moreIcons} ${classes.displayButtonIcons}`}
                        aria-label="playForWorkIcon"
                        id="playForWorkIcon"
                        alt=''
                    />
                ),
                isDisabled: false,
                name: 'Auto Scroll',
            },
            {
                action: () => {
                    this.props.setAllCacheXpaths(!cacheXpaths);
                    this.changeCacheXpath();
                },
                type: 'customToggle',
                key: cacheXpaths,
                icon: (
                    <img
                        src={CacheIcon}
                        className={classes.moreIcons}
                        style={{
                            height: 18,
                            width: 20,
                        }}
                        aria-label="storageIcon"
                        id="storageIcon"
                        alt=''
                    />
                ),
                isDisabled: `${selectedTab}` === '1' || (checkObject(isWsRunning) && isWsRunning[paramTestCaseId]) || `${paramTestCaseId}` === '-1',
                name: 'Cache Steps',
            },
            {
                action: () => {
                    const eventName = SEGMENT_EVENT.CREATION_MODE
                    track(eventName,{testcase_name:selectedTestCase.testCaseName,testcase_id:selectedTestCase.testCaseId,sataus:disablDebugStepsState ? 'Off':'On'})
                    this.props.toggleCreationMode();
                    this.changeDebugStepsState(paramTestCaseId, false, false, !disablDebugStepsState);
                },
                type: 'customToggle',
                key: creationMode,
                icon: (
                    <img
                        src={CreationIcon}
                        className={`${classes.moreIcons} ${classes.displayButtonIcons}`} aria-label="playForWorkIcon" id="playForWorkIcon" alt=''
                    />
                ),
                isDisabled: this.disabledRunButton,
                name: 'Creation Mode',
            },
            {
                action: () => {
                    this.changeDebugStepsState(paramTestCaseId, false);
                },
                key: debugStepsState,
                icon: <img src={RemoveIcon} className={`${classes.moreIcons} ${classes.displayButtonIcons18}`} aria-label="debugIcon" id="debugIcon" alt='' />,
                isDisabled: this.disabledRunButton || disablDebugStepsState,
                name: 'Remove All Debug Steps',
            },
            {
                action: () => {
                    this.downloadLogsFromTestSteps();
                },
                icon: (
                    <img
                        src={DownloadLogs}
                        className={`${classes.moreIcons} ${classes.displayButtonIcons18}`}
                        aria-label="downloadIcon"
                        id="downloadIcon"
                        alt=''
                    />
                ),
                isDisabled: false,
                name: 'Download Logs',
            },
            {
                action: () => {
                    TestCaseUtils.downloadFileZip(selectedTestCase.testCaseName, selectedTestCase.testCaseId);
                },
                icon: (
                    <img
                        src={DownloadedFile}
                        className={`${classes.moreIcons} ${classes.displayButtonIcons18}`}
                        aria-label="downloadIcon"
                        id="downloadIcon"
                        alt=''
                    />
                ),
                isDisabled: !isAnyDownloadFileAvailable,
                name: 'Get Downloaded Files',
            },
        ];
        return items;
    }

    segmentTrack = (selectedTestCase) => {
        const eventName =  SEGMENT_EVENT.TEST_CASE_EXECUTION_INITIATED


        if(eventName) {
            const { testCaseName ,testCaseId,projectName} = selectedTestCase

            const segmentData = {
                page:'Step Editor',
                testcase_name : testCaseName,
                testcase_id: testCaseId,
                project_name : projectName
            }
           
            track(eventName,segmentData)

        }
    }
    render() {
        const {
            actionType,
            classes,
            emptySelectedSteps,
            headerMiddleActions,
            isWsRunning,
            newStepIndex,
            redoData,
            selectedTab,
            isSaveRequestPending,
            saveBtnPressLoader,
            stopRetryFlag,
            selectedTestCase,
            selectedSteps,
            toggleStopIcon,
            undoData,
            wsRunningCaseData,
            paramProjectId,
            isLoadingCaseNameUpdate,
            isTagModalOpen,
            selectedProject,
            testCaseStatus,
            instrNumArray,
            stepsType,
            showLoaderForGenerate,
            setShowLoaderForGenerate,
            shadowDom
        } = this.props;
        const {
            dontAllowRetry,
            isConfirmNavigationModal,
            sendEmailModal,
            isShowMoreBtn,
            showFull,
            showNextPrevCaseName,
            executeScriptSystemId,
            openGenerateModal
        } = this.state;

        const testCaseStatusFromFunction = getTestCaseStatus(selectedTestCase, wsRunningCaseData);
        
        let selectedDetails = checkKeyInObject(selectedTestCase, 'testScripts');

        selectedDetails = checkArrayLength(selectedDetails) ? selectedDetails[0] : selectedDetails;

        const activeExecuteScript = checkKeyInObject(executeScriptSystemId, 'testScriptid') ? executeScriptSystemId : null;

        const scripts = [];
        // let testCaseId = null;
        if (activeExecuteScript) {
            scripts.push(activeExecuteScript.testScriptid);
            // testCaseId = activeExecuteScript.testCaseId;
        } else {
            scripts.length = 0;
        }

        const __isMsgType13Received = checkKeyInObject(wsRunningCaseData, paramTestCaseId)
            ? wsRunningCaseData[paramTestCaseId].isMsgType13Received
            : false; // temp for debugPoint
        const __isMsgType16Received = checkKeyInObject(wsRunningCaseData, paramTestCaseId)
            ? wsRunningCaseData[paramTestCaseId].isMsgType16Received
            : false; // temp for debugPoint
        const forceDisableRetryNSave =
            checkKeyInObject(wsRunningCaseData, paramTestCaseId) && // case is between msgType 2 and 8
            !wsRunningCaseData[paramTestCaseId].isMsgType2Sent;
        const isRetryButtonDisabledForTestCase = TestCaseUtils.isRetryButtonDisabled[paramTestCaseId];
        const isAnyActionInMiddle = actionType === 'add' || actionType === 'edit' || actionType === 'delete' || checkArrayLength(selectedSteps);

        const shouldGenerateDisable = (`${selectedTab}` === '1'
        ? isRetryButtonDisabledForTestCase ||
        checkKeyInObject(dontAllowRetry, paramTestCaseId) ||
        testCaseStatusFromFunction.code === 'in progress' ||
        !this.checkRetryOriginalIcon()
        : this.disabledRunButton);

        const execute_button_classname =  (testCaseStatus === 0 || testCaseStatus === -2) ? classes.disable_executeBtn : classes.executeBtn;
        return (
            <div className={classes.headerMiddleRoot}>
                <div className={classes.headerMiddleLeftContainer}>
                    {!isLoadingCaseNameUpdate ? (
                        <div className={classes.caseDetailContainer}>
                            <div className={classes.descContainer} style={{ marginTop: isShowMoreBtn ? 5 : 0 }}>
                                <div style={{ maxWidth: '100%', display: 'flex', alignItems: 'center' }}>
                                    <p className={classes.name}>
                                        <Tooltip
                                            data={checkKeyInObject(
                                                selectedTestCase,
                                                'testCaseName',
                                                'value',
                                                showNextPrevCaseName || 'New TestCase Name',
                                            )}
                                        >
                                            {checkKeyInObject(selectedTestCase, 'testCaseName', 'value', showNextPrevCaseName || 'New TestCase Name')}
                                        </Tooltip>
                                    </p>
                                    <Tooltip data="Click to Change Name & Description">

                                        <EditOutlinedIcon
                                            alt="Edit"
                                            aria-label="editIcon"
                                            id="editIcon"
                                            onClick={() => this.modalForUpdateTestCaseName()}
                                            style={{
                                                marginLeft: '10px',
                                                color: '#5F7B96',
                                                cursor: 'pointer',
                                                fontSize: '22px',
                                                height: '1rem',
                                                width: '1rem',
                                            }}
                                        />
                                    </Tooltip>
                                </div>

                                <div className={classes.displayTimer}>
                                    {stepsType === 'live' && selectedProject.showTimer ? <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem', whiteSpace: 'nowrap' }}>
                                        <AccessTimeIcon className={classes.clockIcon} />
                                        <Typography className={classes.stepTimerText}> Time elapsed </Typography>
                                        <TestCaseTimer calledFrom="TestSteps" testCaseId={getParamValues(2)} />
                                    </div> : null
                                    }
                                    <div style={{ display: 'flex', marginLeft: '1rem', whiteSpace: 'nowrap' }}>
                                        <Typography className={classes.stepTimerText}>  Selected Steps </Typography>
                                        <span className={classes.avatar}>{checkArrayLength(selectedSteps) ? selectedSteps.length : 0}</span>
                                    </div>
                                    <div style={{ display: 'flex', marginLeft: '1rem', whiteSpace: 'nowrap' }}>
                                        <Typography className={classes.stepTimerText}> Total Steps </Typography>
                                        <span className={classes.avatar}>{checkArrayLength(instrNumArray) ? instrNumArray.length : 0}</span>
                                    </div>
                                    {
                                        shadowDom &&
                                        <div style={{ display: 'flex', marginLeft: '1rem', whiteSpace: 'nowrap' }}>
                                            <Typography className={classes.stepTimerText}> {shadowDom ? 'Shadow DOM enabled':''} </Typography>
                                        </div>
                                    }

                                </div>
                            </div>
                            <div ref={this.textCont} className={classes.descContainer}>
                                <div ref={this.textDiv} style={{ maxWidth: '100%', display: 'flex' }}>
                                    <p className={classes.desc}>
                                        <Tooltip data={selectedTestCase.desc}>
                                            {checkKeyInObject(selectedTestCase, 'desc') ? selectedTestCase.desc : 'Case Description'}
                                        </Tooltip>
                                    </p>
                                    {isShowMoreBtn ? (
                                        <Tooltip data="Show more">
                                            <IconButton
                                                style={{
                                                    height: 'auto',
                                                    width: 'auto',
                                                    marginLeft: '10px',
                                                    marginBottom: 5,
                                                }}
                                                onClick={() => {
                                                    this.setState({ showFull: true });
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faEye}
                                                    aria-label="faEye"
                                                    id="faEye"
                                                    style={{ color: '#3B91DF', fontSize: 16 }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    ) : null}
                                    {showFull ? (
                                        <AlertModal
                                            open={showFull}
                                            isOnlyShow
                                            heading="Description"
                                            confirmButtonText="Close"
                                            onClickAlertDialogClose={() => {
                                                this.setState({ showFull: false });
                                            }}
                                            msg={checkKeyInObject(selectedTestCase, 'desc') ? selectedTestCase.desc : 'Case Description'}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.caseDetailContainerLoading}>
                            <div className={classes.loaderWrapper}>
                                <ContentLoader height={10} width={150} />
                            </div>
                            <ContentLoader height={10} width={150} />
                        </div>
                    )}
                    {/* <div className={classes.nexPrevWrapper}>
                        {prevTestCase && prevTestCase !== true && prevTestCase.testCaseId !== -1 && !isNextPrev ? (
                            <Tooltip data={prevTestCase.testCaseName}>
                                <Button
                                    className={classes.navigationFor}
                                    onClick={() => {
                                        if (checkArrayLength(undoData)) {
                                            this.setState({ isConfirmNavigationModal: true, isNextPrev: 'prev' });
                                        } else {
                                            this.setState({ isNextPrev: 'prev' }, () => {
                                                this.callNextPrevSuccess();
                                            });
                                        }
                                    }}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginRight: '8px' }}
                                >
                                    <LeftArrow style={{ fontSize: '20px', marginLeft: '4px' }} aria-label="leftArrowIcon" id="leftArrowIcon" />
                                    <Typography style={{ color: '#fff' }}>Prev</Typography>
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button
                                className={classes.navigationForDisabled}
                                disabled
                                style={isNextPrev === 'prev' ? { justifyContent: 'center', marginRight: '8px' } : { marginRight: '8px' }}
                            >
                                {isNextPrev === 'prev' ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    [
                                        <LeftArrow key="1" style={{ fontSize: '20px', marginLeft: '4px', color: '#fff' }} />,
                                        <Typography key="2" style={{ color: '#fff' }}>
                                            Prev
                                        </Typography>,
                                    ]
                                )}
                            </Button>
                        )}
                        {nextTestCase && nextTestCase !== true && !isNextPrev ? (
                            <Tooltip data={nextTestCase.testCaseName}>
                                <Button
                                    className={classes.navigationBack}
                                    onClick={() => {
                                        if (checkArrayLength(undoData)) {
                                            this.setState({ isConfirmNavigationModal: true, isNextPrev: 'next' });
                                        } else {
                                            this.setState({ isNextPrev: 'next' }, () => {
                                                this.callNextPrevSuccess();
                                            });
                                        }
                                    }}
                                    variant="contained"
                                    color="primary"
                                >
                                    <Typography style={{ color: '#fff' }}>Next</Typography>
                                    <RightArrow style={{ fontSize: '20px', marginRight: '4px' }} aria-label="rightArrowIcon" id="rightArrowIcon" />
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button
                                className={classes.navigationBackDisabled}
                                disabled
                                style={isNextPrev === 'next' ? { justifyContent: 'center' } : {}}
                            >
                                {isNextPrev === 'next' ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    [
                                        <Typography key="1" style={{ color: '#fff' }}>
                                            Next
                                        </Typography>,
                                        <RightArrow key="2" style={{ fontSize: '20px', marginRight: '4px', color: '#fff' }} />,
                                    ]
                                )}
                            </Button>
                        )}
                    </div> */}
                    <div className={classes.iconsContainer}>
                    <Button
                    disabled={(__isMsgType13Received ||
                        __isMsgType16Received ||
                        newStepIndex !== -1 ||
                        isSaveRequestPending ||
                        saveBtnPressLoader ||
                        checkKeyInObject(dontAllowRetry, paramTestCaseId) ||
                        isRetryButtonDisabledForTestCase ||
                        forceDisableRetryNSave ||
                        (
                            !undoData.length ||
                            isAnyActionInMiddle &&
                            `${selectedTab}` === '1'
                        )) || (actionType === 'add' ||
                        actionType === 'edit')}
                        onClick={() => {
                            if (newStepIndex === -1) { // only if no add step field is in action
                                // Do nothing if testCaseId is -1 as button is disabled
                                if (`${paramTestCaseId}` === '-1' && !isAnyActionInMiddle) {
                                    TestCaseUtils.saveNewTestCase({ paramTestCaseId, paramProjectId, desc: selectedTestCase.desc, testCaseName: selectedTestCase.testCaseName }, true);
                                } else {
                                    !__isMsgType13Received && !__isMsgType13Received && !isSaveRequestPending && !forceDisableRetryNSave && !checkKeyInObject(dontAllowRetry, paramTestCaseId) && !isRetryButtonDisabledForTestCase ? (TestCaseUtils.saveTestSteps(paramTestCaseId, paramProjectId)) : {};
                                }
                                !checkKeyInObject(dontAllowRetry, paramTestCaseId) && !isRetryButtonDisabledForTestCase ? emptySelectedSteps() : {};
                            }
                        }}
                    
                    className={`${classes.actionBtn} ${classes.saveBtn}`}>
                        <span style={{textTransform:'none'}}>Save</span>
                    </Button>


                    <Button disabled={shouldGenerateDisable} className={shouldGenerateDisable? `${classes.actionBtn} ${classes.generateDisabled}`:`${classes.actionBtn} ${classes.generateBtn}`}
                               
                                onClick={() => {
                                    this.setState({callingFrom: 'Generate', openGenerateModal: true})
                                    setShowLoaderForGenerate({[selectedTestCase.testCaseId] : true})
                                    if (toggleStopIcon) {
                                        toggleStopIcon(false);
                                    }
                                    this.props.queryRemove();
                                
                                    if (`${paramTestCaseId}` === '-1') {
                                        TestCaseUtils.saveNewTestCase(
                                            { paramTestCaseId, paramProjectId, desc: selectedTestCase.desc, testCaseName: selectedTestCase.testCaseName },
                                            false,
                                            this.unCheckRetryStatus,
                                            // showMessage parameter to prevent calling updateSteps api before clicking on generate button
                                            true
                                        );
                                        this.checkRetryStuts();
                                    } else if (!this.disabledRunButton) {
                                        TestCaseUtils.saveTestSteps(paramTestCaseId, paramProjectId, true)
                                        // TestCaseUtils.runTestSteps(
                                        //     { paramTestCaseId, projectId: paramProjectId },
                                        //     '2',
                                        //     () => { },
                                        //     this.unCheckRetryStatus,
                                        // );
                                        // this.checkRetryStuts();
                                    }

                                    if (!checkKeyInObject(dontAllowRetry, paramTestCaseId) && !isRetryButtonDisabledForTestCase) {
                                        emptySelectedSteps();
                                    }
                                }}    
                                >
                                <img  src= {shouldGenerateDisable ? generate_icon_grey:generate_icon}/>                                     
                        <span style={{textTransform:'none',marginLeft:'4px'}}>Generate</span>
                    </Button>

                    <Button onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({ callingFrom: 'Execute' })
                                    this.replaceURL(selectedDetails.testScriptid, 'execute');
                                    this.getExecutionScript(selectedDetails);
                                    this.segmentTrack(selectedTestCase)
                                }}
                                disabled={testCaseStatus === 0 || testCaseStatus === -2} 
                                className={`${classes.actionBtn} ${execute_button_classname}`}>
                        <img src= {execute_icon}/>
                        <span style={{textTransform:'none',marginLeft:'4px'}}>Execute</span>
                    </Button>

                        {openGenerateModal || activeExecuteScript ? (
                            <ExecuteScriptModal
                                scriptIds={scripts}
                                projectId={activeExecuteScript?.projectId}
                                name={checkKeyInObject(selectedTestCase, 'testCaseName', 'value')}
                                type="script"
                                callingFrom={this.state.callingFrom}
                                modalState
                                testCase={this.props.selectedTestCase}
                                handleClose={this.handleExeScriptClose}
                                testCaseId={this.props.selectedTestCase.testCaseId}
                                aiqExecution={selectedProject.aiqExecution}
                                isGenFromOriginalSteps={selectedTab === 1}
                                sendOriginalSteps={(platformDetails) => this.toSendOriginalSteps(platformDetails)}
                            />
                        ) : null}

                    </div>
                </div>
                <div className={classes.midHeader}>
                    <HeaderBottom />
                    <div className={classes.ActionHeader}>
                        <Typography className={classes.headerTitle}>
                            ACTION
                        </Typography>
                    </div>
                    <div className={classes.ActionHeader}>
                        <Typography className={classes.headerTitle}>
                            DATA
                        </Typography>
                        <Tooltip 
                        paperBody={classes.paperBody}
                        tootTipLabel={classes.tootTipLabel}
                        data="The user data needed to complete the test step.">
                            <Help
                                className={`${classes.moreIcons} ${classes.displayButtonIcons}`}
                                aria-label="helpIcon"
                                id="helpIcon"
                            />
                        </Tooltip>
                    </div>
                    <div className={classes.ActionHeader}>
                        <Typography className={classes.headerTitle}>
                            EXPECTED RESULT
                        </Typography>
                    </div>
                    <div className={classes.groupButton}>
                        
                        <Tooltip data="Stop">
                            <IconButton disabled={(!checkKeyInObject(wsRunningCaseData[paramTestCaseId], 'sessionId', 'bool') ||
                                ((!isWsRunning[paramTestCaseId] || stopRetryFlag || `${selectedTab}` === '1') &&
                                    !(
                                        wsRunningCaseData[paramTestCaseId] &&
                                        (checkKeyInObject(wsRunningCaseData[paramTestCaseId], 'isMsgType13Received') ||
                                            checkKeyInObject(wsRunningCaseData[paramTestCaseId], 'isMsgType16Received'))
                                    )))}
                                onClick={() => {
                                    TestCaseUtils.sendStopMsg(paramProjectId, paramTestCaseId);
                                    emptySelectedSteps();
                                    toggleStopIcon(true);
                                    this.unCheckRetryStatus();
                                }}
                                className={classes.mr10}>
                                <HighlightOffRoundedIcon

                                    style={{
                                        cursor: 'pointer', color: (!checkKeyInObject(wsRunningCaseData[paramTestCaseId], 'sessionId', 'bool') ||
                                            ((!isWsRunning[paramTestCaseId] || stopRetryFlag || `${selectedTab}` === '1') &&
                                                !(
                                                    wsRunningCaseData[paramTestCaseId] &&
                                                    (checkKeyInObject(wsRunningCaseData[paramTestCaseId], 'isMsgType13Received') ||
                                                        checkKeyInObject(wsRunningCaseData[paramTestCaseId], 'isMsgType16Received'))
                                                ))) ? '#C4C4C4' : '#5F7B96'
                                    }}


                                />

                            </IconButton>
                        </Tooltip>



                        <Tooltip data="Undo">
                            <IconButton disabled={(newStepIndex !== -1 ||
                                isSaveRequestPending ||
                                saveBtnPressLoader ||
                                !undoData.length ||
                                `${selectedTab}` === '1' ||
                                isAnyActionInMiddle)}
                                onClick={
                                    newStepIndex === -1 && checkArrayLength(undoData)
                                        ? () => {
                                            this.props.undo();
                                            this.props.setActionType('undo');
                                        }
                                        : null
                                }
                                className={classes.mr10}>
                                <UndoRoundedIcon

                                    style={{
                                        fontSize: '22px', cursor: 'pointer', color: (newStepIndex !== -1 ||
                                            isSaveRequestPending ||
                                            saveBtnPressLoader ||
                                            !undoData.length ||
                                            `${selectedTab}` === '1' ||
                                            isAnyActionInMiddle) ? '#C4C4C4' : '#5F7B96'
                                    }}

                                />
                            </IconButton>
                        </Tooltip>



                        <Tooltip data="Redo">
                            <IconButton disabled={(newStepIndex !== -1 || !redoData.length ||
                                `${selectedTab}` === '1' || isAnyActionInMiddle)}
                                onClick={
                                    newStepIndex === -1 && checkArrayLength(redoData)
                                        ? () => {
                                            this.props.redo();
                                            this.props.setActionType('redo');
                                        }
                                        : null
                                }
                                className={classes.mr10}>
                                <RedoRoundedIcon
                                    style={{
                                        fontSize: '22px', cursor: 'pointer', color: (newStepIndex !== -1 || !redoData.length ||
                                            `${selectedTab}` === '1' || isAnyActionInMiddle) ? '#C4C4C4' : '#5F7B96'
                                    }}
                                />
                            </IconButton>
                        </Tooltip>





                         <IconButton disabled={(`${paramTestCaseId}` === '-1' || !checkArrayLength(selectedTestCase.recoverTestSteps)
                            || checkKeyInObject(wsRunningCaseData, `${paramTestCaseId}`, 'bool'))}
                            className={classes.mr10}>
                            <CustomDownloadModal
                                handleSubmit={(downloadType) => {
                                    TestCaseUtils.handleDownloadTestCase(`${selectedTab}` === '0' ? 'Live Steps' : 'Original Steps', downloadType, () => {
                                        this.handleDownloadPopupToggle(false);
                                    });
                                }}
                                keepMounted
                                containerStyle={{ height: 'unset' }}
                                btn={
                                    <Tooltip data="Download TestSteps">
                                        <IconButton>
                                            <DescriptionOutlinedIcon
                                                style={{
                                                    fontSize: '22px', cursor: 'pointer', color: (`${paramTestCaseId}` === '-1' || !checkArrayLength(selectedTestCase.recoverTestSteps)
                                                        || checkKeyInObject(wsRunningCaseData, `${paramTestCaseId}`, 'bool')) ? '#C4C4C4' : '#5F7B96'
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                }
                            />
                        </IconButton>


                        {/* icon added */}
                        {/* <Stoppedicon /> */}

                        <TestStepConfigurationMenu dontAllowRetry={this.state.dontAllowRetry} openGenerateModal ={openGenerateModal} selectedTab = {selectedTab} headerMiddleActions={headerMiddleActions}/>
{/* 
                        <MenuOptions
                            items={this.renderActionMenuItems()}
                            keepMounted
                            btn={
                                <Tooltip data="More Options">
                                    <IconButton>
                                        <MoreHorizOutlinedIcon
                                            style={{
                                                color: '#5F7B96', cursor: 'pointer'
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            }
                        /> */}

                    </div>


                    {sendEmailModal ? (
                        <SendLog
                            sendEmailModal={sendEmailModal}
                            testCase={selectedTestCase}
                            onCompelete={() => {
                                this.setState({ sendEmailModal: false });
                            }}
                        />
                    ) : null}
                    {isConfirmNavigationModal && (
                        <ConfirmNavigationModal
                            onConfirm={this.callNextPrevSuccess}
                            onCancel={this.callNextPrevCancel}
                            msg="Your recent changes to the test steps are not saved. Are you sure you want to leave before saving?"
                            leaveBtn="Leave this page"
                            stayBtn="Stay on the page"
                        />
                    )}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        // auth reducer
        user: state.authReducer.user,
        // project reducer
        shiftToLive: state.projectReducer.shiftToLive,
        testCases: state.projectReducer.testCases,
        isSaveRequestPending: state.testStepReducer.isLoading,
        isWsRunning: state.projectReducer.isWsRunning,
        selectedTestCase: state.projectReducer.selectedTestCase,
        wsRunningCaseData: state.projectReducer.wsRunningCaseData,
        wsKeys: state.projectReducer.wsKeys,
        isLoadingCaseNameUpdate: state.projectReducer.isLoadingCaseNameUpdate,
        // selectedTestCaseReducer
        actionType: state.selectedTestCaseReducer.actionType,
        autoScroll: state.selectedTestCaseReducer.autoScroll,
        creationMode: state.selectedTestCaseReducer.creationMode,
        newStepIndex: state.selectedTestCaseReducer.newStepIndex,
        listView: state.selectedTestCaseReducer.listView,
        redoData: state.selectedTestCaseReducer.redoData,
        selectedSteps: state.selectedTestCaseReducer.selectedSteps,
        stopRetryFlag: state.selectedTestCaseReducer.stopRetryFlag,
        testSteps: state.selectedTestCaseReducer.testSteps,
        instrNumArray: state.selectedTestCaseReducer.instrNumArray,
        undoData: state.selectedTestCaseReducer.undoData,
        saveBtnPressLoader: state.selectedTestCaseReducer.saveBtnPressLoader,
        testCaseStatus: state.selectedTestCaseReducer.testCaseStatus,
        stepsType: state.selectedTestCaseReducer.stepsType,
        // test case reducer
        isTagModalOpen: state.testCaseReducer.isTagModalOpen,
        caseOrder: state.testCaseReducer.caseOrder,
        caseOrderBy: state.testCaseReducer.caseOrderBy,
        // projects Reducer
        debugPointList: state.projectsReducer.debugPointList,
        selectedProject: state.projectReducer.selectedProject,
        isGenerateBtnClicked: state.projectReducer.isGenerateBtnClicked,
        showLoaderForGenerate: state.projectReducer.showLoaderForGenerate,
        shadowDom:state.selectedTestCaseReducer.shadowDom,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        // Selected Test Case Action
        emptySelectedSteps: () => dispatch(SelectedTestCaseActions.emptySelectedSteps()),
        emptyUndoRedoArrays: (...args) => dispatch(SelectedTestCaseActions.emptyUndoRedoArrays(...args)),
        redo: (...args) => dispatch(SelectedTestCaseActions.redo(...args)),
        setActionType: (flag) => dispatch(SelectedTestCaseActions.setActionType(flag)),
        setAllCacheXpaths: (...args) => dispatch(SelectedTestCaseActions.setAllCacheXpaths(...args)),
        toggleStopIcon: (flag) => dispatch(SelectedTestCaseActions.toggleStopIcon(flag)),
        toggleAutoScroll: () => dispatch(SelectedTestCaseActions.toggleAutoScroll()),
        toggleCreationMode: () => dispatch(SelectedTestCaseActions.toggleCreationMode()),
        toggleListView: (...args) => dispatch(SelectedTestCaseActions.toggleListView(...args)),
        undo: (...args) => dispatch(SelectedTestCaseActions.undo(...args)),
        updateShowPasteButton: (...args) => dispatch(SelectedTestCaseActions.updateShowPasteButton(...args)),
        updateTestStepsObject: (...args) => dispatch(SelectedTestCaseActions.updateTestStepsObject(...args)),
        //  Project Action
        getTestCases: (...args) => dispatch(ProjectActions.getTestCases(...args)),
        //  testCase Action
        toggleTagModal: (...args) => dispatch(TestCaseActions.toggleTagModal(...args)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        //  testStep Action
        downloadLogs: (...args) => dispatch(TestStepActions.downloadLogs(...args)),
        //  projects Action
        updatedebugPointList: (...args) => dispatch(ProjectsActions.updatedebugPointList(...args)),
        setShowLoaderForGenerate: (data) => dispatch(ProjectActions.setShowLoaderForGenerate(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(HeaderMiddle));
