import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
    Typography,
    InputLabel,
    FormControl,
    Select,
    IconButton,
    Input,
    TextField,
    Grid,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '../Tooltip';
import Filedrop from '../Filedrop/Filedrop';
import { checkArrayLength, checkKeyInObject, checkObject } from '../../utils';
import FileList from '../FileList/FileList';
import { generalModalActions } from '../../store/actions';
import StepAndDataModal from '../../features/modal/UpdateProject/StepAndDataModal';
import projectMiddleware from '../../store/middleware/projectMiddleware';
import singleFileCreationService from '../../services/singleFileCreationService';
import ContentLoader from '../ContentLoader';

let isMounted = false;
const styles = (theme) => ({
    root: {
        padding: '0 30px',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 'calc(100vh - 320px)',
    },
    labelColor: {
        '&>label': {
            color: '#595959',
            fontSize: '12px',
            transform: 'translate(0, 1.5px)',
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    formControl: {
        width: '255px',
        flexDirection: 'row',
    },
    select: {
        width: '100%',
        fontSize: 12,
        color: '#595959',
    },
    font: {
        fontSize: 12,
        color: '#595959',
    },
    expansionPanelRoot: {
        width: 'calc(100% - 10px)',
        left: 10,
    },
    customMarginTop: {
        marginTop: '10px',
    },
    scrollView: {
        overflow: 'auto',
        maxHeight: 140,
        padding: 0,
    },
    listView: {
        width: '96%',
        padding: 0,
    },
    fileUploadContainer: {
        flexDirection: 'column',
    },
    step1upload: {
        marginTop: 15,
        padding: '0 30px',
        [theme.breakpoints.only('xs')]: {
            maxWidth: '77%',
            marginTop: '15px',
            marginLeft: '11%',
            marginBottom: '10px',
        },
    },
    errorMessage: {
        fontSize: 12,
        color: 'red',
        display: 'block',
    },
});

class ProjectDataSheetSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnCaseSteps: 1,
            columnCaseData: 2,
            expectedResult: 3,
            showModal: false,
            modalType: '',
            uploadedFiles: [],
            UploadError: false,
            expanded: 'accepted',
            isFirst: false,
            didChange: false,
            argument: '',
            stepAndDataArray: [],
            showError: true,
            isLoadingParser: props.isUpdateProject,
        };
    }

    componentDidMount() {
        const {
            modalData: { isUpdateProject, project },
            tabsData,
        } = this.props;
        let previousData = {};
        isMounted = true;
        let callBack = () => {};
        if (isUpdateProject || checkKeyInObject(tabsData.ProjectDateSetting, 'state', 'bool')) {
            if (checkKeyInObject(tabsData.ProjectDateSetting, 'state', 'bool')) {
                previousData = tabsData.ProjectDateSetting.state;
            } else if (
                isUpdateProject &&
                checkObject(project) &&
                checkKeyInObject(tabsData.projectInfo, 'state', 'bool') &&
                checkKeyInObject(tabsData.projectInfo.state, 'projectAdvanceValues', 'bool')
            ) {
                previousData = this.getDefaultValues(tabsData.projectInfo.state.projectAdvanceValues);
                callBack = this.getTestData;
            }
            this.setStateIfComponentMounted(
                {
                    ...previousData,
                    isFirst: true,
                    didChange: checkKeyInObject(previousData, 'didChange') ? previousData.didChange : false,
                },
                () => {
                    callBack();
                },
            );
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            modalData: { isUpdateProject, project },
            tabsData,
        } = this.props;
        if (
            isUpdateProject &&
            checkObject(project) &&
            !(
                checkKeyInObject(tabsData.projectInfo, 'state', 'bool') &&
                checkKeyInObject(tabsData.projectInfo.state, 'projectAdvanceValues', 'bool')
            ) &&
            checkKeyInObject(nextProps.tabsData.projectInfo, 'state', 'bool') &&
            checkKeyInObject(nextProps.tabsData.projectInfo.state, 'projectAdvanceValues', 'bool')
        ) {
            const previousData = this.getDefaultValues(nextProps.tabsData.projectInfo.state.projectAdvanceValues);
            this.setStateIfComponentMounted(
                {
                    ...previousData,
                    isFirst: true,
                    didChange: false,
                },
                () => {
                    this.getTestData();
                },
            );
        }
    }

    componentWillUnmount() {
        const { updateTabData } = this.props;
        isMounted = false;
        updateTabData('ProjectDateSetting', { state: { ...this.state } });
    }

    onFileDropCallback = ([...files]) => {
        const file = files[0];
        // Commented this to remove restrictions for file types
        // const fileExtension = `.${file.name.split('.').pop()}`;
        // if (!(
        //     this.checkFileExtension(fileExtension) &&
        //     this.checkFileType(file.type)
        // )) {
        //     this.setStateIfComponentMounted({
        //         UploadError: true
        //     })
        // }
        // else {
        // const { modalData: { project }, tabsData } = this.props;

        // const selectedApp = {
        //     value: checkKeyInObject(project, 'projectId', 'value', ''),
        //     label: checkObject(project) && checkKeyInObject(tabsData['projectInfo'], 'state', 'bool') && checkKeyInObject(tabsData['projectInfo'].state, 'projectName', 'bool') ? this.getDefaultValues(tabsData['projectInfo'].state.projectName) : '',
        // };
        this.setStateIfComponentMounted({
            UploadError: false,
        });

        // let filesArray = {
        //     systemId: selectedApp,
        //     projectId: checkKeyInObject(project, 'projectId', 'value', ''),
        //     accountId: this.props.user && this.props.user.accountId ? this.props.user.accountId : 1,
        // };
        this.setStateIfComponentMounted({
            uploadedFiles: [file],
            currentFiles: [files[0]],
        });
    };

    getDefaultValues = (projectAdvanceValues) => ({
        columnCaseSteps: projectAdvanceValues.stepsColNum ? projectAdvanceValues.stepsColNum : 1,
        columnCaseData: projectAdvanceValues.dataColNum ? projectAdvanceValues.dataColNum : 2,
        expectedResult: projectAdvanceValues.expectedResultsColNum ? projectAdvanceValues.expectedResultsColNum : 3,
        argument: projectAdvanceValues.customParserArgs ? projectAdvanceValues.customParserArgs : '',
    });

    getTestData = async () => {
        const {
            modalData: { project },
            testCases,
        } = this.props;
        let data = testCases;
        this.setStateIfComponentMounted({ isLoadingParser: true });
        if (!checkArrayLength(data)) {
            data = await projectMiddleware.getTestCases(checkKeyInObject(project, 'projectId', 'value', ''));
        }
        const getDataIfTestCase = data ? data.find((FindData) => FindData.testCaseLoc) : null;
        if (getDataIfTestCase) {
            const { testCaseLoc } = getDataIfTestCase;
            const getFileInfo = await singleFileCreationService.getFileInfo(testCaseLoc, false);
            const obj = {
                stepAndDataArray: getFileInfo,
                showError: false,
                isLoadingParser: false,
            };
            if (isMounted) {
                this.setStateIfComponentMounted(obj);
            } else {
                this.props.updateTabData('ProjectDateSetting', { state: { ...this.state, ...obj } });
            }
        } else {
            this.setStateIfComponentMounted({ showError: true, isLoadingParser: false });
        }
    };

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted)
            this.setState(
                (prevState) => ({ didChange: prevState.isFirst, ...obj }),
                () => {
                    callback();
                    this.props.updateTabData('ProjectDateSetting', { state: { ...this.state } });
                },
            );
    };

    getStep_data = (data) => {
        this.setStateIfComponentMounted({
            columnCaseSteps: Number(data.Step) + 1,
            columnCaseData: Number(data.Data) + 1,
            expectedResult: Number(data.Result) + 1,
        });
    };

    getNumMenuItems = (f = 0, t = 100 + 1, ignore = 0, type = null, ignore2 = null) => {
        const rows = [];
        for (let i = f; i <= t; i++) {
            if (i !== ignore && i !== ignore2) {
                rows.push(
                    <MenuItem style={{ fontSize: 12, color: '#595959' }} value={i} key={i}>
                        {type === 'step' ? 'Step ' : type === 'data' ? 'Data ' : type === 'result' ? 'Results ' : ''}
                        {i === 0 ? '0' : i}
                    </MenuItem>,
                );
            }
        }
        return rows;
    };

    selectHandler = (key, val) => {
        this.setStateIfComponentMounted({
            [key]: val,
        });
    };

    checkFileType = (type) => {
        const allowedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
        return allowedFileTypes.indexOf(type) > -1;
    };

    checkFileExtension = (ext) => {
        const allowedFileExtensions = ['.xls', '.xlsx', '.csv'];
        return allowedFileExtensions.indexOf(ext) > -1;
    };

    camelize = (value) => value.charAt(0).toUpperCase() + value.slice(1);

    expansionPanel = (files, panelName, step) => {
        const { classes } = this.props;
        const { expanded } = this.state;
        return (
            <Accordion
                className={classes.expansionPanelRoot}
                expanded={expanded === panelName && files.length > 0}
                onChange={this.handleExpansion(panelName)}
            >
                <AccordionSummary className={classes.customMarginTop} expandIcon={<ExpandMoreIcon />}>
                    <Typography>{`${this.camelize(panelName)} ${files.length} files`}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.scrollView}>
                    <List className={classes.listView}>
                        {files &&
                            files.map((file, index) => (
                                <FileList
                                    file={{ file }}
                                    key={index.toString()}
                                    index={index}
                                    listItemAction={this.listItemAction}
                                    step={step}
                                    removeFile={this.removeFile}
                                />
                            ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        );
    };

    removeFile = (/* step, ind */) => {
        this.setStateIfComponentMounted({
            currentFiles: [],
            uploadedFiles: [],
        });
    };

    handleExpansion = (panel) => (event, expanded) => {
        this.setStateIfComponentMounted({
            expanded: expanded ? panel : false,
        });
    };

    handleOpenModal = (type) => {
        if (!this.state.showError) {
            this.setStateIfComponentMounted({ showModal: true, modalType: type });
        }
    };

    handleFilterChange = (e) => {
        this.setStateIfComponentMounted({ [e.target.name]: e.target.value.trim() });
    };

    render() {
        const {
            classes,
            modalData: { isUpdateProject },
            tabsData,
        } = this.props;
        const {
            columnCaseSteps,
            columnCaseData,
            expectedResult,
            stepAndDataArray,
            showError,
            argument,
            uploadedFiles,
            UploadError,
            modalType,
            isLoadingParser,
        } = this.state;
        const dataList = (ignore = 1, type = null, ignore2 = null) => {
            return isUpdateProject && checkArrayLength(stepAndDataArray) && checkArrayLength(stepAndDataArray[0])
                ? this.getNumMenuItems(1, 100, ignore, type, ignore2)
                : this.getNumMenuItems(1, 100, ignore, type, ignore2);
        };


        let errorMessage = null;
        if (
            checkKeyInObject(tabsData, 'projectInfo.state.duplicateName', 'value', false) ||
            checkKeyInObject(tabsData, 'projectInfo.state.isSubmitDisabled', 'value', false)
        ) {
            errorMessage = (
                <div>
                    <span className={classes.errorMessage}>{checkKeyInObject(tabsData, 'projectInfo.state.error', 'value', '')}</span>
                </div>
            );
        }

        return (
            <div className={classes.root}>
                {errorMessage}
                <div style={{ width: '100%' }}>
                    <Typography style={{ fontSize: 12, fontWeight: 'bold', color: '#595959', marginTop: '8px', width: '100%' }}>
                        Custom Parser Settings for Test Case
                    </Typography>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div>
                            <div className={classes.labelColor} style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingTop: 10 }}>
                                <InputLabel
                                    style={{
                                        marginBottom: '9.7px',
                                    }}
                                    shrink
                                    htmlFor="StepsNumber-label-placeholder"
                                >
                                    Column Number
                                </InputLabel>
                                <div className={classes.formControl}>
                                    <FormControl
                                        style={{
                                            width: 'auto',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {isLoadingParser ? (
                                            <ContentLoader height={28} width={70} />
                                        ) : (
                                            <>
                                                <Select
                                                    value={columnCaseSteps}
                                                    onChange={(event) => {
                                                        this.selectHandler('columnCaseSteps', event.target.value);
                                                    }}
                                                    input={<Input name="StepsNumber" id="StepsNumber-label-placeholder" />}
                                                    /*displayEmpty*/
                                                    name="Steps Number"
                                                    className={classes.select}
                                                >
                                                    {dataList(columnCaseData, 'step', expectedResult)}
                                                </Select>
                                                {isUpdateProject && (
                                                    <Tooltip data="Show">
                                                        <IconButton
                                                            onClick={() => this.handleOpenModal('testStep')}
                                                            disabled={showError}
                                                            style={{ width: '16px', height: '16px' }}
                                                        >
                                                            <FontAwesomeIcon style={{ color: '#3B91DF', fontSize: '14px' }} icon={faEye} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        style={{
                                            width: 'auto',
                                            marginRight: '10px',
                                        }}
                                    >
                                        {isLoadingParser ? (
                                            <ContentLoader height={28} width={70} />
                                        ) : (
                                            <>
                                                <Select
                                                    value={columnCaseData}
                                                    onChange={(event) => {
                                                        this.selectHandler('columnCaseData', event.target.value);
                                                    }}
                                                    input={<Input name="DataNumber" id="DataNumber-label-placeholder" />}
                                                    /*displayEmpty*/
                                                    name="Data Number"
                                                    className={classes.select}
                                                >
                                                    {dataList(columnCaseSteps, 'data', expectedResult)}
                                                </Select>
                                                {isUpdateProject && (
                                                    <Tooltip data="Show">
                                                        <IconButton
                                                            onClick={() => this.handleOpenModal('testData')}
                                                            disabled={showError}
                                                            style={{ width: '16px', height: '16px' }}
                                                        >
                                                            <FontAwesomeIcon style={{ color: '#3B91DF', fontSize: '14px' }} icon={faEye} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        style={{
                                            width: 'auto',
                                        }}
                                    >
                                        {isLoadingParser ? (
                                            <ContentLoader height={28} width={70} />
                                        ) : (
                                            <>
                                                <Select
                                                    value={expectedResult}
                                                    onChange={(event) => {
                                                        this.selectHandler('expectedResult', event.target.value);
                                                    }}
                                                    input={<Input name="expectedResult" id="expectedResult-label-placeholder" />}
                                                    /*displayEmpty*/
                                                    name="Expected Results"
                                                    className={classes.select}
                                                >
                                                    {dataList(columnCaseData, 'result', columnCaseSteps)}
                                                </Select>
                                                {isUpdateProject && (
                                                    <Tooltip data="Show">
                                                        <IconButton
                                                            onClick={() => this.handleOpenModal('testResult')}
                                                            disabled={showError}
                                                            style={{ width: '16px', height: '16px' }}
                                                        >
                                                            <FontAwesomeIcon style={{ color: '#3B91DF', fontSize: '14px' }} icon={faEye} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: 'calc(45% - 15px)', marginLeft: 15 }}>
                            {checkArrayLength(uploadedFiles) && (
                                <TextField
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        classes: {
                                            input: classes.font,
                                        },
                                    }}
                                    id="projectArgument"
                                    label="Arguments"
                                    type="text"
                                    fullWidth
                                    name="argument"
                                    className={classes.labelColor}
                                    value={argument}
                                    onChange={this.handleFilterChange}
                                    style={{ margin: 0, marginTop: 10.5 }}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <Grid container spacing={8} className={classes.fileUploadContainer} classes={{ container: classes.step1upload }}>
                    {!checkArrayLength(uploadedFiles) ? (
                        <Filedrop
                            config={{
                                dropCallback: this.onFileDropCallback,
                                text: <span>Select Parser File</span>,
                            }}
                        />
                    ) : (
                        this.expansionPanel(uploadedFiles, 'accepted', 'datafile')
                    )}
                    {UploadError ? (
                        <Typography className={classes.errorMessage}>Only xls, xlsx, or csv file formats are acceptable</Typography>
                    ) : null}
                </Grid>
                <StepAndDataModal
                    Steps={this.state.columnCaseSteps}
                    ColumnData={this.state.columnCaseData}
                    ExpectedResult={this.state.expectedResult}
                    data={this.state.stepAndDataArray}
                    getStep_data={(d) => this.getStep_data(d)}
                    open={this.state.showModal}
                    handleClose={() => {
                        this.setStateIfComponentMounted({ showModal: false });
                    }}
                    modalType={modalType}
                    resetValues={() => this.setStateIfComponentMounted({ columnCaseSteps: 1, columnCaseData: 1, expectedResult: 1 })}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        tabsData: state.generalModalReducer.tabsData,
        testCases: state.projectReducer.testCases,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectDataSheetSettings));
