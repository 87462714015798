import { ActionTypes } from '../constants';
import { checkObject, checkArrayLength, checkKeyInObject, addUserPreference } from '../../utils/utils';
import { TestStepUtils } from '../../utils/TestStepUtils';
import _ from 'lodash';

const initialState = {
    actionType: '', // Which action performed i.e add,edit,undo,redo etc
    autoScroll: true,
    cacheXpaths: {},
    creationMode: false,
    editedStepIndex: -1,
    editedStepOfMsgType13: {}, // it holds original step which has been edited in the middle of  msgType 13 & 14
    editedStepOfMsgType16: {}, // it holds original step which has been edited in the middle of  msgType 16 & 17/18
    newStepIndex: -1,
    newStep: false,
    instrNumArray: [],
    isTestCaseAlreadyRunning: false,
    lastTreatedStep: {}, // Use to save stepDetails for undo/redo last action
    listView: false,
    redoData: [], // Steps details, after undo, save here for redo again
    saveBtnPressLoader: false,
    selectedSteps: [], // Indexes of selected steps
    smartRetryLoader: {},
    debugPointLoader: {},
    stopRetryFlag: false,
    stepsType: 'live', // To check which steps to show (enum: live/recover)
    testCaseId: null,
    testSteps: {},
    testCaseStatus: 0,
    undoData: [], // Steps details, after redo, save here for undo again
    autoSuggestCount: 0,
    expandInstrNo: [], // array of number of steps which are expanded on step editor page.
    shadowDom:false
};

export default function selectedTestCaseReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_SELECTED_TESTCASE_REDUCER: {
            return { ...initialState };
        }

        case ActionTypes.SET_EXPANDED_INSTR_NO: {
            const {stepNo, isExpanded} = action.payload;
            let _expandInstrNo = [...state.expandInstrNo];
            if (isExpanded ) {
                if (_expandInstrNo.indexOf(stepNo+'.') === -1 ) {
                    _expandInstrNo = [...state.expandInstrNo, stepNo+'.']
                }else {
                    _expandInstrNo = [...state.expandInstrNo]
                }
               
            } else {
              let indexToRemove =  _expandInstrNo.indexOf(stepNo+'.');
                _expandInstrNo.splice(indexToRemove,1);
            }
            const obj = {
                ...state,
                expandInstrNo : [..._expandInstrNo]
            };
            return obj;
        }

        case ActionTypes.SET_SELECTED_TESTCASE_DATA: {
            const { cacheXpaths, instrNumArray, testCaseId, testCaseStatus, testStepsObj, stepsType } = action.payload;
            const obj = {
                ...state,
                cacheXpaths,
                editedStepIndex: -1,
                instrNumArray,
                newStepIndex: -1,
                isTestCaseAlreadyRunning: false,
                newStep: false,
                testCaseId,
                testCaseStatus,
                testSteps: testStepsObj,
                stepsType,
            };
            return obj;
        }

        case ActionTypes.UPDATE_AUTO_SUGGEST_COUNT: {
            const { count } = action.payload;
            const obj = {
                ...state,
                autoSuggestCount: count,
            };
            return obj;
        }

        case ActionTypes.ADD_DUMMY_STEP_IN_STEPS_ARRAY: {
            const { flag, instrNumArray, testSteps, newIndex } = action.payload;
            let obj = { ...state };
            if (flag) {
                obj = { ...obj, testSteps, instrNumArray, newStepIndex: newIndex, newStep: flag, actionType: 'add' };
            } else {
                obj = { ...obj, newStep: flag, newStepIndex: -1 };
            }
            return obj;
        }

        case ActionTypes.REMOVE_DUMMY_STEP_IN_STEPS_ARRAY: {
            const { flag, instrNumArray, testSteps } = action.payload;
            return { ...state, testSteps, instrNumArray, newStepIndex: -1, newStep: flag, actionType: '' };
        }

        case ActionTypes.ADD_STEP_IN_STEPS_ARRAY: {
            const { instrNumArray, testSteps } = action.payload;
            return { ...state, testSteps, instrNumArray, newStepIndex: -1, newStep: false, actionType: '' };
        }

        case ActionTypes.OPEN_EDIT_MODE_FOR_STEP: {
            return { ...state, editedStepIndex: action.payload.index, actionType: 'edit' };
        }

        case ActionTypes.CLOSE_EDIT_MODE_FOR_STEP: {
            return { ...state, editedStepIndex: -1, actionType: '' };
        }

        case ActionTypes.EDIT_STEP_IN_STEPS_ARRAY: {
            const { failedStepsData, debugStepsData, step } = action.payload;
            const testStepsObj = state.testSteps;
            const testCaseId = window.location.pathname.split('/').pop();
            let obj = { ...state };

            if (
                checkKeyInObject(failedStepsData, testCaseId) &&
                checkKeyInObject(failedStepsData[testCaseId], 'instrNum') &&
                !checkObject(state.editedStepOfMsgType13)
            ) {
                obj = { ...obj, editedStepOfMsgType13: step };
            }
            if (
                checkKeyInObject(debugStepsData, testCaseId) &&
                checkKeyInObject(debugStepsData[testCaseId], 'instrNum') &&
                !checkObject(state.editedStepOfMsgType16)
            ) {
                obj = { ...obj, editedStepOfMsgType16: step };
            }
            testStepsObj[step.instrNum] = { ...testStepsObj[step.instrNum], ...step };
            obj = { ...obj, testSteps: testStepsObj, editedStepIndex: -1, actionType: '' };
            return obj;
        }

        case ActionTypes.UPDATE_SINGLE_STEP_STATUS: {
            const { instrNum, data, msgType } = action.payload;
            const currentStep = checkObject(state.testSteps[instrNum]) ? JSON.parse(JSON.stringify(state.testSteps[instrNum])) : {};
            let testSteps = { ...state.testSteps };

            if (msgType === '5') {
                const parentInstrNum = instrNum.split('.').shift();
                let parentStep = checkObject(state.testSteps[parentInstrNum]) ? JSON.parse(JSON.stringify(state.testSteps[parentInstrNum])) : {};
                parentStep = { ...parentStep, status: data.status };
                testSteps = { ...testSteps, [parentInstrNum]: { ...parentStep } };
            }

            testSteps = { ...testSteps, [instrNum]: { ...currentStep, ...data } };
            const obj = { ...state, testSteps };
            return obj;
        }
        case ActionTypes.SET_TEST_CASE_GENERATION_STATUS: {
            const { status } = action.payload;
            const obj = { ...state, isTestCaseAlreadyRunning: status };
            return obj;
        }
        case ActionTypes.DELETE_STEPS_FROM_STEPS_ARRAY: {
            const { testSteps, instrNumArray } = action.payload;
            return { ...state, testSteps, instrNumArray, actionType: '' };
        }
        /* UNDO REDO Cases start */
        case ActionTypes.SAVE_TEMP_STEPS_ARRAY: {
            const { data } = action.payload;
            const temp = [...state.undoData];

            // At 1st index of undoData, Insert last step's detail, on which action has performed
            temp.unshift(data);
            const obj = { ...state, undoData: temp, redoData: [], actionType: '' };
            return obj;
        }
        case ActionTypes.UNDO_STEPS_ARRAY_ACTION: {
            const { testSteps, instrNumArray, redoData, lastTreatedStep } = action.payload;
            return { ...state, testSteps, instrNumArray, redoData, lastTreatedStep, actionType: '' };
        }
        case ActionTypes.REDO_STEPS_ARRAY_ACTION: {
            const { testSteps, instrNumArray, undoData, lastTreatedStep } = action.payload;
            return { ...state, testSteps, instrNumArray, undoData, lastTreatedStep, actionType: '' };
        }
        case ActionTypes.SET_ACTIONTYPE_FOR_STEPS_ARRAY: {
            const { actionType } = action.payload;
            const obj = { ...state, actionType };
            return obj;
        }
        case ActionTypes.EMPTY_UNDO_REDO_ARRAY: {
            const obj = { ...state, undoData: [], redoData: [] };
            return obj;
        }
        /* UNDO REDO Cases end */
        case ActionTypes.TOGGLE_STOP_ICON: {
            const { flag } = action.payload;
            const obj = { ...state, stopRetryFlag: flag };
            return obj;
        }
        case ActionTypes.TOGGLE_SAVE_ICON: {
            const { flag } = action.payload;
            const obj = { ...state, saveBtnPressLoader: flag };
            return obj;
        }
        case ActionTypes.TOGGLE_STEP_TYPE: {
            const { flag } = action.payload;
            const obj = { ...state, stepsType: flag };
            return obj;
        }
        case ActionTypes.SET_ALL_CACHE_XPATHS: {
            const { cacheXpaths } = action.payload;
            const obj = { ...state, cacheXpaths };
            return obj;
        }
        case ActionTypes.TOGGLE_STEP_XPATH: {
            const { flag, instrNum } = action.payload;
            const { testSteps } = state;
            let cacheXpaths = JSON.parse(JSON.stringify(state.cacheXpaths));

            const parentStep = testSteps[instrNum];
            if (checkKeyInObject(cacheXpaths, instrNum)) {
                if (checkKeyInObject(parentStep, 'hasChild') && checkArrayLength(parentStep.subInstructions)) {
                    parentStep.subInstructions.forEach((sub) => delete cacheXpaths[sub.instrNum]);
                }
                delete cacheXpaths[instrNum];
            } else {
                if (checkKeyInObject(parentStep, 'hasChild') && checkArrayLength(parentStep.subInstructions)) {
                    parentStep.subInstructions.forEach((sub) => {
                        cacheXpaths = { ...cacheXpaths, [sub.instrNum]: flag };
                    });
                }
                cacheXpaths = { ...cacheXpaths, [instrNum]: flag };
            }

            const obj = { ...state, cacheXpaths };
            return obj;
        }
        case ActionTypes.TOGGLE_SELECT_ALL_STEP: {
            const { instrNumArray, selectedSteps } = state;
            let _selectedSteps = [];


            if (checkArrayLength(instrNumArray) && checkArrayLength(selectedSteps) && instrNumArray.length !== selectedSteps.length || selectedSteps.length === 0) {
                _selectedSteps = [...instrNumArray];
            }

            const obj = { ...state, selectedSteps: _selectedSteps };
            return obj;
        }
        case ActionTypes.SELECT_MULTIPLE_STEPS: {
            const { steps } = action.payload;
            let obj = { ...state };
            if (checkArrayLength(steps)) {
                obj = { ...obj, selectedSteps: steps };
            }
            return obj;
        }
        case ActionTypes.SELECT_STEP: {
            const { step } = action.payload;
            const { selectedSteps, instrNumArray } = state;

            const isNotSelected = selectedSteps.indexOf(step.instrNum) === -1;
            let _selectedSteps = [];
            _selectedSteps = TestStepUtils.selectStepHelper(step, isNotSelected, [...selectedSteps], [...instrNumArray]);

            const obj = { ...state, selectedSteps: _.uniq(_selectedSteps) };

            return obj;
        }
        case ActionTypes.EMPTY_SELECT_STEP: {
            return { ...state, selectedSteps: [] };
        }
        case ActionTypes.TOGGLE_AUTO_SCROLL: {
            return { ...state, autoScroll: !state.autoScroll };
        }
        case ActionTypes.SET_TEST_STEPS_OBJECT: {
            const { testSteps } = action.payload;
            return { ...state, testSteps };
        }
        case ActionTypes.TOGGLE_LIST_VIEW: {
            const { flag } = action.payload;
            setTimeout(() => {
                addUserPreference('listview', flag);
            }, 500);
            return { ...state, listView: flag };
        }
        case ActionTypes.SMART_RETRY_LOADER: {
            const { data, show } = action.payload;
            const { testCaseId, instrNum } = data;
            let smartRetryLoader = { ...state.smartRetryLoader };
            if (show) {
                smartRetryLoader = { ...smartRetryLoader, [testCaseId]: instrNum };
            } else {
                delete smartRetryLoader[testCaseId];
            }
            return { ...state, smartRetryLoader };
        }
        case ActionTypes.DEPUG_POINT_LOADER: {
            const { data, show } = action.payload;
            const { testCaseId, instrNum } = data;
            let debugPointLoader = { ...state.debugPointLoader };
            if (show) {
                debugPointLoader = { ...debugPointLoader, [testCaseId]: instrNum };
            } else {
                delete debugPointLoader[testCaseId];
            }
            return { ...state, debugPointLoader };
        }
        case ActionTypes.UPDATE_SHOW_PASTE_BUTTON: {
            return { ...state, isShowPasteButton: action.payload };
        }
        case ActionTypes.TOGGLE_CREATION_MODE: {
            return { ...state, creationMode: !state.creationMode };
        }
        case ActionTypes.TOGGLE_TESTCASE_SHADOWDOM: {
            return { ...state, shadowDom: action.payload  };
        }
        default:
            return state;
    }
}
