import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import styles from './Styles';
import BrowserIcon from "./BrowserIcon/";


const BrowserDetailsContainer = ({browserDetails,selected,onChange,classes}) => {

    return (
        <Box className={classes.browser_details_root}>
        <Box className={classes.browser_details_name_contianer} >
            <BrowserIcon browserName = {browserDetails.logoName}/>
            <Box className={classes.browser_name}>{browserDetails.name}</Box>
        </Box>
        <Box className={classes.browser_versions_root}>
        {
            browserDetails.versions.map((version)=>{
                return (
                    <Box className={classes.browser_version_container}>
                        <Box onClick={(e)=>{
                            e.stopPropagation();
                            onChange(version)
                        }}  className={version.id===selected.id?classes.selected_browser_version_name:classes.browser_version_name} > 
                            {version.name}
                        </Box>

                    </Box>
                )
            })
        }
        </Box>
      </Box>  
    )

}

BrowserDetailsContainer.propTypes = {
    browserDetails:PropTypes.object.isRequired,
    onChange:PropTypes.func
}

export default withStyles(styles)(BrowserDetailsContainer)

 