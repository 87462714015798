// import material
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// import packages
import React from 'react';
import { BLACK_FONT } from '../../../common/cssConstants';

const styles = () => ({
    col: {
        width: '50%',
    },
    body: {
        '& > p': {
            color: BLACK_FONT,
        },
    },
    header: {
        '& > p': {
            color: BLACK_FONT,
            fontSize: 16,
            fontWeight: '600',
        },
    },
    horizontalRow: {
        width: 'calc(100% + 78px)',
        marginLeft: '-55px',
        border: 0,
        borderTop: '1px solid #DCDCDC',
    },
    label: {
        width: 50,
        '& > p': {
            color: BLACK_FONT,
        },
    },
    root: {
        width: '100%',
    },
    row: {
        padding: '6px 0px',
        display: 'flex',
        justifyContent: 'start',
    },
    value: {
        marginLeft: 55,
        '& > p': {
            color: BLACK_FONT,
            fontSize: 14,
            fontWeight: '600',
        },
    },
});

function DissociateModal({ userName, userRole, classes }) {
    return (
        <div className={classes.root}>
            <div className={classes.row}>
                <div className={classes.label}>
                    <Typography>Name</Typography>
                </div>
                <div className={classes.value}>
                    <Typography>{userName}</Typography>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.label}>
                    <Typography>Role</Typography>
                </div>
                <div className={classes.value}>
                    <Typography>{userRole}</Typography>
                </div>
            </div>
        </div>
    );
}

export default withStyles(styles)(DissociateModal);
