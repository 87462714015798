import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from 'react-redux';
// icons
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Enable from '@material-ui/icons/PowerSettingsNew';
import Disabled from '@material-ui/icons/NotInterested';
import Export from '@material-ui/icons/Launch';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { faVideo, faPencilAlt, faStar } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TestCaseUtils } from '../../utils/TestCaseUtils';
import { THREE_DOT_MENU_CSS, MODAL_CANCEL_BUTTON, MODAL_DELETE_BUTTON, MODAL_SUBMIT_BUTTON, BLACK_FONT } from '../../common/cssConstants';
import Tooltip from '../Tooltip';
import projectServices from '../../services/projectServices';
import { ModalActions, ProjectActions, ProjectsActions } from '../../store/actions';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON } from '../Button/Btn';

const styles = () => ({
    root: {
        marginRight: -19,
        marginTop: 0,
    },
    icon: {
        color: '#5F7B96',
        marginTop: '2px',
    },
    menu_divider: {
        marginLeft: 10,
        marginRight: 10,
    },
    menu_item: {
        height: 10,
        padding: '12px 12px 12px 2px',
        boxSizing: 'content-box',
    },
    menu_item_disabled: {
        height: 10,
        paddingLeft: '2px',
        paddingRight: '12px',
        cursor: 'auto',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
        marginRight: THREE_DOT_MENU_CSS.iconMarginRight,
        marginLeft: THREE_DOT_MENU_CSS.iconMarginLeft,
        fontSize: THREE_DOT_MENU_CSS.iconFontSize,
    },
    moreIconsBtnDisable: {
        color: '#1e68cc !important',
        marginLeft: '5px !important',
    },
    videoCam: {
        color: '#00aeac',
    },
    videoCamDisabled: {
        color: '#898c8c',
    },
    list_item_text: {
        padding: 0,
        '&>span': {
            fontSize: THREE_DOT_MENU_CSS.fontSize,
            color: BLACK_FONT,
        },
    },
    list_item_text_disabled: {
        padding: 0,
        '&>span': {
            fontSize: THREE_DOT_MENU_CSS.fontSize,
            color: '#bac4ce !important',
        },
    },
    list_item_icon: {
        marginRight: 5,
        minWidth: 15,
    },
    list_item_icon_disabled: {
        marginRight: 5,
        color: '#bac4ce',
    },
    iconRoot: {
        width: '30px',
        height: '30px',
        fontSize: '1.25rem',
    },
    paper: {
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    },
    message: {
        float: 'left',
        fontSize: 12,
        color: BLACK_FONT,
    },
    boldFont: {
        color: BLACK_FONT,
        fontWeight: 500,
        marginLeft: 3,
    },
    deleteButtonDanger: MODAL_DELETE_BUTTON,
});

class ProjectCardActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleFavouriteProject = (event) => {
        // event.stopPropagation();
        this.props.handleFavourite();
        // this.props.toggleModal();
        this.handleClose(event);
    };

    handleNewProjectOpen = (event, projectId) => {
        event.stopPropagation();
        this.handleClose(event);
        this.props.handleUpdateProject(projectId);
    };

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
        event.stopPropagation();
    };

    handleClose = (event) => {
        event.stopPropagation();
        this.setState({ anchorEl: null });
    };

    confirmDelete = (isConfirm) => {
        if (isConfirm) {
            this.props.onDelete();
        }
        this.props.toggleModal();
    };

    confirmEnable = (isConfirm) => {
        const { enableProject, projectId } = this.props;
        if (isConfirm) {
            enableProject(projectId);
        }
        this.props.toggleModal();
    };

    handleOnDeleteEnable = (event, isDisable = true) => {
        event.stopPropagation();
        const { toggleModal, classes, project } = this.props;
        if (isDisable) {
            this.setState({ anchorEl: null });
        }
        toggleModal('GeneralModal', null, null, {
            title: `${isDisable ? 'Disable' : 'Enable'} Project`,
            closeIconAction: () => {
                if (isDisable) {
                    this.confirmDelete(false);
                } else {
                    this.confirmEnable(false);
                }
            },
            component: [
                {
                    content: (
                        <div
                            style={{
                                width: '100%',
                                padding: '0 30px',
                                minWidth: '300px',
                            }}
                        >
                            <Typography className={classes.message}>
                                {!project.hasArtefacts
                                    ? `Are you sure you want to ${isDisable ? 'disable' : 'enable'}`
                                    : 'This project is not empty. Please confirm you wish to delete it '}{' '}
                                <span className={classes.boldFont}>{project && project.projectName ? project.projectName : ''}</span>?
                            </Typography>
                        </div>
                    ),
                    buttons: [
                        {
                            name: `${isDisable ? 'Disable' : 'Enable'}`,
                            variant: 'contained',
                            buttonClass: isDisable ? classes.deleteButtonDanger : '',
                            style: { ...MODAL_SUBMIT_BUTTON },
                            action: () => {
                                if (isDisable) {
                                    this.confirmDelete(true);
                                } else {
                                    this.confirmEnable(true);
                                }
                            },
                            isDisabled: false,
                        },
                        {
                            name: 'Cancel',
                            variant: 'outlined',
                            style: { ...MODAL_CANCEL_BUTTON },
                            action: () => {
                                if (isDisable) {
                                    this.confirmDelete(false);
                                } else {
                                    this.confirmEnable(false);
                                }
                            },
                            isDisabled: false,
                        },
                    ],
                },
            ],
        });
    };

    handleEdit = (event, projectId) => {
        event.stopPropagation();
        this.handleClose(event);
        this.props.handleProjectNameEdit(projectId);
    };

    callForRecord = async (accountId, projectId, discoveryId, callBack) => {
        TestCaseUtils.recordTestCase({
            accountId,
            projectSystemId: projectId,
            discoveryId,
            RecordData: callBack,
            fIndex: '',
            callBackForLoader: null,
            idModal: false,
        });
    };

    handleRecordContext = (e) => {
        e.preventDefault();
        this.props.toggleIdModal(true)
    }

    render() {
        const { anchorEl } = this.state;
        const { classes, toggleModal, authReducer, projectId, listView, project, showDisabledProjects, isFavourite } = this.props;
        const { accountId } = authReducer.user;
        const favouriteProject = isFavourite ? 'Remove Star' : 'Add Star';
        return (
            <div className={listView ? '' : classes.root}>
                {/* <IconButton >
                    <FontAwesomeIcon icon={faBolt} aria-label="faBolt" id="faBolt" className={classes.icon} style={{ color: '#1BABAB' }} />
                </IconButton> */}
                {!showDisabledProjects ? (
                    <div>
                        <Tooltip data="Record">
                            <IconButton
                                onClick={
                                    !showDisabledProjects
                                        ? () => {
                                              setTimeout(() => {
                                                  this.callForRecord(accountId, projectId, projectId, () => {});
                                              }, 1000);
                                          }
                                        : null
                                }
                                onContextMenu={this.handleRecordContext}
                                className={classes.iconRoot}
                            >
                                <FontAwesomeIcon
                                    icon={faVideo}
                                    aria-label="faVideo"
                                    id="faVideo"
                                    className={!showDisabledProjects ? classes.videoCam : classes.videoCamDisabled}
                                />
                            </IconButton>
                        </Tooltip>
                        <IconButton
                            aria-label="More"
                            aria-owns={anchorEl ? 'long-menu' : null}
                            aria-haspopup="true"
                            onClick={this.handleClick}
                            className={classes.iconRoot}
                        >
                            <MoreHoriz className={classes.icon} aria-label="moreHorizIcon" id="moreHorizIcon" />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            classes={{ paper: classes.paper }}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                            style={{ marginTop: 12 }}
                        >
                            <MenuItem className={classes.menu_item} onClick={($event) => this.handleFavouriteProject($event, projectId)}>
                                <ListItemIcon className={classes.list_item_icon} aria-label="listItemIcon" id="listItemIcon">
                                    <FontAwesomeIcon
                                        style={{ fontSize: THREE_DOT_MENU_CSS.faIconFontSize, padding: '1px' }}
                                        icon={faStar}
                                        aria-label="faPencilAlt"
                                        id="faPencilAlt"
                                        className={classes.moreIconsBtn}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.list_item_text} inset primary={favouriteProject} />
                            </MenuItem>
                            <MenuItem className={classes.menu_item} onClick={($event) => this.handleNewProjectOpen($event, projectId)}>
                                <ListItemIcon className={classes.list_item_icon} aria-label="listItemIcon" id="listItemIcon">
                                    <FontAwesomeIcon
                                        style={{ fontSize: THREE_DOT_MENU_CSS.faIconFontSize, padding: '1px' }}
                                        icon={faPencilAlt}
                                        aria-label="faPencilAlt"
                                        id="faPencilAlt"
                                        className={classes.moreIconsBtn}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.list_item_text} inset primary="Project Properties" />
                            </MenuItem>
                            <MenuItem
                                onClick={(ev) => {
                                    toggleModal('uploadTestCase', null, null, projectId);
                                    this.handleClose(ev);
                                }}
                                className={classes.menu_item}
                            >
                                <ListItemIcon className={classes.list_item_icon} aria-label="listItemIcon" id="listItemIcon">
                                    <CloudUpload className={classes.moreIconsBtn} aria-label="cloudUploadIcon" id="cloudUploadIcon" />
                                </ListItemIcon>
                                <ListItemText className={classes.list_item_text} inset primary="Upload Test Case" />
                            </MenuItem>
                            <MenuItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({ anchorEl: null });
                                    toggleModal('GeneralModal', null, null, {
                                        title: 'Download Project',
                                        closeIconAction: () => {
                                            toggleModal();
                                        },
                                        component: [
                                            {
                                                content: (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            padding: '0 30px',
                                                            minWidth: '300px',
                                                        }}
                                                    >
                                                        <Typography className={classes.message}>
                                                            Are you sure you want to download{' '}
                                                            <span className={classes.boldFont}>
                                                                {project && project.projectName ? project.projectName : ''}
                                                            </span>
                                                            ?
                                                        </Typography>
                                                    </div>
                                                ),
                                                buttons: [
                                                    NEXT_SUBMIT_BUTTON({
                                                        name: 'Download',
                                                        action: () => {
                                                            const url = `/v1/${project.projectId}/download_project`;
                                                            projectServices.downloadGradle(
                                                                url,
                                                                { fileName: project.projectName, id: project.projectId, type: 'project' },
                                                                this.props.showSnackBar,
                                                                'Unable to Export Project',
                                                            );
                                                            toggleModal();
                                                        },
                                                    }),
                                                    CANCEL_BUTTON({ action: () => toggleModal() }),
                                                ],
                                            },
                                        ],
                                    });
                                }}
                                className={classes.menu_item}
                            >
                                <ListItemIcon className={classes.list_item_icon} aria-label="listItemIcon" id="listItemIcon">
                                    <Export className={classes.moreIconsBtn} aria-label="exportIcon" id="exportIcon" />
                                </ListItemIcon>
                                <ListItemText className={classes.list_item_text} inset primary="Download Project" />
                            </MenuItem>
                            <MenuItem
                                onClick={(e) => {
                                    this.handleOnDeleteEnable(e);
                                }}
                                className={classes.menu_item}
                            >
                                <ListItemIcon className={classes.list_item_icon} aria-label="listItemIcon" id="listItemIcon">
                                    <Disabled className={classes.moreIconsBtn} aria-label="disabledIcon" id="disabledIcon" />
                                </ListItemIcon>
                                <ListItemText className={classes.list_item_text} inset primary="Disable Project" />
                            </MenuItem>
                        </Menu>
                    </div>
                ) : (
                    <div>
                        <Tooltip data="Enable Project">
                            <IconButton
                                onClick={(e) => {
                                    this.handleOnDeleteEnable(e, false);
                                }}
                                className={classes.iconRoot}
                            >
                                <Enable
                                    className={`${classes.moreIconsBtn} ${classes.moreIconsBtnDisable}`}
                                    aria-label="enableIcon"
                                    id="enableIcon"
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </div>
        );
    }
}
ProjectCardActions.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    project: PropTypes.shape({}).isRequired,
    onDelete: PropTypes.func.isRequired,
    // toggleUpload: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
    return {
        authReducer: state.authReducer,
        // state: state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        recordTestCase: (...args) => dispatch(ProjectActions.recordTestCase(...args)),
        enableProject: (...args) => dispatch(ProjectsActions.enableProject(...args)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        toggleIdModal: (bool) => dispatch(ProjectActions.toggleIdModal(bool)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectCardActions));
