// material
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import WarningIcon from '@material-ui/icons/Warning';

// packages
import { connect } from 'react-redux';
import React from 'react';

// icons material
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import History from '@material-ui/icons/History';
import Calendar from 'mdi-material-ui/Calendar';
import AccountArrowLeft from 'mdi-material-ui/AccountArrowLeft';
// icons fav
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// custom
import { Link } from 'react-router-dom';
import {
    checkArrayLength,
    checkKeyInObject,
    getCountAvatar,
    sort,
    getFormatedDate,
    getRelativeTime,
    getTimeInHours,
} from '../../../utils';
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import TableCell from '../../../components/Table/TableCell';
import TableRow from '../../../components/Table/TableRow';
import TableFooter from '../../../common/TableFooter';
import { GeneralActions, ScheduleActions, ProjectActions, generalModalActions, ModalActions } from '../../../store/actions';
import { EnhancedTableHead } from '../../../components/EnhancedTable';
import {
    MUI_ACTION_MENU_ICON,
    THREE_DOT_MENU_CSS,
    TABLES_CSS,
    MODAL_SUBMIT_BUTTON,
    MODAL_CANCEL_BUTTON,
    MODAL_DELETE_BUTTON,
} from '../../../common/cssConstants';
import Tooltip from '../../../components/Tooltip';
import ScheduleModal from '../../admin/modal/scheduleModal';
import ExecutionScheduleModal from '../../../components/ScheduleModal/ExecutionScheduleModal';
import ExpandBtn from '../../../components/Button/ExpandBtn';
import { MenuOptions } from '../../../components/Button/MenuOptions';
import SuiteActions from '../../../common/SuiteActions';
import ConfirmAssignModal from './ConfirmAssignModal';
import { SEGMENT_EVENT } from '../../../common/constants';
import { track } from '../../../services/Segment';
import moment from 'moment';

// styles
const styles = (theme) => ({
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '20%',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    cell_: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '100%',
        maxWidth: '100%',
        borderRight: '1px solid rgb(224, 224, 224)',
        alignItems: 'flex-start !important',
        '&>:first-child': {
            paddingLeft: '10px !important',
            display: 'flex',
        },
    },
    headerContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 35,
        marginTop: -35,
        width: 'calc(100% - 55px)',
    },
    circularPrgress: {
        position: 'absolute',
        color: '#4885ed',
    },
    addProject: {
        width: '25px',
        height: '25px',
        display: 'flex',
        backgroundColor: '#1babab',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        cursor: 'pointer',
        boxShadow: '0 0 3px 0 rgba(0,0,0,0.12), 0 3px 3px 0 rgba(0,0,0,0.24)',
        top: '60px',
    },
    plus: {
        color: '#fff',
        fontSize: '10px',
    },
    svg: {
        fontSize: '23px',
        marginLeft: '10px',
        marginRight: '5px',
    },
    btnColor: {
        color: 'rgb(27, 171, 171)',
    },
    disabledBtnColor:{
        color: '#afb7ac',
    },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
    },
    moreIcons: MUI_ACTION_MENU_ICON,
    noDataText: {
        display: 'flex',
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '200px',
    },
    root: {
        width: 'calc(100% - 40px)',
        margin: '0px 20px',
        marginTop: '10px',
        borderRadius: 0,
        boxShadow: 'none',
    },
    wrapperTable: {
        borderRadius: '5px',
        marginBottom: '10px',
    },
    stopPlayIcons: {
        fontSize: '25px',
        color: '#1BABAB',
    },
    nonSortableContainer: {
        cursor: 'default',
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'inherit',
        justifyContent: 'flex-start',
    },
    maxHeight: {
        maxHeight: 'calc(100vh - 152px)',
        height: 'auto',
    },
    subCellContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxHeight: 100,
        padding: '0px !important',
        width: '100%',
        paddingLeft: '9px !important',
        paddingBottom: '9px !important',
        overflow: 'hidden',
        overflowY: 'auto',
    },
    faSubHeaderIcon: {
        fontSize: 18,
        color: '#5F7B96',
        marginRight: 6,
    },
    subHeaderFirstColStyle: {
        alignItems: 'center',
        display: 'flex',
        marginTop: 9,
        justifyContent: 'space-between',
        width: '100%',
    },
    notAvailable: {
        fontSize: '18px',
        color: '#8498AA',
        fontWeight: 'bold',
        marginTop: '10px',
    },
    fileIcon: {
        color: '#ECECEC',
        fontSize: '50px',
        marginLeft: '10px',
        marginRight: '5px',
    },
    tableWrapper: {
        overflowX: 'hidden',
        overflowY: 'auto',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        borderRadius: '5px',
    },
    tableRows: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F3F3F3 !important',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        display: 'flex !important',
    },
    tableSubRows: {
        backgroundColor: '#fff',
        display: 'flex',
        '&>div': {
            border: '1px solid #D9E0E7',
            boxSizing: 'border-box',
        },
    },
    tableRows1: {
        cursor: 'pointer',
        display: 'flex !important',
        '&:hover': {
            backgroundColor: 'rgb(237, 237, 237) !important',
        },
        '&>div': {
            boxSizing: 'border-box',
            borderTop: 'none',
        },
        backgroundColor: '#FEFBEE',
    },
    currentCell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        paddingLeft: '0px !important',
        width: '16.6%',
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    wrap: {
        overflow: 'hidden',
        whiteSpace: 'normal',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    tooltipPaperBody: {
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.8)',
        backgroundColor: '#fff',
        borderRadius: '3px',
        overflow: 'hidden',
        padding: '5px 0',
    },
    deleteButtonDanger: MODAL_DELETE_BUTTON,
    wrapperAction: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    iconBtn: {
        width: '30px',
        height: '30px',
    },
});

const headers = [
    { id: 'name', sortable: true, numeric: false, disablePadding: true, label: 'Schedule', styles: { paddingLeft: 10 }, width: 'calc(100% / 8)' },
    {
        id: 'testSuiteName',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: ' Test Suite',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 8)',
    },
    {
        id: 'owner_user_Name',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Schedule Owner',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 8)',
    },
    {
        id: 'start_time',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Start Date',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 8)',
    },
    { id: 'end_time', sortable: true, numeric: false, disablePadding: true, label: 'End Date', styles: { paddingLeft: 10 }, width: 'calc(100% / 8)' },
    {
        id: 'schedule_interval',
        sortable: true,
        numeric: false,
        disablePadding: true,
        label: 'Interval',
        styles: { paddingLeft: 10 },
        width: 'calc(100% / 8)',
    },
    { id: 'executionHistory', sortable: false, numeric: false, disablePadding: true, label: 'Execution History', width: 'calc(100% / 8)' },
    { id: 'funcs', sortable: false, numeric: false, disablePadding: true, label: 'Actions', styles: { paddingLeft: 10 }, width: 'calc(100% / 8)' },
];
const subHeaders = [
    { id: 'case', sortable: false, numeric: false, disablePadding: false, label: 'Case', styles: { paddingLeft: 20 }, width: 'calc(100% / 8)' },
    { id: 'extra', sortable: false, numeric: false, disablePadding: true, label: '', styles: { paddingLeft: 10 }, width: 'calc(100% / 8)' },
    {
        id: 'last_scheduled_run',
        sortable: false,
        numeric: false,
        disablePadding: true,
        label: 'Last Scheduled Run',
        styles: { paddingLeft: 20 },
        width: 'calc(100% / 8)',
    },
    { id: 'extra1', sortable: false, numeric: false, disablePadding: true, label: '', styles: { paddingLeft: 10 }, width: 'calc(100% / 8)' },
    {
        id: 'schedule_execution_status',
        sortable: false,
        numeric: false,
        disablePadding: true,
        label: 'Schedule Execution Status',
        styles: { paddingLeft: 20 },
        width: 'calc(100% / 8)',
    },
    { id: 'extra2', sortable: false, numeric: false, disablePadding: true, label: '', styles: { paddingLeft: 10 }, width: 'calc(100% / 8)' },
    { id: 'extra3', sortable: false, numeric: false, disablePadding: true, label: '', styles: { paddingLeft: 10 }, width: 'calc(100% / 8)' },
    { id: 'funcs', sortable: false, numeric: false, disablePadding: false, label: 'Actions', styles: { paddingLeft: 10 }, width: 'calc(100% / 8)' },
];
class ScheduledSuites extends React.Component {
    constructor(props) {
        super(props);
        this.colsWidth = {};
        this.state = {
            order: 'asc',
            orderBy: 'name',
            rowExpendStatus: [0, false],
            page: 0,
            rowsPerPage: 10,
            selectedSchedule: {},
            schedules: [],
            isCaseBtnsDisable: false,
            deleteTestCaseData: null,
            AssigntoLoggedInPopUp:false,
            assignSchedule:{},
            testCases:{}
        };
        props.updateTitle('Schedules');
    }

    UNSAFE_componentWillMount() {
        this.updateSchedules(this.props.schedules);
    }

    async componentDidMount() {
        for (let i = 0, j = 0; i < headers.length && j < subHeaders.length; i++) {
            const header = headers[i];
            if (header.colSpan && header.colSpan > 1) {
                for (let k = j; k < j + header.colSpan; k++) {
                    const subHeader = subHeaders[k];
                    subHeader.width = `calc(${header.width} / ${header.colSpan})`;
                    subHeader.parentIndex = i;
                }
                j += header.colSpan;
            } else {
                header.colSpan = 1;
                const subHeader = subHeaders[j];
                subHeader.width = header.width;
                subHeader.parentIndex = i;
                j++;
            }
        }
        const { getSchedules, getTestSuitesForSchedules, getProjectByIdAndName } = this.props;
        getTestSuitesForSchedules();
        getSchedules();
        getProjectByIdAndName();

        const scheduleSettingRows = localStorage.getItem('rowsJobsPerPage');
        if (scheduleSettingRows) {
            this.updateState({
                rowsPerPage: parseInt(scheduleSettingRows, 10),
                selectedSchedule: {},
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateSchedules(nextProps.schedules);
    }

    componentWillUnmount() {
        const { queryRemove } = this.props;
        queryRemove();
    }

    onDeleteTestCase = (suite, caseData) => {
        this.setState({
            deleteTestCaseData: caseData,
            suite,
        });
    };

    select_deSelectAllCurrentPage = (schedules, isSelect) => {
        const { selectedSchedule } = this.state;
        const _selectedSchedule = JSON.parse(JSON.stringify(selectedSchedule));
        schedules.forEach((schedule) => {
            if (!isSelect && _selectedSchedule[schedule.schedule_id]) {
                delete _selectedSchedule[schedule.schedule_id];
            } else if (isSelect) {
                _selectedSchedule[schedule.schedule_id] = true;
            }
        });
        this.setState({
            selectedSchedule: _selectedSchedule,
        });
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleExpend = (id,jobId) => {
        const { rowExpendStatus , testCases } = this.state;
        this.setState(() => ({
            rowExpendStatus: [id, id === rowExpendStatus[0] ? !rowExpendStatus[1] : true],
            isCaseBtnsDisable: false,
        }));

        if(!(testCases[jobId])) {
            
            this.getTestCases(jobId,id);
        }


    };

    getTestCases = (jobId,schedule_id) => {
        console.log('getTestCases',jobId);
        const {getTestCasesByJobId, getExecutionDetailsByScheduleId} = this.props;
        if(jobId == 0){
            getExecutionDetailsByScheduleId(schedule_id).then((res)=>{
                if(res && res.payload && res.payload.data){
                    console.log('res',res.payload);
                    const newTestCases = {...this.state.testCases}
                    newTestCases[jobId] = res.payload.data.tcDetails
                    this.setState({
                        testCasesReady:true,
                        testCases:newTestCases
                    })
                } else if(res && res.payload && res.payload.error) {
                    const newTestCases = {...this.state.testCases}
                    newTestCases[jobId] = []
                    this.setState({
                        testCasesReady:true,
                        testCases:newTestCases
                    })
    
                }
            })
        }
        else{
            getTestCasesByJobId(jobId).then((res)=>{

                if(res && res.payload && res.payload.data) {
    
                    const newTestCases = {...this.state.testCases}
                    newTestCases[jobId] = res.payload.data
                    this.setState({
                        testCasesReady:true,
                        testCases:newTestCases
                    })
                } else if(res && res.payload && res.payload.error) {
                    const newTestCases = {...this.state.testCases}
                    newTestCases[jobId] = []
                    this.setState({
                        testCasesReady:true,
                        testCases:newTestCases
                    })
    
                }
            })
        }

    }
    handleChangePage = (page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        localStorage.setItem('rowsJobsPerPage', event.target.value);
        this.setState({ rowsPerPage: event.target.value });
    };

    handleCloseTestCaseDeleteDialog = async (flag) => {
        if (flag) {
            if (this.state.deleteTestCaseData && this.state.deleteTestCaseData.testCaseId) {
                const result = await this.props.deleteTestCaseFromSuite(this.state.suite.testSuiteId, [this.state.deleteTestCaseData.testCaseId]);
                if (result.payload) {
                    const testCases = sort(this.state.suite.testCases, 'asc', 'ordinal').filter(
                        (_case) => `${_case.testCaseId}` !== `${this.state.deleteTestCaseData.testCaseId}`,
                    );
                    await this.handleSubmitOrganize(this.state.suite.testSuiteId, testCases);
                    this.getTestSuiteWithAccount();
                }
            }
        }
        this.setState({
            deleteTestCaseData: null,
            suite: null,
        });
    };

    handleDelete = (schedule_id, getSchedules, name) => {
        const { toggleModal, deleteSchedule, classes } = this.props;
        toggleModal('GeneralModal', null, null, {
            title: 'Delete Schedule',
            closeIconAction: () => {
                toggleModal();
            },
            component: [
                {
                    content: (
                        <div
                            style={{
                                width: '100%',
                                padding: '0 30px',
                                minWidth: '300px',
                            }}
                        >
                            <Typography className={classes.message}>
                                Are you sure you want to delete <b>{name}</b>?
                            </Typography>
                        </div>
                    ),
                    buttons: [
                        {
                            name: 'Delete',
                            variant: 'contained',
                            buttonClass: classes.deleteButtonDanger,
                            style: { ...MODAL_SUBMIT_BUTTON },
                            action: () => {
                                const eventName = SEGMENT_EVENT.DELETE_SCHEDULE;
                                const segmentData = {
                                    schedule_name: name,
                                    schedule_id
                                }
                                track(eventName,segmentData)
                                deleteSchedule(schedule_id, getSchedules);
                                toggleModal();
                            },
                            isDisabled: false,
                        },
                        {
                            name: 'Cancel',
                            variant: 'outlined',
                            style: { ...MODAL_CANCEL_BUTTON },
                            action: () => {
                                toggleModal();
                            },
                            isDisabled: false,
                        },
                    ],
                },
            ],
        });
    };

    currentRowIndicator = () => {
        return (
            <span
                style={{
                    display: 'inline-block',
                    width: '4px',
                    minWidth: '4px',
                    marginRight: '16px',
                    height: '39px',
                    backgroundColor: '#ff9700',
                    position: 'relative',
                }}
            ></span>
        );
    };

    closeModal = () => {
        this.props.toggleModal();
        setTimeout(() => {
            this.props.clearGeneralModal();
        }, 500);
    };

    toggleModal = (type, updateData = {}) => {
        const { toggleModal, projectsByIdAndName } = this.props;
        toggleModal('GeneralModal', null, null, {
            title: type === 'Add' ? 'Create Schedule' : 'Update Schedule',
            closeIconAction: () => {
                this.closeModal();
            },
            component: [
                {
                    name: 'Job',
                    content: <ExecutionScheduleModal updateData={updateData} type={type} />,
                    buttons: [
                        {
                            name: 'Next',
                            variant: 'contained',
                            style: { ...MODAL_SUBMIT_BUTTON },
                            action: () => {
                                this.props.changeTab(this.props.currentTab + 1);
                            },
                            isDisabled: false,
                        },
                        {
                            name: 'Cancel',
                            variant: 'outlined',
                            style: { ...MODAL_CANCEL_BUTTON },
                            action: () => {
                                this.closeModal();
                            },
                            isDisabled: false,
                        },
                    ],
                },
                {
                    name: 'Schedule',
                    content: <ScheduleModal updateData={updateData} type={type} getTimeInHours={getTimeInHours} callingFrom='schedule' />,
                    buttons: [
                        {
                            name: type === 'Update' ? 'Update' : 'Create',
                            variant: 'contained',
                            style: { ...MODAL_SUBMIT_BUTTON },
                            action: () => {
                                const { tabsData, sauceCredentialDetails } = this.props;
                                let lastRunConfiguration = [];
                                let objectId;
                                let exec_details = [];
                                if (checkArrayLength(checkKeyInObject(tabsData, 'job.state.selected_suites'))) {
                                    tabsData.job.state.selected_suites.forEach((id) => {
                                        objectId = id;
                                    });
                                }
                                console.log('tabsData',tabsData);
                               // this.validateDuplicates(tabsData)
                                if (checkArrayLength(checkKeyInObject(tabsData, 'schedule.state.localContent'))) {
                                    // lastRunConfiguration =[...localContent];
                                    lastRunConfiguration.push(...tabsData.schedule.state.localContent)
                                }
                                if (checkArrayLength(checkKeyInObject(tabsData, 'schedule.state.externalGrid'))) {
                                    lastRunConfiguration.push(...tabsData.schedule.state.externalGrid)
                                }
                                if (checkArrayLength(checkKeyInObject(tabsData, 'schedule.state.sauceContent'))) {
                                    const _sauceContent = [...tabsData.schedule.state.sauceContent].map((row, ind) => {
                                        return {
                                            ...row,
                                            ...tabsData.schedule.state.deviceSettings[ind]
                                        }
                                    });
                                    lastRunConfiguration.push(..._sauceContent)
                                    // if (checkArrayLength(checkKeyInObject(tabsData, 'schedule.state.sauceContent'))){
                                    //     lastRunConfiguration.push(...tabsData.schedule.state.sauceContent)
                                    // }
                                }

                                console.log('lastRunConfiguration',lastRunConfiguration);

                                if(checkArrayLength(lastRunConfiguration)){
                                    // Checking Sauce credentials
                                    
                                    if (!Object.keys(sauceCredentialDetails).length) {
                                        lastRunConfiguration = lastRunConfiguration.filter((elem) => elem.environmentType.toLowerCase() !== 'saucelabs')
                                    }
                                    exec_details = lastRunConfiguration.map((row, index) => {
                                         const newObj = {
                                            environmentType: row.environmentType.toLowerCase(),
                                            platform: (row?.platform?.toLowerCase().includes('mac') || row?.platform?.toLowerCase().includes('os x')) ? row?.platform : row?.platform?.toLowerCase().replace(/./, c => c.toUpperCase()),
                                            platformVersion: row.platformVersion,
                                            browser: row.browser?.toLowerCase().replace(/headful|headless/g, '').replace('()', '').trim() || '',
                                            browserVersion: row.browserVersion || '', 
                                            appiumVersion: row.appiumVersion,
                                            deviceName: row.device || row.deviceName || '',
                                            deviceOrientation: row.orientation || '',
                                            extraCapabilities: row.options?.filter(option => (option?.key?.length || option?.value?.length)) || [],
                                            autoAcceptAlerts : row.autoAcceptAlerts || false,
                                            autoGrantPermission: row.autoGrantPermissions || false,
                                            enableAnimations: row.enableAnimations || false,
                                            sauceConnectId: row.sauceConnectId || row.tunnelId || '',
                                            deviceType:row.deviceType || row.type || ''
                                         }
                                        if (row.sauceConnectId || row.tunnelID) {
                                            newObj.sauceConnectId = row.sauceConnectId || row.tunnelID
                                        }
                                        if(newObj.sauceConnectId === 'None') {
                                            newObj.sauceConnectId = ''
                                        }
                                        return newObj
                                    })
                                    localStorage.setItem('lastRunConfiguration', JSON.stringify(exec_details))
                                }

                                const suite = tabsData?.job?.state?.suites?.find(suite => suite.id === tabsData.job.state.selected_suites[0])
                                const project = projectsByIdAndName?.find(project => project.projectId === suite.projectId)
                                const data = {
                                    name: checkKeyInObject(tabsData, 'job.state.jobName', 'value', ''),
                                    descrption: '',
                                    object_id: objectId,
                                    object_name: suite.name,
                                    project_id: checkKeyInObject(tabsData, 'job.state.selectedValues') ? checkKeyInObject(tabsData, 'job.state.selectedValues') : 0,
                                    project_name: project.projectName, 
                                    object_type: 2,
                                    start_time: checkKeyInObject(tabsData, 'schedule.state.startTime')
                                        ? tabsData.schedule.state.startTime.toISOString()
                                        : '',
                                    end_time: checkKeyInObject(tabsData, 'schedule.state.endTime')
                                        ? tabsData.schedule.state.endTime.toISOString()
                                        : '',
                                    schedule_interval: getTimeInHours(checkKeyInObject(tabsData, 'schedule.state.schedulingData', 'value', [])),
                                    execution_mode: checkKeyInObject(tabsData, 'schedule.state.selectedExecutionMode', 'value', ''),
                                    remote_driver: checkKeyInObject(tabsData, 'schedule.state.use_remote_driver', 'value', true)
                                        ? tabsData.schedule.state.url
                                        : '',
                                    time_unit: 'hours',
                                    is_paused: updateData.is_paused ? updateData.is_paused : false,
                                    exec_details,
                                };
                                if (type === 'Update') {
                                    const id = checkKeyInObject(updateData, 'schedule_id') ? updateData.schedule_id : 0;
                                    this.props.updateJob(id, JSON.stringify(data));
                                    const eventName = SEGMENT_EVENT.UPDATE_SCHEDULE;
                                    const segmentData = {...data}
                                    track(eventName,segmentData);
                                    
                                } else {
                                    const eventName = SEGMENT_EVENT.CREATE_SCHEDULE;
                                    const segmentData = {...data}
                                    track(eventName,segmentData);

                                    this.props.addJob(JSON.stringify(data));
                                }
                                this.closeModal();
                            },
                            isDisabled: (tabsData) => {
                                return (
                                    getTimeInHours(checkKeyInObject(tabsData, 'schedule.state.schedulingData', 'value', [])) < 1 ||
                                    checkKeyInObject(tabsData, 'schedule.state.startTime') >= checkKeyInObject(tabsData, 'schedule.state.endTime') ||
                                    !checkArrayLength(checkKeyInObject(tabsData, 'job.state.selected_suites'))  || this.validateDuplicates(tabsData) ||  checkArrayLength(checkKeyInObject(tabsData, 'schedule.state.deviceNotAvailable')) ||
                                    !checkKeyInObject(tabsData, 'job.state.selectedValues')|| !(checkArrayLength(checkKeyInObject(tabsData, 'schedule.state.sauceContent')) ||checkArrayLength(checkKeyInObject(tabsData, 'schedule.state.externalGrid')) || checkArrayLength(checkKeyInObject(tabsData, 'schedule.state.localContent')))
                                );
                            },
                        },
                        {
                            name: 'Cancel',
                            variant: 'outlined',
                            style: { ...MODAL_CANCEL_BUTTON },
                            action: () => {
                                this.closeModal();
                            },
                            isDisabled: false,
                        },
                    ],
                },
            ],
        });
    };

    validateDuplicates = (tabsData ) => {
        const { localContent, sauceContent } = tabsData.schedule.state
        let duplicateSauceObjects = [];
        let duplicateLocalObjects = [];
        // let seen = new Set();
        // var hasDuplicates = sauceContent.some(function(currentObject) {
        //     return seen.size === seen.add(currentObject.id).size;
        // });

        const duplicateIds = sauceContent.map(v => v.id).filter((v, i, vIds) => vIds.indexOf(v) !== i)
        const duplicates = sauceContent.filter(obj => duplicateIds.includes(obj.id));
        console.log(duplicates)

        duplicateSauceObjects = duplicates.length ?  duplicates[0] : {};

            for (let i = 0; i < localContent.length; i++) {
                const objToCheck = localContent[i]
                const localDuplicates = localContent.filter(val => val.browser === objToCheck.browser && val.platform === objToCheck.platform)
                if (localDuplicates.length > 1) {
                    duplicateLocalObjects = objToCheck;
                } else {
                    duplicateLocalObjects = {};
                }
            }

            if(Object.keys(duplicateSauceObjects).length > 0){
                return true
            }else if(Object.keys(duplicateLocalObjects).length > 0){
                return true
            }
            
                return false;
            
    }

    validateExternalGrid = (tabsData) => {
        const { externalGrid } = tabsData.schedule.state
        const isEmpty = externalGrid.some((elem) => !elem.platform.length || !elem.platformVersion.length || !elem.browser.length)
       return isEmpty
    }
    updateSchedules(schedules) {
        if (checkArrayLength(schedules)) {
            let tempSchedules = JSON.parse(JSON.stringify(schedules));
            tempSchedules = tempSchedules.map((schedule, index) => {
                let projectDetail;
                let month;
                let days;
                let hours = 0;
                let detailsInterval = '';
                let schedulingDataInterval = [];
                if (schedule.schedule_interval > 0) {
                    hours = schedule.schedule_interval;
                    month = parseInt(hours / 730, 10);
                    hours %= 730;
                    days = parseInt(hours / 24, 10);
                    hours %= 24;
                    if (hours || days || month) {
                        let h;
                        let m;
                        let d = '';
                        if (month) {
                            m = `${month} month `;
                            detailsInterval = m;
                        }
                        if (days) {
                            d = `${days} days `;
                            detailsInterval += d;
                        }
                        if (hours) {
                            h = `${hours} hours `;
                            detailsInterval += h;
                        }
                    }
                    schedulingDataInterval = detailsInterval;
                }
                if (checkArrayLength(schedule.objects)) {
                    schedule.objects.forEach((obj) => {
                        const pjtIndex = projectDetail.map((pjt) => pjt.projectId).indexOf(obj.project_id);
                        if (pjtIndex >= 0) {
                            projectDetail[pjtIndex].objDetails.push({
                                type: obj.object_type === 1 ? 'cases' : obj.object_type === 2 ? 'suites' : obj.object_type === 3 ? 'tags' : '',
                                name: obj.object_name,
                                id: obj.object_id,
                            });
                        } else {
                            projectDetail.push({
                                id: `${schedule.schedule_id}_${index}`,
                                projectName: obj.project_name,
                                projectId: obj.project_id,
                                schedules: [
                                    `Runs every ${schedule.run_interval} hours since ${getFormatedDate(
                                        schedule.start_time,
                                        'DD/MM/YYYY h:mm a',
                                        'N/A',
                                    )}`,
                                    `Stop on ${getFormatedDate(schedule.last_run_time, 'DD/MM/YYYY h:mm a', 'N/A')}`,
                                ],
                            });
                        }
                    });
                }
                return { ...schedule, projectDetail, schedulingDataInterval };
            });

            this.setState({ schedules: tempSchedules });
        }
    }




    assignToMe =  () => {
        const { user, getExecutionDetailsByScheduleId, projectsByIdAndName } = this.props;
        const { assignSchedule } = this.state;
        let _exec_details= [];
        let execution_mode = '';
        const updateData = assignSchedule;
        getExecutionDetailsByScheduleId(assignSchedule.schedule_id).then((res)=>{
            if(res && res.payload && res.payload.data){
                // console.log("res",res.payload);
                execution_mode = res.payload.data.executionMode ? res.payload.data.executionMode : '';
                _exec_details = res.payload.data.pbDetails.map((row, index) => {
                    const newObj = {
                    environmentType: row.environmentType.toLowerCase(),
                    platform: (row?.platform?.toLowerCase().includes('mac') || row?.platform?.toLowerCase().includes('os x')) ? row?.platform : row?.platform?.toLowerCase().replace(/./, c => c.toUpperCase()),
                    platformVersion: row.platformVersion,
                    browser: row.browser.toLowerCase().replace(/headful|headless/g, '').replace('()', '').trim(),
                    browserVersion: row.browserVersion,
                    appiumVersion: row.appiumVersion,
                    deviceName: row.deviceName,
                    deviceOrientation: row.deviceOrientation,
                    }
                    if (row.tunnelID) {
                    newObj.tunnelID = row.tunnelID
                    }
                    return newObj
                })

            } else if(res && res.payload && res.payload.error) {
                this.props.showSnackBar(`not able to get schedule for ${assignSchedule.schedule_id}`, '', false, 8000, true);
            }
        })
        const project = projectsByIdAndName?.find(project => project.projectId === updateData.projectId)
        const data = {
            object_name: updateData.testSuiteName,
            name:updateData.name,
            descrption: '',
            object_id: updateData.testSuiteId,
            object_type: 2,
            start_time: updateData.start_time,
            end_time: updateData.end_time,
            project_id: updateData.projectId,
            project_name: project ? project.projectName: '', 
            schedule_interval: updateData.schedule_interval,
            execution_mode: execution_mode ? execution_mode == 0 ? 'Parallel' : 'Serial' : '',
            time_unit: 'hours',
            is_paused: updateData.is_paused ? updateData.is_paused : false,
            exec_details:_exec_details
        };
        return <ConfirmAssignModal 
                    title="Assign to Me"
                    message= {`Are you sure to assign '${user.name}' the owner of the schedule ?`}
                    confirmButtonText = "Assign"
                    handleClose =  {(submit)=>{
                            this.setState({
                            AssigntoLoggedInPopUp:false,
                            },()=>{
                                if(submit) {
                            const id = checkKeyInObject(updateData, 'schedule_id') ? updateData.schedule_id : 0;
                            this.props.updateJob(id, JSON.stringify(data));
                                }
                            })
                    }}
                />
            
        
    }

    routeTestCase = (projectId, testCaseId) => {
        const { history } = this.props;
        history.push({
            pathname: `/projects/${projectId}/${testCaseId}`,
        });
    };

    handleStoreState = () => {
        this.props.storeSuiteState({
            order: this.state.order,
            orderBy: this.state.orderBy,
            page: this.state.page,
            rowExpendStatus: this.state.rowExpendStatus,
            rowsPerPage: this.state.rowsPerPage,
        });
    };

    tableBodyRender = () => {
        const { classes, query = '', isLoading, suiteLoading, user } = this.props;
        const { order, orderBy, rowsPerPage, page, rowExpendStatus, schedules, isCaseBtnsDisable, testCasesReady,testCases } = this.state;
        let scheduleArray = schedules;
        if (query && checkArrayLength(scheduleArray)) {
            scheduleArray = scheduleArray.filter(
                (schedule) =>
                    schedule.name.toLowerCase().includes(query.toLowerCase()) || schedule.owner_user_Name.toLowerCase().includes(query.toLowerCase()) || schedule.testSuiteName.toLowerCase().includes(query.toLowerCase()),
            );
        }

        scheduleArray.map((schedule,index)=>{
            schedule.name = schedule.name !== '' ? schedule.name : schedule.testSuiteName ;
            return schedule;
        })
        const currentPageSchedules = sort(scheduleArray, order, orderBy, orderBy === 'start_time' || orderBy === 'end_time' ? 'time' : '').slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        );

        const _isLoading = suiteLoading || isLoading;
        return (
            <div className={classes.root}>
                <div
                    style={checkArrayLength(scheduleArray) || _isLoading ? { minHeight: 'calc(100vh - 152px)' } : {}}
                    className={`${classes.wrapperTable} ${classes.maxHeight}`}
                >
                    <div className={classes.tableWrapper}>
                        {checkArrayLength(scheduleArray) && !_isLoading ? (
                            <Table id="myTbl" aria-labelledby="">
                                <Hidden only={['xs']}>
                                    <EnhancedTableHead
                                        headers={headers}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        currentPageData={currentPageSchedules}
                                        rowCount={currentPageSchedules.length}
                                        isColSpan
                                        colsWidth={this.colsWidth}
                                        resizeable
                                    />
                                </Hidden>
                                <Hidden smUp>
                                    <EnhancedTableHead
                                        headers={headers}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        currentPageData={currentPageSchedules}
                                        rowCount={currentPageSchedules.length}
                                        isColSpan
                                        colsWidth={this.colsWidth}
                                    />
                                </Hidden>
                                <TableBody style={{ maxHeight: 'calc(100vh - 188px)' }}>
                                    {currentPageSchedules.map((schedule, i) => {
                                        const _row = [];
                                        _row.push(
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={i}
                                                className={classes.tableRows}
                                                style={{ height: '40px', backgroundColor: '#f9f9f9' }}
                                            >
                                                <TableCell
                                                    className={[
                                                        rowExpendStatus[0] === schedule.schedule_id && rowExpendStatus[1]
                                                            ? classes.currentCell
                                                            : classes.cell,
                                                        classes.noWrap,
                                                        classes.minWidth,
                                                    ].join(' ')}
                                                    width={headers[0].width}
                                                    data-resize="element0_c"
                                                >
                                                    <div style={{ width: '100%' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {rowExpendStatus[0] === schedule.schedule_id && rowExpendStatus[1]
                                                                ? this.currentRowIndicator()
                                                                : null}
                                                            {!schedule.is_suite_executable &&
                                                                <Tooltip data="This scheduled run is paused as it contains ungenerated test cases. You can generate test cases from the test suite." popperStyle={{maxWidth: 200, lineHeight: 1.5}} toolTipAreaStyle={{margin: '4px 10px 0 -2px'}} >
                                                                    <WarningIcon style={{fontSize: 15, color: '#F39B31'}}/>
                                                                </Tooltip>
                                                            }
                                                            <Typography
                                                                noWrap
                                                                className={classes.noWrap}
                                                                style={{
                                                                    color: '#000000',
                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                    // display: 'flex',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                <Tooltip data={schedule.name ? schedule.name : null}>
                                                                    {schedule.name ? schedule.name : schedule.testSuiteName}
                                                                </Tooltip>
                                                            </Typography>

                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[1].width}
                                                    data-resize="element1_c"
                                                >
                                                    <div style={{ width: '100%' }}>
                                                        <Typography
                                                            noWrap
                                                            className={classes.noWrap}
                                                            style={{
                                                                color: '#000000',
                                                                fontSize: TABLES_CSS.normalFontSize,
                                                                display: 'inline-block',
                                                            }}
                                                        >
                                                            <Tooltip data={schedule.testSuiteName ? schedule.testSuiteName : null}>
                                                                {schedule.testSuiteName}
                                                            </Tooltip>
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[2].width}
                                                    data-resize="element2_c"
                                                >
                                                    <div style={{ width: '100%' }}>
                                                        <Typography
                                                            noWrap
                                                            className={classes.noWrap}
                                                            style={{
                                                                color: '#000000',
                                                                fontSize: TABLES_CSS.normalFontSize,
                                                                display: 'inline-block',
                                                            }}
                                                        >
                                                            <Tooltip data={schedule.testSuiteName ? schedule.testSuiteName : null}>
                                                                {schedule.owner_user_Name ? schedule.owner_user_Name : ''}
                                                            </Tooltip>
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[3].width}
                                                    data-resize="element3_c"
                                                >
                                                    <div style={{ width: '100%' }}>
                                                        <Typography
                                                            noWrap
                                                            className={classes.noWrap}
                                                            style={{
                                                                color: '#000000',
                                                                fontSize: TABLES_CSS.normalFontSize,
                                                                display: 'inline-block',
                                                            }}
                                                        >
                                                            {checkKeyInObject(user, 'isRelativeDateFormat')
                                                                ? getRelativeTime(schedule.start_time)
                                                                : getFormatedDate(schedule.start_time, 'DD MMM YYYY', '')}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[4].width}
                                                    data-resize="element4_c"
                                                >
                                                    <div style={{ width: '100%' }}>
                                                        <Typography
                                                            noWrap
                                                            className={classes.noWrap}
                                                            style={{
                                                                color: '#000000',
                                                                fontSize: TABLES_CSS.normalFontSize,
                                                                display: 'inline-block',
                                                            }}
                                                        >
                                                            {checkKeyInObject(user, 'isRelativeDateFormat')
                                                                ? getRelativeTime(schedule.end_time)
                                                                : getFormatedDate(schedule.end_time, 'DD MMM YYYY', '')}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[5].width}
                                                    data-resize="element5_c"
                                                >
                                                    <div style={{ width: '100%' }}>
                                                        <Typography
                                                            noWrap
                                                            className={classes.noWrap}
                                                            style={{
                                                                color: '#000000',
                                                                fontSize: TABLES_CSS.normalFontSize,
                                                                display: 'inline-block',
                                                            }}
                                                        >
                                                            {schedule.schedulingDataInterval}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[6].width}
                                                    data-resize="element6_c"
                                                >
                                                    <div style={{ width: '100%' }}>
                                                        <Link to={`/reports/suite/${schedule.testSuiteId}`}>
                                                            <div
                                                                style={{
                                                                    backgroundColor: '#1BABAB',
                                                                    height: '22px',
                                                                    width: '22px',
                                                                    borderRadius: '50px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <History
                                                                    style={{ fontSize: '16px', color: '#fff' }}
                                                                    aria-label="historyIcon"
                                                                    id="historyIcon"
                                                                />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </TableCell>

                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[7].width}
                                                    data-resize="element7_c"
                                                >
                                                    <div className={classes.wrapperAction}>
                                                        <Tooltip data={this.props.user.name == schedule.owner_user_Name ? '' : 'Assign To Me'}>
                                                            <IconButton
                                                                className={`${classes.iconBtn}`}
                                                                style={{cursor : this.props.user.name != schedule.owner_user_Name ? 'pointer' : 'default'}}
                                                                aria-label="Assign"
                                                                onClick={() => {
                                                                    this.props.user.name != schedule.owner_user_Name ? this.setState({ AssigntoLoggedInPopUp: true, assignSchedule: schedule }) : void(0);
                                                                }}
                                                            >
                                                                <AccountArrowLeft className={`${classes.svg} ${this.props.user.name == schedule.owner_user_Name ? classes.disabledBtnColor : classes.btnColor} `} />
                                                             </IconButton>
                                                        </Tooltip>
                                                        <Tooltip data={schedule.is_paused ? 'Play' : 'Pause'}>
                                                            <IconButton
                                                                className={classes.iconBtn}
                                                                aria-label="Play"
                                                                onClick={() => {
                                                                    this.props.excuteScedule(schedule.schedule_id, schedule.is_paused ? 0 : 1);
                                                                }}
                                                                disabled={!schedule.is_suite_executable}
                                                            >
                                                                {schedule.is_paused ? (
                                                                    <PlayCircleOutline
                                                                        className={classes.stopPlayIcons}
                                                                        aria-label="playCircleOutkineIcon"
                                                                        id="playCircleOutkineIcon"
                                                                        style={{color: !schedule.is_suite_executable ? '#bbbbbb' : ''}}
                                                                    />
                                                                ) : (
                                                                    <PauseCircleOutline
                                                                        className={classes.stopPlayIcons}
                                                                        aria-label="pauseCircleOutkineIcon"
                                                                        id="pauseCircleOutkineIcon"
                                                                        style={{color: !schedule.is_suite_executable ? '#bbbbbb' : ''}}
                                                                    />
                                                                )}
                                                            </IconButton>

                                                        </Tooltip>
                                                        <MenuOptions items={this.renderItems(schedule)} keepMounted />
                                                        <ExpandBtn
                                                            flag={rowExpendStatus[0] === schedule.schedule_id && rowExpendStatus[1]}
                                                            action={() => this.handleExpend(schedule.schedule_id,schedule.latest_job_id)}
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>,
                                        );
                                        if (rowExpendStatus[0] === schedule.schedule_id && rowExpendStatus[1]) {
                                            _row.push(
                                                <TableRow
                                                    style={{ height: '40px', background: TABLES_CSS.subHeaderRowColor }}
                                                    key={`${schedule.schedule_id}-subHeader`}
                                                >
                                                    {subHeaders.map((column, ind) => (
                                                        <TableCell
                                                            className={[classes.cell, classes.noWrap].join(' ')}
                                                            width={column.width}
                                                            data-sub-header="subHeader"
                                                            data-child-resize={`${ind}_${column.parentIndex}_${headers[column.parentIndex].colSpan}`}
                                                            key={`testCase-${column.id}`}
                                                        >
                                                            <div style={{ width: '100%' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <div className={classes.nonSortableContainer} key={column.id}>
                                                                        <Typography
                                                                            variant="body2"
                                                                            style={{
                                                                                fontSize: TABLES_CSS.headingFontSize,
                                                                                color: TABLES_CSS.headingFontColor,
                                                                                fontWeight: TABLES_CSS.headingFontWeight,
                                                                            }}
                                                                            noWrap
                                                                        >
                                                                            {column.label}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>,
                                            );
                                        }
                                        if (rowExpendStatus[0] === schedule.schedule_id && rowExpendStatus[1]) {
                                            if(testCasesReady) {

                                                if (testCases[schedule.latest_job_id] && testCases[schedule.latest_job_id].length > 0) {
                                                    _row.push(
                                                        testCases[schedule.latest_job_id].map((testCase) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={-1}
                                                                    style={{
                                                                        height: '40px',
                                                                        display:
                                                                            rowExpendStatus[0] === schedule.testSuiteId && rowExpendStatus[1]
                                                                                ? 'table-row'
                                                                                : 'none',
                                                                    }}
                                                                    className={classes.tableRows1}
                                                                    key={`${schedule.testSuiteId}${testCase.id}`}
                                                                >
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap].join(' ')}
                                                                        width={subHeaders[0].width}
                                                                        data-child-resize={`${0}_${subHeaders[0].parentIndex}_${
                                                                            headers[subHeaders[0].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <div className={classes.wrapperContent}>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Typography
                                                                                    noWrap
                                                                                    style={{
                                                                                        fontSize: TABLES_CSS.normalFontSize,
                                                                                        color: TABLES_CSS.normalFontColor,
                                                                                        display: 'inline-block',
                                                                                    }}
                                                                                    className={classes.noWrap}
                                                                                >
                                                                                    <Tooltip data={testCase.name ? testCase.name : null}>
                                                                                        {testCase.testcaseName}
                                                                                    </Tooltip>
                                                                                </Typography>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap].join(' ')}
                                                                        width={subHeaders[1].width}
                                                                        data-child-resize={`${1}_${subHeaders[1].parentIndex}_${
                                                                            headers[subHeaders[1].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <div className={classes.wrapperContent}>
                                                                            <Typography
                                                                                noWrap
                                                                                style={{
                                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                                    color: TABLES_CSS.normalFontColor,
                                                                                    display: 'inline-block',
                                                                                }}
                                                                            ></Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap].join(' ')}
                                                                        width={subHeaders[2].width}
                                                                        data-child-resize={`${2}_${subHeaders[2].parentIndex}_${
                                                                            headers[subHeaders[2].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <div className={classes.wrapperContent}>
                                                                            <Typography
                                                                                noWrap
                                                                                style={{
                                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                                    color: TABLES_CSS.normalFontColor,
                                                                                    display: 'inline-block',
                                                                                    
                                                                                }}
                                                                            >
                                                                                {checkKeyInObject(testCase, 'executionDate') ? checkKeyInObject(user, 'isRelativeDateFormat')
                                                                                    ? getRelativeTime(moment(testCase.executionDate).toISOString())
                                                                                    : getFormatedDate(moment(testCase.executionDate).toISOString(), 'DD MMM YYYY', '') : 'NA'}
                                                                            </Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap].join(' ')}
                                                                        width={subHeaders[3].width}
                                                                        data-child-resize={`${3}_${subHeaders[3].parentIndex}_${
                                                                            headers[subHeaders[3].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <div className={classes.wrapperContent}>
                                                                            <Typography
                                                                                noWrap
                                                                                style={{
                                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                                    color: TABLES_CSS.normalFontColor,
                                                                                    display: 'inline-block',
                                                                                }}
                                                                            ></Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap].join(' ')}
                                                                        width={subHeaders[4].width}
                                                                        data-child-resize={`${4}_${subHeaders[4].parentIndex}_${
                                                                            headers[subHeaders[4].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <div className={classes.wrapperContent}>
                                                                            <Typography
                                                                                noWrap
                                                                                style={{
                                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                                    color: TABLES_CSS.normalFontColor,
                                                                                    display: 'inline-block',
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                { checkKeyInObject(testCase, 'executionStatus')  ? testCase.executionStatus === 'ERROR'
                                                                                    ? 'FAILED'
                                                                                    : testCase.executionStatus : 'NA'}
                                                                            </Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap].join(' ')}
                                                                        width={subHeaders[5].width}
                                                                        data-child-resize={`${5}_${subHeaders[5].parentIndex}_${
                                                                            headers[subHeaders[5].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <div className={classes.wrapperContent}>
                                                                            <Typography
                                                                                noWrap
                                                                                style={{
                                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                                    color: TABLES_CSS.normalFontColor,
                                                                                    display: 'inline-block',
                                                                                }}
                                                                            ></Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap].join(' ')}
                                                                        width={subHeaders[6].width}
                                                                        data-child-resize={`${6}_${subHeaders[6].parentIndex}_${
                                                                            headers[subHeaders[6].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <div className={classes.wrapperContent}>
                                                                            <Typography
                                                                                noWrap
                                                                                style={{
                                                                                    fontSize: TABLES_CSS.normalFontSize,
                                                                                    color: TABLES_CSS.normalFontColor,
                                                                                    display: 'inline-block',
                                                                                }}
                                                                            ></Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={[classes.cell, classes.noWrap].join(' ')}
                                                                        width={subHeaders[7].width}
                                                                        data-child-resize={`${7}_${subHeaders[7].parentIndex}_${
                                                                            headers[subHeaders[7].parentIndex].colSpan
                                                                        }`}
                                                                    >
                                                                        <SuiteActions
                                                                            isCaseBtnsDisable={isCaseBtnsDisable}
                                                                            removeDeleteCase
                                                                            executionRoute={
                                                                                checkKeyInObject(testCase, 'testexecutionId', 'value', 0)
                                                                                    ? `/projects/${schedule.projectId}/${testCase.testcaseId}/executions/${testCase.testexecutionId}`
                                                                                    : null
                                                                            }
                                                                            goToTestCase={() => {
                                                                                this.handleStoreState();
                                                                            }}
                                                                            testCaseRoute={`/projects/${schedule.projectId}/${testCase.testcaseId}`}
                                                                            testCase={testCase}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }),
                                                    );
                                                } else {
                                                    _row.push(
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            style={{
                                                                height: '40px',
                                                                display:
                                                                    rowExpendStatus[0] === schedule.schedule_id && rowExpendStatus[1]
                                                                        ? 'table-row'
                                                                        : 'none',
                                                            }}
                                                            key={`${schedule.schedule_id}empty`}
                                                            className={classes.tableRows1}
                                                        >
                                                            <TableCell className={classes.cell} width="100%">
                                                                <Typography
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        color: TABLES_CSS.normalFontColor,
                                                                        width: '100%',
                                                                        padding: 0,
                                                                        margin: 0,
                                                                    }}
                                                                >
                                                                    No Test Cases are available.
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>,
                                                    );
                                                }

                                            } else {

                                                _row.push(
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        style={{
                                                            height: '50px'
                                                        }}
                                                        key={`${schedule.schedule_id}empty`}
                                                        className={classes.tableRows1}
                                                    >
                                                        <TableCell className={classes.cell} width="100%">
                                                            <Typography
                                                                style={{
                                                                    textAlign: 'center',
                                                                   // color: TABLES_CSS.normalFontColor,
                                                                    width: '100%',
                                                                    padding: 0,
                                                                    margin: 0,
                                                                }}
                                                            >
                                                            <CircularProgress className={classes.circularPrgress} size={18} thickness={3} />
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>,
                                                );

                                            }
                                        }

                                        return [..._row];
                                    })}
                                </TableBody>
                            </Table>
                        ) : (
                            <div className={`${classes.noDataText} ${classes.maxHeight}`}>
                                {!_isLoading ? (
                                    <>
                                        <Calendar className={classes.fileIcon} />
                                        <Typography className={classes.notAvailable}>Schedule does not exist!</Typography>
                                    </>
                                ) : (
                                    <CircularProgress className={classes.circularPrgress} size={50} thickness={3} />
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <TableFooter
                    count={scheduleArray.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    labelRowsPerPage="Schedules per page:"
                    labelForCounter="Schedule"
                    countAvatar={getCountAvatar(scheduleArray.length)}
                    containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                />
            </div>
        );
    };

    renderItems(schedule) {
        const { classes, getSchedules } = this.props;
        const items = [
            {
                action: () => {
                    this.toggleModal('Update', schedule);
                },
                icon: <Edit aria-label="editScheduleIcon" id="editScheduleIcon" className={classes.moreIcons} />,
                isDisabled: false,
                name: ' Update Schedule',
            },
            {
                action: () => {
                    this.handleDelete(schedule.schedule_id, getSchedules, schedule.name);
                },
                icon: <Delete aria-label="deleteScheduleIcon" id="deleteScheduleIcon" className={classes.moreIcons} />,
                isDisabled: false,
                name: 'Delete Schedule',
            },
        ];
        return items;
    }

    render() {
        const { classes, openTaskPanel } = this.props;
        const { AssigntoLoggedInPopUp } = this.state;

        return (
            <div>
                <div style={{ marginLeft: openTaskPanel ? '32px' : '10px' }} className={classes.headerContainer}>
                    <div style={{ zIndex: 2 }}>
                        <Tooltip data="Add Schedule">
                            <div>
                                <span
                                    aria-hidden
                                    className={classes.addProject}
                                    onClick={() => {
                                        this.toggleModal('Add');
                                    }}
                                >
                                    <FontAwesomeIcon className={classes.plus} icon={faPlus} aria-label="faPlus" id="faPlus" />
                                </span>
                            </div>
                        </Tooltip>
                    </div>
                </div>
                {this.tableBodyRender()}
                {
                    AssigntoLoggedInPopUp ?
                    this.assignToMe() : void(0)
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        // auth reducer
        user: state.authReducer.user,
        // general reducer
        query: state.generalReducer.queryValue,
        // schedule reducer
        isLoading: state.scheduleReducer.isLoading,
        suiteLoading: state.scheduleReducer.suiteLoading,
        schedules: state.scheduleReducer.schedules,
        // General Modal reducer
        currentTab: state.generalModalReducer.currentTab,
        tabsData: state.generalModalReducer.tabsData,
        openTaskPanel: state.generalReducer.openTaskPanel,
        sauceCredentialDetails:state.accountReducer.sauceDetails,
        projectsByIdAndName:state.projectReducer.projectsByIdAndName,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // Schedule Actions
        getSchedules: () => dispatch(ScheduleActions.getSchedules()),
        deleteSchedule: (...args) => dispatch(ScheduleActions.deleteSchedule(...args)),
        excuteScedule: (...args) => dispatch(ScheduleActions.excuteScedule(...args)),

        // Modal Action
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),

        // General Actions
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        // General Modal Actions
        clearGeneralModal: (...args) => dispatch(generalModalActions.clearGeneralModal(...args)),
        changeTab: (...args) => dispatch(generalModalActions.changeTab(...args)),
        getTestSuitesForSchedules: () => dispatch(ScheduleActions.getTestSuitesForSchedules()),
        updateJob: (...args) => dispatch(ScheduleActions.UpdateJob(...args)),
        addJob: (...args) => dispatch(ScheduleActions.addJob(...args)),
        storeSuiteState: (states) => dispatch(ProjectActions.storeSuiteState(states)),
        getProjectByIdAndName: (...args) => dispatch(ProjectActions.getProjectByIdAndName(...args)),
        getTestCasesByJobId: (jobId) => dispatch(ScheduleActions.getTestCasesByJobId(jobId)),
        getExecutionDetailsByScheduleId: (scheduleId) => dispatch(ProjectActions.getExecutionDetailsByScheduleId(scheduleId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScheduledSuites));
