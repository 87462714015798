import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { BLACK_FONT } from '../../common/cssConstants';

const styles = () => ({
    text: {
        fontSize: 12,
        color: BLACK_FONT,
        textAlign: 'center',
    },
});

function TaskMessageTile(props) {
    const { classes, message } = props;
    return (
        <Grid container spacing={1}>
            <Grid item xs={1} sm={1} md={2} lg={2} />
            <Grid item xs={9} sm={9} md={8} lg={8}>
                <Typography noWrap className={classes.text}>
                    {message}
                </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} />
        </Grid>
    );
}

TaskMessageTile.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    message: PropTypes.string,
};

TaskMessageTile.defaultProps = {
    message: 'Loading...',
};

export default withStyles(styles)(TaskMessageTile);
