// import materials
import { withStyles } from '@material-ui/core/styles';
import { Typography, Hidden } from '@material-ui/core';

// import packages
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import React from 'react';

// import custom container
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { ProjectUtils } from '../../utils/ProjectUtils';
import { TABLE_PAGE_HEADING, TOOLBAR_COUNT_AVATAR } from '../../common/cssConstants';
import { GeneralActions, ModalActions, ProjectActions, SidebarActions } from '../../store/actions';
import {
    checkArrayLength,
    checkKeyInObject,
    sort,
    getCountAvatar,
    addUserPreference,
    getUserPreference,
    getParamValues,
    checkIsArray,
} from '../../utils/utils';
import ProjectsActions from '../../store/actions/projectsActions';
import {AccountActions} from '../../store/actions';
import TablePagination from '../Pagination/TablePagination';
import ProjectCardLoader from './ProjectCardLoader';
import ProjectsToolbar from './ProjectsToolbar';
import ProjectsTable from './ProjectsTable';
import ProjectCard from './ProjectCard';
import CustomButton from '../Button/CustomButton';
import OverallHealth from '../../features/overhealth';
import NoSearchResultFoundMessage from '../NoSearchResultFoundMessage';

const styles = (theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 3,
        maxHeight: 'calc(100vh - 150px) !important',
        overflowY: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.only('xs')]: {
            maxHeight: 'calc(100vh - 105px) !important',
            justifyContent: 'center',
        },
    },
    piImage: {
        width: '70px',
        height: '80px',
        border: '0px solid green',
        marginTop: '20px',
        margin: 'auto',
    },
    notExistColor: {
        color: '#78909c',
        textAlign: 'center',
        marginBottom: 12,
        fontSize: '20px',
    },
    root: {
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    titleProject: {
        flex: 1,
        color: TABLE_PAGE_HEADING.fontColor,
        fontSize: '12px',
        fontWeight: 'bold',
    },
    toolbarCountAvatar: { ...TOOLBAR_COUNT_AVATAR, fontSize: 12, fontWeight: 'bold' },
    addProject: {
        display: 'flex',
        // backgroundColor: '#1babab',
        // alignItems: 'center',
        // justifyContent: 'center',
        // borderRadius: '100%',
        // cursor: 'pointer',
        // boxShadow: '0 0 3px 0 rgba(0,0,0,0.12), 0 3px 3px 0 rgba(0,0,0,0.24)',
        position: 'fixed',
        top: '57px',
    },
    plus: {
        color: '#fff',
        fontSize: '12px',
    },
    paginationWidth: {
        width: '30%',
        marginRight: '25px',
        [theme.breakpoints.only('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.only('md')]: {
            width: '40%',
        },
        [theme.breakpoints.only('xs')]: {
            width: '55%',
        },
    },
    addProjectCircle: {
        color: '#78909c',
        textAlign: 'center',
        marginBottom: 12,
        fontSize: '14px',
    },
    addprojectMidCircle: {
        width: '32px',
        height: '32px',
        cursor: 'pointer',
        display: 'flex',
        boxShadow: 'rgba(0, 0, 0, 0.65) 0px 2px 4px 0px',
        alignItems: 'center',
        borderRadius: '100%',
        justifyContent: 'center',
        backgroundColor: '#1babab',
        margin: '0 10px',
    },
    footerStyles: {
        width: '100%',
        backgroundColor: '#fff',
        height: 50,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
    newProjectText: {
        marginLeft:'8px',
        textTransform:'none'
    }
});

class ProjectsGridList extends React.Component {
    constructor(props) {
        super(props);
        this.isRecentlyLoaded = false;
        this.is_mounted = false;
        this.isStarredProjectsMounted = false;
        this.state = {
            projects: props.projects,
            cardWidth: '100%',
            cardsColsCount: 0,
            page: 0,
            rowsPerPage: 12,
            listView: false,
            order: 'asc',
            orderBy: 'lastActivityDate',
            paginationCount: [],
            isWidthShort: false,
        };
        props.updateTitle('Projects');
    }

    /* Component life cycle start */
    componentDidMount() {
        this.is_mounted = true;
        const { getSideBarDataCount, sideBarCount, getProjectByIdAndName, getPermissions } = this.props;
        this.changeProjectType(getParamValues(1) || 'All');
        getPermissions();

        if (sideBarCount && sideBarCount.num_of_projects === 0) {
            // check if side_bar count has no value
            getSideBarDataCount(); // to get side_bar data on mobile projects screen
        }

        getProjectByIdAndName();
        this.calculateWidth();
        window.addEventListener('resize', this.calculateWidth);
        const width = window.innerWidth;
        if (width < 600) {
            this.updateState({ isWidthShort: true });
        }
        const isListView = getUserPreference('project_listview');
        const rowsPerPage = getUserPreference(`project_${isListView ? 'listview' : 'gridview'}_rowsPerPage`);
        this.setStateIfComponentMounted({
            listView: isListView || false,
            rowsPerPage: rowsPerPage || 10,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { projects, disabledProjects, showDisabledProjects, showFavouriteProjects, favouriteProjects } = nextProps;
        const searchedProject = this.getSearchedProjectNames(nextProps);
        const localProjectsCopy = showDisabledProjects ? disabledProjects : showFavouriteProjects ? favouriteProjects : projects;
        let filteredProjects = localProjectsCopy;
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.changeProjectType(getParamValues(1) || 'All');
        }
        if(checkIsArray(searchedProject) &&  !checkArrayLength(searchedProject)) {
            let prevPageNo = parseInt( this.state.pageNo || 0)
                this.setState({
                    page:prevPageNo
                })
        }

        if (checkArrayLength(searchedProject) && checkArrayLength(localProjectsCopy)) {
            filteredProjects = localProjectsCopy.filter(
                (project) => checkKeyInObject(project, 'projectName') && searchedProject.includes(project.projectName),
            );
        }

        filteredProjects = checkArrayLength(filteredProjects)
            ? filteredProjects.map((proj) => {
                  const percentage = Math.ceil((proj.totalTestsPassedCount / proj.totalTestsCount) * 100);
                  return { ...proj, percentage: Number.isNaN(percentage) ? 0 : percentage };
              })
            : [];

        if (getParamValues(1) === 'starred' && !this.isStarredProjectsMounted && checkArrayLength(filteredProjects)) {
            this.isStarredProjectsMounted = true;
            this.changeProjectType('starred');
        }
        this.setStateIfComponentMounted({ projects: filteredProjects, paginationCount: this.getPaginationCount() }, () => {
            this.calculateWidth();
            this.handleRequestSort(this.state.orderBy, true);
        });
    }

    componentWillUnmount() {
        this.is_mounted = false;
        const { queryRemove, clearSnackBar } = this.props;
        queryRemove();
        window.removeEventListener('resize', this.calculateWidth);
        clearSnackBar();
    }
    /* Component life cycle end */

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (this.is_mounted) this.setState(obj, callback);
    };

    getSearchedProjectNames = (props = this.props) => {
        const { query = '' } = props;
        const searchedProject = query ? query.split(' ') : [];
        return searchedProject;
    };

    getCardWidth = (c) => 100 / c;

    getPaginationCount = () => {
        const { cardsColsCount, rowsPerPage, listView } = this.state;
        if (cardsColsCount) {
            const val = cardsColsCount * 3;
            const array = !listView ? [val, val * 2, val * 3, val * 4] : [5, 10, 20, 50];
            if (!array.includes(rowsPerPage)) {
                const __rowsPerPage = getUserPreference(`project_${listView ? 'listview' : 'gridview'}_rowsPerPage`);
                this.setStateIfComponentMounted({ rowsPerPage: __rowsPerPage || array[0] });
            }
            return array;
        }
        return [];
    };

    handleChangePage = (page) => {
        const { showDisabledProjects, showFavouriteProjects } = this.props;
        const searchedProject = this.getSearchedProjectNames();
        this.setStateIfComponentMounted({ page });

        if(checkIsArray(searchedProject) &&  !checkArrayLength(searchedProject)) {
            this.setState({
                pageNo:page
            });
        }
        if (!showDisabledProjects && !showFavouriteProjects && !checkArrayLength(searchedProject)) {
            this.props.getAllProjects(page, this.state.rowsPerPage);
        }
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleChangeRowsPerPage = (event) => {
        const { showDisabledProjects, showFavouriteProjects } = this.props;
        addUserPreference(`project_${this.state.listView ? 'listview' : 'gridview'}_rowsPerPage`, event.target.value);
        this.setStateIfComponentMounted({ rowsPerPage: event.target.value, page: 0 });

        if (!showDisabledProjects && !showFavouriteProjects) {
            this.props.getAllProjects(0, event.target.value);
        }
    };

    handleView = (isListView) => {
        const { showDisabledProjects, showFavouriteProjects, getAllProjects } = this.props;
        const { page } = this.state;
        const searchedProject = this.getSearchedProjectNames();
        addUserPreference('project_listview', isListView);
        this.setStateIfComponentMounted({ listView: isListView }, () => {
            this.setStateIfComponentMounted({ paginationCount: this.getPaginationCount() });
            const rowsPerPage = getUserPreference(`project_${isListView ? 'listview' : 'gridview'}_rowsPerPage`);
            const __rowsPerPage = rowsPerPage || (isListView ? 5 : 12);
            if (!showDisabledProjects && !showFavouriteProjects && !checkArrayLength(searchedProject)) {
                getAllProjects(page, __rowsPerPage);
            }
        });
    };

    handleClick = async (event) => {
        event.preventDefault();
    };

    handleRequestSort = (property, isSameSort = false) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc' && !isSameSort) {
            order = 'asc';
        }
        const projects = sort(cloneDeep(this.state.projects), order, orderBy, orderBy === 'lastActivityDate' ? 'date' : '');
        this.setStateIfComponentMounted({ order, orderBy, projects });
    };

    calculateWidth = () => {
        const { openTaskPanel, taskPanelWidth } = this.props;
        // Calculate screen with on runtime
        const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
        // Set drawer width according to screen size
        let newWidth = screenWidth;
        // subtract sidePanels width from screen width if any panel open repectively
        if (openTaskPanel) {
            newWidth -= taskPanelWidth;
        }
        let cardWidth;
        let cardsColsCount = 0;
        if (newWidth >= 1920) {
            cardWidth = `${this.getCardWidth(5)}%`;
            cardsColsCount = 5;
        } else if (newWidth >= 1280) {
            cardWidth = `${this.getCardWidth(4)}%`;
            cardsColsCount = 4;
        } else if (newWidth >= 960) {
            cardWidth = `${this.getCardWidth(3)}%`;
            cardsColsCount = 3;
        } else if (newWidth >= 600) {
            cardWidth = `${this.getCardWidth(2)}%`;
            cardsColsCount = 2;
        } else {
            cardWidth = `${this.getCardWidth(1)}%`;
            cardsColsCount = 1;
        }
        this.setStateIfComponentMounted({ cardWidth, cardsColsCount }, () => {
            this.setStateIfComponentMounted({ paginationCount: this.getPaginationCount() });
        });
    };

    changeProjectType = (projectType) => {
        const { showEnableProjects, removeFavouriteProjects } = this.props;
        const { page } = this.state;
        let obj = {};
        let callback;
        obj = { ...obj, projects: [], page: 0 };
        if (projectType === 'All') {
            const isListView = getUserPreference('project_listview');
            const __rowsPerPage = getUserPreference(`project_${isListView ? 'listview' : 'gridview'}_rowsPerPage`);
            const rowsPerPage = __rowsPerPage || (isListView ? 5 : 12);
            showEnableProjects();
            callback = () => {
                this.props.getAllProjects(page, rowsPerPage);
            };
        } else if (projectType === 'starred') {
            showEnableProjects();
            callback = this.props.getAllFavouriteProjects;
        } else {
            removeFavouriteProjects();
            callback = this.props.getDisabledProjects;
        }
        this.setState(obj, callback);
    };

    gotoDashboard = () => {
        this.props.history.push('/projects');
    };

    gotoProject = () => {
        this.props.history.push('/projects');
    };

    changeScroll = (e) => {
        const { projects, rowsPerPage, isWidthShort } = this.state;
        if (
            e.target.scrollHeight - 50 - e.target.scrollTop <= e.target.clientHeight &&
            !this.isRecentlyLoaded &&
            projects &&
            projects.length > rowsPerPage &&
            isWidthShort
        ) {
            this.isRecentlyLoaded = true;
            setTimeout(() => {
                this.isRecentlyLoaded = false;
                this.setState((prevState) => ({ rowsPerPage: prevState.rowsPerPage + 3 }));
            }, 500);
        }
    };

    render() {
        const {
            isLoading,
            isDisableProjectsLoading,
            classes,
            toggleModal,
            sideBarCount,
            openTaskPanel,
            showDisabledProjects,
            showFavouriteProjects,
            isFavouriteProjectsLoading,
        } = this.props;
        const { projects, cardsColsCount, page, rowsPerPage, listView, order, orderBy, paginationCount, isWidthShort } = this.state;

        const searchedProject = this.getSearchedProjectNames();
        const cardsCols = [];
        let currentCol = 0;
        let paginatedProjects = [];
        const dummyCardCount = page * rowsPerPage + rowsPerPage;

        if (checkArrayLength(projects) && (showDisabledProjects || showFavouriteProjects || checkArrayLength(searchedProject))) {
            paginatedProjects = projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        } else if (checkArrayLength(projects)) {
            paginatedProjects = projects;
        }

        const projectLength = checkArrayLength(searchedProject)
            ? checkIsArray(projects, 'length')
            : showDisabledProjects
            ? sideBarCount.num_of_disable_projects
            : showFavouriteProjects
            ? sideBarCount.num_of_fav_projects
            : sideBarCount.num_of_projects;
        let allCards = [];
        if (!listView) {
            if (checkArrayLength(paginatedProjects)) {
                paginatedProjects.forEach((project, index) => {
                    if (!cardsCols[currentCol]) {
                        cardsCols[currentCol] = [];
                    }
                    cardsCols[currentCol].push(
                        <div key={index} style={{ margin: '10px 0' }}>
                            <ProjectCard project={project} rowsPerPage={rowsPerPage} page={page} showDisabledProjects={showDisabledProjects} />
                        </div>,
                    );
                    currentCol++;
                    currentCol = currentCol === cardsColsCount ? 0 : currentCol;
                });
            }
            cardsCols.forEach((card, index) => {
                allCards.push(
                    <div
                        key={index}
                        style={{
                            width: `calc(${this.state.cardWidth} - ${20 + 20 / cardsColsCount}px)`,
                            margin: `0 ${cardsCols.length - 1 === index ? '20px' : '0'} 0 20px`,
                        }}
                    >
                        {card}
                    </div>,
                );
            });
            if (isWidthShort && projects.length !== rowsPerPage) {
                allCards.push(
                    <div key="isRecentlyLoaded">
                        <p>loading...</p> 
                    </div>,
                );
            }
        } else {
            allCards = (
                <ProjectsTable
                    project={projects}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    history={this.props.history}
                    listView={listView}
                    handleRequestSort={this.handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                    showDisabledProjects={showDisabledProjects}
                    showFavouriteProjects={showFavouriteProjects}
                    searchedProject={searchedProject}
                    isLoading={isLoading}
                    isFavouriteProjectsLoading={isFavouriteProjectsLoading}
                    isDisableProjectsLoading={isDisableProjectsLoading}
                />
            );
        }

        const projectsToolbar = (
            <ProjectsToolbar
                handleRequestSort={this.handleRequestSort}
                order={order}
                orderBy={orderBy}
                history={this.props.history}
                toggleModal={toggleModal}
                handleView={this.handleView}
                listView={listView}
                showDisabledProjects={showDisabledProjects}
                showFavouriteProjects={showFavouriteProjects}
            />
        );

        const dummyCards = [];
        for (let i = 0; i < dummyCardCount; i++) {
            dummyCards.push(
                 <ProjectCardLoader key={i} _width={`calc(${this.state.cardWidth} - ${20 + 20 / cardsColsCount}px)`} />
            );
        }

        return (
            <React.Fragment>
             
            <div className={classes.root}>
                <div style={{ minHeight: 'calc(100vh - 145px)',display:'flex',flexDirection:'column' }}>
                
                    <div className={classes.container} onClick={this.handleClick} onScroll={this.changeScroll} aria-hidden>
                        <div style={{display:'flex',flexDirection:'column',width:'100%'}}>
                        <div style={{margin:'12px 20px 12px 20px'}}>
                        <OverallHealth/>
                        </div>
                        <div style={{
                            color: '#9b9b9b',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            margin:'12px 20px 12px 20px'
                        }}>
                            {'Project List'}
                        </div>    
                           

                        {((isLoading && !listView) || isDisableProjectsLoading || isFavouriteProjectsLoading) &&
                        !checkArrayLength(paginatedProjects) ? (
                            <div style={{display:'flex',flexWrap:'wrap'}}>
                            {dummyCards}
                        </div>    
                        ) : (paginatedProjects && paginatedProjects.length > 0) || (isLoading && listView) ? (
                            
                            <div style={{display:'flex',flexDirection:'row'}}>
                                {allCards}
                             </div>   
                            
                        ) : (
                            <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'center'}}>
                                <NoSearchResultFoundMessage />

                            </div>
                        )}
                        </div>
                    </div>
                </div>
                <Hidden only={['xs']}>
                    <div style={{ position: 'fixed', top: 40, right: openTaskPanel ? 368 : 35 }}>
                        {!showDisabledProjects && (
                            <Hidden only={['xs']}>
                                    <span
                                        style={{ right: openTaskPanel ? 600 : 264 }}
                                        className={classes.addProject}
                                        onClick={() => ProjectUtils.toggleUpdateModal(false, null, null, this.props.history)}
                                        aria-hidden
                                    >

                                     <CustomButton style={{padding:'8px 16px'}} title={<span><FontAwesomeIcon className={classes.plus} icon={faPlus} aria-label="faPlus" id="faPlus" /><span className={classes.newProjectText}>{'New Project'}</span></span>}/>

                                    </span> 
                            </Hidden>
                        )}
                        {projectsToolbar}
                    </div>
                </Hidden>
                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                    <div style={{ position: 'fixed', top: 40, right: 15 }}>{projectsToolbar}</div>
                </Hidden>
                {checkArrayLength(paginatedProjects) ? (
                    <div className={classes.footerStyles}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginTop: 0, marginLeft: 20 }} className={classes.toolbarCountAvatar}>
                                {getCountAvatar(
                                    checkArrayLength(searchedProject)
                                        ? checkIsArray(projects, 'length')
                                        : showDisabledProjects
                                        ? sideBarCount.num_of_disable_projects
                                        : showFavouriteProjects
                                        ? sideBarCount.num_of_fav_projects
                                        : sideBarCount.num_of_projects,
                                )}
                            </span>
                            <Typography variant="h2" className={classes.titleProject}>
                                {showDisabledProjects ? 'Disabled Projects' : 'Projects'}
                            </Typography>
                        </div>
                        {!isWidthShort && (
                            <div className={classes.paginationWidth}>
                                <TablePagination
                                    count={projectLength || 0}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={paginationCount}
                                    page={page}
                                    handleChangePage={this.handleChangePage}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    applyCustomClasses
                                    labelRowsPerPage="Projects per page:"
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={classes.footerStyles}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginTop: 0, marginLeft: 20 }} className={classes.toolbarCountAvatar}>
                                {getCountAvatar(0)}
                            </span>
                            <Typography variant="h2" className={classes.titleProject}>
                                {showDisabledProjects ? 'Disabled Projects' : 'Projects'}
                            </Typography>
                        </div>
                        {!isWidthShort && (
                            <div className={classes.paginationWidth}>
                                <TablePagination
                                    count={projectLength || 0}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={paginationCount}
                                    page={page}
                                    handleChangePage={this.handleChangePage}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    applyCustomClasses
                                    labelRowsPerPage="Projects per page:"
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            </React.Fragment>
        );
    }
}

ProjectsGridList.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    isLoading: PropTypes.bool.isRequired,
    openTaskPanel: PropTypes.bool.isRequired,
    taskPanelWidth: PropTypes.number.isRequired,
    toggleModal: PropTypes.func.isRequired,
};

ProjectsGridList.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        isLoading: state.projectReducer.isLoading,
        projectsByIdAndName: state.projectReducer.projectsByIdAndName,
        projects: state.projectReducer.projects,
        // general reducer
        query: state.generalReducer.queryValue,
        user: state.authReducer.user,
        sideBarCount: state.sidebarReducer.sideBarCount,
        openTaskPanel: state.generalReducer.openTaskPanel,
        // projects reducer
        disabledProjects: state.projectsReducer.disabledProjects,
        isDisableProjectsLoading: state.projectsReducer.isLoading,
        showDisabledProjects: state.projectsReducer.showDisabledProjects,
        // general modal reducer
        currentTab: state.generalModalReducer.currentTab,
        // favourite Projects
        showFavouriteProjects: state.projectReducer.isFavourite,
        isFavouriteProjectsLoading: state.projectReducer.isFavouriteProjectsLoading,
        favouriteProjects: state.projectReducer.favouriteProjects,
        // search ProjectByName
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // General Actions
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
        // Modal Actions
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        clearSnackBar: () => dispatch(ModalActions.clearSnackbar()),
        // Project Actions
        getAllFavouriteProjects: (...args) => dispatch(ProjectActions.getFavouriteProjects(...args)),
        getProjectByIdAndName: (...args) => dispatch(ProjectActions.getProjectByIdAndName(...args)),
        getAllProjects: (...args) => dispatch(ProjectActions.getAllProjects(...args)),
        addProject: (data) => dispatch(ProjectActions.createNewProject(data)),
        removeFavouriteProjects: (...args) => dispatch(ProjectActions.removeFavouriteProjects(...args)),
        // Projects Actions
        getDisabledProjects: (...args) => dispatch(ProjectsActions.getDisabledProjects(...args)),
        showEnableProjects: (...args) => dispatch(ProjectsActions.showEnableProjects(...args)),
        // Sidebar Actions
        getSideBarDataCount: () => dispatch(SidebarActions.getSideBarDataCount()),
        getPermissions: () => dispatch(AccountActions.getPermissions()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectsGridList));
