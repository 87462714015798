// import packages
import React from 'react';
// import materials
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { BLACK_FONT } from '../../../common/cssConstants';

const styles = (theme) => ({
    formControl: {
        width: '100%',
        flexDirection: 'row',
    },
    inputLabel: {
        left: 'inherit',
    },
    selectBox: {
        width: '100%',
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        // flexBasis: 200,
        width: '100%',
        flexDirection: 'row',
        float: 'left',
        padding: 10,
    },
    textFieldTitle: {
        width: '40%',
        float: 'left',
        fontSize: 14,
        lineHeight: 2,
        /* height: auto, */
        textAlign: 'left',
        color: BLACK_FONT,
    },
    rPModal: {
        borderBottom: '1px solid #e1e1e1 !important',
        width: '100%',
        padding: 15,
        minHeight: '95px',
    },
    textFieldRMM: {
        width: '100%',
        // paddingLeft: 20,
    },
    warnnText: {
        paddingTop: 7,
        fontSize: 12,
        color: BLACK_FONT,
    },
    textWarn: {
        width: '100%',
        float: 'left',
        fontSize: 18,
        /* height: auto, */
        textAlign: 'left',
    },
    textEmail: {
        width: '100%',
        float: 'left',
        fontSize: 18,
        textAlign: 'left',
    },
    row: {
        padding: '6px 0px',
        display: 'flex',
        justifyContent: 'start',
    },
    col: {
        width: '50%',
    },
    header: {
        '& > p': {
            color: BLACK_FONT,
            fontSize: 16,
            fontWeight: '600',
        },
    },
    horizontalRow: {
        width: 'calc(100% + 78px)',
        marginLeft: '-55px',
        border: 0,
        borderTop: '1px solid #DCDCDC',
    },
    body: {
        '& > p': {
            color: BLACK_FONT,
        },
    },
});

function MultiDeleteUser({ users, classes }) {
    return (
        <div
            style={{
                paddingLeft: '15px',
                marginTop: '16px',
            }}
        >
            <div className={classes.row}>
                <div className={[classes.col, classes.header].join(' ')}>
                    <Typography>User</Typography>
                </div>
                <div className={[classes.col, classes.header].join(' ')}>
                    <Typography>Role</Typography>
                </div>
            </div>
            <hr className={classes.horizontalRow} />
            <div style={{height: '330px', overflow: 'auto'}}>
                {users.map((user, index) => {
                    return (
                        <div key={index}  >
                            <div className={classes.row}>
                                <div className={[classes.col, classes.body].join(' ')}>
                                    <Typography>{user.name}</Typography>
                                </div>
                                <div className={[classes.col, classes.body].join(' ')}>
                                    <Typography>{user.role}</Typography>
                                </div>
                            </div>
                            <hr className={classes.horizontalRow} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default withStyles(styles)(MultiDeleteUser);
