// import @material
import { withStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

// icons
import Settings from '@material-ui/icons/Settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

// import packages
import PropTypes from 'prop-types';
import React from 'react';
import BreadCrumb from './BreadCrumb';

// import custom
import SwipableTaskPanel from '../../features/Tasks/SwipableTaskPanel';
import CustomButton  from '../Button/CustomButton';
import { ProjectUtils } from '../../utils/ProjectUtils';

// styles
const styles = () => ({
    gearDashboard: {
        color: '#4A4A4A',
        fontSize: 20,
    },
    toolBar: {
        padding: '0px',
    },
    topBar: {
        backgroundColor: '#ececec',
        height: 56,
        boxShadow: '0 0 0 #fff',
        position: 'static',
    },
    topBarContainer: {
        backgroundColor: '#fff',
        height: 50,
        marginTop: 42,
        overflow: 'hidden',
    },
    topBarHeadingContainer: {
        alignItems: 'center',
        display: 'flex',
        height: 52,
        marginBottom: 5,
        width: '100%',
        justifyContent: 'space-between',
    },
    topBarText: {
        marginTop: 3,
        color: 'white',
        marginLeft: 8,
    },
    title: {
        paddingLeft: 10,
        fontSize: '16px',
    },
    settingWrapper: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#F4F6F7',
        border: '1px solid #C4C4C4',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 15,
        width: 240,
        height: 36,
        boxShadow: '0 1px 4px 0 rgba(0,0,0,0.4)',
        cursor: 'pointer',
    },
    newProjectText: {
        marginLeft:'8px',
        textTransform:'none'
    },
    plus: {
        color: '#fff',
        fontSize: '12px',
    },
    addProjectButtonContainer: {
        marginRight:'8px',
        marginTop:'3px'
    }
});

const StickyHeaderBar = (props) => {
    // one of icon and source are need to be passed,
    //  for icon isIcon must be passed true as prop
    const {
        classes,
        history,
        width,
        forceUpdateBackButton,
        openTaskPanel,
        showRightPanel,
        toggleDashboardSettingGear,
        toggleSwipableTaskPanel,
        update_ForceUpdateBackButtonFlag,
        isOnDashboard,
        isSidebarCountLoading
    } = props;

    return (
        <div className={classes.topBarContainer} style={{ width }}>
            <AppBar className={classes.topBar}>
                <Toolbar disableGutters className={classes.toolBar}>
                    <div className={classes.topBarHeadingContainer}>
                        <Typography variant="subtitle1" className={classes.topBarText}>
                            <BreadCrumb
                                history={history}
                                forceUpdateBackButton={forceUpdateBackButton}
                                update_ForceUpdateBackButtonFlag={update_ForceUpdateBackButtonFlag}
                            />
                        </Typography>
                        { !isSidebarCountLoading && isOnDashboard && (
                            <div style={{display:'flex'}}>
                                <div className={classes.addProjectButtonContainer}>
                                    <CustomButton onClick={() => ProjectUtils.toggleUpdateModal(false, null, null, history)} style={{padding:'8px 16px'}} title={<span><FontAwesomeIcon className={classes.plus} icon={faPlus} aria-label="faPlus" id="faPlus" /><span className={classes.newProjectText}>{'Add Project'}</span></span>}/>
                                </div>
                                <div className={classes.settingWrapper} onClick={() => toggleDashboardSettingGear(true)} aria-hidden>
                                    <Settings className={classes.gearDashboard} aria-label="settingsIcon" id="settingsIcon" />
                                    <p className={classes.title}>Dashboard Management</p>
                                </div>

                            </div>
                        )}
                    </div>
                    <Hidden only={['xs']}>
                        {showRightPanel && <SwipableTaskPanel toggleSwipableTaskPanel={toggleSwipableTaskPanel} openTaskPanel={openTaskPanel} />}
                    </Hidden>
                </Toolbar>
            </AppBar>
        </div>
    );
};

StickyHeaderBar.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    openTaskPanel: PropTypes.bool,
    showRightPanel: PropTypes.bool,
    toggleDashboardSettingGear: PropTypes.func.isRequired,
    toggleSwipableTaskPanel: PropTypes.func,
    // eslint-disable-next-line react/no-unused-prop-types
    url: PropTypes.string.isRequired, // do not remove this key
};

StickyHeaderBar.defaultProps = {
    openTaskPanel: false,
    showRightPanel: false,
    toggleSwipableTaskPanel: () => {},
};

export default withStyles(styles)(StickyHeaderBar);
