// material-ui component
import { Grid } from '@material-ui/core';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// Font Awesome
import { faCamera, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'react-dropzone';
import { checkArrayLength } from '../../utils/utils';
import defaultScreenShot from '../../assets/images/noPhoto.png';
import requestImageService from '../../services/requestImageService';
import { connect } from 'react-redux';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    companyName: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '20px',
        margin: '0 !important',
        height: '26px',
        width: '143px',
        color: '#8898A8',
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '26px',
    },
    camera: {
        marginTop: '15px',
        height: '20px',
        width: '20px',
        color: '#5F7B96',
        lineHeight: '20px',
    },
    companyLogo: {
        width: '120px',
        height: '120px',
        display: 'flex',
        alignItems: ' center',
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        color: '#8498aa',
        fontWeight: 'bold',
        fontSize: '12px',
        position: 'absolute',
    },
    companyLogoImage: {
        width: '120px',
        height: '120px',
    },
    companyWrapper: {
        color: '#8498aa',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        width: '120px',
        height: '120px',
        position: 'relative',
        overflow: 'hidden',
        margin: '20px 30px 20px 20px',
        zIndex: 1,
        border: '1px solid #BAC4CE',
        [theme.breakpoints.only('xs')]: {
            height: '80px',
            width: '80px',
            margin: '10px 10px 10px 10px',
        },
    },
    userAvatarWrapper: {
        color: '#8498aa',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        height: '90px',
        width: '90px',
        background: '#BAC4CE',
        border: '4px solid #fff',
        borderRadius: '50%',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 1,
    },
    dropZone: {
        cursor: 'pointer',
        zIndex: 1,
        width: '100%',
        height: '100%',
        '&>:nth-child(1)': {
            display: 'flex',
            height: '50%',
        },
        '&>:nth-child(2)': {
            display: 'flex',
            width: '100%',
            height: '50%',
            alignItems: 'flex-end',
            justifyContent: 'center',
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.6)',
            },
        },
        '&:hover>:nth-child(2)': {
            backgroundColor: 'rgba(0,0,0,0.4)',
        },
    },
    userAvatar: {
        zIndex: -1,
        width: '100% !important',
        position: 'absolute',
        height: '100%',
        color: 'white',
        top: '8px',
    },
    circleAlign: {
        display: 'flex',
        position: 'absolute',
        width: '100%',
        bottom: '-20px',
        flexDirection: 'row',
    },
    cameraInAvatar: {
        cursor: 'pointer',
        fontSize: '20px',
        color: '#d8d8d8',
        marginBottom: '3px',
    },
    username: {
        position: 'relative',
        top: '36px',
        left: '16px',
        fontSize: '20px',
        fontWeight: 'normal',
        color: '#8498aa',
    },
});

const Header = (props) => {
    const { classes, companyLogo, companyName, isPreference, profileImageLink, userDetails, onDrop,onCompanyDrop, profileImage, selectedTab } = props;
    let loadImage = useCallback(async () => {
        await requestImageService.requestImage(companyLogo);
        await requestImageService.requestImage(profileImageLink)
    },[profileImageLink,companyLogo])
    React.useEffect(()=>{
        loadImage()
    },[profileImageLink,companyLogo,loadImage])
    const pImg = checkArrayLength(profileImage) && profileImage[0].preview ? profileImage[0].preview : null;
    let defaultImg = <FontAwesomeIcon icon={faUser} className={classes.userAvatar} aria-label="faUser" id="faUser" />;
    let bg = null;
    if (pImg && selectedTab === 0) {
        defaultImg = <img src={pImg} alt="" className={classes.userAvatar} style={{ top: 0, background: 'white' }} />;
        bg = 'white';
    } else if (userDetails.imgLoc) {
        defaultImg = <img src={props.avatarImg} alt="" className={classes.userAvatar} style={{ top: 0, background: 'white' }} />;
        bg = 'white';
    }
    return (
        <div className={classes.root}>
            <Grid container spacing={0}>
                <Grid item xs={10} sm={10} md={10} lg={10} style={{ width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.companyWrapper} style={{ backgroundColor: 'white' }}>
                            <img src={props.companyImg || defaultScreenShot} alt="" className={classes.companyLogoImage} />
                            <div className={classes.companyLogo}>
                                {/* <FontAwesomeIcon icon={faCamera} aria-label="faCamera" id="faCamera" className={classes.camera} />
                                    Company logo */}
                                {selectedTab === 0 && !isPreference && (
                                    <Dropzone onDrop={onCompanyDrop} className={classes.dropZone} multiple={false} disabled={false}>
                                        {({ getRootProps, getInputProps }) => (
                                            <span {...getRootProps({})}>
                                                <input {...getInputProps()} />
                                                <span
                                                    aria-hidden
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                ></span>
                                                <span>
                                                    <FontAwesomeIcon
                                                        icon={faCamera}
                                                        className={classes.cameraInAvatar}
                                                        aria-label="faCamera"
                                                        id="faCamera"
                                                        onClick={() => {}}
                                                    />
                                                </span>
                                            </span>
                                        )}
                                    </Dropzone>
                                )}
                            </div>
                        </div>
                        <div className={classes.companyName}>{companyName}</div>
                    </div>
                </Grid>
                {!isPreference && (
                    <Grid item xs={2} sm={2} md={2} lg={2} style={{ position: 'relative' }}>
                        <div className={classes.circleAlign}>
                            <div className={classes.userAvatarWrapper} style={bg ? { backgroundColor: bg } : {}}>
                                {defaultImg}
                                {selectedTab === 0 && (
                                    <Dropzone onDrop={onDrop} className={classes.dropZone} multiple={false} disabled={false}>
                                        {({ getRootProps, getInputProps }) => (
                                            <span {...getRootProps({})}>
                                                <input {...getInputProps()} />
                                                <span
                                                    aria-hidden
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                ></span>
                                                <span>
                                                    <FontAwesomeIcon
                                                        icon={faCamera}
                                                        className={classes.cameraInAvatar}
                                                        aria-label="faCamera"
                                                        id="faCamera"
                                                        onClick={() => {}}
                                                    />
                                                </span>
                                            </span>
                                        )}
                                    </Dropzone>
                                )}
                            </div>
                            <div className={classes.username}>{userDetails.name}</div>
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

Header.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    companyLogo: PropTypes.string,
    companyName: PropTypes.string,
    isPreference: PropTypes.bool,
    onDrop: PropTypes.func,
    userDetails: PropTypes.shape({
        name: PropTypes.string,
    }),
};

Header.defaultProps = {
    companyLogo: '',
    companyName: '',
    isPreference: false,
    onDrop: () => {},
    userDetails: {
        name: '',
    },
};

const mapStateToProps = (state, props) => {
    const companyImg = props.companyLogo && state.imagesReducer.images ? state.imagesReducer.images[props.companyLogo] : defaultScreenShot;
    const avatarImg = props.profileImageLink && state.imagesReducer.images ? state.imagesReducer.images[props.profileImageLink] : defaultScreenShot;
    return {
        companyImg,
        avatarImg
    };
};

export default connect(mapStateToProps,null)(withStyles(styles)(Header));
