/* eslint-disable no-template-curly-in-string */
class AutoSuggestUtils {
    testDataSuggestions = [
        { label: '{Today - 1{yy}, MM/dd/yyyy}' },
        { label: '{Today, [Date_format]}' },
        { label: '{Today - 1{mm}, MM/dd/yyyy}' },
        { label: '{Today - 2{dd}, MM/dd/yyyy}' },
        { label: '{Today + 2{dd}, MM/dd/yyyy}' },
        { label: '{Today, MM/dd/yyyy}' },
        { label: '{today, dd/mm/yy}' },
        { label: '{today, mm/dd/yy} ' },
        { label: '{today, yy/mm/dd}' },
        { label: '${variable_name}' },
    ];

    testInstructionSuggestions = [
        { label: '_var{[excel formula]} as [var_name]' },
        { label: '_xl{[excel formula]} as [var_name]' },
        { label: 'Assert [text] is visible on the page' },
        { label: 'Choose _css{"[selector]"}' },
        { label: 'Choose [text]' },
        { label: 'Choose {xpath: "[address]"}' },
        { label: 'Click on [text] radio' },
        { label: 'Begin block [block_name]' },
        { label: 'Begin script _bash with ${input_var}' },
        { label: 'Begin script _js with ${input_var}' },
        { label: 'Begin script _py with ${input_var}' },
        { label: 'break' },
        { label: 'Click _css{"[selector]"}' },
        { label: 'Click on [text] radio for [text]' },
        { label: 'Click on [text] radio' },
        { label: 'Click on radio next to [text]' },
        { label: 'Click on [exact attribute value]' },
        { label: 'Click on [text] after [text]' },
        { label: 'Click on [text] for [text]' },
        { label: 'Click on [text] before [text]' },
        { label: 'Click {xpath: "[address]"}' },
        { label: 'Click on _xy{x, y} of  _xpath{"[//img]"}' },
        { label: 'Click on _xy{x, y} of _css{#some_html_node_id}' },
        { label: 'Create random variable [var_name]' },
        { label: 'Capture screenshot' },
        { label: 'Double Click on _xy{x, y} of [text]' },
        { label: 'Download file by clicking on "[download_label]"' },
        { label: 'End block' },
        { label: 'End script save as ${output_var_name}' },
        { label: 'Enter [textbox]' },
        { label: 'Enter text in _css{"[selector]"}' },
        { label: 'Enter text in [text]' },
        { label: 'Enter text in {xpath: "[address]"}' },
        { label: 'Exec _js{[script]} with ${input_var} returning ${output_var}' },
        { label: 'Exec _py{[script]} with ${input_var} returning ${output_var}' },
        { label: 'Exec _sh{[script]} with ${input_var} returning ${output_var}' },
        { label: 'Exec "[artifact file]" with ${input_var} returning ${output_var}' },
        { label: 'Fill in [textbox]' },
        { label: 'Fill in text in _css{"[selector]"}' },
        { label: 'Fill in text in [text]' },
        { label: 'Fill in text in {xpath: "[address]"}' },
        { label: 'Go to site' },
        { label: 'Go to web page' },
        { label: 'Go to page' },
        { label: 'Go to' },
        { label: 'Go to website' },
        { label: 'enter [textbox] and save as xyz' },
        { label: 'Hit enter' },
        { label: 'Hit Down arrow key' },
        { label: 'hit up arrow key' },
        { label: 'Hit spacebar' },
        { label: 'Hit alt' },
        { label: 'Hit escape' },
        { label: 'Hit tab' },
        { label: 'Hover on _css{"[selector]"}' },
        { label: 'Hover on [text]' },
        { label: 'Hover on {xpath: "[address]"}' },
        { label: 'Hover over _css{"[selector]"}' },
        { label: 'Hover on _xy{x, y} of _xpath{//img[@id="some_html_node_id"]}' },
        { label: 'Hover over [text]' },
        { label: 'Hover over {xpath: "[address]"}' },
        { label: 'Market:' },
        { label: 'Navigate website' },
        { label: 'Navigate to website' },
        { label: 'Navigate to site' },
        { label: 'Navigate to web page' },
        { label: 'Navigate to page' },
        { label: 'Navigate site' },
        { label: 'Navigate web page' },
        { label: 'Navigate page' },
        { label: 'Navigate to' },
        { label: 'Navigate' },
        { label: 'Open website' },
        { label: 'Open site' },
        { label: 'Open web page' },
        { label: 'Open page' },
        { label: 'Print' },
        { label: 'Press _css{"[selector]"}' },
        { label: 'Press {xpath: "[address]"}' },
        { label: 'Press on radio next to [text]' },
        { label: 'Press on [text] radio' },
        { label: 'Press on [text] radio for [text]' },
        { label: 'Run ${flow name} for [number] times' },
        { label: 'Run ${flow name} for all rows' },
        { label: 'Save _css{"[selector]"} as [var_name]' },
        { label: 'Save {xpath: "[address]"} as [var_name]' },
        { label: 'Scroll up' },
        { label: 'Scroll down' },
        { label: 'Select _css{"[selector]"}' },
        { label: 'Select [text]' },
        { label: 'Select {xpath: "[address]"}' },
        { label: 'Set screen/window size - [width] * [height]' },
        { label: 'Set text in _css{"[selector]"}' },
        { label: 'Set text in [text]' },
        { label: 'Set text in {xpath: "[address]"}' },
        { label: 'Set screen to hd' },
        { label: 'Set screen to mobile phone' },
        { label: 'Set screen to tablet' },
        { label: 'Set screen to tablet landscape' },
        { label: 'Set screen to 1080' },
        { label: 'Set screen to 1080p' },
        { label: 'Set screen to 720' },
        { label: 'Set screen to 900' },
        { label: 'Set screen to Full HD' },
        { label: 'Set screen size - 200 * 200' },
        { label: 'Switch to 2nd tab' },
        { label: 'Switch to 2nd window' },
        { label: 'Switch to 3rd tab' },
        { label: 'Switch to 3rd window' },
        { label: 'Switch to alert and click on accept' },
        { label: 'Switch to alert and click on cancel' },
        { label: 'Switch to alert and click on leave' },
        { label: 'Switch to alert and click on ok' },
        { label: 'Switch to alert and click on stay' },
        { label: 'Switch to alert and save message as alert_set1 and click ok' },
        { label: 'Switch to confirm and click on accept' },
        { label: 'Switch to confirm and click on cancel' },
        { label: 'Switch to confirm and click on leave' },
        { label: 'Switch to confirm and click on ok' },
        { label: 'Switch to confirm and click on stay' },
        { label: 'Switch to new tab' },
        { label: 'Switch to new window' },
        { label: 'Switch to original tab' },
        { label: 'Switch to original window' },
        { label: 'Switch to prompt and click on accept' },
        { label: 'Switch to prompt and click on cancel' },
        { label: 'Switch to prompt and click on leave' },
        { label: 'Switch to prompt and click on ok' },
        { label: 'Switch to prompt and click on stay' },
        { label: 'Switch to window with title [title1]' },
        { label: 'Type in [textbox]' },
        { label: 'Type in text in _css{"[selector]"}' },
        { label: 'Type in text in [text]' },
        { label: 'Type in text in {xpath: "[address]"}' },
        { label: 'Take screenshot' },
        { label: 'Upload file to _css{"[selector]"}' },
        { label: 'Upload file to [text]' },
        { label: 'Upload file to {xpath: "[address]"}' },
        { label: 'Use custom code from [location]' },
        { label: 'Verify _css{"[selector]"} begins with [text] or begins with [text]' },
        { label: 'Verify _css{"[selector]"} begins with [text] or ends with [text]' },
        { label: 'Verify _css{"[selector]"} begins with [text]' },
        { label: 'Verify _css{"[selector]"} contains [text] or begins with [text]' },
        { label: 'Verify _css{"[selector]"} contains [text] or contains [text]' },
        { label: 'Verify _css{"[selector]"} contains [text] or ends with [text]' },
        { label: 'Verify _css{"[selector]"} contains [text]' },
        { label: 'Verify _css{"[selector]"} ends with [text] or ends with [text]' },
        { label: 'Verify _css{"[selector]"} ends with [text]' },
        { label: 'Verify _css{"[selector]"} is _css{"[selector]"}' },
        { label: 'Verify _css{"[selector]"} is _css{"[selector]"}' },
        { label: 'Verify _css{"[selector]"} is disabled' },
        { label: 'Verify _css{"[selector]"} is enabled' },
        { label: 'Verify _css{"[selector]"} is not visible' },
        { label: 'Verify _css{"[selector]"} is visible' },
        { label: 'Verify [text] is on the page' },
        { label: 'Verify {xpath: "[address]"} begins with [text] or begins with [text]' },
        { label: 'Verify {xpath: "[address]"} begins with [text] or ends with [text]' },
        { label: 'Verify {xpath: "[address]"} begins with [text]' },
        { label: 'Verify {xpath: "[address]"} contains [text] or begins with [text]' },
        { label: 'Verify {xpath: "[address]"} contains [text] or contains [text]' },
        { label: 'Verify {xpath: "[address]"} contains [text] or ends with [text]' },
        { label: 'Verify {xpath: "[address]"} contains [text]' },
        { label: 'Verify {xpath: "[address]"} ends with [text] or ends with [text]' },
        { label: 'Verify {xpath: "[address]"} ends with [text]' },
        { label: 'Verify {xpath: "[address]"} is {xpath: "[address]"}' },
        { label: 'Verify {xpath: "[address]"} background-color is #ffffff' },
        { label: 'Verify {xpath: "[address]"} color is #e01719' },
        { label: 'Verify {xpath: "[address]"} font-size 26px' },
        { label: 'Verify {xpath: "[address]"} is disabled' },
        { label: 'Verify {xpath: "[address]"} is enabled' },
        { label: 'Verify {xpath: "[address]"} is not visible' },
        { label: 'Verify {xpath: "[address]"} is visible' },
        { label: 'Verify alert is exists' },
        { label: 'Verify pop up is exists' },
        { label: 'Verify new tab exists' },
        { label: 'Verify tab is exists' },
        { label: 'Verify the current url is [url]' },
        { label: 'Verify url is [url]' },
        { label: 'Verify variable ${var_name} is [text]' },
        { label: 'Verify ${var_name} is on the screen' },
        { label: 'Verify ${var_name}  on the page' },
        { label: 'Verify new window exists' },
        { label: 'Verify window is exists' },
        { label: 'Wait [number] secs' },
        { label: 'Wait for [number] seconds' },
        { label: 'Wait for [number] secs' },
        { label: 'Wait until _css{"[selector]"} is exists' },
        { label: 'Wait until _css{"[selector]"} is visible' },
        { label: 'Wait until [text] is exists' },
        { label: 'Wait until [text] is visible' },
        { label: 'Wait until {xpath: "[address]"} is exists' },
        { label: 'Wait until {xpath: "[address]"} is visible' },
        { label: '[Action] and save it as [var_name]' },
        { label: 'Assert image of "text", "text" is on the page' },
        { label: 'elif [condition] run block [block_name]' },
        { label: 'Else, run block [block_name]' },
        { label: 'if {xpath:"[address]"} is visible, Click on [exact attribute value]' },
        { label: 'if current url is [url], Enter [text]' },
        { label: 'if [condition], run block [block_name]' },
        { label: 'if [condition], continue' },
        { label: 'Launch website' },
    ];

    expectedResults = [];
}
export default new AutoSuggestUtils();
