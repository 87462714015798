import HttpBase from '../../services/HttpBase';

class TestSuite extends HttpBase {
    async getTestSuites(accountId, projectId) {
        const result = await this.httpClient.get(`/testSuites/${accountId}/${projectId}/getTestSuites`);
        if (result && result.data) return result.data;
        return [];
    }

    async getTestSuitesWithAccount() {
        try {
            const result = await this.httpClient.get('/v1/gettestsuites');
            if (result.status === 200) {
                return result.data;
            }
            return [];
        } catch (error) {
            if (error.response) {
                throw error.response.statusText;
            }
            throw error;
        }
    }

    async getReports() {
        try {
            const result = await this.httpClient.get('/v1/jobs/get_reports');
            if (result.status === 200) {
                return result.data;
            }
        } catch (error) {
            if (error.response) {
                throw error.response.statusText;
            }
            throw error;
        }
        return null;
    }

    async addTestSuites(obj) {
        const result = await this.httpClient.post('/v1/test_suite/create', obj);
        return result.data;
    }

    async editTestSuites(obj) {
        const { testSuiteId } = obj;
        delete obj.testSuiteId;
        const result = await this.httpClient.post(`/v1/test_suite/${testSuiteId}/update`, obj);
        // NOTE: only returning the first!!! /v1/test_suite/{test_suite_id}/update
        if (result.status === 200 && result.data) {
            return result.data;
        }
        throw new Error('Failed to update testSuite');
    }
    async stopTestSuites(obj) {
        try {
            const result = await this.httpClient.post(`/v1/test_suite/stoptestsuite`,obj);
            if (result.status === 200 && result.data) {
                return result.data;
            }
            throw new Error('Failed to stop testSuite')

        } catch (error) {
            throw error;
        }

    }

    async cloneTestSuite(suiteData) {
        const { testSuiteId } = suiteData;
        delete suiteData.testSuiteId;
        const result = await this.httpClient.post(`v1/test_suite/${testSuiteId}/clone`, suiteData);
        if (result.status === 200) {
            return result.data;
        }
        throw new Error('Unable to clone test suite');
    }

    async deleteTestSuites(testSuiteIds) {
        try {
            const result = await this.httpClient.post('/testSuites/delete', { testSuiteIds });
            if (result.status === 200) {
                return result.data;
            }
            throw new Error(`Unable to delete test suite with id: ${testSuiteIds}`);
        } catch (error) {
            throw typeof error === 'string' ? error : 'Unable to delete test suite(s)';
        }
    }

    async disableTestSuites(testSuiteIds, val) {
        try {
            let _error = 'Invalid Call';
            if (val === 1) {
                // Disable
                _error = `Unable to disable test suite with ids: ${testSuiteIds}`;
            }
            if (val === 0) {
                // Restore
                _error = `Unable to restore test suite with ids: ${testSuiteIds}`;
            }
            const data = {
                testSuiteIds,
                disabledStatus: val,
            };
            const result = await this.httpClient.post('/testSuite/status', data);
            if (result.status === 200) {
                return data;
            }
            throw _error;
        } catch (error) {
            throw error.toString();
        }
    }

    async associateTestSuites(testSuiteId, testCaseIds) {
        const result = await this.httpClient.post('testCases/associate', {
            case_ids: testCaseIds,
            suite_id: testSuiteId,
        });
        if (result && result.status === 200) {
            return result.status;
        }
        throw new Error('Failed');
    }

    async executeTestSuite(obj, suiteId, onCompelete = () => {}) {
        const result = await this.httpClient.post(`/v1/testsuite/${suiteId}/execute`, obj);
        if (result.status === 200) {
            onCompelete();
        }
        return { data: result.data };
    }

    async getTestSuiteById(testSuiteId) {
        const result = await this.httpClient.get(`/v1/${testSuiteId}/get_suite`);
        if (result.status === 200) {
            return result.data;
        }
        throw new Error('Unable to fetch suite');
    }

    async getProjectAndTestSuite() {
        const result = await this.httpClient.get('v1/test_suite/get_project_and_suites');
        if (result.status === 200) {
            return result.data;
        }
        throw new Error('Unable to fetch suite');
    }
}
const TestSuiteMiddleware = new TestSuite();
export { TestSuiteMiddleware };
