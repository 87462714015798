import React from 'react';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';

import { Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ModalActions, FlowActions, generalModalActions } from '../../../store/actions';
import { getProjectDropDownData } from '../../../utils/utils';
import SelectCustom from '../GeneralModal/Select';
import { TestStepUtils } from '../../../utils/TestStepUtils';

const styles = () => ({
    label: {
        height: '16px',
        width: '75px',
        color: '#595959',
        fontFamily: 'Roboto',
        fontSize: '12px',
        letterSpacing: 0,
        lineHeight: '16px',
    },
    formControl1: {
        width: '100%',
        padding: '0 40px 0 0px',
        marginBottom: 30,
        '&>p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    table: {
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.2)',
    },
    tableRow: {
        height: '40px',
        width: '746px',
    },
    tableBoadyRow: {
        backgroundColor: '#FAFAFA',
    },
    wrapperFormControl: {
        width: 360,
    },
    tableCell: {
        fontFamily: 'Roboto Medium',
        color: '#000000',
    },
});

let isMounted = false;

class CloneVariablesModal extends React.Component {
    state = {
        selectedProject: 0,
        selectedName: '',
    };

    componentDidMount() {
        const { clearErr } = this.props;
        isMounted = true;
        clearErr();
        this.updatedTabsData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { variableData } = nextProps;

        this.setStateIfComponentMounted({
            selectedName: variableData[0].selectedName,
            selectedProject: variableData[0].projectId.toString(),
        });
    }

    componentWillUnmount() {
        this.onUnmount();
    }

    onUnmount = () => {
        isMounted = false;
        this.updatedTabsData();
    };

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted)
            this.setState({ error: '', ...obj }, () => {
                callback();
                this.updatedTabsData();
            });
    };

    selectProjectHandler = (selectedProjectName) => {
        const { clearErr } = this.props;
        this.setStateIfComponentMounted(
            {
                selectedProject: selectedProjectName.value,
                selectedName: selectedProjectName.label,
            },
            () => {
                clearErr();
            },
        );
    };

    updatedTabsData = () => {
        this.props.updateTabData('cloneVariable', { state: { ...this.state, variableData: this.props.variableData } });
    };

    render() {
        const { selectedProject } = this.state;
        const { variableData, classes, projects } = this.props;
        const filtered = projects.filter((proj) => proj.projectId !== variableData[0].projectId);
        let projectData = getProjectDropDownData(filtered);
        projectData = projectData.sort((a, b) => a.label.localeCompare(b.label));

        return (
            <div>
                <div className={classes.wrapperFormControl}>
                    <FormControl className={classes.formControl1}>
                        <SelectCustom
                            value={selectedProject}
                            placeholder="Search"
                            onChange={(e) => this.selectProjectHandler(e)}
                            options={projectData}
                            className={classes.selectCustom}
                            isBorder
                            isMultiSelect={false}
                            readOnly={false}
                        />
                        <span className={classes.label}>Select project</span>
                    </FormControl>
                </div>

                <div>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>Variables</TableCell>
                                <TableCell className={classes.tableCell}>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {variableData.map((data) => (
                                <TableRow className={classes.tableBoadyRow} key={data.name}>
                                    <TableCell>{data.key}</TableCell>
                                    <TableCell>{TestStepUtils.isShowAsterik(data.key, data.value)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    projects: state.projectReducer.projectsByIdAndName,
});

const mapDispatchToProps = (dispatch) => {
    return {
        // Variable
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),

        clearErr: () => dispatch(FlowActions.clearErr()),

        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CloneVariablesModal));
