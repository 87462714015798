import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

const colorYellow = '#f1b737';

const styles = () =>
    createStyles({
        avatar: {
            backgroundColor: colorYellow,
        },
        guttersStyle: {
            padding: '12px 24px',
        },
        multilineStyle: {
            margin: 0,
            padding: '0px 16px',
        },
        typography: {
            color: '#ffffff',
        },
        viewProfileButton: {
            '&:hover': {
                backgroundColor: '#ffe5aa !important',
            },
            border: `1px solid ${colorYellow}`,
            borderRadius: 4,
            color: colorYellow,
            minHeight: 25,
            padding: '3px 14px',
        },
        text: {
            color: '#ffffff',
            fontSize: '13px',
        },
    });

function ListView(props) {
    const { classes, user, isForBtn, calledFrom } = props;

    function handleViewProfile() {
        if (user && user.role && user.role.toLocaleUpperCase() === 'SDET') {
            props.backhistory.push('/profile');
        } else {
            props.backhistory.push('/admin/profile');
        }
    }

    function splitFirstLetter(value = '') {
        let val;
        if (value !== '') {
            val = value.charAt(0).toUpperCase();
        }
        return val;
    }
    function towLetterAvatar(name) {
        let letters = '';
        const nameArr = name.split(' ').splice(0, 2);
        if (nameArr.length) {
            nameArr.forEach((part) => {
                letters += splitFirstLetter(part);
            });
        }
        return letters;
    }

    return (
        <List>
            {!isForBtn && Object.keys(user).length > 0 ? (
                <ListItem disableGutters>
                    <Avatar className={classes.avatar}>
                        <Typography className={classes.typography}>{towLetterAvatar(user.name)}</Typography>
                    </Avatar>
                    {calledFrom === 'sideBarContent' ? (
                        <ListItemText classes={{ primary: classes.text, secondary: classes.text }} primary={user.name} secondary={user.email} />
                    ) : (
                        <ListItemText primary={user.name} secondary={user.email} />
                    )}
                </ListItem>
            ) : isForBtn ? (
                <ListItem className={classes.listItemStyle}>
                    <Button variant="outlined" size="small" className={classes.viewProfileButton} onClick={handleViewProfile}>
                        View Profile
                    </Button>
                </ListItem>
            ) : null}
        </List>
    );
}

export default withStyles(styles)(ListView);
