import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// icons
import Done from '@material-ui/icons/Done';
import Alert from 'mdi-material-ui/Alert';
import CheckIcon from '@material-ui/icons/Check';
import { CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Warning from '@material-ui/icons/Warning';
import Pause from '@material-ui/icons/Pause';

import { getMessageTooltip } from '../../../utils';

const styles = () => ({
    statusIcon: {
        cursor: 'pointer',
    },
    statusIconTabledata: {
        fontSize: 18,
        width: '18px !important',
        height: '18px !important',
        color: 'inherit',
    },
    check: {
        fontSize: 18,
        width: '18px !important',
        height: '18px !important',
    },
    noDisplayStatusIcon: {
        fontSize: 18,
        width: '18px !important',
        height: '18px !important',
        color: 'inherit',
        visibility: 'hidden',
    },
    statusContainer: {
        display: 'flex',
        height: '22px',
        alignItems: 'center',
        marginRight: '10px',
    },
    statusContainerModal: {
        display: 'flex',
        height: '22px',
        alignItems: 'center',
        marginLeft: '20px',
    },
    RobotoMonoImportant: {
        fontFamily: 'Roboto Mono !important',
    },
    showText: {
        width: '100%',
        padding: '0px 8px 8px',
        fontWeight: 500,
        fontSize: 12,
        overflow: 'hidden',
    },
});

function StatusSnipet(props) {
    const { testStepStatus, testStepMsg, classes, testStepNumber, id, handleHover, callBackSetState, callFrom } = props;

    let statusMap = {};
    const statusColorMap = {
        0: '#00B330', // success
        1: '#ffd53d', // partial success
        2: '#ff8800', // warning
        3: '#E8001C', // error
        4: '#0000aa', // in progress
        5: '#ffffff', // not yet run
        6: '#0b0b0b', // stop
    };

    if (callFrom === 'testStepTableData') {
        statusMap = {
            0: <FontAwesomeIcon icon={faCheck} aria-label="faCheck" id="faCheck" className={classes.check} />, // success
            1: <Warning className={classes.statusIconTabledata} aria-label="warningIcon" id="warningIcon" />, // partial success
            2: <Alert className={classes.statusIconTabledata} />, // warning
            3: <Alert className={classes.statusIconTabledata} />, // error
            4: <CircularProgress className={classes.statusIconTabledata} style={{ marginTop: 5 }} />, // in progress
            5: <CheckIcon className={classes.noDisplayStatusIcon} aria-label="checkIcon" id="checkIcon" />, // not yet run
            6: <Pause className={classes.statusIconTabledata} style={{ marginTop: 5 }} aria-label="pauseIcon" id="pauseIcon" />, // stop
            '': <CheckIcon className={classes.noDisplayStatusIcon} aria-label="checkIcon" id="checkIcon" />, // not yet run
        };
    } else {
        statusMap = {
            0: (
                <Done
                    aria-describedby={id}
                    onMouseLeave={() => callBackSetState()}
                    className={classes.statusIcon}
                    aria-label="doneIcon"
                    id="doneIcon"
                />
            ), // success
            1: <Alert aria-describedby={id} onMouseLeave={() => callBackSetState()} className={classes.statusIcon} />, // partial success
            2: <Alert aria-describedby={id} onMouseLeave={() => callBackSetState()} className={classes.statusIcon} />, // warning
            3: <Alert aria-describedby={id} onMouseLeave={() => callBackSetState()} className={classes.statusIcon} />, // error
            4: <CircularProgress aria-describedby={id} onMouseLeave={() => callBackSetState()} className={classes.statusIcon} size={23} />, // in progress
            5: (
                <CheckIcon
                    aria-describedby={id}
                    onMouseLeave={() => callBackSetState()}
                    className={classes.noDisplayStatusIcon}
                    aria-label="checkIcon"
                    id="checkIcon"
                />
            ), // not yet run
        };
    }
    const statusKey = `${testStepStatus}`.toLowerCase();
    const TooltipMessage = getMessageTooltip(testStepStatus);
    return (
        <span>
            {callFrom === 'testStepTableData' || callFrom === 'screenShotModal' ? (
                <span
                    style={{
                        color: statusColorMap[statusKey],
                    }}
                    className={callFrom === 'testStepTableData' ? classes.statusContainer : classes.statusContainerModal}
                >
                    {['0', '1', '2', '3', '6'].includes(`${testStepStatus}`) ? (
                        <TooltipMessage data={testStepMsg} position="right-end" allowTooltipHover persistTooltip isPointerEvents={false}>
                            {statusMap[statusKey]}
                        </TooltipMessage>
                    ) : (
                        statusMap[statusKey]
                    )}
                </span>
            ) : (
                <span
                    aria-describedby={id}
                    onMouseEnter={handleHover}
                    style={{
                        color: statusColorMap[statusKey],
                        verticalAlign: 'middle',
                        marginLeft: testStepNumber % 1 === 0 ? '16px' : '0px',
                        paddingRight: '8px',
                        marginRight: testStepNumber % 1 === 0 ? '-16px' : '0px',
                        top: '1px',
                        position: 'relative',
                        paddingLeft: testStepNumber % 1 === 0 ? 0 : 47,
                    }}
                >
                    {statusMap[statusKey]}
                </span>
            )}
        </span>
    );
}

StatusSnipet.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    testStepNumber: PropTypes.string.isRequired,
    handleHover: PropTypes.func,
    callBackSetState: PropTypes.func,
    testStepMsg: PropTypes.string.isRequired,
    id: PropTypes.string,
};

StatusSnipet.defaultProps = {
    id: '',
    handleHover: () => {},
    callBackSetState: () => {},
};

export default withStyles(styles)(StatusSnipet);
