// import material
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import TableCell from '../../../components/Table/TableCell';
import TableRow from '../../../components/Table/TableRow';
import { EnhancedTableHead } from '../../../components/EnhancedTable';
import { TABLES_CSS, BLACK_FONT } from '../../../common/cssConstants';

// import packages

const styles = (theme) => ({
    cell: {
        color: TABLES_CSS.normalFontColor,
        fontSize: TABLES_CSS.normalFontSize,
        width: '120px',
        maxWidth: '120px',
        display: 'inline-block',
        fontWeight: 'bold',
        [theme.breakpoints.only('xs')]: {
            maxWidth: 'calc(100vw - 252px)',
            width: 'calc(100vw - 252px)',
        },
    },
    cellRole: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: '4px 0px 0px 10px !important',
        width: 'calc(100% / 3)',
    },
    col: {
        width: 'inherit',
    },
    firstCell: {
        textAlign: 'left',
        flexDirection: 'row',
        paddingLeft: 10,
        width: 'calc(100% / 3)',
    },
    firstRowValue: {
        color: '#a3a3a3 !important',
    },
    formControl: {
        width: '100%',
        flexDirection: 'row',
    },
    horizontalRow: {
        width: '100%',
        marginBottom: '10px',
        border: 0,
        borderTop: '1px solid #DCDCDC',
    },
    label: {
        alignSelf: 'center',
        width: 90,
        '& > p': {
            color: BLACK_FONT,
        },
    },
    rootDialogContent: {
        width: '100%',
    },
    row: {
        padding: '5px 0px 0px 0px',
        display: 'flex',
        justifyContent: 'start',
    },
    selectBox: {
        width: '100%',
    },
    tableRows: {
        cursor: 'default',
        height: '30px !important',
    },
    value: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '& > p': {
            color: BLACK_FONT,
            fontSize: 16,
            fontWeight: '600',
        },
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    header: {
        height: '30px !important',
    },
    borderNone: {
        border: 'none !important',
    },
    tableBody: {
        overflow: 'hidden !important',
        maxHeight: 'unset',
    },
});

const headers = [
    { id: 'name', sortable: false, numeric: false, disablePadding: true, label: 'User', width: '50%' },
    { id: 'role', sortable: false, numeric: false, disablePadding: true, label: 'Role', width: '50%' },
];

function AddUserToGroup({ classes, handleSelectChange, users, groups, selectedGroup }) {
    return (
        <div>
            <div className={classes.rootDialogContent}>
                <div className={[classes.col, classes.label].join(' ')}>
                    <Typography>Groups</Typography>
                </div>
                <div className={classes.row}>
                    <div className={[classes.col, classes.value].join(' ')}>
                        <FormControl className={classes.formControl}>
                            <Select
                                value={selectedGroup}
                                onChange={handleSelectChange}
                                className={classes.selectBox}
                                disableUnderline
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                inputProps={{
                                    name: 'selectedGroup',
                                    id: 'selectedGroup',
                                }}
                            >
                                <MenuItem value="Select Group" disabled>
                                    Select Group
                                </MenuItem>
                                {groups &&
                                    groups.map((group) => (
                                        <MenuItem
                                            key={group.id}
                                            value={group.id}
                                            disabled={
                                                users.length === 1 && users[0].groups
                                                    ? users[0].groups.map((g) => g.id).indexOf(group.id) !== -1
                                                    : false
                                            }
                                        >
                                            {group.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <hr className={classes.horizontalRow} />
                <Table>
                    <EnhancedTableHead
                        headers={headers}
                        style={{ paddingRight: 0 }}
                        updateRowHeight={classes.header}
                        updateBorder={classes.borderNone}
                    />
                    <TableBody className={classes.tableBody}>
                        {users.map((user, index) => {
                            return (
                                <TableRow key={index} className={classes.tableRows}>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        padding="none"
                                        className={[classes.firstCell, classes.noWrap, classes.minWidth].join(' ')}
                                        width={headers[0].width}
                                        data-resize={`element${0}_c`}
                                    >
                                        <div style={{ width: '100%' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography noWrap className={classes.cell}>
                                                    {user.name}
                                                </Typography>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        padding="none"
                                        className={[classes.cellRole, classes.noWrap, classes.minWidth].join(' ')}
                                        width={headers[1].width}
                                        data-resize={`element${1}_c`}
                                    >
                                        <div style={{ width: '100%' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography noWrap className={classes.cell}>
                                                    {user.role}
                                                </Typography>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}
export default withStyles(styles)(AddUserToGroup);
