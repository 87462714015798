import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Remove from '@material-ui/icons/RemoveCircle';
import Add from '@material-ui/icons/AddCircle';

const styles = () => ({
    zoom: {
        width: 190,
        height: '100%',
        backgroundColor: '#ECECEC',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    iconButtonStyle: {
        width: 25,
        height: 25,
    },
    parentDiv: {
        background: 'white',
        color: '#000',
        width: '100px',
        fontWeight: 500,
        fontSize: '17px',
    },
    percentage: {
        fontSize: '1em',
        color: '#000',
        border: 'none',
        width: '55px',
        height: '29px',
        textAlign: 'end',
        background: 'transparent',
        '&:focus': {
            outline: 'none',
        },
        /* Firefox */
        '-moz-appearance': 'textfield',
        /* Chrome, Safari, Edge, Opera */
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
});

let interval = null;
let intervalTime = 0;
let tempPercentValue = 0;
class ImageResizer extends Component {
    state = {
        percentValue: 100,
    };

    UNSAFE_componentWillMount() {
        this.updateByProp(this.props.defaultValue);
    }

    componentDidMount() {
        document.getElementById('i_r_percentage').value = this.state.percentValue;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.defaultValue !== this.state.percentValue) {
            this.updateByProp(nextProps.defaultValue);
        }
    }

    updateByProp = (value) => {
        this.setState({ percentValue: value }, () => {
            this.updateValues(this.state.percentValue);
        });
    };

    updateValues = (size) => {
        document.getElementById('i_r_percentage').value = size;
        const { imgId } = this.props;
        const image = document.getElementById(imgId);
        if (image) {
            image.style.width = `${size}%`;
        }
    };

    holdZoomInOut = (type) => {
        this.zoom(type);
        interval = setInterval(() => {
            if (intervalTime >= 500) {
                this.zoom(type, null, 1);
            }
            intervalTime += 25;
        }, 25);
    };

    stopZoomInOut = (key) => {
        clearInterval(key);
        intervalTime = 0;
        if (tempPercentValue > 0) {
            this.setState(
                {
                    percentValue: tempPercentValue,
                },
                () => {
                    tempPercentValue = 0;
                    this.props.onChange(this.state.percentValue);
                },
            );
        }
    };

    zoom = (type, value, changer = 10) => {
        let size = value;
        if (size === undefined || size === null) {
            if (tempPercentValue > 0) {
                size = tempPercentValue;
            } else {
                size = this.state.percentValue;
            }
        }
        if (type === 'in' && size < 200) {
            if (size + changer > 200) {
                size = 200;
            } else {
                size += changer;
            }
        } else if (type === 'out' && size > 10) {
            if (size - changer < 10) {
                size = 10;
            } else {
                size -= changer;
            }
        }
        if (intervalTime < 500) {
            this.setState({ percentValue: size }, () => {
                this.props.onChange(this.state.percentValue);
                if (this.state.percentValue >= 10 && this.state.percentValue <= 200) {
                    this.updateValues(this.state.percentValue);
                }
            });
        } else {
            tempPercentValue = size;
            this.updateValues(size);
        }
    };

    changePercent = (e) => {
        if (e.target && e.target.value) {
            this.zoom('exact', parseInt(e.target.value, 10));
        }
    };

    render() {
        const { classes } = this.props;
        // const {
        //     percentValue,
        // } = this.state;
        return (
            <div className={classes.zoom}>
                <IconButton
                    className={classes.iconButtonStyle}
                    onMouseDown={() => {
                        this.holdZoomInOut('out');
                    }}
                    onMouseUp={() => {
                        this.stopZoomInOut(interval);
                    }}
                    disableRipple
                    // disabled={isZoomDisabled === '-'}
                >
                    <Remove aria-label="removeIcon" id="removeIcon" />
                </IconButton>
                <div className={classes.parentDiv}>
                    <input
                        type="number"
                        id="i_r_percentage"
                        min="10"
                        max="200"
                        // value={percentValue}
                        className={classes.percentage}
                        onChange={this.changePercent}
                    />
                    %
                </div>
                <IconButton
                    className={classes.iconButtonStyle}
                    onMouseDown={() => {
                        this.holdZoomInOut('in');
                    }}
                    onMouseUp={() => {
                        this.stopZoomInOut(interval);
                    }}
                    disableRipple
                >
                    <Add aria-label="addicon" id="addIcon" />
                </IconButton>
            </div>
        );
    }
}

ImageResizer.propTypes = {
    imgId: PropTypes.string.isRequired,
};

export default withStyles(styles)(ImageResizer);
