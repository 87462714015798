import React, { useEffect, useState } from 'react';
import { IconButton, MenuItem, CircularProgress } from '@material-ui/core';
import MoreHorizOutlined from '@material-ui/icons/MoreHorizOutlined';
import Menu from '@material-ui/core/Menu';

import { CustomSwitch } from '../../../../../common/miniComponents';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import singleFileCreationService from '../../../../../services/singleFileCreationService';
import { checkArrayLength, checkKeyInObject, checkObject, TestCaseUtils, getTestCaseStatus } from '../../../../../utils';
import { SEGMENT_EVENT } from '../../../../../common/constants';
import { track } from '../../../../../services/Segment';
import { mapStateToProps, mapDispatchToProps } from './redux_utils';
import TestStepConfigurationService from '../../../../../services/TestStepConfigSettingService';

import config from './../../../../../config/index';

import { successAlertBar, warningAlertBar } from '../../../../../services/AlertBarService';
import Tooltip from '../../../../../components/Tooltip';

const TestStepConfigurationMenu = (props) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(Boolean(anchorEl));
  const [options, setOptions] = useState([]);
  const [cacheXpaths, setCacheXpaths] = useState(true);
  const [disablDebugStepsState, setDisablDebugStepsState] = useState(false);
  const [debugStepsState, setDebugStepsState] = useState(false);
  const [configSettingLoading, setConfigSettingLoading] = useState(true);
  const [projectDetailsLoading, setProjectDetailsLoading] = useState(true);


  const { projectId, testCaseId } = useParams();

  const { classes, toggleAutoScroll, autoScroll, downloadLogs, selectedTestCase, listView, toggleListView, selectedTab, headerMiddleActions, isWsRunning, setAllCacheXpaths, creationMode, shadowDom, toggleCreationMode, openGenerateModal, toggleShadowDom, getProjectDetails, selectedProject } = props
  const ITEM_HEIGHT = 90;

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true)
  };



  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);

  };

  useEffect(() => {
    getTestStepConfigSetting();
    getProjectDetails(projectId);

  }, [])

  useEffect(() => {
    if (selectedProject && Object.keys(selectedProject).length) {
      setProjectDetailsLoading(false)
    }
  }, [selectedProject])


  const getTestStepConfigSetting = async () => {

    try {
      const data = await TestStepConfigurationService.getTestStepConfigSetting(testCaseId);
      setConfigSettingLoading(false)
      if (data) {

        if (!(data.autoScroll)) {
          toggleAutoScroll();
        }
        if (data.shadowDom) {
          toggleShadowDom(true);
        }
        if (data.creationMode) {
          toggleCreationMode();
        }




      }

    } catch (err) {

      setConfigSettingLoading(false)
      console.log(err);


    }

  }

  const updateTestStepConfigsetting = async (data) => {
    try {
      const result = await TestStepConfigurationService.updateTestStepConfigSetting(testCaseId, data)
      if (result && checkObject(result) && result.shadowDom) {
       // successAlertBar({ message: 'Shadow DOM scan is enabled.', close: true, duration: 8000 });
      } else if (result && checkObject(result) && !(result.shadowDom)) {
       // warningAlertBar({ message: 'Shadow DOM scan is disabled.', close: true, duration: 8000 });


      }
    } catch (err) {
      getTestStepConfigSetting();

    }
  }



  const downloadLogsFromTestSteps = async () => {
    const {
      user: { userId },
      selectedTestCase
    } = props;


    const tcId = Number(testCaseId) ? Number(testCaseId) : 0;

    const data = {
      projectId,
      testCaseId: tcId,
      userId,
      isEmail: 1,
    };
    const response = await downloadLogs(data);
    if (response) {
      singleFileCreationService.showFile(
        response,
        'download',
        ` ${selectedTestCase && selectedTestCase.testCaseName ? selectedTestCase.testCaseName : 'filename'}.zip`,
        'application/zip',
      );
    }
  };

  const disabledRunButton = () => {
    const {
      actionType,
      isWsRunning, // true on 3, 4, 5 false on 8, 13, 16 and clearWsData function
      isSaveRequestPending, // while save steps request is pending it's true
      saveBtnPressLoader, // when save steps request is sent it's true, false on success/faliure
      selectedSteps, // array with instruction number of selected steps
      testSteps, // selectedTestCaseReducer object of steps
      wsRunningCaseData,
      selectedTestCase,
      isGenerateBtnClicked,
      openGenerateModal,
      dontAllowRetry
    } = props;

    const testCaseStatusFromFunction = getTestCaseStatus(selectedTestCase, wsRunningCaseData);


    const __isMsgType13Received = checkKeyInObject(wsRunningCaseData, testCaseId)
      ? wsRunningCaseData[testCaseId].isMsgType13Received
      : false;
    const __isMsgType16Received = checkKeyInObject(wsRunningCaseData, testCaseId)
      ? wsRunningCaseData[testCaseId].isMsgType16Received
      : false;
    const _TestCaseUtils = TestCaseUtils
    const isRetryButtonDisabledForTestCase = _TestCaseUtils.isRetryButtonDisabled[testCaseId]; // true/false against testCaseId
    // forceDisableRetryNSave is true when case is between msgType 2 & 8 and run by other user
    const forceDisableRetryNSave =
      checkKeyInObject(wsRunningCaseData, testCaseId) && // true between msgType 2 and 8
      !wsRunningCaseData[testCaseId].isMsgType2Sent; // isMsgType2Sent is true when case run by current user

    return (
      !checkObject(testSteps) || // no steps
      actionType === 'add' || // step addition is in progress
      actionType === 'edit' || // step updation is in progress
      isGenerateBtnClicked[testCaseId] || // msgtype 3 is not received 
      testCaseStatusFromFunction.code === 'in progress' ||  // Need to get TC status when we have delay in msgtype 3
      openGenerateModal || // generate modal is opened
      checkKeyInObject(isWsRunning, testCaseId, 'bool') || // generation is in progress
      saveBtnPressLoader || // save button pressed
      forceDisableRetryNSave || // generation is in progress, run by other user
      checkKeyInObject(dontAllowRetry, testCaseId) || // run button pressed
      isSaveRequestPending || // save request is in progress
      isRetryButtonDisabledForTestCase || // run button is intentionally disabled
      __isMsgType13Received || // stop at msgType = 13 // temp for debugPoint
      __isMsgType16Received || // stop at msgType = 16    // temp for debugPoint
      checkArrayLength(selectedSteps) // if any step selected
    );
  }

  const changeDebugStepsState = (testCaseId, flag, onlyState = false, disableDebug = false) => {
    const { debugPointList, testSteps, updatedebugPointList, updateTestStepsObject } = props;
    setDisablDebugStepsState(disableDebug)
    setDebugStepsState(flag)

    if (checkObject(testSteps) && !onlyState) {
      if (flag) {
        const debugInstrArray = [];
        Object.keys(testSteps).forEach((instrNum) => {
          testSteps[instrNum].debug = true;
          debugInstrArray.push(instrNum);
        });
        debugPointList[testCaseId] = debugInstrArray;
      } else {
        Object.keys(testSteps).forEach((instrNum) => {
          delete testSteps[instrNum].debug;
        });
        delete debugPointList[testCaseId];
      }
      updateTestStepsObject(testSteps);
      updatedebugPointList(debugPointList);
    }
  };

  const { testSteps, instrNumArray, shadowDomEnabled } = props;

  const isAnyDownloadFileAvailable = checkObject(testSteps) && checkArrayLength(instrNumArray) && instrNumArray.some((instrNum) => testSteps[instrNum] && testSteps[instrNum].downloadFile)

  useEffect(() => {
    const isNewNlp = (config.isNewNlp === 'true');


    const options = [
      {
        id: 'OriginalSteps',
        label: 'Original Steps',
        isDisabled: `${selectedTestCase.testCaseId}` === '-1',
        toggle: {
          checked: selectedTab,
          action: () => {
            selectedTab === 0 ? headerMiddleActions.handleTabs(1) : headerMiddleActions.handleTabs(0);
          }
        }
      },
      {
        id: 'line',
        label: 'Line',
        isDisabled: selectedTab === 1,
        toggle: {
          checked: listView,
          action: () => {
            toggleListView(!listView);
          }
        }
      },
      {
        id: 'autoScroll',
        label: 'Auto Scroll',
        toggle: {
          loading: configSettingLoading,
          checked: autoScroll,
          action: () => {

            const data = {
              creationMode,
              shadowDom,
              autoScroll: !autoScroll
            }

            toggleAutoScroll();
            updateTestStepConfigsetting(data);
          }
        }
      },
      {
        id: 'cacheSteps',
        label: 'Cache Steps',
        isDisabled: `${selectedTab}` === '1' || (checkObject(isWsRunning) && isWsRunning[testCaseId]) || `${testCaseId}` === '-1',

        toggle: {
          loading: configSettingLoading,
          checked: cacheXpaths,
          action: () => {

            setAllCacheXpaths(!cacheXpaths);
            setCacheXpaths(!cacheXpaths);

          }
        }
      },
      {
        id: 'creationMode',
        label: 'Creation Mode',
        isDisabled: disabledRunButton(),
        toggle: {
          loading: configSettingLoading,
          checked: creationMode,
          action: () => {
            const eventName = SEGMENT_EVENT.CREATION_MODE
            track(eventName, { testcase_name: selectedTestCase.testCaseName, testcase_id: selectedTestCase.testCaseId, sataus: disablDebugStepsState ? 'Off' : 'On' })
            toggleCreationMode();
            changeDebugStepsState(testCaseId, false, false, !disablDebugStepsState);
            const data = {
              creationMode: !creationMode,
              shadowDom,
              autoScroll
            }
            updateTestStepConfigsetting(data);


          }
        }
      },
      {
        id: 'enableShadowDom',
        label: 'Enable shadow DOM',
        isDisabled: !isNewNlp || shadowDomEnabled,
        style: (!isNewNlp) || (selectedProject && selectedProject.shadowDomEnabled) ? { opacity: '0.5' } : {},
        toggle: {
          loading: configSettingLoading || (projectDetailsLoading),
          checked: shadowDomEnabled || shadowDom,
          action: () => {
            if (!(isNewNlp) || (selectedProject && selectedProject.shadowDomEnabled)) {
              return
            }

            toggleShadowDom(!shadowDom);
            const data = {
              creationMode,
              shadowDom: !shadowDom,
              autoScroll
            }
            updateTestStepConfigsetting(data);


          }
        }
      },
      {
        id: 'removeAllDebugSteps',
        label: 'Remove all debug steps',
        menuAction: () => {
          changeDebugStepsState(testCaseId, false);
          handleClose();

        },
        isDisabled: disabledRunButton() || disablDebugStepsState,

      },

      {
        id: 'downloadLogs',
        label: 'Download logs',
        isDisabled: false,
        menuAction: () => {
          downloadLogsFromTestSteps();
          handleClose();
        }
      },
      {
        id: 'getDownloadedFiles',
        label: 'Get downloaded files',
        isDisabled: !isAnyDownloadFileAvailable,
        menuAction: () => {
          console.log(selectedTestCase)
          TestCaseUtils.downloadFileZip(selectedTestCase.testCaseName, selectedTestCase.testCaseId);
          handleClose();

        }
      }
    ];

    setOptions(options);

    if (creationMode === false && disablDebugStepsState) {
      setDisablDebugStepsState(false);
    }

  }, [autoScroll, listView, selectedTab, isWsRunning, cacheXpaths, creationMode, configSettingLoading, shadowDom, projectDetailsLoading, selectedProject, isAnyDownloadFileAvailable])


  return (
    <React.Fragment>
      <IconButton onClick={openMenu}>
        <MoreHorizOutlined />
      </IconButton>
      <Menu id="test-step-config-menu" anchorEl={anchorEl} open={open} onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '230px',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem disabled={option.isDisabled} key={option.id}>
            <div style={option.style} className={classes.option_root}>
              <div onClick={() => {
                if (!option.isDisabled && option.menuAction) {
                  option.menuAction();
                }
              }} className={classes.option}>
                {option.label}
              </div>
              {
                option.toggle &&
                <div>
                  {
                    !(option.toggle.loading) &&
                    <Tooltip data={option.id ==='enableShadowDom' && shadowDomEnabled ? 'Activated on the Project level' : ''}>
                      <CustomSwitch
                        checked={option.toggle.checked}
                        onChange={option.toggle.action ? option.toggle.action : () => { }}
                        value={option.id}
                        h={16}
                        w={30}
                        margin="2px 0 7px 0"
                      />
                    </Tooltip>
                  }
                  {
                    option.toggle.loading &&
                    <CircularProgress size={18} />
                  }

                </div>
              }

            </div>

          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>

  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TestStepConfigurationMenu));
