import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

// Keyboard input comprehension node modules
import CloseOnEscape from 'react-close-on-escape';

import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Close from '@material-ui/icons/Close';
import SelectCustom from '../GeneralModal/Select';
import { FlowActions, ModalActions, SelectedTestCaseActions } from '../../../store/actions';
import { checkArrayLength, checkObject, checkKeyInObject, getCountAvatar } from '../../../utils/utils';
import { SuggestionBox } from '../../../components/SuggestionBox/SuggestionBox';

// custom variable
import {
    DIALOG_ROOT,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_HEADER_CLOSE_ICON,
    MODAL_FOOTER,
    MODAL_SUBMIT_BUTTON,
    MODAL_CANCEL_BUTTON,
    MODAL_HEADER_TITLE,
    TOOLBAR_COUNT_AVATAR,
    BLACK_FONT,
} from '../../../common/cssConstants';
import Tooltip from '../../../components/Tooltip';

const styles = (theme) => ({
    root: {
        width: '100%',
        display: 'flex',
    },
    dialog: {
        textAlign: 'center',
        backgroundColor: '#e9effd',
        borderBottom: '1px solid #dcdcdc',
    },
    actionBtn: {
        padding: '2px 8px',
        minHeight: 0,
        borderColor: '#1067ce',
        textTransform: 'capitalize',
        borderRadius: '6px',
        minWidth: '75px',
    },
    formControl: {
        width: '100%',
        flexDirection: 'column',
        padding: '0 45px 0 30px',
        paddingBottom: '14px',
        paddingTop: '14px',
        borderBottom: '1px solid #dcdcdc',
        borderTop: '1px solid #dcdcdc',
        '&>p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    formControl1: {
        width: '100%',
        flexDirection: 'column',
        padding: '0 40px 0 30px',
        paddingBottom: '14px',
        paddingTop: '14px',
        '&>p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    nameTitle: {
        width: '100%',
        padding: '0 30px',
        display: 'flex',
        marginBottom: 5,
        justifyContent: 'space-between',
        '&>p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#3B91DF',
            fontSize: '14px',
        },
    },
    formControlAddFromHeader: {
        padding: '0px 30px 5px 30px',
        width: '65%',
        flexDirection: 'column',
        '&>p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    placeholder: {
        '&>div>div>:nth-child(3)::-webkit-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
        '&>div>div>:nth-child(3)::-moz-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    dialogTitleStyle: MODAL_HEADER_TITLE,
    listItem: {
        width: '100%',
        minHeight: '40px',
        borderBottom: '1px solid #bac4ce',
        backgroundColor: '#F9F9F9',
        paddingTop: 0,
        paddingBottom: 0,
        padding: '0 30px',
        display: 'flex',
        alignItems: 'center',
        '&:hover [class*="secondaryAction"]': {
            visibility: 'visible',
        },
    },
    listItemText: {
        width: '100%',
        minHeight: '40px',
        borderBottom: '1px solid #ECECEC',
        backgroundColor: '#FFFFFF',
        paddingTop: 0,
        paddingBottom: 0,
        padding: '0 30px',
        display: 'flex',
        alignItems: 'center',
        '&:hover [class*="secondaryAction"]': {
            visibility: 'visible',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: '#FAFAFA',
        },
    },
    secondaryAction: {
        position: 'inherit',
        transform: 'none',
        width: '20%',
        display: 'flex',
        alignSelf: 'flex-start',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'flex-end',
        visibility: 'hidden',
    },
    mainText: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        minHeight: '40px',
        wordBreak: 'break-all',
        '&>span': {
            width: '80%',
            overflow: 'hidden',
            maxHeight: '40px',
            wordBreak: 'break-word',
            display: 'inline-block',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '1.5',
        },
        '&>div': {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '40px',
        },
    },
    inputFieldsTags: {
        width: '100%',
        minHeight: '38px',
        border: '1px solid #ced4da',
        borderRadius: '3px !important',
    },
    mainTextFull: {
        '&>span': {
            maxHeight: 'unset',
        },
    },
    select: {
        width: '100%',
    },
    dialogModal: {
        overflow: 'hidden',
        '&>div': {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    dialogContentAddFromHeader: {
        overflowX: 'hidden',
        width: 700,
        padding: '0 !important',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 100px)',
        },
    },
    font: {
        fontSize: 12,
        fontWeight: 500,
        color: BLACK_FONT,
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    toolbarCountAvatar: { ...TOOLBAR_COUNT_AVATAR },
    cloneHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    originalFlow: {
        color: 'rgb(89, 89, 89)',
        fontSize: 12,
        marginTop: 12,
        marginLeft: -100,
        fontWeight: 500,
    },
    inputContainer: {
        width: 'calc(100% - 110px)',
    },
    avatar: {
        color: '#000000',
        height: 22,
        padding: '0px 4px',
        display: 'inline-block',
        overflow: 'hidden',
        minWidth: 22,
        fontSize: 13,
        textAlign: 'center',
        lineHeight: 1.9,
        marginLeft: 10,
        marginRight: 8,
        borderRadius: 22,
        fontWeight: 500,
        backgroundColor: '#AAD4F7',
    },
    label: {
        color: '#595959',
        fontSize: '12px',
    },
    error: {
        color: 'red',
        fontSize: 12,
        marginTop: 5,
    },
    selectCustom: {
        width: 'calc(100% - 410px)',
    },
});

const buttonText = { add: 'Save', addFromHeader: 'Save', edit: 'Update', clone: 'Clone' };
const modalHeading = { add: 'New', addFromHeader: 'New', edit: 'Update', clone: 'Clone', view: 'List' };

class FlowModal extends React.Component {
    state = {
        name: this.props.blockData.name || '',
        steps: [],
        originalFlowName: '',
        selectedStepsIds: [],
        changingStep: -1, // This will use to store index of add and edit step
        isFieldUpdate: false,
        selectedDataStepsIds: [],
        changingDataStep: -1, // This will use to store index of add and edit step
        isSubmitted: false,
        actionType: '',
        viewFull: '',
        selectedProject: {},
        dataSteps: [],
        modalName: '',
    };

    UNSAFE_componentWillMount() {
        this.setState({ modalName: this.props.modalName });
    }

    componentDidMount() {
        this.componentMounted();
    }

    UNSAFE_componentWillUpdate() {
        const { modalName, steps } = this.state;
        if (modalName !== 'view' && (!checkArrayLength(steps) || steps[steps.length - 1].instr !== '')) {
            this.clickHandler('add', (!checkArrayLength(steps) ? 0 : steps.length) - 1);
        }
    }

    onChangeInstr = (index) => async (e, { newValue }) => {
        const { steps = [] } = this.state;
        const cloneSteps = JSON.parse(JSON.stringify(steps));
        if (steps[index]) {
            steps[index].instr = newValue;
            this.setState({ steps });
        } else {
            const _step = {
                instr: newValue,
                instrNum: '-1',
                data: '',
                expectedResults: '',
                id: index,
            };
            cloneSteps.push(_step);
            this.setState({ steps: cloneSteps });
        }
    };

    onChangeData = (index) => async (e, { newValue }) => {
        const { steps } = this.state;
        const cloneSteps = checkArrayLength(steps) ? JSON.parse(JSON.stringify(steps)) : [];

        if (checkArrayLength(steps) && steps[index]) {
            steps[index].data = newValue;
            this.setState({ steps });
        } else {
            const _step = {
                instr: '',
                instrNum: '-1',
                data: newValue,
                expectedResults: '',
                id: index,
            };
            cloneSteps.push(_step);
            this.setState({ steps: cloneSteps });
        }
    };

    onChangeExpectedResult = (index) => async (e, { newValue }) => {
        const { steps } = this.state;
        const cloneSteps = checkArrayLength(steps) ? JSON.parse(JSON.stringify(steps)) : [];

        if (checkArrayLength(steps) && steps[index]) {
            steps[index].expectedResults = newValue;
            this.setState({ steps });
        } else {
            const _step = {
                instr: '',
                instrNum: '-1',
                data: '',
                expectedResults: newValue,
                id: index,
            };
            cloneSteps.push(_step);
            this.setState({ steps: cloneSteps });
        }
    };

    componentMounted = () => {
        const {
            blockData: { name = '', steps },
        } = this.props;
        const { modalName } = this.state;
        const _name = name;
        let selectedStepsIds = steps && steps.map((step) => step.instrNum);
        let selectedDataStepsIds = steps && steps.map((step) => step.instrNum);
        if (!checkArrayLength(selectedStepsIds) && modalName === 'addFromHeader') {
            selectedStepsIds = ['0'];
            selectedDataStepsIds = ['0'];
        }
        this.setState({ name: _name, selectedStepsIds, steps, selectedDataStepsIds, originalFlowName: _name });
        if (modalName === 'edit') {
            this.setState({
                dataSteps: steps,
            });
        }
    };

    updateModal = (name) => {
        this.setState({ modalName: name }, () => {
            this.componentMounted();
        });
    };

    handleChange = (e) => {
        this.setState({
            name: e.target.value,
            isSubmitted: false,
        });
    };

    clearForm = () => {
        const { projectId, getFlowDataByProject } = this.props;
        const { modalName } = this.state;
        this.setState({
            name: '',
            selectedStepsIds: [],
            selectedDataStepsIds: [],
        });
        this.props.handleClose(false, {});
        if (modalName === 'addFromHeader' || modalName === 'clone' || modalName === 'add' || modalName === 'edit') {
            getFlowDataByProject(projectId);
        }
    };

    handleSubmit = () => {
        const {
            shouldUpdateBlock,
            blockData,
            cloneFlow,
            createNewFlow,
            projectId,
            showSnackBar,
            user: { accountId },
            updateFlow,
        } = this.props;
        const { name, modalName, dataSteps, selectedProject } = this.state;
        let { steps } = this.state;
        const filterd = steps.filter((stp) => stp.instr.length);
        steps = steps.filter((stp) => stp.instr.length);
        if (name && !name.includes(' ')) {
            this.setState({ isSubmitted: true });
            const obj = { ...blockData, steps: filterd };
            obj.name = name;
            obj.curPrjId = Number(projectId);
            if (modalName === 'edit') {
                const selectedDataSteps = dataSteps;
                obj.data = selectedDataSteps;
                obj.steps = steps;
                shouldUpdateBlock();
                updateFlow(obj, this.clearForm);
            } else if (modalName === 'clone') {
                if (!checkArrayLength(Object.keys(selectedProject))) {
                    showSnackBar('Please select project');
                    return;
                }
                obj.name = blockData.name;
                obj.newName = name;
                obj.projectId = Number(checkKeyInObject(selectedProject, 'projectId', 'value', 0));
                shouldUpdateBlock();
                cloneFlow(obj, this.clearForm);
            } else if (modalName === 'addFromHeader') {
                obj.name = name;
                const sendsteps = [];
                for (let i = 0; i < steps.length; i++) {
                    if (steps[i].instr.length) {
                        sendsteps.push({
                            instr: steps[i].instr,
                            xpath: steps[i].xpath,
                            value: steps[i].value,
                            data: steps[i].data,
                            expectedResults: steps[i].expectedResults,
                        });
                    }
                }
                obj.steps = sendsteps;
                obj.accountId = accountId;
                obj.projectId = Number(projectId);
                shouldUpdateBlock();
                createNewFlow(obj, this.clearForm, projectId);
            } else {
                const flowObj = { ...blockData, steps };
                flowObj.name = name;
                createNewFlow(flowObj, this.clearForm, projectId);
            }
        } else {
            showSnackBar('Spaces are not allowed in flow name');
        }
        this.props.emptySelectedSteps();
    };

    addNewStep = (index) => {
        const { steps } = this.state;
        const _steps = checkArrayLength(steps) ? JSON.parse(JSON.stringify(steps)) : [];
        const _step = {
            instr: '',
            instrNum: '-1',
            data: '',
            expectedResults: '',
            id: index,
        };
        _steps.splice(index + 1, 0, _step);
        this.setState({ actionType: 'add', changingStep: index + 1, isFieldUpdate: true, steps: _steps });
    };

    removeNewStep = (index) => {
        const { steps } = this.state;
        if (steps && steps.length) {
            const _steps = JSON.parse(JSON.stringify(steps));
            _steps.splice(index, 1);
            this.setState({ actionType: 'add', changingStep: index, isFieldUpdate: true, steps: _steps });
        }
    };

    clickHandler = (clickEvent, index, step) => {
        const { selectedStepsIds, steps } = this.state;
        switch (clickEvent) {
            case 'add': {
                this.addNewStep(index);
                break;
            }
            case 'edit': {
                this.setState({ actionType: 'edit', changingStep: index, isFieldUpdate: true });
                break;
            }
            case 'delete': {
                // to click on delete/bin icon
                break;
            }
            case 'remove': {
                // to click on (-) icon, only remove from block
                const newSteps = steps.filter((item) => `${item.instr}` !== `${step.instr}`);
                if (this.state.modalName === 'add') {
                    const newSelectedStepsIds = selectedStepsIds.filter((id) => `${id}` !== `${step.instrNum}`);
                    this.setState({ selectedStepsIds: newSelectedStepsIds, steps: newSteps });
                } else {
                    this.setState({ steps: newSteps, changingStep: -1 });
                }
                break;
            }
            case 'view': {
                this.setState((state) => {
                    if (state.viewFull === `${step.instr}-${index}`) {
                        return { viewFull: '' };
                    }
                    return { viewFull: `${step.instr}-${index}` };
                });
                break;
            }
            default:
                break;
        }
    };

    addStepId = (willAdd, value, data, FTD) => {
        const { changingStep, selectedStepsIds, steps, modalName } = this.state;
        const { allSteps } = this.props;
        const _steps = checkArrayLength(steps) ? JSON.parse(JSON.stringify(steps)) : [{ instr: value, xpath: '', stepId: '' }];
        if (willAdd) {
            if (modalName === 'addFromHeader' || modalName === 'edit' || modalName === 'clone') {
                _steps.splice(changingStep, 1, { instr: value, xpath: '', stepId: '', data });
                if (selectedStepsIds.indexOf(`${changingStep + 1}`) < 0) {
                    selectedStepsIds.push(`${changingStep + 1}`);
                }
            } else {
                const newStep = checkArrayLength(allSteps) && allSteps.find((step) => step.instr === value);
                const newInstrNum = (checkKeyInObject(newStep, 'instrNum') && newStep.instrNum) || -1;
                if (checkObject(_steps) && checkObject(newStep) && changingStep) {
                    _steps.splice(changingStep, 1, newStep);
                }
                if (selectedStepsIds.indexOf(`${changingStep + 1}`) < 0) {
                    selectedStepsIds.push(`${newInstrNum}`);
                }
            }
        } else {
            _steps.splice(changingStep, 1);
        }
        if (!FTD || (FTD === 'FTD' && value.length > 0 && data.length > 0)) {
            this.setState({ actionType: '', isFieldUpdate: false, steps: _steps });
        }
    };

    addDataStepId = (willAdd, value) => {
        const { changingDataStep, selectedDataStepsIds, dataSteps, modalName } = this.state;
        const { allSteps } = this.props;
        const _dataSteps = checkArrayLength(dataSteps) ? JSON.parse(JSON.stringify(dataSteps)) : [{ instr: value, xpath: '', stepId: '' }];
        if (willAdd) {
            if (modalName === 'addFromHeader' || modalName === 'clone') {
                _dataSteps.splice(changingDataStep, 1, { instr: value, xpath: '', stepId: '' });
                if (changingDataStep !== -1) {
                    selectedDataStepsIds.push(`${changingDataStep + 1}`);
                }
            } else {
                const newStep = checkArrayLength(allSteps) && allSteps.find((step) => step.instr === value);
                const newInstrNum = (checkKeyInObject(newStep, 'instrNum') && newStep.instrNum) || -1;
                if (checkObject(_dataSteps) && checkObject(newStep) && changingDataStep) {
                    _dataSteps.splice(changingDataStep, 1, newStep);
                }
                if (newInstrNum !== -1) {
                    selectedDataStepsIds.push(`${newInstrNum}`);
                }
            }
        } else {
            _dataSteps.splice(changingDataStep, 1);
        }
        this.setState({ changingDataStep: -1, dataSteps: _dataSteps });
    };

    updateDataStepId = (willUpdate, value, id) => {
        // Commented code will use (if needed) after getting proper data in get api
        if (willUpdate) {
            const { changingDataStep, dataSteps, modalName } = this.state;
            // const { allSteps } = this.props;
            const listed = dataSteps;
            if (modalName === 'edit' && listed[id]) {
                listed[id].data = value;
                this.setState({ changingDataStep: -1, steps: listed });
            } else if (value && (changingDataStep || changingDataStep === 0)) {
                const newStep = { xpath: '', stepId: '', instr: value };
                dataSteps.splice(changingDataStep, 1, newStep);
                this.setState({ changingDataStep: -1 });
            }
        }
    };

    updateStepId = (willUpdate, value, id, data, FTD) => {
        // Commented code will use (if needed) after getting proper data in get api
        if (willUpdate) {
            const { changingStep, steps, modalName } = this.state;
            // const { allSteps } = this.props;
            const listed = steps;
            if (modalName === 'edit' && listed[id]) {
                listed[id].instr = value;
                listed[id].data = data;
                if (!FTD || (FTD === 'FTD' && value.length > 0 && data.length > 0)) {
                    this.setState({ actionType: '', isFieldUpdate: false, changingStep: -1, steps: listed });
                }
            } else if (value && (changingStep || changingStep === 0)) {
                const newStep = { xpath: '', stepId: '', instr: value, data };

                steps.splice(changingStep, 1, newStep);
                if (!FTD || (FTD === 'FTD' && value.length > 0 && data.length > 0)) {
                    this.setState({ actionType: '', isFieldUpdate: false, changingStep: -1 });
                }
            }
        } else if (!FTD || (FTD === 'FTD' && value.length > 0 && data.length > 0)) {
            this.setState({ changingStep: -1, isFieldUpdate: false });
        }
    };

    selectProjectHandler = (selectedProject) => {
        this.setState({
            selectedProject: { ...selectedProject },
            isSubmitted: false,
        });
    };

    convertToSelectValues = (inputArray = []) => {
        return inputArray.map((element) => {
            return {
                value: element.projectName,
                label: element.projectName,
                projectId: element.projectId,
            };
        });
    };

    renderListItem = (fullStep, index, actionType, addStepId, updateStepId, typeCall, isFieldUpdate, changingStep) => {
        const { viewFull, steps, modalName } = this.state;
        const { allSteps, classes, userVariables } = this.props;
        // if (isFieldUpdate && index == changingStep) {
        if (modalName !== 'view') {
            return (
                <ListItem
                    key={index}
                    className={modalName === 'edit' ? classes.listItemText : classes.listItem}
                    style={{ padding: modalName === 'edit' || modalName === 'view' ? '0 20px' : '' }}
                >
                    <SuggestionBox
                        actionType={actionType}
                        addStepId={this.addStepId}
                        autoFocus={`${index}` === '0'}
                        suggestions={allSteps}
                        userVariables={userVariables}
                        instr={fullStep.instr}
                        value={fullStep.instr}
                        updateStepId={updateStepId}
                        id={index}
                        data={fullStep.data}
                        expectedResults={fullStep.expectedResults}
                        steps={steps}
                        changingStep={changingStep}
                        clickHandler={(clickEvent) => {
                            this.clickHandler(clickEvent, index, fullStep);
                        }}
                        removeNewStep={this.removeNewStep}
                        onChangeExpectedResult={this.onChangeExpectedResult}
                        onChangeData={this.onChangeData}
                        onChangeInstr={this.onChangeInstr}
                    />
                </ListItem>
            );
        }
        return (
            <ListItem
                key={`${fullStep.instr}-${index}`}
                className={classes.listItemText}
                style={{ padding: modalName === 'edit' || modalName === 'view' ? '0 20px' : '' }}
            >
                <span className={`${classes.mainText} ${`${fullStep.instr}-${index}` === viewFull ? classes.mainTextFull : ''}`}>
                    <p className={classes.noWrap} style={{ maxWidth: '33%', minWidth: '33%', padding: 0, fontSize: 12 }}>
                        <Tooltip data={fullStep.instr}>{fullStep.instr}</Tooltip>
                    </p>
                    <p className={classes.noWrap} style={{ minWidth: '33%', maxWidth: '33%', padding: '0 0 0 10px', fontSize: 12 }}>
                        <Tooltip data={fullStep.data}>{fullStep.data}</Tooltip>
                    </p>
                    <p className={classes.noWrap} style={{ minWidth: '33%', maxWidth: '33%', padding: '0 0 0 10px', fontSize: 12 }}>
                        <Tooltip data={fullStep.expectedResults}>{fullStep.expectedResults}</Tooltip>
                    </p>
                </span>
            </ListItem>
        );
    };

    render() {
        const { classes, handleClose, isLoading, open, userVariables, blockData } = this.props;
        const { actionType, isSubmitted, name, steps, isFieldUpdate, changingStep, selectedProject, modalName } = this.state;
        const isSubmitDisabled =
            !name ||
            (modalName === 'clone' && !checkArrayLength(Object.keys(selectedProject))) ||
            /\s+/g.test(name) ||
            !checkArrayLength(steps) ||
            !steps.some((_step) => checkKeyInObject(_step, 'instr'));
        return (
            <div>
                <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" className={classes.dialogModal} maxWidth={false}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            {modalHeading[modalName]} Flow
                        </DialogTitle>
                        <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                            <Close style={MODAL_HEADER_CLOSE_ICON} onClick={() => handleClose(false)} aria-label="closeIcon" id="closeIcon" />
                        </div>
                    </div>
                    <div>
                        <DialogContent className={classes.dialogContentAddFromHeader} style={modalName === 'clone' ? { width: 900 } : {}}>
                            {modalName === 'edit' || modalName === 'view' ? (
                                <div>
                                    <div className={classes.nameTitle}>
                                        <Typography style={{ display: 'flex' }}>
                                            {name}
                                            <span
                                                className={classes.toolbarCountAvatar}
                                                style={{ margin: '0 0 0 10px', padding: '0 5px 0 4px', backgroundColor: '#A6CDF0' }}
                                            >
                                                <span>
                                                    {checkKeyInObject(blockData, 'steps') && checkArrayLength(blockData.steps)
                                                        ? blockData.steps.length
                                                        : '0'}
                                                </span>
                                            </span>
                                        </Typography>
                                        {modalName === 'view' && (
                                            <FontAwesomeIcon
                                                icon={faPencilAlt}
                                                aria-label="faPencilAlt"
                                                id="faPencilAlt"
                                                style={{ color: '#3B91DF' }}
                                                onClick={() => {
                                                    this.updateModal('edit');
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            ) : modalName === 'clone' ? (
                                <div className={classes.cloneHeader}>
                                    <FormControl className={classes.formControlAddFromHeader}>
                                        <div className={classes.inputContainer}>
                                            <span className={classes.label}>Flow Name</span>
                                            <TextField
                                                inputRef={(input) => {
                                                    this._my_field = input;
                                                }}
                                                id="name"
                                                aria-label="Name"
                                                onChange={this.handleChange}
                                                value={name}
                                                className={classes.placeholder}
                                                margin="none"
                                                fullWidth
                                                autoFocus
                                                type="text"
                                                autoComplete="off"
                                                InputProps={{
                                                    classes: { input: classes.font },
                                                    endAdornment: (
                                                        <span className={classes.avatar}>
                                                            {getCountAvatar(
                                                                checkKeyInObject(blockData, 'steps') && checkArrayLength(blockData.steps)
                                                                    ? blockData.steps.length
                                                                    : '0',
                                                            )}
                                                        </span>
                                                    ),
                                                }}
                                            />
                                        </div>
                                        {/\s+/g.test(name) && <span className={classes.error}>Flow name should not contain white spaces.</span>}
                                    </FormControl>
                                    <div className={classes.originalFlow}>Original Flow Name: {this.state.originalFlowName}</div>
                                </div>
                            ) : (
                                <FormControl className={classes.formControlAddFromHeader}>
                                    <div>
                                        <span className={classes.label}>Flow Name</span>
                                        <TextField
                                            inputRef={(input) => {
                                                this._my_field = input;
                                            }}
                                            id="name"
                                            aria-label="Name"
                                            onChange={this.handleChange}
                                            value={name}
                                            className={classes.placeholder}
                                            margin="none"
                                            InputProps={{ classes: { input: classes.font } }}
                                            fullWidth
                                            autoFocus
                                            type="text"
                                        />
                                    </div>
                                    {/\s+/g.test(name) && <span className={classes.error}>Flow name should not contain white spaces.</span>}
                                </FormControl>
                            )}
                            {modalName === 'clone' ? (
                                <FormControl className={classes.formControl1}>
                                    <span className={classes.label}>Project</span>
                                    <SelectCustom
                                        value={checkKeyInObject(selectedProject, 'label', 'value', '')}
                                        onChange={(data) => {
                                            this.selectProjectHandler(data);
                                        }}
                                        className={classes.selectCustom}
                                        isBorder
                                        options={this.convertToSelectValues(this.props.projects)}
                                        isMultiSelect={false}
                                        readOnly={false}
                                    />
                                </FormControl>
                            ) : null}
                            <List dense className={classes.root}>
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        overflow: 'hidden',
                                        borderRadius: modalName === 'edit' || modalName === 'view' ? '5px' : '',
                                        margin: modalName === 'edit' || modalName === 'view' ? '0 30px' : '',
                                        boxShadow: modalName === 'edit' || modalName === 'view' ? '0 2px 7px 0 rgba(0,0,0,0.2)' : '',
                                    }}
                                >
                                    <FormControl
                                        className={classes.formControl}
                                        style={{
                                            paddingTop: '12px',
                                            borderTop: modalName === 'edit' || modalName === 'view' ? 'none' : '',
                                            padding: modalName === 'edit' || modalName === 'view' ? '12px 35px 12px 20px' : '',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Typography style={{ width: '33%', fontWeight: '500', fontSize: '12px', color: BLACK_FONT }}>
                                            Action
                                        </Typography>
                                        <Typography
                                            style={{
                                                width: '33%',
                                                fontWeight: '500',
                                                textAlign: 'left',
                                                paddingLeft: 5,
                                                fontSize: '12px',
                                                color: BLACK_FONT,
                                            }}
                                        >
                                            Data
                                        </Typography>
                                        <Typography
                                            style={{
                                                width: '33%',
                                                fontWeight: '500',
                                                textAlign: 'left',
                                                paddingLeft: 5,
                                                fontSize: '12px',
                                                color: BLACK_FONT,
                                            }}
                                        >
                                            Expected Result
                                        </Typography>
                                    </FormControl>
                                    <div
                                        className="scrollTable"
                                        style={{
                                            maxHeight: `calc(100vh - ${modalName === 'clone' ? '455px' : '379px'})`,
                                        }}
                                    >
                                        {modalName === 'addFromHeader' && !checkArrayLength(steps) ? (
                                            <ListItem
                                                key="addFromHeader"
                                                className={modalName === 'edit' ? classes.listItemText : classes.listItem}
                                                style={{
                                                    padding: modalName === 'edit' || modalName === 'view' ? '0 20px' : '',
                                                }}
                                            >
                                                <SuggestionBox
                                                    actionType={actionType}
                                                    addStepId={this.addStepId}
                                                    autoFocus={false}
                                                    userVariables={userVariables}
                                                    instr=""
                                                    updateStepId={this.updateStepId}
                                                    steps={steps}
                                                    id={checkArrayLength(steps) - 1}
                                                    changingStep={changingStep}
                                                    data=""
                                                    value=""
                                                    expectedResults=""
                                                    removeNewStep={this.removeNewStep}
                                                    onChangeExpectedResult={this.onChangeExpectedResult}
                                                    onChangeData={this.onChangeData}
                                                    onChangeInstr={this.onChangeInstr}
                                                />
                                            </ListItem>
                                        ) : (
                                            checkArrayLength(steps) &&
                                            steps.map((step, index) =>
                                                this.renderListItem(
                                                    step,
                                                    index,
                                                    step.instr,
                                                    actionType,
                                                    this.addStepId,
                                                    this.updateStepId,
                                                    'Action',
                                                    isFieldUpdate,
                                                    changingStep,
                                                ),
                                            )
                                        )}
                                    </div>
                                </div>
                            </List>
                        </DialogContent>
                        <DialogActions style={MODAL_FOOTER}>
                            {modalName !== 'view' && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => this.handleSubmit()}
                                    disabled={isSubmitted || isSubmitDisabled || isLoading}
                                    style={MODAL_SUBMIT_BUTTON}
                                >
                                    {buttonText[modalName]}
                                </Button>
                            )}
                            <CloseOnEscape
                                onEscape={() => {
                                    if (modalName === 'edit') {
                                        this.updateModal('view');
                                    } else {
                                        this.props.handleClose(false, {});
                                    }
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        if (modalName === 'edit') {
                                            this.updateModal('view');
                                        } else {
                                            this.props.handleClose(false, {});
                                        }
                                    }}
                                    style={MODAL_CANCEL_BUTTON}
                                >
                                    {modalName === 'view' ? 'Close' : 'Cancel'}
                                </Button>
                            </CloseOnEscape>
                        </DialogActions>
                    </div>
                </Dialog>
            </div>
        );
    }
}

FlowModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    blockData: PropTypes.shape({
        name: PropTypes.string,
        steps: PropTypes.array,
    }).isRequired,
    allSteps: PropTypes.array,
    modalName: PropTypes.string,
};

FlowModal.defaultProps = {
    // blockData: {
    //     name: '',
    //     steps: [],
    // },
    allSteps: [],
    modalName: 'view',
};

const mapStateToProps = (state) => ({
    isLoading: state.flowReducer.isLoading,
    user: state.authReducer.user,
    // Project reducer
    projects: state.projectReducer.projectsByIdAndName,
    userVariables: state.projectReducer.userVariables,
});

const mapDispatchToProps = (dispatch) => {
    return {
        // Flow
        createNewFlow: (...args) => dispatch(FlowActions.createNewFlow(...args)),
        cloneFlow: (...args) => dispatch(FlowActions.cloneFlow(...args)),
        getFlowDataByProject: (...args) => dispatch(FlowActions.getFlowDataByProject(...args)),
        updateFlow: (...args) => dispatch(FlowActions.updateFlow(...args)),
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        // Selected TestCase Actions
        emptySelectedSteps: (...args) => dispatch(SelectedTestCaseActions.emptySelectedSteps(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FlowModal));
