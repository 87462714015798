import { DialogActions, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';
import config from './../../../config';
import { DIALOG_ROOT } from '../../../common/cssConstants';
const { videoImageUrl } = config;
const styles = (theme) => ({
    dialogContainer: {
        minWidth: '650px',
        width: '70%',
        [theme.breakpoints.only('xs')]: {
            minWidth: 'calc(100vw - 30px)',
        },
    },
    logtitle: {
        width: '90%',
        textAlign: 'center',
    },
    video: {
        height: '100%',
        width: '100%',
        minHeight: '360px',
    },
    dialogRoot: DIALOG_ROOT,
});

let timer;
let currenttime = 0;

class VideoPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoUrl: props.videoUrl.replace('../videos', ''),
        };
    }

    componentDidMount = () => {


        this.setState({
            videoUrl:`/platform/v1/get_execution_videos?path=${this.state.videoUrl}`
        });

        setTimeout(() => {
            const ele = document.getElementById('videoPlayer');
            const rm = document.getElementById('srcRM');
            if (ele && rm) {
                // const doEnd = ele.duration;
                rm.parentNode.removeChild(rm);
                ele.setAttribute('src', `${this.state.videoUrl}`);
                ele.setAttribute('type', 'video/mp4');
                ele.setAttribute('autoplay', true);
                ele.setAttribute('controls', true);

            }
        }, 200);
    };

    play = () => {
        const ele = document.getElementById('videoPlayer');
        const dur = ele && ele.duration;
        timer = setInterval(() => {
            if (Math.round(ele.currentTime) >= Math.round(dur) - 1) {
                ele.setAttribute('src', `${this.state.videoUrl}`);
                ele.currentTime = 0;
                ele.pause();
                ele.setAttribute('poster', `${videoImageUrl}`);
                clearInterval(timer);
            }
        }, 100);
    };

    pause = () => {
        clearTimeout(timer);
        const ele = document.getElementById('videoPlayer');
        currenttime = ele.currentTime;
        const dur = ele && ele.duration;
        const check = setInterval(() => {
            if (Math.round(currenttime) + 0.65 >= Math.round(dur)) {
                ele.setAttribute('src', `${this.state.videoUrl}#t=0,${dur - 0.65}`);
                currenttime = 0;
                ele.pause();
                ele.setAttribute('poster', `${videoImageUrl}`);
                clearInterval(check);
            }
        }, 100);
    };

    seek = (ev) => {
        const { currentTime, duration } = ev.target;
        clearTimeout(timer);
        currenttime = currentTime;
        this.play();
        const check = setInterval(() => {
            if (Math.round(currenttime) <= Math.round(duration) - 1) {
                currenttime = currentTime;
                clearInterval(check);
            } else {
                currenttime = 0;
                this.pause();
                clearInterval(check);
            }
        }, 100);
    };

    render() {
        const { classes } = this.props;
        const { videoUrl } = this.state;
        /* eslint-disable jsx-a11y/media-has-caption */


        return (
                <video  onPlay={this.play} onPause={this.pause} onSeeking={this.seek} id="videoPlayer" className={classes.video}  controls>
                    <source id="srcRM"  src={videoUrl} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
          
        );
    }
}

VideoPreview.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    videoUrl: PropTypes.string.isRequired,
};

const Video = withStyles(styles)(VideoPreview);

const VideoPreviewModal = (props) => {
    const { classes, modalState, videoUrl } = props;
    const handleClose = (e) => {
        props.handleClose(null,null,null,null,null,null,modalState);
        e.stopPropagation();
    };
    return (
        <Dialog
            disableRestoreFocus
            open={modalState}
            onClose={handleClose}
            className={classes.dialogRoot}
            classes={{ paper: classes.dialogContainer }}
            aria-labelledby="simple-dialog-title"
        >
            <DialogActions>
                <DialogTitle className={classes.logtitle}>Test Execution Video</DialogTitle>
                <IconButton variant="fab" onClick={handleClose} color="secondary" aria-label="Close">
                    <Close aria-label="closeIcon" id="closeIcon" />
                </IconButton>
            </DialogActions>
            <Video videoUrl={videoUrl} />
        </Dialog>
    );
};

VideoPreviewModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    modalState: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    videoUrl: PropTypes.string.isRequired,
};

export default withStyles(styles)(VideoPreviewModal);

export { Video };
