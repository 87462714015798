import HttpBase from '../../services/HttpBase';

class ProjectsMiddleware extends HttpBase {
    async getDisabledProjects() {
        try {
            const result = await this.httpClient.get('/v1/get_disable_projects');
            if (result.status === 200 && result.data) {
                return result.data;
            }
            throw new Error('Could not get disabled projects');
        } catch (error) {
            throw new Error('Could not get disabled projects');
        }
    }

    async enableProject(projectId) {
        try {
            const result = await this.httpClient.post('/v1/enable_project', JSON.stringify({ project_ids: [projectId] }));
            if (result.status === 200 && result.data) {
                return result.data;
            }
            throw new Error(false);
        } catch (error) {
            throw new Error('Failed to enable project');
        }
    }
}

export default new ProjectsMiddleware();
