import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, FormControl, TextField, Select, Input, MenuItem, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { timezone, language } from '../../common/timezoneAndLanguages';
import { USER_NAME } from '../../common/constants';

const styles = (theme) => ({
    input: {
        '&>div>input': {
            padding: '2px 0px 7px',
            width: '50%',
            fontSize: '12px',
        },
    },
    noBoxShadow: {
        backgroundColor: 'transparent',
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)',
    },
    expensionContent: {
        margin: '0 !important',
        cursor: 'default',
    },
    expensionsummary: {
        height: 'auto !important',
        minHeight: 'auto !important',
        paddingLeft: '0px',
    },
    expensionDetail: {
        padding: 0,
    },
    radio: {
        height: 'auto !important',
        minHeight: 'auto !important',
        // marginBottom: '20px'
    },
    formControl: {
        paddingBottom: '10px',
    },
    inputLanguage: {
        width: '100%',
        borderRadius: '3px',
        '&>div>div': {
            fontSize: '12px',
        },
    },
    margin: {},
    button: {
        borderRadius: '3px',
        // minHeight: 0,
        minWidth: '75px',
        padding: '2px 8px',
        textTransform: 'capitalize',

        height: '34px',
        width: '69px',
        border: '1px solid #3B91DF',
        backgroundColor: '#3B91DF',
    },
    buttonContainer: {
        padding: '10px 0px 0px 0px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonSave: {
        background: '#0092e6',
        color: '#fff',
        width: '100px',
        height: '50px',
    },
    buttonCancel: {
        background: 'white',
        color: '#000',
        width: '100px',
        height: '50px',
        marginRight: '10px',
    },
    heading: {
        paddingLeft: '20px',
        fontWeight: 'bold',
        color: '#494949',
        fontSize: '12px',
    },
    sidebarItem: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
    },
    paddingOnXS: {
        padding: 0,
        [theme.breakpoints.only('xs')]: {
            padding: 10,
        },
    },
});

class AdminProfile extends React.Component {
    constructor() {
        super();
        this.state = {
            expanded: null,
            // newFeature: 'Rapid Release',
            isDisabled: false,
        };
    }

    handleExpand = (panel) => {
        this.setState((prevState) => ({
            expanded: prevState.expanded === panel ? false : panel,
        }));
    };

    handleSelectionChange = (event) => {
        this.props.actions.handleSelectionChange(event.target);
    };

    handleRadioButtons = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSave = () => {
        this.props.actions
            .postUserProfileInfo(this.props.userDetails, this.props.profileImage)
            .then(() => {
                this.setState({ isDisabled: false });
            })
            .catch(() => {
                this.setState({ isDisabled: false });
            });
    };

    render() {
        const { classes, userDetails, user } = this.props;
        const { expanded, isDisabled } = this.state;
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={false} sm={1} md={2} lg={2} />
                <Grid item xs={12} sm={10} md={7} lg={7}>
                    <div className={classes.paddingOnXS}>
                        <div className={classes.sidebarItem} style={{ borderBottom: '1px solid #c5ced8', paddingTop: 20 }}>
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    Name
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} style={{ paddingLeft: 6 }}>
                                <TextField
                                    disabled={true}
                                    fullWidth
                                    InputProps={{ disableUnderline: true, autoComplete: 'off' }}
                                    className={classes.input}
                                    value={userDetails && userDetails.name ? userDetails.name : ''}
                                    placeholder="Name"
                                    aria-label="User Details Name"
                                    id="userDetailsName"
                                    onChange={this.handleSelectionChange}
                                    name="name"
                                />
                            </Grid>
                        </div>
                        <div className={classes.sidebarItem} style={{ borderBottom: '1px solid #c5ced8', paddingTop: 20 }}>
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    Company Name
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} style={{ paddingLeft: 6 }}>
                                <TextField
                                    disabled={true}
                                    fullWidth
                                    InputProps={{ disableUnderline: true, autoComplete: 'off' }}
                                    className={classes.input}
                                    value={userDetails?.accountName || ''}
                                    placeholder="Company Name"
                                    aria-label="Admin Company Name"
                                    id="adminCompnayName"
                                    //onChange={this.handleSelectionChange}
                                    name={'accountName'}
                                />
                            </Grid>
                        </div>
                        <div
                            className={classes.sidebarItem}
                            style={{
                                borderBottom: expanded === 'ContactInfo' ? 'none' : '1px solid #c5ced8',
                                backgroundColor: expanded === 'ContactInfo' ? 'white' : '',
                                paddingTop: 20,
                            }}
                        >
                            <Grid
                                item
                                xs={4}
                                sm={4}
                                md={3}
                                lg={3}
                                style={{ display: 'flex', alignItems: expanded === 'ContactInfo' ? '' : 'flex-end', paddingBottom: '2px' }}
                            >
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    Contact Information
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} style={{ paddingLeft: 6 }}>
                                <TextField
                                    disabled={user.name === USER_NAME}
                                    fullWidth
                                    InputProps={{ disableUnderline: true, autoComplete: 'off' }}
                                    className={classes.input}
                                    value={userDetails.email || ''}
                                    placeholder="Primary Email"
                                    aria-label="User Details Email"
                                    id="userDetailsEmail"
                                    onChange={this.handleSelectionChange}
                                    name="email"
                                />
                            </Grid>
                        </div>
                        <div
                            className={classes.sidebarItem}
                            style={{ borderBottom: expanded === 'Language' ? 'none' : '1px solid #c5ced8', paddingTop: 12 }}
                        >
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    Language
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={9} lg={9} style={{ paddingLeft: 6 }}>
                                <FormControl className={classes.margin} classes={{ root: classes.inputLanguage }}>
                                    <Select
                                        value={userDetails.language || 'US'}
                                        onChange={this.handleSelectionChange}
                                        displayEmpty
                                        inputProps={{
                                            name: 'language',
                                        }}
                                        input={<Input disableUnderline style={{ padding: ' 4px 0px 0px 0px' }} />}
                                    >
                                        {language &&
                                            language.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.value} key={index}>
                                                        {item.label}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </div>
                        <div
                            className={classes.sidebarItem}
                            style={{ borderBottom: expanded === 'Language' ? 'none' : '1px solid #c5ced8', paddingTop: 12 }}
                        >
                            <Grid item xs={4} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '2px' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    Time Zone
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={9} lg={9} style={{ paddingLeft: 6 }}>
                                <FormControl className={classes.margin} classes={{ root: classes.inputLanguage }}>
                                    <Select
                                        value={userDetails && userDetails.timeZone ? userDetails.timeZone : ''}
                                        onChange={this.handleSelectionChange}
                                        displayEmpty
                                        inputProps={{
                                            name: 'timeZone',
                                        }}
                                        input={<Input disableUnderline style={{ padding: ' 4px 0px 0px 0px' }} />}
                                    >
                                        {timezone &&
                                            timezone.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.abbr} key={index}>
                                                        {item.text}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </div>
                        {/* <div style={{ marginLeft: '39px', paddingTop: 20, flexDirection: "row", display: 'flex', borderBottom: expanded === 'NewUser' ? 'none' : '1px solid #c5ced8', backgroundColor: expanded === 'NewUser' ? 'white' : '', }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: expanded === 'NewUser' ? '' : 'flex-end', }}>
                            <Typography variant="subtitle1" gutterBottom className={classes.heading} >
                                New User Features
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                            <Accordion expanded={expanded === 'NewUser'} /* onChange={this.handleExpand('Company Name')} */
                        /* className={classes.noBoxShadow}>
                                {expanded !== 'NewUser' ?
                                    <AccordionSummary expandIcon={null} className={classes.expensionsummary} classes={{ content: classes.expensionContent }}>
                                        <Typography className={classes.heading}>
                                            {this.state.newFeature}
                                        </Typography>
                                    </AccordionSummary> : " "}
                                <AccordionDetails className={classes.expensionDetail} style={{ borderBottom: '1px solid #c5ced8' }}>
                                    <span style={{ display: 'flex' }}>
                                        <Typography style={{ fontWeight: 600, marginBottom: '10px' }}>
                                            Select how to release new product features to your company
                                        </Typography>

                                    </span>
                                    <span onClick={() => this.handleExpand('NewUser')} style={{ margin: '-3px 15px 0px 0px', padding: 0, position: 'absolute', right: 0, cursor: 'pointer', top: 0, }}>
                                        {expanded !== 'NewUser' ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                                    </span>

                                </AccordionDetails>
                                <RadioGroup
                                    className={classes.radioGroup}
                                    value={this.state.newFeature}
                                    onChange={this.handleRadioButtons}
                                    style={{ paddingBottom: '23px', paddingTop: '15px' }}
                                    name={'newFeature'}
                                >
                                    <FormControlLabel value="Rapid Release" className={classes.formControl} control={<Radio className={classes.radio} />} label="Rapid Release" />
                                    <FormControlLabel value="Schedule Release" control={<Radio className={classes.radio} />} label="Schedule Release" />
                                </RadioGroup>
                            </Accordion>
                        </Grid>
                    </div> */}
                        <div className={classes.buttonContainer}>
                            {/* <Button
                            classes={{ outlined: classes.cancleButton }}
                            color="secondary"
                            onClick={() => toggleModal()}
                            variant="outlined"
                            className={classes.button}
                        >
                            Cancel
                            </Button> */}
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                disabled={isDisabled}
                                onClick={() => {
                                    this.setState({ isDisabled: true });
                                    this.handleSave();
                                }}
                            >
                                Save
                            </Button>
                            {/* <Button variant="contained" color='primary' className={classes.buttonCancel}>
                            Cancel
                        </Button> */}
                            {/* <Button variant="contained" color='primary' className={classes.buttonSave} onClick={this.handleSave}  >
                            Save
                        </Button> */}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={false} sm={1} md={3} lg={3} />
            </div>
        );
    }
}

AdminProfile.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    userDetails: PropTypes.shape({
        name: PropTypes.string,
        language: PropTypes.string,
        timeZone: PropTypes.string,
        email: PropTypes.string,
        accountName: PropTypes.string,
    }).isRequired,
};

AdminProfile.defaultProps = {
    userDetails: {
        name: '',
        language: 'US',
        timeZone: 'PDT',
        email: '',
        accountName: '',
    },
};

function mapStateToProps(state) {
    return {
        user: state.authReducer.user,
    };
}

export default connect(mapStateToProps, null)(withStyles(styles)(AdminProfile));
