import { createStyles, withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Download from 'mdi-material-ui/Download';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/AddCircle';

import Select from 'react-select';
import moment from 'moment';
import AccountTab from '../../settings/AccountTab';
import { AccountActions, ModalActions, GeneralActions, UserActions, generalModalActions } from '../../../store/actions';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON } from '../../../components/Button/Btn';
import Tooltip from '../../../components/Tooltip';
import { checkKeyInObject, checkArrayLength, arrayAwait } from '../../../utils';
import { ActionTypes } from '../../../store/constants';
import { USER_NAME } from '../../../common/constants';
import { PASSWORD_REGEX } from '../../../common/regexConstants';
import AdminAccountInfo from '../modal/AdminAccountInfo';
import AccountUser from '../modal/AccountUser';
import GroupList from '../groups/GroupList';
import UserList from '../users/UserList';
import AssociationModal from '../modal/AssociationModal';

import { isEmail } from '../../../common/regexConstants';

const items = [{ value: 'SDET' }, { value: 'ADMIN' }];

// Theme-dependent styles
const styles = (theme) =>
    createStyles({
        dialogContentStyle: {
            maxWidth: '1270px !important',
        },
        toolbar: theme.mixins.toolbar,
        headerContainer: {
            display: 'flex',
            placeContent: 'center',
            margin: '0px 20px',
        },
        upoadIcon: {
            fontSize: 18,
        },
        filterContainer: {
            height: '50px',
            marginTop: '10px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
            borderRadius: '5px',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
            paddingLeft: '15px',
        },
        leftContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        select: {
            fontSize: '12px',
        },
        pipe: {
            borderLeft: '1px solid #cdcdcd',
            height: '35px',
            width: '1px',
            margin: '0px 20px 0px 20px',
        },
        outline: {
            border: '1px solid  #1067cd',
        },
        buttonRoot: {
            fontSize: 12,
            padding: '4px 10px',
        },
        actionButtonsWrapper: {
            width: 200,
            display: 'flex',
            justifyContent: 'flex-end',
            paddingLeft: 15,
            paddingRight: 15,
            height: '45px',
            alignItems: 'center',
        },
        addIcon: {
            color: '#1BABAB',
            marginRight: '15px',
            cursor: 'pointer',
            fontSize: 28,
        },
        noDataText: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '88px',
        },
        circularProgressIcon: {
            position: 'absolute',
            color: '#4885ed',
        },
    });

let submitButtonDisabled = false;

const optionsScreen = [
    { value: 'accountsScreen', label: 'Accounts' },
    // { value: 'groupsScreen', label: 'Groups' },
    { value: 'userScreen', label: 'Users' },
];

const screenPathToValue = {
    '/admin/accounts': optionsScreen[0],
    '/admin/users': optionsScreen[1], // [2]
    // '/admin/groups': optionsScreen[1],
};

const screenValueToPath = {
    accountsScreen: '/admin/accounts',
    // 'groupsScreen': '/admin/groups',
    userScreen: '/admin/users',
};

class AccountList extends React.Component {
    constructor(props) {
        super(props);
        props.updateTitle('Accounts');
        this.state = {
            selectedScreen: optionsScreen[0],
        };
        this.onCreateUser = () => {};
        this.onDownloadUser = () => {};
    }

    componentDidMount() {
        const {
            getAccounts,
            /* userGroups, */ getGroups,
            activeUser: { accountId },
            location,
        } = this.props;
        this.fetchUsers();
        getAccounts(accountId);
        if (accountId) {
            getGroups(accountId);
        }
        this.handleChangeScreen(screenPathToValue[location.pathname]);
    }

    componentWillUnmount() {
        this.props.clearSnackBar();
    }

    getLoadingComponent = () => {
        const { classes } = this.props;
        return (
            <div className={classes.noDataText}>
                <CircularProgress className={classes.circularProgressIcon} size={50} thickness={3} />
            </div>
        );
    };

    fetchUsers = () => {
        const {
            activeUser: { accountId, name },
        } = this.props;
        if (`${accountId}` === '1' && name === USER_NAME) {
            this.props.getUsers();
        } else {
            this.props.getUserByAccountId(accountId);
        }
    };

    createAccount = async () => {
        const {
            updateTabData,
            getAccounts,
            /* tabsData, */ activeUser: { accountId },
            tabsData: {
                adminAccountInfo: {
                    state: { name, description, selectedDateStart, selectedDateEnd, isAccountActive },
                },
            },
            toggleSnackBar,
        } = this.props;
        const users = [];
        if (checkKeyInObject(this.props.tabsData, 'accountUser.state', 'bool', false)) {
            const {
                tabsData: {
                    accountUser: {
                        state: { addRowData },
                    },
                },
            } = this.props;
            if (checkArrayLength(addRowData)) {
                addRowData.forEach((_user, i) => {
                        users.push({
                            email: _user.email,
                            userName: _user.userName,
                            password: _user.password,
                            isUserActive: _user.isUserActive,
                            role: _user.selectedItem,
                            startDate: `${moment(_user.userDateStart).format('YYYY-MM-DD')}T00:00:00Z`,
                            endDate: `${moment(_user.userDateEnd).format('YYYY-MM-DD')}T00:00:00Z`,
                        });
                    }
                )}
        }

        const accObj = {
            accountName: name,
            description,
            accountStartDate: `${moment(selectedDateStart).format('YYYY-MM-DD')}T00:00:00Z`,
            accountEndDate: `${moment(selectedDateEnd).format('YYYY-MM-DD')}T00:00:00Z`,
            disabledStatus: !isAccountActive,
            isRelativeDateFormat: true,
            address: '',
            time_zone: '',
            language: '',
        };
        let userObj = {};
        if (checkArrayLength(users)) {
            userObj = {
                userName: users[0].userName,
                email: users[0].email,
                password: users[0].password,
                role: users[0].role,
                startDate: users[0].startDate,
                endDate: users[0].endDate,
                isUserInactive: !checkKeyInObject(users[0], 'isUserActive', 'value', true),
            };
        }
        const finalObj = { ...accObj, ...userObj };
        const isCreated = await this.props.createAccount({ ...finalObj });
        if (isCreated.type !== ActionTypes.SHOW_ERROR) {
            if (checkArrayLength(users) && checkKeyInObject(isCreated, 'payload._data.data', 'bool', false)) {
                const _accountId = isCreated.payload._data.data;
                // Removing 1st element of an array to prevent same user which is already created by "accountanduser" endpoint.
                const _users = [...users];
                _users.shift();
                arrayAwait(
                    _users,
                    async (user) => {
                        await this.props.createUser({
                            accountId: `${_accountId}`,
                            password: user.password,
                            name: user.userName,
                            email: user.email,
                            role: user.role,
                            startDate: user.startDate,
                            endDate: user.endDate,
                            isUserInactive: !checkKeyInObject(user, 'isUserActive', 'value', true),
                        });
                    },
                    () => {
                        this.fetchUsers();
                        getAccounts(_accountId);
                    },
                );
            } else {
                this.fetchUsers();
                getAccounts(accountId);
            }
        } else {
            // console.log('API Call Failed');
            toggleSnackBar('Failed to add account');
        }
        this.closeModal();
        updateTabData('isDisabledSubmitButton', false);
    };

    // updateUser = async (data) => { // for single user update
    //     if (checkKeyInObject(data, 'tabsData.UserModal.state.selectedItem') && checkKeyInObject(data, 'user')) {
    //         const { user } = data;
    //         const isUpdated = await this.props.updateUser({ userId: user.id, role: data.tabsData.UserModal.state.selectedItem, email: user.email, name: user.name });
    //         if (isUpdated) {
    //             this.fetchUsers();
    //         }
    //     }
    //     this.closeModal();
    // };

    closeModal = () => {
        this.props.toggleModal();
        submitButtonDisabled = false;
        setTimeout(() => {
            this.props.clearGeneralModal();
        }, 500);
    };

    handleChangeScreen = (selectedScreen) => {
        this.setState({ selectedScreen });
    };

    openDownload = () => {
        const { selectedScreen } = this.state;
        /* if (selectedScreen.label === 'Accounts') {
            // For account download
        } else if (selectedScreen.label === 'Groups') {
            // For group download
        } else  */ if (
            selectedScreen.label === 'Users'
        ) {
            this.onDownloadUser();
        }
    };

    openCreate = () => {
        const { selectedScreen } = this.state;
        // checkKeyInObject(this.props.userGroups, 'Groups')
        if (selectedScreen.label === 'Accounts') {
            this.openCreateAccountModal();
        } else if (selectedScreen.label === 'Groups') {
            this.openCreateGroupModal();
        } else if (selectedScreen.label === 'Users') {
            this.onCreateUser();
        }
    };

    isAccountSubmitDisable = (tabsData) => {
        return (
            Boolean(
                checkKeyInObject(tabsData, 'adminAccountInfo.state.isDuplicateName', 'value', false) ||
                    !checkKeyInObject(tabsData, 'adminAccountInfo.state.name', 'value', false),
            ) ||
            !(
                (checkKeyInObject(tabsData, 'accountUser.state.addRowData', 'value', []).length === 1 && this.isRowValid(tabsData, 0)) ||
                (checkKeyInObject(tabsData, 'accountUser.state.addRowData', 'value', []).length > 1 && this.isAllRowsValid(tabsData))
            ) ||
            !checkKeyInObject(tabsData, 'adminAccountInfo.state.selectedDateStart') ||
            !checkKeyInObject(tabsData, 'adminAccountInfo.state.selectedDateEnd') ||
            new Date(tabsData.adminAccountInfo.state.selectedDateStart) >= new Date(tabsData.adminAccountInfo.state.selectedDateEnd)
        );
    };

    isRowValid = (tabsData, i) => {
        const userRows = [...checkKeyInObject(tabsData, 'accountUser.state.addRowData', 'value', [])];
        const isPastedDateSame = tabsData.accountUser.state.isPastedDateSame;
        if (userRows && userRows[i]) {
            return Boolean(
                userRows[i].userName.trim() &&
                    (i !== 0 || checkKeyInObject(tabsData, 'accountUser.state.isUsernameValid', 'value', false)) &&
                    checkKeyInObject(tabsData, 'accountUser.state.isEmailDisabled', 'value', false) &&
                    userRows[i].email.trim() &&
                    isEmail.test(String(userRows[i].email).toLowerCase()) &&
                    userRows[i].password &&
                    PASSWORD_REGEX.test(userRows[i].password) &&
                    userRows[i].selectedItem &&
                    !isPastedDateSame &&
                    new Date(userRows[i].userDateStart) < new Date(userRows[i].userDateEnd),
            );
        }
        return false;
    };

    isAllRowsValid = (tabsData) => {
        const userRows = [...checkKeyInObject(tabsData, 'accountUser.state.addRowData', 'value', [])];
        for (let i = 0; i < userRows.length; i++) {
            if (!this.isRowValid(tabsData, i)) {
                return false;
            }
        }
        return true;
    };

    openCreateAccountModal = () => {
        const { classes, changeTab, currentTab } = this.props;
        const data = [
            {
                heading: 'Account Info',
                modal: <AdminAccountInfo isUpdate={false} />,
                nextAction: () => {
                    changeTab(currentTab + 1);
                },
                nextDisable: () => {
                    return false;
                },
                cancelAction: this.closeModal,
                // __ Save Without Going Through All Tabs __\\
                // submitAction: () => {
                //     if (!submitButtonDisabled) {
                //         this.createAccount();
                //     }
                //     submitButtonDisabled = true;
                // },
                submitDisable: this.isAccountSubmitDisable,
            },
            {
                heading: 'Users',
                modal: <AccountUser isUpdate={false} items={items} />,
                cancelAction: this.closeModal,
                submitAction: () => {
                    //  this.props.updateTabData('isDisabledSubmitButton', true);
                    if (!submitButtonDisabled) {
                        this.createAccount();
                    }
                    submitButtonDisabled = true;
                },
                submitDisable: this.isAccountSubmitDisable,
            },
        ];
        const title = 'New Account';
        const onClose = () => {
            this.closeModal();
        };
        this.openModal(title, classes, onClose, data);
    };

    isGroupSubmitDisable = (tabsData) => {
        return (
            Boolean(
                checkKeyInObject(tabsData, 'adminAccountInfo.state.isDuplicateName', 'value', false) ||
                    !checkKeyInObject(tabsData, 'adminAccountInfo.state.name', 'value', false),
            ) ||
            !checkKeyInObject(tabsData, 'adminAccountInfo.state.selectedDateStart') ||
            !checkKeyInObject(tabsData, 'adminAccountInfo.state.selectedDateEnd') ||
            new Date(tabsData.adminAccountInfo.state.selectedDateStart) >= new Date(tabsData.adminAccountInfo.state.selectedDateEnd)
        );
    };

    openCreateGroupModal = () => {
        const { classes, changeTab, currentTab } = this.props;
        const data = [
            {
                heading: 'Group Info',
                modal: <AdminAccountInfo isUpdate={false} isForAccount={false} />,
                nextAction: () => {
                    changeTab(currentTab + 1);
                },
                nextDisable: () => {
                    return false;
                },
                cancelAction: this.closeModal,
                submitAction: () => {
                    this.createGroup();
                },
                submitDisable: this.isGroupSubmitDisable,
            },
            {
                heading: 'Users',
                modal: (
                    <AssociationModal
                    // for Update start
                    // isUpdate={true}
                    // selectedUsersIds={selectedUsersIds}
                    // for Update end
                    />
                ),
                cancelAction: this.closeModal,
                submitAction: () => {
                    this.createGroup();
                },
                submitDisable: this.isGroupSubmitDisable,
            },
        ];
        const title = 'New Group';
        const onClose = () => {
            this.closeModal();
        };
        this.openModal(title, classes, onClose, data);
    };

    openModal = (title, classes, onClose, data) => {
        this.props.toggleModal('GeneralModal', null, null, {
            title,
            dialogContentStyle: classes.dialogContentStyle,
            closeIconAction: onClose,
            component: [
                {
                    name: data[0].heading,
                    content: data[0].modal,
                    buttons: [
                        NEXT_SUBMIT_BUTTON({
                            name: 'Next',
                            action: data[0].nextAction,
                            isDisabled: data[0].nextDisable,
                        }),
                        CANCEL_BUTTON({ action: data[0].cancelAction }),
                        // __ Save Without Going Through All Tabs __\\
                        // SAVE_OUTLINE_BUTTON({
                        //     action: data[0].submitAction,
                        //     isDisabled: data[0].submitDisable
                        // })
                    ],
                },
                {
                    name: data[1].heading,
                    content: data[1].modal,
                    buttons: [
                        NEXT_SUBMIT_BUTTON({
                            name: 'Create',
                            action: data[1].submitAction,
                            isDisabled: data[1].submitDisable,
                        }),
                        CANCEL_BUTTON({ action: data[1].cancelAction }),
                    ],
                },
            ],
        });
    };

    createGroup = async () => {
        const {
            tabsData,
            activeUser: { accountId } /* , getGroups */,
        } = this.props;
        if (checkKeyInObject(tabsData, 'adminAccountInfo.state.name', 'value', false)) {
            const { activeUser, getGroups, createGroupSubmit /* , associateUsersWithGroup */ } = this.props;
            let selectedUserIds = [];
            if (checkKeyInObject(tabsData, 'associateUsersToGroup.state.selectedUserIds', 'bool', false)) {
                selectedUserIds = [...tabsData.associateUsersToGroup.state.selectedUserIds];
            }
            const result = await createGroupSubmit({
                name: tabsData.adminAccountInfo.state.name,
                description: tabsData.adminAccountInfo.state.description,
                start_date: `${moment(tabsData.adminAccountInfo.state.selectedDateStart).format('YYYY-MM-DD')}T00:00:00Z`,
                end_date: `${moment(tabsData.adminAccountInfo.state.selectedDateEnd).format('YYYY-MM-DD')}T00:00:00Z`,
                account_id: activeUser.accountId,
                user_ids: [...selectedUserIds],
            });
            if (checkKeyInObject(result, 'type', 'value', false) === ActionTypes.CREATE_GROUP_SUCCESS && accountId) {
                getGroups(accountId);
            }
            this.closeModal();
        }
    };

    render() {
        const {
            accounts,
            activeUser,
            classes,
            query,
            queryRemove,
            sideBarCount,
            toggleModal,
            isLoadingAccounts,
            isLoadingGroups,
            isLoadingUsers,
            users,
        } = this.props;
        const { selectedScreen } = this.state;
        const APP_USER = `${activeUser.accountId}` === '1' && activeUser.name === USER_NAME;
        const ADMIN = activeUser.role === 'ADMIN';
        const adminOptionsScreen = optionsScreen;

        const tabContent =
            selectedScreen.label === 'Accounts' && APP_USER ? (
                <AccountTab
                    toggleModal={toggleModal}
                    accounts={accounts || []}
                    users={users || []}
                    activeUser={activeUser || {}}
                    query={query}
                    queryRemove={queryRemove}
                    sideBarCount={sideBarCount}
                    closeModal={this.closeModal}
                    items={items}
                    isLoading={isLoadingAccounts}
                    // updateUser={this.updateUser} // for single user update
                    fetchUsers={this.fetchUsers}
                    loadingComponent={this.getLoadingComponent()}
                />
            ) : selectedScreen.label === 'Groups' && (APP_USER || ADMIN) ? (
                <GroupList
                    toggleModal={toggleModal}
                    accounts={accounts || []}
                    users={users || []}
                    activeUser={activeUser || {}}
                    query={query}
                    queryRemove={queryRemove}
                    sideBarCount={sideBarCount}
                    closeModal={this.closeModal}
                    isLoading={isLoadingGroups}
                    loadingComponent={this.getLoadingComponent()}
                />
            ) : selectedScreen.label === 'Users' && (APP_USER || ADMIN) ? (
                <UserList
                    toggleModal={toggleModal}
                    accounts={accounts || []}
                    users={users || []}
                    activeUser={activeUser || {}}
                    query={query}
                    queryRemove={queryRemove}
                    sideBarCount={sideBarCount}
                    closeModal={this.closeModal}
                    isLoading={isLoadingUsers}
                    onCreateClick={(cb) => {
                        this.onCreateUser = cb;
                    }}
                    onDownloadClick={(cb) => {
                        this.onDownloadUser = cb;
                    }}
                    loadingComponent={this.getLoadingComponent()}
                />
            ) : null;

        return (
            <div>
                <div className={classes.headerContainer}>
                    <div className={classes.filterContainer}>
                        <div className={classes.leftContainer}>
                            {APP_USER ? (
                                <>
                                    <div style={{ width: 200 }}>
                                        <Select
                                            name="screen"
                                            classNamePrefix="select"
                                            value={selectedScreen}
                                            onChange={(item) => {
                                                this.props.history.push(screenValueToPath[item.value]);
                                            }}
                                            options={adminOptionsScreen}
                                            styles={{
                                                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                            }}
                                            // defaultValue={optionsFormatDate[0].label}
                                            closeMenuOnSelect
                                            className={classes.select}
                                            isSearchable={false}
                                        />
                                    </div>
                                    <span className={classes.pipe} />
                                </>
                            ) : null}
                        </div>
                        <div className={classes.actionButtonsWrapper}>
                            {
                                <Tooltip
                                    data={
                                        selectedScreen.label === 'Accounts'
                                            ? 'Add Account'
                                            : selectedScreen.label === 'Groups'
                                            ? 'Add Group'
                                            : selectedScreen.label === 'Users'
                                            ? 'Add User'
                                            : null
                                    }
                                >
                                    <AddIcon onClick={this.openCreate} className={classes.addIcon} aria-label="addIcon" id="addIcon" />
                                </Tooltip>
                            }
                            {selectedScreen.label === 'Users' ? (
                                <Tooltip data="Download Users">
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        //    aria-owns={anchorEl ? 'simple-menu' : null}
                                        aria-haspopup="true"
                                        // onClick={this.handleOpenMenu}
                                        onClick={this.openDownload}
                                        classes={{ outlined: classes.outline, root: classes.buttonRoot }}
                                    >
                                        <Download className={classes.upoadIcon} />
                                        Download
                                    </Button>
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div>{tabContent}</div>
            </div>
        );
    }
}

AccountList.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    toggleModal: PropTypes.func.isRequired,
    queryRemove: PropTypes.func.isRequired,
    query: PropTypes.string,
};

AccountList.defaultProps = {
    query: '',
};

const mapStateToProps = (state) => {
    return {
        accounts: state.accountReducer.accounts,
        sideBarCount: state.sidebarReducer.sideBarCount,
        users: state.userReducer.users,
        userGroups: state.userReducer.userGroups,
        activeUser: state.authReducer.user,
        // general reducer
        query: state.generalReducer.queryValue,
        currentTab: state.generalModalReducer.currentTab,
        tabsData: state.generalModalReducer.tabsData,
        isLoadingAccounts: state.accountReducer.isLoading,
        isLoadingGroups: state.userReducer.isLoading,
        isLoadingUsers: state.userReducer.isLoadingUsers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAccounts: (accountId) => dispatch(AccountActions.getAccounts(accountId)),
        getUsers: () => dispatch(UserActions.getUsers()),
        getGroups: (accountId) => dispatch(UserActions.getGroups(accountId)),
        createGroupSubmit: (...args) => dispatch(UserActions.createGroup(...args)),
        getUserByAccountId: (accountId) => dispatch(UserActions.getUserByAccountId(accountId)),
        associateUsersWithGroup: (data) => dispatch(UserActions.associateUsersWithGroup(data)),
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        toggleSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        clearSnackBar: () => dispatch(ModalActions.clearSnackbar()),
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
        clearGeneralModal: (...args) => dispatch(generalModalActions.clearGeneralModal(...args)),
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
        createAccount: (data) => dispatch(UserActions.createAccount(data)),
        // updateUser: (id, role) => dispatch(UserActions.updateUser(id, role)), // for single user update
        createUser: (data) => dispatch(UserActions.createUser(data)),
        changeTab: (...args) => dispatch(generalModalActions.changeTab(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountList));
