import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Chip } from '@material-ui/core';

const styles = (theme) => ({
    font: {
        fontSize: 12,
        color: '#595959',
    },
    chipRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5),
        minHeight: 50,
        marginBottom: 7,
    },
    labelColor: {
        '&>label': {
            color: '#595959',
            fontSize: '12px',
            transform: 'translate(0, 1.5px)',
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    chipClickDisable: {
        margin: theme.spacing(0.5),
        cursor: 'not-allowed',
        backgroundColor: '#f2e8e8 !important',
        color: '#d18a8a !important',
    },
    chipClick: {
        margin: theme.spacing(0.5),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#c7c5c5',
        },
    },
    timeSpanContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        width: 'calc(100% - 240px)',
    },
    col1: {
        marginLeft: 27,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        width: 'calc(100% - 327px)',
    },
    timeSpanLabel: {
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.95)',
        position: 'absolute',
        top: 0,
        fontWeight: 500,
        paddingTop: 9,
    },
    timeSpanLabel1: {
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.54)',
        position: 'absolute',
        paddingBottom: 36,
    },
    requiredSpan: {
        color: '#D0021B',
        marginLeft: 10,
    },
    chirRoot1: {
        justifyContent: 'space-between',
        padding: '0 30px',
        alignItems: 'center',
    },
    rootDialogContent: {
        width: '100%',
        overflow: 'auto',
    },
    rootDialogContentSpace: {
        marginTop: '10px',
    },
    timeText: {
        margin: 0,
        width: '225px',
    },
    chipRoot1: {
        marginLeft: 27,
    },
    contaiberChipRoot: {
        justifyContent: 'start',
        padding: '0 30px',
    },
    timeSpanChips: {
        marginRight: 26,
    },
});

const SchedulingTime = ({ handleTimeChange, schedulingTimeValue, schedulingData, handleTimeSelect, show, handleDeleteFromChip, classes, tab }) => {
    return (
        <div>
            <div
                className={
                    tab === 'suite'
                        ? `${classes.chipRoot} ${classes.chirRoot1}`
                        : ` ${classes.chipRoot} ${classes.rootDialogContent} ${classes.rootDialogContentSpace}`
                }
            >
                <div className={tab === 'suite' ? classes.timeSpanContainer : `${classes.col1}`}>
                    <span className={tab === 'suite' ? classes.timeSpanLabel : classes.timeSpanLabel1}>
                        Time span<span className={classes.requiredSpan}>*Mandatory</span>
                    </span>
                    <TextField
                        margin="normal"
                        onChange={(e) => {
                            handleTimeChange(e);
                        }}
                        InputLabelProps={{ shrink: true }}
                        className={tab === 'suite' ? `${classes.labelColor}` : `${classes.timeText} ${classes.labelColor}`}
                        InputProps={{
                            classes: {
                                input: classes.font,
                            },
                            value: schedulingTimeValue,
                            min: '1',
                            step: '1',
                        }}
                        aria-label="Time Span"
                        id="schedulingTimeValue"
                    />
                </div>
                <div className={classes.timeSpanChips}>
                    {schedulingData.map((data) => {
                        const icon = null;
                        const isDisable =
                            schedulingTimeValue === '' ||
                            Number(schedulingTimeValue) < data.minVal ||
                            (Number(schedulingTimeValue) > data.maxVal && data.maxVal !== -1);
                        return (
                            <Chip
                                key={data.key}
                                icon={icon}
                                label={data.label}
                                className={!isDisable ? classes.chipClick : classes.chipClickDisable}
                                onClick={
                                    !isDisable
                                        ? () => {
                                              handleTimeSelect(data);
                                          }
                                        : () => {}
                                }
                            />
                        );
                    })}
                </div>
            </div>
            {show && (
                <div className={tab === 'suite' ? `${classes.chipRoot} ${classes.contaiberChipRoot}` : `${classes.chipRoot} ${classes.chipRoot1}`}>
                    {schedulingData &&
                        schedulingData.map((data) => {
                            const icon = null;
                            if (`${data.value}` === '0') return null;
                            return (
                                <Chip
                                    key={data.key}
                                    icon={icon}
                                    label={`${data.value} ${data.label}`}
                                    onDelete={handleDeleteFromChip(data)}
                                />
                            );
                        })}
                </div>
            )}
        </div>
    );
};

export default withStyles(styles)(SchedulingTime);
