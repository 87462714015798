// material-ui component
import { withStyles } from '@material-ui/core/styles';
import { TableSortLabel, Typography } from '@material-ui/core';

// lib import
import PropTypes from 'prop-types';
import React from 'react';
// import icons
import { faChevronDown, faChevronUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '../Table/TableRow';
import TableCell from '../Table/TableCell';
import TableHead from '../Table/TableHead';
import { TABLES_CSS } from '../../common/cssConstants';
import { resizerX } from '../../utils/utils';

// constants and variables
const styles = (theme) => ({
    actionStyle: {},
    cell: {
        flexDirection: 'row',
        fontSize: TABLES_CSS.headingFontSize,
        fontWeight: TABLES_CSS.headingFontWeight,
        color: TABLES_CSS.headingFontColor,
        textAlign: 'left',
    },
    checkboxSpacing: {
        padding: '0px 0px 0px 20px',
        width: '8%',
    },
    lockCell: {
        width: 48,
    },
    nonSortableContainer: {
        cursor: 'default',
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'inherit',
        justifyContent: 'flex-start',
    },
    tableHeadContainer: {
        backgroundColor: '#ffffff',
        color: '#000',
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
        },
    },
    extraPadding: {
        paddingLeft: '10px !important',
    },
    extraPaddingTwo: {
        paddingLeft: '20px !important',
    },
    sortIconAwaysVisible: {
        fontSize: 10,
        marginLeft: 10,
        color: '#9B9B9B',
        display: 'none',
    },
    sortIcon: {
        fontSize: 10,
        marginLeft: 10,
        color: '#9B9B9B',
        display: 'inline-block !important',
    },
    label: {
        '&:hover': {
            '& > svg': {
                display: 'inline-block !important',
            },
        },
    },
    resizeableDiv: {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        zIndex: 2,
        display: 'none',
    },
});

class EnhancedTableHeadBase extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isRightPanelOpened, isSideBarOpened } = this.props;
        if (isRightPanelOpened !== nextProps.isRightPanelOpened || isSideBarOpened !== nextProps.isSideBarOpened) {
            this.disableResize(true); // sent true for hard reset invoke function only under ths condition
        }
    }

    componentDidUpdate() {
        const { headers, resizeable, colsWidth, elementPrefix } = this.props;
        if (resizeable) {
            headers.forEach((data, index) => {
                if (index !== headers.length - 1) {
                    resizerX(`r${index}`, `element${index}`, `element${index + 1}`, colsWidth, elementPrefix);
                }
            });
        }
    }

    createSortHandler = (property) => () => {
        this.props.onRequestSort(property);
    };

    render() {
        const {
            classes,
            headers,
            order,
            orderBy,
            showSelectBtn,
            rowCount,
            numSelected,
            inActiveCount,
            isColSpan,
            onSelectAllClick,
            resizeable,
            selectbuttonIndex,
            calledFrom,
            checkBoxClass,
            style,
            updateRowHeight,
            updateBorder,
            elementPrefix,
            tableRowStyle,
        } = this.props;
        const _style = style || {};
        return (
            <TableHead dataTypePrefix={`${elementPrefix}`} style={{ ..._style }} calledFrom={calledFrom}>
                {resizeable && <div id={`${elementPrefix}mask`} className={classes.resizeableDiv}></div>}
                <TableRow
                    className={`${updateRowHeight || ''} ${updateBorder || ''}`}
                    style={{
                        height: calledFrom === 'suiteReport' ? '51px' : '40px',
                        backgroundColor: calledFrom === 'suiteReport' || calledFrom === 'projectsTable' ? '#F9F9F9' : '#fff',
                        ...tableRowStyle,
                    }}
                >
                    {headers.map(
                        (column, index) =>
                            (column.status && column.status === 'InActive') || (column.checked && column.checked === true) ? null : (
                                <TableCell
                                    dataTypePrefix={`${elementPrefix}`}
                                    colSpan={
                                        isColSpan ? (column.colSpan ? column.colSpan : 1) + (inActiveCount && index === 0 ? inActiveCount : 0) : 1
                                    }
                                    key={`${column.id}-${column.label}`}
                                    className={[
                                        classes.borders,
                                        classes.minWidth,
                                        calledFrom === "postAction" ? '' : classes.extraPadding,
                                        column.id === 'Actions' ? classes.actionStyle : '',
                                        updateRowHeight || '',
                                    ].join(' ')}
                                    width={column.width}
                                    style={{
                                        height: calledFrom === 'suiteReport' ? '51px' : '40px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        position: 'relative',
                                    }}
                                >
                                    <div
                                        id={`${elementPrefix}element${index}`}
                                        style={{
                                            display: 'flex',
                                            position: 'relative',
                                            width: calledFrom === 'suiteReport' ? '90%' : '100%',
                                            height: '100%',
                                            alignItems: 'center',
                                            overflow: 'hidden',
                                            whiteSpace: calledFrom === 'suiteReport' ? 'normal' : 'nowrap',
                                        }}
                                    >
                                        <span style={column.styles ? { ...column.styles, display: 'flex' } : { display: 'flex' }}>
                                            {index === selectbuttonIndex && showSelectBtn ? (
                                                <Checkbox
                                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                                    className={checkBoxClass || ''}
                                                    checked={numSelected === (rowCount + 1) && numSelected > 0}
                                                    onChange={(e) => {
                                                        onSelectAllClick(e);
                                                    }}
                                                    style={{
                                                        height: '40px',
                                                        width: '20px',
                                                        marginRight: '10px',
                                                        color: numSelected === rowCount && rowCount !== 0 ? '#3b91df' : '#bac4ce',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            ) : null}
                                            {column.sortable ? (
                                                <TableSortLabel hideSortIcon onClick={this.createSortHandler(column.id)}>
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.label}
                                                        style={{
                                                            fontSize: TABLES_CSS.headingFontSize,
                                                            color: TABLES_CSS.headingFontColor,
                                                            fontWeight: calledFrom === 'suiteReport' ? 'normal' : TABLES_CSS.headingFontWeight,
                                                        }}
                                                        noWrap
                                                    >
                                                        {column.label}
                                                        {orderBy === column.id ? (
                                                            order === 'desc' ? (
                                                                <FontAwesomeIcon
                                                                    icon={faChevronDown}
                                                                    aria-label="faChevronDown"
                                                                    id="faChevronDown"
                                                                    className={classes.sortIcon}
                                                                />
                                                            ) : (
                                                                <FontAwesomeIcon
                                                                    icon={faChevronUp}
                                                                    aria-label="faChevronUp"
                                                                    id="faChevronUp"
                                                                    className={classes.sortIcon}
                                                                />
                                                            )
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faChevronDown}
                                                                aria-label="faChevronDownhover"
                                                                id="faChevronDownhover"
                                                                className={classes.sortIconAwaysVisible}
                                                            />
                                                        )}
                                                    </Typography>
                                                </TableSortLabel>
                                            ) : (
                                                <div className={classes.nonSortableContainer}>
                                                    {calledFrom === 'suiteReport' ? (
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                fontSize: TABLES_CSS.headingFontSize,
                                                                color: TABLES_CSS.headingFontColor,
                                                                fontWeight: 'normal',
                                                            }}
                                                        >
                                                            {column.label}
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                fontSize: TABLES_CSS.headingFontSize,
                                                                color: TABLES_CSS.headingFontColor,
                                                                fontWeight: TABLES_CSS.headingFontWeight,
                                                            }}
                                                            noWrap
                                                        >
                                                            {column.label}
                                                        </Typography>
                                                    )}
                                                </div>
                                            )}
                                        </span>
                                    </div>
                                    {index !== headers.length - 1 && resizeable && (
                                        <span
                                            style={{
                                                height: 40,
                                                display: 'inline-block',
                                                // borderLeft: '1px #007bff solid',
                                                borderRight: '1px #ECECEC solid',
                                                width: 4,
                                                position: 'absolute',
                                                cursor: 'col-resize',
                                                zIndex: 1,
                                                right: '0',
                                                top: '7',
                                            }}
                                            id={`${elementPrefix}r${index}`}
                                        >
                                            <FontAwesomeIcon
                                                style={{ color: '#9B9B9B', position: 'absolute', top: '-9px', right: '-6px' }}
                                                icon={faSortDown}
                                                aria-label="faSortDown"
                                                id="faSortDown"
                                            />
                                        </span>
                                    )}
                                </TableCell>
                            ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHeadBase.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func,
    order: PropTypes.string,
    elementPrefix: PropTypes.string,
    // orderBy: PropTypes.string.isRequired,
    headers: PropTypes.array.isRequired,
    resizeable: PropTypes.bool,
    selectbuttonIndex: PropTypes.number,
    tableRowStyle: PropTypes.shape({}),
};

EnhancedTableHeadBase.defaultProps = {
    order: 'asc',
    onRequestSort: () => {},
    resizeable: false,
    selectbuttonIndex: 0,
    elementPrefix: '',
    tableRowStyle: {},
};

export const EnhancedTableHead = withStyles(styles)(EnhancedTableHeadBase);
