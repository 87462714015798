import React, { useEffect, memo, useState } from 'react';
import './index.css';
import Line from '../../common/Line';
import CircularCard from '../../components/ProgressCard/CircularCard';
import { Link } from 'react-router-dom';

const PassedIcon = require('../../assets/images/green_right_tick.svg');
const FailedIcon = require('../../assets/images/redcross.svg');
const RightArrowIcon = require('../../assets/images/RightArrow.svg')

const OverallHealthData = ({ data }) => {
  
  const [projectData, setProjectData] = useState({});
  const [suiteData, setSuiteData] = useState({});
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    if (data && data.projectData && data.suiteData) {
      setProjectData(data.projectData);
      setSuiteData(data.suiteData);
      setDataReady(true);
    }
  }, [data]);

  const getStrokeColor = (progress) => {
    if (progress <= 25) {
      return '#E8001C';
    }
    if (progress < 75) {
      return '#F39B31';
    }
    return '#00B330';
  };

  return (
    <React.Fragment>
      {dataReady && (
        <div className="container-root">
          <div className="container-title">Overall Health (last 24 hours)</div>
          <div className="container-content">
            <div className="section-root">
            <div style={{width:'100%'}}>
              <div className="section-title">Projects</div>
              <div className="section-content">
                <CircularCard
                    progress={projectData.progress}
                    colors={[getStrokeColor(projectData.progress), '#D8D8D8']}
                    size={120}
                    strokeWidth={10}
                    data={projectData.statics}
                  />
                <div className="section-content-numbers">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={PassedIcon} />
                    <div style={{ paddingLeft: '8px' }}>
                      <strong>{projectData.passed} </strong>
                      Passed
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '20px',
                    }}
                  >
                    <img src={FailedIcon} />
                    <div style={{ paddingLeft: '8px' }}>
                      <strong>{projectData.failed} </strong>
                      Failed
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>

            <div className="section-root">
            <div className="line">
              <Line lineHeight="160" lineWidth="1" />

              </div>
              <div style={{width:'100%'}}>

              <div className="section-title">
                  <div>Test Suites</div>
                  <div className="viewall-link">
                  <Link className="link" to={{ pathname: '/testsuites'}}>
                  View All
                  </Link>
                  <div style={{marginLeft:'6px'}}><img src={RightArrowIcon}/></div></div>

              </div>

              <div className="section-content">

                <CircularCard
                    progress={suiteData.progress}
                    colors={[getStrokeColor(suiteData.progress), '#D8D8D8']}
                    size={120}
                    strokeWidth={10}
                    data={suiteData.statics}
                  />
                <div className="section-content-numbers">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={PassedIcon} />
                    <div style={{ paddingLeft: '8px' }}>
                      <strong>{suiteData.passed} </strong>
                      Passed
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '20px',
                    }}
                  >
                    <img src={FailedIcon} />
                    <div style={{ paddingLeft: '8px' }}>
                      <strong>{suiteData.failed} </strong>
                      Failed
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>

            <div className="section-root">
            <div className="line">
              <Line lineHeight="160" lineWidth="1" />
              </div>
              <div style={{width:'100%'}}>
              <div className="section-title">
              <div>Test Cases</div>
                  <div className="viewall-link">
                  <Link className="link" to={{ pathname: '/testcases'}}>
                  View All
                  </Link>

                  <div style={{marginLeft:'6px'}}><img src={RightArrowIcon}/></div></div>

              </div>
              <div className="section-content">

                <CircularCard
                    progress={projectData.progress}
                    colors={[getStrokeColor(projectData.progress), '#D8D8D8']}
                    size={120}
                    strokeWidth={10}
                    data={projectData.statics}
                  />
                <div className="section-content-numbers">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={PassedIcon} />
                    <div style={{ paddingLeft: '8px' }}>
                      <strong>{projectData.passed} </strong>
                      Passed
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '20px',
                    }}
                  >
                    <img src={FailedIcon} />
                    <div style={{ paddingLeft: '8px' }}>
                      <strong>{projectData.failed} </strong>
                      Failed
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default memo(OverallHealthData);
