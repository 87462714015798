import { ActionTypes } from '../constants';
import { checkObject } from '../../utils';

const initialState = {
    error: null,
    isLoading: false,
    schedules: [],
    success: null,
    executionLoading: false,
    suiteLoading: false,
    suitesForJob: [],
    execDetails:{},
    updateLoading:false,
};

export default function scheduleReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };
        case ActionTypes.CLEAR_SUCCESS:
            return {
                ...state,
                success: null,
            };
        case ActionTypes.GET_ALL_SCHEDULES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                schedules: action.payload,
                error: null,
            };
        }
        case ActionTypes.GET_ALL_SCHEDULES_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.payload.message,
            };
        }
        case ActionTypes.DELETE_SCHEDULES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                success: action.payload.success,
                error: null,
            };
        }

        case ActionTypes.ADD_JOB_SCCUESS: {
            let obj = {
                ...state,
                isLoading: false,
                success: action.payload.success,
                error: null,
            };
            if (checkObject(action.payload.data)) {
                obj = {
                    ...obj,
                    schedules: [...state.schedules, action.payload.data],
                };
            }
            return obj;
        }

        case ActionTypes.GET_ALL_SCHEDULES:
        case ActionTypes.DELETE_SCHEDULES:
        case ActionTypes.ADD_JOB:
        case ActionTypes.UPDATE_JOB: {
            return {
                ...state,
                isLoading: true,
                error: null,
                success: null,
            };
        }

        case ActionTypes.UPDATE_JOB_SCCUESS: {
            const { updatedJob } = action.payload;
            const tempSchedules = JSON.parse(JSON.stringify(state.schedules));
            const updatedSchedules = tempSchedules.map((schedule) => {
                if (schedule.schedule_id === action.payload.id) {
                    return { ...schedule, ...updatedJob };
                }
                return schedule;
            });
            return {
                ...state,
                isLoading: false,
                success: action.success,
                error: null,
                schedules: [...updatedSchedules],
            };
        }

        case ActionTypes.DELETE_SCHEDULES_FAILURE:
        case ActionTypes.ADD_JOB_FAILED:
        case ActionTypes.UPDATE_JOB_FAILED: {
            return {
                ...state,
                isLoading: false,
                error: action.payload.message,
                success: null,
            };
        }

        case ActionTypes.EXECUTE_SCHEDULE: {
            return {
                ...state,
                executionLoading: true,
                error: null,
                success: null,
            };
        }

        case ActionTypes.EXECUTE_SCHEDULE_SUCCESS: {
            return {
                ...state,
                executionLoading: false,
                success: action.success,
                error: null,
            };
        }

        case ActionTypes.EXECUTE_SCHEDULE_FAILED: {
            return {
                ...state,
                executionLoading: false,
                error: action.payload.message,
                success: null,
            };
        }

        case ActionTypes.GET_SUITE_SCHEDULES: {
            return {
                ...state,
                suiteLoading: true,
            };
        }

        case ActionTypes.GET_SUITE_SCHEDULES_SUCCESS: {
            return {
                ...state,
                suiteLoading: false,
                suitesForJob: action.payload.data,
                error: null,
            };
        }

        case ActionTypes.GET_SUITE_SCHEDULES_FAILURE: {
            return {
                ...state,
                suiteLoading: false,
                error: action.payload,
            };
        }
        case ActionTypes.GET_EXECUTION_DETAILS_BY_SCHEDULE_ID_SUCCESS:{
            return {
                ...state,
                updateLoading:true,
                execDetails:action.payload.data,
            }
        }

        default:
            return state;
    }
}
