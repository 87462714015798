import { ActionTypes } from '../constants';

const initialState = {
    isError: false,
    isLoading: false,
    error: null,
    success: null,
    alerts: [],
    alertDetail: {},
};

export default function alertReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOGOUT_SUCCESS:
            return {
                ...initialState,
            };

        case ActionTypes.CLEAR_ERROR: {
            return { ...state, isError: false, error: null };
        }

        case ActionTypes.CLEAR_SUCCESS: {
            return { ...state, success: null };
        }

        case ActionTypes.GET_ALERTS: {
            return { ...state, isLoading: true };
        }

        case ActionTypes.GET_ALERTS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                alerts: action.payload.data,
                error: null,
                isError: false,
            };
        }

        case ActionTypes.GET_ALERTS_FAILED: {
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.payload.error?.message || action.payload.message,
                success: null,
            };
        }

        case ActionTypes.GET_ALERT_DETAIL: {
            return { ...state, isLoading: true };
        }

        case ActionTypes.GET_ALERT_DETAIL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                alertDetail: action.payload.data,
                error: null,
                isError: false,
            };
        }

        case ActionTypes.GET_ALERT_DETAIL_FAILED: {
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.payload.error || action.payload.message,
                success: null,
            };
        }

        case ActionTypes.CLEAR_ALERT_DETAIL: {
            return {
                ...state,
                isLoading: false,
                alertDetail: {},
                error: null,
                isError: false,
            };
        }

        case ActionTypes.REMOVE_ALERTS: {
            return { ...state, isLoading: true };
        }

        case ActionTypes.REMOVE_ALERTS_SUCCESS: {
            let alerts = [...state.alerts];
            alerts = alerts.filter((alert) => {
                return !action.payload.alert_ids.some((alertId) => alert.alert_id === alertId);
            });

            return {
                ...state,
                isLoading: false,
                error: null,
                isError: false,
                alerts,
            };
        }

        case ActionTypes.REMOVE_ALERTS_FAILED: {
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.payload.error || action.payload.message,
                success: null,
            };
        }

        default:
            return state;
    }
}
