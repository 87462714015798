// import material
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// import packages
import React from 'react';
import { connect } from 'react-redux';

// import icons
import CloseOnEscape from 'react-close-on-escape';
import Close from '@material-ui/icons/Close';
import {
    DIALOG_ROOT,
    MODAL_HEADER_TITLE,
    MODAL_HEADER_CLOSE_ICON,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_SUBMIT_BUTTON,
    MODAL_CANCEL_BUTTON,
    MODAL_FOOTER,
} from '../../../../common/cssConstants';
import config from '../../../../config';
import { TestStepActions } from '../../../../store/actions';
import { getParamValues } from '../../../../utils';

const styles = (theme) => ({
    dialogTitleStyle: MODAL_HEADER_TITLE,
    dialogModal: {
        '&>div': {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    dialog: {
        textAlign: 'center',
        backgroundColor: '#e9effd',
        borderBottom: '1px solid #dcdcdc',
    },
    formControl: {
        width: '100%',
        flexDirection: 'row',
        padding: '0px 30px',
    },
    dataName: {
        width: '100%',
        fontSize: 12,
        fontWeight: 400,
        paddingBottom: '10px',
        color: '#595959',
    },
    placeholder: {
        '&>div>div>:nth-child(3)::-webkit-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            color: '#9B9B9B',
            opacity: 1,
        },
        '&>div>div>:nth-child(3)::-moz-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            color: '#9B9B9B',
            opacity: 1,
        },
    },
    actionBtn: {
        padding: '2px 8px',
        minHeight: 0,
        borderColor: '#1067ce',
        textTransform: 'capitalize',
        borderRadius: '6px',
        minWidth: '75px',
    },
    textArea: {
        width: '100%',
        '&>div>textarea': {
            fontSize: '14px',
            padding: '15px 20px 0px 20px',
            '&::-webkit-input-placeholder': {
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: 400,
                color: '#9B9B9B',
                opacity: 1,
            },
            '&::-moz-input-placeholder': {
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: 400,
                color: '#9B9B9B',
                opacity: 1,
            },
        },
    },
    dialogContent: {
        overflow: 'hidden',
        padding: '0',
        width: 500,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
        },
    },
});

class SendLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logMoreDetail: '',
        };
    }

    sendEmailLogs = () => {
        const { user, onCompelete, testCase } = this.props;
        const { logMoreDetail } = this.state;
        const pathArray = getParamValues();
        const paramProjectId = Number(pathArray[1]);
        const paramTestCaseId = Number(pathArray[2]);
        if (testCase && paramProjectId && paramTestCaseId) {
            let screen_shot_url = '';
            if (testCase.testSteps && testCase.testSteps.length > 0) {
                for (let i = 0; i < testCase.testSteps.length; i++) {
                    const testStep = testCase.testSteps[i];
                    if (testStep.status && `${testStep.status}` === '3' && !testStep.hasChild) {
                        if (testStep.screenshotPaths) {
                            screen_shot_url = testStep.screenshotPaths[0]
                                ? `${config.api.endpoint}/testCases/previewScreenShot?screenShotURL=${testStep.screenshotPaths[0]}`
                                : '';
                        }
                        break;
                    }
                }
            }
            const data = {
                userId: user.userId,
                projectId: paramProjectId,
                testCaseId: paramTestCaseId,
                isEmail: 0,
                usr_comments: logMoreDetail,
                screen_shot_url,
            };
            this.props.sendEmailLogs(data);
            onCompelete(true);
        }
    };

    render() {
        const { classes, sendEmailModal, onCompelete } = this.props;
        const { logMoreDetail } = this.state;
        return (
            <div>
                <Dialog disableRestoreFocus open={sendEmailModal} aria-labelledby="form-dialog-title" className={classes.dialogModal}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            Send Logs
                        </DialogTitle>
                        <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                            <Close
                                style={MODAL_HEADER_CLOSE_ICON}
                                onClick={() => {
                                    onCompelete(false);
                                }}
                                aria-label="closeIcon"
                                id="closeIcon"
                            />
                        </div>
                    </div>
                    <DialogContent className={classes.dialogContent}>
                        <FormControl className={classes.formControl}>
                            <div style={{ width: '100%' }}>
                                <Typography className={classes.dataName}>More Details (optional)</Typography>
                                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                                    <TextField
                                        multiline
                                        rows="7"
                                        variant="filled"
                                        placeholder="Please enter the problem description here"
                                        InputProps={{ disableUnderline: true }}
                                        className={classes.textArea}
                                        aria-label="Log More Detail"
                                        id="logMoreDetail"
                                        value={logMoreDetail}
                                        onChange={(e) => {
                                            this.setState({ logMoreDetail: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>
                        </FormControl>
                    </DialogContent>
                    <DialogActions style={MODAL_FOOTER}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                this.sendEmailLogs();
                            }}
                            style={MODAL_SUBMIT_BUTTON}
                        >
                            Send Logs
                        </Button>
                        <CloseOnEscape onEscape={() => onCompelete(false)}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    onCompelete(false);
                                }}
                                style={MODAL_CANCEL_BUTTON}
                            >
                                Cancel
                            </Button>
                        </CloseOnEscape>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        sendEmailLogs: (...args) => dispatch(TestStepActions.sendEmailLogs(...args)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SendLog));
