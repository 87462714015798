import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, DialogActions, Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import CloseOnEscape from 'react-close-on-escape';
import { MODAL_FOOTER, MODAL_SUBMIT_BUTTON, MODAL_CANCEL_BUTTON, BLACK_FONT } from '../../../common/cssConstants';

const styles = (theme) => ({
    heading: {
        // paddingLeft: '40px',
    },
    formControl: {
        margin: '0px 30px',
        width: 'calc(100% - 60px)',
    },
    group: {
        margin: '0px',
    },
    radio: {
        margin: '0 0 15px 0',
        '&>span:first-child': {
            height: 24,
            width: 24,
            margin: '0 10px 0 0',
        },
    },
    buttonStyle: {
        padding: '2px 8px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#1067ce',
        borderRadius: '6px',
        textTransform: 'capitalize',
        marginRight: '10px',
    },
    cancelButton: {
        padding: '2px 8px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#1067ce',
        borderRadius: '6px',
        textTransform: 'capitalize',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 0,
        marginBottom: 0,
    },
    title: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        minHeight: '60px',
        paddingLeft: '20px',
        fontSize: 'medium',
    },
    expansionPanel: {
        width: '100%',
        overflowY: 'auto',
        marginTop: '5px',
    },
    expansionDetails: {
        width: '100%',
        overflowX: 'auto',
    },
    chipsContainer: {
        width: '100%',
        overflowY: 'auto',
    },
    chip: {
        margin: '5px 0 0 15px',
        '&>span': {
            paddingLeft: '9px',
        },
        '&>span:nth-child(2)': {
            color: `${BLACK_FONT} !important`,
        },
        background: '#EBF0F3',
    },
    bootstrapRoot: {
        padding: 0,
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    bootstrapInput: {
        fontSize: 12,
        padding: '10px 12px',
        width: 'calc(100% - 24px)',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    bootstrapFormLabel: {
        fontSize: 12,
    },
    inputFields: {
        width: '95%',
        marginBottom: 20,
    },
    addBtn: {
        minHeight: '39px',
        width: '95%',
    },
});

class AutoGenerateModal extends React.Component {
    state = {
        value: 'simpleMode',
        keywords: '',
        depth: '5',
        noOfCases: '5',
    };

    onChangeKeywords = (e) => {
        if (e.keyCode === 13) {
            this.setState({ keywords: '' });
            return;
        }
        this.setState({ keywords: e.target.value });
    };

    handleChange = (event) => {
        this.setState({ value: event.target.value });
    };

    startGenerating = () => {
        const { depth, value, noOfCases, keywords } = this.state;
        const { toggleModal, startGenrate, appUrl, projectId } = this.props;
        let obj = {};
        if (value === 'simpleMode') {
            obj = {
                website: appUrl,
                customXpaths: {
                    click: '(//input[@type=‘text’ or @type=‘email’ or @type=‘password’  or @role =‘textbox’])',
                    enter: '(//input[@type=‘radio’ or @type=‘checkbox’ or @type=‘submit’] | //select | //a | //button)',
                },
                // depth: depth,
                // projectId: projectId,
                // url: window.location.protocol + "//" + window.location.hostname,
                // generate: noOfCases,
            };
        } else if (value === 'advancedMode') {
            obj = {
                website: appUrl,
                customXpaths: {
                    click: '(//input[@type=‘text’ or @type=‘email’ or @type=‘password’  or @role =‘textbox’])',
                    enter: '(//input[@type=‘radio’ or @type=‘checkbox’ or @type=‘submit’] | //select | //a | //button)',
                },
                crawlerOption: {
                    depth,
                    maxDuration: 0,
                    numOfDriver: 25,
                },
                validationOption: {
                    keyword: keywords,
                    projectId,
                    url: `${window.location.protocol}//${window.location.hostname}`,
                    generate: noOfCases,
                },
            };
        }
        startGenrate(obj);

        toggleModal();
    };

    render() {
        const { classes, toggleModal } = this.props;
        const { value, keywords, depth, noOfCases } = this.state;
        return (
            <div style={{ width: this.state.value === 'advancedMode' ? 530 : '' }}>
                <div
                    style={{
                        maxHeight: 'calc(100vh - 330px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    <FormControl component="fieldset" className={classes.formControl}>
                        <RadioGroup aria-label="modes" name="gender1" className={classes.group} value={this.state.value} onChange={this.handleChange}>
                            <FormControlLabel
                                className={classes.radio}
                                value="simpleMode"
                                control={<Radio style={{ color: '#3B91DF' }} />}
                                label="Simple Mode"
                            />
                            <FormControlLabel
                                className={classes.radio}
                                value="advancedMode"
                                control={<Radio style={{ color: '#3B91DF' }} />}
                                label="Advanced Mode"
                            />
                        </RadioGroup>
                    </FormControl>
                    {value === 'advancedMode' && (
                        <div style={{ paddingLeft: 30 }}>
                            <span style={{ fontSize: '12px', color: '#595959' }}>Number of test cases</span>
                            <br />
                            <TextField
                                id="bootstrap-input"
                                aria-label="No Of Cases"
                                className={classes.inputFields}
                                onChange={(e) => this.setState({ noOfCases: e.target.value })}
                                value={noOfCases}
                                type="number"
                                InputProps={{
                                    classes: {
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    className: classes.bootstrapFormLabel,
                                }}
                            />
                            <br />
                            <span style={{ fontSize: '12px', color: '#595959' }}>Enter depth</span>
                            <br />
                            <TextField
                                id="bootstrap-input"
                                aria-label="Depth"
                                className={classes.inputFields}
                                onChange={(e) => this.setState({ depth: e.target.value })}
                                value={depth}
                                type="number"
                                InputProps={{
                                    classes: {
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    className: classes.bootstrapFormLabel,
                                }}
                            />
                            <br />
                            <span style={{ fontSize: '12px', color: '#595959' }}>Keyword</span>
                            <br />
                            <TextField
                                id="bootstrap-input"
                                aria-label="Kyewords"
                                className={classes.inputFields}
                                onChange={this.onChangeKeywords}
                                value={keywords}
                                InputProps={{
                                    classes: {
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    className: classes.bootstrapFormLabel,
                                }}
                            />
                        </div>
                    )}
                </div>
                <DialogActions style={MODAL_FOOTER}>
                    <Button color="secondary" disabled={false} onClick={this.startGenerating} style={MODAL_SUBMIT_BUTTON} variant="contained">
                        Start
                    </Button>
                    <CloseOnEscape onEscape={() => toggleModal()}>
                        <Button style={MODAL_CANCEL_BUTTON} color="secondary" onClick={() => toggleModal()} variant="outlined">
                            Cancel
                        </Button>
                    </CloseOnEscape>
                </DialogActions>
            </div>
        );
    }
}

export default withStyles(styles)(AutoGenerateModal);
