import { ActionTypes } from '../constants';
import { checkKeyInObject, checkArrayLength } from '../../utils/utils';
import config from '../../config';
import CookieStorage from '../../common/cookieStorage';

const initialState = {
    accounts: [],
    error: null,
    isLoading: false,
    singleAccount: {},
    success: null,
    marketYamlData: [],
    prefences: {},
    getPrefences: null,
    getPrefencesErr: null,
    samlConfig: {
        aiqAcsUrl: '',
        aiqMetadataUrl: '',
        domain: '',
        metadataUrl: '',
        userCount: '',
        isSamlAccount: false,
        aiqRelayState: '',
    },
    environmentConfig: {
        // SauceUserName:'',
        // SauceAccessKey:'',
        // SauceDataCenter:'',
    },

    sauceDetails:{

    },
    supportedEnvironments:[],
    // environmentConfig:{
    //     environments:{
    //         environmentType:[
    //             {
    //                 environment:[
    //                     {
    //                         platformDetails:{

    //                     }
    //                 },
    //                 {
    //                     {
    //                         platformDetails:{

    //                     }
    //                 }
    //                 ]
    //             }
    //         ]
    //     }
    // }
};

export default function accountReducer(state = initialState, action) {
    switch (action && action.type) {
        case ActionTypes.CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        case ActionTypes.CLEAR_SUCCESS:
            return {
                ...state,
                success: null,
            };

        case ActionTypes.DELETE_BULK_VIDEOS:
        case ActionTypes.DELETE_BULK_OBJECTS:
        case ActionTypes.UPLOAD_YAML:
        case ActionTypes.UPDATE_SAML_CONFIG:
        case ActionTypes.UPDATE_ACCOUNT_DETAIL:
        case ActionTypes.GET_SAML_CONFIG:
        case ActionTypes.GET_ACCOUNT_DETAIL:
        case ActionTypes.GET_MARKET:
        case ActionTypes.GET_ACCOUNTS:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.GET_ACCOUNTS_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                accounts: action.payload,
            };

        case ActionTypes.UPLOAD_YAML_FAILURE:
        case ActionTypes.UPDATE_ACCOUNT_DETAIL_FAILURE:
        case ActionTypes.GET_ACCOUNT_DETAIL_FAILURE:
        case ActionTypes.GET_MARKET_FAILURE:
        case ActionTypes.GET_ACCOUNTS_FAILURE:
            return {
                ...state,
                error: action.payload.message,
                isLoading: false,
                success: null,
            };

        case ActionTypes.GET_MARKET_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                marketYamlData: action.payload,
            };

        case ActionTypes.GET_ACCOUNT_DETAIL_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                singleAccount: action.payload,
            };

        case ActionTypes.CLEAR_SINGLE_ACCOUNT:
            return {
                ...state,
                singleAccount: {},
            };

        case ActionTypes.GET_SAML_CONFIG_SUCCESS: {
            const accId = CookieStorage.get('accountId');
            return {
                ...state,
                isLoading: false,
                samlConfig: {
                    ...state.samlConfig,
                    ...action.payload,
                    aiqRelayState: `${config.api.endpoint}/v1/accounts/${accId}/${accId}/auth/saml`,
                },
                // samlConfig: action.payload.samlConfig,
            };
        }

        case ActionTypes.UPDATE_SAML_CONFIG_SUCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.UPDATE_SAML_CONFIG_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.message,
            };

        case ActionTypes.UPDATE_ACCOUNT_DETAIL_SUCCESS: {
            const account = action.payload;
            const accountId = checkKeyInObject(account, 'accountId', 'value', null);
            const accounts = JSON.parse(JSON.stringify(state.accounts));
            const obj = {
                ...state,
                error: null,
                isLoading: false,
                success: 'Account updated successfully',
                singleAccount: account,
            };

            if (accountId && checkArrayLength(accounts)) {
                const index = accounts.findIndex((acc) => acc.accountId === accountId);
                if (index) {
                    accounts[index] = { ...account[index], ...account };
                }

                obj.accounts = accounts;
            }

            return obj;
        }

        case ActionTypes.UPLOAD_YAML_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                success: 'Uploaded successfully.',
            };

        case ActionTypes.GET_ACCOUNTS_PREFENCES_SUCCESS: {
            localStorage.setItem('executionEngine', action.payload.accounts.executionEngine);
            localStorage.setItem('windowsBrowser',action.payload.accounts.windowsBrowsers)
            return {
                ...state,
                prefences: action.payload,
                getPrefences: true,
                getPrefencesErr: false,
            };
        }

        case ActionTypes.GET_ACCOUNT_PERMISSION_SUCCESS: {
            localStorage.setItem('accountPermission',JSON.stringify(action.payload));
            return {
                ...state,
            };
        }

        case ActionTypes.GET_ACCOUNTS_PREFENCES_FAILED: {
            return {
                ...state,
                prefences: {},
                getPrefences: false,
                getPrefencesErr: true,
            };
        }

        case ActionTypes.UPDATE_ACCOUNT_PREFENCES: {
            return {
                ...state,
            };
        }

        case ActionTypes.UPDATE_ACCOUNT_PREFENCES_SUCCESS: {
            return {
                ...state,
                success: 'Successfully updated settings',
            };
        }

        case ActionTypes.UPDATE_ACCOUNT_PREFENCES_FAILED: {
            return {
                ...state,
                error: 'Error in update settings',
            };
        }

        case ActionTypes.GET_ACCOUNTS_PREFENCES:
        case ActionTypes.UPDATE_DASHBOARDS:
            return {
                ...state,
                getPrefencesErr: false,
            };

        case ActionTypes.UPDATE_DASHBOARDS_SUCCESS: {
            return {
                ...state,
                success: 'Successfully update dashboard settings',
            };
        }

        case ActionTypes.UPDATE_DASHBOARDS_FAILED: {
            return {
                ...state,
                error: 'Error in update Dashboard settings',
            };
        }

        case ActionTypes.DELETE_BULK_VIDEOS_SUCCESS:
        case ActionTypes.DELETE_BULK_OBJECTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
                success: action.payload.msg,
            };

        case ActionTypes.DELETE_BULK_VIDEOS_FAILURE:
        case ActionTypes.DELETE_BULK_OBJECTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload.err.message,
                success: null,
            };

        case ActionTypes.GET_EXECUTION_ENVIRONMENT_CONFIG_SUCCESS:
            console.log("AccountMiddleware reducer",action.payload);
            return {
                ...state,
                //...state.environmentConfig,
                //...action.payload,
                environmentConfig: action.payload,
                //success:true,
            }

        case ActionTypes.GET_SUPPORTED_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                supportedEnvironments: action.payload,
            }

        case ActionTypes.GET_SAUCE_DETAILS_SUCCESS:
            return {
                ...state,
                sauceDetails: action.payload,
            }
        case ActionTypes.POST_SAUCE_DETAILS_SUCCESS:
            return {
                ...state
            }

        case ActionTypes.POST_EXECUTION_ENVIRONMENT_CONFIG_SUCCESS:
            return {
                ...state
            }
            
        default:
            return state;
    }
}
