// import material
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';

// import libs
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// import icons
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItemText from '@material-ui/core/ListItemText';

// import custom components
import { ProjectActions } from '../../../store/actions';
import './sideBarStyle.css';
import SideBarContent from './SideBarContent';
import menu_toggle_icon from '../../../assets/icons/features/menu_toggle_icon.svg';

const drawerWidth = 250;

// Custom Accordion Starts
const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        marginTop: '-2px !important',
        width: drawerWidth,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
})(MuiExpansionPanel);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgb(57, 57, 57)',
        display: 'flex',
        minHeight: '53px !important',
        padding: '0 16px 0 16px !important',
        '& :last-child': {
            paddingRight: 0,
        },
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {}, // used empty object to insure usage of above '&$expanded'
})((props) => <MuiExpansionPanelSummary {...props} />);

AccordionSummary.muiName = 'AccordionSummary';

const AccordionDetails = withStyles({
    root: {
        backgroundColor: 'rgb(74, 74, 74)',
        height: 'calc(100vh - 97px)',
        padding: 0,
        zIndex: 9999,
    },
})(MuiExpansionPanelDetails);
// Custom Accordion Ends

const styles = ({ mixins, spacing, /* typography,  */ transitions }) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: 55,
        height: 'auto',
        border: 'none !important',
        backgroundColor: '#4a4a4a',
        overflow: 'visible',
        transition: transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        height: 'auto',
        border: 'none !important',
        backgroundColor: '#4a4a4a',
        transition: transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 55,
    },
    listItemContainer: {
        display: 'inline-flex',
        width: '80%',
    },
    listItemText: {
        marginTop: 0,
        marginBottom: 0,
        padding: '0 16px 0 25px',
    },
    ParentExpansionIconStyle: {
        color: '#fff',
        fontSize: 16,
        margin: '5px 4px 0px 7px',
    },
    titleText: {
        color: '#fff',
        fontSize: 14,
        paddingTop: spacing(0.5),
    },
    sideBarRoot: {
        position: 'fixed',
        zIndex: '2',
    },
    toolbarExtra: {
        ...mixins.toolbar,
        marginTop: spacing(-2),
    },
});

const SideBar = (props) => {
    const { classes, history, isUpdate, isSideBarOpened, toggleSideBar } = props;
    return (
        <div onMouseLeave={() => toggleSideBar(false)} className={classes.sideBarRoot}>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: isSideBarOpened,
                    [classes.drawerClose]: !isSideBarOpened,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: isSideBarOpened,
                        [classes.drawerClose]: !isSideBarOpened,
                    }),
                }}
                open={isSideBarOpened}
            >
                <div className={classes.toolbarExtra} />
                <Accordion
                    id="Expansionpannel"
                    expanded
                    onMouseLeave={() => toggleSideBar(false)}
                    style={{ backgroundColor: 'transparent', boxShadow: isSideBarOpened ? '3px 3px 9px 0 rgba(0,0,0,0.5)' : 'none' }}
                >
                    <AccordionSummary onClick={() => toggleSideBar(true)}>
                        <div>
                            <div style={{ display: 'inline-flex', width: '80%' }}>
                                <img src={menu_toggle_icon}/>
                                <ListItemText className={classes.listItemText} classes={{ secondary: classes.titleText }} secondary="MENU" />

                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SideBarContent history={history} isUpdate={isUpdate} swipable={false} isSideBarOpened={isSideBarOpened} />
                    </AccordionDetails>
                </Accordion>
            </Drawer>
        </div>
    );
};

SideBar.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state) {
    return {
        isSideBarOpened: state.projectReducer.isSideBarOpened,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleSideBar: (flag) => dispatch(ProjectActions.toggleSideBar(flag)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SideBar));
