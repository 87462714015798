/* eslint-disable no-template-curly-in-string */
// import materials
import { Hidden, Paper, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
// import Tooltip from '@material-ui/core/Tooltip';
// import packages
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
// import icons
import CheckIcon from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import Close from '@material-ui/icons/Close';
// import custom components
import { checkObject, checkArrayLength, getSecondsToFormatedTime, checkKeyInObject, handleXpathLength } from '../../../../utils/utils';
import { SelectedTestCaseActions, TestCaseActions, ProjectActions } from '../../../../store/actions';
import { TestStepUtils } from '../../../../utils/TestStepUtils';
import Tooltip from '../../../../components/Tooltip';
import LoadingStepRow from './LoadingRow';
import StatusSnipet from '../../Case/StatusSnipet';
import RowActionButtons from '../Misc/RowActionButtons';
import FadedRowLoader from '../Misc/FadedRowLoader';
import AutoSuggestComponent from './AutoSuggestComponent';
import MultilineAutoSuggest from './MultilineAutoSuggest';
import PreLoadImage from '../../../../common/PreLoadImage';
import DebugPointSelector from '../Misc/DebugPointSelector';
import DeleteAlertModal from '../../../modal/Delete/DeleteAlertModal';
import SmartPlayButton from '../Misc/SmartPlayButton';
import defaultScreenShot from '../../../../assets/images/noScreenshot.png';
import addStep from '../../images/addStep.png';
import expandStep from '../../images/expandStep.png';
import collapsStep from '../../images/collaps.png'


const listViewTextStyle = (theme) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 400,
    minWidth: 100,
    [theme.breakpoints.only('xs')]: {
        maxWidth: '100%',
    },
});
// Styles
const styles = (theme) => ({
    checkIcon: {
        width: '20px',
        height: '20px',
        border: '1px solid #efefef',
        borderRadius: '50%',
        marginLeft: '10px',
        cursor: 'pointer',
        position: 'relative',
        bottom: '25px',
        right: '3px',
    },
    checkIconContainer: {
        width: '100%',
        display: 'flex',
    },
    close: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    display: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
    },
    center: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
    },
    displayFlex: {
        display: 'flex',
    },
    even: {
        backgroundColor: '#ffffff',
        margin: '0px',
        padding: '0px !important',
    },
    imagePreviewContainer: {
        height: '85px',
        width: '118.5px',
        position: 'absolute',
        justifyContent: 'space-around',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#fff',
        fontSize: '16px',
        display: 'flex',
        '& > div > span':{
            display: 'none'
        },
        '&:hover': {
            '& > div > span': {
                display: 'block',
            },
            background: 'rgba(0, 63, 155, 0.6)',
        },
       
        '&:hover [class*="viewScreen"], &:hover [class*="checkIcon"]': {
            display: 'block',
        },
    },
    listViewCheckBox: {
        width: 20,
        height: 20,
        border: '1px solid rgba(0, 0, 0, 0.54)',
        color: 'transparent',
        marginLeft: 10,
        cursor: 'pointer',
        borderRadius: 4,
        overflow: 'hidden',
    },
    listViewCheckBoxSelected: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    listViewRow: {
        padding: '0px !important',
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            width: '90%',
        },
    },
    listViewRowBackGround: {
        background: 'rgba(0, 0, 0, 0.1)',
    },
    listViewStepNumberColumnStyle: {
        color: '#000',
        minWidth: '43px',
        paddingLeft: 15,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 10,
        },
    },
    listViewStepInstructionColumnStyle: {
        ...listViewTextStyle(theme),
        color: '#1168CD',
    },
    listViewStepDataColumnStyle: {
        ...listViewTextStyle(theme),
        color: '#1BABAB',
    },
    listViewStepExpectedResultColumnStyle: {
        ...listViewTextStyle(theme),
        color: '#B98700',
    },
    RobotoMonoImportant: {
        fontFamily: 'Roboto Mono !important',
    },
    odd: {
        backgroundColor: '#ffffff',
    },
    parentContainerWithDebugCall: {
        borderBottom: '1px solid #e0e0e0',
        backgroundColor: '#E4E4E4',
    },
    parentContainerWithNoDebugCall: {
        borderBottom: '1px solid #e0e0e0',
        
    },
    paper: {
        padding: theme.spacing(1),
        maxWidth: '220px',
        height: 'auto',
        wordBreak: 'break-word',
        zIndex: '10000',
        borderRadius: 8,
    },
    popperClear: {
        alignSelf: 'flex-end',
        display: 'flex',
        float: 'right',
        fontSize: '13px',
        height: '14px',
        textAlign: 'right',
        width: '16px',
    },
    popperPaperChild: {
        boxShadow: '0px 0px 19px 1px rgb(165, 165, 165) !important',
        right: '-3px',
        top: '-3px',
    },
    popperPaperChildReplay: {
        width: 235,
        background: '#ffedee',
    },
    screenShotContainer: {
        border: '3px solid #dddddd',
        height: '91px',
        position: 'relative',
        width: '124.5px',
    },
    screenShotThumbnail: {
        height: '85px',
        width: '118.5px',
    },
    screenViewRow: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    screenViewStepNumberColumnStyle: {
        color: '#000',
        paddingLeft: 5,
        marginRight: 5,
    },
    screenViewStepNumberColumnChildStyle: {
        minWidth: '27px',
        display: 'block',
    },
    screenViewStepInstrDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '45px',
        width:'19%'
        
        // maxWidth: 'calc(100% - 205px)',
        // maxWidth: '254px',
        // minWidth: 200,
    },
    screenViewStepInstructionColumnStyle: {
        overflow: 'hidden',
        height: '62px',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
       
        fontSize: 12,
        fontWeight: 400,
        color: '#242723',

    },
    screenViewInstrDataDivider: {
        backgroundColor: '#979797',
        height: 1,
        marginTop: 5,
        marginBottom: 5,
    },
    screenViewDataColumnStyle: {
        
        fontSize: 12,
        fontWeight: 400,
        marginLeft: '10.5px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '61px',
        color: '#2da6ea',
       
    },
    screenViewExpectedResultStyle: {
       
        fontSize: 12,
        fontWeight: 400,
        maxWidth: '235px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '61px',
        color: '#242723',
        marginLeft: '10.5px',
    },
    showText: {
        width: '100%',
        padding: '0px 8px 8px',
        fontWeight: 500,
        fontSize: 12,
        overflow: 'hidden',
    },
    container: {
        display: 'flex',
    },
    spanRow: {
        width: '100%',
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        fontSize: '13px',
        margin: '10px 0',
    },
    stepDuration: {
        color: 'rgb(88, 124, 153)',
        fontSize: 12,
        fontWeight: 400,
        display: 'block',
        marginRight: '30px',
        marginLeft: '8px',
    },
    tableCellActionListViewPadding: {
        padding: '0px 0px 0px 0px',
    },
    tableCellActionPadding: {
        padding: '16px 0px 16px 20px',
    },
    tableCellAction: {
        margin: '0px !important',
        textAlign: 'left',
        paddingRight: '0px !important',
    },
    tableRowDataList: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 !important',
        padding: '0px !important',
        justifyContent: 'space-between',
        width: '100%',
        position: 'relative',
        '&:hover': {
            '& > div > div > span > span': {
                visibility: 'visible',
            },
            '& > div > div > div > span > span': {
                visibility: 'visible',
            },
        },
    },
    testNameColored: {
        color: 'blue',
    },
    testnamedisabled: {
        color: '#4C4C4C',
    },
    timerSpan: {
        color: 'rgb(88, 124, 153)',
        fontSize: 12,
        fontWeight: 400,
        marginRight: '31px',
        marginLeft: 8,
    },
    viewScreen: {
        cursor: 'pointer',
        padding: '2px 5px',
        fontSize: '14px',
        position: 'absolute',
       
    },
    warning: {
        backgroundColor: '#fff3cd',
        color: '#856404',
        '&>div': {
            fontSize: '17px',
            fontWeight: '500',
        },
    },
    listViewRow1stContainer: {
        position: 'relative',
        minHeight: '32px',
        paddingLeft: '30px',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        flex: '1 1 0%',
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            paddingLeft: '10px',
            minHeight: '68px',
        },
    },
    pipe: {
        width: '20px',
        minWidth: '20px',
        textAlign: 'center',
        overflow: 'hidden',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    poperGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    add: {
        position: 'relative',
    },
});

const prevIsSelectChange = {};

class TestStepsTableData extends React.Component {
    constructor(props) {
        super(props);
        this.stepKey = `${props.projectId}_${props.testCaseId}_${props.testStep.instrNum}`;
        TestStepUtils.stepsRef[this.stepKey] = React.createRef();
        this.isComponentMount = false;
        this.toolTipEnterTimeOut = null;
        this.toolTipLeaveTimeOut = null;
        this.toolTipEnterTimeOutReplay = null;
        this.toolTipLeaveTimeOutReplay = null;
        this.timer = null;
        this.state = {
            isReplayButtonDisabled: false,
            anchorEl: null,
            warnMsg: '',
            anchorElReplay: null,
            debugCall: false,
            popoverTimer: true,
            showPopUp: false,
            showToast: false,
            openAlertDialog: false,
            shouldLoadComponent: false,
            forceUpdate: false,
            showVariableWarning: false,
            debuggedData: null,
          
        };
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setStateIfComponentMounted({ shouldLoadComponent: true });
        }, 400);
        this.isComponentMount = true;

        document.addEventListener('debuggedStep', this.updateDebuggedStep, true);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { failedStepsData, debugStepsData, returnData, testCaseId, projectId, testStep } = nextProps;
        this.stepKey = `${projectId}_${testCaseId}_${testStep.instrNum}`;
        const failedStep = failedStepsData[testCaseId];
        const debugStep = debugStepsData[testCaseId];
        let obj = {};
        if (returnData) {
            obj = { ...obj, debugCall: false };
        }

        if (this.state.isReplayButtonDisabled) {
            obj = { ...obj, isReplayButtonDisabled: false };
        }

        if (
            this.state.anchorElReplay &&
            (checkObject(failedStep) || checkObject(debugStep)) &&
            (this.props.testStep.showSmartRetryButton || this.props.testStep.showDebugPlayButton)
        ) {
            obj = { ...obj, showPopUp: false, anchorElReplay: null };
        }
        if (checkObject(obj)) {
            this.setStateIfComponentMounted(obj);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { actionFunctions: actionFunctionsNext, ...restNextProps } = nextProps;
        const { actionFunctions: actionFunctionsPrev, ...restPrevProps } = this.props;
        const shouldUpdate =
            (!_.isEqual(_.omit(restNextProps, _.functions(restNextProps)), _.omit(restPrevProps, _.functions(restPrevProps))) ||
                !_.isEqual(nextState, this.state)) &&
            (prevIsSelectChange[nextProps.testStep.instrNum] === undefined ||
                prevIsSelectChange[nextProps.testStep.instrNum] === restNextProps.isSelectChange);
        if (
            prevIsSelectChange[nextProps.testStep.instrNum] !== undefined &&
            prevIsSelectChange[nextProps.testStep.instrNum] !== restNextProps.isSelectChange
        ) {
            if (nextState.shouldLoadComponent) {
                this.setState({ forceUpdate: !nextState.forceUpdate });
            } else {
                this.setState({ shouldLoadComponent: true });
            }
        }
        prevIsSelectChange[nextProps.testStep.instrNum] = restNextProps.isSelectChange;
        return shouldUpdate;
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        this.isComponentMount = false;

        document.removeEventListener('debuggedStep', this.updateDebuggedStep, true);
    }

    onClickAlertDialogClose = (choice) => {
        const { testStep } = this.props;
        if (choice) {
            TestStepUtils.handleDeleteSteps([testStep.instrNum]);
        }
        this.props.setActionType('');
        this.setStateIfComponentMounted({ openAlertDialog: false });
    };

    /* Delete steps start */
    onClickAlertDialogOpen = () => {
        this.setStateIfComponentMounted({ openAlertDialog: true });
        this.props.setActionType('delete');
    };

    /* Delete steps end */

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (checkKeyInObject(this, 'isComponentMount') && this.isComponentMount) {
            this.setState(obj, callback);
            
        }
    };

    updateDebuggedStep = (event) => {
        const { index, testStep, testCaseId } = this.props;
        const data = event.detail;
        const reg = new RegExp('^\\${(.*?)}$', 'i');

        if (data && `${data.stepToEdit}` === `${testStep.instrNum}` && `${testCaseId}` === `${data.testCaseId}`) {
            if (testStep.data.match(reg)) {
                this.setState({
                    showVariableWarning: true,
                    errMsg: 'This data field is associated with a variable. Do you want to overwrite it?',
                    debuggedData: data,
                });
            } else if (testStep.columnName) {
                this.setState({
                    showVariableWarning: true,
                    errMsg: 'This data field is associated with a data file. Do you want to overwrite it?',
                    debuggedData: data,
                });
            } else {
                TestStepUtils.updateLiveDebugStep(data, index, testStep);
            }
        }
    };

    handlePopoverOpen = (event) => {
        const { currentTarget } = event;

        if (this.toolTipEnterTimeOut !== null) {
            clearTimeout(this.toolTipEnterTimeOut);
            this.toolTipEnterTimeOut = null;
        }
        if (this.toolTipLeaveTimeOut !== null) {
            clearTimeout(this.toolTipLeaveTimeOut);
            this.toolTipLeaveTimeOut = null;
        }
        if (!this.state.showPopUp) {
            this.toolTipEnterTimeOut = setTimeout(() => {
                this.setStateIfComponentMounted({ showPopUp: true, anchorEl: this.state.anchorEl ? null : currentTarget });
            }, 1000);
        }
    };

    handlePopoverTimer = () => {
        if (this.toolTipEnterTimeOut !== null) {
            clearTimeout(this.toolTipEnterTimeOut);
        }
        if (this.state.showPopUp) {
            this.toolTipLeaveTimeOut = setTimeout(() => {
                this.setStateIfComponentMounted({ showPopUp: false, anchorEl: null });
            }, 500);
        }
    };

    handleReplayPopoverEnter = (event) => {
        const { currentTarget } = event;
        if (this.toolTipEnterTimeOutReplay !== null) {
            clearTimeout(this.toolTipEnterTimeOutReplay);
            this.toolTipEnterTimeOutReplay = null;
        }
        if (this.toolTipLeaveTimeOutReplay !== null) {
            clearTimeout(this.toolTipLeaveTimeOutReplay);
            this.toolTipLeaveTimeOutReplay = null;
        }
        if (!this.state.showPopUp) {
            this.toolTipEnterTimeOutReplay = setTimeout(() => {
                this.setStateIfComponentMounted((state) => ({ showPopUp: true, anchorElReplay: state.anchorElReplay ? null : currentTarget }));
            }, 1000);
        }
    };

    handleReplayPopoverLeave = () => {
        if (this.toolTipEnterTimeOutReplay !== null) {
            clearTimeout(this.toolTipEnterTimeOutReplay);
        }
        if (this.state.showPopUp) {
            this.toolTipLeaveTimeOutReplay = setTimeout(() => {
                this.setStateIfComponentMounted({ showPopUp: false, anchorElReplay: null });
            }, 500);
        }
    };

    /* Steps CRUD Functions start */
    handleAdd = (data, index) => {
        const { testStep } = this.props;
        const _testStep = testStep ? { ...testStep } : {};
        delete _testStep.copyData;
        TestStepUtils.handleAddSteps(data, _testStep, index, true);
    };

    handleEdit = (testInstruction, testData, expectedResults, index) => {
        const { testStep } = this.props;
        TestStepUtils.handleEditSteps(testInstruction, testData, index, testStep, expectedResults);
    };

    /* Steps CRUD Functions end */

    imageHoverPreview = (classes, callBack) => {
        const { stepsType } = this.props;
        return (
            <div aria-hidden  className={classes.imagePreviewContainer}  onClick={callBack}>
                {/* <div className={classes.checkIconContainer}>
                    <span
                        aria-hidden
                        className={classes.checkIcon}
                        style={isSelected ? checkIconStyle : {}}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!isWsRunning[testCaseId]) {
                                this.props.selectStep(testStep);
                            }
                        }}
                    >
                        {' '}
                        <CheckIcon viewBox="0 0 32 32" aria-label="checkIcon" id="checkIcon" />
                    </span>
                </div> */}

                {stepsType === 'live' && (
                    <div className={classes.viewScreen}>
                        <span>View Screen</span>
                    </div>
                 )} 
            </div>
        );
    };

    handleWarnModal = (boolean) => {
        const { index, testStep } = this.props;
        const { debuggedData } = this.state;

        if (boolean) {
            const updatedSteps = JSON.parse(JSON.stringify(debuggedData));
            TestStepUtils.updateLiveDebugStep(updatedSteps, index, testStep);
        }
        this.setState({ showVariableWarning: false, errMsg: '', debuggedData: null });
    };

    disableReplayButton = () => {
        this.setStateIfComponentMounted({ isReplayButtonDisabled: true });
    };

    handleClose = () => {
        this.setStateIfComponentMounted({ showToast: false });
    };

    render() {
        const {
            actionFunctions,
            classes,
            changeIndex,
            toggleScreenShotModal,
            index,
            instrNumArray,
            // isNewSmartRetryStep,
            isWsRunning,
            listView,
            md5sum,
            showTextField,
            selectedSteps,
            selectedProject: { showTimer },
            smartRetryLoader,
            debugPointLoader,
            stepsType,
            testCaseId,
            projectId,
            testStep,
            userVariables,
            testDataColumns,
            selectedHoverImage,
            isTestCaseAlreadyRunning,
            wsRunningCaseDataCurrCase,
            actionType,
            height,
            shwoTost,
            isSelectChange,
            newStep,
            toggleExpandedFlow,
            expandInstrNo,
            debugPointList,
            creationMode,
            openTaskPanel
        } = this.props;

        const { isReplayButtonDisabled, anchorEl, debugCall, anchorElReplay, openAlertDialog, showVariableWarning, errMsg } = this.state;
        const open = Boolean(anchorEl);
        const id = open ? 'no-transition-popper' : null;
        const isSmartRetryStep = checkKeyInObject(testStep, 'showSmartRetryButton', 'value', false);
        const isSmartRetryLoader =
            checkKeyInObject(smartRetryLoader, testCaseId) &&
            checkObject(testStep) &&
            Number(testStep.instrNum) === Number(smartRetryLoader[testCaseId]);
        const isDebugPointStep = checkKeyInObject(testStep, 'showDebugPlayButton', 'value', false);
        const isDebugPointLoader =
            checkKeyInObject(debugPointLoader, testCaseId) &&
            checkObject(testStep) &&
            Number(testStep.instrNum) === Number(debugPointLoader[testCaseId]);
        const isSelected = selectedSteps.indexOf(testStep.instrNum) > -1;
        const sessionId = checkKeyInObject(wsRunningCaseDataCurrCase, 'sessionId');
        const testStepObj = checkKeyInObject(wsRunningCaseDataCurrCase, 'testStepObj');
        const showStepActions =
            !isWsRunning[testCaseId] &&
            !checkArrayLength(selectedSteps) &&
            !['add', 'edit', 'delete'].includes(actionType) &&
            ((sessionId && testStepObj && sessionId === testStepObj.sessionId && ['13', '16'].includes(`${testStepObj.msgType}`)) || // if run from tab 1 & get msgType 13 | 16 in tab 2 don't show tray
                (!sessionId && !testStepObj)); // if no session id then should not have testStepObj i.e not running
        const data = (
            <>
                <div
                    ref={TestStepUtils.stepsRef[this.stepKey]}
                    className={[
                        classes[debugCall ? 'parentContainerWithDebugCall' : 'parentContainerWithNoDebugCall'],
                        index % 2 === 0 ? `${classes.even}` : `${classes.odd}`,
                        'tableRow',
                    ].join(' ')}
                    key={testStep.instrNum}
                    style={
                        showTextField && actionType === 'add'
                            ? {
                                  display: 'flex',
                                  alignItems: 'center',
                                  position: 'fixed',
                                  height,
                                  width: 'calc(100% - 10px)',
                                  marginLeft: 5,
                                  boxShadow: '0 0 7px 0 rgba(0,0,0,0.8)',
                              }
                            : listView
                            ? { height }
                            : { display: 'flex', alignItems: 'center', height }
                    }
                >
                    {this.state.shouldLoadComponent || showTextField || isSelectChange !== prevIsSelectChange[testStep.instrNum] ? (
                        <>
                            <FadedRowLoader testCaseId={testCaseId} testStep={testStep} listView={listView} />
                            <div
                                className={[
                                    classes.tableCellAction,
                                    classes[
                                        listView && !(showTextField && actionType === 'add')
                                            ? 'tableCellActionListViewPadding'
                                            : 'tableCellActionPadding'
                                    ],
                                ].join(' ')}
                                style={listView && !(showTextField && actionType === 'add') ? {} : { width: '100%' }}
                            >
                                {/* Check if add or edit clicked or is there any new step
                                    then check if current index = index of step going to be edit or new position in case of add
                                */}
                                {showTextField && actionType === 'edit' ? (
                                    <div style={listView ? { paddingLeft: '25px' } : {}}>
                                        <AutoSuggestComponent
                                            handleEdit={this.handleEdit}
                                            md5sum={md5sum}
                                            index={index}
                                            testData={testStep.data}
                                            testInstruction={testStep.instr}
                                            expectedResults={testStep.expectedResults}
                                            testStep={testStep}
                                            userVariables={userVariables}
                                            listView={listView}
                                            stepKey={this.stepKey}
                                            rootStyle={{ height: '37px' }}
                                        />
                                    </div>
                                ) : showTextField && actionType === 'add' ? (
                                    <div className={classes.add}>
                                        <MultilineAutoSuggest
                                            handleAdd={this.handleAdd}
                                            md5sum={md5sum}
                                            index={index}
                                            testData={testStep.data}
                                            testInstruction={testStep.instr}
                                            expectedResults={testStep.expectedResults}
                                            testStep={testStep}
                                            userVariables={userVariables}
                                            listView={listView}
                                            stepKey={this.stepKey}
                                            rootStyle={{ height: '37px' }}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <div className={classes.tableRowDataList}>
                                            {listView ? (
                                                <div
                                                    className={[
                                                        classes.listViewRow,
                                                        selectedHoverImage === index ? classes.listViewRowBackGround : '',
                                                        `${testStep.status}` !== '5' ? classes.testNameColored : classes.testnamedisabled,
                                                    ].join(' ')}
                                                    onMouseOver={() => {
                                                        if (!(selectedSteps && selectedSteps.length > 0)) {
                                                            changeIndex(index);
                                                        }
                                                    }}
                                                    onMouseLeave={() => {
                                                        if (!(selectedSteps && selectedSteps.length > 0)) {
                                                            changeIndex(null);
                                                        }
                                                    }}
                                                >
                                                    <div className={classes.container}>
                                                        <div className={classes.listViewRow1stContainer}>
                                                        
                                                            <DebugPointSelector testStep={testStep} testCaseId={testCaseId} />
                                                            <StatusSnipet
                                                                testStepStatus={testStep.status}
                                                                testStepMsg={testStep.message || ''}
                                                                testStepNumber={testStep.instrNum}
                                                                testStep={testStep}
                                                                callFrom="testStepTableData"
                                                            />
                                                            <div
                                                                aria-hidden
                                                                className={classes.displayFlex}
                                                                onClick={() => {
                                                                    if (!isWsRunning[testCaseId]) {
                                                                        changeIndex(null);
                                                                        this.props.selectStep(testStep);
                                                                    }
                                                                }}
                                                            >
                                                                <span
                                                                    className={
                                                                        !isSelected
                                                                            ? classes.listViewCheckBox
                                                                            : [classes.listViewCheckBox, classes.listViewCheckBoxSelected].join(' ')
                                                                    }
                                                                >
                                                                    <CheckIcon viewBox="0 0 32 32" aria-label="checkIcon" id="checkIcon" />
                                                                </span>
                                                            </div>
                                                            <span
                                                                className={[classes.listViewStepNumberColumnStyle, classes.RobotoMonoImportant].join(
                                                                    ' ',
                                                                )}
                                                                style={{
                                                                    ...(checkArrayLength(instrNumArray) && instrNumArray.length > 99
                                                                        ? {
                                                                              minWidth: 53,
                                                                              paddingRight: 5,
                                                                              paddingLeft: 5,
                                                                              textAlign: 'center',
                                                                          }
                                                                        : {}),
                                                                    ...(checkArrayLength(instrNumArray) && instrNumArray.length < 999
                                                                        ? {
                                                                              minWidth: 48,
                                                                          }
                                                                        : {}),
                                                                    ...((`${testStep.instrNum}`.match(/\./g) || []).length > 0
                                                                        ? { paddingRight: 15 }
                                                                        : {}),
                                                                }}
                                                            >
                                                                {`${testStep.instrNum} `}
                                                            </span>
                                                            <span
                                                                onMouseEnter={this.handlePopoverOpen}
                                                                onMouseLeave={this.handlePopoverTimer}
                                                                className={classes.spanRow}
                                                            >
                                                                <span
                                                                    className={[
                                                                        classes.listViewStepInstructionColumnStyle,
                                                                        classes.RobotoMonoImportant,
                                                                    ].join(' ')}
                                                                >
                                                                    {handleXpathLength(testStep.instr)}
                                                                </span>
                                                                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                                                    <div style={{ width: '90%' }}>
                                                                        <hr className={classes.screenViewInstrDataDivider} />
                                                                    </div>
                                                                </Hidden>
                                                                {(testStep.data || testStep.columnName) && (
                                                                    <Hidden only={['xs']}>
                                                                        <span className={classes.pipe}>|</span>
                                                                    </Hidden>
                                                                )}
                                                                {(testStep.data || testStep.columnName) && (
                                                                    <span
                                                                        className={[
                                                                            classes.listViewStepDataColumnStyle,
                                                                            classes.RobotoMonoImportant,
                                                                        ].join(' ')}
                                                                    >
                                                                        {TestStepUtils.renderDataField(
                                                                            testStep.instr,
                                                                            testStep.data,
                                                                            userVariables,
                                                                            testDataColumns,
                                                                        )}
                                                                    </span>
                                                                )}
                                                                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                                                    <div style={{ width: '90%' }}>
                                                                        <hr className={classes.screenViewInstrDataDivider} />
                                                                    </div>
                                                                </Hidden>
                                                                {testStep.expectedResults && (
                                                                    <Hidden only={['xs']}>
                                                                        <span className={classes.pipe}>|</span>
                                                                    </Hidden>
                                                                )}
                                                                {testStep.expectedResults && (
                                                                    <span
                                                                        className={[
                                                                            classes.listViewStepExpectedResultColumnStyle,
                                                                            classes.RobotoMonoImportant,
                                                                        ].join(' ')}
                                                                    >
                                                                        {`${testStep.expectedResults || ''}`}
                                                                    </span>
                                                                )}
                                                            </span>
                                                            <Popper
                                                                id={id}
                                                                anchorEl={anchorEl}
                                                                className={classes.paper}
                                                                onMouseLeave={this.handlePopoverTimer}
                                                                onMouseOver={() => {
                                                                    if (this.toolTipLeaveTimeOut !== null) {
                                                                        clearTimeout(this.toolTipLeaveTimeOut);
                                                                        this.toolTipLeaveTimeOut = null;
                                                                    }
                                                                }}
                                                                open={open}
                                                                placement="top"
                                                            >
                                                                <Paper className={classes.popperPaperChild}>
                                                                    <Clear
                                                                        className={classes.popperClear}
                                                                        onClick={() => {
                                                                            if (this.toolTipEnterTimeOut !== null) {
                                                                                clearTimeout(this.toolTipEnterTimeOut);
                                                                            }
                                                                            this.setStateIfComponentMounted({
                                                                                showPopUp: false,
                                                                                anchorEl: null,
                                                                            });
                                                                        }}
                                                                        aria-label="clearIcon"
                                                                        id="clearIcon"
                                                                    />
                                                                    <Typography className={[classes.showText, classes.RobotoMonoImportant].join(' ')}>
                                                                        {testStep.instr}
                                                                        {TestStepUtils.dataHoverPreview(
                                                                            testStep.data,
                                                                            testStep.instr,
                                                                            'popper',
                                                                            userVariables,
                                                                            testDataColumns,
                                                                        )}
                                                                    </Typography>
                                                                </Paper>
                                                            </Popper>
                                                        </div>
                                                        <div className={classes.display}>
                                                            <div>
                                                                <RowActionButtons
                                                                    index={index}
                                                                    isSmartRetryStep={isSmartRetryStep}
                                                                    isDebugPointStep={isDebugPointStep}
                                                                    onClickAlertDialogOpen={this.onClickAlertDialogOpen}
                                                                    testCaseId={testCaseId}
                                                                    testStep={testStep}
                                                                    shwoTost={shwoTost}
                                                                    actionFunctions={actionFunctions}
                                                                />
                                                                     { (!isSmartRetryStep && actionType !== 'add' && !newStep && !testStep.isNew ) && showStepActions  &&
                                                                        <Tooltip data="Add Step">
                                                                            <IconButton color="primary" onClick={() => {
                                                                                TestStepUtils.onAddStep(
                                                                                    index,
                                                                                    true,
                                                                                    testStep.instrNum,
                                                                                    {
                                                                                        testInstruction: testStep.instr,
                                                                                        testData: testStep.data,
                                                                                        sendToTestCaseParser: testStep.sendToTestCaseParser,
                                                                                        expectedResults: testStep.expectedResults || '',
                                                                                    }, // this parameter is used to copy step
                                                                                );

                                                                            }} style={{ position: 'absolute', top: '25px', right: '14rem', zIndex: 99 }} aria-label="upload picture" component="span">
                                                                                <img src={addStep} style={{ width: '24px', height: '24px' }} alt="addStep" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    }
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-end',
                                                                }}
                                                            >
                                                                {stepsType === 'live' && showTimer ? (
                                                                    <span className={classes.timerSpan}>
                                                                        {`${testStep.status}` !== '5'
                                                                            ? testStep.duration
                                                                                ? getSecondsToFormatedTime(Math.round(testStep.duration), 'MM:SS')
                                                                                : '00:01'
                                                                            : '--:--'}
                                                                    </span>
                                                                ) : 
                                                                <span className={classes.timerSpan}>
                                                                    {'--:--'}
                                                                    </span>
                                                                
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    className={[
                                                        classes.screenViewRow,
                                                        `${testStep.status}` !== '5' ? classes.testNameColored : classes.testnamedisabled,
                                                    ].join(' ')}
                                                >
                                                  <div
                                                        style={{
                                                            flex: '1 1 0%',
                                                            width: '100%',
                                                            display: 'flex',
                                                            position: 'relative',
                                                            overflow: 'hidden',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        {/* Debuger */}
                                                        <Checkbox
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (!isWsRunning[testCaseId]) {
                                                                    this.props.selectStep(testStep);
                                                                }
                                                            }}
                                                            checked={isSelected}
                                                            style={{
                                                                height: '40px',
                                                                width: '20px',
                                                                marginLeft: '10px',
                                                                marginRight: '10px',
                                                                color: selectedSteps === instrNumArray ? '#3b91df' : '#bac4ce',
                                                            }}
                                                           
                                                        />
                                                       { testStep.subInstructions?.length > 0  && expandInstrNo.indexOf(testStep.instrNum+'.') === -1  && <IconButton style={{position: 'absolute', top: '66px', left: '12px'}} 
                                                       color="primary" aria-label="Expand Step" 
                                                       onClick={()=> {toggleExpandedFlow(testStep.instrNum, true);  }}
                                                       component="span">
                                                           <img alt='Expand Step' style={{width: '14px', height: '20px'}} src={expandStep} />
                                                        </IconButton>
                                                        
                                                        }
                                                        <DebugPointSelector testStep={testStep} testCaseId={testCaseId} />
                                                        <StatusSnipet
                                                            testStepStatus={testStep.status}
                                                            testStepMsg={testStep.message || ''}
                                                            testStepNumber={testStep.instrNum}
                                                            testStep={testStep}
                                                            callFrom="testStepTableData"
                                                        />
                                                        {/* Test step number */}
                                                        <span
                                                            className={[classes.screenViewStepNumberColumnStyle, classes.RobotoMonoImportant].join(
                                                                ' ',
                                                            )}
                                                        >
                                                            <span
                                                                className={classes.screenViewStepNumberColumnChildStyle}
                                                                style={{
                                                                    ...(checkArrayLength(instrNumArray) && instrNumArray.length > 99
                                                                        ? {
                                                                              minWidth: 40,
                                                                              paddingRight: 5,
                                                                              paddingLeft: 5,
                                                                              textAlign: 'center',
                                                                          }
                                                                        : {}),
                                                                    ...(checkArrayLength(instrNumArray) && instrNumArray.length < 999
                                                                        ? {
                                                                              minWidth: 50,
                                                                          }
                                                                        : {}),
                                                                    ...((`${testStep.instrNum}`.match(/\./g) || []).length > 0
                                                                        ? { paddingRight: 15 }
                                                                        : {}),
                                                                }}
                                                            >
                                                                {`${testStep.instrNum} `}
                                                            </span>
                                                        </span>
                                                        {/* Test step Screenshot begin */}
                                                        {testStep && (
                                                            <div className={classes.screenShotContainer}>
                                                                {this.imageHoverPreview(classes, () => {
                                                                    toggleScreenShotModal(true, index);
                                                                })}
                                                                <PreLoadImage
                                                                    key={TestStepUtils.getPreviewImage(testStep)}
                                                                    alt=""
                                                                    defaultImage={defaultScreenShot}
                                                                    className={classes.screenShotThumbnail}
                                                                    src={TestStepUtils.getPreviewImage(testStep)}
                                                                />
                                                            </div>
                                                        )}
                                                        {/* Test step screenshot end */}
                                                       
                                                        {/* Test step instruction/ Action begin */}
                                                        <span
                                                            className={classes.screenViewStepInstrDataContainer}
                                                            style ={openTaskPanel?{width:'20%'}:{width:'20%'}}
                                                            onMouseEnter={this.handlePopoverOpen}
                                                            onMouseLeave={this.handlePopoverTimer}
                                                        >
                                                            <span
                                                                className={[
                                                                    classes.screenViewStepInstructionColumnStyle,
                                                                    classes.RobotoMonoImportant,
                                                                    classes.center,
                                                                ].join(' ')}
                                                            >
                                                                {handleXpathLength(testStep.instr)}
                                                            </span>
                                                            {/* <span>
                                                                <hr className={classes.screenViewInstrDataDivider} />
                                                            </span> */}
                                                         
                                                        </span>
                                                        
                                                        {/* Test step data */}
                                                        <span 
                                                            style ={openTaskPanel?{width:'20%'}:{width:'20%'}}
                                                            className={[classes.screenViewDataColumnStyle, classes.RobotoMonoImportant, classes.center].join(' ')}
                                                            >
                                                                <Tooltip data={` ${TestStepUtils.renderDataField(
                                                                    testStep.instr,
                                                                    testStep.data,
                                                                    userVariables,
                                                                    testDataColumns,
                                                                )}`}>
                                                                {TestStepUtils.renderDataField(
                                                                    testStep.instr,
                                                                    testStep.data,
                                                                    userVariables,
                                                                    testDataColumns,
                                                                )}
                                                                </Tooltip>
                                                            </span>
                                                            {/* <span>
                                                                {testStep.expectedResults && <hr className={classes.screenViewInstrDataDivider} />}
                                                            </span> */}
                                                           
                                                            {/* Test step expectedResults */}
                                                            <span
                                                                style ={openTaskPanel?{width:'20%'}:{width:'20%'}}
                                                                className={[classes.screenViewExpectedResultStyle, classes.RobotoMonoImportant, classes.center].join(
                                                                    ' ',
                                                                )}
                                                            >
                                                                {`${testStep.expectedResults || ''}`}
                                                            </span>
                                                    </div>
                                                    <div className={classes.poperGrid}>
                                                        {/* Tooltip popup for instruction/Action */}
                                                        <Popper
                                                            id={id}
                                                            anchorEl={anchorEl}
                                                            className={classes.paper}
                                                            onMouseLeave={this.handlePopoverTimer}
                                                            onMouseOver={() => {
                                                                if (this.toolTipLeaveTimeOut !== null) {
                                                                    clearTimeout(this.toolTipLeaveTimeOut);
                                                                    this.toolTipLeaveTimeOut = null;
                                                                }
                                                            }}
                                                            open={open}
                                                            placement="top"
                                                        >
                                                            <Paper className={classes.popperPaperChild}>
                                                                <Clear
                                                                    className={classes.popperClear}
                                                                    onClick={() => {
                                                                        if (this.toolTipEnterTimeOut !== null) {
                                                                            clearTimeout(this.toolTipEnterTimeOut);
                                                                        }
                                                                        this.setStateIfComponentMounted({
                                                                            showPopUp: false,
                                                                            anchorEl: null,
                                                                        });
                                                                    }}
                                                                    aria-label="clearIcon"
                                                                    id="clearIcon"
                                                                />
                                                                <Typography className={[classes.showText, classes.RobotoMonoImportant].join(' ')}>
                                                                    {testStep.instr}
                                                                    {TestStepUtils.dataHoverPreview(
                                                                        testStep.data,
                                                                        testStep.instr,
                                                                        'popper',
                                                                        userVariables,
                                                                        testDataColumns,
                                                                    )}
                                                                    {testStep.expectedResults && ` | ${testStep.expectedResults}`}
                                                                   
                                                                </Typography>
                                                            </Paper>
                                                        </Popper>
                                                        {!isTestCaseAlreadyRunning && (
                                                            <div>
                                                                <RowActionButtons
                                                                    index={index}
                                                                    isSmartRetryStep={isSmartRetryStep}
                                                                    isDebugPointStep={isDebugPointStep}
                                                                    onClickAlertDialogOpen={this.onClickAlertDialogOpen}
                                                                    testCaseId={testCaseId}
                                                                    testStep={testStep}
                                                                    shwoTost={shwoTost}
                                                                    actionFunctions={actionFunctions}
                                                                />
                                                              
                                                                { (!isSmartRetryStep && actionType !== 'add' && !newStep && !testStep.isNew ) && showStepActions  &&
                                                                <Tooltip data="Add Step">
                                                                    <IconButton color="primary" onClick={() => {
                                                                        TestStepUtils.onAddStep(
                                                                            index,
                                                                            true,
                                                                            testStep.instrNum,
                                                                            {
                                                                                testInstruction: testStep.instr,
                                                                                testData: testStep.data,
                                                                                sendToTestCaseParser: testStep.sendToTestCaseParser,
                                                                                expectedResults: testStep.expectedResults || '',
                                                                            }, // this parameter is used to copy step
                                                                        );

                                                                    }} style={{ position: 'absolute', top: '95px', right: '7rem', zIndex: 99 }} aria-label="upload picture" component="span">
                                                                        <img src={addStep} style={{ width: '24px', height: '24px' }} alt="addStep" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                } 

                                                                { testStep.subInstructions?.length > 0 &&  expandInstrNo.indexOf(testStep.instrNum+'.') !== -1 &&  <IconButton style={{ position: 'absolute',top: '96px', left: '12px', zIndex: 99}} 
                                                                color="primary" aria-label="Collaps Step" 
                                                                onClick={()=>{   toggleExpandedFlow(testStep.instrNum, false)}}
                                                                component="span">
                                                                    <img alt='Collaps Step' style={{width: '14px', height: '20px'}} src={collapsStep} />
                                                                </IconButton>}
                                                                
                                                            </div>
                                                        )}
                                                        {stepsType === 'live' && showTimer ? (
                                                            <span className={classes.stepDuration}>
                                                                {`${testStep.status}` !== '5'
                                                                    ? testStep.duration
                                                                        ? getSecondsToFormatedTime(Math.round(testStep.duration), 'MM:SS')
                                                                        : '00:01'
                                                                    : '--:--'}
                                                            </span>
                                                        ) : 
                                                        <span className={classes.stepDuration}>
                                                            {'--:--'}
                                                            </span>
                                                        
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                            <SmartPlayButton
                                                closePopper={() => this.setStateIfComponentMounted({ showPopUp: false, anchorElReplay: null })}
                                                disableReplayButton={this.disableReplayButton}
                                                handleReplayPopoverEnter={this.handleReplayPopoverEnter}
                                                handleReplayPopoverLeave={this.handleReplayPopoverLeave}
                                                isReplayButtonDisabled={isReplayButtonDisabled}
                                                isSmartRetryStep={isSmartRetryStep}
                                                isSmartRetryLoader={isSmartRetryLoader}
                                                isDebugPointStep={isDebugPointStep}
                                                isDebugPointLoader={isDebugPointLoader}
                                                listView={listView}
                                                projectId={projectId}
                                                testCaseId={testCaseId}
                                                testStep={testStep}
                                                debugPointList={debugPointList}
                                                creationMode={creationMode}
                                            />
                                            {(isSmartRetryStep || isDebugPointStep) && Boolean(anchorElReplay) && (
                                                <Popper
                                                    id={id}
                                                    anchorEl={anchorElReplay}
                                                    className={classes.paper}
                                                    onMouseLeave={this.handleReplayPopoverLeave}
                                                    onMouseOver={() => {
                                                        if (this.toolTipLeaveTimeOutReplay !== null) {
                                                            clearTimeout(this.toolTipLeaveTimeOutReplay);
                                                            this.toolTipLeaveTimeOutReplay = null;
                                                        }
                                                    }}
                                                    open={Boolean(anchorElReplay)}
                                                    placement="top"
                                                >
                                                    <Paper className={[classes.popperPaperChild, classes.popperPaperChildReplay].join(' ')}>
                                                        <Clear
                                                            className={classes.popperClear}
                                                            onClick={() => {
                                                                if (this.toolTipEnterTimeOutReplay !== null) {
                                                                    clearTimeout(this.toolTipEnterTimeOutReplay);
                                                                }
                                                                this.setStateIfComponentMounted({ showPopUp: false, anchorElReplay: null });
                                                            }}
                                                            aria-label="clearIcon"
                                                            id="clearIcon"
                                                        />
                                                        <Typography className={[classes.showText, classes.RobotoMonoImportant].join(' ')}>
                                                            Click to continue generation.
                                                        </Typography>
                                                    </Paper>
                                                </Popper>
                                            )}
                                        </div>

                                        {/* TODO: This should be open after proper json from BE */}
                                        {/* testElement */}
                                    </div>
                                )}
                            </div>
                            <Snackbar
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                onClose={() => {
                                    this.handleClose();
                                }}
                                open={this.state.showToast}
                                message="Retry test case to enable live debug."
                                ContentProps={{ className: classes.warning }}
                                autoHideDuration={2000}
                                action={
                                    <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleClose}>
                                        <Close aria-label="closeIcon" id="closeIcon" />
                                    </IconButton>
                                }
                            />
                            {openAlertDialog ? (
                                <DeleteAlertModal
                                    deleteButton="Danger"
                                    modalfor="liveTestStep"
                                    open={openAlertDialog}
                                    handleClose={this.onClickAlertDialogClose}
                                />
                            ) : null}
                        </>
                    ) : (
                        <LoadingStepRow listView={listView} itemKey={this.stepKey} />
                    )}
                    <hr/>
                </div>
                {showTextField && actionType === 'add' ? (
                    <div
                        key={`${testStep.instrNum}_mask`}
                        style={listView ? { height: height + 5 } : { display: 'flex', alignItems: 'center', height: height + 5 }}
                    ></div>
                ) : null}

                {showVariableWarning ? (
                    <DeleteAlertModal
                        open={showVariableWarning}
                        modalfor="VariableDataWarning"
                        handleClose={this.handleWarnModal}
                        msg={errMsg}
                        cancelBtnText="Cancel"
                        submitBtnText="Okay"
                    />
                ) : null}
            </>
        );
        return data;
    }
}

TestStepsTableData.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    index: PropTypes.number.isRequired,
    md5sum: PropTypes.string.isRequired,
    selectedProject: PropTypes.shape({}).isRequired,
    userVariables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    testStep: PropTypes.shape({}).isRequired,
};
const mapStateToProps = (state, props) => {
    let showTextField = false;
    if (props.index === state.selectedTestCaseReducer.editedStepIndex || props.index === state.selectedTestCaseReducer.newStepIndex) {
        showTextField = true;
    }
    let wsRunningCaseDataCurrCase = state.projectReducer.wsRunningCaseData;
    wsRunningCaseDataCurrCase = checkKeyInObject(wsRunningCaseDataCurrCase, props.testCaseId, 'value', {});
    return {
        // Project reducer
        selectedProject: state.projectReducer.selectedProject,
        failedStepsData: state.projectReducer.failedStepsData, // Obj contains sessionId as key and objext as value
        debugStepsData: state.projectReducer.debugStepsData,
        testDataColumns: state.projectReducer.testDataColumns,
        isWsRunning: state.projectReducer.isWsRunning,
        userVariables: state.projectReducer.userVariables,
        wsRunningCaseDataCurrCase,
        debugPointList: state.projectsReducer.debugPointList[props.testCaseId ] || [],
        // Selected Test Case Reducer
        isTestCaseAlreadyRunning: state.selectedTestCaseReducer.isTestCaseAlreadyRunning,
        selectedSteps: state.selectedTestCaseReducer.selectedSteps,
        smartRetryLoader: state.selectedTestCaseReducer.smartRetryLoader,
        debugPointLoader: state.selectedTestCaseReducer.debugPointLoader,
        newStep: state.selectedTestCaseReducer.newStep,
        stepsType: state.selectedTestCaseReducer.stepsType,
        testStep: state.selectedTestCaseReducer.testSteps[props.instrNum] || {},
        instrNumArray: state.selectedTestCaseReducer.instrNumArray,
        showTextField,
        creationMode: state.selectedTestCaseReducer.creationMode,
        // selected test case reducer
        actionType: state.selectedTestCaseReducer.actionType,
        expandInstrNo: state.selectedTestCaseReducer.expandInstrNo || [],
        openTaskPanel: state.generalReducer.openTaskPanel,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // Selected Test Case Action
        setActionType: (...args) => dispatch(SelectedTestCaseActions.setActionType(...args)),
        selectStep: (...args) => dispatch(SelectedTestCaseActions.selectStep(...args)),
        toggleStepCacheXpath: (...args) => dispatch(SelectedTestCaseActions.toggleStepCacheXpath(...args)),
        // test case action
        toggleScreenShotModal: (...args) => dispatch(TestCaseActions.toggleScreenShotModal(...args)),
        // Project Action
        shwoTost: () => dispatch(ProjectActions.ShowToast()),
        toggleExpandedFlow: ( ...args) => dispatch(SelectedTestCaseActions.toggleExpandedRow(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TestStepsTableData));
