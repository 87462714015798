import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Select from '../../../modal/GeneralModal/Select';

const styles = () => ({
    select: {
        width: '100%',
    },
});

const EditTestSuiteDialogContent = (props) => {
    const { searchValue, searchOnChange, options, index, itemName, suite_id, classes, placeholder, isError, callingFrom } = props;
    return (
        <Select
            value={searchValue}
            placeholder={placeholder}
            onChange={(target) => searchOnChange(target, index, itemName, suite_id)}
            className={classes.select}
            callingFrom={callingFrom}
            options={options}
            isError={isError}
        />
    );
};

export default withStyles(styles)(EditTestSuiteDialogContent);
