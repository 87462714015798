// import material
import { withStyles } from '@material-ui/core/styles';

// import packages
import React from 'react';
import { connect } from 'react-redux';

// import custom componenet
import { TABLES_CSS } from '../../../../common/cssConstants';
import CaseStatus from '../../Case/CaseStatus';
import { testCaseStatus, checkKeyInObject, checkArrayLength } from '../../../../utils';

const styles = () => ({
    root: {
        marginTop: '-30px',
        marginBottom: '13px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItem: 'center',
    },
    avatar: {
        minWidth: '20px',
        height: '25px',
        borderRadius: '20px',
        backgroundColor: '#f4f6f7',
        color: '#fff',
        textAlign: 'center',
        lineHeight: 2.2,
        paddingRight: '20px',
        paddingLeft: '15px',
        display: 'inline-block',
        maxWidth: '20%',
        marginRight: 20,
    },
});

const ExecutionHeaderTop = (props) => {
    const { classes, paramTestCaseId, paramExecutionId, executionDetails, instrNumArray, executionStatus } = props;
    if (checkKeyInObject(executionStatus, executionDetails.executionId, 'bool') || checkKeyInObject(executionStatus, paramExecutionId, 'bool')) {
        executionDetails.status = executionStatus[executionDetails.executionId || paramExecutionId];
    }
    let testCase = {
        isGenerating: false,
        testCaseId: paramTestCaseId,
        executionId: checkKeyInObject(executionDetails, 'executionId') || paramExecutionId,
        status: executionDetails.status,
        statusMessage: checkKeyInObject(executionDetails, 'statusMessage') || testCaseStatus[executionDetails.status],
        stepsCount: checkArrayLength(instrNumArray) ? instrNumArray.length : 0,
    };
    if (!checkKeyInObject(executionDetails, 'status', 'bool')) {
        testCase = {
            ...testCase,
            status: -3,
            statusMessage: 'Execution is waiting to start',
            stepsCount: 0,
        };
    }
    return (
        <div className={classes.root}>
            <div className={classes.avatar}>
                <CaseStatus
                    testCase={testCase}
                    customStyles={{
                        fontSize: TABLES_CSS.normalFontSize,
                        color: TABLES_CSS.normalFontColor,
                    }}
                    is_nowrap
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        executionDetails: state.executionReducer.executionDetails,
        wsRunningCaseData: state.projectReducer.wsRunningCaseData, // getting this prop just because of rerender on msgType 4,5,8
        instrNumArray: state.executionReducer.instrNumArray,
        executionStatus: state.executionReducer.executionStatus,
    };
};

export default connect(mapStateToProps, null)(withStyles(styles)(ExecutionHeaderTop));
