import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';

import LastPage from '@material-ui/icons/LastPage';
import FirstPage from '@material-ui/icons/FirstPage';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { BLACK_FONT } from '../../common/cssConstants';

const styles = (/* theme */) => ({
    root: {
        width: '100%',
        display: 'flex',
        height: '25px',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 25,
        overflow: 'hidden',
    },
    label: {
        color: BLACK_FONT,
        fontSize: '12px',
    },
    dropDown: {
        '&>div>div:focus': {
            background: 'transparent',
        },
    },
    select: {
        padding: '12px 21px 12px 6px',
        fontSize: '13px',
        color: '#4a4a4a',
    },
    pageCount: {
        color: BLACK_FONT,
        fontSize: '12px',
    },
    navigation: {
        display: 'flex',
    },
    arrow: {
        fontSize: '20px',
        color: BLACK_FONT,
        cursor: 'pointer',
    },
    disabled: {
        pointerEvents: 'none',
        opacity: '0.6',
    },
});

function TablePagination(props) {
    const { classes, count, page, rowsPerPage, labelRowsPerPage, rowsPerPageOptions, handleChangePage, handleChangeRowsPerPage } = props;
    let noOfPages = count && rowsPerPage ? Math.floor(count % rowsPerPage === 0 ? count / rowsPerPage - 1 : count / rowsPerPage) : 0;
    if (page > noOfPages && noOfPages >= 0) {
        handleChangePage(noOfPages);
    } else if (noOfPages === -1) {
        noOfPages = page
    }
    return (
        <div className={classes.root}>
            <div className={classes.labelContainer}>
                <div className={classes.label}>{labelRowsPerPage}</div>
                <Select
                    defaultValue={""}
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                    disableUnderline
                    className={classes.dropDown}
                    classes={{ select: classes.select }}
                >
                    {rowsPerPageOptions.map((option, index) => {
                        return (
                            <MenuItem value={option} key={index}>
                                {' '}
                                {option}
                            </MenuItem>
                        );
                    })}
                </Select>
            </div>
            <div className={classes.pageCount}>{`Page ${page + 1} of ${noOfPages === -1 ? 1 : noOfPages + 1}`}</div>
            <div className={classes.navigation}>
                <FirstPage
                    className={[classes.arrow, page === 0 ? classes.disabled : ''].join(' ')}
                    onClick={() => handleChangePage(0)}
                    aria-label="firstPageIcon"
                    id="firstPageIcon"
                />
                <ArrowLeft
                    className={[classes.arrow, page === 0 ? classes.disabled : ''].join(' ')}
                    onClick={() => handleChangePage(page - 1)}
                    aria-label="arrowLeftIcon"
                    id="arrowLeftIcon"
                />
            </div>
            <div className={classes.navigation}>
                <ArrowRight
                    className={[classes.arrow, page === noOfPages ? classes.disabled : ''].join(' ')}
                    onClick={() => handleChangePage(page + 1)}
                    aria-label="arrowRightIcon"
                    id="arrowRightIcon"
                />
                <LastPage
                    className={[classes.arrow, page === noOfPages ? classes.disabled : ''].join(' ')}
                    onClick={() => handleChangePage(noOfPages)}
                    aria-label="lastPageIcon"
                    id="lastPageIcon"
                />
            </div>
        </div>
    );
}

TablePagination.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    count: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    labelRowsPerPage: PropTypes.string,
    handleChangePage: PropTypes.func.isRequired,
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    page: PropTypes.number,
    rowsPerPageOptions: PropTypes.array.isRequired,
};

TablePagination.defaultProps = {
    labelRowsPerPage: '',
    page: -1,
};

export default withStyles(styles)(TablePagination);
