import {ActionTypes} from '../constants'

const selectionExecutionAction = (data) => {
    return (dispatch) => dispatch({
        type: ActionTypes.SELECTED_EXECUTION,
        payload: data,
    })
}

const sortSelectedExecution = (data) => {
    return (dispatch) => dispatch({
        type: ActionTypes.SORT_SELECTED_EXECUTION,
        payload: data,
    })
}

export  {selectionExecutionAction, sortSelectedExecution}