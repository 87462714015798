import {
    DIALOG_ROOT,
    BLACK_FONT,
    MODAL_CANCEL_BUTTON,
    MODAL_SUBMIT_BUTTON,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_HEADER_CLOSE_ICON,
} from '../../../common/cssConstants';

export const styles = (theme) => ({
    root: {
        width: '100%',
        padding: '0px',
        overflow: 'hidden',
        marginBottom: '20px',
    },
    dialogRoot: DIALOG_ROOT,
    dialogTitleStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '30px 0px 0px 30px',
        '& > h2': {
            fontSize: 18,
            color: BLACK_FONT,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: 575,
            padding: '30px 30px 22px 30px',
        },
    },
    closeIcon: MODAL_HEADER_CLOSE_ICON,
    closeIconCont: MODAL_HEADER_CLOSE_ICON_CONT,
    checkBox: {
        cursor: 'pointer',
        height: 20,
        width: 20,
        color: '#3B91DF',
        marginRight: 10,
    },
    mainDialog: {
        padding: '0 30px',
        maxWidth: '600px',
        [theme.breakpoints.up('sm')]: {
            minWidth: '550px',
        },
        [theme.breakpoints.only('xs')]: {
            minHeight: 'calc(100vh - 265px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
    },
    testCountStyle: {
        marginTop: '2%',
        color: BLACK_FONT,
        fontSize: 12,
    },
    cancelButton: MODAL_CANCEL_BUTTON,
    buttonStyle: MODAL_SUBMIT_BUTTON,
    bodyContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // maxWidth: 529
    },
    countWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            marginTop: '3px',
            marginBottom: '5px',
        },
    },
    stepper: {
        padding: 0,
        overflow: 'hidden',
        marginBottom: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        [theme.breakpoints.only('xs')]: {
            maxWidth: '257px',
        },
    },
    stepContainer: {
        position: 'relative',
        marginRight: 50,
    },
    stepVal: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        paddingTop: 13,
        fontSize: 12,
        color: '#9B9B9B',
        cursor: 'pointer',
    },
    activeStepVal: {
        borderTop: '3px solid #1168CD',
        paddingTop: 10,
        color: '#1168CD',
    },
    errorMsg: {
        color: '#E8001C',
        fontSize: 12,
        fontWeight: '400',
        marginLeft: 30,
    },
    failedFilesBody: {
        padding: '10px 0px 10px 25px',
        maxHeight: '180px',
        overflowY: 'auto',
    },
    failedFilesContainer: {
        marginTop: 20,
        marginBottom: -20,
    },
    failedFilesHead: {
        display: 'flex',
    },
    failedFileSection: {
        display: 'flex',
        padding: 5,
    },
    responseContainer: {},
    succeedFilesContainer: {
        marginBottom: 10,
    },
    succeedFilesBody: {
        padding: '10px 0px 10px 25px',
        maxHeight: '180px',
        overflowY: 'auto',
    },
    succeedFilesHead: {
        display: 'flex',
    },
    succeedFileSection: {
        padding: 5,
    },
    fileCard: {
        width: 'calc(100% - 8px)',
        height: 40,
        borderRadius: 5,
        backgroundColor: '#FFFFFF',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 20px',
        marginBottom: 16,
    },
    folderIcon: {
        color: '#3B91DF',
        fontSize: 20,
        marginRight: 10,
    },
    cancelIcon: {
        color: '#E8001C',
        fontSize: 20,
        marginLeft: 10,
        cursor: 'pointer',
    },
    checkIcon: {
        color: '#00B330',
        fontSize: 20,
        marginLeft: 10,
        cursor: 'pointer',
    },
    textCenter: {
        textAlign: 'center',
    },
    text: {
        color: BLACK_FONT,
        fontSize: 12,
    },
    fullWidth: {
        width: '100%',
    },
});
