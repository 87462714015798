import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { LOGIN_PAGE_CSS } from '../../common/cssConstants';
import { COLORS } from '../../common/Colors';

const styles = () => ({
    textField: {
        backgroundColor: 'white',
        width: '100%',
        height: LOGIN_PAGE_CSS.textFieldHeight,
        '& label.Mui-focused': {
            color: COLORS.gray,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: COLORS.gray,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: COLORS.gray,
            },
            '&:hover fieldset': {
                borderColor: COLORS.gray,
            },
            '&.Mui-focused fieldset': {
                borderColor: COLORS.gray,
            },
        },
    },
    formInput: {
        width: '100%',
        border: 'none',
        maxWidth: '100%',
        paddingTop: '4px',
        paddingBottom: '4px',
        '&>div>input': {
            '-webkit-box-shadow': '0 0 0px 1000px #ffffff inset !important',
        },
    },
});

const CustomTextField = (props) => {
    const {
        autoFocus,
        name,
        id,
        value,
        placeholder,
        onChange,
        onKeyPress,
        // color,
        disabled,
        ariaLabel,
        InputProps,
        error,
        type,
        required,
        classes,
        styles: _sytles,
        divStyle = {},
    } = props;
    return (
        <div className={classes.textField} style={divStyle}>
            <TextField
                autoFocus={autoFocus}
                name={name}
                disabled={disabled}
                aria-label={ariaLabel}
                id={id}
                placeholder={placeholder}
                onChange={onChange}
                onKeyPress={onKeyPress}
                // color={color}
                value={value}
                InputProps={InputProps}
                error={error}
                type={type}
                required={required}
                className={classes.formInput}
                style={{ ..._sytles }}
            />
        </div>
    );
};

export default withStyles(styles)(CustomTextField);
