import React from 'react';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress, Typography, DialogActions, Button } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import CloseOnEscape from 'react-close-on-escape';
import { ModalActions, FlowActions } from '../../../store/actions';
import { getProjectDropDownData } from '../../../utils/utils';
import SelectSerachBox from '../Flow/SelectSerachBox';
import { MODAL_FOOTER, MODAL_CANCEL_BUTTON, MODAL_SUBMIT_BUTTON } from '../../../common/cssConstants';

const styles = () => ({
    root: {},
    buttonStyle: {
        padding: '2px 8px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#1067ce',
        borderRadius: '6px',
        textTransform: 'capitalize',
        marginRight: '10px',
    },
    cancelButton: {
        padding: '2px 8px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#1067ce',
        borderRadius: '6px',
        textTransform: 'capitalize',
    },
    avatar: {
        minWidth: '20px',
        height: '20px',
        borderRadius: '20px',
        backgroundColor: '#3b91df',
        color: '#fff',
        textAlign: 'center',
        marginRight: '8px',
        fontSize: '13px',
        lineHeight: 1.7,
        padding: '0 4px',
        display: 'inline-block',
    },
    formControl: {
        width: '100%',
        flexDirection: 'column',
        padding: '10px 30px',
        borderBottom: '1px solid #dcdcdc',
        '&>p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    formControlNested: {
        width: '100%',
        flexDirection: 'column',
        borderBottom: '1px solid #dcdcdc',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        '&>p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    formWrapperWithError: {
        display: 'flex',
        backgroundColor: '#F0F7EA',
        minHeight: '60px',
        paddingLeft: '30px',
        alignItems: 'center',
    },
    colorError: {
        display: 'flex',
        justifyContent: 'center',
        margin: '5px 0px 5px 0px',
        fontWeight: 600,
        fontSize: '14px',
        minHeight: '25px',
        maxWidth: '90%',
    },
});

class CloneAllVariables extends React.Component {
    state = {
        selectedProject: 0,
        selectedName: '',
    };

    componentDidMount() {
        const { clearErr } = this.props;
        clearErr();
    }

    selectProjectHandler = (selectedProjectName) => {
        const { clearErr } = this.props;
        this.setState(
            {
                selectedProject: selectedProjectName.value,
                selectedName: selectedProjectName.label,
            },
            () => {
                clearErr();
            },
        );
    };

    handleSubmit = () => {
        const { allVariables, toggleModal, cloneAllVariables } = this.props;
        const { selectedProject } = this.state;
        const data = allVariables.map((variable) => {
            return { ...variable, newPrjId: Number(selectedProject) };
        });
        cloneAllVariables({ vars: data }, toggleModal);
    };

    render() {
        const { allVariables, classes, projects, toggleModal, loading, notClonedVars } = this.props;
        const { selectedProject, selectedName } = this.state;
        const filtered = projects.filter((proj) => proj.projectId !== allVariables[0].projectId);
        let projectData = getProjectDropDownData(filtered);
        projectData = projectData.sort((a, b) => a.label.localeCompare(b.label));
        const err = notClonedVars && notClonedVars.map((vars) => vars.key);
        return (
            <div>
                <div>
                    {loading ? (
                        <CircularProgress style={{ margin: '50px 45% 0px', color: '#4885ed' }} size={30} />
                    ) : (
                        <FormControl
                            className={classes.formControl}
                            style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'row', paddingTop: '0px' }}
                        >
                            <Typography style={{ fontWeight: 'normal', padding: '8px 2px' }}>Select Project</Typography>
                            <SelectSerachBox
                                id={selectedProject}
                                value={selectedName}
                                options={projectData}
                                onChange={(e) => this.setState({ selectedName: e })}
                                onSelect={this.selectProjectHandler}
                                height={120}
                            />
                        </FormControl>
                    )}
                    <FormControl className={classes.formControl} style={{ backgroundColor: '#F0F7EA' }}>
                        <Typography style={{ fontWeight: 'bold' }}>
                            <span className={classes.avatar}>{allVariables.length}</span>
                            {allVariables.length === 1 ? 'Variable is' : 'Variables are'} selected
                        </Typography>
                    </FormControl>
                    {/* <FormControl
                    className={classes.formControl}
                    style={{ backgroundColor: '#fff' }}
                >
                    <Typography>
                        key
                    </Typography>
                </FormControl>
                <FormControl
                    className={classes.formControl}
                    style={{ backgroundColor: '#F0F7EA' }}
                >
                    <Typography style={{ fontWeight: 'bold' }}>
                        Value
                    </Typography>
                </FormControl>
                <FormControl
                    className={classes.formControl}
                    style={{ backgroundColor: '#fff' }}
                >
                    <Typography>
                        value
                    </Typography>
                </FormControl> */}

                    {err && err.length && (
                        <FormControl className={classes.formControlNested} style={{ backgroundColor: '#800000' }}>
                            <Typography style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}>
                                Unable to clone following variables already exist
                            </Typography>
                        </FormControl>
                    )}
                    {err && err.length && (
                        <div className={classes.formWrapperWithError}>
                            <Typography className={classes.colorError}>{err.join(',  ')}</Typography>
                        </div>
                    )}
                    {/* {
                    err && err.length &&
                    <div>
                        <div>
                            {
                                err.map((er, ind) => (
                                    <FormControl key={ind.toString()} className={classes.formControl}>
                                        <Typography>
                                            {er}
                                        </Typography>
                                    </FormControl>
                                ))
                            }
                        </div>
                    </div>
                } */}
                </div>

                <DialogActions style={MODAL_FOOTER}>
                    <Button
                        style={MODAL_SUBMIT_BUTTON}
                        variant="contained"
                        color="secondary"
                        disabled={Boolean(typeof selectedProject !== 'string')}
                        onClick={this.handleSubmit}
                    >
                        {allVariables.length === 1 ? 'Clone' : 'Clone All'}
                    </Button>
                    <CloseOnEscape onEscape={() => toggleModal()}>
                        <Button style={MODAL_CANCEL_BUTTON} color="secondary" onClick={() => toggleModal()} variant="outlined">
                            Cancel
                        </Button>
                    </CloseOnEscape>
                </DialogActions>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    projects: state.projectReducer.projects,
    loading: state.flowReducer.cloneAllVarLoading,
    err: state.flowReducer.error,
    notClonedVars: state.flowReducer.notCloned,
});

const mapDispatchToProps = (dispatch) => {
    return {
        // // Variable
        showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        cloneAllVariables: (...args) => dispatch(FlowActions.cloneAllVariables(...args)),
        clearErr: () => dispatch(FlowActions.clearErr()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CloneAllVariables));
