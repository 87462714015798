import { ActionTypes } from '../constants';
import { AccountMiddleware } from '../middleware';
import ModalActions from './modalActions';
import { AccountUtils } from '../../utils';

class AccountActions {
    static clearError() {
        return { type: ActionTypes.CLEAR_ERROR };
    }

    static clearSuccess() {
        return { type: ActionTypes.CLEAR_SUCCESS };
    }

    static clearSingleAccount() {
        return { type: ActionTypes.CLEAR_SINGLE_ACCOUNT };
    }

    static getAccounts(accountId) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_ACCOUNTS });
            return AccountMiddleware.getAccounts()
                .then((result) => {
                    dispatch({
                        type: ActionTypes.GET_ACCOUNTS_SUCCESS,
                        payload: result.accounts,
                    });

                    if (accountId) {
                        const userAccount = AccountUtils.getAccount(result.accounts, accountId);
                        dispatch({
                            type: ActionTypes.GET_ACCOUNT_DETAIL_SUCCESS,
                            payload: userAccount,
                        });
                    }

                    return true;
                })
                .catch((error) => {
                    return dispatch({
                        type: ActionTypes.GET_ACCOUNTS_FAILURE,
                        payload: {
                            error,
                            message: 'Failed to fetch accounts',
                        },
                    });
                });
        };
    }





    static getAccountById(accountId) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_ACCOUNT_DETAIL });
            return AccountMiddleware.getAccountById(accountId)
                .then((result) => {
                    return dispatch({
                        type: ActionTypes.GET_ACCOUNT_DETAIL_SUCCESS,
                        payload: result.account,
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.GET_ACCOUNT_DETAIL_FAILURE,
                        payload: {
                            message: 'Failed to fetch account',
                        },
                    });
                });
        };
    }

    static getSamlConfig(accountId) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_SAML_CONFIG });
            return AccountMiddleware.getSamlConfig(accountId)
                .then((result) => {
                    return dispatch({
                        type: ActionTypes.GET_SAML_CONFIG_SUCCESS,
                        payload: result.samlConfig,
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.GET_SAML_CONFIG_FAILURE,
                        payload: {
                            message: 'Failed to fetch saml config',
                        },
                    });
                });
        };
    }

    static getMarket() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_MARKET });
            return AccountMiddleware.getMarket()
                .then((result) => {
                    return dispatch({
                        type: ActionTypes.GET_MARKET_SUCCESS,
                        payload: result.marketYamlData,
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.GET_MARKET_FAILURE,
                        payload: {
                            // message: 'Failed to get market',
                        },
                    });
                });
        };
    }

    static updateAccount(formData) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.UPDATE_ACCOUNT_DETAIL });
            return AccountMiddleware.updateAccount(formData)
                .then((result) => {
                    return dispatch({
                        type: ActionTypes.UPDATE_ACCOUNT_DETAIL_SUCCESS,
                        payload: result.account,
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.UPDATE_ACCOUNT_DETAIL_FAILURE,
                        payload: {
                            message: 'Failed to update account',
                        },
                    });
                });
        };
    }

    static updateSamlConfig(samalConfigData, accountId) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.UPDATE_SAML_CONFIG });
            return AccountMiddleware.updateSamlConfig(samalConfigData, accountId)
                .then(() => {
                    // dispatch()

                    dispatch(this.getSamlConfig(accountId));

                    dispatch(ModalActions.toggleSnackBar('saml config updated', '', true));
                    return dispatch({
                        type: ActionTypes.UPDATE_SAML_CONFIG_SUCESS,
                        message: 'saml config updated',
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.UPDATE_SAML_CONFIG_FAILURE,
                        payload: {
                            message: 'Failed to update saml config',
                        },
                    });
                });
        };
    }

    static uploadYamlFile(formData, isGlobal = false, onCompelete = () => {}, cb = () => {}) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.UPLOAD_YAML });
            return AccountMiddleware.uploadYamlFile(formData, isGlobal)
                .then(() => {
                    onCompelete();
                    cb();
                    return dispatch({
                        type: ActionTypes.UPLOAD_YAML_SUCCESS,
                    });
                })
                .catch((error) => {
                    cb();
                    return dispatch({
                        type: ActionTypes.UPLOAD_YAML_FAILURE,
                        payload: {
                            error,
                            message: 'Failed to upload file.',
                        },
                    });
                });
        };
    }

    static getAccountsPrefrences(id) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_ACCOUNTS_PREFENCES });
            return AccountMiddleware.GetAccountsPrefences(id)
                .then((result) => {
                    return dispatch({
                        type: ActionTypes.GET_ACCOUNTS_PREFENCES_SUCCESS,
                        payload: result,
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.GET_ACCOUNTS_PREFENCES_FAILED,
                    });
                });
        };
    }

    static getPermissions() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_ACCOUNT_PERMISSION });
            return AccountMiddleware.GetPermissions()
                .then((result) => {
                    return dispatch({
                        type: ActionTypes.GET_ACCOUNT_PERMISSION_SUCCESS,
                        payload: result,
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.GET_ACCOUNT_PERMISSION_FAILED,
                    });
                });
        };
    }

    static UpdateCaseColumns(id, data, callBack = () => {}) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.UPDATE_ACCOUNT_PREFENCES });
            return AccountMiddleware.UpateAccountPrefences(id, data)
                .then((result) => {
                    callBack(id);
                    return dispatch({
                        type: ActionTypes.UPDATE_ACCOUNT_PREFENCES_SUCCESS,
                        payload: result,
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.UPDATE_ACCOUNT_PREFENCES_FAILED,
                    });
                });
        };
    }

    // //////////////////////////////////////////////USING SAME TYPE FOR BOTH PREFENCES CASE`s///////////////////////////////////////////////////////
    static UpdateDashboardCards(id, data) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.UPDATE_DASHBOARDS });
            return AccountMiddleware.UpateAccountPrefencesDashBoard(id, data)
                .then((result) => {
                    dispatch({
                        type: ActionTypes.GET_ACCOUNTS_PREFENCES_SUCCESS,
                        payload: result,
                    });
                    return dispatch({
                        type: ActionTypes.UPDATE_DASHBOARDS_SUCCESS,
                        payload: result,
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.UPDATE_DASHBOARDS_FAILED,
                    });
                });
        };
    }

    static deleteBulkObjects(days, time, timespan) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.DELETE_BULK_OBJECTS });
            return AccountMiddleware.deleteBulkObjects(days)
                .then(() => {
                    return dispatch({
                        type: ActionTypes.DELETE_BULK_OBJECTS_SUCCESS,
                        payload: { msg: `screenshots/reports older than ${time} ${timespan} will be deleted.` },
                    });
                })
                .catch((err) => {
                    return dispatch({
                        type: ActionTypes.DELETE_BULK_OBJECTS_FAILURE,
                        payload: err,
                    });
                });
        };
    }

    static deleteBulkVideos() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.DELETE_BULK_VIDEOS });
            return AccountMiddleware.deleteBulkVideos()
                .then(() => {
                    return dispatch({
                        type: ActionTypes.DELETE_BULK_VIDEOS_SUCCESS,
                        payload: { msg: 'videos older than 15 days will be deleted.' },
                    });
                })
                .catch((err) => {
                    return dispatch({
                        type: ActionTypes.DELETE_BULK_VIDEOS_FAILURE,
                        payload: err.message,
                    });
                });
        };
    }

    static getEnvironmentConfig(accountId){
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_EXECUTION_ENVIRONMENT_CONFIG });
            return AccountMiddleware.getEnvironmentConfig(accountId)
                .then((result) => {
                    return dispatch({
                        type: ActionTypes.GET_EXECUTION_ENVIRONMENT_CONFIG_SUCCESS,
                        payload: result.environmentConfig,
                        //payload: result,
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.GET_EXECUTION_ENVIRONMENT_CONFIG_FAILURE,
                        payload: {
                            message: 'Failed to fetch saml config',
                        },
                    });
                });
        };
    }


    static getSauceDetails(){
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_SAUCE_DETAILS });
            return AccountMiddleware.getSauceDetails()
                .then((result) => {
                    return dispatch({
                        type: ActionTypes.GET_SAUCE_DETAILS_SUCCESS,
                        payload: result.sauceCredentials,
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.GET_SAUCE_DETAILS_FAILURE,
                        payload: {
                            message: 'Failed to fetch sauce details',
                        },
                    });
                });
        }; 
    }


    static updateSauceDetails(type,data){
        return (dispatch) => {
            dispatch({ type: ActionTypes.POST_SAUCE_DETAILS });
            return AccountMiddleware.updateSauceDetails(type,data)
                .then((result) => {
                    return dispatch({
                        type: ActionTypes.POST_SAUCE_DETAILS_SUCCESS,
                        payload: result.data,
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.POST_SAUCE_DETAILS_FAILURE,
                        payload: {
                            message: 'Failed to fetch sauce details',
                        },
                    });
                });
        }; 
    }

    static getsupportedEnvironments(){
        return (dispatch) => {
            dispatch({ type: ActionTypes.GET_SUPPORTED_ENVIRONMENT });
            return AccountMiddleware.getsupportedEnvironments()
                .then((result) => {
                    return dispatch({
                        type: ActionTypes.GET_SUPPORTED_ENVIRONMENT_SUCCESS,
                        payload: result.supportedEnvironments,
                        //payload: result,
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.GET_SUPPORTED_ENVIRONMENT_FAILURE,
                        payload: {
                            message: 'Failed to fetch saml config',
                        },
                    });
                });
        };
    }

    static postEnvironmentConfig(accountId){
        return (dispatch) => {
            dispatch({ type: ActionTypes.POST_EXECUTION_ENVIRONMENT_CONFIG });
            return AccountMiddleware.postEnvironmentConfig(accountId)
                .then((result) => {
                    return dispatch({
                        type: ActionTypes.POST_EXECUTION_ENVIRONMENT_CONFIG_SUCCESS,
                        //payload: result.environmentConfig,
                        payload: result,
                    });
                })
                .catch(() => {
                    return dispatch({
                        type: ActionTypes.POST_EXECUTION_ENVIRONMENT_CONFIG_FAILURE,
                        payload: {
                            message: 'Failed to fetch saml config',
                        },
                    });
                });
        };
    }
}

export default AccountActions;
