import React, { useEffect, useState, useRef } from 'react'
import NavigationPrompt from 'react-router-navigation-prompt';

// Importing material-ui components
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
// Importing Components
import ProjectDetailTopBar from '../../projectDetails/ProjectDetailTopBar_v2'
import DatasheetHeaderMidddle from '../../projectDetails/Data/Datasheet/DatasheetHeaderMidddle';
import DatasheetTable from '../../projectDetails/Data/Datasheet'
import Table from '../../../components/Table/Table';
import ConfirmNavigationModal from '../../modal/ConfirmNavigationModal';

// Importing utils
import { resizeAllCols } from '../../../utils/utils'
import { useSelector, useDispatch } from 'react-redux';
import { ProjectActions, ModalActions } from '../../../store/actions';
import singleFileCreationService from '../../../services/singleFileCreationService';
import { getFileNameWithoutExtension } from '../../../utils/StringUtils';

const useStyles = makeStyles({
    root: {
        width: 'calc(100% - 40px)',
        backgroundColor: '#ececec',
        margin: '0 20px',
    },
    tableContainer: {
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        '&::-webkit-scrollbar': {
            width: 15,
            height: 15
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(193,193,193)',
        }
    },
    container: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 270px)',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        '&::-webkit-scrollbar': {
            width: 15,
            height: 15
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(193,193,193)',
        }
    },
    errorContainer: {
        maxHeight: 'calc(100vh - 305px)',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        backgroundColor: '#fff',
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 10,
    },
    errorText: {
        fontSize: 20,
        color: '#78909c',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        '&>:first-child': {
            paddingLeft: '2px',
        },
    },
    textfieldClass: {
        '&>div': {
            padding: '2px 7px',
            '&>input': {
                padding: '8.5px 14px',
            },
            '&:hover': {
                boxShadow: '0 0 4px #5e5959 !important'
            }
        }
    },
    errorFieldClass:{
        '&>div': {
            border:'1px solid red',
        },
    },
    
    focused:{
        border: "1px solid red",
    },
    notchedOutline:{
        borderColor: '#b6b6b6 !important',
    },
    managerHeaderCell: {
        visibility: 'hidden',
        pointerEvents: 'none',
        cursor: 'default',
        marginTop: 5,
    },
    persistMoreIconOnClick: {
        visibility: 'visible',
        display: 'block',
        pointerEvents: 'all',
        cursor: 'pointer',
        marginTop: 5,
    },
    hideOption: {
        visibility: 'hidden',
        pointerEvents: 'none',
        cursor: 'default',
    },
    tableRowHover: {
        borderBottom: 'solid 0.5px rgb(151 151 151 / 69%)',
        '&:hover': {
            backgroundColor: '#ffffff !important',
            '& $managerHeaderCell': {
                visibility: 'visible',
                display: 'block',
                pointerEvents: 'all',
                cursor: 'pointer',
            },
            '& $barStyle': {
                pointerEvents: 'all',
                cursor: 'col-resize',
                visibility: 'visible',
                minHeight: 40,
                display: 'inline-block',
                borderRight: 'solid 0.5px rgb(151 151 151 / 69%)',
                width: 4,
                position: 'absolute',
                zIndex: 1,
                right: '0',
                top: '7',
            }
        }
    },
    width100: {
        width: '100%'
    },
    cellContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    textStyle: {
        wordBreak: 'break-word',
        marginLeft: 20,
        display: 'block',
        fontSize: 12,
    },
    barStyle: {
        visibility: 'hidden',
        pointerEvents: 'none',
        cursor: 'default',
    },
    barIconStyle: {
        color: '#9B9B9B',
        position: 'absolute',
        top: '-9px',
        right: '-4px'
    },
    circularProgressStyle: {
        margin: '100px 50% 0px',
        color: '#4885ed'
    },
});

const Datasheet = (props) => {

    const classes = useStyles();
    const { history, match: { params: { projectId, testDataId } } } = props;
    let colsWidth = {}
    const minColumnWidth = 250;  // Minumum width is 250px. Which can be update based on UX
    const [availWidth, setAvailWidth] = useState(null)
    const [sheetData, setSheetData] = useState([])
    const [prevSheetData, setPrevSheetData] = useState([])
    const [updateSheet, setUpdateSheet] = useState(false)
    const [rowNo, setRowNo] = useState(null)
    const [columnNo, setColumnNo] = useState(null)
    const [isColumnEmpty, setIsColumnEmpty] = useState(false)
    const [isDupColNamesFound, setIsDupColNamesFound] = useState(false)
    const [hoverColumn, setHoverColumn] = useState(null)
    const [isValidTestDataId, setIsValidTestDataId] = useState('')
    const [isDataFileExistInProject, setDataFileExistInProject] = useState(true)
    const [testDataDetails, setTestDataDetails] = useState({});
    const dispatch = useDispatch();
    const ref = useRef(null)

    const useRedux = (state) => {
        return {
            project: state.projectReducer.selectedProject,
        };
    };
    const { project } = useSelector(useRedux);

    const dispatchActions = () => {
        return {
            getProjectDetails: (projectId) => dispatch(ProjectActions.getProjectDetails(projectId)),
            getTestData: (projectId) => dispatch(ProjectActions.getTestData(projectId)),
            previewTestData: (testDataId) => dispatch(ProjectActions.previewTestData(testDataId)),
            handleUpdateData: (...args) => dispatch(ProjectActions.handleUpdateData(...args)),
            showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
        };
    };
    const actions = dispatchActions();

    useEffect(() => {
        (async () => {
            let obj = {};
            const { getProjectDetails, getTestData, previewTestData, showSnackBar } = actions;

            getProjectDetails(projectId) // Don't need to wait for api response
            const _testDataCaller = getTestData(projectId)
            const _previewDataCaller = previewTestData(testDataId);

            // To minimize network response time of independent apis
            const testData = await _testDataCaller;
            const previewData = await _previewDataCaller;

            if (previewData?.payload?.error) {
                setIsValidTestDataId(previewData?.payload?.error?.message)
                showSnackBar(previewData?.payload?.error?.response ? previewData?.payload?.error?.response?.data : previewData?.payload.message)
                return null
            }
            if (Array.isArray(testData?.payload)) {
                const _testData = testData?.payload?.filter((elem) => elem?.testDataId === parseInt(testDataId));
                if(!_testData.length && !previewData?.payload?.error){
                    setDataFileExistInProject(false)
                    return null
                }
                obj = {
                    testDataFileName: _testData[0]?.testDataFileName,
                    testDataName: _testData[0]?.testDataName
                }
            }
            //PreviewData contains sheetData 
            if (previewData?.payload?.data) {
                const data = JSON.parse(previewData?.payload?.data)
                const order = JSON.parse(previewData?.payload?.order)
                setSheetData(constructDataSet(data, order))
                setPrevSheetData(constructDataSet(data, order))
                setTestDataDetails(obj)
            }
            setAvailWidth(ref?.current?.scrollWidth)
        })()
    }, [])


    // Use separately because it needs to be run on column resize
    useEffect(() => {
        resizeAllCols(colsWidth);
    }, [colsWidth])

    const constructDataSet = (data, order) => {
        let _order = {};
        order.forEach((elem) => {
            _order = {
                ..._order,
                [elem?.trim()]: data[elem].map((valElem) => valElem?.trim())
            }
        })

        // Make 2D aray for rendering
        const dataSet = []
        const keys = Object.keys(_order).map(columnName => {
            return {
                value: columnName,
                isPasswordColumn: columnName.toLowerCase().includes("password") ? true : false 
            }
        })
        const values = Object.values(_order)
        dataSet.push(keys)
        for (let i = 0; i < values[0]?.length; i++) {
            let tempArr = []
            for (let j = 0; j < values.length; j++) {
                tempArr.push({value: values[j][i], isPasswordColumn: keys.some((columnName, ind) => columnName.value.toLowerCase().includes("password") && ind === j) && Boolean(values[j][i].length)})
            }
            dataSet.push(tempArr)
        }
        return dataSet;
    }

    const handleDownload = () => {
        let fileName = 'datasheet';
        let isPasswordColumnFound = false;
        if(testDataDetails && testDataDetails.testDataFileName) {
            fileName = getFileNameWithoutExtension(testDataDetails.testDataFileName)
        }
        if(sheetData[0].some(cell => cell.value.toLowerCase().includes("password"))){
            isPasswordColumnFound = true;
        }
        const testDataName = `${fileName}.xlsx`;
        singleFileCreationService.downloadArtifact({ testDataId, testDataName, testDataFileName: '', isPasswordColumnFound });
    };
    
    const handleSave = () => {
        const { handleUpdateData } = actions;
        let _sheetData = JSON.parse(JSON.stringify(sheetData))
        _sheetData = _sheetData.map(row => row.map(cell => {
            return {
                value: cell.value?.trim(), isPasswordColumn: cell.isPasswordColumn
            }
        }))
        const headRow = _sheetData.shift();       
        let dataSet = []
        let isDuplicateColumnNameFound = false;
        for(let i = 0; i < headRow.length; i++){
            for(let j = 0; j < headRow.length; j++){
                if(i !== j && headRow[i].value.toLowerCase() === headRow[j].value.toLowerCase()){
                    isDuplicateColumnNameFound = true;
                    break;
                    }            
            }
        }
        if(headRow.some((elem) => elem.value === '')){
            setIsColumnEmpty(true)
            return null
        }
        if(isDuplicateColumnNameFound){
            setIsDupColNamesFound(true)
            return null
        }
        for (let i = 0; i < _sheetData[0].length; i++) {
            let tempArr = []
            for (let j = 0; j < _sheetData.length; j++) {
                tempArr.push(_sheetData[j][i])
            }
            dataSet.push(tempArr)
        }
        const updatedDataSet = headRow.map((elem, i) => {
            return {
                key: elem.value,
                values: dataSet[i].map(cell => cell.value)
            }
        })
        let updatedSheetData = [..._sheetData]
        updatedSheetData.unshift(headRow)
        updatedSheetData = updatedSheetData.map((row, rowInd) => row.map((cell, cellInd) => {
            cell.isPasswordColumn = headRow.some((columnName, ind) => columnName.value.toLowerCase().includes("password") && cellInd === ind) && Boolean(cell.value.length)
            return cell
            })
        )

        setSheetData(updatedSheetData)
        setIsColumnEmpty(false)
        setIsDupColNamesFound(false)
        setUpdateSheet(false)
        setPrevSheetData(updatedSheetData)
        handleUpdateData(projectId, testDataId, updatedDataSet)

    };

    const handleCancel = () => {
        setIsColumnEmpty(false)
        setIsDupColNamesFound(false)
        setUpdateSheet(false)
        setSheetData(prevSheetData)
    };

    const updateSheetData = (e, column, row) => {
        let _sheetData = JSON.parse(JSON.stringify(sheetData))
        _sheetData[row][column].value = e.target.value
        if(!_sheetData[row][column].value.length){
            _sheetData[row][column].isPasswordColumn = false
        }
        setSheetData(_sheetData)
    }

    const tableActions = (action, index, label) => {
        let _sheetData = JSON.parse(JSON.stringify(sheetData));
        if (action === "row") {
            _sheetData.splice(index, 0, [..._sheetData[0]].fill({value: '', isPasswordColumn: false}));
        }
        else if (action === 'deleteRow') {
            _sheetData.splice(index, 1);
        } else if (action === 'column') {
            for (let ind = 0; ind < _sheetData.length; ind++) {
                _sheetData[ind].splice(index, 0, {value: '', isPasswordColumn: false})
            }
            setAvailWidth(prev => prev + minColumnWidth)
        } else if (action === 'deleteCol') {
            const emptyColumns = _sheetData[0].filter((elem) => elem.value === '')
            if(emptyColumns.length === 1 && label === ''){
                setIsColumnEmpty(false)
            }
            for (let ind = 0; ind < _sheetData.length; ind++) {
                _sheetData[ind].splice(index, 1)
            }
            if(window.screen.width < availWidth){
                setAvailWidth(prev => prev - minColumnWidth)
            }
        }
        setSheetData(_sheetData)
        setHoverColumn(false)
        setRowNo(null)
        setColumnNo(null)
    }

    const calcColumnWidth = (availabaleWidth, totalColumns) => {
        const cellWidth = availabaleWidth / totalColumns;
        if (cellWidth > minColumnWidth)
            return cellWidth;
        return minColumnWidth;
    }

    const handleHoverOnTable = (row, col) => {
        if (row === 0) {
            setHoverColumn(true)
        } else {
            setHoverColumn(false)
        }
        setRowNo(row)
        setColumnNo(col)
    }

    const renderPopperItems = (menu, rowInd, colInd, label) => {
        const items = {
            rows: [
                { action: () => tableActions('row', rowInd), name: ' Insert 1 row above' },
                { action: () => tableActions('row', rowInd + 1), name: ' Insert 1 row below' },
                { action: () => tableActions('deleteRow', rowInd), name: ' Delete row' },
            ],
            columns: [
                { action: () => tableActions('column', colInd), name: ' Insert 1 column left' },
                { action: () => tableActions('column', colInd + 1), name: ' Insert 1 column right' },
                { action: () => tableActions('deleteCol', colInd, label), name: ' Delete column' },
            ]
        }
        return items[menu]
    }

    return (
        <div className={classes.root}>

            {/* This is Top bar header */}
            <ProjectDetailTopBar history={history} project={project} callFromDatasheet callFromStepEditor />
            <DatasheetHeaderMidddle
                sheetData={sheetData}
                prevSheetData={prevSheetData}
                isColumnEmpty={isColumnEmpty}
                isDupColNamesFound={isDupColNamesFound}
                mainTitle={testDataDetails?.testDataName ? testDataDetails?.testDataName : testDataDetails?.testDataFileName}
                updateSheet={updateSheet}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleDownload={handleDownload}
                action={(isEditMode) => {
                    setUpdateSheet(isEditMode)
                }}
            />

            {/*  Main container having data cells */}

            {
            !isDataFileExistInProject || isValidTestDataId ?
            <div className={classes.errorContainer}>
                {!isDataFileExistInProject && <Typography className={classes.errorText}>Data file does not exist in this project</Typography>}
                {isValidTestDataId && <Typography className={classes.errorText}>Data file does not exist</Typography>}
            </div>
            :
            !sheetData.length ?
                    <div ref={ref}>
                        <CircularProgress className={classes.circularProgressStyle} size={60} />
                    </div>
                    :
                    <div className={classes.container}>
                        <Table dataTypePrefix="d_" removeOverflow={true} style={{ width: `${availWidth}px` }}>
                            <div ref={ref} datatypeprefix="d_" data-type={`d_TableBody`} className={classes.tableContainer} style={{ width: `${availWidth}px` }}>
                                {sheetData.map((rowData, index) => (
                                    <DatasheetTable
                                        key={index}
                                        rowData={rowData}
                                        rowIndex={index}
                                        isColumnEmpty={isColumnEmpty}
                                        updateSheet={updateSheet}
                                        updateDataSheet={(...args) => updateSheetData(...args)}
                                        elementPrefix={'d_'}
                                        display={'flex'}
                                        rowNo={rowNo}
                                        setRowNo={(row) => setRowNo(row)}
                                        columnNo={columnNo}
                                        setColumnNo={(column) => setColumnNo(column)}
                                        hoverColumn={hoverColumn}
                                        setHoverColumn={(column) => setHoverColumn(column)}
                                        handleHoverOnTable={(...args) => handleHoverOnTable(...args)}
                                        renderPopperItems={renderPopperItems}
                                        classes={classes}
                                        availWidth={availWidth}
                                        width={calcColumnWidth(availWidth, sheetData[0]?.length) + "px"}
                                        colsWidth={colsWidth}
                                        tableHeadStyle={{
                                            alignItems: 'center',
                                            borderBottom: 'solid 0.5px rgb(151 151 151 / 69%)',
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 1,
                                        }}
                                    />
                                ))}
                            </div>
                        </Table>
                    </div>
            }

            {/*  Prompt to detect unsaved changes */}
            <NavigationPrompt when={JSON.stringify(sheetData.map(row => row.map(cell => cell.value))) !== JSON.stringify(prevSheetData.map(row => row.map(cell => cell.value)))}>
                {({ onConfirm, onCancel }) => (
                    <ConfirmNavigationModal
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                        msg="Your recent changes to the datasheet are not saved. Are you sure you want to leave before saving?"
                        leaveBtn="Leave this page"
                        stayBtn="Stay on the page"
                    />
                )}
            </NavigationPrompt>
        </div>
    )
}

export default Datasheet;