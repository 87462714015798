import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import NotificationList from './NotificationList';
import { ProjectActions } from '../../store/actions/index';
import { BLACK_FONT } from '../../common/cssConstants';

const styles = createStyles({
    menu: {
        margin: '30px 0px 0px -45px',
        position: 'fixed',
        '&>:nth-child(2)': {
            maxHeight: 'calc(100vh - 65px)',
            overflow: 'hidden',
        },
        '&>div>ul': {
            maxHeight: 'calc(100vh - 65px)',
            overflow: 'hidden',
        },
    },
    paper: {
        maxHeight: 'unset',
    },
    divider: {
        outline: 'none',
        marginLeft: 15,
        marginRight: 15,
    },
    menuItem: {
        minWidth: 300,
        display: 'flex',
        '&:hover': {
            backgroundColor: '#e9ebee',
        },
        outline: 'none',
        height: 'auto',
        padding: '5px 16px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    markAllMenu: {
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: '8px',
        backgroundColor: 'white',
        height: 24,
        '&>p': {
            width: '100%',
            color: '#003ca3',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '14px',
        },
        '&>p:hover': {
            textDecoration: 'underline',
        },
        '&:hover': {
            backgroundColor: 'white',
        },
        borderBottom: 'none',
    },
    clearMenu: {
        textAlign: 'right',
        display: 'inline-block',
        paddingLeft: '16px',
        paddingRight: '16px',
        width: '100%',
        cursor: 'default',
        backgroundColor: 'white',
        borderBottom: 'none',
        height: 'auto',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    menuBtns: {
        margin: '0',
        color: '#003ca3',
        fontSize: '12px',
        cursor: 'pointer',
        width: 'auto',
        fontWeight: 'bold',
        minWidth: '0',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    markAsReadMenu: {
        textAlign: 'right',
        display: 'inline-block',
        paddingLeft: '16px',
        paddingRight: '16px',
        width: '100%',
        cursor: 'default',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    status: {
        pointerEvents: 'none',
        fontSize: 14,
        padding: '0px 0px 5px 15px',
        borderBottom: '1px solid #e1e1e1',
        outline: 'none',
    },
    controllerDiv: {
        width: '100%',
        minWidth: 60,
        // maxHeight: 190,
        overflowY: 'auto',
        outline: 'none',
        maxHeight: 'calc(100vh - 145px)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    container: {
        marginBottom: 3,
        minWidth: 300,
        minHeight: 40,
        textAlign: 'center',
    },
    emptyNotification: {
        width: '100%',
        height: '100%',
        lineHeight: 3,
        fontWeight: 600,
        color: '#9a9a9a',
    },
    notificationHeading: {
        color: BLACK_FONT,
        fontWeight: 'bold',
        display: 'block',
        float: 'left',
    },
    notificationHeadingBtns: {
        display: 'block',
        float: 'right',
    },
    timeStatus: {
        backgroundColor: 'white',
        fontWeight: 'bold',
        color: BLACK_FONT,
        fontSize: '12px',
        borderTop: '1.5px solid rgba(0, 0, 0, 0.12)',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
});

class UserNotification extends React.Component {
    goToSmartMaintenance = () => {
        this.handleMenuItemClick();
        this.props.history.push('/analyze/smartmaintenance');
    };

    handleMenuItemClick = () => {
        this.props.handleMenuClose();
    };

    handleMenuClose = () => {
        this.props.handleMenuClose();
    };

    handleCloseNotificaton = (notificationId) => {
        const { accountId } = this.props.user;
        this.props.doMarkNotificationDeleted(notificationId, accountId);
    };

    markReadNotify = (notificationId) => {
        const { accountId } = this.props.user;
        this.props.markNotificationRead([notificationId], accountId);
        this.goToSmartMaintenance();
    };

    // clearAll = () => {
    //     const { getNonDeletedNotification, clearAllNotificaton } = this.props;
    //     const {data}=getNonDeletedNotification;
    //     if(data && data.length){
    //         let ids = [];
    //         data.map((notifyData) => ids.push(notifyData.notificationId));
    //         if(ids.length) {
    //             clearAllNotificaton(ids);
    //         }
    //     }
    // };

    markReadAll = () => {
        const { getNonDeletedNotification, markNotificationRead } = this.props;
        const { data } = getNonDeletedNotification;
        if (data && data.length) {
            const { accountId } = this.props.user;
            const ids = [];
            data.map((notifyData) => !notifyData.isRead && ids.push(notifyData.notificationId));
            if (ids.length) {
                markNotificationRead(ids, accountId);
            }
        }
    };

    expandNotify = () => {
        // Todo: Make limitation number of notification which able to be shown at one time before get coded
        // Todo: If click "See All" button, this function should redirect to another window. (UX Team working on it)
        this.goToSmartMaintenance();
    };

    render() {
        const { anchorEl, classes, getNonDeletedNotification } = this.props;
        const { data } = getNonDeletedNotification;
        const newData = [];
        const earlierData = [];
        if (data) {
            data.forEach((notifyData) => {
                if (notifyData.isRead) {
                    earlierData.push(notifyData);
                } else {
                    newData.push(notifyData);
                }
            });
        }
        return (
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleMenuClose}
                className={classes.menu}
                PopoverClasses={{
                    paper: classes.paper,
                }}
            >
                <Typography className={[classes.menuItem, classes.clearMenu].join(' ')}>
                    <span className={classes.notificationHeading}>Notifications</span>
                    {data && data.length ? (
                        <span className={classes.notificationHeadingBtns}>
                            <span
                                aria-hidden
                                className={classes.menuBtns}
                                onClick={() => {
                                    this.markReadAll();
                                }}
                            >
                                Mark All as Read
                            </span>
                            {/* <span>&nbsp; | &nbsp;</span>
                        <span className={classes.menuBtns} onClick={() => {this.clearAll()}}>Clear All</span> */}
                        </span>
                    ) : (
                        ''
                    )}
                </Typography>
                <div className={classes.controllerDiv}>
                    {data && data.length ? (
                        <div>
                            {newData && newData.length ? (
                                <Typography className={[classes.menuItem, classes.timeStatus].join(' ')}>
                                    <span>NEW</span>
                                </Typography>
                            ) : null}
                            {newData && newData.length
                                ? newData.map((notifyData) => {
                                      return (
                                          <MenuItem className={classes.menuItem} key={notifyData.notificationId}>
                                              <NotificationList
                                                  notifyData={notifyData}
                                                  markReadNotify={(e) => this.markReadNotify(e)}
                                                  handleCloseNotificaton={(e) => this.handleCloseNotificaton(e)}
                                              />
                                          </MenuItem>
                                      );
                                  })
                                : null}
                            {earlierData && earlierData.length ? (
                                <Typography className={[classes.menuItem, classes.timeStatus].join(' ')}>
                                    <span>EARLIER</span>
                                </Typography>
                            ) : null}
                            {earlierData && earlierData.length
                                ? earlierData.map((notifyData) => {
                                      return (
                                          <MenuItem className={classes.menuItem} key={notifyData.notificationId}>
                                              <NotificationList
                                                  notifyData={notifyData}
                                                  markReadNotify={(e) => this.markReadNotify(e)}
                                                  handleCloseNotificaton={(e) => this.handleCloseNotificaton(e)}
                                              />
                                          </MenuItem>
                                      );
                                  })
                                : null}
                        </div>
                    ) : (
                        <div className={classes.container}>
                            <Typography className={classes.emptyNotification}>You don't have any notification</Typography>
                        </div>
                    )}
                </div>
                {data && data.length ? (
                    <MenuItem
                        className={[classes.menuItem, classes.markAllMenu].join(' ')}
                        key={2}
                        onClick={() => {
                            this.expandNotify();
                        }}
                    >
                        <p>See All</p>
                    </MenuItem>
                ) : (
                    ''
                )}
            </Menu>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        getNonDeletedNotification: state.projectReducer.nonDeletedNotify,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        doMarkNotificationDeleted: (notificationId, accountId) => dispatch(ProjectActions.getMarkNotificationDeleted(notificationId, accountId)),
        markNotificationRead: (notificationIds, accountId) => dispatch(ProjectActions.postMarkReadNotification(notificationIds, accountId)),
        getAllNotification: (accountId) => dispatch(ProjectActions.getNonDeletedNotification(accountId)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserNotification));
