import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProjectItemSummary from '../../../components/ProjectItemSummary/ProjectItemSummary';
import ScriptTable from './ScriptTable';
import { GeneralActions, ProjectActions } from '../../../store/actions';

const styles = () => ({
    expansionPanelSummary: {
        minHeight: '0px !important',
        '&>:first-child': {
            cursor: 'default !important',
            margin: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
    },
    expandIcon: {
        right: '10px',
    },
    expansionPanelDetails: {
        padding: '0 0px',
    },
});

class ScriptList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: 'scriptList',
            showLoader: this.props.showLoader,
        };
    }

    UNSAFE_componentWillReceiveProps({ showLoader }) {
        if (showLoader !== this.setState.showLoader) {
            this.setState({ showLoader });
        }
    }

    handleChange = (panel) => () => {
        this.setState((state) => ({
            expanded: state.expanded !== panel ? panel : false,
        }));
    };

    render() {
        const { showLoader } = this.state;
        const {
            testCases,
            appUrl,
            badgeName,
            date,
            downloadGradle,
            env,
            executions,
            getExecutionVideo,
            deleteTestScript,
            project,
            query,
            queryRemove,
            previewTestCase,
            previewTestData,
            previewTestScript,
            showSnackBar,
            testData,
            user,
            history,
        } = this.props;
        // Flatten the test cases + scripts
        const testScripts = [];
        const _testCases = JSON.parse(JSON.stringify(testCases));
        _testCases.forEach((testCase) => {
            if (testCase.testScripts && testCase.testScripts.length > 0) {
                testCase.testScripts.forEach((testScript) => {
                    const execution = executions && executions.find((_execution) => _execution.executionId === testScript.executionId);
                    const executionVideoUrl = execution ? execution.executionVideoUrl : '';
                    testScripts.push({
                        userId: testScript.userId,
                        testScriptId: testScript.testScriptid,
                        systemId: testScript.discoveryId, // this is project id not script id
                        caseName: testCase.testCaseName,
                        caseLastRun: testScript.lastExecutedTime,
                        // caseLastRun: testCase.lastExecutedTime,
                        scriptName: testScript.testScriptName,
                        scriptGeneratedAt: testScript.generationTime,
                        scriptURL: testScript.testScriptDownloadLink,
                        executionId: testScript.executionId,
                        executionVideoUrl,
                        projectId: testScript.projectId,
                        isReady: testScript.testScriptGenerationStatus === 'SUCCESS',
                        /* "0": "INPROGRESS", "1": "SUCCESS", "2": "FAILED", */
                        isExecInprogress: `${testScript.lastExecStatus}` === '0',
                        testCase,
                    });
                });
            }
        });
        testData.forEach((testDatum, i) => {
            let testCaseIds = '';
            testDatum.associatedTestCases.forEach((assTestCase) => {
                testCaseIds += assTestCase.id;
            });
            testData[i].testCases = testCaseIds;
        });
        return (
            <div>
                <div style={{ position: 'relative', height: 15, backgroundColor: '#fff' }}>
                    <ProjectItemSummary
                        appName={`${project.projectName} App`}
                        appUrl={appUrl}
                        badgeName={badgeName}
                        date={date}
                        env={env}
                        testCases={testCases}
                        projectSystemId={project.projectId}
                    />
                </div>
                <ScriptTable
                    date={date}
                    testData={testData}
                    testScripts={testScripts}
                    handleDeleteTestScript={deleteTestScript}
                    query={query}
                    queryRemove={queryRemove}
                    previewTestCase={previewTestCase}
                    previewTestData={previewTestData}
                    previewTestScript={previewTestScript}
                    getExecutionVideo={getExecutionVideo}
                    downloadGradle={downloadGradle}
                    showLoader={showLoader}
                    testCases={testCases}
                    showSnackBar={showSnackBar}
                    aiqExecution={project.aiqExecution}
                    user={user}
                    history={history}
                />
            </div>
        );
    }
}

ScriptList.propTypes = {
    //     appUrl: PropTypes.string.isRequired,
    //     badgeName: PropTypes.string.isRequired,
    classes: PropTypes.shape({}).isRequired,
    //     env: PropTypes.shape({}).isRequired,
    //     date: PropTypes.instanceOf(Date).isRequired,
    query: PropTypes.string,
    queryRemove: PropTypes.func.isRequired,
    testData: PropTypes.array.isRequired,
    deleteTestScript: PropTypes.func.isRequired,
    downloadGradle: PropTypes.func.isRequired,
    showSnackBar: PropTypes.func.isRequired,
};

ScriptList.defaultProps = {
    query: '',
};

const mapStateToProps = (state) => {
    return {
        testData: state.projectReducer.testData,
        testCases: state.projectReducer.testCases,
        executions: state.projectReducer.executions,
        user: state.authReducer.user,
        // general reducer
        query: state.generalReducer.queryValue,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        deleteTestScript: (...args) => dispatch(ProjectActions.deleteTestScript(...args)),
        getExecutionVideo: (execId, cb) => dispatch(ProjectActions.getExecutionVideo(execId, cb)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScriptList));
