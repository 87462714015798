import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import CloseOnEscape from 'react-close-on-escape';
import Typography from '@material-ui/core/Typography';
import { DIALOG_ROOT, MODAL_HEADER_CLOSE_ICON_CONT, MODAL_HEADER_CLOSE_ICON, MODAL_FOOTER, MODAL_SUBMIT_BUTTON, MODAL_CANCEL_BUTTON, MODAL_HEADER_TITLE, BLACK_FONT } from '../../../common/cssConstants';



const styles = (theme) => ({
    dialogTitleStyle: MODAL_HEADER_TITLE,
    dialog: {
        textAlign: 'center',
        backgroundColor: '#e9effd',
        borderBottom: '1px solid #dcdcdc',
    },
    keyHeading: {
        width: '100px',
        float: 'left',
        fontSize: '12px',
    },
    formControl: {
        width: '100%',
        flexDirection: 'row',
    },
    mainHeading: {
        width: '100%',
        float: 'left',
        fontSize: 12,
        color: BLACK_FONT,
        marginBottom: 20,
    },
    dialogModal: {
        "&>div": {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    dataName: {
        width: 92,
        float: 'left',
        fontSize: 12,
    },
    dataValue: {
        float: 'left',
        fontSize: 12,
        fontWeight: 'bold',
        maxWidth: 'calc(100% - 100px)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    dialogContent: {
        overflow: 'hidden',
        width: 370,
        padding: '0 !important',
        marginLeft: '30px',
        marginRight: '30px',
        [theme.breakpoints.only('xs')]: {
            width: `calc(100vw - 100px)`,
        },
    },
    deleteButtonDanger: {
        backgroundColor: '#fff !important',
        color: '#ff0000 !important',
        border: '1px solid #ff0000 !important',
        '&:hover': {
            backgroundColor: '#ff0000 !important',
            color: '#fff !important',
        }
    },
    select: {
       // borderRadius: 3,
         width: '40%',
       //height: '100%',
        '&>div': {
            borderBottom: 'none !important',
            transition: 'none !important',
        },
        '& input': {
            //textAlign: 'center',
            height: '100%',
        },
    },
    selectSauce:{
        paddingLeft:25,

    },
    sauceHeading:{
        paddingLeft:'4px',
        color: '#000000',
        overflow: 'hidden',
        fontSize: '12px',
        fontWeight: '500',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    }
});

class ConfirmAssignModal extends Component {
    state = {
        isDisabled: false
    }
    
    render() {
        const { classes,
            handleClose,
            message,
            title,
            confirmButtonText,
        } = this.props;

        return (
            <div>
                <Dialog
                    disableRestoreFocus
                    open={true}
                    aria-labelledby="form-dialog-title"
                    className={classes.dialogModal}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            {title}
                        </DialogTitle>
                        <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                            <Close style={MODAL_HEADER_CLOSE_ICON} onClick={() => handleClose(false)} aria-label="closeIcon" id="closeIcon"/>
                        </div>
                    </div>
                    <DialogContent className={classes.dialogContent} >
                        <Typography
                            className={classes.mainHeading}
                        >
                            {message}
                        </Typography>
                    </DialogContent>
                    <DialogActions style={MODAL_FOOTER}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => { this.setState({ isDisabled: true }); handleClose(true) }}
                            style={MODAL_SUBMIT_BUTTON}
                        >
                            {confirmButtonText}
                        </Button>
                        <CloseOnEscape onEscape={() => handleClose(false)}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleClose(false)}
                                style={MODAL_CANCEL_BUTTON}
                            >
                                Cancel
                            </Button>
                        </CloseOnEscape>
                    </DialogActions>

                </Dialog>
            </div >
        )
    }

}

export default withStyles(styles)(ConfirmAssignModal);