import config from '../config';

export const AUTONOMIQ_TOKEN = 'aut_token';
export const AUTONOMIQ_ROLE = 'aut_role';
export const AUTONOMIQ_USR = 'aut_usr';
export const AUTONOMIQ_ACNT = 'aut_account';
export const AUTONOMIQ_ID = 'aut_id';
export const AUTONOMIQ_REMEMBER = 'aut_remember';
export const AUTONOMIQ_WEBSITE = config.website;
export const AUTONOMIQ_COPYRIGHT = ' All Rights Reserved';
export const AUTONOMIQ_YEAR = '©2020 ';

export const AUTONOMIQ_VERSION = `v${config.releaseVersion}`;

export const DATE_0001_YEAR = new Date(-62135526492000); // 0001-01-02 -> YYYY-MM-DD
export const DATE_9999_YEAR = new Date(253402196400000); // 9999-12-31 -> YYYY-MM-DD
export const DATE_2001_YEAR = new Date(978462000000); // 2001-01-03 -> YYYY-MM-DD
export const DATE_2099_YEAR = new Date(4102167600000); // 2099-12-29 -> YYYY-MM-DD
export const DATE_2038_YEAR = new Date(2147454000000); // 2038-01-19 -> YYYY-MM-DD

// TODO: Move these?
// Dashboard Filters UI
export const FILTER_VALUES = ['LAST 1W', '1M', '6M', '2Y'];

// Dashboard Filters Types
export const FILTER_VALUE_MAP = {
    'LAST 1W': 'week',
    '1M': 'month',
    '6M': 'sixmonth',
    '2Y': 'twoyear',
};

// Dashboard Filters Data Keys
export const FILTER_DATA_MAP = {
    'LAST 1W': 'weekReports',
    '1M': 'monthReports',
    '6M': 'sixMonthReports',
    '2Y': 'yearReports',
};

// Service requests not needing authorization header
export const IGNORE_AUTH_HEADER_SERVICE_REQUESTS = [
    '/v1/auth',
    '/auth/v1/confirm',
    '/auth/v1/forgotpassword',
    '/auth/v1/verifytoken',
    '/auth/v1/resetpassword',
];

export const USER_NAME = 'appuser';

export const GOTO_STEP_EDITOR = 'Go to Step Editor';

// New Step
export const NEW_MOCK_STEP = {
    id: '',
    data: '',
    instr: '',
    instrNum: '',
    expectedResults: '',
    xpath: '',
    status: '5',
    message: '',
    screenshotNo: '',
    screenshotSmallPaths: [],
    screenshotPaths: [],
    hasChild: false,
    columnName: '',
    isNew: true,
};

export const SUITE_CONDITIONS = [
    {
        id: 1,
        value: 'always',
    },
    {
        id: 2,
        value: 'all success',
    },
    {
        id: 3,
        value: 'all fail',
    },
    {
        id: 4,
        value: 'partial',
    },
];

export const DELAY_UNIT = [
    {
        id: 1,
        value: 'minutes',
    },
    {
        id: 2,
        value: 'hours',
    },
    {
        id: 3,
        value: 'days',
    },
    {
        id: 4,
        value: 'weeks',
    },
];

export const defaultStartDate = new Date(DATE_2001_YEAR.setHours(0, 0, 0, 0)).getTime();
export const defaultEndDate = new Date(DATE_2099_YEAR.setHours(23, 59, 0, 0)).getTime();
export const defaultAccountEndDate = new Date(DATE_2038_YEAR.setHours(23, 59, 0, 0)).getTime();

export const DATA_FILE_TYPE = {
    arti: 'arti',
    audio: 'audio',
    data: 'data',
};

export const DEV_HOSTNAMES = ["localhost","qa1.autonomiq.ai","qa2.autonomiq.ai","platform.autonomiq.ai"]


export const SEGMENT_EVENT_PREFIX = "AIQ";

export const MAIN_NAVIGATION_EVENT_PREFIX = "Main Nav";

export const USER_SETTINGS_EVENT_PREFIX = "User Settings";

export const SEGMENT_EVENT = {
    //Test Actions

    TEST_CASE_EVENT : {

        TEST_CASE_CREATE:`${SEGMENT_EVENT_PREFIX} Create Test Write`,
        TEST_CASE_GENERATED:`${SEGMENT_EVENT_PREFIX} Test Case Generate`,
        TEST_CASE_EXECUTED:`${SEGMENT_EVENT_PREFIX} Test Case Execute`,
        TEST_CASE_CLONED: `${SEGMENT_EVENT_PREFIX} Test Case Clone`,
        TEST_CASE_DELETED : `${SEGMENT_EVENT_PREFIX} Test Case Delete`,
        TEST_CASE_UPDATED : `${SEGMENT_EVENT_PREFIX} Test Case Update`,
        TEST_CASE_NAME_DESCRIPTION_UPDATED : `${SEGMENT_EVENT_PREFIX} Test Case Name Description Update`,
        TEST_CASE_DISABLED : `${SEGMENT_EVENT_PREFIX} Test Case Disable`,
        TEST_CASE_ENABLED : `${SEGMENT_EVENT_PREFIX} Test Case Enable`,
        TEST_CASE_PREVIEWED : `${SEGMENT_EVENT_PREFIX} Test Case Preview`,
        TEST_CASE_PREVIEW_DOWNLOADED : `${SEGMENT_EVENT_PREFIX} Test Case Preview Download`,
        TEST_DATA_PREVIEWED:`${SEGMENT_EVENT_PREFIX} Test Data - Preview`,
        TEST_DATA_PREVIEW_DOWNLOADED : `${SEGMENT_EVENT_PREFIX} Test Data Preview Download`,
        TEST_SCRIPT_PREVIEWED:`${SEGMENT_EVENT_PREFIX} Test Script - Preview`,
        TEST_SCRIPT_PREVIEW_DOWNLOADED: `${SEGMENT_EVENT_PREFIX} Test Script Preview Download`,
        TEST_CASE_GENERATION_REPORT_DOWNLOADED: `${SEGMENT_EVENT_PREFIX} Test Case Generation Report Download`,
    },

    TEST_CASE_EXECUTION_INITIATED : `${SEGMENT_EVENT_PREFIX} Test Case Execute Initiate`,
    NOTIFICATIONS_VIEWED : `${SEGMENT_EVENT_PREFIX} Notifications Click`,
    PROJECT_CREATED : `${SEGMENT_EVENT_PREFIX} Create Project`,
    PROJECT_UPDATED :  `${SEGMENT_EVENT_PREFIX} Update Project`,
    USER_CREATED : `${SEGMENT_EVENT_PREFIX} Create User`,
    SEARCH : `${SEGMENT_EVENT_PREFIX} Main Search - Search`,
    RECORDER_STARTED:`${SEGMENT_EVENT_PREFIX} Create Test Recorder Start`,
    RECORDER_FAILED:`${SEGMENT_EVENT_PREFIX} Create Test Recorder Fail`,
    RECORDER_INSTALL_INITIATED:`${SEGMENT_EVENT_PREFIX} Create Test Recorder Install Initiate`,
    RECORDER_INSTALL_PERFORMED:`${SEGMENT_EVENT_PREFIX} Create Test Recorder install Perform`,
    CREATE_TEST_UPLOAD:`${SEGMENT_EVENT_PREFIX} Create Test Upload`,
    CREATE_TEST_WRITE:`${SEGMENT_EVENT_PREFIX} Create Test Write`,

    CREATION_MODE : `${SEGMENT_EVENT_PREFIX} Creation Mode`,
    
    CREATE_SCHEDULE:`${SEGMENT_EVENT_PREFIX} Create Schedule`,
    UPDATE_SCHEDULE:`${SEGMENT_EVENT_PREFIX} Update Schedule`,
    DELETE_SCHEDULE:`${SEGMENT_EVENT_PREFIX} Delete Schedule`,



    LOGIN:`${SEGMENT_EVENT_PREFIX} Login`,
    LOGIN_FAIL:`${SEGMENT_EVENT_PREFIX} Login Fail`,

    LOGOUT : `${SEGMENT_EVENT_PREFIX} Logout`,

    MAIN_NAVIGATION_EVENT : {

        DASHBOARD : `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Dashboard Click`,
        PROJECTS: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Projects Click`,
        TEST_SUITES: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Test Suites Click`,
        TEST_CASES: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Test Cases Click`,
        REPORTS: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Reports Click`,
        SCHEDULES: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Schedules Click`,
        ALERT_DETAILS: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Alert Details Click`,
        SMART_MAINTENANCE: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Smart Maintenance Click`,
        CONSOLE: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Console Click`,
        PROFILE: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Profile Click`,
        USERS: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Users Click`,
        ACCOUNTS: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Accounts Click`,
        PREFERENCES: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Preferences Click`,
        SECURITY: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Security Click`,
        SAUCELABS_CREDENTIALS: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Saucelabs Credentials Click`,
        SETTINGS: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Settings Click`,
        LEGAL_AND_COMPLIANCE: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Legal And Compliance Click`,
        WALKME: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Walkme Click`,
        PRODUCT_TOUR: `${SEGMENT_EVENT_PREFIX} ${MAIN_NAVIGATION_EVENT_PREFIX} Product Tour Click`,
    },

    USER_SETTINGS_EVENT : {
        PROFILE: `${SEGMENT_EVENT_PREFIX} ${USER_SETTINGS_EVENT_PREFIX} Profile Click`,
        SETTINGS: `${SEGMENT_EVENT_PREFIX} ${USER_SETTINGS_EVENT_PREFIX} Settings Click`,
        LEGAL_AND_COMPLIANCE: `${SEGMENT_EVENT_PREFIX} ${USER_SETTINGS_EVENT_PREFIX} Legal And Compliance Click`,
        GENERAL: `${SEGMENT_EVENT_PREFIX} ${USER_SETTINGS_EVENT_PREFIX} General Click`,
        EXECUTION_ENVIRONMENT: `${SEGMENT_EVENT_PREFIX} ${USER_SETTINGS_EVENT_PREFIX} Execution Environment Click`,
        SAML: `${SEGMENT_EVENT_PREFIX} ${USER_SETTINGS_EVENT_PREFIX} SAML Click`,
        SECURITY: `${SEGMENT_EVENT_PREFIX} ${USER_SETTINGS_EVENT_PREFIX} Security Click`,
        SAUCELABS_CREDENTIALS :  `${SEGMENT_EVENT_PREFIX} ${USER_SETTINGS_EVENT_PREFIX} Saucelabs Credentials Click`
    },

    USER_SETTINGS_SETUP:`${SEGMENT_EVENT_PREFIX} User Settings Setup`
}