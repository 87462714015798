// import material
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import packages
import React from 'react';

// import icons
import Lock from '@material-ui/icons/Lock';
import { BLACK_FONT } from '../../../common/cssConstants';
import { CustomSwitch } from '../../../common/miniComponents';

const styles = () => ({
    col: {
        width: 'inherit',
    },
    horizontalRow: {
        width: 'calc(100% + 78px)',
        marginLeft: '-55px',
        border: 0,
        borderTop: '1px solid #DCDCDC',
    },
    label: {
        alignSelf: 'center',
        '& > p': {
            color: BLACK_FONT,
            fontSize: 12,
        },
    },
    lockIcon: {
        fontSize: 20,
        color: '#979797',
    },
    root: {
        width: '100%',
        marginTop: 0,
    },
    row: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        height: 40,
    },
    value: {
        display: 'flex',
        minWidth: '35px',
        maxWidth: '35px',
        justifyContent: 'flex-start',
    },
});

class CaseTabHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            caseHeaderStatus: JSON.parse(JSON.stringify(props.headers)),
        };
    }

    statusChange = (item, index) => {
        const updatedState = [...this.state.caseHeaderStatus];
        item.status = !item.status;
        updatedState[index] = item;
        this.setState({ caseHeaderStatus: updatedState }, () => this.props.changeCaseHeaderStatus(this.state.caseHeaderStatus));
    };

    selectStatus = (item, itemIndex, headerPref) => {
        const { classes } = this.props;
        if (item.label === 'Name' || item.label === 'Actions') return <Lock className={classes.lockIcon} aria-label="lockIcon" id="lockIcon" />;
        return (
            <CustomSwitch
                checked={item.status}
                onClick={() => this.statusChange(item, itemIndex, headerPref)}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
        );
    };

    render() {
        const { classes, prefences, getPrefences } = this.props;
        const { caseHeaderStatus } = this.state;
        let headerPref = [];
        if (getPrefences) {
            const {
                accounts: { testcaseColumns },
            } = prefences;
            headerPref = testcaseColumns;
        }
        return (
            <div className={classes.root}>
                {caseHeaderStatus.map((item, i) => (
                    <div key={i}>
                        <div className={classes.row}>
                            <div className={[classes.col, classes.value].join(' ')}>{this.selectStatus(item, i, headerPref)}</div>
                            <div className={[classes.col, classes.label].join(' ')}>
                                <Typography>{`${item.name}`}</Typography>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
export default withStyles(styles)(CaseTabHeader);
