import React from 'react'
// Importing components
import TableHead from '../../../../components/Table/TableHead';
import DatasheetRow from './DatasheetRow';

const DatasheetTable = (props) => {

    const {
        classes,
        rowData,
        isColumnEmpty,
        tableHeadStyle,
        elementPrefix,
        tableRowStyle,
        tableCellStyles,
        updateSheet,
        colsWidth,
        rowIndex,
        width,
        availWidth,
        updateDataSheet,
        renderPopperItems,
        rowNo,
        setRowNo,
        columnNo,
        setColumnNo,
        hoverColumn,
        setHoverColumn,
        handleHoverOnTable,
    } = props

    return (
        <>
            {/* Table Header Row */}
            {rowIndex === 0 ?
                <TableHead isTableHeadClass={true} dataTypePrefix={`${elementPrefix}`} style={{ ...tableHeadStyle }}>
                    <div id={`${elementPrefix}mask`}></div>
                    <DatasheetRow
                        tableRowStyle={tableRowStyle}
                        tableCellStyles={tableCellStyles}
                        elementPrefix={elementPrefix}
                        isColumnEmpty={isColumnEmpty}
                        rowData={rowData}
                        updateSheet={updateSheet}
                        renderPopperItems={renderPopperItems}
                        updateDataSheet={(...args) => updateDataSheet(...args)}
                        classes={classes}
                        rowNo={rowNo}
                        setRowNo={(row) => setRowNo(row)}
                        columnNo={columnNo}
                        setColumnNo={(column) => setColumnNo(column)}
                        hoverColumn={hoverColumn}
                        setHoverColumn={(column) => setHoverColumn(column)}
                        handleHoverOnTable={(...args) => handleHoverOnTable(...args)}
                        availWidth={availWidth}
                        rowIndex={rowIndex}
                        colsWidth={colsWidth}
                        width={width}
                    />
                </TableHead>
                :
                // {Table Body}
                <DatasheetRow
                    tableRowStyle={tableRowStyle}
                    tableCellStyles={tableCellStyles}
                    elementPrefix={elementPrefix}
                    isColumnEmpty={isColumnEmpty}
                    updateSheet={updateSheet}
                    renderPopperItems={renderPopperItems}
                    updateDataSheet={(...args) => updateDataSheet(...args)}
                    rowData={rowData}
                    rowNo={rowNo}
                    setRowNo={(row) => setRowNo(row)}
                    columnNo={columnNo}
                    setColumnNo={(column) => setColumnNo(column)}
                    hoverColumn={hoverColumn}
                    setHoverColumn={(column) => setHoverColumn(column)}
                    handleHoverOnTable={(...args) => handleHoverOnTable(...args)}
                    classes={classes}
                    availWidth={availWidth}
                    rowIndex={rowIndex}
                    colsWidth={colsWidth}
                    width={width}
                />
            }
        </>
    )
}

export default DatasheetTable
