import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Close from '@material-ui/icons/Close';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { faEye, faCalendar } from '@fortawesome/free-regular-svg-icons';
import CloseOnEscape from 'react-close-on-escape';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getValidUrl, checkArrayLength, getTimeInHours } from '../../../utils/utils';
import FileList from '../../../components/FileList/FileList';
import ProjectMiddleware from '../../../store/middleware/projectMiddleware';
import StepAndDataModal from './StepAndDataModal';
import singleFileCreationService from '../../../services/singleFileCreationService';
import ProjectActions from '../../../store/actions/projectActions';
import Filedrop from '../../../components/Filedrop/Filedrop';
import { DIALOG_ROOT, MODAL_HEADER_TITLE, MODAL_FOOTER, MODAL_SUBMIT_BUTTON, MODAL_CANCEL_BUTTON, BLACK_FONT } from '../../../common/cssConstants';
import Tooltip from '../../../components/Tooltip';
import ContentLoader from '../../../components/ContentLoader';
import { CustomSwitch } from '../../../common/miniComponents';

const styles = (theme) => ({
    dialog: {
        textAlign: 'center',
    },
    dialogRoot: DIALOG_ROOT,
    circleIcon: {
        color: '#1068cd',
    },
    circleIconDisabled: {
        color: 'rgba(0, 0, 0, 0.26)',
    },
    iconContainer: {
        borderBottom: '1px solid #959595',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginBottom: 8,
    },
    removeIcon: {
        color: 'red',
    },
    fileUploadContainer: {
        flexDirection: 'column',
    },
    step1upload: {
        marginTop: 15,
        [theme.breakpoints.only('xs')]: {
            maxWidth: '77%',
            marginTop: '15px',
            marginLeft: '11%',
            marginBottom: '10px',
        },
    },
    mainDialog: {
        overflow: 'hidden',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 271px)',
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    labelColor: {
        '&>label': {
            color: '#595959',
            fontSize: '12px',
            transform: 'translate(0, 1.5px)',
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    root: {
        width: '100%',
        flex: '1 1 auto',
        padding: '0 6px 0px',
        overflowY: 'hidden',
        overflowX: 'hidden',
    },
    formControl: {
        width: '100%',
        flexDirection: 'row',
    },
    inputLabel: {
        left: 'inherit',
    },
    showSettings: {
        color: '#1168cd',
        cursor: 'pointer',
        fontSize: 12,
        paddingTop: '6px',
        textDecoration: 'underline',
    },
    textField: {
        marginRight: theme.spacing(1),
        marginTop: 0,
        marginBottom: 0,
        height: '44px',
    },
    dialogTitleStyle: MODAL_HEADER_TITLE,
    font: {
        fontSize: 12,
        color: '#595959',
    },
    textField1: {
        marginLeft: theme.spacing(1),
        marginTop: 0,
        marginBottom: 0,
        height: '44px',
    },
    testInfo: {
        color: '#1168cd',
        border: '1px solid #1168cd',
        width: 310,
        padding: 3,
        textAlign: 'center',
        cursor: 'pointer',
    },
    closeIcon: {
        color: '#9B9A9A',
        fontSize: 20,
    },
    closeIconCont: {
        position: 'absolute',
        right: '16px',
        top: '16px',
    },
    testError: {
        color: 'red',
        textAlign: 'left',
        fontSize: 11,
    },
    typo: {
        paddingTop: 16,
        width: '80%',
        fontSize: 12,
        color: BLACK_FONT,
    },
    select: {
        width: '100%',
        fontSize: 12,
        color: '#595959',
    },
    chip: {
        margin: theme.spacing(0.5),
        fontSize: '12px',
        height: 27,
    },
    chipClick: {
        margin: theme.spacing(0.5),
        cursor: 'pointer',
        fontSize: '12px',
        height: 27,
        '&:hover': {
            backgroundColor: '#c7c5c5',
        },
    },
    chipClickDisable: {
        margin: theme.spacing(0.5),
        cursor: 'not-allowed',
        backgroundColor: '#F2E8E8 !important',
        color: '#D18A8A !important',
        fontSize: '12px',
        height: 27,
    },
    chipRoot: {
        display: 'flex',
    },
    timeText: {
        margin: 0,
        width: '50%',
    },
    smartNotificationsAlertMessage: {
        fontSize: '9px',
        color: 'red',
        paddingLeft: '63px',
        display: 'block',
        margin: 0,
        marginTop: '-14px',
    },
    calender: {
        color: '#D18A8A',
        fontSize: '14px',
        marginLeft: '12px',
    },
    expansionPanelRoot: {
        width: 'calc(100% - 10px)',
        left: 10,
    },
    customMarginTop: {
        marginTop: '10px',
    },
    listView: {
        width: '96%',
        padding: 0,
    },
    scrollView: {
        overflow: 'auto',
        maxHeight: 140,
        padding: 0,
    },
    buttonStyle: {
        padding: '8px 18px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#C4C4C4',
        borderRadius: '6px',
        boxShadow: 'none',
        textTransform: 'capitalize',
        margin: '0px',
        marginRight: '10px',
        '&>:nth-child(1)': {
            minWidth: '50px',
            fontSize: '12px',
        },
    },
    cancelButton: {
        padding: '8px 18px',
        minWidth: '75px',
        minHeight: 0,
        boxShadow: 'none',
        color: BLACK_FONT,
        borderColor: '#C4C4C4',
        borderRadius: '6px',
        textTransform: 'capitalize',
        '&>:nth-child(1)': {
            minWidth: '50px',
            fontSize: '12px',
        },
    },
    errorMessage: {
        color: 'red',
        textAlign: 'left',
        margin: '6px 0px',
        fontSize: 12,
    },
    partition: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

class UpdateProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isErrorInProjectUrl: false,
            isSubmitDisabled: true,
            isAutoExecute: false,
            showTimer: false,
            smartRetry: false,
            aiqExecution: true,
            isNoExecutionScreenshot: false,
            emailToSend: '',
            projectName: '',
            projectURL: '',
            projectXPATH: [{ name: 'xpath-0', value: '' }],
            argument: '',
            projectId: '',
            columnCaseSteps: 0,
            columnCaseData: 0,
            uploadedFiles: [],
            totalFiles: '',
            ignoredFiles: [],
            ShowMe: false,
            showModal: false,
            stepAndDataArray: [],
            project: {},
            showError: true,
            projectStatus: false,
            schedulingStatus: false,
            schedulingOpen: false,
            schedulingTimeValue: '',
            schedulingData: [
                // { key: 0, label: 'Year', value: 0, },
                { key: 1, label: 'Month', value: 0, minVal: 1, maxVal: -1 },
                { key: 2, label: 'Days', value: 0, minVal: 1, maxVal: 31 },
                { key: 3, label: 'Hours', value: 0, minVal: 1, maxVal: 24 },
            ],
            isFullScreenShot: false,
            duplicateName: false,
            smartNotificationsAlertMessage: '',
            expanded: 'accepted',
            show: false,
            modalType: '',
            UploadError: false,
            isLoadingParser: props?.modalData?.updateProject,
        };
    }

    // getting Steps and Data Array
    getTestData = async () => {
        const { project } = this.state;
        this.setState({ isLoadingParser: true });
        const data = await ProjectMiddleware.getTestCases(project.projectId);
        const getDataIfTestCase = data ? data.find((FindData) => FindData.testCaseLoc) : null;
        if (getDataIfTestCase) {
            const { testCaseLoc } = getDataIfTestCase;
            const getTestData = await singleFileCreationService.getFileInfo(testCaseLoc, false);
            this.setState({
                stepAndDataArray: getTestData,
                showError: false,
                isLoadingParser: false,
            });
        } else {
            this.setState({ showError: true, isLoadingParser: false });
        }
    };

    // Is the form data valid (enough)?
    componentDidMount = async () => {
        const { getProjectInfo, modalData } = this.props;

        if (modalData.updateProject) {
            const { project } = modalData;
            this.updateState(
                {
                    projectName: (project && project.projectName) || '',
                    projectURL: (project && project.appUrl) || '',
                    emailToSend: (project && project.emailToSend) || '',
                    projectId: (project && project.projectId) || '',
                    project,
                },
                () => {
                    let obj = {};
                    if (this.state.project) {
                        this.getTestData();
                    } else {
                        obj.isLoadingParser = false;
                    }
                    obj = {
                        ...obj,
                        isErrorInProjectUrl: this.projectURLInError,
                        isSubmitDisabled: !this.enableSubmit,
                    };
                    this.setState(obj);
                },
            );
            const onCompelete = (projectAdvanceValues) => {
                if (projectAdvanceValues && Object.keys(projectAdvanceValues).length > 0) {
                    const _projectXPATH = [];
                    if (projectAdvanceValues.xpathAttributes) {
                        JSON.parse(projectAdvanceValues.xpathAttributes).forEach((value, index) => {
                            _projectXPATH.push({ name: `xpath-${index}`, value: `${value}` });
                        });
                    }
                    // check if projextXpath has any value
                    const hasXPATH = !!(_projectXPATH && _projectXPATH.length > 0);
                    // let years = 0;
                    let month = 0;
                    let days = 0;
                    let hours = 0;
                    if (projectAdvanceValues.interval) {
                        hours = projectAdvanceValues.interval;
                        // years = parseInt(hours / 8760);
                        // hours = hours % 8760;
                        month = parseInt(hours / 730, 10);
                        hours %= 730;
                        days = parseInt(hours / 24, 10);
                        hours %= 24;
                    }
                    this.setState({
                        emailToSend: projectAdvanceValues.emailToSend || '',
                        projectName: projectAdvanceValues.name,
                        projectURL: projectAdvanceValues.appUrl,
                        projectXPATH: hasXPATH && modalData.updateProject ? _projectXPATH : this.state.projectXPATH,
                        columnCaseSteps: projectAdvanceValues.stepsColNum ? projectAdvanceValues.stepsColNum : 0,
                        columnCaseData: projectAdvanceValues.dataColNum ? projectAdvanceValues.dataColNum : 0,
                        argument: projectAdvanceValues.customParserArgs ? projectAdvanceValues.customParserArgs : '',
                        projectStatus: !projectAdvanceValues.cacheXpaths,
                        isAutoExecute: projectAdvanceValues.isAutoExecute,
                        showTimer: projectAdvanceValues.showTimer || false,
                        smartRetry: projectAdvanceValues.smartRetry,
                        aiqExecution: projectAdvanceValues.aiqExecution,
                        isFullScreenShot: projectAdvanceValues.isFullScreenshot,
                        isNoExecutionScreenshot: !projectAdvanceValues.isNoExecutionScreenshot,
                        schedulingStatus: /* years > 0 || */ month > 0 || hours > 0 || days > 0,
                        show: month > 0 || hours > 0 || days > 0,
                        schedulingData: [
                            // { key: 0, label: 'Year', value: years, },
                            { key: 1, label: 'Month', value: month, minVal: 1, maxVal: -1 },
                            { key: 2, label: 'Days', value: days, minVal: 1, maxVal: 31 },
                            { key: 3, label: 'Hours', value: hours, minVal: 1, maxVal: 24 },
                        ],
                    });
                }
            };
            getProjectInfo(modalData.projectId, onCompelete); // for dispatch the Project Info
        }
    };

    get enableSubmit() {
        if (!this.state.emailToSend) {
            return this.state.projectName && this.validateUrl(this.state.projectURL);
        }
        return this.state.projectName && this.validateUrl(this.state.projectURL) && this.validateEmail(this.state.emailToSend);
    }

    get projectURLInError() {
        if (this.state.projectURL.length === 0) {
            return false;
        }
        return !this.validateUrl(this.state.projectURL);
    }

    get emailInError() {
        if (this.state.emailToSend) {
            return !this.validateEmail(this.state.emailToSend);
        }
        return true;
    }

    getNumMenuItems = (f = 0, t = 100 + 1, ignore = 0, type = null) => {
        const rows = [];
        for (let i = f; i <= t + 1; i++) {
            if (!(ignore !== 0 && i === ignore)) {
                rows.push(
                    <MenuItem
                        style={{
                            fontSize: 12,
                            color: '#595959',
                        }}
                        value={i}
                        key={i}
                    >
                        {type === 'step' ? 'Step ' : type === 'data' ? 'Data ' : ''}
                        {i === 0 ? '0' : i}
                    </MenuItem>,
                );
            }
        }
        return rows;
    };

    getStep_data = (data) => {
        this.setState({
            columnCaseSteps: Number(data.Step) + 1,
            columnCaseData: Number(data.Data) + 1,
        });
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleXPATHChange = (event, ind) => {
        const { value, name } = event.currentTarget;
        const projectXPATH = [...this.state.projectXPATH];

        projectXPATH.forEach((xpath) => {
            if (xpath.name === name) projectXPATH[ind].value = value;
        });

        this.setState({ projectXPATH });
    };

    addNewField = (e) => {
        e.preventDefault();
        const { projectXPATH } = this.state;

        const _projectXPATH = [...projectXPATH];
        _projectXPATH.push({
            name: `xpath-${projectXPATH.length}`,
            value: '',
        });
        this.setState({ projectXPATH: _projectXPATH });
    };

    removeCurrentField = (name) => (e) => {
        /*eslint-disable */
        e.preventDefault();
        const { projectXPATH } = this.state;
        const _projectXPATH = [...projectXPATH];

        _projectXPATH.some((xpath, index) => {
            if (xpath.name === name) {
                _projectXPATH.splice(index, 1);
                return true;
            }
        });

        this.setState({ projectXPATH: _projectXPATH });
    };

    customURL = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i',
    ); // fragment locator;

    emailRegex = /\S+@\S+\.\S+/;

    handleEmailChange = (event) => {
        const { value } = event.currentTarget;

        this.setState({ emailToSend: value.trim() }, () => {
            this.setState({
                // isErrorInEmail: this.emailInError,
                isSubmitDisabled: !this.enableSubmit,
            });
        });
    };

    handleURLChange = (event) => {
        const { value } = event.currentTarget;

        // In callback, check if url is valid and
        // If form ready to submit
        this.setState({ projectURL: value.trim() }, () => {
            this.setState({
                isErrorInProjectUrl: this.projectURLInError,
                isSubmitDisabled: !this.enableSubmit,
            });
        });
    };

    handleNameChange = (event) => {
        const { value } = event.currentTarget;
        let obj = { projectName: value.trim() };
        let callback = () => {};
        if (this.state.duplicateName) {
            obj = { ...obj, duplicateName: false };
        }
        if (this.state.isSubmitDisabled && this.state.projectURL) {
            callback = this.checkSameProjectName;
        }
        this.setState(obj, callback);
    };

    // currently check same name onBlur only
    checkSameProjectName = (callBack = () => {}) => {
        const {
            allProject,
            modalData: { updateProject },
        } = this.props;
        var getSameProName = allProject.filter(
            (findName) =>
                (typeof findName.projectName === 'string' ? findName.projectName.toLowerCase() : findName.projectName) ===
                (typeof this.state.projectName === 'string' ? this.state.projectName.toLowerCase() : this.state.projectName),
        );
        if (getSameProName.length > 0 && !updateProject) {
            this.setState({ isSubmitDisabled: true, duplicateName: true });
        } else {
            this.setState({ isSubmitDisabled: !this.enableSubmit, duplicateName: false }, () => {
                if (!this.state.isSubmitDisabled && typeof callBack === 'function') {
                    callBack();
                }
            });
        }
    };

    camelize = (value) => value.charAt(0).toUpperCase() + value.slice(1);

    expansionPanel = (files, panelName, step) => {
        const { classes } = this.props;
        const { expanded } = this.state;
        return (
            <Accordion
                className={classes.expansionPanelRoot}
                expanded={expanded === panelName && files.length > 0}
                onChange={this.handleExpansion(panelName)}
            >
                <AccordionSummary className={classes.customMarginTop} expandIcon={<ExpandMoreIcon aria-label="expandMoreIcon" id="expandMoreIcon" />}>
                    <Typography>{`${this.camelize(panelName)} ${files.length} files`}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.scrollView}>
                    <List className={classes.listView}>
                        {files &&
                            files.map((file, index) => (
                                <FileList
                                    file={{ file }}
                                    key={index.toString()}
                                    index={index}
                                    listItemAction={this.listItemAction}
                                    step={step}
                                    removeFile={this.removeFile}
                                />
                            ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        );
    };
    handleExpansion = (panel) => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };
    removeFile = (step, ind) => {
        this.setState({
            currentFiles: [],
            uploadedFiles: [],
        });
    };
    validateUrl = (url) => {
        return this.customURL.test(String(url).toLowerCase());
    };

    validateEmail = (email) => {
        return this.emailRegex.test(String(email).toLowerCase());
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.checkSameProjectName(this.submitFormData);
    };
    submitFormData = () => {
        const { isAutoExecute, projectName, projectURL, projectXPATH, schedulingStatus, aiqExecution, isNoExecutionScreenshot } = this.state;
        this.setState({
            isSubmitDisabled: true,
        });
        let onComplete = () => {
            this.setState({
                emailToSend: '',
                isAutoExecute: true,
                projectName: '',
                projectURL: '',
                projectStatus: false,
                schedulingStatus: false,
                aiqExecution: true,
                schedulingTimeValue: '',
                schedulingData: [
                    // { key: 0, label: 'Year', value: 0, },
                    { key: 1, label: 'Month', value: 0, minVal: 1, maxVal: -1 },
                    { key: 2, label: 'Days', value: 0, minVal: 1, maxVal: 31 },
                    { key: 3, label: 'Hours', value: 0, minVal: 1, maxVal: 24 },
                ],
                projectXPATH: [{ name: 'xpath-0', value: '' }],
            });
        };
        let hours = 0;
        if (schedulingStatus) {
            hours = getTimeInHours(this.state.schedulingData);
        }
        const validProjectURL = getValidUrl(projectURL);
        this.handleUpdateProject(isAutoExecute, projectName, validProjectURL, projectXPATH, onComplete, hours, aiqExecution, isNoExecutionScreenshot);
    };
    handleFilterChange = (e) => {
        this.setState({ [e.target.name]: e.target.value.trim() });
    };
    handleUpdateProject = async (
        isAutoExecute,
        projectName,
        projectURL,
        projectXPATH,
        onComplete,
        interval,
        aiqExecution,
        isNoExecutionScreenshot,
    ) => {
        const {
            user,
            clearError,
            modalData: { updateProject },
        } = this.props;
        const {
            argument,
            columnCaseSteps,
            columnCaseData,
            emailToSend,
            projectId,
            uploadedFiles,
            isFullScreenShot,
            showTimer,
            smartRetry,
        } = this.state;
        const { accountId } = user;
        const userId = user.data ? user.data.userId : user.userId;

        // let redirectWithId = false;
        let xpathAttr = [];
        projectXPATH &&
            projectXPATH.forEach((xpath) => {
                if (xpath.value) {
                    xpathAttr.push(xpath.value);
                }
            });
        let userVars = [];
        const validProjectURL = getValidUrl(this.state.projectURL);
        const projectObj = {
            projectName: this.state.projectName,
            appUrl: validProjectURL,
            xpathAttr,
            userVars,
            interval,
            stepsColNum: columnCaseSteps || 0,
            dataColNum: columnCaseData || 0,
            parserArgs: argument,
            cacheXpaths: !this.state.projectStatus,
            isFullScreenshot: isFullScreenShot,
            isAutoExecute,
            emailToSend,
            showTimer,
            smartRetry,
            aiqExecution,
            isNoExecutionScreenshot: !isNoExecutionScreenshot,
        };

        let formData = new FormData();
        if (uploadedFiles) {
            formData.append('parserFile', uploadedFiles[0]);
        }
        let response;

        if (updateProject) {
            formData.append('reqParams', JSON.stringify(projectObj));
            const projectNameUpdateData = { accountId, projectId, formData };
            response = await this.props.updateProject(projectNameUpdateData);
        } else {
            formData.append('params', JSON.stringify(projectObj));
            const projectNameUpdateData = { accountId, userId, formData };
            response = await this.props.createNewProject(projectNameUpdateData);
            if (response && response.payload && response.payload.data && response.payload.data.id) {
                // redirectWithId = true;
                this.props.history.push(`/projects/${response.payload.data.id}/cases`);
            }
        }
        if (response) {
            this.props.toggleModal();
        }

        clearError();
    };
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    checkFileType(type) {
        const allowedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
        return allowedFileTypes.indexOf(type) > -1;
    }

    checkFileExtension(ext) {
        const allowedFileExtensions = ['.xls', '.xlsx', '.csv'];
        return allowedFileExtensions.indexOf(ext) > -1;
    }

    onFileDropCallback = ([...files]) => {
        const file = files[0];
        const fileExtension = `.${file.name.split('.').pop()}`;
        if (!(this.checkFileExtension(fileExtension) && this.checkFileType(file.type))) {
            this.setState({
                UploadError: true,
            });
        } else {
            this.setState({
                UploadError: false,
            });
            console.log(this.state.ignoredFiles);

            setTimeout(() => {
                const ignoredFiles = [...this.state.ignoredFiles];
                ignoredFiles.push({
                    file: { name: file.name },
                    status: 'ignored',
                });
                this.setState({
                    dropZoneError: 'Only xls, xlsx, or csv file formats are acceptable',
                    ignoredFiles,
                });
            }, 1000);
            this.setState({
                uploadedFiles: [file],
                currentFiles: [files[0]],
                dropZoneError: '',
                totalFiles: this.state.totalFiles + 1,
            });
        }
    };

    isProjectSelected = () => {
        this.setState({
            dropZoneError: 'Please Select Project and App Name First',
        });
    };
    handleOpenModal = (type) => {
        if (!this.state.showError) {
            this.setState({ showModal: true, modalType: type });
        }
    };
    callAdvancedShow = () => {
        this.setState({ ShowMe: !this.state.ShowMe });
    };
    handleClose = () => {
        this.props.toggleModal();
    };
    selectHandler = (key, val) => {
        this.setState({
            [key]: val,
        });
    };
    handleTimeChange = (event) => {
        let val = event.target.value.trim();
        if (val != '') {
            val = val.split('.')[0];
            val = val.split(',')[0];
        }
        this.setState({ schedulingTimeValue: val });
    };
    handleTimeSelect = (current) => {
        const { schedulingTimeValue, schedulingData } = this.state;
        if (schedulingTimeValue != '' && parseInt(schedulingTimeValue) > 0) {
            const schedulingDataCopy = [...schedulingData];
            const chipToChange = schedulingData.indexOf(current);
            schedulingDataCopy[chipToChange].value = parseInt(schedulingTimeValue);
            this.setState({ schedulingData: schedulingDataCopy, schedulingTimeValue: '', show: true });
        }
    };
    handleDeleteFromChip = (data) => () => {
        const { schedulingData } = this.state;
        const schedulingDataCopy = [...schedulingData];
        const chipToChange = schedulingData.indexOf(data);
        schedulingDataCopy[chipToChange].value = 0;
        this.setState({ schedulingData: schedulingDataCopy });
        let checkVal = schedulingData.find((sch) => sch.value > 0);
        if (!!!checkVal) {
            this.setState({ show: false, emailToSend: '' });
        }
    };

    handleSwitchs = (event) => {
        let obj = { [event.target.value]: event.target.checked };
        if (event.target.value == 'schedulingStatus') {
            obj['schedulingOpen'] = false;
        }
        if (event.target.value == 'aiqExecution') {
            obj['isAutoExecute'] = false;
            if (this.props.modalData && !this.props.modalData.updateProject) {
                obj['isNoExecutionScreenshot'] = false;
            }
        }
        if (event.target.value === 'projectStatus') {
            let smartNotificationsAlertMessage = '';
            if (event.target.checked) {
                smartNotificationsAlertMessage = 'Enabling it will disable cache Xpaths option.';
            } else {
                smartNotificationsAlertMessage = 'Disabling it will enable cache Xpaths option.';
            }
            obj['smartNotificationsAlertMessage'] = smartNotificationsAlertMessage;
        }
        this.setState(obj);
    };
    render() {
        const {
            classes,
            open,
            modalData: { updateProject },
            toggleModal,
        } = this.props;
        const {
            emailToSend,
            isAutoExecute,
            isErrorInProjectUrl,
            showTimer,
            smartRetry,
            aiqExecution,
            isSubmitDisabled,
            projectXPATH,
            ShowMe,
            columnCaseData,
            columnCaseSteps,
            isNoExecutionScreenshot,
            uploadedFiles,
            showError,
            stepAndDataArray,
            projectStatus,
            schedulingData,
            schedulingStatus,
            schedulingOpen,
            schedulingTimeValue,
            isFullScreenShot,
            duplicateName,
            smartNotificationsAlertMessage,
            show,
            modalType,
            UploadError,
            isLoadingParser,
        } = this.state;
        const dataList = (type, ignore = 1) => {
            return updateProject && checkArrayLength(stepAndDataArray) && checkArrayLength(stepAndDataArray[0])
                ? this.getNumMenuItems(1, stepAndDataArray[0].length, ignore, type)
                : this.getNumMenuItems(0, 100, ignore, type);
        };
        return (
            <div>
                <Dialog
                    disableRestoreFocus
                    open={open}
                    onClose={toggleModal}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    className={classes.dialogRoot}
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            {updateProject ? 'Update Project' : 'New Project'}
                        </DialogTitle>
                        <div className={classes.closeIconCont}>
                            <Close className={classes.closeIcon} onClick={() => toggleModal()} aria-label="closeIcon" id="closeIcon" />
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <DialogContent className={classes.mainDialog}>
                            <div className={classes.root}>
                                <TextField
                                    autoFocus
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        classes: {
                                            input: classes.font,
                                        },
                                    }}
                                    id="projectName"
                                    aria-label="Project name"
                                    type="text"
                                    fullWidth
                                    onChange={this.handleNameChange}
                                    className={classes.labelColor}
                                    onBlur={() => {
                                        this.checkSameProjectName();
                                    }}
                                    value={this.state.projectName}
                                    required
                                />
                                {duplicateName ? <Typography className={classes.testError}>Project name already exists.</Typography> : null}
                                <TextField
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        classes: {
                                            input: classes.font,
                                        },
                                    }}
                                    id="projectURL"
                                    aria-label="App URL"
                                    type="text"
                                    fullWidth
                                    onChange={this.handleURLChange}
                                    className={classes.labelColor}
                                    value={this.state.projectURL}
                                    required
                                    error={isErrorInProjectUrl}
                                />
                                {updateProject || ShowMe ? (
                                    <div>
                                        {ShowMe ? (
                                            <Typography className={classes.showSettings} onClick={this.callAdvancedShow}>
                                                Hide Advanced Settings
                                            </Typography>
                                        ) : null}
                                        {projectXPATH &&
                                            projectXPATH.map((xpath, index) =>
                                                index + 1 !== projectXPATH.length ? (
                                                    <div key={index.toString()} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <TextField
                                                            key={index.toString()}
                                                            margin="normal"
                                                            InputLabelProps={{ shrink: true }}
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.font,
                                                                },
                                                            }}
                                                            name={xpath.name}
                                                            value={xpath.value}
                                                            aria-label="XPATH (optional)"
                                                            className={classes.labelColor}
                                                            fullWidth
                                                            onChange={(e) => this.handleXPATHChange(e, index)}
                                                            type="text"
                                                        />
                                                        <div className={classes.iconContainer}>
                                                            <Tooltip data={'Remove xpath'}>
                                                                <IconButton
                                                                    color="inherit"
                                                                    aria-label="Remove xpath"
                                                                    onClick={this.removeCurrentField(xpath.name)}
                                                                >
                                                                    <RemoveCircleIcon
                                                                        className={classes.removeIcon}
                                                                        aria-label="removeCircleIcon"
                                                                        id="removeCircleIcon"
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div key={index.toString()} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <TextField
                                                            margin="normal"
                                                            InputLabelProps={{ shrink: true }}
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.font,
                                                                },
                                                            }}
                                                            name={xpath.name || `xpath-${projectXPATH ? projectXPATH.length - 1 : 0}`}
                                                            value={xpath.value}
                                                            aria-label="XPATH (optional)"
                                                            className={classes.labelColor}
                                                            fullWidth
                                                            onChange={(e) => this.handleXPATHChange(e, index)}
                                                            type="text"
                                                        />
                                                        <div className={classes.iconContainer}>
                                                            <Tooltip data={'Add xpath'}>
                                                                <IconButton
                                                                    disabled={xpath.value === ''}
                                                                    color="inherit"
                                                                    aria-label="Add xpath"
                                                                    onClick={(e) => this.addNewField(e)}
                                                                >
                                                                    <AddCircleIcon
                                                                        className={
                                                                            xpath.value === '' ? classes.circleIconDisabled : classes.circleIcon
                                                                        }
                                                                        aria-label="addCircleIcon"
                                                                        id="addCircleIcon"
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                        <div style={{ width: '100%', display: 'flex', marginLeft: '-14.5px' }}>
                                            <div style={{ width: '50%' }}>
                                                <div className={classes.partition} style={{ position: 'relative' }}>
                                                    <FormControlLabel
                                                        style={{
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                        }}
                                                        control={
                                                            <CustomSwitch
                                                                checked={schedulingStatus}
                                                                onChange={this.handleSwitchs}
                                                                value="schedulingStatus"
                                                            />
                                                        }
                                                    />
                                                    <Typography className={classes.typo}>
                                                        Scheduling
                                                        <FontAwesomeIcon
                                                            icon={faCalendar}
                                                            aria-label="faCalendar"
                                                            id="faCalendar"
                                                            className={classes.calender}
                                                            style={{
                                                                cursor: schedulingStatus ? 'pointer' : 'not-allowed',
                                                            }}
                                                            onClick={() => {
                                                                if (schedulingStatus) {
                                                                    this.setState({ schedulingOpen: !schedulingOpen });
                                                                }
                                                            }}
                                                        />
                                                    </Typography>
                                                    {schedulingStatus && schedulingOpen ? (
                                                        <Paper
                                                            style={{
                                                                zIndex: 2,
                                                                width: 'calc(200% + 4px)',
                                                                top: '48px',
                                                                left: '13px',
                                                                position: 'absolute',
                                                                padding: '15px 20px',
                                                            }}
                                                        >
                                                            <div style={{ marginBottom: 10 }}>
                                                                <div className={classes.chipRoot} style={{ justifyContent: 'space-between' }}>
                                                                    <TextField
                                                                        margin="normal"
                                                                        value={schedulingTimeValue}
                                                                        aria-label="Scheduling Time Value"
                                                                        id="schedulingTimingValue"
                                                                        onChange={this.handleTimeChange}
                                                                        type="number"
                                                                        className={`${classes.timeText} ${classes.labelColor}`}
                                                                        InputProps={{
                                                                            classes: {
                                                                                input: classes.font,
                                                                            },
                                                                            min: '1',
                                                                            step: '1',
                                                                        }}
                                                                    />
                                                                    <div>
                                                                        {schedulingData.map((data) => {
                                                                            let icon = null;
                                                                            let isDisable =
                                                                                schedulingTimeValue == '' ||
                                                                                Number(schedulingTimeValue) < data.minVal ||
                                                                                (Number(schedulingTimeValue) > data.maxVal && data.maxVal != -1);
                                                                            return (
                                                                                <Chip
                                                                                    key={data.key}
                                                                                    icon={icon}
                                                                                    label={data.label}
                                                                                    className={
                                                                                        !isDisable ? classes.chipClick : classes.chipClickDisable
                                                                                    }
                                                                                    onClick={
                                                                                        !isDisable
                                                                                            ? () => {
                                                                                                  this.handleTimeSelect(data);
                                                                                              }
                                                                                            : null
                                                                                    }
                                                                                />
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                                {show && (
                                                                    <div className={classes.chipRoot} style={{ justifyContent: 'start' }}>
                                                                        {schedulingData &&
                                                                            schedulingData.map((data) => {
                                                                                let icon = null;
                                                                                if (data.value == 0) return null;
                                                                                return (
                                                                                    <Chip
                                                                                        key={data.key}
                                                                                        icon={icon}
                                                                                        label={data.value + ' ' + data.label}
                                                                                        onDelete={this.handleDeleteFromChip(data)}
                                                                                        className={classes.chip}
                                                                                    />
                                                                                );
                                                                            })}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <TextField
                                                                id="emailToSend"
                                                                aria-label="Email"
                                                                type="email"
                                                                fullWidth
                                                                InputLabelProps={{ shrink: true }}
                                                                InputProps={{
                                                                    classes: {
                                                                        input: classes.font,
                                                                    },
                                                                }}
                                                                className={classes.labelColor}
                                                                value={emailToSend}
                                                                onChange={this.handleEmailChange}
                                                                disabled={!show}
                                                            />
                                                        </Paper>
                                                    ) : null}
                                                </div>
                                                <div>
                                                    <div className={classes.partition}>
                                                        <FormControlLabel
                                                            style={{
                                                                marginLeft: 0,
                                                                marginRight: 0,
                                                            }}
                                                            control={
                                                                <CustomSwitch
                                                                    checked={projectStatus}
                                                                    onChange={this.handleSwitchs}
                                                                    value="projectStatus"
                                                                />
                                                            }
                                                        />
                                                        <Typography className={classes.typo}>Enable smart notifications</Typography>
                                                    </div>
                                                    {smartNotificationsAlertMessage && smartNotificationsAlertMessage.length > 0 ? (
                                                        <span className={classes.smartNotificationsAlertMessage}>
                                                            {smartNotificationsAlertMessage}
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                <div className={classes.partition}>
                                                    <FormControlLabel
                                                        style={{
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                        }}
                                                        control={
                                                            <CustomSwitch
                                                                checked={isFullScreenShot}
                                                                onChange={this.handleSwitchs}
                                                                value="isFullScreenShot"
                                                            />
                                                        }
                                                    />
                                                    <Typography className={classes.typo}>Show full screen images</Typography>
                                                </div>
                                                <div className={classes.partition}>
                                                    <FormControlLabel
                                                        style={{
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                        }}
                                                        control={
                                                            <CustomSwitch
                                                                disabled={aiqExecution}
                                                                checked={isAutoExecute && !aiqExecution}
                                                                onChange={this.handleSwitchs}
                                                                value="isAutoExecute"
                                                            />
                                                        }
                                                    />
                                                    <Typography className={classes.typo}>Auto Generate Video</Typography>
                                                </div>
                                            </div>
                                            <div style={{ width: '50%', paddingLeft: '18px' }}>
                                                <div className={classes.partition}>
                                                    <FormControlLabel
                                                        style={{
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                        }}
                                                        control={
                                                            <CustomSwitch checked={smartRetry} onChange={this.handleSwitchs} value="smartRetry" />
                                                        }
                                                    />
                                                    <Typography className={classes.typo}>Smart Retry</Typography>
                                                </div>
                                                <div className={classes.partition}>
                                                    <FormControlLabel
                                                        style={{
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                        }}
                                                        control={<CustomSwitch checked={showTimer} onChange={this.handleSwitchs} value="showTimer" />}
                                                    />
                                                    <Typography className={classes.typo}>Show Step Timer</Typography>
                                                </div>
                                                <div className={classes.partition}>
                                                    <FormControlLabel
                                                        style={{
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                        }}
                                                        control={
                                                            <CustomSwitch checked={aiqExecution} onChange={this.handleSwitchs} value="aiqExecution" />
                                                        }
                                                    />
                                                    <Typography className={classes.typo}>Execute using AIQ Engine</Typography>
                                                </div>
                                                <div className={classes.partition}>
                                                    <FormControlLabel
                                                        style={{
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                        }}
                                                        control={
                                                            <CustomSwitch
                                                                disabled={!aiqExecution}
                                                                checked={isNoExecutionScreenshot && aiqExecution}
                                                                onChange={this.handleSwitchs}
                                                                value="isNoExecutionScreenshot"
                                                            />
                                                        }
                                                    />
                                                    <Typography className={classes.typo}>Show screenshot for AIQ executions</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <Typography
                                                style={{ fontSize: 12, fontWeight: 'bold', color: '#595959', marginTop: '8px', width: '100%' }}
                                            >
                                                Custom Parser Settings for Test Case
                                            </Typography>
                                            <div style={{ display: 'flex', width: '100%' }}>
                                                <div style={{ width: 'calc(50% - 15px)' }}>
                                                    <div
                                                        className={classes.labelColor}
                                                        style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingTop: 10 }}
                                                    >
                                                        <InputLabel
                                                            style={{
                                                                marginBottom: '9.7px',
                                                            }}
                                                            shrink
                                                            htmlFor="StepsNumber-label-placeholder"
                                                        >
                                                            Column Number
                                                        </InputLabel>
                                                        <FormControl className={classes.formControl}>
                                                            <div
                                                                style={{
                                                                    width: 'calc(50% - 10px)',
                                                                    marginRight: '20px',
                                                                }}
                                                            >
                                                                {isLoadingParser ? (
                                                                    <ContentLoader height={28} width={70} />
                                                                ) : (
                                                                    <>
                                                                        <Select
                                                                            value={columnCaseSteps}
                                                                            onChange={(event) => {
                                                                                this.selectHandler('columnCaseSteps', event.target.value);
                                                                            }}
                                                                            input={<Input name="StepsNumber" id="StepsNumber-label-placeholder" />}
                                                                            displayEmpty
                                                                            name="Steps Number"
                                                                            className={classes.select}
                                                                        >
                                                                            {dataList('step', columnCaseData)}
                                                                        </Select>
                                                                        {updateProject && (
                                                                            <Tooltip data={'Show'}>
                                                                                <IconButton
                                                                                    onClick={() => this.handleOpenModal('testStep')}
                                                                                    disabled={showError}
                                                                                    style={{ width: '16px', height: '16px' }}
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        style={{ color: '#3B91DF', fontSize: '14px' }}
                                                                                        icon={faEye}
                                                                                        aria-label="faEye"
                                                                                        id="faEye"
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: 'calc(50% - 10px)',
                                                                }}
                                                            >
                                                                {isLoadingParser ? (
                                                                    <ContentLoader height={28} width={70} />
                                                                ) : (
                                                                    <>
                                                                        <Select
                                                                            value={columnCaseData}
                                                                            onChange={(event) => {
                                                                                this.selectHandler('columnCaseData', event.target.value);
                                                                            }}
                                                                            input={<Input name="DataNumber" id="DataNumber-label-placeholder" />}
                                                                            displayEmpty
                                                                            name="Data Number"
                                                                            className={classes.select}
                                                                        >
                                                                            {dataList('data', columnCaseSteps)}
                                                                        </Select>
                                                                        {updateProject && (
                                                                            <Tooltip data={'Show'}>
                                                                                <IconButton
                                                                                    onClick={() => this.handleOpenModal('testData')}
                                                                                    disabled={showError}
                                                                                    style={{ width: '16px', height: '16px' }}
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        style={{ color: '#3B91DF', fontSize: '14px' }}
                                                                                        icon={faEye}
                                                                                        aria-label="faEye"
                                                                                        id="faEye"
                                                                                    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                <div style={{ width: 'calc(50% - 15px)', marginLeft: 30 }}>
                                                    <TextField
                                                        margin="normal"
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.font,
                                                            },
                                                        }}
                                                        id="projectArgument"
                                                        aria-label="Arguments"
                                                        type="text"
                                                        fullWidth
                                                        name={'argument'}
                                                        className={classes.labelColor}
                                                        value={this.state.argument}
                                                        onChange={this.handleFilterChange}
                                                        error={isErrorInProjectUrl}
                                                        style={{ margin: 0, marginTop: 10.5 }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <Grid
                                            container
                                            spacing={1}
                                            className={classes.fileUploadContainer}
                                            classes={{
                                                container: classes.step1upload,
                                            }}
                                        >
                                            {uploadedFiles == 0 ? (
                                                <Filedrop
                                                    config={{
                                                        dropCallback: this.onFileDropCallback,
                                                        text: <span>Select xls, xlsx, or csv parser file</span>,
                                                    }}
                                                />
                                            ) : (
                                                this.expansionPanel(uploadedFiles, 'accepted', 'datafile')
                                            )}
                                            {UploadError ? (
                                                <Typography className={classes.errorMessage}>
                                                    Only xls, xlsx, or csv file formats are acceptable
                                                </Typography>
                                            ) : null}
                                        </Grid>
                                    </div>
                                ) : (
                                    <Typography className={classes.showSettings} onClick={this.callAdvancedShow}>
                                        Show Advanced Settings
                                    </Typography>
                                )}
                            </div>
                        </DialogContent>
                        <DialogActions style={MODAL_FOOTER}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="secondary"
                                disabled={isSubmitDisabled || !this.enableSubmit}
                                style={MODAL_SUBMIT_BUTTON}
                            >
                                Save
                            </Button>
                            <CloseOnEscape onEscape={toggleModal}>
                                <Button className={classes.cancelButton} onClick={() => toggleModal()} variant="outlined" style={MODAL_CANCEL_BUTTON}>
                                    Cancel
                                </Button>
                            </CloseOnEscape>
                        </DialogActions>
                        <StepAndDataModal
                            Steps={this.state.columnCaseSteps}
                            ColumnData={this.state.columnCaseData}
                            data={this.state.stepAndDataArray}
                            getStep_data={(d) => this.getStep_data(d)}
                            open={this.state.showModal}
                            handleClose={() => {
                                this.setState({ showModal: false });
                            }}
                            modalType={modalType}
                            resetValues={() => this.setState({ columnCaseSteps: 0, columnCaseData: 0 })}
                        />
                    </form>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        allProject: state.projectReducer.projects,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createNewProject: (data) => dispatch(ProjectActions.createNewProject(data)),
        updateProject: (data) => dispatch(ProjectActions.updateProject(data)),
        clearError: () => dispatch(ProjectActions.clearError()),
        getAllProjects: (...args) => dispatch(ProjectActions.getAllProjects(...args)),
        getProjectDetails: (projectId) => dispatch(ProjectActions.getProjectDetails(projectId)),
        getProjectInfo: (projectId, onCompelete, onFail) => dispatch(ProjectActions.getProjectInfo(projectId, onCompelete, onFail)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpdateProject));
