// import materials
import { Hidden, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
// import packages
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
// import icons
import Clear from '@material-ui/icons/Clear';
// import custom components
import { BLACK_FONT } from '../../../../common/cssConstants';
import { getSecondsToFormatedTime, checkKeyInObject } from '../../../../utils/utils';
import { SelectedTestCaseActions, TestCaseActions, ProjectActions } from '../../../../store/actions';
import { TestStepUtils } from '../../../../utils/TestStepUtils';
import StatusSnipet from '../../Case/StatusSnipet';
import PreLoadImage from '../../../../common/PreLoadImage';
import FadedRowLoader from '../../TestSteps/Misc/FadedRowLoader';
import RowActionButtons from '../../TestSteps/Misc/RowActionButtons';
import LoadingStepRow from '../../TestSteps/LiveSteps/LoadingRow';
import Tooltip from '../../../../components/Tooltip';

// Styles
const styles = (theme) => ({
    check: {
        fontSize: 18,
        width: '18px !important',
        height: '18px !important',
    },
    alignItemC: {
        alignItems: 'center',
    },
    display: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
    },
    even: {
        backgroundColor: '#ffffff',
        margin: '0px !important',
        padding: '0px !important',
    },
    imagePreviewContainer: {
        height: '85px',
        width: '142px',
        position: 'absolute',
        justifyContent: 'space-around',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#fff',
        fontSize: '16px',
        display: 'flex',
        '& > div > span':{
            display: 'none'
        },
        '&:hover': {
            '& > div > span': {
                display: 'block',
            },
            background: 'rgba(0, 63, 155, 0.6)',
        },
    },
    listViewRow: {
        padding: '0px !important',
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            width: '90%',
        },
    },
    listViewRowBackGround: {
        background: 'rgba(0, 0, 0, 0.1)',
    },
    listViewStepNumberColumnStyle: {
        color: '#000',
        minWidth: '43px',
        paddingLeft: 15,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 10,
        },
    },
    listviewStepInstructionColumnSpan:{
        width: '400px',
        display: 'flex',
        maxWidth: '400px',
        minWidth: '200px',
        flexDirection: 'column',
    },
    listViewStepInstructionColumnStyle: {
        marginLeft: '175px',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: BLACK_FONT,
        fontSize: 12,
        fontWeight: 400,
        [theme.breakpoints.only('xs')]: {
            maxWidth: '100%',
        },
    },
    listViewStepDataColumnStyle: {
        marginLeft: '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        color: '#1168CD',
        fontSize: 12,
        fontWeight: 400,
        [theme.breakpoints.only('xs')]: {
            maxWidth: '100%',
        },
    },
    RobotoMonoImportant: {
        fontFamily: 'Roboto Mono !important',
    },
    moreIconsBtn: {
        height: '22px',
        width: '24px',
        backgroundColor: 'transparent !important',
        cursor: 'pointer',
        marginRight: 10,
        color: 'rgba(0, 0, 0, 0.54)',
    },
    odd: {
        backgroundColor: '#ffffff',
    },
    parentContainerWithDebugCall: {
        borderBottom: '1px solid #e0e0e0',
        backgroundColor: '#E4E4E4',
    },
    parentContainerWithNoDebugCall: {
        borderBottom: '1px solid #e0e0e0',
    },
    paper: {
        padding: theme.spacing(1),
        maxWidth: '220px',
        height: 'auto',
        wordBreak: 'break-word',
        zIndex: '10000',
        borderRadius: 8,
    },
    popperClear: {
        alignSelf: 'flex-end',
        display: 'flex',
        float: 'right',
        fontSize: '13px',
        height: '14px',
        textAlign: 'right',
        width: '16px',
    },
    popperPaperChild: {
        boxShadow: '0px 0px 19px 1px rgb(165, 165, 165) !important',
        right: '-3px',
        top: '-3px',
    },
    screenShotContainer: {
        border: '3px solid #dddddd',
        height: '91px',
        position: 'relative',
        width: '148px',
    },
    screenShotThumbnail: {
        height: '85px',
        width: '142px',
    },
    screenViewRow: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    screenViewStepNumberColumnStyle: {
        color: '#000',
        paddingLeft: 5,
        marginRight: 5,
    },
    screenViewStepNumberColumnChildStyle: {
        minWidth: '27px',
        display: 'block',
    },
    screenViewStepInstrDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '45px',
        width: '254px',
        maxWidth: '254px',
        minWidth: 200,
    },
    center: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
    },
    screenViewStepInstructionColumnStyle: {
        overflow: 'hidden',
        height: '62px',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        fontSize: 12,
        fontWeight: 400,
        color: '#242723',
    },
    screenViewInstrDataDivider: {
        backgroundColor: '#979797',
        height: 1,
        marginTop: 5,
        marginBottom: 5,
    },
    screenViewDataColumnStyle: {
        fontSize: 12,
        fontWeight: 400,
        marginLeft: '70.5px',
        width: '256px',
        maxWidth: '256px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '50px',
        color: '#2da6ea',
    },
    showText: {
        width: '100%',
        padding: '0px 8px 8px',
        fontWeight: 500,
        fontSize: 12,
        overflow: 'hidden',
    },
    spanRow: {
        width: '100%',
        display: 'inline-block',
        overflow: 'hidden',
        fontSize: '13px',
        margin: '10px 0',
    },
    stepDuration: {
        color: 'rgb(88, 124, 153)',
        fontSize: 12,
        fontWeight: 400,
        display: 'block',
        marginRight: '30px',
    },
    tableCellActionListViewPadding: {
        padding: '0px 0px 0px 0px',
    },
    tableCellActionPadding: {
        padding: '16px 0px 16px 20px',
    },
    tableCellAction: {
        margin: '0px !important',
        textAlign: 'left',
        paddingRight: '0px !important',
    },
    tableRowDataList: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 !important',
        padding: '0px !important',
        justifyContent: 'space-between',
        width: '100%',
        position: 'relative',
    },
    testNameColored: {
        color: 'blue',
    },
    testnamedisabled: {
        color: '#4C4C4C',
    },
    timerSpan: {
        color: 'rgb(88, 124, 153)',
        fontSize: 12,
        fontWeight: 400,
        marginRight: '31px',
    },
    viewScreen: {
        cursor: 'pointer',
        padding: '2px 5px',
        fontSize: '14px',
        position: 'absolute',
    },
    warnPaper: {
        // pointerEvents: 'none',
        zIndex: 99999,
        paddingBottom: '10px',
        maxWidth: ' 280px !important',
    },
    debugIconStyle: {
        color: 'red',
        cursor: 'pointer',
        fontSize: 13,
        marginRight: 10,
        marginTop: 2,
        [theme.breakpoints.only('xs')]: {
            marginRight: 0,
        },
    },
    listViewRow1stContainer: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        paddingLeft: '30px',
        minHeight: '32px',
        [theme.breakpoints.only('xs')]: {
            paddingLeft: '10px',
            minHeight: '68px',
        },
    },
    listViewIntrDataContainer: {
        display: 'flex',
        maxWidth: '100%',
        [theme.breakpoints.only('xs')]: {
            display: 'block',
            maxWidth: '98%',
        },
    },
    pipe: {
        width: '4%',
        textAlign: 'center',
        overflow: 'hidden',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    poperGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    timeContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
});
// global

class ExecutionStepRow extends React.Component {
    constructor(props) {
        super(props);
        TestStepUtils.stepsRef[`${props.projectId}_${props.executionId}_${props.testStep.instrNum}`] = React.createRef();
        this.isComponentMount = false;
        this.toolTipEnterTimeOut = null;
        this.toolTipLeaveTimeOut = null;
        this.toolTipEnterTimeOutWarn = null;
        this.toolTipLeaveTimeOutWarn = null;
        this.toolTipEnterTimeOutReplay = null;
        this.toolTipLeaveTimeOutReplay = null;
        this.state = {
            anchorEl: null,
            anchorElWarn: null,
            debugCall: false,
            showPopUp: false,
            showPopUpWarn: false,
            shouldLoadComponent: false,
        };
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setStateIfComponentMounted({ shouldLoadComponent: true });
        }, 400);
        this.isComponentMount = true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { returnData } = nextProps;
        if (returnData) {
            this.setStateIfComponentMounted({ debugCall: false });
        }
    }

    componentWillUnmount() {
        this.isComponentMount = false;
    }

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (checkKeyInObject(this, 'isComponentMount') && this.isComponentMount) {
            this.setState(obj, callback);
        }
    };

    handlePopoverOpen = (event) => {
        const { currentTarget } = event;

        //setting tooltip postion as Top if the row present at bottom of page to avoid tooltip flicker
        let rect = null;
        if(currentTarget.getBoundingClientRect) {
             rect = currentTarget.getBoundingClientRect();
        }
        let offsetBottom = 0
        if(rect) {
            offsetBottom = rect.y || 0
        }
        let totalDocHeight = document && document.body && document.body.clientHeight ? document.body.clientHeight : 0
        if(totalDocHeight && totalDocHeight-offsetBottom < 140) {
            this.setState({
                tooltipPosition:'top'
            })
        }
        if (this.toolTipEnterTimeOut !== null) {
            clearTimeout(this.toolTipEnterTimeOut);
            this.toolTipEnterTimeOut = null;
        }
        if (this.toolTipLeaveTimeOut !== null) {
            clearTimeout(this.toolTipLeaveTimeOut);
            this.toolTipLeaveTimeOut = null;
        }
        if (!this.state.showPopUp) {
            this.toolTipEnterTimeOut = setTimeout(() => {
                this.setStateIfComponentMounted({ showPopUp: true, anchorEl: this.state.anchorEl ? null : currentTarget });
            }, 1000);
        }
    };

    handleWarnPopoverEnter = (event) => {
        const { currentTarget } = event;
        if (this.toolTipEnterTimeOutWarn !== null) {
            clearTimeout(this.toolTipEnterTimeOutWarn);
            this.toolTipEnterTimeOutWarn = null;
        }
        if (this.toolTipLeaveTimeOutWarn !== null) {
            clearTimeout(this.toolTipLeaveTimeOutWarn);
            this.toolTipLeaveTimeOutWarn = null;
        }
        if (!this.state.showPopUpWarn) {
            this.toolTipEnterTimeOutWarn = setTimeout(() => {
                this.setStateIfComponentMounted((state) => ({ showPopUpWarn: true, anchorElWarn: state.anchorElWarn ? null : currentTarget }));
            }, 1000);
        }
    };

    handleWarnPopoverLeave = () => {
        if (this.toolTipEnterTimeOutWarn !== null) {
            clearTimeout(this.toolTipEnterTimeOutWarn);
        }
        if (this.state.showPopUpWarn) {
            this.toolTipLeaveTimeOutWarn = setTimeout(() => {
                this.setStateIfComponentMounted({ showPopUpWarn: false, anchorElWarn: null });
            }, 500);
        }
    };

    handlePopoverTimer = () => {
        if (this.toolTipEnterTimeOut !== null) {
            clearTimeout(this.toolTipEnterTimeOut);
        }
        if (this.state.showPopUp) {
            this.toolTipLeaveTimeOut = setTimeout(() => {
                this.setStateIfComponentMounted({ showPopUp: false, anchorEl: null });
            }, 500);
        }
    };

    handleReplayPopoverLeave = () => {
        if (this.toolTipEnterTimeOutReplay !== null) {
            clearTimeout(this.toolTipEnterTimeOutReplay);
        }
        if (this.state.showPopUp) {
            this.toolTipLeaveTimeOutReplay = setTimeout(() => {
                this.setStateIfComponentMounted({ showPopUp: false });
            }, 500);
        }
    };

    imageHoverPreview = (classes, callBack) => {
        return (
            <div aria-hidden className={classes.imagePreviewContainer} onClick={callBack}>
                <div className={classes.viewScreen}>
                    <span>View Screen</span>
                </div>
            </div>
        );
    };

    render() {
        const {
            classes,
            changeIndex,
            toggleScreenShotModal,
            index,
            listView,
            selectedProject: { showTimer },
            executionId,
            projectId,
            testStep,
            selectedHoverImage,
            userVariables,
            testDataColumns,
            openTaskPanel
        } = this.props;
        const { anchorEl, anchorElWarn, debugCall, shouldLoadComponent } = this.state;
        const open = Boolean(anchorEl);
        const openWarn = Boolean(anchorElWarn);
        const id = open ? 'no-transition-popper' : null;
        const stepKey = `${projectId}_${executionId}_${testStep.instrNum}`;
        let dataColWidth = openTaskPanel ?  '18vh': '50vh';
        const data = (
            <div
                ref={TestStepUtils.stepsRef[stepKey]}
                data-id={stepKey}
                className={[
                    classes[debugCall ? 'parentContainerWithDebugCall' : 'parentContainerWithNoDebugCall'],
                    index % 2 === 0 ? `${classes.even}` : `${classes.odd}`,
                    'tableRow',
                ].join(' ')}
                key={testStep.instrNum}
                style={listView ? {} : { display: 'flex', alignItems: 'center', height: 123 }}
            >
                {shouldLoadComponent ? (
                    <>
                        <FadedRowLoader isExecution testStep={testStep} listView={listView} />
                        <div
                            className={[
                                classes.tableCellAction,
                                classes[listView ? 'tableCellActionListViewPadding' : 'tableCellActionPadding'],
                            ].join(' ')}
                            style={listView ? {} : { width: '100%' }}
                        >
                            <div>
                                <div className={classes.tableRowDataList}>
                                    {listView ? (
                                        <div
                                            className={[
                                                classes.listViewRow,
                                                `${selectedHoverImage}` === `${index}` ? classes.listViewRowBackGround : '',
                                                `${testStep.status}` !== '5' ? classes.testNameColored : classes.testnamedisabled,
                                            ].join(' ')}
                                            onMouseOver={() => {
                                                changeIndex(index);
                                            }}
                                            onMouseLeave={() => {
                                                changeIndex(null);
                                            }}
                                        >
                                            <Grid container className={classes.container}>
                                                <Grid item xs={10} sm={9} md={7} lg={9} className={classes.listViewRow1stContainer}>
                                                    <StatusSnipet
                                                        testStepStatus={testStep.status}
                                                        testStepMsg={testStep.message || ''}
                                                        testStepNumber={testStep.instrNum}
                                                        testStep={testStep}
                                                        handleWarnPopoverEnter={this.handleWarnPopoverEnter}
                                                        handleWarnPopoverLeave={() => this.handleReplayPopoverLeave()}
                                                        callFrom="testStepTableData"
                                                    />
                                                    <Popper
                                                        id={id}
                                                        placement="top"
                                                        open={openWarn}
                                                        anchorEl={anchorElWarn}
                                                        className={classes.warnPaper}
                                                        onMouseLeave={this.handleWarnPopoverLeave}
                                                        onMouseOver={() => {
                                                            if (this.toolTipLeaveTimeOutWarn !== null) {
                                                                clearTimeout(this.toolTipLeaveTimeOutWarn);
                                                                this.toolTipLeaveTimeOutWarn = null;
                                                            }
                                                        }}
                                                    >
                                                        <Paper className={classes.popperPaperChild}>
                                                            <Clear
                                                                className={classes.popperClear}
                                                                onClick={() => {
                                                                    if (this.toolTipEnterTimeOutWarn !== null) {
                                                                        clearTimeout(this.toolTipEnterTimeOutWarn);
                                                                    }
                                                                    this.setStateIfComponentMounted({
                                                                        showPopUpWarn: false,
                                                                        anchorElWarn: null,
                                                                    });
                                                                }}
                                                            />
                                                            <Typography
                                                                dangerouslySetInnerHTML={{ __html: testStep.message }}
                                                                className={[classes.showText, classes.RobotoMonoImportant].join(' ')}
                                                            />
                                                        </Paper>
                                                    </Popper>
                                                    <span
                                                        className={[classes.listViewStepNumberColumnStyle, classes.RobotoMonoImportant].join(' ')}
                                                        style={(`${testStep.instrNum}`.match(/\./g) || []).length > 0 ? { paddingRight: '15px' } : {}}
                                                    >
                                                        {`${testStep.instrNum} `}
                                                    </span>
                                                    <span className={classes.spanRow}>
                                                        <span
                                                            onMouseEnter={this.handlePopoverOpen}
                                                            onMouseLeave={this.handlePopoverTimer}
                                                            className={classes.listViewIntrDataContainer}
                                                        >
                                                            <span className={classes.listviewStepInstructionColumnSpan}>
                                                            <span
                                                                className={[
                                                                    classes.listViewStepInstructionColumnStyle,
                                                                    classes.RobotoMonoImportant,
                                                                ].join(' ')}
                                                            >
                                                            <Tooltip position={this.state.tooltipPosition}  data={testStep.instr}>
                                                                {`${testStep.instr}`}
                                                            </Tooltip>
                                                            </span>
                                                            </span>
                                                            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                                                <div style={{ width: '90%' }}>
                                                                    <hr className={classes.screenViewInstrDataDivider} />
                                                                </div>
                                                            </Hidden>
                                                            {/***<Hidden only={['xs']}>
                                                                {(testStep.data || testStep.columnName) && <span className={classes.pipe}>|</span>}
                                                            </Hidden>***/}
                                                            {(testStep.data || testStep.columnName) && (
                                                                <span
                                                                    className={[
                                                                        classes.listViewStepDataColumnStyle,
                                                                        classes.RobotoMonoImportant,
                                                                    ].join(' ')}
                                                                    style={{
                                                                        width:dataColWidth
                                                                    }}
                                                                >
                                                                <Tooltip position={this.state.tooltipPosition}  data= {TestStepUtils.renderDataField(
                                                                        testStep.instr,
                                                                        testStep.data,
                                                                        userVariables,
                                                                        testDataColumns,
                                                                    )}>
                                                                    {TestStepUtils.renderDataField(
                                                                        testStep.instr,
                                                                        testStep.data,
                                                                        userVariables,
                                                                        testDataColumns,
                                                                    )}
                                                                    </Tooltip>
                                                                </span>
                                                            )}
                                                        </span>
                                                    </span>
                                                    <Popper
                                                        id={id}
                                                        anchorEl={anchorEl}
                                                        className={classes.paper}
                                                        onMouseLeave={this.handlePopoverTimer}
                                                        onMouseOver={() => {
                                                            if (this.toolTipLeaveTimeOut !== null) {
                                                                clearTimeout(this.toolTipLeaveTimeOut);
                                                                this.toolTipLeaveTimeOut = null;
                                                            }
                                                        }}
                                                        open={open}
                                                        placement="top"
                                                    >
                                                        <Typography></Typography>
                                                        {/***<Paper className={classes.popperPaperChild}>
                                                            <Clear
                                                                className={classes.popperClear}
                                                                onClick={() => {
                                                                    if (this.toolTipEnterTimeOut !== null) {
                                                                        clearTimeout(this.toolTipEnterTimeOut);
                                                                    }
                                                                    this.setStateIfComponentMounted({
                                                                        showPopUp: false,
                                                                        anchorEl: null,
                                                                    });
                                                                }}
                                                            />
                                                            <Typography className={[classes.showText, classes.RobotoMonoImportant].join(' ')}>
                                                                {testStep.instr}
                                                                {TestStepUtils.dataHoverPreview(
                                                                    testStep.data,
                                                                    testStep.instr,
                                                                    'popper',
                                                                    userVariables,
                                                                    testDataColumns,
                                                                )}
                                                            </Typography>
                                                        </Paper>***/}
                                                    </Popper>
                                                </Grid>
                                                <Grid item xs={2} sm={3} md={5} lg={3} className={classes.display}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            sm={10}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-end',
                                                            }}
                                                        >
                                                            <div style={{marginRight:'10px'}}>
                                                                <RowActionButtons index={index} testStep={testStep} isExecution />
                                                            </div>
                                                        </Grid>
                                                        <Grid item sm={2} className={classes.timeContainer}>
                                                            {showTimer ? (
                                                                <span className={classes.timerSpan}>
                                                                    {testStep.duration
                                                                        ? getSecondsToFormatedTime(Math.round(testStep.duration), 'MM:SS')
                                                                        : '--:--'}
                                                                </span>
                                                            ) : null}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ) : (
                                        <div
                                            className={[
                                                classes.screenViewRow,
                                                `${testStep.status}` !== '5' ? classes.testNameColored : classes.testnamedisabled,
                                            ].join(' ')}
                                        >
                                            <Grid container>
                                                <Grid
                                                    item
                                                    sm={10}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <StatusSnipet
                                                        testStepStatus={testStep.status}
                                                        testStepMsg={testStep.message || ''}
                                                        testStepNumber={testStep.instrNum}
                                                        testStep={testStep}
                                                        handleWarnPopoverEnter={this.handleWarnPopoverEnter}
                                                        handleWarnPopoverLeave={() => this.handleReplayPopoverLeave()}
                                                        callFrom="testStepTableData"
                                                    />
                                                    <Popper
                                                        id={id}
                                                        placement="top"
                                                        open={openWarn}
                                                        anchorEl={anchorElWarn}
                                                        className={classes.warnPaper}
                                                        onMouseLeave={this.handleWarnPopoverLeave}
                                                        onMouseOver={() => {
                                                            if (this.toolTipLeaveTimeOutWarn !== null) {
                                                                clearTimeout(this.toolTipLeaveTimeOutWarn);
                                                                this.toolTipLeaveTimeOutWarn = null;
                                                            }
                                                        }}
                                                    >
                                                        <Paper className={classes.popperPaperChild}>
                                                            <Clear
                                                                className={classes.popperClear}
                                                                onClick={() => {
                                                                    if (this.toolTipEnterTimeOutWarn !== null) {
                                                                        clearTimeout(this.toolTipEnterTimeOutWarn);
                                                                    }
                                                                    this.setStateIfComponentMounted({
                                                                        showPopUpWarn: false,
                                                                        anchorElWarn: null,
                                                                    });
                                                                }}
                                                            />
                                                            <Typography
                                                                dangerouslySetInnerHTML={{ __html: testStep.message }}
                                                                className={[classes.showText, classes.RobotoMonoImportant].join(' ')}
                                                            />
                                                        </Paper>
                                                    </Popper>
                                                    <span
                                                        className={[classes.screenViewStepNumberColumnStyle, classes.RobotoMonoImportant].join(' ')}
                                                    >
                                                        <span
                                                            className={classes.screenViewStepNumberColumnChildStyle}
                                                        >{`${testStep.instrNum} `}</span>
                                                    </span>

                                                    {testStep && (
                                                        <div className={classes.screenShotContainer}>
                                                            {this.imageHoverPreview(classes, () => {
                                                                toggleScreenShotModal(true, index);
                                                            })}
                                                            <PreLoadImage
                                                                key={TestStepUtils.getPreviewImage(testStep)}
                                                                alt=""
                                                                className={classes.screenShotThumbnail}
                                                                src={TestStepUtils.getPreviewImage(testStep)}
                                                            />
                                                        </div>
                                                    )}
                                                    <Tooltip position={this.state.tooltipPosition}  data={testStep.instr}>
                                                        <span
                                                            className={classes.screenViewStepInstrDataContainer}
                                                            onMouseEnter={this.handlePopoverOpen}
                                                            onMouseLeave={this.handlePopoverTimer}
                                                        >

                                                            <span
                                                                className={[
                                                                    classes.screenViewStepInstructionColumnStyle,
                                                                    classes.RobotoMonoImportant,
                                                                    classes.center
                                                                ].join(' ')}
                                                            >
                                                                {`${testStep.instr}`}
                                                            </span>
                                                        </span>
                                                    </Tooltip>
                                                        <span className={[classes.screenViewDataColumnStyle, classes.RobotoMonoImportant,classes.center].join(' ')}>
                                                            <Tooltip data={` ${ TestStepUtils.renderDataField(
                                                                testStep.instr,
                                                                testStep.data,
                                                                userVariables,
                                                                testDataColumns,
                                                            )}`}>
                                                            {TestStepUtils.renderDataField(
                                                                testStep.instr,
                                                                testStep.data,
                                                                userVariables,
                                                                testDataColumns,
                                                            )}
                                                            </Tooltip>
                                                        </span>
                                                </Grid>
                                                <Grid item sm={2} className={classes.poperGrid}>
                                                    <Popper
                                                        id={id}
                                                        anchorEl={anchorEl}
                                                        className={classes.paper}
                                                        onMouseLeave={this.handlePopoverTimer}
                                                        onMouseOver={() => {
                                                            if (this.toolTipLeaveTimeOut !== null) {
                                                                clearTimeout(this.toolTipLeaveTimeOut);
                                                                this.toolTipLeaveTimeOut = null;
                                                            }
                                                        }}
                                                        open={open}
                                                        placement="top"
                                                    >
                                                        <Typography></Typography>
                                                    {/***<Paper className={classes.popperPaperChild}>
                                                            <Clear
                                                                className={classes.popperClear}
                                                                onClick={() => {
                                                                    if (this.toolTipEnterTimeOut !== null) {
                                                                        clearTimeout(this.toolTipEnterTimeOut);
                                                                    }
                                                                    this.setStateIfComponentMounted({
                                                                        showPopUp: false,
                                                                        anchorEl: null,
                                                                    });
                                                                }}
                                                            />
                                                            <Typography className={[classes.showText, classes.RobotoMonoImportant].join(' ')}>
                                                                {testStep.instr}
                                                                {TestStepUtils.dataHoverPreview(
                                                                    testStep.data,
                                                                    testStep.instr,
                                                                    'popper',
                                                                    userVariables,
                                                                    testDataColumns,
                                                                )}
                                                            </Typography>
                                                        </Paper>***/}
                                                    </Popper>
                                                    <div>
                                                        <RowActionButtons index={index} testStep={testStep} isExecution styles={{ width: 50 }} />
                                                    </div>
                                                    {showTimer ? (
                                                        <span className={classes.stepDuration}>
                                                            {testStep.duration
                                                                ? getSecondsToFormatedTime(Math.round(testStep.duration), 'MM:SS')
                                                                : '--:--'}
                                                        </span>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <LoadingStepRow listView={listView} itemKey={stepKey} />
                )}
            </div>
        );
        return data;
    }
}

ExecutionStepRow.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    index: PropTypes.number.isRequired,
    selectedProject: PropTypes.shape({}).isRequired,
    userVariables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    testStep: PropTypes.shape({}).isRequired,
};
const mapStateToProps = (state /* , props */) => {
    return {
        // Project reducer
        selectedProject: state.projectReducer.selectedProject,
        testDataColumns: state.projectReducer.testDataColumns,
        openTaskPanel: state.generalReducer.openTaskPanel,
        // Selected Test Case Reducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // Selected Test Case Action
        setActionType: (...args) => dispatch(SelectedTestCaseActions.setActionType(...args)),
        toggleStepCacheXpath: (...args) => dispatch(SelectedTestCaseActions.toggleStepCacheXpath(...args)),
        // test case action
        toggleScreenShotModal: (...args) => dispatch(TestCaseActions.toggleScreenShotModal(...args)),
        // Project Action
        shwoTost: () => dispatch(ProjectActions.ShowToast()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionStepRow));
