import { DialogActions, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
    dialogContainer: {
        minWidth: '700px',
        width: '70%',
        minHeight: '600px',
    },
    logtitle: {
        width: '80%',
        textAlign: 'center',
    },
    name: {
        fontWeight: 'bold',
    },
    methodcontent: {
        display: 'flex',
        flexDirection: 'column',
        /* border-top: .5px solid, */
        padding: ' 10px',
    },
    statusFail: {
        color: 'red',
    },
    statusPass: {
        color: 'green',
    },
    details: {
        resize: 'vertical',
        minHeight: '400px',
        maxHeight: '500px',
        minWidth: '520px',
        maxWidth: '550px',
    },
});

// let timer;
// const currenttime = 0;

class ExecutionStatusModal extends React.Component {
    handleClose = (e) => {
        this.props.handleClose();
        e.stopPropagation();
    };

    handleSnackBackError = () => {
        this.props.handleClose();
    };

    render() {
        const { classes, modalState, data } = this.props;
        /* eslint-disable jsx-a11y/media-has-caption */
        return (
            <Dialog
                disableRestoreFocus
                open={modalState}
                onClose={this.handleClose}
                classes={{ paper: classes.dialogContainer }}
                aria-labelledby="simple-dialog-title"
            >
                <DialogActions>
                    <DialogTitle className={classes.logtitle}>Test Execution Status</DialogTitle>
                    <IconButton variant="fab" onClick={this.handleClose} color="secondary" aria-label="Close">
                        <Close aria-label="closeIcon" id="closeIcon" />
                    </IconButton>
                </DialogActions>
                <ul>
                    {data.classes.map((cls, index) => (
                        <li key={index}>
                            <span className={classes.name}>{cls.className}</span>
                            <ol>
                                {cls.testMethods.map((method, methodIndex) => (
                                    <li key={methodIndex} className={classes.methodcontent}>
                                        <span>Name: {method.name === '' ? 'N/A' : method.name}</span>
                                        <span>Duration (ms): {method.durationMs}</span>
                                        <span className={`${method.status === 'FAIL' ? `${classes.statusFail}` : `${classes.statusPass}`}`}>
                                            Status: {method.status}
                                        </span>
                                        {method.status === 'FAIL' && (
                                            <span>
                                                <div>Exception:</div>
                                                {method.exception.exceptionClass && method.exception.stacktrace ? (
                                                    <div>
                                                        <textarea
                                                            className={classes.details}
                                                            rows="6"
                                                            cols="50"
                                                            readOnly
                                                            value={method.exception.exceptionClass + method.exception.stacktrace}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <textarea rows="6" cols="50" readOnly value={method.exception} />
                                                    </div>
                                                )}
                                            </span>
                                        )}
                                    </li>
                                ))}
                            </ol>
                        </li>
                    ))}
                </ul>
            </Dialog>
        );
        /* eslint-enable jsx-a11y/media-has-caption */
    }
}

ExecutionStatusModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    modalState: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    data: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ExecutionStatusModal);
