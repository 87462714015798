import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { GeneralActions, ProjectActions, AccountActions, TestCaseActions } from '../../../store/actions';
import { checkArrayLength, checkObject, sort, checkKeyInObject, getTestCaseStatusWithout_ws, TestCaseUtils } from '../../../utils';
import usePrevious from '../../../common/UsePrevious';
import { SEGMENT_EVENT } from '../../../common/constants';
import { track } from '../../../services/Segment';
// import { headers } from './constants';

const useContainer = (props) => {
    // let testCases_forTesting = {};
    // const testingFunction_getTestCase = () => {
    //     // eslint-disable-next-line no-console
    //     console.log(testCases_forTesting);
    // };

    const dispatch = useDispatch();
    const dispatchActions = () => {
        return {
            setSelectedTask: (newTask) => dispatch(ProjectActions.setSelectedTask(newTask)),
            createTestCase: (data, projectId) => dispatch(ProjectActions.createTestCase(data, projectId)),
            getTestCases: (...args) => dispatch(ProjectActions.getTestCases(...args)),
            queryRemove: () => dispatch(GeneralActions.queryRemove()),
            getAccountsPrefrences: (id) => dispatch(AccountActions.getAccountsPrefrences(id)),
            updateCaseOrder: (...args) => dispatch(TestCaseActions.updateCaseOrder(...args)),
        };
    };
    const actions = dispatchActions();

    const useRedux = (state) => {
        return {
            user: state.authReducer.user,

            query: state.generalReducer.queryValue,
            project: state.projectReducer.selectedProject,
            isLoading: state.projectReducer.isLoading,

            prefences: state.accountReducer.prefences,
            getPreferences: state.accountReducer.getPrefences,
            getPreferencesErr: state.accountReducer.getPrefencesErr,

            isLoadingCases: state.projectReducer.isLoadingCases,
            testCases: state.projectReducer.testCases,
        };
    };
    const redux = useSelector(useRedux);

    const [state, updateState] = useState({
        order: 'desc',
        orderBy: 'createdTime',
        sortedTestCases: [],
        filteredTestCases: [],
        // heads: [],
        // cols: [],
        rowsPerPage: 20,
        page: 0,
        selectedCases: [],
    });
    const [pageNo,setPageNo] = useState(null);
    const setState = (newState) => {
        updateState((p) => {
            const updatedKeys = typeof newState === 'function' ? newState(p) : checkObject(newState) ? newState : {};
            return { ...p, ...updatedKeys };
        });
    };

    const previousData = usePrevious({ testCases: redux.testCases, query: redux.query });

    useEffect(() => {
        window.testingFunction_getTestCase = () => {
            // testingFunction_getTestCase();
        };
        const { order, orderBy } = state;
        const { user } = redux;
        const { updateCaseOrder, getAccountsPrefrences } = actions;
        updateCaseOrder(order, orderBy);

        const caseSettingRows = localStorage.getItem('rowsCasePerPage');
        if (caseSettingRows) {
            setState({
                rowsPerPage: parseInt(caseSettingRows, 10),
            });
        }

        getAccountsPrefrences(user.accountId);
        return () => {
            const { queryRemove, setSelectedTask } = actions;
            setSelectedTask(-1);
            queryRemove();
            delete window.testingFunction_getTestCase;
        };
    }, []);

    useEffect(() => {
        // JSON.parse(JSON.stringify(state.sortedTestCases));
    }, [state.sortedTestCases]);

    useEffect(() => {
        const { testCases, query = '', project } = redux;
        const { order, orderBy, sortedTestCases } = state;
        let obj = {};
        let filteredTestCases = sortedTestCases;
        let forcedFilter = false;
        let prevPageNo = parseInt(pageNo || 0)
        if(!redux.query) {
            setState({
                page:prevPageNo
            })
            
        }
        if (
            !Array.isArray(sortedTestCases) ||
            (Array.isArray(testCases) && sortedTestCases.length !== testCases.length) ||
            !previousData ||
            !isEqual(testCases, previousData.testCases)
        ) {
            let _sortedTestCases = checkArrayLength(testCases)
                ? testCases.map((testCase) => {
                      return {
                          ...testCase,
                          appUrl: project.appUrl,
                          projectName: project.name,
                          sortingStatus: getTestCaseStatusWithout_ws(testCase).msg,
                          lastRun: testCase.lastRun !== testCase.createdTime ? testCase.lastRun : null,
                      };
                  })
                : [];
            _sortedTestCases = checkArrayLength(_sortedTestCases)
                ? sort(_sortedTestCases, order, orderBy, orderBy === 'lastRun' || orderBy === 'createdTime' ? 'time' : '')
                : [];
            filteredTestCases = _sortedTestCases;
            forcedFilter = true;
            obj = { ...obj, sortedTestCases: _sortedTestCases, filteredTestCases };
        }
        if (checkArrayLength(filteredTestCases) && (!previousData || query !== previousData.query || forcedFilter)) {
            if (query) {
                filteredTestCases = filteredTestCases.filter(
                    (testCase) => checkKeyInObject(testCase, 'testCaseName') && testCase.testCaseName.toLowerCase().includes(query.toLowerCase()),
                );
            }
            obj = { ...obj, filteredTestCases };
        }
        setState(obj);
    }, [redux.testCases, redux.query, redux.project, state.order, state.orderBy, state.sortedTestCases.abbrev,pageNo,previousData]);

    const createTestCase = () => {
        const { history, match } = props;
        const {
            project: { appUrl, projectId, projectName },
        } = redux;
        
        const eventName = SEGMENT_EVENT.CREATE_TEST_WRITE;
        let segmentData = {
            project_id:projectId,
            project_name:projectName,
            app_url:appUrl
        }
        
        track(eventName,segmentData);

        const newTestCase = TestCaseUtils.getDummyTestCase(projectId, projectName, appUrl);
        actions.createTestCase(newTestCase, projectId);
        if (match) {
            const { params } = match;
            if (params && Object.keys(params).length > 0) {
                history.push({
                    pathname: `/projects/${params.projectId}/${newTestCase.testCaseId}`,
                });
            }
        }
    };

    let caseInterval = null;
    let getUpdateCase = () => {};
    const stopUpdate = () => {
        clearInterval(caseInterval);
    };
    const getUpdate = () => {
        const { project } = redux;
        caseInterval = setInterval(() => {
            getUpdateCase = localStorage.getItem('addedNewCase');
            if (getUpdateCase) {
                actions.getTestCases(project.projectId);
                localStorage.removeItem('addedNewCase');
                stopUpdate();
            }
        }, 500);
    };

    const recordTestCase = async (accountId, projectSystemId, discoveryId, RecordData, fIndex, idModal, callBackForLoader = () => {}) => {
        localStorage.removeItem('addedNewCase');
        getUpdate();
        TestCaseUtils.recordTestCase({ accountId, projectSystemId, discoveryId, RecordData, fIndex, callBackForLoader, idModal });
        return null;
    };

    const handleRequestSort = (property) => {
        const { sortedTestCases } = state;
        const { query = '' } = redux;
        const { updateCaseOrder } = actions;
        const orderBy = property;
        let order = 'desc';

        if (state.orderBy === property && state.order === 'desc') {
            order = 'asc';
        }

        const _sortedTestCases = checkArrayLength(sortedTestCases)
            ? sort(sortedTestCases, order, orderBy, orderBy === 'lastRun' || orderBy === 'createdTime' ? 'time' : '')
            : [];
        let filteredTestCases = _sortedTestCases;
        if (query && checkArrayLength(filteredTestCases)) {
            filteredTestCases = filteredTestCases.filter(
                (testCase) => checkKeyInObject(testCase, 'testCaseName') && testCase.testCaseName.toLowerCase().includes(query.toLowerCase()),
            );
        }
        updateCaseOrder(order, orderBy);
        setState({ order, orderBy, filteredTestCases, sortedTestCases: _sortedTestCases });
    };

    const handleChangePage = (page) => {
        setState({ page });
        if(!redux.query) {
            setPageNo(page)
        }
    };

    const handleChangeRowsPerPage = (event) => {
        localStorage.setItem('rowsCasePerPage', event.target.value);
        setState({ rowsPerPage: event.target.value });
    };

    const handleSelect = (testCaseId) => {
        setState((prev) => {
            const { selectedCases } = prev;
            const _selectedCases = [...selectedCases];
            const ind = _selectedCases.indexOf(testCaseId);
            if (ind >= 0) {
                _selectedCases.splice(ind, 1);
            } else {
                _selectedCases.push(testCaseId);
            }
            return { selectedCases: _selectedCases };
        });
    };

    const handleSelectAllClick = (event, currentPageCases) => {
        const { checked } = event.target;
        selectDeselectAll(checked, currentPageCases);
    };

    const selectDeselectAll = (checked, currentPageCases) => {
        const { selectedCases } = state;
        const _selectedCases = [...selectedCases];
        if (checkArrayLength(currentPageCases)) {
            currentPageCases.forEach((testCase) => {
                const ind = _selectedCases.indexOf(testCase.testCaseId);
                if (ind >= 0 && !checked) {
                    _selectedCases.splice(ind, 1);
                } else if (ind === -1 && checked) {
                    _selectedCases.push(testCase.testCaseId);
                }
            });
        }
        setState({ selectedCases: _selectedCases });
    };

    return {
        actions,
        redux,
        state,
        handleRequestSort,
        handleChangePage,
        handleChangeRowsPerPage,
        createTestCase,
        recordTestCase,
        handleSelectAllClick,
        handleSelect,
    };
};

export default useContainer;
