// packages
import React from 'react';
// material ui core
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
// icons -> material
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Share from '@material-ui/icons/Share';
import CallMerge from '@material-ui/icons/CallMerge';
import Settings from '@material-ui/icons/Settings';
import FileDownload from '@material-ui/icons/GetApp';
import Upload from '@material-ui/icons/Publish';
import VideoCam from '@material-ui/icons/Videocam';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import CircularProgress from '@material-ui/core/CircularProgress';

import '../../app/app.css';
import { ModalActions, generalModalActions, TestCaseActions, ProjectActions } from '../../store/actions';
// Images
import AiqSvg from './images/AiqSvg'
import { checkKeyInObject } from '../../utils/utils';

import Tooltip from '../Tooltip';
import MergeModalMerge from '../MergeModal/MergeModal_Merge';
import MergeModalArrange from '../MergeModal/MergeModal_Arrange';
import ActionMenu from '../Menu/ActionMenu';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON } from '../Button/Btn';
import { CustomDownloadModal } from '../CustomModal/CustomDownloadModal';

// styles
const styles = () => ({
    headingContainer: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        alignItems: 'center',
        padding: '0px 15px !important',
    },
    noButton: {
        height: 13,
    },
    emptyDiv: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        height: 'calc(100vh - 0px)',
        left: 0,
        position: 'fixed',
        top: 0,
        width: 'calc(100vw - 0px)',
        zIndex: 1201,
        '&:hover': {
            display: 'none !important',
        },
    },
    moreIconButton: {
        height: '25px',
        width: '25px',
        margin: '10px 10px 10px 0px',
        backgroundColor: '#3B91DF',
        boxShadow: '0 0 2px 1px rgba(0,0,0,0.12), 0 2px 7px 0 rgba(0,0,0,0.7)',
        zIndex: 1202,
        float: 'right',
        '&:hover': {
            backgroundColor: '#3B91DF !important',
        },
    },
    hoverParent: {
        '&>div': {
            display: 'none',
        },
        '&:hover': {
            '& + aside': {
                width: 'calc(100% - 45px) !important',
            },
            '&>div': {
                display: 'block',
            },
        },
    },
    childButtonsWrapper: {
        '-webkit-transition': 'width 0.5s',
        transition: 'width 0.5s',
        display: 'flex',
        width: 0,
        overflow: 'hidden',
        maxHeight: 45,
        float: 'right',
        justifyContent: 'flex-end',
        '&>span': {
            display: 'none',
        },
        '&>div': {
            zIndex: 1202,
            display: 'flex',
            justifyContent: 'flex-end',
        },
        '&:hover': {
            width: 'calc(100% - 45px) !important',
            '&>span': {
                display: 'block',
            },
        },
    },
    childIconButton: {
        height: '24px',
        width: '24px',
        margin: '10px 10px 10px 0px',
        backgroundColor: '#FFFFFF !important',
        boxShadow: '0 0 2px 1px rgba(0,0,0,0.12), 0 2px 7px 0 rgba(0,0,0,0.7)',
        '&:hover': {
            backgroundColor: '#f5f5f5 !important',
        },
    },
    customAddButton: {
        height: '24px',
        width: '24px',
        borderRadius: '12px',
        margin: '10px 10px 10px 0px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF !important',
        boxShadow: '0 0 2px 1px rgba(0,0,0,0.12), 0 2px 7px 0 rgba(0,0,0,0.7)',
        '&:hover': {
            backgroundColor: '#f5f5f5 !important',
        },
    },
    childIconButtonRecord: {
        height: '24px',
        width: '24px',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 2px 1px rgba(0,0,0,0.12), 0 2px 7px 0 rgba(0,0,0,0.7)',
        margin: '10px 10px 10px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    recordLoader: {},
    moreIcon: {
        fontSize: 18,
        color: '#FFFFFF',
    },
    childIcon: {
        fontSize: 18,
        color: '#5F7B96',
    },
    addIcon: {
        fontSize: 18,
        color: '#5F7B96',
        marginTop: 2,
        cursor: 'pointer',
    },
    childIconDisabled: {
        fontSize: 18,
        color: 'rgba(0, 0, 0, 0.26)',
    },
    uploadFileInput: {
        display: 'none',
    }
});

class ProjectItemSummary extends React.Component {
    constructor() {
        super();
        this.state = {
            rows: [
                { label: 'Name', status: 'Lock', name: 'Name' },
                { label: 'uploaded', status: 'Active', name: 'Uploaded' },
                { label: 'scriptGeneration', status: 'Active', name: 'Script Generation' },
                { label: 'lastGenerated', status: 'Active', name: 'Last Generated' },
                { label: 'Actions', status: 'Lock', name: 'Actions' },
            ],
            anchorEl: null,
            recordLoader: false,
        };
    }

    // ///////////////////UI Handle recorder play/stop option ///////////////////////////
    // pauseIcon = () => {
    //     const { blink } = this.state;
    //     if (blink) {
    //         this.setState({ blink: false }, async () => {
    //             await PluginService.pauseRecording('pause');
    //         });
    //     } else if (!blink) {
    //         this.setState({ blink: true }, async () => {
    //             await PluginService.pauseRecording('pause');
    //         });
    //     }
    // };
    // /////////////////////////////////////////////////////////////////////////////////

    closeMergeModal = () => {
        this.props.toggleModal();
        setTimeout(() => {
            this.props.clearGeneralModal();
        }, 500);
    };

    renderItems() {
        const { classes, recordTestCase, projectSystemId, user } = this.props;
        const discoveryId = projectSystemId;
        const items = [
            {
                action: (e) => {
                    e.stopPropagation();
                    this.setState({ anchorEl: null, recordLoader: true }, () => {
                        recordTestCase(
                            user.accountId,
                            projectSystemId,
                            discoveryId,
                            () => {
                                this.setState({ recordLoader: false });
                            },
                            '',
                            true,
                        );
                    });
                },
                icon: (
                    <FontAwesomeIcon
                        style={{ padding: '0 7px 0 10px', fontSize: '0.8 em' }}
                        icon={faVideo}
                        aria-label="faVideo"
                        id="faVideo"
                        className={classes.moreIcons}
                    />
                ),
                isDisabled: false,
                name: 'Open',
            },
            {
                action: (e) => {
                    e.stopPropagation();
                    setTimeout(() => {
                        this.setState(
                            { anchorEl: null, recordLoader: true },
                            () => {
                                recordTestCase(user.accountId, projectSystemId, discoveryId, () => {
                                    this.setState({ recordLoader: false });
                                });
                            },
                            '',
                            true,
                        );
                    }, 100);
                    this.setState({ anchorEl: null });
                    return false;
                },
                icon: (
                    <FontAwesomeIcon
                        style={{ padding: '0 7px 0 10px', fontSize: '0.8 em' }}
                        icon={faFolderOpen}
                        aria-label="faFolderOpen"
                        id="faFolderOpen"
                        className={classes.moreIcons}
                    />
                ),
                isDisabled: false,
                name: 'Load unpacked',
            },
        ];
        return <ActionMenu items={items} />;
    }

    render() {
        const {
            appUrl,
            badgeName,
            badgeValue,
            classes,
            user,
            recordTestCase,
            projectSystemId,
            createTestCase,
            createTestSuite,
            toggleModal,
            updateNewData,
            toggleBlockModal,
            handleAddNewVariable,
            downloadData,
            projectName,
            onClickAlertDialogOpen,
            isShow,
            cloneAllVariables,
            prefences,
            getPrefences,
            handleDownloadPopupToggle,
            handleDownloadBlock,
            disableDownloadFlow,
            toggleIdModal,
            handleUploadJSONFile,
        } = this.props;
        const { rows, anchorEl, recordLoader } = this.state;
        let headers = [];

        if (getPrefences && prefences.accounts) {
            const {
                accounts: { testcaseColumns },
            } = prefences;
            const parsed = JSON.parse(testcaseColumns);
            headers = rows.map((val) => {
                if (parsed.includes(val.label)) {
                    val.status = true;
                    return val;
                }
                val.status = false;
                return val;
            });
        } else {
            headers = [
                { label: 'Name', status: false, name: 'Name' },
                { label: 'Uploaded', status: false, name: 'Uploaded' },
                { label: 'Script Genereration', status: false, name: 'Script Genereration' },
                { label: 'Last Generated', status: false, name: 'Last Generated' },
                { label: 'Actions', status: false, name: 'Actions' },
            ];
        }
        const discoveryId = projectSystemId;
        const btns = [];

        if (badgeName === 'Suites' || badgeName === 'Suite') {
            btns.push(
                <Tooltip data="Create Test Suite" key="SuitesAddIcon">
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!(this.props.isLoading || this.props.isLoadingCases) && typeof createTestSuite === 'function') {
                                createTestSuite();
                            }
                        }}
                        aria-label="Add"
                        className={classes.childIconButton}
                        id="createTestSuite"
                        style={this.props.isLoading || this.props.isLoadingCases ? { cursor: 'not-allowed' } : {}}
                        disabled={this.props.isLoading || this.props.isLoadingCases}
                    >
                        <AddIcon className={classes.childIcon} aria-label="addIcon" id="addIcon" />
                    </IconButton>
                </Tooltip>,
            );
        }
        if (badgeName === 'Cases' || badgeName === 'Case') {
            if (toggleModal) {
                btns.push(
                    <Tooltip data="Create Test Case" key="CasesAddIcon">
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                createTestCase();
                            }}
                            aria-label="Create"
                            className={classes.childIconButton}
                            id="createTestCase"
                        >
                            <AddIcon className={classes.childIcon} aria-label="addIcon" id="addIcon" />
                        </IconButton>
                    </Tooltip>,
                );

                btns.push(
                    <Tooltip data="Upload" key="CasesFaUpload">
                        <IconButton
                            aria-label="Upload"
                            className={classes.childIconButton}
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleModal('uploadTestCaseStep', null, null, projectSystemId);
                            }}
                            id="uploadTestCaseStep"
                        >
                            <Upload className={classes.childIcon} aria-label="uploadIcon" id="uploadIcon" />
                        </IconButton>
                    </Tooltip>,
                );
            }

            if (recordTestCase) {
                btns.push(
                    <Tooltip data="Record" key="CasesFaVideo">
                        {recordLoader ? (
                            <div className={classes.childIconButtonRecord}>
                                <CircularProgress className={classes.recordLoader} size={20} />
                            </div>
                        ) : (
                            <IconButton
                                color="inherit"
                                aria-label="Record"
                                style={{ marginTop: '10px' }}
                                className={classes.childIconButton}
                                onClick={() => {
                                    this.setState({ anchorEl: null, recordLoader: true }, () => {
                                        recordTestCase(
                                            user.accountId,
                                            projectSystemId,
                                            discoveryId,
                                            () => {
                                                this.setState({ recordLoader: false });
                                            },
                                            '',
                                            false,
                                        );
                                    });
                                }}
                                onContextMenu={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    this.setState({ anchorEl: null }, () => {
                                        toggleIdModal(true);
                                    });
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faVideo}
                                    aria-label="faVideo"
                                    id="faVideo"
                                    className={classes.icon}
                                    style={{ color: '#5F7B96', fontSize: '16px' }}
                                />
                            </IconButton>
                        )}
                    </Tooltip>,
                );
            }

            btns.push(
                <Tooltip data="(Beta)" key="Generateflow">
                    <IconButton
                        aria-label="Generate flow"
                        className={classes.childIconButton}
                        disabled
                        onClick={(e) => {
                            e.stopPropagation();
                            this.props.toggleModal('adminUserModal', null, '', { modaltype: 'AutoGenerate', appUrl, projectId: projectSystemId });
                        }}
                        id="generateFlow"
                    >
                        <AiqSvg />
                    </IconButton>
                </Tooltip>,
            );

            btns.push(
                <Tooltip data="Manage columns" key="ArrangeColumns">
                    <IconButton
                        aria-label="Manage columns"
                        className={classes.childIconButton}
                        onClick={() => {
                            toggleModal('groupModal', null, null, { modaltype: 'ArrangeColumns', rows: headers });
                        }}
                        id="manageColumns"
                    >
                        <Settings className={classes.childIcon} aria-label="settingsIcon" id="settingsIcon" />
                    </IconButton>
                </Tooltip>,
            );

            btns.push(
                <Tooltip data="Merge test cases" key="MergeCases">
                    <IconButton
                        aria-label="Merge Cases"
                        className={classes.childIconButton}
                        id="mergeTestCases"
                        onClick={() => {
                            toggleModal('GeneralModal', null, null, {
                                title: 'Merge Cases',
                                closeIconAction: () => {
                                    this.closeMergeModal();
                                },
                                component: [
                                    {
                                        name: 'Merge',
                                        content: <MergeModalMerge />,
                                        buttons: [
                                            NEXT_SUBMIT_BUTTON({
                                                name: 'Next',
                                                action: () => {
                                                    this.props.changeTab(this.props.currentTab + 1);
                                                },
                                            }),
                                            CANCEL_BUTTON({ action: this.closeMergeModal }),
                                        ],
                                    },
                                    {
                                        name: 'Arrange',
                                        content: <MergeModalArrange />,
                                        buttons: [
                                            NEXT_SUBMIT_BUTTON({
                                                name: 'Save',
                                                action: () => {
                                                    const { tabsData, mergeTestCases } = this.props;
                                                    const newCaseName =
                                                        checkKeyInObject(tabsData, 'merge') &&
                                                        checkKeyInObject(tabsData.merge, 'state') &&
                                                        checkKeyInObject(tabsData.merge.state, 'newCaseName')
                                                            ? tabsData.merge.state.newCaseName
                                                            : '';
                                                    const testCasesToMerge =
                                                        checkKeyInObject(tabsData, 'merge') &&
                                                        checkKeyInObject(tabsData.merge, 'state') &&
                                                        checkKeyInObject(tabsData.merge.state, 'selectedTestCaseIds')
                                                            ? [...tabsData.merge.state.selectedTestCaseIds]
                                                            : [];
                                                    mergeTestCases(newCaseName, testCasesToMerge, projectSystemId);
                                                    this.closeMergeModal();
                                                },
                                                isDisabled: (tabsData) => {
                                                    const isDuplicate =
                                                        checkKeyInObject(tabsData, 'merge') &&
                                                        checkKeyInObject(tabsData.merge, 'state') &&
                                                        checkKeyInObject(tabsData.merge.state, 'isDuplicate')
                                                            ? tabsData.merge.state.isDuplicate
                                                            : '';
                                                    const newCaseName =
                                                        checkKeyInObject(tabsData, 'merge') &&
                                                        checkKeyInObject(tabsData.merge, 'state') &&
                                                        checkKeyInObject(tabsData.merge.state, 'newCaseName')
                                                            ? tabsData.merge.state.newCaseName
                                                            : '';
                                                    const testCasesToMerge =
                                                        checkKeyInObject(tabsData, 'merge') &&
                                                        checkKeyInObject(tabsData.merge, 'state') &&
                                                        checkKeyInObject(tabsData.merge.state, 'selectedTestCaseIds')
                                                            ? [...tabsData.merge.state.selectedTestCaseIds]
                                                            : [];
                                                    return isDuplicate || !(!!newCaseName && testCasesToMerge.length > 1);
                                                },
                                            }),
                                            CANCEL_BUTTON({ action: this.closeMergeModal }),
                                        ],
                                    },
                                ],
                            });
                        }}
                    >
                        <CallMerge className={classes.childIcon} aria-label="callMergrIcon" id="callMergeIcon" />
                    </IconButton>
                </Tooltip>,
            );
        }

        if (!(badgeName === 'Cases' || badgeName === 'Case') && recordTestCase) {
            btns.push(
                <Tooltip data="Record" key="RecordFaVideo">
                    <IconButton
                        color="inherit"
                        aria-label="Record"
                        id="record"
                        className={classes.childIconButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            this.setState({ anchorEl: null, recordLoader: true }, () => {
                                recordTestCase(
                                    user.accountId,
                                    projectSystemId,
                                    discoveryId,
                                    () => {
                                        this.setState({ recordLoader: false });
                                    },
                                    '',
                                    false,
                                );
                            });
                        }}
                    >
                        <VideoCam className={classes.childIcon} aria-label="videoCamIcon" id="videoCamIcon" />
                    </IconButton>
                </Tooltip>,
            );
        }

        if ((badgeName === 'Data Files' || badgeName === 'Data File') && toggleModal) {
            btns.push(
                <Tooltip data="Upload" key="DataFilesUpload">
                    <IconButton
                        color="inherit"
                        aria-label="Add data"
                        className={classes.childIconButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleModal('uploadData', null, '', { projectSystemId, updateNewData, accountId: user.accountId });
                        }}
                        id="uploadDataFile"
                    >
                        <Upload className={classes.childIcon} aria-label="uploadIcon" id="uploadIcon" />
                    </IconButton>
                </Tooltip>,
            );

            btns.push(
                <Tooltip data="Upload file" key="uploadFile">
                    <div className={classes.customAddButton}>
                        <input
                            accept="application/JSON"
                            className={classes.uploadFileInput}
                            id="contained-button-file"
                            type="file"
                            onChange={handleUploadJSONFile}
                        />
                        <label htmlFor="contained-button-file">
                            <AddIcon className={classes.addIcon} aria-label="addIcon" id="addIcon" />
                        </label>
                    </div>
                </Tooltip>,
            );
        }
        if (badgeName === 'Variables' || badgeName === 'Variable') {
            if (isShow) {
                btns.push(
                    <Tooltip data="Share Variables" key="CloneVariables">
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                cloneAllVariables();
                            }}
                            className={classes.childIconButton}
                            id="shareVariable"
                        >
                            <Share className={classes.childIcon} aria-label="shareIcon" id="shareIcon" />
                        </IconButton>
                    </Tooltip>,
                );
            }

            btns.push(
                <Tooltip data="Add Variable" key="AddVariable">
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAddNewVariable();
                        }}
                        aria-label="Add"
                        className={classes.childIconButton}
                        id="addVariable"
                    >
                        <AddIcon className={classes.childIcon} aria-label="addIcon" id="addIcon" />
                    </IconButton>
                </Tooltip>,
            );

            btns.push(
                <Tooltip data="Clear All Variable" key="ClearVariable">
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            if (badgeValue > 0) {
                                onClickAlertDialogOpen();
                            }
                        }}
                        aria-label="Clear"
                        id="clearVariables"
                        className={classes.childIconButton}
                        disabled={badgeValue < 1}
                    >
                        <ClearIcon className={badgeValue < 1 ? classes.childIconDisabled : classes.childIcon} aria-label="clearIcon" id="clearIcon" />
                    </IconButton>
                </Tooltip>,
            );

            btns.push(
                <Tooltip data="Download All Variable" key="DownloadVariable">
                    <IconButton
                        aria-label="Download"
                        className={classes.childIconButton}
                        disabled={badgeValue < 1}
                        id="downloadVariables"
                        onClick={(e) => {
                            e.stopPropagation();
                            const obj = {
                                data: downloadData,
                                name: `${projectName}_Variables`,
                                fileName: `${projectName}_Variables`,
                                modalName: 'VariableModal',
                            };
                            toggleModal('filePreviewModal', null, null, obj);
                        }}
                    >
                        <FileDownload
                            className={badgeValue < 1 ? classes.childIconDisabled : classes.childIcon}
                            aria-label="fileDownloadIcon"
                            id="fileDownloadIcon"
                        />
                    </IconButton>
                </Tooltip>,
            );
        }
        if (badgeName === 'Flows' || badgeName === 'Flow') {
            btns.push(
                <Tooltip data="Create New Flow" key="CreateNewFlow">
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleBlockModal(true, {}, 'addFromHeader');
                        }}
                        id="createNewFlow"
                        aria-label="Create"
                        className={classes.childIconButton}
                    >
                        <AddIcon className={classes.childIcon} aria-label="addIcon" id="addIcon" />
                    </IconButton>
                </Tooltip>,
            );

            const dataSet = handleDownloadPopupToggle();
            btns.push(
                <CustomDownloadModal
                    key="DownloadFlow"
                    handleSubmit={(downloadType, projectFile) => {
                        handleDownloadBlock(downloadType, projectFile);
                    }}
                    dataSet={dataSet}
                    keepMounted
                    calledFrom="flow"
                    projectName={projectName}
                    containerStyle={{ height: 'unset' }}
                    btn={
                        <Tooltip data="Download Flow">
                            <IconButton className={classes.childIconButton} disabled={disableDownloadFlow} id="downloadFlow" aria-label="Download">
                                <FileDownload
                                    className={disableDownloadFlow ? classes.childIconDisabled : classes.childIcon}
                                    aria-label="fileDownloadIcon"
                                    id="fileDownloadIcon"
                                />
                            </IconButton>
                        </Tooltip>
                    }
                />,
            );
        }

        return btns.length ? (
            <div className={classes.headingContainer}>
                <div style={{ width: '100%' }}>
                    <div className={classes.hoverParent}>
                        <div className={classes.emptyDiv}></div>
                        <IconButton className={classes.moreIconButton} disableRipple>
                            <MoreHoriz className={classes.moreIcon} aria-label="moreHerizIcon" id="moreHorizIcon" />
                        </IconButton>
                    </div>
                    <aside className={classes.childButtonsWrapper}>
                        <span className={classes.emptyDiv}></span>
                        <div>
                            {btns.reverse()}
                            <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => this.setState({ anchorEl: null })}>
                                {this.renderItems()}
                            </Menu>
                        </div>
                    </aside>
                </div>
            </div>
        ) : (
            <div className={classes.noButton}></div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        prefences: state.accountReducer.prefences,
        getPrefences: state.accountReducer.getPrefences,
        currentTab: state.generalModalReducer.currentTab,
        tabsData: state.generalModalReducer.tabsData,
        user: state.authReducer.user,
        isLoadingCases: state.projectReducer.isLoadingCases,
        isLoading: state.projectReducer.isLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // Modal Action
        toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
        changeTab: (...args) => dispatch(generalModalActions.changeTab(...args)),
        clearGeneralModal: (...args) => dispatch(generalModalActions.clearGeneralModal(...args)),
        mergeTestCases: (...args) => dispatch(TestCaseActions.mergeTestCases(...args)),
        toggleIdModal: (bool) => dispatch(ProjectActions.toggleIdModal(bool)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectItemSummary));
