// material
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
// icons
import { faEye } from '@fortawesome/free-solid-svg-icons';
import WarningIcon from '@material-ui/icons/Warning';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import ConfirmIcon from '@material-ui/icons/CheckCircle';
import Restore from '@material-ui/icons/PowerSettingsNew';
import TableRow from '../Table/TableRow';
import TableCell from '../Table/TableCell';
// custom
import { THREE_DOT_MENU_CSS, TABLES_CSS, BLACK_FONT, MUI_ACTION_MENU_ICON } from '../../common/cssConstants';
import { getFormatedDate, checkKeyInObject, getRelativeTime } from '../../utils/utils';
import { MenuOptions } from '../Button/MenuOptions';
import ExpandBtn from '../Button/ExpandBtn';
import Tooltip from '../Tooltip';
// style
const styles = (theme) => ({
    AddIcon: {
        color: '#ff9700',
        fontSize: '25px',
    },
    avatar_: {
        minWidth: '20px',
        height: '20px',
        borderRadius: '20px',
        textAlign: 'center',
        fontSize: '13px',
        lineHeight: 1.7,
        padding: '0 4px',
        display: 'inline-block',
        backgroundColor: '#b0c8fa',
        color: BLACK_FONT,
        marginRight: '12px',
        overflow: 'hidden',
        boxSizing: 'border-box',
        marginTop: '-4px',
        fontWeight: 500,
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '16.6%',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    cell_: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        paddingLeft: '10px !important',
        width: '100%',
        maxWidth: '100%',
        '&>:first-child': {
            paddingLeft: '10px !important',
            display: 'flex',
        },
    },
    checkBox: {
        height: 25,
        width: 25,
    },
    checkBoxIcon: {
        color: '#0092e5',
        fontSize: '25px',
        marginLeft: '-4px',
    },
    moreIcons: MUI_ACTION_MENU_ICON,
    currentCell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        paddingLeft: '0px !important',
        width: '100%',
        maxWidth: '100%',
    },
    eyeIcon: {
        color: '#5f94fe',
        cursor: 'pointer',
        fontSize: 22,
    },
    // listIcon: {
    //     color: '#00aead',
    //     fontSize: '1.15rem',
    //     height: '20px !important',
    //     width: '20px !important',
    //     "&:hover": {
    //         backgroundColor: "rgba(0, 0, 0, 0.1)",
    //         borderRadius: '12px',
    //     },
    // },
    moreIconsBtn: {
        color: THREE_DOT_MENU_CSS.iconColor,
    },
    tableRows: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.07) !important',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },

    //
    tableRow: {
        height: '40px',
        backgroundColor: '#f4f6f7',
    },
    containerCheckBox: {
        display: 'flex',
        alignItems: 'center',
    },
    testSuiteNameText: {
        fontSize: TABLES_CSS.normalFontSize,
        display: 'inline-block',
    },
    wrapperTextFieldWidth: {
        width: '100%',
    },
    wrapperActionIcon: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    iconButtonStyle: {
        width: '30px',
        height: '30px',
    },
    calender: {
        color: '#4a4a4a',
        fontSize: '16px',
        width: '18px !important',
        marginTop: '-4px',
        marginRight: '12px',
    },
    calenderDisabled: {
        color: '#b7c4cf',
        fontSize: '16px',
        width: '18px !important',
        marginTop: '-4px',
        marginRight: '12px',
    },
});

function SuiteTableRow(props) {
    const {
        classes,
        actions: {
            /* clearOpenedId, */
            currentRowIndicator,
            handleExpend,
            handleRowsUpDownCancel,
            handleRowsUpDownConfirm,
            handelSelectSuite,
            handleSelectWithShift,
            onSuiteMenuClick,
            renderItems,
            routeTo,
            /* setOpenedId, */
        },
        data: {
            isCaseBtnsDisable,
            /* open, */
            /* openedId, */
            rowExpendStatus,
            selectedSuites,
            selectedUpDownTemp,
            isShiftDown,
            /* currentPageSuites, */
        },
        parentComponent,
        suite,
        headers,
        user,
    } = props;
    const isNonGeneratedTestCaseExist = suite?.testCases?.some(testCase => !testCase.isScriptAvailable)
    let index = 0;
    const disabledRowContentColor = '#BAC4CE';
    const conditionalColor = suite.disabledStatus ? disabledRowContentColor : '#000000';
    return (
        <TableRow hover tabIndex={-1} className={classes.tableRows} style={{ height: '40px', backgroundColor: '#f4f6f7' }}>
            <TableCell
                className={[
                    parentComponent === 'suiteTable' && rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1]
                        ? classes.currentCell
                        : classes.cell,
                    classes.noWrap,
                    classes.minWidth,
                ].join(' ')}
                width={headers[index].width}
                data-resize={`element${index++}_c`}
            >
                <div className={classes.wrapperTextFieldWidth}>
                    <div className={classes.containerCheckBox}>
                        {parentComponent === 'suiteTable' && rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1]
                            ? currentRowIndicator()
                            : null}
                        {parentComponent === 'suiteTable' && (
                            <IconButton
                                onClick={() => {
                                    if (isShiftDown) {
                                        handleSelectWithShift(suite.testSuiteId);
                                    } else {
                                        handelSelectSuite(suite.testSuiteId, suite.disabledStatus);
                                    }
                                }}
                                variant="contained"
                                color="primary"
                                className={classes.checkBox}
                                style={{ marginRight: '5px' }}
                                tabIndex={-1}
                            >
                                {selectedSuites[suite.testSuiteId] ? (
                                    <CheckBox className={classes.checkBoxIcon} aria-label="checkBoxIcon" id="checkBoxIcon" />
                                ) : (
                                    <CheckBoxBlank
                                        className={classes.checkBoxIcon}
                                        style={{ color: '#bac5d0' }}
                                        aria-label="checkBoxBlankIcon"
                                        id="checkBoxBlankIcon"
                                    />
                                )}
                            </IconButton>
                        )}
                        {parentComponent === 'suiteTable' && (
                            <span className={classes.avatar_}>{suite.testCases && suite.testCases.length ? suite.testCases.length : 0}</span>
                        )}
                        {isNonGeneratedTestCaseExist && 
                            <Tooltip data={'This suite contains test cases that are not generated yet.'} toolTipAreaStyle={{margin: '4px 10px 0 -2px'}} >
                                <WarningIcon style={{fontSize: 15, color: '#F39B31'}}/>
                            </Tooltip>
                        }
                        <Typography noWrap style={{ color: conditionalColor, alignItems: 'center' }} className={`${classes.noWrap} ${classes.testSuiteNameText}`}>
                            <Tooltip data={suite.testSuiteName ? suite.testSuiteName : null}>{suite.testSuiteName}</Tooltip>

                        </Typography>
                    </div>
                </div>
            </TableCell>
            <TableCell
                className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                width={headers[index].width}
                data-resize={`element${index++}_c`}
            >
                <div className={classes.wrapperTextFieldWidth}>
                    <Typography noWrap style={{ color: conditionalColor }} className={`${classes.noWrap} ${classes.testSuiteNameText}`}>
                        <Tooltip data={suite.projectName ? suite.projectName : null}>{suite.projectName}</Tooltip>
                    </Typography>
                </div>
            </TableCell>
            {parentComponent === 'suiteTable' && (
                <TableCell
                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                    width={headers[index].width}
                    data-resize={`element${index++}_c`}
                >
                    <div className={classes.wrapperTextFieldWidth}>
                        <Typography noWrap style={{ color: conditionalColor }} className={`${classes.noWrap} ${classes.testSuiteNameText}`}>
                            {checkKeyInObject(user, 'isRelativeDateFormat')
                                ? getRelativeTime(suite.creationTime)
                                : getFormatedDate(suite.creationTime, 'DD MMM YYYY', '')}
                        </Typography>
                    </div>
                </TableCell>
            )}
            {parentComponent !== 'suiteReport' && (
                <TableCell
                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                    width={headers[index].width}
                    data-resize={`element${index++}_c`}
                >
                    <div className={classes.wrapperTextFieldWidth}>
                        <Typography noWrap style={{ color: conditionalColor }} className={`${classes.noWrap} ${classes.testSuiteNameText}`}>
                            {checkKeyInObject(user, 'isRelativeDateFormat')
                                ? getRelativeTime(suite.lastRunTime)
                                : getFormatedDate(suite.lastRunTime, 'DD MMM YYYY', '')}
                        </Typography>
                    </div>
                </TableCell>
            )}
            <TableCell
                className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                width={headers[index].width}
                data-resize={`element${index++}_c`}
            >
                <div className={classes.wrapperTextFieldWidth}>
                    <Typography noWrap style={{ color: conditionalColor }} className={`${classes.noWrap} ${classes.testSuiteNameText}`}>
                        {suite.executionStatus === 'ERROR' ? 'FAILED' : suite.executionStatus === 'NA' ? '' : suite.executionStatus}
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                className={[classes.cell_, classes.noWrap, classes.minWidth].join(' ')}
                width={headers[index].width}
                data-resize={`element${index}_c`}
            >
                <div className={classes.wrapperTextFieldWidth}>
                    {parentComponent === 'suiteTable' ? (
                        <div className={classes.wrapperActionIcon}>
                            {isCaseBtnsDisable && selectedUpDownTemp[0][0] === suite.testSuiteId ? (
                                <Tooltip data="Cancel">
                                    <IconButton
                                        className={classes.iconButtonStyle}
                                        aria-label="Cancel"
                                        onClick={() => {
                                            handleRowsUpDownCancel();
                                        }}
                                    >
                                        <CancelIcon
                                            className={classes.AddIcon}
                                            style={{ color: '#ff2525' }}
                                            aria-label="cancelIcon"
                                            id="cancelIcon"
                                        />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                            {isCaseBtnsDisable && selectedUpDownTemp[0][0] === suite.testSuiteId ? (
                                <Tooltip data="Confirm">
                                    <IconButton
                                        className={classes.iconButtonStyle}
                                        aria-label="Confirm"
                                        onClick={() => {
                                            handleRowsUpDownConfirm();
                                        }}
                                    >
                                        <ConfirmIcon
                                            className={classes.AddIcon}
                                            style={{ color: '#08bd1e' }}
                                            aria-label="confirmIcon"
                                            id="confirmIcon"
                                        />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                            {!suite.disabledStatus ? (
                                <MenuOptions
                                    items={renderItems(suite, classes)}
                                    keepMounted
                                    iconButtonDisabled={selectedSuites[suite.testSuiteId]}
                                    MoreVertStyle={selectedSuites[suite.testSuiteId] ? { color: disabledRowContentColor } : {}}
                                />
                            ) : null}
                            {suite.disabledStatus ? (
                                <Tooltip data="Enable Suite">
                                    <IconButton
                                        className={classes.iconButtonStyle}
                                        style={{ marginLeft: '-4px' }}
                                        aria-label="Enable"
                                        onClick={() => onSuiteMenuClick(suite, 2)}
                                        disabled={selectedSuites[suite.testSuiteId]}
                                    >
                                        <Restore
                                            className={classes.moreIconsBtn}
                                            style={selectedSuites[suite.testSuiteId] ? { color: disabledRowContentColor } : {}}
                                            aria-label="restoreIcon"
                                            id="restoreIcon"
                                        />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                            {!suite.disabledStatus && (
                                <ExpandBtn
                                    flag={rowExpendStatus[0] === suite.testSuiteId && rowExpendStatus[1]}
                                    disabledStatus={suite.disabledStatus}
                                    action={() => handleExpend(suite.testSuiteId)}
                                />
                            )}
                        </div>
                    ) : (
                        <div>
                            <IconButton
                                className={classes.iconButtonStyle}
                                style={{ marginLeft: '10px' }}
                                aria-label="ViewTestCase"
                                onClick={() => {
                                    routeTo('reports/suite', suite.testSuiteId);
                                }}
                            >
                                <FontAwesomeIcon icon={faEye} aria-label="faEye" id="faEye" className={classes.eyeIcon} />
                            </IconButton>
                            {/* <IconButton
                                style={{ width: '30px', height: '30px' }}
                                aria-label=""
                                onClick={(event) => { }}
                            >
                                <ViewList onClick={(e) => { e.stopPropagation(); }} className={classes.listIcon} />
                            </IconButton> */}
                        </div>
                    )}
                </div>
            </TableCell>
        </TableRow>
    );
}

SuiteTableRow.propTypes = {
    parentComponent: PropTypes.string.isRequired,
    suite: PropTypes.instanceOf(Object).isRequired,
    actions: PropTypes.shape({
        clearOpenedId: PropTypes.func,
        currentRowIndicator: PropTypes.func,
        handleExpend: PropTypes.func,
        handelSelectSuite: PropTypes.func,
        handleRowsUpDownCancel: PropTypes.func,
        handleRowsUpDownConfirm: PropTypes.func,
        onSuiteMenuClick: PropTypes.func,
        renderItems: PropTypes.func,
        routeTo: PropTypes.func,
        setOpenedId: PropTypes.func,
    }),
    data: PropTypes.shape({
        isCaseBtnsDisable: PropTypes.bool,
        open: PropTypes.shape({}),
        rowExpendStatus: PropTypes.instanceOf(Array),
        selectedSuites: PropTypes.instanceOf(Object),
        selectedUpDownTemp: PropTypes.instanceOf(Array),
    }),
};

SuiteTableRow.defaultProps = {
    actions: {
        clearOpenedId: () => {},
        currentRowIndicator: () => {},
        handleExpend: () => {},
        handelSelectSuite: () => {},
        handleRowsUpDownCancel: () => {},
        handleRowsUpDownConfirm: () => {},
        onSuiteMenuClick: () => {},
        renderItems: () => {},
        routeTo: () => {},
        setOpenedId: () => {},
    },
    data: {
        isCaseBtnsDisable: false,
        open: {},
        rowExpendStatus: [],
        selectedSuites: {},
        selectedUpDownTemp: [],
    },
};

export default withStyles(styles)(SuiteTableRow);
