/* eslint-disable no-unused-expressions */

// material UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseOnEscape from 'react-close-on-escape';
import Typography from '@material-ui/core/Typography';

// packages
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

// icons
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';

// custom components
import { checkArrayLength, checkKeyInObject, checkObject } from '../../../utils/utils';
import { pluralize } from '../../../common/presentation';
import { UploadProgressActions, TestSuiteActions, ProjectActions } from '../../../store/actions';
import CheckList from '../../../components/FileList/CheckList';
import Filedrop from '../../../components/Filedrop/Filedrop';
import Loader from '../../../components/Loader';
import '../Stepper/uploadWizardScroll.css';
import { DIALOG_ROOT, BLACK_FONT } from '../../../common/cssConstants';
import Tooltip from '../../../components/Tooltip';
import { DialogTop } from '../../../components/Modal/ModalTop';
import { testDataUtils } from '../../../utils/TestDataUtils';
import { DATA_FILE_TYPE } from '../../../common/constants';

const styles = (theme) => ({
    actionBtn: {
        padding: '8px 18px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#C4C4C4',
        borderRadius: '6px',
        boxShadow: 'none',
        textTransform: 'capitalize',
        margin: '0px',
        marginRight: '10px',
        '&>:nth-child(1)': {
            minWidth: '50px',
            fontSize: '12px',
        },
    },
    dialogRoot: DIALOG_ROOT,
    bodyContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    countWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.only('xs')]: {
            marginTop: '3px',
            marginBottom: '5px',
        },
    },
    dialog: {
        minWidth: 575,
        padding: '27px 25px 27px',
        textAlign: 'center',
        [theme.breakpoints.only('xs')]: {
            minWidth: '310px',
        },
    },
    mainDialog: {
        padding: '0 30px',
        maxWidth: '600px',
        minWidth: '600px',
        overflowY: 'hidden',
    },
    root: {
        minWidth: 540,
        maxWidth: 540,
    },
    stepper: {
        padding: 0,
        overflow: 'hidden',
        marginBottom: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        [theme.breakpoints.only('xs')]: {
            maxWidth: '257px',
        },
    },
    stepContainer: {
        position: 'relative',
        marginRight: 50,
    },
    stepVal: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
        paddingTop: 13,
        fontSize: 12,
        color: '#9B9B9B',
        cursor: 'pointer',
    },
    activeStepVal: {
        borderTop: '3px solid #1168CD',
        paddingTop: 10,
        color: '#1168CD',
    },
    testCountStyle: {
        marginTop: '2%',
        color: BLACK_FONT,
        fontSize: 12,
    },
    failedFilesBody: {
        padding: '10px 0px 10px 25px',
        maxHeight: '180px',
        overflowY: 'auto',
    },
    failedFilesContainer: {
        marginTop: 20,
        marginBottom: -20,
    },
    failedFilesHead: {
        display: 'flex',
    },
    failedFileSection: {
        display: 'flex',
        padding: 5,
    },
    responseContainer: {},
    succeedFilesContainer: {
        marginBottom: 10,
    },
    succeedFilesBody: {
        padding: '10px 0px 10px 25px',
        maxHeight: '180px',
        overflowY: 'auto',
    },
    succeedFilesHead: {
        display: 'flex',
    },
    succeedFileSection: {
        padding: 5,
    },
    fileCard: {
        width: 'calc(100% - 8px)',
        height: 40,
        borderRadius: 5,
        backgroundColor: '#FFFFFF',
        boxShadow: '0 2px 7px 0 rgba(0,0,0,0.2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 20px',
        marginBottom: 16,
    },
    folderIcon: {
        color: '#3B91DF',
        fontSize: 20,
        marginRight: 10,
    },
    cancelIcon: {
        color: '#E8001C',
        fontSize: 20,
        marginLeft: 10,
        cursor: 'pointer',
    },
    checkIcon: {
        color: '#00B330',
        fontSize: 20,
        marginLeft: 10,
        cursor: 'pointer',
    },
    textCenter: {
        textAlign: 'center',
    },
    text: {
        color: BLACK_FONT,
        fontSize: 12,
    },
    fullWidth: {
        width: '100%',
    },
    searchInput: {
        marginBottom: '10px',
        width: 230,
    },
});

function getSteps() {
    return ['Test Data', 'Artifacts', 'Audio', 'Attach Cases to Data'];
}

let isMounted = false;
class UploadTestDataNew extends React.Component {
    state = {
        activeStep: 0,
        completed: {},
        dropZoneError: '',
        lastActiveStep: 2,
        ignoredFiles: [],
        loader: false,
        testCaseIds: [],
        uploadedArtifacts: [],
        uploadedAudio: [],
        uploadedFiles: [],
        // states to manage after upload api responded
        isResponded: false,
        succeedFilesArray: [],
        failedFilesArray: [],
        currentFile: '',
        query: '',
    };

    componentDidMount() {
        isMounted = true;
    }

    componentWillUnmount() {
        isMounted = false;
    }

    onFileDropCallback = (files, step) => {

         const allFiles = [...this.state.uploadedFiles,...this.state.uploadedAudio,...this.state.uploadedArtifacts,...files]

         let totalFileSize = 0;

         allFiles.forEach((file)=>{
             totalFileSize = totalFileSize+file.size
         });

         totalFileSize = (totalFileSize/1000000);
         let message = allFiles.length <=1 ? 'File is too large to upload. Maximum size limit is 20 MB' : 'File(s) are too large to upload. Maximum size limit is 20 MB';

         if(totalFileSize>20) {
             this.props.showSnackBar(message)
             return ;
         }




        if (step === 'Artifacts') {
            const uploadedArtifacts = [...this.state.uploadedArtifacts, ...files];

            this.setStateIfComponentMounted({
                dropZoneError: '',
                uploadedArtifacts,
                [step]: true,
            });
        } else {
            let IgnoredFileTimeout = null;
            const ignoredFiles = [...this.state.ignoredFiles];
            const acceptedFile = this.state.activeStep === 2 ? [...this.state.uploadedAudio] : [...this.state.uploadedFiles];
            files.forEach((file) => {
                const fileExtension = `.${file.name.split('.').pop()}`;
                if (!(this.checkFileType(file.type, step) || this.checkFileExtension(fileExtension, step))) {
                    ignoredFiles.push({
                        file: { name: file.name, tab: this.state.activeStep === 2 ? 'Audio' : 'Data' },
                        key: `${step}Id`,
                        status: 'ignored',
                    });
                    IgnoredFileTimeout = setTimeout(() => {
                        clearTimeout(IgnoredFileTimeout);
                        this.setStateIfComponentMounted({
                            dropZoneError:
                                this.state.activeStep === 2 ? 'Only wav file formats are acceptable' : 'Only xls or xlsx file formats are acceptable',
                            ignoredFiles,
                        });
                    }, 1000);
                } else {
                    acceptedFile.push(file);
                }
            });
            this.state.activeStep === 2
                ? acceptedFile &&
                  this.setStateIfComponentMounted(
                      {
                          dropZoneError: '',
                          uploadedAudio: acceptedFile,
                      },
                      () => {
                          // this.autoShiftNext();
                      },
                  )
                : acceptedFile &&
                  this.setStateIfComponentMounted(
                      {
                          dropZoneError: '',
                          uploadedFiles: acceptedFile,
                      },
                      () => {
                          // this.autoShiftNext();
                      },
                  );
        }
    };

    setStateIfComponentMounted = (obj, callback = () => {}) => {
        if (isMounted) this.setState(obj, callback);
    };

    getTestCaseIds = (ids) => {
        this.setStateIfComponentMounted({ testCaseIds: ids });
    };

    autoShiftNext = () => {
        const { uploadedFiles, testCaseIds } = this.state;
        if (uploadedFiles.length !== 0 && testCaseIds.length !== 0) {
            this.handleNextStep();
        }
    };

    handleNextStep = () => {
        let activeStep;

        if (this.isLastStep() && !this.allStepsCompleted()) {
            const steps = getSteps();
            activeStep = steps.findIndex((step, i) => !(i in this.state.completed));
        } else {
            activeStep = this.state.activeStep + 1;
        }
        this.setStateIfComponentMounted({
            activeStep,
        });
    };

    totalSteps = () => getSteps().length;

    completedSteps = () => {
        return Object.keys(this.state.completed).length;
    };

    isLastStep = () => {
        return this.state.activeStep === this.totalSteps() - 1;
    };

    allStepsCompleted = () => {
        return this.completedSteps() === this.totalSteps();
    };

    checkFileType = (type, step) => {
        const allowedFileTypes = [];
        if (step !== 'Audio') {
            allowedFileTypes.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv');
        }
        return allowedFileTypes.indexOf(type) > -1;
    };

    checkFileExtension = (ext, step) => {
        const allowedFileExtensions = [];
        if (step === 'Audio') {
            allowedFileExtensions.push('.wav');
        } else {
            allowedFileExtensions.push('.xls', '.xlsx', '.csv');
        }
        return allowedFileExtensions.indexOf(ext) > -1;
    };

    handleStep = (goTo) => {
        this.setStateIfComponentMounted({ activeStep: goTo, lastActiveStep: this.state.activeStep });
    };

    /* Confirmation modal start */
    toggleConfirmationModal = () => {
        const { testCases, testData } = this.props;
        const { testCaseIds, uploadedFiles } = this.state;
        const _testCases = checkArrayLength(testCases) ? testCases : [];
        const oldDataToChange = [];
        const newData = [];

        _testCases.forEach((testCase) => {
            testCase._testData = [];
            if(testData && Array.isArray(testData) &&  checkArrayLength(testData)) {
                testData.forEach((td)=>{
                    if(td.testCasesIds.includes(testCase.testCaseId)) {
                        testCase._testData.push(td.testDataId)
                    }
                })
            }
            
            if (checkArrayLength(testCase._testData) && checkArrayLength(testCaseIds) && testCaseIds.includes(testCase.testCaseId)) {
                let associatedData = {};
                if (checkArrayLength(testData)) {
                    associatedData = testData.find((datum) => `${datum.testDataId}` === `${testCase._testData[0]}`);
                }
                if (
                    checkArrayLength(uploadedFiles) &&
                    checkObject(associatedData) &&
                    checkKeyInObject(associatedData, 'type') !== DATA_FILE_TYPE.arti &&
                    checkKeyInObject(associatedData, 'type') !== DATA_FILE_TYPE.audio
                ) {
                    // if uploadedFiles array presents that means we're uploading data not artifact
                    oldDataToChange.push({ case: testCase.testCaseName, data: [associatedData.testDataFileName] });
                    newData.push({ case: testCase.testCaseName, data: uploadedFiles[0].name });
                }
            }
        });
        if (checkArrayLength(oldDataToChange) && checkArrayLength(newData) && checkArrayLength(uploadedFiles)) {
            testDataUtils.toggleConfirmationModal({
                data: { oldDataToChange, newData },
                dataName: uploadedFiles[0].name,
                handleButtonClick: this.handleButtonClick,
            });
        } else {
            this.handleButtonClick();
        }
    };
    /* Confirmation modal end */

    handleButtonClick = async () => {
        this.setStateIfComponentMounted({ loader: true });
        const { uploadTestDataFile, toggleModal, user, selectedProjectId = 1 } = this.props;
        const { uploadedFiles, testCaseIds, uploadedArtifacts, uploadedAudio } = this.state;
        let multipleTestData = false;

        if (checkArrayLength(uploadedFiles) || checkArrayLength(uploadedArtifacts) || checkArrayLength(uploadedAudio)) {
            const onComplete = (data) => {
                this.props.testDataValues.updateNewData(data);
            };
            const accountId = user && user.accountId ? user.accountId : null;

            const params = {
                systemId: selectedProjectId,
                projectId: selectedProjectId,
                accountId,
            };
            const formData = new FormData();
            formData.append('testcaseIds', checkArrayLength(testCaseIds) ? testCaseIds : []);
            if (checkArrayLength(uploadedFiles)) {
                multipleTestData = uploadedFiles.length > 1;
                for (let i = 0; i < uploadedFiles.length; i += 1) {
                    formData.append('datafile', uploadedFiles[i]);
                }
            }
            if (checkArrayLength(uploadedArtifacts)) {
                for (let i = 0; i < uploadedArtifacts.length; i += 1) {
                    formData.append('artifacts', uploadedArtifacts[i]);
                }
            }
            if (checkArrayLength(uploadedAudio)) {
                for (let i = 0; i < uploadedAudio.length; i += 1) {
                    formData.append('audio', uploadedAudio[i]);
                }
            }
            const response = await uploadTestDataFile(params, formData, onComplete, multipleTestData);
            let succeedFilesArray = [];
            let failedFilesArray = [];
            let isResponded = false;
            if (multipleTestData && (checkKeyInObject(response, 'failed') || checkKeyInObject(response, 'success'))) {
                isResponded = true;
                if (checkArrayLength(response.failed)) {
                    failedFilesArray = response.failed;
                }
                if (checkArrayLength(response.success)) {
                    succeedFilesArray = response.success;
                }
            } else {
                toggleModal();
            }
            this.setStateIfComponentMounted({ loader: false, isResponded, failedFilesArray, succeedFilesArray });
            // Retrieve all testData after upload so that it will show new updated data with testCases
            // if testCase is asso with other data calling function will remove this
            this.props.getTestData(selectedProjectId);
        } else {
            this.setStateIfComponentMounted({ dropZoneError: 'Failed to Upload Files!', loader: false });
        }
    };

    handleError = () => {
        this.setStateIfComponentMounted({ dropZoneError: false });
    };

    camelize = (value) => value.charAt(0).toUpperCase() + value.slice(1);

    removeFile = (step, ind) => {
        const { uploadedFiles, uploadedArtifacts, uploadedAudio } = this.state;

        let _uploadedArtifacts = [...uploadedArtifacts];
        let _uploadedAudio = [...uploadedAudio];
        let _uploadedfiles = [...uploadedFiles];
        step === 'Artifacts'
            ? (_uploadedArtifacts = _uploadedArtifacts.filter((...index) => index[1] !== ind))
            : step === 'Audio'
            ? (_uploadedAudio = _uploadedAudio.filter((...index) => index[1] !== ind))
            : (_uploadedfiles = _uploadedfiles.filter((...index) => index[1] !== ind));
        this.setStateIfComponentMounted({
            uploadedArtifacts: _uploadedArtifacts,
            uploadedAudio: _uploadedAudio,
            uploadedFiles: _uploadedfiles,
            currentFile: '',
            [step]: step === 'Artifacts' ? _uploadedArtifacts.length > 1 : step === 'Audio' ? _uploadedAudio.length > 1 : _uploadedfiles.length > 1,
        });
    };

    removeIgnoredFile = (step, ind) => {
        const { ignoredFiles } = this.state;
        let ignoredfiles = [...ignoredFiles];
        const i = ignoredfiles
            .map((file, index) => {
                if (file.key === `${step}Id`) return index;
                return undefined;
            })
            .filter((data) => data !== undefined);
        ignoredfiles = ignoredFiles.filter((...index) => index[1] !== i[ind]);
        this.setStateIfComponentMounted({
            ignoredFiles: ignoredfiles,
        });
    };

    showName = (step, file) => {
        if (file[step]) return file[step].name;
        if (file.file) return file.file.name;
        return file.name;
    };

    fileCard = (files, panelName, step) => {
        const { classes } = this.props;
        return (
            files &&
            files.map((file, index) => (
                <div
                    key={index}
                    className={classes.fileCard}
                    onMouseOver={() => {
                        if (panelName !== 'ignored') {
                            this.setStateIfComponentMounted({ currentFile: `${panelName}_${index}` });
                        }
                    }}
                    onMouseLeave={() => {
                        if (panelName !== 'ignored') {
                            this.setStateIfComponentMounted({ currentFile: '' });
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faFolder} aria-label="faFolder" id="faFolder" className={classes.folderIcon} />
                    <Typography noWrap className={`${classes.text} ${classes.fullWidth}`}>
                        <Tooltip data={step && this.showName(step, file)}>{step && this.showName(step, file)}</Tooltip>
                    </Typography>
                    {panelName === 'ignored' ? (
                        <Tooltip data="Remove">
                            <Cancel
                                className={classes.cancelIcon}
                                onClick={() => {
                                    this.removeIgnoredFile(step, index);
                                }}
                                aria-label="cancelIcon"
                                id="cancelIcon"
                            />
                        </Tooltip>
                    ) : this.state.currentFile === `${panelName}_${index}` ? (
                        <Tooltip data="Remove">
                            <Cancel
                                className={classes.cancelIcon}
                                onClick={() => {
                                    this.removeFile(step, index);
                                }}
                                aria-label="cancelIcon"
                                id="cancelIcon"
                            />
                        </Tooltip>
                    ) : (
                        <CheckCircle className={classes.checkIcon} aria-label="checkCircleIcon" id="checkCircleIcon" />
                    )}
                </div>
            ))
        );
    };

    toggleResponseMode = () => {
        this.setStateIfComponentMounted({ isResponded: false });
    };

    handleChange = (ev) => {
        const { value } = ev.target;
        this.setState({ query: value });
    };

    clearSearch = () => {
        this.setState({ query: '' });
    };

    showTab(index) {
        const { uploadedArtifacts, uploadedAudio, uploadedFiles } = this.state;
        switch (index) {
          //  case 0: // TestData
               // return uploadedArtifacts.length === 0 && uploadedAudio.length === 0;
          //  case 1: // Artifacts
               // return uploadedFiles.length === 0 && uploadedAudio.length === 0;
         //   case 2: // Audio
              //  return uploadedFiles.length === 0 && uploadedArtifacts.length === 0;
            case 3: // Associate
                return uploadedFiles.length === 1 || uploadedArtifacts.length > 0 || uploadedAudio.length === 1;
            default:
                return true;
        }
    }

    render() {
        const { classes, open, showSnackBar, toggleModal, testCases } = this.props;
        const {
            activeStep,
            dropZoneError,
            failedFilesArray,
            ignoredFiles,
            isResponded,
            loader,
            succeedFilesArray,
            testCaseIds,
            uploadedArtifacts,
            uploadedAudio,
            uploadedFiles,
            query,
        } = this.state;

        const steps = getSteps();
        const dataIgnoredFiles = ignoredFiles.filter((file) => file.key === 'DataId');
        const audioIgnoredFiles = ignoredFiles.filter((file) => file.key === 'AudioId');

        return (
            <div>
                <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" maxWidth="md" className={classes.dialogRoot}>
                    <DialogTop title={isResponded ? 'Uploaded Files' : 'Upload'} closeIconAction={() => toggleModal()} />
                    <DialogContent className={classes.mainDialog}>
                        <div className={classes.root}>
                            {isResponded ? (
                                <div className={classes.responseContainer}>
                                    <div className={classes.succeedFilesContainer}>
                                        <div className={classes.succeedFilesHead}>
                                            <Done style={{ marginRight: 5, color: 'green' }} aria-label="doneIcon" id="doneIcon" />
                                            <Typography style={{ fontSize: 20, color: 'green' }}>
                                                {`${succeedFilesArray && succeedFilesArray.length} File(s) successfully uploaded`}
                                            </Typography>
                                        </div>
                                        <div className={[classes.succeedFilesBody, 'scrollContainer'].join(' ')}>
                                            {checkArrayLength(succeedFilesArray) ? (
                                                succeedFilesArray.map((succeedFile, index) => (
                                                    <div key={index} className={classes.succeedFileSection}>
                                                        <Typography style={{ width: 'max-content', maxWidth: '98%' }}>
                                                            <Tooltip data={succeedFile.testDataFileName}>{succeedFile.testDataFileName}</Tooltip>
                                                        </Typography>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className={classes.succeedFileSection}>
                                                    <Typography>No successfully uploaded files</Typography>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={classes.failedFilesContainer}>
                                        <div className={classes.failedFilesHead}>
                                            <Clear style={{ marginRight: 5, color: 'red' }} aria-label="clearIcon" id="clearIcon" />
                                            <Typography style={{ fontSize: 20, color: 'red' }}>
                                                {`${failedFilesArray && failedFilesArray.length} File(s) failed to upload`}
                                            </Typography>
                                        </div>
                                        <div className={[classes.failedFilesBody, 'scrollContainer'].join(' ')}>
                                            {checkArrayLength(failedFilesArray) ? (
                                                failedFilesArray.map((failedFile, index) => (
                                                    <div key={index} className={classes.failedFileSection}>
                                                        <Typography noWrap style={{ maxWidth: failedFile.errMsg ? '25%' : '98%' }}>
                                                            <Tooltip data={failedFile.name}>{failedFile.name}</Tooltip>
                                                        </Typography>
                                                        {failedFile.errMsg && <span style={{ marginLeft: 10, marginRight: 10 }}>-</span>}
                                                        {failedFile.errMsg && (
                                                            <Typography noWrap style={{ maxWidth: '70%', color: 'red' }}>
                                                                <Tooltip data={failedFile.errMsg}> {failedFile.errMsg}</Tooltip>
                                                            </Typography>
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <div className={classes.failedFileSection}>
                                                    <Typography>No filed files</Typography>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className={classes.stepper}>
                                        {steps.map((label, index) =>
                                            !this.showTab(index) ? null : (
                                                <div className={classes.stepContainer} key={label} onClick={() => this.handleStep(index)} aria-hidden>
                                                    <span className={`${classes.stepVal} ${index === activeStep ? classes.activeStepVal : ''}`}>
                                                        {label}
                                                    </span>
                                                </div>
                                            ),
                                        )}
                                    </div>
                                    <div className={classes.bodyContainer}>
                                        {activeStep === 0 ? (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: 'calc(50% - 15px)',
                                                        marginRight: 15,
                                                    }}
                                                >
                                                    <Filedrop
                                                        config={{
                                                            dropCallback: this.onFileDropCallback,
                                                            step: 'Data',
                                                            text: 'Select xls, xlsx, or csv File',
                                                        }}
                                                        innerStyle={{ margin: '40px 0 25px 0' }}
                                                    />
                                                    <div className={`${classes.countWrapper}`}>
                                                        <Typography className={classes.testCountStyle}>
                                                            Total Accepted Data : {uploadedFiles && uploadedFiles.length}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        width: 'calc(50% - 15px)',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexDirection:
                                                            !checkArrayLength(uploadedFiles) && !checkArrayLength(dataIgnoredFiles) ? '' : 'column',
                                                        justifyContent:
                                                            !checkArrayLength(uploadedFiles) && !checkArrayLength(dataIgnoredFiles) ? 'center' : '',
                                                        marginLeft: 15,
                                                        marginTop: 10,
                                                    }}
                                                >
                                                    {uploadedFiles.length > 0 && this.fileCard(uploadedFiles, 'accepted', 'Data')}
                                                    {dataIgnoredFiles.length > 0 && this.fileCard(dataIgnoredFiles, 'ignored', 'Data')}
                                                    {!checkArrayLength(uploadedFiles) && !checkArrayLength(dataIgnoredFiles) && (
                                                        <div>
                                                            <Typography className={`${classes.text} ${classes.textCenter}`}>
                                                                No files are selected!
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ) : null}
                                        {activeStep === 1 ? (
                                            <div style={{ width: '100%' }}>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: 'calc(50% - 15px)',
                                                            marginRight: 15,
                                                        }}
                                                    >
                                                        <Filedrop
                                                            config={{
                                                                dropCallback: this.onFileDropCallback,
                                                                step: 'Artifacts',
                                                                text: 'Select Any File',
                                                            }}
                                                            innerStyle={{
                                                                margin: '40px 0 25px 0',
                                                            }}
                                                        />
                                                        <div className={`${classes.countWrapper}`}>
                                                            <Typography className={classes.testCountStyle}>
                                                                {pluralize(uploadedArtifacts.length, 'Total Artifact')}:{' '}
                                                                {uploadedArtifacts && uploadedArtifacts.length}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: 'calc(50% - 15px)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            flexDirection: !checkArrayLength(uploadedArtifacts) ? '' : 'column',
                                                            justifyContent: !checkArrayLength(uploadedArtifacts) ? 'center' : '',
                                                            marginLeft: 15,
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        {uploadedArtifacts.length > 0 && this.fileCard(uploadedArtifacts, 'accepted', 'Artifacts')}
                                                        {!checkArrayLength(uploadedArtifacts) && (
                                                            <div>
                                                                <Typography className={`${classes.text} ${classes.textCenter}`}>
                                                                    No files are selected!
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        {activeStep === 2 ? (
                                            <div style={{ width: '100%' }}>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: 'calc(50% - 15px)',
                                                            marginRight: 15,
                                                        }}
                                                    >
                                                        <Filedrop
                                                            config={{
                                                                dropCallback: this.onFileDropCallback,
                                                                step: 'Audio',
                                                                text: 'Select any wav File',
                                                            }}
                                                            innerStyle={{
                                                                margin: '40px 0 25px 0',
                                                            }}
                                                        />
                                                        <div className={`${classes.countWrapper}`}>
                                                            <Typography className={classes.testCountStyle}>
                                                                {pluralize(uploadedAudio.length, 'Total Accepted Audio')}:{' '}
                                                                {uploadedAudio && uploadedAudio.length}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: 'calc(50% - 15px)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            flexDirection:
                                                                !checkArrayLength(uploadedAudio) && !checkArrayLength(audioIgnoredFiles)
                                                                    ? ''
                                                                    : 'column',
                                                            justifyContent:
                                                                !checkArrayLength(uploadedAudio) && !checkArrayLength(audioIgnoredFiles)
                                                                    ? 'center'
                                                                    : '',
                                                            marginLeft: 15,
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        {uploadedAudio.length > 0 && this.fileCard(uploadedAudio, 'accepted', 'Audio')}
                                                        {audioIgnoredFiles.length > 0 && this.fileCard(audioIgnoredFiles, 'ignored', 'Audio')}
                                                        {!checkArrayLength(uploadedAudio) && !checkArrayLength(audioIgnoredFiles) && (
                                                            <div>
                                                                <Typography className={`${classes.text} ${classes.textCenter}`}>
                                                                    No files are selected!
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        {activeStep === 3 ? (
                                            <CheckList
                                                getTestCaseIds={this.getTestCaseIds}
                                                testCases={testCases}
                                                checkedIds={testCaseIds}
                                                toggleModal={toggleModal}
                                                query={query}
                                                clearSearch={this.clearSearch}
                                                handleSearch={this.handleChange}
                                                dataName="Association"
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions
                        style={{ display: 'flex', justifyContent: 'flex-start', margin: '10px 0 0 0', padding: '30px', paddingTop: '0px' }}
                    >
                        {!isResponded && (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    isResponded
                                        ? this.toggleResponseMode()
                                        : activeStep === 3 || uploadedFiles.length > 1 || uploadedAudio.length > 1
                                        ? this.toggleConfirmationModal()
                                        : this.handleStep(3);
                                }}
                                className={classes.actionBtn}
                                color="secondary"
                                disabled={
                                    !isResponded &&
                                    activeStep !== 3 &&
                                    ((activeStep === 0 && !checkArrayLength(uploadedFiles)) ||
                                        (activeStep === 1 && !checkArrayLength(uploadedArtifacts)) ||
                                        (activeStep === 2 && !checkArrayLength(uploadedAudio)))
                                }
                            >
                                {activeStep === 3 || uploadedFiles.length > 1 || uploadedAudio.length > 1 ? 'Submit' : 'Next'}
                            </Button>
                        )}
                        <CloseOnEscape
                            onEscape={() => {
                                if (!(activeStep === 3 && !isResponded)) {
                                    toggleModal();
                                }
                            }}
                        >
                            <Button
                                variant={isResponded ? 'contained' : 'outlined'}
                                onClick={() => {
                                    activeStep === 3 && !isResponded ? this.handleStep(this.state.lastActiveStep) : toggleModal();
                                }}
                                className={classes.actionBtn}
                                color="secondary"
                            >
                                {activeStep === 3 && !isResponded ? 'Back' : 'Close'}
                            </Button>
                        </CloseOnEscape>
                    </DialogActions>
                    {loader ? <Loader loadingText="" /> : null}
                </Dialog>
                {dropZoneError ? (showSnackBar(dropZoneError), this.handleError()) : null}
            </div>
        );
    }
}
/* eslint-disable no-unused-expressions */

const mapStateToProps = (state) => {
    return {
        isLoading: state.projectReducer.isLoading,
        projects: state.projectReducer.projects,
        testCases: state.projectReducer.testCases,
        testData: state.projectReducer.testData,
        user: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadTestDataFile: (...args) => dispatch(UploadProgressActions.uploadTestDataFile(...args)),
        getTestSuites: (...args) => dispatch(TestSuiteActions.getTestSuites(...args)),
        getTestData: (...args) => dispatch(ProjectActions.getTestData(...args)),
    };
};

UploadTestDataNew.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    testDataValues: PropTypes.instanceOf(Object).isRequired,
    open: PropTypes.bool.isRequired,
    showSnackBar: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    selectedProjectId: PropTypes.string,
};

UploadTestDataNew.defaultProps = {
    selectedProjectId: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UploadTestDataNew));
