import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import StayCurrentPortraitOutlinedIcon from '@material-ui/icons/StayCurrentPortraitOutlined';
import StayCurrentLandscapeOutlinedIcon from '@material-ui/icons/StayCurrentLandscapeOutlined';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Tooltip from '../../../components/Tooltip';
import { BLACK_FONT } from '../../../common/cssConstants';
import { SAUCELABS_DOCUMENTATION_URLS } from '../../../common/externalUrlConstants';

import { CustomSwitch } from '../../../common/miniComponents';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 8,
        marginRight: 'auto'
    },
    typography: {
        marginBottom: 10,
        fontSize: 12,
        color: '#605F5F'
    },
    deviceSettingWrapper: {
        display: "flex",
        alignItems: "center",
        fontSize: 12,
        color: "#3B91DF",
        fontWeight: 500,
        cursor: "pointer",
        pointerEvents: 'auto'
    },
    popoverForReal: {
        width: 404,
        height: 296
    },
    popoverForEmulator: {
        width: 404,
        height: 200
    },
    popoverContent: {
        padding: "20px"
    },
    formLabelRoot: {
        margin: 0,
        marginBottom: 14,
    },
    formLabel: {
        color: "#605F5F",
        fontSize: 12,
        marginLeft: 10,
    },
    checked: {
        color: '#3B91DF !important'
    },
    orientationBtnsWrapper: {
        display: 'flex',
        flexDirection: "row",
        '& > label > span:nth-child(2)': {
            fontSize: 12,
            marginLeft: 6,
            color: '#000000'
        },
        '& > label:nth-child(1)': {
            marginLeft: -1,
        },
        '& > label:nth-child(2)': {
            marginLeft: 7,
        }
    },
    optionsHeading: {
        display: "flex",
        alignItems: "center",
        margin: '15px 0px',
        '& > p': {
            fontSize: 12,
            color: '#605F5F'
        },
        '& > svg': {
            color: "#9B9B9B",
            marginLeft: 10,
            fontSize: 20
        }
    },
    optionsWrapper: {
        '& > div:nth-child(1)': {
            width: "54%",
            display: 'flex',
            justifyContent: "space-between",
            '& > p': {
                fontSize: 12,
                fontWeight: 500,
                color: '#000000'
            }
        },
        '& > div:nth-child(2)': {
            display: 'flex',
            alignItems: "end",
        }
    },
    addIcon: {
        fontSize: 20,
        color: "#005FC2"
    },
    closeIcon: {
        fontSize: 20,
        color: "#9B9B9B"
    },
    hideCloseBtn: {
        display: "none"
    },
    showCloseBtn: {
        '&:hover': {
            '& $hideCloseBtn': {
                display: "block"
            },
            '& $TextField': {
                marginRight: 5
            }
        }
    },
    TextField: {
        margin: '0 25px 0 17px'
    },
    disableBtn: {
        color: '#9B9B9B'
    },
    disabledContent:{
        backgroundColor: 'rgb(0 0 0 / 5%)',
        cursor: 'not-allowed'
    },
    events:{
        pointerEvents: 'none'
    },
    tooltipContent: {
        fontSize: '12px',
        color: BLACK_FONT,
        margin: '2px',
        fontWeight: 400,
        width:'max-content'
    },
    infoIcon : {
        color: "#9B9B9B",
        marginLeft: 10,
        fontSize: 20,
        cursor:'pointer'

    }

}));


const DeviceSettings = ({ deviceSettings, setState, rowInd, deviceType, configurationSetting }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const isFieldEmpty = deviceSettings[rowInd]?.options?.some(option => !option.key || !option.value) || configurationSetting === 'lastRunConfiguration'
    const handlePopover = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    console.log("deviceSettings",deviceSettings);

    const handleChange = (e, CalledFor, ind) => {
        const _deviceSettings = JSON.parse(JSON.stringify(deviceSettings))
        if (CalledFor === 'switch') {
            _deviceSettings[rowInd][e.target.name] = e.target.checked
        }
        else if (CalledFor === 'orientation') {
            _deviceSettings[rowInd].orientation = e.target.value
        }
        else {
            _deviceSettings[rowInd].options[ind][e.target.name] = e.target.value
        }
        setState({ deviceSettings: _deviceSettings })
    };

    const handleActions = (action, ind) => {
        const _deviceSettings = JSON.parse(JSON.stringify(deviceSettings))
        if (action === "add") {
            _deviceSettings[rowInd].options.push({ key: '', value: '' })
        }
        else {
            _deviceSettings[rowInd].options = _deviceSettings[rowInd].options.filter((_, index) => index !== ind)
        }
        setState({ deviceSettings: _deviceSettings })
    }

    const switches = [
       // { label: 'Auto Accept Alerts', value: 'autoAcceptAlerts', state: deviceSettings[rowInd]?.autoAcceptAlerts },
        { label: 'Auto Grant Permissions', value: 'autoGrantPermissions', state: deviceSettings[rowInd]?.autoGrantPermissions },
        { label: 'Enable Animations', value: 'enableAnimations', state: deviceSettings[rowInd]?.enableAnimations }
    ]

    return (
        <div className={classes.container}>
            <div className={classes.deviceSettingWrapper} onClick={handlePopover}>
                <span><SettingsIcon style={{ fontSize: 12 }} /></span>
                <span style={{ marginLeft: 5 }}>Device Settings</span>
            </div>
            <Popover
                id={'popover'}
                classes={{ paper: deviceType === 'real' ? classes.popoverForReal : classes.popoverForEmulator }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 200,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={`${classes.popoverContent} ${configurationSetting === 'lastRunConfiguration' && classes.disabledContent}`}>
                    
                        <FormGroup className={configurationSetting === 'lastRunConfiguration' ? classes.events : ''}>
                            {switches.map((_switch, index) => (
                                <FormControlLabel
                                    classes={{ root: classes.formLabelRoot, label: classes.formLabel }}
                                    key={index}
                                    value={_switch.label}
                                    control={
                                        <CustomSwitch
                                            key={index}
                                            name={_switch.value}
                                            checked={_switch.state}
                                            onChange={(e) => handleChange(e, 'switch')}
                                            value={_switch.value}
                                            h={12}
                                            w={27}
                                            trackColor="#9B9B9B"
                                            margin="0"
                                        />
                                    }
                                    label={_switch.label}
                                    labelPlacement="end"
                                />
                            ))}
                        </FormGroup>
                    
                    <Typography className={classes.typography}>Device orientation</Typography>
                    <RadioGroup aria-label="orientation" name='radio' value={deviceSettings[rowInd]?.orientation} className={`${classes.orientationBtnsWrapper} ${configurationSetting === 'lastRunConfiguration' && classes.events}`} onChange={(e) => handleChange(e, 'orientation')}>
                        <FormControlLabel
                            value="portrait"
                            control={<Radio disableRipple classes={{ checked: classes.checked }} icon={<StayCurrentPortraitOutlinedIcon />} checkedIcon={<StayCurrentPortraitOutlinedIcon />} />} label="Portrait"
                        />
                        <FormControlLabel
                            value="landscape"
                            control={<Radio disableRipple classes={{ checked: classes.checked }} icon={<StayCurrentLandscapeOutlinedIcon />} checkedIcon={<StayCurrentLandscapeOutlinedIcon />} />} label="Landscape"
                        />
                    </RadioGroup>
                    <div className={classes.optionsHeading}>
                    <Typography>Other configuration options</Typography>
                        <Tooltip isPointerEvents = {false}  persistTooltip={true} data={<div className={classes.tooltipContent}>{'Check '}<a target="_blank" style={{textDecoration: 'none' }} href={SAUCELABS_DOCUMENTATION_URLS.CAPABILITIES_DOCUMENTAION}>{'Sauce Labs Documentation'}</a>{' for using capabilities'}</div>}>
                            <InfoIcon className={classes.infoIcon}  />
                        </Tooltip>
                    </div>
                    <div className={`${classes.optionsWrapper} ${configurationSetting === 'lastRunConfiguration' && classes.events}`}>
                        <div>
                            <Typography className={classes.typography}>Key</Typography>
                            <Typography className={classes.typography}>Value</Typography>
                        </div>
                        <div>
                            <div>
                                {deviceSettings[rowInd]?.options?.map((option, ind) => (
                                    <div key={ind} style={{ display: 'flex', alignItems: "end" }} className={deviceSettings[rowInd]?.options?.length > 1 ? classes.showCloseBtn : ''}>
                                        <div style={{ display: 'flex' }}>
                                            <TextField id="standard-basic1" placeholder='ex. resigningEnabled' name='key' value={option.key} onChange={(e) => handleChange(e, "textField", ind)} />
                                            <TextField id="standard-basic2" className={classes.TextField} placeholder='ex. true' name='value' value={option.value} onChange={(e) => handleChange(e, "textField", ind)} />

                                        </div>
                                        {deviceSettings[rowInd]?.options?.length > 1 &&
                                            <IconButton className={`${classes.hideCloseBtn}`} onClick={() => handleActions("delete", ind)}>
                                                <CloseIcon className={classes.closeIcon} />
                                            </IconButton>
                                        }

                                    </div>

                                ))
                                }
                            </div>
                            <IconButton onClick={() => handleActions("add")} disabled={isFieldEmpty}>
                                <AddIcon className={`${classes.addIcon} ${isFieldEmpty && classes.disableBtn}`} style={{cursor: configurationSetting === 'lastRunConfiguration' && 'not-allowed'}} />
                            </IconButton>
                        </div>
                    </div>
                </div>

            </Popover>
        </div>
    );
}

DeviceSettings.propTypes = {
    deviceSettings: PropTypes.shape([]).isRequired,
    setState: PropTypes.func.isRequired,
    rowInd: PropTypes.number.isRequired,
    deviceType: PropTypes.string.isRequired
};

DeviceSettings.defaultProps = {
    deviceSettings: [],
    setState: () => { },
    rowInd: 0,
    deviceType: 'real'
}


export default DeviceSettings;