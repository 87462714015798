import store from "../store";
import { ActionTypes } from "../store/constants";


const alertBar = ({message='',type='default',close=true,duration=5000}) => {

    store.dispatch({
        type: ActionTypes.TOGGLE_ALERTBAR,
        payload: {
            open:true,
            message,
            type,
            isCloseOption: close,
            autoHideDuration:duration
    }
})
}

const successAlertBar = ({message='',close=true,duration=5000}) => {
    
    store.dispatch({
        type: ActionTypes.TOGGLE_ALERTBAR,
        payload: {
            open:true,
            message,
            type:'Success',
            isCloseOption:close,
            autoHideDuration:duration
    }
})
}

const errorAlertBar = ({message='',close=true,duration=5000}) => {

    store.dispatch({
        type: ActionTypes.TOGGLE_ALERTBAR,
        payload: {
            open:true,
            message,
            type:'Error',
            isCloseOption:close,
            autoHideDuration:duration
    }
})
}


const warningAlertBar = ({message='',close=true,duration=5000}) => {

    store.dispatch({
        type: ActionTypes.TOGGLE_ALERTBAR,
        payload: {
            open:true,
            message,
            type:'Warning',
            isCloseOption:close,
            autoHideDuration:duration
    }
})
}

const infoAlertBar = ({message='',close=true,duration=5000}) => {

    store.dispatch({
        type: ActionTypes.TOGGLE_ALERTBAR,
        payload: {
            open:true,
            message,
            type:'Info',
            isCloseOption:close,
            autoHideDuration:duration
    }
})
}

export  {alertBar,successAlertBar,errorAlertBar,warningAlertBar,infoAlertBar};


