import axios from 'axios';
import config from '../config';
import createHeader from '../common/createHeader';

export default class HttpBase {
    constructor() {
        this.ValidateApi = (conf) => {
            const url = conf.url.replace(conf.baseURL, '');
            const header = createHeader(url);
            conf.headers = header;
            return conf;
        };
        this.getMethods = (endpoint) => ({
            get: (url, conf) => {
                return axios.get(`${endpoint}/${url.replace(/^[//]/, '')}`, {
                    ...conf,
                    'Content-Type': 'application/json',
                });
            },
            post: (url, data, conf) => {
                return axios.post(`${endpoint}/${url.replace(/^[//]/, '')}`, data, {
                    ...conf,
                    'Content-Type': 'application/json',
                });
            },
        });
        this.httpClient = this.getMethods(config.api.endpoint);
       // this.httpClientScheduleSVC = this.getMethods(config.schedule.endpoint);
        this.httpClientUsrmgmt = this.getMethods(config.usrmgmt.endpoint);
        this.httpClientSchedule = this.getMethods(config.schedule.endpoint);
        this.httpClientRecorder = this.getMethods(config.recorder.endpoint);
        this.httpClientInternal = this.getMethods(config.internal.endpoint);
        this.httpClientSCIM = this.getMethods(config.scim.endpoint);
        axios.interceptors.request.use((_config) => this.ValidateApi(_config));
    }
}
