// import materials
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Close from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// import packages
import React from 'react';
import { connect } from 'react-redux';

// import custom component
import CloseOnEscape from 'react-close-on-escape';
import { UserActions, AccountActions } from '../../../store/actions';
import CreateGroupModal from '../../admin/modal/CreateGroupModal';
import DeleteGroupModal from '../../admin/modal/DeleteGroupModal';
import EditGroupModal from '../../admin/modal/EditGroupModal';
import ManageHeaderModal from '../../admin/modal/ManageHeaderModal';
// import AssociationModal from '../../admin/modal/AssociationModal';
import DissociateModal from '../../admin/modal/DissociateModal';
import { checkKeyInObject, checkArrayLength } from '../../../utils/utils';
import CaseTabHeader from '../../admin/modal/CaseTabHeader';
import {
    DIALOG_ROOT,
    MODAL_HEADER_TITLE,
    MODAL_FOOTER,
    MODAL_SUBMIT_BUTTON,
    MODAL_CANCEL_BUTTON,
    MODAL_HEADER_CLOSE_ICON_CONT,
    MODAL_HEADER_CLOSE_ICON,
} from '../../../common/cssConstants';

// constants
const buttonText = {
    Add: 'Create',
    Delete: 'Delete',
    EnableDisable: '',
    Edit: 'Update',
    Setting: 'Save',
    /* Associate: 'Add', */ DeleteUser: 'Confirm',
    ArrangeColumns: 'Save',
};
const statusDropDownData = [
    { value: 1, label: 'Active' },
    { value: 0, label: 'Suspend' },
];
const title = {
    Add: 'Create New Group',
    Delete: 'Delete Group',
    Edit: 'Update Group',
    Setting: 'Manage Columns',
    /* Associate: 'Add Users to Group', */ DeleteUser: 'Dissociate User',
    ArrangeColumns: 'Manage Columns',
};

const styles = (theme) => ({
    boldText: {
        fontWeight: 'bold',
        color: '#003F9B',
    },
    card: {
        backgroundColor: '#FCF9E5',
        borderTop: '1px solid #b2b2b2',
        minHeight: 80,
        paddingBottom: 10,
        paddingTop: 10,
        textAlign: 'center',
    },
    dialogNew: {
        ...DIALOG_ROOT,
        '&>div': {
            borderRadius: '15px',
            boxShadow: '0 0 45px 0 rgba(0,0,0,0.8)',
        },
        '&>:nth-child(2)': {
            minWidth: '242px',
        },
    },
    dialogContentManage: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 296px)',
        paddingBottom: 0,
        '&:first-child': {
            paddingTop: 0,
            width: 'auto',
            [theme.breakpoints.only('xs')]: {
                width: 'calc(100vw - 100px)',
            },
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 10,
        },
    },
    dialogContent: {
        overflow: 'hidden',
        minWidth: 370,
        padding: '0 30px !important',
        maxHeight: 'calc(100vh - 290px)',
        overflowY: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 100px)',
        },
    },
    dialogContentManageColumns: {
        overflow: 'hidden',
        padding: '0 30px !important',
        maxHeight: 'calc(100vh - 290px)',
        overflowY: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 100px)',
        },
    },
    dialogContentAddUser: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 296px)',
        paddingBottom: 12,
        overflow: 'hidden',
        '&:first-child': {
            padding: 0,
            width: 590,
            [theme.breakpoints.only('xs')]: {
                width: '100%',
            },
        },
    },
    dialogTitleStyle: MODAL_HEADER_TITLE,
    deleteButtonDanger: {
        backgroundColor: '#fff !important',
        color: '#ff0000 !important',
        border: '1px solid #ff0000 !important',
        '&:hover': {
            backgroundColor: '#ff0000 !important',
            color: '#fff !important',
        },
    },
    wrapperDialogTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

class GroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.interval = null;
        const { groupDatum, userData } = props.modalData;
        this.state = {
            groupName: (groupDatum && groupDatum.groupName) || '',
            groupNameError: '',
            userName: userData && userData.userName,
            userRole: userData && userData.userRole,
            member: (groupDatum && groupDatum.member) || '',
            selectedStatus: (groupDatum && groupDatum.status) || 'placeholder',
            ischanged: false,
            updatedHeader: [],
            selectedUsersIds: [],
            isDisabled: false,
            caseHeaderStatus: [],
        };

        this.interval = null;
    }

    get enableSubmit() {
        const {
            modalData: { modaltype },
        } = this.props;
        const { ischanged, member, groupName, selectedStatus, updatedHeader, isDisabled } = this.state;
        if (isDisabled) {
            return false;
        }
        switch (modaltype) {
            case 'Add':
                return ischanged && groupName.length;

            case 'Delete':
                return true;

            case 'EnableDisable':
                return true;

            case 'Edit':
                return ischanged && (member || member.length) && groupName.length && (selectedStatus === 1 || selectedStatus === 0);

            case 'Setting':
                return updatedHeader && updatedHeader.length > 0;

            // case 'Associate':
            //     return ischanged;

            case 'DeleteUser':
                return true;

            case 'ArrangeColumns':
                return true;

            default:
                return false;
        }
    }

    setting = async () => {
        this.props.changeStatus(this.state.updatedHeader);
        // close modal
        this.props.toggleModal();
    };

    changeAssociatData = (associateGroupData) => {
        const _selectedUsersIds = [];
        if (associateGroupData && associateGroupData.users) {
            associateGroupData.users.forEach((users) => _selectedUsersIds.push(users.id));
        }
        this.setState({
            groupName: associateGroupData ? associateGroupData.name : '',
            selectedUsersIds: _selectedUsersIds,
        });
    };

    clearForm = () => {
        this.setState({
            groupName: '',
            selectedUsersIds: [],
        });
        // close modal
        this.props.toggleModal();
    };

    changeHeaderStatus = (updatedHeader) => {
        this.setState({ updatedHeader });
    };

    changeCaseHeaderStatus = (caseHeaderStatus) => {
        this.setState({ caseHeaderStatus });
    };

    associate = async () => {
        const {
            associateUsersWithGroup,
            modalData: { associateGroupData },
            getGroups,
            user,
        } = this.props;
        const { selectedUsersIds } = this.state;

        const _selectedUsersIds = JSON.parse(JSON.stringify(selectedUsersIds));
        const groupId = associateGroupData && associateGroupData.id;

        if (associateGroupData && associateGroupData.users) {
            associateGroupData.users.forEach((users) => {
                const index = _selectedUsersIds.indexOf(users.id);
                _selectedUsersIds.splice(index, 1);
            });
        }

        await associateUsersWithGroup({ group_id: groupId, user_ids: _selectedUsersIds });
        await getGroups(user.accountId);
        this.clearForm();
    };

    createGroup = async () => {
        const { groupName } = this.state;
        const { user, getGroups, toggleModal, createGroup, modalData } = this.props;
        // close modal
        const allGroups = checkKeyInObject(modalData, 'allGroups');
        if (
            allGroups &&
            checkArrayLength(
                allGroups.filter((group) => {
                    return `${group.name}`.toLowerCase() === `${groupName}`.toLowerCase();
                }),
            )
        ) {
            this.setState({ groupNameError: 'Group with same name already exists' });
        } else {
            await createGroup({ name: groupName, account_id: user.accountId });
            await getGroups(user.accountId);
            toggleModal();
        }
    };

    updateGroup = async () => {
        // close modal
        this.props.toggleModal();
    };

    deleteGroup = async () => {
        const {
            modalData: { groupData, clearSelectedGroups },
            deleteGroup,
            user,
            getGroups,
        } = this.props;
        const groupIds = groupData ? groupData.groupIds : [];
        await deleteGroup({ group_ids: groupIds });
        await getGroups(user.accountId);
        clearSelectedGroups();
        // close modal
        this.props.toggleModal();
    };

    EnableDisableGroup = async () => {
        const {
            modalData: { groupData, clearSelectedGroups },
        } = this.props;
        await groupData.submitFunction();
        clearSelectedGroups();
        // close modal
        this.props.toggleModal();
    };

    dissociateUser = async () => {
        const {
            modalData: { userData },
            dissociateUser,
            getGroups,
            user,
        } = this.props;
        const userId = userData ? userData.userId : [];
        const groupId = userData ? userData.groupId : 0;
        await dissociateUser({ user_ids: [userId], group_id: groupId });
        await getGroups(user.accountId);
        // close modal
        this.props.toggleModal();
    };

    handleChange = (name) => (event) => {
        this.setState({ [name]: event.target.value, ischanged: true });
        if (name === 'groupName' && this.state.groupNameError) {
            this.setState({ groupNameError: '' });
        }
    };

    updateCaseColumns = () => {
        const { caseHeaderStatus } = this.state;
        const {
            updateCaseColumns,
            user: { accountId },
            toggleModal,
            getAccountsPrefrences,
        } = this.props;

        const cols = [];
        caseHeaderStatus.forEach((col) => {
            if (col.label !== 'Name' && col.label !== 'Actions' && col.status) {
                cols.push(col.label);
            }
        });
        updateCaseColumns(accountId, JSON.stringify(cols), getAccountsPrefrences);
        toggleModal();
    };

    handleSelectChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, ischanged: true });
    };

    handleChangeSelectedUsers = (ids) => {
        this.setState({ selectedUsersIds: ids, ischanged: true });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const {
            modalData: { modaltype },
            toggleModal,
        } = this.props;

        switch (modaltype) {
            case 'Add':
                this.createGroup();
                break;
            case 'Delete':
                this.deleteGroup();
                break;
            case 'EnableDisable':
                this.EnableDisableGroup();
                break;

            case 'Edit':
                this.updateGroup();
                break;
            case 'Setting':
                this.setting();
                break;

            // case 'Associate':
            //     this.associate();
            //     break;

            case 'DeleteUser':
                this.dissociateUser();
                break;

            case 'ArrangeColumns':
                this.updateCaseColumns();
                break;

            default:
                toggleModal();
                break;
        }
    };

    render() {
        const { classes, modalData, open, toggleModal, prefences, getPrefences } = this.props;
        // const groupId = modalData.associateGroupData && modalData.associateGroupData.id;

        const { modaltype, groupData, rows /* , usersDropDownData */ } = modalData;

        const { groupName, groupNameError, member, selectedStatus /* , selectedUsersIds */, userName, userRole } = this.state;
        const component = {
            Add: <CreateGroupModal groupName={groupName} groupNameError={groupNameError} handleChange={this.handleChange} />,
            Delete: (
                <DeleteGroupModal
                    groupNames={groupData && groupData.groupNames}
                    members={groupData && groupData.members}
                    _multiple={groupData && groupData._multiple}
                    action="delete"
                    modalFor="delete"
                />
            ),
            EnableDisable: (
                <DeleteGroupModal
                    groupNames={groupData && groupData.groupNames}
                    members={groupData && groupData.members}
                    _multiple={groupData && groupData._multiple}
                    action={modaltype}
                    modalFor={checkKeyInObject(groupData, 'action') ? groupData.action : ''}
                />
            ),
            Edit: (
                <EditGroupModal
                    groupName={groupName}
                    member={member}
                    selectedStatus={selectedStatus}
                    statusDropDownData={statusDropDownData}
                    handleChange={this.handleChange}
                    handleSelectChange={this.handleSelectChange}
                />
            ),
            Setting: <ManageHeaderModal headers={rows} changeHeaderStatus={this.changeHeaderStatus} />,
            // Associate: (
            //     <AssociationModal
            //         groupName={groupName}
            //         groupId={groupId}
            //         handleSelectChange={this.handleChangeSelectedUsers}
            //         selectedUsersIds={selectedUsersIds}
            //         usersDropDownData={usersDropDownData}
            //         isNextBtnDisable={modalData.isNextBtnDisable}
            //         isPrevBtnDisable={modalData.isPrevBtnDisable}
            //         nextBtnClick={() => { if (modalData.nextBtnClick) modalData.nextBtnClick() }}
            //         prevBtnClick={() => { if (modalData.prevBtnClick) modalData.prevBtnClick() }}
            //     />
            // ),
            DeleteUser: <DissociateModal userName={userName} userRole={userRole} />,
            ArrangeColumns: (
                <CaseTabHeader
                    headers={rows}
                    changeCaseHeaderStatus={this.changeCaseHeaderStatus}
                    prefences={prefences}
                    getPrefences={getPrefences}
                />
            ),
        };

        return (
            <div>
                <Dialog
                    disableRestoreFocus
                    open={open}
                    aria-labelledby="form-dialog-title"
                    // maxWidth="sm"
                    className={classes.dialogNew}
                >
                    <div className={classes.wrapperDialogTitle}>
                        <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitleStyle }}>
                            {modaltype === 'EnableDisable' ? (groupData.action === 'enable' ? 'Enable Group' : 'Disable Group') : title[modaltype]}
                        </DialogTitle>
                        <div style={MODAL_HEADER_CLOSE_ICON_CONT}>
                            <Close style={MODAL_HEADER_CLOSE_ICON} onClick={() => toggleModal()} aria-label="closeIcon" id="closeIcon" />
                        </div>
                    </div>
                    <DialogContent
                        className={
                            modaltype === 'Setting' || modaltype === 'ArrangeColumns' ? classes.dialogContentManageColumns : classes.dialogContent
                        }
                    >
                        {component[modaltype]}
                    </DialogContent>
                    <DialogActions style={MODAL_FOOTER}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="secondary"
                            disabled={!this.enableSubmit}
                            style={MODAL_SUBMIT_BUTTON}
                            className={modaltype === 'Delete' || modaltype === 'DeleteUser' ? classes.deleteButtonDanger : ''}
                            onClick={(e) => {
                                this.setState({ isDisabled: true });
                                this.handleSubmit(e);
                            }}
                        >
                            {modaltype === 'EnableDisable' ? (groupData.action === 'enable' ? 'Enable' : 'Disable') : buttonText[modaltype]}
                        </Button>
                        <CloseOnEscape onEscape={() => toggleModal()}>
                            <Button onClick={() => toggleModal()} variant="outlined" style={MODAL_CANCEL_BUTTON}>
                                Cancel
                            </Button>
                        </CloseOnEscape>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        prefences: state.accountReducer.prefences,
        getPrefences: state.accountReducer.getPrefences,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: () => dispatch(UserActions.getUsers()),
        associateUsersWithGroup: (data) => dispatch(UserActions.associateUsersWithGroup(data)),
        createGroup: (data) => dispatch(UserActions.createGroup(data)),
        updateGroup: (data) => dispatch(UserActions.updateGroup(data)),
        deleteGroup: (groupIds) => dispatch(UserActions.deleteGroups(groupIds)),
        changeStatus: (data) => dispatch(UserActions.changeStatus(data)),
        dissociateUser: (data) => dispatch(UserActions.dissociateUser(data)),
        getGroups: (accountId) => dispatch(UserActions.getGroups(accountId)),

        updateCaseColumns: (...args) => dispatch(AccountActions.UpdateCaseColumns(...args)),
        getAccountsPrefrences: (id) => dispatch(AccountActions.getAccountsPrefrences(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupModal));
