/* eslint-disable no-throw-literal */
import HttpBase from '../../services/HttpBase';
import { checkObject } from '../../utils/utils';

class SidebarMiddleware extends HttpBase {
    async getSideBarDataCount() {
        const { data, status } = await this.httpClient.get('/v1/account/get_counts');
        if (checkObject(data) && status >= 200 && status < 300) {
            return data;
        }
        throw 'Data not found';
    }
}

const SideBarMiddleware = new SidebarMiddleware();
export { SideBarMiddleware };
