import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const styles = (theme) => ({
    buttonContainer: {
        margin: '0px 9px 11px 0px',
        padding: '10px 0px 0px 0px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonSave: {
        borderRadius: '2px',
        // minHeight: 0,
        minWidth: '75px',
        padding: '2px 8px',
        textTransform: 'capitalize',
        height: '20px',
    },
    buttonCancel: {
        borderRadius: '2px',
        // minHeight: 0,
        minWidth: '75px',
        padding: '2px 8px',
        textTransform: 'capitalize',
        height: '20px',
        marginRight: '5px',
    },
    button: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
    },
    headingWrapper: {
        borderBottom: '1px solid #c5ced8',
        paddingLeft: 15,
        paddingTop: 20,
        flexDirection: 'row',
        display: 'flex',
        [theme.breakpoints.only('xs')]: {
            paddingTop: 30,
        },
    },
    heading: {
        paddingLeft: 9,
        fontWeight: 'bold',
        color: '#494949',
        fontSize: 12,
    },
    fonts: {
        fontSize: 12,
    },
    subContainer: {
        padding: 0,
        [theme.breakpoints.only('xs')]: {
            padding: '40px 10px 60px 10px',
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
});

class AdminLegal extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <Grid item xs={false} sm={1} md={2} lg={2} />
                <Grid item xs={12} sm={10} md={7} lg={7}>
                    <div className={classes.subContainer}>
                        <div className={classes.headingWrapper}>
                            <Grid item xs={6} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    Cloud Storage Location
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={8} md={8} lg={8}>
                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    className={classes.fonts}
                                    style={{ color: '#0092e6', cursor: 'pointer' }}
                                >
                                    Cloud Location
                                </Typography>
                            </Grid>
                        </div>
                        <div className={classes.headingWrapper}>
                            <Grid item xs={6} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    Privacy Certification
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={8} md={8} lg={8}>
                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    className={classes.fonts}
                                    style={{ color: '#0092e6', cursor: 'pointer' }}
                                >
                                    Privacy Certification
                                </Typography>
                            </Grid>
                        </div>
                        <div className={classes.headingWrapper}>
                            <Grid item xs={6} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    Security Certification
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={8} md={8} lg={8}>
                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    className={classes.fonts}
                                    style={{ color: '#0092e6', cursor: 'pointer' }}
                                >
                                    Security
                                </Typography>
                            </Grid>
                        </div>
                        <div className={classes.headingWrapper}>
                            <Grid item xs={6} sm={4} md={3} lg={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <Typography variant="subtitle1" gutterBottom className={classes.heading}>
                                    Security Overview
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={8} md={8} lg={8}>
                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    className={classes.fonts}
                                    style={{ color: '#0092e6', cursor: 'pointer' }}
                                >
                                    White Paper
                                </Typography>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={false} sm={1} md={3} lg={3} />
            </div>
        );
    }
}

export default withStyles(styles)(AdminLegal);
