import jwt_decode from 'jwt-decode';
import { IGNORE_AUTH_HEADER_SERVICE_REQUESTS } from './constants';
import AuthActions from '../store/actions/authActions';
import CookieStorage from './cookieStorage';
import config from '../config';
import store from '../store/index';

const createHeader = (baseUrl) => {
    const token = localStorage.getItem('token');
    const isIgnored = IGNORE_AUTH_HEADER_SERVICE_REQUESTS.some((url) => url === baseUrl);
    const accId = CookieStorage.get('accountId');
    const authType = CookieStorage.get('auth-type');
    const redirectionUrl = window.location.href;
    if (isIgnored || !token) {
        return {
            'Content-Type': 'application/json',
        };
    }
    if (token) {
        const tokenDetail = jwt_decode(token);
        const currTime = new Date();
        const expTime = new Date(tokenDetail.exp * 1000);

        if (currTime.getTime() >= expTime.getTime()) {
            if (authType && authType === 'saml') {
                window.location.assign(`${config.api.endpoint}/v1/accounts/${accId}/${accId}/auth/saml?location=${redirectionUrl}`);
            } else {
                store.dispatch(AuthActions.logout());
            }
        }
    }
    if (baseUrl.indexOf('usermgmt/v1/users') > -1 && baseUrl.indexOf('/update') > -1) {
        return {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
        };
    }
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    };
};

export default createHeader;
