import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import CallMerge from '@material-ui/icons/CallMerge';
import { ModalActions, generalModalActions, TestCaseActions, ProjectActions } from '../../../store/actions';
import CustomButton from '../../../components/Button/CustomButton';
import { MenuOptions } from '../../../components/Button/MenuOptions';
import MergeModalMerge from '../../../components/MergeModal/MergeModal_Merge';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON } from '../../../components/Button/Btn';
import MergeModalArrange from '../../../components/MergeModal/MergeModal_Arrange';
import { checkKeyInObject, checkObject } from '../../../utils';
import store from '../../../store'

const styles = () => ({
    root: {
        padding: 15,
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '5px',
    },
    customButton: {
        backgroundColor: '#f0f0f0 !important',
        padding: '8px 11px !important',
    },
    customButtonText: {
        color: '#353333 !important',
    },
    rightBtns: {
        display: 'flex',
    },
    threeDotsButton: {
        marginLeft: 10,
    },
    childIcon: {
        fontSize: 18,
        color: '#5F7B96',
    },
    childIconDisabled: {
        fontSize: 18,
        color: 'rgba(0, 0, 0, 0.26)',
    },
});

const CaseHeader = ({ createTestCase, projectSystemId, recordTestCase, appUrl, numSelected, showDisabledTestCases, changeStatus, testCaseCount }) => {
    const classes = makeStyles(styles)();

    const [callBack, setCallback] = useState('');
    const [anchorElPopover, setAnchorElPopover] = React.useState(null);
    

    const dispatch = useDispatch();
    const dispatchActions = () => {
        return {
            //toggleTestCaseDisplay: () => dispatch(TestCaseActions.toggleTestCaseDisplay()),
            toggleModal: (...args) => dispatch(ModalActions.toggleModal(...args)),
            changeTab: (...args) => dispatch(generalModalActions.changeTab(...args)),
            clearGeneralModal: (...args) => dispatch(generalModalActions.clearGeneralModal(...args)),
            mergeTestCases: (...args) => dispatch(TestCaseActions.mergeTestCases(...args)),
            toggleIdModal: (bool) => dispatch(ProjectActions.toggleIdModal(bool)),
        };
    };
    const actions = useCallback(dispatchActions(),[dispatchActions]);

    const useRedux = (state) => {
        return {
            currentTab: state.generalModalReducer.currentTab,
            tabsData: state.generalModalReducer.tabsData,
            user: state.authReducer.user,
        };
    };
    const redux = useSelector(useRedux);

    useEffect(() => {
        if (callBack === 'Record' || callBack === 'Open') {
            recordTestCase(
                redux.user.accountId,
                projectSystemId,
                projectSystemId,
                () => {
                },
                '',
                callBack === 'Open',
            );
        } else if (callBack === 'Load unpacked') {
            recordTestCase(redux.user.accountId, projectSystemId, projectSystemId, () => {
            });
        } else if (callBack === 'RecordContex') {
            actions.toggleIdModal(true);
        }
    }, [callBack]);

    // const subRenderItems = () => {
    //     const items = [
    //         {
    //             action: (e) => {
    //                 e.stopPropagation();
    //                 setRecordLoader(true);
    //                 setAnchorEl(null);
    //                 setCallback('Open');
    //             },
    //             icon: (
    //                 <FontAwesomeIcon
    //                     style={{ padding: '0 7px 0 10px', fontSize: '0.8 em' }}
    //                     icon={faVideo}
    //                     aria-label="faVideo"
    //                     id="faVideo"
    //                     className={classes.moreIcons}
    //                 />
    //             ),
    //             isDisabled: false,
    //             name: 'Open',
    //         },
    //         {
    //             action: (e) => {
    //                 e.stopPropagation();
    //                 setTimeout(() => {
    //                     setRecordLoader(true);
    //                     setAnchorEl(null);
    //                     setCallback('Load unpacked');
    //                 }, 100);
    //                 setAnchorEl(null);
    //                 return false;
    //             },
    //             icon: (
    //                 <FontAwesomeIcon
    //                     style={{ padding: '0 5px', fontSize: '0.8 em' }}
    //                     icon={faFolderOpen}
    //                     aria-label="faFolderOpen"
    //                     id="faFolderOpen"
    //                     className={classes.moreIcons}
    //                 />
    //             ),
    //             isDisabled: false,
    //             name: 'Load unpacked',
    //         },
    //     ];
    //     return <ActionMenu items={items} />;
    // };

    const closeMergeModal = () => {
        actions.toggleModal();
        setTimeout(() => {
            actions.clearGeneralModal();
        }, 500);
    };

    const renderDisabledCasesItems = () => {
        const items = [];
        // items.push({
        //     action: (e) => {
        //         e.stopPropagation();
        //         actions.toggleTestCaseDisplay();
        //     },
        //     type: 'customToggle',
        //     icon: <Disable className={classes.moreIcons} aria-label="disableIcon" id="disableIcon" />,
        //     isDisabled: false,
        //     name: ' Show Disabled TestCases',
        // });

        items.push({
            action: (e) => {
                e.stopPropagation();
                actions.toggleModal('GeneralModal', null, null, {
                    title: 'Merge Cases',
                    closeIconAction: () => {
                        closeMergeModal();
                    },
                    component: [
                        {
                            name: 'Merge',
                            content: <MergeModalMerge />,
                            buttons: [
                                NEXT_SUBMIT_BUTTON({
                                    name: 'Next',
                                    action: () => {
                                        actions.changeTab(redux.currentTab + 1);
                                    },
                                }),
                                CANCEL_BUTTON({ action: closeMergeModal }),
                            ],
                        },
                        {
                            name: 'Arrange',
                            content: <MergeModalArrange />,
                            buttons: [
                                NEXT_SUBMIT_BUTTON({
                                    name: 'Save',
                                    action: () => {
                                        // Get updated redux state data
                                        const { tabsData } = store.getState().generalModalReducer
                                        if (checkObject(tabsData)) {
                                            const { mergeTestCases } = actions;
                                            const newCaseName =
                                                checkKeyInObject(tabsData, 'merge') &&
                                                checkKeyInObject(tabsData.merge, 'state') &&
                                                checkKeyInObject(tabsData.merge.state, 'newCaseName')
                                                    ? tabsData.merge.state.newCaseName
                                                    : '';
                                            const testCasesToMerge =
                                                checkKeyInObject(tabsData, 'merge') &&
                                                checkKeyInObject(tabsData.merge, 'state') &&
                                                checkKeyInObject(tabsData.merge.state, 'selectedTestCaseIds')
                                                    ? [...tabsData.merge.state.selectedTestCaseIds]
                                                    : [];
                                            mergeTestCases(newCaseName, testCasesToMerge, projectSystemId);
                                            closeMergeModal();
                                        }
                                    },
                                    isDisabled: (tabsData) => {
                                        const isDuplicate =
                                            checkKeyInObject(tabsData, 'merge') &&
                                            checkKeyInObject(tabsData.merge, 'state') &&
                                            checkKeyInObject(tabsData.merge.state, 'isDuplicate')
                                                ? tabsData.merge.state.isDuplicate
                                                : '';
                                        const newCaseName =
                                            checkKeyInObject(tabsData, 'merge') &&
                                            checkKeyInObject(tabsData.merge, 'state') &&
                                            checkKeyInObject(tabsData.merge.state, 'newCaseName')
                                                ? tabsData.merge.state.newCaseName
                                                : '';
                                        const testCasesToMerge =
                                            checkKeyInObject(tabsData, 'merge') &&
                                            checkKeyInObject(tabsData.merge, 'state') &&
                                            checkKeyInObject(tabsData.merge.state, 'selectedTestCaseIds')
                                                ? [...tabsData.merge.state.selectedTestCaseIds]
                                                : [];
                                        return isDuplicate || !(!!newCaseName && testCasesToMerge.length > 1);
                                    },
                                }),
                                CANCEL_BUTTON({ action: closeMergeModal }),
                            ],
                        },
                    ],
                });
            },
            icon: <CallMerge className={classes.childIcon} aria-label="callMergrIcon" id="callMergeIcon" />,
            isDisabled: false,
            name: ' Merge test cases',
        });

        return items;
    }

    // const renderItems = () => {
    //     const items = [];
    //     if (actions.toggleModal) {
    //         items.push({
    //             action: (e) => {
    //                 e.stopPropagation();
    //                 actions.toggleModal('uploadTestCase', null, null, projectSystemId);
    //             },
    //             icon: <Upload className={classes.moreIcons} aria-label="uploadIcon" id="uploadIcon" />,
    //             isDisabled: false,
    //             name: ' Upload',
    //         });
    //     }
    //     if (recordTestCase) {
    //         items.push({
    //             action: (e) => {
    //                 e.stopPropagation();
    //                 setRecordLoader(true);
    //                 setAnchorEl(null);
    //                 setCallback('Record');
    //             },
    //             onContextMenu: (e) => {
    //                 e.stopPropagation();
    //                 e.preventDefault();
    //                 setAnchorEl(null);
    //                 setCallback('RecordContex');
    //             },
    //             icon: (
    //                 <FontAwesomeIcon
    //                     style={{ padding: '0 5px', fontSize: '0.8 em' }}
    //                     icon={faVideo}
    //                     aria-label="faVideo"
    //                     id="faVideo"
    //                     className={classes.moreIcons}
    //                 />
    //             ),
    //             isDisabled: recordLoader,
    //             name: ' Record',
    //         });
    //     }
    //     items.push({
    //         action: (e) => {
    //             e.stopPropagation();
    //             actions.toggleModal('adminUserModal', null, '', { modaltype: 'AutoGenerate', appUrl, projectId: projectSystemId });
    //         },
    //         icon: <AiqSvg />,
    //         isDisabled: true,
    //         name: ' (Beta)',
    //     });
    //     // btns.push(
    //     //     <Tooltip data="Manage columns" key="ArrangeColumns">
    //     //         <IconButton
    //     //             aria-label="Manage columns"
    //     //             className={classes.childIconButton}
    //     //             onClick={() => {
    //     //
    //     //             }}
    //     //             id="manageColumns"
    //     //         >
    //     //             <Settings className={classes.childIcon} aria-label="settingsIcon" id="settingsIcon" />
    //     //         </IconButton>
    //     //     </Tooltip>,
    //     // );
    //     items.push({
    //         action: (e) => {
    //             e.stopPropagation();
    //             actions.toggleModal('GeneralModal', null, null, {
    //                 title: 'Merge Cases',
    //                 closeIconAction: () => {
    //                     closeMergeModal();
    //                 },
    //                 component: [
    //                     {
    //                         name: 'Merge',
    //                         content: <MergeModalMerge />,
    //                         buttons: [
    //                             NEXT_SUBMIT_BUTTON({
    //                                 name: 'Next',
    //                                 action: () => {
    //                                     actions.changeTab(redux.currentTab + 1);
    //                                 },
    //                             }),
    //                             CANCEL_BUTTON({ action: closeMergeModal }),
    //                         ],
    //                     },
    //                     {
    //                         name: 'Arrange',
    //                         content: <MergeModalArrange />,
    //                         buttons: [
    //                             NEXT_SUBMIT_BUTTON({
    //                                 name: 'Save',
    //                                 action: () => {
    //                                     const { tabsData } = redux;
    //                                     if (checkObject(tabsData)) {
    //                                         const { mergeTestCases } = actions;
    //                                         const newCaseName =
    //                                             checkKeyInObject(tabsData, 'merge') &&
    //                                             checkKeyInObject(tabsData.merge, 'state') &&
    //                                             checkKeyInObject(tabsData.merge.state, 'newCaseName')
    //                                                 ? tabsData.merge.state.newCaseName
    //                                                 : '';
    //                                         const testCasesToMerge =
    //                                             checkKeyInObject(tabsData, 'merge') &&
    //                                             checkKeyInObject(tabsData.merge, 'state') &&
    //                                             checkKeyInObject(tabsData.merge.state, 'selectedTestCaseIds')
    //                                                 ? [...tabsData.merge.state.selectedTestCaseIds]
    //                                                 : [];
    //                                         mergeTestCases(newCaseName, testCasesToMerge, projectSystemId);
    //                                         closeMergeModal();
    //                                     }
    //                                 },
    //                                 isDisabled: (tabsData) => {
    //                                     const isDuplicate =
    //                                         checkKeyInObject(tabsData, 'merge') &&
    //                                         checkKeyInObject(tabsData.merge, 'state') &&
    //                                         checkKeyInObject(tabsData.merge.state, 'isDuplicate')
    //                                             ? tabsData.merge.state.isDuplicate
    //                                             : '';
    //                                     const newCaseName =
    //                                         checkKeyInObject(tabsData, 'merge') &&
    //                                         checkKeyInObject(tabsData.merge, 'state') &&
    //                                         checkKeyInObject(tabsData.merge.state, 'newCaseName')
    //                                             ? tabsData.merge.state.newCaseName
    //                                             : '';
    //                                     const testCasesToMerge =
    //                                         checkKeyInObject(tabsData, 'merge') &&
    //                                         checkKeyInObject(tabsData.merge, 'state') &&
    //                                         checkKeyInObject(tabsData.merge.state, 'selectedTestCaseIds')
    //                                             ? [...tabsData.merge.state.selectedTestCaseIds]
    //                                             : [];
    //                                     return isDuplicate || !(!!newCaseName && testCasesToMerge.length > 1);
    //                                 },
    //                             }),
    //                             CANCEL_BUTTON({ action: closeMergeModal }),
    //                         ],
    //                     },
    //                 ],
    //             });
    //         },
    //         icon: <CallMerge className={classes.childIcon} aria-label="callMergrIcon" id="callMergeIcon" />,
    //         isDisabled: false,
    //         name: ' Merge test cases',
    //     });
    //     return items;
    // };



    const handleUpload =(e) => {
        e.stopPropagation();
        setAnchorElPopover(null)
        actions.toggleModal('uploadTestCase', null, null, projectSystemId);

    }
    const handleRecord = (e) => {
        e.stopPropagation();
        setAnchorElPopover(null)
        setCallback('Record');
        setTimeout(() => {
            setCallback('')
        });
    }

    const openPopover = event => {
        setAnchorElPopover(event.currentTarget);
    };

    const handleLoadUnpacked = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setCallback('RecordContex');
        setTimeout(() => {
            setCallback('')
        });
    }
    return (
        <div className={classes.root}>
            <CustomButton title="Add TEST CASE"  
                classNameSlider={''} 
                onClick={openPopover}
                anchorEl={anchorElPopover}
                open={Boolean(anchorElPopover)} 
                onClose={() => setAnchorElPopover(null)}
                createTestCase = {createTestCase}
                handleUpload = {handleUpload}
                handleRecord = {handleRecord}
                handleLoadUnpacked = {handleLoadUnpacked}
                anchorPosition = {{top:260,left:35}}
            />
           <div className={classes.rightBtns}>
                {numSelected > 0 ? (
                    <CustomButton
                        title="Execute"
                        faIcon={faBolt}
                        className={classes.customButton}
                        textClassName={classes.customButtonText}
                        onClick={() => {}}
                    />
                ) : null}
              <div className={classes.threeDotsButton}>
                    {/* start dotted menu */}
                    {testCaseCount ? ( <MenuOptions items={renderDisabledCasesItems()} keepMounted /> ) : null}
                    
                    {/* end dotted menu */}
                </div>
            </div>
        </div>
    );
};

export default CaseHeader;
