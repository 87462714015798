import React from 'react';
import classNames from 'classnames';
// import materials
import { withStyles } from '@material-ui/core/styles';
import { Typography, FormControlLabel, AccordionSummary, AccordionDetails, Accordion, DialogActions, Toolbar, Button, Chip } from '@material-ui/core';
import { ExpandMore, Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CloseOnEscape from 'react-close-on-escape';
import { MODAL_FOOTER, MODAL_SUBMIT_BUTTON, MODAL_CANCEL_BUTTON, BLACK_FONT } from '../../../common/cssConstants';
import Table from '../../../components/Table/Table';
import TableBody from '../../../components/Table/TableBody';
import TableRow from '../../../components/Table/TableRow';
import TableCell from '../../../components/Table/TableCell';
import { EnhancedTableHead } from '../../../components/EnhancedTable';
import Tooltip from '../../../components/Tooltip';
import SearchInput from '../../modal/GeneralModal/SearchInput';
import { checkArrayLength } from '../../../utils';
import { CustomSwitch } from '../../../common/miniComponents';

const styles = (theme) => ({
    title: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        minHeight: '60px',
        marginTop: 5,
    },
    searchInput: {
        width: 250,
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 280px)',
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    tableWrapper: {
        '&>table>thead>tr>th': {
            padding: '0 !important',
        },
    },
    serachContainer: {
        width: '100%',
        borderBottom: '1px solid #e1e1e1',
        display: 'flex',
        alignItems: 'center',
        minHeight: '65px',
        marginBottom: 10,
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '25px',
        position: 'absolute',
        width: '10px',
        top: '95px',
    },
    checkBox: {
        height: 25,
        width: 25,
    },
    checkBoxIcon: {
        color: '#0092e5',
        fontSize: '20px',
        marginLeft: '-4px',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '16.6%',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    font: {
        fontSize: 12,
        color: '#595959',
    },
    searchInputIcon: {
        fontSize: '20px',
    },
    expansionPanel: {
        width: '100%',
        overflowY: 'auto',
    },
    expansionDetails: {
        width: '100%',
        overflowX: 'auto',
    },
    chipsContainer: {
        width: '100%',
        overflowY: 'auto',
    },
    chip: {
        margin: '5px 0 0 15px',
        '&>span': {
            paddingLeft: '9px',
        },
        '&>span:nth-child(2)': {
            color: `${BLACK_FONT} !important`,
        },
        background: '#EBF0F3',
    },
    heading: {
        // paddingLeft: '40px',
    },
    expandContainer: {
        // '&>div:nth-child(2)': {
        //     left: '8px',
        // },
    },
    toogleContainer: {
        '&>label': {
            margin: '0 !important',
        },
    },
    checkBoxClass: {
        '&>span>svg': {
            fontSize: '20px',
        },
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#fafafa',
        },
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
});

class UserPermissionsModal extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'name',
        selected: [],
        data: [],
        page: 0,
        rowsPerPage: 5,
        users: [],
        checked: true,
        query: '',
    };

    componentDidMount() {
        this.initialize(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.initialize(nextProps);
    }

    handleRequestSort = (property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            this.setState((state) => ({
                selected: state.data.map((n) => n.ProjectId),
            }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = (id) => this.state.selected.indexOf(id) !== -1;

    deleteChipUser = (usr) => {
        const { users } = this.state;
        const filtered = users.filter((_usr) => `${_usr.id}` !== `${usr.id}`);
        this.setState({ users: filtered });
    };

    filterUsers = (data, selected, IsVisible) => {
        return data.map((usr) => {
            if (selected.includes(usr.ProjectId)) {
                usr.IsVisible = IsVisible;
            }
            return usr;
        });
    };

    handleToggle = () => {
        const { selected, data, checked } = this.state;
        if (checked) {
            const filtered = this.filterUsers(data, selected, false);
            this.setState({ data: filtered, checked: false });
        } else {
            const filteredUnchecked = this.filterUsers(data, selected, true);
            this.setState({ data: filteredUnchecked, checked: true });
        }
    };

    selectedUsers = (check) => {
        const { selected, data } = this.state;
        if (check === 'checked') {
            const filtered = this.filterUsers(data, selected, true);
            this.setState({ data: filtered });
        } else {
            const filteredUnchecked = this.filterUsers(data, selected, false);
            this.setState({ data: filteredUnchecked });
        }
    };

    updateUserPermissions = () => {
        const { toggleModal, getUserProjectPermission } = this.props;
        const { users, data, selected } = this.state;
        const sendArr = [];
        const filteredProjects = data.filter((pro) => {
            if (selected.includes(pro.ProjectId)) {
                return pro;
            }
            return false;
        });
        users.forEach((usr) => {
            filteredProjects.forEach((proj) => {
                sendArr.push({ userId: usr.id, projectId: proj.ProjectId, isVisible: proj.IsVisible });
            });
        });
        getUserProjectPermission({ userProjectAssocList: sendArr });
        toggleModal();
    };

    initialize = (props) => {
        if (props.users) {
            // let filteredUsers = props.users.filter(
            //     (user, ind) =>
            //         user.role !== 'ADMIN' && user.email !== 'appuser@test.com',
            // );
            this.setState({
                users: props.users,
                // selectedUser: props.user,
                // currentIndex: props.currentIndex,
            });
        }

        if (props.projects) {
            const refactoredProject = props.projects.map((proj) => createData(proj.projectId, proj.projectName, true));

            this.setState({ data: refactoredProject });
        }
    };

    handleSearchProjectName = (e) => {
        this.setState({ query: e.target.value });
    };

    clearSearch = () => {
        this.setState({ query: '' });
    };

    render() {
        const { classes, toggleModal } = this.props;
        const { data, order, orderBy, selected, rowsPerPage, page, users, checked, query } = this.state;

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        let searchProjectName = checkArrayLength(data) ? data : [];
        if (query) {
            searchProjectName = searchProjectName.filter((project) => {
                return project && project.name && project.name.toLowerCase().includes(query.toLowerCase().trim());
            });
        }

        const headers = [
            { id: 'All', sortable: true, numeric: false, disablePadding: false, label: 'Project Name', styles: { paddingLeft: 10 }, width: '100%' },
        ];
        return (
            <div>
                <div
                    style={{
                        height: 'calc(100vh - 373px)',
                        padding: '0 30px 5px 30px',
                        overflow: 'auto',
                    }}
                >
                    <div className={classes.title}>
                        <Accordion className={classes.expansionPanel}>
                            <AccordionSummary className={classes.expandContainer} expandIcon={<ExpandMore className={classes.arrowIcon} />}>
                                <Typography className={classes.heading}>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            width: '23px',
                                            height: '22px',
                                            borderRadius: '50%',
                                            color: 'white',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            backgroundColor: '#3B91DF',
                                            paddingTop: '2px',
                                            marginRight: '5px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {users && users.length}
                                    </span>{' '}
                                    SDET users selected
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.expansionDetails}>
                                <div className={classes.chipsContainer}>
                                    {users &&
                                        users.map((usr, ind) => {
                                            return (
                                                <Chip
                                                    key={ind.toString()}
                                                    label={usr.name}
                                                    className={classes.chip}
                                                    onDelete={() => this.deleteChipUser(usr)}
                                                />
                                            );
                                        })}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div
                        className={classes.serachContainer}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <EnhancedTableToolbar
                            selectedUsers={this.selectedUsers}
                            unSelectAll={() => this.setState({ selected: [] })}
                            numSelected={selected.length}
                            checked={checked}
                            onToggleSwitch={this.handleToggle}
                        />

                        <SearchInput
                            id="UserPermissionsModal"
                            placeholder="Search"
                            onChange={(e) => this.handleSearchProjectName(e)}
                            value={query}
                            classStyle={classes.searchInput}
                            clearSearch={this.clearSearch}
                        />
                    </div>
                    <div className={classes.tableWrapper}>
                        <div
                            style={{
                                borderRadius: 5,
                                overflow: 'hidden',
                                boxShadow: '0 2px 7px 0 rgba(0,0,0,0.2)',
                            }}
                        >
                            <Table>
                                <EnhancedTableHead
                                    headers={headers}
                                    onSelectAllClick={(e) => {
                                        this.handleSelectAllClick(e);
                                    }}
                                    order={order}
                                    orderBy={orderBy}
                                    currentPageData={data}
                                    numSelected={selected.length}
                                    rowCount={data.length}
                                    showSelectBtn
                                    style={{ paddingRight: 0 }}
                                    checkBoxClass={classes.checkBoxClass}
                                    onRequestSort={this.handleRequestSort}
                                />
                                <TableBody style={{ maxHeight: 'calc(100vh - 558px)', minHeight: '100px' }}>
                                    {stableSort(searchProjectName, getSorting(order, orderBy)).map((n, index) => {
                                        const isSelected = this.isSelected(n.ProjectId);
                                        return (
                                            <TableRow
                                                key={index}
                                                style={{ height: '40px', cursor: 'default' }}
                                                tabIndex={-1}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                className={classes.row}
                                            >
                                                <TableCell
                                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                                    width={headers[0].width}
                                                    data-resize={`element${0}_c`}
                                                >
                                                    <div style={{ paddingLeft: '0px', width: '100%' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.handleClick(n.ProjectId);
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                className={classes.checkBox}
                                                                style={{ marginRight: '5px' }}
                                                                tabIndex={-1}
                                                            >
                                                                {isSelected ? (
                                                                    <CheckBox
                                                                        className={classes.checkBoxIcon}
                                                                        aria-label="checkBoxIcon"
                                                                        id="checkBoxIcon"
                                                                    />
                                                                ) : (
                                                                    <CheckBoxBlank
                                                                        className={classes.checkBoxIcon}
                                                                        style={{ color: '#bac5d0' }}
                                                                        aria-label="checkBoxBlankIcon"
                                                                        id="checkBoxBlankIcon"
                                                                    />
                                                                )}
                                                            </IconButton>
                                                            <Typography
                                                                noWrap
                                                                style={{
                                                                    width: '130px',
                                                                    maxWidth: '130px',
                                                                    display: 'inline-block',
                                                                }}
                                                            >
                                                                <Tooltip data={n.name ? n.name : null}>{n.name}</Tooltip>
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 49 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
                <DialogActions style={MODAL_FOOTER}>
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={this.updateUserPermissions}
                        style={MODAL_SUBMIT_BUTTON}
                        disabled={!users.length || (selected && !selected.length)}
                    >
                        Ok
                    </Button>
                    <CloseOnEscape onEscape={() => toggleModal()}>
                        <Button variant="outlined" onClick={() => toggleModal()} style={MODAL_CANCEL_BUTTON}>
                            Cancel
                        </Button>
                    </CloseOnEscape>
                </DialogActions>
            </div>
        );
    }
}

export default withStyles(styles)(UserPermissionsModal);

function createData(id, name, boolean) {
    return { ProjectId: id, name, IsVisible: boolean };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const toolbarStyles = (theme) => ({
    root: {
        paddingRight: theme.spacing(1),
        minWidth: '55%',
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  width: '206px',
                  border: '1px solid #F0F7EA',
                  borderRadius: '20px',
                  backgroundColor: '#F0F7EA',
                  boxShadow: '0 1px 4px 0 rgba(0,0,0,0.28)',
                  maxHeight: '25px',
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        flex: '0 0 auto',
    },
    formControlLabel: {
        '&>span:nth-child(2)': {
            fontWeight: 'bold',
            color: BLACK_FONT,
        },
    },
});

let EnhancedTableToolbar = (props) => {
    const { numSelected, classes, unSelectAll, checked, onToggleSwitch } = props;

    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: '15px',
                minWidth: '55%',
                marginLeft: '10px',
            }}
        >
            <div
                className={classes.title}
                style={{
                    display: 'flex',
                    flex: 2,
                    lineHeight: '16px',
                    paddingTop: '2px',
                    alignItems: 'center',
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        color="inherit"
                        style={{
                            fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
                            color: BLACK_FONT,
                            fontSize: '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        <span
                            style={{
                                display: 'inline-block',
                                width: '23px',
                                height: '22px',
                                borderRadius: '50%',
                                color: 'white',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                backgroundColor: '#3B91DF',
                                paddingTop: '3px',
                                marginRight: '5px',
                                textAlign: 'center',
                            }}
                        >
                            {numSelected}
                        </span>{' '}
                        Selected
                    </Typography>
                ) : (
                    ''
                )}
                {numSelected > 0 && (
                    <Tooltip data="Cancel">
                        <Close
                            className={classes.multiCloseIcon}
                            onClick={unSelectAll}
                            style={{
                                color: '#4a4a4a',
                                cursor: 'pointer',
                                fontSize: 22,
                                marginLeft: 10,
                            }}
                        />
                    </Tooltip>
                )}
            </div>
            <div
                className={classes.actions}
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'flex-end',
                }}
            >
                {numSelected > 0 && (
                    <div className={classes.toogleContainer}>
                        <FormControlLabel
                            control={<CustomSwitch checked={checked} onChange={onToggleSwitch} value="checked" />}
                            label={checked ? 'Allow' : 'Restrict'}
                            className={classes.formControlLabel}
                        />
                    </div>
                )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);
