import HttpBase from '../../services/HttpBase';

class DashboardMiddleWare extends HttpBase {
    async getDashboardInfo(period_id) {
        const result = await this.httpClient.get(`v1/dashboard/get_dashboard_info/${period_id}`);
        if (result && result.data && result.data && Object.keys(result.data).length && result.status === 200) {
            return { data: result.data };
        }
        return null;
    }
}
export default new DashboardMiddleWare();
