import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import { IconButton, Checkbox } from '@material-ui/core';
import Select from '../../modal/GeneralModal/Select';
import { checkKeyInObject, checkArrayLength, isDateCorrect, convertToSelectValues } from '../../../utils';
import DatePicker from '../../modal/GeneralModal/DatePicker';
import TableRow from '../../../components/Table/TableRow';
import TableCell from '../../../components/Table/TableCell';
import { defaultStartDate, defaultEndDate, USER_NAME } from '../../../common/constants';
import { CustomSwitch } from '../../../common/miniComponents';

class AccountUserRow extends Component {
    state = {
        isFirst: true,
    };

    componentDidMount() {
        if (this.state.isFirst) {
            this.updateState({ isFirst: false });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            !_.isEqual(_.omit(nextProps, _.functions(nextProps)), _.omit(this.props, _.functions(this.props))) || !_.isEqual(nextState, this.state)
        );
    }

    updateState = (obj) => {
        this.setState(obj);
    };

    calenderRef1 = React.createRef();

    calenderRef2 = React.createRef();

    render() {
        const {
            classes,
            data,
            index,
            isUpdate,
            selectedUsers,
            items,
            headers,

            handleSelectUser,
            handleChangeTextField,
            handleDateChange,
            deleteTabRow,
            getSelectedItems,
            changeStatus,
            onEmailBlur,
            onPasswordBlur,
            setState,
            disableRowOnUpdate,
            addRowData,
            handleChangeRaw,
            handleCalendarClose,
        } = this.props;
        return (
            <TableRow
                hover
                tabIndex={-1}
                className={classes.tableRows}
                id={data.id}
                key={index}
                style={{ height: '50px', backgroundColor: '#f9f9f9', cursor: 'default' }}
            >
                <TableCell className={`${classes.cell} ${classes.padding30}`} width={headers[0].width}>
                    {disableRowOnUpdate && index === 0 ?
                        null
                    : isUpdate && data.userName !== USER_NAME && (
                        <Checkbox
                            checked={selectedUsers.some((user) => user.userName === data.userName)}
                            className={classes.checkBox}
                            classes={{
                                root: classes.checkBoxRoot,
                                checked: classes.checkBoxChecked,
                            }}
                            onClick={() => {
                                handleSelectUser(data);
                            }}
                        />
                    )}
                    <TextField
                        id="username"
                        type="username"
                        value={data.userName}
                        name="userName"
                        InputProps={{ disableUnderline: true }}
                        disabled={disableRowOnUpdate && index === 0 ? false : isUpdate ? true : index !== 0}
                        // className={`${classes.textField} ${!isUsernameValid ? classes.errorTextField : null}`}
                        className={classes.textField}
                        onChange={(e) => handleChangeTextField(e, index)}
                        style={
                            !isUpdate
                                ? { width: 215 }
                                : data.userName === USER_NAME
                                ? { width: 215, marginLeft: 30 }
                                : disableRowOnUpdate && index === 0
                                ? { width: 192, marginLeft: 46 }
                                : { width: 192, marginLeft: 30 }
                        }
                        onBlur={(event) => {
                            const { users } = this.props;
                            if (Boolean(event.target.value.trim()) && checkArrayLength(users)) {
                                const _users = users.filter((_user) => _user.name === event.target.value);
                                if (
                                    (isUpdate &&
                                        checkArrayLength(_users) &&
                                        checkKeyInObject(_users[0], 'id', 'bool', false) &&
                                        _users[0].id !== data.id) ||
                                    _users.length
                                ) {
                                    setState({ isUsernameValid: false,duplicateUserOnDiffAccount: [..._users],duplicateUser: [] });
                                }
                            }
                        }}
                    />
                </TableCell>
                <TableCell className={classes.padding10} width={headers[1].width}>
                    <TextField
                        id="email"
                        type="email"
                        name="email"
                        autoComplete="off"
                        InputProps={{ disableUnderline: true }}
                        disabled={disableRowOnUpdate && index === 0 ? false : isUpdate ? true : index !== 0}
                        className={classes.textField}
                        value={data.email}
                        onChange={(e) => handleChangeTextField(e, index)}
                        onBlur={onEmailBlur}
                    />
                </TableCell>
                <TableCell className={classes.padding10} width={headers[2].width}>
                    {disableRowOnUpdate && index === 0 ? (
                        <TextField
                            id="password"
                            type="password"
                            name="password"
                            autoComplete="off"
                            InputProps={{ disableUnderline: true }}
                            className={classes.textField}
                            value={data.password}
                            onChange={(e) => handleChangeTextField(e, index)}
                            onBlur={onPasswordBlur}
                        />
                    ) : isUpdate ? (
                        <div className={classes.passwordContainer}>
                            <div className={classes.fontPassword}>******</div>
                            {/***<div
                                className={classes.oval}
                                aria-hidden
                                onClick={() => {
                                    togglePasswordModal(data);
                                }}
                            >
                                <ResetPassword className={classes.lockIcon} />
                            </div>***/}
                        </div>
                    ) : (
                        <TextField
                            id="password"
                            type="password"
                            name="password"
                            autoComplete="off"
                            InputProps={{ disableUnderline: true }}
                            className={classes.textField}
                            value={data.password}
                            onChange={(e) => handleChangeTextField(e, index)}
                            onBlur={onPasswordBlur}
                        />
                    )}
                </TableCell>
                <TableCell className={classes.padding10} width={headers[3].width}>
                    <div
                        className={classes.datePickerWrapper}
                        ref={(node) => {
                            this.calenderRef1 = node;
                        }}
                    >
                        <DatePicker
                            calendarClassName={classes.calendarClass}
                            selected={data.userDateStart !== defaultStartDate ? isDateCorrect(data.userDateStart, 'value', null) : null}
                            onChange={handleDateChange('userDateStart', index,data.userName)}
                            popperPlacement="auto-left"
                            dateFormat="MMMM d, yyyy"
                            className={classes.customDatePicker}
                            calenderRef={this.calenderRef1}
                        />
                    </div>
                </TableCell>
                <TableCell className={classes.padding10} width={headers[4].width}>
                    <div
                        className={classes.datePickerWrapper}
                        ref={(node) => {
                            this.calenderRef2 = node;
                        }}
                    >
                        <DatePicker
                            calendarClassName={classes.calendarClass}
                            selected={data.userDateEnd !== defaultEndDate ? isDateCorrect(data.userDateEnd, 'value', null) : null}
                            onChange={handleDateChange('userDateEnd', index,data.userName)}
                            onChangeRaw={(e) => handleChangeRaw(e, index)}
                            onCalendarClose={handleCalendarClose}
                            popperPlacement="auto-left"
                            dateFormat="MMMM d, yyyy"
                            className={classes.customDatePicker}
                            minDate={new Date(data.userDateStart).setDate(new Date(data.userDateStart).getDate() + 1)}
                            calenderRef={this.calenderRef2}
                        />
                    </div>
                </TableCell>
                <TableCell className={classes.padding10} width={headers[5].width}>
                    <Select
                        value={data.selectedItem}
                        onChange={(_data) => {
                            getSelectedItems(_data.value, data.id);
                        }}
                        className={classes.select}
                        isBorder={false}
                        options={convertToSelectValues(items)}
                        isMultiSelect={false}
                        readOnly
                        key={Math.random()}
                    />
                </TableCell>
                <TableCell className={classes.padding8} width={headers[6].width}>
                    <CustomSwitch checked={data.isUserActive} onChange={changeStatus(data.id)} value="isUserActive" />
                </TableCell>
                <TableCell className={classes.padding40} width={headers[7].width}>
                    <div className={classes.actionIconsContainer}>
                        {
                            !isUpdate && addRowData.length > 1 ? (
                                <IconButton className={classes.iconButtonRoot} onClick={() => deleteTabRow(data.id)} disabled={false}>
                                    <CloseIcon className={classes.actionIcons} />
                                </IconButton>
                            ) : disableRowOnUpdate && index === 0 ? (
                                <IconButton className={classes.iconButtonRoot} onClick={() => deleteTabRow(data.id)} disabled={false}>
                                    <CloseIcon className={classes.actionIcons} />
                                </IconButton>
                            ) : null
                        }
                    </div>
                </TableCell>
            </TableRow>
        );
    }
}

export default AccountUserRow;
