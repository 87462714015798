import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { getFormatedDate, getRelativeTime } from '../../utils/utils';
import Tooltip from '../Tooltip';

const styles = (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    item: {
        marginLeft: '1%',
        paddingTop: '2%',
        color: theme.palette.primary.dark,
    },
    timeItem: {
        marginLeft: '2%',
        fontSize: theme.typography.body1,
        color: theme.palette.secondary.light,
    },
    time: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '0%',
        paddingRight: '5px',
        fontSize: 16,
    },
    caseTableHeader: {
        width: '100%',
    },
});

function ProjectDate(props) {
    const { classes, date, caseTableHeader, customStyles, toolTip, user } = props;
    return (
        <Grid className={caseTableHeader ? [classes.root, classes.caseTableHeader].join(' ') : classes.root}>
            {/* <FontAwesomeIcon icon={faCalendarAlt} aria-label="faCalendarAlt" id="faCalendarAlt" className={classes.iconStyle} style={customStyles && customStyles.fontSize ? { fontSize: customStyles.fontSize, marginRight: '8px' } : { marginRight: '8px' }} /> */}
            <Typography noWrap className={classes.item}>
                {' '}
                {toolTip ? (
                    <Tooltip data={toolTip}>
                        <span style={customStyles && customStyles.fontSize ? { fontSize: customStyles.fontSize, marginRight: '8px' } : {}}>
                            {date ? (user && user.isRelativeDateFormat ? getRelativeTime(date) : getFormatedDate(date, 'DD MMM YYYY', '')) : ''}
                        </span>
                    </Tooltip>
                ) : (
                    <span style={customStyles && customStyles.fontSize ? { fontSize: customStyles.fontSize, marginRight: '8px' } : {}}>
                        {date ? (user && user.isRelativeDateFormat ? getRelativeTime(date) : getFormatedDate(date, 'DD MMM YYYY', '')) : ''}
                    </span>
                )}
            </Typography>
            {/* <div className={classes.time} /> */}
            {/* <Typography noWrap className={classes.timeItem} >
                {' '}
                {date ? moment(date).format('HH:mm:ss') : ''}
            </Typography> */}
        </Grid>
    );
}

ProjectDate.propTypes = {
    // date: PropTypes.instanceOf(Date),
    classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ProjectDate);
