import React from 'react';

// Components
import HeaderTop from './HeaderTop';
import HeaderMiddle from './HeaderMiddle';
import ProjectDetailTopBar from '../../projectDetailTopBar';

const Header = (props) => {
    const { headerMiddleActions, history, isNextPrev, paramProjectId, selectedTab } = props;

    return (
        <div>
            <HeaderTop />
            <ProjectDetailTopBar callFromStepEditor />

            <HeaderMiddle
                history={history}
                isNextPrev={isNextPrev}
                paramProjectId={paramProjectId}
                selectedTab={selectedTab}
                headerMiddleActions={headerMiddleActions}
            />

            {/* <HeaderBottom /> */}
        </div>
    );
};

export default Header;
