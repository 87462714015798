// import material
import { withStyles } from '@material-ui/core/styles';

// import icons - material
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

// import packages
import React from 'react';

const styles = () => ({
    keyboardArrowStyle: {
        color: '#C4C4C4',
        fontSize: 20,
        marginRight: 5,
    },
});

const Arrow = ({ classes, flag }) => {
    return flag ? (
        <KeyboardArrowUp className={classes.keyboardArrowStyle} aria-label="keyboardArrowUpIcon" id="keyboardArrowUpIcon" />
    ) : (
        <KeyboardArrowDown className={classes.keyboardArrowStyle} aria-label="keyboardArrowDownIcon" id="keyboardArrowDownIcon" />
    );
};

const ArrowUpDown = withStyles(styles)(Arrow);
export { ArrowUpDown };
