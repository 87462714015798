import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import Checkbox from '@material-ui/core/Checkbox';

import { addUserPreference } from '../../../../utils';
import { MODAL_SUBMIT_BUTTON } from '../../../../common/cssConstants';
import { DialogTop } from '../../../../components/Modal/ModalTop';
import GeneralModalFooter from '../../../modal/GeneralModal/GeneralModalFooter';

const styles = () => ({
    multiSelectAlertRoot: {
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        left: '50%',
        transform: 'translate(-50%, 0px)',
        width: 400,
        height: 180,
        zIndex: 1,
        borderRadius: '0 0 15px 15px',
        boxShadow: '0 0 80px 0 rgba(0,0,0,0.8)',
    },
    innerText: {
        margin: 0,
        fontSize: 12,
        color: '#4A4A4A',
        marginLeft: 30,
        marginTop: 12,
    },
    dontShowCheckbox: {
        color: '#F39B31 !important',
        height: 20,
        width: 20,
        marginRight: 8,
    },
    dontShowMessage: {
        color: '#4A4A4A',
        fontSize: 12,
    },
    dontShowWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 30,
    },
    btnWrapper: {
        marginLeft: 30,
        marginTop: 10,
    },
    overlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: 1,
        height: '100%',
        width: '100%',
    },
    faLightBulb: {
        color: '#F39B31',
        height: 20,
        width: 12,
        marginRight: 10,
    },
});

const AlertPopUpModal = (props) => {
    const [isDontShow, setisDontShow] = useState(false);
    const { localStorageKey, handleClose, top, classes, heading, checkBoxMessage, message } = props;
    return (
        <div>
            <div className={classes.overlay}></div>
            <div className={classes.multiSelectAlertRoot} style={{ top }}>
                <DialogTop
                    title={heading}
                    titleStyle={{ padding: '30px 45px 5px 30px' }}
                    closeIconAction={handleClose}
                    icon={
                        <FontAwesomeIcon
                            icon={faLightbulb}
                            aria-label="AlertPopUpModal_faLightBulb"
                            id="AlertPopUpModal_faLightBulb"
                            className={classes.faLightBulb}
                        />
                    }
                />
                <p className={classes.innerText}>{message}</p>
                <div className={classes.dontShowWrapper}>
                    <Checkbox
                        className={classes.dontShowCheckbox}
                        checked={isDontShow}
                        onClick={() => {
                            setisDontShow(!isDontShow);
                        }}
                    />
                    <p className={classes.dontShowMessage}>{checkBoxMessage}</p>
                </div>
                <div className={classes.btnWrapper}>
                    <GeneralModalFooter
                        buttons={[
                            {
                                name: 'OK',
                                variant: 'contained',
                                style: { ...MODAL_SUBMIT_BUTTON },
                                action: () => {
                                    if (isDontShow) {
                                        addUserPreference(`${localStorageKey}`, true);
                                    }
                                    handleClose();
                                },
                                isDisabled: false,
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};
AlertPopUpModal.defaultProps = {
    top: 0,
};
export default withStyles(styles)(AlertPopUpModal);
