import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { BLACK_FONT, FLEX_CENTRE } from '../../../common/cssConstants';
import { generalModalActions } from '../../../store/actions';
import Select from '../../modal/GeneralModal/Select';
import { checkKeyInObject, checkArrayLength, isDateCorrect, makeLocalDate } from '../../../utils';
import { isEmail, isAccountName, PASSWORD_REGEX } from '../../../common/regexConstants';
import DatePicker from '../../modal/GeneralModal/DatePicker';
import { defaultStartDate, defaultEndDate } from '../../../common/constants';

const styles = () => ({
    mainWrapper: {
        maxHeight: 'calc(100vh - 278px)',
        overflowX: 'hidden',
        padding: '0px 30px',
    },
    font: {
        fontSize: 12,
        color: BLACK_FONT,
    },
    labelColor: {
        '&>label': {
            color: BLACK_FONT,
            fontSize: '12px',
            transform: 'translate(0, 1.5px)',
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    select: {
        width: '100%',
        marginTop: '16px',
        marginBottom: '8px',
    },
    dateTimeContainer: {
        ...FLEX_CENTRE,
        width: 522,
        justifyContent: 'space-between',
    },
    startTimeContainer: {
        display: 'flex',
        width: '45%',
        flexDirection: 'column',
    },
    endTimeContainer: {
        display: 'flex',
        width: '45%',
        flexDirection: 'column',
    },
    calendarClassEnd: {
        textTransform: 'capitalize',
        top: -10,
        '&>.react-datepicker__triangle': {
            display: 'none',
        },
    },
    customDatePicker: {
        background: 'transparent',
        border: 'none',
        color: BLACK_FONT,
        fontSize: 12,
        fontWeight: 500,
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        outline: 'none',
        padding: '6px 0 7px 0',
        width: '100%',
    },
    label: {
        marginTop: 10,
        fontSize: 12,
        color: BLACK_FONT,
    },
    errorMessage: {
        fontSize: 11,
        color: 'red',
        display: 'block',
    },
});

class UserModal extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            selectedItem: '',
            userName: '',
            password: '',
            selectedDateStart: defaultStartDate,
            selectedDateEnd: defaultEndDate,
            isDirty: false,
            isDuplicateUserName: false,
            isDuplicateEmail: false,
            isPastedDateSame: false
        };
        this.calenderRef1 = React.createRef();
        this.calenderRef2 = React.createRef();
    }

    componentDidMount() {
        const { updateTabData, modalData, modalFor } = this.props;
        if (modalFor === 'UpdateUser') {
            let dateObj = {};
            if (checkKeyInObject(modalData, 'user.startDate') && checkKeyInObject(modalData, 'user.endDate')) {
                dateObj = {
                    selectedDateStart: new Date(makeLocalDate(modalData.user.startDate)).getTime(),
                    selectedDateEnd: new Date(makeLocalDate(modalData.user.endDate)).getTime(),
                };
            }
            this._setState(
                {
                    userName: checkKeyInObject(modalData, 'user.name'),
                    email: checkKeyInObject(modalData, 'user.email'),
                    selectedItem: checkKeyInObject(modalData, 'user.role'),
                    ...dateObj,
                },
                () => {
                    updateTabData('UserModal', { state: { ...this.state } });
                    updateTabData('isDisabledSubmitButton', false);
                    updateTabData('modalData', { ...modalData });
                },
            );
        } else if (modalFor === 'AddUser') {
            updateTabData('UserModal', { state: { ...this.state } });
            updateTabData('isDisabledSubmitButton', false);
        }
    }

    getSelectedItems = (selectedItem) => {
        if(selectedItem){
            this._setState({ selectedItem });
        } else {
            this._setState({ selectedItem: '' })
        }
    };

    handleChange = (name) => (event) => {
        this._setState({ [name]: event.target.value.trim() }, () => {
            if (name === 'userName' && this.state.isDuplicateUserName) {
                this.checkUserName();
            } else if (name === 'email' && this.state.isDuplicateEmail) {
                this.checkEmail();
            }
        });
    };

    checkEmail = () => {
        const { users, modalData } = this.props;
        let isUserEmailValid;
        if (checkArrayLength(users)) {
            isUserEmailValid = users.find((user) => {
                if (checkKeyInObject(modalData, 'user.id') !== user.id && user.email === this.state.email) {
                    return user;
                }
                return false;
            });
            if (isUserEmailValid) {
                this._setState({ isDuplicateEmail: true });
            } else {
                this._setState({ isDuplicateEmail: false });
            }
        }
    };

    checkUserName = () => {
        const { users } = this.props;
        let isUserNameValid;
        if (checkArrayLength(users)) {
            isUserNameValid = users.find(({ name }) => name === this.state.userName);
            if (isUserNameValid) {
                this._setState({ isDuplicateUserName: true });
            } else {
                this._setState({ isDuplicateUserName: false });
            }
        }
    };

    _setState = (obj, cb = () => {}) => {
        const { updateTabData } = this.props;
        this.setState(obj, () => {
            cb();
            updateTabData('UserModal', { state: { ...this.state } });
        });
    };

    errorMessage = () => {
        const { modalFor, classes } = this.props;
        const { userName, isDuplicateUserName } = this.state;

        let error = null;
        if (modalFor === 'AddUser' && userName === '') {
            error = <span className={classes.errorMessage}>Please fillout username.</span>;
        } else if (modalFor === 'AddUser' && userName !== '' && !isAccountName.test(userName) && !isEmail.test(userName)) {
            error = <span className={classes.errorMessage}>Username should only be lowercase alphanumeric and spaces are not allowed.</span>;
        } else if (isDuplicateUserName) {
            error = <span className={classes.errorMessage}>Usename already exists.</span>;
        }
        return error;
    };

    render() {
        const {
            email,
            userName,
            password,
            isDirty,
            selectedItem,
            /* isDuplicateUserName, */ isDuplicateEmail,
            selectedDateStart,
            selectedDateEnd,
            isPastedDateSame
        } = this.state;
        const { classes, items, convertToSelectValues, modalFor, activeUser, modalData } = this.props;
        const isRoleOptionDisabled = activeUser.role === 'ADMIN' && Number(activeUser.id) === (modalData && modalData.user && modalData.user.id);

        return (
            <div className={classes.mainWrapper}>
                <TextField
                    id="UserNameTextField"
                    value={userName}
                    label="User Name"
                    aria-label="User Name TextField"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        classes: {
                            input: classes.font,
                        },
                        autoComplete: 'off',
                    }}
                    className={classes.labelColor}
                    onChange={this.handleChange('userName')}
                    onBlur={this.checkUserName}
                    disabled={modalFor === 'UpdateAccountUser' || modalFor === 'UpdateUser'}
                />

                {this.errorMessage()}

                <TextField
                    id="EmailTextField"
                    value={email}
                    label="Email"
                    aria-label="Email TextField"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    margin="normal"
                    autoComplete="off"
                    InputProps={{
                        classes: {
                            input: classes.font,
                        },
                        autoComplete: 'off',
                    }}
                    className={classes.labelColor}
                    onChange={this.handleChange('email')}
                    onBlur={this.checkEmail}
                />

                {email && !isEmail.test(String(email).toLowerCase()) && (
                    <span style={{ fontSize: 11, color: 'red', display: 'block' }}>Invalid email address.</span>
                )}

                {email && isDuplicateEmail && <span style={{ fontSize: 11, color: 'red', display: 'block' }}>Email address already exists.</span>}

                {modalFor === 'AddUser' && (
                    <TextField
                        id="PasswordTextField"
                        value={password}
                        aria-label="Password TextField"
                        label="Password"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        InputProps={{
                            classes: {
                                input: classes.font,
                            },
                            autoComplete: 'off',
                        }}
                        className={classes.labelColor}
                        margin="normal"
                        onChange={this.handleChange('password')}
                        type="password"
                        onFocus={() => {
                            this._setState({ isDirty: true });
                        }}
                    />
                )}
                {modalFor === 'AddUser' && password && isDirty && !PASSWORD_REGEX.test(password) ? (
                   <span style={{ fontSize: 11, color: 'red', display: 'block' }}>Password should contain atleast 8 characters, 1 special, 1 small and upper case character, 1 numeric character</span>
                ) : (
                    <span />
                )}

                <div className={classes.dateTimeContainer}>
                    <div
                        className={classes.startTimeContainer}
                        ref={(node) => {
                            this.calenderRef1 = node;
                        }}
                    >
                        <span className={classes.label}>Start time (Optional)</span>
                        <DatePicker
                            calendarClassName={classes.calendarClassStart}
                            selected={selectedDateStart !== defaultStartDate ? isDateCorrect(selectedDateStart, 'value', null) : null}
                            onChange={(date) => {
                                if (date) {
                                    this._setState({ selectedDateStart: date.getTime() });
                                } else {
                                    this._setState({ selectedDateStart: defaultStartDate });
                                }
                            }}
                            popperPlacement="auto-left"
                            dateFormat="MMMM d, yyyy"
                            className={`${classes.customDatePicker}`}
                            // minDate={new Date()}
                            calenderRef={this.calenderRef1}
                        />
                    </div>
                    <div
                        className={classes.endTimeContainer}
                        ref={(node) => {
                            this.calenderRef2 = node;
                        }}
                    >
                        <span className={classes.label}>End time (Optional)</span>
                        <DatePicker
                            calendarClassName={classes.calendarClassEnd}
                            selected={selectedDateEnd !== defaultEndDate ? isDateCorrect(selectedDateEnd, 'value', null) : null}
                            onChange={(date) => {
                                if (date) {
                                    this._setState({ selectedDateEnd: date.getTime() });
                                } else {
                                    this._setState({ selectedDateEnd: defaultEndDate });
                                }
                            }}
                            onChangeRaw={(e) => {
                                if (selectedDateStart >= (new Date(e.target.value)).getTime()) {
                                    this._setState({ isPastedDateSame: true });
                                }
                                else{
                                    this._setState({ isPastedDateSame: false });
                                }
                            }}
                            onCalendarClose={() => {
                                this._setState({ isPastedDateSame: false });
                            }}
                            popperPlacement="auto-left"
                            dateFormat="MMMM d, yyyy"
                            className={`${classes.customDatePicker}`}
                            minDate={new Date(selectedDateStart).setDate(new Date(selectedDateStart).getDate() + 1)}
                            calenderRef={this.calenderRef2}
                        />
                    </div>
                </div>
                {(new Date(selectedDateStart) >= new Date(selectedDateEnd) || isPastedDateSame) && (
                    <span style={{ fontSize: 11, color: 'red', display: 'block' }}>"End time" must be greater than "Start time".</span>
                )}

                <Select
                    value={selectedItem}
                    onChange={(data) => {
                        this.getSelectedItems(data.value);
                    }}
                    className={classes.select}
                    isBorder
                    label="Role"
                    isDisabled={isRoleOptionDisabled}
                    options={convertToSelectValues(items)}
                    isMultiSelect={false}
                    readOnly
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tabsData: state.generalModalReducer.tabsData,
        users: state.userReducer.users,
        activeUser: state.authReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserModal));
