import React from 'react';

const HiddenContext = React.createContext({ width: '' });

const HiddenProvider = ({ width, ...props }) => {
    const contextValues = { width };

    return <HiddenContext.Provider value={contextValues}>{props.children}</HiddenContext.Provider>;
};

const useHiddenContext = () => {
    return React.useContext(HiddenContext);
};

export { useHiddenContext };
export default HiddenProvider;
