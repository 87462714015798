// import material
import { withStyles } from '@material-ui/core/styles';
// import packages
import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { checkKeyInObject } from '../../../../utils';

const styles = () => ({
    downloadedFileLoaderContainer: {
        alignItems: 'center',
        backgroundColor: '#ffffffbf',
        borderBottom: '1px solid #e0e0e0',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        position: 'absolute',
        width: '100%',
        zIndex: 1,
        '&>div': {
            color: '#2792e6',
        },
    },
    DownloadingTag: {
        position: 'absolute',
        top: '8px',
        right: '16px',
        fontSize: '10px',
    },
});

const FadedRowLoader = ({ classes, isExecution, listView, testCaseId, testStep }) => {
    let showLoader = null;
    const isWsRunning = useSelector((state) => state.projectReducer.isWsRunning);
    if (isExecution) {
        showLoader = testStep.isDownloadingFileExec;
    } else {
        showLoader = testStep.isDownloadingFileCase && checkKeyInObject(isWsRunning, testCaseId);
    }

    return showLoader ? (
        <div className={classes.downloadedFileLoaderContainer}>
            <div className={classes.DownloadingTag}>Downloading File ...</div>
            <CircularProgress aria-label="downloadingFileLoader" id="downloadingFileLoader" size={listView ? 28 : 40} />
        </div>
    ) : null;
};

export default withStyles(styles)(FadedRowLoader);
