import React from 'react';
import { connect } from 'react-redux';
import RequestImageService from '../services/requestImageService';
import defaultScreenShot from '../assets/images/noScreenshot.png';

class PreLoadImage extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;
    }

    componentDidMount() {
        if (!this.props.image) {
            this.props.onStartLoad();
            this.timer = setTimeout(() => {
                RequestImageService.requestImage(this.props.src, this.props.onError);
            }, 400);
        } else {
            this.props.onLoad();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.image && nextProps.isLoading === false) {
            this.props.onStartLoad();
            this.timer = setTimeout(() => {
                RequestImageService.requestImage(nextProps.src, this.props.onError);
            }, 400);
        }
        if ((this.props.isLoading === true && nextProps.isLoading === false) || (nextProps.src !== this.props.src && nextProps.image)) {
            nextProps.onLoad();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const { dispatch, isLoading, onLoad, onError, onStartLoad, image, defaultImage, ...rest } = this.props;
        return (
            <img
                {...rest}
                src={image}
                onError={(e) => {
                    if (defaultImage) e.target.src = defaultImage;
                }}
                alt=""
            />
        );
    }
}

PreLoadImage.defaultProps = {
    onStartLoad: () => {},
    onLoad: () => {},
    onError: () => {},
};

const mapStateToProps = (state, props) => {
    const isLoading = props.src && state.imagesReducer.loading ? state.imagesReducer.loading[props.src] : false;
    const image = props.src && state.imagesReducer.images ? state.imagesReducer.images[props.src] : defaultScreenShot;
    return {
        isLoading,
        image,
    };
};

export default connect(mapStateToProps, null)(PreLoadImage);
