const  componentStyles = (theme) => {
    return {

    messageContainer: {

        fontSize:'16px',
        fontWeight:'400',
        lineHeight:'24px',
        fontFamily:'Roboto !important'
    },

    messageContainerTitle : {
        fontWeight:'700'
    },

    messageContainerDescription : {
        fontWeight:'400'
    },
   
    
    success: {
        backgroundColor: '#5D9D52',
        borderRadius:'4px',
        color: '#ffffff',
    },

    error: {
        borderRadius:'4px',
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,

    },
    warning: {
        backgroundColor: '#FDF3D1',
        color: '#806420',
        borderRadius:'4px',
        
    },
    info: {
        backgroundColor: '#2DA6EA',
        color: '#ffffff',
        borderRadius:'4px',
    },

    closeIcon :{
        fontSize:'20px'
    }
}
}

export default componentStyles;
