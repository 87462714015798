import React from 'react';
import { Grid, Modal, Typography, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

const styles = (theme) => ({
    loader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    loadingText: {
        color: theme.palette.primary.contrastText,
        margin: 20,
        textAlign: 'center',
    },
});

function Loader(props) {
    const { classes, loadingText } = props;
    return (
        <Modal open>
            <Grid container className={classes.loader}>
                <Grid item>
                    <CircularProgress className={classes.progress} size={50} color="secondary" />
                </Grid>
                <Grid item>
                    <Typography className={classes.loadingText} variant="subtitle1">
                        {loadingText}
                    </Typography>
                </Grid>
            </Grid>
        </Modal>
    );
}

Loader.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    loadingText: PropTypes.string,
};
Loader.defaultProps = {
    loadingText: '',
};

export default withStyles(styles)(Loader);
