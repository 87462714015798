import { ActionTypes } from '../constants';
import { checkKeyInObject } from '../../utils/utils';

const initialState = {
    currentTab: 0,
    tabsData: {}, // Any type of state which can be used to transfer data b/w tabs
};

export default function generalModalReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ActionTypes.CLEAR_GENERAL_MODAL: {
            return {
                ...state,
                currentTab: 0,
                tabsData: {},
            };
        }
        case ActionTypes.CHANGE_TAB: {
            return {
                ...state,
                currentTab: payload,
            };
        }
        case ActionTypes.UPDATE_TAB_DATA: {
            let obj = { ...state };
            if (checkKeyInObject(payload, 'key', 'bool') && checkKeyInObject(payload, 'data', 'bool')) {
                obj = {
                    ...obj,
                    tabsData: { ...state.tabsData, [payload.key]: payload.data },
                };
            } else if (checkKeyInObject(payload, 'key')) {
                delete obj.tabsData[payload.key];
            }
            return obj;
        }

        default:
            return state;
    }
}
