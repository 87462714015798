import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PreLoadImage from '../../../common/PreLoadImage';

let heightGetImage = '';
let widthGetImage = '';

class SliderImageModal extends React.Component {
    constructor() {
        super();
        this.state = {
            hoverActive: false,
        };
    }

    getImageWidthHeight = () => {
        const ele = document.getElementsByClassName('sliderImages')[0];
        heightGetImage = ele.clientHeight;
        widthGetImage = ele.clientWidth;
    };

    render() {
        const { alt, currentIndex, hoverCase, sliderMask, src, handleModal, totalLength, classes, ind, selectedHoverImage } = this.props;
        const imagePreviewContainer = {
            position: 'absolute',
            justifyContent: 'space-around',
            flexDirection: 'column',
            alignItems: 'center',
            color: '#fff',
            fontSize: '16px',
            display: 'flex',
            top: 9,
            height: heightGetImage,
            width: widthGetImage,
            background: 'rgba(0,0,0,0.6)',
            zIndex: 999,
        };
        return (
            <div
                onMouseLeave={() => {
                    selectedHoverImage(null);
                    this.setState({ hoverActive: false });
                }}
                onMouseEnter={() => {
                    selectedHoverImage(ind);
                    this.setState({ hoverActive: true });
                }}
            >
                {/* Check if we hover on current image show without mask */}
                {hoverCase !== null && hoverCase !== currentIndex ? <div className={sliderMask}></div> : null}
                <PreLoadImage
                    onMouseEnter={this.getImageWidthHeight}
                    className="sliderImages"
                    style={{ maxWidth: '100%', maxHeight: '90px', minWidth: '160px' }}
                    alt={alt}
                    src={src}
                />
                {this.state.hoverActive && (
                    <div className={classes.imagePreviewContainerStyle} style={imagePreviewContainer}>
                        {/* perviously it was handleModal(true, hoverCase, currentIndex, totalLength) */}
                        <div
                            aria-hidden
                            onClick={() => handleModal(true, currentIndex, totalLength)}
                            className={classes.viewScreen}
                            style={{ display: 'block' }}
                        >
                            <span style={{ fontSize: 14 }}>View Screen</span>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const styles = () => ({
    viewScreen: {
        border: '1px solid #efefef',
        padding: '2px 5px',
        borderRadius: '5px',
        display: 'none',
        fontSize: '14px',
    },
    imagePreviewContainerStyle: {
        background: 'rgba(0,0,0,0.6)',
        display: 'flex',
        border: '1px solid gold',
        cursor: 'pointer',
    },
});

export default withStyles(styles)(SliderImageModal);
