// import material
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

// import packages
import React from 'react';
import { BLACK_FONT } from '../../../common/cssConstants';

const styles = (theme) => ({
    col: {
        width: '50%',
        '& > p': {
            color: BLACK_FONT,
            fontSize: 12,
            margin: 0,
        },
    },
    header: {
        '& > p': { fontWeight: 'bold' },
    },
    row: {
        display: 'flex',
        justifyContent: 'start',
        marginBottom: 10,
    },
    mainHeading: {
        width: '100%',
        fontSize: 12,
        color: BLACK_FONT,
        marginBottom: 20,
    },
    keyHeading: {
        width: 92,
        float: 'left',
        fontSize: 12,
        [theme.breakpoints.only('xs')]: {
            width: 72,
        },
    },
    dataValue: {
        float: 'left',
        fontSize: 12,
        fontWeight: 'bold',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    dataName: {
        float: 'left',
        fontSize: 12,
        fontWeight: 'bold',
        maxWidth: 'calc(100% - 96px)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.only('xs')]: {
            width: 0,
        },
    },
    formControl: {
        width: '100%',
        flexDirection: 'column',
    },
    text: {
        width: 'calc(100% - 20px)',
        maxWidth: '150px',
    },
});

function DeleteGroupModal({ groupNames, classes, members, _multiple, modalFor }) {
    const content = _multiple ? (
        <div>
            <Typography className={classes.mainHeading}>{`Are you sure you want to ${modalFor}?`}</Typography>
            <div>
                <div className={classes.row}>
                    <div className={[classes.col, classes.header].join(' ')}>
                        <p>Name</p>
                    </div>
                    <div className={[classes.col, classes.header].join(' ')}>
                        <p>Members</p>
                    </div>
                </div>
                {groupNames &&
                    groupNames.length > 0 &&
                    members &&
                    members.length > 0 &&
                    groupNames.map((groupName, index) => {
                        return (
                            <div key={`${groupName}-${index}`} className={classes.row}>
                                <div className={classes.col}>
                                    <p className={[classes.text, classes.noWrap].join(' ')}>{groupName}</p>
                                </div>
                                <div className={classes.col}>
                                    <p className={[classes.text, classes.noWrap].join(' ')}>{members[index]}</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    ) : (
        <div>
            <Typography className={classes.mainHeading} style={{ float: 'left' }}>
                {`Are you sure you want to ${modalFor}?`}
            </Typography>
            <FormControl className={classes.formControl}>
                <div
                    style={{
                        width: '100%',
                        height: 30,
                    }}
                >
                    <Typography className={classes.keyHeading}>Name</Typography>
                    <Typography className={classes.dataName}>{groupNames[0]}</Typography>
                </div>
                <div
                    style={{
                        width: '100%',
                    }}
                >
                    <Typography className={classes.keyHeading}>Members</Typography>
                    <Typography
                        className={[classes.dataValue, classes.noWrap].join(' ')}
                        style={{
                            display: 'block',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                    >
                        {members[0]}
                    </Typography>
                </div>
            </FormControl>
        </div>
    );
    return <div>{content}</div>;
}

export default withStyles(styles)(DeleteGroupModal);
