import store from '..';
import { ActionTypes } from '../constants';
import { TestStepUtils } from '../../utils/TestStepUtils';
import { checkIsArray, checkKeyInObject, checkObject, checkArrayLength, getParamValues } from '../../utils';

class selectedTestCaseAction {
    static clearSelectedTestCaseData() {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.CLEAR_SELECTED_TESTCASE_REDUCER,
            });
    }

    static setSelectedTestCaseData(data = {}) {
        const { platformDebugPointList, testCaseId, testSteps } = data;
        const testStepsObj = {};
        const instrNumArray = [];

        return (dispatch, getState) => {
            let { debugPointList } = getState().projectsReducer;
            const { selectedTestCaseReducer } = getState();
            const testCaseStatus = data.testCaseStatus || selectedTestCaseReducer.testCaseStatus;
            const stepsType = data.stepsType || selectedTestCaseReducer.stepsType;

            if (checkArrayLength(platformDebugPointList)) {
                debugPointList = { ...debugPointList, [testCaseId]: platformDebugPointList };
            }

            const isAnyDebugPoint = checkKeyInObject(debugPointList, testCaseId, 'bool') && checkIsArray(debugPointList[testCaseId]);
            testSteps.forEach((step) => {
                let __step = step;
                if (isAnyDebugPoint && debugPointList[testCaseId].indexOf(step.instrNum) > -1) {
                    __step = { ...step, debug: true };
                }
                testStepsObj[step.instrNum] = __step;
                instrNumArray.push(step.instrNum);
            });
            const cacheXpaths = TestStepUtils.setCacheXpath(true, selectedTestCaseReducer, instrNumArray, testStepsObj);
            dispatch({
                type: ActionTypes.UPDATE_DEBUG_POINT_LIST,
                payload: { debugPointList },
            });
            return dispatch({
                type: ActionTypes.SET_SELECTED_TESTCASE_DATA,
                payload: {
                    cacheXpaths,
                    instrNumArray,
                    testCaseId,
                    testCaseStatus,
                    testStepsObj,
                    stepsType,
                },
            });
        };
    }

    static updateAutoSuggestCount = (count) => {
        return (dispatch) => {
            return dispatch({
                type: ActionTypes.UPDATE_AUTO_SUGGEST_COUNT,
                payload: { count },
            });
        };
    };

    static addDummyStepInStepsArray(flag, instrNumArray, testSteps, newIndex, callback = () => {}) {
        setTimeout(() => {
            callback();
        }, 500); // wait 500 ms to set index before scrolling
        return (dispatch) =>
            dispatch({
                type: ActionTypes.ADD_DUMMY_STEP_IN_STEPS_ARRAY,
                payload: { flag, instrNumArray, testSteps, newIndex },
            });
    }

    static removeDummyStepInStepsArray(flag, instrNumArray, testSteps) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.REMOVE_DUMMY_STEP_IN_STEPS_ARRAY,
                payload: { flag, instrNumArray, testSteps },
            });
    }

    static addStepInStepsArray(obj) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.ADD_STEP_IN_STEPS_ARRAY,
                payload: { ...obj },
            });
    }

    static openEditModeForStep(index) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.OPEN_EDIT_MODE_FOR_STEP,
                payload: { index },
            });
    }

    static closeEditModeForStep() {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.CLOSE_EDIT_MODE_FOR_STEP,
            });
    }

    static editStepInStepsArray(step = {}) {
        return (dispatch, getState) => {
            const { failedStepsData } = getState().projectReducer;
            const { debugStepsData } = getState().projectReducer;
            dispatch({
                type: ActionTypes.EDIT_STEP_IN_STEPS_ARRAY,
                payload: { step, failedStepsData, debugStepsData },
            });
        };
    }

    static updateStepStatus(instrNum, data = {}, msgType = null) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.UPDATE_SINGLE_STEP_STATUS,
                payload: { instrNum, data, msgType },
            });
    }

    static setTestCaseGenerationStatus(status = false) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.SET_TEST_CASE_GENERATION_STATUS,
                payload: { status },
            });
    }

    static deleteStepsFromStepsArray(indexesToRemove) {
        const { testSteps, instrNumArray } = TestStepUtils.removeNewInsrNumAndIndex(indexesToRemove);
        const indexesToRemoveSortedASC = [...indexesToRemove.sort((a, b) => a - b)];

        return (dispatch, getState) => {
            const { failedStepsData, debugStepsData } = getState().projectReducer;
            const paramTestCaseId = getParamValues(2);
            const isMsgType13Received = checkKeyInObject(failedStepsData, paramTestCaseId) && failedStepsData[paramTestCaseId].instrNum;
            const isMsgType16Received = checkKeyInObject(debugStepsData, paramTestCaseId) && debugStepsData[paramTestCaseId].instrNum;
            // Add isFirstEditedStep only if msgType 13 and 16 received
            if (isMsgType13Received || isMsgType16Received) {
                let instrNum = instrNumArray[indexesToRemoveSortedASC[0]];
                if (checkKeyInObject(testSteps, instrNum)) {
                    testSteps[instrNum] = { ...testSteps[instrNum], isFirstEditedStep: true };
                } else if (!instrNum) {
                    instrNum = instrNumArray[instrNumArray.length - 1];
                    if (checkKeyInObject(testSteps, instrNum)) {
                        testSteps[instrNum] = { ...testSteps[instrNum], isFirstEditedStep: true };
                    }
                }
            }
            dispatch({
                type: ActionTypes.DELETE_STEPS_FROM_STEPS_ARRAY,
                payload: { testSteps, instrNumArray },
            });
        };
    }

    static saveTemp = (data) => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.SAVE_TEMP_STEPS_ARRAY,
                payload: { data },
            });
    };

    static undo = () => {
        const { testSteps, instrNumArray, redoData, undoData } = store.getState().selectedTestCaseReducer;
        let currentSteps = { ...testSteps };
        let _instrNumArray = [...instrNumArray];
        const temp = [...redoData];
        const stepData = undoData.shift(); // Extract last step saved for undo from 1st index
        const lastTreatedStep = { ...stepData }; // Step detail to control undo functionality
        // If last action performed on step is delete or add
        //  insert step on 1st index to save in redo otherwise do nothing with temp
        if (lastTreatedStep.action === 'delete' || lastTreatedStep.action === 'add') temp.unshift(lastTreatedStep);
        // Performing undo action start
        if (checkObject(lastTreatedStep)) {
            const { stepsDataToSave, action: stepAction } = lastTreatedStep;
            if (checkArrayLength(stepsDataToSave)) {
                if (stepAction === 'delete') {
                    const instrNumToAdd = stepsDataToSave
                        .map((stepDetails) => checkKeyInObject(stepDetails, 'testStep.instrNum', 'value', ''))
                        .filter((instrNum) => !!instrNum);
                    const { newIndexes, updatedInstrNums, updatedTestSteps } = TestStepUtils.createMultiNewInstrNumbers(instrNumToAdd);
                    newIndexes.forEach((index) => {
                        const currentRow = stepsDataToSave.find((stepDetails) => stepDetails.rowIndex === index);
                        if (checkObject(currentRow)) {
                            const instrNum = updatedInstrNums[index];
                            updatedTestSteps[instrNum] = { ...currentRow.testStep };
                        }
                    });
                    currentSteps = { ...updatedTestSteps };
                    _instrNumArray = [...updatedInstrNums];
                } else if (stepAction === 'edit') {
                    stepsDataToSave.forEach((stepDetails) => {
                        // After undo save edited step's detail in redoData at 1st index, in proper format.
                        temp.unshift({
                            action: stepAction,
                            stepsDataToSave: [
                                {
                                    rowIndex: stepDetails.rowIndex,
                                    testStep: currentSteps[stepDetails.testStep.instrNum],
                                },
                            ],
                        });
                        currentSteps[stepDetails.testStep.instrNum] = stepDetails.testStep;
                        _instrNumArray.splice(stepDetails.rowIndex, 1, stepDetails.testStep.instrNum);
                    });
                } else if (stepAction === 'add') {
                    const indexesToRemove = stepsDataToSave.map((stepDetails) => stepDetails.rowIndex);
                    const updatedData = TestStepUtils.removeNewInsrNumAndIndex(indexesToRemove);
                    currentSteps = { ...updatedData.testSteps };
                    _instrNumArray = [...updatedData.instrNumArray];
                }
            }
        }
        const data = { testSteps: currentSteps, instrNumArray: _instrNumArray, redoData: temp, lastTreatedStep };
        // Performing undo action end
        return (dispatch) => dispatch({ type: ActionTypes.UNDO_STEPS_ARRAY_ACTION, payload: data });
    };

    static redo = () => {
        const { testSteps, instrNumArray, redoData, undoData } = store.getState().selectedTestCaseReducer;
        let currentSteps = { ...testSteps };
        let _instrNumArray = [...instrNumArray];
        const temp = [...undoData];
        const stepData = redoData.shift(); // Extract last step saved for redo from 1st index
        const lastTreatedStep = { ...stepData }; // Step detail to control undo functionality
        // If last action performed on step is delete or add
        //  insert step on 1st index to save in undo otherwise do nothing with temp
        if (lastTreatedStep.action === 'delete' || lastTreatedStep.action === 'add') temp.unshift(lastTreatedStep);

        // Performing undo action start
        if (checkObject(lastTreatedStep)) {
            const { stepsDataToSave, action: stepAction } = lastTreatedStep;
            if (checkArrayLength(stepsDataToSave)) {
                if (stepAction === 'delete') {
                    const indexesToRemove = stepsDataToSave.map((stepDetails) => stepDetails.rowIndex);
                    const updatedData = TestStepUtils.removeNewInsrNumAndIndex(indexesToRemove);
                    currentSteps = { ...updatedData.testSteps };
                    _instrNumArray = [...updatedData.instrNumArray];
                } else if (stepAction === 'edit') {
                    stepsDataToSave.forEach((stepDetails) => {
                        // After redo save edited step's detail in undoData at 1st index, in proper format.
                        temp.unshift({
                            action: stepAction,
                            stepsDataToSave: [
                                {
                                    rowIndex: stepDetails.rowIndex,
                                    testStep: currentSteps[stepDetails.testStep.instrNum],
                                },
                            ],
                        });
                        currentSteps[stepDetails.testStep.instrNum] = stepDetails.testStep;
                        _instrNumArray.splice(stepDetails.rowIndex, 1, stepDetails.testStep.instrNum);
                    });
                } else if (stepAction === 'add') {
                    const instrNumToAdd = stepsDataToSave
                        .map((stepDetails) => checkKeyInObject(stepDetails, 'testStep.instrNum', 'value', ''))
                        .filter((instrNum) => !!instrNum);
                    const { newIndexes, updatedInstrNums, updatedTestSteps } = TestStepUtils.createMultiNewInstrNumbers(instrNumToAdd);
                    newIndexes.forEach((index) => {
                        const currentRow = stepsDataToSave.find((stepDetails) => stepDetails.rowIndex === index);
                        if (checkObject(currentRow)) {
                            const instrNum = updatedInstrNums[index];
                            updatedTestSteps[instrNum] = { ...currentRow.testStep };
                        }
                    });
                    currentSteps = { ...updatedTestSteps };
                    _instrNumArray = [...updatedInstrNums];
                }
            }
        }
        const data = { testSteps: currentSteps, instrNumArray: _instrNumArray, undoData: temp, lastTreatedStep };
        return (dispatch) => dispatch({ type: ActionTypes.REDO_STEPS_ARRAY_ACTION, payload: data });
    };

    static emptyUndoRedoArrays = () => {
        return (dispatch) => dispatch({ type: ActionTypes.EMPTY_UNDO_REDO_ARRAY });
    };

    static setActionType = (actionType = '') => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.SET_ACTIONTYPE_FOR_STEPS_ARRAY,
                payload: { actionType },
            });
    };

    static toggleStopIcon = (flag) => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.TOGGLE_STOP_ICON,
                payload: { flag },
            });
    };

    static toggleSaveIcon = (flag) => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.TOGGLE_SAVE_ICON,
                payload: { flag },
            });
    };

    static toggleStepsType = (flag) => {
        // use to toggle b/w steps types live or recover
        return (dispatch) =>
            dispatch({
                type: ActionTypes.TOGGLE_STEP_TYPE,
                payload: { flag },
            });
    };

    /* CacheXpath start */
    static setAllCacheXpaths = (flag) => {
        return (dispatch, getState) => {
            const { selectedTestCaseReducer } = getState();
            const cacheXpaths = TestStepUtils.setCacheXpath(flag, selectedTestCaseReducer);

            return dispatch({
                type: ActionTypes.SET_ALL_CACHE_XPATHS,
                payload: { cacheXpaths },
            });
        };
    };

    static toggleStepCacheXpath = (flag, instrNum) => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.TOGGLE_STEP_XPATH,
                payload: { flag, instrNum },
            });
    };

    /* CacheXpath end */
    /* Step selection functionality start */
    static toggleSelectAllTestSteps = () => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.TOGGLE_SELECT_ALL_STEP,
            });
    };

    static selectMultipleSteps = (steps) => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.SELECT_MULTIPLE_STEPS,
                payload: { steps },
            });
    };

    static selectStep = (step) => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.SELECT_STEP,
                payload: { step },
            });
    };

    static emptySelectedSteps = () => {
        return (dispatch) => dispatch({ type: ActionTypes.EMPTY_SELECT_STEP });
    };

    /* Step selection functionality end */
    static toggleAutoScroll = () => {
        return (dispatch) => dispatch({ type: ActionTypes.TOGGLE_AUTO_SCROLL });
    };

    static updateTestStepsObject = (testSteps = {}) => {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.SET_TEST_STEPS_OBJECT,
                payload: { testSteps },
            });
        };
    };

    /* Switch between list/screeshot view start */
    static toggleListView = (flag) => {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.TOGGLE_LIST_VIEW,
                payload: { flag },
            });
        };
    };

    //  Set expanded rows
    static toggleExpandedRow = (stepNo,isExpanded) => {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.SET_EXPANDED_INSTR_NO,
                payload: {stepNo, isExpanded}
            })
        }

    }

    /* Switch between list/screeshot view end */
    /* Smart Retry Loader b/w msgType 14 and 3 start */
    static setSmartRetryLoader = (data, show) => {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.SMART_RETRY_LOADER,
                payload: { data, show },
            });
        };
    };
    /* Smart Retry Loader b/w msgType 14 and 3 end */

    /* Debug Point Loader b/w msgType 17/18 and 3 start */
    static setDebugPointLoader = (data, show) => {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.DEPUG_POINT_LOADER,
                payload: { data, show },
            });
        };
    };

    /* Debug Point Loader b/w msgType 17/18 and 3 end */
    /* Smart Retry Loader b/w msgType 14 and 3 start */
    static updateShowPasteButton = (cond) => {
        return (dispatch) => dispatch({ type: ActionTypes.UPDATE_SHOW_PASTE_BUTTON, payload: cond });
    };

    static toggleCreationMode = () => {
        return (dispatch) => dispatch({ type: ActionTypes.TOGGLE_CREATION_MODE });
    };

    static toggleShadowDom = (val) => {
        return (dispatch) => dispatch({ type: ActionTypes.TOGGLE_TESTCASE_SHADOWDOM,payload:val});
    }
}

export default selectedTestCaseAction;
