import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

// Keyboard input comprehension node modules
import CloseOnEscape from 'react-close-on-escape';

const styles = (theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    dialog: {
        textAlign: 'center',
    },
    buttonStyle: {
        padding: '2px 8px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#1067ce',
        borderRadius: '6px',
        textTransform: 'capitalize',
        marginRight: '10px',
    },
    cancelButton: {
        padding: '2px 8px',
        minWidth: '75px',
        minHeight: 0,
        borderColor: '#1067ce',
        borderRadius: '6px',
        textTransform: 'capitalize',
    },
});

class FilePreviewModal extends React.Component {
    state = {
        isButtonDisabled: false,
        isDownloadClicked: false,
    };

    scriptDataWidthRef = React.createRef();

    handleButtonClick = () => {
        const { toggleModal } = this.props;
        toggleModal();
    };

    handleEscape = () => {
        this.handleButtonClick();
    };

    render() {
        const { classes, open, modalData } = this.props;
        return (
            <div className={classes.root}>
                <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" maxWidth="md">
                    <DialogTitle id="form-dialog-title" className={classes.dialog}>
                        {modalData.fileName ? modalData.fileName : 'Report Preview'}
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <hr />
                            {parse(modalData.data.replace(/>\s+</g, '><'))}
                        </div>
                    </DialogContent>
                    <DialogActions style={{ margin: '10px 10px' }}>
                        <Button
                            variant="contained"
                            onClick={modalData.handleDownload}
                            className={classes.buttonStyle}
                            color="secondary"
                            disabled={this.state.isDownloadClicked}
                        >
                            Download
                        </Button>
                        <CloseOnEscape onEscape={this.handleEscape}>
                            <Button
                                variant="outlined"
                                onClick={this.handleButtonClick}
                                className={classes.cancelButton}
                                color="secondary"
                                disabled={this.state.isButtonDisabled}
                            >
                                Close
                            </Button>
                        </CloseOnEscape>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

FilePreviewModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
};

export default withStyles(styles)(FilePreviewModal);
