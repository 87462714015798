import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Select from '../../modal/GeneralModal/Select';
import { BLACK_FONT } from '../../../common/cssConstants';

const styles = () => ({
    formControl: {
        width: '100%',
        borderTop: '1px solid #dcdcdc',
        borderBottom: '1px solid #dcdcdc',
        padding: '12px 30px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&>p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    root: {
        width: '100%',
        display: 'flex',
    },
    formControlAddFromHeader: {
        padding: '0px 30px 5px 30px',
        width: '85%',
        flexDirection: 'column',
        '&>p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    placeholder: {
        '&>div>div>:nth-child(3)::-webkit-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
        '&>div>div>:nth-child(3)::-moz-input-placeholder': {
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 300,
            color: '#C4C4C4',
            opacity: 1,
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    font: {
        fontSize: 12,
    },
    listItem: {
        width: '100%',
        minHeight: '40px',
        borderBottom: '1px solid #bac4ce',
        backgroundColor: '#F9F9F9',
        paddingTop: 0,
        paddingBottom: 0,
        display: 'flex',
        alignItems: 'center',
        padding: '0 30px',
        '&:hover [class*="secondaryAction"]': {
            visibility: 'visible',
        },
    },
    select: {
        width: '100%',
    },
    rootContainer: {
        marginTop: '-5px',
    },
    titleName: {
        color: '#595959',
        fontSize: '12px',
    },
    wrapperFormSelect: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    projectText: {
        width: '100%',
        fontWeight: '500',
        fontSize: '12px',
        color: BLACK_FONT,
    },
    wrapperTextField: {
        marginBottom: 17,
    },
});

const EditTestCaseDialogContent = (props) => {
    const { titleName, ariaLabel, onChange, placeholder, value, searchValue, searchOnChange, options, classes } = props;
    return (
        <div className={classes.rootContainer}>
            <FormControl className={classes.formControlAddFromHeader}>
                <div className={classes.wrapperTextField}>
                    <span className={classes.titleName}>{titleName}</span>
                    <TextField
                        id="name"
                        aria-label={ariaLabel}
                        onChange={onChange}
                        value={value}
                        className={classes.placeholder}
                        placeholder={placeholder}
                        margin="none"
                        InputProps={{ classes: { input: classes.font } }}
                        fullWidth
                        autoFocus
                        type="text"
                    />
                </div>
            </FormControl>
            <div className={classes.root}>
                <div className={classes.wrapperFormSelect}>
                    <FormControl className={classes.formControl}>
                        <Typography className={classes.projectText}>Project</Typography>
                    </FormControl>
                    <div>
                        <div key="addFromHeader" className={classes.listItem}>
                            <Select
                                value={searchValue}
                                placeholder="Search"
                                onChange={(target) => searchOnChange(target)}
                                className={classes.select}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(EditTestCaseDialogContent);
