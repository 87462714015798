import * as Cookies from 'es-cookie';

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
/*eslint-disable */
export default Storage = {
    get: (name, options) => {
        const value = Cookies.get(name, options);
        return value ? (isJson(value) ? JSON.parse(value) : value) : undefined;
    },

    set: (name, value, options) => {
        Cookies.set(name, JSON.stringify(value), options);
    },

    setSimple: (name, value, options) => {
        Cookies.set(name, value, options);
    },

    remove: (name, options) => {
        Cookies.remove(name, options);
    },
};
