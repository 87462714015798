import { TestSuiteMiddleware } from '../middleware';
import { SidebarActions } from './SidebarAction';
import { googleAnalytics } from '../../utils/utils';

import { ActionTypes } from '../constants';

class TestSuiteActions {
    static getTestSuites(project, accountId, getWithoutLoading = false) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_TESTSUITS,
                payload: { getWithoutLoading },
            });
            return TestSuiteMiddleware.getTestSuites(accountId, project.projectId)
                .then((data) =>
                    dispatch({
                        type: ActionTypes.GET_TESTSUITS_SUCCESS,
                        payload: {
                            data,
                            project,
                        },
                    }),
                )
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_TESTSUITS_FAILURE,
                        payload: error,
                    }),
                );
        };
    }

    static getReports(getWithoutLoading = false) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_REPORTS,
                payload: { getWithoutLoading },
            });
            return TestSuiteMiddleware.getReports()
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.GET_REPORTS_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_REPORTS_FAILURE,
                        payload: {
                            error,
                            message: 'Unable to get suite reports',
                        },
                    }),
                );
        };
    }

    static getTestSuitesWithAccount() {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_TESTSUITS_WITH_ACCOUNT,
            });

            return TestSuiteMiddleware.getTestSuitesWithAccount()
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.GET_TESTSUITS_WITH_ACCOUNT_SUCCESS,
                        payload: data,
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.GET_TESTSUITS_WITH_ACCOUNT_FAILURE,
                        payload: {
                            error,
                            message: 'Unable to get test suites',
                        },
                    }),
                );
        };
    }
    static stopTestSuite(SuiteData) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.STOP_TESTSUITES,
            });
            return TestSuiteMiddleware.stopTestSuites(SuiteData)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.STOP_TESTSUITES_SUCCESS,
                        payload:data,
                    });
                })
                .catch(() =>
                    dispatch({
                        type: ActionTypes.STOP_TESTSUITES_FAILURE,
                        payload: 'Failed to stop test suite',
                    }),
                );
        };

    }

    static cloneTestSuite(suiteData, suiteTab) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.CLONE_TESTSUITE,
            });
            return TestSuiteMiddleware.cloneTestSuite(suiteData)
                .then((data) => {
                    dispatch(SidebarActions.increaseSidebarCount('num_of_test_suits'));
                    return dispatch({
                        type: ActionTypes.CLONE_TESTSUITE_SUCCESS,
                        payload: { data, message: 'Test suite cloned successfully', suiteTab },
                    });
                })
                .catch(() =>
                    dispatch({
                        type: ActionTypes.CLONE_TESTSUITE_FAILURE,
                        payload: 'Failed to clone test suite',
                    }),
                );
        };
    }

    static deleteTestSuites(TestSuiteIds, suiteTab, onComplete = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.DELETE_TESTSUITES,
            });
            return TestSuiteMiddleware.deleteTestSuites(TestSuiteIds)
                .then(() => {
                    onComplete();
                    dispatch(SidebarActions.decreaseSidebarCount('num_of_test_suits'));
                    return dispatch({
                        type: ActionTypes.DELETE_TESTSUITES_SUCCESS,
                        payload: {
                            data: TestSuiteIds,
                            message: 'Testsuite Delete Successfully',
                            suiteTab,
                        },
                    });
                })
                .catch((error) => {
                    onComplete();
                    return dispatch({
                        type: ActionTypes.DELETE_TESTSUITES_FAILURE,
                        payload: error,
                    });
                });
        };
    }

    static disableTestSuites(TestSuiteIds, val, suiteTab, onComplete = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.DISABLE_TESTSUITES,
            });
            return TestSuiteMiddleware.disableTestSuites(TestSuiteIds, val)
                .then((data) => {
                    onComplete();
                    return dispatch({
                        type: ActionTypes.DISABLE_TESTSUITES_SUCCESS,
                        payload: {
                            data,
                            message: `${
                                val === 0
                                    ? `Test ${TestSuiteIds.length > 1 ? 'suites' : 'suite'} restored`
                                    : `Test ${TestSuiteIds.length > 1 ? 'suites' : 'suite'} disabled`
                            } successfully`,
                            suiteTab,
                        },
                    });
                })
                .catch((error) =>
                    dispatch({
                        type: ActionTypes.DISABLE_TESTSUITES_FAILURE,
                        payload: error,
                    }),
                );
        };
    }

    static addTestSuites(obj, suiteTab) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.ADD_TESTSUITE,
            });

            return TestSuiteMiddleware.addTestSuites(obj)
                .then((data) => {
                    googleAnalytics('send', 'event', 'Create Test Suite', 'Create Test Suite', 'Success');
                    dispatch(SidebarActions.increaseSidebarCount('num_of_test_suits'));
                    dispatch({
                        type: ActionTypes.ADD_TESTSUITE_SUCCESS,
                        payload: { data, message: 'Test Suite Added Successfully', suiteTab },
                    });
                    return data.testSuiteId;
                })
                .catch(() => {
                    googleAnalytics('send', 'event', 'Create Test Suite', 'Create Test Suite', 'Fail');
                    dispatch({
                        type: ActionTypes.ADD_TESTSUITS_FAILURE,
                        payload: 'Failed to Add Test Suite',
                    });
                });
        };
    }

    static editTestSuites(obj, suiteTab) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.EDIT_TESTSUITS,
            });

            return TestSuiteMiddleware.editTestSuites(obj)
                .then((data) => {
                    return dispatch({
                        type: ActionTypes.EDIT_TESTSUITS_SUCCESS,
                        payload: {
                            data,
                            message: 'Test Suite Updated Successfully',
                            suiteTab,
                        },
                    });
                })
                .catch(() => {
                    dispatch({
                        type: ActionTypes.EDIT_TESTSUITS_FAILURE,
                        payload: 'Failed to Update Test Suite',
                    });
                });
        };
    }

    static associateTestSuites(testSuiteId, testCaseIds, onComplete = () => {}, onFail = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.ASSOCIATE_TESTSUITS,
            });

            return TestSuiteMiddleware.associateTestSuites(testSuiteId, testCaseIds)
                .then(() => {
                    dispatch({
                        type: ActionTypes.ASSOCIATE_TESTSUITS_SUCCESS,
                        payload: {
                            testCaseIds: [...testCaseIds],
                            testSuiteId,
                            message: 'Successfully Added to Suite',
                        },
                    });
                    onComplete();
                })
                .catch((error) => {
                    console.error({ error });
                    onFail();
                    dispatch({
                        type: ActionTypes.ASSOCIATE_TESTSUITS_FAILURE,
                        payload: 'Failed to Add',
                    });
                });
        };
    }

    static executeTestSuite(obj, suiteId, onCompelete = () => {}) {
        return async (dispatch) => {
            try {
                dispatch({
                    type: ActionTypes.CREATE_EXECUTE_TEST_SUITE,
                });
                await TestSuiteMiddleware.executeTestSuite(obj, suiteId, onCompelete);
                dispatch(TestSuiteActions.getTestSuiteById(suiteId));
                return dispatch({
                    type: ActionTypes.CREATE_EXECUTE_TEST_SUITE_SUCCESS,
                    payload: '',
                });
            } catch (e) {
                return dispatch({
                    type: ActionTypes.CREATE_EXECUTE_TEST_SUITE_FAILURE,
                    payload: e,
                });
            }
        };
    }

    static getTestSuiteById(suiteId) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_SINGLE_TESTSUITE,
            });
            return TestSuiteMiddleware.getTestSuiteById(suiteId)
                .then((data) =>
                    dispatch({
                        type: ActionTypes.GET_SINGLE_TESTSUITE_SUCCESS,
                        payload: { data },
                    }),
                )
                .catch(() => {
                    dispatch({
                        type: ActionTypes.GET_SINGLE_TESTSUITE_FAILURE,
                        payload: {
                            message: 'Unable to fetch suite',
                        },
                    });
                });
        };
    }

    static getProjectAndTestSuiteData(onComplete = () => {}) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.GET_PROJECTS_AND_TESTSUITES,
            });
            return TestSuiteMiddleware.getProjectAndTestSuite()
                .then((data) => {
                    setTimeout(() => {
                        onComplete();
                    }, 50);
                    return dispatch({
                        type: ActionTypes.GET_PROJECTS_AND_TESTSUITES_SUCCESS,
                        payload: data,
                    });
                })
                .catch(() =>
                    dispatch({
                        type: ActionTypes.GET_PROJECTS_AND_TESTSUITES_FAILURE,
                        payload: {
                            message: 'Could not get project and suites ',
                        },
                    }),
                );
        };
    }
}

export { TestSuiteActions };
