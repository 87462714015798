import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '../components/Pagination/TablePagination';
import { TOOLBAR_COUNT_AVATAR, BLACK_FONT } from './cssConstants';

const styles = () => ({
    footer: {
        height: '50px',
        display: 'flex',
        alignItems: 'center',
    },
    toolbarCountAvatar: { ...TOOLBAR_COUNT_AVATAR },
    lableForCounter: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 20,
    },
    counter: {
        margin: '0 0 0 5px',
        color: BLACK_FONT,
        fontSize: 14,
    },
});

function TableFooter(props) {
    const {
        classes,
        count,
        countAvatar,
        rowsPerPage,
        rowsPerPageOptions,
        page,
        handleChangePage,
        handleChangeRowsPerPage,
        labelRowsPerPage,
        labelForCounter,
        showPagination = true,
        containerStyle,
    } = props;
    return (
        <div
            style={{ justifyContent: labelForCounter ? 'space-between' : 'flex-end', backgroundColor: '#f9f9f9', ...containerStyle }}
            className={classes.footer}
        >
            {labelForCounter && (
                <div className={classes.lableForCounter}>
                    <span style={{ margin: 0 }} className={classes.toolbarCountAvatar}>
                        {countAvatar}
                    </span>
                    <p className={classes.counter}>{labelForCounter}</p>
                </div>
            )}
            {showPagination && (
                <div style={{ minWidth: '310px', paddingRight: '15px' }}>
                    <TablePagination
                        count={count}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage={labelRowsPerPage}
                    />
                </div>
            )}
        </div>
    );
}

TableFooter.propTypes = {};

export default withStyles(styles)(TableFooter);
