/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { EnhancedTableHead } from '../EnhancedTable';
import Table from '../Table/Table';
import TableBody from '../Table/TableBody';
import TableRow from '../Table/TableRow';
import TableCell from '../Table/TableCell';
import { TABLES_CSS, BLACK_FONT } from '../../common/cssConstants';
import Tooltip from '../Tooltip';
import { checkArrayLength, checkObject } from '../../utils/utils';

// const CustomTableCell = withStyles((/* theme */) => ({
//     head: {},
//     body: {
//         fontSize: 14,
//     },
// }))(TableCell);

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
    },
    table: {
        // minWidth: 600,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    heading: {
        fontSize: 20,
        fontWeight: 500,
    },
    rowData1: {
        // padding:12,
        border: '1px solid #9a9a9a',
        textAlign: '-webkit-center',
        // marginTop: 1
    },
    rowData: {
        padding: 6,
        border: '1px solid #9a9a9a',
        textAlign: '-webkit-center',
    },
    subHeading: {
        fontWeight: 500,
        fontSize: 14,
    },
    text: {
        fontSize: '12px',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        width: '16.6%',
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    minWidth: {
        // minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
});

const headers = [
    {
        id: 'All',
        sortable: false,
        numeric: false,
        disablePadding: false,
        label: 'Variable',
        styles: { paddingLeft: 10, color: BLACK_FONT, fontWeight: 500 },
        width: '30%',
    },
    { id: 'func', sortable: false, numeric: false, disablePadding: false, label: 'Value', styles: { paddingLeft: 10 }, width: '70%' },
];

const PreviewDownload = (props) => {
    const { classes, downloadData } = props;
    const isVertical = checkObject(downloadData) && checkArrayLength(downloadData.columns) && downloadData.columns.length === 2;
    return (
        <div>
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    {isVertical && <EnhancedTableHead headers={headers} showSelectBtn={false} />}
                    <TableBody style={{ maxHeight: 'unset', overflow: 'auto' }}>
                        {!isVertical && (
                            <TableRow
                                style={{ height: '40px', cursor: 'default', width: 'inherit' }}
                                tabIndex={-1}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                className={classes.row}
                            >
                                <TableCell
                                    className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                    key="col-Variable"
                                    width={120}
                                    style={{ minWidth: 120, maxWidth: 120 }}
                                >
                                    <Typography
                                        noWrap
                                        className={classes.noWrap}
                                        style={{ fontSize: TABLES_CSS.normalFontSize, display: 'inline-block', fontWeight: 500, color: BLACK_FONT }}
                                    >
                                        Variable
                                    </Typography>
                                </TableCell>
                                {downloadData &&
                                    downloadData.columns.map((value, index) => (
                                        <TableCell
                                            className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                            key={`col-${value}-${index}`}
                                            width={120}
                                            style={{ minWidth: 120, maxWidth: 120 }}
                                        >
                                            <Typography
                                                noWrap
                                                className={classes.noWrap}
                                                style={{ fontSize: TABLES_CSS.normalFontSize, display: 'inline-block' }}
                                            >
                                                <Tooltip data={value || null}>{value}</Tooltip>
                                            </Typography>
                                        </TableCell>
                                    ))}
                            </TableRow>
                        )}
                        {downloadData &&
                            downloadData.data.map((item, index) => (
                                <TableRow
                                    key={index}
                                    style={{ height: '40px', cursor: 'default' }}
                                    tabIndex={-1}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    className={classes.row}
                                >
                                    {!isVertical && (
                                        <TableCell
                                            className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                            key="col-value"
                                            width={120}
                                            style={{ minWidth: 120, maxWidth: 120 }}
                                        >
                                            <Typography
                                                noWrap
                                                className={classes.noWrap}
                                                style={{
                                                    fontSize: TABLES_CSS.normalFontSize,
                                                    display: 'inline-block',
                                                    fontWeight: 500,
                                                    color: BLACK_FONT,
                                                }}
                                            >
                                                Value
                                            </Typography>
                                        </TableCell>
                                    )}
                                    {item.map((cell, ind) => (
                                        <TableCell
                                            className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                                            width={isVertical ? headers[ind].width : 120}
                                            key={`data-${cell}-${ind}`}
                                            style={{
                                                minWidth: isVertical ? headers[ind].width : 120,
                                                maxWidth: isVertical ? headers[ind].width : 120,
                                            }}
                                        >
                                            <Typography
                                                noWrap
                                                className={classes.noWrap}
                                                style={{ fontSize: TABLES_CSS.normalFontSize, display: 'inline-block' }}
                                            >
                                                <Tooltip data={cell.value ? cell.value : null}>{cell.value}</Tooltip>
                                            </Typography>
                                        </TableCell>
                                    ))}
                                    {/* {item.map((val, ind) =>
                                    <CustomTableCell numeric className={classes.rowData} key={ind + "_" + index}>
                                        <pre><Typography className={classes.subHeading} align="left">{val.value}</Typography></pre>
                                    </CustomTableCell>
                                )} */}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
};

PreviewDownload.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    downloadData: PropTypes.instanceOf(Object),
};

PreviewDownload.defaultProps = {
    downloadData: {},
};

export default withStyles(styles)(PreviewDownload);
