import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { checkKeyInObject, getExcelDownloadBtn } from '../../utils';
import { BLACK_FONT, NO_DECORATION } from '../../common/cssConstants';
import Tooltip from '../Tooltip';

const styles = () => ({
    menu_divider: {
        marginLeft: 10,
        marginRight: 10,
    },
    menu_item: {
        height: 10,
        paddingLeft: 2,
        paddingRight: 12,
        paddingTop: 12,
        paddingBottom: 12,
        boxSizing: 'content-box',
        display: 'flex',
    },
    menu_item_disabled: {
        cursor: 'default',
        height: 10,
        paddingLeft: 2,
        paddingRight: 12,
        paddingTop: 12,
        paddingBottom: 12,
        display: 'flex',
        boxSizing: 'content-box',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&>$list_item_text': {
            '&>span': {
                color: '#bac4ce !important',
            },
        },
        '&>$list_item_icon': {
            color: '#bac4ce',
        },
    },
    selectedIcon: {
        color: '#fff !important',
    },
    list_item_text: {
        padding: 0,
        '&>span': {
            color: BLACK_FONT,
            fontSize: 11,
            '&:hover': {
                fontWeight: 500,
            },
        },
    },
    list_item_text_selected: {
        '&>span': {
            color: '#FFF !important',
        },
    },
    list_item_icon: {
        minWidth: 0,
        marginRight: 0,
    },
    button_list_item_icon: {
        marginRight: 12,
        marginLeft: -16,
    },
    last_button_list_item_icon: {
        marginRight: 12,
        marginLeft: -4,
    },
    button_menu_item: {
        paddingRight: 0,
        paddingLeft: 0,
    },
    selectedItem: {
        backgroundColor: '#5b5b5b9c !important',
    },
    noBackground: {
        backgroundColor: '#fff !important',
    },
    padding: {
        padding: 0,
    },
    firstIndex: { margin: '1px 5px 0px 0px', fontSize: 12 },
    secondIndex: { margin: '1px 0px 0px 0px', fontSize: 12 },
    firstIndexCustomPlain: {
        margin: '1px 5px 0px 0px',
        fontSize: 12,
        color: BLACK_FONT,
        fontWeight: 400,
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 500,
        },
    },
    secondIndexCustomPlain: {
        margin: '1px 0px 0px 0px',
        fontSize: 12,
        color: BLACK_FONT,
        fontWeight: 400,
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 500,
        },
    },
    secondIndexCustomToggle: {
        margin: 0,
        fontSize: 12,
        fontWeight: 400,
        width: 20,
    },
    firstIndexCustomToggle: {
        margin: 0,
        fontSize: 12,
        fontWeight: 400,
        marginLeft: 5,
        width: 20,
    },
    hover: {
        '&:hover': {
            fontWeight: '500 !important',
        },
    },
    pipe: {
        borderLeft: '1px solid #000',
        width: '7px',
        height: '10px',
    },
    customRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 12px 10px 0px',
        boxSizing: 'content-box',
        height: 12,
    },
    itemName: {
        fontSize: 12,
        margin: 0,
        cursor: 'default',
    },
    name0Disabled: {
        color: '#C4C4C4',
        fontWeight: 400,
        cursor: 'default',
    },
    name0Key: {
        color: BLACK_FONT,
        fontWeight: 400,
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 500,
        },
    },
    name0Default: {
        color: '#1168CD',
        fontWeight: 500,
        cursor: 'default',
    },
    name1Disabled: {
        color: '#C4C4C4',
        fontWeight: 400,
        cursor: 'default',
    },
    name1Key: {
        color: '#1168CD',
        fontWeight: 500,
        cursor: 'default',
    },
    name1Default: {
        color: BLACK_FONT,
        fontWeight: 400,
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 500,
        },
    },
    link: NO_DECORATION,
});

function ActionItem(data, action, classes) {
    const customStyle = checkKeyInObject(data.item, 'custom_style', 'value', {});
    return (
        <div key={data.index} className={classes.customRoot} style={customStyle}>
            {data.item.icon}
            <p
                id="first"
                onClick={data.item.isDisabled ? null : data.item.key ? data.item.action : null}
                className={[
                    classes.firstIndex,
                    data.item.isDisabled && data.item.name[0] !== 'Current'
                        ? classes.name0Disabled
                        : data.item.key
                        ? classes.name0Key
                        : classes.name0Default,
                ].join(' ')}
                aria-hidden
            >
                {data.item.name[0]}
            </p>
            <span id="pipe" className={classes.pipe} style={{ borderLeft: data.item.isDisabled ? '1px solid #C4C4C4' : '1px solid #000' }} />
            <p
                id="last"
                onClick={data.item.isDisabled ? null : !data.item.key ? data.item.action : null}
                className={[
                    classes.secondIndex,
                    data.item.isDisabled ? classes.name1Disabled : data.item.key ? classes.name1Key : classes.name1Default,
                ].join(' ')}
                aria-hidden
            >
                {data.item.name[1]}
            </p>
        </div>
    );
}

function CustomActionItem(isLink, data, classes, route = '') {
    let btn = (
        <div key={data.index} className={classes.customRoot}>
            {data.item.icon}
            <p
                id="first"
                onClick={data.item.isDisabled ? null : data.item.key ? data.item.action : null}
                className={[
                    classes.firstIndex,
                    data.item.isDisabled && data.item.name[0] !== 'Current'
                        ? classes.name0Disabled
                        : `${data.item.key}` === `${data.item.name}`
                        ? classes.name0Default
                        : classes.name0Key,
                ].join(' ')}
                aria-hidden
            >
                {data.item.name}
            </p>
        </div>
    );

    if (isLink) {
        btn = (
            <Link key={data.index} to={`${route}`} className={classes.link}>
                {btn}
            </Link>
        );
    }

    return btn;
}

const ActionMenu = React.forwardRef(function ActionMenu(props,ref) {
    const { classes, items } = props;
    const getItem = (index, item) => {
        return (
            <Tooltip key={index.toString()} data={item.dataRh ? item.dataRh : null}>
                <MenuItem
                    component={item.isLink && item.route && !(item.isDisabled) ? Link : null}
                    to={item.isLink && item.route && !(item.isDisabled) ? `${item.route}` : null}
                    disableRipple={item.isDisabled}
                    onClick={item.isDisabled ? null : item.action ? item.action : null}
                    className={[
                        item.isDisabled ? classes.menu_item_disabled : classes.menu_item,
                        item.selectedTab ? classes.selectedItem : classes.noBackground,
                        item.prop === 'divider' ? classes.padding : {},
                    ].join(' ')}
                    disabled={false}
                    data-testid={item.dataId ? item.dataId : 0}
                    onContextMenu={item.onContextMenu}
                >
                    {item.icon ? (
                        <ListItemIcon className={[classes.list_item_icon, item.selectedTab ? classes.selectedIcon : ''].join(' ')}>
                            {item.icon}
                        </ListItemIcon>
                    ) : null}
                    {item.name ? (
                        item.isOpenNewTab ? ( 
                        item.isDisabled ? 
                        <ListItemText
                            className={[item.selectedTab ? classes.list_item_text_selected : '', classes.list_item_text].join(' ')}
                            inset
                            primary={item.name}
                        />
                        :
                        <Link className={classes.link} to={item.route ? `${item.route}` : null}>
                            <ListItemText
                                className={[item.selectedTab ? classes.list_item_text_selected : '', classes.list_item_text].join(' ')}
                                inset
                                primary={item.name}
                            />
                        </Link>
                        ) : (
                            <ListItemText
                            className={[item.selectedTab ? classes.list_item_text_selected : '', classes.list_item_text].join(' ')}
                            inset
                            //style={{paddingLeft: 10}}
                            primary={item.name}
                        />
                        )
                    ) : null}
                </MenuItem>
            </Tooltip>
        );
    };
    return (
        items &&
        items.map((item, index) =>
            item && item.downloadable ? (
                <MenuItem key={index.toString()} className={classes.button_menu_item} disabled={item.isDisabled}>
                    <Button
                        href={item.link}
                        key={item.name}
                        fullWidth
                        style={{ textTransform: 'none' }}
                        disableRipple
                        variant="flat"
                        disableFocusRipple
                    >
                        <ListItemIcon className={classes.button_list_item_icon}>{item.icon}</ListItemIcon>
                        <ListItemText className={classes.list_item_text} inset primary={item.name} />
                    </Button>
                </MenuItem>
            ) : item.type === 'custom' ? (
                ActionItem({ index: index.toString(), item }, {}, classes)
            ) : item.type === 'customToggle' ? (
                <div key={index.toString()} className={classes.customRoot}>
                    {item.icon}
                    <p className={classes.itemName} style={{ color: item.isDisabled ? '#C4C4C4' : BLACK_FONT }}>
                        {' '}
                        {item.name}
                    </p>
                    <p
                        onClick={item.isDisabled || item.key ? null : item.action}
                        className={[classes.firstIndexCustomToggle, !item.key ? classes.hover : ''].join(' ')}
                        style={{
                            fontWeight: item.isDisabled ? 400 : !item.key ? 400 : 500,
                            color: item.isDisabled ? '#C4C4C4' : !item.key ? '#000000' : '#1168CD',
                            cursor: item.isDisabled || item.key ? 'default' : 'pointer',
                        }}
                        aria-hidden
                    >
                        On
                    </p>
                    <span className={classes.pipe} style={{ borderLeft: item.isDisabled ? '1px solid #C4C4C4' : '1px solid #000' }} />
                    <p
                        onClick={item.isDisabled || !item.key ? null : item.action}
                        className={[classes.secondIndexCustomToggle, item.key ? classes.hover : ''].join(' ')}
                        style={{
                            fontWeight: item.isDisabled ? 400 : item.key ? 400 : 500,
                            color: item.isDisabled ? '#C4C4C4' : item.key ? '#000000' : '#1168CD',
                            cursor: item.isDisabled || !item.key ? 'default' : 'pointer',
                        }}
                        aria-hidden
                    >
                        Off
                    </p>
                </div>
            ) : item.type === 'customPlain' ? (
                <div key={index.toString()} className={classes.customRoot}>
                    {item.icon}
                    <p onClick={item.action1} className={classes.firstIndexCustomPlain} aria-hidden>
                        {item.name[0]}
                    </p>
                    <span className={classes.pipe} style={{ borderLeft: item.isDisabled ? '1px solid #C4C4C4' : '1px solid #000' }} />
                    <p onClick={item.action2} className={classes.secondIndexCustomPlain} aria-hidden>
                        {item.name[1]}
                    </p>
                </div>
            ) : item.type === 'customProject' ? (
                CustomActionItem(item.isLink, { index: index.toString(), item }, classes, item.route)
            ) : item.excelDownloadBtnData ? (
                getExcelDownloadBtn(
                    getItem(index, item),
                    item.excelDownloadBtnData.dataSet,
                    item.excelDownloadBtnData.fileName,
                    item.excelDownloadBtnData.sheetName,
                    item.excelDownloadBtnData.selectedFlow,
                )
            ) : (
                getItem(index, item)
            ),
        )
    );
})

ActionMenu.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    items: PropTypes.arrayOf(Object).isRequired,
};

export default withStyles(styles)(ActionMenu);

class IconMenu extends React.Component {
    render() {
        const { classes, items } = this.props;
        return (
            items &&
            items.map((item, index) => (
                <Tooltip key={index.toString()} data={item.dataRh ? item.dataRh : null}>
                    <MenuItem
                        disableRipple={item.isDisabled}
                        onClick={item.isDisabled ? null : item.action ? item.action : null}
                        className={[
                            item.selectedTab ? classes.selectedItem : classes.noBackground,
                            item.prop === 'divider' ? classes.padding : {},
                        ].join(' ')}
                        disabled={false}
                        data-testid={item.dataId ? item.dataId : 0}
                    >
                        {item.icon ? (
                            <ListItemIcon className={[classes.list_item_icon, item.selectedTab ? classes.selectedIcon : ''].join(' ')}>
                                {item.icon}
                            </ListItemIcon>
                        ) : null}
                        {item.name ? (
                            <ListItemText
                                className={[item.selectedTab ? classes.list_item_text_selected : '', classes.list_item_text].join(' ')}
                                inset
                                primary={item.name}
                            />
                        ) : null}
                    </MenuItem>
                </Tooltip>
            ))
        );
    }
}

export const ActionIconMenu = withStyles(styles)(IconMenu);
