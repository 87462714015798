import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import FileDownload from '@material-ui/icons/GetApp';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    fileDropComp: {
        width: '100%',
        marginTop: '10px',
    },
    dropZone: {
        background: 'rgba(59,145,223,0.1)',
        width: 'inherit !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px dashed #E6E6E6',
        borderRadius: '5px',
        minHeight: '120px',
    },
    innerBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    customButton: {
        outline: 'none',
        alignItems: 'center',
        userSelect: 'none',
        verticalAlign: 'middle',
        justifyContent: 'center',
        textDecoration: 'none',
        color: '#1168cd',
        padding: '8px 16px',
        minWidth: 64,
        boxSizing: 'border-box',
        minHeight: 36,
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontWeight: 500,
        borderRadius: 4,
        textTransform: 'uppercase',
    },
    customButtonDisabled: {
        color: '#38393a',
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '2',
    },
    customButtonEnable: {
        color: '#3B91DF',
        textAlign: 'center',
        cursor: 'pointer',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '2',
        '&:hover': {
            backgroundColor: 'rgba(17, 104, 205, 0.08) !important',
        },
    },
});

class Filedrop extends Component {
    onDrop = (files) => {
        const { config } = this.props;
        if (!config.disabled) {
            config.dropCallback(files, config.step);
        }
    };

    render() {
        const { classes, config, innerStyle, isMultiple = true } = this.props;
        return (
            <div className={classes.fileDropComp}>
                <Dropzone onDrop={this.onDrop} className={classes.dropZone} multiple={isMultiple} disabled={config.disabled}>
                    {({ getRootProps, getInputProps }) => (
                        <div className={classes.innerBlock} style={innerStyle || {}} {...getRootProps({})}>
                            <input {...getInputProps()} />
                            <FileDownload style={{ fontSize: '20px', color: '#595959' }} aria-label="fileDownloadIcon" id="fileDownloadIcon" />
                            <Typography style={{ fontSize: '12px' }}>Drag & Drop</Typography>
                            <Typography>or</Typography>
                            <Typography
                                className={`${classes.customButton} ${config.disabled ? classes.customButtonDisabled : classes.customButtonEnable}`}
                            >
                                {config.text}
                            </Typography>
                        </div>
                    )}
                </Dropzone>
            </div>
        );
    }
}

Filedrop.propTypes = {
    config: PropTypes.shape({}).isRequired,
    classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(Filedrop);
