import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { getDatesDiff, getSecondsToFormatedTime, checkKeyInObject, checkArrayLength } from '../../../../utils/utils';

const styles = () => ({});

let timerInterval = null;
let isComponentMount = false;
class TestCaseTimer extends React.Component {
    state = {
        elapsed: '--:--:--',
        isFirstTime: true,
    };

    UNSAFE_componentWillMount() {
        const { calledFrom, executionDetailsSteps, total_time } = this.props;
        isComponentMount = true;
        if (calledFrom === 'Execution') {
            this.updateStepTimerForExecution(executionDetailsSteps, total_time);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isFirstTime } = this.state;
        const { wsRunningCaseData, selectedTestCase, total_time } = this.props;
        const { testCaseId, calledFrom, executionDetailsSteps } = nextProps;

        if (calledFrom === 'TestSteps') {
            const prevWsRunningCaseData = wsRunningCaseData && wsRunningCaseData[testCaseId] ? wsRunningCaseData[testCaseId] : null;
            const currWsRunningCaseData =
                nextProps.wsRunningCaseData && nextProps.wsRunningCaseData[testCaseId] ? nextProps.wsRunningCaseData[testCaseId] : null;

            const prevWsRunningCaseDataStartTime = prevWsRunningCaseData && prevWsRunningCaseData.startTime ? prevWsRunningCaseData.startTime : null;
            const currWsRunningCaseDataStartTime = currWsRunningCaseData && currWsRunningCaseData.startTime ? currWsRunningCaseData.startTime : null;

            if (
                (currWsRunningCaseDataStartTime && currWsRunningCaseDataStartTime !== prevWsRunningCaseDataStartTime) ||
                (currWsRunningCaseDataStartTime && isFirstTime)
            ) {
                this.setState({ isFirstTime: false });
                clearInterval(timerInterval);
                // Don't remove this commented Line (Haseeb)
                // if want to stop time on msgType 13 unComment
                // if (currWsRunningCaseData.isStart) {
                timerInterval = setInterval(() => {
                    if (isComponentMount) {
                        const elapsed = getDatesDiff(new Date(), new Date(currWsRunningCaseDataStartTime), 'HH:MM:SS');
                        this.setState({ elapsed });
                    }
                }, 1000);
                // }
            } else if (!currWsRunningCaseData) {
                clearInterval(timerInterval);
                if (!prevWsRunningCaseDataStartTime && selectedTestCase.totalTime && !selectedTestCase.isGenerating) {
                    const elapsed = getSecondsToFormatedTime(Math.round(selectedTestCase.totalTime), 'HH:MM:SS');
                    this.setState({ elapsed });
                }
            }
        } else if (calledFrom === 'Execution') {
            this.updateStepTimerForExecution(executionDetailsSteps, total_time);
        }
    }

    componentWillUnmount() {
        clearInterval(timerInterval);
        isComponentMount = false;
    }

    updateStepTimerForExecution = (executionDetailsSteps, total_time) => {
        if (checkArrayLength(executionDetailsSteps)) {
            if (total_time > 0) {
                const elapsed = getSecondsToFormatedTime(Math.round(total_time), 'HH:MM:SS');
                this.setState({ elapsed });
            } else {
                let totalDuration = 0;
                executionDetailsSteps.forEach((step) => {
                    totalDuration += Number(checkKeyInObject(step, 'duration', 'value', 0));
                });
                const elapsed = getSecondsToFormatedTime(Math.round(totalDuration), 'HH:MM:SS');
                this.setState({ elapsed });
            }
        }
    };

    render() {
        const { elapsed } = this.state;
        return (
            <span
                style={{
                    color: '#587c99',
                    fontSize: 12,
                    fontWeight: 400,
                    width: '54px',
                    display: 'inline-block',
                }}
            >
                {elapsed}
            </span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        wsRunningCaseData: state.projectReducer.wsRunningCaseData,
        selectedTestCase: state.projectReducer.selectedTestCase,
    };
};

TestCaseTimer.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    wsRunningCaseData: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, null)(withStyles(styles)(TestCaseTimer));
