import { checkArrayLength, checkKeyInObject } from '.';

class TestCaseHelpers {
    static updateTestCase = (testCases, testCase) => {
        if (checkArrayLength(testCases)) {
            const findTestCaseIndex = testCases.findIndex((item) => item.testCaseId === testCase.testCaseId);
            if (findTestCaseIndex > -1) {
                const obj = {
                    ...testCases[findTestCaseIndex],
                    testCaseName: testCase.name,
                    desc: testCase.desc,
                };
                testCases[findTestCaseIndex] = obj;
            }
        }
        return testCases;
    };

    static checkDuplicateTestCaseName = (testCases, testCaseData) => {
        return (
            checkArrayLength(testCases) &&
            testCases.some(
                (testCase) =>
                    checkKeyInObject(testCase, 'testCaseName') &&
                    testCaseData.name === testCase.testCaseName &&
                    checkKeyInObject(testCase, 'testCaseId') &&
                    `${testCaseData.testCaseId}` !== `${testCase.testCaseId}`,
            )
        );
    };
}

export { TestCaseHelpers };
