import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography, TableSortLabel, Button, CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Download from 'mdi-material-ui/Download';
import History from '@material-ui/icons/History';
// import Checkbox from '@material-ui/core/Checkbox';
import Select from 'react-select';
import { saveAs } from 'file-saver/FileSaver';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import { Link } from 'react-router-dom';
import { checkArrayLength, sort, getFormatedDate, getRelativeTime, getCountAvatar } from '../../utils/utils';
import { GeneralActions, ModalActions, TestSuiteActions } from '../../store/actions';
import { TABLES_CSS, TABLE_PAGE_HEADING, BLACK_FONT } from '../../common/cssConstants';
import JSONTOCsv from '../../services/JSONToCsv';
import singleFileCreationService from '../../services/singleFileCreationService';
import { CustomDownloadModal } from '../../components/CustomModal/CustomDownloadModal';
import TableFooter from '../../common/TableFooter';
import Tooltip from '../../components/Tooltip';

const styles = (/* theme */) => ({
    cellRole: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: '4px 0px 0px 10px',
    },
    tableRows: {
        cursor: 'pointer',
        backgroundColor: '#ffffff',
    },
    checkBoxIcon: {
        '&span>svg': {
            color: '#0092e5',
            fill: '#bac4ce',
            marginBottom: '8px',
        },
    },
    moreIconsBtn: {
        color: '#F39B31',
    },
    sortIcon: {
        fontSize: 10,
        marginLeft: 10,
        color: '#9B9B9B',
    },
    table: {
        width: '100%',
        minWidth: 600,
        borderSpacing: 0,
        borderCollapse: 'collapse',
        padding: 0,
        listStyle: 'none',
        overflow: 'hidden',
        margin: 0,
        maxHeight: 'calc(100vh - 280px)',
        overflowY: 'scroll',
    },
    tableHeadTR: {
        height: '51px',
        color: 'inherit',
        display: 'block',
        outline: 'none',
        verticalAlign: 'middle',
        backgroundColor: '#F9F9F9',
        borderBottom: '2px solid rgba(224, 224, 224, 1)',
    },
    tableBodyTR: {
        color: 'inherit',
        outline: 'none',
        verticalAlign: 'middle',
    },
    tableTH: {
        textAlign: 'left',
        verticalAlign: 'inherit',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        fontWeight: '500',
        height: 'inherit',
        display: 'inline-flex',
        '& span:first-child': {
            display: 'flex',
            alignItems: 'center',
        },
        backgroundColor: '#f9f9f9',
    },
    tableTD: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        verticalAlign: 'inherit',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.8125rem',
        fontWeight: '400',
        display: 'inline-flex',
        height: '55px',
    },
    selectedItem: {
        zIndex: '999999999 !important',
        listStyle: 'none',
        boxShadow: '3px 2px 11px 0px #cdcdcd, -3px -2px 11px 0px #cdcdcd',
    },
    dragAble: {
        '& .dragAble': {
            '& svg': {
                cursor: 'all-scroll !important',
            },
        },
    },
    root: {
        width: 'calc(100% - 40px)',
        margin: '0px 20px',
        marginTop: '10px',
        boxShadow: 'none',
    },
    filterContainer: {
        height: '50px',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        paddingLeft: '15px',
    },
    leftContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    heading: {
        width: 'calc(100% - 40px)',
        marginTop: '20px',
        color: TABLE_PAGE_HEADING.fontColor,
        fontSize: TABLE_PAGE_HEADING.fontSize,
        fontWeight: 'normal',
    },
    cellFontSize: {
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        color: '#9B9A9A',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '71%',
    },
    cellInStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    badgeStyle: {
        width: '37px',
        height: '19px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        fontSize: 12,
        fontWeight: 'bold',
        justifyContent: 'center',
    },
    badgeContain: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    select: {
        fontSize: '12px',
    },
    pipe: {
        borderLeft: '1px solid #cdcdcd',
        height: '35px',
        width: '1px',
        margin: '0px 20px 0px 20px',
    },
    outline: {
        border: '1px solid  #1067cd',
    },
    buttonRoot: {
        fontSize: 12,
        padding: '4px 10px',
    },
    upoadIcon: {
        fontSize: 18,
    },
    downloadBtnWrapper: {
        width: 200,
        display: 'flex',
        justifyContent: 'flex-end',
        paddingLeft: 15,
        paddingRight: 15,
        height: '45px',
        alignItems: 'center',
    },
    tableCont: {
        borderRadius: 5,
        overflow: 'hidden',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    },
});

const headers = [
    { id: 'suite_name', sortable: true, numeric: false, disablePadding: true, label: 'Name', width: '29%' },
    { id: 'creation_time', sortable: true, numeric: false, disablePadding: true, label: 'Start Time', width: 'calc(71% / 7)' },
    { id: 'total', sortable: true, numeric: false, disablePadding: true, label: 'Total', width: 'calc(71% / 7)' },
    { id: 'passed', sortable: true, numeric: false, disablePadding: true, label: 'Passed', width: 'calc(71% / 7)' },
    { id: 'failed', sortable: true, numeric: false, disablePadding: true, label: 'Failed', width: 'calc(71% / 7)' },
    { id: 'errors_count', sortable: true, numeric: false, disablePadding: true, label: 'Product Bug', width: 'calc(71% / 7)' },
    { id: 'warnings_count', sortable: true, numeric: false, disablePadding: true, label: 'Automation Bug', width: 'calc(71% / 7)' },
    { id: 'exexcutionHistory', sortable: false, numeric: false, disablePadding: true, label: 'Execution History', width: 'calc(71% / 7)' },
    // { id: 'checkBox', sortable: false, numeric: false, disablePadding: true, label: '', width: '5%', },
];

const selectedOptions = [
    { value: 'projects', label: 'Project', disabled: 'yes' },
    { value: 'testSuites', label: 'Test Suites', disabled: 'no' },
    { value: 'testCases', label: 'Test Cases', disabled: 'yes' },
];

const optionsFormatDate = [
    { value: 'actualDate', label: 'Actual Date (Default)' },
    { value: 'relativeDate', label: 'Relative Date' },
];

let dontMove = [];

class SuiteReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: 'asc',
            orderBy: 'testCaseName',
            testSuiteReports: [],
            // allChecked: false,
            isSortChange: false,
            selectedDateFormat: optionsFormatDate[0],
            anchorEl: null,
            page: 0,
            rowsPerPage: 20,
        };
        props.updateTitle('Reports');
    }

    UNSAFE_componentWillMount() {
        this.mapTestSuiteArrayWithProjects(this.props);
    }

    componentDidMount() {
        this.getReport();
        const suiteSettingRows = localStorage.getItem('rowsSuitePerPage');
        if (suiteSettingRows) {
            this.updateState({
                rowsPerPage: parseInt(suiteSettingRows, 10),
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.mapTestSuiteArrayWithProjects(nextProps);
    }

    componentWillUnmount() {
        this.props.queryRemove();
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { testSuiteReports, isSortChange } = this.state;
        const selectedSuiteCount = this.filterSuites(testSuiteReports).length;
        if (dontMove.indexOf(oldIndex) === -1) {
            let movedArray = JSON.parse(JSON.stringify(testSuiteReports));
            if (selectedSuiteCount === 0) {
                movedArray = arrayMove(movedArray, oldIndex, newIndex);
            } else if (oldIndex < newIndex) {
                for (let i = 0; i < selectedSuiteCount; i++) {
                    movedArray = arrayMove(movedArray, oldIndex, newIndex + selectedSuiteCount - 1);
                }
            } else {
                for (let i = 0; i < selectedSuiteCount; i++) {
                    movedArray = arrayMove(movedArray, oldIndex + i, newIndex + i);
                }
            }
            const status = oldIndex === newIndex ? isSortChange : true;
            this.setState({
                testSuiteReports: movedArray,
                isSortChange: status, // isSortChange is use to show/hide header messages for drag and drop
            });
        }
    };

    getExecutionCount = (suite, countFor) => {
        if (countFor === 'total') {
            return suite.total;
        }
        if (countFor === 'passed') {
            return suite.passed;
        }
        if (countFor === 'failed') {
            return suite.failed;
        }
        return null;
    };

    getReport = () => {
        const { getReports } = this.props;
        getReports();
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    handleChangePage = (page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        localStorage.setItem('rowsSuitePerPage', event.target.value);
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    mapTestSuiteArrayWithProjects = (props) => {
        const { executionReports } = props;
        if (checkArrayLength(executionReports)) {
            // const testSuiteReports = [];
            // executionReports.forEach((suite, i) => {
            //     if (suite.job && suite.job.jobId && ['SUCCESS', 'PARTIAL', 'ERROR', 'INPROGRESS'].includes(suite.job_status)) { // Check job_status for releas_5_3, requested by puneet
            //         const projectId = suite.job.etrs && suite.job.etrs.tasks && suite.job.etrs.tasks[0] && suite.job.etrs.tasks[0].projectId;
            //         const projectName = suite.job.etrs && suite.job.etrs.tasks && suite.job.etrs.tasks[0] && suite.job.etrs.tasks[0].projectName;

            //         testSuiteReports.push({ ...suite, project: { projectId: projectId, projectName: projectName } });
            //     }
            // });
            this.setState({}, () => {
                if (checkArrayLength(executionReports)) {
                    this.handleRequestSort('creation_time', executionReports, 'desc');
                }
            });
        }
    };

    handleRequestSort = (property, _testSuiteReports, _order) => {
        if (!this.state.isSortChange) {
            const orderBy = property;
            let order = 'desc';
            if (this.state.orderBy === property && this.state.order === 'desc') {
                order = 'asc';
            }
            const currentPageSuites = sort(_testSuiteReports, _order || order, orderBy, orderBy === 'creation_time' ? 'time' : '');
            this.setState({ order: _order || order, orderBy, testSuiteReports: currentPageSuites });
        }
    };

    handleToggle = (id) => (e) => {
        const { testSuiteReports } = this.state;
        const index = testSuiteReports.map((_suite) => _suite.suite_id).indexOf(id);
        testSuiteReports[index] = { ...testSuiteReports[index], checked: e.target.checked };
        // const allChecked = !testSuiteReports.some((_suite) => !_suite.checked);
        this.setState({ testSuiteReports });
    };

    handleToggleAll = (isAllChecked) => {
        const testSuite = [...this.state.testSuiteReports];
        let newSuiteData = [];

        if (isAllChecked) {
            newSuiteData = testSuite.map((_case) => {
                const newTestSuite = { ..._case, checked: true };
                return newTestSuite;
            });
        } else {
            newSuiteData = testSuite.map((_case) => {
                const newTestSuite = { ..._case, checked: false };
                return newTestSuite;
            });
        }
        this.setState({ testSuiteReports: newSuiteData });
    };

    filterSuites = (_suites) => {
        const filteredSuites = [];
        _suites.forEach((suite) => {
            if (suite.checked === true) filteredSuites.push(suite.suite_id);
        });
        return filteredSuites;
    };

    shouldCancelStart = (e) => {
        if (e && e.path && e.path.length) {
            for (let i = 0; i < e.path.length; i++) {
                if (e.path[i] && e.path[i].dataset && e.path[i].dataset.moveable && e.path[i].dataset.moveable.toLowerCase() === 'false') {
                    return true; // Cancel Dragable
                }
            }
        }
        return false; // Allow Dragable
    };

    handleChangeDateFormat = (selectedDateFormat) => {
        this.setState({ selectedDateFormat });
    };

    handleDownloadReport = async (downloadType) => {
        const { testSuiteReports, selectedDateFormat } = this.state;
        if (downloadType === 'csv') {
            try {
                const header = {
                    'Suite Name': 'Suite Name',
                    'Start Time': 'Start Time',
                    Total: 'Total',
                    Pass: 'Pass',
                    Fail: 'Fail',
                    'Product Bug': 'Product Bug',
                    'Automation Bug': 'Automation Bug',
                };
                const data = testSuiteReports.map((report) => {
                    return {
                        'Suite Name': `${report.suite_name}`,
                        'Start Time': `${
                            selectedDateFormat.value === 'actualDate'
                                ? getFormatedDate(report.creation_time, 'MMM DD YYYY', '')
                                : getRelativeTime(report.creation_time)
                        }`,
                        Total: `${report.total}`,
                        Pass: `${report.passed}`,
                        Fail: `${report.failed}`,
                        'Product Bug': `${report.errors_count}`,
                        'Automation Bug': `${report.warnings_count}`,
                    };
                });
                JSONTOCsv.CSVFile(header, data, 'SuiteReport');
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        } else {
            const data = await singleFileCreationService.getSuiteReport(selectedDateFormat, testSuiteReports, this.getExecutionCount);
            if (data) {
                const blob = new Blob([data], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, 'SuiteReport.xlsx');
            }
        }
    };

    render() {
        const { classes, query } = this.props;
        const {
            testSuiteReports,
            order,
            orderBy,
            // allChecked,
            isSortChange,
            selectedDateFormat,
            anchorEl,
            page,
            rowsPerPage,
        } = this.state;
        const _testSuiteReports = testSuiteReports;
        const testSuiteIds = {};
        if (_testSuiteReports) {
            _testSuiteReports.forEach((_suite, index) => {
                testSuiteIds[_suite.suite_id] = index + 1;
            });
        }
        let _currentPageSuites = _testSuiteReports || [];

        if (query && checkArrayLength(_currentPageSuites)) {
            _currentPageSuites = _currentPageSuites.filter(
                (report) => 
                ('suite_name' in report && report.suite_name.toLowerCase().includes(query.toLowerCase()) ||
                 'creation_time' in report && (new Date(report.creation_time).toDateString().toLowerCase().includes(query.toLowerCase()))),
            );
        }


        const currentPageSuites = query ? _currentPageSuites : _currentPageSuites.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        const numSelected = this.filterSuites(_testSuiteReports).length;
        // const rowCount = _testSuiteReports.length;

        const SortableList = SortableContainer(() => {
            const SortableItem = SortableElement(({ value, styles: _styles, _classes, dataMoveAble }) => {
                return (
                    <li className={_classes} style={_styles} data-moveable={dataMoveAble}>
                        {value}
                    </li>
                );
            });
            let disableCondition = numSelected < 1; // Disable sorting if true.
            let dataIndex = -1;
            const dataMerge = [];
            let isPrevSelected = false; // its checking that if steps are selected in order if thy are in order then its true otherwise its false
            let foundSelected = false; // Use to check if consecutive steps are selected set false if not,
            let selectedIndex = -1; // First selected step's index
            dontMove = []; // array of steps which are not dragable (Temporary)
            return (
                <div className={classes.tableCont}>
                    <span className={classes.tableHeadTR}>
                        {headers.map(
                            (column, index) =>
                                (column.status && column.status === 'InActive') || (column.checked && column.checked === true) ? null : (
                                    <span
                                        key={column.id}
                                        className={[classes.borders, classes.extraPadding, classes.tableTH].join(' ')}
                                        style={column.styles ? { ...column.styles } : { width: headers[index].width }}
                                    >
                                        <span>
                                            {column.sortable && !isSortChange ? (
                                                <TableSortLabel
                                                    hideSortIcon
                                                    data-rh={orderBy === column.id && order === 'desc' ? 'Sort Ascending' : 'Sort Descending'}
                                                    onClick={() => {
                                                        this.handleRequestSort(column.id, _testSuiteReports);
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        style={{
                                                            // fontSize: TABLES_CSS.headingFontSize,
                                                            color: TABLES_CSS.normalFontColor,
                                                            fontWeight: 500,
                                                            fontSize: 10,
                                                            paddingLeft: column.label === 'Name' ? 40 : 0,
                                                        }}
                                                    >
                                                        {column.label}
                                                        {orderBy === column.id ? (
                                                            order === 'desc' ? (
                                                                <FontAwesomeIcon
                                                                    icon={faChevronDown}
                                                                    aria-label="faChevronDown"
                                                                    id="faChevronDown"
                                                                    className={classes.sortIcon}
                                                                />
                                                            ) : (
                                                                <FontAwesomeIcon
                                                                    icon={faChevronUp}
                                                                    aria-label="faChevronUp"
                                                                    id="faChevronUp"
                                                                    className={classes.sortIcon}
                                                                />
                                                            )
                                                        ) : null}
                                                    </Typography>
                                                </TableSortLabel>
                                            ) : (
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        // fontSize: TABLES_CSS.headingFontSize,
                                                        color: TABLES_CSS.normalFontColor,
                                                        fontWeight: 500,
                                                        fontSize: 10,
                                                        paddingLeft: column.label === 'Name' ? 40 : 0,
                                                    }}
                                                >
                                                    {column.label}
                                                </Typography>
                                            )}
                                            {/* {index === headers.length - 1 ?
                                                <Checkbox
                                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                                    checked={numSelected === rowCount}
                                                    onChange={(e) => { this.handleToggleAll(!allChecked) }}
                                                    style={{ width: '20px', color: numSelected === rowCount ? '#3b91df' : '#bac4ce', }}
                                                />
                                                : null} */}
                                        </span>
                                    </span>
                                ),
                            this,
                        )}
                    </span>
                    <ul className={`${classes.table} scrollTable`}>
                        {currentPageSuites.forEach((suite, index) => {
                            // const DragHandle = sortableHandle(() => <span className="dragAble">
                            //     <FontAwesomeIcon
                            //         style={{ color: '#8898A8', height: '15px', width: '20px', marginLeft: '10px', cursor: 'not-allowed', }}
                            //         className={classes.moreIconsBtn}
                            //         icon={faBars}
                            //         aria-label="faBars"
                            //         id="faBars"
                            //     />
                            // </span>);
                            const data = [];
                            data.push(
                                <span
                                    key={`${index}-1`}
                                    className={[classes.cellRole, classes.tableTD].join(' ')}
                                    style={{ width: headers[0].width }}
                                >
                                    <span style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <span style={{ width: '100%' }}>
                                            <span style={{ width: '100%', display: 'flex', alignItems: 'center', padding: 0 }}>
                                                {/* <DragHandle /> */}
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        color: '#3B91DF',
                                                        fontSize: 12,
                                                        marginLeft: '30px',
                                                        width: 'calc(100% - 30px)',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Tooltip data={suite.suite_name}>{suite.suite_name}</Tooltip>
                                                </p>
                                            </span>
                                        </span>
                                    </span>
                                </span>,
                            );
                            data.push(
                                <span
                                    key={`${index}-2`}
                                    className={[classes.cellRole, classes.tableTD].join(' ')}
                                    style={{ width: headers[1].width }}
                                >
                                    <span className={classes.cellInStyle} style={{ color: BLACK_FONT }}>
                                        <p style={{ margin: 0, fontSize: 12 }}>
                                            {' '}
                                            {selectedDateFormat.value === 'actualDate'
                                                ? getFormatedDate(suite.creation_time, 'MMM DD YYYY', '')
                                                : getRelativeTime(suite.creation_time)}
                                        </p>
                                    </span>
                                </span>,
                            );
                            data.push(
                                <span
                                    key={`${index}-3`}
                                    className={[classes.cellRole, classes.tableTD].join(' ')}
                                    style={{ width: headers[2].width }}
                                >
                                    <span className={classes.cellInStyle} style={{ color: '#3B91DF' }}>
                                        <p style={{ margin: 0, fontSize: 12 }}>{suite.total}</p>
                                    </span>
                                </span>,
                            );
                            data.push(
                                <span
                                    key={`${index}-4`}
                                    className={[classes.cellRole, classes.tableTD].join(' ')}
                                    style={{ width: headers[3].width }}
                                >
                                    <span className={classes.cellInStyle} style={{ color: '#3B91DF' }}>
                                        <p style={{ margin: 0, fontSize: 12 }}>{suite.passed}</p>
                                    </span>
                                </span>,
                            );
                            data.push(
                                <span
                                    key={`${index}-5`}
                                    className={[classes.cellRole, classes.tableTD].join(' ')}
                                    style={{ width: headers[4].width }}
                                >
                                    <span className={classes.cellInStyle} style={{ color: '#3B91DF' }}>
                                        <p style={{ margin: 0, fontSize: 12 }}>{suite.failed}</p>
                                    </span>
                                </span>,
                            );
                            data.push(
                                <span
                                    key={`${index}-7`}
                                    className={[classes.cellRole, classes.tableTD].join(' ')}
                                    style={{ width: headers[6].width }}
                                >
                                    <div className={classes.badgeContain}>
                                        <div className={classes.badgeStyle} style={{ backgroundColor: '#E8001C' }}>
                                            {suite.errors_count}
                                        </div>
                                    </div>
                                </span>,
                            );
                            data.push(
                                <span
                                    key={`${index}-8`}
                                    className={[classes.cellRole, classes.tableTD].join(' ')}
                                    style={{ width: headers[6].width }}
                                >
                                    <div className={classes.badgeContain}>
                                        <div className={classes.badgeStyle} style={{ backgroundColor: '#F39B31' }}>
                                            {suite.warnings_count}
                                        </div>
                                    </div>
                                </span>,
                            );
                            data.push(
                                <span
                                    key={`${index}-9`}
                                    className={[classes.cellRole, classes.tableTD].join(' ')}
                                    style={{ width: headers[7].width }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' /*  paddingBottom: 20 */ }}>
                                        <Link to={`/reports/suite/${suite.suite_id}`}>
                                            <div
                                                style={{
                                                    backgroundColor: '#1BABAB',
                                                    height: '22px',
                                                    width: '22px',
                                                    borderRadius: '50px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <History style={{ fontSize: '16px', color: '#fff' }} aria-label="historyIcon" id="historyIcon" />
                                            </div>
                                        </Link>
                                    </div>
                                </span>,
                            );
                            // data.push(<span
                            //     key={`${index}-10`}
                            //     className={[classes.cellRole, classes.tableTD].join(" ")}
                            //     style={{ width: headers[8].width }}
                            // >
                            //     <div style={{ display: 'flex', alignItems: 'center', /* paddingBottom: 20 , width: '100%'  */ }}>
                            //         <Checkbox
                            //             checked={suite.checked === true}
                            //             color='primary'
                            //             disableRipple
                            //             className={classes.checkBoxIcon}
                            //             onClick={this.handleToggle(suite.suite_id)}
                            //             style={{
                            //                 color: suite.checked === true ? '#3b91df' : '#bac4ce',
                            //                 height: '40px',
                            //                 width: '20px',
                            //                 paddingLeft: 10
                            //             }}
                            //         />
                            //     </div>
                            // </span>);
                            if (disableCondition || !suite.checked || !isPrevSelected) {
                                // if no steps selected or current step is not selected or previous to current step is not selected then
                                // increase index for single step in dataMerge
                                dataIndex++;
                            }
                            if (!disableCondition && suite.checked) {
                                // If current step is selected set isPrevSelected 'true' for next step
                                isPrevSelected = true;
                                if (selectedIndex === -1) {
                                    // If there's no prior selectedIndex i.e -1
                                    selectedIndex = dataIndex; // Set current dataIndex as first selected index
                                    foundSelected = true; // set foundSelect true as it's first index we assume it as consecutive with next steps
                                } else if (selectedIndex !== -1 && selectedIndex !== dataIndex) {
                                    // If we select step 1,2 then skip step 3 and then again select 4th one then
                                    // step-in this condition and set foundSelected false (read it's initialization comment)
                                    // and disableCondition true (read it's initialization comment)
                                    foundSelected = false;
                                    disableCondition = true;
                                }
                            } else {
                                // otherwise set isPrevSelected 'false' for next step
                                isPrevSelected = false;
                            }
                            if (!dataMerge[dataIndex]) {
                                // If value for current index of dataMerge is undefined initialize it with emty array
                                dataMerge[dataIndex] = [];
                            }
                            dataMerge[dataIndex].push(data);
                        })}
                        {dataMerge.length > 0 ? (
                            dataMerge.map((data, index) => {
                                if ((disableCondition && numSelected > 0) || query !== '') {
                                    return (
                                        <li
                                            key={`item-${index}`}
                                            className={[classes.tableRows, classes.tableBodyTR].join(' ')}
                                            style={{ cursor: 'default' }}
                                        >
                                            {data}
                                        </li>
                                    );
                                }

                                if (!(Number(index) === Number(selectedIndex) && foundSelected) && numSelected > 0) {
                                    // if current step is not selected or foundSelected is false
                                    // push index in array for none dragable li
                                    dontMove.push(index);
                                }
                                return (
                                    <SortableItem
                                        dataMoveAble={(Number(index) === Number(selectedIndex) && foundSelected) || numSelected === 0}
                                        key={`item-${index}`}
                                        index={index}
                                        _classes={
                                            !(Number(index) === Number(selectedIndex) && foundSelected) && numSelected > 0
                                                ? [classes.tableRows, classes.tableBodyTR].join(' ')
                                                : [classes.tableRows, classes.tableBodyTR, classes.dragAble].join(' ')
                                        }
                                        styles={{ cursor: 'default' }}
                                        id={index}
                                        value={data}
                                    />
                                );
                            })
                        ) : (
                            <li
                                key="item-nodata"
                                className={[classes.tableRows, classes.tableBodyTR].join(' ')}
                                style={{ cursor: 'default', height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                {this.props.isLoading ? (
                                    <CircularProgress style={{ color: '#4885ed' }} size={40} thickness={3} />
                                ) : (
                                    <Typography style={{ textAlign: 'center' }}> No suites available.</Typography>
                                )}
                            </li>
                        )}
                    </ul>
                </div>
            );
        });

        return (
            <div className={classes.root}>
                <div className={classes.filterContainer}>
                    <div className={classes.leftContainer}>
                        <div style={{ width: 150 }}>
                            <Select
                                className={classes.select}
                                classNamePrefix="select"
                                defaultValue={selectedOptions[1]}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isRtl={false}
                                isSearchable
                                name="screen"
                                options={selectedOptions}
                                closeMenuOnSelect={false}
                                isOptionDisabled={(option) => option.disabled === 'yes'}
                            />
                        </div>
                        <span className={classes.pipe} />
                        <div style={{ width: 200 }}>
                            <Select
                                name="screen"
                                classNamePrefix="select"
                                value={selectedDateFormat}
                                onChange={this.handleChangeDateFormat}
                                options={optionsFormatDate}
                                defaultValue={optionsFormatDate[0].label}
                                closeMenuOnSelect
                                className={classes.select}
                            />
                        </div>
                    </div>
                    {testSuiteReports && testSuiteReports.length ? (
                        <div className={classes.downloadBtnWrapper}>
                            <CustomDownloadModal
                                handleSubmit={(downloadType) => {
                                    this.handleDownloadReport(downloadType);
                                }}
                                keepMounted
                                containerStyle={{ height: 'unset' }}
                                btn={
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        aria-owns={anchorEl ? 'simple-menu' : null}
                                        aria-haspopup="true"
                                        classes={{ outlined: classes.outline, root: classes.buttonRoot }}
                                    >
                                        <Download className={classes.upoadIcon} />
                                        Download
                                    </Button>
                                }
                                calledFrom="report"
                            />
                        </div>
                    ) : null}
                </div>
                <div style={{ marginTop: 15, minHeight: 'calc(100vh - 217px)' }}>
                    <SortableList
                        lockAxis="y"
                        useDragHandle
                        onSortStart={this.onSortStart}
                        shouldCancelStart={this.shouldCancelStart}
                        onSortEnd={this.onSortEnd}
                        helperClass={classes.selectedItem}
                    />
                </div>
                <TableFooter
                    count={testSuiteReports.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    page={page}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    applyCustomClasses
                    labelRowsPerPage="Reports per page:"
                    labelForCounter="Reports"
                    countAvatar={getCountAvatar(testSuiteReports.length)}
                    containerStyle={{ marginLeft: '-20px', marginRight: '-20px' }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // auth reducer
        user: state.authReducer.user,
        // general reducer
        query: state.generalReducer.queryValue,
        // project reducer
        testCasesWithAccount: state.testCaseReducer.testCasesWithAccount,
        executionReports: state.projectReducer.executionReports,
        isLoading: state.suiteReportReducer.isLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getReports: () => dispatch(TestSuiteActions.getReports()),
        // General Action
        queryRemove: () => dispatch(GeneralActions.queryRemove()),
        updateTitle: (...args) => dispatch(GeneralActions.updateTitle(...args)),
        clearSnackBar: () => dispatch(ModalActions.clearSnackbar()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SuiteReport));
