import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '../Table/TableCell';
import TableRow from '../Table/TableRow';
import { checkKeyInObject, checkArrayLength } from '../../utils/utils';
import Tooltip from '../Tooltip';
import { TestStepUtils } from '../../utils/TestStepUtils';

const styles = (theme) => ({
    text: {
        fontSize: 12,
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    noWrap: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
    cell: {
        textAlign: 'left',
        flexDirection: 'row',
        padding: 0,
        '&>:first-child': {
            paddingLeft: '20px !important',
        },
    },
    minWidth: {
        minWidth: '60px !important',
        [theme.breakpoints.only('xs')]: {
            minWidth: '200px !important',
            maxWidth: '200px !important',
        },
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#FAFAFA',
        },
    },
});
function PreviewTestDataBody(props) {
    const { classes, testData, headers } = props;
    let keys = [];
    let data = {};
    if (checkKeyInObject(testData, 'data') && checkKeyInObject(testData, 'order') && testData.data && testData.order) {
        data = typeof testData.data === 'object' ? testData.data : JSON.parse(testData.data);
        keys = typeof testData.order === 'object' ? testData.order : JSON.parse(testData.order);
    }
    const renderData = [];
    if (checkArrayLength(keys) && checkKeyInObject(data, keys[0])) {
        let len = 0;
        for (let i = 0; i < keys.length; i++) if (data[keys[i]].length > len) len = data[keys[i]].length;
        for (let i = 0; i < len; i++) {
            let obj = {};
            keys.forEach((key) => {
                obj = { ...obj, [key]: data[key][i] };
            });
            renderData.push(obj);
        }
    }
    return renderData.map((row, index) => {
        return (
            <TableRow style={{ height: 40, cursor: 'default' }} key={`renderData-${index}`} className={classes.row} dataTypePrefix="m_">
                {keys.map((col, ind) => {
                    const cellData = TestStepUtils.isShowAsterik(col, row[col]);
                    return (
                        <TableCell
                            style={{ alignItems: 'flex-start' }}
                            className={[classes.cell, classes.noWrap, classes.minWidth].join(' ')}
                            width={headers[ind].width}
                            data-resize={`m_element${ind}_c`}
                            dataTypePrefix="m_"
                            key={col}
                        >
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: 24, marginBottom: 24 }}>
                                    <div className={classes.text}>
                                        <Tooltip data={typeof cellData === 'string' ? cellData : null}>{cellData}</Tooltip>
                                    </div>
                                </div>
                            </div>
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    });
}

PreviewTestDataBody.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    testData: PropTypes.instanceOf(Object),
};

PreviewTestDataBody.defaultProps = {
    testData: {},
};

export default withStyles(styles)(PreviewTestDataBody);
