import React, { forwardRef, memo } from 'react';

const Line = forwardRef((props,ref)=>{

    const {
        variant,
        lineHeight,
        lineWidth ,
        color ,
        onClick , 
        ...rest
    } = props;

    const vertical_style = {
        borderLeft: `${lineWidth}px solid ${color}`,
        height: `${lineHeight}px`        
    }
    const horizantal_style = {
        borderTop: `${lineHeight}px solid ${color}`,
        width:`${lineWidth}px` 
    }

    const getStyles = () => {
        return variant === 'horizantal' ? horizantal_style : vertical_style
    }
    return (
        <div onClick={onClick} ref={ref} style={getStyles()} {...rest} ></div>
    )

});

Line.defaultProps = {
    variant:'vertical',
    onClick:()=>{},
    color:'#EEEEEE',
    lineHeight:'100',
    lineWidth:'1'
}

export default memo(Line);