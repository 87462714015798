import { ActionTypes } from '../constants';
import { checkKeyInObject } from '../../utils/utils';

const initialState = {
    liveAlerts: {},
    error: null,
    changes: {},
    executions: [],
    recentTestGenerations: [],
    projectStats: {},
    isError: false,
    isLoading: false,
    success: null,
    settingDialogOpen: false,
    projectCount: 0,
    tickerIndicatorValue: localStorage.getItem('StickyHeader') ? localStorage.getItem('StickyHeader') : 'Week',
    dashboardData: {},
};

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_ERROR: {
            return { ...state, isError: false, error: null };
        }

        case ActionTypes.CLEAR_SUCCESS: {
            return { ...state, success: null };
        }

        case ActionTypes.GET_DASHBOARD_INFO: {
            return { ...state, isLoading: true };
        }

        case ActionTypes.GET_DASHBOARD_INFO_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                dashboardData: { ...checkKeyInObject(action, 'payload.data', 'value', {}) },
                error: null,
                isError: false,
            };
        }

        case ActionTypes.GET_DASHBOARD_INFO_FAILED: {
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.payload.error || action.payload.message,
                success: null,
                liveAlerts: {
                    error: { count: 0 },
                    warning: { count: 0 },
                    smart: { count: 0 },
                    time: { count: 0 },
                },
            };
        }
        case ActionTypes.TOGGLE_DASHBOARD_SETTING_GEAR:
            return {
                ...state,
                settingDialogOpen: action.payload.settingDialogOpen,
            };

        case ActionTypes.DASHBOARD_TICKER_TIMEINDICATOR:
            return {
                ...state,
                tickerIndicatorValue: action.payload.tickerIndicatorValue,
            };
        default:
            return state;
    }
}
