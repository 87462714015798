import {useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ProjectActions, ModalActions, TestCaseActions } from '../../../../store/actions';
import { checkArrayLength, checkKeyInObject, checkObject, getStepsData, TestCaseUtils } from '../../../../utils';
import WSService from '../../../../services/WSService';
import { TestCaseMiddleware } from '../../../../store/middleware';
// import usePrevious from '../../../../../common/UsePrevious';
import { warningAlertBar } from '../../../../services/AlertBarService';
const useContainer = (props) => {
    let isWaitingForMsgTypeTwoResponse = false; // prevent ws receiver call until msgType 2 is being sent
    let [ws,setWs] = useState({})
    const dispatch = useDispatch();
    const dispatchActions = () => {
        return {
            getExecutionTasks: (...args) => dispatch(ProjectActions.getExecutionTasks(...args)),
            showSnackBar: (...args) => dispatch(ModalActions.toggleSnackBar(...args)),
            // getTestCases: (...args) => dispatch(ProjectActions.getTestCases(...args)),
            // getTestData: (...args) => dispatch(ProjectActions.getTestData(...args)),
            toggleMsgType15Flag: (...args) => dispatch(ProjectActions.toggleMsgType15Flag(...args)),
            toggleMsgType18Flag: (...args) => dispatch(ProjectActions.toggleMsgType18Flag(...args)),
            saveSessionId: (...args) => dispatch(ProjectActions.saveSessionId(...args)),
            clearWsData: (...args) => dispatch(ProjectActions.clearWsData(...args)),
            removeMsgType2Data: (...args) => dispatch(ProjectActions.removeMsgType2Data(...args)),
            cloneTestCase: (...args) => dispatch(TestCaseActions.cloneTestCase(...args)),
            deleteTestCase: (...args) => dispatch(TestCaseActions.deleteTestCase(...args)),
        };
    };
    const actions = dispatchActions();

    const useRedux = (state) => {
        return {
            tags: state.tagReducer.tags,
            query: state.generalReducer.queryValue,
            selectedExecuteTask: state.projectReducer.selectedExecuteTask,
            user: state.authReducer.user,
            project: state.projectReducer.selectedProject,
            testData: state.projectReducer.testData,
            executions: state.projectReducer.executions,
            failedStepsData: state.projectReducer.failedStepsData[checkKeyInObject(checkKeyInObject(props, 'testCase'), 'testCaseId')],
            debugStepsData: state.projectReducer.debugStepsData[checkKeyInObject(checkKeyInObject(props, 'testCase'), 'testCaseId')],
            wsRunningCaseData: state.projectReducer.wsRunningCaseData[checkKeyInObject(checkKeyInObject(props, 'testCase'), 'testCaseId')],
            isWsRunning: state.projectReducer.isWsRunning[checkKeyInObject(checkKeyInObject(props, 'testCase'), 'testCaseId')],
            isGenerateBtnClicked: state.projectReducer.isGenerateBtnClicked[checkKeyInObject(checkKeyInObject(props, 'testCase'), 'testCaseId')],
        };
    };
    const redux = useSelector(useRedux);
    const [openDeleteCaseDialog, setOpenDeleteCaseDialog] = useState(false);

    const hadleCloseDeleteAlertModal = (isConfirm) => {
        if (isConfirm) {
            actions.deleteTestCase([props.testCase.testCaseId], 'projectDetails');
        }
        setOpenDeleteCaseDialog(false);
    };

    useEffect(() => {
        const initWs = async () => {
            setWs(await WSService.getWebSocketInstance())
            if (!isWaitingForMsgTypeTwoResponse) {
                reviceWSEvents();
            }
        };

        initWs();
    }, []);
    
   
    const getDataResizeKey = (cellName) => {
        return `element${props.heads.findIndex((elm) => elm.id === cellName)}_c`;
    };

    const getCell = (cellName) => {
        const { heads } = props;
        const headIndex = heads.findIndex((elm) => elm.id === cellName);
        return headIndex > -1 ? heads[headIndex] : {};
    };

    // const getTestCasesAndData = async (projectId, projectChange = false) => {
    //     const { getTestCases, getTestData } = actions;
    //     if (projectId && projectChange) {
    //         await getTestCases(projectId, () => getTestData(projectId, false));
    //     }
    // };

    const showTags = (testCaseTags) => {
        const { tags } = redux;
        let tagNames = '';
        if (checkArrayLength(testCaseTags)) {
            testCaseTags.forEach((tagId) => {
                const tagFound = tags.find((tag) => tagId === tag.tag_id);
                if (tagFound) {
                    tagNames += `, ${tagFound.name}`;
                }
            });
        }
        return tagNames.slice(2); // to remove first ', '
    };

    const handleClickRow = (e, testCase) => {
        const { projectId } = props;
        const { getExecutionTasks, showSnackBar } = actions;
        if (testCase.testCaseId && testCase.testScriptId && testCase.testScriptId!==0) {
            getExecutionTasks(projectId || 0, -1, testCase.testCaseId); // -1 is for status all in executions
        } else {
            showSnackBar('No executions found.');
        }
    };

    const reviceWSEvents = (testCase = null) => {
        const _actions = {
            UpdateScriptMessageJsonFromCaseTable: ({ sendFifteen, sendEighteen }) => {
                if (checkObject(testCase)) {
                    UpdateScriptMessageJson(testCase, () => {}, sendFifteen, sendEighteen);
                }
            },
        };

        const data = {
            callingFrom: 'CaseTable',
            paramTestCaseId: testCase && testCase.testCaseId,
        };
        // Invoking web socket receiver
        TestCaseUtils.receiveWSEvents(ws, _actions, data);
    };

    const UpdateScriptMessageJson = async (testCase, onComplete = () => {}, sendFifteen = true, sendEighteen = true) => {
        const {
            project: { cacheXpaths, projectId },
            failedStepsData,
            debugStepsData,
        } = redux;
        const { toggleMsgType15Flag, toggleMsgType18Flag } = actions;

        const paramTestCaseId = testCase.testCaseId;
        const Steps = testCase.recoverTestSteps ? [...testCase.recoverTestSteps] : [];
        const filteredTestSteps = [];
        const stepsToSend = [];

        Steps.forEach((step) => {
            filteredTestSteps.push({
                ...step,
                subInstructions: [],
            });
        });

        filteredTestSteps.forEach((step) => {
            if (step.instr) {
                stepsToSend.push({
                    data: step.data,
                    instr: step.instr,
                    columnName: step.columnName,
                    xpath: cacheXpaths ? step.xpath || '' : '',
                    stepId: `${step.stepId || ''}`,
                });
            }
        });
        const isMsgType13Received = checkKeyInObject(failedStepsData, paramTestCaseId) && failedStepsData[paramTestCaseId].instrNum;
        const isMsgType16Received = checkKeyInObject(debugStepsData, paramTestCaseId) && debugStepsData[paramTestCaseId].instrNum;
        if (isMsgType13Received && sendFifteen) {
            console.info('myMessage object sending in msgType: 15', failedStepsData[paramTestCaseId].sessionId);
            WSService.sendMessage(
                JSON.stringify({
                    msgType: 15,
                    sessionId: failedStepsData[paramTestCaseId].sessionId,
                    accountId: WSService.getAccountId(),
                }),
            );
            toggleMsgType15Flag(paramTestCaseId, [], true);
        } else if (isMsgType16Received && sendEighteen) {
            console.info('myMessage object sending in msgType: 18', debugStepsData[paramTestCaseId].sessionId);
            WSService.sendMessage(
                JSON.stringify({
                    msgType: 18,
                    sessionId: debugStepsData[paramTestCaseId].sessionId,
                    accountId: WSService.getAccountId(),
                }),
            );
            toggleMsgType18Flag(paramTestCaseId, [], true);
        } else {
            const isTestCaseAlreadyRunning = await TestCaseMiddleware.getTestCaseGenerationStatus(paramTestCaseId);
            if (!isTestCaseAlreadyRunning) {
                // const myMessage = {
                //     filename: testCase.testCaseName,
                //     projectId: Number(projectId),
                //     testCaseId: Number(paramTestCaseId),
                //     testSteps: stepsToSend,
                // };
                const _projectId = Number(projectId);
                const _testcaseId = Number(paramTestCaseId);
                const newSessionId = await WSService.getNewSessionId(WSService.getAccountId());
                // console.info('myMessage object sending in msgType: 2', myMessage);
                actions.saveSessionId(newSessionId, `${paramTestCaseId}`, 'CaseTable');
                try {
                    isWaitingForMsgTypeTwoResponse = true;

                    let consoleMessage = `DATA SENT through retry API request with [PROJECT]: , ${_projectId}, [TESTCASE]: , ${_testcaseId},  [SESSIONID] , ${newSessionId}, `;
                    let color = 'DodgerBlue';
                    // eslint-disable-next-line no-console
                    console.log(`%c${consoleMessage}`, `color:${color};`, [...stepsToSend]);

                    const res = await TestCaseUtils.updateStepsRequestPayload(_projectId, _testcaseId, stepsToSend, newSessionId);
                    if (checkKeyInObject(res, 'type') && res.type === 'UPDATE_STEPS_SUCCESS') {
                        consoleMessage = `RECEIVED retry API success respons with [PROJECT]: , ${_projectId}, [TESTCASE]: , ${_testcaseId},  [SESSIONID] , ${newSessionId}, `;
                        color = 'Green';
                        // eslint-disable-next-line no-console
                        console.log(`%c${consoleMessage}`, `color:${color};`);
                    } else {
                        actions.clearWsData(paramTestCaseId, 'runApiFailed');
                        consoleMessage = `RECEIVED retry API failure respons with [PROJECT]: , ${_projectId}, [TESTCASE]: , ${_testcaseId},  [SESSIONID] , ${newSessionId}, `;
                        color = 'red';
                        // eslint-disable-next-line no-console
                        console.log(`%c${consoleMessage}`, `color:${color};`);
                        actions.removeMsgType2Data([`${paramTestCaseId}`], 'CaseTable');
                    }
                    isWaitingForMsgTypeTwoResponse = false;
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log(err, 'ERROR');
                }
            } else {

                warningAlertBar({
                    message:{
                        title:'Script is being generated',
                        description:'Once the script generation is complete, you can run your test.'
                    },
                    duration:20000,

                })
               // actions.showSnackBar('Generation/Edit in progress. Some features will not be accessible.', '', false, 8000, true);
            }
        }
        reviceWSEvents(testCase);
        onComplete();
    };

    const runTestSteps = async (testCase) => {
        const _steps = await getStepsData(testCase.testCaseId);
        if (
            (checkKeyInObject(_steps, 'testSteps') && checkArrayLength(_steps.testSteps)) ||
            (checkKeyInObject(_steps, 'recoverTestSteps') && checkArrayLength(_steps.recoverTestSteps))
        ) {
            testCase = { ...testCase, ..._steps };
            UpdateScriptMessageJson(testCase);
        }
    };

    return {
        actions,
        redux,
        // state,
        runTestSteps,
        // UpdateScriptMessageJson,
        // reviceWSEvents,
        handleClickRow,
        showTags,
        // getTestCasesAndData,
        getCell,
        getDataResizeKey,
        setOpenDeleteCaseDialog,
        openDeleteCaseDialog,
        hadleCloseDeleteAlertModal,
    };
};

export default useContainer;
