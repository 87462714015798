// import materials
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

// import packages
import React from 'react';

const placeholderStyle = {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 300,
    color: '#C4C4C4',
    opacity: 1,
};

const styles = () => ({
    font: {
        fontSize: 12,
        color: '#595959',
    },
    labelColor: {
        '&>label': {
            color: '#595959',
            fontSize: '12px',
            transform: 'translate(0, 1.5px)',
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    marginNormal: {
        marginBottom: 0,
        marginTop: 0,
    },
    placeholder: {
        '&>div>input::-webkit-input-placeholder': { ...placeholderStyle },
        '&>div>div>input::-moz-input-placeholder': { ...placeholderStyle },
    },
    root: {
        width: '100%',
        flex: '1 1 auto',
        padding: '0 6px 0px',
        overflowY: 'hidden',
        overflowX: 'hidden',
    },
    testError: {
        color: 'red',
        fontSize: 11,
        margin: 0,
    },
});

function CreateGroupModal({ groupName, classes, handleChange, groupNameError }) {
    return (
        <div className={classes.root}>
            <TextField
                autoFocus
                className={[classes.labelColor, classes.placeholder].join(' ')}
                fullWidth
                id="GroupName"
                InputLabelProps={{ shrink: true }}
                InputProps={{ classes: { input: classes.font } }}
                aria-label="Group Name"
                onChange={handleChange('groupName')}
                placeholder="Enter group name"
                required
                type="text"
                value={groupName}
            />
            {groupNameError ? <p className={classes.testError}>{groupNameError}</p> : null}
        </div>
    );
}

export default withStyles(styles)(CreateGroupModal);
