//import { temp_getAlertDetail_response, temp_getAlerts_response } from '../../features/analyze/alerts/__mocks__';
import HttpBase from '../../services/HttpBase';

class AlertMiddleware extends HttpBase {


    /* New feature of smart maintanance changes

    temp_getAlerts = async () => {
        await sleep(400);
        return { data: temp_getAlerts_response, status: 200 };
    };

     async getAlerts() {
         const result = await this.temp_getAlerts(); // this.httpClient.get('/v1/bugs/get_execution_bugs');
         if (result && result.data && Object.keys(result.data).length && result.status === 200) {
             return { data: result.data };
         }
         throw new Error('no alert found');
     }
     async removeAlerts(alert_ids) {
         const result = await this.httpClient.post('alerts/removeAlerts', { alert_ids });
         if (result && result.status === 200) {
             return { data: result.data };
         }
         throw new Error('no alert found');
     }

    temp_getAlertDetail = async (bug_id) => {
        await sleep(400);
        return { data: temp_getAlertDetail_response.filter((bug) => bug.bug_id === bug_id), status: 200 };
    };
    async getAlertDetail(bug_id) {
        const result = this.temp_getAlertDetail(bug_id); // await this.httpClient.get(`/v1/bugs/${bug_id}/get_execution_bug `);
        if (result && result.data && Object.keys(result.data).length && result.status === 200) {
            return { data: result.data };
        }
        throw new Error('no alert found');
    }
    */

    async getAlerts() {
        try {
            const result = await this.httpClient.get(`/v1/alerts/get_execution_alerts`);
            if (result && result.data && Object.keys(result.data).length && result.status === 200) {
                return { data: result.data };
            }
            throw new Error("no alert found");
        } catch (error) {
            throw error
        }
    }
    async removeAlerts(alert_ids) {
        try {
            const result = await this.httpClient.post(`alerts/removeAlerts`, { alert_ids });
            if (result && result.status === 200) {
                return { data: result.data };
            }
            throw new Error("no alert found");
        } catch (error) {
            throw error
        }
    }

}
export default new AlertMiddleware();
