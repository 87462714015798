/* eslint-disable no-underscore-dangle */
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
    text: {
        fontSize: '12px',
    },
});

const UserData = (props) => {
    const { classes, email, name, userRole } = props;
    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography className={classes.text}>UserName</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography className={classes.text}>{name}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography className={classes.text}>Email</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography className={classes.text}>{email}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography className={classes.text}>Role</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography className={classes.text}>{userRole}</Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

UserData.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
};

export default withStyles(styles)(UserData);
