import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';

import { DIALOG_ROOT, MODAL_FOOTER } from '../../../common/cssConstants';
import { checkArrayLength, checkKeyInObject } from '../../../utils/utils';
import EditTestCaseDialogContent from './EditTestCaseDialogContent';
import { DialogTop } from '../../../components/Modal/ModalTop';
import GeneralModalFooter from '../../modal/GeneralModal/GeneralModalFooter';
import { CANCEL_BUTTON, NEXT_SUBMIT_BUTTON } from '../../../components/Button/Btn';

const styles = (theme) => ({
    root: {
        width: '100%',
        display: 'flex',
    },
    dialogModal: {
        '&>div': {
            borderRadius: '0px',
        },
        ...DIALOG_ROOT,
    },
    dialogContent: {
        overflow: 'hidden',
        width: 500,
        padding: '0 !important',
        maxHeight: 'calc(100vh - 271px)',
        overflowY: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100vw - 100px)',
        },
    },
});

class EditTestCase extends React.Component {
    state = {
        testCaseName: '',
        selectedProjectId: 'default',
        selectedProjectName: 'default',
    };

    componentDidMount() {
        this.updateState({
            testCaseName: this.props.editTestCaseData.testCaseName,
            selectedProjectId: `${this.props.editTestCaseData.discoveryId}`,
        });
    }

    UNSAFE_componentWillUpdate() {
        const { selectedProjectName, selectedProjectId } = this.state;
        const { projectDropDownData } = this.props;
        if (!selectedProjectName && checkArrayLength(projectDropDownData)) {
            const selected = projectDropDownData.find((_data) => checkKeyInObject(_data, 'value') && _data.value === selectedProjectId);
            this.updateState({ selectedProjectName: checkKeyInObject(selected, 'label') ? selected.label : '' });
        }
    }

    handleChange = (e) => {
        this.setState({
            testCaseName: e.target.value,
        });
    };

    selectProjectHandler = (selectedProject) => {
        if(checkKeyInObject(selectedProject, 'value')){
            this.setState({
                selectedProjectId: selectedProject.value,
                selectedProjectName: selectedProject.label,
            });
        }

    };

    clearForm = () => {
        this.setState({
            testCaseName: '',
            selectedProjectId: 'default',
            selectedProjectName: 'default',
        });
        this.props.handleClose();
    };

    updateState = (obj) => {
        this.setState(obj);
    };

    render() {
        const { classes, handleClose, open, projectDropDownData, handleSubmit, editTestCaseData } = this.props;
        const { testCaseName, selectedProjectId, selectedProjectName } = this.state;
        const isSubmitDisabled = !(
            selectedProjectId !== 'default' &&
            selectedProjectName !== 'default' &&
            testCaseName.trim() !== '' &&
            editTestCaseData.discoveryId !== Number(selectedProjectId)
        );
        return (
            <div>
                <Dialog disableRestoreFocus open={open} aria-labelledby="form-dialog-title" className={classes.dialogModal}>
                    <DialogTop
                        title="Move Test Case"
                        closeIconAction={() => {
                            handleClose(false);
                        }}
                    />
                    <DialogContent style={{ paddingTop: 0 }} className={classes.dialogContent}>
                        <EditTestCaseDialogContent
                            titleName="Case"
                            ariaLabel="TestCaseName"
                            onChange={this.handleChange}
                            placeholder="Test Case Name"
                            value={testCaseName}
                            searchValue={selectedProjectId}
                            searchOnChange={this.selectProjectHandler}
                            options={projectDropDownData}
                        />
                    </DialogContent>
                    <DialogActions style={MODAL_FOOTER}>
                        <GeneralModalFooter
                            buttons={[
                                NEXT_SUBMIT_BUTTON({
                                    name: 'Move',
                                    action: () => {
                                        handleSubmit(testCaseName, selectedProjectId, selectedProjectName, this.clearForm);
                                    },
                                    isDisabled: isSubmitDisabled,
                                }),
                                CANCEL_BUTTON({ action: this.clearForm }),
                            ]}
                        />
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(EditTestCase);
