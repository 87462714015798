import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import CheckBox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxBlank from '@material-ui/icons/CheckBoxOutlineBlank';

import { checkArrayLength, checkKeyInObject } from '../../utils/utils';
import { generalModalActions } from '../../store/actions';
import { BLACK_FONT } from '../../common/cssConstants';
import SearchInput from '../../features/modal/GeneralModal/SearchInput';

const styles = () => ({
    dialogContent: {
        overflowY: 'auto',
        paddingBottom: 12,
        paddingLeft: 40,
        width: '600px',
        '&:first-child': {
            paddingTop: 0,
            width: 530,
        },
    },
    checkBoxButton: {
        '&>span>svg': {
            fontSize: 20,
        },
    },
    rootDialogContent: {
        width: '100%',
        '&>div:first-of-type': {
            margin: '0px 30px 20px 30px',
        },
    },
    padding: {
        padding: '0px',
    },
    labelColor: {
        '&>label': {
            color: '#595959',
            fontSize: '12px',
            transform: 'translate(0, 1.5px)',
        },
        '&>div': {
            '&:before': {
                borderColor: '#979797 !important',
            },
            '&:after': {
                borderColor: '#979797 !important',
            },
        },
    },
    newCaseNameInput: {
        margin: '0px',
        width: 'calc(100% - 60px)',
    },
    font: {
        fontSize: 12,
        color: '#595959',
    },
    closeButton: {
        cursor: 'pointer',
    },
    checkBoxIcon: {
        color: '#0092e5',
        fontSize: '20px',
    },
    checkBoxBlankColorIcon: {
        color: '#bac5d0',
    },
    checkBox: {
        height: 25,
        width: 25,
        marginRight: '5px',
    },
    listItem: {
        display: ' flex',
        alignItems: 'center',
        borderBottom: '1px solid  #cad3db',
        padding: '8px 30px 6px 30px',
        color: '#b8c4cf',
        '&:nth-of-type(odd)': {
            backgroundColor: '#fafafa',
        },
    },
    listItemText: {
        cursor: 'pointer',
        fontSize: '12px',
        color: BLACK_FONT,
    },
    underLine: {
        borderBottom: '0.5px solid #bdc8d3',
        fontSize: '12px',
        '&:after': {
            borderBottom: '0.5px solid #bdc8d3',
            transition: 0,
        },
        '&:before': {
            borderBottom: '0.5px solid #bdc8d3',
            transition: 0,
        },
    },
    testError: {
        color: 'red',
        marginTop: 5,
        fontSize: 11,
    },
    wrapperCheckBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #cad3db',
        borderTop: '1px solid #cad3db',
        padding: ' 3px 0px 3px 15px',
    },
    wrapperQueryCases: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 435px)',
    },
});

class MergeModalMerge extends Component {
    state = {
        newCaseName: '',
        query: '',
        selectedTestCaseIds: [],
        isDuplicate: false,
    };

    UNSAFE_componentWillMount() {
        if (checkKeyInObject(this.props.tabsData, 'merge')) {
            this.setState({ ...this.props.tabsData.merge.state });
        }
    }

    componentWillUnmount() {
        this.props.updateTabData('merge', { state: this.state });
    }

    onSelectAllClick = (e, cases) => {
        const _selectedTestCaseIds = [];
        if (e.target.checked) {
            cases.forEach((_case) => {
                _selectedTestCaseIds.push(_case.testCaseId);
            });
        }
        this.setState({ selectedTestCaseIds: [..._selectedTestCaseIds] });
    };

    handleChange = (e) => {
        const { testCases } = this.props;
        let isDuplicate = false;
        if (Array.isArray(testCases)) {
            for (let i = 0; i < testCases.length; i++) {
                if (checkKeyInObject(testCases[i], 'testCaseName') && e.target.value === testCases[i].testCaseName) {
                    isDuplicate = true;
                    break;
                }
            }
        }
        this.setState({ [e.target.name]: e.target.value, isDuplicate });
    };

    handleSearch = (e) => {
        const query = e.target.value;
        this.setState({ query });
    };

    clearSearch = () => {
        this.setState({ query: '' });
    };

    handleSelectCase = (caseId) => {
        const { selectedTestCaseIds } = this.state;
        if (selectedTestCaseIds.includes(caseId)) {
            const temp = [...selectedTestCaseIds];
            temp.splice(temp.indexOf(caseId), 1);
            this.setState({ selectedTestCaseIds: [...temp] });
        } else {
            this.setState({ selectedTestCaseIds: [...selectedTestCaseIds, caseId] });
        }
    };

    render() {
        const { classes, testCases } = this.props;
        const { newCaseName, query, selectedTestCaseIds, isDuplicate } = this.state;
        const queryCases = checkArrayLength(testCases)
            ? testCases.filter((_case) => {
                  return _case && _case.testCaseName && _case.testCaseName.toLowerCase().includes(query.toLowerCase().trim());
              })
            : [];

        let numSelected = 0;
        queryCases.forEach((_case) => {
            if (selectedTestCaseIds.indexOf(_case.testCaseId) >= 0) {
                numSelected++;
            }
        });
        const rowCount = checkArrayLength(queryCases) ? queryCases.length : 0;
        return (
            <DialogContent
                className={classes.dialogContent}
                style={{ maxHeight: `calc(100vh - ${isDuplicate ? '309px' : '330px'})` }}
                classes={{ root: classes.padding }}
            >
                <div className={classes.rootDialogContent}>
                    <div>
                        <TextField
                            id="standard-basic"
                            name="newCaseName"
                            label="New Name"
                            aria-label="New Case Name"
                            onChange={this.handleChange}
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            fullWidth
                            autoFocus
                            type="text"
                            InputProps={{
                                value: newCaseName,
                                classes: {
                                    input: classes.font,
                                },
                            }}
                            className={`${classes.labelColor} ${classes.newCaseNameInput}`}
                        />
                        {isDuplicate ? <Typography className={classes.testError}>Test case name already exists.</Typography> : null}
                    </div>
                    <div className={classes.wrapperCheckBox}>
                        <CheckBox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            className={classes.checkBoxButton}
                            checked={numSelected === rowCount}
                            onChange={(e) => {
                                this.onSelectAllClick(e, queryCases);
                            }}
                            style={{ height: '20px', width: '20px', marginLeft: '18px', color: numSelected === rowCount ? '#3b91df' : '#bac4ce' }}
                        />
                        <SearchInput
                            id="MergeModal_Merge"
                            placeholder="Search"
                            onChange={this.handleSearch}
                            value={this.state.query}
                            style={{ marginRight: '40px', width: 250 }}
                            clearSearch={this.clearSearch}
                        />
                    </div>
                    <div className={classes.wrapperQueryCases}>
                        {queryCases &&
                            queryCases.map((testCase) => (
                                <div key={testCase.testCaseId} className={classes.listItem}>
                                    <IconButton
                                        onClick={() => {
                                            this.handleSelectCase(testCase.testCaseId);
                                        }}
                                        variant="contained"
                                        color="primary"
                                        className={classes.checkBox}
                                        tabIndex={-1}
                                    >
                                        {selectedTestCaseIds.includes(testCase.testCaseId) ? (
                                            <CheckBoxIcon className={classes.checkBoxIcon} aria-label="checkBoxIcon" id="checkBoxIcon" />
                                        ) : (
                                            <CheckBoxBlank
                                                className={`${classes.checkBoxIcon} ${classes.checkBoxBlankColorIcon}`}
                                                aria-label="checkBoxBlankIcon"
                                                id="checkBoxBlankIcon"
                                            />
                                        )}
                                    </IconButton>
                                    <div
                                        aria-hidden
                                        className={classes.listItemText}
                                        onClick={() => {
                                            this.handleSelectCase(testCase.testCaseId);
                                        }}
                                    >
                                        {testCase.testCaseName}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </DialogContent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        testCases: state.projectReducer.testCases,
        tabsData: state.generalModalReducer.tabsData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTabData: (...args) => dispatch(generalModalActions.updateTabData(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MergeModalMerge));
