/* eslint-disable no-console */
import ReconnectingWebSocket from 'reconnecting-websocket';
import config from '../config';
import store from '../store';
import { AuthActions } from '../store/actions';
import { checkArrayLength } from '../utils';
import { checkKeyInObject } from '../utils/utils';

// Global Variables for ws msgs
let connectionCount = 0;

export default class WSService {
    sessionIdWebSocket;

    webSocket;

    sessionId;

    sessionIds = [];

    accountId;

    alreadyInitializedWS = false;

    alreadyInitializedSessionIdWS = false;

    static initializeWebSocket = (accountId, ws = 'webSocket', alreadyInitialized = 'alreadyInitializedWS') => {
        return new Promise((resolve) => {
            if (!this[alreadyInitialized]) {
                this.accountId = accountId;

                let wsURL = '';
                if (config.eventSource.endpoint) {
                    wsURL = `${config.eventSource.endpoint}?accountId=${accountId}`;
                } else {
                    const baseURL = `${(window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host}/ws`;
                    wsURL = `${baseURL}?accountId=${accountId}`;
                }
                this[ws] = new ReconnectingWebSocket(wsURL);
                this[ws].onopen = () => {
                    const token = localStorage.getItem('token');
                    console.log('EVENT SOURCE OPEN: ', wsURL); // eslint-disable-line no-console
                    if (this[ws].readyState === this[ws].OPEN) {
                        try {
                            this[ws].send(
                                JSON.stringify({
                                    msgJson: JSON.stringify({ authorizationToken: token }),
                                    msgType: 1,
                                    sessionId: '',
                                    accountId,
                                }),
                            );
                            this[alreadyInitialized] = true;
                            if (ws !== 'webSocket') {
                                resolve(this[ws]); // resolve only when condition meets otherwise this.webSocket.onmessage resolve it
                            }
                        } catch (err) {
                            console.log(err, 'ERROR');
                        }
                    }
                };
                if (ws === 'webSocket') {
                    this.webSocket.onmessage = (evt) => {
                        if (evt.data && `${JSON.parse(evt.data).msgType}` === '6') {
                            store.dispatch(AuthActions.logout());
                            return;
                        }
                        try {
                            const testStepObj = JSON.parse(evt.data);
                            this.sessionId = testStepObj.sessionId;

                            resolve(this.webSocket);
                        } catch (err) {
                            console.log(err, 'ERROR');
                        }
                    };
                    this.webSocket.onclose = (flag) => {
                        console.log(`EVENT SOURCE CLOSE WITH CODE: ${flag.code} message from service`); // eslint-disable-line no-console
                        this.alreadyInitializedWS = false;
                        if (checkKeyInObject(flag, 'code') && `${flag.code}` !== '1000' && `${flag.code}` !== '1006') {
                            WSService.initializeWebSocket(this.accountId);
                        }
                    };
                    this.webSocket.onerror = () => {
                        console.log('ERROR MESSAGE: message from service'); // eslint-disable-line no-console

                        // close old socket con and establish new on getting error
                        WSService.closeWebSocketInstance();
                    };
                } else {
                    this[ws].onclose = () => {
                        this[alreadyInitialized] = false;
                    };
                }
            } else {
                console.info('Web Socket is already initialized');
                resolve(this[ws]);
            }
        });
    };

    static getWebSocketInstance = async (_ws, alreadyInitialized) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const ws = await WSService.initializeWebSocket(this.accountId, _ws, alreadyInitialized);
            resolve(ws);
        });
    };

    static closeWebSocketInstance = (flag) => {
        if (this.alreadyInitializedWS) {
            this.webSocket.close(flag);
        }
    };

    static getSessionId = () => {
        return this.sessionId;
    };

    static getAccountId = () => {
        return this.accountId;
    };

    static sendMessage = async (dataToSend, _ws, alreadyInitialized) => {
        const ws = await WSService.getWebSocketInstance(_ws, alreadyInitialized);
        console.log('message sent!!!', connectionCount);
        WSService.waitForSocketConnection(ws, () => {
            connectionCount = 0;
            ws.send(dataToSend);
        });
    };

    static waitForSocketConnection(socket, callback) {
        connectionCount++;
        setTimeout(() => {
            if (socket.readyState === socket.OPEN) {
                console.log('Connection is made');
                if (callback != null) {
                    callback();
                }
            } else if (connectionCount < 10) {
                console.log('wait for connection...', connectionCount);
                WSService.waitForSocketConnection(socket, callback);
            }
        }, 10); // wait 10 milisecond for the connection...
    }

    static getNewSessionId = async (accountId) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            this.sessionId = await WSService.generateSessionIds(1);
            if (checkArrayLength(this.sessionId)) {
                resolve(this.sessionId[0]);
            } else {
                WSService.getNewSessionId(accountId);
            }
        });
    };

    static generateSessionIds = (count = 5) => {
        this.sessionIds = [];
        const token = localStorage.getItem('token');
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            await WSService.getWebSocketInstance('webSocket', 'alreadyInitializedWS');
            new Array(count).fill('').forEach(() => {
                WSService.sendMessage(
                    JSON.stringify({
                        msgJson: JSON.stringify({ authorizationToken: token }),
                        msgType: 1,
                        sessionId: '',
                        accountId: this.accountId,
                    }),
                    'webSocket',
                    'alreadyInitializedWS',
                );
            });

            this.webSocket.onmessage = (evt) => {
                try {
                    const testStepObj = JSON.parse(evt.data);
                    if (testStepObj.msgType === 1 && testStepObj.sessionId) {
                        console.log('RECEIVED MESSAGE: ', testStepObj);
                        if (!this.sessionIds.includes(testStepObj.sessionId)) {
                            this.sessionIds.push(testStepObj.sessionId);
                            if (this.sessionIds.length === count) {
                                resolve(this.sessionIds);
                            }
                        }
                    }
                } catch (err) {
                    console.log(err, 'getNewSessionId');
                    reject(false);
                }
            };
        });
    };
}

/*
 * WebSocket sendMessage
 * Do empty ping / check connection alive
 * return promise
 * if alive send message, and resolve promise
 * else, initialize connection and wait unless we get response (using promise)
 * then send message and resolve promise
 */
